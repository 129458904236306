<section class="sec-pad">
  <div class="container">
    <div class="heading-sec">
      <p>
        The General Data Protection Regulation (GDPR) is an EU regulation that expands the protection of personal data
        of EU citizens. In doing so, it also expands the obligations of organizations who collect or process that data.
        The goals of the GDPR are to increase transparency and fairness in the handling of individuals’ personal information.
        Personal data is any information relating to an identifiable individual.
        <br><br>
        Enforcement of the GDPR began on May 25, 2018. All organizations needed to be aware of their responsibilities
        and ensure that they were compliant with GDPR by May 25, 2018. Non-compliance can result in hefty fines.
        <br><br>
        The following information is provided to help you understand general concepts about the GDPR. It is not legal
        advice, and you should speak to legal counsel regarding the GDPR and how it affects your organization.
      </p>
    </div>
    <div class="heading-sec">
      <h3>Does the GDPR apply to me?</h3>
      <p>
        The GDPR applies to any organization that is organized in the EU and any organization that processes personal data of EU citizens.
        <br><br>
        Organizations are classified as processors or controllers of personal data. Organizations that determine the purpose
        of the storage or processing of personal information are considered controllers. Organizations that store or process
        personal data on behalf of another organization are considered processors. Some organizations may be both.
        <br><br>
        When it comes to the personal information I enter into WellWink, is my organization a controller or a processor?
        <br><br>
        Because you control and manage the data you enter into the CRM, you are the controller for that data. You decide
        how that data is used, how long to keep it, how often to update it, etc.
      </p>
    </div>
    <div class="heading-sec">
      <h3>What is WellWink role?</h3>
      <p>
        WellWink is a Processor. WellWink is the Processor of the personal data you manage in our systems.
        We store the data for you and process it per your actions in our applications.
      </p>
    </div>
    <div class="heading-sec">
      <h3>How does my organization ensure our compliance?</h3>
      <p>
        Every organization may be different due to their role in managing user data. For example, some organizations
        will be both controllers and processors. In general, you must have processes in place to follow through on
        requests from your users regarding their personal data.
        <br><br>
        When it comes to the data in WellWink, review our updated Terms of Use and Privacy Policy. When you accept WellWink
        Terms of Use, you are acknowledging that your use of WellWink will be compliant with all applicable laws, including the GDPR.
      </p>
    </div>
    <div class="heading-sec">
      <h3>WellWink Compliance?</h3>
      <p>
        We have been fully <span class="fw-500">Compliant Since May 25, 2018.</span> These are just some of the many steps WellWink has taken to meet
        the data transparency goals of the GDPR. This continues our practice of protecting your data and providing for
        the legal and secure handling of your organization’s critical business information.
        <br><br>
        We have amended our Data Processing Addendum (based on Model Contractual Clauses) to be compliant with the data
        processing requirements of GDPR.
      </p>
      <br><br>
      <div class="alert alert-info">
        The information above is provided to help you understand WellWink’s role as processor of your data, the rights
        of your users, and the responsibilities you hold as a controller of their data. It is not comprehensive and is
        not legal advice.
      </div>
    </div>

    <h3>Frequently Asked Questions</h3>
    <div class="panel-group" id="accordion">
      <div class="panel panel-default">
        <div class="panel-heading" id="headingOne">
          <h4 class="panel-title">
            <a href="#collapseOne" data-toggle="collapse" data-parent="#accordion" aria-expanded="true" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;What is GDPR?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse in" id="collapseOne">
          <div class="panel-body">
            The EU's General Data Protection Regulation (GDPR) is a game changer in data protection and privacy laws.
            The EU has realized that while technology has evolved drastically in the last few decades, privacy laws have
            not. In 2016, EU regulatory bodies decided to update the current Data Protection Directive to suit the
            changing times. This law creates a comprehensive list of regulations that govern the processing of EU
            residents' personal data.
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingTwo">
          <h4 class="panel-title">
            <a href="#collapseTwo" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;Who does it apply to?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseTwo">
          <div class="panel-body">
            GDPR applies to any organization that works with the personal data of EU residents. This law introduces new
            obligations for data processors while clearly stating the accountability of data controllers.
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingThree">
          <h4 class="panel-title">
            <a href="#collapseThree" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;Where does the GDPR apply?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseThree">
          <div class="panel-body">
            This law doesn't have territorial boundaries. It doesn't matter where your organization is from — if you
            process the personal data of subjects of the EU, you come under the jurisdiction of the law.
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingFour">
          <h4 class="panel-title">
            <a href="#collapseFour" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;What are the penalties for non-compliance?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseFour">
          <div class="panel-body">
            A breach of the GDPR incurs a fine of up to 4% of annual global turnover or €20 million (whichever is greater).
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingFive">
          <h4 class="panel-title">
            <a href="#collapseFive" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;What is personal data or Personally Identifiable Information (PII)?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseFive">
          <div class="panel-body">
            Any information relating to an identified or identifiable natural person. The identifiers are classified into
            two types: direct (e.g., name, email, phone number, etc.) and indirect (e.g., date of birth, gender, etc.).
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingSix">
          <h4 class="panel-title">
            <a href="#collapseSix" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;What is personal data or Personally Identifiable Information (PII)?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseSix">
          <div class="panel-body">
            Any information relating to an identified or identifiable natural person. The identifiers are classified into
            two types: direct (e.g., name, email, phone number, etc.) and indirect (e.g., date of birth, gender, etc.).
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingSeven">
          <h4 class="panel-title">
            <a href="#collapseSeven" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;What are the key changes from the previous regulations?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseSeven">
          <div class="panel-body">
            <p>
              <span class="fw-500">New & enhanced rights for data subjects-</span> This law gives an individual the right to exercise complete
              authority over their personal data. Some of the rights highlighted in the regulation are:
            </p>
            <p>
              <span class="fw-500">Explicit consent-</span> Data subjects must be informed about how their personal data
              will be processed. Organizations must make it as easy for data subjects to withdraw their consent as
              it is to grant it.
            </p>
            <p>
              <span class="fw-500">Right to access-</span> At any point in time, the data subject can ask the controller
              what personal data is being stored or retained about him/her.
            </p>
            <p>
              <span class="fw-500">Right to be forgotten-</span> The data subject can request the controller to remove
              their personal information from the controller's systems.
            </p>
            <p>
              <span class="fw-500">Obligations of the processors-</span> GDPR has raised the bar for the responsibilities
              and liabilities of data processors as well. Processors must be able to demonstrate compliance with the GDPR
              and they must follow the data controller's instructions.
            </p>
            <p>
              <span class="fw-500">Data Protection Officer-</span> Organizations may need to appoint a staff member or
              external service provider who is responsible for overseeing GDPR, general privacy management compliance
              and data protection practices.
            </p>
            <p>
              <span class="fw-500">Privacy Impact Assessments (PIA)-</span> Organizations must conduct privacy impact
              assessments of their large-scale data processing to minimize the risks and identify measures to mitigate them.
            </p>
            <p>
              <span class="fw-500">Breach notification-</span> Controllers must notify the stakeholders (the supervisory
              authority, and where applicable, the data subjects) within 72 hours of becoming aware of a breach.
            </p>
            <p>
              <span class="fw-500">Data portability-</span> The controller must be able to provide data subjects with a
              copy of their personal data in machine readable format. If possible, they must be able to transfer the data
              to another controller.
            </p>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingEight">
          <h4 class="panel-title">
            <a href="#collapseEight" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;What are the lawful bases the data controller can use to process customer data?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseEight">
          <div class="panel-body">
            The data controller can choose from six data processing bases. These are:
            <p>
              <span class="fw-500">Contract-</span> This applies when you need to process the customer's personal data to
              fulfill your contractual obligations, or to take some action based on the customer's request
              (e.g. sending a quote or invoice).
            </p>
            <p>
              <span class="fw-500">Legal Obligation-</span> This applies when you have to comply with an obligation under
              any applicable law (e.g. providing information in response to valid requests, such as an investigation by an authority).
            </p>
            <p>
              <span class="fw-500">Vital Interests-</span> This applies to urgent matters of life and death, especially with regards to health data.
            </p>
            <p>
              <span class="fw-500">Public Task-</span> This applies to activities of public authorities.
            </p>
            <p>
              <span class="fw-500">Legitimate Interests-</span> Legitimate interests can include commercial interests,
              such as direct marketing, individual interests, or broader societal benefits. The controller must document
              and keep a record of decisions on legitimate interests in the form of a Legitimate Interests Assessment.
            </p>
            <p>
              <span class="fw-500">Consent-</span> Consent is also a lawful basis to process data. Consent of the data
              subject means "any freely given, specific, informed, and unambiguous indication of the data subject's wishes
              by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing
              of personal data relating to him or her."
            </p>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingNine">
          <h4 class="panel-title">
            <a href="#collapseNine" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;What is LIA?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseNine">
          <div class="panel-body">
            <p>
              LIA stands for Legitimate Interests Assessment. It specifies the reason an organization wants to process a
              customer's personal data. The organization must also conduct an LIA to show that the processing is necessary.
            </p>
            <p>The assessment of whether a legitimate interest exists.</p>
            <p>The establishment of the necessity for processing.</p>
            <p>The performance of the balancing test.</p>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="headingTen">
          <h4 class="panel-title">
            <a href="#collapseTen" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;Does the GDPR require EU personal data to stay in the EU?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapseTen">
          <div class="panel-body">
            No, the GDPR does not require EU personal data to stay in the EU, nor does it place any new restrictions on
            transfers of personal data outside the EU. Our data processing addendum, which references the European
            Commission’s model clauses, will continue to help our customers facilitate transfers of EU personal data outside of the EU.
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-heading" id="heading11">
          <h4 class="panel-title">
            <a href="#collapse11" data-toggle="collapse" data-parent="#accordion" class="collapsed">
              <i class="fa fa-chevron-down"></i> &nbsp;&nbsp;Where can I find additional resources on GDPR?
            </a>
          </h4>
        </div>
        <div class="panel-collapse collapse" id="collapse11">
          <div class="panel-body">
            <p class="fw-500">Here are some links you can refer to for additional reading on the GDPR:</p>
            <p><a href="http://gdprandyou.ie/resources" target="_blank">Find your supervisory authority</a></p>
            <p><a href="https://edps.europa.eu" target="_blank">EU Data Protection Supervisor</a></p>
            <p><a href="https://www.eugdpr.org/" target="_blank">Website of EU GDPR</a></p>
            <p><a href="https://ec.europa.eu/commission/priorities/justice-and-fundamental-rights/data-protection/2018-reform-eu-data-protection-rules_en"
                  target="_blank">Rules for businesses and organizations</a>
            </p>
            <p><a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/"
                  target="_blank">Your organization's guide to GDPR</a>
            </p>
            <div class="alert alert-info">
              Note:Zoho Corporation is not responsible for the content in these pages and does not endorse these links.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
