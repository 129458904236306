import {Component, Injector, OnInit} from '@angular/core';
import {Comment} from '../../routes/practice-site/tasks/comment';
import {Goal} from '../goal';
import {CommentService} from '../../routes/practice-site/tasks/comment.service';
import {removeUnderScore} from '../../services/remove-under-score';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-goal-details',
  templateUrl: './goal-details.component.html',
  styleUrls: ['./goal-details.component.scss']
})
export class GoalDetailsComponent implements OnInit {
  public goal: Goal = new Goal();
  programme_type: any;
  public comments

  constructor(public injector: Injector, public commentService: CommentService, public userService: UserService) { }

  ngOnInit(): void {
    this.goal = this.injector.get('goal', null);
    this.programme_type = this.injector.get('programme_type', null);
    this.commentService.getComments(this.goal.id, 'Goal').then(resp => {
      this.comments = resp.map(comment => new Comment().load_from_json(comment));
    })
  }
}
