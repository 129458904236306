<div class="chat-flex" [class.body-panel-shown]="middle_selected">
  <div class="left-panel">
    <div class="search-sec typeheadchat">
      <i class="fa icon-magnifier"></i>
      <label class="form-control">
        <input placeholder="Search or Start new sms"
               [(ngModel)]="token" [typeahead]="data_source"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               (typeaheadNoResults)="changeTypeaheadNoResults($event)"
               (typeaheadOnSelect)="typeaheadOnSelect($event)"
               [typeaheadOptionsLimit]="7"
               [typeaheadMinLength]="1"
               [typeaheadOptionField]="'user_full_name'"
               (input)="onInputChange($event.target.value)"
               typeaheadWaitMs="500"
        />
        <i class="fa fa-spinner fa-pulse" style="left:auto;" *ngIf="typeaheadLoading"></i>
      </label>
    </div>
    <ul class="user-list border-list m0 p0 scroll-browser">
      <ng-container *ngIf="smsService.selected_conversation && smsService.conversations && smsService.conversations.length > 0">
        <li class="noborder static-li" *ngIf="filteredConversation.length && token.length">Chat</li>
        <ng-container *ngFor="let conversation of filteredConversation">
          <li class="list" [class.new-msg]="conversation.unread_count > 0" [class.active]="conversation.id == smsService.selected_conversation.id">
            <div class="media" (click)="loadMessages(conversation);middle_selected=true">
              <div class="media-left">
                <img alt="Image" [src]="conversation?.thumbnail">
              </div>
              <div class="media-body media-middle">
                <div class="flex-grow">
                  <h4 class="name grow" [title]="conversation.title">
                    {{conversation.title}}
                    <small *ngIf="conversation?.patient?.customize_age"> - {{conversation?.patient?.customize_age}}</small>
                  </h4>
                  <small class="date" *ngIf="conversation.last_sms">{{conversation.last_sms.created_at | date: 'shortTime'}}</small>
                </div>
                <div class="flex-grow">
                  <h6 class="sub-msg grow" *ngIf="conversation.last_sms">
                    {{conversation.last_sms.body}}
                  </h6>
                  <span *ngIf="conversation.unread_count > 0" class="counter">{{conversation.unread_count}}</span>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="typeheadResult.length">
        <li class="noborder static-li">Contacts</li>
        <li class="list" *ngFor="let recipient of typeheadResult">
          <div class="media" (click)="addToChatroom(recipient);middle_selected=true">
            <div class="media-left">
              <img alt="Image" [src]="recipient.patient.profile_pic">
            </div>
            <div class="media-body media-middle">
              <div class="flex-grow">
                <h4 class="name grow" [title]="recipient.patient.name">
                  {{recipient.patient.name}}
                  <small *ngIf="recipient.patient.customize_age"> - {{recipient.patient.customize_age}}</small>
                </h4>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <app-sms-details class="body-panel p-relative"
                   [conversation]="smsService.selected_conversation">
  </app-sms-details>
</div>

