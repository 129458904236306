import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {BlockCalendar} from '../../doctors/doctor';
import {BlockCalendarService} from '../../../../services/block-calendar.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-block-calendar-form',
  templateUrl: './block-calendar-form.component.html',
  styleUrls: ['./block-calendar-form.component.scss']
})
export class BlockCalendarFormComponent implements OnInit {
  @Input() permissions;
  @Input() block_calendar: BlockCalendar;
  @Input() calendar_locations: any;
  @Input() calendarable_id: number;
  @Input() calendarable_type: string;
  @Input() block_calendar_locations: any;
  @Output() edited_block_calendar: EventEmitter<BlockCalendar> = new EventEmitter<BlockCalendar>();
  @Output() block_form_hide: EventEmitter<BlockCalendar> = new EventEmitter<BlockCalendar>();
  dateCustomClasses: DatepickerDateCustomClasses[];
  messageAfterSave = false;
  public error = '';
  public success = false;
  public show_form = true;
  public dateRange = false;
  public today: any;
  minDate: Date;
  constructor(private _block_calendar_service: BlockCalendarService, private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 1);
  }

  ngOnInit() {
    this.block_calendar.calendarable_id = this.calendarable_id;
    this.block_calendar.calendarable_type = this.calendarable_type;
    this.today = new Date().toJSON().split('T')[0];
  }

  hide_form(calendar) {
    this.block_form_hide.emit(this.block_calendar);
  }

  save() {
    if (this.block_calendar.validationCheck()) {
      this.setTimeOutDateRange();
      return;
    }

    this._block_calendar_service.save(this.block_calendar).then(res => {
      if (res.id) {
        this.block_calendar.id = res.id;
        this.block_calendar.location_name = res.location_name;
        this.edited_block_calendar.emit(this.block_calendar);
        this.success = true;
        return res;
      } else {
        this.error = res.error[0];
        this.setTimeOutSuccessMessage();
      }
    }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error );
  }

  setTimeOutDateRange(): any {
    this.dateRange = true;
    setTimeout(function() {
      this.dateRange = false;
    }.bind(this), 3000);
  }

  setTimeOutSuccessMessage(): any {
    this.messageAfterSave = true;
    setTimeout(function() {
      this.messageAfterSave = false;
      this.success = false;
    }.bind(this), 3000);
  }

  onChange(selectedValue) {
    this.block_calendar.location_id = parseInt(selectedValue);
  }

}
