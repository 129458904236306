import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {CustomHttpClient} from "../../services/custom-http-client.service";
import {promise} from "selenium-webdriver";
import {NoteComponent} from "./note-component";
import {HttpParams} from "@angular/common/http";
import {nullRemover} from "../../services/nullRemover";


@Injectable({
  providedIn: 'root'
})
export class NoteComponentService {
  reloadNoteComponentList: Subject<any> = new Subject<any>();
  constructor(private _tokenService: CustomHttpClient ) { }

  create (note_component: NoteComponent): Promise<any> {
   return  this._tokenService
      .post('note_components', note_component.to_params())
      .toPromise()
  }

  update (note_component: NoteComponent): Promise<any> {
    return  this._tokenService
      .put('note_components', note_component.to_params())
      .toPromise()
      .then(response => response)
  }

  save(note_component: NoteComponent): Promise<any> {
    if (note_component.is_new_record()) {
      return this.create(note_component);
    }
    else {
      return this.update(note_component);
    }
  }

  getNoteComponents(filter: any): Promise<any> {
   let params = new HttpParams( {fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('note_components', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          note_components: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  getCategories(): Promise<any> {
    return this._tokenService.get('note_components/categories')
      .toPromise()
  }
  searchComponentTypeahead (component: any): Promise<any> {
    let params = new HttpParams( {fromObject: nullRemover(component)});
    return this._tokenService.get('note_components/search_component_typeahead', {params: params})
      .toPromise()
  }
}
