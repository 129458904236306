import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PatientService} from '../../services/patient.service';
import {UserService} from '../../services/user.service';
import {Patient} from '../../health/doctor/patient';
import {Router} from '@angular/router';
import {GlobalErrorHandlerService} from '../../services/global-error.service';

@Component({
  selector: 'app-patient-verification',
  templateUrl: './patient-verification.component.html',
  styleUrls: ['./patient-verification.component.scss']
})
export class PatientVerificationComponent implements OnInit {
  public mask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  mobile_message = '';
  email_message = '';
  general_message = '';
  patient: Patient;
  type = 'email';
  edit_email = false;
  edit_phone = false;
  @Input() parent_class_name = 'container';
  data = {mobile_otp: '', email_otp: ''};
  @Output() continue:  EventEmitter<any> = new EventEmitter<any>();
  @Input() skip_auto_navigation = false;
  message_class = 'success';

  constructor(private patient_service: PatientService, private globalErrorHandlerService: GlobalErrorHandlerService,
              private user_service: UserService, private router: Router) { }
  ngOnInit() {
    this.load_patient(true);
    this.patient = this.user_service.patient_to_verify;
    if (this.patient && !this.patient.mobile_verified) {
      this.sendOtp('sms', '')
    }
    if (this.patient && !this.patient.email_verified) {
      this.sendOtp('email', '')
    }
    if (this.patient && this.patient.verified()) {
      this.continue.emit(this.patient);
    }
  }

  load_patient(force_load?) {
    if (!this.user_service.patient_to_verify) {
      this.user_service.load_patient_to_verify(force_load);
      this.patient = this.user_service.patient_to_verify;
    }
    return this.patient;
  }

  patient_loaded() {
    return this.patient || this.load_patient()
  }

  toggleEditPhone() {
    this.edit_phone = !this.edit_phone;
  }

  toggleEditEmail() {
    this.edit_email = !this.edit_email;
  }


  sendNewMobileOtp(value) {
    this.sendOtp('sms', value).then(res => {
      this.toggleEditPhone();
    })
  }

  sendNewEmailOtp(value) {
    this.sendOtp('email', value).then(res => {
      this.toggleEditEmail();
    })
  }

  verify_email() {
    this.patient_service.verifyEmail({id: this.user_service.patient_to_verify.hashid, otp: this.data.email_otp}).
    then(res => {
      this.patient = this.user_service.patient_to_verify;
      this.user_service.patient_to_verify.email_verified = true;
      this.email_message = res.message;
      this.message_class = 'success';
      if (this.patient.verified()) {
        this.user_service.set_patient_verified();
      }
      this.clear_messages();
    }).catch(res => {
      this.email_message =  res.error.message;
      this.message_class = 'failed';
    })
  }

  sendOtp(type, data) {
    this.type =  type;
    return this.patient_service.sendOtp({id: this.user_service.patient_to_verify.hashid, type: type, data: data}).
    then(response => {
      this.general_message = response.message;
      this.message_class = 'success';
      this.clear_messages();
    }).catch(res => {
      this.globalErrorHandlerService.error = res.error.error;
      this.general_message =  res.error.message;
      this.message_class = 'failed';
      this.clear_messages();
    })
  }

  verifyOtp() {
    this.patient_service.verifyOtp({id: this.user_service.patient_to_verify.hashid, otp: this.data.mobile_otp}).
    then(res => {
      this.patient = this.user_service.patient_to_verify;
      this.user_service.patient_to_verify.mobile_verified = true;
      this.mobile_message = res.message;
      this.message_class = 'success';
      if (this.patient.verified()) {
        this.user_service.set_patient_verified();
      }
      this.clear_messages();
    }).catch(res => {
      this.mobile_message =  res.error.message;
      this.message_class = 'failed';
      this.clear_messages();
    })
  }

  mobile_verified() {
    return this.user_service.patient_to_verify.mobile_verified;
  }

  email_verified() {
    return this.user_service.patient_to_verify.email_verified;
  }

  clear_messages() {
    setTimeout(() => {
      this.mobile_message = '';
      this.email_message = '';
      this.general_message = '';
    }, 3500);
  }

  goto_profile() {
    if (this.patient.verified()) {
      this.continue.emit(this.patient);
      if (!this.skip_auto_navigation) {
        this.router.navigate(['/patients', this.patient.id]);
      }
    }
  }


}
