<div class="flex-control flex-column flex-height" *ngIf="!editTask">
  <div class="flex-header">
    <div class="media">
      <div class="media-body media-middle"><h4 class="m0">{{task.task_name}}</h4></div>
      <div class="media-body text-right">
        <a (click)="updateTask(task)" class="btn btn-tertiary btn-outline"><i class="fa icon-pencil vl-m mr-sm fs14"></i> Edit</a>
        <a (click)="markCompletedModel.show();" class="btn btn-secondary btn-outline ml" *ngIf="task.status_id !== 'Completed'">
          <i class="fa icon-check vl-m mr-sm fs14"></i> Mark Completed
        </a>
        <a class="btn btn-danger btn-outline ml" type="button" (click)="deleteModal.show()">
          <i class="fa icon-trash vl-m mr-sm fs14"></i> Delete
        </a>
      </div>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <ul class="task-list list-unstyled border-list m0">
      <li class="list">
        <div class="media">
          <div class="media-left text-tertiary">
            <i class="fa icon-book-open fs16 vl-m mr"></i>
            <span class="field-label">Description</span>
          </div>
          <h5 class="media-body media-middle m0 fw-300" style="white-space:pre; word-break:break-all;">{{task.task_description}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-check fs16 vl-m mr"></i>
            <span class="field-label">Assigned to</span>
          </div>
          <h5 class="media-body media-middle m0 fw-300">{{task.assigned_to_name}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-user fs16 vl-m mr"></i>
            <span class="field-label">Patient</span>
          </div>
          <h5 *ngIf="!task.patient.name" class="media-body media-middle m0 fw-300">No patient is associated</h5>
          <h5 class="media-body media-middle m0 fw-300">{{task.patient.name}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-flag fs16 vl-m mr"></i>
            <span class="field-label">Priority</span>
          </div>
          <h5 class="media-body media-middle m0 fw-300">{{task.priority}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-calendar fs16 vl-m mr"></i>
            <span class="field-label">Due Date</span>
          </div>
          <h5 class="media-body media-middle m0 fw-300">{{task.due_date | date : 'mediumDate'}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-star fs16 vl-m mr"></i>
            <span class="field-label">Status</span>
          </div>
          <div class="media-body media-middle">
            <label *ngIf="task.status_id == 'Not Started'" class="label label-danger m0">{{task.status_id}}</label>
            <label *ngIf="task.status_id == 'In Progress'" class="label label-info m0">{{task.status_id}}</label>
            <label *ngIf="task.status_id == 'Completed'" class="label label-success m0">{{task.status_id}}</label>
          </div>
        </div>
      </li>
    </ul>
    <app-comment class="p" [comments]="task.comments" [commentable_id]="task.id"
                 [commentable_type]="'Task'" [commentable]="task" [programme_type]="programme_type"></app-comment>
  </div>
</div>
<div class="flex-control flex-column flex-height" *ngIf="editTask">
  <div class="flex-body scroll-browser p">
    <ng-container *ngComponentOutlet="newTaskComponent;injector: view_custom_injector;"></ng-container>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Task</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to delete the Task?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteTask(task);">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #markCompletedModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="markCompletedModel.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Mark Task Completed</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to mark this task as completed?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="markCompleted();markCompletedModel.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="markCompletedModel.hide();">No</button>
      </div>
    </div>
  </div>
</div>
