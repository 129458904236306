<ul class="actions-btn list-unstyled m0 mt" *ngIf="!sourceable_type && (sourceable_type !== 'ProgrammeEnrolment')">
  <li>
    <a class="btn btn-secondary btn-outline btn-sm" tooltip="Instant Message"
       *ngIf="patient.can_login" (click)="send_message(patient)">
      <i class="custom-icon im-icon"></i>
    </a>
  </li>
  <li>
    <button class="btn btn-secondary btn-outline btn-sm" tooltip="Email" (click)="emailModel.show()">
      <i class="fa icon-envelope vl-m"></i>
    </button>
  </li>
  <li class="sms-tooltip">
    <button [disabled]="!userService.user.twilio_configurations.sms"
            class="btn btn-secondary btn-outline btn-sm"
            [tooltip]="userService.user.twilio_configurations.sms ? 'SMS' : 'Twilio is not configured'"
            (click)="smsModel.show()">
      <i class="fa icon-bubbles vl-m"></i>
    </button>
  </li>
  <li>
    <a class="btn btn-secondary btn-outline btn-sm" tooltip="Task" (click)="addNewTaskInPopup(patient)">
      <i class="fa icon-notebook"></i>
    </a>
  </li>
  <li>
    <a class="btn btn-secondary btn-outline btn-sm" tooltip="Note" (click)="addNewNoteInPopup(patient)">
      <i class="fa icon-note"></i>
    </a>
  </li>
</ul>

<div dropdown class="ml-sm p-relative pull-right" *ngIf="sourceable_type === 'ProgrammeEnrolment'">
  <button type="button" dropdownToggle class="btn btn-secondary"><i class="fa fa-plus"></i> Activity</button>
  <ul class="dropdown-menu animated slideInDown">
    <li *ngIf="userService.user.twilio_configurations.voice"><a (click)="phoneService.initiatePhoneCall(patient.mobile_number_value,
        {name: patient.name,
                    pic: patient.profile_pic,
                    mobile: patient.mobile_number_value,
                    phone: patient.landline_number_value,
                    e_contacts: patient.contacts,
                    show_selection: true
                    }, source_id_for_phone_call)">
      <i class="fa icon-phone fa-fw"></i>&nbsp; Phone Call</a></li>
    <li *ngIf="userService.user.twilio_configurations.video"><a (click)="addVideoToTab(patient)"><i class="fa icon-camrecorder fa-fw"></i>&nbsp; Video Call</a></li>
    <li ><a (click)="addNewNoteInPopup(patient)"><i class="fa icon-note fa-fw"></i>&nbsp; Note</a></li>
    <li ><a (click)="addNewTaskInPopup(patient)"><i class="fa icon-notebook fa-fw"></i>&nbsp; Task</a></li>

    <li ><a (click)="startAction('email'); emailModel.show()"><i class="fa icon-envelope fa-fw"></i>&nbsp; Email</a></li>
    <li *ngIf="userService.user.twilio_configurations.sms"><a (click)="startAction('sms'); smsModel.show()"><i class="fa icon-bubble fa-fw"></i>&nbsp; SMS</a></li>
  </ul>
</div>

<div class="modal flex-modal fade" bsModal #emailModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog" style="margin:auto; height:557px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="emailModel.hide(); trackingService.deleteTrackingObject(actionId)"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Email</h4>
      </div>
      <div class="modal-body scroll-browser">
        <ul class="list-unstyled border-list m0">
          <li class="list" *ngIf="smtp_associate_practice_emails.length > 0 && userService.current_user.isDoctor">
            <div class="media">
              <div class="media-left media-middle">
                <i class="fa icon-user fs16 vl-m mr"></i>
                <span class="fs16">FROM*</span>
              </div>
              <div class="media-body media-middle">
                <select class="form-control" [(ngModel)]="selected_smtp_practice_id" name="email_configuration_id" required>
                  <option *ngFor="let practice of smtp_associate_practice_emails; let i = index;"  [value]="practice.id">
                    {{practice.name}}
                  </option>
                </select>
              </div>
            </div>
          </li>
          <li class="list">
            <div class="media">
              <div class="media-left media-middle" style="min-width:100px;">
                <i class="fa icon-user fs16 vl-m mr"></i>
                <span class="fs16">To</span>
              </div>
              <div class="media-body media-middle">
                <h4 class="m0">{{patient.name}}</h4>
                <h5 class="m0" *ngIf="patient.valid_email">{{patient.valid_email}}</h5>
                <h5 class="m0" *ngIf="!patient.valid_email">Email Id doesn't exist</h5>
              </div>
            </div>
          </li>
          <li class="list">
            <div class="media overflow-visible">
              <div class="media-left media-middle" style="min-width:100px;">
                <i class="fa icon-doc fs16 vl-m mr"></i>
                <span class="fs16">Subject*</span>
              </div>
              <div class="media-body media-middle">
                <input type="text" class="form-control" placeholder="Subject of the email" [(ngModel)]="temp_subject" name="subject">
              </div>
              <div class="media-right">
                <app-embed-rich-text-template [embedFor]="'email'"
                                              [showWarning]="temp_email_body ? true : false"
                                              (exportTemplate)="embedEmailBody($event)">
                </app-embed-rich-text-template>
              </div>
            </div>
          </li>
        </ul>
        <editor #editor apiKey="no-api-key"  name="email_body" [(ngModel)]="temp_email_body"
                [init]="init_tiny">
        </editor>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-secondary mr" [disabled]="(temp_email_body && temp_subject)  ? false : true" (click)="sendEmail()">Send</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="emailModel.hide(); trackingService.deleteTrackingObject(actionId)">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #smsModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog" style="height:400px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="smsModel.hide(); trackingService.deleteTrackingObject(actionId)"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Send SMS</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-info text-center p-sm fs12 mb-sm">Text message is not secure, please do not share patient health information while sending text.</div>
        <div class="media mb-lg overflow-visible">
          <div class="media-left media-middle" style="min-width:62px;">
            <i class="fa icon-user fs22 vl-m mr"></i>
            <span class="fs16">To</span>
          </div>
          <div class="media-body media-middle">
            <h4 class="m0">{{patient.name}}</h4>
            <h5 class="m0" *ngIf="patient.mobile_number_value">{{patient.mobile_number_value | mask: '(000) 000-0000'}}</h5>
          </div>
          <div class="media-right">
            <app-embed-rich-text-template [embedFor]="'text_message'"
                                          [showWarning]="temp_sms_body ? true : false"
                                          (exportTemplate)="embedSmsBody($event)">
            </app-embed-rich-text-template>
          </div>
        </div>
        <textarea  #s_body rows="5" type="text" class="form-control"
                   [(ngModel)]="temp_sms_body"
                   name="sms_body" required>
        </textarea>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-secondary mr" (click)="sendSms()" [disabled]="temp_sms_body ? false : true">Send</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="smsModel.hide() ;trackingService.deleteTrackingObject(actionId)">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #newNoteModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog" style="height:446px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newNoteModel.hide(); show_node_model = false; trackingService.deleteTrackingObject(actionId)"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Note</h4>
      </div>
      <ng-container *ngIf="show_node_model">
        <ng-container *ngComponentOutlet="newNoteComponent; injector: custom_injector;"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #newTaskModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog" style="height:608px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newTaskModel.hide(); show_task_model = false;">
          <i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Add Task</h4>
      </div>
      <div class="modal-body" *ngIf="show_task_model">
        <ng-container *ngComponentOutlet="newTaskComponent; injector: custom_injector;"></ng-container>
      </div>
    </div>
  </div>
</div>
