import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router'
import {SpecialtySearchService} from  '../../services/specialty-search.service'
import {Observable} from 'rxjs/Observable';
import  {ServicesService} from  '../../services/services.service'
import  {LoaderService} from  '../../services/loader.service'
import  {SearchService} from  '../../services/search.service'
import  {SlotDatesService} from  '../../services/slot-dates.service'
import  {Physician} from '../models/physician'
import { GoogleMap } from '@angular/google-maps';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  mergeMap,
  pairwise,
  switchMap,
} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {Location} from '../../routes/practices/locations/location';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  static  MAPPINGS = {
    query: 'query', q: 'query', specialty_id: 'specialty_id', page: 'page_number',
    lat: 'lat', long: 'long', service_id: 'service_id', gender: 'gender', shift: 'shift_time',
    radius: 'radius', sort_by: 'sort_by', 'term': 'term', illness_term: 'illness_term', area_text: 'area_text', area: 'area_text'
  };
  sort_by: any;
  page_number = 1;
  total_pages = 1;
  total_count = 0;
  public  dates = [new Date(Date.now() - 86400000), new Date(), new Date(Date.now() + 86400000)];
  load_inprogress = false;
  physicians: Physician[] = [];
  online_virtual_physicians: Physician[] = [];
  zoom = 15;
  lat: number;
  search_by_map = false;
  long: number;
  area_text = '';
  radius: number;
  suggestion = '';
  query = '';
  specialty_id: string;
  gender:any;
  shift_time:any;
  markers: google.maps.Marker[] = [];
  public  term = '';
  public  illness_term = '';
  public  service_id: string;
  public specialties: Observable<any[]>;
  public medical_services: Observable<any[]>;
  public active_results = false;
  public message_error = false;
  searchHandler: Subject<any> = new Subject<any>();
  JSON = JSON;

  private
  private goingHome =  true;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  constructor(private route: ActivatedRoute, private router: Router, private specialtySearchService: SpecialtySearchService,
              private medicalService: ServicesService, public searchService: SearchService, private loaderService: LoaderService,
              private slot_dates_service: SlotDatesService) {
    this.searchHandler.pipe(
      debounceTime(1000),
    switchMap(() => this.search()),
      distinctUntilChanged()
    ).subscribe(resp => console.log(resp))
  }

  ngOnInit() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
    this.router.events.pipe(filter((e: any) => e instanceof RoutesRecognized),
      pairwise()
    ).subscribe((e: any) => {
      this.goingHome = e[1].url === '/';
    })
    this.route.url.subscribe(url => console.log(url))
    this.route.queryParams.subscribe(params => {
      console.log(params, params['app_type']);
      this.searchService.app_type = params['app_type'];
      Object.keys(SearchComponent.MAPPINGS).forEach(key => {
        if (params[key]) {
          this[SearchComponent.MAPPINGS[key]] = params[key];
        }
      });
      window.scrollTo(0, 0)
      this.search();
    });
    this.specialties = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.term);
    }).pipe(mergeMap((token: string) => this.getSpecialties(token)));
    this.medical_services = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.illness_term);
    }).pipe(mergeMap((token: string) => this.medicalService.search_services(token)));
    if (this.searchService.app_type === 'virtual_visit') {
      this.loaderService.show();
      this.searchService.videoConsultationDoctors().subscribe(response => {
        this.total_count = response.total_count;
        response.doctors.forEach(doctor => {
          this.physicians.push(new Physician().load_from_json(doctor, response.slots[doctor.id]));
        });
        this.active_results = true;
        this.loaderService.hide();
      }, error => {
        console.log(error);
        this.loaderService.hide();
      })
      this.searchService.onlineVirtualDoctors().subscribe(response => {
        this.total_count = response.total_count;
        response.doctors.forEach(doctor => {
          const ph = new Physician().load_from_json(doctor, response.slots[doctor.id]);
          ph.setVirtualSlots();
          if (ph.virtual_slots) {
            this.online_virtual_physicians.push(ph);
          }
        });
        console.log(this.online_virtual_physicians);
        this.active_results = true;
        // this.loaderService.hide();
      }, error => {
        console.log(error);
        this.loaderService.hide();
      })
    } else {
      this.search();
    }
  }

  addMarker(loc: Location) {
    this.markers.push(new google.maps.Marker({
      position: {
        lat: loc.lat,
        lng: loc.long,
      },
      label: {
        color: 'blue',
        text: loc.name,
      },
      title: loc.name,
      opacity: loc.marker_opacity,
      animation: google.maps.Animation.BOUNCE ,
    }));
    console.log(this.markers);
  }

  area_selected(area_data) {
    this.lat = area_data.lat;
    this.long = area_data.long;
    this.area_text = area_data.name;
  }

  specialtySelect(specialty) {
    this.specialty_id = specialty.item.id
  }
  serviceSelect(service) {
    this.service_id = service.item.id
  }

  performSearch() {
     this.router.navigate(['/search'], { queryParams: this.search_params });
     // this.searchHandler.next(1);
  }

  setTimeOutFailsMessage(): any {
    this.message_error = true;
    setTimeout(function() {
      this.message_error = false;
    }.bind(this), 3000);
  }

  search(by_suggestion?: boolean, load_more?: boolean): Observable<any> {
    // setTimeout(() => {
        this.slot_dates_service.got2Today();
        this.load_inprogress = true;
        if (!load_more) {
          this.page_number = 1;
        }
        if (by_suggestion) {
          this.query = this.suggestion;
          this.suggestion  = '';
        }
        this.loaderService.show();
        this.searchService.searchDoctors(this.search_params).subscribe(response => {
          this.total_pages = response.total_pages;
          this.total_count = response.total_count;
          if (!load_more) {
            this.physicians = [];
            this.online_virtual_physicians = [];
          }
          response.doctors.forEach(doctor => {
            let physician = new Physician().load_from_json(doctor, response.slots[doctor.id]);
            this.physicians.push(physician);
            const ph = new Physician().load_from_json(doctor, response.slots[doctor.id]);

            if (ph.locations.filter(l => l.name === 'Video Consultation').length > 0) {
              ph.setVirtualSlots();
            }
            if (ph.virtual_slots) {
              this.online_virtual_physicians.push(ph);
            }
          });
          this.active_results = true;
          // this.sortResults();
          this.loaderService.hide();
          this.setLatLngBounds();
          if (!this.search_by_map) {
            this.setLatLngBounds();
          }
          this.load_inprogress = false;
        }, error => {

          console.log(error);
          this.loaderService.hide();
        });
        if (!by_suggestion) {
          this.checkSuggestion();
        }
        // }
      // ,500)
    return of(1);
  }


  checkSuggestion() {
    if (this.query === undefined || this.query!.length <= 4) {
      return;
    }
    this.searchService.getSuggestion(this.query).subscribe(response => {
      if (response['all_ok']) {

        this.suggestion = '';
      }
      else {
        this.suggestion = response['suggestion']
      }
    });
  }
  specialtyChange(data) {
    if (data.replace(' ', '').length === 0) {
      this.specialty_id = '';
    }
  }
  serviceChange(data) {
    if (data.replace(' ', '').length === 0) {
      this.service_id = '';
    }
  }
  areaSearch(data) {
    if (data.replace(' ', '').length === 0) {
      this.lat = 0;
      this.long = 0;
    }
  }
  map_moved(event: google.maps.MapMouseEvent) {
    this.center = this.map.getBounds().getCenter().toJSON();
  }
  map_idle(event?) {
    this.center = this.map.getBounds().getCenter().toJSON();
    if (this.search_by_map) {
      this.search();
    }
  }
  getSpecialties(term: any) {
    return this.specialtySearchService.search(term);
  }

  sortResults() {
    if (this.sort_by === 'distance') {
      this.physicians = this.physicians.sort((d1, d2) => d1.mdistance - d2.mdistance);
    }
    else {
      this.physicians = this.physicians.sort((d1, d2) => {
        return d2.overall_rating - d1.overall_rating
      });
    }
  }

  get search_params() {
    let search_params: Object;
    search_params = this.setQueryParams();
    if (this.search_by_map) {
      search_params = {...search_params, ...this.getlatLngBoundsFromBounds()};
    }
    return search_params;
  }

  setQueryParams (): Object {
     return  {
      query: this.query, specialty_id: this.specialty_id, page: this.page_number, app_type: this.searchService.app_type,
      lat: this.lat, long: this.long, service_id: this.service_id, gender: this.gender, shift: this.shift_time,
      radius: this.radius, sort_by: this.sort_by, term: this.term, illness_term: this.illness_term, area: this.area_text
    };
  }
  setLatLngBounds() {
    let lat = -180;
    let long = 180;
    this.physicians.forEach(doc => {
      doc.locations.forEach(loc => {
        if (loc.lat < lat) {
          lat = loc.lat;
        }
        if (loc.long > long) {
          long = loc.long;
        }
        this.addMarker(loc)
      });
    });
    // if (this.physicians.length > 1) {
    //   this.center = {
    //     lat: lat,
    //     lng: long,
    //   };
    // }
    console.log('center', this.center);
  }
  loadMore() {
    if (!this.load_inprogress && this.page_number < this.total_pages) {
      this.page_number += 1;
      this.search(false, true);
    }
  }
  getlatLngBoundsFromBounds() {
    return {
      north: this.center.lat,
      east: this.center.lng,
      south: this.center.lat,
      west: this.center.lng,
      bounds: true
    }
  }


}
