<div class="main-wrapper">
  <ng-container *ngFor="let practice_setting of practice_settings">
    <h3 class="mt0" *ngIf="practice_setting.setting_type === 'office'">Office Visit Widget</h3>
    <h3 class="mt0" *ngIf="practice_setting.setting_type === 'virtual'">Virtual Visit Widget</h3>
    <hr class="hr-line mt-lg">
    <form #practiceSettingForm="ngForm" (ngSubmit)="save(practice_setting)" class="mb-xl">
      <div class="row">
        <div class="col-sm-3 col-xs-6 form-group">
          <label>Book Appointment By*</label>
          <select class="form-control" name="integration_flow" [(ngModel)]="practice_setting.integration_flow">
            <option>Select Flow</option>
            <option [value]="'Location'">Location</option>
            <option [value]="'Doctor'">Doctor</option>
          </select>
          <div class="label label-danger" *ngIf="customValidations.formErrors.integration_flow">
            {{ customValidations.formErrors.integration_flow }}
          </div>
        </div>
        <div class="col-sm-2 col-xs-6 form-group">
          <label>Text*</label>
          <input class="form-control" autocomplete="off" [value]="practice_setting.doctor_name_color"
                 [cpPosition]="'bottom'" [cpOutputFormat]="'hex'"
                 [(colorPicker)]="practice_setting.doctor_name_color" name="doctor_name_color"
                 [style.background]="practice_setting.doctor_name_color"/>
          <div class="label label-danger" *ngIf="customValidations.formErrors.doctor_name_color">
            {{ customValidations.formErrors.doctor_name_color }}
          </div>
        </div>
        <div class="col-sm-2 col-xs-6 form-group">
          <label>Buttons*</label>
          <input class="form-control" autocomplete="off" [value]="practice_setting.button_color"
                 [cpOutputFormat]="'hex'"
                 [(colorPicker)]="practice_setting.button_color" [cpPosition]="'bottom'" name="button_color"
                 [style.background]="practice_setting.button_color"/>
          <div class="label label-danger" *ngIf="customValidations.formErrors.button_color">
            {{ customValidations.formErrors.button_color }}
          </div>
        </div>
        <div class="col-sm-2 col-xs-6 form-group">
          <label>Buttons Hover*</label>
          <input class="form-control" autocomplete="off" [value]="practice_setting.hover_color" [cpPosition]="'bottom'"
                 [cpOutputFormat]="'hex'"
                 [(colorPicker)]="practice_setting.hover_color" name="hover_color"
                 [style.background]="practice_setting.hover_color"/>
          <div class="label label-danger" *ngIf="customValidations.formErrors.hover_color">
            {{ customValidations.formErrors.hover_color }}
          </div>
        </div>
        <div class="col-sm-2 col-xs-6 form-group" *ngIf="practice_setting.setting_type === 'office'">
          <label class="center-block">&nbsp;</label>
          <a *ngIf="show_office_loader" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
          <button *ngIf="!show_office_loader" class="btn btn-primary" type="submit" [disabled]="!practiceSettingForm.form.valid">Save</button>
        </div>
        <div class="col-sm-2 col-xs-6 form-group" *ngIf="practice_setting.setting_type === 'virtual'">
          <label class="center-block">&nbsp;</label>
          <a *ngIf="show_virtual_loader" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
          <button *ngIf="!show_virtual_loader" class="btn btn-primary" type="submit" [disabled]="!practiceSettingForm.form.valid">Save</button>
        </div>
      </div>
      <div *ngIf="message" class="p alert alert-success text-center">{{message}}</div>
    </form>
  </ng-container>
  <ng-container>
    <app-program-integration-index></app-program-integration-index>
    <h3 class="mt0">PMS Integration</h3>
    <hr class="hr-line mt-lg">
    <app-integration [practice]="practice" class="d-block"></app-integration>
    <app-twilio-configuration [practice]="practice"></app-twilio-configuration>
    <app-email-configuration [practice]="practice" class="mt0"></app-email-configuration>
  </ng-container>
</div>
