import { Component, OnInit } from '@angular/core';
import {LoaderService} from '../../services/loader.service';
import {ProgramService} from '../program.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {UserService} from '../../services/user.service';
import {DashboardData} from "./dashboard-data";

@Component({
  selector: 'app-program-dashboard',
  templateUrl: './program-dashboard.component.html',
  styleUrls: ['./program-dashboard.component.scss']
})
export class ProgramDashboardComponent implements OnInit {
  public filter: Object = {year: '', month: ''};
  profile_pic_url = 'http://public.aks.com:7005/system/attachments//documents/profile_pic/a61726f4fcee0a7cae899925b49411e26edd5326/Thomas.jpg?1592371453'
  selected_type  = 'month';
  data_available = false;
  duration = 3;
  public lineChartOptions: any = {
    maintainAspectRatio: false,
    responsive: true, legend: {position: 'bottom'}, scales: {
      yAxes: [{
        ticks: {
        }
      }]
    }, tooltips: {
      mode: 'label'
    }, layout: {
      padding: {
        left: 0
      }, margin: {left: 0}
    }
  };

  // lineChart
  public lineChartData: any[];
  public lineChartLabels: any[];
  public lineChartType = 'line';
  public dashboardData: DashboardData;
  public cmsData: any[] = [];
  public privateTableData: any[] = [];
  showTab = 'myTask';
  public selectMonth: any = new Date();
  public maxDate: any = new Date();

  public randomizeType(): void {
    this.lineChartType = this.lineChartType === 'line' ? 'bar' : 'line';
  }

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  change_duration(type, from_type?) {
    if (from_type && this.selected_type == type) {
      return;
    }
    this.selected_type = type;
      this.programService.programme_graph_data({months: this.duration, type: this.selected_type}).then(data => {
        this.data_available = false;
        this.lineChartData = data.data;
        this.lineChartLabels = data.keys;
        setTimeout(() => {
          this.data_available = true;
        }, 200);
      });
  }

  constructor(public loaderService: LoaderService, public programService: ProgramService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public userService: UserService) { }

  ngOnInit(): void {
    this.data_available = true;
    this.setMonthAndYear();
    this.getDashBoardData();
    this.initiateGraphData();
  }


  getCMSData() {
    this.loaderService.show();
    this.cmsData = [];
    this.privateTableData = [];
    this.programService.getCMSData(this.filter).then(resp => {
      this.cmsData = resp.cms;
      this.privateTableData = resp.private_programme;
      console.log(this.cmsData);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
      console.log(resp);
    });
  }

  getDashBoardData() {
    this.loaderService.show();
    this.programService.getDashboardData().then(resp => {
      this.dashboardData = resp;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
      console.log(resp);
    });
  }
  setMonthAndYear(e?) {
    this.filter['year'] = new Date(this.selectMonth).getFullYear();
    this.filter['month'] = new Date(this.selectMonth).getMonth() + 1;
    this.getCMSData();
    return this
  }
  initiateGraphData() {
    this.programService.programme_graph_data({months: this.duration, type: this.selected_type})
      .then(data => {
        this.data_available = false;
        this.lineChartData = data.data;
        this.lineChartLabels = data.keys;
        setTimeout(() => {
          this.data_available = true;
        }, 200);
      });
    this.lineChartData = [
      {
        'label': 'Initial',
        'data': [
          1,
          0,
          0,
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          1,
          1,
          0,
          0,
          0,
          1,
          1,
          1,
          0,
          0,
          2,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          23,
          1,
          1,
          0,
          9,
          0
        ]
      },
      {
        "label": "Follow Up",
        "data": [
          3,
          15,
          2,
          1,
          0,
          8,
          3,
          0,
          2,
          5,
          1,
          1,
          0,
          0,
          2,
          2,
          15,
          9,
          4,
          0,
          0,
          3,
          8,
          0,
          1,
          2,
          6,
          0,
          0,
          19,
          29,
          6,
          2,
          10,
          2
        ]
      }
    ]
    this.lineChartLabels = [
      'Sep-18',
      'Oct-18',
      'Nov-18',
      'Dec-18',
      'Jan-19',
      'Feb-19',
      'Mar-19',
      'Apr-19',
      'May-19',
      'Jun-19',
      'Jul-19',
      'Aug-19',
      'Sep-19',
      'Oct-19',
      'Nov-19',
      'Dec-19',
      'Jan-20',
      'Feb-20',
      'Mar-20',
      'Apr-20',
      'May-20',
      'Jun-20',
      'Jul-20',
      'Aug-20',
      'Sep-20',
      'Oct-20',
      'Nov-20',
      'Dec-20',
      'Jan-21',
      'Feb-21',
      'Mar-21',
      'Apr-21',
      'May-21',
      'Jun-21',
      'Jul-21'
    ]

  }
}
