import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PatientVerificationComponent} from '../../shared/patient-verification/patient-verification.component';
import {SignUpComponent} from '../routes/sign-up/sign-up.component';
import {SignupPresetupComponent} from '../signup-presetup/signup-presetup.component';
import {HomePageSignUpComponent} from '../../shared/sign-up/sign-up.component';
import {FormsModule} from '@angular/forms';
import {DateOfBirthComponent} from '../../shared/date-of-birth/date-of-birth.component';
import {StickySectionComponent} from '../../shared/sticky-section/sticky-section.component';
import {NgxMaskModule} from 'ngx-mask'
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RECAPTCHA_V3_SITE_KEY,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaV3Module
} from 'ng-recaptcha';
import {environment} from '../../../environments/environment';
@NgModule({
    imports: [
      RecaptchaModule,
      RecaptchaFormsModule,
      RecaptchaV3Module,
      CommonModule,
      FormsModule,
      NgxMaskModule.forRoot(),
      MatPasswordStrengthModule,
      MatFormFieldModule,
      MatSlideToggleModule,
      MatInputModule
    ],
  declarations: [
    PatientVerificationComponent,
    HomePageSignUpComponent,
    SignUpComponent,
    SignupPresetupComponent,
    DateOfBirthComponent,
    StickySectionComponent],
  exports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    PatientVerificationComponent,
    HomePageSignUpComponent,
    SignUpComponent,
    SignupPresetupComponent,
    DateOfBirthComponent,
    StickySectionComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.v3_siteKey,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.v2_siteKey,
      } as RecaptchaSettings
    }
  ]
})
export class RegistrationModule { }
