import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {PracticeReviewSetup} from './practice_review_setup';
import {  HttpParams } from '@angular/common/http';
import {LocationService} from '../services/location.service';
import {nullRemover} from '../services/nullRemover';
import {Patient} from '../health/doctor/patient';
import {PatientService} from '../services/patient.service';

@Injectable({
  providedIn: 'root'
})
export class PracticeReviewService {
  public patient: Patient = new Patient();

  constructor(public patientsService: PatientService, public _tokenService: CustomHttpClient) {
  }

  create(practice_review: PracticeReviewSetup): Promise<any> {
    return this._tokenService
      .post('practice_reviews/practice_review_setup', practice_review.to_params())
      .toPromise()
      .then(res => practice_review.load_from_json(res))
  }

  save(practice_review: PracticeReviewSetup): Promise<any> {
    // return this.create(practice_review);
    if (practice_review.is_new_record()) {

      return this.create(practice_review);
    }
    else {
      return this.update(practice_review);
    }
  }

  getPracticeSetup(practice_id: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('practice_id', practice_id);
    return this._tokenService
      .get('practice_reviews/practice_review_setup', {params: args})
      .toPromise()
      .then(res => res);
  }

  update(practice_review: PracticeReviewSetup) {
    return this._tokenService
      .put('practice_reviews/practice_review_setup/' + practice_review.id, practice_review.to_params())
      .toPromise()
      .then(response => response)
  }

  getReviews(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('practice_reviews/reviews', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          reviews: response.body.reviews,
          reviewsCount: response.body.reviewsCount,
          review_response_stats: response.body.review_response_stats,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  fetchPatient(id: number) {
    this.patientsService.getPatient(id).then(res => {
      this.patient = new Patient().load_from_json(res);
    })
  }

  saveFilters(filter: any) {
    filter['filter_for'] = 'review'
    filter['filter_type'] = 'review'
    return this._tokenService
      .post('saved_filters', filter)
      .toPromise()
      .then(res => res)
  }
  getSaveFilters(user_id: any) {
    let args = new HttpParams();
    args = args.set('user_id', user_id);
    args = args.set('filter_for', 'review');
    return this._tokenService
      .get('saved_filters', {params: args})
      .toPromise()
      .then(res => res);
  }

  markPracticeReviewRead() {
    return this._tokenService
      .put('practice_reviews/mark_practice_reviews_read', {})
      .toPromise()
      .then(response => response)
  }

  getReviewReport(filter: Object) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService
      .get('practice_reviews/review_report', {params: params})
      .toPromise()
      .then(res => res);
  }
}
