import {Injectable} from '@angular/core';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {AttachmentService} from '../../../services/attachments.service';
import {DocumentsService} from '../../../services/document.service';
import {DocumentType, PatientDocument} from '../../../health/patient-profile/patient-dashboard/chart/document';
import {AlertService} from '../../../_alert';

@Injectable({
  providedIn: 'root'
})
export class AssociateCarePlanWithChartService {
  public document: PatientDocument = new PatientDocument();
  public documentsType: DocumentType[];
  public successMessage = false;
  public statusMessage = '';

  constructor(public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public attachmentService: AttachmentService, public alertService: AlertService,
              public documentsService: DocumentsService) {
    this.getDocumentType();
  }
   getDocumentType() {
     this.documentsService.getDocumentsType().then(resp => {
       this.documentsType = resp.map(type => new DocumentType().load_from_json(type));
       console.log(this.documentsType)
     })
   }

  associateToMedicalChart(params: any): any {
    console.log('associateToMedicalChart method srvice')
    let el = params.template;
    el.nativeElement.style.height = el.nativeElement.style.offsetHeight;
    html2canvas(el.nativeElement, {
      width: el.nativeElement.offsetWidth,
      height: el.nativeElement.offsetHeight,
    }).then((canvas) => {
      let imgData = canvas.toDataURL('image/png');
      let imgWidth = 210;
      let pageHeight = 295;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let doc = new jsPDF('p', 'mm');
      let position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      let file = doc.output('blob', {filename: 'CarePlan.pdf'});
      this.statusMessage = 'Uploading Care Plan'
      //this.alertService.success('Uploading Care Plan..', this.alertService.options);
      this.uploadAttachment(file, params);
    });
  }

  uploadAttachment(file: any, params: any) {
    this.statusMessage = this.statusMessage + '.'
    //this.alertService.success('Uploading Care Plan..', this.alertService.options);
    let attachment = { fileType: params.fileType, file: file, title: params.title, description: params.description,
      attachable_type: params.attachable_type, attachable_id: 0, doctor_id: params.doctor_id}
    let callback = (p: any): void => {
      console.log(p);
    };

    let upload_callback = (data: any) => {
      if (data !== '') {
        file = null;
        this.setDocumentAttributes(JSON.parse(data).id, params);
        this.attachDocumentWithMedicalChart();
        this.loaderService.hide();
        this.alertService.success('Successfully added to Medical Chart.', this.alertService.options);
        this.successMessage = true;
        this.statusMessage = '';

        setTimeout(() => {
          this.successMessage = false;
        }, 10000)
        return true;
      }
      else {
        file = null;
        this.loaderService.hide();
      }
    };
    if (attachment.file == undefined) {
      this.globalErrorHandlerService.error = 'Please select a valid file.';
    }
    else {
      this.attachmentService.upload(attachment, callback, upload_callback);
    }
  }
  attachDocumentWithMedicalChart() {
    this.statusMessage = this.statusMessage + '..'
    //this.alertService.success('Uploading Care Plan...', this.alertService.options);
    this.documentsService.save(this.document).then(resp => {
      this.document.load_from_json(resp);
    }).catch((res: Response) => {
      this.globalErrorHandlerService.error = 'Please select a valid file.';
      this.loaderService.hide();
    });
  }

  setDocumentAttributes(attachment_id: any, params: any) {
    this.document.patient_id = params.patient_id;
    this.document.attachment_id = attachment_id;
    this.document.document_type_id = this.documentsType.filter(type => type.name == params.document_type)[0]['id'];
    if (params.doctor_id) {
      this.document.doctor_id = params.doctor_id;
    }
    this.document.date = params['date'] ? params['date'] : new Date().toString();
    this.document.name = params.title;
    if (params['sourceable_type']) {
      this.document.source_type = params['sourceable_type']
    }
    if (params['sourceable_id']) {
      this.document.source_id = params['sourceable_id']
    }
    return this.document
  }
}
