<section class="inner-public-banner sec-pad">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="text-container">
          <h1>Chronic Care Management</h1>
          <h4 class="sub-title">
            Streamline your CCM process, improve patient health outcomes and maximize revenue seamlessly with a scalable CCM platform.
          </h4>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="border-box shadow p-lg text-center mb-lg">
              <h2 class="text-primary m0 mb-lg h1">65%</h2>
              <p class="m0">CCM Enrollment <br>Conversion</p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="border-box shadow p-lg text-center mb-lg">
              <h2 class="text-primary m0 mb-lg h1">86%</h2>
              <p class="m0">Disease-based Care Plan Template</p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="border-box shadow p-lg text-center mb-lg">
              <h2 class="text-primary m0 mb-lg h1">50%</h2>
              <p class="m0">Increase in Care Manager Productivity</p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="border-box shadow p-lg text-center mb-lg">
              <h2 class="text-primary m0 mb-lg h1">83%</h2>
              <p class="m0">CCM Billing from Enrolled Patients</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <img class="img-responsive" src="/assets/img/public/service-ccm-bg.jpg"/>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container text-center">
    <h1 class="fw-500">Why WellWink Chronic Care Management?</h1>
    <p class="mb-xl">
      CCM platform automatically specifies eligible patients before their enrollment, documents medications,
      calculates the total time spent with patients by tracking calls & emails, and generates invoices for guaranteed reimbursement.
      The system sends alerts according to the patient’s situation and monitoring device results. Consequently, the
      providers can set the goals for their patients and keep track of their activities during the follow-up care period.
      Thus, the healing plan is set according to the severity of the patient’s condition.
    </p>
  </div>
  <div class="container-fluid">
    <img class="img-responsive" src="/assets/img/public/ccm-diagram_1.png"/>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <h5 class="text-center text-primary text-uppercase">PATIENTS THAT WE ENROLL?</h5>
    <h1 class="fw-500 text-center mb-xl">Eligibility Criteria for the Patient</h1>
  </div>
  <div class="container eligibility-icon">
    <div class="row">
      <div class="col-sm-3 col-xs-6 text-center mt-lg">
        <img class="img-responsive" src="/assets/img/public/llp-icon.png"/>
        <h4>Life Long Patient</h4>
      </div>
      <div class="col-sm-3 col-xs-6 text-center mt-lg">
        <img class="img-responsive" src="/assets/img/public/ibm-icon.png"/>
        <h4>Insured by Medicare</h4>
      </div>
      <div class="col-sm-3 col-xs-6 text-center mt-lg">
        <img class="img-responsive" src="/assets/img/public/more-chronic-icon.png"/>
        <h4>2 or more chronic conditions</h4>
      </div>
      <div class="col-sm-3 col-xs-6 text-center mt-lg">
        <img class="img-responsive" src="/assets/img/public/not-enrolled-icon.png"/>
        <h4>Not enrolled with another practitioner</h4>
      </div>
    </div>
    <hr>
    <ul class="list-group">
      <li class="list-group-item">
        <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
        Determine if the patient meets the eligibility criteria for getting enrolled in CCM program.
      </li>
      <li class="list-group-item">
        <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
        It depends on the provider to choose the diagnosed chronic conditions to bill for CCM.
      </li>
      <li class="list-group-item">
        <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
        The diagnosis is expected to last for at least 12 months.
      </li>
      <li class="list-group-item">
        <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
        As long as the specific requirements for the care management service type meet, there will be no
        limitations in the services to treat the patient.
      </li>
    </ul>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <h5 class="text-center text-primary text-uppercase">REIMBURSEMENT MECHANICS?</h5>
    <h1 class="fw-500 text-center">Step-by-Step CCM System</h1>
    <p class="text-center mb-xl">The following diagram shows the exact flow of the CCM system.</p>
  </div>
  <div class="container-fluid">
    <img class="img-responsive" src="/assets/img/public/ccm-steps.png"/>
  </div>
</section>
<section class="sec-pad cpt-billing">
  <div class="container">
    <h1 class="fw-500 text-center mb-xl">CPT Billing Codes and Reimbursement for CCM</h1>
    <h4>Non-Complex CPTs</h4>
    <div class="row col-height-same">
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99490</h4>
          <ul class="list-group">
            <li class="list-group-item">First 20 mins spent by any clinical staff or physician.</li>
            <li class="list-group-item">Billable once per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.00</span>
          <h2 class="text-primary m0 mb">$63.43</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99491</h4>
          <ul class="list-group">
            <li class="list-group-item">30 mins including first 20 mins, are spent by any clinical staff or physician.</li>
            <li class="list-group-item">Billable once per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.50</span>
          <h2 class="text-primary m0 mb">$85.80</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-12 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99439</h4>
          <ul class="list-group">
            <li class="list-group-item">
              If additional 20 mins (second and third) are spent after CPT-99490 becomes applicable, then CPT 99439 will
              also be applicable and they both can be billed together.
            </li>
            <li class="list-group-item">
              For non-complex care, providers can still bill up to 60 minutes of CCM care per patient per month,
              in 20-minute increments.
            </li>
            <li class="list-group-item">Billable once per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 0.70</span>
          <h2 class="text-primary m0 mb">$47.83</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
    </div>
    <h4>Complex CPTs</h4>
    <div class="row col-height-same">
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99487</h4>
          <ul class="list-group">
            <li class="list-group-item">60 mins are spent by any clinical staff or physician.</li>
            <li class="list-group-item">Billable once per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.81</span>
          <h2 class="text-primary m0 mb">$94.68</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99489</h4>
          <ul class="list-group">
            <li class="list-group-item">Additional 30 mins are spent after CPT-99487 becomes applicable then CPT 99489 will also be applicable and they both can be billed together.</li>
            <li class="list-group-item">Billable once per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.00</span>
          <h2 class="text-primary m0 mb">$139.68</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad bg-secondary">
  <div class="container">
    <div class="row">
      <div class="col-sm-9">
        <h5 class="text-white m0">FROM MINIMAL TO 24/7 CARE</h5>
        <h2 class="text-white">Put Your Health in the Hands of a Caring Expert</h2>
        <h4 class="text-primary m0">Discover the best in chronic care management program</h4>
      </div>
      <div class="col-sm-3">
        <h5 class="text-white">Online Schedule</h5>
        <a class="btn btn-primary btn-lg btn-outline btn-block fs16 text-uppercase" href="https://calendly.com/wellwink" target="_blank">Request A Demo</a>
      </div>
    </div>
  </div>
</section>
