import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {SettingsService} from '../../core/settings/settings.service';
import {User} from '../users/user';
import {CustomHttpClient} from '../../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {LoaderService} from '../../services/loader.service';
import {UserService} from '../../services/user.service';
import {AlertService} from '../../_alert';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  public strength = 0;
  public hashid: any;
  public passwordConfirmation: any;
  public new_password: any;
  public show_error = false;
  public user: User = new User();

  constructor(private  route: ActivatedRoute, private globalErrorHandlerService: GlobalErrorHandlerService,
              public settings: SettingsService, private _tokenService: CustomHttpClient,
              public loaderService: LoaderService, private userService: UserService,
              private alertService: AlertService,  private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.hashid = params['id'];
      if (this.hashid) {
        this.getUserData(this.hashid);
      }
    });
  }


  onSubmit(event) {
    if (this.strength !== 100) {
      this.globalErrorHandlerService.error = 'Password is not strong enough.';
      return;
    }
    let is_password_match = this.check_password_match();
    if (is_password_match) {
      this.loaderService.show();
      this.userService.setUserPassword(this.hashid, this.new_password, this.passwordConfirmation).then(resp => {
        this.disabledSaveButton(event);
        this.resetFields();
        this.loaderService.hide();
        this.router.navigate(['/'], {queryParams: {show_success_message: true}}).then(r => console.log(r));
      }).catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
    }

  }

  checkPasswordStrength($event: number) {
    this.strength = $event;
  }

  private getUser(id: number) {
    let args = new HttpParams();
    args = args.set('id', id.toString());
    return this._tokenService
      .get('users/user_for_set_password', { params: args })
      .toPromise()
      .then(response => response);
  }

  private getUserData(id: any) {
    this.loaderService.show();
    this.getUser(id).then(r => {
      this.user.load_from_json(r);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }


  check_password_match() {
    if (this.new_password === this.passwordConfirmation) {
      return true;
    } else {
      this.setTimeOutFailsMessage();
    }
  }

  setTimeOutFailsMessage(): any {
    this.show_error = true;
    setTimeout(() => {
      this.show_error = false;
    }, 3000)
  }

  private disabledSaveButton(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    target.getElementsByTagName('button')[0].setAttribute('disabled', true);
  }

  resetFields() {
    this.passwordConfirmation = undefined;
    this.new_password = undefined;
  }
}
