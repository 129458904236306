import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {Task} from '../newtask/task';
import {TaskService} from '../../../../services/task.service';
import {Subscription} from 'rxjs/index';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';

@Component({
  selector: 'app-tasksleftpanel',
  templateUrl: './tasksleftpanel.component.html',
  styleUrls: ['./tasksleftpanel.component.scss']
})
export class TasksleftpanelComponent implements OnInit {
  @Input() task_count: Object;
  @Input() filter: Object;
  @Output() updateTasks = new EventEmitter<any>();
  public assigned_to_me_in_progress_count: number;
  public assigned_to_me_completed_count: number;
  public assigned_to_me_not_started_count: number;
  public assigned_by_me_in_progress_count: number;
  public assigned_by_me_completed_count: number;
  public assigned_by_me_not_started_count: number;
  public assigned_to_me_count: number;
  public assigned_by_me_count: number;
  public tasks: Task[];
  public subscription: Subscription;
  constructor(public loaderService: LoaderService, private taskService: TaskService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
    this.subscription = this.taskService.reloadTasks.subscribe(item => {
      if (item) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
  }
  getTaskCount() {
    this.taskService.getTaskCount(this.filter).then(resp => {
      this.assigned_to_me_in_progress_count = (resp['assigned_to_me']['in_progress'] == undefined) ? 0 : resp['assigned_to_me']['in_progress'];
      this.assigned_to_me_completed_count = (resp['assigned_to_me']['completed'] == undefined) ? 0 : resp['assigned_to_me']['completed'];
      this.assigned_to_me_not_started_count = (resp['assigned_to_me']['not_started'] == undefined) ? 0 : resp['assigned_to_me']['not_started'];
      this.assigned_by_me_in_progress_count = (resp['assigned_by_me']['in_progress'] == undefined) ? 0 : resp['assigned_by_me']['in_progress'];
      this.assigned_by_me_completed_count = (resp['assigned_by_me']['completed'] == undefined) ? 0 : resp['assigned_by_me']['completed'];
      this.assigned_by_me_not_started_count = (resp['assigned_by_me']['not_started'] == undefined) ? 0 : resp['assigned_by_me']['not_started'];
      this.assigned_to_me_count = this.assigned_to_me_completed_count + this.assigned_to_me_not_started_count + this.assigned_to_me_in_progress_count;
      this.assigned_by_me_count = this.assigned_by_me_completed_count + this.assigned_by_me_not_started_count + this.assigned_by_me_in_progress_count;
    })
  }
  getTasks() {
    console.log(this.filter)
    if (this.filter['assigned_to'] && this.filter['assigned_to_me']) {
      this.tasks = [];
      this.updateTasks.emit({tasks: this.tasks, totalItems: 0, current_page: 1});
    }
    else {
      this.loaderService.show();
      this.taskService.getTasks(this.filter).then(resp => {
        this.tasks = resp.tasks.map(task => new Task().load_from_json(task));
        this.updateTasks.emit({tasks: this.tasks, totalItems: resp.total, current_page: resp.current_page});
        this.loaderService.hide();
      }).catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      });
    }
  }
  tasksAssignedToMe() {
    delete this.filter['page'];
    delete this.filter['assigned_by_me'];
    delete this.filter['status_id'];
    this.filter['assigned_to_me'] ? delete this.filter['assigned_to_me'] : this.filter['assigned_to_me'] = true;
    console.log(this.filter['assigned_to_me'])
    this.getTasks();
  }
  tasksAssignedToMeStatus(status) {
    delete this.filter['page'];
    delete this.filter['assigned_by_me'];
    if (this.filter['status_id'] === status && this.filter['assigned_to_me']) {
      delete this.filter['status_id'];
      delete this.filter['assigned_to_me'];
    }
    else {
      this.filter['assigned_to_me'] = true;
      this.filter['status_id'] = status;
    }
    console.log(this.filter)
    this.getTasks();
  }
  tasksAssignedByMe() {
    delete this.filter['page'];
    delete this.filter['assigned_to_me'];
    delete this.filter['status_id'];
    this.filter['assigned_by_me'] ? delete this.filter['assigned_by_me'] : this.filter['assigned_by_me'] = true;
    console.log('this.filter[\'assigned_by_me\']', this.filter['assigned_by_me'])
    this.getTasks();
  }
  tasksAssignedByMeStatus(status) {
    delete this.filter['page'];
    delete this.filter['assigned_to_me'];
    if (this.filter['status_id'] === status && this.filter['assigned_by_me']) {
      delete this.filter['status_id'];
      delete this.filter['assigned_by_me'];
    }
    else {
      this.filter['assigned_by_me'] = true;
      this.filter['status_id'] = status;
    }
    this.getTasks();
  }
}
