import { Component, OnInit} from '@angular/core';
import {EnoteService} from "../../services/enote.service";
import {LoaderService} from "../../services/loader.service";
import {BsModalService} from "ngx-bootstrap/modal";

import {CreateNoteComponent} from "../create-note/create-note.component";

@Component({
  selector: 'app-drafted-enote',
  templateUrl: './drafted-enote.component.html',
  styleUrls: ['./drafted-enote.component.scss']
})
export class DraftedEnoteComponent extends CreateNoteComponent implements OnInit {


  constructor(public _enote_service: EnoteService,public modalService: BsModalService,public loaderService: LoaderService) {
    super(_enote_service,modalService,loaderService);
  }

  ngOnInit() {
  }
}
