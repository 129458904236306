import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { PermissionsService, RoleType, Role}    from '../permissions/permissions.service';
import {TabsService} from '../../services/tabs.service'


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {


  role_types: RoleType[];
  roles: Role[];
  selected_menu: string;
  public role_type = '';
  public users_listing: any;

  constructor(private permissionService: PermissionsService, public tabs_Service: TabsService) {
    this.getRoleTypeAndRoles();
    this.tabs_Service.register('users_components');

  }

  ngOnInit() {
  }

  getRoleTypeAndRoles(): void {
    this.permissionService.userRoleTypes().then(permissions => {
      this.role_types = permissions.dynamic_role_types;
      this.roles = permissions.roles;
      this.role_type = this.role_types[0].name;
      this.selected_menu = this.role_types[0].name;
    });
  }

  setRoleType(id: number, tab: string): void {
    this.selected_menu = tab;
    this.role_type = tab;
    this.tabs_Service.deactivate_tab();
  }

  iconMapping(role_type_name): any {
    let iconMappingHash = { 'patient': 'fa icon-user', 'doctor': 'fa fa-user-md', 'practice': 'fa fa-hospital-o', 'group': 'fa fa-group', 'enterprise': 'fa fa-key'}
    let icon = iconMappingHash[role_type_name];
    return icon;
  }
}
