<div class="btn-group" dropdown  [insideClick]="true">
  <button #buttoncustomhtml id="button-custom-html" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
          aria-controls="dropdown-custom-html">Template <span class="caret"></span>
  </button>
  <div id="dropdown-custom-html" *dropdownMenu class="dropdown-menu animated slideInDown">
    <div class="input-group richText-template">
      <input name="specialty" [(ngModel)]="filter['token']"
             [typeahead]="templates" typeaheadWaitMs="500"
             [typeaheadAsync]="true"
             (typeaheadLoading)="changeTypeaheadLoadingTemplate($event)"
             (typeaheadNoResults)="changeTypeaheadNoResultsTemplate($event)"
             (typeaheadOnSelect)="typeaheadOnTemplateSelect($event.item)" [typeaheadOptionsLimit]="10"
             [typeaheadOptionField]="'name'" placeholder="Get Rich Text Template"
             class="form-control" autocomplete="off">
      <div class="input-group-addon" *ngIf="typeaheadLoadingTemplate"><i class="fa fa-spinner fa-spin"></i></div>
    </div>
    <div *ngIf="typeaheadNoResultsTemplate" class="text-danger fs12 pt-sm">No Results Found!</div>
  </div>
</div>

<div class="modal flex-modal fade" bsModal #confirmationModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="max-width:480px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="confirmationModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Rich Text Template</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-warning p-sm m0 text-center">
          Inserting a template will erase existing text in the body, are you sure you want to use a template?
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="exportRichTextTemplate(); this.confirmationModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="confirmationModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
