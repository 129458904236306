<div class="flex-body scroll-browser" [ngSwitch]="view" #scrollContainer>
  <mwl-calendar-month-view
    *ngSwitchCase="'month'"
    [viewDate]="viewDate"
    [events]="events"
    [tooltipTemplate]="tooltipTemplate"
    (eventClicked)="eventClicked($event.event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="'week'"
    [weekStartsOn]="weekStartsOn"
    [viewDate]="viewDate"
    [events]="events"
    [hourSegments]="2"
    [tooltipTemplate]="tooltipTemplate"
    [eventTitleTemplate]="title_template"
    (eventClicked)="eventClicked($event.event)"
    (hourSegmentClicked)="hourSegmentClicked($event)"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="'day'"
    [viewDate]="viewDate"
    [events]="events"
    [hourSegments]="2"
    [eventTitleTemplate]="title_template"
    [tooltipTemplate]="tooltipTemplate"
    (eventClicked)="eventClicked($event.event)"
    (hourSegmentClicked)="hourSegmentClicked($event)"
  >
  </mwl-calendar-day-view>
</div>

<ng-template #title_template let-event="event">
  <span class="status-color" [style.background]="event.meta.event?.status_color"></span>
  <small class="text-gray-dark">{{userService.removeUnderScore(event.meta.event?.event_type)}}</small>
  <h6 class="event-subject">{{event.meta.event.subject}}</h6>
</ng-template>

<div class="modal fade flex-modal" bsModal #newPatientEventModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="closePatientEventModel()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">{{patientEvent.id ? 'Update' : 'Create'}} Event</h4>
      </div>
      <div class="modal-body scroll-browser" *ngIf="show_new_patient_event_model">
        <app-patient-event [patientEvent]="patientEvent" (closePatientEventModel)="closePatientEventModel()"> </app-patient-event>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltipTemplate let-event="event">
  <div class="popover top">
    <div class="arrow"></div>
    <div class="popover-content">
      <app-health-calendar-event-tooltip [event]="event.meta.event"></app-health-calendar-event-tooltip>
    </div>
  </div>
</ng-template>

<div class="modal fade flex-modal" bsModal #viewPatientEventModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="closeViewEventModal()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Event</h4>
      </div>
      <app-health-calendar-event-view *ngIf="show_view_patient_event_model" [event]="patientEvent"
                                      class="scroll-browser"
                                      (editEvent)="editEvent($event)"
                                      [comments]="comments"
                                      (closeViewPopup)="closeViewEventModal()"
      ></app-health-calendar-event-view>
    </div>
  </div>
</div>
