import { Component, OnInit } from '@angular/core';
import {Call, Device} from '@twilio/voice-sdk';
import {PhoneService} from '../phone.service';
import baseApiUrl from './../../globals'

@Component({
  selector: 'app-phone-call',
  templateUrl: './phone-call.component.html',
  styleUrls: ['./phone-call.component.scss']
})
export class PhoneCallComponent implements OnInit {
  device: Device;
  token: string;
  in_call = false;
  incoming_call = false;
  from: string;
  baseUrl = baseApiUrl;
  call: Call;
  device_registered = false;
  output_selection = false;
  log = false;
  phone_number: any;
  input_volume_bar_width: string;
  input_volume_bar_background_color: string;
  output_volume_bar_width: string;
  output_volume_bar_background_color: string;
  client_name: any;
  STATUS = {'ringing': 'Ringing', 'open': 'Connected', 'connecting': 'Connecting', 'closed': 'Disconnected'}


  constructor(public phoneService: PhoneService) { }

  ngOnInit(): void {
    this.startupClient().then();
  }

  get callStatus() {
    let status =  this.call.status();
    return this.STATUS[status];
  }

  async startupClient() {
    console.log('Requesting Access Token...');
  if (!this.phoneService.token) {
    this.phoneService.getAuthToken().then(resp => {
      console.log('Got a token.');
      this.phoneService.token = resp.token;
      this.client_name = resp.identity;
      this.intitializeDevice();
    }).catch(err => {
      console.log(err);
      console.log('An error occurred. See your browser console for more information.');
    })
   } else {
    await this.intitializeDevice();
  }
  }
  async intitializeDevice() {
    this.log = true;
    this.device = new Device(this.phoneService.token, {
      // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
      // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
    });

    this.addDeviceListeners(this.device);

    // Device must be registered in order to receive incoming calls
    await this.device.register();
  }

  addDeviceListeners(device) {
    device.on('registered',  () => {
      console.log('Twilio.Device Ready to make and receive calls!');
      this.device_registered = true;
    });

    device.on('error',  (error) => {
      console.log('Twilio.Device Error: ', error.message);
    });
    device.on('connect', (conn) => {
      console.log('Successfully established call ! ', conn);
    });

    device.on('incoming', (call) => {
      this.call = call;
      console.log(`Incoming call from ${call.parameters.From}`);

      // show incoming call div and incoming phone number
      this.incoming_call = true;
      console.log(this.incoming_call);
      this.from = call.parameters.From;


      // add event listener to call object
      call.addListener('cancel', () => {
        this.device.disconnectAll();
        console.log('handledisconnected : ', call);
        console.log('Incoming call ended.');
        this.resetIncomingCallUI();
      });
    });

    device.audio.on('deviceChange', this.updateAllAudioDevices.bind(device));

    // Show audio selection UI if it is supported by the browser.
    if (device.audio.isOutputSelectionSupported) {
      this.output_selection = true;
    }
  }

  handleIncomingCall(call) {

  }

   acceptIncomingCall(call) {
    this.call.accept();

    // update UI
    console.log('Accepted incoming call.');
    this.in_call = true;
    // incomingCallAcceptButton.classList.add('hide');
    // incomingCallRejectButton.classList.add('hide');
    // incomingCallHangupButton.classList.remove('hide');
  }

  // REJECT INCOMING CALL

   rejectIncomingCall(call) {
    this.call.reject();

    console.log('Rejected incoming call');
    this.resetIncomingCallUI();
  }
  async makeOutgoingCall() {
    if (this.device) {
      await this.makeCall()
    } else {
      this.startupClient().then()
      await this.makeCall();
    }
  }
   async makeCall() {
    this.phoneService.calling = true;
    let params = {
      // get the phone number to call from the DOM
      phone: this.phoneService.phone_number,
    };

    if (this.device) {
      console.log(`Attempting to call ${params.phone} ...`);

      // Twilio.Device.connect() returns a Call object
      this.call = await this.device.connect({ params });
      // add listeners to the Call
      // "accepted" means the call has finished connecting and the state is now "open"
      this.call.on('accept', () => {
        console.log('Call in progress ...', this.call);
        this.phoneService.startTimer();
        this.phoneService.calling = false;
        this.phoneService.in_call = true;
      });
      this.call.on('cancel', () => {
        console.log('Call cancelled.');
        this.phoneService.in_call = false;
      });
      this.call.on('disconnect', () => {
        console.log('Call disconnected.');
        this.phoneService.stopTimer();
        this.phoneService.in_call = false;
      });
      this.call.on('error', (error) => {
        console.log('Call error occurred.', error);
        this.phoneService.in_call = false;
      });
      this.call.on('reject', () => {
        console.log('The call was rejected.');
      });
    } else {
      console.log('Unable to make call.');
    }
  }

  bindVolumeIndicators() {
    this.call.on('volume', (inputVolume, outputVolume) => {
      let inputColor = 'red';
      if (inputVolume < 0.5) {
        inputColor = 'green';
      } else if (inputVolume < 0.75) {
        inputColor = 'yellow';
      }

      this.input_volume_bar_width = Math.floor(inputVolume * 300) + 'px';
      this.input_volume_bar_background_color = inputColor;

      let outputColor = 'red';
      if (outputVolume < 0.5) {
        outputColor = 'green';
      } else if (outputVolume < 0.75) {
        outputColor = 'yellow';
      }

      this.output_volume_bar_width = Math.floor(outputVolume * 300) + 'px';
      this.output_volume_bar_background_color = outputColor;
    });
  }

  // HANG UP INCOMING CALL

   hangupIncomingCall() {
    this.hangup();

    this.resetIncomingCallUI();
  }
  hangup() {
    console.log('Hanging up ...');
    this.phoneService.in_call = false
    if (this.device) {
      this.device.disconnectAll();
      delete this.call;
    }
  }

  resetIncomingCallUI() {
    this.incoming_call = false;
    this.from = '';
  }


  async getAudioDevices() {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    this.updateAllAudioDevices.bind(this.device);
  }

  updateAllAudioDevices() {
    if (this.device) {
      this.updateSpeakerDevices(this.device.audio.speakerDevices.get());
      this.updateRingToneDevices(this.device.audio.ringtoneDevices.get());
    }
  }

  updateSpeakerDevices(selectedDevices) {
    this.device.audio.availableOutputDevices
      .forEach( (device, id) => {
      let isActive = selectedDevices.size === 0 && id === 'default';
      selectedDevices.forEach(d => {
        if (d.deviceId === id) {
          isActive = true;
        }
      });
    });
  }
  updateRingToneDevices(selectedDevices) {
    this.device.audio.availableOutputDevices
      .forEach( (device, id) => {
      let isActive = selectedDevices.size === 0 && id === 'default';
      selectedDevices.forEach(d => {
        if (d.deviceId === id) {
          isActive = true;
        }
      });
    });
  }

  closePopUp() {
    this.hangup();
    this.phoneService.hidden = true;
    this.phoneService.showSmallWindow = false;
    delete this.phoneService.source_id
    delete this.phoneService.phone_number
    delete this.phoneService.clock
  }
}
