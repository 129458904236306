import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import {HttpParams} from '@angular/common/http';
import {CustomHttpClient} from '../../services/custom-http-client.service';


@Injectable()
export class LeadService {
  private practicesByStageType = 'leads/get_leads_by_status';
  private setPracticeStageUrl = 'leads/accept_or_reject_practice';
  private setPracticeClientStatus = 'leads/set_practice_status';

    constructor(private _tokenService: CustomHttpClient) { }

  getPracticesByStageType(stage_type: number, current_page: string): any {
    let params = new HttpParams();
    params = params.set('stage_type', stage_type.toString());
    params = params.set('page', current_page);
    let url = this.practicesByStageType;

    return this._tokenService.getWithHeaders(url, {observe: 'response' , params: params, responseType: 'json' })
      .map(response => {
        return {
          leads: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
      .catch(this.handleError);
  }

  setPracticesStage(practice_id, stage_type, rejection_reason): any {
    let rejection_reason_for_practice = rejection_reason || '';
    let params = new HttpParams();
    params = params.set('practice_id', practice_id.toString());
    params = params.set('stage_request', stage_type.toString());
    params = params.set('rejection_reason', rejection_reason_for_practice.toString());
    let url = this.setPracticeStageUrl;

    return this._tokenService.get(url, { params: params })
      .map(response => response)
      .catch(this.handleError);
  }

  setPracticesStatusId(practice_id, status_id): any {
    let params = new HttpParams();
    params = params.set('practice_id', practice_id.toString());
    params = params.set('status_id', status_id.toString());
    let url = this.setPracticeClientStatus;

    return this._tokenService.get(url, { params })
      .map(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
