import {Component, OnInit, ViewChild} from '@angular/core';
import {ContactUsService} from '../contact-us.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss']
})
export class ContactUsFormComponent implements OnInit{
  @ViewChild('ContactForm') contactForm: NgForm;
  show_form = true;
  contact_us = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    comments: '',
    captcha:''
  }
  message = ''

  constructor(public contactUsService : ContactUsService) { }

  ngOnInit(): void {
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onSubmit(){
    this.contactUsService.sendEmail(this.contact_us).then(resp => {
        this.message =  resp.message;
        this.show_form = false;
      }
    )
  }
}
