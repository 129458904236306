import {Component, OnInit, Input, TemplateRef} from '@angular/core';
import {Control} from '../enote_models/control';
import {Term} from '../enote_models/term';
import {EnoteService} from '../../services/enote.service';
import {TermControlValue, TermControl} from '../enote_models/term_control';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {TermControlValueProperty} from '../enote_models/term_control_value_property';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss']
})
export class ControlComponent implements OnInit {
  @Input() term: Term;
  @Input() term_control: TermControl;
  public focus_control: Control;
  @Input() control_index: string;
  modalRef: BsModalRef;
  public token = '';
  public search_token = '';
  public first_data_source: Observable<any>;
  public second_data_source: Observable<any>;

  public term_control_value_properties: TermControlValueProperty[] = [];
  public normal_term_control_value_property: TermControlValueProperty;
  public abnormal_term_control_value_property: TermControlValueProperty;

  constructor(public _enote_service: EnoteService, private modalService: BsModalService) {
    this.first_data_source = Observable.create((observer: any) => {
      observer.next(this.normal_term_control_value_property.prop_value);
    }).pipe(
      mergeMap((token: string) => {
        return this._enote_service.term_search(this.normal_term_control_value_property.prop_value, 'general')
      })
    );
    this.second_data_source = Observable.create((observer: any) => {
      observer.next(this.abnormal_term_control_value_property.prop_value);
    }).pipe(
      mergeMap((token: string) => {
        return this._enote_service.term_search(this.abnormal_term_control_value_property.prop_value, 'general')
      })
    );
  }

  ngOnInit() {
    if (this.term_control.isDefualtTermControl()) {
      this.setTermControlValueProp();
    }
  }

  onChange(control_id) {
    console.log(control_id);
    let seletedControl = this._enote_service.default_controls.filter(d_control => d_control.id == control_id)[0];
    this.term.term_controls.forEach(t_control => {
      if (t_control.isDefualtTermControl()) {
         t_control._destroy = true;
      }
    })
    let new_term_control = new TermControl();
    new_term_control.control_id = seletedControl.id;
    new_term_control.control = new Control('', '').load_from_json(seletedControl);
    new_term_control.copyDefautlControlDefaultValue(new_term_control);
    this.term_control = new_term_control;
    this.term.term_controls.unshift(new_term_control);
    this.term_control_value_properties = [];
    this.setTermControlValueProp();
    console.log(this.term_control);
  }

  openModal(template: TemplateRef<any>) {
    if (this.term_control.isDefualtTermControl()) {
      this.setTermControlValueProp();
      this.modalRef = this.modalService.show(template);
    }
    console.log(this.term);
  }
  setTermControlValueProp() {
    if (this.term_control.term_control_value_properties.length > 0) {
      this.term_control_value_properties = this.term_control.term_control_value_properties;
      this.setNormalAbnormalValueProperties();
      return
    }
    this.term_control.term_control_values.forEach(term_control_value => {
      let term_control_value_property = new TermControlValueProperty();
      term_control_value_property.control_value_id = term_control_value.value.id;
      term_control_value_property.prop_type = 'text';
      term_control_value_property.value = term_control_value.value;
      this.term_control_value_properties.push(term_control_value_property);
      this.setNormalAbnormalValueProperties();
    });
    console.log(this.term_control);

  }
  setNormalAbnormalValueProperties() {
    this.term_control_value_properties.forEach(a => {
      if (a.value.is_normal == null || a.value.is_normal == undefined) {
        return;
      }
      if (a.value.is_normal) {
        this.normal_term_control_value_property = a;
        if (this.term_control.control.name.toLocaleLowerCase() === 'observation' && !a.term_id && !a.prop_value) {
          this.normal_term_control_value_property.prop_value = this.term.name;
        }
        if (this.term_control.control.name.toLowerCase() === 'present/absent' && !a.term_id && !a.prop_value) {
          this.normal_term_control_value_property.prop_value = this.term.name ? 'No' + ' ' + this.term.name : null;
        }
        if (this.term_control.control.name.toLowerCase() === 'normal/abnormal' && !a.term_id && !a.prop_value) {
          this.normal_term_control_value_property.prop_value = this.term.name ? this.term.name + ' ' + 'normal' : null;
        }
      }
      if (!a.value.is_normal) {
        this.abnormal_term_control_value_property = a;
        this.abnormal_term_control_value_property.enable_custom_control = true;
        if (this.term_control.control.name.toLowerCase() === 'observation' && !a.term_id && !a.prop_value) {
          this.abnormal_term_control_value_property.prop_value = this.term.name;
        }
        if (this.term_control.control.name.toLowerCase() === 'present/absent' && !a.term_id && !a.prop_value) {
          this.abnormal_term_control_value_property.prop_value = this.term.name;
        }
        if (this.term_control.control.name.toLowerCase() === 'normal/abnormal' && !a.term_id && !a.prop_value) {
          this.abnormal_term_control_value_property.prop_value = this.term.name ? this.term.name + ' ' + 'abnormal' : null;
        }

      }

    });
    this.term_control.term_control_value_properties = this.term_control_value_properties
  }
  handleCommaClick(term, control) {
    this._enote_service.focusSelectedControl();
    this.term.term_controls.map(term_control => term_control.control.is_focus = false);
    control.focusControl();
  }


  showCommaTillLastTerm(control_index) {
    return (this.term.controls.length - 1) == control_index
  }

  onBackSpaceDown(term_control) {
    if (term_control.control.isDefaultControl()) {
      return false;
    }
    if (term_control.id) {
      term_control._destroy = true;
    }
    else {
      let index = this.term.term_controls.indexOf(term_control);
      this.term.term_controls.splice(index, 1);
    }
    return false;
  }

  stopEditing($event) {
    return false;
  }

  handle_callback(item) {
    item.show_typeahead = false
  }

  public changeTypeaheadLoading(e: boolean): void {
  }

  public changeTypeaheadNoResults(e: boolean): void {
         console.log(e);
  }

  public typeaheadOnFirstSelect(e: any, term_control_value_property: any): void {
    term_control_value_property.prop_value = e.item.name;
    term_control_value_property.term_id = e.item.library_datum_id;
    console.log(e, term_control_value_property)
  }
  public typeaheadOnSecondSelect(e: any, term_control_value_property: any): void {
    term_control_value_property.prop_value = e.item.name;
    term_control_value_property.term_id = e.item.library_datum_id;
    console.log(e, term_control_value_property)
  }

  save(template: TemplateRef<any>) {
    this.term_control.term_control_value_properties = this.term_control_value_properties
    this.modalRef.hide();
  }
  cancel(template: TemplateRef<any>) {
    this.modalRef.hide();
  }
}
