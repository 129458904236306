import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneCallComponent } from './phone-call/phone-call.component';
import {PhoneService} from './phone.service';
import {FormsModule} from '@angular/forms';
import {NgxMaskModule} from "ngx-mask";





@NgModule({
  declarations: [PhoneCallComponent],
  imports: [
    CommonModule,
    FormsModule, NgxMaskModule
  ],
  exports: [
    PhoneCallComponent
  ],
  providers: [PhoneService]
})
export class VoiceCallModule { }
