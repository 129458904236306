<ng-container *ngIf="invoice">
  <div #invoiceRef class="flex-body scroll-browser invoice-ui">

    <div class="row">
      <div class="col-xs-6"><h2 class="mt mb">Invoice</h2></div>
      <div class="col-xs-6 text-right"><img src="assets/img/branding/logo.png" style="max-width:170px;" alt="WellWink"></div>
    </div>
    <div class="mt-lg mb-lg">
      <h3 class="fs22 mt0 mb fw-500"><label class="fw-500">Invoice#</label> {{invoice.invoice_number}}</h3>
      <h3 class="fs20 fw-400 m0 mb"><label class="fw-400">Billing Date</label> {{invoice.bill_date | date: 'shortDate'}}</h3>
      <h3 class="fs20 fw-400 m0"><label class="fw-400">Date due</label> {{invoice.due_date | date: 'shortDate'}}</h3>
    </div>
    <div class="row">
      <div class="col-xs-6">
        <h3 class="fs22">Wellwink LLC</h3>
        <p class="fs16 mb-sm">414 Hackensack Avenue,</p>
        <p class="fs16 mb-sm">Hachensack, New Jersey 07601</p>
        <p class="fs16 mb-sm">United States</p>
        <p class="fs16 mb-sm">+1 201-949-0209</p>
      </div>
      <div class="col-xs-3">
        <h3 class="fs22">Bill To</h3>
        <p class="fs16 mb-sm">{{invoice.payer_name}}</p>
        <p class="fs16 mb-sm">{{invoice.practice_name}}</p>
        <p class="fs16 mb-sm">{{invoice.practice_address}}</p>
        <p class="fs16 mb-sm">{{invoice.practice_phone | mask: '(000) 000-0000'}}</p>
        <p class="fs16 mb-sm">{{invoice.practice_email}}</p>
      </div>
      <div class="col-xs-3">
        <h3 class="fs22">Ship To</h3>
        <p class="fs16 mb-sm">{{invoice.payer_name}}</p>
        <p class="fs16 mb-sm">{{invoice.practice_name}}</p>
        <p class="fs16 mb-sm">{{invoice.practice_address}}</p>
        <p class="fs16 mb-sm">{{invoice.practice_phone | mask: '(000) 000-0000'}}</p>
      </div>
    </div>

    <h2 class="fs25">${{invoice.amount}} due {{invoice.due_date | date: 'shortDate'}}</h2>
    <p class="fs16">Software License and Services fee for the month of  {{invoice.bill_date | date: 'MMMM, y'}}</p>

    <table class="table mt-lg">
      <thead>
      <tr>
        <th class="text-center" style="width:50px;">Sr#</th>
        <th>Invoice detail</th>
        <th class="text-center" style="width:150px;">Quantity</th>
        <th class="text-right" style="width:150px;">Amount</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of invoice.invoice_items; let i = index">
        <td class="text-center">{{i + 1}}</td>
        <td>{{item.description}}</td>
        <td class="text-center">{{item.quantity}}</td>
        <td class="text-right">${{item.amount}}</td>
      </tr>
      <tr>
        <td colspan="3" class="text-right fw-500">Total</td>
        <td class="text-right">${{invoice.itemsTotalAmount}}</td>
      </tr>
      <tr>
        <td colspan="3" class="text-right fw-60">Discount</td>
        <td class="text-right">${{invoice.discount ? invoice.discount: 0}}</td>
      </tr>
      <tr>
        <td colspan="3" class="text-right"><h4 class="fw-500">Amount Payable</h4></td>
        <td class="text-right"><h4 class="fw-500">${{invoice.amount}}</h4></td>
      </tr>
      </tbody>
    </table>

    <p class="fs16">{{invoice.invoice_number}} . ${{invoice.amount}} due {{invoice.due_date | date: 'shortDate'}}</p>

  </div>

  <div class="flex-footer text-center pb">
    <ng-container *ngIf="!loading">
      <button class="btn btn-secondary btn-min mr-sm" (click)="loading = true; createPdf('print', invoiceRef)">Print</button>
      <button class="btn btn-inverse btn-min" (click)="createPdf('save',invoiceRef)">Download</button>
    </ng-container>
    <a *ngIf="loading" class="btn btn-default mr"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
  </div>

</ng-container>
