import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Term} from '../../../../../enote/enote_models/term';
import {MedicationSummaryService} from '../medication-summary/medication-summary.service';
import {GlobalErrorHandlerService} from '../../../../../services/global-error.service';
import {LoaderService} from '../../../../../services/loader.service';
import {Patient} from '../../../../doctor/patient';

@Component({
  selector: 'app-medication-summaries',
  templateUrl: './medication-summaries.component.html',
  styleUrls: ['./medication-summaries.component.scss']
})
export class MedicationSummariesComponent implements OnInit, OnChanges {
  public patient_summary: Object = {medications: [], problems: [], allergies: []};
  public medications_status: any;
  public allergies_status: any;
  public problems_status: any;
  @Input() patient: Patient;
  @Input() summary_of;
  @Input() intakeForm;

  constructor(public medicationSummaryService: MedicationSummaryService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService) { }

  ngOnInit(): void {
    if (this.intakeForm) {
      this.patientSummaryData(this.summary_of, 'current');
    }
  }

  ngOnChanges() {
    this.patientSummaryData('all', 'current');
  }

  patientSummaryData(summary_type, status) {
    this.medicationSummaryService.getPatientSummaryData(this.patient.id, status, summary_type).then(resp => {
      if (summary_type === 'all' || summary_type === 'medications') {
        this.medications_status = status;
        this.patient_summary['medications'] = resp['medications'].map(term => new Term('', '').load_from_json2(term));
      }
      if (summary_type === 'all' || summary_type === 'problems') {
        this.problems_status = status;
        this.patient_summary['problems'] = resp['problems'].map(term => new Term('', '').load_from_json2(term));
        this.sortPatientProblems();
      }
      if (summary_type === 'all' || summary_type === 'allergies') {
        this.allergies_status = status;
        this.patient_summary['allergies'] = resp['allergies'].map(term => new Term('', '').load_from_json2(term));
      }
      this.loaderService.hide();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  private sortPatientProblems() {
    let sortOrder = ['Primary-diagnosis', 'Principal-diagnosis', 'Secondary-diagnosis', null];
    this.patient_summary['problems'].sort(function (a, b) {
      return sortOrder.indexOf(a.diagnosis_type) - sortOrder.indexOf(b.diagnosis_type);
    });
  }
}
