<span contenteditable="false">
  <span [ngClass]="term_control.control.is_focus ? 'bg-gray' : '' " contenteditable="false">
    <span contenteditable="false">
      <span contenteditable="false"
            [class]="term_control.control.control_class"
            [id]="term_control.control.id+ '' + term.library_datum_id"
            [ngClass]="'control-'+term_control.control.id">{{term_control.control ? term_control.control.name : ''}}</span>
    </span>
  </span>
</span>
