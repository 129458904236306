import {
  Component,
  ViewChild,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {Room, LocalTrack, LocalVideoTrack, LocalAudioTrack, RemoteParticipant} from 'twilio-video';
import { CameraComponent } from '../camera/camera.component';
import { ParticipantsComponent } from '../participants/participants.component';
import { VideoChatService } from '../services/videochat.service';
import {UserService} from '../../services/user.service';
import {AppointmentService} from '../../services/appointment.service';
import {TabsService} from '../../services/tabs.service';
import {MessageService} from '../../services/message.service';
import {EnrolmentsService} from '../../program/enrolments.service';
import {Activity} from '../../program/activity';
import {TrackingService} from '../../services/tracking.service';

@Component({
  selector: 'app-video-panel',
  templateUrl: './video-panel.component.html',
  styleUrls: ['./video-panel.component.scss']
})
export class VideoPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('camera', { static: false }) camera: CameraComponent;
  @ViewChild('participants', { static: false }) participants: ParticipantsComponent;
  @Output() callEnded = new EventEmitter<Object>();
  public appointment_id: string;
  public patient_user_id: any;
  @Input() room_name: string;
  public audio = new Audio('../../../assets/audio/call.mp3');
  hideChat = true;

  constructor(
    public videoChatService: VideoChatService, public messageService: MessageService,
    public appointmentService: AppointmentService, public tabs_service: TabsService,
    public userService: UserService, public trackingService: TrackingService,
    public enrolmentsService: EnrolmentsService) { }

  async ngOnInit() {
    console.log('video call on init')
    this.videoChatService.camera = this.camera;
    this.videoChatService.loadAudio();
    this.appointmentService.patientStatusObservable.subscribe(val => {
      if (val.patient_user_ids?.includes(this.videoChatService.patient_user_id) ||
        val.user_id.toString() === this.videoChatService.patient_user_id.toString()) {
        this.videoChatService.portal_online_status = val.portal_online_status;
        this.videoChatService.mobile_online_status = val.mobile_online_status;
      }
    })
  }

  get activeRoom() {
    return this.videoChatService.activeRoom;
  }
  ngAfterViewInit(): void {
    console.log('video call view after init')
    this.videoChatService.camera = this.camera;
    if (!this.room_name) {
      this.patient_user_id = this.videoChatService.patient_user_id;
      if (this.userService.current_user.isPracticeUser) {
        this.messageService.selected_chatroom = null;
        this.messageService.initializeChatrooms().then(resp => {
          this.messageService.set_user_on_selection(this.videoChatService.patient_user_id);
        })
      }
    }
    if (this.room_name || this.patient_user_id) {
      this.videoChatService.room_name = this.room_name ? this.room_name : 'room_' + this.patient_user_id;
    }
    if (this.room_name) {
      this.onRoomChanged(this.room_name);
    }
  }

  ngOnDestroy(): void {
    console.log('i\'m called........');
    this.resetState();
    this.videoChatService.call_ended = false;
    this.videoChatService.inCall = false;
    this.videoChatService.patient_cancelled_call = false;
    this.videoChatService.localTracks?.forEach(t => {
      this.camera.closeStream(t);
    });
    this.camera?.closeCamera();
    this.camera?.closeCamera();
  }

  resetState(): void {
    this.videoChatService.countDown?.unsubscribe();
    this.videoChatService.countDown = undefined;
    this.videoChatService.counter = undefined;
    this.videoChatService.timer?.unsubscribe();
    this.videoChatService.timer = undefined;
  }

  async onLeaveRoom(call_ended?: Object) {
    this.videoChatService.activeRoom?.disconnect();
    this.videoChatService.activeRoom = null;
    this.participants.clear();
    await this.camera.initializePreview();
  }

  async onRoomChanged(roomName: string) {
    this.videoChatService.calling = true;
    if (this.userService.current_user.isPracticeUser) {
      await this.videoChatService.playAudio();
    }
    if (roomName) {
      if (!this.videoChatService.localTracks) {
        await this.camera.initializePreview();
      }
      console.log('local tracks', this.videoChatService.localTracks);
      await this.videoChatService
        .joinOrCreateRoom(roomName, this.videoChatService.localTracks).then(() => {
          this.videoChatService.answerTimer = setTimeout(() => {
            if (!this.videoChatService.callAnswered) {
              this.cancelCall(true);
            }
          }, 20000);

          this.videoChatService.activeRoom.on('participantConnected', () => {
            this.videoChatService.callAnswered = true; // Set flag to true when someone answers
            clearTimeout(this.videoChatService.answerTimer); // Clear the timer since the call is answered
          });

          if (!this.videoChatService.call_cancelled) {
            this.participants.initialize(this.videoChatService.activeRoom.participants);
            this.registerRoomEvents();
            if (this.videoChatService.patient_user_id) {
              this.videoChatService.broadcastRoom(this.patient_user_id);
            }
          }
        });
    }
  }

  onParticipantsChanged(_: boolean) {
    this.videoChatService.nudge();
    this.videoChatService.inCall = true;
    this.videoChatService.calling = false;

  }
  onParticipantsRemoved(_: boolean) {
  }

  private registerRoomEvents() {
    this.videoChatService.activeRoom
      .on('disconnected',
        (room: Room) => {
          console.log('room disconnected.');
          if (this.userService.current_user.isPatient) {
            room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track));
          }
          if (this.videoChatService.answerTimer) {
            clearTimeout(this.videoChatService.answerTimer)
          }
        })
      .on('participantConnected',
        (participant: RemoteParticipant) => {
          this.videoChatService.calling = false;
          this.participants.add(participant);
          this.videoChatService.pauseAudio();
        })
      .on('participantDisconnected',
        (participant: RemoteParticipant) => {
          console.log('participant disconnected');
          this.participants.remove(participant);
          this.cancelCall();
        })
      .on('dominantSpeakerChanged',
        (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
  }

  private detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach(el => el.remove());
    }
  }

  private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
    return !!track
      && ((track as LocalAudioTrack).detach !== undefined
        || (track as LocalVideoTrack).detach !== undefined);
  }

  cancelCall(broadCast?: boolean) {
    this.videoChatService.call_cancelled = true;
    this.videoChatService.call_ended = true;
    this.videoChatService.inCall = false;
    this.videoChatService.callAnswered = false;
    this.videoChatService.pauseAudio();
    if ( broadCast) {
      this.videoChatService.broadcastCallCancelled(this.videoChatService.patient_user_id).then(resp => {
        console.log(resp);
      })
    }
    this.videoChatService.timer?.unsubscribe();
    this.onLeaveRoom().then(r => {
      setTimeout(() => {
        this.resetState();
      }, 10000)
    });
    this.videoChatService.calling = false;
    if (this.videoChatService.source_type === 'ProgrammeEnrolment') {
      this.logTimeForPatientTime();
    }
  }

  toggleWindow() {
    this.videoChatService.showSmallWindow = !this.videoChatService.showSmallWindow;
  }

  closeVideoPanel() {
    this.videoChatService.call_cancelled = false;
    this.videoChatService.patient_user_id = null;
    this.messageService.selected_chatroom = null;
    this.videoChatService.pauseAudio();
    this.ngOnDestroy();
    this.videoChatService.showLayoutVideo = false;
    this.videoChatService.clock = 0;
    this.videoChatService.camEnable = true;
    this.videoChatService.micEnable = true;
  }

  toggleChat() {
    this.messageService.selected_chatroom.unread_count = 0;
    this.hideChat = !this.hideChat;
    if (!this.hideChat) {
      console.log(this.messageService.selected_chatroom);
      this.messageService.scrollToBottom.next(this.messageService.selected_chatroom);
    }
    this.messageService.restartUnreadCount(this.messageService.selected_chatroom?.id, true)
  }

  callAllowed() {
    return !this.showUserStatus() && this.userService.current_user.isPracticeUser &&
      this.messageService.selected_chatroom?.status_label?.toLowerCase() !== 'offline'
  }

  startCall() {
    this.videoChatService.start_call_message = false;
    this.videoChatService.call_cancelled = false;
    this.videoChatService.patient_cancelled_call = false;
    this.messageService.sendMessageObserver
      .next(
        {body: ` ${this.userService.current_user.full_name} started a call`, message_type: 'system', sub_type: 'call_started'}
      );
    this.onRoomChanged(this.videoChatService.room_name).then(r => console.log(r));

  }

  logTimeForPatientTime() {
    let add_activity: Activity = new Activity();
    add_activity.time_log_attributes.minutes = this.videoChatService.clock > 0 ? ( this.videoChatService.clock / 60 ) : '';
    add_activity.activiable_type = 'ProgrammeEnrolment';
    add_activity.activiable_id = this.videoChatService.source_id;
    add_activity.activity_type = 'video_call';
    add_activity.description = 'Video Call With ' + this.videoChatService.patient_full_name;
    this.enrolmentsService.addActivityAndLogTime(add_activity).then(resp => {
      this.enrolmentsService.reloadActivityList.next(1);
      this.enrolmentsService.reloadProgramEnrolmentTime.next(1);
      this.trackingService.enrollmentLogTime(resp.activiable_id, add_activity.time_log_attributes.minutes);

    }).catch(res => {
      console.log(res);
    })
  }

  showUserStatus(): boolean {
    return this.appointmentService.show_status_class(this.videoChatService.mobile_online_status) === 'offline' && this.videoChatService.portal_online_status === 'offline'
  }
}
