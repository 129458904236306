import {MultipartItem} from './multipart-item';
import baseApiUrl from './../../../globals'
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {HttpTokenService} from '../../../services/http.token.service';
export class MultipartUploader {
  public url: string;
  public authToken: string;
  public isUploading = false;
  public progress = 0;
  public isHTML5 = true;
  public timeout = 7200000;
  public op: any;
  public currentAuthData: any;

  constructor(public options: any, public globalErrorHandlerService: GlobalErrorHandlerService, private _tokenService: HttpTokenService) {

    // Object.assign(this, options);
    this.url = `${baseApiUrl}${options.path}`;
    this.authToken = options.authToken;
    this.currentAuthData = options.currentAuthData;
  }

  public uploadItem(item: MultipartItem, onprogress) {
    this.op = onprogress;
    console.debug('multipart-uploader.ts & uploadItem() ==>.');
    if (this.isUploading) {
      console.debug('multipart-uploader.ts & uploadItem() uploader is uploading now.');
    }
    this.isUploading = true;
    this._xhrTransport(item);
  }

  private _onBeforeUploadItem(item: any) {
    item._onBeforeUpload();
  }


  private _parseHeaders(headers: any) {
    let parsed: any = {}, key: any, val: any, i: any;

    if (!headers) {
      return parsed;
    }

    headers.split('\n').map((line: any) => {
      i = line.indexOf(':');
      key = line.slice(0, i).trim().toLowerCase();
      val = line.slice(i + 1).trim();

      if (key) {
        parsed[key] = parsed[key] ? parsed[key] + ', ' + val : val;
      }
    });

    return parsed;
  }

  private _transformResponse(response: any, headers: any): any {
    return response;
  }

  private _isSuccessCode(status: any) {
    return (status >= 200 && status < 300) || status === 304;
  }

  private _render() {
    // todo: ?
  }

  _xhrTransport(item: any) {
    console.debug('multipart-uploader.ts & _xhrTransport() ==>.');

    let xhr = item._xhr = new XMLHttpRequest();
    xhr.timeout = this.timeout;

    // if (item.formData.length === 0){
    //  throw new TypeError('Invalid form,form is empty.');
    // }

    this._onBeforeUploadItem(item);
    xhr.upload.onprogress = (event) => {
      console.log([event.loaded, event.total]);
      this.progress = (event.loaded * 100) / event.total;
      item.onProgress(this.progress);
      this.op(this.progress);
    };

    xhr.onload = () => {
      console.debug('multipart-uploader.ts & _xhrTransport.onload() ==>');
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      let response = this._transformResponse(xhr.response, headers);
      let gist = this._isSuccessCode(xhr.status) ? 'Success' : 'Error';
      let method = '_on' + gist + 'Item';
      (<any>this)[method](item, response, xhr.status, headers);
      this._onCompleteItem(item, response, xhr.status, headers);
    };

    xhr.onerror = () => {
      console.debug('multipart-uploader.ts & _xhrTransport.onerror() ==>');
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      let response = this._transformResponse(xhr.response, headers);
      this._onErrorItem(item, response, xhr.status, headers);
      // this._onCompleteItem(item, response, xhr.status, headers);
    };

    xhr.ontimeout = () => {
      console.debug('multipart-uploader.ts & _xhrTransport.ontimeout() ==>');
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      let response = this._transformResponse(xhr.response, headers);
      this._onErrorItem(item, response, xhr.status, headers);
      // this._onCompleteItem(item, response, xhr.status, headers);
    };

    xhr.onabort = () => {
      console.debug('multipart-uploader.ts & _xhrTransport.onabort() ==>');
      let headers = this._parseHeaders(xhr.getAllResponseHeaders());
      let response = this._transformResponse(xhr.response, headers);
      // this._onCancelItem(item, response, xhr.status, headers);
      this._onCompleteItem(item, response, xhr.status, headers);
    };

    xhr.open(item.method, this.url, true);
    xhr.withCredentials = item.withCredentials;

    if (this.authToken) {
      xhr.setRequestHeader('Authorization', this.authToken);
    }
    if (this._tokenService.userSignedIn()) {
      xhr.setRequestHeader('access-token', this._tokenService.currentAuthData.accessToken);
      xhr.setRequestHeader('client', this._tokenService.currentAuthData.client);
      xhr.setRequestHeader('uid', this._tokenService.currentAuthData.uid);
      xhr.setRequestHeader('token-type', this._tokenService.currentAuthData.tokenType);
      xhr.setRequestHeader('expiry', this._tokenService.currentAuthData.expiry);
    }
    console.debug('multipart-uploader.ts & _xhrTransport() send...');
    xhr.send(item.formData);
    this._render();
  }

  public onSuccessItem(item: any, response: any, status: any, headers: any) {
    // this.currentAuthData = {accessToken: headers['access-token'], uid: headers['uid'], client: headers['client'], tokenType: headers['token-type']};
  }

  public onErrorItem(item: any, response: any, status: any, headers: any) {
    this.isUploading = false;
  }

  public onCancelItem(item: any, response: any, status: any, headers: any) {
  }

  public onCompleteItem(item: any, response: any, status: any, headers: any) {
  }

  private _onSuccessItem(item: any, response: any, status: any, headers: any) {
    item._onSuccess(response, status, headers);
    this.onSuccessItem(item, response, status, headers);
  }

  public _onErrorItem(item: any, response: any, status: any, headers: any) {
    console.debug('multipart-uploader.ts & _onErrorItem() ==>' + ' Error status:' + status);
    item._onError(response, status, headers);
    this.onErrorItem(item, response, status, headers);
  }

  private _onCancelItem(item: any, response: any, status: any, headers: any) {
    item._onCancel(response, status, headers);
    this.onCancelItem(item, response, status, headers);
  }

  public _onCompleteItem(item: any, response: any, status: any, headers: any) {
    item._onComplete(response, status, headers);
    this.onCompleteItem(item, response, status, headers);
    if (status == 500) {
       this.globalErrorHandlerService.error = JSON.parse(response)['error'];
}
    this.isUploading = false;

    // this.progress = this._getTotalProgress();
    this._render();
  }
}
