import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {VisitsService} from '../../../../services/visits.service';
declare var html2pdf: any;
@Component({
  selector: 'app-print-inflow',
  templateUrl: './print-inflow.component.html',
  styleUrls: ['./print-inflow.component.scss']
})
export class PrintInflowComponent implements OnInit {
  @ViewChild('patientInFlow') el: ElementRef;
  public keys_array: any;
  constructor(public _visitService: VisitsService) { }
  ngOnInit() {
    this._visitService.visitAppointment(this._visitService.searchParams).then(visits => {
      this._visitService.visits = visits.filters;
      this._visitService.visits = this._visitService.generateKeyValue();
      this.keys_array = Object.keys(this._visitService.visits);
    });
  }

  ngOnChanges() {
  }

  generatePdf() {



    // var fs = require('fs');
    // //var pdf = require('./../../../front-end/node_modules/html2pdf.js');
    // var html = fs.readFileSync("sliderImages", 'utf8');
    // var options = { format: 'Letter' };
    //
    // html2pdf.create(html, options).toFile('./testora.pdf', function(err, res) {
    //   if (err) return console.log(err);
    //   console.log(res); // { filename: '/app/businesscard.pdf' }
    // });

    this._visitService.generatePdf(this.el);
  }

  visitsKeys() {
    if (this.keys_array && this.keys_array.length > 0) {
      return this.keys_array;
    }

  }

}
