<ng-container *ngIf="!viewTemplate">
  <editor class="flex-body p b0" style="height:100%;" #editor apiKey="no-api-key" id="{{richTextTemplate.id}}" name="template_body"
          [(ngModel)]="richTextTemplate.body"
          [init]="init_tiny" required>
  </editor>

  <div class="flex-footer text-center p-sm">
    <ng-container  *ngIf="!saving">
      <button class="btn btn-secondary btn-min mr" type="button" (click)="signRichTextTemplate(richTextTemplate, 'signed')">Sign</button>
      <button class="btn btn-tertiary btn-outline" type="button" (click)="saveAsDraft(richTextTemplate, 'drafted')">Save as Draft</button>
    </ng-container>
    <ng-container *ngIf="saving">
      <i class="fa fa-spinner fa-spin"></i>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="viewTemplate">
  <div class="p flex-body scroll-browser" [innerHTML]="richTextTemplate.body | safeHtml"></div>
  <div class="flex-footer p-sm">
    <label class="label label-primary fs12 fw-500 mr-sm">Signed by</label>
    <span class="text-capitalize" *ngIf="richTextTemplate?.modifier_name"> {{ richTextTemplate?.modifier_name }}</span>
    on {{richTextTemplate.last_modified_at | date: 'MMMM d, y, h:mm a' }}
    <button class="btn btn-secondary btn-min ml-xl" type="button" (click)="printRichText.emit('Print')">Print</button>
  </div>
</ng-container>
