import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {PublicContentPagesRoutingModule} from './public-content-pages-routing.module';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyAndPolicyComponent } from './privacy-and-policy/privacy-and-policy.component';
import { GDPRComponent } from './gdpr/gdpr.component';
import { PartnerComponent } from './partner/partner.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { WhyWellwinkComponent } from './why-wellwink/why-wellwink.component';
import { PublicMedicalCenterComponent } from './public-medical-center/public-medical-center.component';
import { PublicDoctorsComponent } from './public-doctors/public-doctors.component';
import { PublicPatientsComponent } from './public-patients/public-patients.component';
import { PublicAppointmentsBookingComponent } from './public-appointments-booking/public-appointments-booking.component';
import { PublicPatientCommunicationComponent } from './public-patient-communication/public-patient-communication.component';
import { PublicPracticeMarketingComponent } from './public-practice-marketing/public-practice-marketing.component';
import { PublicReputationManagementComponent } from './public-reputation-management/public-reputation-management.component';
import { PublicPatientPortalComponent } from './public-patient-portal/public-patient-portal.component';
import { PublicPatientRemoteMonitoringComponent } from './public-patient-remote-monitoring/public-patient-remote-monitoring.component';
import { PublicIntegrationComponent } from './public-integration/public-integration.component';
import { PublicTelemedicineComponent } from './public-telemedicine/public-telemedicine.component';
import { PublicPatientRemindersComponent } from './public-patient-reminders/public-patient-reminders.component';
import { PublicPartnerProgramComponent } from './public-partner-program/public-partner-program.component';
import {PublicServiceService} from './public-service.service';
import { SearchButtonComponent } from './search-button/search-button.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import {NgxMaskModule} from 'ngx-mask';
import { PublicChronicCareManagementComponent } from './public-chronic-care-management/public-chronic-care-management.component';
import { PublicRemoteTherapeuticMonitoringComponent } from './public-remote-therapeutic-monitoring/public-remote-therapeutic-monitoring.component';
import { PublicRemotePatientMonitoringComponent } from './public-remote-patient-monitoring/public-remote-patient-monitoring.component';
import {FaqsComponent} from "./faqs/faqs.component";
import { ContactUsFormComponent } from './contact-us/contact-us-form/contact-us-form.component';

@NgModule({
  declarations: [
    TermsAndConditionsComponent, PrivacyAndPolicyComponent,
    GDPRComponent, PartnerComponent,
    ContactUsComponent, WhyWellwinkComponent, FaqsComponent,
    PublicMedicalCenterComponent, PublicDoctorsComponent,
    PublicPatientsComponent, PublicAppointmentsBookingComponent,
    PublicPatientCommunicationComponent, PublicPracticeMarketingComponent,
    PublicReputationManagementComponent, PublicReputationManagementComponent,
    PublicPatientPortalComponent, PublicPatientPortalComponent,
    PublicPatientRemoteMonitoringComponent, PublicIntegrationComponent,
    PublicTelemedicineComponent, PublicPatientRemindersComponent, PublicPartnerProgramComponent,
    SearchButtonComponent,
    PublicChronicCareManagementComponent,
    PublicRemoteTherapeuticMonitoringComponent,
    PublicRemotePatientMonitoringComponent,
    ContactUsFormComponent
  ],
  imports: [
    CommonModule, FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxMaskModule,
    RouterModule,
    PublicContentPagesRoutingModule
  ],
  exports: [
    SearchButtonComponent
  ],
  providers: [PublicServiceService]
})
export class PublicContentPagesModule { }
