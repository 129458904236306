export class IntakeFormTemplate {
  id: number;
  name: any;
  creator_name: any;
  updater_name: any;
  created_at: any;
  steps: IntakeFromStep[] = [];
  created_by_id: any;
  updated_by_id: any;
  source_type: any;
  source_id: any;
  patient_name: any;
  patient_dob: any;
  patient_phone: any;
  send_date: any;
  requested_by_name: any;
  requested_by_id: any;
  status: any;
  practice_id: any;
  last_updated_at: any;
  pending_index_number: any;
  steps_names: string[] = [];
  patient_id: any


  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  to_params() {
    return {
      intake_form: {
        id: this.id,
        name: this.name,
        practice_id: this.practice_id,
        intake_steps_attributes: this.steps.map(step => new IntakeFromStep().load_from_json(step))
      }
    }
  }

  load_from_json(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.creator_name = json.creator_name;
    this.created_at = json.created_at;
    this.updater_name = json.updater_name;
    this.updated_by_id = json.updated_by_id;
    this.last_updated_at = json.last_updated_at;
    this.practice_id =json.practice_id;
    this.patient_id =json.patient_id;
    if (json.steps) {
      this.steps = json.steps.map(step => new IntakeFromStep().load_from_json(step));
    }
    if (json.intake_steps) {
      this.steps = json.intake_steps.map(step => new IntakeFromStep().load_from_json(step));
    }
    this.patient_name = json.patient_name;
    this.patient_dob = json.patient_dob;
    this.patient_phone = json.patient_phone;
    this.send_date = json.send_date;
    this.status = json.status;
    this.requested_by_name = json.requested_by_name;
    this.steps_names = json.steps_names;
    this.pending_index_number = json.pending_index_number;

    return this;
  }
}

export class IntakeFromStep {
  id: number;
  name: string;
  status: string;
  step_order: number;
  intake_template_id: number;
  created_at: Date;
  updated_at: Date;
  _destroy = false;

  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.status = json.status;
    this.step_order = json.step_order;
    this.intake_template_id = json.intake_template_id;
    this.updated_at = json.updated_at;
    this.created_at = json.created_at;
    this._destroy = json._destroy;
    return this;
  }

  to_params() {
    return {
      intake_form: {
        id: this.id,
        name: this.name,
        status: this.status,
        step_order: this.step_order,
        intake_template_id: this.intake_template_id,
        _destroy: this._destroy,
      }
    }
  }

}
