import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../services/tabs.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {

  constructor(public _tabs_service: TabsService) {
    this._tabs_service.register('templates');
  }

  ngOnInit() {
  }

}
