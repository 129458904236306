import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../../services/tabs.service';

@Component({
  selector: 'app-admissions',
  templateUrl: './admissions.component.html',
  styleUrls: ['./admissions.component.scss']
})
export class AdmissionsComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('admissions');

  }

  ngOnInit() {
  }

}
