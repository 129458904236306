import {Component, OnInit, Injector, ViewChild} from '@angular/core';
import {CaseService} from "../../../services/case.service";
import {Case} from "../case";
import {Note} from "./note";
import {NoteService} from "../../../services/note.service";
import {CustomValidations} from "../../customValidations/custom-validations";
import {NgForm} from "@angular/forms";
import {ModalDirective} from "ngx-bootstrap/modal";
import {GlobalErrorHandlerService} from "../../../services/global-error.service";

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  public admission: Case;
  public note = new Note();
  public case_id:number;
  @ViewChild('classicModal') classicModal: ModalDirective;

  constructor(private injector:Injector,private caseService:CaseService,
              private noteService:NoteService,public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit() {
    let id = this.injector.get('id', null);
    this.fetchCase(id);
  }

  fetchCase(id) {
    this.caseService.getCase(id).then(json => {
      this.admission = new Case().load_from_json(json);
      this.note.case_id = this.admission.id;
      this.case_id = this.note.case_id;
    })


  }
  addNote(){
    if(this.note.case_id){
    }else{
      this.note.case_id = this.case_id;
    }

    this.noteService.create(this.note).then(resp => {
     this.note =  this.note.load_from_json(resp);
      this.admission.notes.unshift(this.note);
      this.note = new Note();
      this.classicModal.hide();
    }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error );
  }
}
