import { Component, OnInit, Input } from '@angular/core';
import {Practice} from  '../practice'
import {PhoneService} from '../../../../voice-call/phone.service';
@Component({
  selector: 'app-practice-header',
  templateUrl: './practice-header.component.html',
  styleUrls: ['./practice-header.component.scss']
})
export class PracticeHeaderComponent implements OnInit {
  @Input() practice: Practice;
  constructor(public phoneService: PhoneService) {
  }

  ngOnInit() {
  }

}
