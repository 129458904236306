import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, Renderer2 } from '@angular/core';
import {Observable} from 'rxjs';
import {Term} from '../../../../enote/enote_models/term';
import {EnoteService} from '../../../../services/enote.service';
import {mergeMap} from 'rxjs/operators';
import {MessageService} from '../../../../services/message.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() public  term: any;
  @Output() public enter_pressed = new EventEmitter<Boolean>();


  @ViewChild('typeahead') typeahead: ElementRef;
  @ViewChild('text_box') text_box: ElementRef;
  @Output() removeTypeAhead: EventEmitter<any> = new EventEmitter<any>();
  public data_source: Observable<any>;
  public token = '';

  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public recently_notified =  false;
  @Input() public  literals;
  public taggable_users = [{name: 'name'}]
  @Output() on_select: EventEmitter<Term> = new EventEmitter<Term>();

  @ViewChildren('literal_ref') components: QueryList<ElementRef>;

  ngAfterViewInit() {
    this.focusLiteral(0);
  }
  constructor(private renderer: Renderer2, public _messageService: MessageService) { }

  ngOnInit() {
    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).mergeMap((token: string) => this._messageService.getTaggable({token: token}));
  }

  ngOnChanges() {
    this.token = '';
    this.focusLiteral(Math.max(this.literals.length - 1, 0));
  }

  check_keyup(event, literal) {
    this.notifyTyping();
    if (event.key == '@') {
      literal.show_typeahead = true;
      this.term.focus_on_init = true;
      this.focusTypeHead();
      event.preventDefault();
      return  false;
    } else {
    return true;
    }
  }

  onPaste(e) {
    e.preventDefault();
    const text = (e.originalEvent || e).clipboardData.getData('text/plain');
    window.document.execCommand('insertText', false, text);
  }
  notify_submit() {
    this.enter_pressed.emit(true);
    setTimeout( () => {
      this.focusLiteral(0);
    }, 300);
    return false;
  }
  public checkRemoval(event, literal, i) {
    this.notifyTyping();
    if (event.key == 'Backspace' && this.token.length == 0) {
      literal.show_typeahead = false;
      this.removeTypeAhead.emit(true);
      this.focusLiteral(i);
      // this.focusOpenText();
    }
  }

  focusTypeHead() {
    setTimeout(() => {
      this.typeahead.nativeElement.focus();
    }, 100);
   };

  focusLiteral(index) {
    setTimeout(() => {
      if (this.components.toArray().length < index + 1) {
        return
      }
      this.components.toArray()[index].nativeElement.focus();
      // select all the content in the element
      document.execCommand('selectAll', false, null);
// collapse selection to the end
      document.getSelection().collapseToEnd();
    }, 100);
  }

  typeaheadOnSelect(event, literal, index) {
    let taggable_literal = {text: '', show_typeahead: false , freetext: false, taggable: event.item, contenteditable: false, type_text: ''}
    let freetext_literal = {text: '', show_typeahead: false , freetext: true, taggable: {}, focused: true, contenteditable: false, type_text: ''}
    literal.show_typeahead = false;
    this.token = '';
    literal.type_text = '';
    this.literals.splice(index, 0, freetext_literal);
    this.literals.splice(index, 0, taggable_literal);
    console.log('in typehead SELECTION');
    this.focusLiteral(index + 1);
  }

  stopEditing(event) {
    return false;
  }


  blurLiteral(index) {
    this.literals[index].contenteditable = false;
  }

  notifyTyping() {
    if (!this.recently_notified) {
      this.recently_notified = true;
      this._messageService.notifyTyping().subscribe(r => {
      });
      setTimeout(() => {
        this.recently_notified = false;
      }, 2000);
    }
  }

  name_clicked(index) {
    this.literals[index].contenteditable = true;
    this.focusLiteral(index);
  }

  onFreeTextBackSpaceDown(index) {
    if (this.literals[index] && this.literals[index].text.length == 0 && index > 0) {
      if (!this.literals[index - 1].freetext) {
      this.name_clicked(index - 1);
      }
      else {
        this.literals.splice(index, 1);
        this.focusLiteral(index - 1);
      }
    }
  }

  onBackSpaceDown(event, index) {
    this.literals.splice(index, 1);
    this.focusLiteral(index);
    if (this.literals[index] && this.literals[index].text.length == 0 && this.literals.length > 1) {
      this.literals.splice(index, 1);
      this.focusLiteral(index - 1);
    }
  }

  inputClicked(event) {
    if (this.text_box && this.text_box.nativeElement.id == event.target.id) {
    this.focusLiteral(Math.max(this.literals.length - 1, 0));
    }
  }
  typeheadEnter(event) {
    console.log('in typehead enter');
    setTimeout(() => {
      this.token = '';
      this.focusLiteral(Math.max(this.literals.length - 1, 0));
    }, 200);
  }

}
