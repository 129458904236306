import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../services/tabs.service';

@Component({
  selector: 'app-reviews-dashboard',
  templateUrl: './reviews-dashboard.component.html',
  styleUrls: ['./reviews-dashboard.component.scss']
})
export class ReviewsDashboardComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('Reviews');
  }

  ngOnInit() {
  }
}
