import { Component, OnInit, Input } from '@angular/core';
import {AppointmentService} from '../../../../services/appointment.service';
import {Appointment} from '../../../../health/models/appointment';
import {TabsService} from '../../../../services/tabs.service';
import {Tab} from '../../../../tabs/tab';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {PhoneService} from '../../../../voice-call/phone.service';
@Component({
  selector: 'app-todays-patients-listing',
  templateUrl: './todays-patients-listing.component.html',
  styleUrls: ['./todays-patients-listing.component.scss']
})
export class TodaysPatientsListingComponent implements OnInit {

  @Input() appointments: Appointment[];
  @Input() totalItems;
  @Input() pagination;
  @Input() practice;
  @Input() providers;
  @Input() app_type: string;
  public selected_appointment_id: any;
  public show_doctor_dropdown = false;
  public appointment: Appointment;

  public values: string[] = ['cancelled', 'scheduled', 'confirmed', 'checkedin', 'rescheduled', 'noshow', 'checkedout', 'admitted']
  public color_code: string;
  constructor(private _tabs_service: TabsService,
              public _appointment_service: AppointmentService,
              public phoneService: PhoneService) { }

  ngOnInit() {
    this._appointment_service.is_schedular = false;
    this._appointment_service.patientStatusObservable.subscribe(val => {
      this.appointments?.map( app => {
        console.log(app.patient_id, val);
        if (val.user_id === app.patient.user.id) {
          app.portal_online_status = val.portal_online_status;
          app.mobile_online_status = val.mobile_online_status;
          console.log('appointment', app);
        }
      })
    })
  }

  ngOnChanges() {
  }

  iconMapping(index) {
    let status = this.values[index];
    return status;
  }

  capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  wait_class(ticker) {
    let waiting_time = Number(Math.ceil(ticker / 15));
    if (ticker > 60) {
      return '#ff0000';
    }
    let val = {0: '#008000' , 1: '#008000', 2: '#e5e500' , 3: '#FF8C00', 4: '#ff0000', 5: '#ff0000'}
    let color_val = val[waiting_time];
    this.color_code = color_val;
    return this.color_code;
  }

  change_status(appointment) {
    this._appointment_service.change_status(appointment.id, appointment.status_id, '').then(res => {
       appointment = appointment.load_from_json(res.appointment);
      let itemIndex = this.appointments.findIndex(item => item.id == appointment.id);
       this.appointments[itemIndex] = appointment;
    });
  }

  split_time(data, data_type) {
    if (data_type == 'date') {
    return data.split(' ')[1];
    }
    if (data_type == 'timeformat') {
    return data.split(' ')[2];
    }
  }

  add_to_tabs(appointment) {
    let title = appointment.patient_name() + '-' + 'Profile';
    let id = appointment.patient_id;
    this._tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id, {id: id, appointment: appointment}))
  }
  providerSelected(doctorID) {
    this._appointment_service.setDoctorID(this.selected_appointment_id, doctorID).then(resp => {
      this.appointments.filter(appointment => appointment.id == this.selected_appointment_id)[0].load_from_json(resp);
      this.show_doctor_dropdown = !this.show_doctor_dropdown;
    })
  }
  showDropDown(appointment) {
    if (appointment.appointment_with == 'doctor' || !appointment.appointment_with) {
      return true;
    }
    this.show_doctor_dropdown = !this.show_doctor_dropdown;
    this.selected_appointment_id = appointment.id;
  }
}
