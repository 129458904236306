export class Goal {
  id: number;
  achiever_id: number;
  achiever_type: any;
  assigned_by: any;
  created_at: any;
  description: string;
  name: string;
  progress = 0;
  source_id: number;
  source_type: string;
  status: string;
  achiever_name: string;
  assigned_by_name: string;
  comment_count: number;
  patient_id: number;
  care_plan_name = 'Custom'
  care_plan_id: string;


  load_from_json(json) {
    this.id = json.id;
    this.care_plan_id = json.care_plan_id;
    this.care_plan_name = json.care_plan_name;
    this.achiever_id = json.achiever_id;
    this.achiever_type = json.achiever_type;
    this.assigned_by = json.assigned_by;
    this.created_at = json.created_at;
    this.description = json.description;
    this.name = json.name;
    this.progress = json.progress;
    this.source_id = json.source_id;
    this.source_type = json.source_type;
    this.status = json.status;
    this.achiever_name = json.achiever_name;
    this.assigned_by_name = json.assigned_by_name;
    this.comment_count = json.comment_count;
    return this
  }

  to_params() {
    return {
      goal: {
        id: this.id,
        name: this.name,
        progress: this.progress,
        care_plan_name: this.care_plan_name,
        care_plan_id: this.care_plan_id,
        achiever_id: this.achiever_id,
        achiever_type: this.achiever_type,
        source_id: this.source_id,
        source_type: this.source_type,
        status: this.status,
        description: this.description,
      }
    }
  }
}
