import {Control, Value} from './control';
import {TermControlValueProperty} from './term_control_value_property';
export class TermControl {
    id: number;
    base_term_id: number;
    control_id: number;
    term_control_values: TermControlValue[] = [];
    _destroy = false;
    control: Control;
    term_control_value_properties: TermControlValueProperty[] = [];
    to_params(index?) {
        return{
            id: this.id,
            control_id: this.control_id,
            d_order: index,
            base_term_id: this.base_term_id,
            _destroy: this._destroy,
            enote_base_term_control_values_attributes: this.term_control_values.map(term_control_value => term_control_value.to_params()),
            enote_base_term_control_value_properties_attributes: this.term_control_value_properties.map(prop => prop.to_params())

        }
    }

    load_from_json(json) {
        this.id = json.id;
        this.base_term_id = json.base_term_id;
        this.control_id = json.control_id;
        if (json.control) {
        this.control =  new Control('', '').load_from_json(json.control);
        }
        if (json.term_control_values) {
        this.term_control_values = json.term_control_values.map(term_control_value => new TermControlValue().load_from_json(term_control_value));
        }
        this.term_control_value_properties = json.term_control_value_properties.map(a => {

          let b = new TermControlValueProperty().load_from_json(a);

          let term_control_value  = this.term_control_values.filter(a => a.value.id == b.control_value_id)[0];

          if (term_control_value) {
            a.value = term_control_value.value;
          }
          return b;
        });
        return this;
    }

  copy_term_control(json) {
    this.control_id = json.control_id;
    if (json.control) {
      this.control =  new Control('', '').load_from_json(json.control);
    }
    if (json.term_control_values) {
      this.term_control_values = json.term_control_values.map(term_control_value => new TermControlValue().copy(term_control_value));
    }
    this.term_control_value_properties = json.term_control_value_properties.map(a => {

      let b = new TermControlValueProperty().copy_properties(a);

      let term_control_value  = this.term_control_values.filter(a => a.value.id == b.control_value_id)[0];

      if (term_control_value) {
        a.value = term_control_value.value;
      }
      return b;
    });
    return this;
  }
    get recent_value_name() {
        if (this.control.isDefaultControl()) {
            return 'default';
        }
        if (this.term_control_values.length > 0) {
            return this.term_control_values.filter(term_control_value => term_control_value.recent_value)[0].value.name;
        }
        else {
            return this.control.name;
        }

    }

   get selectedTermControlValue() {
      let a =  this.term_control_values.filter(a => a.recent_value)[0];
     return a;
   }

   get selectedValueProperties() {
      if (!this.selectedTermControlValue) {
        return [];
      }
      let a = this.term_control_value_properties.filter(a => a.control_value_id == this.selectedTermControlValue.control_value_id)
      return a;
   }

  public isInputText() {
    return this.control.native_type === 'input_text';
  }
  public isDefualtTermControl() {
    return this.control.isDefaultControl();
  }


  public copyDefautlControlDefaultValue(compTermControl) {
    compTermControl.control.values.forEach(value => {
      let term_control_value = new TermControlValue();
      term_control_value.control_value_id = value.id;
      term_control_value.val_name = value.name;
      if (value.default_value_flag) {
        term_control_value.recent_value = true;
      }
      term_control_value.value = term_control_value.value.load_from_json(value);
      this.term_control_values.push(term_control_value);
    } )

  }

  get textProperty() {
    let a =  this.selectedValueProperties.filter(a => a.is_text)[0];
    return a
  }

  get colorProperty() {
    let a =  this.selectedValueProperties.filter(a => a.is_color)[0];
    return a
  }

  get custom_text() {
    return this.textProperty ? this.textProperty.prop_value : null
  }
  get color() {
    return this.colorProperty ? this.colorProperty.prop_value : this.selectedTermControlValue && this.selectedTermControlValue.color
  }

  reIntiazlizeTermControlValues() {
    this.term_control_values = []
  }
  resetDefaultContol() {
    this.term_control_values.forEach(a => {
      if (a.value.default_value_flag) {
        a.recent_value = true;
      }
      else {
        a.recent_value = false
      }
    })
  }

}
export class TermControlValue {


    term_control_value_properties: TermControlValueProperty[] = [];
    id: number;
    control_value_id: number;
    term_control_id: number;
    recent_value: boolean;
    val_name: string;
    output_text: string;
    value: Value = new Value();
    name: string;
    to_params() {
        return{
            id: this.id,
            control_value_id: this.control_value_id,
            term_control_id: this.term_control_id,
            val_name: this.value.name ? (this.setValName(this.value.name) ? this.val_name : this.value.name) : this.val_name,
          /*this conditions will only work when we are editing patient summary free text / onset control value */
            recent_value: this.recent_value
        }
    }
    load_from_json(json) {
        this.id = json.id;
        if (json.output_text) {
          this.output_text = json.output_text;
        }
        this.val_name = json.val_name;
        this.recent_value = json.recent_value;
        this.control_value_id = json.control_value_id;
        this.term_control_id = json.base_term_control_id;
        if (json.value) {
          this.value = this.value.load_from_json(json.value);
        }
        return this;
    }
  load_from_json_without_id(json) {
    if (json.output_text) {
      this.output_text = json.output_text;
    }
    this.val_name = json.name === 'Free-Text' ? '' : json.name;
    this.recent_value = json.recent_value;
    this.control_value_id = json.id;
    this.term_control_id = json.base_term_control_id;
    if (json.values) {
      this.value = this.value.load_from_json(json.value);
    }
    return this;
  }

  copy(json) {
    if (json.output_text) {
      this.output_text = json.output_text;
    }
    this.val_name = json.val_name;
    this.recent_value = json.recent_value;
    this.control_value_id = json.control_value_id;
    if (json.value) {
      this.value = this.value.load_from_json(json.value);
    }
    return this;
  }
    get color() {
      return this.value.property.color;
    }

    is_normal_value() {
      return this.value.is_normal;
    }

  public setValName(name: string) {
    return (name === 'Free-Text' || name === 'Onset')
  }
}
