<div class="flex-control flex-column flex-height view-campaign">
  <div class="flex-header">
    <div class="media">
      <div class="media-left media-middle"><h4 class="m0 lhn">{{campaign.name}}</h4></div>
      <div class="media-right media-middle"><span class="label label-success text-capitalize fs13">{{campaign.status}}</span></div>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <ul class="list-unstyled border-list m0">
      <ng-container *ngIf="campaign.outreach_type === 'email'">
        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-doc fs14 vl-m mr"></i>
              <label class="field-label">Subject</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400">{{campaign.subject}}</h5>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-doc fs14 vl-m mr"></i>
              <label class="field-label">Preview Text</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400">{{campaign.preview_text}}</h5>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-envelope fs14 vl-m mr"></i>
              <label class="field-label">Reply to</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400">{{campaign.reply_to}}</h5>
          </div>
        </li>
      </ng-container>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Contact type</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{campaignService.removeUnderScore(campaign.recipient_type)}}</h5>
        </div>
      </li>
      <ng-container *ngIf="campaign.recipient_type === 'patients'">
      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Selection Criteria</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{campaignService.removeUnderScore(campaign.patient_selection)}}</h5>
        </div>
      </li>
      <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-location-pin fs14 vl-m mr"></i>
              <label class="field-label">Locations</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400" *ngIf="campaign.location_type === 'location_not_applicable'">All Locations</h5>
            <h5 class="media-body media-middle m0 fw-400" *ngIf="campaign.location_type !== 'location_not_applicable'">{{campaign.locations_name}}</h5>
          </div>
        </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa fa-stethoscope fs14 vl-m mr"></i>
            <label class="field-label">Doctors</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400" *ngIf="campaign.doctor_type === 'doctor_not_applicable'">All Doctors</h5>
          <h5 class="media-body media-middle m0 fw-400" *ngIf="campaign.doctor_type !== 'doctor_not_applicable'">{{campaign.doctors_names}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.patient_review_type !== 'patient_with_ratings' && campaign.patient_review_type !== 'patient_with_tags'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Patient Reviews</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400" *ngIf="campaign.patient_review_type === 'review_not_applicable'">All</h5>
          <h5 class="media-body media-middle m0 fw-400" *ngIf="campaign.patient_review_type !== 'review_not_applicable'">{{campaignService.removeUnderScore(campaign.patient_review_type)}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.patient_review_type === 'patient_with_tags'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Patients With Tags</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{campaign.tag_names}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.patient_review_type === 'patient_with_ratings'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-star fs14 vl-m mr"></i>
            <label class="field-label">Patient Ratings</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{campaign.ratings_name}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.demographic_type === 'demographic_not_applicable'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Demographic</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">All</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.demographic_type === 'state'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">State</label>
          </div>
          <h5 class="media-body media-middle m0 text-capitalize fw-400">{{campaign.state_names}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.demographic_type === 'city'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Cities</label>
          </div>
          <h5 class="media-body media-middle m0 text-capitalize fw-400">{{campaign.cities_names}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.demographic_type === 'zip_code'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Zip Code</label>
          </div>
          <h5 class="media-body media-middle m0 text-capitalize fw-400">{{campaign.zip_code_names}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Reason of Visit</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400" *ngIf="campaign.service_type === 'service_not_applicable'">All</h5>
          <h5 class="media-body media-middle m0 fw-400" *ngIf="campaign.service_type !== 'service_not_applicable'">{{campaign.service_names}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.web_access_type !== 'web_access_not_applicable'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Web Access</label>
          </div>
          <h5 class="media-body media-middle m0 text-capitalize fw-400">{{campaign.web_access_type}}</h5>
        </div>
      </li>
      </ng-container>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-calendar fs14 vl-m mr"></i>
            <label class="field-label">Created by & On</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{campaign.creator_name}}, {{campaign.created_at}}</h5>
        </div>
      </li>
    </ul>
    <div class="main-wrapper">
      <app-email-campaign-analytics *ngIf="campaignAnalytics" [campaignAnalytics]="campaignAnalytics" [campaign]="campaign"></app-email-campaign-analytics>
      <app-email-campaign-recipients-analytics *ngIf="recipient_statistics" [recipients]="recipient_statistics" [campaign]="campaign"></app-email-campaign-recipients-analytics>
      <button class="btn btn-secondary" type="button" (click)="campaignService.saveAsCampaign(campaign)">Save As</button>
    </div>
  </div>
</div>
