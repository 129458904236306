import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Patient } from '../../../health/doctor/patient';
import {PatientService} from '../../.././services/patient.service';
import {UserService} from '../../.././services/user.service';
import { CustomValidations } from '../../../routes/customValidations/custom-validations'
import { FormControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { GlobalErrorHandlerService } from '../../../services/global-error.service';

@Component({
  selector: 'app-patient-relative-form',
  templateUrl: './patient-relative-form.component.html',
  styleUrls: ['./patient-relative-form.component.scss']
})
export class PatientRelativeFormComponent implements OnInit {

  public customValidations: CustomValidations;
  public patient: Patient = new Patient();
  public relationships: string[] = ['father', 'mother', 'sister', 'brother', 'spouse', 'child', 'legalguardian']
  public dob_invalid = false;
  public gender_error = false;
  @Output() new_added_family_member: EventEmitter<Patient> = new EventEmitter<Patient>();

  @ViewChild('familyrelativeForm') familyrelativeForm: NgForm;
  constructor(private _patient_service: PatientService, private _user_service: UserService, private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.customValidations = new CustomValidations();
  }

  ngOnInit() {
    this.patient.relationship_id = 1;
    if (this.patient.user.gender == 'male' || this.patient.user.gender == 'female') {
      this.patient.user.gender = this.patient.user.gender
    } else {
      this.patient.user.gender = 'Gender';
    }
  }

  setTimeOutFailsMessage(): any {
    this.dob_invalid = true;
    setTimeout(() => {
      this.dob_invalid = false;
    }, 3000)
  }

  save_relative_patient() {
    if (this.patient.user.gender == 'Gender') {
      this.gender_error = true;
      setTimeout(() => {
        this.gender_error = false;
        this.patient.success = '';
      }, 2000)
      return;
    }

    if (this.patient.check_valid_dob(this.patient.dob)) {
      this.patient.patient_dob_year();
      return;
    }
    this.current_logged_in_user();
    this.patient.name = [this.patient.user.first_name, this.patient.user.last_name].join(' ');
    this._patient_service.save(this.patient).then(res => {
      this.new_added_family_member.emit(this.patient);
    }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error);
  }

  current_logged_in_user() {
    let user = this._user_service.current_user;
    if (user) {
    this.patient.parent_id = user.profileable_id;
    }
  }
  ngAfterViewChecked() {
    this.customValidations.formChanged(this.familyrelativeForm);
  }

  callme() {
    this.ngAfterViewChecked();
  }

}
