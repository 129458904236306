<div class="row patient-info-tab">
  <div class="col-sm-9 col-xs-12">
    <ng-container *ngIf="appointments_array && appointments_array.length > 0">
      <ul class="border-list list-unstyled">
        <li class="list" *ngFor="let appointment of appointments_array">
          <ng-container *ngIf="appointment.id">
            <div class="media hidden-xs overflow-visible">
              <div class="media-left" style="min-width:80px;">
                <img class="img-circle thumb64" [src]="appointment.doctor?.profile_pic.modal.img" alt="Image"/>
              </div>
              <div class="media-body overflow-visible">
                <div class="row patient-info-col">
                  <div class="col-sm-9">
                    <a (click)="gotoDetail(appointment.doctor.id)" class="fs18 lhn">{{appointment.doctor?.complete_name}}</a>
                    <div class="info-star-holder">
                      <h5 class="title fw-400">{{appointment.doctor?.designation}}</h5>
                      <ng-container *ngIf="appointment.status == 'checkedout' && appointment.practice_review_status == 'start'">
                        <ng-container *ngIf="appointment.review_type == 'doctor' || appointment.review_type == 'both'">
                          <div class="review-star-ui" *ngIf="!appointment.patient_doctor_review.isReadonly">
                            <rating [(ngModel)]="appointment.patient_doctor_review.rating" placement="bottom" ngbTooltip="{{appointment.patient_doctor_review.description}}"  [max]="5" [readonly]="true"></rating>
                            <button *ngIf="_userService.current_user.isPatientUser()" class="fa fa-pencil edit-icon btn btn-inverse" (click)="appointment.patient_doctor_review.isReadonly =! appointment.patient_doctor_review.isReadonly"></button>
                          </div>
                          <div class="border-box p mb" *ngIf="appointment.patient_doctor_review.isReadonly">
                            <h5 class="m0 fw-400">Any Feedback you want to give?</h5>
                            <div class="review-star-ui mb">
                              <rating [(ngModel)]="appointment.patient_doctor_review.rating" [max]="5" [readonly]="false"></rating>
                            </div>
                            <textarea class="form-control no-resize" rows="5" [(ngModel)] = "appointment.patient_doctor_review.description" required></textarea>
                            <div class="mt">
                              <a *ngIf="loader_service.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
                              <button class="btn btn-secondary btn-outline mr" *ngIf="!loader_service.uploading_in_process" (click)="saveReview(appointment.patient_doctor_review); appointment.patient_doctor_review.isReadonly =! appointment.patient_doctor_review.isReadonly"  [disabled]="!appointment.patient_doctor_review.description && !appointment.patient_doctor_review.rating">Submit</button>
                              <button class="btn btn-tertiary btn-outline" *ngIf="!loader_service.uploading_in_process" (click)="appointment.patient_doctor_review.isReadonly =! appointment.patient_doctor_review.isReadonly">Cancel</button>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                    <div class="info-star-holder pt-sm">
                      <h6 class="title"><i class="fa icon-location-pin vl-m"></i> &nbsp;{{appointment.location?.practice_name}}</h6>
                      <ng-container *ngIf="appointment.status == 'checkedout' && appointment.practice_review_status == 'start'">
                        <ng-container *ngIf="appointment.review_type == 'practice' || appointment.review_type == 'both'">
                          <div class="review-star-ui"  *ngIf="!appointment.patient_practice_review.isReadonly" >
                            <rating [(ngModel)]="appointment.patient_practice_review.rating" [max]="5"  placement="bottom" ngbTooltip="{{appointment.patient_practice_review.description}}" [readonly]="true"></rating>
                            <button *ngIf="_userService.current_user.isPatientUser()" class="fa fa-pencil edit-icon btn btn-inverse" (click)=" appointment.patient_practice_review.isReadonly =! appointment.patient_practice_review.isReadonly"></button>
                          </div>
                          <div class="border-box p mb" *ngIf="appointment.patient_practice_review.isReadonly">
                            <h5 class="m0 fw-400">Any Feedback you want to give?</h5>
                            <div class="review-star-ui mb">
                              <rating [(ngModel)]="appointment.patient_practice_review.rating" [max]="5" [readonly]="false"></rating>
                            </div>
                            <textarea class="form-control no-resize" rows="7" type="text" [(ngModel)] = "appointment.patient_practice_review.description" required></textarea>
                            <div class="mt">
                              <a *ngIf="loader_service.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
                              <button class="btn btn-secondary btn-outline mr" *ngIf="!loader_service.uploading_in_process" (click)="saveReview(appointment.patient_practice_review); appointment.patient_practice_review.isReadonly =! appointment.patient_practice_review.isReadonly"  [disabled]="!appointment.patient_practice_review.description && !appointment.patient_practice_review.rating">Submit</button>
                              <button class="btn btn-tertiary btn-outline" *ngIf="!loader_service.uploading_in_process" (click)="appointment.patient_practice_review.isReadonly =! appointment.patient_practice_review.isReadonly">Cancel</button>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                    <h6 class="mt-sm" *ngIf="appointment.location && appointment.location.contact_numbers && appointment.location.contact_numbers.length > 0">
                      <i class="icon-phone vl-m"></i> &nbsp;{{appointment.location.phone.value | mask:'(000) 000-0000'}}
                    </h6>
                    <h6 class="mt-sm" *ngIf="appointment.appointment_type !== 'video'">
                      <i class="fa icon-pin vl-m"></i> &nbsp;{{appointment.location?.complete_address}}
                    </h6>
                    <h6 class="mt-sm" *ngIf="appointment.appointment_type === 'video'">
                      <i class="fa icon-camrecorder vl-m"></i> &nbsp;Online Video Consultation
                    </h6>
                  </div>
                  <div class="col-sm-3 pricing-col">
                    <h3 *ngIf="_userService.current_user.isPatient">${{appointment.charges}}</h3>
                    <ng-container *ngIf="appointment.payment_status === 'unpaid' && appointment.charges > 0">
                      <label class="label label-warning m0">Pending</label>
                      <a class="btn btn-secondary btn-outline" (click)="pay(appointment)">Pay Now</a>
                    </ng-container>
                  </div>
                </div>
                <div class="row bottom-info">
                  <div class="col-sm-4 col-xs-6">
                    <label class="field-label">Appointment</label>
                    <label class="label date">{{appointment.showBookingTimeAndSlot()}}</label>
                  </div>
                  <div class="col-sm-3 col-xs-6">
                    <label class="field-label">Status</label>
                    <label class="label status-colors" [ngClass]="appointment.status">{{capitalize(appointment.status)}}</label>
                  </div>
                  <div class="col-sm-3 col-xs-6" *ngIf="appointment.amenity_name">
                    <label class="field-label">Service</label>
                    <h6 class="m0">{{appointment.amenity_name}}</h6>
                  </div>
                  <div class="col-sm-3 col-xs-6" *ngIf="appointment.service">
                    <label class="field-label">Reason of Visit</label>
                    <h6 class="m0">{{appointment.service?.name}}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="media visible-xs">
              <div class="media-left" style="min-width:80px;">
                <img class="img-circle thumb64" [src]="appointment.doctor?.profile_pic.modal.img" alt="Image"/>
              </div>
              <div class="media-body media-middle">
                <a (click)="gotoDetail(appointment.doctor.id)" class="fs18 lhn">{{appointment.doctor?.complete_name}}</a>
                <div class="info-star-holder">
                  <h5 class="title fw-400">{{appointment.doctor?.designation}}</h5>
                  <ng-container *ngIf="appointment.status == 'checkedout' && appointment.practice_review_status == 'start'">
                    <ng-container *ngIf="appointment.review_type == 'doctor' || appointment.review_type == 'both'">
                      <div class="review-star-ui" *ngIf="!appointment.patient_doctor_review.isReadonly">
                        <rating [(ngModel)]="appointment.patient_doctor_review.rating" tooltip="{{appointment.patient_doctor_review.description}}" [max]="5" [readonly]="true"></rating>
                        <button *ngIf="_userService.current_user.isPatientUser()" class="fa fa-pencil edit-icon btn btn-inverse" (click)="appointment.patient_doctor_review.isReadonly =! appointment.patient_doctor_review.isReadonly"></button>
                      </div>
                      <div class="border-box p mb" *ngIf="appointment.patient_doctor_review.isReadonly">
                        <h5 class="m0 fw-400">Any Feedback you want to give?</h5>
                        <div class="review-star-ui mb">
                          <rating [(ngModel)]="appointment.patient_doctor_review.rating" [max]="5" [readonly]="false"></rating>
                        </div>
                        <textarea class="form-control no-resize" rows="5" [(ngModel)] = "appointment.patient_doctor_review.description" required></textarea>
                        <div class="mt">
                          <a *ngIf="loader_service.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
                          <button class="btn btn-secondary btn-outline mr" *ngIf="!loader_service.uploading_in_process" (click)="saveReview(appointment.patient_doctor_review); appointment.patient_doctor_review.isReadonly =! appointment.patient_doctor_review.isReadonly"  [disabled]="!appointment.patient_doctor_review.description && !appointment.patient_doctor_review.rating">Submit</button>
                          <button class="btn btn-tertiary btn-outline" *ngIf="!loader_service.uploading_in_process" (click)="appointment.patient_doctor_review.isReadonly =! appointment.patient_doctor_review.isReadonly">Cancel</button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <div class="row patient-info-col">
                <div class="col-sm-9 col-xs-9">
                  <div class="info-star-holder pt-sm">
                    <h6 class="title"><i class="fa icon-location-pin vl-m"></i> &nbsp;{{appointment.location?.practice_name}}</h6>
                    <ng-container *ngIf="appointment.status == 'checkedout' && appointment.practice_review_status == 'start'">
                      <ng-container *ngIf="appointment.review_type == 'practice' || appointment.review_type == 'both'">
                        <div class="review-star-ui"  *ngIf="!appointment.patient_practice_review.isReadonly" >
                          <rating [(ngModel)]="appointment.patient_practice_review.rating" [max]="5" tooltip="{{appointment.patient_practice_review.description}}" [readonly]="true"></rating>
                          <button *ngIf="_userService.current_user.isPatientUser()" class="fa fa-pencil edit-icon btn btn-inverse" (click)=" appointment.patient_practice_review.isReadonly =! appointment.patient_practice_review.isReadonly"></button>
                        </div>
                        <div class="border-box p mb" *ngIf="appointment.patient_practice_review.isReadonly">
                          <h5 class="m0 fw-400">Any Feedback you want to give?</h5>
                          <div class="review-star-ui mb">
                            <rating [(ngModel)]="appointment.patient_practice_review.rating" [max]="5" [readonly]="false"></rating>
                          </div>
                          <textarea class="form-control no-resize" rows="7" type="text" [(ngModel)] = "appointment.patient_practice_review.description" required></textarea>
                          <div class="mt">
                            <a *ngIf="loader_service.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
                            <button class="btn btn-secondary btn-outline mr" *ngIf="!loader_service.uploading_in_process" (click)="saveReview(appointment.patient_practice_review); appointment.patient_practice_review.isReadonly =! appointment.patient_practice_review.isReadonly"  [disabled]="!appointment.patient_practice_review.description && !appointment.patient_practice_review.rating">Submit</button>
                            <button class="btn btn-tertiary btn-outline" *ngIf="!loader_service.uploading_in_process" (click)="appointment.patient_practice_review.isReadonly =! appointment.patient_practice_review.isReadonly">Cancel</button>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                  <h6 class="mt-sm" *ngIf="appointment.location && appointment.location.contact_numbers && appointment.location.contact_numbers.length > 0">
                    <i class="icon-phone vl-m"></i> &nbsp;{{appointment.location.phone.value | mask: ' (000) 000-0000'}}
                  </h6>
                  <h6 class="mt-sm" *ngIf="appointment.appointment_type !== 'video'"><i class="fa icon-pin vl-m"></i> &nbsp;{{appointment.location?.complete_address}}</h6>
                  <h6 class="mt-sm" *ngIf="appointment.appointment_type === 'video'"><i class="fa icon-pin vl-m"></i> Online Video Consultation</h6>
                </div>
                <div class="col-sm-3 col-xs-3 pricing-col">
                  <h3 *ngIf="_userService.current_user.isPatient">${{appointment.charges}}</h3>
                  <ng-container *ngIf="appointment.payment_status === 'unpaid' && appointment.charges > 0">
                    <label class="label label-warning m0">Pending</label>
                    <a class="btn btn-secondary btn-outline" (click)="pay(appointment)">Pay Now</a>
                  </ng-container>
                </div>
              </div>
              <div class="row bottom-info">
                <div class="col-sm-4 col-xs-6">
                  <label class="field-label">Appointment</label>
                  <label class="label date">{{appointment.showBookingTimeAndSlot()}}</label>
                </div>
                <div class="col-sm-3 col-xs-6">
                  <label class="field-label">Status</label>
                  <label class="label status-colors" [ngClass]="appointment.status">{{capitalize(appointment.status)}}</label>
                </div>
                <div class="col-sm-3 col-xs-6" *ngIf="appointment.amenity_name">
                  <label class="field-label">Service</label>
                  <h6 class="m0">{{appointment.amenity_name}}</h6>
                </div>
                <div class="col-sm-3 col-xs-6" *ngIf="appointment.service">
                  <label class="field-label">Reason of Visit</label>
                  <h6 class="m0">{{appointment.service?.name}}</h6>
                </div>
              </div>
            </div>
          </ng-container>
        </li>
      </ul>
    </ng-container>
    <ng-container  *ngIf="appointments_array && appointments_array.length > 0">
      <pagination class="check m0" *ngIf="+totalItems > 5 && +totalItems !=0" [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="5" (pageChanged)="pageChanged($event)"></pagination>
    </ng-container>
  </div>
  <div class="col-sm-3 col-xs-12 hidden-xs" *ngIf="appointments_array && appointments_array.length > 0">
    <div class="alert alert-warning mb0 mr mt p-sm">
      <h5 class="font-500 text-center mt0"><i class="fa icon-home-surgeon d-block fs30"></i> My Medical Team</h5>
      <ul class="list-unstyled border-list m0">
        <ng-container *ngFor="let member of medical_team_members">
          <li class="list media m0">
            <div class="media-body">
              <a class="fs12 lhn" [routerLink]="['/doctors',member.id]">{{member.complete_name}}</a>
              <h6 class="text-muted m0">{{member.designation}}</h6>
            </div>
            <div class="media-right text-right"><span class="badge">{{member.count}}</span></div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #paymentModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog" style="display:flex; flex-direction:column; justify-content:center">
    <div class="modal-content">
      <div class="modal-body" *ngIf="!paymentSuccessful">
        <button type="button" class="close" (click)="paymentModal.hide()"><i class="fa fa-times fs16"></i></button>
        <div class="patient-payment-flow">
          <app-braintree-payment *ngIf="payableAppointment" (paymentSuccessful)="onPayment($event)"
                                 [appointment_id]="payableAppointment.id" [appointmentPayment]="true"
                                 [patient]="_userService.current_user">
          </app-braintree-payment>
        </div>
      </div>
      <div *ngIf="paymentSuccessful">
        <div class="modal-header">
          <button type="button" class="close" (click)="paymentModal.hide()"><i class="fa fa-times fs16"></i></button>
          <h4 class="m0 text-center">Payment</h4>
        </div>
        <div class="modal-body">
          <p class="alert alert-success text-center">Payment has been processed successfully.</p>
        </div>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-tertiary btn-outline btn-min" (click)="paymentModal.hide(); paymentSuccessful = false;">Close</button>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="appointments_array && appointments_array.length <= 0">
  <app-no-result-found></app-no-result-found>
</ng-container>
