import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from '../../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../../services/global-error.service';
import {EnrolmentsService} from '../../../../enrolments.service';
import {ProgramEnrollment} from '../../../../eligible-patient';
import {Task} from '../../../../../routes/practice-site/tasks/newtask/task';
import {Tab} from '../../../../../tabs/tab';
import {EdittaskComponent} from '../../../../../routes/practice-site/tasks/edittask/edittask.component';
import {TabsService} from '../../../../../services/tabs.service';
import {TaskService} from '../../../../../services/task.service';
import {Subscription} from 'rxjs';
import {NewtaskComponent} from '../../../../../routes/practice-site/tasks/newtask/newtask.component';
import {Patient} from '../../../../../health/doctor/patient';
import {TrackingService} from '../../../../../services/tracking.service';
import {CarePlan} from '../enrolled-patient-plan.component';

@Component({
  selector: 'app-enrolled-patient-task',
  templateUrl: './enrolled-patient-task.component.html',
  styleUrls: ['./enrolled-patient-task.component.scss']
})
export class EnrolledPatientTaskComponent implements OnInit, OnDestroy {
  @Input() programEnrollment: ProgramEnrollment;
  @Input() patient: Patient;
  @Input() programme_type;
  @Input() careplan: CarePlan;
  public sourceable_type = 'ProgrammeEnrolment'
  @Input() enrolment_tasks: Task[] = [];
  public subscription: Subscription;
  public newTasksubscription: Subscription;
  custom_injector: Injector;
  view_custom_injector: Injector;
  selectedComponent: any;
  public newTaskComponent = NewtaskComponent;
  public ediTaskComponent = EdittaskComponent;
  show_view_task_model = false;
  @ViewChild('newTaskModal') newTaskModal;
  @ViewChild('editTaskModal') editTaskModal;
  show_new_task_model = false;


  constructor(public injector: Injector, public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public enrolmentsService: EnrolmentsService, public tabs_service: TabsService,
              public taskService: TaskService, public trackingService: TrackingService) {
    this.subscription = this.taskService.reloadTasks.subscribe(item => {
      if (!item) return;
      const processTask = (item: any) => {
        const task = new Task().load_from_json(item);
        const index = this.enrolment_tasks.findIndex(t => t.id === task.id);

        if (index !== -1) {
          this.enrolment_tasks[index] = task;
        } else {
          this.enrolment_tasks.push(task);
        }
      };
      if (item.care_plan_id === this.careplan.id || (!item.care_plan_id && this.careplan.name === 'Custom')) {
        processTask(item);
        console.log(item);
        this.getEnrolmentTasks();
      }
    });

    this.newTasksubscription = this.taskService.closeNewTaskForm.subscribe( resp => {
      if (resp) {
        this.newTaskModal.hide();
        this.editTaskModal.hide();
        this.show_view_task_model = false;
        this.show_new_task_model = false;
      }
    });
  }

  ngOnInit(): void {
  }
  getEnrolmentTasks() {
    this.loaderService.show();
    this.enrolmentsService.getEnrolmentTasks(this.programEnrollment.id).then(resp => {
      console.log(this.enrolment_tasks);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  viewTask(task: Task) {
    this.setCustomInjector(task);
  }
  addTasktab() {
    let task = new Task();
    // task.temp_activity_id = this.trackingService.startTracking('task');
    task.patient = this.patient;
    task.patient_id = this.patient.id;
    task.care_plan_id = this.careplan.id;
    task.care_plan_name = this.careplan.name;
    this.setCustomInjectorForNewTask(task);
  }

  setCustomInjector(task) {
    this.view_custom_injector  = Injector.create([
      {provide: 'task', useValue: task},
      {provide: 'id', useValue:  task.id},
      {provide: 'programme_type', useValue:  this.programme_type},
      {provide: 'add_patient_button', useValue:  false},
      {provide: 'isAssignedToRequired', useValue: false},
    ], this.injector);
    this.show_view_task_model = true;
    this.editTaskModal.show();
  }

  setCustomInjectorForNewTask(task) {
    this.custom_injector  = Injector.create([
      {provide: 'task', useValue: task},
      {provide: 'id', useValue:  task.id},
      {provide: 'source_id', useValue:  this.programEnrollment.id},
      {provide: 'source_type', useValue:  this.sourceable_type},
      {provide: 'programme_type', useValue:  this.programme_type},
      {provide: 'add_patient_button', useValue:  false},
      {provide: 'isAssignedToRequired', useValue: false},
    ], this.injector);
    this.show_new_task_model = true;
    this.newTaskModal.show();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.newTasksubscription.unsubscribe();
  }
}
