import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MedicalCenter} from '../medical-center';
import {UserService} from '../../../../services/user.service';
import {MedicalCenterService} from '../medical-center.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {AlertService} from '../../../../_alert';

@Component({
  selector: 'app-medical-centers',
  templateUrl: './medical-centers.component.html',
  styleUrls: ['./medical-centers.component.scss']
})
export class MedicalCentersComponent implements OnInit {
  medicalCenters: MedicalCenter[] = [];
  selectedMedicalCenterToDelete: MedicalCenter;
  @Input() patient_id: any;
  @Input() intakeReport;
  @Output() editMedicalCenterEmitter: EventEmitter<MedicalCenter> = new EventEmitter<MedicalCenter>();
  constructor(public userService: UserService, public medicalCenterService: MedicalCenterService,
              public loaderService: LoaderService, private globalErrorHandlerService: GlobalErrorHandlerService,
              public alertService: AlertService) { }

  ngOnInit(): void {
    this.getMedicalCenters();
  }

  editMedicalCenter(medicalCenter: MedicalCenter) {
    this.editMedicalCenterEmitter.emit(new MedicalCenter().load_from_json(medicalCenter));
  }

  delete(medicalCenter: MedicalCenter) {
    this.loaderService.show();
    this.medicalCenterService.delete(medicalCenter.id).then(resp => {
      this.medicalCenters.splice(this.medicalCenters.indexOf(medicalCenter), 1);
      this.loaderService.hide();
      this.alertService.success('Medical Center has been deleted successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  getMedicalCenters() {
    if (this.patient_id) {
      this.medicalCenterService.getMedicalCenters(this.patient_id).then( medicalCenters => {
        this.medicalCenters = medicalCenters.map(mCenter => new MedicalCenter().load_from_json(mCenter))
      }).catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
    }
  }
}
