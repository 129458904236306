import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChange, ElementRef } from '@angular/core';
import {Observable} from 'rxjs';
import {PatientService} from '../../../services/patient.service';
import {Router} from '@angular/router';
import {PatientAuthorizationService} from '../../../services/patient-authorization.service';
import $ from 'jquery';

@Component({
    selector: 'app-navsearch',
    templateUrl: './navsearch.component.html',
    styleUrls: ['./navsearch.component.scss']
})
export class NavsearchComponent implements OnInit, OnChanges {
  public dataSourcePatients: Observable<any>;
  public asyncSelected = '';

  @Input() visible: boolean;
    @Output() onclose = new EventEmitter<boolean>();
    term: string;

    constructor(private elem: ElementRef,
                private patientService: PatientService,
                private router: Router,
                public patientAuthService: PatientAuthorizationService) {
      this.dataSourcePatients = Observable.create((observer: any) => {
        observer.next(this.asyncSelected);
      }).mergeMap((token: string) => this.getPatients(token));
    }
  getPatients(token): Observable<any> {
    return this.patientService.searchPatients(token);
  }
    ngOnInit() {

    }

    closeNavSearch() {
        this.visible = false;
        this.onclose.emit();
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        // console.log(changes['visible'].currentValue)
        if (changes['visible'].currentValue === true) {
            this.elem.nativeElement.querySelector('input').focus();
        }
    }

    handleForm() {
        console.log('Form submit: ' + this.term);
    }


  public changeTypeaheadLoading(e: boolean): void {
  }

  public changeTypeaheadNoResults(e: boolean): void {
  }

  public typeaheadOnSelect(e: any): void {
    this.gotoPatient(e.item);
  }
  gotoPatient(patient) {
    this.router.navigate(['/enterprise/patients/search'], { queryParams: { id: patient.id }});
  }
}
