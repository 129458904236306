import {Component, Input, OnInit} from '@angular/core';
import {PatientEvent} from '../../../../../health/patient-profile/health-calendar/patient-event';
import {UserService} from '../../../../../services/user.service';

@Component({
  selector: 'app-health-calendar-table',
  templateUrl: './health-calendar-table.component.html',
  styleUrls: ['./health-calendar-table.component.scss']
})
export class HealthCalendarTableComponent implements OnInit{
  @Input() tableClass: any;
  @Input() events: PatientEvent[] = [];
  @Input()  tableFor: string;
  tableColumns = [
    { label: 'Date', condition: 'all', minWidth: undefined, maxWidth: undefined },
    { label: 'Time', condition: 'all', minWidth: undefined, maxWidth: undefined },
    { label: 'Duration', condition: 'all', minWidth: undefined, maxWidth: undefined },
    { label: 'Event', condition: 'bill', minWidth: undefined, maxWidth: undefined },
    { label: 'Event', condition: 'health_calendar', minWidth: 100, maxWidth: 200 },
    { label: 'Type', condition: 'all', minWidth: undefined, maxWidth: undefined },
    { label: 'Description', condition: 'bill', minWidth: undefined, maxWidth: undefined },
    { label: 'Description', condition: 'health_calendar', minWidth: 200, maxWidth: 200 },
    { label: 'Notify', condition: 'all', minWidth: undefined, maxWidth: undefined },
    { label: 'Status', condition: 'all', minWidth: undefined, maxWidth: undefined },
    { label: 'Comments', condition: 'bill', minWidth: undefined, maxWidth: undefined },
    { label: 'Comments', condition: 'health_calendar', minWidth: 250, maxWidth: 250 }
  ];

  constructor(public userService: UserService) {

  }

  ngOnInit() {

  }

}
