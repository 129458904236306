import { Component, OnInit } from '@angular/core';
import {TabsService} from "../../../services/tabs.service";

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('scheduler');
  }

  ngOnInit(): void {
  }

}
