import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  OnChanges,
  Output,
  EventEmitter,
  ElementRef,
  SimpleChanges
} from '@angular/core';
import { AttachmentService } from '../../../services/attachments.service'
import {ModalDirective} from 'ngx-bootstrap/modal';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';


@Component({
  selector: '' +
    'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit , OnChanges {
  @ViewChild('file') fileInput: ElementRef;
  @Input() attachable_type: string;
  @Input() attachable_id: number;
  @Input() only_modal = false;
  public latest_picture: any;
  @Output() popup_closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() document_uploaded: EventEmitter<any> = new EventEmitter<any>();
  description: string;
  title: string;
  @ViewChild('classicModal', {static: true}) public modal;
  file: File;
  @Input() fileType;
  @Input() allowedFileType;
  @Input() show;
  @Input() auto_upload;
  progress: any;
  @ViewChild('classicModal') classicModal: ModalDirective;
  @ViewChild('file') clearfile: any;
  constructor(public loaderService: LoaderService, private attachmentService: AttachmentService, private ref: ChangeDetectorRef,
              private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.progress = this.attachmentService.multipartItem.progress
  }

  upload() {
    if ( this.allowedFileType && this.file.type !== this.allowedFileType) {
      return this.globalErrorHandlerService.error = 'This file format is not compatible.'
    }
    this.loaderService.show();
    if (this.description === undefined) {
      this.description = '';
    }
    if (this.title === undefined) {
      this.title = '';
    }
    let attachment = {
      fileType: this.fileType,
      file: this.file, title: this.title,
      description: this.description,
      attachable_id: this.attachable_id,
      attachable_type: this.attachable_type
    }
    let callback = (p: any): void => {
      this.progress = p;
      this.ref.detectChanges();
    };

    let upload_callback = (data: any) => {
      if (data !== '') {
        this.file = null;
        this.title = null;
        this.description = null;
        this.document_uploaded.emit(JSON.parse(data));
        this.loaderService.hide();
        if (!this.auto_upload) {
          this.clear_variables()
        }
      } else {
        this.file = null;
        this.loaderService.hide();
        this.document_uploaded.emit(false);
      }
    };
    if (attachment.file === undefined) {
      this.globalErrorHandlerService.error = 'Please select a valid file.';
      this.loaderService.hide();
    } else {
      this.latest_picture = this.attachmentService.upload(attachment, callback, upload_callback);
    }

  }

  clear_variables() {
    this.progress = 0;
    this.description = null;
    this.clearfile.nativeElement.value = '';
    this.document_uploaded.emit(false);
  }
  selectFile($event): void {
    let inputValue = $event.target;
    if ( null == inputValue || null == inputValue.files[0] || inputValue.files[0] === undefined) {
      if (this.auto_upload) {
        this.clear_variables();
      }
      return;
    } else {
      this.file = inputValue.files[0];
      if (this.auto_upload) {
        this.upload();
      }
    }
  }

  setType(type) {
    this.fileType = type
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.show) {
      if (!this.auto_upload) {
        this.modal.show();
      }
      if (this.auto_upload) {
        let event = new MouseEvent('click', {bubbles: false});
        this.fileInput.nativeElement.dispatchEvent(event);
      }
    }
    else {
      this.modal.hide();
    }
  }

  ngOnInit() {
    // this.fileInput.nativeElement.dispatchEvent('click');
  }

  save() {
  }

  handler(type, event) {
    this.popup_closed.emit(event);
  }
}
