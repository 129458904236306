<div class="row flex-control" *ngIf="showCalendar">
  <div class="col-md-6 col-sm-6 flex-control">
    <div class="flex-control flex-column">
      <div class="flex-body scroll-browser">
        <bs-datepicker-inline class="datepicker flex-control" [bsValue]="bsInlineValue"
                              (bsValueChange)="changeValue($event)"
                              [minDate]="minDate"
                              [maxDate]="maxDate"
                              [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', customTodayClass: 'today-date-select', showWeekNumbers:false }"
                              [dateCustomClasses]="dateCustomClasses">
        </bs-datepicker-inline>
      </div>

      <div class="flex-footer text-center row mt">
        <h5 class="col-md-6 m0">
          <label class="label label-secondary">{{device_reading_count ? device_reading_count : '0'}}</label>
          Device recording(s)
        </h5>
        <h5 class="col-md-6 m0">
          <label class="label label-warning">{{manual_reading_count ? manual_reading_count : '0'}}</label>
          Manual recording(s)
        </h5>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-6 flex-control">
    <div class="flex-control flex-column">
      <div class="flex-body scroll-browser">
        <ul class="list-unstyled border-list collapse-list m0" *ngIf="patientVitals && patientVitals.length > 0">
          <ng-container *ngFor="let patientVital of patientVitals">
            <li class="list p-relative overflow-hidden">
              <a class="title text-capitalize" (click)="patientVital.isCollapsed = !patientVital.isCollapsed" [attr.aria-expanded]="!patientVital.isCollapsed"
                 [attr.aria-controls]="patientVital.day_view_label()" [ngClass]="patientVital.descriptor">
                {{patientVital.day_view_label()}}
              </a>
              <span class="btn-hover-right">
          <a class="btn btn-danger btn-outline btn-sm fa fa-trash" ngbTooltip="Mark as invalid" placement="bottom left"
             (click)="selectedPatientVital = patientVital; deleteModal.show();">
          </a>
        </span>
              <div class="bg-lightest-gray" id="{{patientVital.reading_date}}" [collapse]="patientVital.isCollapsed" [isAnimated]="true">
                <ng-container *ngFor="let vitalEntry of patientVital.vital_entries">
                  <div class="row" *ngIf="vitalEntry.value">
                    <div class="col-md-5 co-xs-6">
                      <label class="text-capitalize">{{vitalEntry.vital.vital_sign}}</label>
                    </div>
                    <div class="col-md-7 co-xs-6">
                <span><span [ngClass]="vitalEntry?.descriptor_class">{{vitalEntry.value}}</span> {{vitalService.vitalUnit(vitalEntry.vital)}}
                  <span *ngIf="vitalEntry.descriptor"> ({{vitalEntry.descriptor | titlecase}})</span>
                </span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </li>
          </ng-container>
        </ul>
        <app-no-result-found *ngIf="patientVitals && patientVitals.length == 0"></app-no-result-found>
      </div>
      <ng-container *ngIf="patient.third_party_patient_id">
        <div class="flex-footer text-center" *ngIf="showGetSleepButton()" >
          <button class="btn btn-secondary" (click)="getSleepData()" title="Get Sleep Data">
            Get Sleep Data
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Delete Patient Vital</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to delete the Patient Vital?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deletePatientVital(selectedPatientVital);deleteModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
