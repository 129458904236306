import {Component, OnInit, ViewChild} from '@angular/core';
import {PushNotification} from '../../../interfaces/push_notification';
import {MessageService} from '../../../services/message.service';
import {UserService} from '../../../services/user.service';
import {CustomHttpClient} from '../../../services/custom-http-client.service';
import {PatientEventService} from '../../patient-profile/health-calendar/patient-event.service';
import {PatientEvent} from '../../patient-profile/health-calendar/patient-event';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-patient-event-notifications-dropdown',
  templateUrl: './patient-event-notifications-dropdown.component.html',
  styleUrls: ['./patient-event-notifications-dropdown.component.scss']
})
export class PatientEventNotificationsDropdownComponent implements OnInit {
  public keys: string[];
  public key = 'patient_events';
  public notifications: Object;
  public patientEvent: PatientEvent = new PatientEvent();
  @ViewChild('viewPatientEventNotificationModal') viewPatientEventNotificationModal;
  show_view_patient_event_notification_model: boolean;

  constructor( public _message_service: MessageService, public _userService: UserService,
               private _tokenService: CustomHttpClient, public _patientEventService: PatientEventService,
               private globalErrorHandlerService: GlobalErrorHandlerService,
               private loaderService: LoaderService) {
    if (this._userService.current_user) {
      this.notifications = this._message_service.set_notifications();
    }
  }

  ngOnInit(): void {
  }
  refreshNotifications() {
    this.notifications = this._message_service.set_notifications();
  }
  get_count_for(count) {
    if (typeof(count) === 'function') {
      return count.call(this._message_service);
    } else {
      return  count;
    }
  }
  viewNotification(key: string, notification: PushNotification) {
    if (key === 'patient_events') {
      this.getPatientEvent(notification)
    }
  }
  markNotificationAsRead(notification?: PushNotification) {
      this._tokenService
        .put('notifications/' + notification.id, notification)
        .toPromise()
        .then(response => {
            this._message_service.popPatientEventNotification(notification.id);
          }
        );
  }
  getPatientEvent(notification: PushNotification) {
    this._patientEventService.getPatientEvent(notification.notifier_id).then ( resp => {
      this.patientEvent.load_from_json(resp);
      this.show_view_patient_event_notification_model = true;
      this.viewPatientEventNotificationModal.show();
      this.markNotificationAsRead(notification)
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  closeViewPopup() {
    this.viewPatientEventNotificationModal.hide();
    this.show_view_patient_event_notification_model = false;
  }
}
