export class LibrarySetting {
  id: number;
  is_searchable = false;
  search_result = false;
  header_name: string;
  column_number: string;
  error_message: string;
  parent_column_name: string;
  to_params() {
    return {
      is_searchable: this.is_searchable,
      search_result: this.search_result,
      header_name: this.header_name,
      column_number: this.column_number,
      parent_column_name: this.parent_column_name
    }
  }
  load_from_json(json) {
    this.is_searchable = json.is_searchable;
    this.search_result = json.search_result;
    this.column_number = json.column_name;
    this.header_name = json.header_name;
    return this;
  }

}
export class Library {
  id: number;
  name: string;
  library_data: string;
  library_type_id: number;
  library_settings: LibrarySetting[] = [];
  attachment_id: number;
  codeable_column: boolean;
  libraryType: LibraryType = new LibraryType();
  version: string;
  to_params() {
    return { library: {
      version: this.version,
      attachment_id: this.attachment_id,
      name: this.name,
      codeable_column: this.codeable_column,
      library_type_id: this.library_type_id,
      enote_library_settings_attributes: this.library_settings
    }
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.version = json.version;
    this.name = json.name;
    this.library_settings = json.searchable_enote_library_settings.map(library_setting => new LibrarySetting().load_from_json(library_setting));
    this.libraryType = this.libraryType.load_from_json(json.enote_library_type);
    return this;
  }
}

export class LibraryType {
  id: number;
  lib_type: string;

  load_from_json(json) {
    this.id = json.id;
    this.lib_type = json.lib_type;
    return this;
  }
}

