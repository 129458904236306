<ng-container *ngIf="appointment.status_id == 'checkedin'">
    <ul class="tabs-action-btns m0 p0">
        <li><button type="button" id="scan-{{appointment.id}}" class="btn btn-primary btn-outline" (click)="add_to_tabs('Upload')">Upload <span class="label label-danger" *ngIf="scan_service.get_data(appointment.id) > 0">{{scan_service.get_data(appointment.id)}}</span></button></li>
        <ng-container *ngIf="scan_service.get_data(appointment.id) <= 0">
            <li><button type="button" id="checkout-{{appointment.id}}" class="btn btn-primary btn-outline" (click)="add_payment_to_tabs('Check-Out')">Check-Out</button></li>
        </ng-container>
        <ng-container *ngIf="scan_service.get_data(appointment.id) > 0">
            <li data-toggle="tooltip" data-placement="bottom" title="You cannot Checkout this patient until all uploaded pages are filed.">
                <button type="button" [disabled]="true" id="checkout-{{appointment.id}}" class="btn btn-primary btn-outline disabled" (click)="add_payment_to_tabs('Check-Out')">Check-Out</button>
            </li>
        </ng-container>
        <li>
            <button type="button" id="payment-{{appointment.id}}" class="btn btn-primary btn-outline" (click)="add_payment_to_tabs('Payment')">Payment</button>
        </li>
        <ng-container *ngIf="scan_service.get_data(appointment.id) <= 0">
            <li><button type="button" id="admit-{{appointment.id}}" class="btn btn-primary btn-outline" (click)="add_to_admit_tabs('Admit')">Admit</button></li>
        </ng-container>
      <!--<li><button type="button" id="vitals-{{appointment.id}}" class="btn btn-primary btn-outline" (click)="add_vital_to_tabs('Vital')">Vitals</button></li>-->

      <li><button type="button" id="cancel-{{appointment.id}}" class="btn btn-danger btn-outline" (click)="set_label('Cancel');classicModal.show()">Cancel</button></li>
    </ul>
</ng-container>

<ng-container *ngIf="appointment.status_id == 'checkedout'">
    <ul class="tabs-action-btns m0 p0">
        <li><button type="button" id="checkedout-scan-{{appointment.id}}" class="btn btn-primary btn-outline" (click)="add_to_tabs('Upload')">Upload <span class="label label-danger" *ngIf="scan_service.get_data(appointment.id) > 0">{{scan_service.get_data(appointment.id)}}</span></button></li>
        <li>
            <button type="button" id="checked-out-payment-{{appointment.id}}" class="btn btn-primary btn-outline" (click)="add_payment_to_tabs('Payment')">Payment</button>
        </li>
    </ul>
</ng-container>

<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:330px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Change status to {{label_value}}</h4>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="(label_value == 'Cancel' && appointment.status_id == 'checkedin'); else login">
          <textarea class="form-control no-resize" placeholder="Type your reason here..." maxlength="50" rows="5" cols="64"
                    [(ngModel)]="checkedin_cancel_reason" name="rejection_reason">
          </textarea>
          <h5 class="text-danger">This action will delete all the associated payments.</h5>
        </ng-container>
        <ng-template #login>
          <ng-container *ngIf="label_value == 'Cancel' ">
            <textarea class="form-control no-resize" placeholder="Type your reason here..." maxlength="50" rows="5" cols="64"
                      [(ngModel)]="rejection_reason" name="rejection_reason">
            </textarea>
          </ng-container>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="change_status();classicModal.hide()">Submit</button>
        <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">Cancel</button>
      </div>
    </div>
  </div>
</div>
