import {Component, OnInit, Input} from '@angular/core';
import {PchfService} from '../../../../services/pchf.service';
import {CaseInfo} from '../../../../routes/practice-site/case_info';
import {Patient} from '../../../doctor/patient';
import {Observable, Subscription} from 'rxjs';
import {Staff} from '../../../../routes/practices/contacts/contact';
import {TabsService} from '../../../../services/tabs.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-case-info',
  templateUrl: './case-info.component.html',
  styleUrls: ['./case-info.component.scss']
})

export class CaseInfoComponent implements OnInit {

  @Input() patient: Patient;
  public case_info_listing: CaseInfo[];
  public staffs: Staff[];
  public locations: Location[];
  public case_info: CaseInfo = new CaseInfo();
  public print_case_info: CaseInfo = new CaseInfo();
  public date2DisabledDates;
  public date2DisabledDatesSurgery;
  public physicians: Observable<any[]>;
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  messageAfterSave = false;
  messageAfterSuccessSave = false;
  public show_print = false;


  constructor(public pchf_service: PchfService, public tabs_service: TabsService) {
    this.physicians = Observable.create((observer: any) => {
        observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.pchf_service.pchfDoctorsList(token)));
  }

  ngOnInit() {
    this.pchf_service.getCaseInfoPatient(this.patient).then(res => {
      this.staffs = res.staffs;
      this.locations = res.locations;
      if (res.success == true) {
        this.case_info_listing = res.response.map(case_info => new CaseInfo().load_from_json(case_info));
        if (this.case_info_listing && this.case_info_listing.length > 0) {
            this.case_info = this.case_info_listing[0];
            this.asyncSelected = this.case_info.physician_name;
            this.case_info.patient_id = this.patient.id;
        }
      }
    }).catch(resp =>  resp);
  }

 saveCaseInfo() {
   if (this.case_info.doctor_id == undefined) {
       return;
   }
    this.case_info.patient_id = this.patient.id;
    this.pchf_service.saveCaseInfo(this.case_info).then(res => {
      this.staffs = res.staffs;
      this.locations = res.locations;
      if (res.success == true) {
        this.case_info = new CaseInfo().load_from_json(res.response);
        this.case_info.patient_id = this.patient.id;
        this.asyncSelected = res.physician_name;
        this.case_info_listing = res.listing.map(case_info => new CaseInfo().load_from_json(case_info));
        this.setTimeOutSuccessMessage();
      } else {
          this.setTimeOutFailsMessage();
      }
    }).catch(resp =>  resp);
  }

  addNewCase() {
    this.asyncSelected = '';
    delete this.case_info.doctor_id;
    this.case_info = new CaseInfo();
  }

  public changeTypeaheadNoResults(e: boolean): void {
    delete this.case_info.doctor_id
  }

  public typeaheadOnSelect(e: any): void {
    this.case_info.doctor_id = e.item.id;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  case_info_edit(case_info) {
    this.case_info = case_info;
  }

  setTimeOutSuccessMessage(): any {
    this.messageAfterSuccessSave = true;
    setTimeout(() => {
      this.messageAfterSuccessSave = false;
    }, 3000)
  }

  setTimeOutFailsMessage(): any {
    this.messageAfterSave = true;
    setTimeout(() => {
      this.messageAfterSave = false;
    }, 3000)
  }

  print(case_info) {
    this.show_print = true;
    this.print_case_info = case_info;
  }

  load_case(case_info) {
      this.case_info_listing.filter(e => e.id == case_info.id)
      if (this.case_info_listing && this.case_info_listing.filter(e => e.id == case_info.id) && this.case_info_listing.filter(e => e.id == case_info.id).length > 0) {
         this.case_info = this.case_info_listing.filter(e => e.id == case_info.id)[0]
      }
    }

  handle_response(boolean) {
    this.show_print = boolean;
  }

}
