import {Component, OnInit, Injector, ViewChild, TemplateRef} from '@angular/core';
import {Params, ActivatedRoute}   from '@angular/router';
import { PatientService } from '../../services/patient.service'
import {Patient} from '../doctor/patient';
import {AppointmentService} from '../../services/appointment.service';
import {UserService} from '../../services/user.service';
import {Appointment} from '../models/appointment';
import {HttpTokenService} from '../../services/http.token.service';
import {VideoChatService} from '../../video-chat/services/videochat.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SettingsComponent} from '../../video-chat/settings/settings.component';
import {VideoPanelComponent} from '../../video-chat/video-panel/video-panel.component';
import {LoaderService} from '../../services/loader.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {IntakeFormTemplate} from '../../routes/intake-form-template-index/intake-form-template';

@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit {
  public patient: Patient = new Patient();
  public appointment: Appointment;
  public user_subscription;
  @ViewChild('template', { static: false }) template: TemplateRef<any>;
  modalRef: BsModalRef;
  received = false;
  settings: SettingsComponent;
  room_name: string;
  showVideo: boolean;
  public audio = new Audio('../../../assets/audio/call.mp3');
  @ViewChild('video_panel', { static: false }) video_panel: VideoPanelComponent;
  callEnded = false;
  hidden = true;
  showMessage = false;
  patientIntakeForms: IntakeFormTemplate[] = [];
  public pendingForms: IntakeFormTemplate[] = []; // Placeholder for your form data array
  public currentForm: any;
  public allFormsCompleted: boolean = false;
  constructor(private injector: Injector, private route: ActivatedRoute, public loaderService: LoaderService,
              public userService: UserService, private modalService: BsModalService,
              public patientsService: PatientService, private appointment_service: AppointmentService,
              public _appointment_service: AppointmentService,
              private token_service: HttpTokenService, public videoChatService: VideoChatService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
    this.userService.hideFooterForPatient = true;
  }

  ngOnInit() {
    this.audio.load();
    let id = this.injector.get('id', null);
    this.appointment = this.injector.get('appointment', null);
    if (id) {
      this.fetchPatient(id);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          this.appointment_service.set_selected_patient(+params['id']);
          this.fetchPatient(+params['id'])
        })
    }
    this.token_service.logoutObserver.subscribe((c) => {
      this.unsubscribeFromChannel();
    });

    this.getPatientRelationships();

  }

  fetchPatient(id: number) {
    this.patientsService.getPatient(id).then(res => {
      this.patient = new Patient().load_from_json(res);
      //this.getPatientPendingIntakeForms();
      this.userService.current_user.isPatient ? this.loadPendingForms() : this.allFormsCompleted = true;
      this.patientsService.patient_appointments(this.patient).then(resp => {
        this.patientsService.appointments = resp.map(app => new Appointment().load_from_json(app));
        console.log(this.patientsService.appointments);
      })

    })
  }

  unsubscribeFromChannel() {
    this.user_subscription!.unsubscribe();
  }

  onCallEnded(call_status: Object) {
    console.log(call_status);
    if (call_status['ended']) {
      this.callEnded = true;
    }if (call_status['cancelled']) {
      this.showVideo = false;
    }
  }

  checkedIn(appointment: Appointment) {
    this._appointment_service.markCheckedIn(appointment.id).then(resp => {
      appointment = appointment.load_from_json(resp);
    })
  }
  getPatientRelationships() {
    this.patientsService.getPatientRelationships().then(resp => {
      this.patientsService.patient_relationships = resp;
      this.patientsService.patient_relationships = this.patientsService.patient_relationships
        .filter(rel => !this.patientsService.providers.includes(rel['name']));
    }).catch( resp => {
      this.globalErrorHandlerService = resp.error.error;
    })
  }
  loadPendingForms() {
    this.patientsService.getPatientPendingIntakeForms(this.patient.id).then(forms => {
      this.pendingForms = forms.map(form => new IntakeFormTemplate().load_from_json(form));;
      this.loadNextForm();
    }).catch(error => {
      console.error('Failed to load pending forms:', error);
      this.globalErrorHandlerService = error.error.error;
    });
  }
  loadNextForm() {
    if (this.pendingForms.length > 0) {
      this.currentForm = this.pendingForms.shift();
    } else {
      this.allFormsCompleted = true;
    }
  }
  onFormCompletion() {
    this.loadNextForm();
  }
}
