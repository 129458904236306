import {Injectable, Inject, Injector, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {DataService} from './data.service';
import {UserService} from './user.service';
import {GlobalErrorHandlerService} from './global-error.service';
import {AngularTokenService} from 'angular-token';
import {HttpClient} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {Globals} from '../globals';
export interface SignInData {
  email: string;
  login: string;
  password: string;
  userType?: string;
}
import baseApiUrl from '../globals'
import {ReplaySubject, Subject} from 'rxjs';
import * as ActionCable from 'actioncable'
@Injectable()
export class HttpTokenService extends AngularTokenService {
  _userService: UserService;
  public chatroom_host: string = baseApiUrl + '/cable';
  public already_subscribed_to_userchannel = {};
  public subscriptionSubject: Subject<any> = new ReplaySubject(1);
  public logoutSubject: Subject<any> = new ReplaySubject(1);
  public user_subscription;
  public cable: ActionCable.Cable;
  public chatCables: ActionCable.Cable[] = [];
  constructor(private injector: Injector,
              private _http: HttpClient,
              @Inject(PLATFORM_ID) platformId: Object,
              _activatedRoute: ActivatedRoute,
              public _router: Router,
              private data_service: DataService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public our_global: Globals, ) {
    super(_http, {
      apiBase: our_global.baseApiUrl,
      apiPath: 'api/v1',
      signInPath: 'auth/sign_in',
      signInRedirect: '/',
      signInStoredUrlStorageKey: 'redirectTo',
      signOutPath: 'auth/sign_out',
      validateTokenPath: 'auth/validate_token',
      signOutFailedValidate: true, updatePasswordPath: 'auth',
      resetPasswordPath: 'auth/password',
      resetPasswordCallback: window.location.href
    }, platformId, _activatedRoute, _router);
    setTimeout(() => this._userService = injector.get(UserService, null), 500);
  }
  userSignedIn() {
    return !!this.currentUserData;
  }

  get subscriptionObserver() {
    let o =  this.subscriptionSubject.asObservable()
      o.subscribe(a => {;
    });
    return o;
  }

  get logoutObserver() {
    return this.logoutSubject.asObservable();
  }
  /**
   * @return {?}
   */
  canActivate() {
    if (this.userSignedIn()) {
      return true;
    }
    else {
      if (localStorage.getItem('ignoreRedirectTo')) {
        localStorage.removeItem('ignoreRedirectTo');
        return false;
      }
      localStorage.setItem('redirectTo', window.location.pathname + window.location.search);
      return false;
    }
  }
  request(options: HttpRequest<any>): Observable<any> {
    return this._http.request(options).catch(resp => this.catchAuthError(resp))
  }

  validateToken(): Observable<Response> {
    let res =  super.validateToken();
    res.subscribe(data => this.data_service.mark_user_dirty(), error => {
      this.catchAuthError(error);
    });
    res.subscribe(data => this.handleUserVerification());
    return res;
  }

  createChatRoomCables(chatroom_id): ActionCable.Cable {
    let cable = ActionCable.createConsumer(this.chatroom_host + '?bbbbbbbb=33333&chatroom_id=' + chatroom_id)
    this.chatCables.push(cable)
    console.log('ChatRoomCables', this.chatCables);
    return cable;
  }
  make_user_notification_connection(user_id) {
    if (this.firsttime_subscription()) {
      this.cable = ActionCable.createConsumer(this.chatroom_host + '?bbbbbbbb=33333&user_id=' + user_id)
      this.already_subscribed_to_userchannel['user_subscription'] = true;
      console.log('Cable', this.cable);
    }
  }

  firsttime_subscription() {
    let already_subscribed = this.already_subscribed_to_userchannel['user_subscription'];
    if (typeof(already_subscribed) === 'undefined') {
      return true;
    }
    return false;
  }

  // private catchAuthError(self) {
  //   res.subscribe(data => this.data_service.mark_user_dirty());
  //   res.subscribe(data => this.handleUserVerification());
  //   return res;
  // }

  signIn(signInData: SignInData): Observable<Response> {
    let aa = signInData;
    aa['login'] = signInData['email'];
    let res = super.signIn(aa);
    localStorage.setItem('refresh', 'true');
    res.subscribe(data => {
      this.getAuthHeadersFromResponse(data);
      this.handleUserVerification()
    });
    return res as any;
  }

  private handleUserVerification() {
    console.log(this.currentUserData);
    this.make_user_notification_connection(this.currentUserData.id);
    this.subscriptionSubject.next(true);
    // let obj = this;
    // if(this.currentUserData && !this.currentUserData['extra'].is_verified){
    //   this._router.navigate(['/verify-patient']);
    // }
  }

  private catchAuthError(res) {

    // we have to pass HttpService's own instance here as `self`
      if (res.status === 416) {
        this._userService.logout();
        this._userService.force_close = true;
        this._router.navigate(['home']);
      }
      if (res.status === 0) {
        // this._router.navigate(['home']);
        // window.parent.location.reload();
      }
      if (res.status === 401) {
         // this._router.navigate(['home']);
        // window.parent.location.reload();
      }
      else if (res.status === 403) {
        this.globalErrorHandlerService.error = 'You are not authorized to perform this function.'
        // tslint:disable-next-line:max-line-length
        if (this._userService.current_user && (this._userService.current_user.isDoctor || this._userService.current_user.isPractice || this._userService.current_user.isEnterprise)) {
            if (this._userService.current_user.extra['package_type'].toLowerCase() === 'ultimate') {
               this._router.navigateByUrl('enterprise/home/dashboard');
            }
            if (this._userService.current_user.extra['package_type'].toLowerCase() !== 'ultimate') {
              this._router.navigateByUrl('enterprise/settings');
            }
          }
        else {
          this._router.navigate(['home']);
        }
      }
      else if (res.status === 404 || res.status === 500 || res.status === 504) { // Not found or 500 , 504
        console.log('Internal Server error');
      }
      else if (res.status === 422) {
        console.log('unprocessable entity');
      }
      return Observable.throw(res);

  }
}
