import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Consent} from './consent';
import {LoaderService} from '../../../services/loader.service';
import {ProgramService} from '../../program.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-program-consents',
  templateUrl: './program-consents.component.html',
  styleUrls: ['./program-consents.component.scss']
})
export class ProgramConsentsComponent implements OnInit {
  @ViewChild('newConsentModal') newConsentModal;
  @ViewChild('deleteConsentModal') deleteConsentModal;
  public consenteditor: any;
  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 300,
    relative_urls: false,
    remove_script_host: false,
    convert_urls: true,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    placeholder: 'Summary...',
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
      'insertfile undo redo numlist outdent | bullist indent |contextmenu table | \ ' +
      'link forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.consenteditor = editor;
    },
  }

  @Input() program_id: any;
  @Input() programme_category: any;
  consent: Consent = new Consent();
  @Input() consents: Consent[];
  public selectedConsentToDelete: Consent;

  constructor(
    public loaderService: LoaderService, public programService: ProgramService,
    public globalErrorHandlerService: GlobalErrorHandlerService,
    public userService: UserService
  ) {

  }

  ngOnInit(): void {
  }

  truncateText(text: string, limit: number): string {
    return text.length > limit ? text.substr(0, limit) + '....... ' : text;
  }

  createConsent() {
    this.consent = new Consent();
    if (this.programme_category === 'private_programme') {
      this.consent.programme_type = 'private_pay'
    }
    this.newConsentModal.show();
  }

  editConsent(consent) {
    this.consent = new Consent().load_from_json(consent);
    this.newConsentModal.show();
  }

  closeNewForm() {
    this.newConsentModal.hide();
    this.consent = new Consent();
  }

  saveConsent() {
    this.save();
    this.closeNewForm();
  }

  save() {
    this.loaderService.show();
    this.consent.consentable_id = this.program_id;
    this.consent.consentable_type = 'Programme';
    this.consent.created_by_id = this.userService.current_user.id;
    this.consent.updated_by_id = this.userService.current_user.id;
    this.programService.saveConsent(this.consent)
      .then(resp => {
        this.consent = this.consent.load_from_json(resp);
        this.pushArray(this.consent);
        this.programService.reloadProgram.next(1);
        this.loaderService.hide();
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  pushArray(objectToAdd) {
    const index = this.consents.findIndex(item => item.id === objectToAdd.id);
    if (index !== -1) {
      this.consents[index] = objectToAdd;
    } else {
      this.consents.push(objectToAdd);
    }
  }


  deleteConsent(consent: Consent) {
    this.loaderService.show()
    this.programService.deleteConsent(consent.id).then(resp => {
      this.consents.splice(this.consents.indexOf(consent), 1);
      this.selectedConsentToDelete = new Consent();
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.deleteConsentModal.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
}
