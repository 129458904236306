<div class="table-responsive" *ngIf="medicalCenters.length > 0; else notfound">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th style="min-width:150px;">Address</th>
      <th>City, State, Zip</th>
      <th>Phone</th>
      <th>Fax</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
      <tr *ngFor="let medicalCenter of medicalCenters">
        <td *ngIf="intakeReport">
         {{medicalCenter.name}}
        </td>
        <td *ngIf="!intakeReport">
          <a tooltip="Edit" (click)="editMedicalCenter(medicalCenter)">{{medicalCenter.name}}</a>
        </td>
        <td *ngIf="medicalCenter.center_type">{{userService.removeUnderScore(medicalCenter.center_type)}}</td>
        <td>{{medicalCenter?.address}}</td>
        <td class="text-capitalize">{{medicalCenter.city}}
          <span *ngIf="(medicalCenter.zip || medicalCenter.state) && medicalCenter.city">, </span>
          {{medicalCenter.state}} {{medicalCenter.zip}}
        </td>
        <td>{{medicalCenter?.phone | mask: '(000) 000-0000'}}</td>
        <td>{{medicalCenter?.fax | mask: '(000) 000-0000'}}</td>
        <td>
          <a *ngIf="!intakeReport" class="btn btn-danger btn-outline btn-sm" (click)="selectedMedicalCenterToDelete = medicalCenter;deleteModal.show();">
            <i class="fa fa-trash"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #notfound>
  <ul class="list-group">
    <li class="list-group-item disabled p"><span class="text-gray-dark">No record found.</span></li>
  </ul>
</ng-template>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Record</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p pt-sm pb-sm m0 text-center">“Are you sure you want to delete this record?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="delete(selectedMedicalCenterToDelete);deleteModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
