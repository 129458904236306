import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {Patient} from '../../../doctor/patient';
import {Device} from './device';
import {Subscription} from 'rxjs/Subscription';
import {DeviceService} from './device.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  @Input() patient: Patient
  devices: Device[] = [];
  public subscription: Subscription;
  public totalItems;
  public filter: Object = {patient_id: '', page: 1};
  @Input() isTrainingDevice: boolean;
  public custom_injector: Injector;
  @ViewChild('addDeviceModal') addDeviceModal: ModalDirective;
  public device: Device = new Device();


  constructor(public deviceService: DeviceService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public injector: Injector,
              public userService: UserService) {
    this.subscription = this.deviceService.reloadDeviceList.subscribe(item => {
      if (item) {
        this.deviceService.reloadDeviceList.next(0);
        this.getPatientDevices();
      }
    });
  }

  ngOnInit(): void {
    this.getPatientDevices();
  }
  getPatientDevices() {
    if (this.patient.id) {
      this.filter['patient_id'] = this.patient.id;
      this.loaderService.show();
      this.deviceService.getPatientDevices(this.filter).then(resp => {
        this.devices = resp.devices.map(device => new Device().load_from_json(device));
        this.totalItems = resp.total;
        this.filter['page'] = resp.current_page;
        this.loaderService.hide();
      }).catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      });
    }
  }

  editDevice(device: Device) {
    this.device = new Device().load_from_json(device);
    if (this.isTrainingDevice) {
      this.device.training_date = this.device.reading_date_format(new Date());
    }
    this.addDeviceModal.show();
  }

  addDevice() {
    this.device = new Device();
    this.device.patient_id = this.patient.id;
    this.addDeviceModal.show();
  }

  closeDeviceModel() {
    this.addDeviceModal.hide();
    this.device = new Device();
  }

  pageChanged(page: any) {
    this.filter['page'] = page.page;
    this.getPatientDevices();
  }
}
