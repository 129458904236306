import {Service} from '../routes/practices/practices/practice';
import {Amenity, ReminderAmenity} from '../routes/practices/amenity/amenity';

export class StatusLog {
  id: number;
  status_id: string;
  _destroy = false;
  load_from_json(json) {
    this.id = json.id;
    this.status_id = json.status_id;
    return this;
  }

  to_params() {
    return {
      id: this.id,
      status_id: this.status_id,
      _destroy: this._destroy
    }
  }

}
export class ReminderTemplate {

  id: number;
  reminder_type: string = '';
  name: string;
  trigger_ref: string ;
  trigger_time_type: string;
  trigger_time_value: number;
  trigger_on: string;
  occurrence_rule: string;
  sms_body: string;
  email_body: string;
  outreach_type: string;
  status = 'stopped';
  patient_preferred_method = true;
  outreach_email = false;
  outreach_sms = false;
  services: Service[] = [];
  amenities: ReminderAmenity[] = [];
  statuses: StatusLog[] = [];
  profileable_id: number;
  profileable_type = 'Practice';
  deleteable: boolean;
  attachment_ids: number[];
  email_subject: string;
  source: string;


  load_from_json(json) {
    this.id = json.id;
    this.source = json.source;
    this.email_subject = json.email_subject,
    this.reminder_type = json.reminder_type;
    this.name = json.name;
    this.trigger_ref = json.trigger_ref;
    this.trigger_time_type = json.trigger_time_type;
    this.trigger_time_value = json.trigger_time_value;
    this.trigger_on = json.trigger_on;
    this.occurrence_rule =  json.occurrence_rule;
    this.sms_body = json.sms_body;
    this.email_body = json.email_body;
    this.profileable_id = json.profileable_id;
    this.outreach_type = this.set_email_sms_button(json.outreach_type);
    this.status = json.status;
    this.patient_preferred_method = json.patient_preferred_method;
    this.services = json.services.map(s => new Service().load_from_json(s));
    this.amenities = json.amenities.map(a => new ReminderAmenity().load_from_json(a));
    this.statuses = json.statuses.map(s => new StatusLog().load_from_json(s));
    this.deleteable = json.deleteable;
    this.attachment_ids = json.attachment_ids;
    return this;
  }

  to_params() {
    return {
      reminder_template: {
        id: this.id,
        email_subject: this.email_subject,
        reminder_type: this.reminder_type,
        name: this.name,
        trigger_ref: this.trigger_ref,
        trigger_time_type: this.trigger_time_type,
        trigger_time_value: this.trigger_time_value,
        occurrence_rule: this.occurrence_rule,
        sms_body: this.sms_body,
        email_body: this.email_body,
        profileable_id: this.profileable_id,
        profileable_type: this.profileable_type,
        attachment_ids: this.attachment_ids,
        outreach_type: this.set_outreach_type(),
        status: this.status.toLowerCase(),
        patient_preferred_method: this.patient_preferred_method,
        services_associations_attributes: this.services.map(s => s.to_reminder_service_params()),
        reminder_template_amenities_attributes: this.amenities.map(s => s.to_params()),
        status_logs_attributes: this.statuses.map(s => s.to_params())
      }
    }
  }

  set_email_sms_button(outreach_type) {
    outreach_type = outreach_type.join('_')
    if (outreach_type === 'sms' || outreach_type === 'sms_email') {
      this.outreach_sms = true
    }
    if (outreach_type === 'email' || outreach_type === 'sms_email') {
      this.outreach_email = true
    }
    return outreach_type;
  }

  set_outreach_type(): string {
    if (this.outreach_sms) {
      this.outreach_type = 'sms'
    }
    if (this.outreach_email) {
      this.outreach_type = 'email'
    }
    if (this.outreach_email && this.outreach_sms) {
      this.outreach_type = 'sms_email'
    }
    return this.outreach_type;
  }

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }
}
