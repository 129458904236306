<div class="flex-body scroll-browser">
  <div class="media overflow-visible p-relative" *ngFor="let review of reviews">
    <div class="media-left star-line">
      <div class="star-holder">
        <h6 class="star-text">{{review.rating}}</h6>
        <i class="fa icon-star text-warning"></i>
      </div>
    </div>
    <div class="media-body overflow-visible">
      <div class="border-box p-sm">
        <div class="media mb">
          <div class="media-left" style="min-width:90px;">
            <img alt="Image" class="img-circle thumb64" src="{{review.patient_profile_pic}}">
          </div>
          <div class="media-body media-middle">
            <div class="media-body name-body">
              <h4 class="m0 mb-sm fw-500"><a (click)="openPatientProfile(review)">{{review.patient_name}}</a></h4>
            </div>
            <div class="media-body date-body">
              <span placement="bottom" ngbTooltip="Requested on {{review?.requested_on | date:'MMMM d, y, h:mm a'}}">
                <i class="fa icon-clock"></i> {{review.review_date | date:'MMMM d, y, h:mm a'}}
              </span>
            </div>
            <p class="m0 hidden-xs">{{review.description}}</p>
          </div>
          <p class="m0 mt visible-xs">{{review.description}}</p>
        </div>
        <div class="media-bottom">
          <div class="media">
            <div class="media-left fw-500 media-middle">Appointment</div>
            <div class="media-body media-middle">
              On {{review.appointment_time | date:'MMMM d, y, h:mm a' }}
              with {{review.appointment_doctor}}
            </div>
            <div class="media-right media-middle">
              <div class="btn-group" *ngIf="review.status == 'accepted' || review.public_review_status == 'review_requested'">
                <button class="btn btn-inverse btn-outline btn-sm pl-sm pr-sm" disabled>
                  <i class="fa icon-briefcase mr fs14" *ngIf="review.status == 'accepted'" title="Approved for Practice Website"></i>
                  <i class="fa icon-people fs14" *ngIf="review.public_review_status == 'review_requested'" title="Public review already requested"></i>
                </button>
                <button class="btn btn-inverse btn-outline btn-sm pl-sm pr-sm" disabled><i class="fa fa-check"></i></button>
              </div>
              <button class="btn btn-secondary btn-outline btn-sm ml-sm" title="Approve for Practice Website" (click)="approve_review(review)" *ngIf="review.status == 'pending' && review.description">
                <i class="fa icon-briefcase fs14"></i>
              </button>
              <button class="btn btn-secondary btn-outline btn-sm ml-sm" title="Request a Public Review" (click)="request_public_review(review)" *ngIf="review.public_review_status != 'review_requested'">
                <i class="fa icon-people fs14"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="media-bottom tag-sec">
          <div class="media">
            <div class="media-left media-middle fw-500">Tags</div>
            <div class="media-body">
              <tag-input [(ngModel)]="review.tags"
                         (onAdd)="addTag($event,review)"
                         (onSelect)="selectedTag($event)"
                         (onRemove)="removeTag($event,review)">
                <tag-input-dropdown [autocompleteObservable]='data_source'></tag-input-dropdown>
              </tag-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="flex-footer text-center" *ngIf="totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
