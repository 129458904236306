<ng-container *ngIf="entries_data">
  <div class="row">
    <div class="col-md-12 col-sm-12 mb-lg">
      <div class="heading-flex">
        <h4 class="m0" [ngClass]="(entries_data.length > 0) ? '': 'hidden'">
          <i class="fa icon-graph fs22 mr-sm vl-m"></i> Recent Vitals
        </h4>
        <div>
          <a href="javascript:void(0)" class="btn btn-secondary btn-outline mr-sm" (click)="addVital()" title="Add More Vitals">
            <i class="fa fa-plus"></i> Vitals
          </a>
          <a href="javascript:void(0)" class="btn btn-secondary btn-outline" (click)="showPatientVitals()" title="Show Vitals History">
            <i class="fa icon-graph"></i> History
          </a>
        </div>
      </div>
      <div class="clearfix" [ngClass]="(entries_data.length > 0) ? 'hidden' : '' ">
        <div style="padding:20px; border:2px dashed #DDD;">
          <h3 class="text-primary mt0">Welcome to wellwink.com a platform for all your health care needs, free for life.</h3>
          <p class="m0 mt-lg">
            We are passionate about providing you better care close to your home. You can use this platform to search a
            doctor,
            book an appointment,, maintain health record and communication with your doctor online wherever you go and
            whenever you need.
          </p>
        </div>
        <div class="text-right mt-lg" style="margin-top:-30px !important;"><img style="max-width:150px;" src="/assets/img/homepage/banner2.png"></div>
      </div>
      <div class="clearfix" [ngClass]="(entries_data.length > 0) ? '': 'hidden'">
        <carousel class="vitals-carousel" [interval]="false">
          <ng-container *ngFor="let ve of entries_data;">
            <slide>
              <ng-container *ngFor="let e of ve;let i=index;">
                <div class="anchor-box" (click)="openModal(e.vital?.short)" *ngIf="e?.vital?.short != 'dbp'">
                  <div class="border-box shadow p-sm" [ngClass]="e?.descriptor_class == 'text-primary' ? 'panel-default' : 'panel-danger'">
                    <div class="media">
                      <div class="media-body">
                        <h4 class="m0 fs14">{{text_mapper[e?.vital?.short]}}</h4>
                        <div class="flex-control mt-sm">
                          <span class="fs14 m0 mr-sm" *ngIf="e?.vital.short == 'sbp'">
                            <span [class]="ve[i]?.descriptor_class">{{ve[i]?.value}}</span>
                            <span [class]="ve[i+1]?.descriptor_class">/{{ve[i+1]?.value}}</span>
                          </span>
                          <span class="fs14 m0 mr-sm" *ngIf="e?.vital.short != 'sbp'">
                            <span *ngIf="e?.vital.short != 'wt'" [class]="e?.descriptor_class">{{e?.value}}</span>
                            <span *ngIf="e?.vital.short == 'wt'" [class]="e?.descriptor_class">{{weightInPounds(e?.value)}} </span>
                          </span>
                          <span class="fs13" *ngIf="e?.vital?.short != 'sbp' && e?.vital?.unit">({{vitalService.vitalUnit(e?.vital)}})</span>
                        </div>
                      </div>
                      <div class="media-right vitals-icon-width">
                        <i [ngClass]="class_mapper[e?.vital?.short]"></i>
                      </div>
                      <div class="date-time media-bottom" *ngIf="e?.reading_at_datetime">{{e?.reading_at_datetime | date:'MMMM d, y, h:mm a'}}</div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </slide>
          </ng-container>
        </carousel>
      </div>
    </div>
    <div class="col-md-4 col-sm-4 hidden">
      <h4 class="text-black text-center mt0">Wellness Guide</h4>
      <table class="table table-hover">
        <tbody>
        <tr>
          <td style="min-width:155px;"><h5 class="m0 fw-400"><i class="fa fa-eye mr-sm"></i> Eye Screening</h5></td>
          <td><i class="fa fa-check text-primary"></i></td>
          <td class="text-right"><span class="label label-danger">Overdue</span></td>
        </tr>
        <tr>
          <td style="min-width:155px;"><h5 class="m0 fw-400"><i class="fa fa-heartbeat mr-sm"></i> Blood Pressure</h5></td>
          <td><i class="fa fa-check text-primary"></i></td>
          <td></td>
        </tr>
        <tr>
          <td style="min-width:155px;"><h5 class="m0 fw-400"><i class="fa fa-medkit mr-sm"></i> Normal Checkup</h5></td>
          <td><i class="fa fa-square"></i></td>
          <td class="text-right"><span class="label label-success">Fortnight</span></td>
        </tr>
        <tr>
          <td style="min-width:155px;"><h5 class="m0 fw-400"><i class="fa fa-user-md mr-sm"></i> Dental Visit</h5></td>
          <td><i class="fa fa-square"></i></td>
          <td></td>
        </tr>
        <tr>
          <td style="min-width:155px;"><h5 class="m0 fw-400"><i class="fa fa-medkit mr-sm"></i> Vitals & Nutrient</h5></td>
          <td><i class="fa fa-square"></i></td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-medication-summaries [patient]="patient"></app-medication-summaries>
  <div class="hidden">
    <div class="col-md-6 col-sm-12 col-xs-12">
      <h4 class="text-primary"><i class="fa icon-home-man-ill fs26 vl-m"></i> Problems</h4>
      <ul class="list-group">
        <li class="list-group-item">
          <span class="badge bg-primary">Resolved</span>
          <span class="fs16">Diabetes</span>
        </li>
        <li class="list-group-item">
          <span class="badge bg-primary">Resolved</span>
          <span class="fs16">Fever</span>
        </li>
      </ul>
      <h4 class="text-primary"><i class="fa icon-home-man-ill fs26 vl-m"></i> Allergies</h4>
      <ul class="list-group m0">
        <li class="list-group-item">
          <span class="badge bg-warning">Not Resolved</span>
          <span class="fs16">Pollen Allergy</span>
          <h6 class="m0 mt-sm text-muted">Acute since 5 years</h6>
        </li>
      </ul>
    </div>
    <div class="col-md-6 col-sm-12 col-xs-12">
      <h4 class="text-primary"><i class="fa icon-home-medical-history fs22 vl-m"></i> Medications</h4>
      <ul class="list-group">
        <li class="list-group-item">
          <span class="badge">Since July, 2016</span>
          <div class="media m0">
            <div class="media-left pr0"><i class="fa fa-check text-primary"></i></div>
            <div class="media-right">
              <span class="fs16"> Panadol extra 40mg</span>
              <h6 class="m0 mt-sm text-muted">2 tablets twice a day for 15 days</h6>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <span class="badge">Since July, 2016</span>
          <div class="media m0">
            <div class="media-left pr0"><i class="fa fa-check text-primary"></i></div>
            <div class="media-right">
              <span class="fs16"> Panadol extra 40mg</span>
              <h6 class="m0 mt-sm text-muted">2 tablets twice a day for 15 days</h6>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <span class="badge text-danger">Since July, 2016</span>
          <div class="media m0">
            <div class="media-left pr0"><i class="fa fa-ban text-danger"></i></div>
            <div class="media-right">
              <span class="fs16"> Panadol extra 40mg</span>
              <h6 class="m0 mt-sm text-muted">2 tablets twice a day for 15 days</h6>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div [hidden]="!add_patient_vital && !show_patient_vitals && !show_vital_entries">
    <div class="modal flex-modal" bsModal #vitalModal="bs-modal" [config]="{backdrop: false,ignoreBackdropClick: true}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header" *ngIf="!add_patient_vital">
            <button type="button" class="close" (click)="close();"><i class="fa fa-times fs16"></i></button>
            <h4 class="m0 text-center">{{mapper[short]}} History</h4>
          </div>
          <div class="modal-header" *ngIf="add_patient_vital">
            <button type="button" class="close" (click)="close();"><i class="fa fa-times fs16"></i></button>
            <h4 class="m0 text-center">Add Vitals</h4>
          </div>
          <div class="modal-body scroll-browser">
            <div class="table-responsive" *ngIf="show_vital_entries">
              <table class="table table-hover m0 text-center">
                <thead>
                <tr>
                  <th class="text-center">Date</th>
                  <th class="text-center">Time</th>
                  <th class="text-center">Value</th>
                  <th class="text-center">Descriptor</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let v of vital_entries; let i = index;">
                  <tr>
                    <td>{{v.reading_date | date:'shortDate'}}</td>
                    <td>{{v.reading_date | date:'shortTime'}}</td>
                    <td>
                      <!--                      <span *ngIf="v.vital.short == 'Ht'">{{ConvertHeightInFeetAndInch(v.value)}}</span>-->
                      <!--                      <span [class]="v.descriptor_class" *ngIf="v.vital.short != 'Ht'">{{v.value}}</span>-->
                      <span *ngIf="v.vital.short == 'wt'">{{weightInPounds(v.value)}}</span>
                      <span [class]="v.descriptor_class" *ngIf="v.vital.short != 'wt'">{{v.value}}</span>
                      <span [class]="dbp_vital_entries[i]?.descriptor_class" *ngIf="dbp_vital_entries">/{{dbp_vital_entries[i]?.value}}</span>
                    </td>
                    <td><span [class]="v?.descriptor_class" >{{v?.descriptor}}
                      <span  [class]="dbp_vital_entries[i]?.descriptor_class" *ngIf="dbp_vital_entries">/{{dbp_vital_entries[i]?.descriptor}}</span></span>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <app-vitals *ngIf="show_patient_vitals"
                        [totalItems]="totalItems"
                        [currentPage]="current_page"
                        (historyPageChange)="historyPageChange($event)"
                        (selectedPatientVital)="updatePatientVitals($event)"
                        [patient_vitals]="patientVitals"></app-vitals>
            <div class="summary-vital" *ngIf="add_patient_vital">
              <ng-container *ngComponentOutlet="vitalCompnent;injector: custom_injector;"></ng-container>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-tertiary btn-outline" (click)="close();">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
