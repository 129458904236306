<div class="task-left clearfix">
  <div class="heading" [class.active]="filter['assigned_to_me'] == true">
    <a (click)="tasksAssignedToMe()">My Tasks</a>
    <span class="task-counter text-center fw-500">{{task_count['assigned_to_me_count']}}</span>
  </div>
  <ul class="task-list list-unstyled border-list m0">
    <li class="list" [class.active]="filter['assigned_to_me'] == true && filter['status_id'] == 0">
      <a (click)="tasksAssignedToMeStatus(0)" ><i class="fa fa-clock-o pr-sm"></i> Not Started</a>
      <span class="task-counter text-center fs12">{{task_count['assigned_to_me_not_started_count']}}</span>
    </li>
    <li class="list" [class.active]="filter['assigned_to_me'] == true && filter['status_id'] == 1">
      <a (click)="tasksAssignedToMeStatus(1)"><i class="fa fa-spinner pr-sm"></i> In Progress</a>
      <span class="task-counter text-center fs12">{{task_count['assigned_to_me_in_progress_count']}}</span>
    </li>
    <li class="list" [class.active]="filter['assigned_to_me'] == true && filter['status_id'] == 2">
      <a (click)="tasksAssignedToMeStatus(2)"><i class="fa fa-check-square-o pr-sm"></i> Completed</a>
      <span class="task-counter text-center fs12">{{task_count['assigned_to_me_completed_count']}}</span>
    </li>
  </ul>
  <div class="heading" [class.active]="filter['assigned_by_me'] == true" >
    <a (click)="tasksAssignedByMe()">Delegated by Me</a>
    <span class="task-counter text-center fw-500">{{task_count['assigned_by_me_count']}}</span>
  </div>
  <ul class="task-list list-unstyled border-list">
    <li class="list" [class.active]="filter['assigned_by_me'] == true && filter['status_id'] == 0">
      <a (click)="tasksAssignedByMeStatus(0)"><i class="fa fa-clock-o pr-sm"></i> Not Started</a>
      <span class="task-counter text-center fs12">{{task_count['assigned_by_me_not_started_count']}}</span>
    </li>
    <li class="list" [class.active]="filter['assigned_by_me'] == true && filter['status_id'] == 1">
      <a (click)="tasksAssignedByMeStatus(1)"><i class="fa fa-spinner pr-sm"></i> In Progress</a>
      <span class="task-counter text-center fs12">{{task_count['assigned_by_me_in_progress_count']}}</span>
    </li>
    <li class="list" [class.active]="filter['assigned_by_me'] == true && filter['status_id'] == 2">
      <a (click)="tasksAssignedByMeStatus(2)"><i class="fa fa-check-square-o pr-sm"></i> Completed</a>
      <span class="task-counter text-center fs12">{{task_count['assigned_by_me_completed_count']}}</span>
    </li>
  </ul>
</div>
