import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {SpecialtySearchService} from '../../services/specialty-search.service';
import {DocumentsService} from '../../services/document.service';
import {EnoteService} from '../../services/enote.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Template} from '../enote_models/template';
import {UserService} from '../../services/user.service';
import {Tab} from '../../tabs/tab';
import {EnoteComponent} from '../enote/enote.component';
import {TabsService} from '../../services/tabs.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-templates-filter',
  templateUrl: './templates-filter.component.html',
  styleUrls: ['./templates-filter.component.scss']
})
export class TemplatesFilterComponent implements OnInit {

  bsModal: BsModalRef;
  public specialties: Observable<any[]>;
  public templates: Observable<any[]>;
  public template_search: string;
  public readonly_template: Template;
  public saving = false;
  filter: any = {name: '', docTypeID: null, specialtyName: '', specialtyID: null, page: '1'};
  docTypes: any[];
  typeaheadNoResultsTemplate = false;
  typeaheadLoadingTemplate = false;
  typeaheadLoadingSpecialty = false;
  typeaheadNoResultsSpecialty = false;
  @Input() template: Template;
  constructor(private specialtySearchService: SpecialtySearchService,
              private bsModalService: BsModalService,
              private docService: DocumentsService,
              private enoteService: EnoteService,
              public userService: UserService,
              private loaderService: LoaderService,
              private globalErrorHandlerService: GlobalErrorHandlerService,
              public _tabs_service: TabsService) {
    this.specialties = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.filter.specialtyName);
    }).pipe(mergeMap((token: string) => this.getSpecialties(token)));
    this.templates = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.template_search);
    }).pipe(mergeMap((token: string) => this.getPublicTemplates(token)));
    this.enoteService.pageObservable.subscribe(value => {
      this.filter['page'] = value;
      this.filterTemplates();
    })
  }

  add_enote(template, label) {
    let template_id;
    let stage = (this.userService.current_user.isEnterprise && template && template.is_practice_template ) ? 'read_only_template' : label;
    if (template && template.id) {
      template_id = template.id;
    }
    // tslint:disable-next-line:max-line-length
    this._tabs_service.add_tab(new Tab({title: 'Template-' + template_id, icon: 'fa fa-hospital', autorefresh: false}, EnoteComponent, template_id, {id: template_id, template: template_id, stage: stage, label: stage}))
  }

  getPublicTemplates(token: any) {
    return this.enoteService.get_public_templates(token);
  }
  getSpecialties(term: any) {
    return this.specialtySearchService.search(term);
  }

  ngOnInit() {
    this.filterTemplates();
    this.getDocTypes();
  }

  getDocTypes() {
    this.docService.getDocumentsType().then(resp => this.docTypes = resp)
  }

  typeaheadOnSelect(event) {
    this.filter.specialtyID = event.item.id
  }
  typeaheadOnTemplateSelect(event, template: TemplateRef<any>) {
    this.saving = true
    this.enoteService.getTemplate(event.item.id, true)
      .then(resp => {
        this.readonly_template = new Template().load_from_json(resp);
        this.readonly_template.stage = 'read_only_template';
        this.bsModal = this.bsModalService.show(template);
        this.template_search = null;
        this.saving = false;
      });
  }
  search() {
    this.filter['page'] = 1;
    this.filterTemplates();
  }
  filterTemplates() {
    this.loaderService.show();
    this.enoteService.filterTemplates(this.filter).then(resp => {
      this.enoteService.totalTempaltes = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(error => {
      this.globalErrorHandlerService.error = error.error.error;
      this.loaderService.hide();
    })
  }
  save_as_my_template() {
    this.saving = true;
    this.readonly_template.owner_id = null;
    this.readonly_template.stage = 'enote_temp';
    this.enoteService.create(this.readonly_template, true).then(resp => {
      this.filterTemplates();
      this.saving = false;
    })
    this.bsModal.hide();
  }
  hideModal() {
    this.readonly_template = null;
    this.bsModal.hide();
  }

  changeTypeaheadLoadingTemplate(e: boolean) {
    this.typeaheadLoadingTemplate = e;
  }

  changeTypeaheadNoResultsTemplate(e: boolean) {
    this.typeaheadNoResultsTemplate = e;
  }

  changeTypeaheadLoadingSpecialty(e: boolean) {
    this.typeaheadLoadingSpecialty = e;
  }

  changeTypeaheadNoResultsSpecialty(e: boolean) {
    this.typeaheadNoResultsSpecialty = e;
  }

  onKeydown(event) {
    if (event.key === 'Backspace') {
      this.filter.specialtyID = null;
    }
  }
}
