export class Reminder {

  id : number;
  reminderable_type : string;
  reminderable_id : number;
  message : any;
  subject : any;
  outreach_type : string;
  sent_on : any;
  scheduled_on: string;
  sourceable_type : string;
  sourceable_id : number;
  recipient_title : string;
  recipient_contact : string;
  status: string;
  last_try_at;
  reason: string = '';
  run_count;
  created_at: string;

  load_from_json(json) {
    this.id = json.id;
    this.reminderable_type = json.reminderable_type;
    this.reminderable_id = json.reminderable_id;
    this.message = json.message;
    this.outreach_type = json.outreach_type;
    this.sent_on = json.sent_on;
    this.scheduled_on = json.scheduled_on;
    this.sourceable_type = json.sourceable_type;
    this.sourceable_id =  json.sourceable_id;
    this.recipient_title = json.recipient_title;
    this.recipient_contact = json.recipient_contact;
    this.status = json.status;
    this.last_try_at = json.last_try_at;
    this.reason = json.reason;
    this.run_count = json.run_count;
    this.created_at = json.created_at;
    this.subject = json.subject;
    return this;
  }

  to_params() {
    return {
      reminder_template: {
        id: this.id,
        reminderable_id: this.reminderable_id,
        status: this.status,
        reason: this.reason,
        message: this.message,
      }
    }
  }

}

