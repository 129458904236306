import { PropertyType } from './property_type';
export class Control {
    id: number;
    input_type: string;
    controlable_type = '';
    control_type = '';
    identifier = '';
    native_type = '';
    controlable_id: number;
    name = '';
    control_code = '';
    owner_id: number;
    updated_by_id: number;
    is_active = true;
    show_typeahead = false;
    show_text = true;
    is_focus = false;
    set_control_property = false;
    show_multiselect = false;
    is_control_applied = false;
    control_value = '';
    term_id: number;
    control_index: string;
    property_types: PropertyType[] = [];
    properties: Property =  new Property();
    values: Value[] = [];
    constructor(name, control_code) {
        this.name = name;
        this.control_code = control_code;
    }

    load_from_json(json) {
      this.id = json.id;
      this.name = json.name;
      this.input_type = json.input_type;
      this.controlable_type = json.controlable_type;
      this.controlable_id = json.controlable_id;
      this.control_code = json.control_code;
      this.is_active = json.is_active;
      this.owner_id = json.owner_id;
      this.updated_by_id = json.updated_by_id;
      this.is_active = json.is_active;
      this.is_control_applied = json.is_control_applied;
      this.control_value = json.control_value;
      this.term_id =  json.term_id;
      this.control_type =  json.control_type;
      this.native_type =  json.native_type;

      if (json.properties) {
          this.properties = this.properties.load_from_json(json.properties);
      }

      if (json.values && json.values.length > 0) {
        this.values = json.values.map(value => new Value().load_from_json(value));
      }
      this.values = this.values.sort((n1, n2) => {
        if (n1.is_normal == null) {
          return 0;
        }

        if (n1.is_normal == true) {
          return 1;
        }

        return -1;
      });
        return this;
    }
    get control_class() {
        let value = this.values.filter(value => value.default_value_flag)[0];
        if (value) {
          return value.name;
        }
        else {
          return '';
        }
    }
    get value_name() {
      let value = this.values.filter(value => value.default_value_flag)[0];
      if (value) {
        return value.name;
      }
      else {
        return '';
      }
    }
    showMultiSelect() {
      this.show_multiselect = true;
      return this.show_multiselect;
    }

    hideMultiSelect() {
      this.show_multiselect = false;
      return this.show_multiselect;
    }

    focusControl() {
        this.is_focus = true;
    }

    to_params() {
        return { control: {
            id: this.id,
            controlable_type: this.controlable_type,
            controlable_id: this.controlable_id,
            name: this.name,
            owner_id: this.owner_id,
            updated_by_id: this.updated_by_id,
            is_active: this.is_active,
            is_control_applied: this.is_control_applied,
            control_value: this.control_value,
            term_id: this.term_id
        }
      }
    }

    openControlDefination(term, _enote_service) {
      _enote_service.hideAllControlDefinations();
      this.set_control_property = true;
    }

    // isDefaultControl() {
    //   if(this.name == this.default_control_text)
    //     return true;
    //   return false;
    // }

    hideTypeAhead() {
        this.show_typeahead = false;
        this.show_text = true;
        return;
    }


    isCustomControl() {
        return this.control_type.toLocaleLowerCase() === 'custom'
    }
    isMultiSelectControl() {
      return this.input_type.toLocaleLowerCase() === 'multi_select'
    }

    isDefaultControl() {
        return this.control_type.toLocaleLowerCase() === 'default'
    }

    showTypeAhead() {
        this.show_typeahead = true;
        this.show_text = false;
        return;
    }
}

export class Property {
    id: number;
    color = '';
    optional = '';
    color_hex = '';
    description = '';

    load_from_json(json) {
      this.id = json.id;
      this.color = json.color;
      this.optional = json.optional;
      this.color_hex = json.color_hex;
      this.description = json.description;
      return this;
    }
}

export class Value {
    id: number;
    name: string;
    default_value_flag: boolean;
    selected_value: boolean;
    output_text: string;
    control_id: number;
    property: Property =  new Property();
    is_normal: boolean;

  load_from_json(json) {
      this.id = json.id;
      this.default_value_flag = json.default_value_flag;
      this.selected_value = json.default_value_flag;
      this.output_text = json.output_text;
      this.is_normal = json.is_normal;
      this.name = json.name;
      if (json.property) {
        this.property = this.property.load_from_json(json.property);
      }
      return this;
    }
}
