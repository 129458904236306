<section class="inner-banner-portal">
  <img class="img-responsive hidden-xs" src="/assets/img/public/patient-profile.jpg"/>
  <img class="img-responsive visible-xs" src="/assets/img/public/patient-profile-xs.jpg"/>
</section>
<section class="patient-profile sec-pad">
  <div class="container">
    <div class="text-container">
      <h1>Patient Portal</h1>
      <h4 class="sub-title">Let patients manage their data and coordinate with their care team.</h4>
      <h4 class="sub-title">
        Patient portals provide the ability for patients to have 24-hour access to connect with their provider by reviewing
        patient health information, asking and answering questions, and reviewing notes, making the patient-physician
        relationship closer than ever.
      </h4>
      <a class="btn btn-primary mt-xl register-btn" [routerLink]="'/patient/sign-up'">
        <i class="fa icon-user fs25 vl-m fa-fw"></i> &nbsp;Signup As Patient
      </a>
    </div>
  </div>
</section>
<section class="patient-profile sec-pad pt0">
  <div class="container">
    <div class="row">
      <div class="col-sm-8 col-xs-12">
        <div class="text-container white-container-box p-xl">
          <h1 class="mt0">One Stop Platform</h1>
          <p>
            It is always hectic and time consuming to switch between different platforms and work manually to check
            medical documents, appointment bookings etc. Wellwink provides a unified platform in which a patients can
            have everything they wants from appointment bookings to medical documents, treatment plans, rescheduling appointment,
            history and much more. A 360-degree view for supporting patient-centered care, which includes:
          </p>
          <ul class="list-group m0">
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              &nbsp;
              Book Appointment for office visit and virtual visit
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              &nbsp;
              Communication with Doctors and Medical Staff with Instant Messaging
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              &nbsp;
              Care Team and Appointment History
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              &nbsp;
              Medical Chart
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              &nbsp;
              Preventive and Disease Management Alerts
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              &nbsp;
              Remote Monitoring Data Dashboards
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4 col-xs-12">
        <div class="text-container white-container-box p-xl">
          <h1 class="mt0">Patient Summary</h1>
          <p>
            Having patient’s previous medical history is essential in order to deal with current situation.
            <br><br>
            WellWink provides complete medical history in a summarized form so that provider doesn’t need to go through lengthy documents,
            thus leading towards less time consumption and better results.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
