import {Calendar, Doctor} from '../doctors/doctor'
import {Staff} from '../contacts/contact'
import {Location} from '../locations/location'
import {Physician} from '../../../health/models/physician';
import baseApiUrl from './../../../globals'
import {BaseModel} from '../../../shared/base';
import {Image} from '@ks89/angular-modal-gallery';
import {PatientReview} from '../../../reviews/patient-review';
export interface DoctorShare {
  id: number;
  practice: number;
  doctor: number;
  visit_charges: string;
  virtual_visit_charges: string;
  fees_share: string;
  virtual_visit: string;
}
export class ServiceTechnician extends BaseModel {
  name = '';
  technician_type = '';
  technician_id: number;
  id: number;

  load_from_json(json) {
    if (json.technician) {
      this.name = json.technician.first_name + ' ' + json.technician.last_name;
    }
    this.technician_type = json.technician_type;
    this.technician_id = json.technician_id;
    this.id = json.id
    return this;
  }
}
export class Service extends BaseModel {
  service_id: number;
  name = '';
  id: number;
  description = '';
  show_calendars = false;
  show_calendar_form = false;
  client_id: number;
  service_technicians: ServiceTechnician[] = [new ServiceTechnician()];
  existing_service_technicians: any[];
  existing_service: any = {id: 0, text: ''};
  calendars: Calendar[] = [];
  service =  { name: ''};
  _destroy = false;
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }

  get_url(): string {
    return this.is_new_record() ? 'services/' : ['services', this.id].join('/')
  }

  get_new_service_technician() {
    return this.service_technicians.filter(service_technician => service_technician.name == '')[0];
  }

  add_service_technician(object) {
    let existing_found = this.get_new_service_technician();
    let service_technician = existing_found ? existing_found : new ServiceTechnician();
    service_technician.id = object.id;
    service_technician.technician_id = object.id;
    service_technician.name = object.name;
    service_technician.technician_type = object.type;
    if (!existing_found) {
      this.service_technicians.push(service_technician);
    }
  }

  load_from_json(json) {
    if (!json) {
      return this;
    }
    if (json.service_id) {
      this.service_id = json.service_id;
    }
    this.name = json.name;
    this.id = json.id;
    this.description = json.description;
    this.client_id = json.client_id;
    this.service.name = json.service ? json.service.name : '';
    if (json.service_technicians) {
      let service_technicians = json.service_technicians.map(service_technician => new ServiceTechnician().load_from_json(service_technician));
      this.service_technicians = service_technicians;
      this.existing_service_technicians = json.service_technicians.map(service_technician => {
        return {
          id: service_technician.id,
          text: service_technician.technician ? service_technician.technician.first_name + ' ' + service_technician.technician.last_name : null
        }
      });
    }
    let service = {id: 0, text: ''}
    service.id = json.id;
    service.text = json.service ? json.service.name : '';
    this.existing_service = service;
    if (json.calendars) {
      this.calendars = json.calendars.map(calendar => new Calendar().load_from_json(calendar));
    }
    return this;
  }

  to_params() {
    return {
      clients_service: {
        service_id: this.service_id,
        client_id: this.client_id,
        name: this.name,
        description: this.description,
        service_technicians_attributes: this.service_technicians
      }
    }
  }

  to_reminder_service_params() {
    return {
      id: this.id,
      service_id: this.service_id,
      _destroy: this._destroy
    }
  }

  load_calendars(calendars) {
    for (let calendar of calendars) {
      let calendar_category = new Calendar();
      calendar_category.load_from_json(calendar);
      this.calendars.push(calendar_category);
    }
  }


}

export class Specialty extends BaseModel {
  specialty_id: number;
  description = '';
  id: number;

  load_from_json(json) {
    this.specialty_id = json.id;
    this.description = json.description;
    this.id = json.id;
    return this;
  }
}

export class ContactNumber extends BaseModel {
  value = '';
  type = '';
  id: number;
  hashid = '';

  constructor(value: string, type: string) {
    super();
    this.value = value;
    this.type = type;
  }

  load_from_json(json) {
    this.value = json.value;
    this.type = json.type;
    this.id = json.id;
    this.hashid = json.hashid;
    return this;
  }
}

export class City extends BaseModel {
  name = '';
  state = '';
  id: number;

  load_from_json(json) {
    this.name = json.name;
    this.state = json.state;
    this.id = json.id;
    return this;

  }
}

export class CityArea extends BaseModel {
  name = '';
  lat = '';
  long = '';
  id: number;
  city: City = new City();

  to_params() {
    return {
      name: this.name,
      lat: this.lat,
      id: this.id,
      long: this.long, city_attributes: this.city
    }
  }

  load_from_json(json) {
    this.name = json.name;
    this.lat = json.lat;
    this.long = json.long;
    this.city.load_from_json(json.city);
    this.id = json.id;
    return this;

  }
}

export class Address extends BaseModel {
  id: number;
  line_1 = '';
  line_2 = '';
  city_area: CityArea = new CityArea();
  postal_code = '';

  city_state_zip() {
    return `${this.city_area!.city!.name}, ${this.city_area!.city!.state} ${this.postal_code || ''}`
  }

  line_1_line_2() {
    return `${this.line_1}, ${this.line_2}`
  }

  humanize() {
    return `${this.line_1} ${this.line_2}  ${this.city_area!.city!.name}`
  }

  load_from_json(json) {
    this.line_1 = json.line_1;
    this.line_2 = json.line_2;
    this.postal_code = json.postal_code;
    this.city_area.load_from_json(json.city_area);
    this.id = json.id;
    return this;
  }

  to_params() {
    return {
      id: this.id,
      postal_code: this.postal_code,
      line_1: this.line_1,
      line_2: this.line_2,
      city_area_attributes: this.city_area.to_params()
    }
  }
}
export class Client extends BaseModel {
  static status_mappings = {1: 'In Progress', 2: 'Pending', 3: 'On Hold', 4: 'Completed'};
  static status_class_mappings = {1: 'in_progress', 2: 'pending', 3: 'on_hold', 4: 'completed'};

  id: number;
  email = '';
  status_id: any;
  is_active = true;
  logo: any;
  address: Address = new Address();
  contact_numbers: ContactNumber[] = [new ContactNumber('', 'phone'), new ContactNumber('', 'fax')];
  specialties: Specialty[] = [new Specialty()];
  services: Service[] = [new Service()];
  clients_services: Service[] = [new Service()];
  existing_specialties: any[] = [];
  existing_services: any[];

  has_specialty(): boolean {
    return this.specialties[0].specialty_id ? true : false;
  }

  has_service(): boolean {
    return this.services.length > 0 ? true : false;
  }

  get complete_address() {
    return this.address.humanize();
  }

  get status() {
    return Client.status_mappings[this.status_id]
  }

  get statusClass() {
    return Client.status_class_mappings[this.status_id];
  }

  get active() {
    return this.is_active ? 'true' : 'false';
  }

  load_from_json(json) {
    this.id = json.id;
    this.email = json.email;
    this.status_id = json.status_id;
    this.logo = json.logo;
    this.is_active = json.is_active;

    if (json.address) {
      this.address.load_from_json(json.address);
    }

    if (json.contact_numbers) {
      let contact_numbers = json.contact_numbers.map(c_number => new ContactNumber('', '').load_from_json(c_number));
      this.contact_numbers = contact_numbers;
    }

    if (json.specialties && json.specialties.length > 0) {
      let specialties = json.specialties.map(specialty => new Specialty().load_from_json(specialty));
      this.specialties = specialties;
      this.existing_specialties = json.specialties.map(specialty => {
        return {id: specialty.id, text: specialty.description}
      });
    }
    if (json.services) {
      let services = json.services.map(service => new Service().load_from_json(service));
      this.services = services;
      this.existing_services = json.services.map(service => {
        return {id: service.id, text: service.name}
      });
    }
    if (json.clients_services) {
      let clients_services = json.clients_services.map(client_service => new Service().load_from_json(client_service));
      this.clients_services = clients_services;
    }
    return this;
  }

  to_params() {
    return {
      id: this.id,
      logo: this.logo,
      email: this.email,
      address_attributes: this.address.to_params(),
      contact_numbers_attributes: this.contact_numbers,
      specialty_associations_attributes: this.specialties,
      clients_services_attributes: this.services
    }
  }

  add_specialty(specialty_id) {
    let existing_found = this.get_new_specialty();
    let specialty = existing_found ? existing_found : new Specialty();
    specialty.specialty_id = specialty_id;
    specialty.id = specialty_id;
    if (!existing_found) {
      this.specialties.push(specialty);
    }
    console.log(this.specialties);
  }

  get_new_specialty() {
    return this.specialties.filter(sp => sp.specialty_id == 0)[0];
  }

  get_primary_specialty() {
    return this.specialties[0].description;
  }

  add_service(service_id) {
    let existing_found = this.get_new_service();
    let service = existing_found ? existing_found : new Service();
    service.service_id = service_id;
    service.id = service_id;
    if (!existing_found) {
      this.services.push(service);
    }
  }

  get_new_service() {
    return this.services.filter(sp => sp.service_id == 0)[0];
  }
}

export class PracticePackage {
  id: number;
  status: string;
  practice_id: number;
  package_id: number;
  payment_status = 'pending';

  load_from_json(json) {
    this.id = json.id;
    this.status = json.status;
    this.practice_id = json.practice_id;
    this.package_id = json.package_id;
    this.payment_status = json.payment_status;
    return this;
  }

  to_params() {
    return {
      practice_package: {
        id: this.id,
        status: this.status,
        practice_id: this.practice_id,
        package_id: this.package_id,
        payment_status: this.payment_status,
      }
    }
  }
}

export class Practice extends BaseModel {
  public practice_package: PracticePackage;
  public practice_profile_pic: any = baseApiUrl + '/missing.png';
  public gallery_images: any[] = [baseApiUrl + '/missing.png'];
  public documents: any[] = [baseApiUrl + '/missing.png'];
  public locations_images: any[] = [baseApiUrl + '/missing.png'];
  // public  practice_banner: string= baseApiUrl+ '/missing.png';
  public practice_banner: Image = new Image(0, {img: baseApiUrl + '/cover-default-banner.png'});
  public logo: any = baseApiUrl + '/missing.png';
  practice_pics: Image[] = [];
  public edit_practice = false;
  public practice_client_id: string;
  loaded = false;
  account_type: string;
  hashid: string;
  id: number;
  name = '';
  stage_id: number;
  practice_lead_type = 0;
  email = '';
  auth_token = '';
  request_lastname = '';
  requester_firstname = '';
  requester_title = 'Dr.';
  practice_state = '';
  practice_zip_code = '';
  practice_address = '';
  rejected_by_id: number;
  updated_at: string;
  tagline = '';
  message = '';
  raw_message = '';
  no_of_doctors = '';
  url = '';
  rejection_reason = '';
  practice_total_doctors = 0;
  client: Client = new Client();
  doctors: Doctor[] = [];
  active_doctors: Doctor[] = [];
  physicians: Physician[];
  staffs: Staff[] = [];
  locations: Location[] = [];
  complete_address: string;
  phone_number: string;
  doctor_share: DoctorShare[];
  email_disclaimer: string;
  acceptedPatientReviews: PatientReview[];
  averageRating = 0;
  averageExactRating = 0;
  account_name: string;
  braintree_customer_id: string;
  twilio_number: string;
  password: string;
  password_confirmation: string;
  profile_pics: any;
  load_from_json(json) {
    this.reminder_status = json.reminder_status;
    this.twilio_number = json.twilio_number;
    this.braintree_customer_id = json.braintree_customer_id;
    if (json.current_package) {
      this.practice_package = new PracticePackage().load_from_json(json.current_package);
    }
    this.account_type = json.account_type;
    this.account_name = json.account_name;
    this.hashid = json.hashid;
    this.email_disclaimer = json.email_disclaimer;
    this.practice_client_id = json.practice_client_id;
    if (json.phone_number) {
      this.phone_number = json.phone_number;
    }
    if (json.complete_address) {
      this.complete_address = json.complete_address;
    }
    this.physicians = [];
    this.practice_pics = json.images ? json.images.map((data, index) => new Image(index, {
      img: data.img,
      description: data.picture_type
    })) : new Image(0, {img: baseApiUrl + '/missing.png'});
    this.practice_profile_pic = new Image(0, {img: json.profile_pics});
    this.gallery_images =  json.gallery_images ? json.gallery_images : [baseApiUrl + '/missing.png'];
    this.documents = json.documents ?  json.documents : [baseApiUrl + '/missing.png'];
    this.locations_images = json.locations_images ? json.locations_images : [baseApiUrl + '/missing.png'];
    this.practice_banner =  json.banners ? new Image(0, {img: json.banners}) : new Image(0, {img: baseApiUrl + '/cover-default-banner.png'});
    this.logo = json.practice_logo;
    this.loaded = true;
    this.practice_state = json.practice_state;
    this.practice_zip_code = json.practice_zip_code;
    this.practice_address = json.practice_address;
    this.name = json.name;
    this.practice_lead_type = json.practice_lead_type;
    this.auth_token = json.auth_token;
    this.requester_firstname = json.requester_firstname;
    this.request_lastname = json.request_lastname;
    this.requester_title = json.requester_title;
    this.tagline = json.tagline;
    this.message = json.message;
    this.raw_message = json.raw_message;
    this.practice_total_doctors = json.practice_total_doctors;
    this.url = json.url;
    this.id = json.id;
    this.email = json.email;
    this.rejected_by_id = json.rejected_by_id;
    this.stage_id = json.stage_id;
    this.no_of_doctors = json.no_of_doctors;
    this.rejection_reason = json.rejection_reason;
    this.updated_at = json.updated_at;
    this.locations = json.locations ? json.locations.length : 0;
    this.averageRating = json.averageRating;
    this.averageExactRating = json.averageExactRating;
    this.profile_pics = json.profile_pics;
    if (json.client) {
      this.client.load_from_json(json.client);
    }
    if (json.to_custom_json) {
      this.client.load_from_json(json.to_custom_json.client);
    }
    if (json.doctors) {
      let doctors = json.doctors.map(doctor => new Doctor().load_from_json(doctor));
      this.doctors = doctors;
    }
    if (json.active_doctors) {
      let active_doctors = json.active_doctors.map(doctor => new Doctor().load_from_json(doctor));
      this.active_doctors = active_doctors;
    }
    if (json.physicians) {
      this.physicians = json.physicians.map(phy => new Physician().load_from_json(phy, []));
    }
    if (json.staffs) {
      this.staffs = json.staffs.map(staff => new Staff().load_from_jason(staff));
    }
    if (json.locations) {
      this.locations = json.locations.map(location => new Location().load_from_json(location));
    }
    if (json.doctor_share) {
      this.doctor_share = json.doctor_share;
    }
    if (json.accepted_reviews && json.accepted_reviews.length > 0) {
      this.acceptedPatientReviews = json.accepted_reviews.map(patientreview => new PatientReview().load_from_json(patientreview));
      console.log(this.acceptedPatientReviews);
    }

    return this;
  }


  force_load() {
    this.loaded = true;
  }

  get profile_percentage() {
    return this.practice_stage_percentage_calculation();
  }

  get statusClass() {
    return this.client.statusClass;
  }

  get status() {
    return this.client.status;
  }

  practice_stage_percentage_calculation(): any {
    let practice = this;
    let percentage = 0;
    if (practice.name && practice.client.contact_numbers && practice.client.email && practice.client.address.line_1 && practice.client.address.city_area.city.name) {
      percentage += 60;
    }
    if (practice.locations.length > 0) {
      percentage += 10;
    }
    if (practice.client.services.length > 0) {
      percentage += 10;
    }
    if (practice.doctors) {
      percentage += 20;
    }
    // if(practice.contacts){
    //   percentage += 10;
    // }
    // if(practice.doctors_calenders){
    //   percentage += 30;
    // }
    return percentage.toString();
  }

  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }

  get is_loaded() {
    return this.loaded;
  }

  is_persisted() {
    return !this.is_new_record();
  }

  get is_new() {
    return this.is_loaded && !this.is_persisted();
  }

  get_contact(type) {
    return this.client.contact_numbers.filter(c => c.type == type)[0]
  }

  specialties(): any[] {
    return this.client.specialties
  }

  get_url(): string {
    return this.is_new_record() ? 'practices/create_practice' : ['practices', this.id].join('/')
  }

  get active() {
    return this.client.active;
  }

  to_params() {
    return {
      practice: {
        id: this.id,
        name: this.name,
        reminder_status: this.reminder_status,
        practice_client_id: this.practice_client_id,
        practice_lead_type: this.practice_lead_type,
        tagline: this.tagline,
        message: this.message,
        requester_firstname: this.requester_firstname,
        requester_title: this.requester_title,
        no_of_doctors: this.no_of_doctors,
        request_lastname: this.request_lastname,
        url: this.url,
        password: this.password,
        password_confirmation: this.password_confirmation,
        twilio_number: this.twilio_number,
        email_disclaimer: this.email_disclaimer,
        client_attributes: this.client.to_params(),
        practice_packages_attributes: this.practice_package.package_id ? [this.practice_package] : []
      }
    }
  }

  get get_primary_specialty(): any {
    return this.client.specialties[0];
  }

  set get_primary_specialty(description: any) {
    this.client.specialties[0].description = description;
  }

  get phone(): any {
    return this.client.contact_numbers.filter(c => c.type === 'phone')[0];
  }

  set phone(value: any) {
    this.phone.value = value;
  }

  get fax(): any {
    return this.client.contact_numbers.filter(c => c.type === 'fax')[0]
  }

  set fax(value: any) {
    this.fax.value = value;
  }

}

