import {BaseModel} from '../shared/base';
import {Tag} from './tag';

export class PatientReview extends BaseModel {
  id: number;
  patient_id: number;
  description: string;
  status: string;
  rating: number;
  appointment_id: number;
  reviewable_type: string;
  reviewable_id: number;
  appointment_time: any;
  appointment_doctor: string;
  patient_name: string;
  patient_profile_pic: any;
  isReadonly = false;
  review_date: any;
  requested_on: any;
  tags: Tag[] = [];
  public_review_status: string;
  default_public_link: string;
  default_public_site: string;
  selected_links = [];
  patient_email: string;
  patient_mobile_num: string;
  patient_phone_num: string;
  load_from_json(json) {
    this.review_date = json.review_date;
    this.public_review_status = json.public_review_status;
    if (json.tags) {
      this.tags = json.tags.map(t => new Tag().load_from_json(t));
    }
    this.id = json.id;
    this.patient_id = json.patient_id;
    this.description = json.description;
    this.status = json.status;
    this.rating = json.rating;
    this.appointment_id = json.appointment_id;
    this.reviewable_type = json.reviewable_type;
    this.reviewable_id = json.reviewable_id;
    this.appointment_time = json.appointment_time;
    this.appointment_doctor = json.appointment_doctor;
    this.patient_name = json.patient_name;
    this.patient_profile_pic = json.patient_profile_pic;
    this.isReadonly = json.isReadonly;
    this.requested_on = json.created_at;
    this.patient_email = json.patient_email;
    this.patient_mobile_num = json.patient_mobile_num;
    this.patient_phone_num = json.patient_phone_num;
    this.default_public_link = json.default_public_link;
    this.default_public_site = json.default_public_site;
    this.selected_links = json.selected_links;
    return this;
  }


  /*initializePreferences(){
    this.status = 'start';
    this.first_preference = 'practice_first';
    this.review_type = 'practice';
    return this;
  }*/


  to_params() {
    return {
      patient_review: {
        id: this.id,
        patient_id: this.patient_id,
        description : this.description,
        status: this.status,
        rating: this.rating,
        appointment_id: this.appointment_id,
        reviewable_type: this.reviewable_type,
        reviewable_id: this.reviewable_id,
        appointment_time: this.appointment_time,
        appointment_doctor: this.appointment_doctor,

      }
    }
  }

  is_new_record() {
    return  this.id == undefined;
  }

  is_doctor_review() {
    return this.reviewable_type == 'Doctor';
  }

  is_practice_review() {
    return this.reviewable_type == 'Practice';
  }


}
