<div class="filters-sec bg-lightest-gray clearfix">
  <a class="btn btn-primary btn-outline btn-filter mb" data-toggle="collapse" href="#collapsefilters"><i class="fa fa-filter mr-sm"></i> Filters</a>
  <div class="collapse" id="collapsefilters">
    <div class="row">
      <ng-container *ngIf="emergency_service.visit_user_type != 'Staff' && emergency_service.visit_user_type != 'Doctor'">
        <div class="col-md-2 col-sm-3">
          <label>
            <input [typeahead]="emergency_service.practices" name="practices" [(ngModel)]="asyncSelectedPractice"
                   (typeaheadLoading)="changeTypeaheadLoadingPractice($event)" typeaheadWaitMs="500"
                   (typeaheadNoResults)="changeTypeaheadNoResultsPractice($event)" [typeaheadAsync]="true"
                   (typeaheadOnSelect)="typeaheadOnSelectPractice($event)" [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'name'" placeholder="Practices"
                   class="form-control" autocomplete="off">
          </label>
        </div>
      </ng-container>
      <div class="col-md-2 col-sm-3">
        <label>
          <select [(ngModel)]="emergency_service.location_id" class="form-control">
            <option>Locations</option>
            <option *ngFor="let location of emergency_service.practiceLocations" selected="location_id" [value]="location.id">
              {{location.name}}
            </option>
          </select>
        </label>
      </div>
      <ng-container *ngIf="emergency_service.user_type == 'Enterprise' || emergency_service.user_type == 'Staff'">
        <div class="col-md-2 col-sm-3">
          <label>
            <input [typeahead]="emergency_service.doctors" name="doctors" [(ngModel)]="asyncSelectedDoctor"
                   (typeaheadLoading)="changeTypeaheadLoading($event)" typeaheadWaitMs="500"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)" [typeaheadAsync]="true"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'full_name'" placeholder="Doctors"
                   class="form-control" autocomplete="off">
          </label>
        </div>
      </ng-container>
      <div class="col-md-2 col-sm-3 emergency-status status-field">
        <ss-multiselect-dropdown [options]="myOptions" [texts]="myTexts" [(ngModel)]="optionsModel" [settings]="selectSettings" (ngModelChange)="selectStatuses($event)"></ss-multiselect-dropdown>
      </div>
      <div class="col-xs-12 date-field">
        <!--<input [(ngModel)]="emergency_service.start_date" autocomplete="off" class="form-control" placeholder="From"
                 ngui-datetime-picker [disabled-dates]="date2DisabledDates" [show-close-layer]="true" date-only="true"/>-->

        <input type="text"
               placeholder="From"
               autocomplete="off"
               [(ngModel)]="emergency_service.start_date"
               class="form-control"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
               bsDatepicker
               [bsValue]="bsValue">
      </div>
      <div class="col-sm-2 btns-area">
        <button type="button" class="btn btn-inverse" (click)="performSearch()"><i class="fa fa-filter"></i> Filter</button>
        <button type="button" class="btn btn-primary ml-sm" (click)="add_to_tabs('Arrive')">Arrive</button>
      </div>
      <div class="col-sm-2 order-field pull-right">
        <span>Sort by: </span>
        <select #order [(ngModel)]="emergency_service.order_by" name="order_order" [attr.valll]="order_by" (change)="performSearch()">
          <option *ngFor="let order of display_orders; let i = index;" value={{display_orders_mapping[order]}}>
            <span>{{order}}</span>
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
