import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {IntegrationCredential} from '../integration_credential';
import {CustomValidations} from '../../routes/customValidations/custom-validations';
import {NgForm} from '@angular/forms';
import {IntegrationService} from '../integration.service';
import {LocationService} from '../../services/location.service';
import {Location} from '../../routes/practices/locations/location';
import {DoctorService} from '../../services/doctor.service';
import {Doctor} from '../../routes/practices/doctors/doctor';
import {Practice, Specialty} from '../../routes/practices/practices/practice';
import {LoaderService} from '../../services/loader.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
@Component({
  selector: 'app-curemd-integration-credentials',
  templateUrl: './curemd-integration-credentials.component.html',
  styleUrls: ['./curemd-integration-credentials.component.scss']
})
export class CuremdIntegrationCredentialsComponent implements OnInit {
  @Input() practice: Practice;
  @Input() doctors: any[];
  @Input() credential: IntegrationCredential;
  @ViewChild('credentialForm') credentialForm: NgForm;
  public message: string;
  public customValidations: CustomValidations;
  public locations: any[];
  public providers: any[];
  public practice_locations: any[];
  public practice_resource_locations: any[];
  public practice_provider_ids: any[];
  public practice_location_ids: any[];
  public resources: any;
  constructor(private integrationService: IntegrationService, private locationService: LocationService,
              private doctorService: DoctorService, private loadService: LoaderService, private errorHandler: GlobalErrorHandlerService) {
    this.customValidations = new CustomValidations();
  }

  ngOnInit() {
    this.practice_provider_ids = this.practice.doctors.map(d => d.third_party_doctor_id);
    this.practice_location_ids = this.practice.locations.map(d => d.third_party_location_id);
    this.practice_locations = this.practice.locations.filter(l => !l.third_party_location_id);
    this.practice_resource_locations = this.practice.locations.filter(l => !l.third_party_resource_id);
  }

  authenticate() {
    this.integrationService.authenticate(this.credential).then(resp => {
      this.message = resp.message;
      this.credential = new IntegrationCredential().load_from_json(resp.integrated_practice.practice_credentials);
      this.credential.practice_id = this.practice.id;
      this.credential.is_authenticated = resp.is_authenticated;
      setTimeout(() => {
        this.message = null;
      }, 3000)
    });
  }
  getLocations() {
    this.loadService.show();
    this.integrationService.getLocations(this.credential.id).then(resp => {
      this.loadService.hide();
      this.locations = resp.filter(location => location['Status'] == true);
    }).catch(resp => {
      this.loadService.hide();
    })
  }
  getProviders() {
    this.loadService.show();
    this.integrationService.getProviders(this.credential.id).then(resp => {
      this.loadService.hide();
      this.providers = resp.filter(provider => provider['Status'] == true);
    }).catch(resp => {
      this.loadService.hide();
    })
  }

  getResources() {
    this.loadService.show();
    this.integrationService.getResources(this.credential.id).then(resp => {
      this.loadService.hide();
      this.resources = resp.filter(resource => resource['Status'] == true);
    }).catch(resp => {
      this.loadService.hide();
    })
  }

  addProviderToProfile(provider) {
    let new_doc = new Doctor().load_from_json(this.map_doctor_to_json(provider));
        new_doc.practice_id = this.credential.practice_id;
    this.doctorService.create(new_doc).then(resp => {
      this.practice_provider_ids.push(resp.third_party_doctor_id);
      this.practice.doctors.push(new Doctor().load_from_json(resp))
    }).catch(resp => {
      this.errorHandler.error = resp.error['error'];
    })
  }

  map_doctor_to_json(provider) {
    return {
      third_party_doctor_id: provider.Id,
      is_bookable: false,
      name: `${provider.FirstName} ${provider.MiddleName} ${provider.LastName}`,
      first_name: `${provider.FirstName} ${provider.MiddleName}`,
      last_name: provider.LastName,
      gender: 'male',
      designation: 'MS',
      prefix: 'Dr',
      regno: provider.NPI,
      client: {email: `${provider.FirstName.toLocaleLowerCase().replace(' ', '_')}@mailinator.com`,
        contact_numbers: [{type: 'phone', value: provider.Phone}],
        address: {line_1: provider.Address.Address1, line_2: provider.Address.Address2, postal_code: provider.Address.ZipCode,
          city_area: {city: {name: provider.Address.City, state: provider.Address.State}}
        }
      }
    }
  }

  addLocationToProfile(location) {
    let new_location = new Location().load_from_json(this.map_location_to_json(location));
    this.locationService.create(new_location).then(resp => {
      let new_location = new Location().load_from_json(resp);
      this.practice.locations.push(new_location);
      this.practice_location_ids.push(resp.third_party_location_id);
    }).catch(resp => {
      this.errorHandler.error = resp.error['error'];
    })
  }

  map_location_to_json(location) {
    return {
      third_party_location_id: location.Id,
      practice_id: this.credential.practice_id,
      is_bookable: true,
      email: `${ location.Name.toLocaleLowerCase().replace(' ', '_') }@mailinator.com`,
      name: location.Name,
      specialties: [new Specialty],
      contact_numbers: [{type: 'phone', value: location.Phone}, {type: 'fax', value: ''}],
      address: {line_1: location.Address.Address1, line_2: location.Address.Address2, postal_code: location.Address.ZipCode,
      city_area: {city: {name: location.Address.City, state: location.Address.State}}}
    }
  }

  saveDoctor(doctor: Doctor) {
    this.doctorService.save(doctor).then(resp => {
      let new_doctor = new Doctor().load_from_json(resp);
      this.doctors = this.doctors.map(d => {return d.id == new_doctor.id ? new_doctor : d});
      this.doctors = this.doctors.filter(d => !d.third_party_doctor_id)
    })
  }

  saveLocation(location: Location) {
    this.locationService.save(location).then(resp => {
      let new_location = new Location().load_from_json(resp);
      this.practice_locations = this.practice_locations.map(l => {return l.id == new_location.id ? new_location : l});
      this.practice_locations = this.practice_locations.filter(l => !l.third_party_location_id)
    })
  }

  saveResourceLocation(location: Location) {
    this.locationService.save(location).then(resp => {
      let new_location = new Location().load_from_json(resp);
      this.practice_resource_locations = this.practice_resource_locations.map(l => {return l.id == new_location.id ? new_location : l});
      this.practice_resource_locations = this.practice_resource_locations.filter(l => !l.third_party_resource_id)
    })
  }



}
