import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {MessageService} from '../../../../services/message.service';
import {UserService} from '../../../../services/user.service';
import {Chatroom, ChatroomUser} from '../chatroom';
import {AppointmentService} from '../../../../services/appointment.service';

@Component({
  selector: 'app-chat-users',
  templateUrl: './chat-users.component.html',
  styleUrls: ['./chat-users.component.scss']
})
export class ChatUsersComponent implements OnInit, OnChanges {

  @Input() public chatroom: Chatroom;
  @Input() public show_recipients = false;

  constructor(public messageService: MessageService, public _user_service: UserService, public appointmentService: AppointmentService) {
  }

  ngOnInit() {
  }

  get recipients(): ChatroomUser[] {
    return this.chatroom.new_recipients;
  }

  set recipients(val) {
    this.chatroom.new_recipients = val;
  }

  get chatroom_users() {
    if (this.show_recipients) {
      return this.recipients;
    }
    else {
    return this.chatroom.chatroom_users;
    }
  }

  loadRecipients() {
    this.recipients = [];
    this.messageService.getRecipients(undefined, this.chatroom.is_group).then(data => {
    this.recipients = data.map(r => new ChatroomUser().load_from_json(r, true)).filter(cu => !this.chatroom.recipientsExist(cu.user_id));
    });
    this.show_recipients = true;
  }

  ngOnChanges() {
    if (this.show_recipients) {
      this.loadRecipients();
    }
  }
  toggleSelection(user) {
    user.selected = !user.selected;
  }

}
