<div class="flex-control flex-column flex-height">
  <app-practice-header class="flex-header" *ngIf="practice.id" (edit_practice)="syn_practice($event)" [practice]="practice"></app-practice-header>
  <div class="flex-body">
    <div class="flex-control">
      <div *ngIf="practice.id" class="flex-left wizard-component scroll-browser">
        <div class="f1-steps">
          <div class="f1-progress">
            <div class="f1-progress-line" data-now-value="12.5" data-number-of-steps="7"  [style.width]="width"></div>
          </div>
          <div class="f1-step" [class.active]="step >= 1">
            <a (click)="setStep(1)" class="f1-step-icon"><i  class="fa fa-hospital-o"></i></a>
            <p>Practice</p>
          </div>
          <div class="f1-step" [class.active]="step >= 2">
            <a (click)="setStep(2)" class="f1-step-icon"><i  class="fa fa-map-marker"></i></a>
            <p>Locations</p>
          </div>
          <div class="f1-step" [class.active]="step >= 3">
            <a [class.unclickable]="!practice.is_persisted()"  (click)="setStep(3)" class="f1-step-icon"><i class="fa fa-user-md"></i></a>
            <p>Doctors</p>
          </div>
          <div class="f1-step" [class.active]="step >= 4">
            <a [class.unclickable]="!practice.is_persisted()" (click)="setStep(4)" class="f1-step-icon"><i  class="fa fa-medkit"></i></a>
            <p>Resources</p>
          </div>
          <div class="f1-step" [class.active]="step >= 5">
            <a [class.unclickable]="!practice.is_persisted()" (click)="setStep(5)" class="f1-step-icon"><i  class="fa fa-gear"></i></a>
            <p>Settings</p>
          </div>
          <div class="f1-step" [class.active]="step >= 6">
            <a [class.unclickable]="!practice.is_persisted()" (click)="setStep(6)" class="f1-step-icon"><i class="fa fa-money"></i></a>
            <p>Billing</p>
          </div>
          <div class="f1-step" [class.active]="step == 7">
            <a [class.unclickable]="!practice.is_persisted()" (click)="setStep(7)" class="f1-step-icon"><i class="fa fa-check"></i></a>
            <p>Confirmation</p>
          </div>
        </div>
      </div>
      <div class="flex-body scroll-browser">
        <app-practice *ngIf="step == 1 && practice.id"  [practice_permissions]="practice_permissions" [practice]="practice"></app-practice>
        <app-locations *ngIf="step == 2 && practice.id" [doctor_permissions]="doctor_permissions" [practice_permissions]="practice_permissions" [practice]="practice"></app-locations>
        <app-doctors-listing  *ngIf="step == 3 && practice.id" [doctor_permissions]="doctor_permissions" [practice]="practice"></app-doctors-listing>
        <app-services [practice_permissions]="practice_permissions"  *ngIf="step == 4 && practice.id" [practice]="practice"></app-services>
        <app-practice-settings [practice_permissions]="practice_permissions" *ngIf="step == 5 && practice.id" [practice]="practice"></app-practice-settings>
        <app-billing *ngIf="step == 6 && practice.id" [practice]="practice"></app-billing>
        <app-confirmation [practice_permissions]="practice_permissions" *ngIf="step == 7 && practice.id" [practice]="practice"></app-confirmation>
      </div>
    </div>
  </div>
</div>
