import {Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter} from '@angular/core';
import {TermComponent} from "../term/term.component";
import {EnoteService} from "../../services/enote.service";
import {LoaderService} from "../../services/loader.service";
import {Observable} from 'rxjs';
import {mergeMap} from "rxjs/operators";
import {Term} from "../enote_models/term";
import {TermControl} from "../enote_models/term_control";
import {TermControlValueProperty} from "../enote_models/term_control_value_property";
import {Control} from "../enote_models/control";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss']
})

export class CreateNoteComponent extends TermComponent implements OnInit {

  public data_source: Observable<any>;
  public token: string;
  public save_in_progress: boolean = false;
  propertyModal: BsModalRef;
  public search_token: string = '';
  public first_data_source: Observable<any>;
  public second_data_source: Observable<any>;
  public default_term_control: TermControl;
  public term_control_value_properties: TermControlValueProperty[] = [];
  public normal_term_control_value_property: TermControlValueProperty;
  public abnormal_term_control_value_property: TermControlValueProperty;
  public optionsModel: any[];
  showMessage: boolean;




  constructor(public _enote_service: EnoteService,public modalService: BsModalService,public loaderService: LoaderService) {
    super(_enote_service,modalService,loaderService);
    this.getTermOnFly();
    this.first_data_source = Observable.create((observer: any) => {
      observer.next(this.normal_term_control_value_property.prop_value);
    }).pipe(
      mergeMap((token: string) => {
        return this._enote_service.term_search(this.normal_term_control_value_property.prop_value,'general')
      })
    );
    this.second_data_source = Observable.create((observer: any) => {
      observer.next(this.abnormal_term_control_value_property.prop_value);
    }).pipe(
      mergeMap((token: string) => {
        return this._enote_service.term_search(this.abnormal_term_control_value_property.prop_value,'general')
      })
    );

  }

  ngOnInit() { }
  getTermOnFly() {
    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).pipe(
      mergeMap((token: string) => {
        let lib_id;
        let lib_type;
        if (this.term.parent_term && this.term.parent_term.library_datum_id)
          lib_id = this.term.parent_term.library_id;
        else
          lib_type = 'component';
        return this._enote_service.term_search(this.token,lib_id,lib_type,this.term.parent_term.parent_term && this.term.parent_term.parent_term.library_datum_id,this.term)
      })
    );

  }
  getResutlData(keyword){
    if(this.term.term_type == 'keyword')
      this._enote_service.keyword_and_child_result_data(keyword.id).then(resp => {
        keyword.keyword_data_result = resp;
        this.term.show_keyword_result = !this.term.show_keyword_result;
        this.myOptions = resp;
        this.tiggerTypeahead();
        console.log(this.myOptions)
      })
    return false;
  }
  tiggerTypeahead(){
    setTimeout(() => {
      const element1: HTMLElement = document.getElementById(this.term.id.toString())
      if(element1)
        var element: HTMLElement = element1.getElementsByClassName('dropdown-toggle')[0] as HTMLElement;
      if(element)
        element.style.display = 'none';
      const event = new Event('click', {
        'bubbles': true,
        'cancelable': true
      });
      if(element)
        element.dispatchEvent(event);
    },5)
  }
  OnSelect(){
    let selectedTerm:any = this.myOptions.filter(a => a.id == this.optionsModel[0])[0];
    this.term.name = selectedTerm.name;
    this.term.library_datum_id = selectedTerm.library_datum_id;
    this.term.show_keyword_result = false;
    this.term.term_type = 'term';
    this.setTermControlValueProp(this.term);
  }

  toggleKeywordTerm(e: any){
    e.item.library_datum_id = null;
    e.item.term_type = 'keyword';
    e.item.name = null;
    e.item.control_value_class = 'no-observation';
    e.item.enable_custom_control = false;
    e.item.reIntiazlizeTermControlValues();
    this.term.defaultTermControl.term_control_value_properties = [];
    this.term.defaultTermControl.resetDefaultContol();
    this.term_control_value_properties = [];
    console.log(e.item)
  }

  showModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  public typeaheadOnSelectAddTerm(e: any): Term {

    let term_json = e.item.term_type == 'keyword' ? {name: null,component_type: 'general',term_type: 'keyword'} : e.item;
    if(e.item.term_type == 'keyword')
      term_json['keyword'] = e.item.keyword;
    console.log(e.item,term_json);
    let new_term = new Term('','');
    let parent_term = this.term.parent_term;
    let term_position = this.term.term_position() +1;
    if(parent_term.initial_component && parent_term.parent_term){
      term_position = parent_term.term_position() + 1;
      parent_term = parent_term.parent_term;
    }
    new_term.load_from_json(term_json,this.term.parent_term,this.term.term_position(),this._enote_service);
    parent_term.addToChildren(new_term, term_position);
    if(new_term.isComponentTypeChild() && !new_term.is_open_text)
      new_term.setComponentLevel(new_term.parent_term);
    console.log(new_term,this.term);
    new_term.term_controls = this.term.term_controls.map(a => new TermControl().copy_term_control(a))
    console.log(new_term.term_controls,this.term.term_controls);
    new_term.term_custom_controls = new_term.term_controls.filter(a => a.control.isCustomControl());
    this.setTermControlValueProp(new_term);
    console.log(new_term);
    return new_term;
  }

  resetToken() {
    this.token = ''
  }

  public checkString(event) {
    if (event.key == 'Escape' && this.token.length != 0) {
      this._enote_service.create_term(this.token).then(resp => {
        this.typeaheadOnSelectAddTerm({item: resp})
      })
    }
  }

  openPropteryModal(termPropertyTemplate: TemplateRef<any>) {
    this.setTermControlValueProp(this.term);
    this.propertyModal = this.modalService.show(termPropertyTemplate);
  }
  setTermControlValueProp(term?: Term){
    if(term.defaultTermControl.term_control_value_properties.length > 0){
      this.term_control_value_properties = term.defaultTermControl.term_control_value_properties;
      this.setNormalAbnormalValueProperties(term);
      return
    }
    term.defaultTermControl.term_control_values.forEach(term_control_value => {
      let term_control_value_property = new TermControlValueProperty();
      term_control_value_property.control_value_id = term_control_value.value.id;
      term_control_value_property.prop_type = 'text';
      term_control_value_property.value = term_control_value.value;
      this.term_control_value_properties.push(term_control_value_property);
      this.setNormalAbnormalValueProperties(term);
    });
  }

  setNormalAbnormalValueProperties(term?: Term){
    this.term_control_value_properties.forEach(a => {
      if(a.value.is_normal == null || a.value.is_normal == undefined)
        return;
      if(a.value.is_normal){
        this.normal_term_control_value_property = a;
        if(this.term.defaultTermControl.control.name == "Observation" && !a.term_id && !a.prop_value)
          this.normal_term_control_value_property.prop_value = term.name;
        if(this.term.defaultTermControl.control.name == "Present/Absent" && !a.term_id && !a.prop_value)
          this.normal_term_control_value_property.prop_value = term.name ? 'No'+' '+term.name : null;
        if(this.term.defaultTermControl.control.name == "Normal/Abnormal" && !a.term_id && !a.prop_value)
          this.normal_term_control_value_property.prop_value = term.name ? term.name+' '+'normal' : null;
      }
      if(!a.value.is_normal){
        this.abnormal_term_control_value_property = a;
        this.abnormal_term_control_value_property.enable_custom_control = true;
        if(this.term.defaultTermControl.control.name == "Observation" && !a.term_id && !a.prop_value)
          this.abnormal_term_control_value_property.prop_value = term.name;
        if(this.term.defaultTermControl.control.name == "Present/Absent" && !a.term_id && !a.prop_value)
          this.abnormal_term_control_value_property.prop_value = term.name;
        if(this.term.defaultTermControl.control.name == "Normal/Abnormal" && !a.term_id && !a.prop_value)
          this.abnormal_term_control_value_property.prop_value = term.name ? term.name+' '+'abnormal' : null;

      }

    });
    term.defaultTermControl.term_control_value_properties = this.term_control_value_properties
  }
  onChange(control_id){
    let seletedControl = this._enote_service.default_controls.filter(d_control => d_control.id == control_id)[0];
    this.term.term_controls.forEach(t_control => {
      if(t_control.isDefualtTermControl())
        t_control._destroy = true;
    })
    let new_term_control = new TermControl();
    new_term_control.control_id = seletedControl.id;
    new_term_control.control = new Control('', '').load_from_json(seletedControl);
    new_term_control.copyDefautlControlDefaultValue(new_term_control);
    this.term.term_controls.unshift(new_term_control);
    this.term_control_value_properties = [];
    this.setTermControlValueProp(this.term);
    console.log(this.term.defaultTermControl);
  }
  saveModal(template: TemplateRef<any>){
    this.term.defaultTermControl.term_control_value_properties = this.term_control_value_properties
    this.propertyModal.hide();
  }
  cancelModal(template: TemplateRef<any>){
    this.propertyModal.hide();
  }
}
