import {LayoutComponent} from '../layout/layout.component';
import {TasksComponent} from './practice-site/tasks/tasks.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PermissionsComponent} from './permissions/permissions.component';
import {PracticesComponent} from './practices/practices/practices.component';
import {NewPracticeComponent} from './practices/practices/new-practice.component';
import { AllDoctorsComponent } from './practices/all-doctors/all-doctors.component';
import {PracticeComponent} from './practices/practices/practice.component';
import  {LoginComponent} from '../shared/login/login.component';
import { LeadsComponent } from './leads/leads.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { RecoverComponent } from './recover/recover.component';
import { DoctorsListingComponent } from './practices/doctors/doctors_listing/doctors-listing.component'
import { DoctorProfileDetailComponent } from './practices/doctors/doctor_profile/doctor-profile-detail.component'
import { RatingComponent } from './rating/rating.component'
import { PatientReviewsComponent } from './rating/patient-reviews/patient-reviews.component'
import { UsersComponent } from './users/users.component'
import {EnterpriseAppointmentsComponent} from './enterprise-appointments/enterprise-appointments.component';
import {PatientsComponent} from './patients/patients.component';
import {ReceptionComponent} from './practice-site/reception/reception.component';
import {AdmissionsComponent} from './practice-site/admissions/admissions.component';
import  {ChatComponent} from './practice-site/chat/chat.component';
import {AnnouncementsComponent} from './announcements/announcements.component';
import {PracticeDashboardComponent} from './practice-dashboard/practice-dashboard.component';
import {PracticeProfileComponent} from './practice-profile/practice-profile.component';
import {HtmlComponent} from './html/html.component';
import {VisitsComponent} from './practice-site/visits/visits.component';
import {EmergencyComponent} from './practice-site/emergency/emergency.component';
import {HttpTokenService} from '../services/http.token.service';
import {TemplatesComponent} from '../enote/templates/templates.component';
import {LibraryImporterComponent} from './library-importer/library-importer.component';
import {IntegratedVendorComponent} from '../integration/integrated-vendor/integrated-vendor.component';
import {ReviewsDashboardComponent} from '../reviews/reviews-dashboard/reviews-dashboard.component';
import {ReviewsComponent} from '../reviews/reviews/reviews.component';
import {SurveysComponent} from '../reviews/surveys/surveys.component';
import {ReviewsSetupComponent} from '../reviews/reviews-setup/reviews-setup.component';
import {TeleMedicineComponent} from './practice-site/tele-medicine/tele-medicine.component';
import {SchedulerComponent} from './practice-site/scheduler/scheduler.component';
import {RemindersComponent} from '../reminders/reminders/reminders.component';
import {RoutePermissionGuardGuard} from '../route-permission-guard.guard';
import {EmailMarketingComponent} from '../marketing/email-marketing/email-marketing.component';
import {SmsMarketingComponent} from '../marketing/sms-marketing/sms-marketing.component';
import {AmenityComponent} from './practices/amenity/amenity.component';
import {ProgramDashboardComponent} from '../program/program-dashboard/program-dashboard.component';
import {ProgramsComponent} from '../program/programs/programs.component';
import {EligiblePatientsComponent} from '../program/eligible-patients/eligible-patients.component';
import {BillsComponent} from '../program/bills/bills.component';
import {NoteComponentsComponent} from './note-component/note-components/note-components.component';
import {AlertTemplateIndexComponent} from './alert-template-index/alert-template-index.component';
import {SetPasswordComponent} from './set-password/set-password.component';
import {RichTextTemplateIndexComponent} from './rich-text-template-index/rich-text-template-index.component';
import { Routes } from '@angular/router';
import {UserProfileSettingsComponent} from './user-profile-settings/user-profile-settings.component';
import {SmsComponent} from './practice-site/sms/sms.component';
import {PrivateProgramBillsComponent} from '../program/private-program-bills/private-program-bills.component';
import {PrivateProgramsComponent} from '../program/private-programs/private-programs.component';
import {IntakeFormTemplateIndexComponent} from './intake-form-template-index/intake-form-template-index.component';
import {PatientIntakeFormIndexComponent} from './practice-site/patient-intake-form-index/patient-intake-form-index.component';
import {PatientIntakeComponent} from '../shared/patient-intake/patient-intake.component';
export const routes_mappings: Routes = [
  {
    path: 'intake-form', component: PatientIntakeComponent,
  },
  {
    path: 'login', component: LoginComponent,
  },
  {
    path: 'change-password', component: ChangePasswordComponent
  },
  {
    path: 'set-password', component: SetPasswordComponent
  },
  {
    path: 'recover', component: RecoverComponent,
  },
  {
    path: 'home', redirectTo: '', pathMatch: 'full'
  },
  {
    path: 'enterprise', component: LayoutComponent, canActivate: [HttpTokenService],
    children: [
      {
        path: 'reviews',   redirectTo: 'reviews/dashboard', pathMatch: 'full'
      },
      {
        path: 'settings', redirectTo: 'settings/permissions', pathMatch: 'full'
      },
      {
        path: '', redirectTo: 'home/dashboard', pathMatch: 'full'
      },
      {
        path: 'profile-settings', component: UserProfileSettingsComponent, pathMatch: 'full'
      },
      {
        path: 'program',
        children: [
          {
            path: 'dashboard', component: ProgramDashboardComponent
          },
          {
            path: 'programs', component: ProgramsComponent
          },
          {
            path: 'eligible', component: EligiblePatientsComponent
          },
          {
            path: 'private-bills', component: PrivateProgramBillsComponent
          },

          {
            path: 'bills', component: BillsComponent
          },
          {
            path: 'private-programs', component: PrivateProgramsComponent
          }
        ]
      },
      {
        path: 'reviews',
        children: [
          {
            path: '', component: ReviewsComponent
          },
          {
            path: 'dashboard', component: ReviewsDashboardComponent
          },
          {
            path: 'surveys', component: SurveysComponent
          },
          {
            path: 'setup', component: ReviewsSetupComponent
          }
        ]
      },
      {
        path: 'home',
        children: [
          {
            path: 'appointments', component: EnterpriseAppointmentsComponent
          },
          {
            path: 'dashboard', component: PracticeDashboardComponent
          }
          ,
          {
            path: 'enterprise-dashboard', component: DashboardComponent
          },
          {
            path: 'reception', component: ReceptionComponent
          },
          {
            path: 'admissions', component: AdmissionsComponent
          },
          {
            path: 'messages', component: ChatComponent
          },
          {
            path: 'sms', component: SmsComponent
          },
          {
            path: 'tasks', component: TasksComponent
          }
        ]
      },
      {
        path: 'patients' , children: [
          {
            path: 'search', component: PatientsComponent
          }, {
            path: 'reminders', component: RemindersComponent
          }, {
            path: 'email-marketing', component: EmailMarketingComponent
          }, {
            path: 'sms-marketing', component: SmsMarketingComponent
          }, {
            path: 'today', component: ReceptionComponent
          }
        ]
      },
      {
        path: 'reception' , children: [
          {
            path: 'appointments', component: EnterpriseAppointmentsComponent
          },
          {
            path: 'today', component: ReceptionComponent
          },
          {
            path: 'telemed', component: TeleMedicineComponent, canActivate: [RoutePermissionGuardGuard]
          },
          {
            path: 'scheduler', component: SchedulerComponent
          },
          {
            path: 'visits', component: VisitsComponent
          },
          {
            path: 'admissions', component: AdmissionsComponent
          },
          {
            path: 'emergency', component: EmergencyComponent
          },
          {
            path: 'patient-intake-forms', component: PatientIntakeFormIndexComponent
          }
        ]
      },
      {
        path: 'manage',
        children: [
          {
            path: 'practices', component: PracticesComponent,
            children: [
              {
                path: 'practice/:id', component: PracticeComponent
              },
              {
                path: 'new_practice', component: NewPracticeComponent
              },
              {
                path: 'doctors', component: DoctorsListingComponent
              }
            ]
          },
          {
            path: 'patients', component: PatientsComponent
          },
          {
            path: 'doctors', component: AllDoctorsComponent
          },
          {
            path: 'announcements', component: AnnouncementsComponent

          }
        ]
      },
      {
        path: 'online',
        children: [
          {
            path: 'leads', component: LeadsComponent
          },
          {
            path: 'appointments', component: EnterpriseAppointmentsComponent
          },
          {
            path: 'reviews', component: PatientReviewsComponent
          },
        ]
      },
      {
        path: 'settings',
        children: [
          {
            path: 'permissions', component: PermissionsComponent
          },
          {
            path: 'note_component', component: NoteComponentsComponent
          },
          {
            path: 'amenity', component: AmenityComponent
          },
          {
            path: 'practice', component: PracticeProfileComponent
          },
          {
            path: 'rating', component: RatingComponent
          },
          {
            path: 'users', component: UsersComponent
          },
          {
            path: 'intake-form', component: IntakeFormTemplateIndexComponent
          },
          {
            path: 'doctor', component: DoctorProfileDetailComponent
          },
          {
            path: 'html', component: HtmlComponent
          },
          {
            path: 'importer', component: LibraryImporterComponent
          },
          {
            path: 'templates', component: TemplatesComponent
          },
          {
            path: 'integrations', component: IntegratedVendorComponent
          },
          {
            path: 'alert_template', component: AlertTemplateIndexComponent
          },
          {
            path: 'rich-text-template-index', component: RichTextTemplateIndexComponent
          }
        ]
      }
    ]
  }
];

export const routes = routes_mappings;
