import { Component, OnInit, Input } from '@angular/core';
import {TabsService} from '../../../../services/tabs.service';
import {Tab} from '../../../../tabs/tab';
import {PaymentComponent} from '../../payment/payment.component';
import {ScanService} from '../../../../services/scan.service';
import {ScanComponent} from '../status-actions/scan/scan.component';
import {Case} from '../../case';
import {AppointmentService} from '../../../../services/appointment.service';
import { AdmitComponent } from '../../../practice-site/admit/admit.component';
import {EmergencyService} from '../../../../services/emergency.service';
import {VitalComponent} from '../../vital/vital.component';
@Component({
  selector: 'app-emergency-statuses',
  templateUrl: './emergency-statuses.component.html',
  styleUrls: ['./emergency-statuses.component.scss']
})
export class EmergencyStatusesComponent implements OnInit {
  @Input() appointment;
  public pending_pages: any = '';
  public label_value: string;
  public rejection_reason = '';
  public checkedin_cancel_reason = 'Patient checkedin by error';
  constructor(public emergency_service: EmergencyService, private _appointment_service: AppointmentService, public scan_service: ScanService, private _tabs_service: TabsService) { }

  ngOnInit() {
    this.scan_service.get_waiting(this.appointment).then(response => {
      this.pending_pages = response;
      this.scan_service.set_data(this.pending_pages.length, this.appointment.id);
    });
  }
  add_vital_to_tabs(title) {
    let appointment_id = this.appointment.id + title;
    this._tabs_service.add_tab(new Tab({title: this.appointment.patient_name() + '-' + title, icon: 'fa fa-hospital', autorefresh: false}, VitalComponent, appointment_id, {id: this.appointment.id}))
  }
  add_payment_to_tabs(title) {
    let appointment_id = this.appointment.id + title;
    this._tabs_service.add_tab(new Tab({title: this.appointment.patient_name() + '-' + title, icon: 'fa fa-hospital', autorefresh: false}, PaymentComponent, appointment_id, {id: this.appointment.id, paymentFor: 'Appointment', status: title}))
  }

  add_to_tabs(title) {
    this._tabs_service.add_tab(new Tab({title: this.appointment.patient_name() + '-' + title, icon: 'fa fa-hospital'}, ScanComponent, this.appointment.id, {id: this.appointment.id, appointment: this.appointment, case: new Case()}))
  }

  add_to_admit_tabs(title) {
    this._tabs_service.add_tab(new Tab({title: this.appointment.patient_name() + '-' + title, icon: 'fa fa-hospital'}, AdmitComponent, this.appointment.id, {id: this.appointment.id, appointment: this.appointment, case: new Case()}))
  }

  set_label(label_text) {
    this.label_value = label_text;
  }

  change_status() {
    let status = this.update_status();
    this._appointment_service.change_status(this.appointment.id, status, this.rejection_reason).then(res => {
      this.appointment = this.appointment.load_from_json(res.appointment);
      this.emergency_service.applyFilters();

    });
  }

  update_status() {
    if (this.label_value == 'Cancel') {
      return 'cancelled'
    }
  }

}
