<div class="table-responsiv">
  <table class="table table-hover table-striped">
    <thead>
    <tr>
      <th>Name</th>
      <th>{{handleStaffLable()}} Name</th>
      <th>User Name</th>
      <th>Role</th>
      <th>Phone</th>
      <th>Email</th>
      <th>Status</th>
      <th *ngIf="userService.current_user.isEnterprise">Action</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let user of filter_users_listing">
      <tr>
        <td><a (click)="addToTab(user)" >{{user.first_name}}</a></td>
        <td><a (click)="addToTabProfileAble(user)">{{user.profileable_name}}</a></td>
        <td>{{user.username}}</td>
        <td>{{user.roles_users[0]?.role_user_name}}</td>
        <td>{{user.contact_numbers[0]?.value}}</td>
        <td>{{user.email}}</td>
        <td>
          <select *userCan="'activeinactive,users,settings'" #state [(ngModel)]="user.is_active" (change)="triggerUpdate(user,user.id,user.is_active)" name="active" [ngClass]="user.is_active == true ? 'active' : 'inactive'" class="form-control short-select">
            <option value="true">Active</option>
            <option value="false">In Active</option>
          </select>
        </td>
        <td *ngIf="userService.current_user.isEnterprise" (click)="login(user)"><button class="btn btn-warning ml-sm">LOGIN</button></td>
      </tr>
    </ng-container>
    </tbody>
  </table>

</div>
<div class="flex-footer text-center" *ngIf="filter_users_listing && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="4" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
