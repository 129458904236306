<h4 class="m0 mb" *ngIf="!isTrainingDevice">
  Configure Devices
  <a class="fs14 ml" (click)="addDevice()" title="Add Devices"><i class="fa fa-plus fs12"></i> Add</a>
</h4>
<table class="table table-hover m0">
  <thead>
    <tr>
      <th style="min-width:80px;">Name</th>
      <th>ID</th>
      <!--<th>Model</th>-->
      <ng-container *ngIf="!isTrainingDevice">
        <th>MAC Address</th>
        <th>Language</th>
      </ng-container>
      <th>Time</th>
      <th>Status</th>
      <ng-container *ngIf="isTrainingDevice">
        <th>Training</th>
        <th>When</th>
        <th>By</th>
      </ng-container>
    </tr>
  </thead>
  <tbody *ngIf="devices.length > 0">
  <ng-container *ngFor="let device of devices">
    <tr>
      <td class="p-relative"><a (click)="editDevice(device)">{{device.name}}</a></td>
      <td>{{device.id}}</td>
      <!--<td>{{device.d_model}}</td>-->
      <ng-container *ngIf="!isTrainingDevice">
        <td>{{device.mac_address}}</td>
        <td>{{userService.removeUnderScore(device.language)}}</td>
      </ng-container>
      <td>{{device.config_at_datetime}}</td>
      <td>{{userService.removeUnderScore(device.status)}}</td>
      <ng-container *ngIf="isTrainingDevice">
        <td style="max-width:250px;">{{device.training_status ? 'Yes' : 'No'}} {{device.comments ? (' - ' + device.comments) : ''}}</td>
        <td>{{device.training_datetime}}</td>
        <td>{{device.trainer_name}}</td>
      </ng-container>
    </tr>
  </ng-container>
  </tbody>
</table>
<app-no-result-found *ngIf="devices && devices.length == 0"></app-no-result-found>
<div class="flex-footer text-center" *ngIf="devices && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
<div class="modal fade" bsModal #addDeviceModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:505px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="addDeviceModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">
          <span *ngIf="!isTrainingDevice">{{device.id ? 'Edit' : 'Add'}}</span>
          <span *ngIf="isTrainingDevice">{{device.trained ? 'Edit' : 'Add'}}</span> Device
          <span *ngIf="isTrainingDevice"> Training</span> </h4>
      </div>
      <app-device [patient]="patient" [device]="device" [isTrainingDevice]="isTrainingDevice" (closeDeviceModel)="closeDeviceModel()"></app-device>
    </div>
  </div>
</div>
