<ng-container *ngIf="credential">
  <div class="row form-group">
    <div class="col-sm-3 col-xs-12">
      <label>Integrate with</label>
      <select class="form-control" [disabled]="credential.id" name="integration_flow"
              (change)="setVendor($event.target.value)" [(ngModel)]="credential.pms_vendor_id">
        <option [value]="'0'">Select</option>
        <ng-container *ngFor="let vendor of pms_vendors">
          <option [value]="vendor.id">{{vendor.name}}</option>
        </ng-container>
      </select>
    </div>
  </div>
  <ng-container *ngIf="credential.pms_vendor?.toLowerCase() == 'curemd'">
    <app-curemd-integration-credentials class="d-block" [doctors] ="doctor_listing_array" [practice]="practice" [credential]="credential"></app-curemd-integration-credentials>
  </ng-container>
</ng-container>
