<div class="row">
  <div class="col-sm-12 pl-xl">
    <span [ngClass]="status" *ngIf="status == 'error'">{{message}}</span>
  </div>
</div>
<form (ngSubmit)="verifyMRN()" #MRNForm="ngForm" class="" *ngIf="status == 'valid' || status == 'waiting'">
  <div class="col sm-12 pl-xl">
    Provide your mobile number which is already associated with your patient profile
  </div>
  <div class="col-sm-2 form-group pl-xl">
    <label></label>
    <input type="text" name="mobile_otp" placeholder="Mobile Number" [disabled]="status == 'waiting'" class="form-control" [(ngModel)]="data.mobile_number" required #mobile_number/>
    <span class="error" *ngIf="status != 'waiting'">{{message}}</span>
  </div>
  <div class="col-sm-1 form-group">
    <label class="d-block">&nbsp;</label>
    <button type="submit" class="btn btn-primary" [disabled]="!MRNForm.form.valid" *ngIf="status == 'valid'">Verify</button>
    <h5 class="label label-success fs13" *ngIf="status == 'waiting'"><i class="fa fa-check fa-fw"></i> Verified</h5>
  </div>
</form>

<ng-container *ngIf="status == 'waiting'">
  <form (ngSubmit)="verifyOtpMRN()" #PhoneForm="ngForm" class="col-sm-12">
    <div class="col-sm-2 form-group">
      <label>Mobile Verification Code*</label>
      <input type="text" name="mobile_otp"  class="form-control" [(ngModel)]="data.mobile_otp" required #mobile_otp/>
      <span [ngClass]="message_class">{{message}}</span>
    </div>
    <div class="col-sm-1 form-group">
      <label class="d-block">&nbsp;</label>
      <button type="submit" class="btn btn-primary" [disabled]="!PhoneForm.form.valid">Verify</button>
    </div>
  </form>
  <div class="col-sm-5 form-group pl-xl">
    <h4><a class="btn-link" (click)="sendOtp()">Resend</a></h4>
    <p class="m0 fs12">If you haven't received the Mobile Verification Code within one minute, click "Resend".</p>
    <span class="message_class" >{{general_message}}</span>
  </div>
</ng-container>
<ng-container *ngIf="status == 'verified'" >
  <home-page-sign-up [patient]="patient"></home-page-sign-up>
</ng-container>
