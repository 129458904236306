import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-program-integration-index',
  templateUrl: './program-integration-index.component.html',
  styleUrls: ['./program-integration-index.component.scss']
})
export class ProgramIntegrationIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
