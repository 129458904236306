export class RichTextTemplate {
  id: number;
  name: string;
  text_type: string;
  body = '';
  status: string;
  is_enterprise: boolean;
  author_id: number;
  owner_id: number;
  last_modifier_id: number;
  last_modified_at: Date;
  created_at: Date;
  updated_at: Date;
  author_name: string;
  owner_name: string;
  modifier_name: any;
  is_import = false;
  attachable_ids: any[] = [];
  sourceable_id: number;
  sourceable_type: string;
  is_rich_text: boolean;
  time_passed_ago: string;
  added_to_medical_chart: boolean;
  document_type_name = 'Rich Text';

  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.text_type = json.text_type;
    this.status = json.status;
    this.sourceable_id = json.sourceable_id;
    this.body = json.body;
    this.is_enterprise = json.is_enterprise;
    this.author_id = json.author_id;
    this.owner_id = json.owner_id;
    this.last_modified_at = json.last_modified_at;
    this.last_modifier_id = json.last_modifier_id;
    this.updated_at = json.updated_at;
    this.created_at = json.created_at;
    this.author_name = json.author_name;
    this.owner_name = json.owner_name;
    this.modifier_name = json.modifier_name;
    this.sourceable_type = json.sourceable_type;
    this.is_rich_text = json.is_rich_text;
    this.time_passed_ago = json.time_passed_ago;
    this.added_to_medical_chart = json.added_to_medical_chart
    return this;
  }

  to_params() {
    return {
      rich_text_template: {
        id: this.id,
        name: this.name,
        text_type: this.text_type,
        status: this.status,
        body: this.body,
        is_enterprise: this.is_enterprise,
        author_id: this.author_id,
        owner_id: this.owner_id,
        last_modifier_id: this.last_modifier_id,
        last_modified_at: this.last_modified_at,
        attachable_ids: this.attachable_ids,
        sourceable_type: this.sourceable_type,
        sourceable_id: this.sourceable_id
      }
    }
  }

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }
}
