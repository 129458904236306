<div class="bg-lightest-gray">
  <div class="row">
    <div class="col-md-10 col-sm-9 filters-sec">
      <div class="row enrolled-sec">
        <h4 class="col-md-2 m0">
          {{(alter_type_patients?(alter_type_patients && alter_type_patients == 'undefined') ? 'All' : userService.removeUnderScore(alter_type_patients): 'All')}}
          <label class="label label-warning">{{totalItems}}</label>
        </h4>
        <div class="col-md-2 col-sm-2">
          <select class="form-control" [(ngModel)]="filter['alert_type']" name="relation_with_patient">
            <option value=undefined selected>All Patient</option>
            <option value="alerts_only">Alerts Only</option>
            <option value="no_alerts">No Alerts</option>
            <option value="call_requested">Call Requests</option>
            <option value="opt_out">Opt Out Requested</option>
            <option value="less_than_20_mins">Less than 20 mins Billable Time</option>
            <option value="less_than_16_days">RPM Recordings less than 16 days</option>
            <option value="due_today_tasks">Task due today</option>
          </select>
        </div>
        <div class="col-md-2 col-sm-2">
          <select class="form-control" [(ngModel)]="filter['health_status']" name="health_status">
            <option value=undefined selected>Health Status</option>
            <option value='stable'>Stable</option>
            <option value='moderate'>Moderate</option>
            <option value='unstable'>Unstable</option>
            <option value='critical'>Critical</option>
          </select>
        </div>
        <div class="col-md-2 col-sm-2">
          <select class="form-control" [(ngModel)]="filter['compliance_level']" name="compliant_level">
            <option value=undefined selected>Compliance Level</option>
            <option value='compliant'>Compliant</option>
            <option value='partially_compliant'>Partially Compliant</option>
            <option value='non_compliant'>Non-Compliant</option>
          </select>
        </div>
        <div class="col-md-2 col-sm-6">
          <input type="text" [(ngModel)]="filter['token']" (keyup.enter)="search()" class="form-control" placeholder="Search Patient...">
        </div>
        <div class="col-md-1 col-sm-2">
          <a (click)="search()" class="btn btn-tertiary btn-outline"><i class="fa fa-filter"></i> Filter</a>
        </div>
      </div>
    </div>
    <div class="col-md-2 col-sm-3 text-right">
      <a class="btn btn-secondary" (click)="enrollPatient(program)">Enroll Patient</a>
    </div>
  </div>
</div>
<div class="todaysPatient-list flex-control flex-column">
  <div class="flex-body scroll-browser" *ngIf="program.enrolledPatients.length > 0">
    <ng-container *ngFor="let patient of program.enrolledPatients">
      <app-enrolled-patient-header [patient]="patient" (onPatientNameClick)="openEnrolledPatient($event)"
                                   [program_id]="program.id" [isPatientDetailPage]="false"
                                   [programEnrollment]="patient.tem_programme_enrolment">
      </app-enrolled-patient-header>
    </ng-container>
  </div>
  <div class="flex-footer text-center" *ngIf="program.enrolledPatients && totalItems > 25">
    <pagination class="m0" [totalItems]="totalItems" [boundaryLinks]="true" [maxSize]="25" [itemsPerPage]="25" (pageChanged)="pageChanged($event)"></pagination>
  </div>
  <app-no-result-found *ngIf="program.enrolledPatients && program.enrolledPatients.length == 0"></app-no-result-found>
</div>

<div class="modal fade" bsModal #selectTypeModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="selectTypeModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Select a Program Type</h4>
      </div>
      <div class="modal-body">
        <div class="row form-group mb0">
          <div class="modal-col col-md-10 col-sm-9 col-xs-12">
            <ng-container *ngFor="let type of enrolled_in">
              <label class="fw-500 mr-lg text-capitalize">
                <input type="radio" class="ml" name="{{type}}" [value]="type" [(ngModel)]="selectProgramType"/>
                {{type}}
              </label>
            </ng-container>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="selectProgramTypeToOpen();selectTypeModal.hide()" [disabled]="!selectProgramType">Continue</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="closeSelectProgramModel()">Cancel</button>
      </div>
    </div>
  </div>
</div>


