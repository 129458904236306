<div class="filters-sec">
  <div class="row">
    <div class="col-sm-2">
      <input [(ngModel)]="filter.name" autocomplete="off" (keyup.enter)="search()"
             name="appointment_date_filter" class="form-control"
             placeholder="Name"
             date-only="true"/>
    </div>
    <div class="col-sm-2">
      <select class="form-control" type="text" name="name" [(ngModel)]="filter.docTypeID">
        <option value=undefined selected>Type</option>
        <ng-container *ngFor="let docType of docTypes">
          <option [value]="docType.id">{{docType.name}}</option>
        </ng-container>
      </select>
    </div>
    <div class="col-sm-2">
      <div class="input-group">
        <input name="specialty" [required]="true" [(ngModel)]="filter.specialtyName"
               [typeahead]="specialties" typeaheadWaitMs="500"
               [typeaheadAsync]="true"
               (typeaheadLoading)="changeTypeaheadLoadingSpecialty($event)"
               (typeaheadNoResults)="changeTypeaheadNoResultsSpecialty($event)"
               (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
               (keydown)="onKeydown($event)"
               [typeaheadOptionField]="'description'" placeholder="Specialty"
               class="form-control" autocomplete="off">
        <div class="input-group-addon" *ngIf="typeaheadLoadingSpecialty"><i class="fa fa-spinner fa-spin"></i></div>
      </div>
      <div *ngIf="typeaheadNoResultsSpecialty" class="text-danger fs13">No Results Found!</div>
    </div>
    <div class="col-sm-3">
      <button type="button" class="btn btn-tertiary btn-outline mr-sm" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      <button class="btn btn-secondary" type="button" (click)="add_enote(template, 'new_temp')">Add</button>
    </div>
    <div class="col-sm-2 pull-right" *ngIf="!userService.current_user.isEnterprise">
      <div class="input-group">
        <input name="specialty" [(ngModel)]="template_search"
               [typeahead]="templates" typeaheadWaitMs="500"
               [typeaheadAsync]="true"
               (typeaheadLoading)="changeTypeaheadLoadingTemplate($event)"
               (typeaheadNoResults)="changeTypeaheadNoResultsTemplate($event)"
               (typeaheadOnSelect)="typeaheadOnTemplateSelect($event,modalTemplate)" [typeaheadOptionsLimit]="10"
               [typeaheadOptionField]="'description'" placeholder="Get Tempalte from Library"
               class="form-control" autocomplete="off">
        <div class="input-group-addon" *ngIf="typeaheadLoadingTemplate"><i class="fa fa-spinner fa-spin"></i></div>
      </div>
      <div *ngIf="typeaheadNoResultsTemplate" class="text-danger fs13">No Results Found!</div>
    </div>
  </div>
</div>
<ng-template #modalTemplate>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModal.hide()">
      <i class="fa fa-times fs16"></i>
    </button>
    <h4 class="m0 text-center">Template from Library</h4>
  </div>
  <div class="modal-body">
    <span class="pr">
      <label class="field-label pr">Name</label>
      {{readonly_template.name}}
    </span>
    <span class="pr">
      <label class="field-label pr">Document Type</label>
      {{readonly_template.document_type_name}}
    </span>
    <span class="pr">
      <label class="field-label pr">Specialty</label>
      {{readonly_template.specialtyName}}
    </span>
    <ng-container *ngIf="readonly_template.isReadOnly">
      <ng-container *ngFor="let term of readonly_template.terms; let index=index">
        <div contenteditable="false" [id]="'whole-wrapper-'+term.id" class="mb">
          <div contenteditable="false" *ngIf="term">
            <app-read-only-template [term]="term" [term_index]="index" class="mb-lg"
                                    [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-read-only-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button (click)="save_as_my_template()"  class="btn btn-secondary mr">Accept As My Template</button>
    <button (click)="hideModal()" class="btn btn-tertiary btn-outline">Cancel</button>
  </div>
</ng-template>
