import {Observable} from 'rxjs';
import {EnoteService} from '../../services/enote.service';
import {mergeMap} from 'rxjs/operators';
/**
 * Created by qaisarnadeem on 7/3/19.
 */
export class Keyword {
  id: number;
  name = '';
  result_data: any[];
  child_keywords: Keyword[] = [];
  data_source: Observable<any>;
  result_count = 0;
  keyword_data_result: any[];
  parent_id: number;
  _enote_service: EnoteService ;
  save_in_progress = false;
  library_id: number;
  constructor(_enote_service?: EnoteService) {
    this._enote_service = _enote_service;
    if (_enote_service) {
      this.data_source = Observable.create((observer: any) => {
        observer.next(this.name);
      }).pipe(
        mergeMap((token: string) => {
          return _enote_service.search_keyword(token)
        })
      );
    }

  }

  to_params() {
    return {
      id: this.id,
      name: this.name,
      parent_id: this.parent_id,
      library_id: this.library_id,
      result_data: this.result_data,
      child_keywords_attributes: this.child_keywords.filter(kw => kw.name != '').map(kw => kw.to_params())
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.library_id = json.library_id;
    this.result_data = json.result_data;
    this.keyword_data_result = json.keyword_data_result;
    this.parent_id = json.parent_id;
    this.result_count = json.result_count;
    if (json.child_keywords) {
       this.child_keywords = json.child_keywords.map(kw => new Keyword(this._enote_service).load_from_json(kw));
    }
    return this;
  }
}
