import { Injectable } from '@angular/core';
import {Invoice} from "../routes/practices/billing/invoice";
import {CustomHttpClient} from "./custom-http-client.service";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private _tokenService: CustomHttpClient) { }

  save(invoice: Invoice): Promise<any>{
    if(invoice.is_new_record())
      return this.create(invoice);
    else
      return this.update(invoice);
  }

  create(invoice: Invoice): Promise<any>  {
    return this._tokenService.post(invoice.get_url(),{invoice: invoice.to_params()})
      .toPromise()
      .then(res => res);
  }
  update(invoice: Invoice): Promise<any>  {
    return this._tokenService.put(invoice.get_url(),{invoice: invoice.to_params()})
      .toPromise()
      .then(res => res);
  }
  invoices(practice_package_id: any): Promise<any>{
    return this._tokenService.get('invoices/practice_package_invoices/'+practice_package_id)
      .toPromise()
      .then(res => res);
  }

  delete(invoiceId: any): Promise<any> {
    return this._tokenService.delete('invoices?id=' + invoiceId)
      .toPromise()
      .then(res => res);
  }
}
