import { Component, ElementRef, Inject, OnInit, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import {Practice, PracticePackage} from '../../../../routes/practices/practices/practice'
import { PracticesService } from '../../../../services/practices.service'
import {SpecialtySearchService} from '../../../../services/specialty-search.service';
import { CustomValidations } from '../../../../routes/customValidations/custom-validations'
import { NgForm } from '@angular/forms';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import { SelectComponent } from 'ng2-select'
import {ActivatedRoute, Router} from '@angular/router';
import {PackagesService} from '../../../../services/packages.service';
import {LoaderService} from '../../../../services/loader.service';
import {Observable, Subject} from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import {NgSelectComponent} from '@ng-select/ng-select';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'register-practice',
  templateUrl: './register-practice.component.html',
  styleUrls: ['./register-practice.component.scss']
})
export class RegisterPracticeComponent implements OnInit, OnChanges {
  public practice: Practice;
  @ViewChild('specialtiesInput') specialtiesInput: NgSelectComponent;
  specialtiesLoading = false;
  public customValidations: CustomValidations;
  public searchElementRef: ElementRef;
  public latitude: number;
  public longitude: number;
  public zoom = 18;
  public show_success = false;
  public items: any;
  public mask: Array<string | RegExp>;
  public value: any = {};
  public show_message = true;
  public message = '';
  public package_id: string;
  public showPayment = false;
  public package_name: string;
  public request_type: string;
  public message_for = 'free';
  public packages: any[];
  public package_message: Object =
  {
    free: 'You have successfully registered in our system. Please check your email for further details.',
    easy_going: 'You have successfully registered in our system. Please check your email for further details.',
    better_deal: 'Thank you for contacting WellWink for better deal. Our representative will contact you shortly for requirements and implementation.',
    ultimate: 'Thank you signing up with  WellWink. Our representative will contact you shortly for requirements and implementation.',
    package_update: 'Thank you for upgrading your account. If you need any help, please reach out to WellWink Customer Support at support@wellwink.com.'
  }

  @Output() submitted_practice: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('registerPracticeForm') registerPracticeForm: NgForm;
  public p_package_name: any;
  private strength = 0;
  public password_mismatch = false;
  declarativeFormCaptchaValue: any;
  constructor(public userService: UserService, private title: Title,
              private practiceService: PracticesService,
              private globalErrorHandlerService: GlobalErrorHandlerService,
              @Inject(ElementRef) elementRef: ElementRef,
              private packageService: PackagesService,
              private route: ActivatedRoute, private loaderService: LoaderService,
              private specialtySearchService: SpecialtySearchService,
              public _router: Router) {
                this.customValidations = new CustomValidations();
                this.searchElementRef = elementRef;
                this.setTitle('WellWink | Medical Facility Sign-up');
    }
  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }

  ngOnInit() {
    let practice_package = new PracticePackage();
    this.route.queryParams.subscribe(params => {
      this.package_id = params['package_id'];
      practice_package.package_id = params['package_id'];
      this.package_name = params['package_name'];
      this.request_type = params['request_type'];
      this.p_package_name = params['previous_package_name'];
      console.log(this.package_name, this.package_message[this.package_name]);
      practice_package.status = 'current';
      if (params['practice_id']) {
        this.loaderService.show();
        this.practiceService.getPracticeByHashid(params['practice_id']).then(resp => {
          this.practice = new Practice().load_from_json(resp);
          practice_package.practice_id = this.practice.id;
          this.practice.practice_package =  practice_package;
          this.practice.stage_id = 2;
          if (this.showPay()) {
             this.showPayment = true;
             this.loaderService.hide();
          }
          else {
            this.save()
          }
          this.message_for = 'package_update';
        })
      }
      if (!params['practice_id']) {
        this.practice = new Practice();
        this.practice.practice_package = practice_package;
        this.practice.stage_id = 2;
        if(this.package_name){
          this.message_for = this.package_name;
        }else {
          this.message_for = 'free'
        }
        this.practice.practice_package.package_id ? '' : this.getPackages()
      }
    });
  }
  showPay(): boolean {
    return this.p_package_name === 'free';
  }

  ngOnChanges() {
    this.setGoogleMaps();
  }

  checkPasswordStrength($event: number) {
    this.strength = $event;
  }
  public addTokenLog(message: string, token: string | null) {
    console.log(`${message}`);
  }

  onPaymentSuccesfull(paymentStatus: boolean) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.loaderService.show();
    console.log(paymentStatus, this.practice);
    this.practice.practice_package.payment_status = paymentStatus ?  'successful' : 'unsuccessful';
    if (this.practice.id && this.practice.practice_package.id) {
      this.packageService.updatePracticePackage(this.practice.practice_package).then(resp => {
        console.log(resp);
        this.show_success = true;
        this.showPayment = false;
        this.loaderService.hide();
      }).catch(resp =>  {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      });
    }
    if (this.practice.id && !this.practice.practice_package.id) {
      this.createPracticePackage();
    }

  }
  createPracticePackage() {
    this.loaderService.show();
    this.packageService.createPracticePackage(this.practice.practice_package).then(resp => {
      if (this.package_name == 'ultimate') {
        setTimeout(() => {
          console.log('me there');
          this._router.navigateByUrl('enterprise/home/dashboard?refresh=true');
        }, 3000)
      }
      this.showPayment = false;
      this.show_success = true;
      this.loaderService.hide();
    }).catch(resp =>  {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  save() {
    if (this.practice.password !== this.practice.password_confirmation) {
      this.password_mismatch = true;
      return;
    }
    if (this.practice.password && this.strength !== 100) {
      this.globalErrorHandlerService.error = 'Password is not strong enough.';
      return;
    }
    this.loaderService.show();
    this.practice.client.email = this.practice.client.email.toLowerCase();
    this.practiceService.save(this.practice).then(res => {
      if (this.practice.is_new_record() && ((this.package_name === 'ultimate' && this.request_type === 'signup') || this.package_name === 'easy_going'  || (this.package_name === 'better_deal' && this.request_type === 'signup')) && this.practice.practice_package.payment_status === 'pending') {
        this.showPayment = true;
        this.practice = this.practice.load_from_json(res)
      }
      else {
        this.message = res.message;
        this.practice = new Practice();
        this.show_success = true;
        this.showPayment = false;
      }
      this.loaderService.hide();
    }).catch(resp =>  {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
    this.setTimeOutFailsMessage();
  }

  setTimeOutFailsMessage(): any {
    setTimeout(() => {
      this.submitted_practice.emit(true); }
      , 3000)
  }

  cancel_practice_request() {
    this.submitted_practice.emit(true);
  }

  setGoogleMaps() {
    this.setCurrentPosition();
      let component = this;
      let searchBox = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement.querySelector('#city_area_name'));
      google.maps.event.addListener(searchBox, 'places_changed', function () {
        function closure(elem) {
          let place = searchBox.getPlace();
          if (!place) {
            return;
          }
          elem.practice.client.address.city_area.lat = place.geometry.location.lat();
          elem.practice.client.address.city_area.long = place.geometry.location.lng();
          elem.practice.client.address.city_area.name = place.formatted_address;
        }
        closure(component);
      })
  }

  getSpecialties(term: any) {
    return this.specialtySearchService.search(term);
  }

  practiceSpecialties() {
   return this.practice.client.specialties;
  }

  public selected(value: any): void {
    this.practice.client.add_specialty(value.id);
  }

  public removed(value: any): void {
     let index = this.practiceSpecialties().map(sp => sp.id).indexOf(value.id)
     this.practiceSpecialties().splice(index, 1);
  }

  typed(text: { term: string; items: any }) {
    this.specialtiesLoading = true;
    this.getSpecialties(text.term).subscribe(a => {
      this.specialtiesLoading = false
       return this.items = a.map(sp => {return {id: sp.id, text: sp.description}})
    });
  }
  ngAfterViewChecked() {
    this.customValidations.formChanged(this.registerPracticeForm);
  }

  area_selected(area_data) {
  //   this.lat = area_data.lat;
  //   this.long = area_data.long;
  //   this.area_text = area_data.name;
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  freePackage() {
    return this.packages.filter(p => p.name.toLowerCase() === 'free')[0];
  }

  getPackages(){
  this.packageService.getPackages().then(resp => {
    this.packages = resp
    let pack = this.freePackage();
    this.practice.practice_package.practice_id = pack.id
  });
  }
}
