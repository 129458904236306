import {Component, OnInit} from '@angular/core';
import {TabsService} from '../../services/tabs.service';
import {Tab} from '../../tabs/tab';
import {ProgramEnrollment} from '../eligible-patient';

@Component({
  selector: 'app-private-programs',
  templateUrl: './private-programs.component.html',
  styleUrls: ['./private-programs.component.scss']
})
export class PrivateProgramsComponent implements OnInit {
  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('Private Program');
  }
  ngOnInit(): void {
    this.restartActiveTabTime();
  }

  ngOnDestroy() {
    let tabs: Tab[] = this.tabs_service.tabs;
    tabs?.map((tab: Tab) => {
      if (tab.dependencies['enrolment']) {
        let enrolment: ProgramEnrollment = tab.dependencies['enrolment'];
        enrolment.timer.unsubscribe();
      }
    });
  }

  restartActiveTabTime() {
    let tabs: Tab[] = this.tabs_service.tabs;
    tabs?.map((tab: Tab) => {
      if (tab.dependencies['enrolment']) {
        let enrolment: ProgramEnrollment = tab.dependencies['enrolment'];
        enrolment.startTimer();
      }
    });
  }

}
