import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../../services/custom-http-client.service';
import {nullRemover} from '../../services/nullRemover';

@Injectable({
  providedIn: 'root'
})
export class PatientCommunicationService {

  constructor(private _tokenService: CustomHttpClient) { }

  testRun(contact_type, patient, body, subject?, practice_id?, sourceable_type?, sourceable_id?){
    let  params = {subject: subject, patient_id: patient.id, message_type: contact_type, body: body, practice_id: practice_id,
    sourceable_type: sourceable_type, sourceable_id: sourceable_id}
    params = nullRemover(params)
    console.log(params)
    return this._tokenService.post('communications', params)
      .toPromise()
      .then(resp => resp)
  }
  getSmtpPracticeEmails(){
    return this._tokenService.get('communications/smpts')
      .map((resp) => resp);
  }
}
