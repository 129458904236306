import { Injectable } from '@angular/core';
import  {RatingType, PatientReview} from '../health/doctor/patient_review'
import {CustomHttpClient} from './custom-http-client.service';


@Injectable()
export class RatingsService {
  constructor(private _tokenService: CustomHttpClient) { }
  getRatingTypes(): Promise<any[]> {
    return this._tokenService.get('patient_ratings')
      .toPromise()
      .then(response => response);
  }
  patientRatingTypes(): Promise<any[]> {
    return this._tokenService.get('patient_ratings/patient_rating_types')
      .toPromise()
      .then(response => response);
  }
  create(ratingType: RatingType): Promise<any> {
    return this._tokenService
      .post('patient_ratings/', ratingType.to_params())
      .toPromise()
      .then(res => res)
  }
  delete(ratingType: RatingType): Promise<any> {
    return this._tokenService.deleteWithHeaders('patient_ratings?id=' + ratingType.id, {observe: 'response'})
      .toPromise()
      .then(res => res);
  }
  create_patient_review(patient_review): Promise<any> {
    return this._tokenService.post('patient_ratings/patient_review', patient_review.to_params())
      .toPromise()
      .then(res => res);
  }
  getPatientReviews(status_id): Promise<any> {
    return this._tokenService.get('patient_ratings/patient_reviews?status_id=' + status_id)
      .toPromise()
      .then(res => res);
  }
  allPatientReviews(): Promise<any> {
    return this._tokenService.get('patient_ratings/all_patient_reviews')
      .toPromise()
      .then(res => res);
  }
  update(patientReview: PatientReview, id): Promise<any> {
    return this._tokenService
      .put('patient_ratings/' + id, {status: patientReview.status})
      .toPromise()
      .then(res => res)
  }
}
