import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ReviewsDashboardComponent} from './reviews-dashboard/reviews-dashboard.component';
import {ReviewsComponent} from './reviews/reviews.component';
import {SurveysComponent} from './surveys/surveys.component';
import {ReviewsSetupComponent} from './reviews-setup/reviews-setup.component';

export const routes: Routes = [
  {
    path: 'reviews',
    children: [
    {
      path: '', component: ReviewsComponent
    },
    {
      path: 'dashboard', component: ReviewsDashboardComponent
    },
    {
      path: 'surveys', component: SurveysComponent
    },
    {
      path: 'setup', component: ReviewsSetupComponent
    }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReviewsRoutingModule { }
