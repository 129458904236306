<div class="container" *ngIf="status != 'verified'">
  <div class="row">
    <div class="col-lg-5 col-md-6 col-sm-8 col-xs-12 height-container">
      <h3 class="text-primary"><strong>Already Registered</strong> Verify Your Mobile Number</h3>
      <h4 class="text-muted">Enter your mobile number, enter Verification Code to verify</h4>
      <div class="white-container-box p-xl mt-xl">
        <form (ngSubmit)="sendMobileOtp()" #MRNForm="ngForm" class="">
          <h4 class="fs16 mt0 mb-lg"><i class="fa fa-mobile-phone fs20"></i> &nbsp;Provide your mobile number</h4>
          <div class="row">
            <div class="col-xs-8">
              <input class="form-control input-lg" type="text" name="mobile_otp" placeholder="Mobile Number*" [disabled]="status == 'waiting'"
                     [(ngModel)]="data.mobile_number" required #mobile_number />
            </div>
            <div class="col-xs-4">
              <button type="submit" class="btn btn-primary btn-lg fs16 text-uppercase col-xs-12" [disabled]="!MRNForm.form.valid" *ngIf="status != 'waiting'">
                <i class="icon icon-cursor"></i> <span class="hidden-xs">Send</span>
              </button>
              <!--<button class="btn btn-warning btn-lg fs16 text-uppercase col-xs-12" (click)="edit_number()" *ngIf="status == 'waiting'">-->
                <!--<i class="fa fa-pencil"></i> <span class="hidden-xs">Edit</span>-->
              <!--</button>-->
            </div>
          </div>
          <p class="error text-danger fs13 m0 mt-lg" *ngIf="status != 'waiting'">{{message}}</p>
        </form>
        <ng-container *ngIf="status == 'waiting'">
          <form (ngSubmit)="verifyMobileOTP()" #PhoneForm="ngForm">
            <h4 class="fs16 mt-xl mb-lg"><i class="fa fa-mobile-phone fs20"></i> Mobile Verification Code</h4>
            <div class="row">
              <div class="col-xs-8">
                <input type="text" name="mobile_otp" class="form-control input-lg" [(ngModel)]="data.mobile_otp" required #mobile_otp />
                <p [ngClass]="message_class">{{message}}</p>
              </div>
              <div class="col-xs-4">
                <button type="submit" class="btn btn-primary btn-lg fs16 text-uppercase col-xs-12" [disabled]="!PhoneForm.form.valid">
                  <i class="fa fa-check"></i> <span class="hidden-xs">Verify</span>
                </button>
              </div>
            </div>
          </form>
          <a class="btn btn-inverse btn-outline btn-lg" (click)="sendMobileOtp()">Resend</a>
          <p class="m0 mt-lg fs12 text-danger">If you haven't received the Mobile Verification Code within one minute, click "Resend".</p>
          <span class="message_class">{{general_message}}</span>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!--<ng-container *ngIf="status == 'verified'" ><home-page-sign-up [patient]="patient"></home-page-sign-up></ng-container>-->
