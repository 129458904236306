import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';
const baseApiUrl = environment.url;
@Injectable()
export class Globals {
  baseApiUrl = baseApiUrl;
}

export default baseApiUrl;

