import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {PatientIntakeFormService} from '../patient-intake-form.service';
import {PageChangedEvent} from 'ngx-bootstrap/pagination/pagination.component';
import {IntakeFormTemplate} from '../../../intake-form-template-index/intake-form-template';
import {IntakeFormTemplateService} from '../../../intake-form-template-index/intake-form-template.service';
import {Tab} from '../../../../tabs/tab';
import {TabsService} from '../../../../services/tabs.service';
import {IntakeFormReportComponent} from '../intake-form-report/intake-form-report.component';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from "angular-2-dropdown-multiselect";

@Component({
  selector: 'app-patient-intake-forms',
  templateUrl: './patient-intake-forms.component.html',
  styleUrls: ['./patient-intake-forms.component.scss']
})
export class PatientIntakeFormsComponent implements OnInit {
  public filter: Record<string, any> = {from: '', to: '', keyword: '', page: '1', status: []};
  public statusArray: Array<string> = ['sent', 'being_filled', 'filled', 'added_to_chart', 'discarded'];
  public optionsModel: string[];
  public myOptions: IMultiSelectOption[];
  dateRangeValue: Date[];
  public totalItems: any;

  public intakeFormTemplates: IntakeFormTemplate[] = [];

  // Settings configuration
  selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false
  };

  // Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Choose Status',
    allSelected: 'All selected',
  };

  constructor(public userService: UserService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService, public patientIntakeFormService: PatientIntakeFormService,
              public intakeFormTemplateService: IntakeFormTemplateService, public tabs_service: TabsService) {
  }
  ngOnInit(): void {
    this.myOptions = [
      { id: 'sent', name: 'sent' },
      { id: 'being_filled', name: 'being filled' },
      { id: 'filled', name: 'filled' },
      { id: 'added_to_chart', name: 'added to chart' },
      { id: 'discarded', name: 'discarded' }
    ];
    this.getPatientIntakeForms()
  }

  selectStatuses(event) {
    this.filter['status'] = this.optionsModel.sort().join(',');
  }
  setDateRange(event: Date[]) {
    if (event) {
      this.filter['from'] = String(event[0]);
      this.filter['to'] = String(event[1]);
    } else {
      delete this.filter['from'];
      delete this.filter['to'];
    }
  }

  search() {
    this.filter['page'] = 1;
    console.log(this.filter)
    this.getPatientIntakeForms()
  }

  setStatusFilter(status) {
    this.filter['status'] = status.value;
  }

  viewPatientIntakeForm(form) {
    this.tabs_service.add_tab(new Tab({title: form.name}, IntakeFormReportComponent, form.id,
      {id: form.id, intakeForm: form}))

  }
  getPatientIntakeForms() {
    this.loaderService.show();
    this.intakeFormTemplateService.getSubmittedIntakeForm(this.filter).then(resp => {
      this.intakeFormTemplates = resp.intakeFormTemplates.map(template => new IntakeFormTemplate().load_from_json(template));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  updateStatus(form_id, status: string) {
    this.loaderService.show();
    this.patientIntakeFormService.updatePatientFormStatus(form_id, status).then(resp => {
      this.getPatientIntakeForms();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  pageChanged(event: PageChangedEvent) {
    this.filter['page'] = event.page;
    this.getPatientIntakeForms();
  }

  setActionFilter(id,action) {
    if (action === 'discarded') {
      this.updateStatus(id,action);
    }
  }

  add_to_tabs(form: IntakeFormTemplate) {
    let title = form.patient_name + '-' + 'Profile';
    let id = form.patient_id;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital', autorefresh: true},
      PatientProfileComponent,
      id, {id: id}))
  }
}
