import { Injectable } from '@angular/core';
import {CustomHttpClient} from "../../../../services/custom-http-client.service";
import {nullRemover} from "../../../../services/nullRemover";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PatientCommunicationLogService {

  constructor(private _tokenService: CustomHttpClient) { }

  getCommunicationLog(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('communications', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          communicationLog: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }
}


