<ng-container *ngIf="doctor && doctor.custom_loaded">
  <form (ngSubmit)="save()" #doctorForm="ngForm" class="form-horizontal mt-lg mb-lg p-relative">
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">First Name*:</label>
      <div class="col-sm-3">
        <input type="text" class="form-control" id="firstname" [(ngModel)]="doctor.firstname"
               name="firstname" required
               placeholder="FirstName">
        <div *ngIf="customValidations.formErrors.firstname" style="color: red">
          {{ customValidations.formErrors.firstname }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm">Last Name*:</label>
      <div class="col-sm-3">
        <input type="text" class="form-control" id="lastname" [(ngModel)]="doctor.lastname" name="lastname" required
               placeholder="LastName">
        <div *ngIf="customValidations.formErrors.lastname" style="color: red">
          {{ customValidations.formErrors.lastname }}
        </div>
      </div>
    </div>

    <div class="profile-pic-container img-circle thumb150 pull-right profile-pic">
      <span class="loader-pic" *ngIf="loaderService.uploading_in_process">
        <i class="fa fa-spinner fa-spin fs22"></i>
      </span>
      <span *ngIf="!loaderService.uploading_in_process" >
        <img *ngIf="doctor.profile_pic" [src]="doctor.profile_pic.modal.img" alt="" class="img-circle thumb150">
        <i class="fa fa-pencil pic-edit" (click)="choose_file()"></i>
      </span>
    </div>

    <app-attachments (document_uploaded)="handle_image_upload($event)" (popup_closed)="show_attachment_popup = false" [fileType]="'profile_pic'"
                     [only_modal]="true"  [auto_upload]="true" [attachable_id]="doctor.id" [attachable_type]="'Doctor'" [show]="show_attachment_popup">
    </app-attachments>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Prefix*:</label>
      <div class="col-sm-3">
        <input type="text" class="form-control" id="prefix" [(ngModel)]="doctor.prefix" name="prefix" required
               placeholder="Prefix">
        <div *ngIf="customValidations.formErrors.prefix" style="color: red">
          {{ customValidations.formErrors.prefix }}
        </div>
      </div>

      <label class="col-sm-1 p0 pt-sm">Suffix:</label>
      <div class="col-sm-3">
        <input type="text" class="form-control" id="suffix" [(ngModel)]="doctor.suffix" name="suffix"
               placeholder="Suffix">
      </div>

    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Designation*:</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="designation" [(ngModel)]="doctor.designation" name="designation" required
               placeholder="Designation">
        <div *ngIf="customValidations.formErrors.designation" style="color: red">
          {{ customValidations.formErrors.designation }}
        </div>
      </div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Address:</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="address1" [(ngModel)]="doctor.client.address.line_1" name="line_1"
               placeholder="Address 1">
        <div *ngIf="customValidations.formErrors.line_1" style="color: red">
          {{ customValidations.formErrors.line_1 }}
        </div>
      </div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Address:</label>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="address2" [(ngModel)]="doctor.client.address.line_2"
               [ngModelOptions]="{standalone: true}"
               placeholder="Address 2">
      </div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">City:</label>
      <div class="col-sm-2">
        <input type="text" class="form-control" id="city" [(ngModel)]="doctor.client.address.city_area.city.name" name="city"
               placeholder="City">
        <div *ngIf="customValidations.formErrors.city" style="color: red">
          {{ customValidations.formErrors.city }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm w4p">State:</label>
      <div class="col-sm-2">
        <input type="text" class="form-control" id="state" [(ngModel)]="doctor.client.address.city_area.city.state" name="state"
               placeholder="state">
        <div *ngIf="customValidations.formErrors.state" style="color: red">
          {{ customValidations.formErrors.state }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm w4p">Zip:</label>
      <div class="col-sm-2">
        <input type="text" class="form-control" id="zip" [(ngModel)]="doctor.client.address.postal_code" pattern="\d+" minlength="1" maxlength="8" name="zip"
               placeholder="zip">
        <div *ngIf="customValidations.formErrors.zip" style="color: red">
          {{ customValidations.formErrors.zip }}
        </div>
      </div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Mobile:</label>
      <div class="col-sm-3" *ngIf="doctor.phone">
        <input  mask="(000) 000-0000" type="tel" class="form-control" id="phone" [(ngModel)]="doctor.phone.value"  name="phone"
                placeholder="Mobile Number">
        <div *ngIf="customValidations.formErrors.phone" style="color: red">
          {{ customValidations.formErrors.phone }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm">Phone:</label>
      <div class="col-sm-3" *ngIf="doctor.fax">
        <input type="text" class="form-control" id="fax" [(ngModel)]="doctor.fax.value" name="fax"
               placeholder="Phone">
      </div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Email:</label>
      <div class="col-sm-4">
        <input id="email" placeholder="Email" type="text" class="form-control"
               [(ngModel)]="doctor.client.email" name="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$">
        <div *ngIf="customValidations.formErrors.email" style="color: red">
          {{ customValidations.formErrors.email }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm">Gender*:</label>
      <div class="col-sm-2">
        <select name="gender" id="gender" required class="form-control" [(ngModel)]="doctor.gender">
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="trans">Trans</option>
          <option value="trans_male">Transgender male/Trans man/Female-to-male</option>
          <option value="trans_female">Transgender female/Trans woman/Male-to-female</option>
          <option value="non_binary">Genderqueer/Non-binary</option>
          <option value="decline">Decline to answer</option>
          <option value="other">Other</option>
        </select>
      </div>
    </div>

    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">CNIC:</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" [(ngModel)]="doctor.cnic" name="cnic"/>
        <div *ngIf="customValidations.formErrors.cnic" style="color: red">
          {{ customValidations.formErrors.cnic }}
        </div>
        <div *ngIf="isCnicInValid" style="color: red">
          CNIC already exist in system
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm">Reg.No*:</label>
      <div class="col-sm-2">
        <input type="text" class="form-control" name="regno" [(ngModel)]="doctor.regno" maxlength="10" required/>
        <div *ngIf="customValidations.formErrors?.regno" style="color: red">
          {{ customValidations.formErrors?.regno }}
        </div>
        <div *ngIf="isRegnoInValid" style="color: red">
          Regno already exist in system
        </div>
      </div>
    </div>
    <!--    <div class="form-group ml0">
          <label class="col-sm-1 p0 pt-sm">Visit Charges:</label>
          <div class="col-sm-7">
            <input type="text" class="form-control" id="visit-charges" [(ngModel)]="doctor.visit_charges" name="visit_charges" pattern="[0-9]+" maxlength="5"
                   placeholder="Visit Charges">
          </div>
        </div>-->

    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Specialty:</label>
      <div class="col-sm-10">
        <!--<ng-select (data)="value" [multiple]="true" [active]="getPreselectedSpecialties()" #specialtiesInput (selected)="selected($event)" (removed)="removed($event)" (typed)="typed($event)" placeholder="No specialty selected"></ng-select>-->
        <ng-select bindLabel="text"
                   [multiple]="true"
                   [items]="items"
                   [active]="getPreselectedSpecialties()"
                   (open)="getPreselectedSpecialties()"
                   #specialtiesInput
                   (add)="selected($event)"
                   (remove)="removed($event)"
                   (search)="typed($event)"
                   placeholder="No specialty selected"></ng-select>
      </div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Services:</label>
      <div class="col-sm-10">
        <!--<ng-select (data)="value" [multiple]="true" [active]="getPreselectedServices()" #servicesInput [items]="total_services" (selected)="selected_service($event)" (removed)="removed_service($event)" (typed)="typedServices($event)" placeholder="No service selected"></ng-select>-->
        <ng-select bindLabel="text"
                   [multiple]="true"
                   (open)="getPreselectedServices()"
                   #servicesInput
                   [items]="total_services"
                   (change)="selected_service($event)"
                   (remove)="removed_service($event)"
                   (search)="typedServices($event)"
                   placeholder="No service selected"></ng-select>
      </div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0  pt-sm">Summary:</label>
      <div class="col-sm-10">
        <editor #editor apiKey="no-api-key" id="summary" name="mesage_body" [(ngModel)]="doctor.summary"
                [init]="init_tiny">
        </editor>
      </div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">Advance Booking Limit:</label>
      <div class="col-xs-2">
        <input type="text" class="form-control" id="booking_limit" [(ngModel)]="doctor.advance_booking_limit" name="booking_limit" placeholder="">
      </div>
      <div class="col-xs-2 pt">Days</div>
    </div>
    <div class="form-group ml0">
      <label class="col-sm-1 p0 pt-sm">ID:</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="integration_id" [(ngModel)]="doctor.third_party_doctor_id" name="third_party_doctor_id"
               placeholder="Third Party Location ID">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Appoitment Email:</label>
      <div class="col-md-2 col-sm-9 col-xs-12">
        <label class="white-container-box fw-500 mr-lg">
          Running
          <input type="radio" class="ml" name="running" value="reminder_running" [(ngModel)]="doctor.reminder_status"/>
        </label>
        <label class="white-container-box fw-500">
          Stopped
          <input type="radio" class="ml" name="stopped" value="reminder_stopped" [(ngModel)]="doctor.reminder_status"/>
        </label>
      </div>
    </div>

    <ng-container *ngIf="doctor.public_review_links.length > 0">
      <div class="form-group" *ngFor="let review_link of doctor.public_review_links let link_index = index">
        <label class="col-sm-1 p0 pt-sm">Add Link:</label>
        <div class="col-sm-5 pr-xl">
          <div class="flex-control input-group">
            <select #site class="form-control" style="max-width:100px;" (change)='onChange(review_link,site)'>
              <option value="" selected>Sites</option>
              <ng-container *ngFor="let site of public_sites; let i = index;">
                <option [selected]="site.id == review_link.public_site_id" [value]="site.id">{{site.site_name}}</option>
              </ng-container>
            </select>
            <input class="form-control" style="margin: 0 0 0 -1px; border-radius:0;" type="text"
                   id="link_url" [(ngModel)]="review_link.public_site_url" [ngModelOptions]="{standalone: true}">
            <span class="input-group-btn" style="width:auto;">
                <a class="btn btn-danger btn-outline" (click)="deleteLink(review_link)"><i class="fa fa-trash-o"></i></a>
              </span>
          </div>
          <label class="field-label control-label" style="font-weight:500; text-transform:capitalize;" for="auto_review_{{link_index}}">
            <input class="vl-m m0 mr-sm" id="auto_review_{{link_index}}" type="checkbox"
                   name="my_reviews_{{link_index}}" [(ngModel)]="review_link.default_auto_review">
            Select for Review
          </label>
        </div>
      </div>
    </ng-container>
    <div class="form-group ml0">
      <button class="btn btn-primary" type="button" (click)="add_link()">Add Link</button>
    </div>

    <div class="form-group ml0">
      <div *ngIf="achievement_categories_array">
        <div class="row fields-label">
          <div class="col-sm-3 pr0"></div>
          <div class="col-sm-3 pr0"></div>
          <div class="col-sm-1 sm-1-width pr0"><label>From Year</label></div>
          <div class="col-sm-1 sm-1-width pr0"><label>From Month</label></div>
          <div class="col-sm-1 sm-1-width pr0"><label>To Year</label></div>
          <div class="col-sm-1 sm-1-width pr0"><label>To Month</label></div>
        </div>
        <div *ngFor="let category of achievement_categories_array; let n=index">
          <ng-container *ngIf="category.parent_id">
            <label class="col-sm-12 pt-sm p0" [class.fsdiv18]="!category.parent_id">{{category.name}}:</label>
            <div class="row pb-sm" *ngFor="let doctor_achievement of category.doctors_achievements; let i=index">
              <div class="col-sm-3 pr0">
                <input type="text" placeholder="Role" class='form-control' (keyup)="set_token($event)" (click)="set_search_data(1,category.id)" [typeahead]="data_source" [ngModelOptions]="{standalone: true}" [(ngModel)]="doctor_achievement.title"
                       (typeaheadOnSelect)="typeaheadOnSelect($event,doctor_achievement,1)" [typeaheadOptionsLimit]="7"
                       [typeaheadOptionField]="'name'" typeaheadWaitMs="500"
                />
              </div>
              <div class="col-sm-3 pr0">
                <input type="text" placeholder="Institution" class='form-control' (keyup)="set_token($event)" (click)="set_search_data(2,category.id)"
                       [typeahead]="data_source" [ngModelOptions]="{standalone: true}" [(ngModel)]="doctor_achievement.institution_name"
                       (typeaheadOnSelect)="typeaheadOnSelect($event,doctor_achievement,2)" [typeaheadOptionsLimit]="7"
                       [typeaheadOptionField]="'name'" typeaheadWaitMs="500"
                />
              </div>

              <div class="col-sm-1 sm-1-width pr0" #fromyear>
                <select [(ngModel)]="doctor_achievement.start_date" class="form-control" [name]="'start_date'+i+n" >
                  <option value="year">Select Year</option>
                  <option *ngFor="let from_year of years" [value]="from_year">
                    {{from_year}}
                  </option>
                </select>
                <p id="'from_year'+i+n" class="text-danger fs10">{{doctor_achievement.start_date_error_message}}</p>
              </div>

              <div class="col-sm-1 sm-1-width pr0" #frommonth>
                <select [(ngModel)]="doctor_achievement.start_month" class="form-control" [name]="'start_month'+i+n" >
                  <option value="month">Select Month</option>
                  <option *ngFor="let from_month of months" [value]="from_month">
                    {{from_month}}
                  </option>
                </select>
                <p id="'from_month'+i+n" class="text-danger fs10">{{doctor_achievement.start_month_error_message}}</p>
              </div>

              <div class="col-sm-1 sm-1-width pr0" #toyear>
                <select [(ngModel)]="doctor_achievement.end_date" class="form-control" [name]="'end_date'+i+n" [disabled]="doctor_achievement.currently_present">
                  <option value="year">Select Year</option>
                  <option *ngFor="let to_year of years" [value]="to_year">
                    {{to_year}}
                  </option>
                </select>
                <p [attr.id]="'to_year'+i+n" class="text-danger fs10">{{doctor_achievement.end_date_error_message}}</p>
                <input type="checkbox" [name]="'present'+i+n" (change)="onCheckboxChange(doctor_achievement,doctor_achievement.currently_present)" [(ngModel)]="doctor_achievement.currently_present"> Present
              </div>

              <div class="col-sm-1 sm-1-width pr0" #tomonth>
                <select [(ngModel)]="doctor_achievement.end_month" class="form-control" [name]="'end_monthee'+i+n" [disabled]="doctor_achievement.currently_present">
                  <option value="month">Select Month</option>
                  <option *ngFor = "let to_month of months" [value]="to_month">
                    {{to_month}}
                  </option>
                </select>
                <p id="'to_month'+i+n" class="text-danger fs10">{{doctor_achievement.end_month_error_message}}</p>
              </div>

              <div class="col-sm-1 pr0 mt-sm" style=" width:auto;" *ngIf="(i+1) < category.doctors_achievements.length">
                <a (click)="removeAchievement(category,i)"><i class="fa fa-trash text-danger"></i>
                </a>
              </div>

              <div class="col-sm-1 add-ach pr0 mt-sm" style=" width:auto;" *ngIf="category.doctors_achievements.length == (i + 1)">
                <a (click)="addMore(category)"> <i class="fa fa-plus"></i></a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary btn-lg" [disabled]="!doctorForm.form.valid || isCnicInValid || isRegnoInValid">Submit</button>
    <a class="btn btn-inverse btn-lg"(click)="hide_form()">Cancel</a><span *ngIf="global_error"><label class="text-center text-danger fs10">Please fix the errors first</label></span>
  </form>
</ng-container>
<style>
  .add-groups .col-xs-4{ width:33.33333333%;}
  .add-groups .col-xs-8{ width:66.66666667%;}
</style>
