import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-why-wellwink',
  templateUrl: './why-wellwink.component.html',
  styleUrls: ['./why-wellwink.component.scss']
})
export class WhyWellwinkComponent implements OnInit {

  constructor(public _userService: UserService) { }

  ngOnInit(): void {
    this._userService.iAmPatient = false;
  }

}
