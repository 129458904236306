import {AlertVital} from './alert-vital';
import {AlertDiagnosis} from './alert-diagnosis';

export class AlertTemplateRule {
  id: any;
  frequency_trend: any = 'once';
  gender: any;
  age_trend: any;
  age_value: any;
  age_type: any;
  frequency_value: any;
  frequency_type: any;
  alert_diagnosis_rules: AlertDiagnosis[] = [];
  alert_vital_rules: AlertVital[] = [];
  age_min_value: any;
  age_max_value: any;
  trend: any;
  status: any = true;
  sourceable_type: string;
  sourceable_id: any;
  alert_template_id: any;
  alert_template_description: any;
  parent_id: any;

  to_params() {
    return  {
      id: this.id,
      frequency_trend: this.frequency_trend,
      gender: this.gender,
      age_trend: this.age_trend,
      age_value: this.age_value,
      age_min_value: this.age_min_value,
      age_max_value: this.age_max_value,
      age_type: this.age_type,
      trend: this.trend,
      parent_id: this.parent_id,
      frequency_value: this.frequency_value,
      frequency_type: this.frequency_type,
      status: this.status === true ? 'on' : 'off',
      sourceable_type: this.sourceable_type,
      sourceable_id: this.sourceable_id,
      alert_template_description: this.alert_template_description,
      alert_template_id: this.alert_template_id,
      alert_diagnosis_rules_attributes: this.alert_diagnosis_rules.map(diagnosis => diagnosis.to_params()),
      alert_vital_rules_attributes: this.alert_vital_rules.map(alert => alert.to_params())
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.frequency_trend = json.frequency_trend;
    this.gender = json.gender;
    this.status = json.status === 'on' ? true : false;
    this.sourceable_type = json.sourceable_type;
    this.sourceable_id = json.sourceable_id;
    this.alert_template_id = json.alert_template_id;
    this.age_trend = json.age_trend;
    this.age_value = json.age_value;
    this.age_min_value = json.age_min_value;
    this.age_max_value = json.age_max_value;
    this.age_type = json.age_type;
    this.frequency_value = json.frequency_value;
    this.frequency_type = json.frequency_type;
    this.trend = json.trend;
    this.parent_id = json.parent_id;
    this.alert_template_description = json.alert_template_description;
    if (json.alert_diagnosis_rules) {
      this.alert_diagnosis_rules = json.alert_diagnosis_rules.map(a => new AlertDiagnosis().load_from_json(a))
    }
    if (json.alert_vital_rules) {
      this.alert_vital_rules = json.alert_vital_rules.map(a => new AlertVital().load_from_json(a))
    }
    return this;
  }
  is_new_record() {
    return this.id === 0 || this.id === undefined || this.id === null;
  }
}
