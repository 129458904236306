import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {VitalsService} from '../../../../services/vitals.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {Subscription} from 'rxjs/Subscription';
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

@Component({
  selector: 'app-vitals-graph-view',
  templateUrl: './vitals-graph-view.component.html',
  styleUrls: ['./vitals-graph-view.component.scss']
})
export class VitalsGraphViewComponent implements OnInit, OnChanges, OnDestroy {
  public lineChartData: Array<any>;
  public lineChartLabels: Array<any>;
  public data_available: boolean;
  @Input() patient_id;
  @Input() customToDateGraph: string;
  @Input() customFromDateGraph: string;
  @Input() isCustomGraphDate: boolean;
  @Input() graph_no_of_days: string;
  public filter = {date: '', patient_id: '', shorts: '', from: '', to: ''};
  public type = 'bp';
  public tempUnit = 'F';
  public wtUnit = 'kg';
  public subscription: Subscription;
  private timeOptions: DateTimeFormatOptions = {month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false};

  constructor(public vitalService: VitalsService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
  }

  ngOnInit(): void {
    this.subscription = this.vitalService.reloadEnrolledPatientVitalsList.subscribe(item => {
      if (item) {
        this.getChartData(this.type);
      }
    });
  }

  getChartData(type) {
    this.loaderService.show();
    this.type = type;
    this.setGraphFilter();
    this.filter['short'] = type;
    this.wtUnit = 'kg';
    this.tempUnit = 'F';
    this.vitalService.getVitalsGraphData(this.filter).then(data => {
      this.data_available = false;
      this.lineChartData = data.data.map(d => ({
        label: this.vitalService.custom_graph_vital_mapper[d.label],
        short: d.label,
        data: d.data, fill: d.fill }));
      this.lineChartLabels = data.keys.map(a => new Date(a).toLocaleDateString('en-US', this.timeOptions))
      setTimeout(() => {
        this.data_available = true;
      }, 200);
      this.loaderService.hide();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  setGraphFilter() {
    this.filter['patient_id'] =  this.patient_id;
    this.filter['date'] = this.graph_no_of_days;
    if (this.graph_no_of_days !== 'custom') {
      delete this.filter['from'];
      delete this.filter['to'];
    }
    if (this.graph_no_of_days === 'custom') {
      delete this.filter['date'];
      this.filter['from'] = this.customFromDateGraph;
      this.filter['to'] = this.customToDateGraph;
    }
    return this.filter
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.graph_no_of_days) {
      if (changes.graph_no_of_days.currentValue !== 'custom') {
        this.getChartData(this.type);
      }
    }
    if (changes.customFromDateGraph?.currentValue || changes.customToDateGraph?.currentValue) {
      this.getChartData(this.type);
    }
  }

  convertTemptUnit(unit: string) {
    if (unit === 'C') {
      this.lineChartData.map(a => (a.label = this.vitalService.vital_temp_unit_mapper[a.short] + '(F)',
        a.data = a.data.map(value =>  (this.vitalService.cToF(Number(value))))));
      return;
    }
    if (unit === 'F') {
      this.lineChartData.map(a => (a.label = this.vitalService.vital_temp_unit_mapper[a.short] + '(C)',
        a.data = a.data.map(value =>  (this.vitalService.fToC(Number(value))))));
      return;
    }
  }
  convertWtUnit(unit: string) {
    if (unit === 'kg') {
      this.lineChartData.map(a => (a.label = this.vitalService.vital_kg_unit_mapper[this.wtUnit],
        a.data = a.data.map(value =>  (this.vitalService.kgTolb(Number(value))))));
      return;
    }
    if (unit === 'lbs') {
      this.lineChartData.map(a => (a.label = this.vitalService.vital_kg_unit_mapper[this.wtUnit],
        a.data = a.data.map(value =>  (this.vitalService.lbToKg(Number(value))))));
      return;
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
