<div class="flex-control flex-column">
  <app-tabs>
    <li (click)="tabs_service.deactivate_tab()" [class.active]="!tabs_service.is_active()">Today's Patients</li>
  </app-tabs>
  <app-todays-patients class="flex-control flex-column"
                       [hidden]="tabs_service.is_active()"
                       *ngIf="_user_service.current_user && test(_user_service.current_user)"
                       [providers]="providers" [locations]="locations" [practice]="practice" [doctor_id]="doctor_id">
  </app-todays-patients>
</div>
