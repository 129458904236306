<div class="flex-control flex-column" *ngIf="patient">
  <div class="flex-header">
    <div class="container">
      <div class="row">
        <div class="col-sm-8">
          <h3 class="m0 mt-sm">{{intakeForm.name}}</h3>
        </div>
        <div class="col-sm-4 text-right">
          <button *ngIf="isLoading; else notLoading" class="btn btn-default">
            <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
          </button>
          <ng-template #notLoading>
            <ng-container *ngIf="intakeForm.status !== 'added_to_chart'">
              <button class="btn btn-secondary btn-min mr-sm" *ngIf="!isLoading"
                      (click)="callAssociateWithMedicalChartMethod()" [disabled]="is_added_to_chart || isLoading">
                Add to Chart
              </button>
              <button class="btn btn-secondary btn-min mr-sm" (click)="generatePdf('print')">Print</button>
              <button class="btn btn-tertiary btn-min btn-outline" (click)="generatePdf('save')">Download</button>

            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <div #report class="container p">
      <app-demographics *ngIf="intakeForm.steps_names.includes('Demographics') && patient.id" [patient]="patient" [intakeReport]="true"></app-demographics>
    </div>
  </div>
</div>
