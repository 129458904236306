import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {TagService} from '../../services/tags.service';
import {PatientReview} from '../patient-review';
import {Tag} from '../tag';
import {FormControl} from '@angular/forms';
import {PatientReviewService} from '../patient-review.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {TabsService} from '../../services/tabs.service';
import {Tab} from '../../tabs/tab';
import {PatientProfileComponent} from '../../health/patient-profile/patient-profile.component';

@Component({
  selector: 'app-reviews-listing',
  templateUrl: './reviews-listing.component.html',
  styleUrls: ['./reviews-listing.component.scss']
})
export class ReviewsListingComponent implements OnInit {
  @Input() reviews;
  @Input() totalItems;

  @Output() page: EventEmitter<any> = new EventEmitter();
  public data_source: any;
  public token = '';
  tags: Tag[];
  constructor(private errorHandler: GlobalErrorHandlerService, private tagService: TagService,
              private reviewService: PatientReviewService, private tabsService: TabsService) {
    this.data_source = (text: string): Observable<Response> => {
      return this.tagService.search_tags(text)
    };
  }


  ngOnInit() {
    this.tagService.tags().then(resp => {
      this.tags = resp.map(t => new Tag().load_from_json(t))
    });
  }
  selectedTag(item: any) {
  }
  removeTag(item: any, patient_review: PatientReview) {
    this.tagService.removePatientReviewTag(item['id'], patient_review.id)
  }
  addTag(item: any, patient_review: PatientReview) {
    if (item['id']) {
      this.createReviewTag({patient_review_id: patient_review.id, tag_id: item['id']})
    }
    else {
      this.tagService.create(new Tag().load_from_json(item)).then(resp => {
        let tag = new Tag().load_from_json(resp);
        this.createReviewTag({patient_review_id: patient_review.id, tag_id: tag.id})
      }).catch(error => {
        return false;
      });
    }
  }
  createReviewTag(data) {
    this.tagService.createPatientReviewTag(data).then(resp => {
    })
  }
  pageChanged(event) {
    this.page.emit(event.page);
  }

  approve_review(review: PatientReview) {
    this.reviewService.updateStatus(review).then(resp => {
      review = review.load_from_json(resp);
    })
  }
  request_public_review(review: PatientReview) {
    this.reviewService.requestPublicReview(review).then(resp => {
      review = review.load_from_json(resp);
    }).catch(error => {
      this.errorHandler.error = error.error.message;
    })
  }
  openPatientProfile(review) {
    let title = review.patient_name + '-' + 'Profile';
    let id = review.patient_id;
    this.tabsService.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id,
      {id: id}))
  }
}
