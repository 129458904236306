import { Component, OnInit , Input, Output, EventEmitter , ViewEncapsulation} from '@angular/core';
import  {Slot} from '../../models/physician'
import {SlotDatesService} from '../../../services/slot-dates.service';
import {Router} from '@angular/router';
import {SearchService} from '../../../services/search.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LocationService} from '../../../services/location.service';
@Component({
  selector: 'app-slots',
  templateUrl: './slots.component.html',
  styleUrls: ['./slots.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlotsComponent implements OnInit {
  Object = Object;
  JSON = JSON
  Array = Array;
  public selected_slot;
  @Input() grouped_slots: any;
  @Input() no_navigate = false;
  @Output() slot_clicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() show_future_slot = true;
  max_slots = 0;

  public  locations_names = {};
  public  location_address = {};
  public  location_time_zones = {};
  slots_options: Record<string, any> = {per_location: 3, show_more: false, has_more: false};
  constructor(private slot_dates_service: SlotDatesService,
              private router: Router,
              public locationService: LocationService,
              private globalErrorHandlerService: GlobalErrorHandlerService,
              private searchService: SearchService) { }

  ngOnInit() {
  }
  selectHoveredDate(date: any) {
    this.slot_dates_service.hoveredDate = date.datetime;
  }
  deselectHoveredDate() {
    this.slot_dates_service.hoveredDate = null;
  }
  get dates() {
    return this.slot_dates_service.dates;
  }

  ngOnChanges() {
    console.log(this.grouped_slots);
    this.show_future_slot = this.show_future_slot;
    this.slots_options.has_more = false;
    Object.keys(this.grouped_slots).forEach(location_id => {
      Object.keys(this.grouped_slots[location_id]).forEach(date => {
        if (this.grouped_slots[location_id][date].length > this.max_slots) {
          this.max_slots = this.grouped_slots[location_id][date].length;
        }
        if (this.grouped_slots[location_id][date].length > this.slots_options.per_location) {
          this.slots_options.has_more = true;
        }
        this.locations_names[location_id] = this.grouped_slots[location_id][date][0].location_name;
        this.location_address[location_id] = this.grouped_slots[location_id][date][0].location_address;
        this.location_time_zones[location_id] = this.grouped_slots[location_id][date][0].location_time_zone;
      });

    });
  }

  to_ymd(date) {
   return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  get dates_text() {
    return this.dates.map(date => this.to_ymd(date));
  }

  has_more() {
    return this.slots_options.has_more;
  }

  book_appointment(slot) {
    this.selected_slot = slot;
    this.slot_clicked.emit(slot);
    if (!this.no_navigate) {
      this.router.navigate(['/appointments'],
        { queryParams:
            {
              app_type: this.searchService.app_type,
              slot_size: slot.slot_size, calendar_id: slot.calendar_id, slot: slot.slot
            }
        })
    }
  }

  today_slots_only(slot_date, group_slot) {
    let current_date = this.to_ymd(new Date());
    if (slot_date == current_date) {
      this.book_appointment(group_slot)
    }
    else {
      this.globalErrorHandlerService.error = "Only Today's Appointment Booking allowed.";
    }
  }

}
