<div class="panel panel-default mt-lg">
  <div class="panel-body">
    <div class="col-sm-8 p0" *ngIf="show_form">
      <form (ngSubmit)="save()" #calendarForm="ngForm" class="form-horizontal p-relative">
        <div class="form-group ml0">
          <label class="col-sm-2 p0 pt-sm">Location</label>
          <div class="col-sm-3">
            <select #selectedLocation [(ngModel)]="block_calendar.location_id" name="location" (change)="onChange(selectedLocation.value)" class="form-control wd-sd">
              <option value="">Please Select</option>
              <option *ngFor="let location of block_calendar_locations" value={{location.id}}>{{location.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group ml0">
          <label class="col-sm-2 p0 pt-sm">Name*</label>
          <div class="col-sm-3">
            <input type="text" class="form-control wd-sd" id="name" [(ngModel)]="block_calendar.name" name="name" required
                   placeholder="Name">
          </div>
        </div>
        <div class="form-group ml0">
          <label class="col-sm-2 p0 pt-sm">From*</label>
          <div class="col-sm-4">
            <!--<input [(ngModel)]="block_calendar.from" name="from" class="form-control wd-sd"
                   bsDatepicker
                   [disabled-dates]="date2DisabledDates"
                   [min-date]="today"
                   [max-date]="date2MaxDate"
                   [show-close-layer]="true"
                   [show-week-numbers]="false"
                   date-only="true" required/>-->
            <input [(ngModel)]="block_calendar.from" name="from" class="form-control wd-sd"
                   [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                   bsDatepicker
                   [minDate]="minDate"
                   required/>
          </div>
          <label class="col-sm-2 p0 pt-sm text-center">To*&nbsp;</label>
          <div class="col-sm-4">
            <!--<input [(ngModel)]="block_calendar.to" name="to" class="form-control wd-sd"
                   ngui-datetime-picker
                   [disabled-dates]="date2DisabledDates"
                   [min-date]="today"
                   [max-date]="date2MaxDate"
                   [show-close-layer]="true"
                   [show-week-numbers]="false"
                   date-only="true" required/>-->
            <input [(ngModel)]="block_calendar.to" name="to" class="form-control wd-sd"
                   [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                   bsDatepicker
                   [minDate]="minDate"
                   required/>
          </div>
        </div>
        <div class="form-group ml0">
          <label class="col-sm-2 p0 pt-sm">Start Time*</label>
          <div class="col-sm-4">
            <input required [(ngModel)]="block_calendar.start_time" name="start_time" class="form-control wd-sd" type="time"/>
          </div>
          <label class="col-sm-2 p0 pt-sm text-center">End Time*</label>
          <div class="col-sm-3">
            <input  required [(ngModel)]="block_calendar.end_time" name="end_time" class="form-control wd-sd" type="time"/>
          </div>
        </div>
        <br>
        <button *userCan="permissions" [disabled]="!calendarForm.form.valid" type="button" class="btn btn-primary mr-sm"
                (click)="classicModal.show()">Submit</button>
        <a class="btn btn-inverse"(click)="hide_form(block_calendar)">Cancel</a>
        <div>
          <span *ngIf="messageAfterSave" class="pl-lg">
            <strong [ngClass]="success == true ? 'text-success' : 'text-danger'">{{success ? "Calendar created successfully" : error}}</strong>
          </span>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="dateRange" class="alert alert-danger">Starting range should be less then ending range</div>
</div>

<div class="modal-container">
  <div class="modal fade" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" style="height:200px;">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" type="button" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
          <h4 class="modal-title">Submit</h4>
        </div>
        <div class="modal-body">
          <div class="alert alert-warning p pt-sm pb-sm m0 text-center">
            By this action all the effected appointments will be cancelled. Are you sure you want to continue
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="submit" (click)="save();classicModal.hide()" data-dismiss="classicModal">Yes</button>
          <button class="btn btn-tertiary btn-outline" type="submit" (click)="classicModal.hide()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
