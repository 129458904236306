import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {PermissionsService} from '../../../permissions/permissions.service';
import {DoctorService} from '../../../../services/doctor.service';
import {Doctor} from '../../doctors/doctor';
import {LoaderService} from '../../../../services/loader.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-doctors-filter',
  templateUrl: './doctors-filter.component.html',
  styleUrls: ['./doctors-filter.component.scss']
})
export class DoctorsFilterComponent implements OnInit {

  public values: string[] = ['All', 'Active', 'In-Active'];
  public asyncName = '';
  public asyncPracticeName = '';
  public asyncSpecialty = '';
  public status: boolean = null;
  public specialty_id = '0';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public dataSource: Observable<any>;
  public listing: any;
  public totalItems: string;
  public current_page = '1';

  constructor(private loader: LoaderService, private _permissionService: PermissionsService, private _doctor_service: DoctorService) {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.asyncSpecialty);
    }).pipe(mergeMap((token: string) => this.get_specialties(token)));
  }

  public get_specialties(token): Observable<any> {
    return this._permissionService.getSpecialtiesAsObservable(token);
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.specialty_id = e.item.id;
  }

  filter_doctors() {
    this.loader.show();
    this._doctor_service.apply_filters(this.asyncName, this.asyncPracticeName, this.specialty_id,
    this.status, this.current_page).then(response => {
      this.totalItems = response.total;
      this.current_page = response.current_page;
      this.listing = [];
      for (let user_profile of response.doctors) {
        let doctor = new Doctor();
        doctor.index_load_from_json(user_profile);
        this.listing.push(doctor);
      }
      this.loader.hide();
    });
  }

  setPracticeId() {
    this.specialty_id = '0';
  }

  ngOnInit() {
    this.filter_doctors();
  }

  apply_filter_doctors() {
    this.filter_doctors();
  }

  changeStatus(value) {
    if (value == '1') {
      this.status = null;
    } else if (value == '2') {
      this.status = true;
    } else {
      this.status = false;
    }
  }
  pageChanged(page) {
    this.current_page = page;
    this.filter_doctors();
  }
}
