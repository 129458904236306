import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {TabsService} from '../../../services/tabs.service';
import {SmsService} from './sms.service';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit, OnDestroy {
  public show_im: string;


  constructor(public userService: UserService, public tabs_service: TabsService, public smsService: SmsService) {
  }

  ngOnInit() {
    if (this.userService.user.twilio_configurations.sms) {
      this.tabs_service.register('sms');
      this.getAllConversations();
    } else {
      this.show_im = 'Two Way Text is not enabled, configure mobile phone number in Settings>Practice>Settings';
    }
  }

  getAllConversations() {
    this.smsService.userConversationsList();
  }

  ngOnDestroy(): void {
    this.smsService.showConversations = false;
  }
}
