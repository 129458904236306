import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from './nullRemover';

@Injectable()
export class SearchService {
  app_type: string;

  constructor(private _tokenService: CustomHttpClient) {

  }

  searchDoctors(search_params) {
    let params = new HttpParams({fromObject: nullRemover(search_params)});
    return this._tokenService.get('/search/doctors', {params: params}).
    map((r) => r);
  }

  videoConsultationDoctors() {
    return this._tokenService.get('/search/video_consultants').
    map((r) => r);
  }
  onlineVirtualDoctors() {
    return this._tokenService.get('/search/online_virtual_consultants').
    map((r) => r);
  }

  getSuggestion(text) {
    let params = new HttpParams({fromObject: text});
    return this._tokenService.get('/search/correctify', {params: params}).
    map((r) => r);
  }

}
