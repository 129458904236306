<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Patient Communication</h1>
      <h4 class="sub-title fs22" style="color:#000;">Make best use of technology to communicate with patients, securely</h4>
      <h4 class="sub-title">
        Patient outcomes depend on successful communication. The physician who encourages open communication may obtain
        more complete information, enhance the prospect of a more accurate diagnosis, and facilitate appropriate counseling,
        thus potentially improving adherence to treatment plans that benefits long-term health.
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <div class="row">
      <div class="col-md-7 col-sm-6 col-xs-12 img-container">
        <img class="img-responsive" src="/assets/img/public/ipad-mock.png"/>
      </div>
      <div class="col-md-5 col-sm-6 col-xs-12 text-container">
        <h1>Instant Messaging</h1>
        <br>
        <p class="m0">
          Instant conversation improves patient care. Coordinate with patients through instant chat service or discuss
          challenging cases with peers, one-on-one with 100% confidential instant messaging feature.
          <br><br>
          Providers are always busy seeing patient and do not have time to take calls. Instant messaging increase
          productivity and help them free up their time for patients.
        </p>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad verified-sec">
  <div class="container">
    <div class="row sec-pad">
      <div class="col-sm-6 col-xs-12">
        <h1>Secure Email</h1>
        <br>
        <p>
          Communication is very important in healthcare. An effective communication can reduce late appointments,
          no-shows, and cancellations.
          <br><br>
          For each scheduled appointment, patient recall, appointment reminder or preventive
          care alert Wellwink email service sends strategically timed automated and custom scheduled emails.
        </p>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="p-lg">
          <img class="img-responsive" src="/assets/img/public/secure-email.jpg"/>
        </div>
      </div>
    </div>
    <div class="row sec-pad">
      <div class="col-sm-6 col-xs-12">
        <h1>2 Way Text Messaging</h1>
        <br>
        <p>
          According to research 95 percent of text messages are read within 3 minutes of being sent.
          <br><br>
          By embracing two-way texting as a communication channel for patient engagement, practices can achieve
          significant improvements throughout their operations. Two-way texting is an easy way to deliver relevant
          information in patients' hands before or after appointments.
          <br><br>
          Many patients will lose or discard printed information given or mailed to them, but they will likely read and engage with a text.
        </p>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="p-lg">
          <img class="img-responsive" src="/assets/img/public/two-way.jpg"/>
        </div>
      </div>
    </div>
  </div>
</section>

