<div class="section">
  <h4>
    <i class="fa icon-layers fs20 vl-m mr"></i> Program
    <a class="fs14" tooltip="Edit Program" (click)="editTypeAndSeverity =! editTypeAndSeverity"><i class="fa fa-pencil fs12"></i> Edit</a>
  </h4>
  <div class="row">
    <div class="col-sm-4 col-xs-6 form-group">
      <label class="field-label">Program Name</label>
      <h4 class="fs14 m0 fw-400">{{program.name}}</h4>
    </div>
    <div class="col-sm-4 col-xs-6 form-group">
      <label class="field-label">Billing Provider</label>
      <h4 class="fs14 m0 fw-400 text-capitalize">{{program.billing_provider_name}}</h4>
    </div>
    <div class="col-sm-4 col-xs-6 form-group">
      <label class="field-label">Rendering Provider</label>
      <h4 class="fs14 m0 fw-400 text-capitalize">{{program.care_manager_name}}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4 col-xs-6 form-group">
      <label class="field-label">Care Team</label>
      <h4 class="fs14 m0 fw-400">{{program.care_team}}</h4>
    </div>
    <ng-container *ngIf="!editTypeAndSeverity">
      <div class="col-sm-4 col-xs-6 form-group">
        <label class="field-label">Type</label>
        <h4 class="fs14 m0 fw-400">
          <span *ngFor="let p of programEnrollment.enrollment_types ;let i=index" >
              {{userService.removeUnderScore(p) | uppercase}}
            <span *ngIf="i != programEnrollment.enrollment_types?.length - 1" class="mr">,</span>
          </span>
        </h4>
      </div>
      <div class="col-sm-4 col-xs-6 form-group" *ngIf="programEnrollment?.severity">
        <label class="field-label">CCM Severity</label>
        <h4 class="fs14 m0 fw-400">{{userService.removeUnderScore(programEnrollment.severity)}}</h4>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="editTypeAndSeverity">
    <form (ngSubmit)="update()" #editEnrollmentForm="ngForm" >
      <div class="row form-group">
        <div class="col-md-4 col-sm-3"><label class="field-label control-label">Type</label></div>
        <div class="col-md-6 col-sm-5">
          <div class="row">
            <label class="col-md-2 col-sm-5 text-uppercase" *ngFor="let type of program.programTypes; let i = index">
              <input [id]="type" type="checkbox" [value]="type" [name]="type" (change)="setTypeButtonCall($event)" [checked]="selectedType(type)"/>
              {{userService.removeUnderScore(type)}}
            </label>
          </div>

        </div>
      </div>


      <div class="row form-group" *ngIf="programEnrollment.enrollment_types?.includes('ccm')">
        <div class="col-md-2 col-sm-3"><label class="field-label control-label">CCM Severity*</label></div>
        <div class="col-md-6 col-sm-5">
          <label class="fw-500 mr-lg">
            <input type="radio" class="ml" name="complex" value="complex" [(ngModel)]="programEnrollment.severity" required/>
            Complex
          </label>
          <label class="fw-500 mr-lg">
            <input type="radio" class="ml" name="non_complex" value="non_complex" [(ngModel)]="programEnrollment.severity" required/>
            Non-Complex
          </label>
        </div>
      </div>

      <ng-container *ngIf="programEnrollment.enrollment_types?.includes('tcm')">
        <div class="row form-group">
          <div class="col-md-2 col-sm-3"><label class="field-label control-label">Discharge Date*</label></div>
          <div class="col-md-6 col-sm-5">
            <input type="text" placeholder="Discharge Date*" name="discharge_date"
                   [(ngModel)]="programEnrollment.tcm_discharge_date"
                   class="form-control"
                   bsDatepicker
                   [bsValue]="bsValue"
                   [maxDate]="maxDate"
                   required
                   [bsConfig]="{ customTodayClass: 'today-date-select',
                         dateInputFormat: 'YYYY-MM-DD', showWeekNumbers:false, adaptivePosition: true}">
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-2 col-sm-3"><label class="field-label control-label">Decision Making*</label></div>
          <div class="col-md-6 col-sm-5">
            <label class="fw-500 mr-lg">
              <input type="radio" class="ml" name="tcm_moderate" value="tcm_moderate" [(ngModel)]="programEnrollment.tcm_decision" required/>
              Moderate
            </label>
            <label class="fw-500 mr-lg">
              <input type="radio" class="ml" name="tcm_complex" value="tcm_complex" [(ngModel)]="programEnrollment.tcm_decision" required/>
              Complex
            </label>
          </div>
        </div>

      </ng-container>

      <button type="submit" class="btn btn-secondary btn-sm mr mb-lg" [disabled]="!editEnrollmentForm.form.valid" *ngIf="!loaderService.uploading_in_process">Save</button>
      <a class="btn btn-tertiary btn-outline btn-sm mb-lg" (click)="editTypeAndSeverity =! editTypeAndSeverity">Cancel</a>
    </form>
  </ng-container>
</div>
