<div class="flex-control flex-column">
  <app-tabs>
    <li (click)="tabs_service.deactivate_tab()" [class.active]="!tabs_service.is_active()">Eligible patient</li>
  </app-tabs>
  <div class="flex-control flex-column" [hidden]="tabs_service.is_active()">
    <div class="filters-sec flex-header">
      <div class="row enrolled-sec">
        <h4 class="col-" *ngIf="first_status != 'all'">
          <span>{{status_mapper[filter['status']]}} Patients</span>
          <label class="label label-secondary">{{totalItems}}</label>
        </h4>
        <div class="col- enrolled-status-col">
          <select [(ngModel)]="first_status" (change)="resetStatusPage()"  class="form-control">
            <option value='' selected disabled>Status</option>
            <option  *ngFor="let status of statues" [value]="status[0]">{{status[1]}}</option>
          </select>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-6" style="max-width:200px;" *ngIf="first_status !== 'all'">
          <select [(ngModel)]="second_status" (change)="resetSecondPage()"  class="form-control">
            <option value='' selected disabled>Status</option>
            <option  *ngFor="let s_status of second_statues[first_status]" [value]="s_status[0]">{{s_status[1]}}</option>
          </select>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6" *ngIf="first_status != 'all'">
          <input class="form-control"  [(ngModel)]="filter['token']" (keyup.enter)="filter_patient()" placeholder="Search by name, email, phone, or ICD code">
        </div>
        <div class="col-lg-5 col-md-3 col-sm-6" *ngIf="first_status === 'all'">
          <div class = "input-group">
            <input [(ngModel)]="asyncSelected" [typeaheadAsync]="true"
                   typeaheadWaitMs="500"
                   [typeahead]="dataSourcePatients"
                   (typeaheadLoading)="changePatientTypeaheadLoading($event)"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                   autocomplete="off"
                   (typeaheadOnSelect)="onPatientSelect($event)"
                   [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'name_age_gender_phone'"
                   placeholder="Search Patient"
                   name="patients" class="form-control">
            <div class="input-group-addon" *ngIf="patientTypeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
            <div *ngIf="typeaheadNoResults" class="text-danger fs13">No Results Found!</div>
          </div>
        </div>
        <div class="col- filter-btn" *ngIf="first_status != 'all'">
          <button  class="btn btn-tertiary btn-outline btm-min" type="button" (click)="filter_patient()">
            <i class="fa fa-filter"></i> Filter
          </button>
        </div>
        <div class="col- text-right">
          <button type="button" class="btn btn-secondary btn-min" (click)="classicModal.show()">
            <i class="fa fa-plus mr-sm"></i> Add
          </button>
        </div>
      </div>
    </div>
    <div class="flex-body scroll-browser">
      <table class="table table-hover m0">
        <thead>
        <tr>
          <th>Patient</th>
          <th class="col-xs-2">Diagnosis</th>
          <th class="col-xs-2">Insurance Plan</th>
          <th class="col-xs-2">Eligible For</th>
          <th style="min-width:155px;" class="text-center">Status</th>
          <th style="min-width:215px;">Actions</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let eligiblePatient of eligiblePatients">
          <tr>
            <td>
              <div class="media">
                <div class="media-left thumb64">
                  <img alt="Image" class="img-circle thumb64" src="{{ eligiblePatient.patient_profile_pic }}">
                  <div class="status-label">
                    <span class="label" *ngIf="eligiblePatient.portal_online_status"
                          [ngClass]="_appointment_service.show_status_class(eligiblePatient.portal_online_status)"
                          placement="bottom-left" ngbTooltip="Portal {{_appointment_service.show_status_class(eligiblePatient.portal_online_status)}}">
                      <i class="fa fa-laptop"></i>
                    </span>
                    <span class="label" *ngIf="eligiblePatient.mobile_online_status"
                          [ngClass]="_appointment_service.show_status_class(eligiblePatient.mobile_online_status)"
                          placement="bottom" ngbTooltip="Mobile {{_appointment_service.show_status_class(eligiblePatient.mobile_online_status)}}">
                      <i class="fa fa-mobile fs22"></i>
                    </span>
                  </div>
                </div>
                <div class="media-right" style="min-width:180px;">
                  <h4 class="m0 mb fw-500 fs16"><a (click)="openPatientProfile(eligiblePatient)">{{eligiblePatient.name}}</a></h4>
                  <h6 class="m0 mb"><i class="fa icon-user mr-sm"></i> {{eligiblePatient.customize_age}}</h6>
                  <h6 class="m0">
                    <a href="javascript:void(0);" class="h6 m0" (click)="phoneService.initiatePhoneCall(eligiblePatient.mobile_number_value,
                      {name: eligiblePatient.name, pic: eligiblePatient.patient_profile_pic})">
                      <i class="fa icon-screen-smartphone mr-sm"></i> {{eligiblePatient.mobile_number_value | mask: ' (000) 000-0000'}}
                    </a>
                  </h6>
                </div>
                <div class="media-body">
                  <div dropdown class="flex-size text-center" *ngIf="eligiblePatient.contacts && eligiblePatient.contacts.length > 0">
                    <h6 class="text-uppercase text-warning m0 mb-sm fs10">Emergency Contacts</h6>
                    <ng-container *ngIf="eligiblePatient.contacts && eligiblePatient.contacts.length > 0">
                      <h6 class="fw-500 mt-sm mb-sm text-tertiary text-capitalize">{{eligiblePatient.contacts[0].name}}</h6>
                      <h6 class="m0 mb-sm fw-500">
                        <a href="javascript:void(0);" class="h6 m0" (click)="phoneService.initiatePhoneCall(eligiblePatient.contacts[0]?.contact_number.value,
                          {name: eligiblePatient.contacts[0].name, pic: eligiblePatient.patient_profile_pic})">
                          {{eligiblePatient.contacts[0]?.contact_number.value | mask: '(000) 000-0000'}}
                        </a>
                      </h6>
                      <a class="fs12 mt-sm" dropdownToggle *ngIf="eligiblePatient.contacts.length > 1"><i class="fa fa-chevron-down"></i> more</a>
                      <ul class="dropdown-menu animated slideInDown phone-list-dropdown">
                        <ng-container *ngFor="let contact of eligiblePatient.contacts">
                          <li>
                            <a href="javascript:void(0);" class="h6 m0" (click)="phoneService.initiatePhoneCall(contact.contact_number?.value,
                          {name: contact.name, pic: eligiblePatient.patient_profile_pic})">
                              <label>{{contact.name}}</label>
                              <h5 class="m0 fw-300">{{contact.contact_number?.value | mask: '(000) 000-0000'}}</h5>
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </ng-container>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <ng-container *ngIf="eligiblePatient.chronic_diagnosis_name_code">
                <ng-container *ngFor="let diagnosis of eligiblePatient.chronic_diagnosis_name_code;let i=index">
                  <span tooltip="{{diagnosis[0]}}"> {{diagnosis[1]}}</span><span *ngIf="i != eligiblePatient.chronic_diagnosis_name_code.length - 1">,</span>
                </ng-container>
              </ng-container>
            </td>
            <td>
              <ng-container *ngFor="let insurance_plan of eligiblePatient.insurance_plans;let i=index">
                <span> {{insurance_plan}}</span><span *ngIf="i != eligiblePatient.insurance_plans.length - 1"> <br> </span>
              </ng-container></td>
            <td>{{eligiblePatient.eligible_for}}</td>
            <td class="text-capitalize text-center">
              <label class="label" [ngClass]="eligiblePatient.status">{{status_mapper[eligiblePatient.status]}}</label>
              <label class="status-text">{{eligiblePatient.programme_name}}</label>
              <label class="status-text">{{eligiblePatient.enrolment_date}}</label>
            </td>
            <td>
              <a class="btn btn-secondary btn-outline btn-sm mr-sm" *ngIf="eligiblePatient.status === 'in_progress'"
                 (click)="continueEnrollment(eligiblePatient)">
                Continue
              </a>
              <a class="btn btn-secondary btn-outline btn-sm mr-sm" *ngIf="eligiblePatient.status === 'enrolled'"
                 (click)="viewProgram(eligiblePatient)">
                View
              </a>
              <a class="btn btn-secondary btn-outline btn-sm mr-sm" *ngIf="enrollAble(eligiblePatient.status)"
                 (click)="selectedPatient = eligiblePatient; enrolmentModel.show()">
                Enroll
              </a>
              <ng-container  *ngIf="enrollAble(eligiblePatient.status)">
                <select class="btn btn-secondary btn-outline btn-sm status-control" (change)="changeStatus($event.target.value, eligiblePatient)">
                  <option>Change Status</option>
                  <ng-container *ngFor="let s_status of second_statues[first_status]">
                    <option *ngIf="s_status[0] !== 'all' && s_status[0] !== second_status" [value]="s_status[0]">{{s_status[1]}}</option>
                  </ng-container>
                </select>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <app-no-result-found  *ngIf="eligiblePatients && eligiblePatients.length == 0"></app-no-result-found>
    </div>
    <div class="flex-footer text-center m0" *ngIf="eligiblePatients.length > 0 && totalItems > 25">
      <pagination class="m0" [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="25" (pageChanged)="pageChanged($event)"></pagination>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <app-add-new-patient (closeModel)="classicModal.hide()"></app-add-new-patient>
</div>
<div class="modal fade" bsModal #enrolmentModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:554px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="selectedPatient = undefined;enrolmentModel.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Programs</h4>
      </div>
      <div class="modal-body p0 pt pb">
        <ul class="list-unstyled border-list m0 scroll-browser" style="max-height:400px;">
          <ng-container>
            <li class="list" *ngFor="let program of programsList">
              <a (click)="onChange(program.id,selectedPatient); enrolmentModel.hide()">
                <i class="fa fa-file-text-o vl-m mr-sm fs18"></i> {{program.name}}
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="selectedPatient = undefined; enrolmentModel.hide()">Close</button>
      </div>
    </div>
  </div>
</div>
