<ng-container *ngIf="appointment.status_id == 'scheduled'">
  <ul *userCan="'edit,Today Patients,reception'" class="tabs-action-btns m0 p0">
    <ng-container *ngIf="appointment.doctor">
      <li>
        <button type="button" id="checkin-{{appointment.id}}" class="btn btn-secondary btn-outline"
                (click)="add_payment_to_tabs('Check-In')">Check-In
        </button>
      </li>
    </ng-container>
    <li>
      <button type="button" id="confirm-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="set_label('Confirm');classicModal.show()">Confirm
      </button>
    </li>
    <li>
      <button type="button" id="reschedule-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="add_to_tabs('Reschedule')">Reschedule
      </button>
    </li>
    <li class="p-relative">
      <app-email-intake-form class="d-block" [inputId]="appointment.id" [patient_id]="appointment.patient_id"></app-email-intake-form>
    </li>
    <li>
      <button type="button" id="cancel-{{appointment.id}}" class="btn btn-danger btn-outline"
              (click)="set_label('Cancel');classicModal.show()">Cancel
      </button>
    </li>
    <ng-container *ngIf="check_dob_with_time(appointment.date)">
      <li>
        <button type="button" id="no-show-{{appointment.id}}" class="btn btn-secondary btn-outline"
                (click)="set_label('No Show');classicModal.show()">No Show
        </button>
      </li>
    </ng-container>
  </ul>
</ng-container>

<ng-container *ngIf="appointment.status_id == 'confirmed'">
  <ul *userCan="'edit,Today Patients,reception'" class="tabs-action-btns m0 p0">
    <ng-container *ngIf="appointment.doctor">
      <li>
        <button type="button" id="checkinn-{{appointment.id}}" class="btn btn-secondary btn-outline"
                (click)="add_payment_to_tabs('Check-In')">Check-In
        </button>
      </li>
    </ng-container>
    <li>
      <button type="button" id="reschedulee-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="add_to_tabs('Reschedule')">Reschedule
      </button>
    </li>
    <li>
      <button type="button" id="cancell-{{appointment.id}}" class="btn btn-danger btn-outline"
              (click)="set_label('Cancel');classicModal.show()">Cancel
      </button>
    </li>
    <ng-container *ngIf="check_dob_with_time(appointment.date)">
      <li>
        <button type="button" id="no-showw-{{appointment.id}}" class="btn btn-secondary btn-outline"
                (click)="set_label('No Show');classicModal.show()">No Show
        </button>
      </li>
    </ng-container>
  </ul>
</ng-container>

<ng-container *ngIf="appointment.status_id == 'checkedin'">
  <ul *userCan="'edit,Today Patients,reception'" class="tabs-action-btns m0 p0">
    <ng-container *ngIf="appointment.appointment_type === 'video' || appointment.appointment_type === 'virtual_visit'">
      <li *ngIf="!videoChatService.inCall">
        <button [disabled]="!userService.user.twilio_configurations.video" type="button" id="video-{{appointment.id}}"
                class="btn btn-secondary btn-outline"
                (click)="add_video_to_tabs('Video')">Video
        </button>
      </li>
    </ng-container>
    <li *ngIf="appointment.appointment_type === 'phone_visit'">
      <button [disabled]="!userService.user.twilio_configurations.video" (click)="phoneService.initiatePhoneCall(appointment.patient.mobile_number_value,
        {name: appointment.patient.name,
                    pic: appointment.patient.profile_pic,
                    mobile: appointment.patient.mobile_number_value,
                    phone: appointment.patient.landline_number_value,
                    e_contacts: appointment.patient.contacts,
                    show_selection: true
        })"
              type="button" id="phone-{{appointment.id}}"
              class="btn btn-secondary btn-outline">Phone
      </button>
    </li>
    <li>
      <button type="button" id="scan-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="add_to_tabs('Upload')">Upload
        <span class="label label-danger" *ngIf="scan_service.get_data(appointment.id) > 0">
          {{scan_service.get_data(appointment.id)}}
        </span>
      </button>
    </li>
    <ng-container *ngIf="scan_service.get_data(appointment.id) <= 0">
      <li>
        <button type="button" id="checkout-{{appointment.id}}" class="btn btn-secondary btn-outline"
                (click)="add_payment_to_tabs('Check-Out')">Check-Out
        </button>
      </li>
    </ng-container>
    <ng-container *ngIf="scan_service.get_data(appointment.id) > 0">
      <li data-toggle="tooltip" data-placement="bottom"
          title="You cannot Checkout this patient until all uploaded pages are filed.">
        <button type="button" [disabled]="true" id="checkout-{{appointment.id}}"
                class="btn btn-secondary btn-outline disabled" (click)="add_payment_to_tabs('Check-Out')">Check-Out
        </button>
      </li>
    </ng-container>
    <li>
      <ng-container *ngIf="showSelect">
        <select class="form-control" (change)="onChange($event.target.value)">
          <option>Enote</option>
          <ng-container *ngFor="let enote of enotes">
            <option [value]="enote.id">{{enote.name}}</option>
          </ng-container>
          <option value="0">Create Enote</option>
        </select>
      </ng-container>
    </li>
    <li>
      <button type="button" id="payment-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="add_payment_to_tabs('Payment')">Payment
      </button>
    </li>
    <ng-container *ngIf="scan_service.get_data(appointment.id) > 0">
      <li data-toggle="tooltip" data-placement="bottom"
          title="You cannot Admit this patient until all uploaded pages are filed."
          *userCan="'add,Admissions,reception'">
        <button *ngIf="appointment.appointment_type !== 'video'" type="button" [disabled]="true"
                id="admit-{{appointment.id}}"
                class="btn btn-secondary btn-outline disabled" (click)="add_to_tabs('Admit')">Admit
        </button>
      </li>
    </ng-container>
    <ng-container *ngIf="scan_service.get_data(appointment.id) <= 0">
      <li *userCan="'add,Admissions,reception'">
        <button *ngIf="appointment.appointment_type !== 'video'" type="button" id="admit-{{appointment.id}}"
                class="btn btn-secondary btn-outline"
                (click)="add_to_tabs('Admit')">Admit
        </button>
      </li>
    </ng-container>
    <li>
      <button type="button" id="vitals-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="add_vital_to_tabs('Vital')">Vitals
      </button>
    </li>
    <li>
      <button type="button" id="cancel-{{appointment.id}}" class="btn btn-danger btn-outline"
              (click)="set_label('Cancel');classicModal.show()">Cancel
      </button>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="appointment.status_id == 'checkedout'">
  <ul *userCan="'edit,Today Patients,reception'" class="tabs-action-btns m0 p0">
    <li>
      <button type="button" id="current_scan-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="choose_file()">Upload Rx
      </button>
    </li>
    <li>
      <button type="button" id="checked-out-payment-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="add_payment_to_tabs('Payment')">Payment
      </button>
    </li>
    <li>
      <button type="button" id="re-request-review-{{appointment.id}}" class="btn btn-secondary btn-outline"
              (click)="request_review()">Re-request Review
      </button>
    </li>
  </ul>
</ng-container>


<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style=" height:330px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Change status to {{label_value}}</h4>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="(label_value == 'Cancel' && appointment.status_id == 'checkedin'); else login">
          <textarea class="form-control no-resize" placeholder="Type your reason here..."
                    [(ngModel)]="checkedin_cancel_reason" name="rejection_reason" cols="50" rows="7"></textarea>
          <h5 class="text-danger">This action will delete all the associated payments.</h5>
        </ng-container>
        <ng-template #login>
          <ng-container *ngIf="label_value == 'Cancel' ">
            <textarea class="form-control no-resize" placeholder="Type your reason here..."
                      [(ngModel)]="checkedin_cancel_reason"
                      name="rejection_reason" cols="50" rows="7">
            </textarea>
          </ng-container>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="change_status();classicModal.hide()">Submit</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="classicModal.hide();">Cancel</button>
      </div>
    </div>
  </div>
</div>

<app-attachments (document_uploaded)="handle_image_upload($event)"
                 (popup_closed)="show_attachment_popup= false" [fileType]="'document'"
                 [only_modal]="true" [attachable_type]="'Document'" [attachable_id]="attachable_id_document"
                 [auto_upload]="true"
                 [show]="show_attachment_popup">

</app-attachments>
