<div class="flex-control flex-column flex-height view-campaign" *ngIf="!editCampaign">
  <div class="flex-header">
    <div class="media">
      <div class="media-body media-middle">
        <h4 class="m0">{{campaign.name}}  <span class="text-capitalize">
          <span *ngIf="campaign.status == 'completed'" class="label label-success fs13">{{campaign.status}}</span>
          <span *ngIf="campaign.status == 'running'" class="label label-info fs13">{{campaign.status}}</span>
          <span *ngIf="campaign.status == 'draft'" class="label bg-gray fs13">{{campaign.status}}</span>
        </span>
        </h4>
      </div>
      <div class="media-right text-right" *ngIf="campaign.status === 'draft'">
        <a (click)="updateCampaign(campaign)" class="btn btn-primary btn-outline"><i class="fa fa-pencil vl-m mr-sm fs14"></i> Edit</a>
      </div>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <ul class="list-unstyled border-list m0">
      <ng-container *ngIf="campaign.outreach_type === 'email'">
        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-doc fs14 vl-m mr"></i>
              <label class="field-label">Subject</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400">{{campaign.subject}}</h5>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-doc fs14 vl-m mr"></i>
              <label class="field-label">Preview Text</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400">{{campaign.preview_text}}</h5>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-doc fs14 vl-m mr"></i>
              <label class="field-label">From</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400">{{campaign.sender_name}}   <{{campaign.sender_email}}></h5>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-envelope fs14 vl-m mr"></i>
              <label class="field-label">Reply to</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400">{{campaign.reply_to}}</h5>
          </div>
        </li>
      </ng-container>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Contact type</label>
          </div>
          <h5 class="media-body media-middle text-capitalize m0 fw-400">{{campaignService.removeUnderScore(campaign.recipient_type)}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="campaign.recipient_type === 'patients'">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-pencil fs14 vl-m mr"></i>
            <label class="field-label">Selection Criteria</label>
          </div>
          <h5 class="media-body media-middle text-capitalize m0 fw-400">{{campaignService.removeUnderScore(campaign.patient_selection)}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-calendar fs14 vl-m mr"></i>
            <label class="field-label">Created by & On</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{campaign.creator_name}}, {{campaign.created_at}}</h5>
        </div>
      </li>
    </ul>
    <div class="main-wrapper">
      <h4><span class="text-primary">{{campaign.recipient}}</span> Audience Found</h4>
      <hr class="hr-line mt">
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <div class="row recipients-sec">
            <div class="col-md-4 col-sm-3 col-xs-12">
              <a class="white-container-box audience-sec" [ngClass]="filter['status'] === 'valid' ? 'active' : ''" (click)="campaignAudience(campaign.id,'valid');showAudienceTable = true">
                <div class="media m0">
                  <div class="media-left media-middle"><i class="fa icon-envelope-open"></i></div>
                  <div class="media-body media-middle">
                    <h5>{{campaign.outreach_type === 'email' ? 'Mail' : 'SMS'}} to be sent</h5>
                    <h4 class="mb0">{{campaign.valid_audience}}</h4>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-4 col-sm-3 col-xs-12">
              <a class="white-container-box audience-sec"  [ngClass]="filter['status'] === 'invalid' ? 'active' : ''" (click)="campaignAudience(campaign.id,'invalid');showAudienceTable = true">
                <div class="media m0">
                  <div class="media-left media-middle"><i class="fa icon-user"></i></div>
                  <div class="media-body media-middle">
                    <h5>Invalid {{campaign.outreach_type === 'email' ? 'Email Address' : 'Phone Numbers'}}</h5>
                    <h4 class="mb0">{{campaign.invalid_audience}}</h4>
                  </div>
                </div>
              </a>
            </div>
            <div class="col-md-4 col-sm-3 col-xs-12">
              <a class="white-container-box audience-sec" [ngClass]="filter['status'] === 'unsubscribed' ? 'active' : ''" (click)="campaignAudience(campaign.id,'unsubscribed');showAudienceTable = true">
                <div class="media m0">
                  <div class="media-left media-middle"><i class="fa icon-user"></i></div>
                  <div class="media-body media-middle">
                    <h5>Unsubscribed</h5>
                    <h4 class="mb0">{{campaign.unsubscribe_audience}}</h4>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showAudienceTable">
        <button class="close" type="button" (click)="showAudienceTable = false"><i class="fa fa-times fs16"></i></button>
        <app-email-campaign-recipients-list *ngIf="showAudienceTable" [showAudienceTable]="true" [campaign]="campaign" [totalItems]="totalItems" [filter]="filter" [recipientsList]="audienceList"></app-email-campaign-recipients-list>
      </ng-container>
      <h4>Content</h4>
      <hr class="hr-line mt">
      <div class="row form-group">
        <div class="col-sm-2"><label>Fields</label></div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-5 col-xs-8" *ngIf="campaign.recipient_type == 'patients'">
              <select  class="form-control pl0" name="status_id" (change)="insertTag('[[[' + $event.target.value + ']]]');">
                <option *ngFor="let custom_field of patient_custom_fields; let i = index;" [value]="custom_field">
                  {{custom_field}}
                </option>
              </select>
            </div>

            <div class="col-sm-5 col-xs-8"  *ngIf="campaign.recipient_type != 'patients'">
              <select  class="form-control pl0" name="status_id" (change)="insertTag('[[[' + $event.target.value + ']]]');">
                <option *ngFor="let custom_field of staff_and_doctor_custom_fields; let i = index;" [value]="custom_field">
                  {{custom_field}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <app-embed-rich-text-template [embedFor]="campaign.outreach_type === 'sms' ? 'text_message' : 'email'"
                                        [showWarning]="campaign.campaign_body.length > 0 ? true : false"
                                        (exportTemplate)="setTemplateToBody($event)"
          ></app-embed-rich-text-template>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-sm-2"><label>Body*</label></div>
        <div class="col-sm-8">
          <ng-container *ngIf="campaign.outreach_type === 'sms'">
            <textarea  #s_body cols="120" rows="6" type="text" class="form-control" id="s_body"
                       [(ngModel)]="campaign.campaign_body" name="sms_body" required>
            </textarea>
          </ng-container>
          <ng-container *ngIf="campaign.outreach_type === 'email'">
            <editor #editor apiKey="no-api-key" id="email_body" name="email_body" [(ngModel)]="campaign.campaign_body"
                  [init]="init_tiny" required>
            </editor>
          </ng-container>
          <div class="mt-lg" *ngIf="campaign.campaign_body">
            <a class="btn btn-tertiary btn-outline" (click)="previewModal.show()">Preview</a>
            <div class="btn-group ml-lg" dropdown #dropdown="bs-dropdown" [autoClose]="false">
              <button dropdownToggle type="button" class="btn btn-default dropdown-toggle">
                Send Test {{campaign.outreach_type === 'email' ? 'Email' : 'SMS'}}
              </button>
              <button dropdownToggle type="button" class="btn btn-default dropdown-toggle">
                <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu p">
                <tag-input [(ngModel)]="temptestEmailsOrSms" class="form-control"
                           (onAdd)="addTestEmailOrSms($event,campaign)"
                           (onSelect)="selectTestEmailOrSms($event)"
                           (onRemove)="removeTestEmailOrSms($event,campaign)"
                           addOnBlur="true"
                           secondaryPlaceholder="Enter {{campaign.outreach_type === 'email' ? 'Email' : 'Phone Number'}}"
                           placeholder="Enter {{campaign.outreach_type === 'email' ? 'Email' : 'Phone Number'}}">
                </tag-input>
                <a class="btn btn-primary btn-sm mt mr" (click)="sendTestEmailOrSms()">Send</a>
                <button dropdownToggle type="button" class="btn btn-inverse btn-sm dropdown-toggle mt">
                  Cancel
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-footer pl-lg">
      <div class="row form-group">
        <div class="col-md-2 col-sm-3"></div>
        <div class="col-md-10 col-sm-5">
          <a *ngIf="loaderService.uploading_in_process" class="btn btn-primary">
            <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
          </a>
          <ng-container *ngIf="!loaderService.uploading_in_process">
            <button class="btn btn-secondary mr-lg" *ngIf="campaign.status === 'draft'" type="button" (click)="updateBody()">Save</button>
            <button class="btn btn-secondary btn-outline mr-lg" *ngIf="campaign.campaign_body && campaign.status === 'draft'" type="button" (click)="runModal.show()">Save & Run</button>
            <button class="btn btn-secondary btn-outline mr-lg" type="button" (click)="campaignService.saveAsCampaign(campaign)">Save As</button>
            <button class="btn btn-danger btn-outline mr-lg" *ngIf="campaign.status == 'draft'" type="button" (click)="deleteModal.show()">Delete</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <app-attachments
                   (popup_closed)="show_attachment_popup= false" [fileType]="'profile_pic'"
                   [only_modal]="true" [auto_upload]="true" [attachable_id]="campaign.id"
                   [attachable_type]="'CampaignTemplate'"
                   [show]="show_attachment_popup">
  </app-attachments>
</div>

<ng-container *ngIf="editCampaign">
  <app-new-email-campaign [campaign] = "campaign"  (closeform)="closeNewForm($event)"></app-new-email-campaign>
</ng-container>

<div class="modal fade" bsModal #previewModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-lg preview-modal" style="height:calc(100vh - 22%);">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" (click)="previewModal.hide()"><i class="fa fa-times fs16"></i></button>
        <div [innerHTML]="campaign.campaign_body | safeHtml"></div>
      </div>
      <div class="modal-footer text-center p">
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="previewModal.hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Campaign</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">Are You Sure?</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteTemplate(campaign);">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #runModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="runModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Run Campaign</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-success p m0 text-center">Are you sure you want to Run the campaign?</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="runCampaign(campaign);runModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="runModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
