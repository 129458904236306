<h4 class="text-bold">Patient Diagnosis</h4>
<div class="table-responsive">
  <table class="table table-bordered table-striped table-hover">
    <thead>
    <tr>
      <th class="text-center col-xs-2">ICD-10</th>
      <th>Name</th>
      <th>Description</th>
      <th>Updated</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let diagnos of diagnosis">
      <td class="text-center col-xs-2">{{diagnos.icd_codes ? diagnos.icd_codes?.code : ''}}</td>
      <td>{{diagnos['d_name']}}</td>
      <td>
        <ng-container *ngFor="let term_control of diagnos.custom_term_control_with_values(); let i=index">
          <app-note-custom-control-value class="fs12 fw-400" [term]="term_control" [term_control]="term_control" [control_index]="i"></app-note-custom-control-value>
          <span class="fs12 fw-400" style="margin-left:-3px" *ngIf="i < diagnos.custom_term_control_with_values().length - 3 ">,</span>
          <span class="fs12 fw-400" *ngIf="(diagnos.custom_term_control_with_values().length - 2) == i">and</span>
          <span class="fs12 fw-400" *ngIf="(diagnos.custom_term_control_with_values().length -1) == i">.</span>
        </ng-container>
      </td>
      <td>{{diagnos['last_updated_at'] | date: 'shortDate'}}</td>
      <td>{{diagnos['status']}}</td>
    </tr>
    </tbody>
  </table>
</div>
