<div class="modal fade in document-attach-viewer">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="document_service.is_pchf == false">
        <a class="fa fa-times fs20 text-black pull-right" (click)="trigger_preview_closed()"></a>
        <h3 class="m0 text-center">{{document_service.document_name()}}</h3>
        <span class="input-field">
          <input #current_page class="form-control" type="text" name="current_page" [ngModel]="document_service.selected_page" (change)="gotoPage($event,current_page.value)">
          <span class="total-pages">of {{document_service.total_pages}}</span>
        </span>
      </div>
      <div class="modal-body scroll-browser lg-scroll document_viewer" #document_container (scroll)="page_scrolling($event)">
        <ul class="list-unstyled m0">
          <li *ngFor="let page of document_service.pages;let i = index" [attr.page_number]="i+1" [id]="'page_'+(i+1)" class="page">
            <img [src]="page.url" [alt]="page.text" *ngIf="page.loaded" class="img-responsive">
            <div class="border-box text-center p-sm text-tertiary" *ngIf="!page.loaded">
              <i class="fa fa-spinner fa-spin"></i>&nbsp; {{page.text}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
