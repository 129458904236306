import { Component, OnInit, Input } from '@angular/core';
import { Practice } from '../practice'
import { ConfirmationService } from '../../../../services/confirmation.service'
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input() practice: Practice;
  @Input() practice_permissions: string;
  constructor(private confirmationService: ConfirmationService) { }

  ngOnInit() {
  }
  confirm() {
     this.confirmationService.confirmPractice(this.practice.id)
       .then(res => {
         this.practice.client.status_id = 'completed';
       });
  }
}
