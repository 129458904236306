import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {CustomHttpClient} from '../../../services/custom-http-client.service';
import {MedicalCenter} from './medical-center';

@Injectable({
  providedIn: 'root'
})
export class MedicalCenterService {
  public center_types: string[] = ['pharmacy',  'lab', 'radiology'];
  constructor(private _tokenService: CustomHttpClient) { }

  getMedicalCenters(patient_id) {
    let params: HttpParams = new HttpParams();
    params = params.set('patient_id', patient_id);
    return this._tokenService.get('medical_centers', {params: params})
      .toPromise()
      .then(response => response);
  }

  delete(id: any) {
    return this._tokenService
      .delete('medical_centers?id=' + id)
      .toPromise()
      .then(response => response)
  }

  addMedicalCenter(insurance: MedicalCenter): Promise<any> {
    return this._tokenService
      .post('medical_centers', insurance.to_params())
      .toPromise()
      .then(response => response)
  }

  save(insurance: MedicalCenter): Promise<any> {
    if (insurance.is_new_record()) {
      return this.addMedicalCenter(insurance);
    }
    else {
      return this.editMedicalCenter(insurance);
    }
  }

  editMedicalCenter(insurance: MedicalCenter) {
    return this._tokenService.put('medical_centers/' + insurance.id, insurance.to_params())
      .toPromise()
      .then(response => response);
  }
}
