<ul class="border-list list-unstyled" *ngIf="locations">
  <li class="list" *ngFor="let location of locations; let i=index">
    <h2 class="fs22 mt0">{{location.name}}</h2>
    <div [innerHTML]="location.message | safeHtml"></div>
    <div class="row">
      <div class="col-sm-6">
        <h4 class="mt0"><i class="fa icon-info"></i> Address / Phone</h4>
        <ul class="list-unstyled">
          <li><i class="fa icon-location-pin"></i>&nbsp; {{location.address.humanize()}}</li>
          <li><i class="fa icon-phone"></i>&nbsp; <span placement="bottom" ngbTooltip="Phone">{{userService.showNumber('phone', location.contact_numbers)}}</span></li>
          <li *ngIf="userService.showNumber('fax', location.contact_numbers)"><i class="fa fa-fax" ></i>&nbsp; <span placement="bottom" ngbTooltip="Fax">{{userService.showNumber('fax', location.contact_numbers)}}</span></li>
          <li><i class="fa icon-envelope"></i>&nbsp; {{location.email}}</li>
        </ul>
        <h4><i class="fa icon-clock"></i> Hours of Operation</h4>
        <p>{{location.operational_hours}}</p>
      </div>
      <div class="col-sm-6">
        <h4 class="mt0"><i class="fa icon-star"></i> Specialties</h4>
        <ul class="list-unstyled">
          <li *ngFor="let specialty of location.specialties;">{{specialty.description}}</li>
        </ul>
      </div>
    </div>
  </li>
</ul>
