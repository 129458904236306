import {Component, OnInit} from '@angular/core';
import {LibrarySetting, Library} from './library_setting';
import {LibraryImportService} from '../../services/library-import.service';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-library-importer',
  templateUrl: './library-importer.component.html',
  styleUrls: ['./library-importer.component.scss']
})
export class LibraryImporterComponent implements OnInit {
  public libraries: Library[] = [];
  public library: Library = new Library();
  public show_attachment_popup = false;
  public headers: string[];
  public columns: string[];
  public test = '';
  public libraryTypes: Object[] = [];
  public show_form = false;
  public selected_columns: any[] = [];

  constructor(private libraryImporterService: LibraryImportService, private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.getLibraryTypes();
    this.getLibraries();
  }

  getLibraries() {
    this.libraryImporterService.getLibraries().then(resp => this.libraries = resp.map(library => new Library().load_from_json(library)))
  }

  getLibraryTypes() {
    this.libraryImporterService.getLibraryTypes().then(resp => this.libraryTypes = resp)
  }

  handle_image_upload(data) {
    if (data) {
      this.library.attachment_id = data.id;
      this.library.library_data = data.profile_pic;
      this.columns = data.columns;
      this.headers = data.headers;
      this.initializeLibrarySettings();
    }
    this.show_attachment_popup = false;
  }

  initializeLibrarySettings() {
    this.library.library_settings = this.headers.map((header, index) => {
      let library_setting = new LibrarySetting();
      library_setting.header_name = header;
      library_setting.column_number = this.columns[index];
      return library_setting;
    });
  }

  onchange(event, library_setting) {
    if (library_setting.column_number != undefined) {
        this.selected_columns.splice(this.selected_columns.indexOf(library_setting.column_number), 1);
    }
    if (this.selected_columns.includes(event.target.value)) {
      library_setting.error_message = 'This column is already mapped.';
    }
    else {
      this.selected_columns.push(event.target.value);
      library_setting.error_message = '';
      library_setting.column_number = event.target.value;
    }
    console.log(this.library);

  }

  save() {
    this.loaderService.show();
    this.libraryImporterService.create(this.library.to_params()).then(resp => {
      this.loaderService.hide();
      this.libraries.push(new Library().load_from_json(resp));
      this.show_form = !this.show_form;
      this.library = new Library();
      this.selected_columns = [];
    })
  }

  importLibrary() {
    this.show_form = !this.show_form;
  }

  setParent(event, library_setting) {
    library_setting.parent_column_name = event.target.value;
  }
}
