import {
    Component, ChangeDetectionStrategy, AfterViewInit, Input, ViewChild, ElementRef, Renderer2, OnChanges,
    OnInit, TemplateRef
} from '@angular/core';
import {Term} from '../enote_models/term';
import {EnoteService} from '../../services/enote.service';
import { Observable } from 'rxjs/Observable';
import {TermControlValue} from '../enote_models/term_control';
import { ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {Keyword} from '../enote_models/keyword';
import {LoaderService} from '../../services/loader.service';
import { IMultiSelectOption , IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class TermComponent implements AfterViewInit, OnInit {
  @Input() term: Term;
  @Input() term_index: string;
  public contenteditable = false;
  @ViewChild('open_text_span') open_text_span: ElementRef;
  @ViewChild('term_name_span') term_span: ElementRef;
  public searchable: string;
  public total_term_count = 0;
  modalRef: BsModalRef;
  public save_in_progress = false;
  show_result = false;
  public myOptions: any[];
  selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    itemClasses: 'item-color',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false,
    selectionLimit: 1,
    autoUnselect: true,
    closeOnSelect: true
  };

  // Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select Term',
    allSelected: 'All selected',
  };


  constructor(public _enote_service: EnoteService, public modalService: BsModalService, public loaderService: LoaderService) {
  }

  ngAfterViewInit() {
    if (this._enote_service.active_term == this.term) {
      this.focusOpenText();
    }
  }

  ngOnInit() {
    this._enote_service.term_changes.subscribe(term => {
      if (this.term == term) {
        this.focusOpenText();
      }
    });
    if (this.term.keyword) {
      this.countTerms();
    }
  }
  countTerms() {
    this.total_term_count = this.term.keyword.child_keywords.reduce((a, b) => a + b.result_count, this.term.keyword.result_count)
    console.log(this.total_term_count, this.term.keyword.child_keywords)
    isNaN(this.total_term_count) ? this.total_term_count = 0 : this.total_term_count = this.total_term_count
    console.log(this.total_term_count, this.term.keyword.result_count);
  }

  handleTerm(term: Term) {
    this._enote_service.focusSelectedControl();
    term.focusControl();
    // this._enote_service.active_term = this.term;
  }


  public changeTypeaheadLoading(e: boolean): void {
  }

  public changeTypeaheadNoResults(e: boolean): void {
    console.log(e);
  }

  public typeaheadOnFirstSelect(e: any, keyword): void {
  keyword.name = e.item.sname;
  }

  openModal(term: Term, keyword_ref: TemplateRef<any>) {
    if (term.term_type === 'keyword') {
      if (term.keyword.child_keywords.length === 0) {
        term.keyword.child_keywords.push(new Keyword(this._enote_service));
      }
      this.modalRef = this.modalService.show(keyword_ref, { class: 'keywords-modal' });
      console.log(this.modalRef)
    }
  }
  setChildKeywords(term: Term) {
      term.keyword.child_keywords.push(new Keyword(this._enote_service));

  }
  deleteKeyword(keyword) {
    if (!keyword.id) {
      let index = this.term.keyword.child_keywords.indexOf(keyword);
      if (index > 0) {
        this.term.keyword.child_keywords.splice(index, 1);
        this.countTerms();
      }
      return true;
    }
    keyword.save_in_progress =  true;
    this._enote_service.delete_keyword(keyword).then(resp => {
      keyword.save_in_progress = false;
      this.term.keyword.child_keywords = this.term.keyword.child_keywords.filter(a => a.id !== keyword.id)
      if (this.term.keyword.child_keywords.length === 0) {
        this.term.keyword.child_keywords.push(new Keyword(this._enote_service));
      }
      this.countTerms();

      // this.loaderService.hide();
    });
    return true;
  }
  getResutlData(keyword) {
    this._enote_service.keyword_result_data(keyword.id).then(resp => {
      keyword.result_data = resp;
    })
    return false;
  }
  save(keyword: Keyword, parent_id) {
    setTimeout((t) => {
    if (keyword.name.length == 0 && keyword.id) {
      keyword.save_in_progress =  true;
      this._enote_service.delete_keyword(keyword).then(resp => {
        keyword.save_in_progress = false;
        this.term.keyword.child_keywords = this.term.keyword.child_keywords.filter(a => a.id != keyword.id)
        if (this.term.keyword.child_keywords.length == 0) {
          this.term.keyword.child_keywords.push(new Keyword(this._enote_service));
        }
          this.countTerms();

        // this.loaderService.hide();
      });
      return true;
    }
    if (keyword.name.length == 0 && !keyword.id) {
      let index = this.term.keyword.child_keywords.indexOf(keyword);
      if (index > 0) {
        this.term.keyword.child_keywords.splice(index, 1);
        this.countTerms();
      }
    }
    if (keyword.id) {
      keyword.save_in_progress =  true;
      this._enote_service.update_keyword(keyword).then(resp => {
        keyword.load_from_json(resp);
        this.countTerms();
        keyword.save_in_progress = false;
        // this.loaderService.hide();
      })
    }
    else if (keyword.name.length > 0) {
      keyword.save_in_progress =  true;
      keyword.parent_id = parent_id;
    this._enote_service.create_keyword(keyword).then(resp => {
           keyword.load_from_json(resp);
      this.countTerms();
      keyword.save_in_progress = false;
      // this.loaderService.hide();
    })
    }
  }, 250)}
  cancel(template: TemplateRef<any>) {
    this.modalRef.hide();
  }




  handleSeperatorFocus() {
    this._enote_service.active_term = this.term;
  }
  focusOpenText() {
    if (this.open_text_span) {
      let selection = window.getSelection();
      let range = document.createRange();
      selection.removeAllRanges();
      range.selectNodeContents(this.open_text_span.nativeElement);
      range.collapse(false);
      console.log(range);
      selection.addRange(range);
      this.open_text_span.nativeElement.focus();
    }
  }
  focusTerm() {
    if (this.term_span) {
      let selection = window.getSelection();
      let range = document.createRange();
      selection.removeAllRanges();
      range.selectNodeContents(this.term_span.nativeElement);
      range.collapse(false);
      selection.addRange(range);
      this.term_span.nativeElement.focus();
    }
  }
  onBackSpaceSeparator() {
    return false;
  }

  onbackSpacePress(e, term) {
    if (!term.id && term.open_text.length == 0 && term.parent_term && term.parent_term.initial_component) {
      let parentt = this.term.parent_term.parent_term;
      term.parent_term.deleteYourself();
      let last_child = parentt.lastChild(term.parent_term.term_position())
      this._enote_service.setActiveTerm(last_child.initial_component ? last_child.children[0] : last_child );
      return true;
    }
  }

  onBackSpaceDown(e, term) {
    term.deleteYourself();
    this._enote_service.active_term =  this._enote_service.term;
    return false;
  }

  onBackSpaceDownDelete(e, term) {
      if (this.open_text_span.nativeElement.textContent == '' && term.is_open_text && term.children.length == 0 && term.parent_term.filterChild().length < 2 ) {
         term.parent_term._destroy = true;
         for (let child of term.parent_term.children) {
             child._destroy = true;
         }
      }
    this._enote_service.template.terms = this._enote_service.template.terms.filter(term => !term._destroy);
    return true;
  }

  stopEditing(event) {
    console.log(event);

    return false;
  }

  handle_callback(item) {
    return;
  }

  key_down_call(event) {
    if (event.key === '#') {
      if (!(this.term.parent_term && this.term.parent_term.library_datum_id)) {
        return true;
      }
      this.term.show_typeahead = true;
      this.term.focus_on_init = true;
      this.searchable = 'keyword';
      return false;
    }
    if (event.key === ';') {
      this.searchable = 'term';
      this.term.show_typeahead = true;
      this.term.focus_on_init = true;
      return false;
    }
  }

  blurTerm() {
    this.term.is_focus = false;
    this.contenteditable = false;
  }
  name_clicked() {
    this.contenteditable = true;
    setTimeout(() => {
      this.term_span.nativeElement.focus();
    }, 300);

  }

  addSubComponent(event_catcher) {
    let new_term = new Term('', '');

    new_term.initial_component = true;
    let action_term =  this.term;
    if (action_term.parent_term && action_term.parent_term.initial_component && action_term.parent_term.parent_term) {
      action_term = action_term.parent_term;
    }
    action_term.parent_term.addToChildren(new_term,  action_term.term_position() + 1);


    let open_text_term = new Term('', '');
    open_text_term.is_open_text = true;
    new_term.addToChildren(open_text_term, 0);


    this._enote_service.active_term = open_text_term;
    new_term.focus_on_init = true;
    new_term.index_counter = this._enote_service.get_next_counter;
    console.log(action_term);
    return false;
  }
  open_control_typeahead() {
    this.term.show_control_typeahead = !this.term.show_control_typeahead;

  }
  deleteTerm(e: any) {
    this.onBackSpaceDown(e, e.item);
  }
}
