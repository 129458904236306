<div class="container pricing-module" *ngIf="packages">
  <h2 class="text-center mt-xl"><i class="fa icon-cloud-download fa-fw fs40"></i> WellWink Cloud Pricing</h2>
  <h3 class="text-center text-uppercase tag-line mt-xl mb-xxl fw-300" style="letter-spacing:3px;">Simple | Affordable | Easy To Use</h3>
  <ul class="row list-unstyled">
    <li class="col-sm-3 col-xs-12 text-center package" *ngIf="freePackage">
      <h4 class="title bg-primary">Free Widget</h4>
      <div class="holder">
        <div class="content">
          <h3 class="white-container-box">
            $0 <span>Per Provider Per Month</span>
          </h3>
          <h4>Free Access to All Basic Features</h4>
        </div>
        <div class="btns-holder">
          <a [routerLink]="'/practices/register'"
             [queryParams]="{previous_package_name: current_package['name'],practice_id: practice_id,package_id: freePackage['id'],package_name: freePackage['name']}"
             class="btn btn-primary text-uppercase">
            7 Days Trial
          </a>
          <a href="http://leasol.com/" target="_blank" class="btn btn-inverse text-uppercase">See How it Works</a>
        </div>
        <ul class="list list-unstyled">
          <li>
            <i class="fa fa-chevron-right"></i> Online Appointment Widget
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> One Doctor, Location and Staff
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Doctors listing on WellWink Portal
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Full Portal Access
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Online appointment notification sent via email
          </li>
          <li><i class="fa fa-chevron-right"></i> Recall Management</li>
        </ul>
      </div>
    </li>
    <li class="col-sm-3 col-xs-12 text-center package" *ngIf="betterDealPackage">
      <h4 class="title bg-warning">Better Deal</h4>
      <div class="holder">
        <div class="content">
          <h3 class="white-container-box">
            $69 <span>Per Provider Per Month</span>
          </h3>
          <h4>Full Portal Access with Unlimited Doctors, Locations & Staff</h4>
        </div>
        <div class="btns-holder">
          <a [routerLink]="'/practices/register'"
             [queryParams]="{previous_package_name: current_package['name'],request_type: 'signup',practice_id: practice_id,package_id: betterDealPackage['id'],package_name: betterDealPackage['name']}"
             class="btn btn-primary text-uppercase">{{practice_id ? 'Upgrade' : 'SIGNUP'}}</a>
        </div>
        <ul class="list list-unstyled">
          <li>
            <i class="fa fa-chevron-right"></i> Online Appointment Widget
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Unlimited Doctors, Locations and Staff
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Doctors listing on WellWink Portal
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Full Portal Access
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Online appointment Pushed to PMS
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> PMS Integration
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Recall Management
          </li>
        </ul>
      </div>
    </li>
    <li class="col-sm-3 col-xs-12 text-center package" *ngIf="ultimatePackage">
      <h4 class="title bg-info">Ultimate</h4>
      <div class="holder">
        <div class="content">
          <h3 class="white-container-box">
            $119 <span>Per Provider Per Month</span>
          </h3>
          <h4>Full Featured Subscription</h4>
        </div>
        <div class="btns-holder">
          <a [routerLink]="'/practices/register'"
             [queryParams]="{previous_package_name: current_package['name'],request_type: 'signup', practice_id: practice_id,package_id: ultimatePackage['id'],package_name: ultimatePackage['name']}"
             class="btn btn-primary text-uppercase">{{practice_id ? 'Upgrade' : 'SIGNUP'}}</a>
        </div>
        <ul class="list list-unstyled">
          <li>
            <i class="fa fa-chevron-right"></i> All Features included in Better Deal
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Reputation Management
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Practice Marketing
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Telemedicine
          </li>
        </ul>
      </div>
    </li>
    <li class="col-sm-3 col-xs-12 text-center package" *ngIf="easyGoingPackage">
      <h4 class="title bg-success">Care Management</h4>
      <div class="holder">
        <div class="content">
          <h3 class="white-container-box">
            Contact Us <span>Per Patient Per Month</span>
          </h3>
          <h4>PCM, CCM, RPM, RPM(PAP)</h4>
        </div>
        <div class="btns-holder">
          <a href="https://calendly.com/wellwink" class="btn btn-primary text-uppercase" target="_blank">Request a Demo</a>
        </div>
        <ul class="list list-unstyled">
          <li>
            <i class="fa fa-chevron-right"></i> Auto determine eligible patients
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Create multiple programs by disease category and care team
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Enroll eligible patients in PCM, CCM, RPM programs
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Create and use care plan templates using clinical from medical devices
          </li>
          <li>
            <i class="fa fa-chevron-right"></i> Monitor real-time data feeds coming from medical devices
          </li>
          <li><i class="fa fa-chevron-right"></i> Create & resolve clinical alerts</li>
          <li><i class="fa fa-chevron-right"></i> Auto superbill and sign-off</li>
        </ul>
      </div>
    </li>
  </ul>

  <h3 class="text-center mt-xl mb-lg">Package Comparision</h3>
  <div class="table-responsive white-container-box p-lg">
    <table class="table table-hover table-bordered m0">
      <thead>
        <tr>
          <th style="min-width:360px;"></th>
          <th class="text-center bg-primary text-uppercase">Trial</th>
          <th class="text-center bg-warning text-uppercase">Better Deal</th>
          <th class="text-center bg-info text-uppercase">Ultimate</th>
          <th class="text-center bg-success text-uppercase">Care Management</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="fw-500">Subscription (Per Provider/Month)</td>
          <td class="text-center">0</td>
          <td class="text-center">$69</td>
          <td class="text-center">$119</td>
          <td class="text-center">Contact Us</td>
        </tr>
        <tr>
          <td class="fw-500">One-Time</td>
          <td class="text-center fw-500">$250</td>
          <td class="text-center fw-500">$250</td>
          <td class="text-center fw-500">$250</td>
          <td class="text-center fw-500">$750</td>
        </tr>
        <tr>
          <td class="fw-500" colspan="12">Patient Relationship Management</td>
        </tr>
        <tr>
          <td>Number of Doctors</td>
          <td class="text-center">1</td>
          <td class="text-center">Unlimited</td>
          <td class="text-center">Unlimited</td>
          <td class="text-center">Unlimited</td>
        </tr>
        <tr>
          <td>Number of Staff</td>
          <td class="text-center">2</td>
          <td class="text-center">Unlimited</td>
          <td class="text-center">Unlimited</td>
          <td class="text-center">Unlimited</td>
        </tr>
        <tr>
          <td>Doctors and Location Calendar</td>
          <td class="text-center">1</td>
          <td class="text-center">Unlimited</td>
          <td class="text-center">Unlimited</td>
          <td class="text-center">Unlimited</td>
        </tr>
        <tr>
          <td>Appointment Widget For Office Visit</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Practice Profile</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Doctor Profile</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Doctors listing on WellWink Portal</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>New appointment notification via email</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Full featured Portal Access</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Appointment Scheduler</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Appointment Reminders</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Recall Management</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Tasking</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Instant Messaging</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Patient Portal</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Mobile Application for Patient</td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Appointment Widget for Virtual Visit</td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td>Integration with PMS</td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td class="fw-500">Reputation Management</td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td class="fw-500">Practice Marketing</td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td class="fw-500">Telemedicine</td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
        <tr>
          <td class="fw-500">Care Management</td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-times text-danger"></i></td>
          <td class="text-center"><i class="fa fa-check text-success"></i></td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="web-design">
          <td class="fw-500">
            New Practice Website / Redesign
            <span class="fs18 text-center fw-500 pull-right">One Time</span>
          </td>
          <td class="text-center fw-500 fs18">$500</td>
          <td class="text-center fw-500 fs18">$500</td>
          <td class="text-center fw-500 fs18">$500</td>
          <td class="text-center fw-500 fs18">$500</td>
        </tr>
      </tfoot>
    </table>
  </div>

  <h3 class="text-center mt-xl mb-lg">SEO & Digital Marketing Services</h3>
  <div class="table-responsive white-container-box p-lg">
    <table class="table table-hover table-bordered mb">
      <thead>
        <tr>
          <th style="min-width:360px;">Packages</th>
          <th class="text-center bg-local text-uppercase">Local</th>
          <th class="text-center bg-silver text-uppercase">Silver</th>
          <th class="text-center bg-gold text-uppercase">Gold</th>
          <th class="text-center bg-platinum text-uppercase">Platinum</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="fw-500">Monthly Cost</td>
          <td class="text-center">$299</td>
          <td class="text-center">$499</td>
          <td class="text-center">$599</td>
          <td class="text-center">$899</td>
        </tr>
      </tbody>
    </table>
    <p class="fs12 m0">
      <span class="fw-500">Services Include:</span>
      Campaign Setup, On-Page Optimization, Content Marketing and Link Acquisition, Social
      Media Marketing, Video Marketing, Local Search Optimization, Website Backend Support and Monthly Reporting.
      For complete pricing details, write us at <a href="mailto:sales@wellwink.com">sales@wellwink.com</a>
    </p>
  </div>
</div>
