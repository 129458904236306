import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../../../services/nullRemover';
import {CustomHttpClient} from '../../../services/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class PatientIntakeFormService {

  constructor(private _tokenService: CustomHttpClient) { }

  getPatientIntakeForms(filter: Object): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('intake_forms/patient_forms', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          patientIntakeForms: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  updatePatientFormStatus(form_id, status: string) {
    return this._tokenService.put('intake_forms/form_status', {form_id: form_id, status: status})
      .toPromise()
      .then(response => response);

  }
  updateStepStatus(step_id, status: string) {
    return this._tokenService.put('intake_forms/step_status', {step_id: step_id, status: status})
      .toPromise()
      .then(response => response);

  }
}
