<div class="row">
  <div class="col-sm-8">
    <h4 class="text-black m0 mb fw-500">{{program.name}}&nbsp;&nbsp;
      <label class="fs12 vl-m text-center status-colors checkedin text-capitalize" *ngIf="program.status === 'opened'" style="display:inline-block;">{{program.status}}</label>
      <label class="fs12 vl-m text-center status-colors checkedout text-capitalize" *ngIf="program.status === 'closed'" style="display:inline-block;">{{program.status}}</label>
    </h4>
    <div class="row program-head-info">
      <div class="semi-info">
        <label class="field-label pr m0">Billing Provider</label>
        <span class="fs13 fw-400">{{program.billing_provider_name}}</span>
      </div>
      <div class="semi-info">
        <label class="field-label pr m0">Rendering Provider</label>
        <span class="fs13 fw-400">{{program.care_manager_name}}</span>
      </div>
      <div class="large-info">
        <label class="field-label pr m0">Care Team</label>
        <span class="text-capitalize fw-400 fs13" *ngIf="program.care_team.length == 1">{{program.care_team}}</span>
        <span class="text-capitalize fs13 fw-400" *ngIf="program.care_team.length > 1">
          <span *ngFor="let name of program.care_team ;let i=index">{{name}}
            <span *ngIf="i != program.care_team?.length - 1" class="mr">,</span></span>
        </span>
      </div>
    </div>
  </div>
  <div class="col-sm-4 revenue-row">
    <div class="border-box">
      <label class="field-label">Current Month Revenue</label>
      <h4 class="text-capitalize m0">{{program.current_month_revenue | currency: 'USD': '$':'1.0-0'}}</h4>
    </div>
    <div class="border-box">
      <label class="field-label">Previous Month Revenue</label>
      <h4 class="text-capitalize m0">{{program.previous_month_revenue | currency: 'USD': '$':'1.0-0'}}</h4>
    </div>
    <div class="border-box">
      <label class="field-label">Total Revenue</label>
      <h4 class="text-capitalize m0">{{program.total_revenue | currency: 'USD': '$':'1.0-0'}}</h4>
    </div>
  </div>
</div>
