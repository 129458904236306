<ng-container *ngIf="edit || show_extras">
  <form (ngSubmit)="save()" #UserForm="ngForm" *ngIf="patient && patient.custom_loaded">
    <div class="row flex-row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6 col-xs-6 form-group">
            <label>First Name*</label>
            <input type="text" class="form-control" id="first_name" [(ngModel)]="patient.user.first_name" name="firstname" required>
            <div *ngIf="customValidations.formErrors.firstname" class="text-danger">{{ customValidations.formErrors.firstname }}</div>
          </div>
          <div class="col-sm-6 col-xs-6 form-group">
            <label>Last Name*</label>
            <input type="text" class="form-control" id="last_name" [(ngModel)]="patient.user.last_name" name="lastname" required>
            <div *ngIf="customValidations.formErrors.lastname" class="text-danger">
              {{ customValidations.formErrors.lastname }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xs-6">
            <app-date-of-birth [age_year]="patient.age_year" [show_age_option]="false" [age_month]="patient.age_month" [dob]="patient.user.dob" [dob_stage]="patient.user.dob_stage" (dob_and_stage)="patient.handle_dob_and_dob_stage($event)"></app-date-of-birth>
            <div *ngIf="patient.year_error" class="text-danger">Invalid Date of Birth</div>
          </div>
          <div class="col-sm-6 col-xs-6 form-group">
            <label>Gender*</label>
            <select #selectedGender name="gender" [(ngModel)]="patient.user.gender" (change)="changeGender(selectedGender.value)" class="form-control">
              <option value="Select Gender">Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="trans">Trans</option>
              <option value="trans_male">Transgender male/Trans man/Female-to-male</option>
              <option value="trans_female">Transgender female/Trans woman/Male-to-female</option>
              <option value="non_binary">Genderqueer/Non-binary</option>
              <option value="decline">Decline to answer</option>
              <option value="other">Other</option>
            </select>
            <div *ngIf="gender_error" class="text-danger">Gender Required</div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xs-6 form-group">
            <label *ngIf="add_family_member">Mobile</label>
            <label *ngIf="!add_family_member">Mobile*</label>
            <input  mask="(000) 000-0000" type="text" class="form-control" #patient_phone id="phone" [(ngModel)]="patient.user.phone.value"
                    name="phone" (change)="checkDuplicate(patient.user.phone.value)" [required]="!add_family_member">
          </div>
          <div class="col-sm-6 col-xs-6 form-group">
            <label>Mother's Maiden Name</label>
            <input type="text" class="form-control" id="mother_name" [(ngModel)]="patient.mother_name" name="mother_name" maxlength="15" pattern="[^\s]+">
            <div *ngIf="customValidations.formErrors.mother_name" class="text-danger">{{ customValidations.formErrors.mother_name }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 form-group">
            <label>Email</label><span *ngIf="isEmailRequired()">*</span>
            <input type="text" class="form-control" id="email" #patient_user [(ngModel)]="patient.user.email" name="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                   [required]="isEmailRequired()"  (change)="checkDuplicate(patient.user?.email)">
          </div>
          <div  class="col-sm-6 form-group">
            <label>Home Phone</label>
            <input  mask="(000) 000-0000" type="text" class="form-control" id="fax" [(ngModel)]="patient.user.fax.value" name="fax">
          </div>
        </div>
        <div class="row">
          <div [ngClass]="(userService.current_user && !userService.current_user.isPatient && patient.is_new_record()) ? '':''"  class="col-xs-12 form-group">
            <label>Address 1</label>
            <input type="text" class="form-control" id="address_1" [(ngModel)]="patient.user.address.line_1" name="line_1">
          </div>
          <div class="col-xs-12 form-group">
            <label>Address 2</label>
            <input type="text" class="form-control" id="address_2" [(ngModel)]="patient.user.address.line_2" name="line_2">
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4 form-group">
            <label>City</label>
            <input type="text" class="form-control" id="city" [(ngModel)]="patient.user.address.city_area.city.name" name="city">
          </div>
          <div class="col-xs-4 form-group">
            <label>State</label>
            <input type="text" class="form-control" id="state" [(ngModel)]="patient.user.address.city_area.city.state" name="state">
          </div>
          <div class="col-xs-4 form-group">
            <label>Zip Code</label>
            <input type="text" class="form-control" id="zip" [(ngModel)]="patient.user.address.postal_code" pattern="\d+" minlength="1" maxlength="8"
                   name="zip">
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-6 form-group">
            <label>Race*</label>
            <select class="form-control" [(ngModel)]="patient.user.race" name="race" required>
              <option [ngValue]="null" selected>Select</option>
              <option *ngFor="let race of userService.races; let i = index;" [value]=[race] class="text-capitalize">
                {{race}}
              </option>
            </select>
          </div>
          <div class="col-sm-6 form-group">
            <label>Ethnicity*</label>
            <select class="form-control" [(ngModel)]="patient.user.ethnicity" name="ethnicity" required>
              <option [ngValue]="null" selected>Select</option>
              <option *ngFor="let ethnicity of userService.ethnicities; let i = index;" [value]=[ethnicity] class="text-capitalize">
                {{ethnicity}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 form-group">
            <label>Preferred Language</label>
            <ss-multiselect-dropdown [options]="userService.preferred_languages"
                                     [(ngModel)]="patient.user.preferred_language"
                                     [settings]="selectSettings"
                                     [texts]="myTexts"
                                     (ngModelChange)="selectLanguages($event)"
                                     name="pereferred_languages">
            </ss-multiselect-dropdown>
          </div>
          <div class="col-sm-6 form-group">
            <label>Birth Sex</label>
            <select class="form-control" [(ngModel)]="patient.user.birth_sex" name="birth_sex">
              <option [ngValue]="null" selected>Select</option>
              <option *ngFor="let birth_sex of userService.birth_sexes; let i = index;" [value]=[birth_sex] class="text-capitalize">
                {{birth_sex}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 form-group">
            <label>Sexual Orientation</label>
            <select class="form-control" [(ngModel)]="patient.user.sexual_orientation" name="sexual_orientation">
              <option [ngValue]="null" selected>Select</option>
              <option *ngFor="let sexual_orientation of userService.sexual_orientations; let i = index;" [value]=[sexual_orientation] class="text-capitalize">
                {{sexual_orientation}}
              </option>
            </select>
          </div>
          <div class="col-sm-6 form-group">
            <label>Marital Status</label>
            <select class="form-control" [(ngModel)]="patient.user.marital_status" name="marital_status">
              <option [ngValue]="null" selected>Select</option>
              <option *ngFor="let marital_status of userService.marital_statuses; let i = index;" [value]=[marital_status] class="text-capitalize">
                {{marital_status}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 form-group">
            <label>Work Status</label>
            <select class="form-control" [(ngModel)]="patient.user.work_status" name="work_status">
              <option [ngValue]="null" selected>Select</option>
              <option *ngFor="let work_status of userService.work_statuses; let i = index;" [value]=[work_status] class="text-capitalize">
                {{work_status}}
              </option>
            </select>
          </div>
          <div class="col-sm-6 form-group">
            <label>Responsible Party</label>
            <select class="form-control" [(ngModel)]="patient.user.responsible_party" name="responsible_party">
              <option [ngValue]="null" selected>Select</option>
              <option *ngFor="let responsible_party of userService.responsible_parties; let i = index;" [value]=[responsible_party] class="text-capitalize">
                {{responsible_party}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!--Radio Buttons-->
    <div class="row">
      <div class="col-sm-6 form-group radio-btns">
        <h5>Patient Preferred method of communication</h5>
        <label for="sms">
          <input type="radio" name="sms" id="sms" value="sms" [(ngModel)]="patient.patient_preferred_method">
          SMS
        </label>
        <label for="email1">
          <input type="radio" name="email1" id="email1" value="email" [(ngModel)]="patient.patient_preferred_method">
          Email
        </label>
        <label for="sms_email">
          <input type="radio" name="sms_email" id="sms_email" value="sms_email" [(ngModel)]="patient.patient_preferred_method">
          Both
        </label>
      </div>
      <div class="col-sm-6 form-group radio-btns">
        <h5>Deceased</h5>
        <label for="deceased_present">
          <input type="radio" name="deceased_present" id="deceased_present" [value]='false' [(ngModel)]="patient.user.deceased">
          No
        </label>
        <label for="no_deceased">
          <input type="radio" name="no_deceased" id="no_deceased" [value]='true' [(ngModel)]="patient.user.deceased">
          Yes
        </label>
        <div class="row date-col mt-sm" *ngIf="patient.user.deceased">
          <div class="col-sm-2 form-group">
            <label class="mt">Date*</label>
          </div>
          <div class="col-sm-2 form-group">
            <input type="text" placeholder="Deceased Date" name="deceased_date"
                   [(ngModel)]="patient.user.deceased_date"
                   class="form-control"
                   bsDatepicker
                   [bsValue]="bsValue"
                   [maxDate]="maxDate"
                   [required]="patient.user.deceased"
                   [bsConfig]="{ customTodayClass: 'today-date-select',
                         dateInputFormat: 'YYYY-MM-DD', showWeekNumbers:false, adaptivePosition: true}">
          </div>
        </div>
      </div>
    </div>
    <!--END Radio Buttons-->
    <ng-container *ngIf="duplicate_detetced">
      <div class="white-container-box p-lg mb-sm">
        <h4 class="fs22 mt0">Duplicate Record</h4>
        <h6 class="text-muted">Patient with same contact information already exists in system.</h6>
        <div class="media">
          <div class="media-left"><img class="img-rounded thumbnail thumb80" [src]="duplicate_patient.user.profile_pic" alt="Image"/></div>
          <div class="media-body">
            <h4 class="text-primary m0 mb-sm fw-500">{{duplicate_patient.name}}</h4>
            <h5 class="m0 mb-sm fs13"><i class="fa icon-user"></i> &nbsp;{{duplicate_patient.customize_age}}</h5>
            <h5 class="m0 mb-sm fs13"><i class="fa icon-calendar"></i> &nbsp;{{duplicate_patient.customize_dob}}</h5>
            <h5 class="m0 fs13"><i class="fa icon-phone"></i> &nbsp;{{duplicate_patient.phone}}</h5>
          </div>
        </div>
        <h6 class="alert alert-warning" style="line-height:normal; padding:2px 10px 5px;">
          “Select "Yes" if you want to add this patient as Family Member of {{duplicate_patient.name}}, select a Relationship and a new patient will be added.“
        </h6>
        <button class="btn btn-sm btn-primary mr-sm" type="button" (click)="addAsFamilyMember()" >Yes</button>
        <button class="btn btn-sm btn-inverse" type="button" (click)="hide_duplicate()">No</button>
      </div>
    </ng-container>
    <ng-container *ngIf="duplicate_detetced">
      <div id="scroll_to" class="white-container-box p-lg mb-sm" style="display:none;">
        <h4 class="fs22 mt0">Duplicate Record</h4>
        <h6 class="text-muted">Patient with same contact information already exists in system.</h6>
        <div class="media">
          <div class="media-left"><img class="img-rounded thumbnail thumb80" [src]="duplicate_patient.user.profile_pic" alt="Image"/></div>
          <div class="media-body">
            <h4 class="text-primary m0 mb-sm fw-500">{{duplicate_patient.name}}</h4>
            <h5 class="m0 mb-sm fs13"><i class="fa icon-user"></i> &nbsp;{{duplicate_patient.customize_age}}</h5>
            <h5 class="m0 mb-sm fs13"><i class="fa icon-calendar"></i> &nbsp;{{duplicate_patient.customize_dob}}</h5>
            <h5 class="m0 fs13"><i class="fa icon-phone"></i> &nbsp;{{duplicate_patient.phone}}</h5>
          </div>
        </div>
        <h6 class="alert alert-warning" style="line-height:normal; padding:2px 10px 5px;">
          “Select "Yes" if you want to add this patient as Family Member of {{duplicate_patient.name}}, select a Relationship and a new patient will be added.“
        </h6>
        <button class="btn btn-sm btn-primary mr-sm" (click)="addAsFamilyMember()">Yes</button>
        <button class="btn btn-sm btn-inverse" (click)="hide_duplicate()">No</button>
      </div>
    </ng-container>
    <div class="row" *ngIf="add_family_member">
      <label class="col-sm-2 pt-sm fw-500">Relationship*</label>
      <div class="col-sm-5">
        <select name="relationship" [(ngModel)]="patient.relationship_id" required class="form-control text-capitalize" id="relationship">
          <option value="undefined">Select</option>
          <ng-container *ngFor="let relationship of patientService.patient_relationships">
            <option  [value]="relationship.id">{{relationship.name}}</option>
          </ng-container>
        </select>
      </div>
      <div class="col-sm-5">
        <h6 class="text-danger" *ngIf="!patient.relationship_id">Please select Relationship</h6>
      </div>
    </div>
    <div class="alert alert-success text-center" *ngIf="patient.success" [ngClass]="patient.textclass">{{patient.success}}</div>
    <div class="text-center mb-lg">
      <hr class="hr-line">
      <button type="submit" class="btn btn-secondary btn-min mr" [disabled]="!UserForm.form.valid">Save</button>
      <button type="button" (click)="cancelEditForm()" class="btn btn-tertiary btn-outline btn-min">Cancel</button>
    </div>
  </form>
</ng-container>

<div class="section" *ngIf="!edit && !show_extras">
  <div class="heading">
    <h4>
      <i class="fa icon-people fs20 vl-m mr"></i> Demographics
      <a class="fs14" (click)="editProfile()" tooltip="Edit Demographics" *ngIf="!intakeReport"><i class="fa fa-pencil fs12"></i> Edit</a>
    </h4>
    <ng-container *ngIf="!intakeReport">
      <div class="right-btns" *ngIf="!userService.current_user.isPatient">
        <app-email-intake-form [inputId]="patient.id" [patient_id]="patient.id"></app-email-intake-form>
        <button class="fs12 btn btn-secondary btn-outline ml" (click)="signUpRequestForPatientModel.show()"
                placement="bottom" ngbTooltip="Send an email to activate the account" *ngIf="!patient.can_login"
                [disabled]="checkValidEmail()">
          {{patient.activation_mail_date ? ('Activation eMail Sent on ' + (patient.activation_mail_date | date: 'MMM dd y'))  : 'Enable Portal Access'}}
        </button>
        <button class="fs12 btn btn-secondary btn-outline ml" [disabled]="true" *ngIf="patient.can_login">
          <i class="fa fa-check fs12"></i> Portal Activated
        </button>
      </div>
    </ng-container>
  </div>
  <div class="row demographics-flex mt">
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Date of Birth</label>
      <h4 class="fs14 m0 fw-400">{{patient.user.dob | date: 'MMM d, y'}}</h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Gender</label>
      <h4 class="fs14 m0 fw-400">{{patient.gender_for_display()}}</h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Mobile</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.phone.value" (click)="phoneService.initiatePhoneCall(patient.user.phone.value, {name: patient.name, pic: patient.profile_pic})">{{patient.user.phone.value | mask: '(000) 000-0000'}}</span>
        <span class="text-muted" *ngIf="!patient.user.phone.value">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Home Phone</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.fax.value" (click)="phoneService.initiatePhoneCall(patient.user.fax.value, {name: patient.name, pic: patient.profile_pic})">{{patient.user.fax.value | mask: '(000) 000-0000'}}</span>
        <span class="text-muted" *ngIf="!patient.user.fax.value">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Mother's Maiden Name</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.mother_name">{{patient.mother_name}}</span>
        <span class="text-muted" *ngIf="!patient.mother_name">Not Available</span>
      </h4>

    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Email</label>
      <h4 class="fs14 m0 fw-400">
        <span class="text-muted" *ngIf="checkValidEmail() ;else other_div">Not Available</span>
        <ng-template #other_div>{{patient.user.email}}</ng-template>
      </h4>
    </div>
    <div class="col-sm-6 col-xs-12 form-group">
      <label class="field-label">Address</label>
      <ng-container *ngIf="(patient.user.address.line_1 || patient.user.address.city_area.city.name); else notfound">
        <h4 class="fs14 m0 mb-sm fw-400">{{patient.user.address.line_1}}
          <span *ngIf="patient.user.address.line_2">,{{patient.user.address.line_2}}</span>
        </h4>
        <h4 class="fs14 m0 fw-400">
          {{patient.user.address.city_area.city.name}}
          <span *ngIf="patient.user.address.city_area.city.state">,
            {{patient.user.address.city_area.city.state}}</span>
          <span *ngIf="patient.user.address.postal_code">,
            {{patient.user.address.postal_code}}
            </span>
        </h4>
      </ng-container>
      <ng-template #notfound>
        <h4 class="fs14 m0 fw-400">
          <p class="text-muted">Not Available</p>
        </h4>
      </ng-template>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Patient Communication Method</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.patient_preferred_method" class="text-capitalize">{{userService.removeUnderScore(patient.patient_preferred_method)}}</span>
        <span class="text-muted" *ngIf="!patient.patient_preferred_method">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Race</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.race" class="text-capitalize">{{patient.user.race}}</span>
        <span class="text-muted" *ngIf="!patient.user.race">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Ethnicity</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.ethnicity" class="text-capitalize">{{patient.user.ethnicity}}</span>
        <span class="text-muted" *ngIf="!patient.user.ethnicity">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Preferred Language</label>
      <h4 class="fs14 m0 fw-400">
        <span class="text-capitalize" *ngIf="patient.user.preferred_language?.length > 0">
          <ng-container *ngFor="let language of patient.user.preferred_language; let i=index">
          <span>{{language}}</span><span *ngIf="i != patient.user.preferred_language?.length - 1">, </span>
        </ng-container>
        </span>
        <span class="text-muted" *ngIf="patient.user.preferred_language?.length === 0">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Work Status</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.work_status" class="text-capitalize">{{patient.user.work_status}}</span>
        <span class="text-muted" *ngIf="!patient.user.work_status">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Birth Sex</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.birth_sex" class="text-capitalize">{{patient.user.birth_sex}}</span>
        <span class="text-muted" *ngIf="!patient.user.birth_sex">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Sexual Orientation</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.sexual_orientation" class="text-capitalize">{{patient.user.sexual_orientation}}</span>
        <span class="text-muted" *ngIf="!patient.user.sexual_orientation">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Marital Status</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.marital_status" class="text-capitalize">{{patient.user.marital_status}}</span>
        <span class="text-muted" *ngIf="!patient.user.marital_status">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Responsible Party</label>
      <h4 class="fs14 m0 fw-400">
        <span *ngIf="patient.user.responsible_party" class="text-capitalize">{{patient.user.responsible_party}}</span>
        <span class="text-muted" *ngIf="!patient.user.responsible_party">Not Available</span>
      </h4>
    </div>
    <div class="col-sm-3 col-xs-6 form-group">
      <label class="field-label">Deceased</label>
      <h4 class="fs14 m0 fw-400">
        <span class="text-capitalize">{{patient.user.deceased ? 'Yes' : 'No'}}
          <span *ngIf="patient.user.deceased">
            {{patient.user.deceased_date | date:'MM/d/y'}}
          </span>
          </span>
      </h4>
    </div>
  </div>
</div>

<ng-container *ngIf="!show_extras">
  <app-econtact [patient]="patient" *ngIf="patient.id" [intakeReport]="intakeReport"></app-econtact>
  <div class="section">
    <app-patient-insurance [patient]="patient" [intakeReport]="intakeReport"></app-patient-insurance>
  </div>
  <div class="section">
    <app-patient-medical-center [patient_id]="patient.id" [intakeReport]="intakeReport"></app-patient-medical-center>
  </div>
</ng-container>

<div class="modal fade" bsModal #signUpRequestForPatientModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="signUpRequestForPatientModel.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Enable Portal Access</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p-sm m0 text-center">
          Are you sure to want to enable portal access for this patient, an email request will be sent to patient to activate the account?
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="signUpRequestForPatient(patient.id); signUpRequestForPatientModel.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="signUpRequestForPatientModel.hide();">No</button>
      </div>
    </div>
  </div>
</div>
