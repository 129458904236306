import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-verification-banner',
  templateUrl: './verification-banner.component.html',
  styleUrls: ['./verification-banner.component.scss']
})
export class VerificationBannerComponent implements OnInit {


  constructor(public user_service: UserService) { }

  ngOnInit() {
  }

  get force_close() {
    return this.user_service.force_close;
  }

  set force_close(value) {
    this.user_service.force_close = value;
  }

  close_banner() {
    this.force_close = true;
  }

  getMessage() {
    if (!this.user_service.current_user || !this.user_service.patient_to_verify) {
      return ''
    }
    let types = [];
    if (!this.user_service.patient_to_verify.email_verified) {
    types.push('eMail');
    }

    if (!this.user_service.patient_to_verify.mobile_verified) {
      types.push('Mobile Number');
    }
    return types.join(' and ');
  }
}
