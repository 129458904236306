import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Patient} from '../../doctor/patient';
import {Appointment} from '../../models/appointment';
import {TabsService} from '../../../services/tabs.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrls: ['./patient-dashboard.component.scss']
})
export class PatientDashboardComponent implements OnInit {
  @Input() patient: Patient;
  @Input() appointment: Appointment;
  @Output() showPatientSettings: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Input() injected_practice: Appointment;
  public stage_ids: number[] = [0, 1, 2, 3, 9];
  public selected_stage_id: number;
  public menu_items: Object = {0: 'Summary', 1: 'Appointments', 2: 'Medical Chart', 3: 'Demographics', 4: 'Additional Info',
    5: 'Cases', 6: 'Activity', 7: 'Programs', 8: 'Settings', 9: 'Health Calendar', 10: 'Provider eNotes'};
  stage_id: any;

  constructor(public _tabs_service: TabsService, public _userService: UserService) {
    this.selected_stage_id = 0;
  }

  ngOnInit() {
  }

  onSelect(stage_id: number) {
    this.selected_stage_id = stage_id;
  }

  dynamicClassForVerificationBanner() {
    return this._userService.show_verification_banner() ? 'top-space' : '';
  }
}
