import { Component, OnInit, Renderer2 } from '@angular/core';
import {EnoteService} from '../../services/enote.service';
import {ControlComponent} from '../control/control.component';
import {Control, Value} from '../enote_models/control';
import {TermControl, TermControlValue} from '../enote_models/term_control';
import { IMultiSelectOption , IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';

@Component({
  selector: 'app-note-controls',
  templateUrl: './note-controls.component.html',
  styleUrls: ['./note-controls.component.scss']
})
export class NoteControlsComponent extends ControlComponent implements OnInit {
  public value_selected = false;
  public optionsModel: any[];
  public myOptions: IMultiSelectOption[];
  public showTemp = false;
  public term_control_value: TermControlValue;

  // Settings configuration
  selectSettings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false,
    selectionLimit: 1,
    autoUnselect: true,
    closeOnSelect: true
  };

  // Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select Directions',
    allSelected: 'All selected',
  };


  constructor(public _enote_service: EnoteService, public renderer: Renderer2) {
    super(_enote_service, null)
  }
  setFocus() {
    setTimeout(() => {
      let element =  document.getElementById(this.term_control.id.toString()) as HTMLElement;
      element.focus();
    }, 5)

  }

  ngOnInit() {
    this.setSettings();
    this.initializeControlValue();
  }
  setSettings() {
    if (this.term_control.control.isMultiSelectControl()) {
      this.selectSettings['selectionLimit'] = 0;
      this.selectSettings['dynamicTitleMaxItems'] =  5;
      this.selectSettings['closeOnSelect'] = false;
    }
  }

  initializeControlValue() {
    if (this.term_control.isInputText()) {
      if (this.term_control.term_control_values.length === 0) {
        this.term_control_value = new TermControlValue();
      }
      else {
        this.term_control_value = this.term_control.term_control_values[0];
      }
      this.term_control_value.value.control_id = this.term_control.control_id;
      this.term_control_value.recent_value = true;
      this.term_control.term_control_values = [this.term_control_value]
    }
    this.myOptions = this.term_control.control.values.map(value => { return {id: value.id, name: value.name} });
    this.myTexts.defaultTitle = this.term_control.control.name;
    this.optionsModel = this.term_control.term_control_values.filter(a => a.recent_value).map(term_control_value => term_control_value.control_value_id)
    console.log(this.myOptions, this.optionsModel);
  }

  selectStatuses() {
    this.setCustomControlValue(this.optionsModel);
  }
  setCustomControlValue(ids: any[]) {
    if (ids.length == 0) {
      return;
    }
    this.term_control.term_control_values.forEach(control_value => control_value.recent_value = false);
    let selected_values = this.term_control.control.values.filter(value => ids.includes(value.id));
    let selected_values_ids = selected_values.map(a => a.id);
    let added_values = this.term_control.term_control_values.filter(control_value =>  selected_values_ids.includes(control_value.value.id));
    if (added_values.length > 0) {
      added_values.forEach(a => a.recent_value = true);
      let added_values_ids = added_values.map(a => a.value.id);
      selected_values_ids = selected_values_ids.filter(a => !added_values_ids.includes(a))
      selected_values = selected_values.filter(a => selected_values_ids.includes(a.id))
    }
    if (selected_values.length > 0) {
      selected_values.forEach(select_value => {
          let term_control_value = new TermControlValue();
          term_control_value.recent_value = true;
          term_control_value.value = select_value;
          term_control_value.control_value_id = select_value.id;
          this.term_control.term_control_values.push(term_control_value)

      })
    }
    console.log(this.term_control.term_control_values);
    this.value_selected = true;
  }

  inputFocusout(term_control_value) {
    this.showTemp = false;
    this.term_control_value.control_value_id = term_control_value.values[0]?.id;
  }
}
