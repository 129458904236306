import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Injector} from '@angular/core';
import {Task} from '../newtask/task';
import { Comment} from '../comment';
import {UserService} from '../../../../services/user.service';
import {CommentService} from '../comment.service';
import {TaskService} from '../../../../services/task.service';
import {LoaderService} from "../../../../services/loader.service";
import {TabsService} from "../../../../services/tabs.service";
import {TrackingService} from '../../../../services/tracking.service';
import {NewtaskComponent} from '../newtask/newtask.component';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-edittask',
  templateUrl: './edittask.component.html',
  styleUrls: ['./edittask.component.scss']
})
export class EdittaskComponent implements OnInit, OnDestroy {
  // text : string;
  comment: Comment = new Comment();
  task: Task = new Task();
  editTask = false;
  programme_type: any;
  public add_patient_button = true;
  @Output() update = new EventEmitter<Task>();
  @ViewChild('deleteModal') deleteModal;
  originalTask: Task = new Task();
  view_custom_injector: Injector;
  public newTaskComponent = NewtaskComponent;
  private newTasksubscription: Subscription;
  constructor(public tabsService: TabsService, public loaderService: LoaderService,
              public taskService: TaskService, public commentService: CommentService,
              public injector: Injector, private userService: UserService, public trackingService: TrackingService) {
    this.newTasksubscription = this.taskService.closeNewTaskForm.subscribe( resp => {
      if (resp) {
       this.closeNewForm();
      }
    });
  }


  ngOnInit() {
    this.task = this.injector.get('task', null);
    this.programme_type = this.injector.get('programme_type', null);
    this.programme_type = this.injector.get('programme_type', null);
    this.add_patient_button = this.injector.get('add_patient_button', true);
    this.commentService.getComments(this.task.id, 'Task').then(resp => {
    this.task.comments = resp.map(comment => new Comment().load_from_json(comment));
    })
  }

  closeNewForm() {
    if (this.task.id ===  this.taskService.taskBeforeEdit.id) {
      this.task = this.taskService.taskBeforeEdit.clone();
    }
    this.editTask = false;
  }
  markCompleted() {
    this.task.status_id = 'Completed';
    this.taskService.save(this.task).then(resp => {
      this.taskService.reloadTasks.next(this.task);
    })
  }
  deleteTask(task) {
    this.loaderService.show()
    this.taskService.delete(task.id).then(resp => {
      this.taskService.reloadTasks.next(task);
      this.taskService.closeNewTaskForm.next(true);
      this.loaderService.hide();

  /*    if (task.source_type === 'ProgrammeEnrolment') {
        this.taskService.closeNewTaskForm.next(true)
      } else {
        this.tabsService.close_tab(this.tabsService.active_tab());
      }*/
    }).catch(resp => {
      this.loaderService.hide();
      this.deleteModal.hide();
    })
  }
  stopAction(task: Task) {
    const trackingResult = this.trackingService.stopTracking(this.task.temp_activity_id);
    this.trackingService.logTimeForPatientActivity({duration: trackingResult.duration, description: task.task_name,
      activity_type: 'task', source_id: task.id, activiable_id: task.source_id})
  }
  updateTask(task) {
    this.view_custom_injector  = Injector.create([
      {provide: 'task', useValue: this.task},
      {provide: 'id', useValue:  this.task.id},
      {provide: 'editTask', useValue:  true},
      {provide: 'add_patient_button', useValue:  this.add_patient_button},

    ], this.injector);
    this.taskService.taskBeforeEdit = this.task.clone();
    this.editTask = true;
  }

  ngOnDestroy(): void {
    if (this.editTask) {
      this.taskService.reloadTaskObjectListener.next(this.task);
    }
  }
}
