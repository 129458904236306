export class Tag {
  value: number;
  display: string;
  readonly: boolean;
  id: number;

  load_from_json(json) {
    this.value = json.id;
    this.id = json.id;
    this.display = json.display;
    return this;
  }

  to_params() {
    return {
      tag: {
        name: this.display,
        value: this.value,
        id: this.id,
      }
    }
  }

}

export class PatientReviewTag {
  tag_id: number;
  patient_review_id: number;
  tag: Tag;
  load_from_json(json) {
  }
}
