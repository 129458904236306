import {Component, OnInit} from '@angular/core';
import {Params, ActivatedRoute}   from '@angular/router';
import {PracticesService} from '../../services/practices.service';
import  {Practice} from '../../routes/practices/practices/practice'
import {TabsService} from '../../services/tabs.service';
import {LineLayout, PlainGalleryConfig, PlainGalleryStrategy, GalleryService} from '@ks89/angular-modal-gallery';
import {PhoneService} from '../../voice-call/phone.service';


@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})
export class HealthPortalPracticeComponent implements OnInit {
  public practice: Practice = new Practice();
  public stage_ids: number[] = [0, 1, 2, 3];
  public show_all = false;
  public selected_stage_id: number;
  public menu_items: Object = {0: 'Doctors', 1: 'Locations', 2: 'Staff', 3: 'Practice Reviews'};
  plainGalleryRow: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.ROW,
    layout: new LineLayout({ width: '100%', height: '100%' }, { length: 1, wrap: true }, 'flex-start')
  };
  plainGalleryRow1: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.ROW,
    layout: new LineLayout({ width: '80px', height: '80px' }, { length: 3, wrap: true }, 'flex-start')
  };

  openModalWindow = false;
  imagePointer: number;
  constructor(private route: ActivatedRoute, private practiceService: PracticesService, private tabs_service: TabsService,
              private galleryService: GalleryService, public phoneService: PhoneService) {
    this.selected_stage_id = 0;
    this.tabs_service.register('health_portal_practice_components');
  }
  onSelect(stage_id: number) {
    this.selected_stage_id = stage_id;
  }
  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        this.fetchPractice(+params['id'])
      })
  }
  show_all_reviews() {
    this.show_all = !this.show_all;
  }
  iconMapping(item): any {
    let iconMappingHash = { 0: 'fa fa-user-md', 1 : 'fa fa-map-marker'}
    let icon = iconMappingHash[item];
    return icon;
  }
  fetchPractice(id: number) {
    this.practiceService.getPractice(id, {include_physicians: true}).then(practice => {
      this.practice.load_from_json(practice)
      this.practice.practice_pics = this.practice.practice_pics.filter(e => e.modal.description == 'photo')
    });
  }

  openModalViaService(id: number | undefined, index: number) {
    this.openModalWindow = true;
    this.galleryService.openGallery(id, index);
  }

  cancelImageModal() {
    this.openModalWindow = false;
  }

  showImageModal() {
    this.openModalWindow = true;
  }

}
