<div #list class="other-user-video"
     [style.display]="videoChatService.remoteVideoTrackStatus === 'disabled' ? 'none' : 'block'">
</div>
<!--<h4 class="video-disable-msg text-center"
     [style.display]="videoChatService.remoteVideoTrackStatus === 'enabled' ? 'none' : 'block'">
 {{userService.current_user?.isPracticeUser ? 'Patient' : 'Doctor'}} has disabled video.
</h4>-->
<div id="participant-list" class="text-danger text-center" *ngIf="participantRemoved || this.videoChatService.remoteParticipentRemoved">
  <h3 class="text-center">Your call with <b>{{videoChatService.patient_full_name}}</b> has been disconnected.</h3>
</div>
