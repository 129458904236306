import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges,
  Output,
  EventEmitter, HostListener
} from '@angular/core';
import {MessageService} from '../../../../services/message.service';
import {UserService} from '../../../../services/user.service';
import {Chatroom, Message} from '../chatroom'
import baseApiUrl from  '../../../../../app/globals'
import {Tab} from '../../../../tabs/tab';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {Appointment} from '../../../../health/models/appointment';
import {TabsService} from '../../../../services/tabs.service';
import {Patient} from '../../../../health/doctor/patient';
import {AttachmentViewerService} from '../../../../services/attachment-viewer-service';
import {PatientDocument} from '../../../../health/patient-profile/patient-dashboard/chart/document';
import {LoaderService} from '../../../../services/loader.service';
import {HttpTokenService} from '../../../../services/http.token.service';
import {AppointmentService} from '../../../../services/appointment.service';
declare const $: any; // Declare jQuery

@Component({
  selector: 'app-chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['./chat-detail.component.scss']
})
export class ChatDetailComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('unread_label') private unreadContainer: ElementRef;
  @ViewChild('block_modal') block_modal;
  @Input() public chatroom: Chatroom;
  show_documents = false;
  block_status: boolean;
  public message: any = {};
  @Input() public show_group_info: Boolean;
  @Output() public cross_clicked = new EventEmitter<Boolean>();
  public show_attachment_popup = false;
  @Input() reciever_id: number;
  @Output() group_clicked = new EventEmitter<Boolean>();
  public term = {focus_on_init: false, show_typeahead: false, text: ''}
  public  literals = [{text: 'dummy', freetext: true, show_typeahead: false, contenteditable: false, type_text: '', taggable: {}}]
  public  count = 0;
  public audio;
  public   timer;
  public   timer2;
  public first_message: Message;
  public show_top_date: boolean;
  public audio2;
  public patient_id_for_documents;
  public center_dates: any;

  constructor(public messageService: MessageService,
              public token_service: HttpTokenService,
              public appointmentService: AppointmentService,
              public _user_service: UserService,
              private tabs_service: TabsService,
              public loaderService: LoaderService,
              public attachment_viewer_service: AttachmentViewerService) {
  }
  ngOnInit() {
    this.loadAudio();
    this.scrollToBottom();
    this.messageService.getScrollToBottom.subscribe(chatroom => {
      if (this.chatroom && chatroom.id === this.chatroom.id) {
        this.scrollToBottom();
      }
    });
    this.messageService.subscribeToMessageObserver('chat-detail-subscriber', message => {
      this.message = message;
      this.submit();
    })
  }


  openViewer(message: Message) {
    if (message.preview_avaialble) {
    this.attachment_viewer_service.loadAttachment(message.attachment_id).then();
    }
  }

  preview_closed() {
    this.attachment_viewer_service.toggleView();
  }

  notify_cross_clicked() {
    this.cross_clicked.emit(true);
  }

  loadAudio() {
    this.audio = new Audio();
    this.audio.src = baseApiUrl + '/assets/chatalert.mp3';
    this.audio.load();
    this.audio2 = new Audio();
    this.audio2.src = baseApiUrl + '/assets/chatalert2.mp3';
    this.audio2.load();
  }
  playAudio(type) {
    if (type === 1) {
    this.audio.play();
    }
    if (type === 2) {
      this.audio2.play();
    }
  }

  canBlock() {
    return this.chatroom.is_single_user && this.chatroom.isActive && this.current_user() && !this.current_user().isPatient;
  }

  canUnBlock() {
    // tslint:disable-next-line:max-line-length
    return this.chatroom.is_single_user && !this.chatroom.isActive && !this.current_user().isPatient && this.chatroom.blocked_by_id === this.current_user().id;
  }

  handleBlock(block) {
    this.block_status = block;
    this.block_modal.show();
  }

  processBlock(block) {
    let params = {block: block, chatroom_id: this.chatroom.id}
    this.messageService.handleBlock(params).subscribe(a => {
      this.chatroom.status = a.chatroom.status;
      this.block_modal.hide();
    });
  }

  ngOnChanges() {
    this.broadcastChannel();
    this.messageService.receiver_id['user_id'] = this.messageService.reciever_id;
    this.chatroom_messages();
    this.scrollToBottom();
    if (this.chatroom) {
    this.chatroom.logged_user_id = this._user_service.current_user.id;
    }
    this.literals = [{text: '', freetext: true, show_typeahead: false, contenteditable: false, type_text: '', taggable: {}}];
  }

  toggleGroupInfo() {
      this.group_clicked.emit(true);
  }

  showFileBox() {
    this.show_attachment_popup = false;
    setTimeout( () => {
      this.show_attachment_popup = true;
    }, 75);
  }

  handleFileUpload() {
    this.patient_id_for_documents = undefined;
    let pid = this.current_user() && this.current_user().isPatient && this.current_user().profileable_id
    if (!pid && this.chatroom.recipient_chat_user && this.chatroom.recipient_chat_user.isPatient) {
      pid =  this.chatroom.recipient_chat_user.profileable_id;
    }
    if (pid) {
      this.patient_id_for_documents = pid;
      this.show_documents = false;
      setTimeout( () => {
        this.show_documents = true;
      }, 75);
    } else {
      this.showFileBox();
    }
  }

  scrollToBottom(): void {
    setTimeout( () => {
      let scrollTopPos = this.myScrollContainer && this.myScrollContainer.nativeElement.scrollHeight + 550;
      try {
        if (this.unreadContainer && this.unreadContainer.nativeElement) {
          console.log('setting scroll for', this.unreadContainer.nativeElement);
          scrollTopPos = Math.max(this.unreadContainer.nativeElement.offsetTop - 165, 0);
        }
        console.log('set scroll to', scrollTopPos);
        if (this.myScrollContainer) {
        this.myScrollContainer.nativeElement.scrollTop = scrollTopPos;
        }
      } catch (err) { }
    }, 10);

  }



  page_scrolling(event, page_number?) {
    this.show_top_date = true;
    clearTimeout(this.timer);
    clearTimeout(this.timer2);
    this.timer2 = setTimeout( () => {
      console.log('hide top date');
      this.show_top_date =  false;
    }, 2000);
    this.timer = setTimeout( () => {
      let message_in_view = $('.messages-container li.message').filter(function(i, e) {
         return $(e).position().top > 30 && $(e).position().top <= 80
      });
      if (message_in_view.length > 0 ) {
        let message_id = parseInt($(message_in_view[0]).attr('message-id') );
        this.first_message = this.chatroom_messages().filter( m => {
          return m.id === message_id
        })[0];
      }
      if (this.first_message) {
      this.center_dates = this.first_message.renderDate(this.first_message.created_at);
      }
    } , 10 );
  }

  broadcastChannel() {
    // tslint:disable-next-line:max-line-length
    if (!this.chatroom || !this.chatroom.id || this.messageService.message_subscriber['receiver_subscribed' || this.messageService.detail_listener]) {
      return true;
    }
    this.messageService.detail_listener  = this.token_service.cable.subscriptions.create(
      'UserChannel',
      {
        connected: () => console.log('connected'),
        disconnected: () => console.log('disconnected'),
        received: (data) => this.receivedHandler(data)
      });
    console.log('today patient', this.token_service.cable)
  }

  receivedHandler(message)  {
        if ((message as any).channel_action === 'notify_typing') {
          let chatroom = this.messageService.chatrooms.filter(c => c.id === (message as any).chatroom_id)[0]
          if (chatroom && (message as any).user.id !== this.current_user().id) {
            chatroom.handleUserTyping((message as any).user);
          }
          return;
        }
        if ((message as any).message_type === 'system') {
          if ((message as any).sub_type === 'file_processed' || (message as any).sub_type === 'call_started') {
            this.messageService.handleSystemMessage(new Message().load_from_json(message));
          }
          return;
        }
        if ((message as any).channel_action === 'message_deleted') {
          let chtrom = this.messageService.find_by_chatroom_id((message as any).chatroom_id);
          // tslint:disable-next-line:no-unused-expression
          chtrom && chtrom.delete_message((message as any).id);
          return;
        }
        let audio = 1;
        if (this.messageService.selected_chatroom && (message as any).chatroom_id === this.messageService.selected_chatroom.id) {
          let new_message = new Message();
          new_message = new_message.load_from_json(message);
          this.messageService.selected_chatroom.push_message(new_message);
          this.scrollToBottom();
        } else {
          let new_message = new Message();
          new_message = new_message.load_from_json(message);
          let message_for = this.messageService.find_by_chatroom_id(new_message.chatroom_id);
          if (message_for) {
            message_for.push_message(new_message);
          }
          audio = 2;
        }
        if ((message as any).user_id !== this.current_user().id) {
          this.playAudio(audio);
        }
    this.messageService.message_subscriber['receiver_subscribed'] = true;
  }

  submit() {
    console.log('submitting message');
   if (this.selected_chatroom() && this.selected_chatroom().id) {
     this.update_chatroom_messages();
   } else {
     this.create_new_chatroom();
   }
  }


  verifyChatroom() {
    this.message['body'] = 'Mobile number verified successfully. Chat is open for messages now';
    this.message['message_type'] = 'system';
    this.message['sub_type'] = 'phone_verified';
    this.submit();
  }


  submitInput() {
    // this.term['text']=this.literals.map((lit) => {
    //   return lit.freetext ? `${lit.text}${lit.type_text}` : `@${lit.taggable['name']}`;
    // }).filter( a => a.length > 0).join(' ');
    // this.literals = [{text: "",freetext: true,show_typeahead: false,contenteditable: false,type_text: '',taggable: {}}];
    let taggs_data  = this.get_message_tags_attributes();
    this.message['body'] = taggs_data.text;
    if (taggs_data.taggables.length > 0) {
      this.message['message_type'] = 'tagged';
      this.message['message_tags_attributes'] = taggs_data.taggables;
    }
    this.term.text = '';
    this.literals = [{text: '', freetext: true, show_typeahead: false, contenteditable: false, type_text: '', taggable: {}}];
    this.submit();
  }

  get_message_tags_attributes() {
    let taggables = [];
    let text = '';
    let counter = 0;
    this.literals.filter((lit) => !lit.freetext && lit.taggable).forEach((tag) => {
      taggables.push({taggable_id: tag.taggable['value'], taggable_type: 'Patient'})
    });

    this.literals.forEach((lit) => {
      if (lit.freetext && `${lit.text}${lit.type_text}`.length > 0) {
        text = `${text}${lit.text}${lit.type_text}`
      } else if (!lit.freetext && lit.taggable) {
        taggables[counter]['index'] = Math.max(text.length - 1, 0);
        counter = counter + 1;
      }
    });
    return {text: text, taggables: taggables}
  }


  handle_file_upload(data) {
    if (data['id']) {
    this.message['attachment_id'] = data['id'];
    this.message['message_type'] = 'file';
    this.submit();
    }
  }

  show_verification_box() {
    return this.current_user() && this.current_user().isPatient && this.chatroom && this.chatroom.requiresVerification()
  }


  update_chatroom_messages() {
    this.messageService.receiver_id['user_id'] = this.messageService.reciever_id;
    this.message['reciever_id'] = this.messageService.reciever_id;
    this.message['chatroom_id'] = this.selected_chatroom().id;
    this.messageService.create({message: this.message}).subscribe(message => {
      if ((message as any).current_message.chatroom_id === this.selected_chatroom().id) {
        this.messageService.setChatroomCount((message as any).current_message.chatroom_id, 0);
        this.selected_chatroom().push_raw_message((message as any).current_message);
      }
      this.scrollToBottom();
    });
    this.message = {};
  }

  create_new_chatroom() {
    // tslint:disable-next-line:max-line-length
    this.chatroom = this.chatroom.create_chatroom_message(this._user_service.current_user, this.messageService.receiver_id['user_id'], this.message.body, this.message.message_type, this.message.sub_type);
    this.messageService.createChatroom(this.chatroom).subscribe(data => {
      this.messageService.changeSelectedChatroom(this.messageService.selected_chatroom.load_from_json(data.chatroom, true));
      this.messageService.make_chatroom_connection(this.messageService.selected_chatroom);
      this.broadcastChannel();
      this.messageService.receiver_id['user_id'] = this.messageService.reciever_id;
      this.message = {}
    });
  }

  @HostListener('scrolll', ['$eventt'])
  handleThreadScroll(event) {
    let scrollTop = this.myScrollContainer.nativeElement && this.myScrollContainer.nativeElement.scrollTop
    if (scrollTop && scrollTop < 40 && !this.chatroom.all_loaded && !this.chatroom.load_in_progress) {
      this.messageService.load_prevMessage(this.chatroom);
    }
    this.page_scrolling(event);
  }

  chatrooms() {
    return this.messageService.chatrooms_list();
  }

  selected_chatroom() {
    return this.messageService.active_chatroom();
  }

  applySenderRecieverClass(message_owner_id, message?) {
    if (message && message.isSystemType) {
      return 'system text-center'
    }
    if (this._user_service.current_user && this._user_service.current_user.id === message_owner_id) {
      return 'green_bubble';
    } else if (message_owner_id) {
    return 'white_bubble';
 }

  }


  deleteMessage(message) {
    this.messageService.deleteMessage(message).subscribe(res => {
      let index = this.messageService.selected_chatroom.messages.indexOf(message, 0);
      if (index >= 0) {
        this.messageService.selected_chatroom.messages.splice(index, 1);
        const newMessageIndex = Math.max(index - 1, 0);
        const newMessage = this.messageService.selected_chatroom.messages.length > 0
          ? this.messageService.selected_chatroom.messages[newMessageIndex]
          : new Message();
        this.messageService.selected_chatroom.push_message(newMessage);
        }
    });
  }

   current_user() {
    return this._user_service.current_user;
  }


  chatroom_messages() {
      if (this.messageService.selected_chatroom) {
        return this.messageService.selected_chatroom.messages;
      } else {
        return [];
      }
  }

  handleTagClick(tag) {
    if (tag.taggable_type === 'Patient' && !this.current_user().isPatient) {
      this.add_to_tabs({id: tag.taggable_id, name: tag.name});
    }
  }
  add_to_tabs(patient) {
    // tslint:disable-next-line:max-line-length
    this.tabs_service.add_tab(new Tab({title: patient.name, icon: 'fa fa-hospital'}, PatientProfileComponent, patient.id, {id: patient.id, appointment: new Appointment}))
  }

  ngOnDestroy() {
    // this.messageService.unsubscribeChannel(this.chatroom);
    // this.messageService.message_subscriber['receiver_subscribed'] = true;
    this.messageService.changeSelectedChatroom(undefined);
    this.messageService.resetScrollToBottom();
  }

  sendDocument(document: PatientDocument) {
    this.message['body'] = ''
    this.message['message_type'] = 'document_tagged';
    this.message['message_tags_attributes'] = [{taggable_id: document.id, taggable_type: 'Document'}];
    this.submit();
  }
  triggerYes() {
    this.processBlock(this.block_status);
  }



}
