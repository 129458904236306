import {Component, Input, OnInit} from '@angular/core';
import {ProgramEnrollment} from '../../../eligible-patient';
import {Patient} from '../../../../health/doctor/patient';
import {Goal} from '../../../goal';
import {LoaderService} from '../../../../services/loader.service';
import {EnrolmentsService} from '../../../enrolments.service';
import {GoalService} from '../../../goal.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {Task} from '../../../../routes/practice-site/tasks/newtask/task';
export interface GoalResponse {
  [key: string]: Goal[];
}
export interface TaskResponse {
  [key: string]: Task[];
}
export interface CarePlan {
  name: string;
  id: string;
  isCollapsed: boolean;
}
@Component({
  selector: 'app-enrolled-patient-plan',
  templateUrl: './enrolled-patient-plan.component.html',
  styleUrls: ['./enrolled-patient-plan.component.scss']
})
export class EnrolledPatientPlanComponent implements OnInit {
  @Input() programEnrollment: ProgramEnrollment;
  @Input() patient: Patient;
  @Input() programme_type;
  carePlans: CarePlan[] = [];
  taskResponse: TaskResponse;
  goalResponse: GoalResponse;

  constructor(public loaderService: LoaderService, public goalService: GoalService,
              public enrolmentsService: EnrolmentsService,
              public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    this.getEnrolmentGoals();
    this.getEnrolmentTasks();
  }
  getEnrolmentGoals() {
    this.loaderService.show();
    this.goalService.getEnrolmentGoals(this.programEnrollment.id).then((resp: GoalResponse) => {
      Object.keys(resp).forEach(key => {
        if (this.carePlans.filter(c => c.name === key).length === 0) {
          let id = resp[key].length > 0 ? resp[key][0]['care_plan_id'] : undefined;
          this.carePlans.push({name: key, id: id, isCollapsed: true})
        }
        resp[key] = resp[key].map(goal => new Goal().load_from_json(goal))
      })
      if (!resp['Custom']) {
        if (this.carePlans.filter(c => c.name === 'Custom').length === 0) {
          this.carePlans.push({name: 'Custom', id: undefined, isCollapsed: true})
        }
        resp['Custom'] = [];
      }
      this.goalResponse = resp;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }
  getEnrolmentTasks() {
    this.loaderService.show();
    this.enrolmentsService.getEnrolmentTasks(this.programEnrollment.id).then((resp: TaskResponse) => {
      Object.keys(resp).forEach(key => {
        if (this.carePlans.filter(c => c.name === key).length === 0) {
          let id = resp[key].length > 0 ? resp[key][0]['care_plan_id'] : undefined;
          this.carePlans.push({name: key, id: id, isCollapsed: true})
        }
        resp[key] = resp[key].map(task => new Task().load_from_json(task))
      })
      if (!resp['Custom']) {
        if (this.carePlans.filter(c => c.name === 'Custom').length === 0) {
          this.carePlans.push({name: 'Custom', id: undefined, isCollapsed: true})
        }
        resp['Custom'] = [];
      }
      this.taskResponse = resp;
      this.carePlans[0].isCollapsed = false;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }
}
