import {Component, Injector, Input, OnInit} from '@angular/core';
import {EnoteComponent} from '../../../../../enote/enote/enote.component';
import {EnoteService} from '../../../../../services/enote.service';
import {RichTextTemplate} from '../../../../../routes/rich-text-template-index/rich-text-template';
import {Template} from '../../../../../enote/enote_models/template';
import {GlobalErrorHandlerService} from '../../../../../services/global-error.service';
import {LoaderService} from '../../../../../services/loader.service';

@Component({
  selector: 'app-print-template-content',
  templateUrl: './print-template-content.component.html',
  styleUrls: ['./print-template-content.component.scss']
})
export class PrintTemplateContentComponent implements OnInit {
  @Input() template
  public enoteComponent: any = EnoteComponent;
  public custom_injector: Injector;
  public selectedTemplate: Template

  constructor(public enoteService: EnoteService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService) {
  }

  ngOnInit(): void {
    if (!this.template.is_rich_text) {
      this.setSelectedTemplate(this.template)
    } else {
      this.template = new RichTextTemplate().load_from_json(this.template);
    }
  }

  setSelectedTemplate(selectedTemplate) {
    this.enoteService.getTemplate(selectedTemplate.id).then(resp => {
      this.selectedTemplate = this.enoteService.template;
    }).catch(resp => {
      this.loaderService.hide()
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

}
