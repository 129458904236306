import {Component, OnInit, Injector, ChangeDetectorRef, ViewChild} from '@angular/core';
import {Appointment} from '../../../../health/models/appointment'
import {Observable}        from 'rxjs/Observable';
import {PatientService} from '../../../../services/patient.service'
import {AppointmentService} from '../../../../services/appointment.service'
import {Service} from '../../../../routes/practices/practices/practice';
import {SpecialtySearchService} from '../../../../services/specialty-search.service';
import {Practice} from '../../../practices/practices/practice';
import {UserService} from '../../../../services/user.service';
import {DoctorService} from '../../../../services/doctor.service';
import {Calendar, Doctor} from '../../../practices/doctors/doctor';
import {SlotDatesService} from '../../../../services/slot-dates.service';
import {TabsService} from '../../../../services/tabs.service';
import {Location} from '../../../practices/locations/location';
import {Patient} from '../../../../health/doctor/patient';
import {DatePipe} from '@angular/common';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {LoaderService} from '../../../../services/loader.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {PatientAuthorizationService} from '../../../../services/patient-authorization.service';
import { mergeMap } from 'rxjs/operators';
import {Amenity, BookingMethod} from '../../../practices/amenity/amenity';
import {AmenityService} from '../../../practices/amenity/amenity.service';
import {LocationService} from '../../../../services/location.service';
import {PhoneService} from '../../../../voice-call/phone.service';


@Component({
  selector: 'app-walk-in',
  templateUrl: './walk-in.component.html',
  styleUrls: ['./walk-in.component.scss']
})
export class WalkInComponent implements OnInit {

  public patient: Patient = new Patient();
  public selected_patient: Patient = new Patient();
  public appointment: Appointment = new Appointment();
  public dataSourcePatients: Observable<any>;
  public dataSourceServices: Observable<any>;
  public asyncSelected = '';
  public  show_patient_form = true;
  public asyncSelectedService = '';
  public typeaheadLoading = false;
  public typeaheadLoadingService = false;
  public typeaheadNoResults = false;
  public typeaheadNoResultsService = false;
  public is_data_available = false;
  public is_disable = true;
  public practice: Practice = new Practice();
  public doctor_id: any;
  public refresh = false;
  public show_waiting = false;
  public activeTab = 'bg-greenish';
  public deActiveTab = 'bg-white';
  public doctor: Doctor;
  public appointment_from;
  public slot_sizes: Array<any> = this.initialize_slot_sizes();
  public locations: Location[];
  public doctor_locations: Location[];
  public show_navigator = true;
  public show_future_slots = true;
  public book_flow: boolean;
  public booking_flow = '';
  public show_attachment_popup = false;
  public booking_from_calendar = false;
  public time;
  public doctor_info;
  public calendar_info;
  public provider: Doctor;
  public providers: Doctor[];
  public type: string;
  public injected_practice: any;
  public selected_slot_calendar: Calendar;


  @ViewChild('authorizationModal') authorizationModal: ModalDirective;
  amenities_empty = false;
  public amenity_methods: BookingMethod[];
  amenity_id: any;
  selected_amenity: Amenity;
  location_id: any;
  public amenities: Amenity[] = [];
  public selected_location: Location;

  constructor(public  datePipe: DatePipe,
              private loader: LoaderService, public phoneService: PhoneService,
              private globalErrorHandlerService: GlobalErrorHandlerService,
              private _tab_service: TabsService,
              private _slot_date_service: SlotDatesService,
              private injector: Injector,
              public _user_service: UserService,
              private _appointment_service: AppointmentService,
              private _patient_service: PatientService,
              private specialtySearchService: SpecialtySearchService,
              private doctorService: DoctorService,
              private locationService: LocationService,
              private amenityService: AmenityService,
              public patientAuthService: PatientAuthorizationService) {
    this.patient.patient_type = 'walkin';
    this.dataSourcePatients = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.getPatients(token)));

    this.dataSourceServices = Observable.create((observer: any) => {
      observer.next(this.appointment.service_name);
    }).pipe(mergeMap((token: string) => this.get_services(token)));
  }

  ngOnInit() {
    /*this.booking_from_calendar = this.injector.get('booking_from_calendar', null)
    this.doctor_info = this.injector.get('selected',null);
     let time = this.injector.get('event',null);
     this.time = time.date

    let appointment = this.injector.get('appointment', null);
    this.appointment = appointment;
    this.appointment.service_id = 18;

    let flow_type = this.injector.get('flow_type', null);
    let locations = this.injector.get('locations', null);

    if(!this.booking_from_calendar){
      this.injected_practice = this.injector.get('practice', null);
      this.set_flow_type(flow_type);
      this.locations = locations.filter(loc => loc.is_bookable == true);
    }
    else{
      this.book_from_calander(this.doctor_info, time)
      this.appointment_type = flow_type;
      this.booking_flow = flow_type;
      this.set_flow_type(flow_type);
      this.book_flow = true;
    }*/
    this.booking_from_calendar = this.injector.get('booking_from_calendar', null)
    let appointment = this.injector.get('appointment', null);
    let flow_type = this.injector.get('flow_type', null);
    this.set_flow_type(flow_type);
    this.appointment = appointment;
    if (this.booking_from_calendar) {
      this.doctor_info = this.injector.get('selected_doctor', null);
      this.location_id = this.injector.get('selected_location', null);
      this.doctor_id = this.doctor_info['doctor']!.id;
      if (this.doctor_id) {
        this.fetchDoctor();
      }
      if (this.location_id) {
        this.locationService.getLocation(this.location_id).then(resp => {
          this.selected_location = new Location().load_from_json(resp);
          this.fetchAmenities(this.selected_location.id, this.doctor_id);
        })
      }
      this.calendar_info = this.injector.get('calendar_info', null);
      let time = this.injector.get('event', null);
      this.time = time.date;
      let c = this.datePipe.transform(time.date, 'yyyy-MM-dd')
      this.book_from_calander(this.doctor_info, time, this.calendar_info)
    }
    else {
      let locations = this.injector.get('locations', null);
      this.locations = locations.filter(loc => loc.is_bookable === true);
      this.injected_practice = this.injector.get('practice', null);
    }
    if (this._user_service.current_user.isDoctor && !this.booking_from_calendar) {
      this.change_provider(this._user_service.current_user.profileable_id);
    }
  }

  book_from_calander(doctor_info, time, calendar_info) {
    let date = this.datePipe.transform(time.date, 'yyyy-MM-dd HH:mm')
    this.doctor_id = doctor_info.doctor.id;
    //  this.change_provider(doctor_info.doctor.id);
    // this.slot_calendar(calendar_info.id);
    this.appointment.doctor_id = doctor_info.doctor.id;
    this.appointment.slot_size = calendar_info.slot_size;
    this.appointment.date = date;
    this.appointment.calendar_id = calendar_info.id;
    this.fetchAmenities(doctor_info.location.id, this.doctor_id);
    this.slot_calendar(calendar_info.id)
  }

  initialize_slot_sizes() {
    return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120, 180, 240];
  }

  set_flow_type(flow_type) {
    if (flow_type === 'Book') {
      this.appointment_from = 'book';
      this.appointment.appointment_from = 'book';
      this.booking_flow = flow_type;
      this.book_flow = true;
      this.show_navigator = true;
      this.show_future_slots = true;
    }
    if (flow_type === 'Walk In') {
      this.appointment_from = 'walkin';
      this.appointment.appointment_from = 'walkin';
      this.booking_flow = flow_type;
      this.book_flow = false;
      this.show_navigator = false;
      this.show_future_slots = false;
    }

  }

  onChangeSlotSize(selectedValue) {
    this.appointment.slot_size = selectedValue;
  }

  slotSizeConversion(slot_size) {
    if (slot_size > 90) {
      return (slot_size / 60 + ' Hours');
    }
    return (slot_size + ' Mins');
  }

  apply_class(label_class) {
    if (label_class === 'scheduled') {
      this.appointment.slot_size = '';
      this.activeTab = 'bg-greenish';
      this.deActiveTab = 'bg-white'
      this.show_waiting = false;
      this.appointment.appointment_from = 'walkin';
      this.is_disable = true;
    } else {
      let datePipe = new DatePipe('en-US');
      this.appointment.date = this.appointment.setInitalWaitingTime(datePipe);
      // this.appointment.location_id = this.locations.length === 1 ? this.locations[0].id : null
      this.appointment.slot_size = '15';
      this.show_waiting = true;
      this.activeTab = 'bg-white';
      this.deActiveTab = 'bg-greenish';
      this.appointment.appointment_from = 'waiting';
      this.is_disable = false;
    }
  }

  fetchDoctor() {
    this.doctorService.getDoctor(parseInt(this.doctor_id)).then(json => {
      this.doctor = new Doctor().load_from_json(json);
      this._slot_date_service.got2Today();
      this.refresh = !this.refresh;
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  book_appointment(appointment_from) {
    this.loader.show();
    if ((<HTMLInputElement> document.getElementById('walkinsubmitbtn'))) {
      (<HTMLInputElement> document.getElementById('walkinsubmitbtn')).disabled = true;
    }
    if (this.appointment.appointment_from === 'walkin' ||
      this.appointment.appointment_from === 'waiting' ||
      this.appointment.appointment_from === 'book') {
      this.SetDateWaitingListAndAppointmentType(this.appointment.appointment_from);
    }

    this._appointment_service.save(this.appointment).then(appointment => {
      if (this.appointment.appointment_from === 'walkin' || this.appointment.appointment_from === 'waiting') {
        this.appointment = appointment;
        this.is_disable = true;
        this._patient_service.call_for_appointments = !this._patient_service.call_for_appointments;
      } else {
        this._tab_service.close_tab(this._tab_service.active_tab());
        this._patient_service.call_for_appointments = !this._patient_service.call_for_appointments;
      }
      this.loader.hide();
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
      this.loader.hide();
      if ((<HTMLInputElement> document.getElementById('walkinsubmitbtn'))) {
        (<HTMLInputElement> document.getElementById('walkinsubmitbtn')).disabled = false;
      }
    });
  }

  SetDateWaitingListAndAppointmentType(appointment_from) {

    if (appointment_from === 'walkin') {
      this.appointment.appointment_from = appointment_from;
    } else if (appointment_from === 'book') {
      this.appointment.appointment_from = appointment_from;
    }
    else {
      this.appointment.appointment_from = 'waiting';
      let rightNow = new Date();
      let ymd = this.formatDate(rightNow);
      this.appointment.date = ymd + ' ' + this.appointment.date
    }
    return;
  }


  formatDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.patientAuthService.checkAuthorization(e.item, this.authorizationModal);
    this.patientAuthService.hint = e.item.mother_name_hint;
    this.selectPatient(e.item.id);
  }

  selectPatient(id) {
    this.appointment.patient_id = id;
    if (this.appointment.patient_id && this.patientAuthService.verified) {
      this.loader.show();
      this._patient_service.selected_patient(this.appointment.patient_id).then(res => {
        this.selected_patient = this.selected_patient.load_from_json(res);
        this.appointment.patient_id = this.selected_patient.id;
        this.loader.hide();
      });
    }
  }

  getPatients(token): Observable<any> {
    return this._patient_service.getPatientAsObservable(token);
  }

  get_services(term: any) {
    return this.specialtySearchService.search_services(term);
  }

  set_asyncSelected_service(event, service: Service) {
    this.asyncSelectedService = this.appointment.service_name;
  }

  public changeTypeaheadNoResultsService(e: boolean): void {
    this.typeaheadNoResultsService = e;
  }

  public typeaheadOnSelectService(e: any): void {
    this.appointment.service_id = e.item.id;
  }

  public changeTypeaheadLoadingService(e: boolean): void {
    this.typeaheadLoadingService = e;
  }

  handle_user(new_patient) {
    this.selected_patient = new_patient;
    this.asyncSelected = this.selected_patient.user.full_name_with_information(this.selected_patient.age);
    this.appointment.patient_id = this.selected_patient.id;
  }

  load_practice_data(user) {
    if (user && user.id && !this.is_data_available) {
      this.is_data_available = true;
      this.loader.show();
      this._appointment_service.get_todays_patient_filter(user).then(json => {
        if (json.id) {
          this.practice.load_from_json(json);
          this.appointment.created_by_id = user.id;
          if (this.practice.id) {
            this.providers =  this.practice.active_doctors;
          }
          if (this._user_service.current_user && this._user_service.current_user.isDoctor) {
            if (this.provider) {
              this.providers =  [this.provider];
            } else {
              this.providers = [new Doctor().load_from_json(this._user_service.current_user.profileable)];
            }
          }
        }
        this.loader.hide();
        return true;
      });
    }
    if (user && user.id) {
      return true;
    }
    return false;
  }


  change_provider(id) {
    this.selected_slot_calendar = undefined;
    this.location_id = undefined;
    this.amenity_id = undefined;
    this.doctor_locations = undefined;
    if (id === '') {
      this.doctor_id = '';
      this.appointment.doctor_id = parseInt('');
      return;
    }
    this.doctor_id = id;
    this.appointment.doctor_id = id;
    this.appointment.slot_size = '';
    this.fetchDoctor();
    this.getDoctorsActiveLocationsCalendars(id);
    // this.fetchLocations();
  }

  public getDoctorsActiveLocationsCalendars(id) {
    let params = {
      doctor_id: id
    };
    this.locationService.get_doctor_locations_active_calendar(params).then(resp => {
      this.doctor_locations = resp.map(l => new Location().load_from_json(l));

      if (this.doctor_locations.length === 1) {
        this.location_id = this.doctor_locations[0].id;
        this.appointment.location_id = this.location_id;
        this.fetchAmenities(this.location_id, this.doctor_id);
      }
      else {
        this.loader.hide();
      }
    })
  }

  fetchLocations() {
    this.loader.show();
    this.locationService.getLocations(this.practice.id).then(resp => {
      this.doctor_locations = resp.map(l => new Location().load_from_json(l));
      if (this.doctor_locations.length === 1) {
        this.location_id = this.doctor_locations[0].id;
        this.appointment.location_id = this.location_id;
        this.fetchAmenities(this.location_id, this.doctor_id);
      }
      else {
        this.loader.hide();
      }
    })
  }
  change_location(id) {
    this.amenities_empty = false;
    if (this.appointment.amenity_id) {
      this.change_amenity('');
    }
    this.amenity_id = undefined;
    if (id === '') {
      this.location_id = '';
      this.appointment.location_id = parseInt('');
      return;
    }
    this.location_id = id;

    this.appointment.location_id = id;

    this.appointment.slot_size = '';
    this.loader.show();
    this.fetchAmenities(this.location_id, this.doctor_id);
  }

  fetchAmenities(location_id, doctor_id) {
    this.amenityService.fetchAmenities(location_id, doctor_id).then(resp => {
      this.amenities = resp.map(a => new Amenity().load_from_json(a))
      if (this.amenities.length === 0) {
        this.amenities_empty = true;
      }
      this.loader.hide();
    })
  }
  waiting_list_enable_disable(appointment) {
    // if (this.asyncSelectedService.length < 1) {
    //   appointment.service_id = null;
    // }
    return !(this.selected_patient &&
      this.selected_patient.id &&
      appointment.doctor_id &&
      appointment.service_id &&
      appointment.date &&
      appointment.slot_size &&
      appointment.location_id);
  }

  is_disable_btn(appointment) {
    return !(this.selected_patient &&
      this.selected_patient.id &&
      appointment.patient_id &&
      appointment.doctor_id &&
      this.appointment.service_name.length > 1 &&
      this.appointment.date &&
      appointment.slot_size &&
      this.appointment.calendar_id);
  }

  show_gender_age() {
    if (this.selected_patient.age > 0) {
      return '/ ' + this.selected_patient.age + ' Years';
    } else {
      return '';
    }
  }

  handle_slot(slot) {

    this.appointment.date = slot.slot;
    this.appointment.calendar_id = slot.calendar_id;
    if (!this.appointment.slot_size) {
      this.appointment.slot_size = slot.slot_size;
    }
    this.slot_calendar(slot.calendar_id);
  }

  slot_calendar(id) {
    this.locationService.get_slot_calendar(id).then( resp => {
      console.log(resp);
      this.selected_slot_calendar = resp;

    })
  }

  handle_image_upload(data) {
    if (data) {
      this.patient.user.profile_pic = data.profile_pic;
      this.patient.user.profile_pic_id = data.id;
    }
    this.show_attachment_popup = false;
  }

  close_modal(classicModal) {
    classicModal.hide();
    setTimeout(() => {
      this.show_patient_form = false;
      this.patient = new Patient();
      setTimeout(() => {
        this.show_patient_form = true;
      }, 200);
    }, 200)
  }


  verifyOtp() {
    this._patient_service.verifyPatientAuthorizationOtp({
      patient_id: this.patientAuthService.unathorizedPatient.id,
      otp: this.patientAuthService.data.mobile_otp
    }).then(res => {
      this.patientAuthService.mobile_message = res.message;
      this.patientAuthService.message_class = 'success';
      this.patientAuthService.verified = true;
      this.patientAuthService.clear_messages();
      this.selectPatient(this.patientAuthService.unathorizedPatient.id);
    }).catch(res => {
      this.patientAuthService.mobile_message = res.message;
      this.patientAuthService.message_class = 'failed';
      this.patientAuthService.clear_messages();
    })
  }



  verifyMotherName() {
    this._patient_service.verifyPatientMotherName({
      patient_id: this.patientAuthService.unathorizedPatient.id,
      mother_name: this.patientAuthService.data.mother_name
    }).then(res => {
      this.patientAuthService.mobile_message = res.message;
      this.patientAuthService.message_class = 'success';
      this.patientAuthService.verified = true;
      this.selectPatient(this.patientAuthService.unathorizedPatient.id);
      this.patientAuthService.clear_messages();
    }).catch(res => {
      this.patientAuthService.mobile_message = res.error.message;
      this.patientAuthService.message_class = 'failed';
      this.patientAuthService.clear_messages();
    })
  }
  hideModal() {
    this.asyncSelected = null;
    this.patientAuthService.closeModal(this.authorizationModal);
  }

  change_amenity(value: string) {
    if (value === '') {
      this.amenity_id = undefined;
      this.appointment.slot_size = undefined;
      this.appointment.appointment_type = undefined;
      this.appointment.amenity_id = undefined;
      this.amenity_methods = undefined;
    } else {
      this.selected_amenity = this.amenities.filter(a => a.id === parseInt(value))[0];
      this.appointment.amenity_id = this.amenity_id;
      this.appointment.slot_size = this.selected_amenity.duration.toString();
      this.amenity_id = value;
      this.amenity_methods = this.selected_amenity.amenity_methods;
      if (this.amenity_methods.length > 0) {
        this.appointment.appointment_type = this.amenity_methods[0].method_type;
      }
    }

  }

  get fee(): string {
    return this.amenity_methods.filter(a => a.method_type === this.appointment.appointment_type)[0]!.fee
  }

  set_appointment_type_and_fee(type: any, visit_fee: any) {
    this.appointment.appointment_type = type;
  }
}
