import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VitalsService} from '../../../../services/vitals.service';

@Component({
  selector: 'app-vitals-flowsheet-view',
  templateUrl: './vitals-flowsheet-view.component.html',
  styleUrls: ['./vitals-flowsheet-view.component.scss']
})
export class VitalsFlowsheetViewComponent implements OnInit {
  @Input() vital_data;

  constructor(public vitalService: VitalsService) { }

  ngOnInit(): void {
  }

  convertHeightInFeetAndInch(height) {
    if (height) {
      var heightInFeet = Math.floor(parseInt(height) / 12);
      var heightInInch = parseInt(((parseInt(height) / 12 - heightInFeet) * 12).toFixed(2));
      return `${heightInFeet}' ${heightInInch}''`
    }
    else {
      return 'N/A'
    }
  }
  showValueOfVital(short, value) {
    switch (short.toLowerCase()) {
      case  'wt':
        return this.vitalService.kgTolb(value);
      case 'useage':
        return this.vitalService.setHrAndMin(value);
      default:
        return value;
    }
  }
}
