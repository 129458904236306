import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PatientNotes} from '../patient-notes';
import {LoaderService} from '../../../../../../services/loader.service';
import {PatientNotesService} from '../patient-notes.service';
import {NewPatientNotesComponent} from '../new-patient-notes/new-patient-notes.component';
import {Tab} from '../../../../../../tabs/tab';
import {TabsService} from '../../../../../../services/tabs.service';
import {Subscription} from 'rxjs/index';

@Component({
  selector: 'app-patient-notes-filter',
  templateUrl: './patient-notes-filter.component.html',
  styleUrls: ['./patient-notes-filter.component.scss']
})
export class PatientNotesFilterComponent implements OnInit, OnDestroy {
  public filter: Object = {keyword: null, page: 1, patient_note_type_id: null, patient_id: null};
  public patient_notes_list_array: PatientNotes[] = [];
  public totalItems;
  public patient_note_types;
  @Input() patient_id;
  private subscription: Subscription;
  private closeNewFormSubscription: Subscription;
  @ViewChild('newNoteModel') newNoteModel;
  public custom_injector: Injector;
  public show_node_model = false;
  newNoteComponent = NewPatientNotesComponent;
  constructor(public tabs_service: TabsService, public patientNotesService: PatientNotesService,
              public loaderService: LoaderService, public injector: Injector) {
    this.subscription = this.patientNotesService.reloadNotes.subscribe( resp => {
      this.getPatientNotes();
    });
    this.closeNewFormSubscription = this.patientNotesService.closeNewNoteForm.subscribe( resp => {
      if (resp) {
        this.newNoteModel.hide();
      }
    })
  }

  ngOnInit(): void {
    this.getPatientNotes();
    this.patientNotesService.getPatientNoteType().then(resp => {
      this.patient_note_types = resp;
    });
  }
  search() {
      this.filter['page'] = 1;
      this.getPatientNotes();
  }
  getPatientNotes() {
    this.loaderService.show();
    this.filter['patient_id'] = this.patient_id;
    this.patientNotesService.getPatientNotes(this.filter).then(resp => {
      this.patient_notes_list_array = resp.patient_notes.map(patient_notes => new PatientNotes().load_from_json(patient_notes));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
    });
  }
  addParientNote() {
    let patient_note = new PatientNotes();
    this.tabs_service.add_tab(new Tab({title: 'Add Note', icon: 'fa'}, NewPatientNotesComponent, patient_note.id,
      {id: patient_note.id, patient_note: patient_note, patient_id : this.patient_id}))
  }

  addNewNoteInPopup() {
    let patient_note = new PatientNotes();
    this.setCustomInjector(patient_note);
  }

  setCustomInjector(note: PatientNotes) {
    this.custom_injector  = Injector.create([
      {provide: 'patient_note', useValue: note},
      {provide: 'id', useValue:  note.id},
      {provide: 'patient_id', useValue:  this.patient_id}
    ], this.injector);
    this.show_node_model = true;
    this.newNoteModel.show();
  }

  edit_patient_note(patient_note) {
    this.setCustomInjector(patient_note);
  }

  pageChanged(page) {
    this.filter['page'] = page.page;
    this.getPatientNotes();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.closeNewFormSubscription.unsubscribe();
  }

}
