import { Component, OnInit,Output,EventEmitter,Injector } from '@angular/core';
import { PracticesService } from '../../../services/practices.service';
import  {Practice} from '../practices/practice'
import { Params,ActivatedRoute}   from '@angular/router';
import {LoaderService} from '../../../services/loader.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
  public practice: Practice = new Practice();
  public width:string = '14.3%';
  public  step: number = 1;
  private widthMaping = {1: '14.3%',2: '28.6%',3: '42.9%',4: '56.12%',5: '70.15%',6: '84.18%',7:'100%'};
  practice_permissions: string;
  doctor_permissions: string;
  @Output()  current_step: EventEmitter<number> = new EventEmitter<number>();
  constructor(private practiceService: PracticesService,
              private route: ActivatedRoute,
              private injector: Injector,
              private loaderService: LoaderService) {

  }

  ngOnInit() {
    let id = this.injector.get('id',null);
    this.practice_permissions = this.injector.get('practice_permissions',null);
    this.doctor_permissions = this.injector.get('doctor_permissions',null);
    if(id){
      this.fetchPractice(id);
    }else {
      this.practice.force_load();
      this.practice.edit_practice = true;
    }
  }
  getpracticeFromRoute(): void {
    this.route.params
      .subscribe((params: Params) =>  {this.fetchPractice(+params['id'])})
  }
  fetchPractice(id: number){
    this.loaderService.show()
    this.practiceService.getPractice(id).then(practice => {
      this.practice.load_from_json(practice)
      this.loaderService.hide();
    }).catch(error => this.loaderService.hide());
  }
  setStep(step){
    this.step = step;
    this.width = this.widthMaping[step];
  }
  syn_practice(practice){
    this.practice = practice;
  }

}
