import { Injectable }     from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';


@Injectable()
export class InvitationsService {

  constructor(private _tokenService: CustomHttpClient) { }
  create(invitation): Promise<any> {
    return this._tokenService
      .post('doctor_invitation', invitation)
      .toPromise()
  }

}
