<ng-container *ngIf="admission">
  <div class="col-sm-3 picName-area">
    <div class="cell-holder">
      <div class="pic img-holder text-center">
        <img class="img-circle thumb64" [src]="admission.appointment.patient.profile_pic" alt="Image"/>
      </div>
      <h4 class="m0 mb fw-500 fs16"><a (click)="add_to_tabs_patient_profile(admission.appointment)">{{admission.appointment.patient.name}}</a></h4>
      <h6 class="m0 mb text-capitalize">
        <i class="mr-sm" [ngClass]="(admission.appointment.patient.user.gender == 'female')?'fa icon-user-female':'fa icon-user'"></i>
        {{admission.appointment.patient.show_age_and_gender()}}
        &nbsp;
        {{admission.appointment.patient.customize_dob}}
      </h6>
      <h6 class="m0" *ngIf="admission.appointment.patient.mobile_number_value">
        <i class="fa icon-phone mr-sm"></i> {{admission.appointment.patient.mobile_number_value | mask: ' (000) 000-0000'}}
      </h6>
    </div>
  </div>
  <div class="col-sm-4 border-col">
    <div class="cell-holder pl-sm pr-sm">
      <div class="box-placeholder" permittedClass [condition]="'edit,Admissions,reception'">
        <i class="fa fa-pencil text-primary  pull-right" (click)="editAdmission()" ></i>
        <div class="row mb-sm">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Reason of Admission</label></div>
          <div class="col-sm-8 col-xs-12">
            <span class="text-primary fw-500">{{admission.service.name}}</span>
          </div>
        </div>
        <div class="row mb-sm">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Bed / Ward</label></div>
          <div class="col-sm-8 col-xs-12">{{admission.bed_number}} / {{admission.ward}}</div>
        </div>
        <div class="row mb-sm">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Department</label></div>
          <div class="col-sm-8 col-xs-12">{{admission.department_name}}</div>
        </div>
        <div class="row mb-sm" *ngIf="admission.doctor">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Doctor</label></div>
          <div class="col-sm-8 col-xs-12">{{admission.doctor_name}}</div>
        </div>
        <div class="row mb-sm" *ngIf="admission.staff">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Staff</label></div>
          <div class="col-sm-8 col-xs-12">{{admission.staff.name}}</div>
        </div>
        <div class="row" *ngIf="admission.comment">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Comment</label></div>
          <div class="col-sm-8 col-xs-12">{{admission.comment}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-3 border-col">
    <div class="cell-holder pl-sm pr-sm">
      <h6 class="m0">Admitted On</h6>
      <h6 class="mt-sm">{{admission.admitted_date_time}}</h6>
      <div class="waiting-counter">
        <div class="minutes-cont"><span class="counter">{{ admission.waiting_time() }}</span> Days</div>
        <round-progress [color]="wait_class(admission.waiting_time())" [background]="'#eaeaea'" [stroke]="4" [current]="admission.waiting_time()" [max]="30" [radius]="30"></round-progress>
      </div>
    </div>
  </div>
  <div class="col-sm-3 btn-area" *ngIf="view == 'listing'">
    <div class="cell-holder" *userCan="'edit,Admissions,reception'">
      <ul class="tabs-action-btns m0 p0">
        <li>
          <button class="btn btn-secondary btn-outline" [disabled]="_userService.current_user?.isEnterprise" (click)="add_to_tabs()">Note</button>
        </li>
        <li>
          <button type="button" [disabled]="_userService.current_user?.isEnterprise" id="scan-{{admission.id}}" class="btn btn-secondary btn-outline" (click)="add_upload_to_tabs('Upload')">Upload <span class="label label-danger" *ngIf="scan_service.get_data(admission.id) > 0">{{scan_service.get_data(admission.id)}}</span></button>
        </li>
        <li>
          <button class="btn btn-secondary btn-outline" [disabled]="_userService.current_user?.isEnterprise" (click)="add_payment_to_tabs()">Payment</button>
        </li>
        <ng-container *ngIf="scan_service.get_data(admission.id) > 0">
        <li data-toggle="tooltip" data-placement="bottom" title="You cannot Discharge this patient until all uploaded pages are filed.">
          <button class="btn btn-secondary btn-outline disabled" [disabled]="true" (click)="add_discharge_to_tabs()">Discharge</button>
        </li>
        </ng-container>
        <ng-container *ngIf="scan_service.get_data(admission.id) <= 0">
          <li>
            <button class="btn btn-secondary btn-outline" [disabled]="_userService.current_user?.isEnterprise && true" (click)="add_discharge_to_tabs()">Discharge</button>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</ng-container>
