import {Address, ContactNumber} from '../practices/practice'
import {User} from '../../users/user';
import {BaseModel} from '../../../shared/base';
export class Staff extends BaseModel {
  id: number;
  practice_id: string;
  cell: string;
  first_name: string;
  last_name: string;
  prefix: string;
  sufix: string;
  email: string;
  practice_name: string;
  designation: string;
  address: Address = new Address();
  contact_numbers: ContactNumber[] = [new ContactNumber('', 'phone'), new ContactNumber('', 'fax')];
  user: User = new User();
  to_params() {
    return {
      staff: {
        cell: this.cell,
        practice_id: this.practice_id,
        first_name: this.first_name,
        last_name: this.last_name,
        prefix: this.prefix,
        sufix: this.sufix,
        email: this.email,
        designation: this.designation,
        address_attributes: this.address.to_params(),
        contact_numbers_attributes: this.contact_numbers
      }
    }
  }

  load_from_jason(json) {
    if (json.practice) {
    this.practice_name = json.practice.name;
    }
    this.cell = json.cell;
    this.id = json.id;
    this.practice_id = json.practice_id;
    this.first_name = json.first_name;
    this.last_name = json.last_name;
    this.prefix = json.prefix;
    this.sufix = json.sufix;
    this.designation = json.designation;
    this.email = json.email;
    if (json.user && json.user.id) {
      this.user = this.user.load_from_json(json.user);
    }
    if (json.address) {
    this.address.load_from_json(json.address);
    }
    if (json.contact_numbers) {
      let contact_numbers = json.contact_numbers.map(c_number => new ContactNumber('', '').load_from_json(c_number));
      this.contact_numbers = contact_numbers;
    }
    return this;
  }

  get phone(): any {
    return this.contact_numbers.filter(c => c.type == 'phone')[0]
  }

  get name(): string {
    return `${this.first_name} ${this.last_name}`
  }

  set phone(value: any) {
    this.phone.value = value;
  }

  get fax(): any {
    return this.contact_numbers.filter(c => c.type == 'fax')[0]
  }

  set fax(value: any) {
    this.fax.value = value;
  }

  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }

  is_persisted() {
    return !this.is_new_record();
  }

  get_url(): string {
    return this.is_new_record() ? 'staffs/' : ['staffs', this.id].join('/')
  }
}
