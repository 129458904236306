import { Component, OnInit , Injector, OnChanges, ViewChild} from '@angular/core';
import {User} from '../user'
import { NgForm } from '@angular/forms';
import { CustomValidations } from '../../customValidations/custom-validations';
import { Observable } from 'rxjs/Observable';
import {UserManagementService} from '../../../services/user-management.service'
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import { mergeMap } from 'rxjs/operators';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public user: User = new User();
  public user_type = '';
  public patient = false;
  public doctor = false;
  public group = false;
  public enterprise = false;
  public practice = false;
  public customValidations: CustomValidations;
  public componentMapping = { 'patient': 'Patient', 'doctor': 'Doctor', 'practice': 'Practice', 'group': 'Group', 'enterprise': 'Enterprise'};
  public column_mapping = { 'Patient': 'first_name', 'Doctor': 'first_name', 'Practice': 'name', 'Group': 'name', 'Enterprise': 'first_name'};
  public data_source: Observable<any>;
  public token = '';
  public profileable_id = 0;
  public profileable_type = '';
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public alert_mesage_activation = false;
  public alert_message_active = '';
  public column_name = '';
  public show_user_form = false;
  messageAfterSave = false;
  public alert_mesage = 'User has been created successfully';
  public error_message = false;
  public show_complete_form = true;
  public object_id: number;
  public user_age_gender: string;
  public message_class = '';
  public label = 'Create User';
  public show_save_button = true;

  @ViewChild('UserForm') UserForm: NgForm;
  public is_profile_setting_component: boolean;

  constructor(private injector: Injector, public loaderService: LoaderService,
              private _userManagementService: UserManagementService,
              private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.customValidations = new CustomValidations();
    this.object_id = this.injector.get('id', null);
    this.user_type = this.injector.get('profile_type', null);
    this.profileable_type = this.injector.get('profile_type', null);
    this.is_profile_setting_component = this.injector.get('is_profile_setting_component', false);

    if (this.profileable_type == 'practice') {
    this.show_complete_form = false;
    }

    let profileable_id = this.injector.get('profileable_id', null);
    if (this.object_id) {
    this.getDynamicUserLoaded(this.object_id);
    }

    this.setPlaceHolder();

    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).pipe(mergeMap((token: string) => this._userManagementService.search_by_role_type(token, this.profileable_type)));

  }

  ngOnInit() {
  }

  setPlaceHolder() {
    this.profileable_type = this.componentMapping[this.user_type];
    this.column_name = this.column_mapping[this.profileable_type];
  }

  getDynamicUserLoaded(user_id) {
    this.loaderService.show()
    this._userManagementService.get_user_loaded(this.user, user_id).then(res => {
      this.user = res;
      this.show_user_form = true;
      if (this.user.age) {
      this.set_user_age();
      }
      this.label = 'Update User';
      this.loaderService.hide();
    }).catch((error) => {
      this.loaderService.hide();
    })
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.profileable_id = e.item ? this.set_profileable_id(e) : e;

    this._userManagementService.get_user_inital_data(this.user, this.profileable_type, this.profileable_id).then(res => {
      this.user = res;
      this.show_user_form = true;
      this.setDefaultRole(this.user);

    })
  }

  setDefaultRole(user) {
      user.roles_users[0].role_id = user.roles[0].id;
      return;
  }

  set_user_age() {
    this.user_age_gender = this.user.age + ' Y / ' + this.user.gender;
  }

  setPracticeId() {
    this.profileable_id = 0;
  }

  set_profileable_id(e) {
    let profileable_id = this.profileable_type == 'Patient' ?  e.item.profileable_id : e.item.id;
    return profileable_id;
  }

  save() {
    this.loaderService.show();
    if (this.object_id) {
      this.user.id = this.object_id;
    } else {
      this.show_save_button = false;
    }

    this._userManagementService.save(this.user).then(res => {
      this.alert_mesage = res.success;
      this.message_class = res.textclass;
      this.setTimeOutFailsMessage();
      this.loaderService.hide();
      return res;
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
      this.show_save_button = true;
    });
  }

  setTimeOutFailsMessage(): any {
    this.error_message = true;
    setTimeout(function() {
      this.error_message = false;
    }.bind(this), 3000);
  }

  area_select(area_data) {
    this.user.address.city_area.lat = area_data.lat;
    this.user.address.city_area.long = area_data.long;
    this.user.address.city_area.name = area_data.name;
  }

  selectStaff(value) {
    this.user.profileable_id = parseInt(value);
    this.user.profileable_type = 'Staff';

    this._userManagementService.get_user_inital_data(this.user, this.user.profileable_type, this.user.profileable_id).then(res => {
      this.user = res;
      this.show_complete_form = !this.show_complete_form;
      this.show_user_form = true;
    })

  }

  active_user() {
    this._userManagementService.activate_user(this.user.id, this.user).then(res => {
      this.alert_message_active = 'User has been activated successfully';
      this.setTimeOutSuccessMessagee();
      this.show_user_form = false;
    })
  }

  setTimeOutSuccessMessagee(): any {
    this.alert_mesage_activation = true;
    setTimeout(function() {
      this.alert_mesage_activation = false;
    }.bind(this), 3000);
  }

  setTimeOutSuccessMessage(): any {
    this.messageAfterSave = true;
    setTimeout(function() {
      this.messageAfterSave = false;
    }.bind(this), 3000);
  }
  ngAfterViewChecked() {
    this.customValidations.formChanged(this.UserForm);
  }

}
