<ul class="list-unstyled border-list m0 alerts-list">
  <ng-container *ngFor="let alert of enrolment_alerts">
    <li class="list" [ngClass]="alert.status === 'unread' ? 'active' : ''" >
      <div class="media">
        <div class="media-left media-middle">
          <a  placement="bottom" ngbTooltip="Delete" class='btn btn-danger btn-outline' (click)="deleteSingleAlert(alert)"><i class="fa icon-trash"></i></a>
        </div>
        <div class="media-body media-middle">
          <h6 class="m0 fw-400" *ngIf="alert.alert_type === 'intervention'; else secondHeading">
            <a (click)="openResolveModel(alert)" [style.color]="alert.color_code"
               ngbTooltip="{{alert?.guidelines}}" placement="bottom">
              {{alert.noti_text}}
            </a>
          </h6>
          <ng-template #secondHeading>
            <h6 class="m0 fw-400 text-gray-dark" *ngIf="alert.alert_type !== 'task_overdue'">{{alert.noti_text}}</h6>
          </ng-template>
          <a (click)="openTaskInNewTab(alert)" *ngIf="alert.alert_type == 'task_overdue'"><h5 class="m0">{{alert.noti_text}}</h5></a>
          <small class="text-gray-dark">{{alert.created_at | date: 'short'}}</small>
        </div>
        <ng-container *ngIf="!(alert.alert_type == 'video_call' || alert.alert_type === 'phone_call')">
          <div class="media-right media-middle" *ngIf="alert.status !== 'intervened'"
               ngbTooltip="{{alert.alert_type === 'intervention' ? 'Alert' : userService.removeUnderScore(alert.alert_type)}}"
               placement="bottom-right"
               [style.color]="alert.color_code"><i [ngClass]="alert.icon_class"></i>
          </div>
          <div class="media-right media-middle text-right" style="min-width:115px;" *ngIf="alert.status === 'intervened'">
            <span class="center-block" ngbTooltip='{{interventions_name(alert.interventions)}}' placement="bottom-right">Resolved</span>
            <small class="text-gray-dark">{{alert.updated_at | date: 'short'}}</small>
          </div>
        </ng-container>
        <ng-container *ngIf="userService.user.twilio_configurations.video">
          <div class="media-right media-middle" *ngIf="alert.alert_type == 'video_call'" ngbTooltip="{{userService.removeUnderScore(alert.alert_type)}}"
               placement="bottom-right"  [ngClass]="videoChatService.inCall ? 'alert-disabled' : ''">
            <a (click)="openVideoInNewTab(alert)"><i [ngClass]="alert.icon_class"></i></a>
          </div>
        </ng-container>
        <ng-container *ngIf="userService.user.twilio_configurations.voice">
          <div class="media-right media-middle" *ngIf="alert.alert_type === 'phone_call'" ngbTooltip="{{userService.removeUnderScore(alert.alert_type)}}"
               placement="bottom-right">
            <a (click)="phoneService.initiatePhoneCall(patient?.mobile_number_value,
          {name: patient.name, pic: patient?.profile_pic} ,programEnrollment.id);
          updateAlertStatusAsRead(alert.id)"><i [ngClass]="alert.icon_class"></i></a>
          </div>
        </ng-container>
      </div>
    </li>
  </ng-container>
  <div class="text-center" *ngIf="totalAlertCount > alertLimit">
    <ng-container *ngIf="!loadingAlert">
      <button type="button" class="btn btn-default btn-sm more-btn"
              (click)="loadMoreAlerts()">
        <i class="fa fa-angle-double-down"></i> &nbsp;Load More
      </button>
    </ng-container>
    <span class="spin-icon" *ngIf="loadingAlert"><i class="fa fa-spinner fa-spin"></i></span>
  </div>
  <app-no-result-found *ngIf="enrolment_alerts && enrolment_alerts.length == 0"></app-no-result-found>
</ul>

<div class="modal fade" bsModal #resolveAlertModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:300px;" >
    <div class="modal-content" *ngIf="selected_alert" >
      <div class="modal-header">
        <button class="close" type="button" (click)="closeResolvedModel()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Alert</h4>
      </div>
      <div class="modal-body">
        <label class="field-label">{{selected_alert.noti_text}}</label>
        <app-alert-template-intervention [alert]="selected_alert"
                                         [alert_template_interventions]="interventions"
                                         [isResolveModel]="true"></app-alert-template-intervention>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="resolveAlert();" [disabled]="selected_alert.status === 'intervened'">Resolved</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="closeResolvedModel()">Cancel</button>
      </div>
    </div>
  </div>
</div>



