<div class="flex-header filters-sec">
  <div class="row">
    <div class="col-sm-2">
      <select #duration class="form-control" (change)='selectDuration(duration)'>
        <option selected value="">
          Time range
        </option>
        <option *ngFor="let duration of durations; let i = index;" [value]="duration">{{userService.removeUnderScore(duration)}}</option>
      </select>
    </div>
    <!--    <div class="col-sm-2">
          <select #type class="form-control" [(ngModel)]="this.filter['type']">
            <option selected value="">
              Type
            </option>
            <option *ngFor="let documentType of documentsType; let i = index;" [value]="documentType.id">
              {{documentType.name}}
            </option>
          </select>
        </div>
        <div class="col-sm-2">
          <select #problem class="form-control" [(ngModel)]="this.filter['problem']">
            <option selected value="">
              Problem
            </option>
            <option *ngFor="let service of services; let i = index;" [value]="service.id">
              {{service.name}}
            </option>
          </select>
        </div>-->
    <div class="col-sm-2">
      <select #status class="form-control" [(ngModel)]="this.filter['status']">
        <option selected value="all">Status</option>
        <option class="text-capitalize" *ngFor="let status of enoteStatus; let i = index;" [value]="status">
          {{status}}
        </option>
      </select>
    </div>
    <div class="col-sm-3">
      <button class="btn btn-tertiary btn-outline mr-sm" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      <button class="btn btn-secondary" (click)="createEnote()"><i class="fa fa-upload"></i> Create</button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser hor-scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Reason</th>
      <th>Doctor</th>
      <th>Created by</th>
      <th>Created On</th>
      <th>Status</th>
      <th style="width:150px;">Action</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let enote of patientEnotes">
      <tr>
        <td><a (click)="openEnote(enote.id)">{{enote.name}}</a></td>
        <td>{{userService.removeUnderScore(enote.is_rich_text ? enote.text_type : enote.temp_type)}}</td>
        <td>{{enote.reason_of_visit}}</td>
        <td>{{enote?.doctor_name}}</td>
        <td>{{enote.is_rich_text ? enote.modifier_name : enote.signed_by_name}}</td>
        <td>
          {{enote.created_at | date:'MMMM d, y, h:mm a' }}
        </td>
        <td>{{userService.removeUnderScore(enote.is_rich_text ? enote.status : enote.stage)}}</td>
        <td>
          <span *ngIf="!showDeleteButton(enote)">{{enote.signature}}</span>
          <span *ngIf="showDeleteButton(enote)">
            <a class="btn btn-danger btn-outline btn-sm"  tooltip="Delete" (click)="selectedEnoteToDelete = enote;deleteModal.show();">
              <i class="fa fa-trash"></i>
            </a>
          </span>

        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="patientEnotes && patientEnotes.length == 0" ><app-no-result-found></app-no-result-found></ng-container>
</div>
<div class="text-center flex-footer" *ngIf="patientEnotes && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete template</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p-sm m0 text-center">
          Are you sure you want to delete this template?
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="delete_draft(); this.deleteModal.hide()">Yes</button>
        <button type="button" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
