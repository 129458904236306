<div class="flex-control flex-column" *ngIf="userService.current_user.isEnterprise && !showPracticeDashboard">
  <div class="flex-header filters-sec">
    <div class="row">
      <div class="col-xs-4">
        <input class="form-control" [(ngModel)]="this.text" placeholder="Search Practice">
      </div>
      <div class="col-sm-8">
        <button type="button" class="btn btn-tertiary btn-outline" (click)="searchAllPracticeForReviews()">Filter</button>
        <button type="button" class="btn btn-tertiary btn-outline" *ngIf="text" (click)="clearPracticeFilter()">Clear</button>
      </div>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
          <th>Specialty</th>
          <th>Address</th>
          <th>Contact</th>
          <th class="text-center">No. of Doctors</th>
          <th class="text-center">No. of Locations</th>
          <th class="text-center">Reviews</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let practice of practices">
          <tr>
            <td class="pointer"><a href="javascript:void(0)" (click)="getReviewsOfPractice(practice)">{{practice.name}}</a></td>
            <td [title]="practice.specialties[0]?.description" >{{practice.specialties[0]?.description}}</td>
            <td [title]="practice.practice_humanize_address">{{practice.practice_humanize_address}}</td>
            <td><div>{{practice.requester_firstname}} {{practice.request_lastname}}</div>{{practice.contact_numbers[0]?.value}}</td>
            <td class="text-center">{{practice.practice_total_doctors}}</td>
            <td class="text-center">{{practice.no_of_practice_locations}}</td>
            <td class="text-center">{{practice.averageExactRating}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div class="text-center" *ngIf="practices && practices.length > 30">
      <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
    </div>
  </div>
</div>

<div class="flex-control flex-column" *ngIf="userService.current_user.isPractice || showPracticeDashboard">
  <div class="filters-sec flex-header">
    <div class="row">
      <div class="col-md-2 col-sm-6 review-title-col" *ngIf="practice_review_setup.review_type == 'both'">
        <h4 class="reviews-title" (click)="getReviewsOf()">
          <span *ngIf="filter['reviewable_type'] == 'Doctor'; else elseBlock">
            <svg class="mr-sm" width="24px" height="24px" viewBox="0 0 512 512">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M1050 5104 c-67 -29 -94 -67 -114 -156 l-6 -28 -213 0 c-185 0 -217 -3 -253 -19 -56 -26 -92 -75 -105 -147 -17 -91 -6 -1022 14 -1139 54 -320 186 -582 411 -813 196 -202 425 -335 705 -407 52 -14 108 -25 123 -25 l28 0 0 -512 c0 -551 8 -710 40 -846 124 -528 562 -925 1100 -997 168 -23 315 -16 475 22 468 110 848 486 964 952 35 139 41 236 41 651 l0 407 43 12 c56 17 151 71 209 119 288 241 334 668 102 958 -89 111 -195 186 -329 231 -70 24 -95 27 -210 27 -114 0 -140 -4 -210 -27 -108 -36 -195 -89 -275 -167 -262 -258 -276 -667 -32 -950 56 -65 155 -135 250 -176 l77 -33 3 -353 c4 -501 -8 -599 -98 -784 -106 -218 -271 -371 -502 -465 -104 -43 -207 -61 -343 -61 -250 1 -475 94 -648 269 -118 120 -193 245 -244 410 l-27 88 -4 607 -3 607 48 6 c71 10 229 51 306 80 454 170 805 561 922 1025 43 173 46 225 43 796 l-3 541 -29 37 c-52 69 -81 76 -333 76 l-223 0 0 28 c-1 48 -36 109 -81 139 -35 23 -53 28 -104 28 -71 0 -115 -24 -156 -83 -24 -35 -24 -40 -27 -285 -3 -238 -2 -251 19 -293 28 -59 84 -96 153 -102 99 -8 178 56 195 158 l6 35 108 3 108 3 -4 -438 c-3 -413 -5 -443 -26 -523 -71 -275 -211 -486 -431 -649 -136 -101 -311 -176 -473 -203 -92 -15 -282 -15 -374 0 -415 68 -772 388 -889 797 -39 136 -44 201 -44 613 l0 402 100 0 c95 0 100 -1 104 -22 3 -13 8 -36 11 -53 9 -42 85 -112 130 -121 87 -16 165 18 207 91 22 38 23 51 26 266 2 143 -1 242 -8 268 -29 108 -150 168 -250 125z m3155 -2109 c199 -91 246 -348 92 -507 -145 -149 -401 -112 -502 73 -37 69 -46 172 -20 248 58 177 259 264 430 186z"/>
              </g>
            </svg>
            Doctor
          </span>
          <ng-template #elseBlock>
            <svg class="mr-sm" width="26px" height="26px" viewBox="0 0 512 512">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M2321 5110 c-467 -45 -923 -224 -1310 -513 -124 -92 -396 -364 -488 -488 -147 -196 -288 -455 -368 -673 -48 -131 -108 -371 -131 -521 -25 -168 -25 -542 0 -710 23 -150 83 -390 131 -521 80 -218 221 -477 368 -673 92 -124 364 -396 488 -488 196 -147 455 -288 673 -368 131 -48 371 -108 521 -131 168 -25 542 -25 710 0 150 23 390 83 521 131 218 80 477 221 673 368 124 92 396 364 488 488 147 196 288 455 368 673 48 131 108 371 131 521 25 168 25 542 0 710 -23 150 -83 390 -131 521 -80 218 -221 477 -368 673 -92 124 -364 396 -488 488 -196 147 -455 288 -673 368 -131 48 -372 109 -516 130 -135 19 -464 28 -599 15z m529 -230 c397 -58 703 -174 1015 -383 504 -337 869 -882 983 -1467 39 -199 46 -265 46 -470 0 -205 -7 -271 -46 -470 -114 -585 -479 -1130 -983 -1467 -314 -210 -606 -320 -1020 -384 -104 -17 -466 -17 -570 0 -414 64 -706 174 -1020 384 -504 337 -869 882 -983 1467 -39 199 -46 265 -46 470 0 205 7 271 46 470 98 504 390 991 791 1323 385 318 824 496 1337 541 77 7 371 -2 450 -14z"/>
                <path d="M2042 3971 c-17 -18 -32 -45 -35 -68 -4 -21 -7 -206 -7 -410 l0 -373 -372 0 c-205 0 -390 -3 -411 -7 -23 -3 -50 -18 -68 -35 l-29 -30 0 -488 0 -488 29 -30 c18 -17 45 -32 68 -35 21 -4 206 -7 411 -7 l372 0 0 -372 c0 -205 3 -390 7 -411 3 -23 18 -50 35 -68 l30 -29 488 0 488 0 30 29 c17 18 32 45 35 68 4 21 7 206 7 411 l0 372 373 0 c204 0 389 3 410 7 23 3 50 18 68 35 l29 30 0 476 c0 513 0 506 -53 546 -28 20 -40 21 -428 24 l-399 3 0 372 c0 205 -3 389 -7 410 -3 23 -18 50 -35 68 l-30 29 -488 0 -488 0 -30 -29z m858 -587 c0 -331 2 -401 15 -428 30 -62 20 -61 468 -64 l407 -3 0 -329 0 -330 -397 0 c-359 0 -401 -2 -433 -18 -69 -34 -70 -40 -70 -479 l0 -393 -330 0 -330 0 0 393 c0 353 -2 395 -18 427 -34 69 -40 70 -479 70 l-393 0 0 330 0 330 393 0 c353 0 395 2 427 18 69 34 70 40 70 480 l0 392 335 0 335 0 0 -396z"/>
              </g>
            </svg>
            Practice
          </ng-template>
          <span>Reviews</span>
          <i class="fa fa-chevron-down pull-right"></i>
        </h4>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6" *ngIf=" filter['reviewable_type'] == ('Doctor') && providers">
        <select #provider class="form-control" (change)='selectDoctorFilter(provider)'>
          <option value="" selected>Doctors</option>
          <option *ngFor="let provider of providers; let i = index;" [selected]="provider.id == filter.doctor" [value]="provider.id">{{provider.name}}</option>
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6" *ngIf=" filter['reviewable_type'] == ('Practice') && practiceLocations">
        <select #location class="form-control" (change)='selectLocationFilter(location)'>
          <option value="" selected>Locations</option>
          <option *ngFor="let location of practiceLocations; let i = index;" [selected]="location.id == filter.location" [value]="location.id">{{location.name}}</option>
        </select>
      </div>
      <div class="col-md-1 col-sm-6 col-xs-6 time-date-col">
        <select #time class="form-control" (change)='selectDuration(time)'>
          <option selected value="">Time period</option>
          <option *ngFor="let time of durations; let i = index;" [selected]="time == filter.time_type" [value]="time">{{time}}</option>
        </select>
      </div>
      <div class="col-md-1 col-sm-6 col-xs-6 time-date-col" *ngIf="isOpen">
        <input type="text"
               [bsValue]="dateRangeValue"
               placeholder="Datepicker"
               class="form-control" [outsideClick]="true"
               (bsValueChange)="setDateRange($event)"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false}"
               #dp="bsDaterangepicker"
               bsDaterangepicker/>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6">
        <input type="text" [(ngModel)]="filter['keyword']" class="form-control" (keyup.enter)="filterReviews()" placeholder="Search keyword..." />
      </div>
      <div class="col-xs-3">
        <button class="btn btn-tertiary btn-outline" (click)="filterReviews(); showSaveButton = true"><i class="fa fa-filter fa-fw"></i> Filter</button>
        <button *ngIf="showSaveButton && userService.current_user.isPractice" class="btn btn-primary ml-sm" (click)="saveFilters();showSaveButton = false">Save</button>
        <button *ngIf="userService.current_user.isPractice" class="btn btn-secondary ml-sm" (click)="add_to_tabs()">Report</button>
      </div>
    </div>
  </div>
  <div class="flex-control flex-body flex-row review-dashboard">
    <div class="flex-left flex-control">
      <div class="bg-gray-dark border-box shadow p-sm mb-lg text-center" *ngIf="review_response_stats">
        <h5 class="m0 mb fw-400">Average Rating</h5>
        <h3 class="text-white fw-500 m0 fw-400">{{review_response_stats['average_rating']}}</h3>
      </div>
      <ng-container *ngIf="review_response_stats">
        <div class="border-box shadow p-sm mb-lg">
          <h5 class="text-black text-center m0 mb-lg fw-400">Response Rate</h5>
          <h4 class="text-secondary text-center m0 mb-lg text-right">{{review_response_stats['response_rate']}}%</h4>
          <div class="row text-center">
            <div class="col-xs-6">
              <h6 class="m0 mb-sm fw-400">Requests</h6>
              <h4 class="m0 fw-300 fs16">{{review_response_stats['request_count']}}</h4>
            </div>
            <div class="col-xs-6">
              <h6 class="m0 mb-sm fw-400">Responses</h6>
              <h4 class="m0 fw-300 fs16">{{review_response_stats['response_count']}}</h4>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="flex-body scroll-browser" *ngIf="reviews && review_response_stats">
        <div class="bg-success-dark flex-box" *ngIf="fiveStarWidth > 0" [style.width.%]="fiveStarWidth">
          <h5 class="text-white m0 fw-400">{{(fiveStarReviewsCount/review_response_stats['response_count']) | percent}}</h5>
          <div class="star-holder">
            <h6 class="star-text">5</h6>
            <i class="fa icon-star"></i>
          </div>
          <h6 class="text-white m0">{{fiveStarReviewsCount}} <span class="hidden-xs">Response</span></h6>
        </div>
        <div class="bg-success flex-box mt-sm" *ngIf="fourStarWidth > 0" [style.width.%]="fourStarWidth">
          <h5 class="text-white m0 fw-400">{{(fourStarReviewsCount/review_response_stats['response_count']) | percent}}</h5>
          <div class="star-holder">
            <h6 class="star-text">4</h6>
            <i class="fa icon-star"></i>
          </div>
          <h6 class="text-white m0">{{fourStarReviewsCount}} <span class="hidden-xs">Response</span></h6>
        </div>
        <div class="bg-warning-dark flex-box mt-sm" *ngIf="threeStarWidth > 0" [style.width.%]="threeStarWidth">
          <h5 class="text-white m0 fw-400">{{(threeStarReviewsCount/review_response_stats['response_count']) | percent}}</h5>
          <div class="star-holder">
            <h6 class="star-text">3</h6>
            <i class="fa icon-star"></i>
          </div>
          <h6 class="text-white m0">{{threeStarReviewsCount}} <span class="hidden-xs">Response</span></h6>
        </div>
        <div class="bg-warning flex-box mt-sm" *ngIf="twoStarWidth > 0" [style.width.%]="twoStarWidth">
          <h5 class="text-white m0 fw-400">{{(twoStarReviewsCount/review_response_stats['response_count']) | percent}}</h5>
          <div class="star-holder">
            <h6 class="star-text">2</h6>
            <i class="fa icon-star"></i>
          </div>
          <h6 class="text-white m0">{{twoStarReviewsCount}} <span class="hidden-xs">Response</span></h6>
        </div>
        <div class="bg-info flex-box mt-sm" *ngIf="oneStarWidth > 0" [style.width.%]="oneStarWidth">
          <h5 class="text-white m0 fw-400">{{(oneStarReviewsCount/review_response_stats['response_count']) | percent}}</h5>
          <div class="star-holder">
            <h6 class="star-text">1</h6>
            <i class="fa icon-star"></i>
          </div>
          <h6 class="text-white m0">{{oneStarReviewsCount}} <span class="hidden-xs">Response</span></h6>
        </div>
      </div>
    </div>
    <div class="flex-body">
      <app-reviews-listing class="flex-control flex-column" *ngIf="reviews && reviews.length > 0" (page)="pageChanged($event)" [totalItems]='totalItems' [reviews]="reviews"></app-reviews-listing>
      <div class="alert alert-info text-center fw-500 no-record" *ngIf="reviews && reviews.length == 0">No record found!</div>
    </div>
    <div class="flex-right scroll-browser filters-list">
      <h4><i class="fa icon-star mr-sm"></i> All</h4>
      <ul class="list-unstyled border-list m0">
        <li class="list" [class.active]="filter['rating'] == 5"><a (click)="getReviewsOnRating(5); showSaveButton = true">5-star</a></li>
        <li class="list" [class.active]="filter['rating'] == 4"><a (click)="getReviewsOnRating(4); showSaveButton = true">4-star</a></li>
        <li class="list" [class.active]="filter['rating'] == 3"><a (click)="getReviewsOnRating(3); showSaveButton = true">3-star</a></li>
        <li class="list" [class.active]="filter['rating'] == 2"><a (click)="getReviewsOnRating(2); showSaveButton = true">2-star</a></li>
        <li class="list" [class.active]="filter['rating'] == 1"><a (click)="getReviewsOnRating(1); showSaveButton = true">1-star</a></li>
      </ul>
      <h4><i class="fa icon-speech mr-sm vl-m"></i> Comments</h4>
      <ul class="list-unstyled border-list m0">
        <li class="list" [class.active]="filter['description'] == true"><a (click)="getReviewsOnDescription(1); showSaveButton = true">With comments</a></li>
        <li class="list" [class.active]="filter['description'] == false"><a (click)="getReviewsOnDescription(0); showSaveButton = true">Without comments</a></li>
      </ul>
      <h4><i class="fa icon-tag mr-sm vl-m"></i> Tags</h4>
      <ul class="list-unstyled border-list m0">
        <li class="list" [class.active]="filter['tag'] == true"><a (click)="getReviewsOnTag(1); showSaveButton = true">With tags</a></li>
        <li class="list" [class.active]="filter['tag'] == false"><a (click)="getReviewsOnTag(0); showSaveButton = true">Without tags</a></li>
      </ul>
      <h4><i class="fa icon-people mr-sm"></i> Gender</h4>
      <ul class="list-unstyled border-list m0">
        <li class="list" [class.active]="filter['gender'] == ('male')"><a (click)="getReviewsOnGender(0); showSaveButton = true">Male</a></li>
        <li class="list" [class.active]="filter['gender'] == ('female')"><a (click)="getReviewsOnGender(1); showSaveButton = true">Female</a></li>
        <li class="list" [class.active]="filter['gender'] == ('trans')"><a (click)="getReviewsOnGender(2); showSaveButton = true">Trans</a></li>
        <li class="list" [class.active]="filter['gender'] == ('non_binary')"><a (click)="getReviewsOnGender(3); showSaveButton = true">Genderqueer/Non-binary</a></li>
        <li class="list" [class.active]="filter['gender'] == ('other')"><a (click)="getReviewsOnGender(4); showSaveButton = true">Other</a></li>
      </ul>
    </div>
  </div>
</div>
