import { Component, OnInit, OnChanges, Input} from '@angular/core';
import { Staff } from '../../routes/practices/contacts/contact'
import { ContactService } from '../../services/contact.service';
import { Practice } from '../../routes/practices/practices/practice'
import {SimpleChanges} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'practice-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  public staffs = Array<Staff>();
  @Input() practice: Practice;
  constructor(private contactService: ContactService, public userService: UserService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      let cur = chng.currentValue;
      this.contactService.getContacts(cur.id).then(contacts => {
        for (let contact of contacts) {
          this.staffs.push(new Staff().load_from_jason(contact));

        }
      })
    }
  }
  ngOnInit() {

  }
}
