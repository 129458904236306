import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../../services/tabs.service';
import {AnnouncementComponent} from '../announcement/announcement.component';
import {Tab} from '../../../tabs/tab';
import {Announcement} from '../announcement';
import {AnnouncementsService} from '../../../services/announcements.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-announcements-filter',
  templateUrl: './announcements-filter.component.html',
  styleUrls: ['./announcements-filter.component.scss']
})
export class AnnouncementsFilterComponent implements OnInit {
  public announcements: Announcement[] = [];
  public filter = {name: '', page: '1'};
  public subscription: Subscription;
  public totalItems: string;
  constructor(private tabs_service: TabsService, private announcementService: AnnouncementsService, private _user_service: UserService) {
    this.subscription = this.announcementService.updateAnnouncemets.subscribe(item => {
      if (item) {
        this.announcements.push(item)
      }
    });
  }

  ngOnInit() {
      this.getAnnouncements();
  }
  getAnnouncements() {
   this.announcementService.getAnnouncements(this.filter).then( resp =>  {
     this.announcements = resp.announcements.map(announcement => new Announcement().load_from_json(announcement));
     this.totalItems = resp.total;
     this.filter['page'] = resp.current_page;
   })
  }
  newAnnouncements() {
    this.tabs_service.add_tab(new Tab({title: 'Add', icon: 'fa fa-hospital'}, AnnouncementComponent, null, {id: null}))
  }
  pageChanged(page) {
    this.filter['page'] = page;
    this.getAnnouncements();
  }
}
