import {LayoutComponent} from '../layout'
import  {SearchComponent} from '../search'
import { HealthPortalDoctorComponent } from '../doctor/doctor.component'
import { PatientProfileComponent } from '../patient-profile/patient-profile.component'
import { HealthPortalPracticeComponent } from '../practice/practice.component'
import  {AppointmentsComponent} from './appointments/appointments.component'
import {HomePageSignUpComponent} from '../../shared/sign-up/sign-up.component';
import {PatientVerificationComponent} from '../../shared/patient-verification/patient-verification.component';
import {MrNumberComponent} from '../mr-number/mr-number.component';
import {RegisterPracticeComponent} from './home/register-practice';
import {CanActivatePatientProfile} from '../../services/user.service'
import {ChatComponent} from '../../routes/practice-site/chat/chat.component';
import {PricingInfoComponent} from './home/pricing-info/pricing-info.component';
import {PatientHomeComponent} from "./patient-home/patient-home.component";
import {MobileAppComponent} from "./mobile-app/mobile-app.component";
let routes_mappings = [
  {path: '' , component: LayoutComponent, children: [
      {
        path: 'search', component: SearchComponent
      },
      {
        path: 'patient', component: PatientHomeComponent,
        data: {
          seo: {
            title: 'WellWink Patient Portal: Manage Your Healthcare Online',
            metaTags: [
              { name: 'description', content: 'The WellWink Patient Portal is a secure and convenient way to manage your healthcare online. Book appointments, view your medical records, and communicate with your care team, all from the comfort of your own home.' },
              {name: 'title', content: 'WellWink Patient Portal: Manage Your Healthcare Online'}
            ]
          }
        }
      },
      {
        path: 'mobile-app', component: MobileAppComponent
      },
      {
        path: 'appointments', component: AppointmentsComponent
      },
      {
        path: 'practices/register', component: RegisterPracticeComponent
      },
      {
        path: 'pricing-info', component: PricingInfoComponent,
        data: {
          seo: {
            title: 'WellWink Pricing: Affordable Patient Engagement and Care Management Software',
            metaTags: [
              { name: 'description', content: 'WellWink offers affordable patient engagement and care coordination software for healthcare providers of all sizes. Learn more about our pricing plans and features.' },
              {name: 'title', content: 'WellWink Pricing: Affordable Patient Engagement and Care Management Software'}
            ]
          }
        }
      },
      {
        path: 'doctors/:id', component: HealthPortalDoctorComponent
      },
      {
        path: 'practices/:id', component: HealthPortalPracticeComponent
      },

      {
        path: 'patients/:id', component: PatientProfileComponent, canActivate: [CanActivatePatientProfile]
      }, {
        path: 'patient/sign-up', component: HomePageSignUpComponent
      }, {
        path: 'verify-patient', component: PatientVerificationComponent
      }, {
        path: 'm/:mr_number', component: MrNumberComponent
      },
      {
        path: 'messages', component: ChatComponent
      }
    ]
  },
  {
    path: 'login', component: SearchComponent
  },

  {
    path: '**', redirectTo: '/'
  }
];

export const routes = routes_mappings;
