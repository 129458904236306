<div class="video-box">
  <a *ngIf="!videoChatService.inCall" (click)="closeVideoPanel()" class="close-btn"><i class="fa icon-close"></i></a>
  <div class="absolute-header text-white text-center fs12" *ngIf="videoChatService.clock && videoChatService.inCall">
    <div class="call-timer">{{videoChatService.formatClock}}</div>
    <div class="text-black pt fw-500" *ngIf="videoChatService.remoteAudioTrackStatus === 'disabled' && videoChatService.inCall">
      <i class="fa fa-microphone-slash fs16 d-block"></i> {{userService.current_user.isPatient ? 'Doctor' : 'Patient'}} is on mute
    </div>
    <div class="text-black pt fw-500" *ngIf="videoChatService.remoteVideoTrackStatus === 'disabled' && videoChatService.inCall">
      <i class="fa fa-video-camera disable-camera fs16 d-block"></i> {{userService.current_user.isPatient ? 'Doctor' : 'Patient'}} disabled video
    </div>
  </div>
  <div class="call-timer text-white text-center fs12" *ngIf="videoChatService.connectionLost && videoChatService.inCall">
    Call reconnecting......
  </div>
  <div class="actions-icon">
    <div class="first-icon">
      <ul class="actions">
        <li>
          <a (click)="toggleWindow()"><i class="fa icon-size-actual"></i></a>
        </li>
        <li [ngClass]="{'cam-enable': videoChatService.camEnable,'cam-disable': videoChatService.camEnable == false}" >
          <a (click)="videoChatService.toggleCam()"><i class="fa icon-camrecorder"></i></a>
        </li>
        <li [ngClass]="{'mic-enable': videoChatService.micEnable,'mic-disable': videoChatService.micEnable == false}">
          <a (click)="videoChatService.toggleMic()"><i class="fa icon-microphone"></i></a>
        </li>
      </ul>
    </div>
    <div class="second-icon">
      <ul class="actions">
        <li *ngIf="!videoChatService.inCall && !videoChatService.calling && callAllowed()" class="start-call">
          <a (click)="startCall()"><i class="fa icon-phone"></i></a>
        </li>
        <li *ngIf="videoChatService.inCall" class="end-call">
          <a (click)="cancelCall()"><i class="fa icon-phone"></i></a>
        </li>
      </ul>
    </div>
    <div class="last-icon" *ngIf="messageService.selected_chatroom">
      <ul class="actions">
        <li class="chat">
          <a (click)="toggleChat()">
            <span class="counter" *ngIf="hideChat && messageService.selected_chatroom.unread_count !== 0">{{messageService.selected_chatroom.unread_count}}</span>
            <i class="fa icon-bubbles"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="other-user-video">
    <h4 class="text-center video-disable-msg" *ngIf="videoChatService.connectionError">Network error occurred</h4>
    <app-participants #participants (onleaveRoom)="onLeaveRoom($event)" (roomChanged)="onRoomChanged($event)"
                      (participantsChanged)="onParticipantsChanged($event)"
                      (participantsRemoved)="onParticipantsRemoved($event)"
                      [style.display]="!!activeRoom ? 'block' : 'none'"
                      [activeRoomName]="!!activeRoom ? activeRoom.name : null">
    </app-participants>
    <div class="call-end-timer" *ngIf="!videoChatService.activeRoom && videoChatService.call_ended">
      <h5 class="m0 mb-sm">Call time</h5>
      <h4 class="m0">{{videoChatService.formatClock}}</h4>
    </div>
    <div class="p-status">
      <ng-container *ngIf="userService.current_user?.isPracticeUser">
        <h5 class="text-danger" *ngIf="showUserStatus()">Patient is offline.   </h5><br>
        <h5 class="text-danger" *ngIf="videoChatService.patient_cancelled_call">Patient has declined the call.</h5>
        <h5 class="text-success" *ngIf="videoChatService.start_call_message && callAllowed()">
          Press CALL green button to initiate video call.
        </h5>
      </ng-container>
      <h5 class="text-danger" *ngIf="videoChatService.call_cancelled">Call disconnected.</h5>
    </div>
  </div>
  <app-camera *ngIf="videoChatService.showLayoutVideo" class="personal-user-video"
              (roomChanged)="onRoomChanged($event)" #camera
              (callEnded)="onLeaveRoom({status: true, ended: true, cancelled: false})">
  </app-camera>
</div>
<div id="chat" [hidden]="hideChat" class="chat-box" *ngIf="messageService.selected_chatroom">
  <div class="app-chat-listing">
    <div class="chat-flex">
      <a class="visible-xs msg-chat-btn btn btn-inverse" (click)="toggleChat()"><i class="fa icon-bubbles"></i></a>
      <app-chat-detail class="body-panel p-relative" [chatroom]="messageService.selected_chatroom"></app-chat-detail>
    </div>
  </div>
</div>
<div class="call-receive-modal" *ngIf="userService.current_user?.isPracticeUser" [hidden]="!videoChatService.calling">
  <div class="modal-body" *ngIf="videoChatService.patient_user_id">
    <img class="img-circle" [src]="videoChatService.patient_profile_pic"/>
    <div class="flex-control flex-column">
      <div class="flex-body" style="justify-content:center;">
        <h5 class="text-center text-black">{{videoChatService.patient_full_name}}</h5>
        <div class="calling-text">
          <h5 class="text-center fw-500">Calling</h5>
          <div class="loading-dots">
            <h6 class="dot one">.</h6><h6 class="dot two">.</h6><h6 class="dot three">.</h6>
          </div>
        </div>
      </div>
      <div class="flex-footer text-center pb">
        <button type="submit" class="btn btn-danger btn-min" (click)="cancelCall(true)">
          <i class="fa icon-call-end"></i>
        </button>
      </div>
    </div>
  </div>
</div>
