import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LoaderService} from '../../services/loader.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {UserService} from '../../services/user.service';
import {AttachmentService} from '../../services/attachments.service';
import {RichTextTemplate} from '../../routes/rich-text-template-index/rich-text-template';
import {RichTextTemplateService} from '../../routes/rich-text-template-index/rich-text-template.service';
import {isAuto} from 'html2canvas/dist/types/render/background';

@Component({
  selector: 'app-rich-text-enote',
  templateUrl: './rich-text-enote.component.html',
  styleUrls: ['./rich-text-enote.component.scss']
})
export class RichTextEnoteComponent implements OnInit {
  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 450,
    relative_urls : false,
    remove_script_host : false,
    convert_urls : true,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
      'insertfile undo redo numlist outdent | bullist indent |contextmenu table | uploadimage media | \ ' +
      'link image forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.editor = editor;
    },

    images_upload_handler:
      (data, success, failure) => {
        if (data && data.hasOwnProperty('error') == false) {
          this.loaderService.small_loder_show();
          let attachment = {  attachable_type: 'TextTemplate', attachable_id: this.richTextTemplate.id,
            fileType: data.blob().type, file: data.blob(), description: data.filename()};
          let callback = (p: any): void => {
            console.log(p);
          };

          let upload_callback = (data: any) => {
            data = JSON.parse(data);
            if (data !== '') {
              this.loaderService.small_loder_hide();
              this.image_url =  data.url;
              this.richTextTemplate.attachable_ids.push(data.id);
              success(data.url);
            }
          };
          if (attachment.file === undefined) {
            this.globalErrorHandlerService.error = 'Please select a valid file.';
          }
          else {
            let latest_picture = this.attachmentService.upload(attachment, callback, upload_callback);
            console.log(latest_picture);
          }
        }
      },
  }
  public show_attachment_popup = false;
  public image_url: any;
  public editor: any;
  @Input() richTextTemplate: RichTextTemplate;
  @Input() viewTemplate: boolean;
  @Output() richTextSignedSuccessfully: EventEmitter<RichTextTemplate> = new EventEmitter<RichTextTemplate>()
  @Output() printRichText: EventEmitter<string> = new EventEmitter<string>()
  public saving: boolean;

  constructor(public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public userService: UserService, private attachmentService: AttachmentService,
              public richTextTemplateService: RichTextTemplateService) { }

  ngOnInit(): void {
    if (this.richTextTemplate.status === 'drafted') {
      this.viewTemplate = false;
      this.richTextTemplate = new RichTextTemplate().load_from_json(this.richTextTemplate);
    }
  }

  signRichTextTemplate(richTextTemplate: RichTextTemplate, status?: string) {
    this.loaderService.show();
    this.saving = true;
    this.richTextTemplate.last_modifier_id = this.userService.current_user.id;
    this.richTextTemplate.last_modified_at = new Date();
    this.richTextTemplate.status = status;
    this.richTextTemplateService.save(this.richTextTemplate)
      .then(resp => {
        this.richTextTemplate = this.richTextTemplate.load_from_json(resp);
        this.loaderService.hide();
        if (this.richTextTemplate.status === 'signed') {
          this.viewTemplate = true;
        }
        this.saving = false;
        this.richTextSignedSuccessfully.emit(this.richTextTemplate);
      })
      .catch(resp => {
        this.loaderService.hide();
        this.saving = false;
        console.log(resp)
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  saveAsDraft(richTextTemplate: RichTextTemplate, status: string) {
    this.signRichTextTemplate(richTextTemplate, status)
  }
}
