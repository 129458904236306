import {Component, EventEmitter, Inject, Injector, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {EligiblePatient, ProgramEnrollment} from '../eligible-patient';
import {Program} from '../program';
import {LoaderService} from '../../services/loader.service';
import {ProgramService} from '../program.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {Patient} from '../../health/doctor/patient';
import {PatientService} from '../../services/patient.service';
import {Activity} from '../activity';
import {EnrolmentsService} from '../enrolments.service';
import {Tab} from '../../tabs/tab';
import {PatientProfileComponent} from '../../health/patient-profile/patient-profile.component';
import {TabsService} from '../../services/tabs.service';
import {Subscription} from 'rxjs/Subscription';
import {TrackingService} from '../../services/tracking.service';
import {DOCUMENT} from '@angular/common';
import {AppointmentService} from '../../services/appointment.service';
import {
  PatientNotesService
} from "../../health/patient-profile/patient-dashboard/patient-communication-log/patient-notes/patient-notes.service";
import {
  PatientNotes
} from "../../health/patient-profile/patient-dashboard/patient-communication-log/patient-notes/patient-notes";
import {NewPatientNotesComponent} from '../../health/patient-profile/patient-dashboard/patient-communication-log/patient-notes/new-patient-notes/new-patient-notes.component';

@Component({
  selector: 'app-enrolled-patient',
  templateUrl: './enrolled-patient.component.html',
  styleUrls: ['./enrolled-patient.component.scss']
})
export class EnrolledPatientComponent implements OnInit, OnDestroy {
  public selected_stage_id: number;
  public stage_ids: number[] = [0, 8, 1, 2, 3, 4, 7, 5, 6];
  public menu_items: Object = {0: 'Care Management', 1: 'Monitor', 2: 'Care Plan', 3: 'Medical Chart', 4: 'Profile', 5: 'Consent', 6: 'Settings', 7: 'Health Calendar', 8: 'Summary'};
  public patient: EligiblePatient = new EligiblePatient();
  public program: Program;
  public programEnrollment: ProgramEnrollment;
  custom_injector: Injector;
  public patientForCommunication: Patient;
  public add_activity: Activity = new Activity();
  @ViewChild('logTimeFromButton') logTimeFromButton;
  @ViewChild('statusChangeModel') statusChangeModel;
  @ViewChild('classicModal') classicModal;
  showStatusModel = false;
  reason_opt_out: any;
  public reloadEnrolledPatientHeadSubscription: Subscription;
  public reloadProgramEnrolmentObjectSubscription: Subscription;
  public reloadEnrolledPatientSubscription: Subscription;
  public reloadStickyNote: Subscription;
  public timer: Subscription;
  public patientStatusSubscription: Subscription;
  public programme_type: string;
  public sticky_notes: any[] = [];
  @ViewChild('newNoteModel') newNoteModel;
  public custom_injector_for_note: Injector;
  public show_node_model = false;
  newNoteComponent = NewPatientNotesComponent;
  selectedStickyNoteId: any;
  private closeNoteModel: Subscription;

  constructor(@Inject(DOCUMENT) private document: Document, public injector: Injector,
              public loaderService: LoaderService, public appointmentService: AppointmentService,
              public programService: ProgramService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public patientService: PatientService,
              public enrolmentsService: EnrolmentsService,
              public trackingService: TrackingService,
              public _tabs_service: TabsService,
              public patientNotesService: PatientNotesService) {
    this.selected_stage_id = 0;
    this.reloadEnrolledPatientHeadSubscription = this.programService.reloadEnrolledPatientHead.subscribe(item => {
      if (item) {
        if (this.patient.latest_programme_vitals.length > 0) {
          let latest_programme_vitals = this.patient.latest_programme_vitals?.map(v =>
            (item.vital_entries.filter(a => a.vital_id === v.vital_id)[0]));
          this.reloadEligiblePatientVitalsObject(latest_programme_vitals);
        }
      }
    });
    this.reloadProgramEnrolmentObjectSubscription = this.enrolmentsService.reloadProgramEnrolmentObject.subscribe(item => {
      if (item) {
        if (item.id === this.programEnrollment.id) {
          this.programEnrollment.load_from_json(item);
        }
      }
    });
    this.reloadEnrolledPatientSubscription = this.enrolmentsService.reloadEnrolledPatient.subscribe(item => {
      if (item) {
        this.getEligiblePatientObject(this.patient.id);
      }
    });
    this.reloadStickyNote = this.patientNotesService.reloadStickyNote.subscribe(item => {
      if (item) {
        this.getStickyNotes();
      }
    });
    this.closeNoteModel = this.patientNotesService.closeNewNoteForm.subscribe( resp => {
      if (resp) {
        this.newNoteModel.hide();
        this.show_node_model = false;
      }
    });
    this.patientStatusSubscription =  this.appointmentService.patientStatusObservable.subscribe(val => {
        if (val.user_id === this.patient.user_id) {
          this.patient.portal_online_status = val.portal_online_status;
          this.patient.mobile_online_status = val.mobile_online_status;
          this.patientForCommunication.portal_online_status = val.portal_online_status;
          this.patientForCommunication.mobile_online_status = val.mobile_online_status;
        }
    })
  }



  ngOnInit(): void {
    this.patient = this.injector.get('patient');
    this.program = this.injector.get('program');
    this.programme_type = this.injector.get('programme_type');
    this.programme_type = this.programme_type.toLocaleLowerCase();
    if (this.patient.id) {
      this.patientService.getPatient(this.patient.id).then(resp => this.patientForCommunication = new Patient().load_from_json(resp));
    }
    this.getProgramEnrollmentObject(this.program.id, this.patient.id);

  }

  getStickyNotes(){
    this.patientNotesService.getStickyNotes(this.patient.id).then(resp => {
      this.sticky_notes = resp.map(patient_notes => new PatientNotes().load_from_json(patient_notes));
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  onMenuSelect(stage_id: number) {
    this.selected_stage_id = stage_id;
    if (stage_id === 8) {
      this.loaderService.show();
    }
  }

  getProgramEnrollmentObject(program_id: any, patient_id: any) {
    this.loaderService.show();
    this.programService.getProgramEnrollmentObject(program_id, patient_id).then(resp => {
      this.programEnrollment = this.patient.tem_programme_enrolment.load_from_json(resp);
      this.programEnrollment.selected_enrollment_type = this.programme_type;
      this.getStickyNotes();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  saveActivity() {
    this.loaderService.show();
    this.add_activity.time_log_attributes.programme_type = this.programme_type;
    this.add_activity.activiable_type = 'ProgrammeEnrolment';
    this.add_activity.activiable_id = this.programEnrollment.id;
    this.enrolmentsService.addActivityAndLogTime(this.add_activity).then(resp => {
      this.enrolmentsService.reloadActivityList.next(1);
      this.enrolmentsService.reloadProgramEnrolmentTime.next(1);
      this.logTimeFromButton.hide();
      this.add_activity = new Activity();
      this.loaderService.hide();
    }).catch(res => {
      console.log(res);
      this.loaderService.hide();
      this.globalErrorHandlerService.error = res.error.error;
    })
  }

  openEnrolledPatientProfile(patient: any) {
    let title = patient.name + '-' + 'Profile';
    let id = patient.id;
    this._tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital', autorefresh: true},
      PatientProfileComponent,
      id, {id: id, patient: patient}))
  }

  patientOptOut(program: Program) {
    console.log(this.reason_opt_out, program)
    this.loaderService.show();
    this.enrolmentsService.removePatientFromProgram({
      id: this.programEnrollment.id,
      reason: this.reason_opt_out
    }).then(resp => {
      this.reason_opt_out = undefined;
      this.loaderService.hide();
      this.programService.patientOptOutListener.next(this.programEnrollment.patient_id);
      this._tabs_service.onlyCloseTab(this._tabs_service.active_tab());
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  reloadEligiblePatientVitalsObject(vital: any) {
    this.patient.latest_programme_vitals = vital
  }
  changeStatus() {
    this.showStatusModel = true;
    this.statusChangeModel.show();
  }

  updateHealthComplianceStatus(programEnrollment) {
    this.loaderService.show();
    this.enrolmentsService.updateHealthComplianceStatus(programEnrollment.id, programEnrollment.health_status,
      programEnrollment.compliance_level).then(resp => {
      this.programEnrollment.load_from_json(resp);
      this.statusChangeModel.hide();
      this.showStatusModel = false;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  getEligiblePatientObject(id: any) {
    this.programService.programmePatient(this.program.id, this.patient.id).then(resp => {
      this.patient = this.patient.load_from_json(resp)
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  ngOnDestroy(): void {
    this.reloadEnrolledPatientHeadSubscription.unsubscribe();
    this.reloadProgramEnrolmentObjectSubscription.unsubscribe();
    this.reloadEnrolledPatientSubscription.unsubscribe();
    this.reloadStickyNote.unsubscribe();
    this.patientStatusSubscription.unsubscribe();
    this.closeNoteModel.unsubscribe();
  }

  openStickyNote(note: PatientNotes) {
    this.setCustomInjectorForNote(note);
  }

  unSricky(noteId) {
    this.loaderService.show();
    this.patientNotesService.removeSticky(noteId).then(resp => {
      this.patientNotesService.reloadStickyNote.next(1);
      this.selectedStickyNoteId = '';
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  setCustomInjectorForNote(note: PatientNotes) {
    this.custom_injector  = Injector.create([
      {provide: 'patient_note', useValue: note},
      {provide: 'id', useValue:  note.id},
      {provide: 'patient_id', useValue:  note.patient_id},
      {provide: 'isprogramme_profile', useValue:  true}
    ], this.injector);
    this.show_node_model = true;
    this.newNoteModel.show();
  }

}
