import {Component, OnDestroy, OnInit} from '@angular/core';
import {TabsService} from "../../../../services/tabs.service";
import {Tab} from "../../../../tabs/tab";
import {NoteComponentComponent} from "../../note-component.component";
import {NoteComponent} from "../../note-component";
import {NoteComponentService} from "../../note-component.service";
import {Subscription} from "rxjs/Subscription";
import {LoaderService} from "../../../../services/loader.service";
import {GlobalErrorHandlerService} from "../../../../services/global-error.service";
import {AlertService} from "../../../../_alert";

@Component({
  selector: 'app-note-components-listing',
  templateUrl: './note-components-listing.component.html',
  styleUrls: ['./note-components-listing.component.scss']
})
export class NoteComponentsListingComponent implements OnInit, OnDestroy {
  public filter: Object = {component: '', page: 1, category: ''};
  public noteComponent: NoteComponent = new NoteComponent();
  public noteComponents: NoteComponent[] = [];
  public categories: string[] = [];
  totalItems: any;
  public subscription: Subscription;
  constructor(public tabsService: TabsService, public loader_service: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService, public noteComponentService: NoteComponentService) {
    this.subscription = this.noteComponentService.reloadNoteComponentList.subscribe(value => {
      this.getNoteComponents();
    })
  }

  ngOnInit(): void {
    this.getNoteComponents();
    this.getCategories();
  }

  search() {
    this.filter['page'] = 1;
    this.getNoteComponents();
     }

  adNoteComponent() {
    let noteComponent = new NoteComponent();
    this.tabsService.add_tab(new Tab({title: 'Add Note Component', icon: 'fa'}, NoteComponentComponent,
      noteComponent.id, { noteComponent: noteComponent}))
  }

  editNoteComponent(noteComponent: any) {
    this.tabsService.add_tab(new Tab({title: noteComponent.component, icon: 'fa'}, NoteComponentComponent,
      noteComponent.id, { noteComponent: noteComponent}))

  }
  getNoteComponents() {
    this.loader_service.show();
    this.noteComponentService.getNoteComponents(this.filter).then(resp => {
      this.noteComponents = resp.note_components.map(note_component => new NoteComponent().load_from_json(note_component));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loader_service.hide();
    }).catch(resp => {
      this.loader_service.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  getCategories() {

    this.noteComponentService.getCategories().then(resp => {
      this.categories = resp;
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  pageChanged($event: any ) {
    this.filter['page'] = $event.page;
    this.getNoteComponents();
  }

  changeStatusOfNoteComponent(noteComponent: any) {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
