<div class="flex-control flex-column">
  <div class="todaysPatient-list flex-body scroll-browser">
    <ng-container *ngIf="appointments && appointments.length == 0"><app-no-result-found></app-no-result-found></ng-container>
    <div class="list" *ngFor="let appointment of appointments;let i = index">
      <div class="col-sm-3 picName-area">
        <div class="cell-holder pr-sm">
          <div class="pic img-holder text-center">
            <img class="img-circle thumb64" [src]="appointment.patient.profile_pic" alt="Image"/>
          </div>
          <h4 class="m0 mb fw-500 fs16"><a (click)="add_to_tabs_patient_profile(appointment)">{{appointment.patient.name}}</a></h4>
          <h6 class="m0 mb text-capitalize">
            <i class="mr-sm" [ngClass]="(appointment.patient.user.gender == 'female')?'fa icon-user-female':'fa icon-user'"></i>
            {{appointment.patient.customize_dob}},  &nbsp;
            {{appointment.patient.show_age_and_gender()}}
          </h6>
          <h6 class="m0"><a href="javascript:void(0);" class="h6 m0"
                            (click)="phoneService.initiatePhoneCall(
                            appointment.patient.mobile_number_value,  {name: appointment.patient.name, pic: appointment.patient.profile_pic})">
            <i class="fa icon-phone mr-sm"></i>
            {{appointment.patient.mobile_number_value  | mask: ' (000) 000-0000'}}
          </a>
          </h6>
          <ng-container>
            <app-patient-communication [patient]="appointment.patient"></app-patient-communication>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-1 border-col time-area">
        <div class="cell-holder text-center pl-sm pr-sm" [attr.title]="appointment.timeZone">
          <h4 class="mt0 fs16 fw-400">{{appointment.appointment_time_only}}</h4>
          <h5 class="m0 fw-300">{{ appointment.slot_size }} Mins</h5>
          <i *ngIf="appointment.appointment_type === 'video' || appointment.appointment_type === 'virtual_visit'" class="fa icon-camrecorder border-box"></i>
          <i *ngIf="appointment.appointment_type === 'phone_visit'" class="fa icon-call-in border-box"></i>
          <i *ngIf="appointment.appointment_type === 'office_visit'" class="fa fa-building-o border-box"></i>
          <i *ngIf="appointment.appointment_type === 'Book'" class="fa fa-building-o border-box"></i>
        </div>
      </div>
      <div class="col-sm-6 border-col">
        <div class="cell-holder pl-sm pr-sm">
          <div class="row mb-sm" *ngIf="appointment.service">
            <label class="col-sm-3 col-xs-12 field-label">Reason of Visit</label>
            <div class="col-sm-9 col-xs-12">{{appointment.service.name}}</div>
          </div>
          <div class="row mb-sm">
            <label class="col-sm-3 col-xs-12 field-label">Appointment</label>
            <div class="col-sm-9 col-xs-12" [attr.title]="appointment.timeZone">
              {{appointment.appointment_time}} with
              <span class="pointer text-primary fw-500" (click)="showDropDown(appointment);" *ngIf="appointment.doctor  || !appointment.appointment_with">
              {{appointment?.doctor?.complete_name}}
            </span>
              <span class="text-primary fw-500" *ngIf="!appointment.doctor">
              <a (click)="showDropDown(appointment);" >any available doctor</a>
            </span>
              <ng-container *ngIf="show_doctor_dropdown && selected_appointment_id == appointment.id">
                <select #provider class="form-control" (change)="providerSelected(provider.value)">
                  <option value="">Doctors</option>
                  <option *ngFor="let provider of providers" [value]="provider.id">
                    {{provider.firstname}} {{provider.lastname}}
                  </option>
                </select>
              </ng-container>
            </div>
          </div>
          <div class="row mb-sm">
            <label class="col-sm-3 col-xs-12 field-label">Location</label>
            <div class="col-sm-9 col-xs-12">
              {{appointment?.location?.address.line_1_line_2()}}
              {{appointment?.location?.address.city_state_zip()}}
            </div>
          </div>
          <div class="row mb-sm">
            <label class="col-sm-3 col-xs-12 field-label">Requested On</label>
            <div class="col-sm-9 col-xs-12">{{appointment?.requested_on}} by {{appointment?.actioned_by}}</div>
          </div>
          <div class="row" *ngIf="appointment.notes_for_doctor">
            <label class="col-sm-3 col-xs-12 field-label">Notes</label>
            <div class="col-sm-9 col-xs-12">{{appointment?.notes_for_doctor}}</div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 border-col btn-area">
        <div class="cell-holder pl-sm pr-sm">
          <ul class="tabs-action-btns m0 p0 list-unstyled" *ngIf=" appointment.status_id == 'pending' ">
            <li>
              <button class="btn btn-secondary btn-outline"  *userCan="(_userService.current_user.isPractice || _userService.current_user.isDoctor)  ? 'approvereject,appointments,reception': 'approvereject,appointments,online'"
                      (click)="acknowledge(appointment)">Acknowledge</button>
            </li>
            <li>
              <button class="btn btn-danger btn-outline" *userCan="(_userService.current_user.isPractice || _userService.current_user.isDoctor)  ? 'approvereject,appointments,reception': 'approvereject,appointments,online'"
                      (click)="classicModal.show();">Cancel</button>
            </li>
          </ul>
          <p class="m0" *ngIf="appointment.status_id == 'cancelled'">
            <span class="text-danger">Cancelled by</span>
            {{appointment?.actioned_by}} on {{appointment.actioned_on}} due to {{appointment?.reason_of_cancellation}}
          </p>
          <p class="m0" *ngIf="appointment.status_id != 'pending' && appointment.status_id != 'cancelled'">
            <span class="text-primary">Acknowledged by</span>
            {{appointment?.actioned_by}} on {{appointment.actioned_on}}
          </p>
        </div>
      </div>
      <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
        <div class="modal-dialog modal-md" style="height:285px;">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
              <h4 class="m0 text-center">Reason of Cancellation</h4>
            </div>
            <div class="modal-body">
            <textarea class="form-control no-resize" placeholder="Type your reason here..." maxlength="50" rows="5" cols="64"
                      [(ngModel)]="appointment.reason_of_cancellation">
            </textarea>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-secondary" (click)="cancel(appointment);classicModal.hide();">Submit</button>
              <button type="submit" class="btn btn-tertiary btn-outline" (click)="classicModal.hide();">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center flex-footer" *ngIf="totalItems > 10">
    <pagination class="m0" [totalItems]="totalItems" [maxSize]="10" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
  </div>
</div>
