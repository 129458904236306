<div class="flex-control flex-column" *ngIf="userService?.current_user.twilio_configurations.sms && smsService.showConversations">
  <app-tabs [class.chat-container]="!tabs_service.is_active()">
    <li (click)="tabs_service.deactivate_tab()" [class.active]="!tabs_service.is_active()">SMS  <span *ngIf="userService.current_user.twilio_configurations.twilio_sms_number">  ( {{userService.current_user.twilio_configurations.twilio_sms_number}})</span></li>
  </app-tabs>
  <app-sms-listing class="flex-control flex-column" [hidden]="tabs_service.is_active()"></app-sms-listing>
</div>

<ng-container *ngIf="!userService?.current_user.twilio_configurations.sms">
  <div class="main-wrapper flex-container">
    <div class="alert alert-info text-center" style="margin:auto; width:100%; max-width:500px;">{{ show_im }}</div>
  </div>
</ng-container>
