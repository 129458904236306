<div class="doctor-block-public p-relative">
  <span class="star-special"><img src="/assets/img/red_star.png" alt="star"/></span>
  <div class="row">
    <div class="col-sm-6 col-xs-12 doc-info">
      <div class="media">
        <div class="media-left text-center pr-lg">
          <a [routerLink]="['/doctors',physician.id]"><img class="img-circle thumb64 center-block" [src]="physician.profile_pic" alt="Image" /></a>
          <rating [titles]="[]" class="review-star" [tooltip]="physician.averageExactRating" [(ngModel)]="physician.averageRating" [max]="5" [readonly]="true"></rating>
        </div>
        <div class="media-body">
          <a class="fs20 d-block" (click)="setParams(physician.id)">{{physician.doctor_full_name}}</a>
          <h5 class="fs14 m0 color-black">{{physician.suffix}}</h5>
          <h5 class="mt-sm mb-sm">{{physician.designation}}</h5>
          <h6 class="m0 fw-400" *ngIf="physician.specialties">{{physician.specialties}}</h6>
          <ul class="list-unstyled">
            <li class="mb-lg" *ngFor="let location of physician.practice_address_name_phone">
              <h5 class="mb0 mb-sm" (click)="location.toggle_info_window()">
                <a [routerLink]="['/practices',location.practice_id]" class="fw-500">
                  <div class="media p0">
                    <div class="media-left p0"><i class="fa icon-location-pin" style="margin-top:1px;"></i></div>
                    <div class="media-body pl-sm">{{location.practice_name}}</div>
                  </div>
                </a>
              </h5>
              <p class="m0 fs12"><i class="fa icon-phone"></i> &nbsp;{{location.practices_phone | mask: '(000) 000-0000'}}</p>
              <div class="media p0 m0 fs12">
                <div class="media-left p0"><i class="fa icon-pin"></i></div>
                <div class="media-body pl-sm">{{location.practice_address}}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12">
      <app-slots class="doc-slots flex-control flex-column" [grouped_slots]="physician.grouped_slots"></app-slots>
    </div>
  </div>
</div>
