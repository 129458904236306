<span class="d-block" *ngIf="!term.isComponentTypeChild() && term.control_value_class != 'absent'">
  <span contenteditable="false" class="d-block">
    <span class="fs10 badge pull-right" *ngIf="term.last_updated_at">Modified {{term.last_updated_at | date:'MM/d/y'}}</span>
    <span #term_name_span
          class="lhn"
          [class.term]="term.isTermTypeChild()"
          [class.fs13]="term.isTermTypeChild()"
          [tooltip]="term.status"
          [class.primary-title]="term.component_level == 2"
          [class.secondary-title]="term.component_level == 3"
          [class.tertiary-title]="term.component_level == 4">
      {{term.is_open_text ? term.open_text : term.d_name}}
    </span>
  </span>
</span>

<span class="d-block"></span>

<ng-container *ngFor="let term_control of term.custom_term_control_with_values(); let i=index">
  <app-note-custom-control-value class="fs12 fw-400" [term]="term" [term_control]="term_control" [control_index]="i"></app-note-custom-control-value>
  <span class="fs12 fw-400" style="margin-left:-3px" *ngIf="i < term.custom_term_control_with_values().length - 3 ">,</span>
  <span class="fs12 fw-400" *ngIf="(term.custom_term_control_with_values().length - 2) == i">and</span>
  <span class="fs12 fw-400" *ngIf="(term.custom_term_control_with_values().length -1) == i">.</span>
</ng-container>

<ng-container *ngIf="term.hasChildren()">
  <ng-container *ngFor="let child_terms of term.children_groups;let i=index">
    <span class="fs" [class.indent-component]="i > 0" [class.enote-term]="child_terms.length > 1 && !child_terms[1].isComponentTypeChild() || term.is_open_text">
      <ng-container *ngFor="let child_term of child_terms">
        <ng-container *ngIf="child_term.showTerm() && child_term.showComponent && !child_term.isComponentTypeChild() && child_term.control_value_class != 'absent'">
          <app-note-summary [class.pb-sm]="!child_term.isComponentTypeChild() && child_term.parent_term " [ngClass]="child_term.isComponentTypeChild() && !child_term.is_open_text ? 'flex-component' : '' "  [class.d-block]="!child_term.isComponentTypeChild() && child_term.parent_term" *ngIf="!child_term._destroy" [term]="child_term"></app-note-summary>
        </ng-container>
      </ng-container>
    </span>
  </ng-container>
</ng-container>
