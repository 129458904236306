import { Component, OnInit, ViewChild } from '@angular/core';
const screenfull = require('screenfull');
const browser = require('jquery.browser');
import {Router} from '@angular/router';
import $ from 'jquery';
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import {Globals} from './../../globals'
import {UserService} from '../../services/user.service';
import {HttpTokenService} from '../../services/http.token.service';
import {MessageService} from '../../services/message.service';
import {PracticeReviewService} from '../../reviews/practice_review.service';
import {PushNotification} from '../../interfaces/push_notification';
import {CustomHttpClient} from '../../services/custom-http-client.service';
import {SmsService} from '../../routes/practice-site/sms/sms.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isNavSearchVisible: boolean;
  keys: string[];
  notifications: Object;
  menu_service: MenuService;
  @ViewChild('fsbutton') fsbutton;  // the fullscreen button

  constructor(private userblockService: UserblockService, public user_service: UserService, public settings: SettingsService,
              private menu: MenuService, private router: Router, private _tokenService: CustomHttpClient, private globals: Globals,
              private _message_service: MessageService, private practiceReviewService: PracticeReviewService,
              public smsService: SmsService) {
    this.menu_service = menu;
    if (this.user_service.current_user) {
      this.notifications = this._message_service.set_notifications();
      this.keys = Object.keys(this.notifications);
      this.keys = this.keys.filter(item => item !== 'patient_events');
    }
  }


  ngOnInit() {
    this.isNavSearchVisible = false;
    if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }
    this.set_selected_menu();
    if (!this.user_service.current_user) {
      this.user_service.validateToken();
    }
  }

  get current_user() {
    return this.user_service.current_user;
  }
  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    this.isNavSearchVisible = stat;
  }

  set_selected_menu(menu?) {
    this.menu_service.set_selected_menu(menu);
  }

  get selected_menu() {
    return this.menu_service.selected_menu;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleCollapsedSideabar() {
    this.settings.toggleSidebar();
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  toggleFullScreen(event) {

    if (screenfull.enabled) {
      screenfull.toggle();
    }
    // Switch icon indicator
    let el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
      el.children('em').removeClass('icon-size-actual').addClass('icon icon-frame icon-size-fullscreen');
    }
    else {
      el.children('em').removeClass('icon-size-fullscreen').addClass('icon icon-frame icon-size-actual');
    }
  }

  permitted_menu_for(main_menu) {
    return this.menu_service.get_permitted_menu_for(main_menu);
  }


  logout() {
    this.user_service.logout();
  }

  notificationClick(key: any, notification?: PushNotification) {
    if (key === 'reviews') {
      this.practiceReviewService.markPracticeReviewRead().then(response => {
        this._message_service.notifications['reviews']['count'] = response['reviews']['count'];
      });
    }
    if (key === 'tasks') {
      this._tokenService
        .put('notifications/' + notification.id, notification)
        .toPromise()
        .then(response => {
            this._message_service.popTaskNotification(notification);
          }
        );
    }
  }

  get_count_for(count) {
    if (typeof(count) === 'function') {
      return count.call(this._message_service);
    } else {
      return  count;
    }
  }

  refreshNotifications() {
    this.notifications = this._message_service.set_notifications();
  }
}
