import {Component, OnInit} from '@angular/core';
import {ContactUsService} from "./contact-us.service";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {


  constructor(public contactUsService : ContactUsService) { }

  ngOnInit(): void {
  }
}
