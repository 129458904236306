<ul class="custom-tabs nav nav-pills">
<li *ngFor="let stage_id of stage_ids"  [class.active]="selected_stage_id == stage_id ">
    <button class="btn btn-default" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="stage_id" (click)="onSelect(stage_id)">{{menu_items[stage_id]}}</button>
  </li>
</ul>

<div class="main-wrapper">
  <div class="table-responsive leads">
    <table class="table table-bordered table-striped table-hover">
      <thead>
      <tr>
        <th style="width:300px;">Provider</th>
        <th style="width:300px;">Review</th>
        <th style="width:300px;">Rating</th>
        <th>Reviewed By</th>
        <th>On</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody >
      <ng-container *ngFor="let patientReview of patientReviews; let i = index;">
        <tr>
          <td>
            <div class="col-sm-3 pr0 pl0">
              <img class="img-circle thumb48 " [src]="patientReview.doctor.profile_pic" alt="Image"/>
            </div>
            <div class="col-sm-9 pl0">
              <div class="col-sm-12 pl0 text-primary">
                <b>{{patientReview.doctor.prefix}} {{patientReview.doctor.firstname}}
                  {{patientReview.doctor.lastname}}</b><br>
                {{patientReview.doctor.client.specialties[0].description}}
              </div>
              <div class="col-sm-12 pl0">
                <rating [(ngModel)]="doctor_average_rating" [max]="5" [readonly]="true"></rating>
              </div>
            </div>
          </td>
          <td>{{patientReview.description}}</td>
          <td>
            <ul class="list-unstyled" *ngFor="let patientRating of patientReview.patientRatings">
              <li class="col-sm-12 pl0">
                <span class="col-sm-6 pl0">{{patientRating.ratingType.name}}</span>
                <span class="col-sm-6 pl0"><rating [(ngModel)]="patientRating.score" [max]="5" [readonly]="true"></rating></span>
              </li>
            </ul>
          </td>
          <td>{{patientReview.patient.name}}</td>
          <td>{{patientReview.created_at}}</td>
          <td *ngIf="selected_stage_id == 0">
            <button *userCan="'approvereject,Patient Reviews,online'"  class="btn-xs btn btn-outline btn-primary accept" (click)="changeStatus(i,1,'accept');classicModal.show();">
              Accept
            </button>
            <button *userCan="'approvereject,Patient Reviews,online'"  class="btn-xs btn-inverse btn-outline-leads reject" (click)="changeStatus(i,2,'reject');classicModal.show();">
              Reject
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-container">
  <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1" role="dialog"
       aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" style="height:200px;">
      <div class="modal-content modal-border">
        <div class="modal-heading mb-lg">
          <h4 class="modal-title"><span class="col-sm-8">Are you sure you want to {{status}} the review?</span></h4>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row col-sm-12">
              <div class="col-sm-6 text-center">
                <button type="submit" class="btn btn-default" (click)="classicModal.hide();update();">Yes</button>
                <button type="submit" class="btn btn-default" (click)="classicModal.hide()">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
