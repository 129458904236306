import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {PatientEvent} from '../patient-event';
import {LoaderService} from '../../../../services/loader.service';
import {PatientEventService} from '../patient-event.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {AlertService} from '../../../../_alert';
import {Comment} from '../../../../routes/practice-site/tasks/comment';
import {CommentService} from '../../../../routes/practice-site/tasks/comment.service';

@Component({
  selector: 'app-health-calendar-event-view',
  templateUrl: './health-calendar-event-view.component.html',
  styleUrls: ['./health-calendar-event-view.component.scss']
})
export class HealthCalendarEventViewComponent implements OnInit, OnDestroy {

  @Input() event: PatientEvent;
  @Input() isPatientPortal: boolean
  @Output() editEvent: EventEmitter<PatientEvent> = new EventEmitter<PatientEvent>();
  @Output() closeViewPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isViewMode: boolean;
  @Input() comments: Comment[] = [];


  constructor(public loaderService: LoaderService, public patientEventService: PatientEventService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public alertService: AlertService,
              public commentService: CommentService) {}

  ngOnInit(): void {
    this.isViewMode = this.isPatientPortal ? false : true;
  }

  updateStatus(status: string) {
    this.loaderService.show();
    let status_color = this.event.setColorOfEventStatus(status)
    this.patientEventService.updatePatientEventStatus(this.event.id, status, status_color).then(resp => {
      this.event.load_from_json(resp);
      this.closeView();
      this.alertService.success('Event has been changed successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
    this.event.status = status
  }

  deletePatientEvent(type: any) {
    this.loaderService.show();
    this.patientEventService.deletePatientEvent(this.event.id, type).then( resp => {
      if (resp) {
        this.closeView();
        this.alertService.success('Patient event has been deleted successfully', this.alertService.options);
      }
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  edit(event: PatientEvent) {
    this.loaderService.show();
    this.editEvent.emit(event)
  }

  closeView() {
    this.loaderService.hide();
    this.patientEventService.showDeleteConformation = false;
    this.patientEventService.reloadPatientEventList.next(this.event);
    this.comments = [];
    this.closeViewPopup.emit(true);
  }

  ngOnDestroy(): void {
    this.comments = [];
  }
}
