import {Component, OnInit, Input, ViewChild, Output, EventEmitter, Injector, AfterViewChecked} from '@angular/core';
import {Task} from './task';
import {CustomValidations} from '../../../customValidations/custom-validations';
import {NgForm} from '@angular/forms';
import {PatientService} from '../../../../services/patient.service';
import {Patient} from '../../../../health/doctor/patient';
import {Observable} from 'rxjs';
import {ContactService} from '../../../../services/contact.service';
import {Staff} from '../../../practices/contacts/contact';
import {TaskService} from '../../../../services/task.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {UserService} from '../../../../services/user.service';
import {User} from '../../../users/user';
import { mergeMap } from 'rxjs/operators';
import {TabsService} from '../../../../services/tabs.service';
import {LoaderService} from '../../../../services/loader.service';
import {EdittaskComponent} from '../edittask/edittask.component';
import {TrackingService} from '../../../../services/tracking.service';
import {AlertService} from '../../../../_alert';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-newtask',
  templateUrl: './newtask.component.html',
  styleUrls: ['./newtask.component.scss']
})
export class NewtaskComponent implements OnInit, AfterViewChecked {
  public staffs: Staff[];
  public priorities: Array<string> = ['Medium', 'High', 'Low'];
  public statuses: Array<string> = ['Not Started', 'In Progress', 'Completed'];
  @Input() task: Task;
  @Input() editTask: boolean;
  @Output() closeform = new EventEmitter<boolean>();
  public filter: Object = {token: ''};
  public patients: Observable<any[]>;
  public errorMessage: string;
  public practices: any[];
  public practice_users: any[];
  public  show_patient_form = true;
  customValidations: CustomValidations;
  public date2DisabledDates;
  c_user: User;
  public patient: Patient = new Patient();
  public show_attachment_popup = false;
  public selected_patient: Patient = new Patient();
  public asyncSelected = '';
  @ViewChild('taskForm') taskForm: NgForm;
  public source_type: any;
  public source_id: any;
  public edit_task_model = false;
  public add_patient_button = true;
  public custom_injector: Injector;
  editTaskComponent = EdittaskComponent;
  @ViewChild('editTaskModal') editTaskModal;
  addPatientModal = false;
  isAssignedToRequired: boolean = true;

  constructor(public loaderService: LoaderService, public tabs_service: TabsService , public injector: Injector,
              private currentUser: UserService , private patientService: PatientService, public alertService: AlertService,
              private staffService: ContactService, private taskService: TaskService,private userService: UserService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public trackingService: TrackingService) {
    this.customValidations = new CustomValidations();
    this.patients = Observable.create((observer: any) => {
      observer.next(this.task.patient.name);
    }).pipe(mergeMap((token: string) => this.patientService.searchPatient(token)));
  }

   ngOnInit() {
     this.c_user = this.currentUser.current_user;
     let newtask = this.injector.get('task', null);
     this.source_type = this.injector.get('source_type', null);
     this.source_id = this.injector.get('source_id', null);
     this.add_patient_button = this.injector.get('add_patient_button', true);
     this.editTask = this.injector.get('editTask', false);

     this.isAssignedToRequired = this.injector.get('isAssignedToRequired', true);
     if (newtask) {
       this.task = newtask;
     }
     if (this.source_type === 'ProgrammeEnrolment' && this.source_id ) {
       this.task.source_type = this.source_type;
       this.task.source_id = this.source_id;
     }

     this.task.assigned_to = this.task.id ?  this.task.assigned_to : (this.isAssignedToRequired ? this.c_user.id : undefined);
     if (this.currentUser.current_user.isDoctor) {
       this.getStaff();
     } else {
       this.staffService.get_practice_users().then(users => {
         this.practice_users = users.map(user => new User().load_from_json(user))
       });
     }
     if (this.task.is_new_record()) {
       this.setNotifyTime(new Date())
     }
  }

  getStaff() {
    this.staffService.getContactUsers(null).then(resp => {
      this.staffs = resp.map(staff => new Staff().load_from_jason(staff));
      this.practices = Array.from(new Set(resp.map(staff => staff.practice.name)));
    })
  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.taskForm);
  }
  save() {
    this.loaderService.small_loder_show();
    this.taskService.save(this.task)
      .then(resp => {
        this.task = this.task.load_from_json(resp);
        this.taskService.taskBeforeEdit = this.task.clone();
/*        if (resp.source_type == 'ProgrammeEnrolment') {
          this.stopAction(resp);
        }*/
        this.loaderService.small_loder_hide();
        this.taskService.reloadTasks.next(this.task);
        this.closeNewForm();
        this.alertService.success('Task has been saved successfully', {autoClose: true, keepAfterRouteChange: true})
      })
      .catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }
  typeaheadOnSelect(event) {
    this.task.patient_id = event.item.id;
  }
  setAssignedTo(event) {
    this.task.assigned_to = event.item.id;
  }
  selectAssignedTo(staff) {
    this.task.assigned_to = staff.value;
    console.log('staff value is' + staff.value)
  }
  selectPriority(priority) {
    this.task.priority = priority.value;
  }
  selectStatus(status) {
    this.task.status_id = status.value;
  }
  practice_staff(practice): Staff[] {
   return this.staffs.filter(staff => staff.practice_name == practice)
  }

  close_modal() {
    this.addPatientModal = false;
    setTimeout(() => {
      this.show_patient_form = false;
      this.patient = new Patient();
      setTimeout(() => {
        this.show_patient_form = true;
      }, 200);
    }, 200)
  }

  handle_user(new_patient) {
    this.selected_patient = new_patient;
    this.task.patient_id = new_patient.id;
    this.task.patient.name = new_patient.name;
    // this.asyncSelected = this.selected_patient.user.full_name_with_information(this.selected_patient.age);
  }

  handle_image_upload(data) {
    if (data) {
      this.patient.user.profile_pic = data.profile_pic;
      this.patient.user.profile_pic_id = data.id;
    }
    this.show_attachment_popup = false;
  }

  stopAction(task: Task) {
    const trackingResult = this.trackingService.stopTracking(this.task.temp_activity_id);
    this.trackingService.logTimeForPatientActivity({duration: trackingResult.duration, description: task.task_name,
      activity_type: 'task', source_id: task.id, activiable_id: task.source_id});
  }

  closeNewForm() {
    if (this.editTask) {
      this.taskService.reloadTaskObjectListener.next(this.task);
    }
    this.taskService.closeNewTaskForm.next(true);
    this.edit_task_model = false;
  }

  setDueDateAsNotifyOn(due_date: any) {
    this.task.notify_date = new Date(due_date);
    this.setNotifyTime(due_date);
  }

  setNotifyTime(date: Date) {
    date.setHours(8, 0, 0);
    this.task.notify_time = formatDate(date, 'HH:mm', 'en');
  }
  readOnlyForDelegateAssignee() {
    const assignedTo = this.task.assigned_to;
    const assignedBy = this.task.assigned_by;
    const currentUserID = this.userService.current_user.id;
    return assignedTo !== assignedBy && assignedTo === currentUserID && this.editTask;
  }
}
