import {Component, Input, OnInit} from '@angular/core';
import {Patient} from '../../../health/doctor/patient';
import {Program} from '../../program';
import {EligiblePatient, ProgramEnrollment} from '../../eligible-patient';

@Component({
  selector: 'app-program-wizard',
  templateUrl: './program-wizard.component.html',
  styleUrls: ['./program-wizard.component.scss']
})
export class ProgramWizardComponent implements OnInit {
  public width = '25%';
  public step = 1;
  private widthMapping = {1: '25%', 2: '50%', 3: '75%', 4: '100%'};
  @Input() patient: EligiblePatient;
  @Input() program: Program;

  constructor() { }

  ngOnInit(): void {
  }

  setStep(step: number) {
    this.step = step;
    this.width = this.widthMapping[step];
  }
   registeredPatient(event: any) {
    this.patient = new EligiblePatient().load_from_json(event);
   }
}
