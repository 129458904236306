import {Component, Injector, Input, OnInit} from '@angular/core';
import {EnoteService} from '../../../services/enote.service';
import {Template} from '../../../enote/enote_models/template';
import {Program} from '../../program';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import {ProgramService} from '../../program.service';
import {EnoteComponent} from '../../../enote/enote/enote.component';
import {Patient} from '../../../health/doctor/patient';
import {EligiblePatient, ProgramEnrollment} from '../../eligible-patient';
import {PatientNoteComponent} from '../../../enote/patient-note/patient-note.component';
import {PageChangedEvent} from 'ngx-bootstrap/pagination/pagination.component';
import {RichTextTemplate} from '../../../routes/rich-text-template-index/rich-text-template';

@Component({
  selector: 'app-enrolled-patient-care-plan',
  templateUrl: './enrolled-patient-care-plan.component.html',
  styleUrls: ['./enrolled-patient-care-plan.component.scss']
})
export class EnrolledPatientCarePlanComponent implements OnInit {
  public selectedTemplate: Template;
  public selectedRichTextTemplate: RichTextTemplate;
  @Input() program: Program;
  @Input() patient: Patient;
  @Input() programEnrollment: ProgramEnrollment;
  custom_injector: Injector;
  public enoteComponent: any = EnoteComponent;
  public patientNoteComponent: any = PatientNoteComponent;
  public show_enote = false;
  public stage: string;
  public note_id: any;
  componentToRender: any;
  totalItems: any;
  programCarePlans: any[] = [];
  public currentPage = 1;

  constructor(public enoteService: EnoteService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService, public programService: ProgramService,
              public injector: Injector) { }

  ngOnInit(): void {
    this.getProgramCarePlans();
    // this.getProgramTemplate();
  }
  getProgramTemplate() {
    this.loaderService.show();
    this.programService.getProgramCarePlan(this.program.id, this.patient.id).then(resp => {
      this.selectedTemplate = new Template().load_from_json(resp);
      if (this.selectedTemplate.id)  {
        this.componentToRender = this.enoteComponent;
        this.setSelectedTemplate(this.selectedTemplate);
      } else {
        this.stage = 'create_enote';
        this.componentToRender = this.patientNoteComponent;
        this.setCustomInjectorForCarePlan();
        this.show_enote = true;
        this.loaderService.hide();
      }
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });

  }

  setCustomInjector(programCarePlan) {
    this.custom_injector  = Injector.create([
      {provide: 'template', useValue: programCarePlan.id},
      {provide: 'stage', useValue: programCarePlan.stage},
      {provide: 'source_id', useValue: programCarePlan.source_id},
      {provide: 'source_type', useValue: 'ProgrammeEnrolment'},
      {provide: 'isProgramOrPatientProfile', useValue: true},
      {provide: 'patient_name', useValue: this.patient ? this.patient.name : ''},
      {provide: 'patient_id', useValue: this.patient.id},
      {provide: 'doctor_id', useValue: this.program.care_manager_doctor_id},
    ], this.injector);
  }

  setSelectedTemplate(selectedTemplate) {
    this.loaderService.show();
    this.enoteService.getTemplate(selectedTemplate.id).then(resp => {
      this.selectedTemplate = this.enoteService.template;
      this.setCustomInjector(this.selectedTemplate);
      this.show_enote = true;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  setCustomInjectorForCarePlan() {
    this.custom_injector  = Injector.create([
      {provide: 'patientID', useValue: this.patient.id},
      {provide: 'source_type', useValue: 'ProgrammeEnrolment'},
      {provide: 'source_id', useValue: this.programEnrollment.id},
      {provide: 'stage', useValue: this.stage},
      {provide: 'note_id', useValue: this.note_id},
      {provide: 'isProgramOrPatientProfile', useValue: true}
    ], this.injector);
  }

  addCarePlan() {
    this.selectedTemplate = new Template()
    this.stage = 'create_enote';
    this.componentToRender = this.patientNoteComponent;
    this.setCustomInjectorForCarePlan();
    this.show_enote = true;
  }

  viewListOfCarePlans() {
    this.getProgramCarePlans();
    this.selectedTemplate = undefined;
    this.selectedRichTextTemplate = undefined;
    this.show_enote = false;
  }

  pageChanged(page: PageChangedEvent) {
    this.loaderService.show();
    this.currentPage = page.page
    this.getProgramCarePlans();

  }
  getProgramCarePlans() {
    this.programService.getProgramCarePlans(this.program.id, this.patient.id, this.currentPage).then(resp => {
      this.programCarePlans = resp.care_plans.map(p => {
        if (p.is_rich_text) {
          return new RichTextTemplate().load_from_json(p);
        } else {
          return  new Template().load_from_json(p);
        }
      });
      console.log(this.program.enrolledPatients);
      this.totalItems = resp.total;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  viewCarePlan(carePlan: any) {
    this.componentToRender = this.enoteComponent;
    this.setSelectedTemplate(carePlan);
  }

  viewRichText(richText: any) {
    this.selectedRichTextTemplate = new RichTextTemplate().load_from_json(richText);
    this.componentToRender = this.enoteComponent;
    this.enoteService.template = new Template();
    this.setCustomInjectorForRichText(richText);
    this.show_enote = true;
  }

  setCustomInjectorForRichText(richText) {
    this.custom_injector  = Injector.create([
      {provide: 'template', useValue: undefined},
      {provide: 'stage', useValue: 'richText'},
      {provide: 'source_id', useValue: richText.source_id},
      {provide: 'source_type', useValue: 'ProgrammeEnrolment'},
      {provide: 'isProgramOrPatientProfile', useValue: true},
      {provide: 'patient_name', useValue: this.patient ? this.patient.name : ''},
      {provide: 'patient_id', useValue: this.patient.id},
      {provide: 'doctor_id', useValue: this.program.care_manager_doctor_id},
      {provide: 'is_rich_text', useValue: true},
      {provide: 'selectedRichTextTemplate', useValue: richText},
      {provide: 'viewRichText', useValue: true}
    ], this.injector);
  }
}
