<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Remote Patient Monitoring</h1>
      <h4 class="sub-title fs22 text-primary">Create and manage RPM programs and enhance your revenue bottom-line.</h4>
      <h4 class="sub-title">
        Remote patient monitoring (RPM) allows healthcare providers to monitor patients outside of the clinical setting.
        <br><br>
        Particularly important for the management of chronic diseases, RPM is helping improve patient outcomes, ease of
        care access and reduce healthcare delivery costs.
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad bg-white">
  <div class="container">
    <p class="text-center">
      Since healthcare industry is transforming day by day, we aim to provide the market’s most complete, patient-centered,
      and holistically connected care platform. Remote Patient Monitoring system is designed to allow doctors and medical
      team monitor multiple patients efficiently and get better control of their chronic conditions.
    </p>
  </div>
  <div class="home-laptop"></div>
</section>
<section class="sec-pad ">
  <div class="container">
    <div class="row">
      <div class="col-sm-4 col-xs-12">
        <div class="white-container-box sec-pad text-center p-lg blue-line">
          <h1 class="fs40 mb0"><i class="fa icon-screen-smartphone"></i></h1>
          <h3 class="text-black">4G Connected Medical Devices</h3>
          <p>
            Cell-based and Bluetooth-connected device kits (e.g., scales, BG and BP monitors, etc.) allow patients to
            measure vital statistics at home.
          </p>
        </div>
      </div>
      <div class="col-sm-4 col-xs-12">
        <div class="white-container-box sec-pad text-center p-lg blue-line">
          <h1 class="fs40 mb0"><i class="fa icon-people"></i></h1>
          <h3 class="text-black">User Friendly Experience</h3>
          <p>
            Wellwink offers patient portal a simple, intuitive and efficient system specifically designed for elderly
            patients, a senior- friendly platform.
          </p>
        </div>
      </div>
      <div class="col-sm-4 col-xs-12">
        <div class="white-container-box sec-pad text-center p-lg blue-line">
          <h1 class="fs40 mb0"><i class="fa icon-envelope"></i></h1>
          <h3 class="text-black">2 Way Messaging</h3>
          <p>Secure 2-way communication between providers & patients via text message, built-in instant message, email
            and video calls. Send automated clinical alerts to patients.</p>
        </div>
      </div>
    </div>
  </div>
</section>

