<div class="row">
  <div class="col-md-6 col-sm-12 col-xs-12 intake-col-full">
    <ng-container *ngIf="(intakeForm && summary_of === 'problems') || !intakeForm">
      <div class="clearfix mt mb flex-status">
        <span>
          <i class="fa icon-home-man-ill fs26 mr-sm"></i>
          <h4 class="mr-sm" *ngIf="problems_status !== 'all'">{{problems_status}}</h4>
          <h4>Problems</h4>
          <div class="btn-group" dropdown>
            <button type="button" class="btn btn-link dropdown-toggle fs16 pt0 pb0" dropdownToggle>
              <i class="fa fa-caret-down"></i>
            </button>
            <ul class="dropdown-menu" *dropdownMenu>
              <li (click)="patientSummaryData('problems', 'all')"><a>All</a></li>
              <li (click)="patientSummaryData('problems','current')"><a>Active</a></li>
              <li (click)="patientSummaryData('problems','past')"><a>Resolved</a></li>
            </ul>
          </div>
        </span>
        <button class="btn btn-secondary btn-outline btn-sm fs12 pt0 pb0" type="button" (click)="medicationSummaryComponent.getControls('problems')">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <ul class="list-group m0" *ngIf="patient_summary['problems'].length == 0">
        <li class="list-group-item disabled p"><span class="text-gray-dark">You have no {{problems_status === 'all' ? '' : problems_status  }} problems added.</span></li>
      </ul>
      <ul class="border-list list-unstyled mb-lg" *ngIf="patient_summary['problems'].length > 0">
        <ng-container *ngFor="let term of patient_summary['problems']; let index=index">
          <li class="list summary-tab-list" *ngIf="!term?.isComponentTypeChild() && term?.control_value_class != 'absent'">
            <a (click)="medicationSummaryComponent.openUpdateTermStatusModel(term,'problems')">
              <app-note-summary [term]="term" [term_index]="index" class="d-block" [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-note-summary>
            </a>
          </li>
        </ng-container>
      </ul>
    </ng-container>


    <ng-container *ngIf="(intakeForm && summary_of === 'allergies') || !intakeForm">
      <div class="clearfix mt mb flex-status">
        <span>
          <a class="fs18 pull-right hidden"><i class="fa icon-plus"></i></a>
          <i class="fa icon-home-man-ill fs26 mr-sm"></i>
          <h4 class="mr-sm" *ngIf="allergies_status !== 'all'">{{allergies_status}}</h4>
          <h4>Allergies</h4>
          <div class="btn-group" dropdown>
            <button type="button" class="btn btn-link dropdown-toggle fs16 pt0 pb0" dropdownToggle><i
              class="fa fa-caret-down"></i></button>
            <ul class="dropdown-menu" *dropdownMenu>
              <li (click)="patientSummaryData('allergies', 'all')"><a>All</a></li>
              <li (click)="patientSummaryData('allergies', 'current')"><a>Active</a></li>
              <li (click)="patientSummaryData('allergies', 'past')"><a>Resolved</a></li>
            </ul>
          </div>
        </span>
        <button type="button" class="btn btn-secondary btn-outline btn-sm fs12 pt0 pb0" (click)="medicationSummaryComponent.getControls('allergies')"><i class="fa fa-plus"></i></button>
      </div>
      <ul class="list-group m0" *ngIf="patient_summary['allergies'].length == 0">
        <li class="list-group-item disabled p"><span class="text-gray-dark">You have no {{allergies_status === 'all' ? '' : allergies_status  }} allergies added.</span></li>
      </ul>
      <ul class="border-list list-unstyled" *ngIf="patient_summary['allergies'].length > 0">
        <ng-container *ngFor="let term of patient_summary['allergies'];let index=index">
          <li class="list summary-tab-list" *ngIf="!term?.isComponentTypeChild() && term?.control_value_class != 'absent'">
            <a (click)="medicationSummaryComponent.openUpdateTermStatusModel(term,'allergies')">
              <app-note-summary [term]="term" [term_index]="index" class="d-block" [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-note-summary>
            </a>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>

  <div class="col-md-6 col-sm-12 col-xs-12 intake-col-full">
    <ng-container *ngIf="(intakeForm && summary_of === 'medications') || !intakeForm">
      <div class="clearfix mt mb flex-status">
        <span>
          <i class="fa icon-home-medical-history fs26 mr-sm"></i>
          <h4 class="mr-sm" *ngIf="medications_status !== 'all'">{{medications_status}}</h4>
          <h4>Medications</h4>
          <div class="btn-group" dropdown>
            <button type="button" class="btn btn-link dropdown-toggle fs16 pt0 pb0" dropdownToggle>
              <i class="fa fa-caret-down"></i>
            </button>
            <ul class="dropdown-menu" *dropdownMenu>
              <li (click)="patientSummaryData('medications', 'all')"><a>All</a></li>
              <li (click)="patientSummaryData('medications','current')"><a>Current</a></li>
              <li (click)="patientSummaryData('medications','past')"><a>Past</a></li>
            </ul>
          </div>
        </span>
        <button type="button" class="btn btn-secondary btn-outline btn-sm fs12 pt0 pb0" (click)="medicationSummaryComponent.getControls('medications')"><i class="fa fa-plus"></i></button>
      </div>
      <ul class="list-group m0" *ngIf="patient_summary['medications'].length == 0">
        <li class="list-group-item disabled p"><span class="text-gray-dark">You have no {{medications_status === 'all' ? '' : medications_status  }} medications added.</span></li>
      </ul>
      <ul class="border-list list-unstyled" *ngIf="patient_summary['medications'].length > 0">
        <ng-container *ngFor="let term of patient_summary['medications'];let index=index">
          <li class="list summary-tab-list"  *ngIf="!term.isComponentTypeChild() && term.control_value_class != 'absent'">
            <a (click)="medicationSummaryComponent.openUpdateTermStatusModel(term,'medications')">
              <app-note-summary [term]="term" [term_index]="index" class="d-block" [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-note-summary>
            </a>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</div>


<app-medication-summary #medicationSummaryComponent (getPatientSummaryEmitter)="patientSummaryData('all', 'current')" [patient_id]="patient.id"></app-medication-summary>
