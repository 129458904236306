<div class="filters-sec flex-header">
  <div class="row">
    <div class="col-xs-3">
      <input class="form-control" [(ngModel)]="filter['name']" (keyup.enter)="search()" placeholder="Name">
    </div>
    <div class="col-md-7 col-xs-5">
      <button type="button" class="btn btn-tertiary btn-outline btm-min" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      <button type="button" class="btn btn-secondary btn-min ml" (click)="addIntakeFormTemplate()" >
        <i class="fa fa-plus mr-sm"></i> Add
      </button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Name</th>
      <th>Owner</th>
      <th>Created On</th>
      <th>Last Modified By</th>
      <th>Last Modified On</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let template of intakeFormTemplates">
      <td><a (click)="viewIntakeFormTemplate(template)">{{template.name}}</a></td>
      <td class="text-capitalize">{{template.creator_name}}</td>
      <td>{{template.created_at | date:'MMMM d, y, h:mm a'}}</td>
      <td class="text-capitalize">{{template.updater_name}}</td>
      <td>{{template.last_updated_at | date:'MMMM d, y, h:mm a'}}</td>
    </tr>
    </tbody>
  </table>
  <app-no-result-found *ngIf="intakeFormTemplates && intakeFormTemplates.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center" *ngIf="intakeFormTemplates && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
