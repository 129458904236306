import {Component, OnInit, ViewChild} from '@angular/core';
import {DoctorService} from '../../services/doctor.service';
import {Doctor, DoctorAchievement} from '../../routes/practices/doctors/doctor';
import {Params, ActivatedRoute, Router} from '@angular/router';
import {LineLayout, PlainGalleryConfig, PlainGalleryStrategy} from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss'],
})
export class HealthPortalDoctorComponent implements OnInit {
  public doctor: Doctor = new Doctor();
  public max = 10;
  public rate = 3;
  public isReadonly = true;
  public show_all = false;
  map_lat: number;
  map_long: number;
  openModalWindow = false;
  imagePointer: number;

  center: google.maps.LatLngLiteral;
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];

  public  MAPPINGS = {
    query: '', q: '', specialty_id: '', page: '',
    lat: '', long: '', service_id: '', gender: '', shift: '',
    radius: '', sort_by: '', 'term': '', illness_term: '', area_text: '', area: ''
  };
  plainGalleryRow: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.ROW,
    layout: new LineLayout({ width: '100%', height: '100%' }, { length: 1, wrap: true }, 'flex-start')
  };
  plainGalleryRow1: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.ROW,
    layout: new LineLayout({ width: '80px', height: '80px' }, { length: 3, wrap: true }, 'flex-start')
  };
  constructor(private doctorService: DoctorService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) =>  {this.fetchDoctor(+params['id'])})
    const mapProperties = {
      center: new google.maps.LatLng(35.2271, -80.8431),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

  }
  fetchDoctor(id) {
    this.doctorService.getDoctor(id).then(json => {
      this.doctor = this.doctor.load_from_json(json);
      this.doctor.doctor_pics = this.doctor.doctor_pics.filter(e => e.modal.description != 'profile_pic');
      this.doctor.degrees = this.doctor.achievement_categories_array.filter(x => x.name === 'Degree')[0].doctors_achievements;
      this.doctor.achievement_categories_array = this.doctor.achievement_categories_array.filter(x => x.name != 'Degree');
      this.set_default_lat_long();
    });
  }
  openImageModal(imageSrc, images) {
    let imageModalPointer;
    for (let i = 0; i < images.length; i++) {
      if (imageSrc === images[i].modal.img) {
        imageModalPointer = i;
        break;
      }
    }

    this.openModalWindow = true;
    this.doctor.doctor_pics = images;
    this.imagePointer = imageModalPointer;
  }

  cancelImageModal() {
    this.openModalWindow = false;
  }

  showImageModal() {
    this.openModalWindow = true;
  }
  show_all_reviews() {
    this.show_all = !this.show_all;
  }
  handle_practice_request(event) {

  }

  set_default_lat_long() {
      this.doctor.physician.locations.forEach(loc => {
        this.addMarker(loc);
      });
      if (this.markerPositions.length > 0) {
        this.center = this.markerPositions[0]
      }

    // this.center = this.markerPositions.reduce((max, game) => (max.lat > game.lat && max.lng > game.lng)  ? max : game);
  }

  addMarker(loc) {
    this.addMarkers({
      lat: loc.lat,
      lng: loc.long})

  }
  backToListing() {
    this.router.navigate(['search'], { queryParams: this.search_params});
  }

  get search_params() {
    this.route.queryParams.subscribe(params => {
      Object.keys(this.MAPPINGS).forEach(key => {
        if (params[key]) {
          this.MAPPINGS[key] = params[key];
        }
      });
      window.scrollTo(0, 0)
    });
    return this.MAPPINGS;
  }

  calculateExperience(doctor_achievement) {
    return doctor_achievement.convertToDates(doctor_achievement.start_date, doctor_achievement.start_month, doctor_achievement.end_date, doctor_achievement.end_month)
  }

  addMarkers(pos) {
    this.markerPositions.push(pos);
  }
}
