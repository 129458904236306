<ng-container *ngIf="conversation">
  <div class="header" *ngIf="smsService.selected_conversation">
    <div class="pic-name-sec">
      <div class="flex-grow">
        <div class="pic-holder pointer"><img [src]="conversation.thumbnail"/></div>
        <div class="grow p0 pl">
          <h4 class="name m0 pointer">
            <a [title]="conversation.title" class="text-black" href="javascript:" (click)="add_to_tabs(conversation.patient)">
              {{conversation.title}}
              <small *ngIf="conversation.patient.customize_age"> - {{conversation.patient.customize_age}}</small>
            </a>
          </h4>
          <h6 class="online-status m0 mt-sm" *ngIf="conversation.patient.phone">
            <a href="javascript:void(0);" class="h6 m0 text-black" (click)="phoneService.initiatePhoneCall(conversation.patient.phone,
          {name: conversation.title, pic: conversation.thumbnail})">
              <i class="fa icon-screen-smartphone vl-m"></i> {{conversation.patient.phone | mask: ' (000) 000-0000'}}
            </a>
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-thread scroll-browser messages-container" #scrollMe (scroll)="handleThreadScroll($event)">
    <div class="chat-fixed-date" [hidden]="!show_top_date">
      <span *ngIf="first_message">{{center_dates}}</span>
    </div>
    <h5 *ngIf="conversation.load_in_progress" class="text-center mt0">Loading messages...</h5>
    <ul class="p0">
      <ng-container *ngFor="let message of chatroom_messages();let i = index">
        <li class="clearfix message" [class.in_progress]="message.in_process" #message_box [attr.message-id]="message.id">
          <div class="message-date" *ngIf="i == 0 || chatroom_messages()[i-1].created_at_date_stamp != message.created_at_date_stamp">
            <span>{{message.center_date}}</span>
          </div>
          <div class="bubble" [ngClass]="applySenderRecieverClass(message.sms_type,message)">
            <div class="message sender text-capitalize">
              <span>{{message.name}}</span> <span *ngIf="message.sms_type === 'outgoing'"> - {{message.profileable_type}} </span>
            </div>

            <ng-container *ngIf="message.body">
              <div class="text" [innerHTML]="renderedHTML(message.body)"></div>
            </ng-container>

            <span class="date_time">{{message.created_at | date: 'shortTime'}}</span>
          </div>
        </li>
        <ng-container *ngIf="conversation.lastReadMessageIndex == i && i + 1 < conversation.sms_messages.length">
          <li #unread_label class="text-center text-primary">
            <h6 class="m0 fw-500">{{conversation.last_unread_count}} Unread Message(s)</h6>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
  <div class="footer">
    <div class="grid-grow">
      <textarea  #s_body type="text" class="form-control scroll-browser"
                 [(ngModel)]="sms_message.body" (enter_pressed)="submit()"
                 onInput="this.parentNode.dataset.replicatedValue = this.value"
                 name="sms_body" required>
      </textarea>
      <button class="btn btn-secondary col-xs-12" type="submit" [disabled]="sms_message.body.length == 0" (click)="submit()"><i class="fa fa-paper-plane-o"></i></button>
    </div>
  </div>
</ng-container>
