import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RichTextTemplate} from '../rich-text-template';

@Component({
  selector: 'app-enote-rich-text-template',
  templateUrl: './enote-rich-text-template.component.html',
  styleUrls: ['./enote-rich-text-template.component.scss']
})
export class EnoteRichTextTemplateComponent implements OnInit {

  @Output() enoteTemplateClick: EventEmitter<RichTextTemplate> = new EventEmitter<RichTextTemplate>();

  constructor() { }

  ngOnInit(): void {
  }

  openEnoteRichTextTemplate(template: RichTextTemplate) {
    this.enoteTemplateClick.emit(template)
  }
}
