import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Globals } from '../globals';
import {DocumentsService} from '../services/document.service';
import {VideoChatService} from '../video-chat/services/videochat.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {

  constructor(private _tokenService: HttpClient, private globals: Globals, public document_service: DocumentsService,
              public videoChatService: VideoChatService) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    console.log('layout dest called........');
  }

}
