import {HttpParams} from "@angular/common/http";
import {CustomHttpClient} from "../services/custom-http-client.service";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class AddressesService {

  constructor(private _tokenService: CustomHttpClient) { }
  cities(name: string): Observable<any> {
    let args = new HttpParams();
    args = args.set('name', name);
    return this._tokenService.get('addresses/cities', {params: args})
      .map((resp) => resp);
  }

  states(name: string): Observable<any> {
    let args = new HttpParams();
    args = args.set('name', name);
    return this._tokenService.get('addresses/states', {params: args})
      .map((resp) => resp);
  }

  zip_codes(zip_code: string): Observable<any> {
    let args = new HttpParams();
    args = args.set('zip_code', zip_code);
    return this._tokenService.get('addresses/zip_codes', {params: args})
      .map((resp) => resp);
  }
}
