<form (ngSubmit)="saveAdditionalInfo()" #addForm="ngForm">
  <div class="row">
    <div class="col-sm-6 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Guardian name</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <input type="text" class="form-control" [(ngModel)]="additional_info.guardian_name" name="guardian_name" pattern="^[a-zA-Z ]+$" />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Relation with patient</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.relation_with_patient" name="relation_with_patient">
            <option value="father">Father</option>
            <option value="brother">Brother</option>
            <option value="uncle">Uncle</option>
            <option value="friend">Friend</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">CNIC</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <input type="text" class="form-control" [(ngModel)]="additional_info.cnic" name="cnic"/>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Qualification</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <input type="text" class="form-control" [(ngModel)]="additional_info.qualification" name="qualification" pattern="^[a-zA-Z ]+$"/>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Occupation</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <input type="text" class="form-control" [(ngModel)]="additional_info.occupation" name="occupation" pattern="^[a-zA-Z ]+$"/>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Guardian Passport</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.guardian_passport" name="guardian_passport">
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Mother</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <input type="text" class="form-control" [(ngModel)]="additional_info.mother_name" name="mother_name" pattern="^[a-zA-Z ]+$"/>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Profession</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <input type="text" class="form-control" [(ngModel)]="additional_info.profession" name="profession" pattern="^[a-zA-Z ]+$"/>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Mother Passport</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.mother_passport" name="mother_passport">
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Brothers</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.brothers" name="brothers">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Sisters</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.sisters" name="sisters">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Religion</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.religion" name="religion">
            <option value="muslim(non-syed)">Muslim(non-syed)</option>
            <option value="muslim(syed)">Muslim(syed)</option>
            <option value="non-muslim">Non-muslim</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <h4 class="text-primary">Socio Economic Information</h4>
  <hr class="hr-line mt0">
  <div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Family Income</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <input type="text" class="form-control" [(ngModel)]="additional_info.family_income" name="family_income" pattern="\d*"/>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">House</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.house" name="house">
            <option value="own">Own</option>
            <option value="rented">Rented</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Eligible for</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.eligible_for" name="eligible_for">
            <option value="zakat">Zakat</option>
            <option value="donation">Donation</option>
            <option value="loan">Loan</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Arrange Funds</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.arrange_funds_for" name="arrange_funds_for">
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Volunteer for PCHF</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.volunteer_for_pchf" name="volunteer_for_pchf">
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 col-xs-12 form-group">
      <div class="row">
        <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Available for Marketing</label>
        <div class="col-md-8 col-sm-12 col-xs-12">
          <select class="form-control" [(ngModel)]="additional_info.availability_for_marketing" name="availability_for_marketing">
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mb-xl">
    <button type="submit" class="btn btn-primary btn-lg" [disabled]="!addForm.form.valid">Save</button>
  </div>

  <div *ngIf="messageAfterSuccessSave" class="text-center text-success fw-500">Additional Info has been saved</div>
  <div *ngIf="messageAfterSave" class="text-center text-danger fw-500">Sorry!! Additional Info cannot be saved</div>


  <h4 class="text-primary">Documents & Videos</h4>
  <hr class="hr-line mt0">
  <div class="row form-group">
    <label class="col-md-2 col-sm-3 col-xs-12 pt-sm">Upload</label>
    <div class="col-md-10 col-sm-9">
      <a class="btn btn-primary btn-outline" (click)="show_attachment_popup= true"><i class="fa fa-file-text-o"></i> &nbsp;Choose File</a>
    </div>
  </div>
  <app-attachments (document_uploaded)="handle_file_upload($event)"
                   (popup_closed)="show_attachment_popup= false" [fileType]="'pchf_files'"
                   [only_modal]="true" [attachable_id]="patient.id" [attachable_type]="'Patient'"
                   [show]="show_attachment_popup"></app-attachments>
</form>

<ng-container *ngIf="video_documents && video_documents.length > 0">
  <div class="row mt-xl">
    <label class="col-md-2 col-sm-3 col-xs-12 pt-sm fw-500">Videos</label>
    <div class="col-md-10 col-sm-9">
      <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-12 form-group" *ngFor="let video of video_documents">
          <div class="panel panel-default m0">
            <div class="panel-body p-sm">
              <a class="pull-right text-danger btn-icon" (click)="delete_document(video)"><i class="fa fa-trash"></i></a>
              <a class="wrap-text" href="javascript:void(0)" (click)="show_video_popup(video);classicModal.show()">{{video.document_file_name}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="documents && documents.length > 0">
  <div class="row">
    <label class="col-md-2 col-sm-3 col-xs-12 pt-sm fw-500">Documents</label>
    <div class="col-md-10 col-sm-9">
      <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-12 form-group" *ngFor="let document of documents">
          <div class="panel panel-default m0">
            <div class="panel-body p-sm">
              <a class="pull-right text-danger btn-icon" href="javascript:void(0)" (click)="delete_document(document)"><i class="fa fa-trash"></i></a>
              <a class="wrap-text" href="javascript:void(0)" (click)="goto_document(document)">{{document.document_file_name}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:480px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="stop_player();classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Videos</h4>
      </div>
      <div class="modal-body">
        <video class="img-responsive" controls (click)="toggleVideo()" [src]="document_url" #videoPlayer></video>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-inverse" (click)="stop_player();classicModal.hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<div [hidden]="timeline_active()">
  <app-document-viewer (closed)="preview_closed()" [active]="!timeline_active()"></app-document-viewer>
</div>

