import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../services/tabs.service';
import {PatientProfileComponent} from '../../health/patient-profile/patient-profile.component';
import {Tab} from '../../tabs/tab';
import {Appointment} from '../../health/models/appointment';
import {ActivatedRoute, Params} from '@angular/router';
import {PatientService} from '../../services/patient.service';
import {Patient} from '../../health/doctor/patient';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit {
  public patient: Patient;
  constructor(public tabs_service: TabsService, private route: ActivatedRoute, private patientService: PatientService) {
    this.tabs_service.register('patients');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      let patientId = params['id'];
      console.log(patientId)
      if (patientId) {
        this.getPatient(patientId);
      }
    });

  }
  getPatient(id) {
    this.patientService.getPatient(+id).then(resp => {
      this.patient = new Patient().load_from_json(resp);
      this.add_to_tabs(this.patient);
    })
  }
  add_to_tabs(patient) {
    /*
        pass appointment object to check whether we are opening patient panel through dynamic component or router.
        // tslint:disable-next-line:max-line-length
        Appointment object is flag for that check.Not good to create dummy object but as we had already implemented this logic in appointments module
        where appointment was avaiable so we do same.Best is to replace object with some flag variable.
    */
    let title = patient.id ? patient.name : 'Add';
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, patient.id, {id: patient.id, appointment: new Appointment}))
  }
}
