import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from '../../../../services/message.service';
import {LoaderService} from '../../../../services/loader.service';

@Component({
  selector: 'app-enrolled-patient-chat',
  templateUrl: './enrolled-patient-chat.component.html',
  styleUrls: ['./enrolled-patient-chat.component.scss']
})
export class EnrolledPatientChatComponent implements OnInit {
  @Input() patient
  public show_im = '';

  constructor(public messageService: MessageService, public loaderService: LoaderService) {
  }

  ngOnInit(): void {
    // this.messageService.selected_chatroom = null;
    if (this.patient.can_login) {
      this.loaderService.show();
      this.messageService.initializeChatrooms().then(resp => {
        this.messageService.set_user_on_selection(this.patient.user.id);
        this.toggleChat();
        this.loaderService.hide();
      }).catch(resp => {
        console.log(resp);
        this.loaderService.hide();
      })
    } else {
      this.show_im = 'This patient has not been signed up for the patient portal or mobile app.';
    }
  }

  toggleChat() {
    this.messageService.scrollToBottom.next(this.messageService.selected_chatroom);
    if (this.messageService.selected_chatroom) {
      this.messageService.restartUnreadCount(this.messageService.selected_chatroom.id, true)
    }
  }



}
