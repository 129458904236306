import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Injector,
  OnDestroy
} from '@angular/core';
// @ts-ignore
import * as jsPDF from 'jspdf';
import {VitalsService} from '../../../../services/vitals.service';
import {Appointment} from '../../../../health/models/appointment';
import {PatientVital} from '../PatientVital';
import {LoaderService} from '../../../../services/loader.service';
import {Patient} from '../../../../health/doctor/patient';
import {EnoteComponent} from '../../../../enote/enote/enote.component';
import {AttachmentService} from '../../../../services/attachments.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {PatientDocument} from '../../../../health/patient-profile/patient-dashboard/chart/document';
import {DocumentsService} from '../../../../services/document.service';
import {AssociateCarePlanWithChartService} from '../../todays-patients/associate-care-plan-with-chart.service';
import html2canvas from 'html2canvas';
import {RichTextTemplate} from '../../../rich-text-template-index/rich-text-template';
import {TabsService} from '../../../../services/tabs.service';

@Component({
  selector: 'app-vital-pdf-printer',
  templateUrl: './vital-pdf-printer.component.html',
  styleUrls: ['./vital-pdf-printer.component.scss']
})
export class VitalPdfPrinterComponent implements OnInit, OnDestroy {
  @ViewChild('vital', { static: false }) vital: ElementRef | undefined;
  public vital_print_data;
  public patient: Patient = new Patient();
  public appointment: Appointment;
  public patientVital: PatientVital;
  public today: Date = new Date();
  public enotePrinter = false;
  public enoteComponent: any = EnoteComponent;
  public custom_injector: Injector;
  public templateID: any;
  public age_gender: any;
  public source_type: any;
  public source_id: any;
  public patient_id: any;
  public template_name: any;
  public document: PatientDocument = new PatientDocument();
  public doctor_id: any;
  public is_rich_text: boolean;
  public selectedTemplate: RichTextTemplate;
  public patientVitalId: any;
  public is_added_to_chart: boolean;
  public isLoading: boolean;
  public templateType: any;
  constructor(private vitalService: VitalsService, private injector: Injector, private globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService, private attachmentService: AttachmentService,
              public documentsService: DocumentsService, public tabsService: TabsService,
              public carePlanWithChart:  AssociateCarePlanWithChartService) { }


  ngOnInit() {
    this.loaderService.show();
    this.source_type = this.injector.get('source_type', null);
    this.source_id = this.injector.get('source_id', null);
    this.patient_id = this.injector.get('patient_id', null);
    this.doctor_id = this.injector.get('doctor_id', null);
    this.patientVitalId = this.injector.get('patientVitalId', null);
    this.enotePrinter = this.injector.get('enotePrinter', null);
    this.templateID = this.injector.get('templateID', null);
    this.is_rich_text = this.injector.get('is_rich_text', null);
    this.selectedTemplate = this.injector.get('selectedTemplate', null);
    this.template_name = this.injector.get('template_name', null);
    this.is_added_to_chart = this.injector.get('is_added_to_chart', null);
    this.templateType = this.injector.get('templateType', null);
    if (this.is_rich_text) {
      this.enotePrinter = false;
    }

    if (this.enotePrinter) {
      this.setCustomInjector();
    }

    this.vitalService.getVitalPrintData(this.patient_id, this.patientVitalId, this.source_type === 'Appointment' ? this.source_id : undefined)
      .then(resp => {
        // this.patientVital.age_gender = '';
        this.vital_print_data = resp.print_data;
        this.age_gender = this.patient.calculate_age(resp.print_data.patient_dob, resp.print_data.dob_stage);
        if (resp.print_data.patient_vital) {
          this.patientVital = new PatientVital().load_from_json(resp.print_data.patient_vital);
        }
        this.loaderService.hide();
      }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService = resp.error.error;
    });
  }


  generatePdf(action) {
    this.loaderService.show();
    this.vital.nativeElement.style.height = this.vital.nativeElement.style.offsetHeight;
    html2canvas(this.vital.nativeElement, {
      width: this.vital.nativeElement.offsetWidth,
      height: this.vital.nativeElement.offsetHeight})
      .then((canvas) => {
        let imgData = canvas.toDataURL('image/png');
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let doc = new jsPDF('p', 'mm');
        let position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        if (action === 'print') {
          doc.autoPrint();
          window.open(doc.output('bloburl'), '_blank');
        } else {
          doc.save( 'file.pdf');
        }
        this.loaderService.hide();
      });
  }

  setCustomInjector() {
    this.custom_injector  = Injector.create([{provide: 'template', useValue: this.templateID},
        {provide: 'source_id', useValue: this.source_id}, {provide: 'stage', useValue: 'print_enote'}],
      this.injector);
  }

  callAssociateWithMedicalChartMethod() {
    console.log('Associate with Start');
    this.isLoading = true;
    console.log('isLoading', this.isLoading);
    this.loaderService.show();

    // Offload the heavy task to a background thread using setTimeout
    setTimeout(() => {
      this.associateWithMedicalChart();
    }, 0);
  }

  async associateWithMedicalChart() {
    console.log('setParams-------');
    let date = this.source_type === 'Appointment' ? this.vital_print_data['appointment_date_in_time_zone'] : new Date();
    let params = {
      fileType: 'document', title: this.template_name, description: 'Care Plan', template: this.vital, patient_id: this.patient_id,
      doctor_id: this.doctor_id, attachable_type: 'Document', document_type: 'Care Plan', attachable_id: 0, date: date,
      sourceable_type: this.setSourceType(), sourceable_id: this.setSourceId()
    };
    console.log('Params-------', params);

    try {
      await this.carePlanWithChart.associateToMedicalChart(params);

      // Operation completed successfully
      this.isLoading = false;
      this.is_added_to_chart = true;
      console.log('end of params-------');
    } catch (error) {
      // Handle errors from the associateToMedicalChart operation
      console.error('Error associating to medical chart:', error);
    }
  }

  setSourceId(): number {
    if (this.templateType === 'richText' && this.is_rich_text) {
      return this.selectedTemplate?.id; // Assuming a default value of 0
    } else if (this.templateType === 'patientVital') {
      return this.patientVitalId; // Assuming a default value of 0
    } else {
      return this.templateID; // Assuming a default value of 0
    }
  }

  setSourceType(): string {
    switch (this.templateType) {
      case 'richText':
        return this.is_rich_text ? 'TextTemplate' : '';
      case 'patientVital':
        return 'PatientVital';
      case 'enote':
        return 'Enote::Template';
      default:
        return '';
    }
  }

  ngOnDestroy(): void {
    const activeTab = this.tabsService.tabs.find(
      (a) =>
        a.tab_data.templateID &&
        a.tab_data.templateID === this.setSourceId()
    );
    if (activeTab && activeTab.dependencies) {
      activeTab.dependencies.is_added_to_chart = this.is_added_to_chart;
    }
  }

}
