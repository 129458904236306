export class SMSMessage {
  id: number;
  conversation_id: any;
  name: string;
  profileable_id: any;
  profileable_type: any;
  body = '';
  sms_type: string;
  owner_id: any;
  status: string;
  created_at: any;
  created_at_date_stamp: any;
  center_date: any;
  in_process = false;

  isTextType() {
    return this.sms_type === 'text';
  }

  isSystemType() {
    return this.sms_type == 'system';
  }

  to_params() {
    return {
      body: this.body,
      id: this.id,
      sms_type: this.sms_type,
      owner_id: this.owner_id,
      conversation_id: this.conversation_id,
      status: this.status
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.body = json.body;
    this.name = json.name;
    this.owner_id = json.owner_id;
    this.sms_type = json.sms_type;
    this.profileable_id = json.profileable_id;
    this.profileable_type = json.profileable_type;
    this.status = json.status;
    this.conversation_id = json.conversation_id;
    this.created_at = new Date(json.created_at);
    let date = new Date(this.created_at);
    date.setHours(0, 0, 0, 0);
    this.created_at_date_stamp =  date.getTime();
    this.center_date = this.renderDate(this.created_at);

    return this;
  }

  renderDate(date) {
    date ? date : new Date;
    const today = new Date;
    const yesterday = new Date; yesterday.setDate(today.getDate() - 1)
    if (date.toLocaleDateString() == today.toLocaleDateString()) {
      return 'Today'
    } else if (date.toLocaleDateString() == yesterday.toLocaleDateString()) {
      return 'Yesterday'
    }
    return date.toLocaleDateString('en-US', {
      day : 'numeric',
      month : 'long',
      year: 'numeric'
    })
  }


}
