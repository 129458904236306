<div class="filters-sec flex-header" *ngIf="!isEnoteTemplate">
  <div class="row">
    <div class="col-xs-3">
      <input class="form-control" [(ngModel)]="filter['token']"  placeholder="Name" (keyup.enter)="search()">
    </div>
    <div class="col-xs-2">
      <select [(ngModel)]="filter['type']" class="form-control">
        <option value=undefined selected>Type</option>
        <option *ngFor="let type of richTextTemplateService.rich_text_types; let i = index;" [value]="type">
          {{userService.removeUnderScore(type)}}
        </option>
      </select>
    </div>
    <div class="col-xs-1">
      <select [(ngModel)]="filter['status']" class="form-control">
        <option value=undefined selected>Status</option>
        <option value='public_template'>Public</option>
        <option value='private_template'>Private</option>
      </select>
    </div>
    <div class="col-md-3 col-xs-5">
      <button type="button" class="btn btn-tertiary btn-outline btm-min" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      <button type="button" class="btn btn-secondary btn-min ml" (click)="addRichTextTemplate()" >
        <i class="fa fa-plus mr-sm"></i> Add
      </button>
    </div>
    <div class="col-xs-2 pull-right" *ngIf="!userService.current_user.isEnterprise">
      <div class="input-group">
        <input name="specialty" [(ngModel)]="template_search"
               [typeahead]="templates" typeaheadWaitMs="500"
               [typeaheadAsync]="true"
               (typeaheadLoading)="changeTypeaheadLoadingTemplate($event)"
               (typeaheadNoResults)="changeTypeaheadNoResultsTemplate($event)"
               (typeaheadOnSelect)="typeaheadOnTemplateSelect($event.item)" [typeaheadOptionsLimit]="10"
               [typeaheadOptionField]="'name'" placeholder="Get Rich Text Template"
               class="form-control" autocomplete="off">
        <div class="input-group-addon" *ngIf="typeaheadLoadingTemplate"><i class="fa fa-spinner fa-spin"></i></div>
      </div>
      <div *ngIf="typeaheadNoResultsTemplate" class="text-danger fs13">No Results Found!</div>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Author</th>
      <th>Owner</th>
      <th>Created ON</th>
      <th>Last Modified By</th>
      <th>Last Modified On</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let richTextTemplate of richTextTemplates">
      <td *ngIf="!isEnoteTemplate"><a (click)="editRichTextTemplate(richTextTemplate)">{{richTextTemplate.name}}</a></td>
      <td *ngIf="isEnoteTemplate"><a (click)="openEnoteTemplate(richTextTemplate)">{{richTextTemplate.name}}</a></td>
      <td class="text-capitalize">{{userService.removeUnderScore(richTextTemplate.text_type)}}</td>
      <td class="text-capitalize">{{richTextTemplate.author_name}}</td>
      <td class="text-capitalize">{{richTextTemplate.owner_name}}</td>
      <td>{{richTextTemplate.created_at | date:'MMMM d, y, h:mm a'}}</td>
      <td>{{richTextTemplate.modifier_name}}</td>
      <td>{{richTextTemplate.last_modified_at | date:'MMMM d, y, h:mm a'}}</td>
      <td class="text-capitalize">{{userService.removeUnderScore(richTextTemplate.status)}}</td>

    </tr>
    </tbody>
  </table>
  <app-no-result-found *ngIf="richTextTemplates && richTextTemplates.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center" *ngIf="richTextTemplates && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>

