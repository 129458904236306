import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {Patient} from '../doctor/patient';
import {PatientService} from '../../services/patient.service';

@Component({
  selector: 'app-signup-presetup',
  templateUrl: './signup-presetup.component.html',
  styleUrls: ['./signup-presetup.component.scss']
})
export class SignupPresetupComponent implements OnInit {
  message = '';
  public  json = JSON;
  enable_continue = false;
  @Input() patient: Patient;
  @Output() verified: EventEmitter<any> = new EventEmitter<any>();
  general_message = '';
  status = 'pending';
  type = 'email';
  data = {mobile_otp: '', email_otp: '', mobile_number: ''};
  patient_data = {};
  message_class = 'success';


  constructor(private patient_service: PatientService, private user_service: UserService, private router: Router, private route: ActivatedRoute) { }


  ngOnInit() {
    this.data.mobile_number = this.patient.user.phone.value;
    this.sendMobileOtp();
  }



  edit_number() {
    this.status = 'pending';
  }

  sendMobileOtp() {
    this.patient_service.sendMobileOtp({mobile_number: this.data.mobile_number}).
    then(response => {
      this.message = response.message;
      this.status = 'waiting';
      this.patient_data =  response.patient_data;
      this.message_class = 'success';
      this.clear_messages();
    }).catch(res => {
      this.message =  res.message;
      this.status = 'error';
      this.message_class = 'failed';
    })
  }

  verifyMobileOTP() {
    this.patient_service.verifyMobileOtp({secret_key: this.patient_data['secret_key'], otp: this.data.mobile_otp}).
    then(res => {
      let patient = this.patient;
      let previous_email = this.patient.email;
      if (res.patient) {
      this.patient.load_from_json(res.patient);
      }
      this.patient.user.email = previous_email;
      this.patient.set_secret_key(this.patient_data['secret_key']);
      this.patient.can_login = true;
      this.message_class = 'success';
      this.status = 'verified';
      this.clear_messages();
      this.verified.emit(this.patient);
    }).catch(res => {
      this.message =  res.error.message;
      this.message_class = 'failed';
      this.clear_messages();
    })
  }

  clear_messages() {
    setTimeout(() => {
      this.message = '';
      this.message = '';
      this.message = '';
    }, 3500);
  }


}
