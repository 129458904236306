import {Patient} from '../../../doctor/patient';
import {Doctor} from '../../../../routes/practices/doctors/doctor';
import {User} from '../../../../routes/users/user';
export  class DocumentPage {
  url: string;
  order: number;
  id: number;
  document_id: number;
  parent_id: number;
  parent_name: string;
  text = 'Loading';
  loaded = false;
  content_type: number;
  load_from_json(json) {
    if (!json) {
      return this;
    }
    this.url = json.url;
    this.order = json.order;
    this.id = json.id;
    this.content_type = json.content_type;
    this.parent_id = json.parent_id;
    this.parent_name = json.parent_name;
    this.document_id =  json.attachable_id;
    return this;
  }


  is_image() {
    return this.content_type === 1
  }

  is_video() {
    return this.content_type === 2
  }

  is_audio() {
    return this.content_type === 3
  }

  is_pdf() {
    return this.content_type === 4
  }

}
export class PatientDocument {
  selected_page = 0;
  id: number;
  patient_id: number;
  patient: Patient = new Patient();
  doctor_id: number;
  added_by: User = new User();
  doctor: Doctor = new Doctor();
  name: any = '';
  attachment_id: number;
  document_type_id: number;
  documentType: DocumentType = new DocumentType();
  service_id: number;
  service_name: string;
  pages: DocumentPage[] = [];
  data: string;
  date: any;
  no_of_days: number;
  source_type: string;
  source_id: number;

  is_last_page() {
    return this.selected_page >= this.pages.length;
  }
  is_first_page() {
    return this.selected_page <= 0;
  }

  getNextPage(): DocumentPage {
    if (this.is_last_page()) {
      return null;
    }
    this.selected_page += 1;
    return this.getSelectedPage();
  }

  getPreviousPage(): DocumentPage {
    if (this.is_first_page()) {
      return null;
    }
    this.selected_page -= 1;
    return this.getSelectedPage();
  }

  getSelectedPage() {
    return this.pages[this.selected_page];
  }

  to_params() {
    return {
      document: {
        patient_id: this.patient_id,
        doctor_id: this.doctor_id,
        attachment_id: this.attachment_id,
        document_type_id: this.document_type_id,
        service_id: this.service_id,
        name: this.name,
        date: this.date,
        source_type: this.source_type,
        source_id: this.source_id
      }
    }
  }
  load_from_json(json) {
    this.name = json.name;
    this.id = json.id;
    this.date =  new Date(json.date);
    this.no_of_days = json.no_of_days;
    if (json.patient_id) {
      this.patient = this.patient.load_from_json(json.patient);
    }
    if (json.doctor_id) {
      this.doctor =  this.doctor.load_from_json(json.doctor);
    }
    if (json.attachment_id) {
      this.data = json.document;
    }
    if (json.service_id) {
      this.service_name = json.service.name;
      this.service_id = json.service.id
    }
    if (json.added_by) {
      this.added_by.load_from_json(json.added_by)
    }
    if (json.attachments) {
      this.pages = json.attachments.filter(at => at.page_data != null).map(att => new DocumentPage().load_from_json(att.page_data));
      console.log(this.pages);
    }
    if (json.document_type_id) {
      this.documentType = this.documentType.load_from_json(json.document_type)
    }
    return this;
  }
}
export class DocumentType {
  id: number;
  name: string;

  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    return this;
  }
}
export class Filter {
  document_type_id: number;
  time_range: string;
  service_id: number;
  sort: string;
  order = 'desc';
  page: any = '1';
  patient_id: number;
}
