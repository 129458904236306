import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {BlockCalendar} from '../doctors/doctor';
import {BlockCalendarService} from '../../../services/block-calendar.service';

@Component({
  selector: 'app-block-calendars',
  templateUrl: './block-calendars.component.html',
  styleUrls: ['./block-calendars.component.scss']
})
export class BlockCalendarsComponent implements OnInit {
  @Input() block_calendars;
  @Input() permissions;
  @Input() block_calendar_locations: any;
  public show_block_calendar_form = false;
  public show_block_calendar_listing = true;
  public block_calendar: BlockCalendar = new BlockCalendar();
  public referece_block_calendar: BlockCalendar = new BlockCalendar();
  constructor(private _block_calendar_service: BlockCalendarService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    return this.block_calendar_listing();
  }

  block_calendar_listing() {
    return this.block_calendars;
  }

  edit_calendar(block_calendar) {
    this.show_block_calendar_form = true;
    this.show_block_calendar_listing = false;
    this.block_calendar = block_calendar;
  }

  handle_hide_form(calendar) {
    this.show_block_calendar_form = false;
    this.show_block_calendar_listing = true;
  }

  calendar_saved() {
    this.show_block_calendar_form = false;
    this.show_block_calendar_listing = true;
  }

  delete_block_calendar(block_calendar) {
    this._block_calendar_service.delete_block_calendar(block_calendar).then(res => {
      if (res && res.success) {
        this.block_calendars.splice(this.block_calendars.indexOf(block_calendar), 1);
      }
      this.block_calendars = this.block_calendars;
    });
  }

  set_reference_for_block(block_calendar) {
    this.referece_block_calendar = block_calendar;
  }

}
