import {Component, OnInit, Injector, ViewChild} from '@angular/core';
import {Case} from "../../case";
import {CaseService} from "../../../../services/case.service";
import {DoctorService} from "../../../../services/doctor.service";
import {Observable} from "rxjs";
import {CaseFeedBackService} from "../../../../services/case-feed-back.service";
import {DischargeCheckList, CaseFeedback} from "./discharge_feedback";
import {PaymentService} from "../../../../services/payment.service";
import {Ledger} from "../../payment/payment";
import {ModalDirective} from "ngx-bootstrap/modal";
import {TabsService} from "../../../../services/tabs.service";
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-discharge',
  templateUrl: './discharge.component.html',
  styleUrls: ['./discharge.component.scss']
})
export class DischargeComponent implements OnInit {
  public admission: Case;
  public data_source: Observable<any[]>;
  public token: string = '';
  public dischargeChecklists: DischargeCheckList[] = [];
  public caseFeedback: CaseFeedback;
  public ledger: Ledger;
  @ViewChild('classicModal') classicModal: ModalDirective;

  constructor(private tabsService:TabsService, private injector: Injector, private caseService: CaseService, private doctorService: DoctorService,
              public caseFeedbackService: CaseFeedBackService, private paymentService: PaymentService) {

    this.data_source = Observable.create((observer: any) => {
      observer.next(this.caseFeedback.doctor_name);
    }).pipe(mergeMap((token: string) => this.doctorService.get_practice_doctors(this.token, this.admission.practice_id)));
  }

  ngOnInit() {
    let id = this.injector.get('id', null);
    this.caseFeedback = this.injector.get("caseFeedback",null);
    this.fetchCase(id);
    this.getChecklists();
    this.caseFeedback.case_id = id;

  }

  getCaseledger(){
    this.paymentService.getPayment(this.admission.appointment_id).then(resp => {
      if (resp.ledger){
        this.ledger = new Ledger().load_from_json(resp.ledger);
      }
    });
  }

  fetchCase(id) {
    this.caseService.getCase(id).then(json => {
      this.admission = new Case().load_from_json(json);
      this.getCaseledger();
    });
  }

  getChecklists() {
    this.caseFeedbackService.getCaseCheckLists().then(response => {
      this.dischargeChecklists = response.map(checklist => new DischargeCheckList().load_from_json(checklist));
    })
  }
  public typeaheadOnSelect(e: any): void {
    this.caseFeedback.doctor_name = e.value;
    this.caseFeedback.doctor_id = e.item.id;
  }

  public markChecklist(checklist) {
    if(this.caseFeedback.discharge_checklist_ids.indexOf(checklist.id) > -1)
      this.caseFeedback.discharge_checklist_ids.splice(this.caseFeedback.discharge_checklist_ids.indexOf(checklist.id),1);
    else
      this.caseFeedback.discharge_checklist_ids.push(checklist.id);
  }

  public saveDischarge() {
    this.caseFeedbackService.save(this.caseFeedback).then(resp => {
      if(resp != undefined) {
        this.caseService.reloadCases.next(1);
        this.caseFeedbackService.error_message = null;
        let active_tab:any = this.tabsService.active_tab();
        this.tabsService.close_tab(active_tab);
      }
    })
  }
  public checkPayment(){
    if(!this.ledger || this.ledger.balance != 0)
      this.classicModal.show();
    else
      this.saveDischarge();
  }

}
