export class PatientNotes {
  id: number;
  user_id: number;
  noteable_type: string;
  noteable_id: number;
  patient_id: number;
  time_spent : any;
  note_body : string;
  type : string;
  patient_note_type_id: number;
  created_by: string;
  created_at: any;
  source_type: any;
  source_id: any;
  temp_activity_id: any;
  is_sticky: boolean = false;


  load_from_json(json) {
    this.note_body = json.note_body;
    this.id = json.id;
    this.time_spent = json.time_spent;
    this.noteable_type = json.noteable_type;
    this.noteable_id = json.noteable_id;
    this.patient_id = json.patient_id;
    this.patient_note_type_id = json.patient_note_type_id;
    this.user_id = json.user_id;
    this.type = json.type;
    this.created_by = json.created_by;
    this.created_at = json.created_at;
    this.source_id = json.source_id;
    this.source_type = json.source_type;
    this.is_sticky = json.is_sticky;
    return this;
  }

  to_params() {
    return {
      patient_note: {
        id: this.id,
        note_body: this.note_body,
        time_spent: this.time_spent,
        patient_id: this.patient_id,
        patient_note_type_id: this.patient_note_type_id,
        user_id: this.user_id,
        source_type: this.source_type,
        source_id: this.source_id,
        is_sticky: this.is_sticky
      }
    }
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
  get_url(): string {
    return this.is_new_record() ? 'patient_notes/' : ['patient_notes', this.id].join('/')
  }
}



