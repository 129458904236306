<div class="modal-body flex-body scroll-browser">
  <div [class.disabled-form]="disabledCriteria()">
    <div class="row" *ngIf="invoice">
      <div class="col-md-7 col-sm-6 col-xs-12">
        <ng-container *ngIf="admin_user">
          <h4 class="mt0">To: &nbsp;{{admin_user['user_full_name']}}</h4>
          <p class="fs16 mb-sm">{{admin_user['profileable_name']}}</p>
          <p class="fs16 mb-sm">{{admin_user['user_address']}}</p>
          <p class="fs16 m0">{{admin_user['mobile_number'] | mask: '(000) 000-0000'}}</p>
        </ng-container>
      </div>
      <div class="col-md-5 col-sm-6 col-xs-12">
        <div class="row">
          <div class="col-sm-4"><label class="fs16">Invoice#</label></div>
          <div class="col-sm-8"><p class="fs16 fw-500">{{invoice.invoice_number}}</p></div>
        </div>
        <div class="row form-group">
          <div class="col-sm-4"><label class="m0 mt-sm">Bill Date:</label></div>
          <div class="col-sm-8">
            <div class="input-group">
              <input  type="text" class="form-control" [(ngModel)]="invoice.bill_date" bsDatepicker>
              <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4"><label class="m0 mt-sm">Due Date:</label></div>
          <div class="col-sm-8">
            <div class="input-group">
              <input  type="text" class="form-control" [(ngModel)]="invoice.due_date" bsDatepicker>
              <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form #invoiceForm="ngForm">
      <table class="table table-hover mt-lg">
        <thead>
        <tr>
          <th style="width:30%;">Description</th>
          <th class="text-right">Rate</th>
          <th class="text-right">Quantity</th>
          <th class="text-right">Amount</th>
          <th style="width:90px;" *ngIf="invoice.status === 'unpaid'"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let invoice_item of invoice.filtered_invoice_items; let i = index ">
          <tr *ngIf="!invoice_item._destory">
            <td><input   [name]="'description-' + i" type="text" class="form-control" [(ngModel)]="invoice_item.description" required></td>
            <td><input  [name]="'rate-' + i"  type="text" class="form-control" placeholder="USD" (change)="invoice_item.calculateAmount(invoice)" [(ngModel)]="invoice_item.rate" required></td>
            <td><input   [name]="'quantity-' + i" type="text" class="form-control" (change)="invoice_item.calculateAmount(invoice)" [(ngModel)]="invoice_item.quantity" required></td>
            <td><input   [name]="'amount-' + i" type="text" class="form-control" disabled readonly [(ngModel)]="invoice_item.amount"></td>
            <td class="text-center" *ngIf="invoice.status === 'unpaid'">
              <a class="btn btn-danger btn-outline mr" *ngIf="invoice.filtered_invoice_items.length > 1"  (click)="invoice.removeItem(invoice_item)">
                <i class="fa fa-times"></i>
              </a>
              <a class="btn btn-primary btn-outline" *ngIf="invoice.filtered_invoice_items.indexOf(invoice_item) === invoice.filtered_invoice_items.length - 1" (click)="invoice.addItem()">
                <i class="fa fa-plus"></i>
              </a>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td class="text-right" colspan="3"><span class="fw-500 fs16">Discount</span></td>
          <td><input   name='discaount' type="text" class="form-control text-right" (change)="invoice.discountCalc()" [(ngModel)]="invoice.discount"></td>
          <td *ngIf="invoice.status === 'unpaid'"></td>
        </tr>
        <tr>
          <td class="text-right" colspan="3"><span class="fw-500 fs18">Total Invoice Amount</span></td>
          <td><input disabled type="text" class="form-control text-right" [value]="invoice.amount" placeholder="USD"></td>
          <td *ngIf="invoice.status === 'unpaid'"></td>
        </tr>
        </tbody>
      </table>
    </form>
    <div *ngIf="saved" class="p alert alert-success text-center mt-lg m0">Invoice created successfully</div>
  </div>
</div>
<div class="modal-footer flex-footer" *ngIf="invoice.status === 'unpaid' && !saved" [class.disabled-form]="disabledCriteria()">
  <button class="btn btn-secondary btn-min" (click)="save()" type="button" [disabled]="!invoiceForm.form.valid">Save</button>
</div>
