import {Injectable} from '@angular/core';

@Injectable()
export class GlobalErrorHandlerService {
  public error: string;
  public message: string;
  public reload_app = false;
  public tempObjCopy;
  constructor() {}
}
