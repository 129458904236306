<div class="flex-control flex-column flex-height" *ngIf="!show_enote">
  <div class="flex-header"><h4 class="text-black m0">Create note from</h4></div>
  <div class="flex-body p scroll-browser hor-scroll-browser">
    <div class="row mb-lg">
      <div class="col-xs-4">
        <label class="border-box shadow text-center d-block selection-box" [ngClass]="note_type == 'template' ? 'active': ''" for="template">
          <i class="fa icon-book-open d-block"></i>
          <input id="template" type="radio" value="template" name="note_type" [(ngModel)]="note_type" required>
          <h5 class="fw-400">Template</h5>
        </label>
      </div>
      <div class="col-xs-4">
        <label class="border-box shadow text-center d-block selection-box" [ngClass]="note_type == 'patient_note' ? 'active': ''" for="patient_note">
          <i class="fa icon-user d-block"></i>
          <input id="patient_note" type="radio" value="patient_note" name="note_type" [(ngModel)]="note_type" required>
          <h5 class="fw-400">Patient's Previous Note</h5>
        </label>
      </div>
      <!--<div class="col-xs-3">
        <label class="border-box shadow text-center d-block selection-box" for="blank" [ngClass]="note_type == 'blank' ? 'active': ''">
          <input (change)="move_next()" id="blank" type="radio" value="blank" name="note_type" [(ngModel)]="note_type" required>
          <i class="fa icon-doc d-block"></i>
          <h5 class="fw-400">Blank Note</h5>
        </label>
      </div>-->
      <div class="col-xs-4">
        <label class="border-box shadow text-center d-block selection-box" for="richText" [ngClass]="note_type == 'richText' ? 'active': ''">
          <input id="richText" type="radio" value="richText" name="note_type" [(ngModel)]="note_type" required>
          <i class="fa icon-doc d-block"></i>
          <h5 class="fw-400">Rich Text Template</h5>
        </label>
      </div>
    </div>
    <table class="table table-hover" *ngIf="note_type == 'template'">
      <thead>
      <tr>
        <th style="width:200px; min-width:200px;">Name</th>
        <th>Type</th>
        <th>Specialty</th>
        <th>Author</th>
        <th>Owner</th>
        <th>Created ON</th>
        <th>Last Modified By</th>
        <th>Last Modified on</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let template of limited_templates">
        <ng-container *ngIf="template">
          <tr>
            <td>
              <a class="d-block" (click)="setSelectedTemplate(template.id,'create_enote');show_enote = !show_enote">
                {{template.name}}
              </a>
            </td>
            <td >{{template.document_type_name}}</td>
            <td >{{template.specialty_name}}</td>
            <td >{{template.author_name}}</td>
            <td >{{template.owner_name}}</td>
            <td >{{template.formatted_created_at}}</td>
            <td >{{template.last_modified_by}}</td>
            <td >{{template.last_modified_on}}</td>
            <td>{{template.shared_status}}</td>
          </tr>
        </ng-container>
      </ng-container>
      <tr *ngIf="total_templates > template_limit">
        <td colspan="12">
          <ng-container *ngIf="!temp_loader">
            <button type="button" class="btn btn-secondary btn-outline btn-sm more-btn" (click)="template_limit = template_limit + 5;limit_templates();temp_loader = true">
              <i class="fa fa-angle-double-down"></i> &nbsp;Load More
              <span class="spin-icon" *ngIf="temp_loader"><i class="fa fa-spinner fa-spin"></i></span>
            </button>
          </ng-container>
        </td>
      </tr>
      </tbody>
    </table>
    <ng-container *ngIf="note_type == 'richText'">
      <app-enote-rich-text-template (enoteTemplateClick)="createRichTextTemplate($event)"></app-enote-rich-text-template>
    </ng-container>
    <div class="pt" *ngIf="note_type == 'patient_note' && patient_latest_three_enotes">
      <p *ngIf="patient_latest_three_enotes.length == 0" class="alert alert-info p text-center">No previous note available</p>
      <table class="table table-hover" *ngIf="patient_latest_three_enotes.length > 0">
        <thead>
        <tr>
          <th style="width:300px; min-width:300px;">Date & Time</th>
          <th>Reason of Visit</th>
          <th>Template Name</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let enote of patient_latest_three_enotes">
          <td *ngIf="!enote.is_rich_text">
            <a class="d-block" (click)="setSelectedTemplate(enote.id,'create_enote');show_enote = !show_enote">
              {{enote.formatted_created_at}}
            </a>
          </td>
          <td *ngIf="enote.is_rich_text">
            <a class="d-block" (click)="createRichTextTemplate(enote)">
              {{enote?.created_at | date : 'medium'}}
            </a>
          </td>
          <td *ngIf="!enote.is_rich_text">{{enote.reason_of_visit}}</td>
          <td *ngIf="enote.is_rich_text"></td>
          <td>
            {{enote.name}}
          </td>
        </tr>
        <tr *ngIf="patient_total_templates > note_limit">
          <td colspan="12">
            <ng-container *ngIf="!loader">
              <button type="button" class="btn btn-default btn-sm more-btn"
                      (click)="note_limit = note_limit + 5; latest_five_enotes(patientID, doctor_id);loader = true">
                <i class="fa fa-angle-double-down"></i> &nbsp;Load More
              </button>
            </ng-container>
            <span class="spin-icon" *ngIf="loader"><i class="fa fa-spinner fa-spin"></i></span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-container *ngIf="show_enote">
  <div class="flex-control flex-height enote-patient" [class.flex-collapse]="isSpecial || innerWidth < 991">
    <div class="flex-left" *ngIf="!isProgramOrPatientProfile">
      <ul class="list-unstyled border-list m0 scroll-browser">
        <ng-container *ngFor="let enote of patient_latest_three_enotes">
          <li *ngIf="!enote.is_rich_text" class="list" [class.active]="enote.id == enoteService.template?.id" (click)="setSelectedTemplate(enote.id,enote.stage)">
            <h4 class="fw-500 m0 fs16 day">{{enote.time_passed}}</h4>
            <h6 class="mt-sm mb-sm date">{{enote.formatted_created_at}}</h6>
            <h6 class="text-primary m0">{{enote.name}}</h6>
          </li>

          <li  *ngIf="enote.is_rich_text" class="list" [class.active]="enote.id == selectedRichTextTemplate?.id" (click)="viewRichTextTemplate(enote)">
            <h4 class="fw-500 m0 fs16 day">{{enote.time_passed_ago}}</h4>
            <h6 class="mt-sm mb-sm date">{{enote.created_at | date:'MMMM d, y, h:mm a'}}</h6>
            <h6 class="text-primary m0">{{enote.name}}</h6>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="flex-body flex-control">
      <ng-container *ngIf="(stage && enoteService.template) || is_rich_text" >
        <ng-container *ngComponentOutlet="enoteComponent;injector: custom_injector;"></ng-container>
      </ng-container>
    </div>
    <div class="flex-right" *ngIf="!isProgramOrPatientProfile">
      <a class="btn btn-primary btn-outline btn-collapse" (click)="isSpecial = !isSpecial;innerWidth = 1000">
        <i class="fa fa-chevron-right"></i>
      </a>
      <div class="template-search">
        <input class="form-control" name="specialty" [(ngModel)]="template_search"
               [typeahead]="templates_list" typeaheadWaitMs="500"
               [typeaheadAsync]="true"
               (typeaheadOnSelect)="typeaheadOnTemplateSelect($event,modalTemplate)" [typeaheadOptionsLimit]="10"
               [typeaheadOptionField]="'description'" placeholder="Get Tempalte from Library"
               class="form-control" autocomplete="off" [disabled]="saving">
        <i *ngIf="!saving" class="fa fa-search"></i>
        <i *ngIf="saving" class="fa fa-spinner fa-spin"></i>
      </div>
      <div class="clearfix mt">
        <h5 class="bg-gray-light m0 p text-center fw-500">My Templates</h5>
        <cdk-virtual-scroll-viewport  itemSize="10" class="my-templates scroll-browser">
          <ul class="list-unstyled border-list m0">
            <ng-container *cdkVirtualFor="let template of templates">
              <ng-container *ngIf="template">
                <li (click)="setSelectedTemplate(template.id,'create_enote')" class="list">
                  <i class="fa fa-file-pdf-o text-primary"></i>&nbsp; {{template.name || 'Loading...'}}
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #modalTemplate>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModal.hide()">
      <i class="fa fa-times fs16"></i>
    </button>
    <h4 class="modal-title">
      <span>Name: {{readonly_template.name}}</span>
      <span class="pl-xxl">Document Type: {{readonly_template.document_type_name}}</span>
      <span class="pl-xxl">Specialty: {{readonly_template.specialtyName}}</span>
    </h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="readonly_template.isReadOnly">
      <ng-container *ngFor="let term of readonly_template.terms; let index=index">
        <div contenteditable="false" [id]="'whole-wrapper-'+term.id">
          <div contenteditable="false" *ngIf="term">
            <app-read-only-template [term]="term" [term_index]="index" class="mb-lg"
                                    [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-read-only-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button (click)="save_as_my_template()"  class="btn btn-secondary">Accept As My Template</button>
    <button (click)="hideModal()" class="btn btn-tertiary btn-outline">Cancel</button>
  </div>
</ng-template>
