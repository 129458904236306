import {Component, Injector, OnInit} from '@angular/core';
import {AlertIntervention, AlertTemplate} from '../alert-template';
import {LoaderService} from '../../../services/loader.service';
import {TabsService} from '../../../services/tabs.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {AlertTemplateService} from '../alert-template.service';
import {UserService} from '../../../services/user.service';
import {AlertService} from '../../../_alert';

@Component({
  selector: 'app-alert-template',
  templateUrl: './alert-template.component.html',
  styleUrls: ['./alert-template.component.scss']
})
export class AlertTemplateComponent implements OnInit {
  public alertTemplate: AlertTemplate = new AlertTemplate();
  protocols: any = [];
  public isRulesFormValid: boolean;

  constructor(public injector: Injector, public loaderService: LoaderService, public tabs_service: TabsService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public alertTemplateService: AlertTemplateService,
              public userService: UserService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.alertTemplate = this.injector.get('alertTemplate', this.alertTemplate);
    if (this.userService.current_user.isPracticeUser) {
      console.log(this.userService.current_user.profileable)
      this.alertTemplate.practice_id = this.userService.current_user.profileable['practice_id']
    }
    this.alertTemplate.creator_id = this.userService.current_user.id;
  }

  save() {
    this.loaderService.show();
    this.alertTemplateService.save(this.alertTemplate)
      .then(resp => {
        this.alertTemplate = this.alertTemplate.load_from_json(resp)
        this.loaderService.hide();
        this.alertTemplateService.reload_alert_template_list.next(1);
        this.tabs_service.close_tab(this.tabs_service.active_tab());
        this.alertService.success('Alert has been saved successfully', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })

  }

  closeNewForm() {
    if (this.globalErrorHandlerService.tempObjCopy) {
      this.alertTemplate.load_from_json(this.globalErrorHandlerService.tempObjCopy);
      this.globalErrorHandlerService.tempObjCopy = undefined;
    }
    if (this.tabs_service.active_tab().component === AlertTemplateComponent) {
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }
  }
  validRulesFrom(e: boolean) {
    this.isRulesFormValid = e;
  }
}
