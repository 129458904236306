import { Injectable } from '@angular/core';
import {Router,NavigationEnd} from "@angular/router";
import {UserService} from "../../services/user.service";

@Injectable()
export class MenuService {

  menuItems: Array<any>;
  group_menu: Object;
  selected_menu = 'home';
  selected_sidebar = 'Access Control';
  goingHome = false;

  constructor(private router: Router, private _userService: UserService) {
    this.menuItems = [];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.goingHome = event.url === '/';
        this.set_selected_menu();
        var parent_condition = this.router.url.split('/').slice(-2)[0];
        console.log(parent_condition);
      }
    });
  }

  add_group_menu(obj){
    this.group_menu=obj;
    // Object.keys(obj).forEach((key) => {
    //  addMenu(obj[keys]);
    // });
  }

  set_selected_menu(menu?: string): void {
    const defaultMenu = 'home';
    let smenu = menu || defaultMenu;

    if (!menu) {
      const parent_condition = this.router.url.includes('profile-settings')
        ? 'profile-settings'
        : this.router.url.split('/').slice(-2)[0];

      switch (parent_condition) {
        case 'program':
          smenu = 'program';
          break;
        case 'reviews':
          smenu = 'reviews';
          break;
        case 'home':
          smenu = 'home';
          break;
        case 'reception':
          smenu = 'reception';
          break;
        case 'online':
          smenu = 'online';
          break;
        case 'manage':
          smenu = 'manage';
          break;
        case 'settings':
          smenu = 'settings';
          break;
        case 'patients':
          smenu = 'patients';
          break;
        case 'profile-settings':
          smenu = 'profile-settings';
          break;
      }
    }

    this.selected_menu = smenu;
  }



  addMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,
    icon?: string,
    alert?: string,
    submenu?: Array<any>,
    permission?: string
  }>) {
    items.forEach((item) => {
      this.menuItems.push(item);
    });
  }

  getMenu() {
    return this.group_menu[this.selected_menu];
  }

  get_permitted_menu_for(main_menu){
    let sub_menus = this.group_menu[main_menu];
    let permitted_menus = sub_menus.filter((menu) => {
      if (!menu.permission) {
        return true;
      }
      let [name, parent, category, type, categoryOnly] = menu.permission.split(',');
      let can_access = categoryOnly ? this._userService.permittedCategory(category) :  this._userService.is_permitted(name, parent, category, type);
      return can_access;
    });
    if (permitted_menus.length > 0) {
      return permitted_menus[0].link
    }
    return null
  }

  getMenuFor(key) {
    return this.group_menu[key];
  }


}
