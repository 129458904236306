export class Department {
  name: string;
  practice_id: string;
  id: string;
  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.practice_id = json.practice_id;
    return this;
  }
}
