<div class="section">
  <h4>
    <i class="fa icon-phone fs20 vl-m mr"></i> Emergency Contact
    <a class="fs14" *ngIf="!intakeReport" (click)="addEcontactForm('econtact')" tooltip="Add Emergency Contact"><i
      class="fa fa-plus fs12"></i> Add</a>
  </h4>
  <form *ngIf="openContactForm" #emergencyContactForm='ngForm' (ngSubmit)="save()">
    <div class="row">
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="Name*" name="econtact_name" [(ngModel)]="econtact.name"
               required>
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input mask="(000) 000-0000" type="text" class="form-control" placeholder="Phone*" name="offset_time"
               [(ngModel)]="econtact.contact_number.value" required>
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <select name="relationship" [(ngModel)]="econtact.relationship_id" class="form-control text-capitalize"
                id="relationship" required>
          <option value="" selected>Select Relationship*</option>
          <option *ngFor="let relationship of patientService.patient_relationships;"
                  [value]="relationship.id">{{ relationship.name }}
          </option>
        </select>
      </div>
      <div class="col-xs-12 form-group">
        <input type="text" class="form-control" placeholder="Address " [(ngModel)]="econtact.address.line_1"
               name="e_line_1">
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="City" [(ngModel)]="econtact.address.city_area.city.name"
               name="e_city">
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="State" [(ngModel)]="econtact.address.city_area.city.state"
               name="e_state">
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="Zip Code" [(ngModel)]="econtact.address.postal_code"
               pattern="\d+" minlength="1" maxlength="8"
               name="e_zip">
      </div>
      <div class="col-xs-12 form-group">
        <button type="submit" class="btn btn-secondary btn-sm mr"
                [disabled]="!(emergencyContactForm.form.valid && patient.id)"
                *ngIf="!loaderService.uploading_in_process">Save
        </button>
        <button type="submit" (click)="openContactForm = false;" class="btn btn-tertiary btn-outline btn-sm"
                *ngIf="!loaderService.uploading_in_process">Cancel
        </button>
      </div>
      <p class="col-xs-12 fs12" *ngIf="loaderService.uploading_in_process">
        <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
      </p>
    </div>
  </form>
  <div class="row emergency-cntct">
    <div class="col-md-4 col-sm-4" *ngFor="let econtact of generalEcontactArray">
      <div class="border-box p-sm mb-lg media">
        <div class="media-body">
          <h4 class="m0 fs14">{{ econtact.name }}</h4>
          <p class="mt mb0"><i
            class="fa icon-phone mr"></i> {{ econtact.contact_number.value | mask: '(000) 000-0000' }}</p>
          <p class="m0 text-capitalize"><i class="fa icon-shuffle mr"></i>{{ econtact.relationship_name }}</p>
        </div>
        <div class="media-right media-middle text-right" *ngIf="!intakeReport">
          <a class="btn btn-secondary btn-outline btn-sm mb" tooltip="Edit"
             (click)="editEContact(econtact, 'econtact')">
            <i class="fa fa-pencil"></i>
          </a>
          <a class="btn btn-danger btn-outline btn-sm" tooltip="Delete"
             (click)="selectedEcontactToDelete = econtact;deleteModal.show();">
            <i class="fa fa-trash"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="section">
  <h4>
    <i class="fa icon-phone fs20 vl-m mr"></i> Providers
    <a class="fs14" *ngIf="!intakeReport" (click)="addEcontactForm('provider')" tooltip="Add Providers"><i class="fa fa-plus fs12"></i>
      Add</a>
  </h4>
  <form *ngIf="openProviderForm" #emergencyProvidersContactForm='ngForm' (ngSubmit)="save()">
    <div class="row">
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="Name*" name="provider_name" [(ngModel)]="econtact.name"
               required>
      </div>
      <div class="col-md-4 col-sm-5 form-group inline-table">
        <span class="input-group-addon"><i class="fa fa-phone"></i></span>
        <input mask="(000) 000-0000" type="text" class="form-control phone" placeholder="Phone*" name="provider_offset_time"
               [(ngModel)]="econtact.contact_number.value" required>
      </div>
      <div class="col-md-4 col-sm-5 form-group inline-table">
        <span class="input-group-addon"><i class="fa fa-fax"></i></span>
        <input mask="(000) 000-0000" type="text" class="form-control fax" placeholder="Fax" name="provider_fax"
               [(ngModel)]="econtact.fax">
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <select name="relationship" [(ngModel)]="econtact.relationship_id" class="form-control text-capitalize"
                id="provider_relationship" required>
          <option value="" selected>Select Provider*</option>
          <option *ngFor="let relationship of patientService.patient_providers;"
                  [value]="relationship.id">{{ relationship.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="Facility Name" name="facility_name"
               [(ngModel)]="econtact.facility_name">
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="Speciality" name="speciality"
               [(ngModel)]="econtact.speciality">
      </div>
      <div class="col-xs-12 form-group">
        <input type="text" class="form-control" placeholder="Address " [(ngModel)]="econtact.address.line_1"
               name="line_1">
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="City" [(ngModel)]="econtact.address.city_area.city.name"
               name="city">
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="State" [(ngModel)]="econtact.address.city_area.city.state"
               name="state">
      </div>
      <div class="col-md-4 col-sm-5 form-group">
        <input type="text" class="form-control" placeholder="Zip Code" [(ngModel)]="econtact.address.postal_code"
               pattern="\d+" minlength="1" maxlength="8"
               name="zip">
      </div>
      <div class="col-xs-12 form-group">
        <button type="submit" class="btn btn-secondary btn-sm mr"
                [disabled]="!(emergencyProvidersContactForm.form.valid && patient.id)"
                *ngIf="!loaderService.uploading_in_process">Save
        </button>
        <button type="submit" (click)="openProviderForm = false;" class="btn btn-tertiary btn-outline btn-sm"
                *ngIf="!loaderService.uploading_in_process">Cancel
        </button>
      </div>
      <p class="col-xs-12 fs12" *ngIf="loaderService.uploading_in_process">
        <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
      </p>
    </div>
  </form>


  <div class="row emergency-cntct">
    <div class="col-md-4 col-sm-4" *ngFor="let econtact of providersEcontactArray">
      <div class="border-box p-sm mb-lg media">
        <div class="media-body">
          <h4 class="m0 fs14" *ngIf="intakeReport">{{ provider_name_address(econtact) }}</h4>
          <h4 class="m0 fs14" *ngIf="!intakeReport">{{ provider_name_address(econtact) }}</h4>
          <div class="flex-control ">
          <p class="mt mb0 mr"><i
            class="fa fa-phone mr-sm "></i> {{ econtact.contact_number.value | mask: '(000) 000-0000'}}</p>
          <p class="mt mb0 mr" *ngIf="econtact.fax"><i
            class="fa fa-fax mr-sm"></i> {{ econtact.fax | mask: '(000) 000-0000'}}</p>
            <p class="mt mb0 text-capitalize"><i class="fa icon-shuffle mr-sm"></i>{{ econtact.relationship_name }}</p>
            </div>
        </div>
        <div class="media-right media-middle text-right" *ngIf="!intakeReport">
          <a class="btn btn-secondary btn-outline btn-sm mb" tooltip="Edit"
             (click)="editEContact(econtact, 'provider')">
            <i class="fa fa-pencil"></i>
          </a>
          <a class="btn btn-danger btn-outline btn-sm" tooltip="Delete"
             (click)="selectedEcontactToDelete = econtact;deleteModal.show();">
            <i class="fa fa-trash"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Record</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p pt-sm pb-sm m0 text-center">“Are you sure you want to delete this record?”
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary"
                (click)="deleteEContact(selectedEcontactToDelete);deleteModal.hide()">Yes
        </button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
