import {Component, OnInit, Injector , Input} from '@angular/core';
import {CaseService} from '../../../services/case.service';
import {Case} from '../case';
import {AppointmentService} from '../../../services/appointment.service';
import {Appointment} from '../../../health/models/appointment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public admission: Case;
  @Input() appointment: Appointment;
  @Input() appointment_from: string;
  public status: string;
  public isVisitCall = 'false';
  constructor(private injector: Injector, private caseService: CaseService, private appointmentService: AppointmentService) { }

  ngOnInit() {
    let id = this.injector.get('id', null);
     this.status = this.injector.get('status', null);
    if (id) {
      let paymentFor = this.injector.get('paymentFor', null);
      let isVisitCall = this.injector.get('callFromVisit', null);
      if (isVisitCall ==  true) {
        this.isVisitCall = 'true';
      }
      if (paymentFor == 'Case') {
        this.fetchCase(id);
      }
      if (paymentFor == 'Appointment') {
        this.fetchAppointment(id);
      }
    }
  }

  fetchCase(id) {
    this.caseService.getCase(id).then(json => {
      this.admission = new Case().load_from_json(json);
    });
  }
  fetchAppointment(id) {
    this.appointmentService.getAppointment(id).then(json => {
      this.appointment = new Appointment().load_from_json(json);
      if (this.status == 'Payment') {
        this.appointment.status_id = this.status;
      }
    })
  }
}
