import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { ReviewsRoutingModule } from './reviews-routing.module';
import { ReviewsComponent } from './reviews/reviews.component';
import { ReviewsDashboardComponent } from './reviews-dashboard/reviews-dashboard.component';
import { ReviewsSetupComponent } from './reviews-setup/reviews-setup.component';
import { SurveysComponent } from './surveys/surveys.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import {PracticeReviewService} from './practice_review.service';
import {PatientReviewService} from './patient-review.service';
import { ReviewsFilterComponent } from './reviews-filter/reviews-filter.component';
import { ReviewsListingComponent } from './reviews-listing/reviews-listing.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {NoResultFoundComponent} from '../shared/no-result-found/no-result-found.component';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TagService} from '../services/tags.service'; // this is needed!
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {SharedModule} from '../shared/shared.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { ReviewReportPrinterComponent } from './review-report-printer/review-report-printer.component';
import {NgxMaskModule} from 'ngx-mask';

@NgModule({
  declarations: [ReviewsComponent,
    ReviewsDashboardComponent,
    ReviewsSetupComponent,
    SurveysComponent,
    ReviewsFilterComponent,
    ReviewReportPrinterComponent,
    ReviewsListingComponent],
    imports: [
        CommonModule, PaginationModule.forRoot(), BsDatepickerModule.forRoot(),
        ReviewsRoutingModule, TagInputModule, BrowserAnimationsModule,
        FormsModule, BsDropdownModule.forRoot(),
        RatingModule.forRoot(), SharedModule, NgbTooltipModule, NgxMaskModule
    ],
  providers: [PracticeReviewService, PatientReviewService, TagService]

})
export class ReviewsModule { }
