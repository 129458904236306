import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {EligiblePatient, ProgramEnrollment} from '../../eligible-patient';
import {Program} from '../../program';
import {ProgramService} from '../../program.service';
import {Observable} from 'rxjs/Observable';
import {mergeMap} from 'rxjs/operators';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-program-enrolment-registration',
  templateUrl: './program-enrolment-registration.component.html',
  styleUrls: ['./program-enrolment-registration.component.scss']
})
export class ProgramEnrolmentRegistrationComponent implements OnInit {
  @Input() patient: EligiblePatient;
  @Input() program: Program;
  public programEnrollment: ProgramEnrollment;
  public patient_data_source: Observable<any>;
  public asyncSelected = '';
  public typeaheadNoResults = false;
  public patientTypeaheadLoading = false;
  @Output() registerPatient: EventEmitter<any> = new EventEmitter<any>();
  @Output() continue_to_next_step: EventEmitter<any> = new EventEmitter<any>();
  maxDate: Date = new Date;
  bsValue: Date = new Date;

  constructor(public loaderService: LoaderService, public programService: ProgramService,
              public userService: UserService, public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    this.programEnrollment = this.programService.currentProgramEnrollmentObject ? this.programService.currentProgramEnrollmentObject : new ProgramEnrollment();
    this.patient_data_source = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.programService.getTypeAheadProgramEligiblePatients({token: token})));
  }

  save() {
    this.programEnrollment.patient_id = this.patient.id;
    this.programEnrollment.program_id = this.program.id;
    if ( !this.programEnrollment.id ) {
      this.programEnrollment.status = 'pending';
    }
    this.loaderService.small_loder_show();
    this.programService.enrollPatientWithProgram(this.programEnrollment).then(resp => {
      this.programEnrollment.load_from_json(resp);
      this.programService.currentProgramEnrollmentObject = this.programEnrollment;
      this.registerPatient.emit(this.patient);
      this.programService.eligiblePatientListener.next(this.patient.id)
      this.loaderService.small_loder_hide();
    }).catch(res => {
      console.log(res);
      this.loaderService.small_loder_hide();
      this.globalErrorHandlerService.error = res.error.error;
    })

  }

  continue() {
    this.continue_to_next_step.emit(true);
  }

  handle_user(patient: any) {
    if (patient.id) {
      this.getEligiblePatientObject(patient.id);
    }
  }

  getEligiblePatientObject(id: any) {
    this.loaderService.show();
    this.programService.getEligiblePatient(id).then(
      resp => {
        this.patient = new EligiblePatient().load_from_json(resp);
        this.loaderService.hide();
      }
    ).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
      console.log(resp)
    })
  }

  typeaheadOnPatientSelect(e: any) {
    this.patient = new EligiblePatient().load_from_json(e.item);
    let enrolment = this.patient.program_enrolments.filter(p => p.program_id === this.program.id)[0];
    if (enrolment) {
      this.programService.currentProgramEnrollmentObject = enrolment;
      this.registerPatient.emit(this.patient);
    }
  }

  changeTypeaheadNoResults(e: boolean) {
    this.typeaheadNoResults = e;
  }

  changePatientTypeaheadLoading(e: boolean) {
    this.patientTypeaheadLoading = e;
  }

  setTypeButtonCall(e) {
    const selectedType = e.target.value;
    if (e.target.checked) {
      this.programEnrollment.enrollment_types.push(selectedType);
    } else {
      const index = this.programEnrollment.enrollment_types.indexOf(selectedType);
      if (index !== -1) {
        this.programEnrollment.enrollment_types.splice(index, 1);
      }
    }
    this.programEnrollment.severity = undefined;
  }

  disabledSaveButton() {
    return (this.programEnrollment.enrollment_types.length == 0 ||
      (this.programEnrollment.enrollment_types.includes('ccm') && !this.programEnrollment.severity) ||
      this.programService.currentProgramEnrollmentObject.id)
  }

  selectedType(type) {
    return this.programEnrollment.enrollment_types.includes(type);
  }
}
