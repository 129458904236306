export class Vital {
  static independent_vitals = ['spo2', 'nprs', 'fbg', 'rbg', 'bmi', 'wt', 'ht'];
  id: number;
  vital_sign: string;
  short: string;
  unit: string;
  descriptors: string;
  age_stage: string;
  min_val: any;
  max_val: any;
  min_age: number;
  max_age: number;
  patient_id: number;
  is_customized: boolean;
  vital_type_id: number;
  gender: string;
  load_from_json(json) {
    this.id = json.id;
    this.vital_sign = json.vital_sign;
    this.short = json.short;
    this.unit = json.unit;
    this.descriptors = json.descriptors;
    this.age_stage = json.age_stage;
    this.min_val = json.min_val;
    this.max_val = json.max_val;
    this.max_age =  json.max_age;
    this.min_age = json.min_age;
    this.patient_id = json.patient_id;
    this.is_customized = json.is_customized;
    this.vital_type_id = json.vital_type_id;
    this.gender = json.gender;
    return this;
  }
  to_params() {
    return {
      id: this.id,
      min_val: this.min_val,
      max_val: this.max_val,
      patient_id: this.patient_id
    }
  }
}
