import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {LibrarySetting, Library} from '../routes/library-importer/library_setting';

@Injectable({
  providedIn: 'root'
})
export class LibraryImportService {

  constructor(private _tokenService: CustomHttpClient) { }

  create(library: Object): Promise<any> {
    return this._tokenService
      .post('library_settings', library)
      .toPromise()
      .then(response => response)
  }
  getLibraryTypes(): Promise<any> {
    return this._tokenService
      .get('library_settings/library_types')
      .toPromise()
      .then(response => response)
  }
  getLibraries(): Promise<any> {
    return this._tokenService
      .get('library_settings/libraries')
      .toPromise()
      .then(response => response)
  }

}
