<div class="flex-header filters-sec">
  <div class="row">
    <div class="col-sm-4">
      <input name="title" [(ngModel)]="filter.name" class="form-control" placeholder="Title" autocomplete="off">
    </div>
    <div class="col-sm-8">
      <button class="btn btn-tertiary btn-outline mr-sm" (click)="getAnnouncements()"><i class="fa fa-filter fa-fw"></i>Filter</button>
      <button class="btn btn-secondary" (click)="newAnnouncements()"><i class="fa fa-filter fa-fw"></i>Add</button>
    </div>
  </div>
</div>
<app-announcements-listing class="flex-body scroll-browser" [totalItems]="totalItems" (nextPage)="pageChanged($event)" [announcements]="announcements"></app-announcements-listing>
