<h4 class="m0 mb-lg">{{goal.name}}</h4>
<ul class="task-list list-unstyled border-list m0">
  <li class="list">
    <div class="media">
      <div class="media-left">
        <i class="fa icon-notebook fs16 vl-m mr"></i>
        <span class="field-label">Description</span>
      </div>
      <h5 class="media-body media-middle m0 fw-400">{{goal.description}}</h5>
    </div>
  </li>
  <li class="list">
    <div class="media">
      <div class="media-left media-middle">
        <i class="fa icon-check fs16 vl-m mr"></i>
        <span class="field-label">Assigned By</span>
      </div>
      <h5 class="media-body media-middle m0 fw-400" *ngIf="goal.assigned_by_name">{{goal.assigned_by_name}}</h5>
    </div>
  </li>
  <li class="list">
    <div class="media">
      <div class="media-left media-middle">
        <i class="fa icon-user fs16 vl-m mr"></i>
        <span class="field-label">Patient</span>
      </div>
      <h5 *ngIf="!goal.achiever_name" class="media-body media-middle m0 fw-300">No patient is associated</h5>
      <h5 class="media-body media-middle m0 fw-400">{{goal.achiever_name}}</h5>
    </div>
  </li>
  <li class="list">
    <div class="media">
      <div class="media-left media-middle">
        <i class="fa icon-star fs16 vl-m mr"></i>
        <span class="field-label">Status</span>
      </div>
      <div class="media-body media-middle">
        <label *ngIf="goal.status == 'not_started'" class="label label-danger m0">{{userService.removeUnderScore(goal.status)}}</label>
        <label *ngIf="goal.status == 'in_progress'" class="label label-info m0">{{userService.removeUnderScore(goal.status)}}</label>
        <label *ngIf="goal.status == 'completed'" class="label label-success m0">{{userService.removeUnderScore(goal.status)}}</label>
        <label *ngIf="goal.status == 'pending'" class="label label-warning m0">{{userService.removeUnderScore(goal.status)}}</label>
      </div>
    </div>
  </li>
</ul>
<app-comment class="p" [comments]="comments" [commentable_id]="goal.id" [commentable_type]="'Goal'" [commentable]="goal" [programme_type]="programme_type"></app-comment>
