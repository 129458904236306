<h4 class="text-bold">Monitoring</h4>
<div class="table-responsive">
  <table class="table table-hover table-bordered m0 text-center">
    <thead>
    <tr>
      <ng-container>
        <th class="text-center">Date & Time</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('temperature')">
        <th class="text-center">Temp <br>(F)</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('pulse')">
        <th class="text-center">Pulse <br>(BPM) <br> Rhythm</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('respiration')">
        <th class="text-center">Respiration <br> (BPM) <br> Difficulty</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('sBP') || checkColumnData('dBP')">
        <th class="text-center">B/P</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('weight')">
        <th class="text-center">Weight <br>(Lb)</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('height')">
        <th class="text-center">Height <br>(In)</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('bodyMassIndex')">
        <th class="text-center">BMI <br>(Lb/m2)</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('fbg') || checkColumnData('rbg')">
        <th class="text-center">Blood Glucose <br>(mg/dL)</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('spo2')">
        <th class="text-center">SpO2 <br>(%)</th>
      </ng-container>
      <ng-container *ngIf="checkColumnData('nprs')">
        <th class="text-center">Pain</th>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let pv of patientVitals">
      <tr>
        <td>
          {{pv.date | date:'shortDate'}}
          <br>
          {{pv.date | date:'shortTime'}}
        </td>
        <ng-container *ngIf="checkColumnData('temperature')">
          <td [tooltip]="pv.temperature?.descriptor" [class]="pv.temperature?.descriptor_class">{{pv.temperature?.value}}</td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('pulse')">
          <td [tooltip]="pv.pulse?.descriptor" [class]="pv.pulse?.descriptor_class">
            {{pv.pulse?.value}}
            <br>
            {{pv.pulse?.rhythm}}
          </td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('respiration')">
          <td [tooltip]="pv.respiration?.descriptor" [class]="pv.respiration?.descriptor_class">
            {{pv.respiration?.value}}
            <br>
            {{pv.respiration?.difficulty}}
          </td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('sBP') || checkColumnData('dBP')">
          <td>
            <span [tooltip]="pv.sBP?.descriptor" [class]="pv.sBP?.descriptor_class">{{pv.sBP?.value}}</span>
            <ng-container *ngIf="pv.dBP?.value">
              /
            </ng-container>
            <span [tooltip]="pv.dBP?.descriptor" [class]="pv.dBP?.descriptor_class">{{pv.dBP?.value}}</span>
          </td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('weight')">
          <td>{{ pv.weight?.value}}</td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('height')">
          <td>{{ pv.heightInInch }}</td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('bodyMassIndex')">
          <td [tooltip]="pv.bodyMassIndex?.descriptor" [class]="pv.bodyMassIndex?.descriptor_class">{{pv.bodyMassIndex?.value}}</td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('fbg') || checkColumnData('rbg')">
          <td>
            <ng-container *ngIf="pv.fbg?.value">
              <span [tooltip]="pv.fbg?.descriptor" [class]="pv.fbg?.descriptor_class">{{pv.fbg?.value}}</span>
            </ng-container>
            <ng-container *ngIf="pv.rbg?.value">
              <span [tooltip]="pv.rbg?.descriptor" [class]="pv.rbg?.descriptor_class">{{pv.rbg?.value}}</span>
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('spo2')">
          <td [tooltip]="pv.spo2?.descriptor"><span *ngIf="pv.spo2" [class]="pv.spo2?.descriptor_class">{{pv.spo2?.value}}</span></td>
        </ng-container>
        <ng-container *ngIf="checkColumnData('nprs')">
          <td [tooltip]="pv.nprs?.descriptor"><span *ngIf="pv.nprs" [class]="pv.nprs?.descriptor_class">{{pv.nprs?.value}}</span></td>
        </ng-container>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
