import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PatientEvent} from '../patient-event';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-health-calendar-event-tooltip',
  templateUrl: './health-calendar-event-tooltip.component.html',
  styleUrls: ['./health-calendar-event-tooltip.component.scss']
})
export class HealthCalendarEventTooltipComponent implements OnInit {
  @Input() event: PatientEvent;
  @Input() isViewMode: boolean;
  @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(public userService: UserService) { }

  ngOnInit(): void { }

  showEditButton(): boolean {
    return Boolean((this.isViewMode && !(this.event.event_type === 'appointment' && this.event.appointment_with)))
  }

}
