import {Component, OnInit} from '@angular/core';
import {TermComponent} from "../term/term.component";
import {EnoteService} from "../../services/enote.service";
import {Term} from "../enote_models/term";
import {Value} from "../enote_models/control";
import {LoaderService} from "../../services/loader.service";
import {BsModalService} from "ngx-bootstrap/modal";
@Component({
  selector: 'app-template-note',
  templateUrl: './template-note.component.html',
  styleUrls: ['./template-note.component.scss']
})
export class TemplateNoteComponent extends TermComponent implements OnInit {

  constructor(public _enote_service: EnoteService,public modalService: BsModalService,public loaderService: LoaderService) {
    super(_enote_service,modalService,loaderService);
  }

  ngOnInit() {
  }


}
