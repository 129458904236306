<form class="m0" (ngSubmit)="save()" #noteForm="ngForm">
  <div class="modal-body">
    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label class="field-label control-label">Note*</label>
      </div>
      <div class="col-md-10 col-sm-5">
            <textarea  cols="120" rows="6" type="text" class="form-control" id="note_body"
                       [(ngModel)]="patient_note.note_body"
                       name="note_body"
                       required>
            </textarea>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label class="field-label control-label">Type*</label>
      </div>
      <ng-container *ngIf="!add_note_type_input_show">
        <div class="col-md-5 col-sm-5">
          <select [(ngModel)]="patient_note.patient_note_type_id" class="form-control" name="patient_note_type" required>
            <option value=''>Type</option>
            <option *ngFor="let patient_note_type of patient_note_types; let i = index;" [ngValue]="patient_note_type.id">
              {{patient_note_type.name}}
            </option>
          </select>
        </div>
        <div class="col-md-5 col-sm-5" *ngIf="currentUser.current_user.isEnterprise">
          <button class="btn btn-secondary btn-outline" type="button" (click)="add_note_type_input_show = true">Add</button>
        </div>
      </ng-container>
      <ng-container *ngIf="add_note_type_input_show">
        <div class="col-md-5 col-sm-5">
          <input type="text" class="form-control" placeholder="Type of Note"
                 [(ngModel)]="add_note_type_input_text" name="note_type_input_text">
        </div>
        <div class="col-md-5 col-sm-5">
          <button class="btn btn-secondary" type="button" [disabled]="!add_note_type_input_text" (click)="add_note_type()">Save</button>
          <button class="btn btn-tertiary btn-outline ml" (click)="add_note_type_input_show = false">Cancel</button>
        </div>
      </ng-container>
      <div class="col-md-2 col-sm-3 sticky">
        <label class="field-label control-label ">Sticky</label>
        <input  type="checkbox"  [(ngModel)]="patient_note.is_sticky" name="is_sticky">
      </div>
    </div>
    <div class="row form-group" *ngIf="source_type !== 'ProgrammeEnrolment' && !isprogramme_profile">
      <div class="col-md-2 col-sm-3">
        <label class="field-label control-label">Time Spent</label>
      </div>
      <div class="col-md-5 col-sm-5">
        <input type="number" class="form-control" placeholder="Time Spent" [(ngModel)]="patient_note.time_spent" name="time_spent">
      </div>
      <div class="col-xs-2 pl0"><label class="field-label control-label">min</label></div>
    </div>
  </div>
  <div class="modal-footer">
    <a *ngIf="loaderService.uploading_in_process" class="btn btn-default mr"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
    <button type="submit" class="btn btn-secondary mr" [disabled]="!noteForm.form.valid" *ngIf="!loaderService.uploading_in_process">{{patient_note.id ? 'Update' : 'Save'}}</button>
    <a class="btn btn-danger btn-outline mr"  type="button" *ngIf="patient_note.id" (click)="deleteModal.show()">Delete</a>
    <button  type="button" class="btn btn-tertiary btn-outline" (click)="closeNewForm()">Cancel</button>
  </div>
</form>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px; width:500px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Note</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to delete the Note?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteNote(patient_note);">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #validationModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px; width:500px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="validationModel.hide()"><i class="fa fa-times fs16"></i></button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Note type must be selected”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-inverse" (click)="validationModel.hide();">Cancel</button>
      </div>
    </div>
  </div>
</div>


