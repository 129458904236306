import {Component, Input, OnInit} from '@angular/core';
import {Patient} from '../../../doctor/patient';
import {VitalsService} from '../../../../services/vitals.service';
import {LoaderService} from '../../../../services/loader.service';
import {Vital} from '../../../../routes/practice-site/vital/vital';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';

@Component({
  selector: 'app-vitals-ranges',
  templateUrl: './vitals-ranges.component.html',
  styleUrls: ['./vitals-ranges.component.scss']
})
export class VitalsRangesComponent implements OnInit {
  @Input() patient: Patient;
  public vitals: Vital[] = [];
  public hideVitals = ['ht', 'nprs', 'wt', 'bmi', 'spo2', 'fbg', 'rbg'];
  public newVitalArray = [];
  public shortVital = ['spo2', 'fbg', 'rbg']
  public showVitalsRanges: boolean;

  constructor(public vitalService: VitalsService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService) { }

  ngOnInit(): void {
    this.getVitalEntries();
  }

  getVitalEntries () {
    this.showVitalsRanges = false;
    this.loaderService.show();
    this.vitalService.getPatientCustomizedVitalsForRange(this.patient.id).then(resp => {
      if (resp) {
        this.vitals = resp.map(vital => new Vital().load_from_json(vital));
        this.setNewVitalArray(this.vitals);
        this.showVitalsRanges = true;
        this.loaderService.hide();
      }
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  saveVitalForPatient(vital: Vital) {
    this.vitals.find(a => (a.short === vital.short && a.descriptors === vital.descriptors)).is_customized = true;
    if (vital.patient_id) {
      this.updatePatientVitalRanges(vital);
    } else {
      this.savePatientVitalRanges();
    }
  }

  savePatientVitalRanges() {
    this.loaderService.show();
    this.vitals.map(v => {
      v.id = undefined;
      v.patient_id = this.patient.id;
    })
    this.vitalService.createPatientCustomizedVitalsForRange(this.vitals).then(resp => {
      if (resp) {
        this.vitals = resp.map(v => new Vital().load_from_json(v));
        this.setNewVitalArray(this.vitals);
      }
      this.loaderService.hide();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  updatePatientVitalRanges(vital: Vital) {
    this.loaderService.show();
    this.vitalService.updatePatientCustomizedVitalsForRange(vital).then(resp => {
      if (resp) {
        this.vitals.find(v => v.id === resp.id).load_from_json(resp);
        this.setNewVitalArray(this.vitals);
      }
      this.loaderService.hide();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  groupArrayOfObjects(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  setNewVitalArray(vitals: Vital[]) {
    this.newVitalArray = this.groupArrayOfObjects(vitals, 'short');
    this.newVitalArray['fbg']?.sort((a, b) => Number(a.min_val) - Number(b.min_val));
    this.newVitalArray['rbg']?.sort((a, b) => Number(a.min_val) - Number(b.min_val));
    this.newVitalArray['spo2']?.sort((a, b) => Number(a.min_val) - Number(b.min_val));
  }

}
