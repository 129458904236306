import { Injectable }     from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Service } from '../routes/practices/practices/practice'
import {CustomHttpClient} from './custom-http-client.service';


@Injectable()
export class ServicesService {

  constructor(private _tokenService: CustomHttpClient) { }

  search_services(term: string, extras?: any): Observable<any> {
    let params = new HttpParams();
    params = params.set('term', term);
    params = params.set('extras', extras);
    return this._tokenService
      .get('services', {params: params})
      .map((r) => r );

  }
  create(service: Service): Promise<any> {
    return this._tokenService
      .post(service.get_url(), service.to_params())
      .toPromise()
      .then(response => response);
  }

  save(service: Service): Promise<any> {
    if (service.is_new_record()) {

      return this.create(service);
    }
    else {
      return this.update(service);
    }
  }

  update(service: Service) {
    return this._tokenService
      .put(service.get_url(), service.to_params())
      .toPromise()
      .then(response => response);
  }
  getServices(id: any): Promise<any[]> {
    let params: HttpParams = new HttpParams();
    params =  params.set('client_id', id);

    return this._tokenService.get('services/client_services', {
     params: params
    }).toPromise().then(response => response);
  }
  delete(location_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('client_service_id', location_id);

    return this._tokenService.delete('services', {
       params: params
    }).toPromise().then(res => res);
  }
}
