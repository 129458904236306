import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CalendarEvent, CalendarView} from 'angular-calendar';
import {PatientEvent} from '../patient-event';
import {Patient} from '../../../doctor/patient';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import { differenceInMinutes, startOfDay, startOfHour } from 'date-fns';
import {CustomHttpClient} from '../../../../services/custom-http-client.service';
import {MessageService} from '../../../../services/message.service';
import {PatientEventService} from '../patient-event.service';
import {Subscription} from 'rxjs/Subscription';
import {Comment} from '../../../../routes/practice-site/tasks/comment';
import {CommentService} from '../../../../routes/practice-site/tasks/comment.service';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-health-calendar-view',
  templateUrl: './health-calendar-view.component.html',
  styleUrls: ['./health-calendar-view.component.scss']
})
export class HealthCalendarViewComponent implements OnInit, OnDestroy {
  @Input() events: CalendarEvent[] = [];
  @Input() view: CalendarView;
  @Input() viewDate: Date;
  @Input() weekStartsOn;
  @Input() patient: Patient;
  @ViewChild('newPatientEventModal') newPatientEventModal;
  @ViewChild('viewPatientEventModal') viewPatientEventModal;
  @ViewChild('scrollContainer') scrollContainer: ElementRef<HTMLElement>;
  public show_new_patient_event_model: boolean;
  public patientEvent: PatientEvent = new PatientEvent();
  public show_view_patient_event_model: boolean;
  public subscription: Subscription;
  public comments: Comment[] = [];

  constructor(public userService: UserService, public loaderService: LoaderService,
              public _tokenService: CustomHttpClient, public _message_service: MessageService,
              public patientEventService: PatientEventService,
              public commentService: CommentService) {
    this.subscription = this.patientEventService.scrollToLatestEvent.subscribe(item => {
      this.scrollToCurrentView(item ? item.start_date : undefined);
    });
  }

  ngOnInit(): void {}
  eventClicked(event) {
    this.patientEvent.load_from_json(event.meta.event);
    if (this.patientEvent.notification_ids && this.userService.current_user.isPatient) {
      this.markNotificationsAsRead(this.patientEvent.id);
    }
    this.getEventComments(this.patientEvent.id);
    this.show_view_patient_event_model = true;
    this.viewPatientEventModal.show();
  }
  hourSegmentClicked(event) {
    this.patientEvent = new PatientEvent();
    this.patientEvent.start_date = event.date;
    this.patientEvent.patient_id = this.patient.id;
    this.patientEvent.practice_id = this.userService.current_user.profileable['practice_id'];
    this.showNewEventModel();
  }

  closePatientEventModel() {
    this.newPatientEventModal.hide();
    this.show_new_patient_event_model = false;
    this.patientEvent = new PatientEvent();
  }

  editEvent(event: PatientEvent) {
    this.viewPatientEventModal.hide();
    this.show_view_patient_event_model = false;
    this.patientEvent.load_from_json(event);
    setTimeout(() => {
      this.showNewEventModel()
    }, 900);
  }

  showNewEventModel() {
    this.newPatientEventModal.show();
    this.show_new_patient_event_model = true;
    this.loaderService.hide();
  }

  private scrollToCurrentView(date: Date) {
    if (this.view === CalendarView.Day) {
      let dayStartTime = new Date();
      if (date) {
        let d = new Date(date)
        dayStartTime.setHours(d.getHours());
        dayStartTime.setMinutes(d.getMinutes());
        dayStartTime.setSeconds(d.getSeconds());
      } else {
        dayStartTime.setHours(8);
        dayStartTime.setMinutes(0);
        dayStartTime.setSeconds(0);
      }
      // each hour is 60px high, so to get the pixels to scroll it's just the amount of minutes since midnight
      const minutesSinceStartOfDay = differenceInMinutes(
        startOfHour(dayStartTime),
        startOfDay(new Date())
      );
      this.scrollContainer.nativeElement.scrollTop = minutesSinceStartOfDay;
    }
  }

  markNotificationAsRead(id: number) {
    this._tokenService
      .put('notifications/' + id, {})
      .toPromise()
      .then(response => {
          this._message_service.popPatientEventNotification(id);
        }
      );
  }

  markNotificationsAsRead(event_id: any): void {
    const params = new HttpParams().set('event_id', event_id);
    this._tokenService.get('notifications/events_as_read', { params })
      .toPromise()
      .then((response) => {
        if (response && response['ids'] && Array.isArray(response['ids'])) {
          response['ids'].forEach((id) => {
            this._message_service.popPatientEventNotification(id);
          });
        }
      })
      .catch((error) => {
        console.error('Error marking notifications as read:', error);
      });
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getEventComments(id) {
    this.commentService.getComments(id, 'PatientEvent').then(resp => {
      this.comments = resp.map(comment => new Comment().load_from_json(comment));
    })
  }

  closeViewEventModal() {
    this.patientEventService.showDeleteConformation = false;
    this.viewPatientEventModal.hide();
    this.comments = [];
  }
}
