<h4 class="text-primary">Analytics</h4>
<div class="row">
  <div class="col-sm-6 col-xs-12">
    <ul class="list-unstyled border-list m0">
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Successful Deliveries</span>
            <h4 class="media-body text-tertiary fw-300 m0">{{campaignAnalytics.successful_deliveries_count}}</h4>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Successful Deliveries Percentage</span>
            <h4 class="media-body text-secondary fw-300 m0">{{campaignAnalytics.successful_deliveries_percentage}}%</h4>
          </div>
        </li>
      <ng-container *ngIf="campaign.outreach_type === 'email'">
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Total Open</span>
            <h4 class="media-body text-secondary fw-300 m0">{{campaignAnalytics.total_open_count}}</h4>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Last Opened</span>
            <h4 class="media-body text-tertiary fw-300 m0">{{campaignAnalytics.last_opened}}</h4>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="col-sm-6 col-xs-12">
    <ul class="list-unstyled border-list m0">
      <ng-container *ngIf="campaign.outreach_type === 'email'">
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Clicks per unique opens</span>
            <h4 class="media-body text-secondary fw-300 m0">{{campaignAnalytics.clicks_per_unique_opens}}%</h4>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Total Clicks</span>
            <h4 class="media-body text-secondary fw-300 m0">{{campaignAnalytics.total_clicks_count}}</h4>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Last Clicked</span>
            <h4 class="media-body text-tertiary fw-300 m0">{{campaignAnalytics.last_clicked}}</h4>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="campaign.outreach_type === 'sms'">
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Total Failed Messages</span>
            <h4 class="media-body text-secondary fw-300 m0">{{campaignAnalytics.total_failed_messages}}</h4>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <span class="media-left field-label">Failed Messages percentage</span>
            <h4 class="media-body text-tertiary fw-300 m0">{{campaignAnalytics.total_failed_messages_percentage}} %</h4>
          </div>
        </li>
      </ng-container>

    </ul>
  </div>
</div>
