<div class="flex-control flex-column">
  <app-tabs>
    <li (click)="tabsService.deactivate_tab()" [class.active]="!tabsService.is_active()">Bills</li>
  </app-tabs>
  <div class="flex-control flex-column" [hidden]="tabsService.is_active()">
    <div class="filters-sec flex-header">
      <a class="btn btn-primary btn-outline btn-filter mb" data-toggle="collapse" href="#collapsefilters">
        <i class="fa fa-filter mr-sm"></i> Filters
      </a>
      <div class="collapse" id="collapsefilters">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-6">
            <input class="form-control" placeholder="Patient name, phone or email" (keyup.enter)="search()"
                   [(ngModel)]="filter['token']"
                   type="text">
          </div>
          <div class="col-lg-2 col-md-2 col-sm-6">
            <select class="form-control" [(ngModel)]="filter['billing_provider_id']" name="rendering_provider">
              <option value=undefined selected>Billing Provider Name</option>
              <option *ngFor="let doctor of providers; let i = index;" [value]="doctor.id">
                {{doctor.name}}
              </option>
            </select>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-6">
            <select [(ngModel)]="filter['program_id']"  class="form-control">
              <option value=undefined selected>Program</option>
              <option  *ngFor="let program of programsList" [value]="program.id">{{program.name}}-{{program.type}}</option>
            </select>
          </div>
          <div class="col-lg-1 col-md-2 col-sm-6">
            <input [bsConfig]="{ minMode:'month',dateInputFormat: 'MM-YYYY', clearBtn: true}"
                   [(ngModel)]="selectMonth"
                   (ngModelChange)="setMonthAndYear($event)"
                   [maxDate]="maxDate"
                   bsDatepicker
                   class="form-control"
                   placeholder="Month">
          </div>
          <div class="col-lg-1 col-md-2 col-sm-6">
            <select [(ngModel)]="filter['status']"  class="form-control">
              <option value=undefined selected>Status</option>
              <option value='non_billable'>Non Billable</option>
              <option value='pending'>Pending</option>
              <option value='billed'>Billed</option>
            </select>
          </div>
          <div class="col-lg-1 col-md-2 col-sm-6">
            <input type="number" class="form-control" placeholder="Items Per Page"
                   [(ngModel)]="filter['per_page']" (keyup.enter)="search()" name="items_per_page">
          </div>
          <div class="col-lg-2 col-md-3 col-sm-6">
            <button class="btn btn-tertiary btn-outline mr" type="button" (click)="search()"><i class="fa fa-filter fa-fw"></i> Filter</button>
            <button class="btn btn-secondary" type="button" *ngIf="showExportButton" (click)="exportTableToExcel()"><i class="fa fa-book fa-fw"></i> Export</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="flex-body scroll-browser">-->
    <div class="flex-body">
      <table class="table table-hover m0" #TABLE>
        <thead>
        <tr>
          <th>Name</th>
          <th>DOB</th>
          <th>Gender</th>
          <th>Contact</th>
          <th>Program</th>
          <th>Program Type</th>
          <th>Enrollment Date</th>
          <th>Billing Provider</th>
          <th>Diagnosis</th>
          <th *ngIf="programme_category === 'cms_programme'">CPT</th>
          <th *ngIf="programme_category === 'private_programme'">Package</th>
          <th>Time Spent</th>
          <th>Amount</th>
          <th>Billing Month</th>
          <th>Status</th>
          <th style=" width:100px;">Action</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let bill of bills">
          <tr>
            <td class="text-capitalize"><a (click)="openEnrolledPatient(bill.patient_json, bill.programme_json)">{{bill.name}}</a></td>
            <td>{{bill.dob | date: 'longDate'}}</td>
            <td>{{bill.gender}}</td>
            <td>
              <a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(bill.phone_number, {name: bill.name, pic: bill.patient_json.patient_profile_pic})">
                {{bill.phone_number | mask: '(000) 000-0000'}}
              </a>
            </td>
            <td>{{bill.programme}}</td>
            <td>
              <span *ngFor="let p of bill.enrolled_in ;let i=index">
                {{userService.removeUnderScore(p) | uppercase}}
                <span *ngIf="i != bill.enrolled_in?.length - 1" class="mr">,</span>
              </span>
            </td>
            <td>{{bill.enrolment_date}}</td>
            <td>{{bill.billing_provider_name}}</td>
            <td>
              <ng-container *ngFor="let diagnose of bill.diagnosis; let i = index;">
                <span placement="bottom" ngbTooltip="{{diagnose[1]}}"> {{diagnose[0]}}</span><span *ngIf="i != bill.diagnosis.length - 1">,</span>
              </ng-container>
            </td>
            <td *ngIf="programme_category === 'cms_programme'">
              <ng-container *ngFor="let cpt of bill.cpt_codes;let i=index">
                <span> {{cpt[0]}}</span><span *ngIf="i != bill.cpt_codes.length - 1">,</span>
              </ng-container>
            </td>
            <td *ngIf="programme_category === 'private_programme'">{{bill?.programme_json?.private_package_name}}</td>
            <td>{{bill.time_spent}}min</td>
            <td>${{bill.amount}}</td>
            <td>{{bill.bill_month}}</td>
            <td class="text-capitalize">{{bill.status}}</td>
            <td>
              <a *ngIf="bill.uploading" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
              <ng-container *ngIf="!bill.uploading">
                <a class="btn btn-secondary btn-outline mr-sm" *ngIf="bill.status.toLowerCase() == 'pending'" tooltip="Sign Superbill" (click)="signBill(bill)" ><i class="fa fa-dollar"></i></a>
                <a class="btn btn-secondary btn-outline" tooltip="Print" (click)="reportModel.openCloseModal( true, '', bill, selectMonth)"><i class="fa fa-print"></i></a>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <div class="text-center" *ngIf="bills && totalItems > displayItemsNumber" >
        <div>{{displayItemsNumber}}/{{totalItems}}</div>
        <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="filter['per_page']" (pageChanged)="pageChanged($event)"></pagination>
      </div>
      <app-no-result-found *ngIf="bills && bills.length == 0"></app-no-result-found>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #signBillModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px; width:500px;">
    <div class="modal-content">
      <div class="modal-body">
        <div class="alert alert-warning p m0 text-center">“This patient is Non-Compliant, do you still want to sign this bill?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="signedSuperBill(selectedBill)">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="closeSignBillModal()">No</button>
      </div>
    </div>
  </div>
</div>

<app-report-selection #reportModel [programme_category]="programme_category"></app-report-selection>

<div class="modal fade" bsModal #selectTypeFromBillModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="selectTypeFromBillModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Select a Program Type</h4>
      </div>
      <div class="modal-body">
        <div class="row form-group mb0">
          <div class="modal-col col-md-12  col-xs-12" style="justify-content: center; align-items: center; display: flex;">
            <ng-container *ngFor="let type of enrolled_in">
              <label class="fw-500 mr-lg text-capitalize">
                <input type="radio" class="ml" name="{{type}}" [value]="type" [(ngModel)]="selectProgramType"  />
                {{type}}
              </label>
            </ng-container>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="selectProgramTypeToOpen();selectTypeFromBillModal.hide()" [disabled]="!selectProgramType">Continue</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="closeSelectProgramModel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
