<ng-container *ngIf="doctor2">
  <div class="doc-list clearfix" *ngIf="is_component_base" [class.dexpanded]="doctor.show_profile_detail">
    <div class="col-xs-6">
      <div class="media">
        <div class="media-left">
          <img class="img-rounded thumbnail thumb64" [src]="doctor.profile_pic.modal.img" alt="Image" />
        </div>
        <div class="media-body">
          <h4 class="mb fw-500"><a href="javascript:void(0)" (click)="toggle_view(doctor)">{{doctor.complete_name}}</a></h4>
          <h5 class="m0">{{doctor.designation}}</h5>
        </div>
      </div>
    </div>
    <div class="col-xs-4">
      <span class="pr-lg" *ngIf="user_service.showNumber('phone', doctor.client.contact_numbers)">
        <a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(user_service.showNumber('phone', doctor.client.contact_numbers),
                    {name: doctor.complete_name, pic: doctor.profile_pic.modal.img})">
        <em class="fa fa-mobile"></em>&nbsp; {{user_service.showNumber('phone', doctor.client.contact_numbers) | mask: ' (000) 000-0000'}}
        </a>
      </span>
      <span *ngIf="user_service.showNumber('fax', doctor.client.contact_numbers)">
        <a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(user_service.showNumber('fax', doctor.client.contact_numbers),
                    {name: doctor.complete_name, pic: doctor.profile_pic.modal.img})">
        <em class="fa icon-phone"></em>&nbsp; {{user_service.showNumber('fax', doctor.client.contact_numbers) | mask: ' (000) 000-0000'}}
        </a>
      </span>
      <h5 class="mb0"><em class="fa icon-envelope vl-m"></em>&nbsp; {{doctor.client.email}}</h5>
    </div>
    <div class="col-xs-2">
      <a class="d-block text-right" href="javascript:void(0)" (click)="toggle_view(doctor)">
        {{doctor.show_profile_detail ?  "Show Less Info" : "See Complete Info"}}
        <em class="fa fs20 vl-m pl-sm" [class.fa-plus-square-o]="!doctor.show_profile_detail" [class.fa-minus-square-o]="doctor.show_profile_detail"></em>
      </a>
    </div>
  </div>
  <div class="p-lg flex-body scroll-browser" *ngIf="doctor && doctor.show_profile_detail && user_service.current_user">
    <ng-container *ngIf="doctor.edit_doctor">
      <h3 class="mt0 mb-xl text-primary">Edit doctor Profile</h3>
      <app-doctor-form [doctor]="doctor" (form_hidden)="handle_form_hidden()" (edited_doctor)="get_updated_doctor($event)" ></app-doctor-form>
    </ng-container>
    <ng-container *ngIf="!doctor.edit_doctor">
      <div class="media overflow-visible">
        <div class="media-left text-center">
          <img class="img-circle thumb150" [src]="doctor.profile_pic.modal.img" alt="Image"/>
          <a class="btn btn-primary btn-outline mt-lg" (click)="editDoctor()"
             *userCan="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions">
            <i class="fa fa-pencil mr-sm"></i> Edit Profile
          </a>
        </div>
        <div class="media-body pl-lg" permittedClass [condition]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions">
          <h3>{{doctor.complete_name}}</h3>
          <h4>{{doctor.designation}}</h4>
          <div class="row mt-lg">
            <div class="col-sm-7 doc-user-info">
              <span class="mr"><i class="fa icon-location-pin"></i>&nbsp; {{doctor.client.address.line_1_line_2()}}<br>{{doctor.client.address.city_state_zip()}}</span>
              <span class="mr" *ngIf="user_service.showNumber('phone', doctor.client.contact_numbers)">
            <i class="fa fa-mobile"></i>&nbsp; {{user_service.showNumber('phone', doctor.client.contact_numbers) | mask: '(000) 000-0000'}}
          </span>
              <span class="mr" *ngIf="user_service.showNumber('fax', doctor.client.contact_numbers)">
            <i class="fa fa-phone"></i>&nbsp; {{user_service.showNumber('fax', doctor.client.contact_numbers) | mask: '(000) 000-0000'}}
          </span>
              <span>
            <i class="fa icon-envelope vl-m"></i> &nbsp;{{doctor.client.email}}
          </span>
            </div>
            <div class="col-sm-5">
              <div class="text-right">
                <ng-container *ngIf="doctor.cnic">
                  <span class="pr-lg"><label class="fw-500">CNIC</label>&nbsp; {{doctor.cnic}}</span>
                </ng-container>
                <ng-container *ngIf="doctor.regno">
                  <span><label class="fw-500">RegNo</label>&nbsp; {{doctor.regno}}</span>
                </ng-container>
              </div>
            </div>
          </div>
          <hr class="hr-line">
          <div class="mb-xl" *ngIf="doctor.client.has_specialty()">
            <label class="fw-500 d-block">Specialties</label>
            <span class="mr" *ngFor="let specialty of doctor.client.specialties">{{specialty.description}}</span>
          </div>
          <div class="mb-xl" *ngIf="doctor.client.has_service()">
            <label class="fw-500 d-block">Services</label>
            <span class="mr" *ngFor="let service of doctor.client.services">{{service.name}},</span>
          </div>
          <div class="clearfix mb-lg">
            <label class="fw-500">Summary</label>
            <div [innerHTML]="doctor.summary | safeHtml"></div>
          </div>
          <div class="clearfix mb-lg">
            <ng-container *ngFor="let category of doctor.achievement_categories_array">
              <div hidden *ngIf="category.parent_id"></div>
              <h5 class="fw-500 clearfix mb0" style="clear:both;" *ngIf="category.parent_id == null && category.doctors_achievements">{{category.name}}</h5>
              <ng-container *ngIf="category.doctors_achievements">
                <ng-container *ngIf="category.parent_id != null">
                  <div class="col-xs-4 mb-xl">
                    <h5 class="text-uppercase text-primary mb-sm">{{category.name}}</h5>
                    <div class="mb" *ngFor="let doctor_achievement of category.doctors_achievements">
                      <span *ngIf="doctor_achievement.title">{{doctor_achievement.title}},</span>
                      <span *ngIf="doctor_achievement.institution_name">{{doctor_achievement.institution_name}},</span>
                      <span class="pl-sm fs12 text-bold" *ngIf="doctor_achievement.start_date">{{doctor_achievement.yearsRange()}}</span>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <h5 class="fw-500">Media</h5>
          <ul class="practice-attach-img clearfix p0 m0 list-unstyled" *ngIf="doctor && doctor.id">
            <ng-container *ngIf="get_only_pictures().length > 0">
              <li class="active white-container-box pull-left p mr-lg">
                <ng-container *ngFor="let doc_image of get_only_pictures() ; let doc_i = index">
                  <div class="holder text-center" [ngClass]="{active: doc_image.active_class}">
                    <div class="thumbnail thumb80 p-relative">
                      <a *userCan="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions" class="icon icon-del text-danger" href="javascript:void(0)" (click)="makeActiveInactive(doc_image)"><i class="fa fa-trash"></i></a>
                      <a class="icon icon-yes text-success" href="javascript:void(0)" (click)="removePicture(doc_image,doc_i)"><i class="fa fa-check"></i> Yes</a>
                      <a class="icon icon-no text-danger" href="javascript:void(0)" (click)="makeActiveInactive(doc_image)"><i class="fa fa-times"></i> No</a>
                      <img [src]="doc_image.img" alt="">
                    </div>
                    <h5 class="text-capitalize mb0">{{doc_image.picture_type}}</h5>
                  </div>
                </ng-container>
              </li>
            </ng-container>
          </ul>
          <div *ngIf="doctor">
            <app-attachments *userCan="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions" (document_uploaded)="handle_image_upload($event)" [attachable_type]="'Doctor'" [attachable_id]="doctor.id"></app-attachments>
          </div>
        </div>
      </div>
      <div class="clearfix pt-lg">
        <a class="pull-right btn btn-inverse" *userCan="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
           (click)="calendar_form()">Add Calendar</a>
        <h4>Calendar</h4>
        <app-calendar-form [permissions]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
                           *ngIf="show_calendar_form" (form_hide)="handle_hide_form($event)"
                           (edited_calendar)="calendar_saved($event)" [calendarable_id]="doctor.id"
                           [calendarable_type]="'Doctor'" [calendar]="new_calendar"
                           [calendar_locations]="practice_calendar_locations()">
        </app-calendar-form>
        <ng-container *ngIf="show_calendar_listing">
          <app-calendars-listing [permissions]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
                                 [calendars]="doctor_calendars()" [calendar_locations]="practice_calendar_locations()">
          </app-calendars-listing>
        </ng-container>
      </div>
      <div class="clearfix pt-lg">
        <a class="pull-right btn btn-inverse" *userCan="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
           (click)="block_calendar_form()">Add Block Calendar</a>
        <h4>Block Calendar</h4>
        <app-block-calendar-form [permissions]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions" *ngIf="show_block_calendar_form" (block_form_hide)="handle_block_calendar_hide_form()" (edited_block_calendar)="block_calendar_saved($event)" [block_calendar_locations]="practice_calendar_locations()" [calendarable_id]="doctor.id" [calendarable_type]="'Doctor'" [block_calendar]="new_block_calendar"></app-block-calendar-form>
        <ng-container *ngIf="show_block_calendar_listing">
          <app-block-calendars [permissions]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions" [block_calendars]="doctor_block_calendars()" [block_calendar_locations]="practice_calendar_locations()"></app-block-calendars>
        </ng-container>
      </div>
      <div class="clearfix pt-lg">
        <div class="row">
          <div class="col-xs-2"><h4>Services</h4></div>
          <div class="col-xs-10">
            <input class="form-control" placeholder="Associate service to doctor"
                   [(ngModel)]="token" [typeahead]="data_source" typeaheadWaitMs="500" (typeaheadLoading)="changeTypeaheadLoading($event)"
                   [typeaheadAsync]="true"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'name'"/>
          </div>
        </div>
        <div class="white-container-box p-lg mt" *ngIf="associate_doctor_amenity">
          <h4 class="mt0">Associate Service</h4>
          <hr class="hr-line mt-lg mb">
          <h3 class="mt0 fs22 lhn">{{associate_doctor_amenity.name}}</h3>
          <h4 class="lhn"><span class="media-body media-middle m0" [innerHTML]="associate_doctor_amenity.short_description | safeHtml"></span></h4>
          <div class="clearfix mt-lg">
            <button class="btn btn-primary mr" [disabled]="associate_amenity_with_doctor_button ? true : false" (click)="associate_amenity_with_doctor()">Accept</button>
            <a class="btn btn-inverse" (click)="renew_associate_amenity()">Cancel</a>
          </div>
        </div>
        <ng-container>
          <app-amenity-associate [associate_ammenity_array]="doctor.amenity_associators"  [locations]="practice_calendar_locations()"></app-amenity-associate>
        </ng-container>
      </div>
      <div class="clearfix pt-lg" *ngIf="doctor && doctor.practices">
        <h4>Associated Practices</h4>
        <table class="table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th style="width:140px;">Visit Charges</th>
            <th style="width:140px;">Doctor Share</th>
            <th style="width:140px;">Virtual Visit Charges</th>
            <th class="text-center">Update</th>
            <th class="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let associated_practice of doctor_associated_practices()">
            <tr>
              <td>{{associated_practice.name}}</td>
              <td>{{associated_practice.complete_address}}</td>
              <td>{{associated_practice.phone_number}}</td>
              <td>
                <input class="form-control" type="text" id="charges{{associated_practice.id}}" [value]="doctor_visit_charges(doctor.id,associated_practice.id,'visit')" />
              </td>
              <td>
                <div class="input-group">
                  <input class="form-control" type="text" id="share{{associated_practice.id}}" [value]="doctor_fees_share(doctor.id,associated_practice.id)" />
                  <span class="input-group-addon text-bold">%</span>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input  class="form-control" type="text" id="virtual{{associated_practice.id}}" [value]="doctor_visit_charges(doctor.id,associated_practice.id,'video')" />
                </div>
              </td>
              <td class="text-center">
                <button class="btn btn-primary" (click)="updateChargesShare(doctor.id, associated_practice.id)">Update</button>
              </td>
              <td class="text-center">
                <a *userCan="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
                   (click)="classicModal.show()">
                  <i class="fa fa-times text-danger"></i>
                </a>
              </td>
            </tr>
            <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
              <div class="modal-dialog modal-md" style="height:242px;">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
                    <h4 class="m0 text-center">Remove</h4>
                  </div>
                  <div class="modal-body">
                    <div class="alert alert-danger text-center">
                      If doctor's calendar already exists on one of the location of this practice,
                      system will remove doctors calendar as well as all its appointments will be cancelled.
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" (click)="delete_doctor_association_with_practice(associated_practice);">Yes</button>
                    <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          </tbody>
        </table>
        <ng-container *ngIf="show_error">
          <div class="alert alert-success text-center mb0">Doctor visit data has been updated</div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
