import {Component, Injector, Input, OnInit} from '@angular/core';
import {PatientNoteComponent} from '../../../enote/patient-note/patient-note.component';
import {ProgramService} from '../../program.service';
import {LoaderService} from '../../../services/loader.service';
import {Template} from '../../../enote/enote_models/template';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {Program} from '../../program';

@Component({
  selector: 'app-enrolment-patient-care-plan',
  templateUrl: './enrolment-patient-care-plan.component.html',
  styleUrls: ['./enrolment-patient-care-plan.component.scss']
})
export class EnrolmentPatientCarePlanComponent implements OnInit {
  public custom_injector: Injector;
  public patientNoteComponent: any = PatientNoteComponent;
  public programCarePlan: Template;
  public stage: string;
  public note_id: any;
  @Input() program: Program;

  constructor(public loaderService: LoaderService, public injector: Injector,
              public programService: ProgramService, public globalErrorHandlerService: GlobalErrorHandlerService) { }
  ngOnInit(): void {
    if (this.programService.currentProgramEnrollmentObject.id) {
      this.getCarePlansOfProgram(this.program.id, this.programService.currentProgramEnrollmentObject.patient_id);
    }
    this.setCustomInjector();
  }

  setCustomInjector() {
    this.custom_injector  = Injector.create([
      {provide: 'patientID', useValue: this.programService.currentProgramEnrollmentObject.patient_id},
      {provide: 'source_type', useValue: 'ProgrammeEnrolment'},
      {provide: 'source_id', useValue: this.programService.currentProgramEnrollmentObject.id},
      {provide: 'stage', useValue: this.stage},
      {provide: 'note_id', useValue: this.note_id},
      {provide: 'isProgramOrPatientProfile', useValue: true}
      ], this.injector);
  }

  getCarePlansOfProgram(program_id: any, patient_id: any) {
    this.loaderService.show();
    this.programService.getProgramCarePlan(program_id, patient_id).then(resp => {
      this.programCarePlan = new Template().load_from_json(resp);
      if (this.programCarePlan.id)  {
        this.stage = this.programCarePlan.stage;
        this.note_id = this.programCarePlan.id;
      } else {
        this.stage = 'create_enote';
      }
      this.setCustomInjector();
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

}
