import {Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MessageService} from '../../services/message.service';
import {VideoChatService} from '../../video-chat/services/videochat.service';
import {UserService} from '../../services/user.service';
import {AlertService} from '../../_alert';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {
  private signup_successfully: any;


  constructor( private route: ActivatedRoute, public videoChatService: VideoChatService, public userService: UserService,
               private router: Router, private _message_service: MessageService, private injector: Injector,
               public alertService: AlertService) {
  }


  ngOnInit() {
    this._message_service.set_notifications();
    this.route.queryParams
      .subscribe((params: Params) => {
        this.signup_successfully = params['show_success_message'];
        if (this.signup_successfully) {
          this.practiceSignupSuccessfully();
        }
        if (params['show_login']) {
          this.userService.showLogin();
          this.router.navigate([], {
            replaceUrl: true, // Replace the current URL without adding it to history
          });
        }
      })
  }
  get display_home() {
    return this.router.url.split('?')[0].split('/').filter(a => a.length > 0 && !a.startsWith('#')).length === 0
  }

  private practiceSignupSuccessfully() {
    this.userService.toggleLogin();
    this.userService.user_email_verified_successfully = true;
    setTimeout(() => {
      this.userService.user_email_verified_successfully = false;
    }, 20000);
  }
}
