<h4 class="fs28 mt0"><i class="fa icon-calendar fa-fw"></i> Book an Appointment</h4>
<p>Select you experience with wellwink</p>
<div class="row form-group">
  <div class="col-xs-6 btn-col">
    <button class="patient-btn btn btn-success btn-lg col-xs-12 text-bold" [class.active]="new_patient == true" (click)="new_patient = true">
      <i class="fa fa-user-plus fa-fw"></i> I am a New Patient
    </button>
  </div>
  <div class="col-xs-6 btn-col2">
    <button class="patient-btn btn btn-primary btn-lg col-xs-12 text-bold" [class.active]="new_patient == false" (click)="new_patient = false">
      <i class="fa fa-user-circle fa-fw"></i> I am Existing Patient
    </button>
  </div>
</div>
<div *ngIf="new_patient == true"><app-sign-up (patientCreated)="onPatientCreated($event)"></app-sign-up></div>
<div *ngIf="new_patient == false"><app-login-form></app-login-form></div>
