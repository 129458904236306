import {Component, OnInit, Injector, ReflectiveInjector} from '@angular/core';
import {Practice} from '../practices/practices/practice';
import {DashboardsService} from '../../services/dashboards.service';
import {TabsService} from '../../services/tabs.service';
import {WizardComponent} from '../practices/wizard/wizard.component';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-practice-profile',
  templateUrl: './practice-profile.component.html',
  styleUrls: ['./practice-profile.component.scss']
})
export class PracticeProfileComponent implements OnInit {
  public practice: Practice = new Practice();
  public custom_injector: Injector;
  public wizardComponent = WizardComponent;

  constructor(private dashboadService: DashboardsService, private loadService: LoaderService, private injector: Injector) { }

  ngOnInit() {
    this.loadService.show();
    this.dashboadService.getCurrentUserPractice().then(resp => {
      this.practice.load_from_json(resp)
      let inputProviders = [{provide: 'id', useValue: this.practice.id}, {provide: 'practice_permissions', useValue: 'edit,Practice Profile,settings'}, {provide: 'doctor_permissions', useValue: 'edit,doctors,settings'}];
      this.custom_injector = Injector.create(inputProviders, this.injector);
      this.loadService.hide();
    });
  }


}
