import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class PublicServiceService {

  constructor(private router: Router) { }
  go_to_search(app_type?: string) {
    this.router.navigate(['/search'], {queryParams: {app_type: app_type}}).then(r => console.log(r));
   }
}
