<div class="flex-control flex-column">
  <div class="flex-header" *userCan="'Add,Ratings,Settings'">
    <div class="row">
      <label class="col-sm-2 field-label control-label">Add Measure*</label>
      <div class="col-sm-3">
        <div class="input-group">
          <input required [(ngModel)]="ratingType.name" class="form-control">
          <span class="input-group-btn">
          <a class="btn btn-secondary" (click)="add()"><i class="fa fa-plus"></i></a>
        </span>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-body p">
    <ul class="list-unstyled border-list">
      <li class="list" *ngFor="let rating of ratingTypes">
        <h5 class="m0 fw-300">{{rating.name}}
          <a clas="btn ml" *userCan="'delete,ratings,settings'">
            <i class="fa fa-times text-danger" (click)="remove(rating)"></i>
          </a>
        </h5>
      </li>
    </ul>
  </div>
</div>
