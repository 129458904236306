// truncate.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, arg1: any , arg2: any): string {
    let args = [arg1, arg2];
    let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
    let trail = args.length > 1 && args[1] ? args[1] : '...';

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
