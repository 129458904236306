import {PatientDocument} from '../../health/patient-profile/patient-dashboard/chart/document';

export class ProgramDocument {
  id: any;
  programme_enrolment_id: any;
  document_attributes: PatientDocument;

  load_from_json(json) {
    this.id = json.id;
    this.document_attributes = json.document_attributes;
    this.programme_enrolment_id = json.enrolment_id;
    return this;
  }

  to_params() {
    return {
      enrolment_document: {
        id: this.id,
        document_attributes: this.document_attributes,
        programme_enrolment_id: this.programme_enrolment_id,
      }
    }
  }
}
