import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {PracticePackage} from '../routes/practices/practices/practice';
import {HttpParams} from "@angular/common/http";
import {Observable} from 'rxjs';
import {PracticePackagesInfo} from '../routes/practices/billing/billing.component';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  constructor(private _tokenService: CustomHttpClient) { }

  getPackages(): Promise<any>  {
    return this._tokenService.get('packages').toPromise()
      .then(res => res);
  }
  getPracticePackage(practice_id): Promise<any>  {
    return this._tokenService.get('packages/practice_package/' + practice_id).toPromise()
      .then(res => res);
  }
  createPracticePackage(practicePackage: PracticePackage): Promise<any>  {
    return this._tokenService.post('packages/practice_package', practicePackage.to_params()).toPromise()
      .then(res => res);
  }
  updatePracticePackage(practicePackage: PracticePackage): Promise<any>  {
    return this._tokenService.put('packages/practice_package/' + practicePackage.id, practicePackage.to_params()).toPromise()
      .then(res => res);
  }


  // aun work
  getPracticePackages(practice_id: number): Observable<PracticePackagesInfo> {
    return this._tokenService.get('packages/practice_packages_info/' + practice_id)
  }

  updatePackage(params): Observable<PracticePackagesInfo> {
    return this._tokenService.post('packages/change_practice_packages',  params)
  }
}
