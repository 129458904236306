<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Practice Marketing</h1>
      <h4 class="sub-title fs22 text-primary">Simplified marketing tool which you can manage yourself.</h4>
      <h4 class="sub-title text-white">
        Email & SMS marketing for healthcare providers is the driver of consistent patient growth and practice branding
        to outreach as industry leaders.
        <br><br>
        Healthcare marketing allows physicians, hospitals, and medical practices to stay
        in front of existing patients, reach potential patients, and educate new patients.
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="text-center text-black">Benefits of Using WellWink Email Marketing</h1>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        E-Mail as Preferred Method of Communication
      </h4>
      <p class="m0">
        The patients have spoken! In a recent study, survey results revealed that the preferred method of receiving healthcare
        information is via e-mail for every age group. Trailing far behind e-mail communications is communication by mail,
        phone and then text messaging.
      </p>
    </div>
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        Enables Fast Communication
      </h4>
      <p class="m0">
        One of the biggest benefits of email marketing is that it’s a quick and easy messaging technique, allowing for
        speedy communication between health care providers and their patients. Being able to quickly and efficiently send
        out information in response to changing healthcare needs, email marketing can make your patients think of your
        health care business as the go-to source for the latest developments.
      </p>
    </div>
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        Extremely Cost-Effective
      </h4>
      <p class="m0">
        Email marketing is one of the most cost-effective communication tools available. That means working to foster
        relationships with your patients is more affordable than ever before when you use email marketing.
      </p>
    </div>
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        Targets Select Audiences
      </h4>
      <p class="m0">
        Contact lists can easily be segmented so that certain messages are sent to select audiences. Messages can be sent
        to targeted groups based on their specific needs when using email marketing. This method of communication allows
        health care providers to send the appropriate information to the corresponding patients.
      </p>
    </div>
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        Personalized Communication
      </h4>
      <p class="m0">
        Everyone loves to see their own name when they open an email. It’s very easy to personalize email marketing messages
        and customize them for individual patients. When patients feel personally addressed by your company, this can
        generate patient loyalty, especially when you’re dealing with very sensitive matters. Customized communication
        in the health care industry can help you maintain relationships and is a great way to show that you care about
        your patients.
      </p>
    </div>
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        Increased Online Interaction
      </h4>
      <p class="m0">
        One way to bridge the gap between your patients and your business is to do email marketing. This is particularly
        important for health care businesses because a strong business-patient relationship is key to patient satisfaction.
        By sending out emails to patients, you create an opportunity for them to interact with your brand by linking
        them out to your website.
      </p>
    </div>
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        Newsletter Distribution
      </h4>
      <p class="m0">
        Email marketing is the perfect medium for distributing newsletters and gaining exposure without boundaries.
        With email marketing, you have no geographic boundaries restricting you, which means you can send out newsletters
        to a wide-spread audience. Maybe you’ll choose to share patient testimonials, introduce new staff members, inform
        people of seasonal information, or even update them on the newest technology or services in your newsletters.
      </p>
    </div>
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        Automated Campaigns
      </h4>
      <p class="m0">
        Email marketing makes sending messages on time easier than ever before, especially when compared to direct mail.
        Your email marketing campaigns can be scheduled out weeks in advance by automating the times and dates of the emails.
      </p>
    </div>
    <div class="white-container-box mb-lg pr-xl pl-xl pt-lg pb-lg">
      <h4 class="mt0 fs18 fw-500 text-primary">
        <i class="fa fa-chevron-circle-right"></i> &nbsp;
        Track Marketing Campaign Success
      </h4>
      <p class="m0">
        WellWink allows health care marketers to easily track the success of email marketing campaigns. When reviewing
        campaigns, you’ll want to pay specific attention to your open rates and click-through rates. According to the email
        marketing benchmarks, businesses in the medical, dental and healthcare industries average an open rate of 22.76%
        and a click-through rate of 3.07%.
      </p>
    </div>
  </div>
</section>
