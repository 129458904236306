import { Component, Injector } from '@angular/core';
import { LeadService }    from './leads.service';
import { Practice } from '../practices/practices/practice'
import { WizardComponent } from '../practices/wizard/wizard.component'
import {Tab} from '../../tabs/tab'
import {TabsService} from '../../services/tabs.service'
@Component({
    selector: 'leads',
    templateUrl: './leads.component.html',
    styleUrls: [ './leads.component.scss' ],
    providers: [LeadService]
})

export class LeadsComponent {
    public practices: Practice[];
    public practice: Practice = new Practice();
    public practice_array: Practice[] = [];
    public users_having_rejection_ability: any;
    public practice_id: number;
    public rejection_reason: string;
    public stage_ids = [1, 2, 3];
    public menu_items: Object = {1: 'New', 2: 'Register', 3: 'Rejected'};
    public selected_stage_id: number;
    public values: string[] = ['In Progress', 'Pending', 'On Hold', 'Completed']
    public totalItems: string;
    public current_page = '1';
    constructor(private leadService: LeadService, public tabs_service: TabsService, private injector: Injector) {
        this.selected_stage_id = 1;
        this.get_fresh_practices();
        this.tabs_service.register('leads_components');
    }

    get_fresh_practices(): void {
      this.leadService.getPracticesByStageType(this.selected_stage_id, this.current_page).subscribe(response => {
        this.practices = response.leads.practices_data;
        this.totalItems = response.total;
        this.current_page = response.current_page;
        this.users_having_rejection_ability = response.rejected_by_users;
        this.practice_array = [];
        for (let practice of this.practices) {
          let pract = new Practice();
          pract.load_from_json(practice);
          pract['pending'] = this.defaultStatusValues('pending', pract.client.status_id);
          pract['onhold'] = this.defaultStatusValues('on-hold', pract.client.status_id);
          pract['inprogress'] = this.defaultStatusValues('in-progress', pract.client.status_id);
          pract['completed'] = this.defaultStatusValues('completed', pract.client.status_id);
          this.practice_array.push(pract);
        }
      });
    }

    onSelect(stage_id: number): void {
      this.practice_array = [];
      this.selected_stage_id = stage_id;
      this.get_fresh_practices();
      this.tabs_service.deactivate_tab();
    }

    filtered_practices(): any {
     return this.practice_array.filter(p => p.stage_id == this.selected_stage_id)
    }

    iconMapping(item): any {
        let iconMappingHash = { 1: 'fa icon-user', 2: 'fa fa-user-md', 3: 'fa fa-hospital-o'}
        let icon = iconMappingHash[item];
        return icon;
    }


    setPractice(practice): any {
      this.practice = practice;
    }

    changePracticeStaage(practice, stage_id): any {
    this.leadService.setPracticesStage(practice.id, stage_id, this.rejection_reason).subscribe(practices => {
      practice.stage_id = stage_id;
      this.rejection_reason = '';
      this.users_having_rejection_ability = practices.rejected_by_users;
    });

  }

  changeStatus(value, practice): any {
      let id = practice.id;
      let status_id = value;
      let iconMappingHash = { 1: 'in-progress', 2 : 'pending', 3: 'on-hold', 4: 'completed'}
      let icon = iconMappingHash[value];
      document.getElementById('progress-' + id).className = icon;
      document.getElementById('practice-' + id).className = icon;
      this.leadService.setPracticesStatusId(id, status_id).subscribe(status => {
        if (value == 4) {
          practice.stage_id = 4;
        }
      });
  }

  getRejectedByUserName(rejected_by_id): any {
    // let first_name = this.users_having_rejection_ability.filter(user => user.id == rejected_by_id).map(e => e.first_name)[0];
    // let last_name = this.users_having_rejection_ability.filter(user => user.id == rejected_by_id).map(e => e.last_name)[0];
    // if(first_name && last_name)
    // return first_name+' '+last_name;
    // return '';
  }

  defaultStatusValues(status, status_id): any {
    let status_mapping = { 1: 'in-progress', 2: 'pending', 3: 'on-hold', 4: 'completed'}
    let practiceStatus = status_mapping[status_id];
    if (status == practiceStatus) {
      return true;
    } else {
      return false;
    }
  }

  add_to_tabs(practice) {
    this.tabs_service.add_tab(new Tab({title: practice.name, icon: 'fa fa-hospital'}, WizardComponent, practice.id, {id: practice.id, permissions: 'edit,practices,manage'}))
  }
  pageChanged(page) {
    this.current_page = page.page;
    this.get_fresh_practices();
  }

}
