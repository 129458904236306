import {Component, OnInit, Injector, ViewChild} from '@angular/core';
import {AppointmentService} from '../../../services/appointment.service';
import {Appointment} from '../../../health/models/appointment';
import {Observable} from 'rxjs';
import {ServicesService} from '../../../services/services.service';
import {Case} from '../case';
import {DoctorService} from '../../../services/doctor.service';
import {TabsService} from '../../../services/tabs.service';
import {CaseService} from '../../../services/case.service';
import {ContactService} from '../../../services/contact.service';
import {NgForm} from '@angular/forms';
import {CustomValidations} from '../../customValidations/custom-validations';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {EmergencyService} from '../../../services/emergency.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-admit',
  templateUrl: './admit.component.html',
  styleUrls: ['./admit.component.scss']
})
export class AdmitComponent implements OnInit {
  public services: Observable<any[]>;
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public show_error = false;
  public appointment: Appointment;
  public departments;
  public case: Case;
  public data_source: Observable<any[]>;
  public doctors_data: Observable<any[]>;
  public token = '';
  customValidations: CustomValidations;

  @ViewChild('admissionForm') admissionForm: NgForm;
  constructor(private emergency_service: EmergencyService, private caseService: CaseService, private injector: Injector, private _appointmentService: AppointmentService,
              private globalErrorHandler: GlobalErrorHandlerService, private doctorService: DoctorService,
              private tab_service: TabsService, private _servicesService: ServicesService, private contactService: ContactService) {
    this.customValidations = new CustomValidations();
    this.services = Observable.create((observer: any) => {
      observer.next(this.case.service_name);
    }).pipe(mergeMap((token: string) => this.getServices(token)));
    this.data_source = Observable.create((observer: any) => {
      observer.next(this.case.staff_name);
    }).pipe(mergeMap((token: string) => this.contactService.getOnlyStaff(this.appointment.location.practice_id, token)));
    this.doctors_data = Observable.create((observer: any) => {
      observer.next(this.case.doctor_name);
    }).pipe(mergeMap((token: string) => this.doctorService.get_practice_doctors(token, this.appointment.location.practice_id)));
  }

  ngOnInit() {
    let id = this.injector.get('id', null);
    this.case = this.injector.get('case', null);
    console.log(this.case);
    this.fetchAppointment(id);
  }

  fetchAppointment(id) {
    this._appointmentService.getAppointment(id).then(json => {
      this.appointment = new Appointment().load_from_json(json);
      this.case.appointment_id = this.appointment.id;
      this.departments = json.departments;
    })

  }

  getServices(term) {
    return this._servicesService.search_services(term);
  }


  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.case.service_name = e.value;
    this.case.service_id = e.item.id;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public staffTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public staffTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public stafftypeaheadOnSelect(e: any): void {
    this.case.staff_name = e.value;
    this.case.staff_id = e.item.id;
  }

  public doctorTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public doctorTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public doctortypeaheadOnSelect(e: any): void {
    this.case.doctor_name = e.value;
    this.case.doctor_id = e.item.id;
  }

  selectDepartment(department) {
    this.case.department_id = department.value;
  }

  save() {
    if (parseInt(this.case.department_id) <= 0) {
      this.setTimeOutFailsMessage();
      return;
    }
    this.caseService.save(this.case)
      .then(resp => {
        this.after_save();
        this.caseService.reloadCases.next(1);
        this._appointmentService.reloadSchedulerAppointments.next(this.case.appointment_id);
      });
  }

  setTimeOutFailsMessage(): any {
    this.show_error = true;
    setTimeout(() => {
      this.show_error = false;
    }, 3000)
  }

  after_save() {
    this.emergency_service.applyFilters();
    this.tab_service.set_data(true);
    this.tab_service.close_tab(this.tab_service.active_tab())
  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.admissionForm);
  }
}
