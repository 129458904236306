import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { WizardComponent } from '../../practices/wizard/wizard.component'
import { GroupComponent } from '../../practices/group/group.component'
import { EnterpriseComponent } from '../../practices/enterprise/enterprise.component'
import { UserComponent } from '../user/user.component'
import {Tab} from '../../../tabs/tab'
import {UserManagementService} from '../../../services/user-management.service'

import {TabsService} from '../../../services/tabs.service'
import {AdminLoginService} from '../../../services/admin_login_service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {UserService} from '../../../services/user.service';
import {PageChangedEvent} from 'ngx-bootstrap/pagination/pagination.component';
import {PatientDocument} from '../../../health/patient-profile/patient-dashboard/chart/document';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  @Input() users: any;
  @Input() filter_users_listing: any;
  @Input() totalItems;
  @Input() page;
  @Output() pageChange: EventEmitter<PageChangedEvent> = new EventEmitter<PageChangedEvent>();
  constructor(private _tabService: TabsService,
              public userService: UserService,
              private adminLoginService: AdminLoginService,
              private globalErrorHandlerService: GlobalErrorHandlerService,
              private _user_management_service: UserManagementService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  addToTab(user) {
      this._tabService.add_tab(new Tab({title: [user.first_name, user.last_name].join(' '), icon: 'fa fa-hospital'}, UserComponent, user.id, {id: user.id, profile_type: user.profileable_type, profileable_id: user.profileable_id}))
  }

  addToTabProfileAble(user) {
    if (user.profileable_type == 'Staff' && user.staff_profileable_id > 0) {
      this._tabService.add_tab(new Tab({title: user.profileable_name, icon: 'fa fa-hospital'}, WizardComponent, `${user.profileable_type}_${user.staff_profileable_id}`, {id: user.staff_profileable_id, practice_permissions: 'edit,Practice Profile,settings', doctor_permissions: 'edit,doctors,settings'}))
    }
    if (user.profileable_type == 'Group') {
      this._tabService.add_tab(new Tab({title: user.profileable_name, icon: 'fa fa-hospital'}, GroupComponent, `${user.profileable_type}_${user.profileable_id}`, {id: user.id}));
    }
    if (user.profileable_type == 'Enterprise') {
      this._tabService.add_tab(new Tab({title: user.profileable_name, icon: 'fa fa-hospital'}, EnterpriseComponent, `${user.profileable_type}_${user.profileable_id}`, {id: user.id}))
    }
  }

  triggerUpdate(user_obj, user_id, is_active) {
    this._user_management_service.active_or_deactivate_user_profile(user_id, is_active).then(user => {
      user_obj.is_active = user.is_active;
      });
  }

  handleStaffLable() {
    let name = (this.filter_users_listing && this.filter_users_listing.length > 0) ? this.filter_users_listing[0].profileable_type : ''
    if (name == 'Staff') {
      name = 'Practice';
    }
    return name;
  }

  login(user) {
    if(user.email_status != 'verified')
    {
      this.globalErrorHandlerService.error = 'User is not verified.';
      return;
    }
    this.adminLoginService.loginAsEnterpriseUser(user.id)
      .then(resp => console.log(resp))
      .catch(error => {
        this.globalErrorHandlerService.error = error.error.message;
      });
  }

  pageChanged(page) {
    this.pageChange.emit(page.page)
  }
}
