import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PatientEvent} from '../patient-event';
import {LoaderService} from '../../../../services/loader.service';
import {AlertService} from '../../../../_alert';
import {UserService} from '../../../../services/user.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {PatientEventService} from '../patient-event.service';
import {Term} from '../../../../enote/enote_models/term';
import {MedicationSummaryService} from '../../patient-dashboard/summary/medication-summary/medication-summary.service';
import {IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';

@Component({
  selector: 'app-patient-event',
  templateUrl: './patient-event.component.html',
  styleUrls: ['./patient-event.component.scss']
})
export class PatientEventComponent implements OnInit {
  @Input() patientEvent: PatientEvent;
  @Output() closePatientEventModel: EventEmitter<boolean> = new EventEmitter<boolean>()
  public patient_summary = [];
  public patient_devices = [];
  public bsValue: Date = new Date();
  public showEditConfirmation: boolean;
  selectSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false
  };
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    defaultTitle: 'Notify',
    allSelected: 'All selected',
  };

  constructor(public loaderService: LoaderService,
              public medicationSummaryService: MedicationSummaryService,
              public patientEventService: PatientEventService,
              public alertService: AlertService, public userService: UserService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
  }

  ngOnInit(): void {
    if (this.patientEvent.eventable_id && this.patientEvent.event_type !== 'appointment') {
      this.getDropDownData();
    }
  }

  savePatientEvent() {
    this.loaderService.show();
    this.patientEventService.save(this.patientEvent)
      .then(resp => {
        this.patientEventService.reloadPatientEventList.next(this.patientEvent);
        this.closeForm();
        this.alertService.success('Patient Event has been saved successfully', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  closeForm() {
    this.showEditConfirmation = false;
    this.closePatientEventModel.emit(true);
  }

  changeTypeCall() {
    this.patientEvent.eventable_type = undefined;
    this.patientEvent.eventable_id = undefined;
    this.resetSubjectAndDescription();
    this.getDropDownData();
  }

  getDropDownData() {
    if (this.patientEvent.event_type === 'medication') {
      this.patientSummaryData('medications', 'current');
    }
    if (this.patientEvent.event_type === 'vital_sign') {
      this.patientDeviceForEvents();
    }
  }

  patientSummaryData(summary_type, status) {
    this.loaderService.show();
    this.medicationSummaryService.getPatientSummaryData(this.patientEvent.patient_id, status, summary_type).then(resp => {
      if (summary_type === 'medications') {
        this.patient_summary = resp['medications'].map(term => new Term('', '').load_from_json2(term));
        this.loaderService.hide();
      }
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  patientDeviceForEvents() {
    this.loaderService.show();
    this.patientEventService.getPatientDeviceForEvents(this.patientEvent.patient_id).then(resp => {
      if (resp) {
        this.patient_devices = resp;
        this.loaderService.hide();
      }
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  changeMedicationDropdown() {
    this.patientEvent.eventable_type = 'PatientSummaryDatum';
    let summary
    if (this.patientEvent.eventable_id) {
      summary = this.patient_summary.filter(d => this.patientEvent.eventable_id.toString() === d.ps_id.toString())[0];
    }
    if (summary && summary.d_name) {
      this.patientEvent.description = 'Take ' + this.userService.removeUnderScore(summary.d_name) + ' on time.'
      this.patientEvent.subject = summary.d_name;
    } else {
      this.resetSubjectAndDescription();
    }
  }

  changeMedicalDeviceDropdown() {
    this.patientEvent.eventable_type = 'Device';
    let device
    if (this.patientEvent.eventable_id) {
      device = this.patient_devices.filter(d => this.patientEvent.eventable_id.toString() === d.id.toString())[0];
    }
    if (device && device.name) {
      this.patientEvent.description = 'Use your device to monitor ' + device.name + ' on time.'
      this.patientEvent.subject = device.name;
    } else {
      this.resetSubjectAndDescription();
    }
  }
  resetSubjectAndDescription() {
    this.patientEvent.description = undefined;
    this.patientEvent.subject = undefined;
  }

  selectNotifyBefore(notify: any) {
    this.patientEvent.notify_before = notify;
  }
}
