<div class="flex-header">
  <h4 class="m0">Complete Enrollment</h4>
</div>
<div class="flex-body p scroll-browser">
  <h4 class="text-success" *ngIf="programService.currentProgramEnrollmentObject.status == 'pending' || programService.currentProgramEnrollmentObject.status == 'opt_out'">
    Are you sure to complete the enrollment?
  </h4>
  <h4 class="text-success" *ngIf="programService.currentProgramEnrollmentObject.status === 'enrolled'">Patient Already Enrolled</h4>
  <div>
    <button type="submit" class="btn btn-secondary btn-min mt-lg" (click)="confirmEnrollment()"
            *ngIf="programService.currentProgramEnrollmentObject.status == 'pending' || programService.currentProgramEnrollmentObject.status == 'opt_out'">
      Confirm
    </button>
  </div>
</div>
