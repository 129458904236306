const menu = {
  'home': [
    {
      text: 'Dashboard',
      link: 'home/dashboard',
      icon: 'fa icon-grid',
      complete_name: 'Dashboard',
      permission: 'view,Dashboard,home'
    },
    {
      text: 'Instant Messages',
      link: 'home/messages',
      icon: 'custom-icon im-icon',
      complete_name: 'Instant Messages',
      permission: 'view,Messages,home'
    },
    {
      text: 'SMS',
      link: 'home/sms',
      icon: 'fa icon-bubbles',
      complete_name: 'SMS',
      permission: 'view,SMS,home'
    },
    {
      text: 'Tasks',
      link: 'home/tasks',
      icon: 'fa icon-notebook',
      complete_name: 'Tasks',
      permission: 'view,Tasks,home'
    }
  ],
  'reception': [
    {
      text: 'Requests',
      link: 'reception/appointments',
      icon: 'fa fa-calendar-check-o',
      complete_name: 'Appointments',
      permission: 'view,Appointments,reception'
    }, {
      text: 'Today\'s Patients',
      link: 'reception/today',
      icon: 'fa icon-people',
      complete_name: 'Reception',
      permission: 'view,Today Patients,reception'
    },
    {
      text: 'Scheduler',
      link: 'reception/scheduler',
      icon: 'fa icon-calendar',
    },
    {
      text: 'Intake Forms',
      link: 'reception/patient-intake-forms',
      complete_name: 'Patient Intake Forms',
      icon: 'fa fa-file-text-o'
    },
    {
      text: 'Emergency',
      link: 'reception/emergency',
      icon: 'fa icon-fire',
      complete_name: 'Emergency',
      permission: 'view,Emergency,reception'
    },
    {
      text: 'Admissions',
      link: 'reception/admissions',
      icon: 'fa icon-user-following ',
      complete_name: 'Admissions',
      permission: 'view,Admissions,reception'
    }, {
      text: 'Visits',
      link: 'reception/visits',
      icon: 'fa icon-location-pin',
      complete_name: 'Visits',
      permission: 'view,Visits,reception'
    }
  ],
  'patients': [
    {
      text: 'Patients',
      link: 'patients/search',
      icon: 'fa icon-people',
      complete_name: 'Patients Library',
      permission: 'view,Patients,Patient Communication'
    }, {
      text: 'Reminders',
      link: 'patients/reminders',
      icon: 'fa icon-clock',
      complete_name: 'Reminders',
      permission: 'view,Reminders,Patient Communication'
    }, {
      text: 'Email Marketing',
      link: 'patients/email-marketing',
      icon: 'fa icon-envelope-letter',
      complete_name: 'Email Marketing',
      permission: 'view,Marketing,Patient Communication'

    }, {
      text: 'SMS Marketing',
      link: 'patients/sms-marketing',
      icon: 'fa fa-envelope-open-o',
      complete_name: 'SMS Marketing',
      permission: 'view,Marketing,Patient Communication'

    }, {
      text: 'Today\'s Patients',
      link: 'patients/today',
      icon: 'fa fa-clipboard',
      complete_name: 'Today\'s Patients',
      permission: 'view,Today Patients,patient'
    },
  ],
  'program': [
    {
      text: 'Dashboard',
      link: 'program/dashboard',
      icon: 'fa icon-grid',
    },
    {
      text: 'Enrollment',
      link: 'program/eligible',
      icon: 'fa icon-check',
    },
    {
      text: 'CMS',
      link: 'program/programs',
      icon: 'fa icon-layers',
    },
    {
      text: 'CMS Billing',
      link: 'program/bills',
      icon: 'fa icon-docs'
    },
    {
      text: 'Private Pay',
      link: 'program/private-programs',
      icon: 'fa icon-credit-card',
      permission: 'view,Private Pay,Care Management'
    },
    {
      text: 'Private Pay Billing',
      link: 'program/private-bills',
      icon: 'fa icon-paypal',
      permission: 'view,Private Pay Billing,Care Management'
    },
  ],
  'reviews': [
    {
      text: 'Reviews',
      link: 'reviews/dashboard',
      icon: 'fa icon-star',
      permission: 'view,Reviews,Reputation Management'
    },
    {
      text: 'Survey\'s',
      link: 'reviews/surveys',
      icon: 'fa icon-docs',
      permission: 'view,Survey,Reputation Management'
    },
    {
      text: 'Setup',
      link: 'reviews/setup',
      icon: 'fa icon-wrench',
      permission: 'view,Setup,Reputation Management'
    }
  ],
  'online': [
    {
      text: 'Leads',
      link: 'online/leads',
      icon: 'fa fa-bolt',
      complete_name: 'New Leads',
      permission: 'view,Leads,online'
    },
    {
      text: 'Appointments',
      link: 'online/appointments',
      icon: 'fa fa-hospital-o',
      complete_name: 'Appointments',
      permission: 'view,Appointments,online'
    }
  ],
  'manage': [
    {
      text: 'Patients',
      link: 'manage/patients',
      icon: 'fa fa-h-square',
      complete_name: 'Patients Library',
      permission: 'view,Patients,manage'
    }, {
      text: 'Practices',
      link: 'manage/practices',
      icon: 'fa fa-hospital-o',
      complete_name: 'Practice Registration',
      permission: 'view,Practices,manage'
    }, {
      text: 'Doctors',
      link: 'manage/doctors',
      icon: 'fa fa-user-md',
      complete_name: 'Doctors',
      permission: 'view,Doctors,manage'
    }, {
      text: 'Groups',
      link: 'manage/groups',
      icon: 'fa fa-object-group',
      complete_name: 'Groups',
      permission: 'view,Groups,manage'
    },
    {
      text: 'Announcements',
      link: 'manage/announcements',
      icon: 'fa fa-bullhorn',
      complete_name: 'System Announcements',
      permission: 'view,Announcements,manage'
    }
  ],
  'profile-settings': [],
  'settings': [
    {
      text: 'Library Importer',
      link: 'settings/importer',
      icon: 'fa icon-cloud-download',
      complete_name: 'Library',
      permission: 'view,Library,settings'
    },
    {
      text: 'Note Component',
      link: 'settings/note_component',
      icon: 'fa icon-check',
      complete_name: 'Note Component',
      permission: 'view,Note Component,settings'
    },
    {
      text: 'Practice Profile',
      link: 'settings/practice',
      icon: 'fa fa-hospital-o',
      complete_name: 'Practice Profile',
      permission: 'view,Practice Profile,settings'
    },
    {
      text: 'Access',
      link: 'settings/permissions',
      icon: 'fa icon-lock',
      complete_name: 'Access Control',
      permission: 'view,Access Control,settings'
    },
    {
      text: 'Alerts',
      link: 'settings/alert_template',
      icon: 'fa icon-bell',
      complete_name: 'Alert Template'
    },
    {
      text: 'Rich Text Template',
      link: 'settings/rich-text-template-index',
      icon: 'fa icon-speech',
      complete_name: 'Rich Text Template'
    },
    {
      text: 'Services',
      link: 'settings/amenity',
      icon: 'fa icon-list',
      complete_name: 'Amenity',
    },
    {
      text: 'Users',
      link: 'settings/users',
      icon: 'fa icon-people',
      complete_name: 'Users',
      permission: 'view,Users,settings'
    },
    {
      text: 'Intake Form Templates',
      link: 'settings/intake-form',
      icon: 'fa fa-file-text-o',
      complete_name: 'Intake Form Templates'
    }, {
      text: 'Terms',
      link: 'settings/terms',
      icon: 'fa icon-docs',
      complete_name: 'Terms',
      permission: 'view,Terminology,settings'
    }, {
      text: 'Templates',
      link: 'settings/templates',
      icon: 'fa icon-docs',
      complete_name: 'Templates',
      permission: 'view,Template,settings'
    }, {
      text: 'Integrations',
      link: 'settings/integrations',
      icon: 'fa icon-shuffle',
      complete_name: 'Integrations',
      permission: 'view,Integration,settings'
    }, {
      text: 'Rating',
      link: 'settings/rating',
      icon: 'fa icon-star',
      complete_name: 'Rating',
      permission: 'view,Ratings,settings'
    },
    {
      text: 'Doctor Profile',
      link: 'settings/doctor',
      icon: 'fa fa-hospital-o',
      complete_name: 'Doctor Profile',
      permission: 'view,Doctor Profile,settings'
    },
    // ,
    // {
    //   text: 'HTML',
    //   link: 'settings/html',
    //   icon: 'fa fa-hospital-o',
    //   complete_name: 'HTML'
    // }
  ]
};

export default menu;
