import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Campaign} from '../../campaign';
import {NgForm} from '@angular/forms';
import {LoaderService} from '../../../services/loader.service';
import {TabsService} from '../../../services/tabs.service';
import {Service} from '../../../routes/practices/practices/practice';
import {SpecialtySearchService} from '../../../services/specialty-search.service';
import {Tab} from '../../../tabs/tab';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {UserService} from '../../../services/user.service';
import {AttachmentService} from '../../../services/attachments.service';
import {CampaignService} from '../../campaign.service';
import {ViewEmailCampaignComponent} from '../view-email-campaign/view-email-campaign.component';
import {Doctor} from '../../../routes/practices/doctors/doctor';
import {DoctorService} from '../../../services/doctor.service';
import {LocationService} from '../../../services/location.service';
import {IMultiSelectOption, IMultiSelectSettings} from 'angular-2-dropdown-multiselect';
import {TagService} from '../../../services/tags.service';
import {AddressesService} from '../../addresses.service';
import {StatusLog} from '../../../reminders/reminder-template';
import {ProgramService} from '../../../program/program.service';
interface SmtpEmail {
  id: number;
  user_name: string
}
@Component({
  selector: 'app-new-email-campaign',
  templateUrl: './new-email-campaign.component.html',
  styleUrls: ['./new-email-campaign.component.scss']
})
export class NewEmailCampaignComponent implements OnInit {
  @Input() campaign: Campaign;
  @ViewChild('campaignForm') campaignForm: NgForm;
  @Output() closeform = new EventEmitter<boolean>();

  public total_tags: any;
  public total_services: any;
  public total_city: any;
  public total_state: any;
  public total_zip_code: any;
  public specialtiesLoading = false;
  services: Service[] = [new Service()];
  public smtp_emails: SmtpEmail[] = [];
  public e_statuses = [{id: 0, name: 'Unenrolled'}, {id: 1, name: 'Enrolled'}, {id: 2, name: 'In Progress'}, {id: 3, name: 'Opt Out'},
    {id: 4, name: 'Ready To Enroll'}, {id: 5, name: 'Visit Required'}, {id: 6, name: 'Ineligible'},
    {id: 7, name: 'Patient Declined'}, {id: 8, name: 'Enrolled with other provider'}, {id: 9, name: 'eligible'}, {id: 10, name: 'Shortlisted'}]


  public practiceLocations: any[];
  public providers: any[];
  selected_docs: string[];
  selected_loc: string[];
  selected_progs: string[];
  practicePrograms: any[];
  tags;
  mySettings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true,
  };
  public ratingOptions: IMultiSelectOption[];

  constructor(public injector: Injector, public loaderService: LoaderService, public tabs_service: TabsService,
              private specialtySearchService: SpecialtySearchService, private userService: UserService ,
              public globalErrorHandlerService: GlobalErrorHandlerService, private doctorService: DoctorService,
              private locationService: LocationService, private tagService: TagService, private addressesService: AddressesService,
              public campaignService: CampaignService, public programService: ProgramService) {  }

  ngOnInit(): void {
    let newCampaign = this.injector.get('campaign', null);
    if (newCampaign) {
      this.campaign = newCampaign;
    } else {
      this.campaign = new Campaign();
    }
    this.selected_loc = this.campaign.selected_loc;
    this.selected_docs = this.campaign.selected_docs;
    this.selected_progs = this.campaign.selected_progs;
    if (this.userService.current_user.isPractice) {
      this.getPracticeLocations(this.userService.current_user.practices_id);
      this.getPracticeDoctor(this.userService.current_user.practices_id);
      this.getPracticePrograms();
      let c_user_id = this.userService.current_user.practices_id[0];
      this.campaign.source_id = c_user_id;
      this.campaign.source_type = 'Practice';
    }
    if (this.campaign.outreach_type === 'email') {
      this.campaignService.getSmtpEmails()
        .subscribe(
          resp => {
            this.smtp_emails = resp;
            this.campaign.email_configuration_id = resp[0]['id'];
            },
            error => console.log(error)
        );
    }
    this.ratingOptions = [
      { id: 0, name: '0' },
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: '5' },
    ];
  }
  save() {
    this.campaign.creator_id = this.userService.current_user.id;
    this.loaderService.small_loder_show();
    this.campaignService.save(this.campaign)
      .then(resp => {
        this.campaign = this.campaign.load_from_json(resp)
        this.loaderService.small_loder_hide();
        this.campaignService.reloadCampaigns.next(1);
        this.tabs_service.close_tab(this.tabs_service.active_tab());
        this.tabs_service.add_tab(new Tab({title: this.campaign.name, icon: 'fa'},
          ViewEmailCampaignComponent, this.campaign.id, {campaign: this.campaign} ))

      })
      .catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }
  closeNewForm() {
    if (this.tabs_service.active_tab().component === NewEmailCampaignComponent) {
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }
    else {
      this.closeform.emit(false);
    }
  }

  // Push values in temp_array

  addLocationTempArray(events) {
    this.campaign.temp_locations_array.forEach(s => {
      if (!events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = true;
        } else {
          this.campaign.temp_locations_array = this.campaign.temp_locations_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
      if (events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = false;
        } else {
          this.campaign.temp_locations_array = this.campaign.temp_locations_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
    })
    events.forEach(e => {
      if (this.campaign.temp_locations_array.length === 0) {
        this.campaign.temp_locations_array.push({associationable_type : 'Location', associationable_id : e})
      }
      if (this.campaign.temp_locations_array.filter(s => s.associationable_id === e).length === 0) {
        this.campaign.temp_locations_array.push({associationable_type : 'Location', associationable_id : e})
      }
    });
  }

  addProgramTempArray(events) {
    this.campaign.temp_programs_array.forEach(s => {
      if (!events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = true;
        } else {
          this.campaign.temp_programs_array = this.campaign.temp_programs_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
      if (events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = false;
        } else {
          this.campaign.temp_programs_array = this.campaign.temp_programs_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
    })
    events.forEach(e => {
      if (this.campaign.temp_programs_array.length === 0) {
        this.campaign.temp_programs_array.push({associationable_type : 'Programme', associationable_id : e})
      }
      if (this.campaign.temp_programs_array.filter(s => s.associationable_id === e).length === 0) {
        this.campaign.temp_programs_array.push({associationable_type : 'Programme', associationable_id : e})
      }
    });
  }

  addDoctorsTempArray(events) {
    this.campaign.temp_doctors_array.forEach(s => {
      if (!events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = true;
        } else {
          this.campaign.temp_doctors_array = this.campaign.temp_doctors_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
      if (events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = false;
        } else {
          this.campaign.temp_doctors_array = this.campaign.temp_doctors_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
    })
    events.forEach(e => {
      if (this.campaign.temp_doctors_array.length === 0) {
        this.campaign.temp_doctors_array.push({associationable_type : 'Doctor', associationable_id : e})
      }
      if (this.campaign.temp_doctors_array.filter(s => s.associationable_id === e).length === 0) {
        this.campaign.temp_doctors_array.push({associationable_type : 'Doctor', associationable_id : e})
      }
    });
  }

  addRatingsTempArray(events) {
    this.campaign.temp_rating_array.forEach(s => {
      if (!events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = true;
        } else {
          this.campaign.temp_rating_array = this.campaign.temp_rating_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
      if (events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = false;
        } else {
          this.campaign.temp_rating_array = this.campaign.temp_rating_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
    })
    events.forEach(e => {
      if (this.campaign.temp_rating_array.length === 0) {
        this.campaign.temp_rating_array.push({associationable_type : 'PatientRating', associationable_id : e})
      }
      if (this.campaign.temp_rating_array.filter(s => s.associationable_id === e).length === 0) {
        this.campaign.temp_rating_array.push({associationable_type : 'PatientRating', associationable_id : e})
      }
    });
  }

  // tags add search code start_________________________________________________________________________________________

  addTagsTempArray(event) {
    if (!this.containsObject(event, this.campaign.temp_tags_array)) {
      this.campaign.temp_tags_array.push({associationable_type : 'Tag', associationable_id : event.id,  _destroy : false});
    } else {
      let tag = this.campaign.temp_state_array.filter(s => s['associationable_id'] === event.id && s['associationable_type'] === 'Tag')[0];
      if (tag._destroy) {
        tag._destroy = false;
      }
    }
  }

  typedTags(text: { term: string; total_tags: any }) {

    this.specialtiesLoading = true;
    this.getTAgs(text.term).subscribe(a => {
      this.specialtiesLoading = false

      return this.total_tags = a.map(sp => {return {id: sp.id, text: sp.display}})
    });
  }
  getTAgs(term: any) {
    return this.tagService.search_tags(term);
  }
  removeTags(event) {
    let tag = this.campaign.campaign_associations.filter(s => s['associationable_id'] === event.value.id && s['associationable_type'] === 'Tag')[0];
    if (tag) {
      this.campaign.temp_tags_array.splice(this.campaign.temp_services_array.indexOf(tag), 1);
      this.campaign.temp_tags_array.push({
        id: tag['id'],
        associationable_type: 'Tag',
        associationable_id: tag['associationable_id'],
        _destroy: true
      });
    } else {
      this.campaign.temp_tags_array = this.campaign.temp_tags_array.filter(s => s['associationable_id'] !== event.value.id);
    }
  }
  // tags add search code start end___________________________________________________________________________________




  // City add search code start_________________________________________________________________________________________

  addCityTempArray(event) {
    if (!this.containsObject(event, this.campaign.temp_city_array)) {
      this.campaign.temp_city_array.push({associationable_type : 'City', associationable_id : event.id, _destroy : false});
    }
    else {
      let city = this.campaign.temp_city_array.filter(s => s['associationable_id'] === event.id && s['associationable_type'] === 'City')[0];
      if (city._destroy) {
        city._destroy = false;
      }
    }
  }

  typedCity(text: { term: string; total_city: any }) {

    this.specialtiesLoading = true;
    this.getCity(text.term).subscribe(a => {
      this.specialtiesLoading = false

      return this.total_city = a.map(sp => {return {id: sp.id, text: sp.name}})
    });
  }
  getCity(term: any) {
    return this.addressesService.cities(term);
  }
  removeCity(event) {
    let city = this.campaign.campaign_associations.filter(s => s['associationable_id'] === event.value.id && s['associationable_type'] === 'City')[0];
    if (city) {
      this.campaign.temp_city_array.splice(this.campaign.temp_city_array.indexOf(city), 1);
      this.campaign.temp_city_array.push({
        id: city['id'],
        associationable_type: 'City',
        associationable_id: city['associationable_id'],
        _destroy: true
      });
    } else {
      this.campaign.temp_city_array = this.campaign.temp_city_array.filter(s => s['associationable_id'] !== event.value.id);
    }
  }
  // City add search code start end___________________________________________________________________________________



  // State add search code start_________________________________________________________________________________________

  addStateTempArray(event) {
    if (!this.containsObject(event, this.campaign.temp_state_array)) {
      this.campaign.temp_state_array.push({associationable_type : 'State', associationable_id : event.id, _destroy : false});
    } else {
      let state = this.campaign.temp_state_array.filter(s => s['associationable_id'] === event.id && s['associationable_type'] === 'State')[0];
      if (state._destroy) {
        state._destroy = false;
      }
    }
  }

  typedState(text: { term: string; total_city: any }) {

    this.specialtiesLoading = true;
    this.getState(text.term).subscribe(a => {
      this.specialtiesLoading = false

      return this.total_state = a.map(sp => {return {id: sp.id, text: sp.name}})
    });
  }
  getState(term: any) {
    return this.addressesService.states(term);
  }
  removeState(event) {
    let state = this.campaign.campaign_associations.filter(s => s['associationable_id'] === event.value.id && s['associationable_type'] === 'State')[0];
    if (state) {
      this.campaign.temp_state_array.splice(this.campaign.temp_state_array.indexOf(state), 1);
      this.campaign.temp_state_array.push({
        id: state['id'],
        associationable_type: 'State',
        associationable_id: state['associationable_id'],
        _destroy: true
      });
    } else {
      this.campaign.temp_state_array = this.campaign.temp_state_array.filter(s => s['associationable_id'] !== event.value.id);
    }
  }
  // State add search code start end___________________________________________________________________________________




  // Zip Code add search code start_________________________________________________________________________________________

  addZipCodeTempArray(event) {
    if (!this.containsObject(event, this.campaign.temp_zip_code_array)) {
      this.campaign.temp_zip_code_array.push({associationable_type : 'ZipCode', associationable_id : event.id, _destroy : false});
    } else {
      let zip_code = this.campaign.temp_zip_code_array.filter(s => s['associationable_id'] === event.id && s['associationable_type'] === 'ZipCode')[0];
      if (zip_code._destroy) {
        zip_code._destroy = false;
      }
    }
  }

  typedZipCode(text: { term: string; total_city: any }) {

    this.specialtiesLoading = true;
    this.getZipCode(text.term).subscribe(a => {
      this.specialtiesLoading = false

      return this.total_zip_code = a.map(sp => {return {id: sp.id, text: sp.code}})
    });
  }
  getZipCode(term: any) {
    return this.addressesService.zip_codes(term);
  }
  removeZipCode(event) {
    let zipCode = this.campaign.campaign_associations.filter(s => s['associationable_id'] === event.value.id && s['associationable_type'] === 'ZipCode')[0];
    if (zipCode) {
      this.campaign.temp_zip_code_array.splice(this.campaign.temp_zip_code_array.indexOf(zipCode), 1);
      this.campaign.temp_zip_code_array.push({
        id: zipCode['id'],
        associationable_type: 'ZipCode',
        associationable_id: zipCode['associationable_id'],
        _destroy: true
      });
    } else {
      this.campaign.temp_zip_code_array = this.campaign.temp_zip_code_array.filter(s => s['associationable_id'] !== event.value.id);
    }
  }
  // Zip Code add search code start end___________________________________________________________________________________



  // Select Reason of Visit Code Start________________________________________________________________________________

  typedServices(text: { term: string; total_services: any }) {
    this.specialtiesLoading = true;
    this.getServices(text.term).subscribe(a => {
      this.specialtiesLoading = false
      return this.total_services = a.map(sp => {return {id: sp.id, text: sp.name}})
    });
  }

/*  get getPreselectedServices() {
    return this.campaign.service_ids.map(s => s.service_id)
  }*/

  removeService(event) {
    let service = this.campaign.campaign_associations.filter(s => s['associationable_id'] === event.value.id && s['associationable_type'] === 'Service')[0];
    if (service) {
      this.campaign.temp_services_array.splice(this.campaign.temp_services_array.indexOf(service), 1);
      this.campaign.temp_services_array.push({
        id: service['id'],
        associationable_type: 'Service',
        associationable_id: service['associationable_id'],
        _destroy: true
      });
    } else {
      this.campaign.temp_services_array = this.campaign.temp_services_array.filter(s => s['associationable_id'] !== event.value.id);
    }
  }

  getServices(term: any) {
    return this.specialtySearchService.search_services(term);
  }

  public add_selected_service(value: any): void {
    this.add_service(value.id);
  }

  add_service(service_id) {
    let existing_found = this.get_new_service();
    let service = existing_found ? existing_found : new Service();
    service.service_id = service_id;
    if (!existing_found) {

      this.campaign.temp_services_array.push({associationable_type : 'Service', associationable_id : service.service_id, _destroy : false});

    }
  }

  get_new_service() {
    return this.services.filter(sp => sp.service_id == 0)[0];
  }

  // Select Reason of Visit Code End_____________________________________________________________________________________

  /*Get locations of Practice and Doctors code start*/
  getPracticeDoctor(paractice_id) {
    if (this.userService.current_user.isPractice) {
      if (this.userService.user_logged_in && this.userService.current_user) {
        this.doctorService.getPracticeDoctors(paractice_id).then(resp => {
          if (resp && resp.is_doctor) {
             this.providers = resp.doctor.map(doctor => new Doctor().load_from_json(doctor))
          } else {
             this.providers = resp.map(doctor => new Doctor().load_from_json(doctor))
          }
        })
      }
    }

  }
  getPracticePrograms() {
    if (this.userService.current_user.isPractice) {
      if (this.userService.user_logged_in && this.userService.current_user) {
        this.programService.getPracticePrograms().then(resp => {
         this.practicePrograms = resp;
        })
      }
    }

  }

  getPracticeLocations(paractice_id) {
      let id = paractice_id
      this.locationService.practice_locations(id).then(resp => {
         this.practiceLocations = resp;
      });
  }
  /*Get locations of Practice and Doctors code end*/
  containsObject(obj, list) {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i].associationable_id === obj.id) {
        return true;
      }
    }
    return false;
  }

  // delete
  deleteTemplate(template) {
    this.loaderService.show()
    this.campaignService.delete(template.id).then(resp => {
      this.campaignService.reloadCampaigns.next(1);
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  addEStatusToTempArray(events) {
    this.campaign.temp_e_statuses_array.forEach(s => {
      if (!events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = true;
        } else {
          // tslint:disable-next-line:max-line-length
          this.campaign.temp_e_statuses_array = this.campaign.temp_e_statuses_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
      if (events.includes(s.associationable_id)) {
        if (s.id) {
          s._destroy = false;
        } else {
          // tslint:disable-next-line:max-line-length
          this.campaign.temp_e_statuses_array = this.campaign.temp_e_statuses_array.filter(b => b.associationable_id !== s.associationable_id);
        }
      }
    })
    events.forEach(e => {
      if (this.campaign.temp_e_statuses_array.length === 0) {
        this.campaign.temp_e_statuses_array.push({associationable_type : 'EStatus', associationable_id : e})
      }
      if (this.campaign.temp_e_statuses_array.filter(s => s.associationable_id === e).length === 0) {
        this.campaign.temp_e_statuses_array.push({associationable_type : 'EStatus', associationable_id : e})
      }
    });
    console.log(this.campaign.temp_e_statuses_array);
  }
}
