import { Injectable }     from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {CustomHttpClient} from './custom-http-client.service';
import {LoaderService} from './loader.service';
import {promise} from 'selenium-webdriver';
import fulfilled = promise.fulfilled;
import {Appointment} from './../health/models/appointment';

@Injectable()
export class EmergencyService {
    public practices: any;
    public practice_id = '';
    public appointments: any;
    public totalItems: any;
    public current_page = '1';
    public locations: any;
    public location_doctors: any;
    public location_id = 'Locations';
    public doctors: Observable<any[]>;
    public doctor_id = '0';
    public appointment_type = 'Emergency';
    public status_id = 'checkedin';
    public start_date: any;
    public end_date: any;
    public visit_user_type = '';
    public doctor_name = '';
    public user_type = '';
    public practice_name = 'All Practices';
    public sort_by = 'asc';
    public order_by = 'created_at asc';
    public load_filter = false;
    constructor(public loader: LoaderService, private _tokenService: CustomHttpClient) {}

    getVisitFilters(): Promise <any> {
        return this._tokenService.get('emergency/user_type_emergency_filters')
            .toPromise()
            .then(response => this.setFilters(response));
    }

    setFilters(json_filters) {
        if (json_filters.user_type == 'Staff') {
            this.user_type = 'Staff';
            this.practice_name = json_filters.practice_name
            this.practice_id = json_filters.practices.id;
            this.defaultFilters(json_filters);
        }
        if (json_filters.user_type == 'Enterprise') {
            this.user_type = 'Enterprise';
            this.defaultFilters(json_filters);
        }
        if (json_filters.user_type == 'Doctor') {
            this.user_type = 'Doctor';
            this.doctor_id = json_filters.doctor.id;
            this.doctor_name = json_filters.full_name;
            this.defaultFilters(json_filters);
        }
    }

    defaultFilters(json_filters) {
        this.locations = json_filters.locations;
        this.visit_user_type = json_filters.user_type;
        this.start_date = new Date();
        this.start_date = new Date(this.start_date);
        this.end_date = new Date();
        this.end_date = new Date(this.end_date);
        this.load_filter = true;
    }

    get practiceLocations() {
        return this.locations;
    }

    getPractices(token) {
        let args = new HttpParams();
        args = args.set('token', token);
        return this._tokenService.get('emergency/getPractices', {params: args}).share()
            .map(r => r);
    }

    getDoctors(token: string, practice_id?: any): Observable<any> {
        let args = new HttpParams();
        args = args.set('token', token);
        args = args.set('practice_id', practice_id);
        return this._tokenService.get('emergency/practice_doctors', {params: args}).share()
            .map(r => r);
    }

    isFilterLoaded() {
        return this.load_filter;
    }

    emergencyAppointment(search_params) {
      let params = new HttpParams();
      params = params.set('search_params', search_params);
      this.loader.show();
      return this._tokenService.getWithHeaders('/emergency/emergency_appointments', {observe: 'response', params: params, responseType: 'json'}).toPromise()
        .then(res => {
          this.loader.hide();
          return {
            total: res.headers.get('X-Total'),
            per_page: res.headers.get('X-Per-Page'),
            current_page: res.headers.get('X-Page'),
            total_pages: res.headers.get('X-Total-Pages'),
            previous_page: res.headers.get('X-Prev-Page'),
            filters: res.body
          }});
    }

    resetDoctor() {
      this.doctor_id = '';
      this.doctor_name = '';
    }

    resetPractice() {
        this.practice_id = '';
        this.practice_name = 'All Practices';
    }

    resetLocation() {
        this.locations = [];
        this.location_id = 'Locations';
    }

    updateLocations(practice_id) {
      let args = new HttpParams();
      args = args.set('practice_id', practice_id);
      return this._tokenService.get('emergency/selected_practice_locations', {params: args}).toPromise()
          .then(res => this.practiceLocation(res));
    }

    locationDoctors(location_id) {
        let args = new HttpParams();
        args = args.set('location_id', location_id);
        return this._tokenService.get('emergency/location_doctors', {params: args}).toPromise()
            .then(res => this.updateLocationDoctors(res));
    }

    practiceLocation(json) {
      this.locations = json.locations;
    }

    updateLocationDoctors(json) {
        this.location_doctors = [];
        this.location_doctors = json.doctors;
    }

    get searchParams() {
      let search_params = {
        practice_id: this.practice_id, location_id: this.location_id, doctor_id: this.doctor_id, status_id: this.status_id,
        appointment_type: this.appointment_type, order_by: this.order_by,
        start_date: this.start_date ? this.start_date.toDateString() : ''
      };
      return search_params;
    }

    applyFilters() {
      this.emergencyAppointment(this.searchParams).then(appointments => {
        this.appointments = appointments.filters.map(appointment => new Appointment().load_from_json(appointment));
        this.totalItems = appointments.total;
        this.current_page = appointments.current_page;
      });
    }
}
