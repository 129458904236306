import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ProgramDashboardComponent} from './program-dashboard/program-dashboard.component';
import {ProgramsComponent} from './programs/programs.component';
import {EligiblePatientsComponent} from './eligible-patients/eligible-patients.component';
import {ProgramEnrolmentComponent} from './program-enrolment/program-enrolment.component';
import {BillsComponent} from './bills/bills.component';
import {PrivateProgramBillsComponent} from './private-program-bills/private-program-bills.component';
import {PrivateProgramsComponent} from './private-programs/private-programs.component';


export const routes: Routes = [
  {
    path: 'program',
    children: [
      {
        path: 'dashboard', component: ProgramDashboardComponent
      },
      {
        path: 'programs', component: ProgramsComponent
      },
      {
        path: 'eligible', component: EligiblePatientsComponent
      },
      {
        path: 'enrolment', component: ProgramEnrolmentComponent
      },
      {
        path: 'private-bills', component: PrivateProgramBillsComponent
      },

      {
        path: 'bills', component: BillsComponent
      },
      {
        path: 'private-programs', component: PrivateProgramsComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramRoutingModule { }
