import { Injectable }     from '@angular/core';
import {  HttpParams } from '@angular/common/http';
import {CustomHttpClient} from './custom-http-client.service';
import { Appointment } from '../health/models/appointment';
import {nullRemover} from './nullRemover';
import {Lead} from '../health/models/lead';
import {Patient} from '../health/doctor/patient';
import {Subject} from 'rxjs';

@Injectable()
export class AppointmentService {
  public selected_patient: number;
  patientStatusObservable: Subject<any> = new Subject<any>();
  reloadSchedulerAppointments: Subject<any> = new Subject<any>();
  is_schedular = false;
  constructor(private _tokenService: CustomHttpClient) {}

  getPendingAppointments(filter: any): Promise<any> {
    filter = nullRemover(filter);
    let params = new HttpParams({fromObject: filter});
    return this._tokenService.getWithHeaders('appointments/pending_appointments', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          appointments: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      });
  }

  getAppointments(filter: any): Promise<any> {
    if (filter['date_filter']) {
      filter['date_filter'] = filter['date_filter'].toDateString();
    }
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('appointments', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          appointments: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      });
  }

  getDoctorLocationAppointments(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('appointments/getDoctorLocationAppointments',{observe:'response',params: params,responseType:'json'})
      .toPromise()
      .then(response => {
        return {
          appointments: response.body,
        }
      });
  }

  get_todays_patient_filter(user: any): Promise<any>{

    let params: HttpParams = new HttpParams();
    params = params.set('user_id', user.id.toString());

    return this._tokenService.get('appointments/get_todays_patient_data?user_id=' + user.id, {params: params})
        .toPromise()
      .then(response => response);
  }

  getAppointmentDates(app_type): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('app_type', app_type);
    return this._tokenService.get('appointments/appointment_dates',{params: params})
      .toPromise()
      .then(response => response);
  }

  getAppointment(id: any): Promise<any> {
    return this._tokenService.get('appointments/' + id)
      .toPromise()
      .then(response => response);
  }

  get_doctor_profile(calendar_id: number): Promise<any> {
    return this._tokenService.get('appointments/get_doctor_profile?id=' + calendar_id)
      .toPromise()
      .then(res => res);

  }

  create(appointment: Appointment): Promise<any> {
    return this._tokenService
      .post('appointments/', appointment.to_params())
      .toPromise()
      .then(res => {
        if(this.is_schedular){
          this.reloadSchedulerAppointments.next(res.id);
        }
        return appointment.load_from_json(res)
      })
  }

  createLead(patient: Patient, appointment: Lead): Promise<any> {
    return this._tokenService
        .post('lead_integration/appointment_lead', appointment.to_params(patient))
        .toPromise()
        .then(res => appointment.load_from_json(res))
  }

  acknowledge_cancel(params): Promise<any> {
    return this._tokenService
      .put('appointments/acknowledge_cancel', params)
      .toPromise()
      .then(res => res)
  }

  markCheckedIn(appointmentID: string): Promise<any> {
    return this._tokenService
      .put('appointments/mark_checked_in', {appointment_id: appointmentID})
      .toPromise()
      .then(res => res)
  }

  update(params): Promise<any> {
    return this._tokenService
      .put('appointments', params)
      .toPromise()
      .then(res => res)
  }

  save(appointment: Appointment): Promise<any> {
      return this.create(appointment);
  }

  saveLead(patient, appointment: Lead): Promise<any> {
    return this.createLead(patient, appointment);
  }

  delete(calendar_id: any): Promise<any> {
    return this._tokenService.delete('appointments?calendar_id=' + calendar_id)
      .toPromise()
      .then(res => res);
  }

  set_selected_patient(patient_id) {
    this.selected_patient = patient_id;
  }

  change_status(id, status, reason) {
    let params: HttpParams = new HttpParams();
    params = params.set('appointment_id', id.toString());
    params = params.set('status', status);
    params = params.set('reason', reason);
    return this._tokenService.get('appointments/update_status', {
      params: params
    }).toPromise()
      .then(response => {
        if(this.is_schedular){
          this.reloadSchedulerAppointments.next(response.appointment.id)
        }
        return response;
      });
  }
  setDoctorID(appointmentID, doctorID) {
    return this._tokenService.put('appointments/set_doctor_id', {appointment_id: appointmentID, doctor_id: doctorID})
      .toPromise()
      .then(response => response);
  }
  fetch_vendor_appointments(date: any) {
    let params: HttpParams = new HttpParams();
    params = params.set('date', date);
    return this._tokenService.get('appointments/fetch_vendor_appointments', {params: params})
      .toPromise()
      .then(response => response);
  }
  show_status_class(status: string) {
    if (status) {
      status.toString();
      if (status.includes('online')) {
        return 'online'
      } else if (status.includes('away')) {
        return 'away'
      } else if (status.includes('offline')) {
        return 'offline'
      }
    }
  }
}
