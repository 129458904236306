import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../services/tabs.service';

@Component({
  selector: 'app-alert-template-index',
  templateUrl: './alert-template-index.component.html',
  styleUrls: ['./alert-template-index.component.scss']
})
export class AlertTemplateIndexComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('Alerts')
  }

  ngOnInit(): void {
  }

}
