import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DocumentsService} from '../../../../services/document.service';
import {PatientDocument} from '../../../../health/patient-profile/patient-dashboard/chart/document';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-patient-documents',
  templateUrl: './patient-documents.component.html',
  styleUrls: ['./patient-documents.component.scss']
})
export class PatientDocumentsComponent implements OnInit {

  @ViewChild('classicModal') modal;
  page_number = 1;
  total_pages = 1;
  documents: PatientDocument[];
  public timer;
  @Input() patient_id;
  @Input() show_documents: boolean;
  @Output() upload_clicked = new EventEmitter<Boolean>();
  @Output() send_document = new EventEmitter<PatientDocument>();
  constructor(private document_service: DocumentsService, private user_service: UserService) { }

  ngOnInit() {
    this.getDocuments();
  }

  ngOnChanges() {
  }

  getDocuments() {
    let pid = this.patient_id || this.current_user && this.current_user.isPatient && this.current_user.profileable_id;
    if (pid && this.page_number <= this.total_pages) {
      this.document_service.getDocuments(pid, this.page_number).then(r => {
        if (this.page_number <= 1) {
          this.documents = [];
        }
        this.total_pages = parseInt(r.total_pages);
        r.documents.map(pd => this.documents.push(new PatientDocument().load_from_json(pd)));
        if (this.show_documents) {
          if (this.documents &&  this.documents.length > 0) {
            this.modal.show();
          }
          else { this.triggerUploadButton(); }
        }
      });
    }
  }

  get current_user() {
  return this.user_service.current_user;
  }

  triggerUploadButton() {
    this.upload_clicked.emit(true);
    this.modal.hide();
  }

  sendDocument(document: PatientDocument) {
    this.send_document.emit(document);
    this.modal.hide();
  }
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let offset = 30;
      // visible height + pixel scrolled >= total height
      if (event.target.offsetHeight + event.target.scrollTop + offset >= event.target.scrollHeight) {
        this.page_number += 1 ;
        this.getDocuments();
      }
    }, 200);
  }

}
