import {Comment} from '../../../routes/practice-site/tasks/comment';
export class PatientEvent {
  id: number;
  start_date: any;
  end_date: Date;
  end_by_date: Date = new Date();
  is_notify = true;
  notify_before: any[] = ['15_mins'];
  subject: string;
  duration = '30_mins';
  description: string;
  event_color: string;
  status_color: string;
  no_of_occurrences = 1;
  weekdays: string;
  weekdays_binary: string;
  parent_id: number;
  repeat_number = 1;
  practice_id: number;
  patient_id: number;
  eventable_id: number;
  eventable_type: string;
  action_by_id: number;
  repeat_type: string;
  event_type: string;
  status = 'to_do';
  recur_type = 'never';
  end_type = 'end_after';
  creator_id: number;
  updater_id: number;
  notification_ids: any[];
  month_repeat_day = 1;
  updater_name: string;
  creator_name: string;
  appointment_with: string;
  updated_at: Date;
  monday = false;
  tuesday = false;
  wednesday = false;
  thursday = false;
  friday = false;
  saturday = false;
  sunday = false;
  date: any;
  comments: Comment[] = [];
  week_days_mapping = {
    0: 'monday',
    1: 'tuesday',
    2: 'wednesday',
    3: 'thursday',
    4: 'friday',
    5: 'saturday',
    6: 'sunday'
  }

  to_params() {
    return {
      patient_event: {
        id: this.id,
        start_date: this.start_date,
        is_notify: this.is_notify,
        event_type: this.event_type,
        subject: this.subject,
        duration: this.duration,
        status: this.status,
        event_color: this.setColorOfEvent(this.event_type),
        status_color: this.setColorOfEventStatus(this.status),
        weekdays: this.convert_binary_to_base10(),
        recur_type: this.recur_type,
        practice_id: this.practice_id,
        patient_id: this.patient_id,
        eventable_id: this.eventable_id,
        eventable_type: this.eventable_type,
        action_by_id: this.action_by_id,
        description: this.description,
        creator_id: this.creator_id,
        updater_id: this.updater_id,
        month_repeat_day: this.month_repeat_day,
        notify_before: this.notify_before,
        repeat_number: this.repeat_number,
        end_type: this.end_type,
        no_of_occurrences: this.no_of_occurrences,
        end_date: this.end_date,
        end_by_date: this.end_by_date,
        repeat_type: this.repeat_type
      }
    }
  }

  load_from_json(json: any) {
    this.id = json.id;
    this.start_date = new Date(json.start_date);
    this.end_date = json.end_date;
    this.is_notify = json.is_notify;
    this.notify_before = json.notify_before;
    this.subject = json.subject;
    this.duration = json.duration;
    this.description = json.description;
    this.event_color = json.event_color;
    this.no_of_occurrences = json.no_of_occurrences;
    this.status_color = json.status_color;
    this.parent_id = json.parent_id;
    this.repeat_number = json.repeat_number;
    this.practice_id = json.practice_id;
    this.eventable_id = json.eventable_id;
    this.creator_id = json.creator_id;
    this.eventable_type = json.eventable_type;
    this.action_by_id = json.action_by_id;
    this.repeat_type = json.repeat_type;
    this.event_type = json.event_type;
    this.status = json.status;
    this.recur_type = json.recur_type;
    this.end_type = json.end_type;
    this.updater_id = json.updater_id;
    this.patient_id = json.patient_id;
    this.month_repeat_day = json.month_repeat_day;
    this.updater_name = json.updater_name;
    this.updated_at = json.updated_at;
    this.creator_name = json.creator_name;
    this.appointment_with = json.appointment_with;
    this.notification_ids = json.notification_ids;
    this.date = new Date(json.start_date).toDateString();
    if (json.comments.length > 0) {
      this.comments = json.comments.map(comment => new Comment().load_from_json(comment))
    }
    if (json.end_by_date) {
      this.end_by_date = new Date(json.end_by_date);
    }
    this.weekdays_binary = json.weekdays_binary;
    if (json.weekdays_binary) {
      this.set_weekdays(json.weekdays_binary);
    }
    this.weekdays = json.weekdays;
    return this;
  }

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  setColorOfEvent(s) {
    let status = s.toLowerCase();
    switch (status) {
      case  'appointment':
        return '#bc8ede';
      case 'medication':
        return '#ffd966';
      case 'vital_sign':
        return '#c7d5ed';
      case 'health_maintenance':
        return '#f9a5e1';
      case 'other':
        return '#d0cece'
    }
  }
  setColorOfEventStatus(s) {
    let status = s.toLowerCase();
    switch (status) {
      case  'to_do':
        return '#00a2e8';
      case 'done':
        return '#22b14c';
      case 'missed':
        return '#FFC0CB';
      case 'cancelled':
        return '#808080';
    }
  }

  start_date_format(date): string {
    return new Date((Date.parse(date) - (new Date()).getTimezoneOffset() * 60000)).toISOString()
      .substring(0, new Date((Date.parse(date) - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().length - 1).replace(/\.\d+/, '');
  }

  set_weekdays(weekdays_binary) {
    if (weekdays_binary == '0000000') {
      return
    }
    for (let i = 0, len = weekdays_binary.length; i < len; i++) {
      let binary_value = weekdays_binary.charAt(i);
      let is_selected = (binary_value == '0') ? false : true;
      let day = this.week_days_mapping[i];
      this.set_days_values(day, is_selected);
    }
  }

  set_days_values(day, is_selected) {
    if (day === 'monday') {
      this.monday = is_selected;
    } else if (day === 'tuesday') {
      this.tuesday = is_selected;
    } else if (day === 'wednesday') {
      this.wednesday = is_selected;
    } else if (day === 'thursday') {
      this.thursday = is_selected;
    } else if (day === 'friday') {
      this.friday = is_selected;
    } else if (day === 'saturday') {
      this.saturday = is_selected;
    } else if (day === 'sunday') {
      this.sunday = is_selected;
    }
  }

  convert_binary_to_base10() {
    let str: any;
    let list: Array<boolean> = [];
    let convert_into_binary;
    let convert_into_base2;

    list.push(this.monday, this.tuesday, this.wednesday, this.thursday, this.friday, this.saturday, this.sunday);
    convert_into_binary = list.map(function (x) {
      return x ? 1 : 0
    }).join('');
    convert_into_base2 = parseInt(convert_into_binary, 2);
    return convert_into_base2;
  }
}
