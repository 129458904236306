import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RemindersRoutingModule } from './reminders-routing.module';
import { RemindersComponent } from './reminders/reminders.component';
import { RemindersListingComponent } from './reminders-listing/reminders-listing.component';
import {PaginationModule} from "ngx-bootstrap/pagination";
import {TabsModule} from "../tabs/tabs.module";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import { EditReminderComponent } from './edit-reminder/edit-reminder.component';
import { NewReminderComponent } from './new-reminder/new-reminder.component';
import { ReminderListingComponent } from './reminder-listing/reminder-listing.component';
import {MultiselectDropdownModule} from "angular-2-dropdown-multiselect";
import { EditorModule,TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import {NgxMaskModule} from "ngx-mask";


@NgModule({
  declarations: [RemindersComponent, RemindersListingComponent, EditReminderComponent, NewReminderComponent, ReminderListingComponent],
  imports: [
    CommonModule, TabsModule, SharedModule,
    RemindersRoutingModule, PaginationModule,FormsModule,
    BsDropdownModule, BsDatepickerModule, NgSelectModule,MultiselectDropdownModule,EditorModule,NgxMaskModule
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  exports: [RemindersComponent]
})
export class RemindersModule { }
