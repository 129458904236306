<div class="container">
  <h3 class="text-primary m0">Payment Information</h3>
  <h5 class="text-muted mt-sm mb0">Enter your Card Information</h5>
  <div class="row mt-xl mb-xl">
    <div class="col-lg-8 col-md-7 col-sm-6 col-xs-12">
      <div class="border-box shadow p-lg">
        <div class="row">
          <div class="col-sm-6 col-xs-12"><h4 class="m0 mb-xl fw-500">Secure Payment Info</h4></div>
          <div class="col-sm-6 col-xs-12 text-right"><img src="assets/img/credit-card-icons.gif" alt="Icons"></div>
        </div>
        <form id="payment-form">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
          <div class="mt-lg" *ngIf="!paying">
            <input *ngIf="payment" type="button" value="Pay Now" (click)="submit();"
                   class="btn btn-primary btn-lg fs20 pay-button"/>
            <input *ngIf="update_card" type="button" value="Update" (click)="submit();"
                   class="btn btn-primary btn-lg fs20 pay-button"/>
            <input *ngIf="addPaymentMethod" type="button" value="Create" (click)="submit();"
                   class="btn btn-primary btn-lg fs20 pay-button"/>
            <input *ngIf="appointmentPayment" type="button" value="Pay Now" (click)="submit();"
                   class="btn btn-primary btn-lg fs20 pay-button"/>
          </div>          <div id="error-message">
            <!-- Display error message to your customers here -->
          </div>
        </form>
<!--        <form id="cardForm">-->
<!--          <div class="panel__content">-->
<!--            <div class="textfield&#45;&#45;float-label">-->
<!--              &lt;!&ndash; Begin hosted fields section &ndash;&gt;-->
<!--              <label class="hosted-field&#45;&#45;label" for="card-number"><span class="icon">-->
<!--     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M0 0h24v24H0z"-->
<!--                                                                                              fill="none"/><path-->
<!--       d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg></span>-->
<!--                Card Number-->
<!--              </label>-->
<!--              <div id="card-number" class="hosted-field"></div>-->
<!--              &lt;!&ndash; End hosted fields section &ndash;&gt;-->
<!--            </div>-->
<!--            <div class="custom-name">-->
<!--              <label class="hosted-field&#45;&#45;label" for="cardholder-name" name="cardholderName">-->
<!--      <span class="icon">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path-->
<!--          d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"/><path-->
<!--          d="M0 0h24v24H0z" fill="none"/></svg>-->
<!--        </span>-->
<!--                Cardholder's Name</label>-->
<!--              <input type="text" placeholder="e.g. JOHN DOE" class="name-custom-input" [(ngModel)]="cardholdersName"-->
<!--                     name="cardholderName"/>-->
<!--            </div>-->
<!--            <div class="textfield&#45;&#45;float-label">-->
<!--              &lt;!&ndash; Begin hosted fields section &ndash;&gt;-->
<!--              <label class="hosted-field&#45;&#45;label" for="expiration-date">-->
<!--       <span class="icon">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path-->
<!--        d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"/></svg>-->
<!--     </span>-->
<!--                Expiration Date</label>-->
<!--              <div id="expiration-date" class="hosted-field"></div>-->
<!--              &lt;!&ndash; End hosted fields section &ndash;&gt;-->
<!--            </div>-->
<!--            <div class="textfield&#45;&#45;float-label">-->
<!--              &lt;!&ndash; Begin hosted fields section &ndash;&gt;-->
<!--              <label class="hosted-field&#45;&#45;label" for="cvv">-->
<!--      <span class="icon">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path-->
<!--          d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>-->
<!--        </span>-->
<!--                CVV</label>-->
<!--              <div id="cvv" class="hosted-field"></div>-->
<!--              &lt;!&ndash; End hosted fields section &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
<!--          <footer class="panel__footer">-->
<!--            <p class="fs13 m0">By clicking "Pay" below you agree to be charged Package Amount now and accept our Terms-->
<!--              of-->
<!--              use and Privacy Policy.-->
<!--              <span class="fw-500">You can cancel at any time.</span>-->
<!--            </p>-->
<!--            <hr class="hr-line">-->
<!--            <div class="mt-lg" *ngIf="!paying">-->
<!--              <input *ngIf="payment" type="button" value="Pay Now" (click)="tokenizeUserDetails();"-->
<!--                     class="btn btn-primary btn-lg fs20 pay-button"/>-->
<!--              <input *ngIf="update_card" type="button" value="Update" (click)="updateCard();"-->
<!--                     class="btn btn-primary btn-lg fs20 pay-button"/>-->
<!--              <input *ngIf="addPaymentMethod" type="button" value="Create" (click)="createPaymentMethod();"-->
<!--                     class="btn btn-primary btn-lg fs20 pay-button"/>-->
<!--              <input *ngIf="appointmentPayment" type="button" value="Pay Now" (click)="makeAppointmentPayment();"-->
<!--                     class="btn btn-primary btn-lg fs20 pay-button"/>-->
<!--            </div>-->
<!--            <div class="mt-lg" *ngIf="paying">-->
<!--              <a class="btn btn-primary btn-lg fs30 pay-button"><i class="fa fa-spinner fa-spin"></i></a>-->
<!--            </div>-->
<!--          </footer>-->
<!--        </form>-->
      </div>
    </div>
    <ng-container *ngIf="package_type">
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngIf="package_type == 'easy_going'">
        <div class="panel panel-default review-order">
          <div class="panel-heading">Review Your Order</div>
          <div class="panel-body">
            <h4 class="bg-success package-title">Easy Going</h4>
            <h1 class="m0 fw-500">$29 <small class="fs13">Per Provider Per Month</small></h1>
            <h5>Appointment Widget Implementation by WellWink</h5>
            <ul class="list list-unstyled">
              <li>
                <i class="fa fa-chevron-right"></i> Online Appointment Widget
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Unlimited Doctors, Locations and Staff
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Doctors listing on WellWink Portal
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Full Portal Access
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Online appointment notification sent via email
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Recall Management
              </li>
            </ul>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <h4 class="mt-sm">Total Payment</h4>
              </div>
              <div class="col-sm-6 col-xs-12">
                <h3>$250/-</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngIf="package_type == 'better_deal'">
        <div class="panel panel-default review-order">
          <div class="panel-heading">Review Your Order</div>
          <div class="panel-body">
            <h4 class="bg-warning package-title">Better Deal</h4>
            <h1 class="m0 fw-500">$69 <small class="fs13">Per Provider Per Month</small></h1>
            <h5>Appointment Widget & Practice Website</h5>
            <ul class="list list-unstyled">
              <li>
                <i class="fa fa-chevron-right"></i> Online Appointment Widget
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Unlimited Doctors, Locations and Staff
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Doctors listing on WellWink Portal
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Full Portal Access
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Online appointment Pushed to PMS
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> PMS Integration
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Recall Management
              </li>
            </ul>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <h4 class="mt-sm">Total Payment</h4>
              </div>
              <div class="col-sm-6 col-xs-12">
                <h3>$250/-</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" *ngIf="package_type == 'ultimate'">
        <div class="panel panel-default review-order">
          <div class="panel-heading">Review Your Order</div>
          <div class="panel-body">
            <h4 class="bg-info package-title">Ultimate</h4>
            <h1 class="m0 fw-500">$119 <small class="fs13">Per Provider Per Month</small></h1>
            <h5>Full Featured Subscription</h5>
            <ul class="list list-unstyled">
              <li>
                <i class="fa fa-chevron-right"></i> All Features included in Better Deal
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Reputation Management
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Practice Marketing
              </li>
              <li>
                <i class="fa fa-chevron-right"></i> Telemedicine
              </li>
            </ul>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <h4 class="mt-sm">Total Payment</h4>
              </div>
              <div class="col-sm-6 col-xs-12">
                <h3>{{(package_type == 'ultimate' && pre_package_type == 'free_widget') ? '$250' : '$250'}}/-</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
