import {Component, ViewEncapsulation, OnInit, ChangeDetectorRef, ViewChild, Inject, HostBinding} from '@angular/core';
import baseApiUrl, {Globals} from './globals';
import {UserService} from './services/user.service'
import {SettingsService} from './core/settings/settings.service';
import {EnoteService} from './services/enote.service';
import {Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {MessageService} from './services/message.service';
import {Chatroom, Message} from './routes/practice-site/chat/chatroom';
import {HttpTokenService} from './services/http.token.service';
import {GlobalErrorHandlerService} from './services/global-error.service';
import {VideoChatService} from './video-chat/services/videochat.service';
import {AppointmentService} from './services/appointment.service';
import {VideoPanelComponent} from './video-chat/video-panel/video-panel.component';
import {SeoService} from './services/seo.service';
import {filter, map, mergeMap} from 'rxjs/operators';
import {PhoneService} from './voice-call/phone.service';
import {TrackingService} from './services/tracking.service';
import {Subscription} from 'rxjs/Subscription';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {TabsService} from './services/tabs.service';
import {DOCUMENT} from '@angular/common';
import {EnrolmentsService} from './program/enrolments.service';
import { HttpClient } from '@angular/common/http';
import {VersionCheckService} from './services/version-check.service';
import {environment} from '../environments/environment';
import {SmsService} from './routes/practice-site/sms/sms.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  @ViewChild('video_panel', { static: false }) video_panel: VideoPanelComponent;
  browser = <any>navigator;
  public chatroom: Chatroom;
  public user_subscription;
  public chatroom_host: string = baseApiUrl + '/cable';
  public user_channel =  'UserChannel';
  @ViewChild('classicModal') classicModal;
  closeTabSubscription: Subscription;
  public description: any = 'Chart Review and Monitoring';
  enrollmentObject = {
    time_for_log: 0, adjust_unlogged_time: 0, session_time: 0,
    logged_time: 0, id: 0, patient_name: '', idle_time: 0, programme_type: ''
  };
  // private cable: any;
  //
  // @HostBinding('class.layout-fixed') get isFixed() {
  //   return this.settings.layout.isFixed;
  // };
  //
  // @HostBinding('class.aside-collapsed') get isCollapsed() {
  //   return this.settings.layout.isCollapsed;
  // };
  //
  // @HostBinding('class.layout-boxed') get isBoxed() {
  //   return this.settings.layout.isBoxed;
  // };
  //
  // @HostBinding('class.layout-fs') get useFullLayout() {
  //   return this.settings.layout.useFullLayout;
  // };
  //
  // @HostBinding('class.hidden-footer') get hiddenFooter() {
  //   return this.settings.layout.hiddenFooter;
  // };
  //
  // @HostBinding('class.layout-h') get horizontal() {
  //   return this.settings.layout.horizontal;
  // };
  //
  // @HostBinding('class.aside-float') get isFloat() {
  //   return this.settings.layout.isFloat;
  // };
  //
  // @HostBinding('class.offsidebar-open') get offsidebarOpen() {
  //   return this.settings.layout.offsidebarOpen;
  // };
  //
  // @HostBinding('class.aside-toggled') get asideToggled() {
  //   return this.settings.layout.asideToggled;
  // };
  //
  // @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
  //   return this.settings.layout.isCollapsedText;
  // };


   @HostBinding('class.aside-collapsed') get isCollapsed() {
     return this.settings.layout.isCollapsed;
  };
  constructor(private versionCheckService: VersionCheckService, private activatedRoute: ActivatedRoute,httpClient: HttpClient,
              private seoService: SeoService, private _message_service: MessageService,
              private router: Router, public settings: SettingsService,
              private globals: Globals, public user_service: UserService,
              private globalError: GlobalErrorHandlerService,
              private _enote_service: EnoteService, private token_service: HttpTokenService,
              public phoneService: PhoneService, cd: ChangeDetectorRef,
              public videoChatService: VideoChatService, public appointment_service: AppointmentService,
              public trackingService: TrackingService,
              private _tabs_service: TabsService, private idle: Idle,
              private enrolmentService: EnrolmentsService,
              private smsService: SmsService,
              @Inject(DOCUMENT) private document: Document) {
    this.router.events.subscribe();
    this.closeTabSubscription = this.trackingService.closeTabCall.subscribe(item => {
      if (item) {
        this.enrollmentObject = item;
        if (this.enrollmentObject.time_for_log > 0) {
          this.enrollmentObject.time_for_log = Number(this.enrollmentObject.time_for_log);
          this.enrollmentObject.adjust_unlogged_time = this.enrollmentObject.time_for_log;
          this.enrollmentObject.programme_type = this.enrollmentObject.programme_type;
          this.classicModal.show();
        } else {
          this.closeClassicModal(this.enrollmentObject);
        }
      }
    });
    idle.setIdle(15);
    idle.setTimeout(false);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this._tabs_service.startStop();
      this.reset();
    });
    idle.onIdleStart.subscribe(() => {
      // this.trackingService.stopChatTime.next(1);
      this._tabs_service.stop();
    });
    this.reset();
  }

  reset() {
    this.idle.watch();
  }

  visibilitychange() {
    document.addEventListener('visibilitychange', () => {
      if (this.user_service.current_user) {
        this._message_service.notifyUserFocus(!document.hidden);
      }
      if (document.hidden) {
        this._tabs_service.stop();
      }
      else {
        this._tabs_service.startStop();
      }
    });
  }

  ngOnInit() {
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    if (!this.user_service.current_user) {
      this.user_service.validateToken();
    }
    window.addEventListener('storage', function () {
      if (localStorage.getItem('refresh')) {
        window.parent.location.reload();
        localStorage.removeItem('refresh');
      }
    });

    this.token_service.subscriptionObserver.subscribe((c) => {
      this.subscribeToChannel();
    });
    this.token_service.logoutObserver.subscribe((c) => {
      this.unsubscribeFromChannel();
    });
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe(data => {
      let seoData = data['seo'];
      if (seoData) {
        this.seoService.updateTitle(seoData['title']);
        this.seoService.updateMetaTags(seoData['metaTags']);
      }
    });
    this.visibilitychange();
  }

  receiveCall(): void {
    this.videoChatService.disable_button = true;
    this.browser.getUserMedia = (this.browser.getUserMedia ||
      this.browser.webkitGetUserMedia ||
      this.browser.mozGetUserMedia ||
      this.browser.msGetUserMedia);

    this.browser.mediaDevices.getUserMedia({video: true, audio: true}).then(stream => {
      stream.getTracks().forEach(t => { t.stop() });
      this.videoChatService.showSmallWindow = false;
      this.videoChatService.showLayoutVideo = true;
      this.videoChatService.pauseAudio();
      setTimeout(() => {
        this.video_panel.onRoomChanged(this.videoChatService.room_name).then(r => {
          this.videoChatService.inCall = true;
          this.videoChatService.hidden = true;
          this.videoChatService.startTimer();
        });
      }, 1000)
    }).catch(error => {
      this.declineCall();
    });
  }
  declineCall() {
    this.videoChatService.pauseAudio();
    this.videoChatService.hidden = true;
    this.videoChatService.showLayoutVideo = false;
    this.videoChatService.broadcastCallCancelled(this.videoChatService.patient_user_id, this.videoChatService.caller_id).then();
  }


  subscribeToChannel() {
    // this.cable = ActionCable.createConsumer(this.chatroom_host + '?bbbbbbbb=33333&user_id=' + this.user_service.current_user.id)
    // this.user_subscription = this.token_service.cable.subscriptions.create(
    this.user_subscription = this.user_service.app_component_subscription = this.token_service.cable.subscriptions.create(
      this.user_channel,
      {
        connected: () => console.log('connected'),
        disconnected: () => console.log('disconnected'),
        received: (data) => this.receivedHandlerApp(data)
      });
  }

  receivedHandlerApp(broadcasting)  {
    console.log('UserChannel', broadcasting);
    if ((broadcasting as any).unread_chats_count) {
      this._message_service.setMessagesNotificationsCount((broadcasting as any).unread_chats_count);
    }
    if ((broadcasting as any).channel_action === 'patient_review') {
      this._message_service.set_patient_review_count();
      return true;
    }
    if ((broadcasting as any).channel_action === 'alert') {
      console.log('alert', (broadcasting as any))
      this.enrolmentService.alertPushed.next((broadcasting as any).alert);
      return true;
    }
    if ((broadcasting as any).channel_action === 'sms_reply') {
      console.log('sms_reply', (broadcasting as any))
      this.smsService.handleSMSReply((broadcasting as any).sms_message);
      return true;
    }
    if ((broadcasting as any).channel_action === 'task_notification') {
      this._message_service.pushTaskNotification((broadcasting as any).notification);
      return true;
    }
    if ((broadcasting as any).channel_action === 'patient_events') {
      this._message_service.pushPatientEventNotification((broadcasting as any).notification);
      return true;
    }
    if ((broadcasting as any).channel_action === 'user_deactive') {
      this.globalError.error = 'User deactivated by Admin.';
      this.user_service.logout();
      return true;
    }
    if ((broadcasting as any).channel_action === 'video_call') {
      this.videoChatService.call_cancelled = false;
      this.videoChatService.playAudio();
      this._message_service.selected_chatroom = null;
      this.videoChatService.caller_id = (broadcasting as any).caller_id
      this.videoChatService.caller_full_name = (broadcasting as any).caller_full_name
      this.videoChatService.caller_profile_pic = (broadcasting as any).caller_profile_pic
      this._message_service.set_user_on_selection((broadcasting as any).caller_id);
      this.videoChatService.room_name = (broadcasting as any).room_name;
      if (this.videoChatService.showLayoutVideo) {
        this.videoChatService.showLayoutVideo = false;
      }
      if (!this.videoChatService.showLayoutVideo) {
        this.videoChatService.showLayoutVideo = true;
        this.videoChatService.showSmallWindow = false;
      }
      this.videoChatService.hidden = false;
      this.videoChatService.disable_button = false;
    }
    if ((broadcasting as any).channel_action === 'video_call_cancelled') {
      this.videoChatService.cancelCall();
    }
    if ((broadcasting as any).channel_action === 'user_status') {
      this._message_service.set_user_status((broadcasting as any));
      this.appointment_service.patientStatusObservable.next((broadcasting as any));
    } else {
      if ((broadcasting as any).user_id !== this.user_service.current_user.id) {
        if (this._message_service.is_new_chatroom((broadcasting as any).chatroom)) {
          let new_chatroom = new Chatroom();
          new_chatroom = new_chatroom.load_from_json((broadcasting as any).chatroom);
          if (!this._message_service.chatrooms.filter(c => c.id == new_chatroom.id)[0]) {
            this._message_service.chatrooms.unshift(new_chatroom);
          }
          if (this._message_service.chatrooms.length < 1) {
            if (!this._message_service.selected_chatroom) {
              this._message_service.changeSelectedChatroom(new_chatroom);
              this._message_service.make_chatroom_connection(new_chatroom);
            }
            this._message_service.changeSelectedChatroom(new_chatroom);
          } else {
            this._message_service.incrementChatroomCount((broadcasting as any).chatroom_id, false);
            this._message_service.setMessagesNotificationsCount((this._message_service.notifications['messages'].count || 0) + 1);
          }
          this._message_service.make_chatroom_connection(new_chatroom);
        } else {
          if (this._message_service.chatrooms.length < 2) {
            // tslint:disable-next-line:max-line-length
            this._message_service.changeSelectedChatroom(this._message_service.chatrooms.filter(e => e.id == (broadcasting as any).chatroom_id)[0]);
          } else if ((broadcasting as any).message_type !== 'system') {
            // tslint:disable-next-line:max-line-length
            let chat_opened = this._message_service.triggered && this._message_service.selected_chatroom && (this._message_service.selected_chatroom.id == (broadcasting as any).chatroom_id);
            // tslint:disable-next-line:max-line-length
            this._message_service.incrementChatroomCount((broadcasting as any).chatroom_id, chat_opened && this._message_service.user_in_foreground);
            if (chat_opened && this._message_service.user_in_foreground) {
              this._message_service.restartUnreadCount((broadcasting as any).chatroom_id)
            }
          }
        }
      }
    }
    if ((broadcasting as any).message_type === 'system') {
      this._message_service.handleSystemMessage(new Message().load_from_json(broadcasting));
    }
  }

  unsubscribeFromChannel() {
    this.user_subscription?.unsubscribe();
  }

  saveRemainingTime(params) {
    if (params.adjust_unlogged_time.toString() !== '0') {
      this.trackingService.logTimeForPatientActivity({duration: params.adjust_unlogged_time, description: this.description,
        activity_type: 'chart_review', activiable_id: params.id, source_id: params.id , remaningTime: true, programme_type: params.programme_type});
    }
    this.closeClassicModal(params);
  }

  closeClassicModal(enrollmentObject) {
    this.trackingService.deleteEnrollmentObjectLog(enrollmentObject.id);
    this.description = 'Chart Review and Monitoring';
    this.classicModal.hide();
  }

  get getValue(): number {
    return this.enrollmentObject.time_for_log;
  }

  setLoggedTime(e: any) {
    this.enrollmentObject.adjust_unlogged_time = e.target.value;
  }
}
