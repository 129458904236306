import {Component, Injector, Input, Output, OnInit, ViewChild, EventEmitter, ElementRef, OnDestroy} from '@angular/core';
import {ReminderTemplate, StatusLog} from '../reminder-template';
import {NgForm} from '@angular/forms';
import {LoaderService} from '../../services/loader.service';
import {TabsService} from '../../services/tabs.service';
import { IMultiSelectOption} from 'angular-2-dropdown-multiselect';
import {default as tinymce} from 'tinymce';
import {SpecialtySearchService} from '../../services/specialty-search.service';
import {Service} from '../../routes/practices/practices/practice';
import {Tab} from '../../tabs/tab';
import {RemindersService} from '../reminders.service';
import {EditReminderComponent} from '../edit-reminder/edit-reminder.component';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {UserService} from '../../services/user.service';
import {AttachmentService} from '../../services/attachments.service';
import {environment} from '../../../environments/environment';
import {Amenity, ReminderAmenity} from '../../routes/practices/amenity/amenity';
@Component({
  selector: 'app-new-reminder',
  templateUrl: './new-reminder.component.html',
  styleUrls: ['./new-reminder.component.scss']
})
export class NewReminderComponent implements OnInit, OnDestroy {
  @Input() reminder: ReminderTemplate;
  public rules = [{name: 'Prior To', value: 'prior'}, {name: 'After', value: 'after'}]
  show_attachment_popup = false;
  @ViewChild('reminderForm') reminderForm: NgForm;
  @Output() closeform = new EventEmitter<boolean>();
  public btrigger = [{name: 'Morning (12:00 AM - 10 AM)', value: 'morning'}, {name: 'Noon (10 AM - 1 PM)', value: 'noon'}, {name: 'Afternoon (1 PM - 6PM)', value: 'after_noon'}, {name: 'Evening (5PM -7PM)', value: 'evening'}, {name: 'Night (8 PM-11 PM)', value: 'night'}]
  public atrigger = [{name: 'Minute (s)', value: 'minutes'}, {name: 'Hour (s)', value: 'hours'}, {name: 'Day (s)', value: 'days'}, { name: 'Week (s)', value: 'weeks'}];
  public rtrigger = [{name: 'Day (s)', value: 'days'}, { name: 'Week (s)', value: 'weeks'}, {name: 'Month(s)', value: 'months'}, {name: 'Year(s)', value: 'years'}];

  public a_custom_fields: Array<string> = ['Tags', 'Patient First Name', 'Patient Last Name', 'Patient DOB', 'Patient Gender', 'Appointment DateTime',
    'Reason Of Cancellation', 'Child Appointment DateTime', 'Location Email', 'Practice Email Disclaimer',
    'Patient Birth Day', 'Patient Birth Month', 'Doctor Full Name', 'Doctor Full name - with prefix/suffix', 'Doctor Designation', 'Location City State Zip',
    'Appointment Location Name', 'Appointment Location Full Address', 'Appointment Date', 'Appointment Time',
    'Appointment Status', 'Appointment Reason of Visit', 'Practice Name', 'Practice Full Address', 'Practice Phone Number'];

  public b_custom_fields: Array<string> = ['Tags', 'Patient First Name', 'Patient Last Name', 'Patient DOB', 'Patient Gender', 'Practice Email Disclaimer',
    'Patient Birth Day', 'Patient Birth Month', 'Practice Name', 'Practice Full Address', 'Practice Phone Number'];

  public myOptions: IMultiSelectOption[];
  public optionsModel: string[];
  public attachment_ids: any[] = [];
  public image_url: any;
  public editor: any;
  @ViewChild('s_body') s_body: ElementRef;
  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 300,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
    'styleselect |fontsizeselect fontselect fontsizeselect | bold italic underline strikethrough | \ ' +
    'insertfile undo redo numlist outdent | bullist indent |contextmenu table | uploadimage media | \ ' +
    'link image forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.editor = editor;
    },

    images_upload_handler:
      (data, success, failure) => {
      if (data && data.hasOwnProperty('error') == false) {
        this.loaderService.small_loder_show();
        let id = this.reminder.id ? this.reminder.id : 0;
        let attachment = { attachable_id: id, attachable_type: 'ReminderTemplate',
          fileType: data.blob().type, file: data.blob(), description: data.filename()};
        let callback = (p: any): void => {
        };

        let upload_callback = (data: any) => {
          data = JSON.parse(data);
          if (data !== '') {
            this.loaderService.small_loder_hide();
            this.image_url =  data.url;
            this.attachment_ids.push(data.id);
            success(data.url);
          }
        };
        if (attachment.file === undefined) {
          this.globalErrorHandlerService.error = 'Please select a valid file.';
        }
        else {
          let latest_picture = this.attachmentService.upload(attachment, callback, upload_callback);
          console.log(latest_picture);
        }
      }
    },


  }
  public total_services: any;
  public specialtiesLoading = false;
  services: Service[] = [new Service()];
  selectedServices: object[];
  public total_amenities: any;
  amenities: ReminderAmenity[] = [new ReminderAmenity()];
  selectedAmenities: object[];



  constructor(private userService: UserService , public globalErrorHandlerService: GlobalErrorHandlerService,
              private attachmentService: AttachmentService,
              public reminder_template_servie: RemindersService, private specialtySearchService: SpecialtySearchService,
              public injector: Injector, public loaderService: LoaderService, public tabs_service: TabsService) { }

  ngOnInit(): void {


    this.myOptions = [
      { id: 'cancelled', name: 'cancelled' },
      { id: 'scheduled', name: 'scheduled' },
      { id: 'confirmed', name: 'confirmed' },
      { id: 'checkedin', name: 'checkedin' },
      { id: 'rescheduled', name: 'rescheduled' },
      { id: 'noshow', name: 'noshow' },
      { id: 'checkedout', name: 'checkedout' },
      { id: 'admitted', name: 'admitted' },
      { id: 'acknowledged', name: 'acknowledged' },
      { id: 'pending', name: 'pending' }

    ];
    let newReminder = this.injector.get('reminder', null);
    if (newReminder) {
      this.reminder = newReminder;
    } else {
      this.reminder = new ReminderTemplate();
    }
    this.optionsModel = this.reminder.statuses.map(s => s.status_id);
    this.selectedServices = this.reminder.services.map(s => ({id: s.service_id, text: s.name}));
    this.selectedAmenities = this.reminder.amenities.map(s => ({id: s.amenity_id, text: s.name}));
    console.log(this.selectedServices, this.selectedAmenities);
  }

  typedServices(text: { term: string; total_services: any }) {
    this.specialtiesLoading = true;
    this.getServices(text.term).subscribe(a => {
      this.specialtiesLoading = false
      return this.total_services = a.map(sp => {return {id: sp.id, text: sp.name}})
    });
  }

  typedAmenities(text: { term: string; total_amenities: any }) {
    this.specialtiesLoading = true;
    this.specialtySearchService.search_amenities(text.term).subscribe(a => {
      this.specialtiesLoading = false;
      return this.total_amenities = a.map(sp => {return {id: sp.id, text: sp.name}})
    });
  }

  get getPreselectedServices() {
    return this.reminder.services.map(s => s.service_id)
  }

  getServices(term: any) {
    return this.specialtySearchService.search_services(term);
  }

  public add_selected_service(value: any): void {
    console.log(this.total_services);
    this.add_service(value.id);
    console.log(this.reminder.services);
  }
  public add_selected_amenity(value: any): void {
    this.add_amenity(value.id);
    console.log(this.reminder.amenities);
  }

  add_service(service_id) {
    let existing_found = this.get_new_service();
    let service = existing_found ? existing_found : new Service();
    service.service_id = service_id;
    if (!existing_found) {
      this.reminder.services.push(service);
    }
  }
  add_amenity(amenity_id) {
    let existing_found = this.get_new_amenity();
    let amenity = existing_found ? existing_found : new ReminderAmenity();
    amenity.amenity_id = amenity_id;
    if (!existing_found) {
      this.reminder.amenities.push(amenity);
    }
  }

  get_new_service() {
    return this.services.filter(sp => sp.service_id === 0)[0];
  }
  get_new_amenity() {
    return this.amenities.filter(sp => sp.amenity_id === 0)[0];
  }

  ngAfterViewInit() {
    this.editor = tinymce.get('email_body');
    console.log(this.editor);
  }

  ngOnDestroy() {

    tinymce.remove(this.editor);
  }

  selectStatuses(event) {
    let events = event.map(e => ({status_id: e}));
    this.reminder.statuses.forEach(s => {
      if (!events.map(e => e.status_id).includes(s.status_id)) {
          if (s.id) {
            s._destroy = true;
          } else {
            this.reminder.statuses = this.reminder.statuses.filter(b => b.status_id !== s.status_id);
          }
      }
    })
   events.forEach(e => {
      if (this.reminder.statuses.filter(s => s.status_id === e.status_id).length === 0) {
         this.reminder.statuses.push(new StatusLog().load_from_json(e));
      }
    });
    console.log(this.reminder.statuses);
  }

  save() {
    let c_user_id = this.userService.current_user.practices_id[0];
    this.reminder.profileable_id = c_user_id;
    this.reminder.attachment_ids = this.attachment_ids.length > 0  ?  this.attachment_ids  : null;
    this.loaderService.small_loder_show();
    this.reminder_template_servie.save(this.reminder)
      .then(resp => {
        this.reminder = this.reminder.load_from_json(resp)
        this.loaderService.small_loder_hide();
        this.reminder_template_servie.reloadReminder.next(1);
        this.tabs_service.close_tab(this.tabs_service.active_tab());
        this.tabs_service.add_tab(new Tab({title: 'Edit Reminder', icon: 'fa'},
          EditReminderComponent, this.reminder.id, {reminder: this.reminder} ))

      })
      .catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  closeNewForm() {
    if (this.tabs_service.active_tab().component === NewReminderComponent) {
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }
    else {
      this.closeform.emit(false);
    }
  }

  insertAtCursor(myValue) {
    if (myValue === '[[[Tags]]]') {
      return;
    }
    this.editor = tinymce.get('email_body');
    this.editor.insertContent(myValue);
  }

  insertAtCursorSmS(myValue) {
    if (myValue === '[[[Tags]]]') {
      return;
    }
    let txtarea = this.s_body.nativeElement;

    let scrollPos = txtarea.scrollTop;
    let strPos = 0;
    let br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
      'ff' : (this.s_body.nativeElement.selection ? 'ie' : false ) );
    if (br === 'ie') {
      txtarea.focus();
      let range = this.s_body.nativeElement.selection.createRange();
      range.moveStart ('character', -txtarea.value.length);
      strPos = range.text.length;
    } else if (br === 'ff') {
      strPos = txtarea.selectionStart;
    }

    let front = (txtarea.value).substring(0, strPos);
    let back = (txtarea.value).substring(strPos, txtarea.value.length);
    txtarea.value = front + myValue + back;
    strPos = strPos + myValue.length;
    if (br === 'ie') {
      txtarea.focus();
      let ieRange = this.s_body.nativeElement.selection.createRange();
      ieRange.moveStart ('character', -txtarea.value.length);
      ieRange.moveStart ('character', strPos);
      ieRange.moveEnd ('character', 0);
      ieRange.select();
    } else if (br === 'ff') {
      txtarea.selectionStart = strPos;
      txtarea.selectionEnd = strPos;
      txtarea.focus();
    }
    txtarea.scrollTop = scrollPos;
  }

  removeService(event) {
   let service = this.reminder.services.filter(s => s.service_id === event.value.id)[0];
   if (service && service.id) {
      service._destroy = true
   }
   if (service && !service.id) {
     this.reminder.services = this.reminder.services.filter(s => s.service_id !== event.value.id)
   }
   console.log(this.reminder.services);
  }

  removeAmenity(event) {
    let amenity = this.reminder.amenities.filter(s => s.amenity_id === event.value.id)[0];
    if (amenity && amenity.id) {
      amenity._destroy = true
    }
    if (amenity && !amenity.id) {
      this.reminder.amenities = this.reminder.amenities.filter(s => s.amenity_id !== event.value.id)
    }
    console.log(this.reminder.amenities);
  }

  deleteTemplate(template) {
    this.loaderService.show()
    this.reminder_template_servie.delete(template.id).then(resp => {
      this.reminder_template_servie.reloadReminder.next(1);
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
}
