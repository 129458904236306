import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Patient} from '../../health/doctor/patient';
import {CustomValidations} from '../../routes/customValidations/custom-validations';
import {NgForm} from '@angular/forms';
import {PatientService} from '../../services/patient.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {Router} from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'home-page-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class HomePageSignUpComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;
  public customValidations: CustomValidations;
  show_error = false;
  public show_verification = false;
  public gender_error = false;
  public show_attachment_popup = false;
  public in_progress = false;
  @ViewChild('UserForm') userForm: NgForm;
  private strength = 0;
  public declarativeFormCaptchaValue: string;


  constructor(private title: Title, public _userService: UserService,
              private _patientService: PatientService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
    this.setTitle('WellWink | Patient Sign-up');
    this.customValidations = new CustomValidations();

  }
  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }
  ngOnInit() {
    this._userService.patientSignUp = true;
    if (this.patient == undefined) {
      this.patient = new Patient();
    }
    this.patient.can_login = true;
    if (this.patient.user.gender == 'male' || this.patient.user.gender == 'female') {
      this.patient.user.gender = this.patient.user.gender
    } else {
      this.patient.user.gender = 'Gender';
    }
  }
  ngOnDestroy() {
    this.setTitle('WellWink | Information Technology for Connected Care')
    this._userService.patientSignUp = false;
  }
  save() {
    if (this.strength !== 100) {
      this.globalErrorHandlerService.error = 'Password is not strong enough.';
      return;
    }
    if (this.patient.user.gender == 'Gender') {
      this.gender_error = true;
      setTimeout(() => {
        this.gender_error = false;
        this.patient.success = '';
      }, 2000)
      return;
    }

    if (this.patient.check_valid_dob(this.patient.dob)) {
      this.patient.patient_dob_year();
      return;
    }
    let is_password_match = this.check_password_match();
    if (is_password_match) {
      this.patient.name = [this.patient.user.first_name, this.patient.user.last_name].join(' ');
      this.patient.user.email = this.patient.user.email.toLowerCase();
      this.in_progress = true;
      this._patientService.save(this.patient, this.patient.is_new_record() ? null : ['/patients', this.patient.id]).then(res => {
        this.in_progress = false;
        this._userService.patient_to_verify =  this.patient;
      }).catch(resp =>  {
        this.in_progress = false;
        if (resp && resp.error.pending_signup) {
          this.show_verification = true;
        } else {
          this.globalErrorHandlerService.error = resp.error.error
        }
      } )

    }
  }

  check_password_match() {
    if (this.patient.user.password == this.patient.user.password_confirmation) {
      return true;
    }
    else {
      this.setTimeOutFailsMessage();
    }
  }

  setTimeOutFailsMessage(): any {
    this.show_error = true;
    setTimeout(() => {
      this.show_error = false;
    }, 3000)
  }
  changeGender(value) {
    this.patient.user.gender = value;
  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.userForm);
  }
  trigger_login_close() {
    if (!this._userService.show_signup_popup) {
      this.show_attachment_popup = false;
    }
    this._userService.toggleSignUp();
  }

  handle_patient_verification(patient) {
    this.show_verification = false;
  }

  handle_image_upload(data) {
    if (data) {
      this.patient.user.profile_pic =  data.profile_pic;
      this.patient.user.profile_pic_id = data.id;
    }
    this.show_attachment_popup = false;
  }

  checkPasswordStrength($event: number) {
    this.strength = $event;
  }

  public addTokenLog(message: string, token: string | null) {
    console.log(`${message}`);
  }
}
