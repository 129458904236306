import { Component, OnInit,Input } from '@angular/core';
import {Tab} from "../../../tabs/tab";
import {TabsService} from "../../../services/tabs.service";
import {NewEmailCampaignComponent} from "../new-email-campaign/new-email-campaign.component";
import {EmailCampaignResultComponent} from "../email-campaign-result/email-campaign-result.component";
import {ViewEmailCampaignComponent} from "../view-email-campaign/view-email-campaign.component";
import {Campaign} from "../../campaign";
import {LoaderService} from "../../../services/loader.service";
import {CampaignService} from "../../campaign.service";
import {Subscription} from "rxjs/Subscription";
import {GlobalErrorHandlerService} from "../../../services/global-error.service";

@Component({
  selector: 'app-email-campaign-template-index',
  templateUrl: './email-campaign-template-index.component.html',
  styleUrls: ['./email-campaign-template-index.component.scss']
})
export class EmailCampaignTemplateIndexComponent implements OnInit {
  public subscription: Subscription;
  @Input() campaign_type;
  public filter: Object = {name: '', page: 1, status: '',outreach_type: undefined};
  public totalItems;
  campaigns_list: Campaign[] = []
  selected_campaign: Campaign


  constructor(public tabs_service : TabsService,public loaderService: LoaderService, public campaignService : CampaignService,
              public globalErrorHandlerService: GlobalErrorHandlerService,) {
    this.subscription = this.campaignService.reloadCampaigns.subscribe(item => {
      if (item) {
        this.campaignService.reloadCampaigns.next(0);
        this.getCampaigns();
      }
    });

  }

  ngOnInit(): void {
    this.filter['outreach_type'] = this.campaign_type;
    this.getCampaigns();
  }

  search() {
    this.filter['page'] = 1
    this.getCampaigns()
  }
  getCampaigns() {
    this.loaderService.show();
    this.campaignService.getCampaigns(this.filter).then(resp => {
      this.campaigns_list = resp.campaigns.map(campaign => new Campaign().load_from_json(campaign));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  runCampaign(campaign){
    this.loaderService.show();
    this.campaignService.runCampaign(campaign.id).then(
      resp => {
        campaign.load_from_json(resp);
        this.loaderService.hide();
      }).catch(error => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = error.error.message;
    });
  }

  addTemplate() {
    let template = new Campaign();
    template.outreach_type = this.campaign_type;
    this.tabs_service.add_tab(new Tab({title: 'Add Email Template', icon: 'fa'}, NewEmailCampaignComponent, template.id,
      {id: template.id, campaign: template}))
  }

  emailResult(campaign) {

    let template = campaign;
    this.tabs_service.add_tab(new Tab({title: template.name, icon: 'fa'}, EmailCampaignResultComponent, template.id,
      {id: template.id, campaign: template}))
  }

  pageChanged(page) {
    this.filter['page'] = page.page;
    this.getCampaigns()
  }

  viewEmail(campaign) {
    let template = campaign;
    this.tabs_service.add_tab(new Tab({title: template.name, icon: 'fa'}, ViewEmailCampaignComponent, template.id,
      {id: template.id, campaign: template}))
  }
}
