import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {EligiblePatient, ProgramEnrollment} from '../eligible-patient';
import {LoaderService} from '../../services/loader.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {ProgramService} from '../program.service';
import {Program} from '../program';
import {TabsService} from '../../services/tabs.service';
import {Tab} from '../../tabs/tab';
import {ProgramEnrolmentComponent} from '../program-enrolment/program-enrolment.component';
import {Router} from '@angular/router';
import {PatientProfileComponent} from '../../health/patient-profile/patient-profile.component';
import {EnrolmentsService} from '../enrolments.service';
import {ProgramEnrolledDetailComponent} from '../program-enrolled-detail/program-enrolled-detail.component';
import {AppointmentService} from '../../services/appointment.service';
import {Observable} from 'rxjs/Observable';
import {mergeMap} from 'rxjs/operators';
import {PatientService} from '../../services/patient.service';
import {PhoneService} from '../../voice-call/phone.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-eligible-patients',
  templateUrl: './eligible-patients.component.html',
  styleUrls: ['./eligible-patients.component.scss']
})
export class EligiblePatientsComponent implements OnInit, OnDestroy {
  public filter: Object = {token: '', status: 'eligible', page: 1};
  public totalItems;
  public patient_type = 'Eligible';
  public eligiblePatients: EligiblePatient[] = [];
  public programsList: any;
  public statues = [['all', 'All'], ['enrolled', 'Enrolled'], ['unenrolled', 'Unenrolled']];
  public second_statues = {'enrolled': [['all', 'All'], ['enrolled', 'Enrolled'] , ['in_progress', 'In Progress']],
    'unenrolled': [['all', 'All'], ['shortlisted', 'Short Listed'], ['eligible', 'Eligible'], ['opt_out', 'Opt Out'],
      ['ready_to_enroll', 'Ready to Enroll'],
      ['visit_required', 'Visit Required'], ['ineligible' , 'Ineligible'],
      ['patient_declined', 'Patient Declined'],
      ['enrolled_with_other_provider', 'Enrolled with Other Provider']],
    'all': [['all', 'All'], ['shortlisted', 'Short Listed'], ['eligible', 'Eligible'], ['opt_out', 'Opt Out'],
      ['ready_to_enroll', 'Ready to Enroll'],
      ['visit_required', 'Visit Required'], ['ineligible' , 'Ineligible'],
      ['patient_declined', 'Patient Declined'],
      ['enrolled_with_other_provider', 'Enrolled with Other Provider']]}
  public program_id: number;
  status_mapper = {'enrolled': 'Enrolled', 'enrolled_with_other_provider': 'Enrolled with Other Provider',
    'patient_declined': 'Patient Declined',
    'visit_required': 'Visit Required',
    'ineligible': 'Ineligible',
    'ready_to_enroll': 'Ready to Enroll',
    'in_progress': 'In Progress',
    'unenrolled': 'Unenrolled',
    'shortlisted': 'Short Listed',
    'opt_out': 'Opt Out',
    'eligible': 'Eligible',
     'all': 'Enrolled'}

  public programEnrollment: ProgramEnrollment;
  selectedPatient: EligiblePatient;
  asyncSelected = '';
  public dataSourcePatients: Observable<any>;
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public patientTypeaheadLoading = false;
  second_status = 'eligible';
  first_status = 'unenrolled';
  public eligiblePatientStatusObservable: Subscription;


  constructor(public loaderService: LoaderService,
              public tabs_service: TabsService, public router: Router,
              public phoneService: PhoneService,
              public enrolmentService: EnrolmentsService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public _appointment_service: AppointmentService,
              public programService: ProgramService) {
    this.programService.eligiblePatientListener.subscribe(val => {
      this.eligiblePatients = this.eligiblePatients?.filter(patient => patient.id !== val)
    })
  }

  ngOnInit(): void {
    this.tabs_service.register('Eligible Patient');
    this.dataSourcePatients = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.programService.getTypeAheadProgramEligiblePatients({token: token})));
    this.getAllPracticePrograms();
    this.eligiblePatientStatusObservable = this._appointment_service.patientStatusObservable.subscribe(val => {
      this.eligiblePatients?.map( patient => {
        if (val.user_id === patient.user_id) {
          patient.portal_online_status = val.portal_online_status;
          patient.mobile_online_status = val.mobile_online_status;
        }
      })
    })
    this.search();
  }

  private getAllPracticePrograms() {
    this.programService.getAllPracticePrograms().then(resp => {
      this.programsList = resp;
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  setPatientStatus() {
    this.eligiblePatients = this.eligiblePatients.map(p => {
      if (!p.program_status) {
        p.status = this.filter['status'];
      } else {
        p.status = p.program_status;
      }
      return p;
    })
  }

  public onPatientSelect(e: any): void {
      this.eligiblePatients.push(new EligiblePatient().load_from_json(e.item));
      this.setPatientStatus();
  }
  search() {
    console.log(this.filter);
    this.patient_type = this.filter['status'];
    this.loaderService.show();
    this.programService.getProgramEligiblePatients(this.filter).then(resp => {
      this.eligiblePatients = resp.eligible_patients.map(p => new EligiblePatient().load_from_json(p));
      this.setPatientStatus();
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
    });
  }

  pageChanged(e) {
    console.log('pageChanged', e.page);
    this.filter['page'] = e.page;
    this.search();
  }
  filter_patient() {
    this.filter['page'] = 1;
    this.search();
  }

  enrollProgram(patient: any) {
    this.programEnrollment = new ProgramEnrollment();
    if ( patient.program_enrolments.length > 0 ) {
      this.programEnrollment = patient.program_enrolments.filter(a => a.program_id === this.program_id)[0];
    }
    this.tabs_service.add_tab(new Tab({title: 'Enroll Patient', icon: 'fa', autorefresh: false}, ProgramEnrolmentComponent,
      this.program_id, { program_id: this.program_id, patient: patient, programEnrollment: this.programEnrollment}));
    this.selectedPatient = undefined;
  }

  openPatientProfile(patient: EligiblePatient) {
    let title = patient.name + '-' + 'Profile';
    let id = patient.id;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent,
      id, {id: id, patient: patient}))
  }

  resetStatusPage() {
    if (this.first_status === 'all') {
      delete this.filter['status']
      delete this.patient_type ;
      this.eligiblePatients = [];
      delete this.totalItems;
    } else {
      this.filter['status'] = this.first_status;
      this.second_status = 'all';
      if (this.first_status === 'enrolled') {
        this.filter['status'] = this.second_status;
      }
    }
    this.filter['page'] = 1
  }
  resetSecondPage() {
    if (this.second_status !== 'all') {
      this.filter['status'] = this.second_status;
    } else {
      if (this.first_status === 'enrolled') {
        this.filter['status'] = this.second_status;
      } else {
        this.filter['status'] = this.first_status;
      }
    }
    this.filter['page'] = 1
  }

  onChange(value: any, patient: EligiblePatient) {
    this.program_id = value;
    this.enrollProgram(patient);
  }

  viewProgram(patient: EligiblePatient) {
    let program = patient.program_enrolments.filter(p => p.status ===  'enrolled')[0];
    this.tabs_service.add_tab(new Tab({title: 'Program', icon: 'fa'}, ProgramEnrolledDetailComponent, program.program_id,
      {program_id: program.program_id, is_eligible_patient: true}))

  }

  continueEnrollment(eligiblePatient: EligiblePatient) {
    this. program_id = eligiblePatient.program_enrolments[0].program_id;
    this.enrollProgram(eligiblePatient);
  }
  changePatientTypeaheadLoading(e: boolean) {
    this.patientTypeaheadLoading = e;
  }

  changeTypeaheadLoading(e: boolean) {
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean) {
    this.typeaheadNoResults = e;
  }

  changeStatus(value: any, eligiblePatient: EligiblePatient) {
    this.enrolmentService.updateProgramStatus(eligiblePatient.id, value).then(resp => {
      eligiblePatient.status = value;
    }).catch(err => {
      console.error(err);
    })
  }

  enrollAble(status: string) {
    return ['unenrolled', 'shortlisted', 'enrolled_with_other_provider',
            'patient_declined', 'visit_required', 'opt_out',
            'eligible', 'ineligible', 'ready_to_enroll'].indexOf(status) > -1;
  }
  ngOnDestroy(): void {
    this.eligiblePatientStatusObservable.unsubscribe();
  }
}
