<ul class="list-unstyled border-list collapse-list m0">
  <ng-container *ngFor="let careplan of carePlans">
    <li class="list">
      <a class="title" (click)="careplan.isCollapsed = !careplan.isCollapsed" [attr.aria-expanded]="!careplan.isCollapsed" [attr.aria-controls]="careplan.name">
        {{careplan.name}}
      </a>
      <div class="bg-lightest-gray" id="{{careplan.name}}" [collapse]="careplan.isCollapsed" [isAnimated]="true">
        <app-enrolled-patient-goals *ngIf="goalResponse"
                                    [careplan]="careplan"
                                    [patient]="patient"
                                    [programEnrollment]="programEnrollment"
                                    [programme_type]="programme_type"
                                    [enrolment_goals]="goalResponse[careplan.name]"></app-enrolled-patient-goals>
        <app-enrolled-patient-task *ngIf="taskResponse"
                                   [careplan]="careplan"
                                   [patient]="patient"
                                   [programme_type]="programme_type"
                                   [programEnrollment]="programEnrollment"
                                   [enrolment_tasks]="taskResponse[careplan.name]"></app-enrolled-patient-task>
      </div>
    </li>
  </ng-container>
  <app-no-result-found *ngIf="carePlans && carePlans.length == 0"></app-no-result-found>
</ul>
