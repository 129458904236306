import {Component, Injector, OnInit} from '@angular/core';
import {CareTeam, Program, ProgramCodes, ProgramVital} from '../program';
import {UserService} from '../../services/user.service';
import {ProgramService} from '../program.service';
import {LoaderService} from '../../services/loader.service';
import {TabsService} from '../../services/tabs.service';
import {Tab} from '../../tabs/tab';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {ProgramEnrolledDetailComponent} from '../program-enrolled-detail/program-enrolled-detail.component';
import {environment} from '../../../environments/environment';
import {DoctorUser} from '../bills/bills.component';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
  public program: Program = new Program();
  public providers: DoctorUser[];
  public temp_vitals_array = [];
  public temp_care_team_array = [];
  public selectedCareTeam: any[];
  private skipped_vitals: any[];
  public programme_category: any;

  constructor(public programService: ProgramService, public injector: Injector, public userService: UserService,
              public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public tabs_service: TabsService) { }

  ngOnInit(): void {
    let newProgram  = this.injector.get('program', null);
    this.programme_category  = this.injector.get('programme_category', null);
    if (newProgram) {
      this.program = newProgram;
    } else {
      this.program = new Program();
    }
    this.getPracticeDoctor();
    this.getVitals();
    this.getCareTeam();
    this.selectedCareTeam = this.program.care_teams.map(s => s.user_id);
  }

  save() {
    this.loaderService.small_loder_show();
    this.program.programme_category = this.programme_category;
    this.programService.save(this.program)
      .then(resp => {
        this.program = this.program.load_from_json(resp)
        this.loaderService.small_loder_hide();
        this.programService.reloadProgram.next(1);
        /*this.tabs_service.close_tab(this.tabs_service.active_tab());
        this.tabs_service.add_tab(new Tab({title: this.program.name}, ProgramEnrolledDetailComponent, this.program.id,
          {program_id: this.program.id, program: this.program}));*/
      })
      .catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  private getPracticeDoctor() {
    this.programService.getPracticeDoctorWithUsers().then(resp => {
      this.providers = resp;
    })
  }

  private getVitals() {
    this.programService.getVitals().then(resp => {
      this.temp_vitals_array = resp.filter(vital => !['fbg', 'rbg', 'sbp', 'dbp'].includes(vital.short));
      this.skipped_vitals = resp.filter(vital => ['fbg', 'rbg', 'sbp', 'dbp'].includes(vital.short));
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  private getCareTeam() {
    this.programService.getCareTeam().then(resp => {
      this.temp_care_team_array = resp
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  closeNewForm() {
    if (this.tabs_service.active_tab().component === ProgramComponent) {
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }
  }

  selectCareTeam(event: any) {
    let events = event.map(e => ({user_id: e}));
    this.program.care_teams.forEach(s => {
      if (!events.map(e => e.user_id).includes(s.user_id)) {
        if (s.id) {
          s._destroy = true;
        } else {
          this.program.care_teams = this.program.care_teams.filter(b => b.user_id !== s.user_id);
        }
      }
    })
    events.forEach(e => {
      if (this.program.care_teams.filter(s => s.user_id === e.user_id).length === 0) {
        this.program.care_teams.push(new CareTeam().load_from_json(e));
      }
    });
  }

  setProgramVitals(e) {
    if (e.target.checked) {
      if ( e.target.value === 'bp') {
        this.skipped_vitals.filter(v => ['sbp', 'dbp'].includes(v.short))
          .map(vital => {return this.pushProgramVitals(vital.id.toString())});
      } if ( e.target.value === 'bg') {
        this.skipped_vitals.filter(v => ['rbg', 'fbg'].includes(v.short))
          .map(vital => {return this.pushProgramVitals(vital.id.toString())});
      } if (!['bp', 'bg'].includes(e.target.value)) {
        this.pushProgramVitals(e.target.value);
      }
    }
    if (!e.target.checked) {
      if ( e.target.value === 'bp') {
        this.skipped_vitals.filter(v => ['sbp', 'dbp'].includes(v.short))
          .map(vital => {return this.removeProgramVitals(vital.id.toString())});
      }
      if ( e.target.value === 'bg') {
        this.skipped_vitals.filter(v => ['rbg', 'fbg'].includes(v.short))
          .map(vital => {return this.removeProgramVitals(vital.id.toString())});
      } if (!['bp', 'bg'].includes(e.target.value)) {
        this.removeProgramVitals(e.target.value);
      }
    }
  }

  selectedProgramVitals(shorts: string[]) {
    return this.program.vitals.filter(vital => shorts.includes(vital.short)).length > 0
  }

  pushProgramVitals(e) {
    let temp_vital = new ProgramVital();
    temp_vital.vital_type_id = e;
    if (this.program.vitals.some(obj => obj.vital_type_id.toString() === e)) {
      let existingVital = this.program.vitals.filter(vital => vital.vital_type_id.toString() === e)[0];
      if (existingVital.id && existingVital._destroy) {
        existingVital._destroy = false;
      }
    } else {
      this.program.vitals.push(new ProgramVital().load_from_json(temp_vital));
    }
    return this.program.vitals;
  }

  removeProgramVitals(e) {
    this.program.vitals.forEach(s => {
      if ( s.vital_type_id.toString() === e) {
        if (s.id) {
          return s._destroy = true;
        } else {
          return this.program.vitals = this.program.vitals.filter(b => b.vital_type_id.toString() !== s.vital_type_id.toString());
        }
      }
    })
  }
}
