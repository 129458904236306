export class Attachment {
  document_url = '';
  fileType: string;
  file: File ;
  description = '';
  img = '';
  picture_type = '';
  attachable_type: string;
  attachable_id: number;
  attachment_id: number;
  active_class: boolean;
  id: number;
  to_params(fileType, file, description, attachable_id, attachable_type) {
    return {
       attachment: {fileType: fileType, file: file, description: description, attachable_type: attachable_type, attachable_id: attachable_id}
    }
  }

  load_from_json(json) {
    this.document_url = json.document_url;
    this.img = json.document_url || json.profile_pic;
    this.picture_type = json.document_type || 'photo';
    this.attachment_id = json.id;
    this.active_class = false;
    return this;
  }

  load_from_json2(json) {
    this.img = json.profile_pic;
    this.picture_type = 'photo';
    this.attachment_id = json.id;
    return this;
  }

}
