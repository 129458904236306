import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LoaderService} from "../../../../services/loader.service";
import {TabsService} from "../../../../services/tabs.service";
import {Amenity, BookingMethod} from "../amenity";
import {GlobalErrorHandlerService} from "../../../../services/global-error.service";
import {IMultiSelectOption, IMultiSelectSettings} from "angular-2-dropdown-multiselect";
import {NgForm} from "@angular/forms";
import {Tab} from "../../../../tabs/tab";
import {AmenityService} from "../amenity.service";
import {ViewAmenityComponent} from "../view-amenity/view-amenity.component";
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'app-new-amenity',
  templateUrl: './new-amenity.component.html',
  styleUrls: ['./new-amenity.component.scss']
})
export class NewAmenityComponent implements OnInit {
  public editor: any;
  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 300,
    relative_urls: false,
    remove_script_host: false,
    convert_urls: true,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    placeholder: 'Description...',
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
    'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
    'insertfile undo redo numlist outdent | bullist indent |contextmenu table | \ ' +
    'link forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.editor = editor;
    },
  };
  mySettings: IMultiSelectSettings = {
    enableSearch: false,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true,
  };

  applicable: boolean = false;
  @Input() amenity: Amenity;
  @ViewChild('amenityForm') amenityForm: NgForm;
  @Output() closeform = new EventEmitter<boolean>();
  public selected_method : any[] = []

  public methodOptions;

  constructor(public injector: Injector, public loaderService: LoaderService, public tabs_service: TabsService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public amenityService : AmenityService) { }

  ngOnInit(): void {
    let newAmenity = this.injector.get('amenity', null);
    if (newAmenity) {
      this.amenity = newAmenity;
      if(this.amenity.applicable_patients !== 'all_patients'){
        this.applicable = true;
      }
      this.selected_method = this.amenity.amenity_methods.map(a => a.id)
    } else {
      this.amenity = new Amenity();
    }

    this.amenityService.getBookingTypes()
      .subscribe(
        resp => {
          this.methodOptions = resp.map(a => new BookingMethod().load_from_json(a));
        },
        error => console.log(error)
      );
  }

  save(){
    console.log(this.amenity , "amenity");
    this.loaderService.small_loder_show();
    this.amenityService.save(this.amenity)
      .then(resp => {
        this.amenity = this.amenity.load_from_json(resp)
        this.loaderService.small_loder_hide();
        this.amenityService.reloadamenity.next(1);
        this.tabs_service.close_tab(this.tabs_service.active_tab());
        this.tabs_service.add_tab(new Tab({title: this.amenity.name, icon: 'fa'},
          ViewAmenityComponent, this.amenity.id, {amenity: this.amenity} ))
      })
      .catch(resp => {
        this.loaderService.small_loder_hide();
      })
  }

  closeNewForm() {
    if (this.tabs_service.active_tab().component === NewAmenityComponent) {
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }
    else {
      this.closeform.emit(false);
    }
  }

  addMethodArray(events){
    this.amenity.amenity_methods.forEach(s => {
      if (!events.includes(s.booking_method_id)) {
        if (s.amenity_method_id) {
          s._destroy = true;
        } else {
          this.amenity.amenity_methods = this.amenity.amenity_methods.filter(b => b.amenity_method_id !== s.amenity_method_id);
        }
      }
      if (events.includes(s.booking_method_id)) {
        if (s.amenity_method_id) {
          s._destroy = false;
        } else {
          this.amenity.amenity_methods = this.amenity.amenity_methods.filter(b => b.amenity_method_id !== s.amenity_method_id);
        }
      }
    })

    events.forEach(e => {
      if(this.amenity.amenity_methods.length === 0) {
        this.amenity.amenity_methods.push(this.methodOptions.find(s => s.id === e))
      }
      if (this.amenity.amenity_methods.filter(s => s.id === e).length === 0) {
        this.amenity.amenity_methods.push(this.methodOptions.find(s => s.id === e))
      }
    });
    console.log(this.amenity)
  }

  newPatientsCheckbox(app){
    if (this.applicable){
      this.amenity.applicable_patients = 'new_patients'
    }else {
      this.amenity.applicable_patients = 'all_patients'
    }
  }

}
