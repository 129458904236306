import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {Task} from '../routes/practice-site/tasks/newtask/task';
import {GlobalErrorHandlerService} from './global-error.service';
import {TaskResponse} from '../program/enrolled-patient/enrolled-patient-right-panel/enrolled-patient-plan/enrolled-patient-plan.component';

@Injectable()
export class TaskService {
  reloadTasks: Subject<any> = new Subject<any>();
  closeNewTaskForm: Subject<any> = new Subject<any>();
  reloadTaskObjectListener: Subject<Task> = new Subject<Task>();
  taskBeforeEdit: Task = new Task();

  constructor(private _tokenService: CustomHttpClient, private globalErrorHandlerService: GlobalErrorHandlerService) { }
  create(task: Task): Promise<any> {
    return this._tokenService
      .post(task.get_url(), task.to_params())
      .toPromise()
      .then(response => response)
  }

  save(task: Task): Promise<any> {
    if (task.is_new_record()) {

      return this.create(task);
    }
    else {
      return this.update(task);
    }
  }

  update(task: Task) {
    return this._tokenService
      .put(task.get_url(), task.to_params())
      .toPromise()
      .then(response => response)
  }
  getTasks(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: filter});
    return this._tokenService.getWithHeaders('tasks', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          tasks: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }
  getGroupTasks(id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('patient_id', id);
    return this._tokenService.get('tasks/group_tasks', {params: params})
      .toPromise()
      .then(response => response);
  }
  dueTaskCount(id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('patient_id', id);
    return this._tokenService.get('tasks/due_task_count', {params: params})
      .toPromise()
      .then(resp => resp)
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
  getTaskCount(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: filter});
    return this._tokenService.get('tasks/task_count', {params: params})
      .toPromise()
      .then(resp => resp)
  }
  delete(task: Task) {
    return this._tokenService
      .delete('tasks?id=' + task)
      .toPromise()
      .then(response => response)
  }
  getTask(id: any): Promise<any> {
    return this._tokenService.get('tasks/task?id=' + id)
      .toPromise()
      .then(response => response)
  }
}
