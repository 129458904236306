import { Component, OnInit , Input , Injector, ReflectiveInjector} from '@angular/core';
import  {Tab} from '../tab'
@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  custom_injector: Injector;
  active_tab: Tab;

  @Input() set tab(tab: Tab) {
    if (!tab) {
      this.active_tab = tab;
      return;
    }

    let inputProviders = Object.keys(tab.dependencies).map((inputName) => {return {provide: inputName, useValue: tab.dependencies[inputName]}; })
    this.custom_injector  = Injector.create(inputProviders, this.injector);
    this.active_tab = tab;
  }

  constructor(private injector: Injector) { }

  ngOnInit() {
  }

}
