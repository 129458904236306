import { Component, OnInit, Renderer2 } from '@angular/core';
import {EnoteService} from '../../services/enote.service';
import {ControlComponent} from '../control/control.component';

@Component({
  selector: 'app-read-only-controls',
  templateUrl: './read-only-controls.component.html',
  styleUrls: ['./read-only-controls.component.scss']
})
export class ReadOnlyControlsComponent extends ControlComponent implements OnInit {

  constructor(public _enote_service: EnoteService, public renderer: Renderer2) {
    super(_enote_service, null)
  }
  ngOnInit() {
  }

}
