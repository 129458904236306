<h4 class="m0 mb">Customize Alerts</h4>
<ng-container *ngFor="let alertTemplateRule of alertTemplateRules">
  <h5>
    <mat-slide-toggle [color]="'primary'" [(ngModel)]="alertTemplateRule.status"
                      class="pull-right" (change)="saveTemplateRuleForPatient(alertTemplateRule)"></mat-slide-toggle>
    {{alertTemplateRule.alert_template_description}}
  </h5>
  <ng-container *ngFor="let vital_entry of alertTemplateRule.alert_vital_rules ; let i = index;">
    <div class="row form-group">
      <label class="col-sm-3 field-label control-label">{{vitalService.vital_mapper_with_unit[vital_entry?.short]}}</label>
      <div class="col-sm-3">
        <select id="{{'vital_identifier_'+vital_entry.short}}" name="{{'vital_identifier_'+vital_entry.short}}"
                class="form-control text-capitalize"
                (change)="alertTemplateService.vitalDropdownChange(vital_entry.vital_identifier, vital_entry)"
                [(ngModel)]="vital_entry.vital_identifier" required>
          <option value=undefined selected>Trend</option>
          <option *ngFor="let trend of alertTemplateService.alert_age_trends; let i = index;" [value]="trend">
            {{userService.removeUnderScore(trend)}}
          </option>
        </select>
      </div>
      <div class="col-sm-2" *ngIf="!vital_entry.show_min_max">
        <input type="number" class="form-control" placeholder="Value" id="{{i + 'vital_value'}}"
               [(ngModel)]="vital_entry.value" name="{{i + 'vital_value'}} ">
      </div>
      <ng-container *ngIf="vital_entry.show_min_max">
        <div class="col-sm-2">
          <input type="number" class="form-control" placeholder="Min Value" id="{{i + 'vital_min_value'}}"
                 [(ngModel)]="vital_entry.min_value" name="{{i + 'vital_min_value'}} ">
        </div>
        <div class="col-sm-2">
          <input type="number" class="form-control" placeholder="Max Value" id="{{i + 'vital_max_value'}}"
                 [(ngModel)]="vital_entry.max_value" name="{{i + 'vital_max_value'}} ">
        </div>
      </ng-container>
      <div class="col-sm-2">
        <label *ngIf="loaderService.uploading_in_process" class="field-label control-label border-box" style="justify-content:center;">
          <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
        </label>
        <button type="submit" class="btn btn-secondary btn-outline" *ngIf="!loaderService.uploading_in_process"
                (click)="saveTemplateRuleForPatient(alertTemplateRule)">
          {{alertTemplateRule.id ? 'Update' : 'Save'}}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
<app-no-result-found *ngIf="alertTemplateRules && alertTemplateRules.length == 0"></app-no-result-found>
<div class="text-center" *ngIf="alertTemplateRules && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10"
              (pageChanged)="pageChanged($event)"></pagination>
</div>


