import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {AngularTokenService} from 'angular-token';
import {Observable} from 'rxjs/Observable';
@Injectable()
export class AdminLoginService extends AngularTokenService {



  loginAsEnterpriseUser(user_id): Promise<any> {
    return UserService.secureClient.postWithHeaders('users/login_from_enterprise_user', {user_id: user_id}, {observe: 'response'})
      .toPromise()
      .then(response => {
        this.getAuthHeadersFromResponse({headers: response.headers});
        localStorage.setItem('redirectTo', '/enterprise/home/dashboard');
        localStorage.setItem('ignoreRedirectTo', 'true');
        window.location.href = '/enterprise/home/dashboard';
      })
  }

}
