import {Component, Injector, OnInit} from '@angular/core';
import {CampaignService} from '../../campaign.service';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {Campaign} from '../../campaign';
export interface CampaignAnalytic {
  id?: number;
  clicks_per_unique_opens?: number;
  last_clicked?: string;
  last_opened?: string;
  successful_deliveries_count?: number;
  successful_deliveries_percentage?: number;
  total_clicks_count?: number;
  total_open_count?: number;
  total_failed_messages?: number;
  total_failed_messages_percentage?: number;
  total_clicks_percentage_by_total_open?: number;
  total_clicks_percentage?: number;
}
export interface RecipientStatistic {
  bounced?: number;
  click?: number;
  open?: number;
  unsubscribe?: number;
  delivered?: number;
  failed?: number;
  sent?: number;
}

@Component({
  selector: 'app-email-campaign-result',
  templateUrl: './email-campaign-result.component.html',
  styleUrls: ['./email-campaign-result.component.scss']
})
export class EmailCampaignResultComponent implements OnInit {
  campaign: Campaign;
  public campaignAnalytics: CampaignAnalytic[];
  public recipient_statistics: RecipientStatistic[];

  constructor(public injector: Injector, public globalErrorHandlerService: GlobalErrorHandlerService,
              public campaignService: CampaignService, public loaderService: LoaderService, ) {
    this.campaign = this.injector.get('campaign', null);

  }

  ngOnInit(): void {
    this.getResults()
  }

  getResults() {
    this.loaderService.show();
    this.campaignService.campaignAnalytics(this.campaign.id).then(resp => {
      this.campaignAnalytics = resp;
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
    this.campaignService.campaignRecipients(this.campaign.id).then(resp => {
      this.recipient_statistics = resp;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

}
