<ng-container *ngIf="online_virtual_physicians">
  <ul class="doc-select-list pick-doc">
    <ng-container *ngFor="let doctor of limited_physicians;">
      <li>
        <div class="media">
          <div class="media-left">
            <img style="cursor: pointer" [routerLink]="['/doctors',doctor.id]" class="img-thumbnail img-circle" [src]="doctor.profile_pic">
          </div>
          <div class="media-body">
            <h5>{{doctor.name}}</h5>
            <h6>{{doctor.designation}}</h6>
            <ul class="slots-video-call list-unstyled scroll-browser">
              <ng-container *ngFor="let slot of doctor.virtual_slots;let i=index">
                <li>
                  <a class="btn btn-secondary btn-outline" (click)="onSlotSelect(slot)">{{slot.slot | date: 'shortTime'}}</a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
  <div class="text-center" *ngIf="(online_virtual_physicians.length > 8)
  && !(online_virtual_physicians.length == limit || online_virtual_physicians.length < limit)">
    <button class="btn btn-tertiary btn-outline" (click)="onLoadMore();">
      More <i class="fa icon-arrow-down vl-m"></i>
    </button>
  </div>
</ng-container>
