<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Contact Us</h1>
      <h4 class="sub-title">Want to get in touch? We'd love to hear from you. Hers's how you can reach us...</h4>
    </div>
  </div>
</section>
<section class="sec-pad from-sec">
  <div class="container">
    <div class="row">
      <div class="col-sm-8 col-xs-12">
        <div class="white-container-box p-xl">
          <h3 class="mt0">How Can We Help?</h3>
          <p>Please select a topic below related to your inquiry. If you do not find what you need, fill out our contact form.</p>
          <ul class="list-unstyled border-list mb-xl">
            <li class="list">
              <h4 class="m0 fw-400 text-primary">Get Started</h4>
              <a [routerLink]="'/pricing-info'" class="btn-link">Visit our pricing page, choose a package and start using WellWink.</a>
            </li>
            <li class="list">
              <h4 class="m0 fw-400 text-primary">Get Inspired</h4>
              <a href="https://www.linkedin.com/company/wellwink/" class="btn-link">Discover the many ways in which our customers use WellWink.</a>
            </li>
            <li class="list">
              <h4 class="m0 fw-400 text-primary">Become a Partner</h4>
              <a [routerLink]="'/public-partner-program'" class="btn-link">Join our Partner Program and earn up to <span class="fw-500">25%</span> recurring commissions.</a>
            </li>
          </ul>
          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <div class="white-container-box p text-center mb">
                <i class="fa icon-notebook fs26 mb center-block"></i>
                <div class="fs14">Hackensack, NJ 07601</div>
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="white-container-box p text-center mb">
                <i class="fa icon-envelope fs26 mb center-block"></i>
                <div class="fs14"><a href="mailto:sales@wellwink.com">sales@wellwink.com</a></div>
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="white-container-box p text-center mb">
                <i class="fa icon-phone fs26 mb center-block"></i>
                <a class="fs14" href="tel:+1-848-291-2430">+1 (848) 291-2430</a>
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="white-container-box p text-center mb">
                <i class="fa fa-whatsapp fs26 mb center-block"></i>
                <a class="fs14" href="https://wa.me/+12019490209" target="_blank">+1 (201) 949-0209</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-xs-12">
        <div class="white-container-box p-xl">
          <h3 class="mt0 mb-lg">Get in touch with us</h3>
          <app-contact-us-form class="contact-page-form"></app-contact-us-form>
        </div>
      </div>
    </div>
  </div>
</section>
