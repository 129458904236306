import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../services/nullRemover';
import {TimeLog} from './time-log';
import {Activity} from './activity';
import {Subject} from 'rxjs';
import {ProgramDocument} from './program-enrolment/program-document';
import {TaskResponse} from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-plan/enrolled-patient-plan.component';

@Injectable({
  providedIn: 'root'
})
export class EnrolmentsService {
  reloadActivityList: Subject<any> = new Subject<any>();
  reloadAlertsList: Subject<any> = new Subject<any>();
  reloadProgramEnrolmentTime: Subject<any> = new Subject<any>();
  reloadProgramEnrolmentObject: Subject<any> = new Subject<any>();
  reloadEnrolledPatient: Subject<any> = new Subject<any>();
  timeLogSubscription: Subject<any> = new Subject<any>();
  alertPushed: Subject<any> = new Subject<any>();
  reloadSummary: Subject<any> = new Subject<any>();
  constructor(private _tokenService: CustomHttpClient) { }

  getActivities(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('enrolments/activities', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          activities: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  logTimeForActivity(timeToLog: TimeLog) {
    return this._tokenService
      .post('enrolments/log_time', timeToLog.to_params_for_time_log())
      .toPromise()
      .then(response => response)
  }

  addActivityAndLogTime(activity: Activity) {
    return this._tokenService
      .post('enrolments/activity', activity.to_params())
      .toPromise()
      .then(response => response)
  }
  getEnrolmentAlerts(id: any, limit: number): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('id', id);
    return this._tokenService.get('enrolments/enrolment_alerts', {params: params})
      .toPromise()
      .then(response => response);
  }
  markAlertsRead(id: any): Promise<any> {
    return this._tokenService.put('enrolments/mark_alerts_read', {id: id})
      .toPromise()
      .then(response => response);
  }
  updateProgramStatus(id: any, status: string): Promise<any> {
    return this._tokenService.put('enrolments/patient_status', {id: id, status: status})
      .toPromise()
      .then(response => response);
  }
  updateHealthComplianceStatus(id: any, health_status: any, compliance_level: any): Promise<any> {
    return this._tokenService.put('enrolments/update_status', {id: id,
      health_status: health_status, compliance_level: compliance_level})
      .toPromise()
      .then(response => response);
  }
  updateEnrollmentTypeAndSeverity(id: any, severity: any): Promise<any> {
    return this._tokenService.put('enrolments/update_severity', {id: id,
      severity: severity})
      .toPromise()
      .then(response => response);
  }
  deleteSingleAlert(id: any) {
    return this._tokenService
      .delete('enrolments/alert?id=' + id)
      .toPromise()
      .then(response => response)
  }
  deleteAllAlerts(id: any) {
    return this._tokenService
      .delete('enrolments/alerts?id=' + id)
      .toPromise()
      .then(response => response)
  }
  getEnrolmentTasks(id: any): Promise<TaskResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    return this._tokenService.get('enrolments/tasks', {params: params})
      .toPromise()
      .then(response => response);
  }

  removePatientFromProgram(param: { reason: any; id: number }) {
    return this._tokenService
      .put('enrolments/opt_out_patient', param)
      .toPromise()
      .then(res => res)
  }

  deleteActivity(activity: any) {
    return this._tokenService
      .delete('enrolments/activity?id=' + activity)
      .toPromise()
      .then(response => response)
  }

  updateActivity(activity: Activity) {
    return this._tokenService
      .put('enrolments/activity', activity.to_params())
      .toPromise()
      .then(response => response)
  }

  getEnrolmentTime(id: any) {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    return this._tokenService.get('enrolments/time', {params: params})
      .toPromise()
      .then(response => response);
  }

  saveEnrolmentDocument(programDocument: ProgramDocument) {
    return this._tokenService
      .post('enrolments/enrolment_document', programDocument.to_params())
      .toPromise()
      .then(response => response)
  }

  getTotalActivityTime(id: any) {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    return this._tokenService.get('enrolments/activities_logged_time', {params: params})
      .toPromise()
      .then(response => response);
  }
}
