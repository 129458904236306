<table class="table table-hover table-striped m0">
  <thead>
    <tr>
      <th>Name</th>
      <th>Location</th>
      <th style="width:120px;">Display Order</th>
      <th style="width:260px;">Visit Charges</th>
      <th class="text-center" style="width:260px;">Action</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let associate_amenity of associate_ammenity_array">
      <tr>
        <td>{{associate_amenity.name}}</td>
        <td>
          <select #selectedLocation [(ngModel)]="associate_amenity.location_id" name="location" class="form-control">
            <option value="">Please Select</option>
            <option *ngFor="let location of locations" value={{location.id}}>{{location.name}}</option>
          </select>
        </td>
        <td><input class="form-control" type="number" id="d_order" [(ngModel)]="associate_amenity.d_order"/></td>
        <td>
          <div class="media" *ngFor="let amenity of associate_amenity.amenity_methods">
            <label class="media-left media-middle fw-500" style="min-width:120px;">{{amenity.name}}</label>
            <div class="media-body">
              <input class="form-control" type="number" id="{{amenity.id}}" readonly [(ngModel)]="amenity.fee"/>
            </div>
          </div>
        </td>
        <td class="text-center">
          <button class="btn btn-primary mr" (click)="updateAssociateAmenity(associate_amenity)">Update</button>
          <a class="btn btn-danger btn-outline" (click)="classicModal.show();selected_associate_amenity = associate_amenity"> <i class="fa fa-times text-danger"></i></a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:242px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Remove</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger text-center">
          “Are you sure you want to delete the service?”
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="delete_doctor_association_with_service(selected_associate_amenity);classicModal.hide();">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">Cancel</button>
      </div>
    </div>
  </div>
</div>
