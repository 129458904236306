<div class="flex-control flex-column flex-height" *ngIf="showReport">
  <div class="filters-sec flex-header text-center">
    <button class="btn btn-secondary btn-min mr-sm" (click)="generatePdf('print')">Print</button>
    <button class="btn btn-tertiary btn-min btn-outline" (click)="generatePdf('save')">Download</button>
  </div>
  <div class="flex-body scroll-browser p-lg">
    <div class="container printer-wrapper" #report id="reports">
      <div class="text-center">
        <h2 class="m0">Reputation Management</h2>
        <h4 *ngIf="userService.current_user.isPractice">{{userService.current_user.profileable_name}}</h4>
        <div class="prac-info">
          <div class="col" *ngIf="filter['location_name']"><span class="field-label">Location</span> {{filter['location_name']}}</div>
          <div class="col" *ngIf="filter['doctor_name']"><span class="field-label">Doctor</span> {{filter['doctor_name']}}</div>
          <div class="col" *ngIf="filter['time_type'] && filter['time_type'] !== 'Custom Date'"><span class="field-label">Time</span> {{filter['time_type']}}</div>
          <div class="col" *ngIf="filter['keyword']"><span class="field-label">Keyword</span> {{filter['keyword']}}</div>
          <div class="col" *ngIf="filter['to'] && filter['from']"><span class="field-label">Time</span> {{filter['from'] | date}} to {{filter['to'] | date}}</div>
          <div class="col" *ngIf="filter['description']"><span class="field-label">Comments </span> With comments</div>
          <div class="col" *ngIf="filter['rating']"><span class="field-label">Rating </span> {{filter['rating']}} star</div>
          <div class="col" *ngIf="filter['gender']"><span class="field-label text-capitalize">Gender </span> {{userService.removeUnderScore(filter['gender'])}}</div>
          <div class="col" *ngIf="filter['tag']"><span class="field-label">Tags </span> With tags</div>
        </div>
      </div>
      <div class="row bar-holder">
        <div class="col-sm-5 mb-lg">
          <h4 class="text-center">All Time Ratings</h4>
          <div class="prac-info">
            <div class="col"><span class="field-label">Average Rating</span> {{stats['total_review_response_stats']['average_rating']}}</div>
            <div class="col"><span class="field-label">Response Rate</span> {{stats['total_review_response_stats']['response_rate']}}%</div>
            <div class="col"><span class="field-label">Requests</span> {{stats['total_review_response_stats']['request_count']}}</div>
            <div class="col"><span class="field-label">Responses</span> {{stats['total_review_response_stats']['response_count']}}</div>
          </div>
          <ul class="list-unstyled progress-list">
            <li>
              <h5>Excellent</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-striped active"  [style.width.%]="stats['total_reviews_count']['fiveStarCount'] > 0 ? stats['total_reviews_count']['fiveStarWidth'] : 0">
                  {{stats['total_reviews_count']['fiveStarCount'] > 0 ? (stats['total_reviews_count']['fiveStarWidth'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['total_reviews_count']['fiveStarCount']}}</h5>
            </li>
            <li>
              <h5>Great</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-success progress-bar-striped active" [style.width.%]="stats['total_reviews_count']['fourStarCount'] > 0 ? stats['total_reviews_count']['fourStarWidth'] : 0">
                  {{ stats['total_reviews_count']['fourStarCount'] > 0 ? (stats['total_reviews_count']['fourStarWidth'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['total_reviews_count']['fourStarCount']}}</h5>
            </li>
            <li>
              <h5>Average</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-info progress-bar-striped active" [style.width.%]="stats['total_reviews_count']['threeStarCount'] > 0 ? stats['total_reviews_count']['threeStarWidth'] : 0">
                  {{stats['total_reviews_count']['threeStarCount'] > 0 ? (stats['total_reviews_count']['threeStarWidth'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['total_reviews_count']['threeStarCount']}}</h5>
            </li>
            <li>
              <h5>Poor</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-warning progress-bar-striped active" [style.width.%]="stats['total_reviews_count']['twoStarCount'] > 0 ? stats['total_reviews_count']['twoStarWidth'] : 0">
                  {{stats['total_reviews_count']['twoStarCount'] > 0 ? (stats['total_reviews_count']['twoStarWidth'] | number : '1.0-0') : 0 }}%</div>
              </div>
              <h5 class="counter">{{stats['total_reviews_count']['twoStarCount']}}</h5>
            </li>
            <li>
              <h5>Bad</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-danger progress-bar-striped active" [style.width.%]="stats['total_reviews_count']['oneStarCount'] > 0 ? stats['total_reviews_count']['oneStarWidth'] : 0">
                  {{stats['total_reviews_count']['oneStarCount'] > 0 ? (stats['total_reviews_count']['oneStarWidth'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['total_reviews_count']['oneStarCount']}}</h5>
            </li>
          </ul>
        </div>
        <div class="col-sm-2 hidden-xs"></div>
        <div class="col-sm-5 mb-lg">
          <h4 class="text-center">Current Report Ratings</h4>
          <div class="prac-info">
            <div class="col"><span class="field-label">Average Rating</span> {{stats['review_response_stats']['average_rating']}}</div>
            <div class="col"><span class="field-label">Response Rate</span> {{stats['review_response_stats']['response_rate'] }}%</div>
            <div class="col"><span class="field-label">Requests</span> {{stats['review_response_stats']['request_count']}}</div>
            <div class="col"><span class="field-label">Responses</span> {{stats['review_response_stats']['response_count']}}</div>
          </div>
          <ul class="list-unstyled progress-list">
            <li>
              <h5>Excellent</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-striped active" [style.width.%]="stats['reviewsCount']['fiveStarCount'] > 0 ? stats['reviewsCount']['fiveStarWidth'] : 0">
                  {{stats['reviewsCount']['fiveStarCount'] > 0 ? (stats['reviewsCount']['fiveStarWidth'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['reviewsCount']['fiveStarCount']}}</h5>
            </li>
            <li>
              <h5>Great</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-success progress-bar-striped active" [style.width.%]="stats['reviewsCount']['fourStarCount'] > 0 ? stats['reviewsCount']['fourStarWidth'] : 0">
                  {{stats['reviewsCount']['fourStarWidth'] | number : '1.0-0'}}%</div>
              </div>
              <h5 class="counter">{{stats['reviewsCount']['fourStarCount']}}</h5>
            </li>
            <li>
              <h5>Average</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-info progress-bar-striped active" [style.width.%]="stats['reviewsCount']['threeStarCount'] > 0 ? stats['reviewsCount']['threeStarWidth'] : 0">
                  {{stats['reviewsCount']['threeStarWidth'] | number : '1.0-0'}}%</div>
              </div>
              <h5 class="counter">{{stats['reviewsCount']['threeStarCount']}}</h5>
            </li>
            <li>
              <h5>Poor</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-warning progress-bar-striped active" [style.width.%]="stats['reviewsCount']['twoStarCount'] > 0 ? stats['reviewsCount']['twoStarWidth'] : 0">
                  {{stats['reviewsCount']['twoStarWidth'] | number : '1.0-0'}}%</div>
              </div>
              <h5 class="counter">{{stats['reviewsCount']['twoStarCount']}}</h5>
            </li>
            <li>
              <h5>Bad</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-danger progress-bar-striped active" [style.width.%]="stats['reviewsCount']['oneStarCount'] > 0 ? stats['reviewsCount']['oneStarWidth'] : 0">
                  {{stats['reviewsCount']['oneStarWidth'] | number : '1.0-0'}}%</div>
              </div>
              <h5 class="counter">{{stats['reviewsCount']['oneStarCount']}}</h5>
            </li>
          </ul>
        </div>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th style="width:113px; max-width:113px;">Date</th>
            <th style="width:110px; max-width:110px;">Patient</th>
            <th>Contact</th>
            <th style="width:110px; max-width:110px;">Appointment</th>
            <th style="width:140px; max-width:140px;">Doctor</th>
            <th>Comments</th>
            <th>Rating</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let review of reviews">
            <td>{{review.review_date | date:'MMMM d, y, h:mm a'}}</td>
            <td>{{review.patient_name}}</td>
            <td class="contact-info">
              <p class="m0" *ngIf="review.patient_mobile_num">
                <i class="icon-screen-smartphone mr"></i>
                {{review.patient_mobile_num | mask: ' (000) 000-0000'}}
              </p>
              <p class="m0" *ngIf="review.patient_phone_num">
                <i class="icon-phone mr"></i>
                {{review.patient_phone_num | mask: ' (000) 000-0000'}}
              </p>
              <p class="m0" *ngIf="review.patient_email">
                <i class="icon-envelope mr mt-sm"></i>
                {{review.patient_email}}
              </p>
            </td>
            <td>{{review.appointment_time | date: 'MMM d, y, h:mm a'}}</td>
            <td>{{review.appointment_doctor}}</td>
            <td>{{review.description}}</td>
            <td>{{review.rating}} Star</td>
          </tr>
        </tbody>
      </table>
      <app-no-result-found *ngIf=" reviews.length == 0"></app-no-result-found>
    </div>
  </div>
</div>
