<div class="flex-control flex-column flex-height">
  <div class="flex-header">
    <h4 class="m0">{{amenity.id ? 'Edit' : 'Add'}} Service</h4>
  </div>
  <form (ngSubmit)="save()" #amenityForm="ngForm" class="flex-body">
    <div class="flex-control flex-column" style="height:100%;">
      <div class="flex-body scroll-browser main-wrapper">
        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label>Name*</label>
          </div>
          <div class="col-md-4 col-sm-5">
            <input type="text" class="form-control" placeholder="Name of Service"
                   [(ngModel)]="amenity.name" name="name" required>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label>Method*</label>
          </div>
          <div class="col-md-4 col-sm-5">
            <ss-multiselect-dropdown [options]="methodOptions"
                                     [(ngModel)]="selected_method"
                                     name="m"
                                     [settings]="mySettings"
                                     (ngModelChange)="addMethodArray($event)"
                                     required>
            </ss-multiselect-dropdown>
          </div>
        </div>


        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
              <label>Short description</label>
          </div>
          <div class="col-md-8 col-sm-5">
            <editor #shoteditor apiKey="no-api-key" id="shot_description" name="shot_description" [(ngModel)]="amenity.short_description"
                    [init]="init_tiny">
            </editor>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label>Full Description</label>
          </div>
          <div class="col-md-8 col-sm-5">

            <editor #editor apiKey="no-api-key" id="summary" name="mesage_body" [(ngModel)]="amenity.long_description"
                    [init]="init_tiny">
            </editor>
          </div>
        </div>


        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label>No of booking/day</label>
          </div>
          <div class="col-md-2 col-sm-5">
            <input type="number" class="form-control" placeholder="No of booking/day"
                   [(ngModel)]="amenity.no_of_bookings_per_day" name="no_of_booking">
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label>Duration </label>
          </div>
          <div class="col-md-2 col-sm-5">
            <input type="number" class="form-control" placeholder="Duration"
                   [(ngModel)]="amenity.duration" name="duration">
          </div>min
        </div>
        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label>Offset </label>
          </div>
          <div class="col-md-2 col-sm-5">
            <input type="number" class="form-control" placeholder="Offset Time"
                   [(ngModel)]="amenity.offset_time" name="offset_time">
          </div>
          <div class="col-md-2 col-sm-5">
            <select name="offset_type" [(ngModel)]="amenity.offset_type" class="form-control">
              <option value="mins">Min</option>
              <option value="hours">Hour</option>
            </select>
          </div>
        </div>


        <ng-container *ngFor="let method of amenity.amenity_methods">
          <div class="row form-group">
            <div class="col-md-2 col-sm-3" >
              <label> {{method.name}} Fee*</label>
            </div>
            <div class="col-md-2 col-sm-5">
              <input type="number" class="form-control" placeholder="Fee"
                     [(ngModel)]="method.fee" name="{{method.name}}" required>
            </div>$
          </div>
        </ng-container>


        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label>Auto Acknowledge</label>
          </div>
          <div class="col-md-2 col-sm-5">
            <input type="checkbox" name="auto_acknowledge" [(ngModel)]="amenity.auto_acknowledge">
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-2 col-sm-3 hidden-xs">
            <label>Payment Method *</label>
          </div>
          <div class="col-md-10 col-sm-9 col-xs-12">
            <label class="white-container-box fw-500 mr-lg">
              Insurance
              <input type="radio" class="ml" name="all_patients" value="insurance" [(ngModel)]="amenity.payment_method" required/>
            </label>
            <label class="white-container-box fw-500 mr-lg">
              Self-Pay
              <input type="radio" class="ml" name="customized_patients" value="self_pay" [(ngModel)]="amenity.payment_method" required/>
            </label>
            <label class="white-container-box fw-500 mr-lg">
              Both
              <input type="radio" class="ml" name="customized_patients" value="both" [(ngModel)]="amenity.payment_method" required/>
            </label>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label>Type of Patients</label>
          </div>
          <div class="col-md-10 col-sm-9 col-xs-12">
            <label class="white-container-box fw-500 mr-lg">
              All Patients
              <input type="radio" class="ml" name="applicable_to_all_patients" value="all_patients" [(ngModel)]="amenity.applicable_patients"/>
            </label>
            <label class="white-container-box fw-500 mr-lg">
              New Patients
              <input type="radio" class="ml" name="applicable_to_new_patients" value="new_patients" [(ngModel)]="amenity.applicable_patients"/>
            </label>
            <label class="white-container-box fw-500 mr-lg">
              Existing Patient
              <input type="radio" class="ml" name="applicable_to_existing_patient" value="existing_patient" [(ngModel)]="amenity.applicable_patients"/>
            </label>
          </div>
        </div>

      </div>
      <div class="flex-footer">
        <div class="row form-group">
          <div class="col-md-2 col-sm-3"></div>
          <div class="col-md-8 col-sm-5">
            <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
            <button type="submit" class="btn btn-secondary mr" [disabled]="!amenityForm.form.valid" *ngIf="!loaderService.uploading_in_process">{{amenity.id ? 'Update' : 'Save'}} to Continue</button>
            <button class="btn btn-tertiary btn-outline mr" (click)="closeNewForm()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
