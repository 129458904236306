import { Injectable }     from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { Globals } from './../../globals'
import {CustomHttpClient} from '../../services/custom-http-client.service';

export class Practice {
    id: number;
    name: string;
}


@Injectable()
export class PracticeSearchService {

    constructor(private http: CustomHttpClient, private globals: Globals) {}

    /*search(term: string,role_type_id: string): Observable<Practice[]> {
        return this.http
            .get('permissions/get_practices.json/?name=' + term + '&role_type_id=' + role_type_id)
            .map((r: Response) => r as Practice[]);
    }*/
}
