export class TwilioConfiguration {
  id: number;
  account_sid: string;
  auth_token: string;
  api_key: string;
  api_secret: string;
  type: string;
  twiml_app_sid: string;
  twilio_number: string;
  configurable_type: string;
  configurable_id: number;

  constructor() {
  }

  loadFromJson(json: any): TwilioConfiguration {
    this.id = json.id;
    this.account_sid = json.account_sid;
    this.auth_token = json.auth_token;
    this.api_key = json.api_key;
    this.api_secret = json.api_secret;
    this.type = json.type;
    this.twiml_app_sid = json.twiml_app_sid;
    this.twilio_number = json.twilio_number;
    this.configurable_type = json.configurable_type;
    this.configurable_id = json.configurable_id;
    return this;
  }

  toParams(): any {
    return {
      twilio_configuration: {
        id: this.id,
        account_sid: this.account_sid,
        auth_token: this.auth_token,
        api_key: this.api_key,
        api_secret: this.api_secret,
        type: this.type,
        twiml_app_sid: this.twiml_app_sid,
        twilio_number: this.twilio_number,
        configurable_type: this.configurable_type,
        configurable_id: this.configurable_id
      }
    };
  }

  isNewRecord(): boolean {
    return this.id === 0 || this.id === undefined;
  }

  getUrl(): string {
    return this.isNewRecord() ? 'twilio-configurations/' : `twilio-configurations/${this.id}`;
  }
}
