export class Comment {
  id: number;
  body = '';
  commentable_id: number;
  commenter_id: number;
  commenter_name: string;
  commenter: any;
  commentable_type: string;
  comment_date = new Date;
  commenter_profile_pic: any;
  edit_comment = false;
  created_at: Date;
  updated_at: Date;
  constructor() {
  }
  load_from_json(json) {
    this.id = json.id;
    this.body = json.body;
    this.commenter_id = json.commenter_id;
    this.commenter_name = json.commenter_name;
    this.commenter_profile_pic = json.commenter_profile_pic;
    this.commentable_id = json.commentable_id;
    this.commentable_type = json.commentable_type;
    this.comment_date = json.created_at;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
    return this;
  }
  to_params() {
    return {
      comment: {
        id: this.id,
        body: this.body,
        commentable_type: this.commentable_type,
        commentable_id: this.commentable_id,
        commenter_id: this.commenter_id
      }
    }
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
  get_url(): string {
    return this.is_new_record() ? 'comments/' : ['comments', this.id].join('/')
  }

}
