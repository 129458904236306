<div class="flex-control flex-column" *ngIf="userService.current_user.isEnterprise && !showPracticeDashboard">
  <div class="flex-header filters-sec">
    <div class="row">
      <div class="col-xs-4">
        <input class="form-control" [(ngModel)]="this.text" placeholder="Search Patient">
      </div>
      <div class="col-sm-8">
        <button type="button" class="btn btn-tertiary btn-outline mr-sm" (click)="searchAllPracticeForReviews()">Filter</button>
        <button type="button" class="btn btn-tertiary btn-outline" *ngIf="text" (click)="clearPracticeFilter()">Clear</button>
      </div>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <table class="table table-hover">
      <thead>
      <tr class="theader">
        <th>Name</th>
        <th>Specialty</th>
        <th>Address</th>
        <th>Contact</th>
        <th class="text-center">No. of Doctors</th>
        <th class="text-center">No. of Locations</th>
        <th class="text-center">Reviews</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let practice of practices">
        <tr>
          <td class="pointer"><a href="javascript:void(0)" (click)="getSetup(practice.id)">{{practice.name}}</a></td>
          <td [title]="practice.specialties[0]?.description" >{{practice.specialties[0]?.description}}</td>
          <td [title]="practice.practice_humanize_address">{{practice.practice_humanize_address}}</td>
          <td><div>{{practice.requester_firstname}} {{practice.request_lastname}}</div>{{practice.contact_numbers[0]?.value}}</td>
          <td class="text-center">{{practice.practice_total_doctors}}</td>
          <td class="text-center">{{practice.no_of_practice_locations}}</td>
          <td class="text-center">{{practice.averageExactRating}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
<!--    <div class="text-center" *ngIf="practices && practices.length > 30">-->
<!--      <pagination class="m0" [totalItems]="totalItems" (pageChanged)="pageChanged($event)"></pagination>-->
<!--    </div>-->
  </div>
</div>
<div class="flex-control flex-column" *ngIf="userService.current_user.isPractice || showPracticeDashboard">
  <div class="flex-header"><h4 class="m0">Setup</h4></div>
  <form class="flex-body p scroll-browser" (ngSubmit)="save()" #practice_review="ngForm">
    <div class="mb-xl">
      <label class="field-label control-label">Status</label>
      <label class="btn btn-default btn-sm mr">
        <input type="radio" value="start" name="practice_review_setup.status" [(ngModel)]="practice_review_setup.status">
        Start
      </label>
      <label class="btn btn-default">
        <input type="radio" value="stop" name="practice_review_setup.status" [(ngModel)]="practice_review_setup.status">
        Stop
      </label>
    </div>
    <div class="mb-xl">
      <label class="field-label control-label">Review for</label>
      <label class="btn btn-default btn-sm mr">
        <input type="radio" value="doctor" name="practice_review_setup_practice_review_preferences_review_type" [(ngModel)]="practice_review_setup.review_type">
        Doctor
      </label>
      <label class="btn btn-default btn-sm mr">
        <input type="radio" value="practice" name="practice_review_setup_practice_review_preferences_review_type" [(ngModel)]="practice_review_setup.review_type">
        Practice
      </label>
      <label class="btn btn-default btn-sm mr">
        <input type="radio" value="both" name="practice_review_setup_practice_review_preferences_review_type" [(ngModel)]="practice_review_setup.review_type">
        Both
      </label>
    </div>
<!--    <div class="mb-xl" *ngIf="practice_review_setup.review_type == 'both'">-->
<!--      <label class="field-label control-label">First preference</label>-->
<!--      <label class="btn btn-default btn-sm mr">-->
<!--        <input type="radio" value="doctor_first" name="practice_review_setup_practice_review_preferences_first_preference" [(ngModel)]="practice_review_setup.first_preference">-->
<!--        Doctor-->
<!--      </label>-->
<!--      <label class="btn btn-default btn-sm mr">-->
<!--        <input type="radio" value="practice_first" name="practice_review_setup_practice_review_preferences_first_preference" [(ngModel)]="practice_review_setup.first_preference">-->
<!--        Practice-->
<!--      </label>-->
<!--    </div>-->
    <div class="mb-xl">
      <div class="approve-review flex-control">
        <div class="col">
          <label class="field-label control-label">
            Enable auto approve for public review &nbsp;
            <input class="vl-m m0" type="checkbox" name="auto_approve_enable" [(ngModel)]="practice_review_setup.auto_approve_enabled">
          </label>
        </div>
        <div class="col col-xs-2" *ngIf="practice_review_setup.auto_approve_enabled">
          <span class="pr-sm">for</span>
          <select name="star_dropdown" class="form-control" [(ngModel)]="practice_review_setup.star_preference" placeholder="Star Rating" >
            <option value='' disabled  > Select Star </option>
            <option *ngFor="let star_rating of star_ratings" [value]="star_rating">{{star_rating_mapper[star_rating]}}</option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
      <button type="submit" class="btn btn-secondary mr-sm" *ngIf="!loaderService.uploading_in_process">{{practice_review_setup.id ? 'Update' : 'Create'}}</button>
      <button type="button" (click)="back()" class="btn btn-tertiary btn-outline" *ngIf="userService.current_user.isEnterprise">Back</button>
    </div>
  </form>
</div>
