<div class="table-responsive">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Programs</th>
      <th>Practice</th>
      <th class="text-center">Type</th>
      <th>Care Team</th>
      <th class="text-center">Enrolled On</th>
      <th class="text-center">Status</th>
      <th style="min-width:182px; width:182px;" *ngIf="userService.current_user.isPatient">Actions</th>
    </tr>
    </thead>
    <tbody  *ngIf="patient_program_enrollments.length > 0">
      <ng-container *ngFor="let program of patient_program_enrollments">
        <tr>
          <td>{{program.name}}</td>
          <td>{{program.practice_name}}</td>
          <td class="text-center text-uppercase">{{program.enrollment_type | uppercase}}</td>
          <td style="min-width:200px; white-space:pre-wrap;">{{program.care_team}}</td>
          <td class="text-center">{{program.created_at | date: 'short'}}</td>
          <td class="text-center text-capitalize">{{program.status}}</td>
          <td *ngIf="userService.current_user.isPatient">
            <a class="btn btn-secondary btn-outline btn-sm" title="Request Phone Call"
               (click)="request_type = 'phone';selected_program_enrolment = program;requestToCareTeamModal.show();">
              <i class="fa icon-call-out fs11"></i>
            </a>
            <a class="btn btn-secondary btn-outline btn-sm ml-sm" title="Request Video Call"
               (click)="request_type = 'video';selected_program_enrolment = program;requestToCareTeamModal.show();">
              <i class="fa fa-video-camera fs11"></i>
            </a>
            <a class="btn btn-secondary btn-outline btn-sm ml-sm" title="Sign a Consent"
               *ngIf="program.status === 'pending' && !program.signed_by"
               (click)="selectedProgram = program;saveConsent.show();">
              <i class="fa icon-lock fs11"></i>
            </a>
            <a class="btn btn-secondary btn-outline btn-sm ml-sm" title="Opt Out Request"
               (click)="request_type = 'Opt Out';selected_program_enrolment = program;requestToCareTeamModal.show();">
              <i class="fa icon-logout fs11"></i>
            </a>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<app-no-result-found *ngIf="patient_program_enrollments && patient_program_enrollments.length == 0"></app-no-result-found>

<div class="modal fade" bsModal #requestToCareTeamModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="requestToCareTeamModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-capitalize" *ngIf="request_type == 'Opt Out'">{{request_type}} Request</h4>
        <h4 class="modal-title" *ngIf="request_type == 'video' || request_type == 'phone'">Request {{request_type}} call</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to send {{request_type}} request?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="requestToCareTeam(request_type);requestToCareTeamModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="request_type = undefined; selected_program_enrolment = undefined; requestToCareTeamModal.hide()">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #saveConsent="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="saveConsent.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Sign Date & Time</h4>
      </div>
      <div class="modal-body">
        <div class="row mb-xl">
          <div class="col-md-4 col-sm-5">
            <input  type="date" name="date" placeholder="Date" class="form-control" [(ngModel)]="today_date">
          </div>
          <div class="col-md-4 col-sm-5">
            <input type="time" name="time" placeholder="Time" class="form-control"  [(ngModel)]="current_time">
          </div>
        </div>
        <h4>Signed by <b><span class="text-capitalize">{{patient.name}}</span></b> on <b>{{today_date}}</b> at <b>{{current_time}}</b> from patient portal</h4>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="saveConsentOfPatient(selectedProgram);saveConsent.hide()">Sign</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="selectedProgram = undefined;saveConsent.hide() ">No</button>
      </div>
    </div>
  </div>
</div>
