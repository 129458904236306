import { Injectable } from '@angular/core';
import { CustomHttpClient } from './custom-http-client.service';
import { TwilioConfiguration } from '../routes/practices/twilio-configuration/twilio_configuration';

@Injectable({
  providedIn: 'root'
})
export class TwilioConfigurationService {
  constructor(private httpClient: CustomHttpClient) {}

  fetchTwilioConfigurations(practiceId: number): Promise<TwilioConfiguration[]> {
    return this.httpClient.get(`twilio_configurations?practiceId=${practiceId}`).toPromise();
  }

  createTwilioConfiguration(config: TwilioConfiguration): Promise<any> {
    return this.httpClient.post('twilio_configurations', config).toPromise();
  }

  updateTwilioConfiguration(configuration: TwilioConfiguration): Promise<any> {
    return this.httpClient.put(`twilio_configurations/${configuration.id}`, configuration).toPromise();
  }

  deleteTwilioConfiguration():Promise<any> {
    return this.httpClient.delete(`twilio_configurations/delete_all`).toPromise();
  }
}
