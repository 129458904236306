import { Injectable } from '@angular/core';
import {AlertIntervention, AlertTemplate} from './alert-template';
import {CustomHttpClient} from '../../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../../services/nullRemover';
import {Subject} from 'rxjs';
import {AlertTemplateRule} from './alert-template-rule';
import {toUpper} from 'lodash';
import {UserService} from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AlertTemplateService {
  public severities: string[] = ['critical', 'high', 'low', 'normal'];
  public alert_types: string[] = ['chronic_care_management', 'disease_management'];
  public alert_age_trends: string[] = ['greater_than', 'between', 'less_than', 'equals_to'];
  public alert_age_type: string[] = ['day', 'month', 'year'];
  public alert_frequency_type: string[] = ['f_day', 'f_month', 'f_year'];
  public protocols: any = [];
  public reload_alert_template_list: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient, public userService: UserService) { }

  create(alertTemplate: AlertTemplate): Promise<any> {
    return this._tokenService
      .post('alert_templates', alertTemplate.to_params())
      .toPromise()
      .then(response => response)
  }

  save(alertTemplate: AlertTemplate): Promise<any> {
    if (alertTemplate.is_new_record()) {

      return this.create(alertTemplate);
    }
    else {
      return this.update(alertTemplate);
    }
  }

  update(alertTemplate: AlertTemplate) {
    return this._tokenService
      .put('alert_templates', alertTemplate.to_params())
      .toPromise()
      .then(response => response)
  }

  getAlertTemplates(filter: Object): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('alert_templates', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          alertTemplates: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }
  getVitalProtocols() {
    return this._tokenService.get('alert_templates/protocols')
      .toPromise()
      .then(response => response)
  }
  getInterventions(token) {
    let args = new HttpParams();
    args = args.set('token', token);
    return this._tokenService.get('interventions', {params: args})
      .toPromise()
      .then(response => response)
  }

  createAlertIntervention(intervention: AlertIntervention): Promise<any> {
    return this._tokenService
      .post('interventions', {intervention: intervention.to_params()})
      .toPromise()
      .then(response => response)
  }
  getAlertTemplateInterventions(alert: any) {
    let params = new HttpParams();
    params = params.set('template_id', alert.source_id);
    params = params.set('notification_id', alert.id);
    return this._tokenService.get('alert_templates/interventions', {params: params} )
      .toPromise()
      .then(response => response)
  }
  saveAlertTemplateRuleForPatient(alertTemplateRule: AlertTemplateRule): Promise<any> {
    if (alertTemplateRule.is_new_record()) {
      return this.createAlertTemplateRuleForPatient(alertTemplateRule);
    }
    else {
      return this.updateAlertTemplateRuleForPatient(alertTemplateRule);
    }
  }
  createAlertTemplateRuleForPatient(alertTemplateRule: AlertTemplateRule): Promise<any> {
    return this._tokenService
      .post('alert_templates/rules', {alert_rule: alertTemplateRule.to_params()})
      .toPromise()
      .then(response => response)
  }
  updateAlertTemplateRuleForPatient(alertTemplateRule: AlertTemplateRule): Promise<any> {
    return this._tokenService
      .put('alert_templates/rules', {alert_rule: alertTemplateRule.to_params()})
      .toPromise()
      .then(response => response)
  }
  resolvedInterventionsForPatient(resolvedInterventions: any[]): Promise<any> {
    return this._tokenService
      .post('alert_templates/resolve', {intervention_notifications : resolvedInterventions})
      .toPromise()
      .then(response => response)
  }
  updateAlertStatusAsRead(alert_id: number): Promise<any> {
    return this._tokenService
      .put('enrolments/update_alert_status/' + alert_id, {id: alert_id})
      .toPromise()
      .then(response => response)
  }


  getPatientCustomizeRules(filter: Object): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('alert_templates/customize_patient_rules', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          alertTemplateRules: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  vitalDropdownChange(value: any, entry: any) {
    if (value.toLowerCase() === 'between') {
      entry.value = undefined;
      entry.show_min_max = true;
    }
    if (value.toLowerCase() !== 'between') {
      entry.max_value = undefined;
      entry.min_value = undefined;
      entry.show_min_max = false;
    }
  }
  removeTempObjectFromArray(object: any, array: any[]) {
    if (object.id) {
      let objIndex = array.findIndex((obj => obj.id === object.id));
      console.log(array)
      return array[objIndex]._destroy = true;
    } else {
      let index = array.indexOf(object);
      console.log(array)
      return array.splice(index, 1)
    }
  }
  mapProtocolsToUpperCase(protocol) {
    if (protocol === 'copd' || protocol === 'chf' || protocol === 'aids') {
      return  this.userService.removeUnderScore(toUpper(protocol));
    } else {
      return  this.userService.removeUnderScore(protocol);
    }
  }
}
