<div class="flex-header filters-sec">
  <div class="row">
    <div class="col-sm-2 pr-sm">
      <input [(ngModel)]="asyncName" placeholder="Name" class="form-control">
    </div>
    <div class="col-sm-2 pr-sm">
        <input [(ngModel)]="asyncPracticeName" placeholder="Practice" class="form-control">
    </div>
    <div class="col-sm-2 pr-sm">
      <input [(ngModel)]="asyncSpecialty" (keyup)="setPracticeId()" [typeaheadAsync]="true" typeaheadWaitMs="500" [typeaheadAsync]="true" [typeahead]="dataSource" (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7" [typeaheadOptionField]="'description'" placeholder="Specialty" class="form-control">
    </div>
    <div class="col-sm-2 pl0">
      <select #status class="form-control" (change)='changeStatus(status.value)'>
        <option *ngFor="let value of values; let i = index;" value={{i+1}}>
          {{value}}
        </option>
      </select>
    </div>
    <div class="col-sm-2">
      <button type="button" class="btn btn-tertiary btn-outline" (click)="apply_filter_doctors()">Filter</button>
    </div>
  </div>
</div>
<app-index class="flex-body flex-control flex-column" (current_page)="pageChanged($event)" [totalItems]="totalItems" [listing]="listing" *ngIf="listing"></app-index>
