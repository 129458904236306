import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SurveysComponent} from "../reviews/surveys/surveys.component";
import {ReviewsDashboardComponent} from "../reviews/reviews-dashboard/reviews-dashboard.component";
import {ReviewsSetupComponent} from "../reviews/reviews-setup/reviews-setup.component";
import {RemindersComponent} from "./reminders/reminders.component";


export const routes: Routes = [
  {
    path: 'reminders',
    children: [
      {
        path: '', component: RemindersComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RemindersRoutingModule { }
