<div class="filters-sec">
  <a class="btn btn-primary btn-outline btn-filter mb" data-toggle="collapse" href="#collapsefilters"><i class="fa fa-filter mr-sm"></i> Filters</a>
  <div class="collapse" id="collapsefilters">
    <div class="row">
      <ng-container *ngIf="_visitService.visit_user_type != 'Staff' && _visitService.visit_user_type != 'Doctor'">
        <div class="col-lg-2 col-md-3 col-sm-3 form-group">
          <div class="input-group">
            <input [typeahead]="_visitService.practices" name="practices" [(ngModel)]="asyncSelectedPractice"
                   (typeaheadLoading)="changeTypeaheadLoadingPractice($event)" [typeaheadAsync]="true"
                   (typeaheadNoResults)="changeTypeaheadNoResultsPractice($event)" typeaheadWaitMs="500"
                   (typeaheadOnSelect)="typeaheadOnSelectPractice($event)" [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'name'" placeholder="Practices"
                   class="form-control" autocomplete="off">
            <div class="input-group-addon" *ngIf="typeaheadLoadingPractice"><i class="fa fa-spinner fa-spin"></i></div>
          </div>
          <div *ngIf="typeaheadNoResultsPractice" class="text-danger fs13">No Results Found!</div>
        </div>
      </ng-container>

      <div class="col-lg-2 col-md-3 col-sm-3 form-group">
        <select [(ngModel)]="_visitService.location_id" class="form-control">
          <option>Locations</option>
          <option *ngFor="let location of _visitService.practiceLocations" selected="location_id" [value]="location.id">
            {{location.name}}
          </option>
        </select>
      </div>

      <ng-container *ngIf="_visitService.visit_user_type != 'Doctor'">
        <div class="col-lg-2 col-md-3 col-sm-3 form-group">
          <div class="input-group">
            <input [typeahead]="_visitService.doctors" name="doctors" [(ngModel)]="asyncSelectedDoctor"
                   (typeaheadLoading)="changeTypeaheadLoading($event)" typeaheadWaitMs="500"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)" [typeaheadAsync]="true"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'full_name'" placeholder="Doctors"
                   class="form-control" autocomplete="off">
            <div class="input-group-addon" *ngIf="typeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
          </div>
          <div *ngIf="typeaheadNoResultsDoctor" class="text-danger fs13">No Results Found!</div>
        </div>
      </ng-container>


      <div class="col-lg-2 col-md-3 col-sm-3 form-group">
        <input [(ngModel)]="_visitService.patient_name" (keyup.enter)="performSearch()" class="form-control" placeholder="Patient" />
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 form-group">
        <select [(ngModel)]="_visitService.gender" class="form-control">
          <option value='gender'>Gender</option>
          <option value='male'>Male</option>
          <option value='female'>Female</option>
          <option value='trans'>Trans</option>
          <option value="trans_male">Transgender male/Trans man/Female-to-male</option>
          <option value="trans_female">Transgender female/Trans woman/Male-to-female</option>
          <option value="non_binary">Genderqueer/Non-binary</option>
          <option value="decline">Decline to answer</option>
          <option value='other'>Other</option>
        </select>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 form-group">
        <select [(ngModel)]="_visitService.visit_type" class="form-control" (change)="setStatusOnVisitType()">
          <option value='all'>All</option>
          <option value=0>Office Visit</option>
          <option value=1>Virtual Visit</option>
          <option value=2>Phone Visit</option>
        </select>
        <small class="text-danger" *ngIf="show_notice">un-select previous statuses</small>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-3 form-group">
        <ss-multiselect-dropdown [options]="myOptions" [texts]="myTexts" [(ngModel)]="optionsModel" [settings]="selectSettings" (ngModelChange)="selectStatuses($event)" [disabled]="no_filter ? true : false"></ss-multiselect-dropdown>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-3 form-group">
        <select [(ngModel)]="_visitService.appointment_from" class="form-control">
          <option>Booking From</option>
          <option value=0>Online</option>
          <option value=1>Walk-in</option>
          <option value=2>Booking</option>
          <option value=3>Waiting</option>
          <option value=5>Emergency</option>
          <option value=6>Widget</option>
        </select>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 form-group">
        <!--<input [(ngModel)]="_visitService.start_date" autocomplete="off" class="form-control" placeholder="From"
               ngui-datetime-picker [disabled-dates]="date2DisabledDates" [show-close-layer]="true" date-only="true"/>-->
        <input [(ngModel)]="_visitService.start_date" autocomplete="off" class="form-control"
               placeholder="From"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
               bsDatepicker/>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-2 form-group">
        <!--   <input [(ngModel)]="_visitService.end_date" autocomplete="off" class="form-control" placeholder="To"
                  ngui-datetime-picker [disabled-dates]="date2DisabledDates" [show-close-layer]="true" date-only="true"/>-->
        <input [(ngModel)]="_visitService.end_date" autocomplete="off"  class="form-control"
               placeholder="To"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
               bsDatepicker/>
      </div>

      <div class="col-xs-12 btns-area">
        <button type="button" [disabled]="no_filter ? true : false " class="btn btn-tertiary btn-outline" (click)="performSearch()" [ngClass]="no_filter ? 'disabled' : '' "><i class="fa fa-filter"></i> Filter</button>
        <button *userCan="'print,Visits,reception'" type="button" class="btn btn-secondary ml-sm" (click)="add_to_tabs('Print')"><i class="fa fa-print"></i> Print</button>
      </div>

      <div class="row pull-right">
        <div class="col-sm-2 order-field">
          <span>Group by:</span>
          <select [(ngModel)]="_visitService.group_by">
            <option value=0>Select</option>
            <option value=1>Doctor</option>
            <option value=2>Visit Type</option>
            <option value=3>Practice</option>
            <option value=4>Location</option>
            <option value=5>Date</option>
          </select>
        </div>
        <div class="col-sm-2 order-field">
          <span>Sort by:</span>
          <select [(ngModel)]="_visitService.sort_by">
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
