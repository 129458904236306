import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { CustomValidations } from '../../../routes/customValidations/custom-validations';
import { NgForm } from '@angular/forms';
import {Router} from '@angular/router';
import {User} from '../../../routes/users/user';
import {UserService} from '../../../services/user.service';
import {HttpClient} from '@angular/common/http';
import {HttpTokenService, SignInData} from '../../../services/http.token.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  public user: User = new User();
  public customValidations: CustomValidations;
  public error_message = false;
  public _output: any;
  public show_error = false;


  @ViewChild('LoginForm') LoginForm: NgForm;

  constructor(private _tokenService: HttpTokenService, private router: Router, public _userService: UserService) {
    this.customValidations = new CustomValidations();
  }

  doLogin() {
    this.login(this.user.email, this.user.password);
    // this._userService.login(this.user.email,this.user.password);
  }

  login(email, password) {
    this._tokenService.signIn({
      email:    email,
      password: password,
      login: email
    }).subscribe(res => {
        if (localStorage.getItem('redirectTo')) {
          return true;
        }
      }, error => {
        if (error.statusText == 'Unauthorized') {
          this.error_message = true;
          setTimeout(() => {
            this.error_message = false;
          }, 3000)
        }
        if (error && error.status === 409) {
          this.setTimeOutFailsMessageInactiveUser();
        }
        return false;
      })
  }

  ngOnInit() {
    if (this._userService.current_user) {
      this.router.navigate(['home']);
    }
  }
  ngAfterViewChecked() {
    this.customValidations.formChanged(this.LoginForm);
  }

  callme() {
    this.ngAfterViewChecked();
  }

  setTimeOutFailsMessage(): any {
    this.show_error = true;
    this._output = 'Invalid email or password.';
    this.resetTimeOutMessage();
  }

  setTimeOutFailsMessageInactiveUser(): any {
    this.show_error = true;
    this._output = 'User is inactive/blocked. Please contact our customer support to activate/unblock this user.';
    this.resetTimeOutMessage();
  }

  resetTimeOutMessage() {
    setTimeout(() => {
      this._output = null;
      this.show_error = false;
    }, 3000)
  }

}
