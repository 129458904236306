<div class="flex-header filters-sec">
  <div class="row">
    <div class="col-md-2 col-sm-2"><h4 class="fs16 fw-500">Health Metrics</h4></div>
    <div class="col-md-3 col-sm-3" *ngIf="vitals_view === 'daily'">
      <select class="form-control"  #daily_view_month_dropdown [(ngModel)]="daily_view_month" (change)="setDailyViewMonth(daily_view_month_dropdown.value)" >
        <option [value]="'current'">Current Month</option>
        <option [value]="'previous'">Previous Month</option>
        <option [value]="'two_months_ago'">Two months ago</option>
      </select>
    </div>
    <ng-container *ngIf="vitals_view === 'flowSheet'">
      <div class="col-md-2 col-sm-3">
        <select class="form-control" [(ngModel)]="filter['no_of_days']" name="relation_with_patient">
          <option value="last_seven_days">Last 7 Days</option>
          <option value="last_fourteen_days">Last 14 days</option>
          <option value="last_thirty_days">Last 30 days</option>
          <option value="current_month">Current Month</option>
          <option value="custom">Custom</option>
        </select>
      </div>
    </ng-container>
    <ng-container *ngIf="vitals_view === 'graph'">
      <div class="col-md-2 col-sm-3">
        <select class="form-control" #graph_view_days  [(ngModel)]="graph_no_of_days"
                (change)="setGraphViewDays(graph_view_days.value)" name="graph_view_days_dropdown">
          <option value="last_7_days">Last 7 Days</option>
          <option value="last_14_days">Last 14 days</option>
          <option value="last_30_days">Last 30 Days</option>
          <option value="custom">Custom</option>
        </select>
      </div>
      <div class="col-md-3 col-sm-3" *ngIf="graph_no_of_days == 'custom'">
        <input [(ngModel)]="dateRangeValue" type="text"
               placeholder="Datepicker"
               class="form-control" [outsideClick]="true"
               (bsValueChange)="setDateRangeForGraph($event)"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
               bsDaterangepicker/>
      </div>
    </ng-container>
    <ng-container *ngIf="vitals_view === 'report'">
      <div class="col-md-2 col-sm-3">
        <select class="form-control"  #report_type_dropdown [(ngModel)]="report_type">
          <option value=undefined disabled>Report Type</option>
          <option [value]="'detail'">Detail</option>
          <option [value]="'summary'">Summary</option>
          <option [value]="'trend'">Trend</option>
        </select>
      </div>
        <div class="col-md-2 col-sm-3">
          <select class="form-control" #graph_view_days  [(ngModel)]="report_no_of_days"
                  (change)="setReportViewDays(graph_view_days.value)" name="report_dropdown">
            <option value="last_7_days">Last 7 Days</option>
            <option value="last_14_days">Last 14 days</option>
            <option value="last_30_days">Last 30 Days</option>
            <option value="current_month">Current Month</option>
            <option value="previous_month">Previous Month</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        <div class="col-md-3 col-sm-3" *ngIf="report_no_of_days == 'custom'">
          <input [(ngModel)]="dateRangeValue" type="text"
                 placeholder="Datepicker"
                 class="form-control" [outsideClick]="true"
                 (bsValueChange)="setDateRangeForReport($event)"
                 [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                 bsDaterangepicker/>
        </div>
      <div class="col-md-1 col-sm-3">
        <a class="btn btn-tertiary btn-outline mr" (click)="createSleepReport()">
          <i class="fa fa-filter"></i> Create Report
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="filter['no_of_days'] == 'custom'">
      <div class="col-md-3 col-sm-3">
        <input [(ngModel)]="dateRangeValue" type="text"
               placeholder="Datepicker"
               class="form-control" [outsideClick]="true"
               (bsValueChange)="setDateRange($event)"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
               bsDaterangepicker/>
      </div>
    </ng-container>
    <div class="col-md-4 co-sm-3" *ngIf="vitals_view === 'flowSheet' || vitals_view !== 'report'">
      <a class="btn btn-tertiary btn-outline mr" (click)="search()" *ngIf="vitals_view === 'flowSheet'">
        <i class="fa fa-filter"></i> Filter
      </a>
      <button class="btn btn-secondary mr" *ngIf="vitals_view !== 'report'" (click)="addVital()" title="Add More Vitals">
        <i class="fa fa-plus"></i> Add Vitals
      </button>
    </div>
    <div class="col-md-1 co-sm-1 pull-right">
      <div dropdown class="pull-right">
        <button dropdownToggle type="button" class="btn btn-tertiary btn-outline dropdown-toggle" ngbTooltip="Insights" placement="bottom left">
          <i class="fa fa-ellipsis-v"></i>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown animated slideInDown">
          <li role="menuitem"><a class="dropdown-item" (click)="setView('daily')">Daily View</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="setView('flowSheet')">Flowsheet Over Time</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="setView('graph')">Graph Over Time</a></li>
          <li role="menuitem" *ngIf="patient.third_party_patient_id"><a class="dropdown-item" (click)="setView('report')">Sleep Compliance Report</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="flex-body">
  <ng-container *ngIf="vitals_view === 'daily'">
    <app-vitals-daily-view class="flex-control flex-column p"
                           [minDate]="minDate" [maxDate]="maxDate" [patient]="patient"
                           [daily_view_month]="daily_view_month">
    </app-vitals-daily-view>
  </ng-container>
  <ng-container *ngIf="vitals_view === 'flowSheet'">
    <app-vitals-flowsheet-view class="flex-control flex-column p"
                               [vital_data]="vital_data">
    </app-vitals-flowsheet-view>
  </ng-container>
  <ng-container *ngIf="vitals_view === 'graph'">
    <app-vitals-graph-view class="flex-control flex-column" [patient_id]="patient_id"
                           [customFromDateGraph]="customFromDateGraph" [customToDateGraph]="customToDateGraph"
                           [graph_no_of_days]="graph_no_of_days">
    </app-vitals-graph-view>
  </ng-container>
  <ng-container *ngIf="vitals_view === 'report'">
    <app-vitals-report-view class="flex-control flex-column" [patient_id]="patient_id"
                            [customToDateReport]="customToDateReport"
                            [customFromDateReport]="customFromDateReport"
                            [report_type]="report_type"
                            [report_no_of_days]="report_no_of_days">
    </app-vitals-report-view>
  </ng-container>
</div>
<div [hidden]="!add_patient_vital">
  <div class="modal modal_popup" bsModal #vitalModal="bs-modal" [config]="{backdrop: false,ignoreBackdropClick: true}">
    <div class="modal-dialog modal-lg" style="height:500px">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="close();"><i class="fa fa-times fs16"></i></button>
          <h4 class="m0 text-center">Add Vitals</h4>
        </div>
        <div class="modal-body">
          <app-vitals *ngIf="show_patient_vitals" [patient_vitals]="patientVitals"></app-vitals>
          <div class="program-vital" *ngIf="add_patient_vital">
            <ng-container *ngComponentOutlet="vitalComponent;injector: custom_injector;"></ng-container>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-tertiary btn-outline btn-min" (click)="close();">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
