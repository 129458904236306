<app-tabs>
  <li (click)="tabs_service.deactivate_tab()" [class.active]="!tabs_service.is_active()">Virtual Patient</li>
</app-tabs>


<div [hidden]="tabs_service.is_active()">
  <ng-container *ngIf="_user_service.current_user && test(_user_service.current_user)">
    <app-todays-patients [app_type]="'video'" [providers]="providers" [locations]="locations" [practice]="practice" [doctor_id]="doctor_id"></app-todays-patients>
  </ng-container>
</div>
