import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {LayoutComponent} from '../health/layout';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {WhyWellwinkComponent} from './why-wellwink/why-wellwink.component';
import {PartnerComponent} from './partner/partner.component';
import {PrivacyAndPolicyComponent} from './privacy-and-policy/privacy-and-policy.component';
import {GDPRComponent} from './gdpr/gdpr.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {PublicMedicalCenterComponent} from './public-medical-center/public-medical-center.component';
import {PublicDoctorsComponent} from './public-doctors/public-doctors.component';
import {PublicPatientsComponent} from './public-patients/public-patients.component';
import {PublicPatientRemindersComponent} from './public-patient-reminders/public-patient-reminders.component';
import {PublicTelemedicineComponent} from './public-telemedicine/public-telemedicine.component';
import {PublicIntegrationComponent} from './public-integration/public-integration.component';
import {PublicPatientPortalComponent} from './public-patient-portal/public-patient-portal.component';
import {PublicReputationManagementComponent} from './public-reputation-management/public-reputation-management.component';
import {PublicPatientRemoteMonitoringComponent} from './public-patient-remote-monitoring/public-patient-remote-monitoring.component';
import {PublicPatientCommunicationComponent} from './public-patient-communication/public-patient-communication.component';
import {PublicAppointmentsBookingComponent} from './public-appointments-booking/public-appointments-booking.component';
import {PublicPracticeMarketingComponent} from './public-practice-marketing/public-practice-marketing.component';
import {PublicPartnerProgramComponent} from './public-partner-program/public-partner-program.component';
import {
  PublicChronicCareManagementComponent
} from './public-chronic-care-management/public-chronic-care-management.component';
import {
  PublicRemoteTherapeuticMonitoringComponent
} from './public-remote-therapeutic-monitoring/public-remote-therapeutic-monitoring.component';
import {
  PublicRemotePatientMonitoringComponent
} from './public-remote-patient-monitoring/public-remote-patient-monitoring.component';
import {PatientHomeComponent} from '../health/routes/patient-home/patient-home.component';
import {FaqsComponent} from "./faqs/faqs.component";

export const routes: Routes = [
  {
    path: '',
    data: {
      seo: {
        title: 'WellWink: Empower Healthcare Providers to Deliver Exceptional Patient Experiences',
        metaTags: [
          { name: 'description', content: 'WellWink\'s innovative patient engagement and care coordination solutions help healthcare providers improve patient satisfaction, reduce costs, and achieve better health outcomes.' },
          {name: 'title', content: 'WellWink: Empower Healthcare Providers to Deliver Exceptional Patient Experiences'}
        ]
      }
    },
      component: LayoutComponent, children: [
      {path: 't&c', component: TermsAndConditionsComponent,
        data: {
          seo: {
            title: 'WellWink Terms and Conditions: Learn More About Our Legal Agreement',
            metaTags: [
              { name: 'description', content: 'WellWink\'s terms and conditions outline the legal agreement between you and WellWink. Learn more about your rights and responsibilities as a WellWink user.' },
              {name: 'title', content: 'WellWink Terms and Conditions: Learn More About Our Legal Agreement'}
            ]
          } }
      },
      {path: 'why-wellwink', component: WhyWellwinkComponent,
        data: {
          seo: {
            title: 'WellWink: The Leading Provider of Patient Engagement and Care Management Software',
            metaTags: [
              { name: 'description', content: 'WellWink is a leading provider of patient engagement and care coordination software that helps healthcare providers improve patient satisfaction, reduce costs, and achieve better health outcomes. Learn more about why WellWink is the best choice for your practice.' },
              {name: 'title', content: 'WellWink: The Leading Provider of Patient Engagement and Care Management Software'}
            ]
          } }
      },
      {path: 'faqs', component: FaqsComponent,
        data: {
          seo: {
            title: 'WellWink FAQs: Get Answers to Your Questions About Patient Engagement and Care Management Software',
            metaTags: [
              { name: 'description', content: 'Learn more about WellWink\'s patient engagement and care coordination software by reading our FAQs. Get answers to your questions about pricing, features, and how WellWink can help your practice grow.' },
              {name: 'title', content: 'WellWink FAQs: Get Answers to Your Questions About Patient Engagement and Care Management Software'}
            ]
          } }
      },
      {path: 'partner', component: PartnerComponent,
        data: {
          seo: {
            title: 'WellWink Partner Program: Grow Your Practice and Help Your Patients Achieve Better Health Outcomes',
            metaTags: [
              { name: 'description', content: 'WellWink is a leading provider of patient engagement and care coordination solutions. Our partner program offers healthcare providers the opportunity to partner with us to deliver exceptional patient experiences and improve health outcomes. Learn more about how to become a WellWink partner today.' },
              {name: 'title', content: 'WellWink Partner Program: Grow Your Practice and Help Your Patients Achieve Better Health Outcomes'}
            ]
          } }
      },
      {path: 'p&p', component: PrivacyAndPolicyComponent,
        data: {
          seo: {
            title: 'WellWink Privacy Policy: Learn How We Protect Your Data',
            metaTags: [
              { name: 'description', content: 'WellWink\'s privacy policy outlines how we collect, use, and share your personal data. Learn more about our commitment to protecting your privacy and how you can control your data.' },
              {name: 'title', content: 'WellWink Privacy Policy: Learn How We Protect Your Data'}
            ]
          } }
      },
      {path: 'GDPR', component: GDPRComponent,
        data: {
          seo: {
            title: 'WellWink: How We Keep Your Data Safe in the Age of the GDPR',
            metaTags: [
              { name: 'description', content: 'WellWink uses a variety of security measures to protect your data, including encryption, access control, and regular security audits. Learn more about how we keep your data safe and compliant with the GDPR.' },
              {name: 'title', content: 'WellWink: How We Keep Your Data Safe in the Age of the GDPR'}
            ]
          } }
      },
      {path: 'contact-us', component: ContactUsComponent,
        data: {
          seo: {
            title: 'WellWink Contact Us: Get a Demo of Our Patient Engagement and Care Management Software',
            metaTags: [
              { name: 'description', content: 'Contact us today to schedule a demo of our patient engagement and care coordination software. We\'ll show you how WellWink can help you improve patient satisfaction, reduce costs, and achieve better health outcomes.' },
              {name: 'title', content: 'WellWink Contact Us: Get a Demo of Our Patient Engagement and Care Management Software'}
            ]
          } }
      },
      {path: 'public-medical-center', component: PublicMedicalCenterComponent},
      {path: 'public-doctors', component: PublicDoctorsComponent},
      {path: 'public-patients', component: PublicPatientsComponent},
      {path: 'public-telemedicine', component: PublicTelemedicineComponent,
        data: {
          seo: {
            title: 'Telemedicine for Everyone: Access Healthcare from Anywhere',
            metaTags: [
              { name: 'description', content: `Telemedicine is a convenient and affordable way to access healthcare services from the comfort of your own home. Learn more about what public telemedicine is and how it can benefit you and your loved ones.` },
              {name: 'title', content: 'Telemedicine for Everyone: Access Healthcare from Anywhere'},
              { name: 'twitter:card', content: 'summary' },
              { name: 'twitter:title', content: 'Telemedicine Software Solution | WellWink LLC'},
              { name: 'twitter:description', content: `Get started with WellWink's Telemedicine Software & join over 500,000 other healthcare professionals who love our easy to use, HIPAA compliant telemedicine solution.` },
              { name: 'twitter:image', content: 'https://www.wellwink.com/assets/square-logo.png' },
              { property: 'og:url', content: 'https://www.wellwink.com/assets/square-logo.png' },
              {property: 'og:title', content: 'Telemedicine Software Solution | WellWink LLC'}
            ]
          }
        }
      },
      {path: 'public-integration', component: PublicIntegrationComponent,
        data: {
          seo: {
            title: 'WellWink Integration: Connect Your Healthcare Data and Apps',
            metaTags: [
              { name: 'description', content: 'WellWink Integration makes it easy to connect your healthcare data and apps, so you can access your health information in one place and manage your health more effectively. Learn more about how to integrate WellWink with your favorite healthcare apps and devices.' },
              {name: 'title', content: 'WellWink Integration: Connect Your Healthcare Data and Apps'}
            ]
          } }
      },
      {path: 'public-patient-portal', component: PublicPatientPortalComponent,
        data: {
          seo: {
            title: 'WellWink Patient Portal: Manage Your Healthcare Online',
            metaTags: [
              { name: 'description', content: 'WellWink\'s patient portal gives you secure access to your medical records, appointment scheduling, and more. Manage your healthcare online with WellWink.' },
              {name: 'title', content: 'WellWink Patient Portal: Manage Your Healthcare Online'}
            ]
          }
        }
      },
      {path: 'public-reputation-management', component: PublicReputationManagementComponent,
        data: {
          seo: {
            title: 'WellWink Reputation Management: Boost Your Medical Practice\'s Online Presence',
            metaTags: [
              { name: 'description', content: 'WellWink\'s reputation management software helps medical practices collect and manage patient feedback, respond to online reviews, and improve their online visibility. Learn more.' },
              {name: 'title', content: 'WellWink Reputation Management: Boost Your Medical Practice\'s Online Presence'}
            ]
          }
        }
      },
      {path: 'public-patient-remote-monitoring', component: PublicPatientRemoteMonitoringComponent,
        data: {
          seo: {
            title: 'WellWink Remote Patient Monitoring: Manage Your Health from Home',
            metaTags: [
              { name: 'description', content: 'WellWink\'s remote patient monitoring software helps you track your health data from home and share it with your doctor. Learn more about how WellWink can help you manage your condition and stay healthy.' },
              {name: 'title', content: 'WellWink Remote Patient Monitoring: Manage Your Health from Home'}
            ]
          }
        }
      },
      {path: 'public-patient-reminders', component: PublicPatientRemindersComponent,
        data: {
          seo: {
            title: 'WellWink Patient Reminders: Reduce No-Shows and Improve Patient Care',
            metaTags: [
              { name: 'description', content: 'WellWink\'s patient reminder software helps healthcare providers reduce no-shows and improve patient care by sending automated reminders to patients via email, text message, and phone call. Learn more.' },
              {name: 'title', content: 'WellWink Patient Reminders: Reduce No-Shows and Improve Patient Care'}
            ]
          }
        }
      },
      {path: 'public-patient-communication', component: PublicPatientCommunicationComponent,
        data: {
          seo: {
            title: 'WellWink Patient Communication: Improve Communication with Your Patients',
            metaTags: [
              { name: 'description', content: 'WellWink\'s patient communication software helps healthcare providers improve communication with their patients. Learn more about how WellWink can help you send automated reminders, engage patients in shared decision-making, and provide personalized care.' },
              {name: 'title', content: 'WellWink Patient Communication: Improve Communication with Your Patients'}
            ]
          }
        }
      },
      {path: 'public-appointments-booking', component: PublicAppointmentsBookingComponent,
        data: {
          seo: {
               title: 'Book a Doctor Appointment Online with WellWink',
               metaTags: [
                  { name: 'description', content: 'WellWink is the easiest way to book a doctor appointment online. Find a doctor near you, compare availability, and book your appointment in seconds. Learn more.' },
                 {name: 'title', content: 'Book a Doctor Appointment Online with WellWink'}
               ]
              }
      }},
      {path: 'public-practice-marketing', component: PublicPracticeMarketingComponent,
        data: {
          seo: {
            title: 'WellWink Practice Marketing: Attract More Patients and Grow Your Medical Practice',
            metaTags: [
              { name: 'description', content: 'WellWink\'s practice marketing software helps medical practices attract more patients and grow their business. Learn more about how WellWink can help you improve your online visibility, generate leads, and convert patients.' },
              {name: 'title', content: 'WellWink Practice Marketing: Attract More Patients and Grow Your Medical Practice'}
            ]
          }
        }
      },
      {path: 'public-partner-program', component: PublicPartnerProgramComponent,
        data: {
          seo: {
            title: 'WellWink Partner Program: Grow Your Healthcare Business',
            metaTags: [
              { name: 'description', content: 'WellWink\'s partner program helps healthcare businesses grow by providing them with access to our cutting-edge patient engagement and care coordination software. Learn more and become a partner today.' },
              {name: 'title', content: 'WellWink Partner Program: Grow Your Healthcare Business'}
            ]
          }
        }
      },
      {path: 'public-chronic-care-management', component: PublicChronicCareManagementComponent,
        data: {
          seo: {
            title: 'WellWink Chronic Care Management: Improve Outcomes for Your Patients',
            metaTags: [
              { name: 'description', content: 'WellWink\'s chronic care management software helps healthcare providers improve outcomes for their patients with chronic conditions. Learn more about how WellWink can help you coordinate care, track progress, and prevent complications.' },
              {name: 'title', content: 'WellWink Chronic Care Management: Improve Outcomes for Your Patients'}
            ]
          }
        }
      },
      {path: 'public-remote-therapeutic-monitoring', component: PublicRemoteTherapeuticMonitoringComponent,
        data: {
          seo: {
            title: 'WellWink Remote Therapeutic Monitoring: The Future of Personalized Care',
            metaTags: [
              { name: 'description', content: 'WellWink\'s remote therapeutic monitoring software is the future of personalized care. Learn more about how WellWink can help you collect and track your health data, so you can work with your doctor to develop a personalized care plan that meets your needs.' },
              {name: 'title', content: 'WellWink Remote Therapeutic Monitoring: The Future of Personalized Care'}
            ]
          }
        }
      },
      {path: 'public-remote-patient-monitoring', component: PublicRemotePatientMonitoringComponent,
        data: {
          seo: {
            title: 'WellWink Patient Remote Monitoring: Manage Your Health from Home',
            metaTags: [
              { name: 'description', content: 'WellWink\'s patient remote monitoring software helps you track your health data from home and share it with your doctor. Learn more about how WellWink can help you manage your condition and stay healthy.' },
              {name: 'title', content: 'WellWink Patient Remote Monitoring: Manage Your Health from Home'}
            ]
          }
        }
      }
    ]
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})]
})
export class PublicContentPagesRoutingModule {
}
