import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {DocumentsService} from '../../../../services/document.service';
import {PatientDocument, Filter, DocumentType} from '../chart/document';
import {Patient} from '../../../doctor/patient';
import {Service} from '../../../../routes/practices/practices/practice';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {Appointment} from '../../../models/appointment';
import {AttachmentViewerService} from '../../../../services/attachment-viewer-service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit , OnChanges {
  @Input() patient: Patient;
  @Input() appointment: Appointment;
  public documents: PatientDocument[];
  public show_form = false;
  active_tab = 'timeline';
  public stage_ids: number[] = [0, 1];
  public selected_stage_id: number;
  public time_range = ['1 day ago', 'Week ago', 'Month ago', '6 months ago', '1 year ago'];
  public time_range_value = {'1 day ago': '1 day', 'Week ago': '1 week', 'Month ago': '1 month', '6 months ago': '6 months', '1 year ago': '1 year'};
  public menu_items: Object = {0: 'Timeline', 1: 'Preview'};
  public documentsType: DocumentType[] = [];
  public filter: Filter = new Filter();
  public services: Service[];
  public totalItems: any;

  constructor(private _documentService: DocumentsService,
              private loader_service: LoaderService,
              public attachment_viewer_service: AttachmentViewerService,
              private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.selected_stage_id = 0;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.filter.patient_id = this.patient.id
    this.filter_documents(false)
    this.fetchDocumentTypes();
    this.fetchDocumentServices();
  }
  ngOnInit() {

  }

  deleteDocument(document) {
    this.delete(document);
  }

  onSelect(stage_id: number) {
    this.selected_stage_id = stage_id;
  }
  delete(document) {
    this.loader_service.show();

    this._documentService.delete(document.id).then(response => {
        // this.documents.splice(this.documents.indexOf(document),1);
        this.filter_documents(true, true)
      }
    ).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
      this.loader_service.hide();
    } );
  }
  show_form_toggle(value) {
    this.show_form = value;
  }
  update_documents_array(document) {
    this.filter_documents(false, true);
    this.fetchDocumentServices();
    this.show_form = false;
  }
  selectTime(value) {
    if (value.value !== 'Time Range') {
      this.filter.time_range = this.time_range_value[value.value];
    }
    else {
      delete this.filter.time_range;
    }
  }
  selectType(value) {
    if (value.value !== 'Type') {
      this.filter.document_type_id = value.value;
    }
    else {
      delete this.filter.document_type_id;
    }
  }

  selectProblem(value) {
    if (value.value !== 'Problem') {
      this.filter.service_id = value.value;
    }
    else {
      delete this.filter.service_id;
    }
  }

  filter_documents(is_deleted_call, callLoader?) {
    if (this.totalItems % 10 === 1 && is_deleted_call) {
      this.filter.page = this.filter.page - 1;
    }
    if (!callLoader) {
      this.loader_service.show();
    }
    this._documentService.filter_documents(this.filter).then(json => {
      this.documents = [];
      this.documents = json.documents.map(document => {return new PatientDocument().load_from_json(document)});
      this.totalItems = json.total;
      this.filter.page = json.current_page;
      this.loader_service.hide();
    }).catch(response => {
      this.loader_service.hide();
    })
  }

  fetchDocumentTypes() {
    this.loader_service.show();
    this._documentService.getDocumentsType().then(resp => {
      let documentsType = resp.map(documentType => new DocumentType().load_from_json(documentType));
      this.documentsType = documentsType;
      this.loader_service.hide();
    }).catch(resp => this.loader_service.hide())
  }

  fetchDocumentServices() {
    this._documentService.getDocumentServices(this.patient.id).subscribe(a => {
      this.services = a.map(document => {return new Service().load_from_json(document.service)});
      this.services = this.services.filter((service, index, self) => self.findIndex(t => t.id === service.id) === index);
      this.services = this.services.filter(service => service.name !== '')

    });
  }

  public pageChanged(event: any): void {
    this.filter.page = event.page
    this.filter_documents(false);
  }

  sort(value: any) {
    this.filter.sort = value;
    this.filter.order = (this.filter.order === 'desc' ) ? 'asc' : 'desc'
    this.filter_documents(false);
  }

  timeline_active() {
    return this.active_tab === 'timeline'
  }


  goto_document(document) {
    if (this._documentService.pages.length !== 0) {
      let page_number = this._documentService.pages.findIndex(page => page.document_id === document.id);
      if (page_number >= 0) {
        this.active_tab = 'preview';
        this._documentService.goto_document(page_number);
      } else {
        this.loader_service.show();
        this._documentService.filter_documents(this.filter).then(resp => {
          this.goto_document(document);
          this.loader_service.hide();
        }).catch(err => {this.loader_service.hide()})
      }
    } else {
      this.globalErrorHandlerService.error = 'Document is not available.'
    }
  }

  preview_closed() {
    this._documentService.toggleView();
    this.active_tab = 'timeline'
  }

  openViewer(document) {
    if (document.pages) {
      this.attachment_viewer_service.viewLoadedAttachment(document.pages);
    }
  }

  preview_view_closed() {
    this.attachment_viewer_service.closeLoadedAttachment();
  }

}
