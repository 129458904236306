<section class="inner-public-banner sec-pad">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="text-container">
          <h1>Remote Patient Monitoring</h1>
          <h4 class="sub-title">Create and manage RPM programs and enhance your revenue bottom-line.</h4>
          <a class="btn btn-secondary btn-lg fs16 text-uppercase mt-xl" href="https://calendly.com/wellwink" target="_blank">Request A Demo</a>
        </div>
        <div class="row box-content">
          <div class="col-sm-4">
            <div class="border-box shadow p-lg text-center mb-lg">
              <img class="img-responsive" src="/assets/img/public/hcd-icon.png"/>
              <h4 class="m0 mb-lg">Home Care Devices</h4>
              <p class="m0">
                Cell-based and Bluetooth-connected device (e.g., scales, BG and BP monitors, etc.) allow patients to measure health metrics at the comforts of their homes.
              </p>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="border-box shadow p-lg text-center mb-lg">
              <img class="img-responsive" src="/assets/img/public/mai-icon.png"/>
              <h4 class="m0 mb-lg">Monitoring and Intervention</h4>
              <p class="m0">
                Automatic identification of critical and high values triggered by patient specific rules can effectively
                notify the care coordinator to take action and propose intervention appropriately.
              </p>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="border-box shadow p-lg text-center mb-lg">
              <img class="img-responsive" src="/assets/img/public/ufe-icon.png"/>
              <h4 class="m0 mb-lg">User Friendly Experience</h4>
              <p class="m0">
                WellWink offers a patient portal and mobile app, a simple, intuitive, and efficient system
                specifically designed for elderly patients, and a senior-friendly platform. It provides the best user interface.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <img class="img-responsive" src="/assets/img/public/RPM-hero-bg.jpg"/>
      </div>
    </div>
    <hr class="hr-line">
    <div class="row">
      <div class="col-sm-7"><img class="img-responsive" src="/assets/img/public/rpm-sec2-img.jpg"/></div>
      <div class="col-sm-5">
        <div class="text-container">
          <h3 class="m0 mb-lg">Since the healthcare industry is transforming daily,</h3>
          <h5 class="sub-title">Since the healthcare industry is transforming daily, we aim to provide the market’s
            most complete, <span class="text-primary">patient-centered,</span>
            and <span class="text-primary">holistically connected care platform.</span>
            Remote Patient Monitoring system allows doctors and medical teams to monitor multiple patients efficiently and get satisfactory control of
            their chronic conditions.
          </h5>
          <a class="btn btn-secondary btn-lg fs16 text-uppercase mt-xl" href="https://calendly.com/wellwink" target="_blank">Request A Demo</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sec-pad">
  <div class="container">
    <h5 class="text-center text-primary text-uppercase">Health Matrics</h5>
    <h1 class="fw-500 text-center">The Devices We Use in RPM Program</h1>
    <p class="mb-xl text-center">At WellWink, our vision is to improve patient health outcomes that ultimately reduce annual patient.</p>
  </div>
  <div class="container">
    <ul class="nav nav-tabs bg-white">
      <li>
        <a href="#oximeter" data-toggle="tab" class="active">
          <img class="img-responsive" src="/assets/img/public/tabs-icon1.png"/>
          Oximeter
        </a>
      </li>
      <li>
        <a href="#glucometer" data-toggle="tab">
          <img class="img-responsive" src="/assets/img/public/tabs-icon2.png"/>
          Glucometer
        </a>
      </li>
      <li>
        <a href="#bp" data-toggle="tab">
          <img class="img-responsive" src="/assets/img/public/tabs-icon3.png"/>
          Bp Apparatus
        </a>
      </li>
      <li>
        <a href="#thermometer" data-toggle="tab">
          <img class="img-responsive" src="/assets/img/public/tabs-icon4.png"/>
          Thermometer
        </a>
      </li>
      <li>
        <a href="#bodyscale" data-toggle="tab">
          <img class="img-responsive" src="/assets/img/public/tabs-icon5.png"/>
          Body Scale
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane fade active" id="oximeter">
        <div class="row">
          <div class="col-sm-7">
            <h3 class="m0 mb text-primary">Pulse Oximeter</h3>
            <p>Trusted by doctors and FDA-approved, the Pulse Oximeter gives you fast, accurate vital signs readings
              right from your smartphone or tablet. Measure, track, and share your blood oxygen saturation, pulse rate,
              and perfusion index right from your touchscreen.
            </p>
            <h5>Features</h5>
            <ul class="list-group">
              <li class="list-group-item">Wireless, lightweight, pulse oximeter, and easy to use the interactive app.</li>
              <li class="list-group-item">Save data on our secure cloud to track results and trends.</li>
              <li class="list-group-item">iOS and Android compatible.</li>
            </ul>
            <h5>Integration</h5>
            <ul class="list-group m0">
              <li class="list-group-item">Turn on the BlueTooth of your device.</li>
              <li class="list-group-item">Click the 'Find a Device' button to connect your device with an oximeter.</li>
              <li class="list-group-item">Place your Finger in an oximeter. After a while, SpO2 & Pulse rate readings will appear on the screen.</li>
            </ul>
          </div>
          <div class="col-sm-5">
            <img class="img-responsive" src="/assets/img/public/oximeter-min.png"/>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="glucometer">
        <div class="row">
          <div class="col-sm-7">
            <h3 class="m0 mb text-primary">Wireless Smart Gluco-Monitoring System</h3>
            <p>This lightweight,  portable glucometer works with the WellWink app to measure and record your readings
              automatically and lets you share them with your doctor or family members right from your touchscreen.
            </p>
            <h5>Features</h5>
            <ul class="list-group">
              <li class="list-group-item">Small, smart, and wireless.</li>
              <li class="list-group-item">Clinically tested.</li>
              <li class="list-group-item">Readings saved on the secure Wellwink cloud.</li>
              <li class="list-group-item">iOS and Android compatible.</li>
            </ul>
            <h5>Integration</h5>
            <ul class="list-group m0">
              <li class="list-group-item">Turn on the BlueTooth of your device.</li>
              <li class="list-group-item">Click the 'Find a Device' button to connect your device with the glucometer.</li>
              <li class="list-group-item">Prick your finger and place the blood sample on the test strip. As a result, the blood glucose readings will appear on the screen.</li>
            </ul>
          </div>
          <div class="col-sm-5">
            <img class="img-responsive" src="/assets/img/public/bgm-min.png"/>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="bp">
        <div class="row">
          <div class="col-sm-7">
            <h3 class="m0 mb text-primary">Blood Pressure Monitor</h3>
            <p>Take control of your health with accurate BP readings anytime, anywhere, right from your smartphone or tablet.
              Our technology measures, store, and track your blood pressure virtually and effortlessly.
            </p>
            <h5>Features</h5>
            <ul class="list-group">
              <li class="list-group-item">Wireless, lightweight, and award-winning design.</li>
              <li class="list-group-item">View and track results for accurate systolic/diastolic blood pressure and heart rate results.</li>
              <li class="list-group-item">iOS and Android compatible.</li>
            </ul>
            <h5>Integration</h5>
            <ul class="list-group m0">
              <li class="list-group-item">Turn on the BlueTooth of your device.</li>
              <li class="list-group-item">Click the 'Find a Device' button to connect your device with the glucometer.</li>
              <li class="list-group-item">Insert your arm into the cuff. Press the start button of the BP apparatus.
                The cuff will rapidly deflate, and your systolic, diastolic, and pulse readings will appear on the screen.
              </li>
            </ul>
          </div>
          <div class="col-sm-5">
            <img class="img-responsive" src="/assets/img/public/bp-apparatus-min.png"/>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="thermometer">
        <div class="row">
          <div class="col-sm-7">
            <h3 class="m0 mb text-primary">Thermometer</h3>
            <p>Take control of your health with the Wireless No-Touch Forehead Thermometer. It is necessary to be aware
              of each individual's normal temperature when they are good in health. This helps to accurately diagnose a fever.
            </p>
            <h5>Features</h5>
            <ul class="list-group">
              <li class="list-group-item">Measure, and share temperature results, and track your improvement over time.</li>
              <li class="list-group-item">Compatible with iOS and Android.</li>
            </ul>
            <h5>Integration</h5>
            <ul class="list-group m0">
              <li class="list-group-item">Turn on the BlueTooth of your device.</li>
              <li class="list-group-item">Click the 'Find a Device' button to connect your device with the glucometer.</li>
              <li class="list-group-item">Place the thermometer in front of your forehead. After a while,
                the temperature will appear on the screen.
              </li>
            </ul>
          </div>
          <div class="col-sm-5">
            <img class="img-responsive" src="/assets/img/public/thermometer-min.png"/>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="bodyscale">
        <div class="row">
          <div class="col-sm-7">
            <h3 class="m0 mb text-primary">Wireless Body Composition Scale</h3>
            <p>Take control of your health with the Wireless Scale. Measure, track, and share your Weight and BMI,
              Lean Mass, Body Fat, Muscle Mass, Bone Mass, Body Water using your smartphone or tablet
            </p>
            <h5>Features</h5>
            <ul class="list-group">
              <li class="list-group-item">Measure, track, and share Weight and BMI, Lean Mass, Body Fat, Muscle Mass, Bone Mass, and Body Water results.</li>
              <li class="list-group-item">Compatible with iOS and Android.</li>
            </ul>
            <h5>Integration</h5>
            <ul class="list-group m0">
              <li class="list-group-item">Turn on the BlueTooth of your device.</li>
              <li class="list-group-item">Click the 'Find a Device' button to connect your device with the glucometer.</li>
              <li class="list-group-item">Stand on the body scale. The calculated weight will appear on the screen.</li>
            </ul>
          </div>
          <div class="col-sm-5">
            <img class="img-responsive" src="/assets/img/public/scaling.png"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sec-pad rpm-work">
  <div class="container">
    <h5 class="text-center text-primary text-uppercase">RPM MANAGEMENT SYSTEM</h5>
    <h1 class="fw-500 text-center mb-xl">How Remote Patient Monitoring Works?</h1>
  </div>
  <div class="container">
    <img class="img-responsive diagram" src="/assets/img/public/RPM-DIAGRAM_1.png"/>
    <hr class="hr-line">
    <h1 class="fw-500 text-center">Multiple Views</h1>
    <p class="text-center">
      RPM Patients health monitoring is made easy by viewing health data coming from connected devices using calendar
      view, flowsheet view and chart view for timely management and interventions.
    </p>
    <div class="row">
      <div class="col-sm-6 mt-lg"><img class="border-box img-responsive p-lg" src="/assets/img/public/flowsheet-1.jpg"/></div>
      <div class="col-sm-6 mt-lg"><img class="border-box img-responsive p-lg" src="/assets/img/public/flowsheet-2.jpg"/></div>
      <div class="col-sm-6 mt-lg"><img class="border-box img-responsive p-lg" src="/assets/img/public/flowsheet-3.jpg"/></div>
    </div>
  </div>
</section>

<section class="sec-pad cpt-billing">
  <div class="container">
    <h1 class="fw-500 text-center mb-xl">CPT Billing Codes and Reimbursement for RPM</h1>
    <h4>Non-Complex CPTs</h4>
    <div class="row col-height-same">
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99453</h4>
          <ul class="list-group">
            <li class="list-group-item">Initial set-up and patient education on use of equipment.</li>
            <li class="list-group-item">Billable once at time of setup.</li>
          </ul>
          <span class="pull-right fw-500">RVU 0.55</span>
          <h2 class="text-primary m0 mb">$19.03</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99454</h4>
          <ul class="list-group">
            <li class="list-group-item">
              Device(s) supply with daily recording(s) or programmed alert(s) transmission, 16 or more days a month.
            </li>
            <li class="list-group-item">Billable once every 30 days.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.61</span>
          <h2 class="text-primary m0 mb">$55.72</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99457</h4>
          <ul class="list-group">
            <li class="list-group-item">
              Remote physiologic monitoring treatment management services, first 20 minutes or more of clinical
              staff/physician/other qualified healthcare professional time in a calendar month requiring interactive
              communication with the patient/caregiver during the month.
            </li>
            <li class="list-group-item">
              The 20-minutes for 99457 can include time for interactive communication as well as for furnishing care management services
            </li>
            <li class="list-group-item">Billable once per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.45</span>
          <h2 class="text-primary m0 mb">$50.18</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99458</h4>
          <ul class="list-group">
            <li class="list-group-item">
              Reported when an additional 20 minutes of interactive communication is provided to the patient/caregiver,
              and is billed in conjunction with CPT code 99457.
            </li>
            <li class="list-group-item">
              Code 99458 is for “each additional 20 minutes” there is no cap on the number of minutes that can be billed per patient per month for RPM.
            </li>
            <li class="list-group-item">Billable for every additional 20 minutes per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.18</span>
          <h2 class="text-primary m0 mb">$40.84</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad bg-secondary">
  <div class="container">
    <div class="row">
      <div class="col-sm-9">
        <h5 class="text-white m0">FROM MINIMAL TO 24/7 CARE</h5>
        <h2 class="text-white">Put Your Health in the Hands of a Caring Expert</h2>
        <h4 class="text-primary m0">Discover the best in chronic care management program</h4>
      </div>
      <div class="col-sm-3">
        <h5 class="text-white">Online Schedule</h5>
        <a class="btn btn-primary btn-lg btn-outline btn-block fs16 text-uppercase" href="https://calendly.com/wellwink" target="_blank">Request A Demo</a>
      </div>
    </div>
  </div>
</section>
