import { Component, OnInit } from '@angular/core';
import {Amenity} from "../amenity";
import {Tab} from "../../../../tabs/tab";
import {TabsService} from "../../../../services/tabs.service";
import {NewAmenityComponent} from "../new-amenity/new-amenity.component";
import {ViewAmenityComponent} from "../view-amenity/view-amenity.component";
import {LoaderService} from "../../../../services/loader.service";
import {GlobalErrorHandlerService} from "../../../../services/global-error.service";
import {AmenityService} from "../amenity.service";
import {Subscription} from "rxjs/Subscription";

@Component({
  selector: 'app-amenity-index',
  templateUrl: './amenity-index.component.html',
  styleUrls: ['./amenity-index.component.scss']
})
export class AmenityIndexComponent implements OnInit {
  public subscription: Subscription;
  public totalItems;
  public filter: Object = {name: undefined, page: 1};
  public amenity_list: Amenity[] = [];

  constructor(public tabs_service: TabsService,public loaderService: LoaderService, public globalErrorHandlerService : GlobalErrorHandlerService,
              public amenityService : AmenityService) {
    this.subscription = this.amenityService.reloadamenity.subscribe(item => {
      if (item) {
        this.amenityService.reloadamenity.next(0);
        this.getAmenitys();
      }
    });
  }

  ngOnInit(): void {
    this.getAmenitys();
  }

  addAmenity(){
    let amenity = new Amenity();
    this.tabs_service.add_tab(new Tab({title: 'Add Service', icon: 'fa'}, NewAmenityComponent, amenity.id,
      {id: amenity.id, amenity: amenity}))
  }
  search(){
    this.filter['page'] = 1;
    this.getAmenitys();
  }

  viewAmenity(amenity){
    this.tabs_service.add_tab(new Tab({title: amenity.name, icon: 'fa'}, ViewAmenityComponent, amenity.id,
      {id: amenity.id, amenity: amenity}))
  }
  amenityStatusChange(amenity){
      this.loaderService.show();
      this.amenityService.save(amenity)
        .then(resp => {
          amenity = amenity.load_from_json(resp)
          this.loaderService.hide();
        })
        .catch(resp => {
          this.loaderService.hide();
        })
  }
  pageChanged(page) {
    this.filter['page'] = page.page;
    this.getAmenitys();
  }

  getAmenitys() {
    this.loaderService.show();
    this.amenityService.getAmenities(this.filter).then(resp => {
      this.amenity_list = resp.amenities.map(amenity => new Amenity().load_from_json(amenity));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
    });
  }



}
