<div class="filters-sec flex-header p">
  <div class="row">
    <div class="col-xs-4">
      <input class="form-control" [(ngModel)]="filter['token']" (keyup.enter)="search()" placeholder="Provide Patient first name,last name, email or phone">
    </div>
    <div class="col-xs-2">
      <select [(ngModel)]="filter['patient_type']" class="form-control">
        <option value="0">Registration Type</option>
        <option value='online'>Online</option>
        <option value='walkin'>Walk In</option>
      </select>
    </div>
    <div class="col-xs-2">
      <select [(ngModel)]="filter['can_login']" class="form-control">
        <option value="0">Web Access</option>
        <option value='true'>Yes</option>
        <option value='false'>No</option>
      </select>
    </div>
    <div class="col-xs-2">
      <button type="button" class="btn btn-tertiary btn-outline" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
    </div>
    <div class="col-xs-2 pull-right text-right">
      <button type="button" class="btn btn-secondary btn-min" (click)="classicModal.show()"><i class="fa fa-plus"></i>&nbsp; Add</button>
    </div>
  </div>
</div>
<app-patient-listing class="flex-body scroll-browser" (page)="pageChanged($event)" (sender)="patientGetter($event)" [totalItems]='totalItems' [patients]="patients"></app-patient-listing>
<div class="modal modal_popup" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <app-add-new-patient (closeModel)="search(); classicModal.hide()" (new_user)="handle_user($event);classicModal.hide();"></app-add-new-patient>
</div>
