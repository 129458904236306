import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import * as braintree from 'braintree-web';
import {BraintreeService} from '../../services/braintree.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {LoaderService} from '../../services/loader.service';
import {User} from '../../routes/users/user';
import {environment} from '../../../environments/environment';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {PackagesService} from '../../services/packages.service';

@Component({
  selector: 'app-braintree-payment',
  templateUrl: './braintree-payment.component.html',
  styleUrls: ['./braintree-payment.component.scss']
})
export class BraintreePaymentComponent implements OnInit {
  @Output() paymentSuccessful = new EventEmitter<any>();
  @Output() paymentMethodUpdated = new EventEmitter<any>();
  @Input() update_card = false;
  @Input() package_type: string;
  @Input() pre_package_type: string;
  @Input() practice: any;
  @Input() token: any;
  @Input() addPaymentMethod = false;
  @Input() appointmentPayment = false;
  @Input() patient: User;
  @Input() payment = false;
  @Input() appointment_id;
  private stripe: Stripe;
  hostedFieldsInstance: braintree.HostedFields;
  cardholdersName: string;
  public paying = false;
  private elements:any;
  private paymentElement: any;
  private selected_package: any;
  constructor(private braintreeService: BraintreeService,
              private errorHandlerService: GlobalErrorHandlerService,
              private loaderService: LoaderService,
              private packagesService: PackagesService) {


  }

  ngOnInit() {
      this.initializeStripe();
  }

  initializeStripe() {
      loadStripe(environment.STRIPE_PUBLIC_KEY).then(stripe => {
        this.stripe = stripe
        this.elements = this.stripe.elements({
          mode: 'setup',
          currency: 'usd',
          paymentMethodCreation: 'manual',
          setupFutureUsage: 'off_session',
          paymentMethodTypes: ['card']
        });
        this.paymentElement = this.elements.create('payment',{
          fields: {
            billingDetails: {
              name: 'auto',
              email: 'auto',
              phone: 'auto'
            }
          }
        });
        this.paymentElement.mount('#payment-element');    })
    }


  signUpPayment(paymentMethodId) {
    this.paying = true;
      this.braintreeService.pay(paymentMethodId, this.customer(paymentMethodId), this.practice.id, this.package_type).then(resp => {
        this.paying = false;
        console.log(resp)
        if (resp['success']) {
           this.paymentSuccessful.emit(resp['success']);
        }
        else {
          this.errorHandlerService.error = 'Something went wrong.';
        }
      }).catch((resp) => {
        console.log(resp);
        this.paying = false;
        console.log(resp);
        this.errorHandlerService.error = resp.error.error;
        // perform custom validation here or log errors
      });
  }
  createPaymentMethod(paymentMethodId) {
    this.paying = true;
      this.braintreeService.braintree_customer(paymentMethodId, this.practice.id).then(resp => {
        this.paying = false;
        if (resp['status']) {
          this.practice.braintree_customer_id = resp['customer_id'];
        }
        console.log(resp);
        this.paymentMethodUpdated.emit(true);
        this.addPaymentMethod = false;
      });
  }
  updateCard(paymentMethodId) {
    this.paying = true;
      this.braintreeService.updateCard(paymentMethodId, this.practice.braintree_customer_id).then(resp => {
        this.paying = false;
        console.log(resp)
        this.paymentMethodUpdated.emit(resp['success']);
      }).catch((resp) => {
        console.log(resp);
        this.paying = false;
        console.log(resp);
        this.errorHandlerService.error = resp.error.error;
        // perform custom validation here or log errors
      });
  }
  makeAppointmentPayment(paymentMethodId) {
    this.paying = true;
      this.braintreeService.appointmentPayment(paymentMethodId, this.appointment_id).then(resp => {
        this.paying = false;
        console.log(resp);
        if (resp['success']) {
          this.paymentSuccessful.emit(resp['success']);
        }
        else {
          this.errorHandlerService.error = 'Something went wrong.';
        }
      }).catch((resp) => {
        console.log(resp);
        this.paying = false;
        console.log(resp);
        this.errorHandlerService.error = resp.error.error;
        // perform custom validation here or log errors
      });
  }

  appointmentCustomer() {
    return {
      name: this.patient.first_name + '_' + this.patient.last_name,
      email: this.patient.email,
      phone: this.patient.phone.value,
    }
  }
  invoiceBillingDetail() {
    return {
      name: this.practice.requester_firstname + '_' +  this.practice.request_lastname,
      email: this.practice.client.email,
      phone: this.practice.phone.value,
    }
  }
  customer(paymentMethodId) {
    return {
        name: this.practice.requester_firstname + '_' +  this.practice.request_lastname,
        email: this.practice.client.email,
        phone: this.practice.phone.value,
      payment_method: paymentMethodId,
    }
  }

  // Fetches the label element for the corresponding field
  findLabel(field: braintree.HostedFieldsHostedFieldsFieldData) {
    return document.querySelector('.hosted-field--label[for="' + field.container.id + '"]');
  }

  pay() {
    this.paymentSuccessful.emit(true);
  }

  belling_details() {
    let bd_obj = this.appointmentPayment ? this.appointmentCustomer() : this.invoiceBillingDetail();
    return bd_obj;
  }

  submit() {
    let elements = this.elements
    this.elements.submit().then(response => {
      console.log('validation successful', response);
      this.stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: this.belling_details()
        }
      }).then(response => {
        console.log('payment method response', response);
        if(this.payment) {
          this.signUpPayment(response.paymentMethod.id);
        }
        if(this.addPaymentMethod) {
          this.createPaymentMethod(response.paymentMethod.id);
        }
        if(this.update_card) {
          this.updateCard(response.paymentMethod.id);
        }
        if(this.appointmentPayment) {
          this.makeAppointmentPayment(response.paymentMethod.id);
        }

      },error => {
        console.log('payment method error', error)
      });

    }, error => {
      console.log('error', error);
    })
  }
}
