<span contenteditable="false" *ngIf="!term.is_open_text && !term.initial_component">
  <span class="pl" (click)="name_clicked()" [ngClass]="term.is_focus ? 'bg-gray' : '' "
        contenteditable="false">
    <span #term_name_span [tooltip]="term.control_value_class"
          [class]="term.control_value_class" style="cursor:pointer"
          [ngStyle]="{'text-decoration-line': term.term_type == 'keyword' ? 'underline' : ''}"
          [class.text-danger]="term.term_type == 'keyword' && term.check_keyword_total_count"
          [class.term]="term.isTermTypeChild()"
          [class.text-bold]="term.isComponentTypeChild()"
          [class.main-component-heading]="term.component_level == 2"
          [class.second-component-heading]="term.component_level == 3"
          [class.third-component-heading]="term.component_level == 4"
          [contextMenu]="thirdContextMenu"
          (focus)="handleTerm(term)" (blur)="blurTerm()" (keydown)="stopEditing($event)"
          (keydown.delete)="onBackSpaceDown($event, term)" (keydown.backspace)="onBackSpaceDown($event, term)"
          (click)="handleTerm(term);openModal(term,keyword_ref)" [ngClass]="'term-'+term.id"
          [attr.contenteditable]="contenteditable">{{term.name ? term.name : term.keyword.name.toUpperCase()}}</span>
  </span>

<ng-container *ngIf="term.isTermTypeChild()">
  <span>[</span>
  <ng-container *ngFor="let term_control of term.term_controls; let i=index">
    <ng-container *ngIf="!term_control._destroy">
      <app-control [term]="term" [term_control]="term_control" [control_index]="i"></app-control>,
    </ng-container>
  </ng-container>
  <ng-container>
    <ng-container *ngIf="term.show_control_typeahead">
      <app-controls-searchable [term]="term"></app-controls-searchable>
    </ng-container>
  </ng-container>
<span class="pointer" *ngIf="!term.show_control_typeahead" contenteditable="false"
      (click)="open_control_typeahead()">+</span>
<span>]</span>
</ng-container>
</span>

<ng-container *ngIf="term.hasChildren()">
  <ng-container *ngFor="let child_terms of term.children_groups;let i=index">
      <span [class.d-blockk]="i > 0 && child_terms.length > 0">
    <ng-container *ngFor="let child_term of child_terms">
    <app-term *ngIf="!child_term._destroy" [term]="child_term"></app-term>
  </ng-container>
           </span>
  </ng-container>
</ng-container>

<ng-container *ngIf="term.is_open_text">
  <span>
    <span contenteditable="false" class="p0">
      <span class="pl" style="min-height: 25px; min-width: 25px " [textContent]="term.open_text" contenteditable="true"
            (input)="term.open_text=$event.target.textContent"
            (keydown.enter)="addSubComponent($event)" #open_text_span (keydown)="key_down_call($event)"
            (focus)="handleSeperatorFocus()"
            (keydown.backspace)="onBackSpaceDownDelete($event, term)"></span>
    </span>
    <ng-container *ngIf="term.show_typeahead">
      <app-searchable [searchable]="searchable" [term]="term" (removeTypeAhead)="focusOpenText()"
                      (on_select)="handle_callback($event);term.show_typeahead = false"></app-searchable>
    </ng-container>
  </span>
</ng-container>

<ng-template #keyword_ref>
  <div class="modal-header">
    <button type="button" class="close" (click)="modalRef.hide()"><i class="fa fa-times fs16"></i></button>

    <h4 class="modal-title">{{term.keyword.name}}
        <span *ngIf="term.keyword.result_count > 0"  dropdown class="p-relative">
          <a class="btn btn-link pl-sm pt0 pb0" dropdownToggle (click)="getResutlData(term.keyword)" aria-controls="basic-link-dropdown">
            {{term.keyword.result_count}} terms <i class="fa fa-caret-down"></i>
          </a>
          <ul *dropdownMenu class="dropdown-menu list-unstyled border-list m0 scroll-browser">
            <li class="list" *ngFor="let result of term.keyword.result_data">{{result.name}}</li>
          </ul>
        </span>
      <span class="text-center p-sm fs13" *ngIf="term.keyword.result_count == 0"> 0 term</span>
    </h4>

  </div>

  <div class="modal-body keywordsModal-body">
    <div class="alert alert-danger mb-lg p pt-sm pb-sm fs12" *ngIf="total_term_count && +total_term_count == 0">
      No term found, please add more keywords
    </div>
    <div class="alert alert-success mb-lg p pt-sm pb-sm fs12" *ngIf="total_term_count && +total_term_count > 0">
      {{total_term_count}} terms are associated with the keyword.
    </div>

    <ng-container *ngFor="let child_keyword of term.keyword.child_keywords">
      <div class="row form-group">
        <div class="col-xs-6">
          <div class="input-group">
            <input #typeahead [(ngModel)]="child_keyword.name"
                   typeaheadWaitMs="800"
                   [typeahead]="child_keyword.data_source"
                   [typeaheadAsync]="true"
                   (typeaheadLoading)="changeTypeaheadLoading($event)"
                   autocomplete="off"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                   (typeaheadOnSelect)="typeaheadOnFirstSelect($event,child_keyword)"
                   [typeaheadOptionsLimit]="20"
                   [typeaheadMinLength]="1"
                   (focusout)="save(child_keyword,term.keyword.id)"
                   [typeaheadOptionField]="'sname'"
                   class="form-control">
            <span class="input-group-addon" *ngIf="child_keyword.save_in_progress">
              <i class="fa fa-spinner fa-spin"></i>
            </span>
            <span class="input-group-btn">
              <i class="btn btn-danger btn-outline fa fa-remove" (click)="deleteKeyword(child_keyword)"></i>
            </span>
          </div>
        </div>
        <div class="col-xs-3" *ngIf="child_keyword.id" >
          <div dropdown class="p-relative">
            <a *ngIf="child_keyword.result_count > 0" class="btn btn-primary btn-outline d-block" id="basic-link" dropdownToggle (click)="getResutlData(child_keyword)" aria-controls="basic-link-dropdown">
              {{child_keyword.result_count}} terms <i class="fa fa-caret-down pl-sm"></i>
            </a>
            <span class="d-block text-center p-sm fs13" *ngIf="child_keyword.result_count == 0"> 0 term</span>
            <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu list-unstyled border-list m0 scroll-browser">
              <li class="list" *ngFor="let result of child_keyword.result_data">{{result.name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <a class="btn btn-link pr0 pl0" (click)="setChildKeywords(term)"><i class="fa fa-plus"></i> Add more keywords</a>
  </div>

  <div class="modal-footer">
    <button (click)="cancel(keyword_ref)" class="btn btn-primary">Save</button>
    <button (click)="cancel(keyword_ref)" class="btn btn-inverse">Cancel</button>
  </div>
</ng-template>

<context-menu #thirdContextMenu>
  <ng-container>
    <ng-template contextMenuItem (execute)="deleteTerm($event)">
      Delete
    </ng-template>
  </ng-container>
</context-menu>
