import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {TabsService} from '../../../services/tabs.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {UserService} from '../../../services/user.service';
import {AlertService} from '../../../_alert';
import {RichTextTemplate} from '../rich-text-template';
import {RichTextTemplateService} from '../rich-text-template.service';
import {environment} from '../../../../environments/environment';
import {AttachmentService} from '../../../services/attachments.service';
import {default as tinymce} from 'tinymce';

@Component({
  selector: 'app-rich-text-template',
  templateUrl: './rich-text-template.component.html',
  styleUrls: ['./rich-text-template.component.scss']
})
export class RichTextTemplateComponent implements OnInit {
  public richTextTemplate: RichTextTemplate = new RichTextTemplate();
  public show_attachment_popup = false;
  public image_url: any;
  public editor: any;
  @ViewChild('s_body') s_body: ElementRef;
  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 300,
    relative_urls : false,
    remove_script_host : false,
    convert_urls : true,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
      'insertfile undo redo numlist outdent | bullist indent |contextmenu table | uploadimage media | \ ' +
      'link image forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.editor = editor;
    },

    images_upload_handler:
      (data, success, failure) => {
        if (data && data.hasOwnProperty('error') == false) {
          this.loaderService.small_loder_show();
          let attachment = {  attachable_type: 'TextTemplate',
            fileType: data.blob().type, file: data.blob(), description: data.filename()};
          let callback = (p: any): void => {
            console.log(p);
          };

          let upload_callback = (data: any) => {
            data = JSON.parse(data);
            if (data !== '') {
              this.loaderService.small_loder_hide();
              this.image_url =  data.url;
              this.richTextTemplate.attachable_ids.push(data.id);
              success(data.url);
            }
          };
          if (attachment.file === undefined) {
            this.globalErrorHandlerService.error = 'Please select a valid file.';
          }
          else {
            let latest_picture = this.attachmentService.upload(attachment, callback, upload_callback);
            console.log(latest_picture);
          }
        }
      },
  }
  constructor(public injector: Injector, public loaderService: LoaderService, public tabs_service: TabsService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public userService: UserService,
              public alertService: AlertService, public richTextTemplateService: RichTextTemplateService,
              private attachmentService: AttachmentService) { }

  ngOnInit(): void {
    this.richTextTemplate = this.injector.get('richTextTemplate', this.richTextTemplate);
    if (this.richTextTemplate.is_new_record()) {
      this.setNewObjectFields();
    }
  }

  closeForm() {
    if (this.tabs_service.active_tab().component === RichTextTemplateComponent) {
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }
  }
  closeTab() {
    this.loaderService.hide();
    this.richTextTemplateService.reload_rich_text_template_list.next(1);
    this.tabs_service.close_tab(this.tabs_service.active_tab());
  }
  save() {
    this.loaderService.show();
    this.richTextTemplate.last_modifier_id = this.userService.current_user.id;
    this.richTextTemplateService.save(this.richTextTemplate)
      .then(resp => {
        this.richTextTemplate = this.richTextTemplate.load_from_json(resp);
        this.closeTab();
        this.alertService.success('Rich Text Template has been saved successfully', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.hide();
        console.log(resp)
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }



  insertAtCursor(myValue) {
    this.richTextTemplate.text_type === 'text_message' ?  this.insertAtCursorSmS(myValue) : this.insertAtEditorCursor(myValue);

  }

  private setNewObjectFields() {
    if (!this.richTextTemplate.is_import) {
      this.richTextTemplate.author_id = this.userService.current_user.id;
    }
    this.richTextTemplate.owner_id = this.userService.current_user.id;
    this.richTextTemplate.status = this.userService.current_user.isEnterprise ? 'public_template' : 'private_template';
    this.richTextTemplate.is_enterprise = this.userService.current_user.isEnterprise ? true : false;

  }

  readOnlyForm(): boolean {
    return this.userService.current_user.isEnterprise && this.richTextTemplate.status === 'private_template'
  }

  makeCopy(richTextTemplate: RichTextTemplate) {
    richTextTemplate.id = undefined;
    richTextTemplate.status = 'public_template';
    richTextTemplate.is_enterprise = true;
    richTextTemplate.name = richTextTemplate.name + '- Copy';
    richTextTemplate.owner_id = this.userService.current_user.id;
    this.save();
  }

  deleteTemplate(id: number) {
    this.loaderService.show();
    this.richTextTemplateService.delete(id).then(resp => {
      this.closeTab();
      this.alertService.success('Rich Text Template has been deleted successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }



  insertAtCursorSmS(myValue) {
    if (myValue === '[[[Tags]]]') {
      return;
    }
    let txtarea = this.s_body.nativeElement;
    let scrollPos = txtarea.scrollTop;
    let strPos = 0;
    let br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
      'ff' : (this.s_body.nativeElement.selection ? 'ie' : false ) );
    if (br === 'ie') {
      txtarea.focus();
      let range = this.s_body.nativeElement.selection.createRange();
      range.moveStart ('character', -txtarea.value.length);
      strPos = range.text.length;
    } else if (br === 'ff') {
      strPos = txtarea.selectionStart;
    }

    let front = (txtarea.value).substring(0, strPos);
    let back = (txtarea.value).substring(strPos, txtarea.value.length);
    txtarea.value = front + myValue + back;
    strPos = strPos + myValue.length;
    if (br === 'ie') {
      txtarea.focus();
      let ieRange = this.s_body.nativeElement.selection.createRange();
      ieRange.moveStart ('character', -txtarea.value.length);
      ieRange.moveStart ('character', strPos);
      ieRange.moveEnd ('character', 0);
      ieRange.select();
    } else if (br === 'ff') {
      txtarea.selectionStart = strPos;
      txtarea.selectionEnd = strPos;
      txtarea.focus();
    }
    txtarea.scrollTop = scrollPos;
  }

  insertAtEditorCursor(myValue) {
    if (myValue === '[[[Tags]]]') {
      return;
    }
    this.editor = tinymce.get('template_body');
    this.editor.insertContent(myValue);
  }

  changeTypeCall() {
    if (this.richTextTemplate.text_type === 'text_message') {
      this.richTextTemplate.body = '';
    }
  }

  showDeleteButton(template: RichTextTemplate) {
    return (!template.is_new_record() && (template.owner_id === this.userService.current_user.id))
  }
}
