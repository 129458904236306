import {Component, OnDestroy, OnInit} from '@angular/core';
import {TabsService} from '../../services/tabs.service';
import {Tab} from '../../tabs/tab';
import {EnrolledPatientComponent} from '../enrolled-patient/enrolled-patient.component';
import {ProgramEnrollment} from '../eligible-patient';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, OnDestroy {

  constructor(public tabs_service: TabsService, private _tabs_service: TabsService) {
    this.tabs_service.register('Program');
  }

  ngOnInit(): void {
    this.restartActiveTabTime();
  }

  ngOnDestroy() {
    let tabs: Tab[] = this.tabs_service.tabs;
    tabs?.map((tab: Tab) => {
     if (tab.dependencies['enrolment']) {
       let enrolment: ProgramEnrollment = tab.dependencies['enrolment'];
       enrolment.timer.unsubscribe();
     }
    });
  }

  restartActiveTabTime() {
    let tabs: Tab[] = this.tabs_service.tabs;
    tabs?.map((tab: Tab) => {
      if (tab.dependencies['enrolment']) {
        let enrolment: ProgramEnrollment = tab.dependencies['enrolment'];
        enrolment.startTimer();
      }
    });
  }
}
