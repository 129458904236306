import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentType} from '../chart/document';
import {Service} from '../../../../routes/practices/practices/practice';
import {LoaderService} from '../../../../services/loader.service';
import {DocumentsService} from '../../../../services/document.service';
import {Patient} from '../../../doctor/patient';
import {UserService} from '../../../../services/user.service';
import {EnoteService} from '../../../../services/enote.service';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {Tab} from '../../../../tabs/tab';
import {PatientNoteComponent} from '../../../../enote/patient-note/patient-note.component';
import {TabsService} from '../../../../services/tabs.service';
import {AlertService} from '../../../../_alert';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {RichTextTemplateService} from "../../../../routes/rich-text-template-index/rich-text-template.service";

@Component({
  selector: 'app-patient-provider-enotes',
  templateUrl: './patient-provider-enotes.component.html',
  styleUrls: ['./patient-provider-enotes.component.scss']
})
export class PatientProviderEnotesComponent implements OnInit, OnChanges {
  public documentsType: DocumentType[] = [];
  public services: Service[];
  @Input() patient: Patient;
  public filter: Object = {range: '', page: '1', type: '', problem: '', status: 'all', patient_id: '', practice_id: ''};
  public durations: Array<string> = ['last_1_day', 'last_week', 'last_month', 'last_6_months', 'last_year'];
  public enoteStatus: Array<string> = ['draft', 'signed'];
  public totalItems: any;
  public patientEnotes: any[] = [];
  selectedEnoteToDelete: any;

  constructor(public loaderService: LoaderService, public documentService: DocumentsService, public userService: UserService,
              public enoteService: EnoteService, public globalErrorHandlerService: GlobalErrorHandlerService, public tabsService: TabsService,
              public alertService: AlertService, private richTextTemplateService: RichTextTemplateService) { }

  ngOnInit(): void {
  }

  fetchDocumentTypes() {
    this.documentService.getDocumentsType().then(resp => {
      this.documentsType = resp.map(documentType => new DocumentType().load_from_json(documentType));
    }).catch(resp => this.loaderService.hide())
  }

  fetchDocumentServices() {
    this.documentService.getDocumentServices(this.patient.id).subscribe(a => {
      this.services = a.map(document => {return new Service().load_from_json(document.service)});
      this.services = this.services.filter((service, index, self) => self.findIndex(t => t.id === service.id) === index);
      this.services = this.services.filter(service => service.name !== '')

    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    /* this.fetchDocumentTypes();
     this.fetchDocumentServices();*/
    this.filter['patient_id'] = this.patient.id;
    this.filter['practice_id'] = this.userService.current_user.practices_id[0];
    this.getPatientAssignedTemplate();
  }

  getEnote() {

  }

  createEnote() {
    delete this.enoteService.template
    this.add_enote_to_tabs('Create New', 'create_enote', 'Patient', this.patient.id);
  }

  selectDuration(time: any) {
    this.filter['date_type'] = time.value;
  }

  search() {
    this.filter['page'] = 1;
    this.getPatientAssignedTemplate();
    console.log('filter ', this.filter)
  }

  pageChanged($event: PageChangedEvent) {
    this.filter['page'] = $event.page;
    this.getPatientAssignedTemplate();
  }

  getPatientAssignedTemplate() {
    this.loaderService.show();
    this.filter['patient_id'] = this.patient.id;
    this.enoteService.getPatientAssignedTemplate(this.filter).then(resp => {
      this.patientEnotes = resp.patientEnotes;
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }


  openEnote(value) {
    let enote = this.patientEnotes.filter(e => e.id === value)[0];
    this.add_enote_to_tabs(enote.name, enote.is_rich_text ? enote.status : enote.stage,
      enote.is_rich_text ? enote.sourceable_type : enote.source_type ,
      enote.is_rich_text ? enote.sourceable_id : enote.source_id, enote.id ,
      enote.is_rich_text, enote.is_rich_text ? enote : '')
  }

  add_enote_to_tabs(title, stage, source_type?, source_id?, note_id?, is_rich_text?, rich_text_template?) {
    let patient_id = this.patient.id + title;
    this.tabsService.add_tab(new Tab({title: title, icon: 'fa fa-hospital', autorefresh: false},
      PatientNoteComponent, patient_id, {
        patientID: this.patient.id, stage: stage, is_rich_text: is_rich_text, source_type: source_type, source_id: source_id,
        rich_text_template: rich_text_template, viewRichText: true, showVideo: true, note_id: note_id, isProgramOrPatientProfile: true}))
  }

  delete_draft() {
    this.loaderService.show();
    this.selectedEnoteToDelete.is_rich_text ? this.deleteRichText(this.selectedEnoteToDelete.id) : this.deleteEnote();
  }

  deleteEnote() {
    this.enoteService.template = this.selectedEnoteToDelete
    this.enoteService.delete_drafted_enote().then(resp => {
      this.afterSuccessfullyDelete();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  afterSuccessfullyDelete() {
    this.search();
    this.loaderService.hide();
    this.alertService.success('Deleted successfully', this.alertService.options);
  }

  private deleteRichText(id) {
    this.richTextTemplateService.delete(id).then(resp => {
      this.afterSuccessfullyDelete();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  showDeleteButton(enote: any): boolean {
    return enote.is_rich_text ? (enote.status === 'drafted' ? true : false) : (enote.stage === 'drafted_enote' ? true : false);
  }
}
