import { Injectable } from '@angular/core';
import {PatientReview} from './patient-review';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PatientReviewService {

  constructor(public _tokenService: CustomHttpClient) { }

  getPracticeSetup(practice_id: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('practice_id', practice_id);
    return this._tokenService
      .get('patient_reviews/practice_review_setup', {params: args})
      .toPromise()
      .then(res => res);
  }

  create(patient_review: PatientReview): Promise<any> {
    return this._tokenService
      .post('patient_reviews', patient_review.to_params())
      .toPromise()
      .then(res => patient_review.load_from_json(res))
  }

  request_review(appointment_id: string): Promise<any> {
    return this._tokenService
      .put('patient_reviews/request_review/' + appointment_id, {})
      .toPromise()
  }

  save(patient_review: PatientReview): Promise<any> {
    // return this.create(practice_review);
    if (patient_review.is_new_record()) {

      return this.create(patient_review);
    }
    else {
      return this.update(patient_review);
    }
  }

  getReview(patient_review_id: any): Promise<any> {
    let args = new HttpParams();
    args =  args.set('patient_review_id', patient_review_id);
    return this._tokenService
      .get('patient_reviews', { params: args})
      .toPromise()
      .then(res => res);
  }

  update(patient_review: PatientReview) {
    return this._tokenService
      .put('patient_reviews/' + patient_review.id, patient_review.to_params())
      .toPromise()
      .then(response => response)
  }

  requestPublicReview(patient_review: PatientReview) {
    return this._tokenService
      .put('patient_reviews/request_public_review/' + patient_review.id, {})
      .toPromise()
      .then(response => response)
  }
  updateStatus(patient_review: PatientReview) {
    return this._tokenService
      .put('patient_reviews/update_status/' + patient_review.id, {})
      .toPromise()
      .then(response => response)
  }

  appointmentSecondReview(id: any) {
    let args = new HttpParams();
    args =  args.set('patient_review_id', id);
    return this._tokenService
      .get('patient_reviews/appointment_second_review', { params: args})
      .toPromise()
      .then(res => res);
  }



}
