<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Patient Reminders</h1>
      <h4 class="sub-title fs22 text-primary">Inform patients in a timely manner and make wise decisions.</h4>
      <h4 class="sub-title text-white">
        Provide consistent, productive interactions that create satisfaction with your clients, reinforcing the authenticity of your brand.
      </h4>
      <h4 class="sub-title text-white">
        Enable support staff to focus resources on work that drives revenue, rather than on nominal tasks that eat up valuable time.
      </h4>
    </div>
  </div>
  <video autoplay muted loop><source src="/assets/img/public/mobile-video.mp4" type="video/mp4"></video>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <div class="white-container-box p-xl">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h1 class="mt0">Appointment Reminders</h1>
          <p>
            Nowadays everyone is busy and almost forget important things unless placed a reminder. With automated reminders
            minimize no-shows and cancellation by 90% and save time with automation.  One single no-show a day can cost
            a practice heavily.
            <br><br>
            For each scheduled appointment, Wellwink sends strategically timed automated appointment reminders by email
            or text message or via instant messenger. Stop manually confirming appointments and start enjoying an all-new
            efficiency for your practice.
          </p>
        </div>
        <div class="col-sm-6 col-xs-12">
          <img class="img-responsive" src="/assets/img/public/reminders.jpg"/>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <div class="white-container-box p-xl">
      <div class="row">
        <div class="col-sm-6 col-xs-12 pull-right">
          <h1 class="mt0">Patient Recalls</h1>
          <p>
            Recalling a patient is an important part of the healthcare system and most often required for a routine or
            follow-up after a certain period of time.  There are a number of reasons provider may recall a patient.
            <br><br>
            Patient recall rules can be configure by reason of visit and type of appointment giving leverage to practice
            administrator to setup recall reminders.
          </p>
        </div>
        <div class="col-sm-6 col-xs-12">
          <img class="img-responsive" src="/assets/img/public/patient-recall.jpg"/>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <div class="white-container-box p-xl">
      <div class="row">
        <div class="col-sm-5 col-xs-12">
          <h1 class="mt0">Preventive Care Alerts</h1>
          <p>
            Having a timely checkup on your health is highly recommended to avoid a serious illness. Preventive Care
            rules can be designed to deliver effective and efficient reminders in a timely manner.
            <br><br>
            Preventive care is important because it helps patients stay healthy and access prompt treatment when necessary,
            and it can also help reduce your overall medical expenses. Stay healthier and get more effective
            treatment – Many types of screenings and tests can catch a disease before it starts.
          </p>
        </div>
        <div class="col-sm-7 col-xs-12">
          <ul class="list-group list-unstyled m0 preventive-points">
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              Blood pressure, diabetes, and cholesterol tests
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              Many cancer screenings, including mammograms and colonoscopies
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              Counseling on such topics as quitting smoking, losing weight, eating healthfully, treating depression, and reducing alcohol use
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              Regular well-baby and well-child visits, from birth to age 21
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              Routine vaccinations against diseases such as measles, polio, or meningitis
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              Counseling, screening, and vaccines to ensure healthy pregnancies
            </li>
            <li class="list-group-item">
              <i class="fa fa-chevron-circle-right fa-fw fs16"></i>
              Flu and pneumonia shot
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
