import { Component, OnInit, OnChanges } from '@angular/core';
import {EmergencyService} from '../../../../services/emergency.service';
import {TabsService} from '../../../../services/tabs.service';
import {Observable, Subscription} from 'rxjs';
import {Appointment} from './../../../../health/models/appointment';
import { ArriveComponent } from '../arrive/arrive.component'
import {Tab} from '../../../../tabs/tab';
import { IMultiSelectOption , IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-emergency-filters',
  templateUrl: './emergency-filters.component.html',
  styleUrls: ['./emergency-filters.component.scss']
})
export class EmergencyFiltersComponent implements OnInit {

  public optionsModel: string[];
  public myOptions: IMultiSelectOption[];
  bsValue = new Date();
  // Type-Ahead
  public typeaheadLoading = false;
  public typeaheadLoadingPractice = false;
  public asyncSelectedDoctor = '';
  public asyncSelectedPractice = '';
  public appointment: Appointment = new Appointment();
  public display_orders: string[] = ['Ascending', 'Decending'];
  public display_orders_mapping = {'Ascending': 'created_at+asc', 'Decending': 'created_at+desc'};
  public order_by = 'created_at asc';
  public date2DisabledDates;

  // Settings configuration
  selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false
  };

  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Choose Status',
    allSelected: 'All selected',
  };
  constructor(public emergency_service: EmergencyService, private tab_service: TabsService) {
    this.initializePractices();
    this.initializeDoctors();
  }

  ngOnInit() {
    this.optionsModel = this.emergency_service.status_id.length > 0 ? this.emergency_service.status_id.split(',') : ['checkedin'];
    this.myOptions = [
      { id: 'cancelled', name: 'cancelled' },
      { id: 'checkedin', name: 'checkedin' },
      { id: 'checkedout', name: 'checkedout' },
      { id: 'admitted', name: 'admitted' }
    ];
    this.performSearch();
  }

  ngOnChanges() {
    this.optionsModel = this.emergency_service.status_id.length > 0 ? this.emergency_service.status_id.split(',') : ['checkedin'];
    this.performSearch();
  }

  initializePractices() {
    this.emergency_service.practices = Observable.create((observer: any) => {
      observer.next(this.asyncSelectedPractice);
    }).pipe(mergeMap((token: string) => this.emergency_service.getPractices(token)));
  }

  initializeDoctors() {
    this.emergency_service.doctors = Observable.create((observer: any) => {
      observer.next(this.asyncSelectedDoctor);
    }).pipe(mergeMap((token: string) => this.emergency_service.getDoctors(token, this.emergency_service.practice_id)));
  }

  selectStatuses(event) {
    this.emergency_service.status_id = this.optionsModel.sort().join(',');
  }

  performSearch() {
    this.emergency_service.applyFilters();
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadLoadingPractice(e: boolean): void {
    this.typeaheadLoadingPractice = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.emergency_service.resetDoctor();
  }

  changeTypeaheadNoResultsPractice(e: boolean): void {
    this.emergency_service.resetPractice();
    this.emergency_service.resetLocation();
  }

  typeaheadOnSelect(e: any): void {
    this.emergency_service.doctor_name = e.value;
    this.emergency_service.doctor_id = e.item.id;
  }

  typeaheadOnSelectPractice(e: any): void {
    this.emergency_service.practice_name = e.value;
    this.emergency_service.practice_id = e.item.id;
    this.emergency_service.updateLocations(this.emergency_service.practice_id).then(visits => {
      console.log('locations updated sucessfully');
    });
  }

  add_to_tabs(title) {
    if (this.appointment.id) {
      this.appointment = new Appointment();
    }
    this.tab_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital', autorefresh: false}, ArriveComponent, title, {flow_type: title, appointment: new Appointment(), locations: this.emergency_service.practiceLocations}))
  }

}
