import {Injectable, ChangeDetectorRef} from '@angular/core';

@Injectable()
export class SlotDatesService {
  public hoveredDate: Date;
  public  dates = [new Date(), new Date(Date.now() + 86400000), new Date(Date.now() + 2 * 86400000)];
  constructor() { }


  is_today() {
    return this.to_ymd(this.selected_date) ==  this.to_ymd(new Date(Date.now() + 86400000))
  }

  get selected_date() {
    return this.dates[1];
  }
  get nextDay() {
    return this.dates[2];
  }

  get previousDay() {
    return this.dates[0];
  }

  set nextDay(date) {
    this.dates[2] = date;
  }
  set selected_date(date) {
    this.dates[1] = date;
  }
  set previousDay(date) {
    this.dates[0] = date;
  }


  got2Today() {
    if (!this.is_today()) {
      this.setSelectedDate(new Date(Date.now() + 86400000));
    }
  }
  to_ymd(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }


  setSelectedDate(date) {
    this.selected_date = date;
    this.previousDay = new Date(this.selected_date);
    this.previousDay.setDate(this.selected_date.getDate() - 1);
    this.nextDay = new Date(this.selected_date);
    this.nextDay.setDate(this.selected_date.getDate() + 1);
  }


}
