<h3 class="m0">Profile</h3>
<hr class="hr-line mt">
<div *ngIf="practice" class="white-container-box pt-lg pl-xl pr-xl mb-lg">
  <form (ngSubmit)="save()" #practiceForm="ngForm" class="form-horizontal">
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm">Practice*:</label>
      <div class="col-sm-9">
        <input (click)="callme();" type="text" class="form-control" id="name" [(ngModel)]="practice.name"
               name="name" required
               placeholder="Name">
        <div *ngIf="customValidations.formErrors.name" style="color: red">
          {{ customValidations.formErrors.name }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm">Practice Type:</label>
      <div class="col-sm-9">
        <select class="form-control" name="practice_lead_type" [(ngModel)]="practice.practice_lead_type">
          <option value='human' selected >Human</option>
          <option value='vet'>Vet</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm">Address 1*:</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="line_1" [(ngModel)]="practice.client.address.line_1"
              name="line_1" required
               placeholder="Line 1">
        <div *ngIf="customValidations.formErrors.line_1" style="color: red">
          {{ customValidations.formErrors.line_1 }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm">Address 2:</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="line_2" [(ngModel)]="practice.client.address.line_2"
               [ngModelOptions]="{standalone: true}"
               placeholder="Line 2">

      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">City* :</label>
      <div class="col-sm-3">
        <input type="text" class="form-control" id="city" [(ngModel)]="practice.client.address.city_area.city.name"
               name="city" required
               placeholder="City">
        <div *ngIf="customValidations.formErrors.city" style="color: red">
          {{ customValidations.formErrors.city }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm text-right">State* :</label>
      <div class="col-sm-2 pr0">
        <input type="text" class="form-control" id="state" [(ngModel)]="practice.client.address.city_area.city.state"
              name="state" required
               placeholder="state">
        <div *ngIf="customValidations.formErrors.state" style="color: red">
          {{ customValidations.formErrors.state }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm text-right">Zip* :</label>
      <div class="col-sm-2">
        <input type="number" class="form-control" id="zip" [(ngModel)]="practice.client.address.postal_code"
               name="zip" required
               placeholder="zip">
        <div *ngIf="customValidations.formErrors.zip " style="color: red">
          {{ customValidations.formErrors.zip }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Geo Location*:</label>
      <div class="col-sm-9">
        <input id="practice_city_area_name" #search  placeholder="search for Area" autocorrect="off" autocapitalize="off"
               [(ngModel)]="practice.client.address.city_area.name" [ngModelOptions]="{standalone: true}"
               spellcheck="off" type="text" class="form-control"  required>
        <div *ngIf="customValidations.formErrors.area" style="color: red">
          {{ customValidations.formErrors.area }}
        </div>
        <input type="hidden" placeholder="search for Area" autocorrect="off" autocapitalize="off" id="olat"
               spellcheck="off" class="form-control" [(ngModel)]="practice.client.address.city_area.lat"
               [ngModelOptions]="{standalone: true}">
        <input type="hidden" placeholder="search for Area" autocorrect="off" autocapitalize="off" id="olong"
               spellcheck="off" class="form-control" [(ngModel)]="practice.client.address.city_area.long"
               [ngModelOptions]="{standalone: true}">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Phone* :</label>
      <div class="col-sm-4" *ngIf="practice.phone">
        <input  mask="(000) 000-0000" type="tel" class="form-control" id="phone" [(ngModel)]="practice.phone.value"
               name="phone" required
               placeholder="Phone">
        <div *ngIf="customValidations.formErrors.phone" style="color: red">
          {{ customValidations.formErrors.phone }}
        </div>

      </div>
      <label class="col-sm-1 p0 pt-sm ">Fax :</label>
      <div class="col-sm-4" *ngIf="practice.fax">
        <input type="text" class="form-control" id="fax" [(ngModel)]="practice.fax.value" name="fax" pattern="[0-9]+"
               placeholder="Fax">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Email* :</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="email" [(ngModel)]="practice.client.email"
               name="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" required
               placeholder="Email">
        <div *ngIf="customValidations.formErrors.email" style="color: red">
          {{ customValidations.formErrors.email }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Url :</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="url" [(ngModel)]="practice.url"
               [ngModelOptions]="{standalone: true}"
               placeholder="Url">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Client ID :</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="client_id" [(ngModel)]="practice.practice_client_id"
               [ngModelOptions]="{standalone: true}"
               placeholder="Third Party Client ID.">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1  p0 pt-sm">Tagline* :</label>
      <div class="col-sm-11 pr-xl">
        <input type="text" class="form-control" id="tagline" [(ngModel)]="practice.tagline"
              name="tagline" required
               placeholder="Tagline">
        <div *ngIf="customValidations.formErrors.tagline" style="color: red">
          {{ customValidations.formErrors.tagline }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1  p0 pt-sm">Welcome Message :</label>
      <div class="col-sm-11 pr-xl">
        <editor #editor apiKey="no-api-key" id="mesage" name="mesage_body" [(ngModel)]="practice.message"
                [init]="init_tiny">
        </editor>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1  p0 pt-sm">Email Disclaimer :</label>
      <div class="col-sm-11 pr-xl">
            <textarea rows="12" cols="50" type="text" class="form-control" id="disclaimer" [(ngModel)]="practice.email_disclaimer"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="Email Disclaimer"></textarea>
      </div>
    </div>
    <div class="form-group p-relative">
      <div *ngFor="let specialty of get_specialties(); let i=index">
        <label [class.clearfix]="i%2 == 0" class="col-sm-1  p0 pt-sm">Specialty* :</label>
        <div class="col-sm-4 pr0 pb-sm">
          <input   name="specialty_{{i}}" [required]="i==0 && get_specialties().length < 2" [(ngModel)]="specialty.description"
                 (keyup)="set_asyncSelected($event,specialty)" [typeahead]="specialties" typeaheadWaitMs="500"
                 (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadAsync]="true"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event,specialty)" [typeaheadOptionsLimit]="7"
                 [typeaheadOptionField]="'description'" placeholder="Specialty"
                 class="form-control" autocomplete="off" [multiple]="true">
          <div *ngIf="customValidations.formErrors.specialty_0 && get_specialties().length < 2 " style="color: red">
            {{ customValidations.formErrors.specialty_0 }}
          </div>
        </div>
        <a class="col-sm-1 pt-sm pl-xl" *ngIf="get_specialties().length > 1" (click)="removeSpecialty(specialty)" ><i class="fa fa-trash-o text-danger"></i></a>
        <ng-container *ngIf="(i + 1  == get_specialties().length) && !customValidations.formErrors.specialty_0">
          <a class="col-sm-1 pt-sm pl-xl" (click)="addSpecialty()"><i class="fa fa-plus"></i></a>
        </ng-container>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Appoitment Email:</label>
      <div class="col-md-2 col-sm-9 col-xs-12">
        <label class="white-container-box fw-500 mr-lg">
          Running
          <input type="radio" class="ml" name="running" value="reminder_running" [(ngModel)]="practice.reminder_status"/>
        </label>
        <label class="white-container-box fw-500">
          Stopped
          <input type="radio" class="ml" name="stopped" value="reminder_stopped" [(ngModel)]="practice.reminder_status"/>
        </label>
      </div>
    </div>
    <div class="form-group">
      <label class="col-xs-1"></label>
      <div class="col-xs-11">
        <button class="btn btn-primary mr" type="submit" [disabled]="!practiceForm.form.valid">Submit</button>
        <button class="btn btn-inverse" type="button" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </form>
</div>
