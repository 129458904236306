<ng-container *ngIf="appointment.id == '' ">
  <ng-container *ngIf="_user_service.current_user">
    <form #walkinForm="ngForm" class="form-horizontal main-wrapper clearfix">
      <div class="form-group">
        <label class="col-sm-2 col-xs-12 pt-sm">Patient:</label>
        <div class="col-sm-5 col-xs-9">
          <ng-template  #customItemTemplate let-model="item" let-index="index" let-query="query">
            <div (click)="patientAuthService.checkAuthorization(model,authorizationModal)">
              <span [ngClass]="patientAuthService.isAuthorized(model) ? 'text-black':'text-orange'" [innerHTML]="patientAuthService.highlightQuery(model.complete_detail,query)"></span>
            </div>
          </ng-template>
          <input [(ngModel)]="asyncSelected" typeaheadWaitMs="500" [typeaheadAsync]="true" [typeaheadItemTemplate]="customItemTemplate" typeaheadWaitMs="500"
                 [typeahead]="dataSourcePatients" (typeaheadLoading)="changeTypeaheadLoading($event)" autocomplete="off"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7" [typeaheadOptionField]="'complete_detail'" placeholder="Search Patient"
                 name="patients" class="form-control">
        </div>
        <div class="col-sm-2 col-xs-3">
          <button type="button" class="btn btn-primary" (click)="classicModal.show()"><i class="fa fa-user"></i> Add</button>
        </div>
      </div>
      <ng-container *ngIf="selected_patient && selected_patient.id">
        <div class="form-group">
          <label class="col-sm-2 pt-sm">Selected Patient:</label>
          <div class="col-sm-5">
            <div class="white-container-box p-lg">
              <div class="media">
                <div class="media-left"><img class="img-rounded thumbnail thumb80" [src]="selected_patient.profile_pic" alt="Image"/></div>
                <div class="media-right">
                  <h4 class="text-primary fs18 m0 mb-sm fw-500">{{selected_patient.user.first_name}} {{selected_patient.user.last_name}}</h4>
                  <ng-container *ngIf="selected_patient.user?.contact_numbers">
                    <h5 class="m0 mb-sm"><i class="fa fa-phone"></i> &nbsp;{{selected_patient.mobile_number_value}}</h5>
                  </ng-container>
                  <h5 class="m0 mb-sm"><i class="fa fa-user"></i> &nbsp;{{selected_patient.user.gender}} {{show_gender_age()}}</h5>
                  <h5 class="m0"><i class="fa fa-map-marker"></i> &nbsp;{{selected_patient.user?.address?.city_area?.name}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="form-group">
        <label class="col-sm-2 pt-sm">Reason of Visit:</label>
        <div class="col-sm-5">
          <input [typeahead]="dataSourceServices" name="service" typeaheadWaitMs="500"
                 [(ngModel)]="asyncSelectedService" [typeaheadAsync]="true"
                 (typeaheadLoading)="changeTypeaheadLoadingService($event)"
                 (typeaheadNoResults)="changeTypeaheadNoResultsService($event)"
                 (typeaheadOnSelect)="typeaheadOnSelectService($event)" [typeaheadOptionsLimit]="7"
                 [typeaheadOptionField]="'name'" placeholder="Search Reason"
                 class="form-control" type='text' autocomplete="off">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 pt-sm">Location:</label>
        <div class="col-sm-5">
          <select [(ngModel)]="location_id" name="location" #location class="form-control" (change)='change_location(location.value)'>
            <!--<option [value]="Locations" [selected]="true">Locations</option>-->
            <!--<option value="">Locations</option>-->
            <option *ngFor="let location of locations" [value]="location.id">
              {{location.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 pt-sm">Doctor:</label>
        <div class="col-sm-5">
          <select [(ngModel)]="doctor_id" name="doctor" #provider class="form-control" (change)='change_provider(provider.value)'>
            <option [value]="'Providers'" [selected]="true">Providers</option>
            <option *ngFor="let provider of providers" [value]="provider.id">
            {{provider.first_name}} {{provider.last_name}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-2"></div>
          <div class="col-sm-5">
            <button type="submit" class="btn btn-primary" [disabled]="isEnabled()" (click)="book_appointment(appointment_from)" id="emergencybtn">Submit</button>
          </div>
      </div>
    </form>
  </ng-container>
</ng-container>

<ng-container *ngIf="appointment.id"><app-payment [appointment]="appointment" [appointment_from]="appointment_from"></app-payment></ng-container>

<div class="modal modal_popup" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-lg" style="height:400px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close_modal(classicModal)"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Add Patient</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-9">
            <app-demographics *ngIf="show_patient_form" [show_extras]="true" [patient]="patient"  (new_user)="handle_user($event);close_modal(classicModal);" ></app-demographics>
          </div>
          <div class="col-sm-3">
            <div class="white-container-box text-center">
              <div class="user-profile-pic thumbnail">
                <a class="icon-holder" (click)="show_attachment_popup= true">
                  <i class="fa fa-upload fa-2x"></i>
                  <h5>Upload Your Picture</h5>
                </a>
                <img *ngIf="patient.user && patient.user.profile_pic" class="img-rounded img-responsive" [src]="patient.user.profile_pic" alt="Image"/>
              </div>
            </div>
            <app-attachments (document_uploaded)="handle_image_upload($event)" (popup_closed)="show_attachment_popup= false" [fileType]="'profile_pic'" [only_modal]="true" [attachable_id]="patient.id" [attachable_type]="'User'" [show]="show_attachment_popup" [auto_upload]="true"></app-attachments>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal_popup" bsModal #authorizationModal="bs-modal" [config]="{backdrop: false,ignoreBackdropClick: true}">
  <div class="modal-dialog modal-lg" style="height:500px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="hideModal();"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Patient Authorization Required</h4>
      </div>
      <div class="modal-body">
        <p class="alert alert-danger p">
          <i class="fa fa-exclamation-triangle mr-sm"></i>
          You are not authorized to access this patient record, please get authorization from patient via Text Message or KBA.
        </p>
        <div class="clearfix">
          <span class="fs18 mr-lg vl-m">Authorize by</span>
          <button (click)="patientAuthService.authorizationType('sms')" class="btn btn-primary"><i class="fa fa-mobile-phone fs20 mr-sm"></i> Text Message</button>
          <span class="mr-lg ml-lg vl-m">OR</span>
          <button (click)="patientAuthService.authorizationType('kba')" class="btn btn-primary"><i class="fa fa-file-text-o mr-sm"></i> KBA</button>
        </div>
        <div class="p-lg" *ngIf="patientAuthService.type == 'sms'">
          <h4 class="fs22"><i class="fa fa-mobile-phone mr-sm"></i> Text Message Authorization</h4>
          <p class="text-success mb-lg">Message has been sent. Please ask your patient to provide Verification Code.</p>
          <div class="row">
            <div class="col-sm-6 col-xs-12 form-group">
              <form (ngSubmit)="verifyOtp()" #PhoneForm="ngForm">
                <label>Mobile Verification Code*</label>
                <div class="input-group">
                  <input type="text" name="mobile_otp" class="form-control" [(ngModel)]="patientAuthService.data.mobile_otp" required #mobile_otp/>
                  <span class="input-group-btn">
                  <button class="btn btn-primary"  *ngIf="!patientAuthService.verified" type="submit" [disabled]="!PhoneForm.form.valid">
                    <i class="fa fa-check-circle mr-sm"></i> Verify
                  </button>
                  <button class="btn btn-success" *ngIf="patientAuthService.verified"><i class="fa fa-check mr-sm"></i> Verified</button>
                </span>
                </div>
                <p [ngClass]="patientAuthService.message_class">{{patientAuthService.mobile_message}}</p>
              </form>
            </div>
          </div>
          <h5 class="m0 mb-sm"><a class="btn-link" (click)="patientAuthService.sendOtp('sms','')">
            <i class="fa fa-share-square"></i> Resend</a>
          </h5>
          <p class="m0 fs12">If you haven't received the Mobile Verification Code within one minute, click <span class="text-primary">"Resend"</span></p>
          <p [ngClass]="patientAuthService.message_class" *ngIf="patientAuthService.type == 'sms'">{{patientAuthService.general_message}}</p>
        </div>
        <div class="p-lg" *ngIf="patientAuthService.type == 'kba'">
          <h4 class="fs22"><i class="fa fa-file-text-o mr-sm"></i> KBA Authorization</h4>
          <p class="text-success mb-lg">Please ask for Patient's Mother Name given at time of Registration.</p>
          <div class="row">
            <div class="col-sm-6 col-xs-12 form-group">
              <form (ngSubmit)="verifyMotherName()" #PhoneForm="ngForm">
                <label>Mother's Maiden Name*</label>
                <div class="input-group">
                  <input type="text" name="mother_name" class="form-control" [(ngModel)]="patientAuthService.data.mother_name" maxlength="15" pattern="[^\s]+" required/>
                  <span class="input-group-btn">
                  <button *ngIf="!patientAuthService.verified " type="submit" class="btn btn-primary" [disabled]="!PhoneForm.form.valid">
                    <i class="fa fa-check-circle mr-sm"></i> Verify
                  </button>
                  <button class="btn btn-success" *ngIf="patientAuthService.verified "><i class="fa fa-check mr-sm"></i> Verified</button>
                </span>
                </div>
                <p class="text-muted mt-sm fs12">{{patientAuthService.hint}}</p>
                <p [ngClass]="patientAuthService.message_class">{{patientAuthService.mobile_message}}</p>
              </form>
            </div>
          </div>
          <p [ngClass]="patientAuthService.message_class" *ngIf="patientAuthService.type.includes('sms')">{{patientAuthService.general_message}}</p>
        </div>
      </div>
      <div class="modal-footer mt-lg">
        <button class="btn btn-primary" [disabled]="!patientAuthService.verified" (click)="patientAuthService.closeModal(authorizationModal)">Continue</button>
        <button type="submit" class="btn btn-inverse" (click)="hideModal();">Cancel</button>
      </div>
    </div>
  </div>
</div>
