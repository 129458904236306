import {Component, OnInit, Injector, ViewChild} from '@angular/core';
import {Announcement} from '../announcement';
import {AnnouncementsService} from '../../../services/announcements.service';
import {PermissionsService, Role, RoleType} from '../../permissions/permissions.service';
import {NgForm} from '@angular/forms';
import {CustomValidations} from '../../customValidations/custom-validations';
import {TabsService} from '../../../services/tabs.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  public announcement: Announcement;
  public portals = ['Enterprise', 'Practice', 'Patient', 'Doctor'];
  public errorMessage: string;
  public user_types = ['All', 'Admin', 'Nurses', 'Medical Staff'];
  public customValidations: CustomValidations;
  public announcements: Announcement[];
  public roleTypes: RoleType[];
  public dropdownSettings = {}

  @ViewChild('announcementForm') announcementForm: NgForm;

  constructor(private globalErrorHandlerService: GlobalErrorHandlerService, private tabsService: TabsService, private injector: Injector, private announcementService: AnnouncementsService, private permissionService: PermissionsService) {
    this.customValidations = new CustomValidations();
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
        enableCheckAll: true,
      text: 'Select User Type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
    };
    let announcement = this.injector.get('announcement', null);
    this.announcements = this.injector.get('announcements', null);
    if (announcement) {
      this.announcement = announcement;
    }
    else {
      this.announcement = new Announcement();
    }
    this.getRoleTypes();
  }

  getRoleTypes() {
    this.permissionService.getRoleTypes().then(resp => {
      this.roleTypes = resp.role_types.map(roleType => new RoleType().load_from_json(roleType));
    })
  }

  getRolesOfSelectedRoleType(role_type_id) {
    return this.roleTypes.filter(role_type => role_type.id == role_type_id)[0].roles.map(role => {
      return {'id': role.id, 'itemName': role.name}
    });
  }

  fetchAnnouncement(id: number) {

  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.announcementForm);
  }

  save() {
    this.announcementService.save(this.announcement)
      .then(resp => {
        this.announcement = this.announcement.load_from_json(resp);
        this.tabsService.close_tab(this.tabsService.active_tab());
      }).catch(resp => this.globalErrorHandlerService.error = resp.error.error);
  }

  onSelect(roleType) {
    if (roleType.selectedOptions[0].id.toUpperCase() == 'DOCTOR' || roleType.selectedOptions[0].id.toUpperCase() == 'PATIENT') {
      this.announcement.selected_roles = this.roleTypes.map(role_type => {
        if (role_type.id == roleType.value) {
          return {'id': role_type.roles[0].id, 'itemName': role_type.roles[0].name}
        }
      });
      this.announcement.selected_roles = this.announcement.selected_roles.filter(role => role != undefined)
    }
    else {
      this.announcement.selected_roles = [];
    }
  }

  closeNewForm() {

  }

  OnItemSelect(role) {
    console.log(this.announcement.selected_roles);
  }

  OnItemDeSelect(role) {
    console.log(this.announcement.selected_roles);
  }

  onSelectAll($event: Array<any>) {

  }

  onDeSelectAll($event: Array<any>) {

  }
}
