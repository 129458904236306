import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {LocationService} from '../../../../services/location.service';
import {UserService} from '../../../../services/user.service';
import {Calendar, Doctor} from '../../../practices/doctors/doctor';
import {DoctorService} from '../../../../services/doctor.service';
import {Appointment} from '../../../../health/models/appointment';
import {AppointmentService} from '../../../../services/appointment.service';
import {CalendarEvent, CalendarView} from 'angular-calendar';
import {LoaderService} from '../../../../services/loader.service';
import {SlotDatesService} from '../../../../services/slot-dates.service';
import {DatePipe} from '@angular/common';
import {SlotsService} from '../../../../services/slots.service';
import {Subject} from 'rxjs/Subject';
import {Subscription} from 'rxjs/index';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {UserManagementService} from '../../../../services/user-management.service';
const colors: any = {
  noshow: {
    primary: '#fdd969',
    secondary: '#fdd969',
  },
  confirmed: {
    primary: '#0bcec2',
    secondary: '#0bcec2',
  },
  cancelled: {
    primary: '#fd9191',
    secondary: '#fd9191',
  },
  scheduled: {
    primary: '#02dc02',
    secondary: '#02dc02',
  },
  checkedin: {
    primary: '#06bdde',
    secondary: '#06bdde',
  },
  checkedout: {
    primary: '#b3b1b1',
    secondary: '#b3b1b1',
  },
  rescheduled: {
    primary: '#c55cf6',
    secondary: '#c55cf6',
  },
  admitted: {
    primary: '#06a7ac',
    secondary: '#06a7ac',
  },
  pending: {
    primary: '#ffd863',
    secondary: '#ffd863',
  },
};

@Component({
  selector: 'app-scheduler-dashboard',
  templateUrl: './scheduler-dashboard.component.html',
  styleUrls: ['./scheduler-dashboard.component.scss']
})
export class SchedulerDashboardComponent implements OnInit, OnChanges {

  public appointments: Appointment[] = [];
  public appointment: Appointment = new Appointment();
  public order_by = 'created_at asc';
  public locations: any[];
  public location_id = '0';
  public doctors: Doctor[];
  public practice_id;
  public calendar_start_date = new Date();
  public calendar_end_date = new Date();
  public doctor_id = '0';
  public selectedCalendars:  any = [];
  public selectedDoctor: Doctor;
  public selectedPractice: any;
  public doctorCalander
  public selected: any = [];
  public show_calendar = false
  public showDoctorDropdown = false
  public show_save_button = false
  public doctor = new Doctor();
  physicians
  slots = []
  view: CalendarView = CalendarView.Day;
  viewDate: Date = new Date();
  from_date: Date = new Date();
  to_date: Date = new Date();
  refresh: Subject<any> = new Subject();
  public subscription: Subscription;


  constructor(private datePipe: DatePipe, private slot_service: SlotsService,
              private _slot_date_service: SlotDatesService, public userManagementService: UserManagementService,
              public loaderService: LoaderService, public appointment_service: AppointmentService,
              public locationService: LocationService, public userService: UserService,
              public doctor_service: DoctorService, public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    this.appointment_service.is_schedular = true;
    if (this.userService.current_user.isPractice) {
      this.getPracticeLocations(this.userService.current_user.practices_id);
    }
    if (this.userService.current_user.isDoctor) {
      this.doctor_service.getDoctorLocations(this.userService.current_user.profileable_id).then(resp => {
        this.locations = resp;
      }).catch(error => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = error.error.error;
      });
    }
    if (this.userService.current_user.selected_calendars.length > 0) {
      this.selectedCalendars = this.userService.current_user.selected_calendars
      if (this.selectedCalendars) {
        this.getCurrentUserSaveCalendar();
      }
    }
    if (this.userService.current_user.selected_calendars != this.selectedCalendars ) {
      this.show_save_button = true
    }
    this.subscription = this.appointment_service.reloadSchedulerAppointments.subscribe(item => {
      if (item) {
        this.updateEvents(item);
      }
    });
  }

  ngOnChanges() {
    this.fetchEvents();
  }

  updateEvents(id) {
    this.loaderService.show();
    this.appointment_service.getAppointment(id).then(item => {
      let selected_hash = this.selected.find(
        a => (a['doctor']['doctor']['id'] === item.doctor_id && a['doctor']['location']['id'] ===  item.location_id));
      selected_hash.events = selected_hash.events.filter(a => a.meta.appointment.id !== item.id);
      let event = this.appointment_to_event_mapper(item);
      selected_hash.events.push(event);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
  appointment_to_event_mapper(a) {
    return {
      title: a['patient']['name'],
      start: new Date(a['date_with_time_zone'].split('.')[0]),
      end: (a['end_date_with_time_zone'] && new Date(a['end_date_with_time_zone'].split('.')[0])) || new Date(new Date(a['date_with_time_zone'].split('.')[0]).getTime() + a['slot_size'] * 60 * 1000 ),
      color: this.setColorOfAppointment(a['status_id']),
      meta: {appointment:  new Appointment().load_from_json(a), show_actions: false}
    }
  }

  getPracticeLocations(paractice_id) {
    let id = paractice_id;
    this.locationService.practice_locations(id).then(resp => {
      this.locations = resp;
    }).catch(error => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = error.error.error;
    });
  }

  select_location(id) {
    if (id == '0') {
      this.location_id = '0';
      this.showDoctorDropdown = false
      return 0;
    } else {
      this.loaderService.show();
      this.showDoctorDropdown = true;
      if (this.userService.current_user.isPractice) {
        this.location_id = id;
        this.selectedPractice = this.locations.find(location => location.id == id);
        let search_params = { location_id: id, practice_id: this.userService.current_user.practices_id };
        this.doctor_service.get_locations_doctors(search_params).then(doctors => {
          this.doctors = doctors.map(doctor => {
            return new Doctor().load_from_json(doctor)
          });
          this.loaderService.hide();
        }).catch(error => {
          this.loaderService.hide();
          this.globalErrorHandlerService.error = error.error.error;
        });
      }
      if (this.userService.current_user.isDoctor) {
        this.location_id = id;
        this.selectedPractice = this.locations.find(location => location.id == id)
        this.doctor_service.getDoctor(this.userService.current_user.profileable_id).then(json => {
          this.doctor = new Doctor().load_from_json(json);
          this.loaderService.hide();
          return this.select_doctor(this.doctor);
        }).catch(error => {
          this.loaderService.hide();
          this.globalErrorHandlerService.error = error.error.error;
        });
      }
    }
  }


  select_doctor(id) {
    if (id == '0') {
      return false;
    }
    let arry;
    if (this.userService.current_user.isDoctor) {
      this.selectedDoctor = this.doctor
    }
    if (this.userService.current_user.isPractice) {
      this.selectedDoctor = this.doctors.find(doctor  => doctor.id == id)
    }
    arry = {doctor : this.selectedDoctor, location : this.selectedPractice, location_id : this.location_id }
    if ((this.selectedCalendars.length == 0 ) || !(this.userExists(arry, this.selectedCalendars))) {
      this.selectedCalendars.push(arry)
      this.show_save_button = true
      this.fetchEvents();
    }
  }


  userExists(obj, list) {
    return list.some(function(el) {
      return (el.doctor.id == obj.doctor.id && el.location.id == obj.location.id);
    });
  }
  get_doctor_appointments() {
    this.loaderService.show();
    this.selected = [];
    let search_params
    this.userService.current_user.selected_calendars = this.selectedCalendars
    this.selectedCalendars.forEach((provider, index) => {
      let events = []
      search_params = {
        doctor_id: provider.doctor.id, location_id: provider.location.id,
        order_by: this.order_by, date: this.viewDate, type: this.view
      };
      this.appointments = [];
      this.appointment_service.getDoctorLocationAppointments(search_params).then(resp => {
          this.show_calendar = true;
          events = resp.appointments.map(a => {
            return this.appointment_to_event_mapper(a);
          });
          this.selected.push({doctor: provider, events: events, index: index});
          this.selected = this.selected.sort((n1, n2) => n1.index - n2.index);
          this.setCalendarStartAndEndDates();
          if (this.selectedCalendars.length == this.selected.length) {
            this.loaderService.hide();
          }
        }
      ).catch(error => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = error.error.error;
      })
    })
  }

  getDoctorCalendar(params) {
    let search_params = {
      doctor_id: params.doctor_id, location_id: params.location_id, practice_id : this.userService.current_user.practices_id
    };

    this.doctor_service.get_Doctor_location_calendar(search_params).then(

      resp =>  {return this.doctorCalander = resp}
    )
  }

  deleteSelectedCalender(i) {
    let removeIndex = this.selectedCalendars.map(function(item) { return item }).indexOf(i);
    this.selectedCalendars.splice(removeIndex, 1);
    if (this.selectedCalendars.length > 0) {
      this.fetchEvents();
      this.show_save_button = true
    } else {
      this.show_calendar = false
      this.show_save_button = true
    }
  }

  saveSelectedCalendar() {
    this.loaderService.show();
    this.show_save_button = false;
    let parms = this.selectedCalendars.map(cdata => ({doctor_id: cdata.doctor.id, location_id: cdata.location.id}));
    this.userService.saveSelectedCalendar(parms).then(resp => {
      this.userService.current_user.set_new_calendars(resp);
      this.loaderService.hide();
    }).catch(error => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = error.error.error;
    });
  }

  fetchEvents() {
    this.get_doctor_appointments();
  }
  setCalendarStartAndEndDates() {
    if (this.view === 'day') {
      return this.selected.forEach( select => select.doctor.doctor.validCalendars(this.viewDate, this.viewDate))
    }
    if (this.view === 'week') {
      this.calendar_start_date = this.getLastOrFirstDayOfWeak(this.viewDate, 'first');
      this.calendar_end_date = this.getLastOrFirstDayOfWeak(this.viewDate, 'last');
      return this.selected.forEach(select => select.doctor.doctor
        .validCalendars(this.getLastOrFirstDayOfWeak(this.viewDate, 'first'), this.getLastOrFirstDayOfWeak(this.viewDate, 'last')))
    }
  };

  getLastOrFirstDayOfWeak(curr: Date, day: string) {
    curr = new Date(curr)
    let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6

    let firstday = new Date(curr.setDate(first));
    let lastday = new Date(curr.setDate(last));
    return day === 'first' ?  firstday : lastday;
  }
  fetchDoctor(id) {
    this.doctor_service.getDoctor(parseInt(id)).then(json => {
      this.doctor.load_from_json(json);
      this._slot_date_service.got2Today();
    });
  }
  getCurrentUserSaveCalendar() {
    this.loaderService.show();
    this.userService.getCurrentUserSaveCalendar().then(resp => {
      this.userService.current_user.set_new_calendars(resp);
      this.selectedCalendars = this.userService.current_user.selected_calendars;
      this.fetchEvents();
    }).catch(error => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = error.error.error;
    });
  }

  setColorOfAppointment(s) {

    let status = s.toLowerCase();
    switch (status) {
      case  'checkedin':
        return colors.checkedin;
      case 'confirmed':
        return colors.confirmed;
      case 'scheduled':
        return colors.scheduled;
      case 'cancelled':
        return colors.cancelled;
      case 'noshow':
        return colors.noshow;
      case 'checkedout':
        return colors.checkedout;
      case 'rescheduled':
        return colors.rescheduled;
      case 'admitted':
        return colors.admitted;
      case 'pending':
        return colors.pending;
    }
  }
}
