import { NgForm } from '@angular/forms';
export class CustomValidations {
  customForm: NgForm;

  formErrors = {
    'practice_url': '',
    'client_secret': '',
    'client_id': '',
    'auth_url': '',
    'api_url': '',
    'doctor': '',
    'document_type': '',
    'fax': '',
    'portal': '',
    'title': '',
    'detail': '',
    'firstname': '',
    'first_name': '',
    'last_name': '',
    'password': '',
    'password_confirmation': '',
    'notes': '',
    'lastname': '',
    'suffix': '',
    'prefix': '',
    'designation': '',
    'no_of_doctors': '',
    'pets_name': '',
    'name': '',
    'line_1': '',
    'city': '',
    'state': '',
    'zip': '',
    'area': '',
    'phone': '',
    'email': {
      'required': 'Email is required.',
      'pattern': 'Email is invalid.',
    },
    'message': '',
    'specialty_0': '',
    'tagline': '',
    'description': '',
    'sender_email': '',
    'username': '',
    'user_name': '',
    'cnic': '',
    'service': '',
    'comment': '',
    'ward': '',
    'staff': '',
    'bed_number': '',
    'assigned_to': '',
    'task_name': '',
    // 'task_description':'',
    'priority': '',
    'due_date': '',
    'button_color': '',
    'slots_color': '',
    'highlight_color': '',
    'doctor_name_color': '',
    'integration_flow': '',
     'hover_color': '',
    'mother_name': '',
    'regno':''
  };

  validationMessages = {
    'mother_name': {
      'pattern': 'Spaces are not allowed.',
      'required': 'Mother\'s maiden name is required.',
      'maxlength': 'Mother\'s maiden name cannot be more than 15 characters long.',

    },
    'doctor': {
      'required': 'Please select Doctor.',
    },
    'document_type': {
      'required': 'Please select Document Type.',
    },
    'title': {
      'required': 'Title is required.',
    },
    'pets_name': {
      'required': 'Pets name is required.',
    },
    'detail': {
      'required': 'Detail is required.',
    },
    'priority': {
      'required': 'Please select Priority.',
    },
    'task_name': {
      'required': 'Please write something in Task Name',
    },
   /* 'task_description':{
      'required': 'Please write something in description',
    },*/
    'assigned_to': {
      'required': 'Please assignet this task to someone.',
    },
    'bed_number': {
      'required': 'Bed Number required.',
    },
    'service': {
      'required': 'Service required.',
    },
    'comment': {
      'required': 'Comment required.',
    },
    'ward': {
      'required': 'Ward required.',
    },
    'staff': {
      'required': 'Staff required.',
    }
    , 'cnic': {
      'required': 'CNIC required.',
    },
    'regno': {
      'required': 'Reg No required.',
      'maxlength': 'Reg No cannot be more than 10 characters long.',
    },
    'firstname': {
      'pattern': 'Field only accept letters',
      'required': 'First name is required.',
      'minlength': 'First name must be at least 15 characters long.',
      'maxlength': 'First name cannot be more than 24 characters long.',
    },
    'first_name': {
      'pattern': 'Field only accept letters',
      'required': 'First name is required.',
      'minlength': 'First name must be at least 15 characters long.',
      'maxlength': 'First name cannot be more than 24 characters long.',
    },
    'password': {
      'required': 'Password is required.',
      'minlength': 'First name must be at least 8 characters long.',
      'maxlength': 'First name cannot be more than 15 characters long.',
    },
    'password_confirmation': {
      'required': 'Password Mismatch',
      'minlength': 'First name must be at least 8 characters long.',
      'maxlength': 'First name cannot be more than 15 characters long.',
    },
    'notes': {
      'required': 'Doctor note is required.',
      'minlength': 'Note must be at least 5 characters long.',
      'maxlength': 'Note cannot be more than 24 characters long.',
    },
    'lastname': {
      'pattern': 'Field only accept letters',
      'required': 'Last name is required.',
      'minlength': 'Last name must be at least 15 characters long.',
      'maxlength': 'Last name cannot be more than 24 characters long.',
    },
    'last_name': {
      'pattern': 'Field only accept letters',
      'required': 'Last name is required.',
      'minlength': 'Last name must be at least 15 characters long.',
      'maxlength': 'Last name cannot be more than 24 characters long.',
    },
    'suffix': {
      'required': 'Suffix is required.'
    },
    'designation': {
      'required': 'Designation is required.'
    },
    'prefix': {
      'required': 'Prefix is required.'
    },
    'no_of_doctors': {
      'required': 'No of Doctors required.',
    },
    'name': {
      'pattern': 'Field only accept letters',
      'required': 'Practice name is required.',
      'minlength': 'Practice must be at least 8 characters long.',
      'maxlength': 'Practice cannot be more than 75 characters long.',
    },
    'line_1': {
      'required': 'Address 1 is required.'
    },
    'city': {
      'required': 'City is required.'
    },
    'state': {
      'required': 'State is required.'
    },
    'zip': {
      'pattern': 'Please enter correct format',
      'required': 'Zip is required.',
      'minlength': 'Zip code must be at least 1 character long.',
      'maxlength': 'Zip code cannot be more than 8 characters long.',
    },
    'area': {
      'required': 'Area is required.'
    },
    'phone': {
      'pattern': 'Please enter valid phone number',
      'required': 'Phone is required.',
       'Mask error': 'format is invalid'
    },
    'fax': {
      'pattern': 'Please enter valid fax number',
      'minlength': 'Fax must be at least 1 character long.',
      'maxlength': 'Fax cannot be more than 15 characters long.',
    },
    'email': {
      'required': 'Email is required.',
      'pattern': 'Email is invalid.'
    },
    'sender_email': {
      'required': 'Sender Email is required.',
      'pattern': 'Sender Email is invalid.'
    },
    'message': {
      'maxlength': 'Maximum 500 words are allowed.'
    },
    'specialty_0': {
      'required': 'Atleast one Specialty is required.'
    },
    'tagline': {
      'required': 'Tagline is required.'
    },
    'description': {
      'required': 'Description is required.'
    },
    'username': {
      'required': 'Username is required.'
    },
    'button_color': {
      'required': 'Please select Color.'
    },
    'slots_color': {
      'required': 'Please select Color.'
    },
    'highlight_color': {
      'required': 'Please select Color.'
    },
    'doctor_name_color': {
      'required': 'Please select Color.'
    },
    'integration_flow': {
      'required': 'Please select integration flow is required.'
    },
    'hover_color': {
      'required': 'Please select Color.'
    },
    'api_url': {
      'required': 'API URL Required.'
    },
    'auth_url': {
      'required': 'Auth URL required.'
    },
    'client_secret': {
      'required': 'Client Secret Required.'
    },
    'client_id': {
      'required': 'Client ID required.'
    },
    'user_name': {
      'required': 'Username is required.'
    },
    'practice_url': {
      'required': 'Practice URL is required.'
    }
  };
  formChanged(customForm) {
    this.customForm = customForm;
    if (!this.customForm) { return; }
    if (this.customForm) {
      this.customForm.valueChanges
        .subscribe(data => this.onValueChanged(data));
    }
  }

  onValueChanged(data?: any) {
    if (!this.customForm) { return; }
    const form = this.customForm.form;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          console.log(field, key);
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

}
