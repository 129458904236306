import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Physician} from '../models/physician';
import {SlotsService} from '../../services/slots.service';
import {LoaderService} from '../../services/loader.service';
import {SlotDatesService} from '../../services/slot-dates.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';

@Component({
  selector: 'app-slot-navigator',
  templateUrl: './slot-navigator.component.html',
  styleUrls: ['./slot-navigator.component.scss']
})
export class SlotNavigatorComponent implements OnInit, OnChanges {
  @Input() physicians: Array<Physician>;
  @Input() autoload: boolean;
  @Input() allow_past_navigation = false;
  @Input() refresh = false;
  @Input() appointment_type: string;
  @Input() show_navigator = true;
  @Input() amenity_id: string;
  @Input() location_id: string;
  @Input() resch_appointment_ids: Array<string>;
  constructor(private slot_service: SlotsService,
              public slot_dates_service: SlotDatesService,
              private datePipe: DatePipe, private globalErrorHandlerService: GlobalErrorHandlerService,
              private loaderService: LoaderService) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.show_navigator = this.show_navigator;
    if (this.autoload) {
        this.loadSlots(this.selected_date);
    }
  }

  is_today() {
    return this.slot_dates_service.is_today();
  }

  to_ymd(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  get dates() {
    return this.slot_dates_service.dates;
  }

  get selected_date() {
    return this.dates[1];
  }

  get nextDay() {
    return this.dates[2];
  }

  get previousDay() {
    return this.dates[0];
  }

  set nextDay(date) {
    this.dates[2] = date;
  }

  set selected_date(date) {
    this.dates[1] = date;
  }

  set previousDay(date) {
    this.dates[0] = date;
  }

  setSelectedDate(date) {
    this.slot_dates_service.setSelectedDate(date);
  }

  setNextDate() {
    this.setSelectedDate(this.nextDay);
    this.loadSlots(this.selected_date);
  }

  setPreviousDate() {
    this.setSelectedDate(this.previousDay);
    this.loadSlots(this.selected_date);
  }

  loadSlots(date) {
    this.loaderService.show();
    let doctor_ids = this.physicians.map(a => a.id).join(',');
    this.slot_service
      .loadSlots(
        {
          doctor_ids: doctor_ids,
          amenity_id: this.amenity_id,
          location_id: this.location_id,
          appointment_ids: this.resch_appointment_ids,
          date: this.datePipe.transform(date, 'yyyy-MM-dd')
        }
      )
      .subscribe(response => {
        this.physicians.forEach(doc => {
          doc.load_slots(response[doc.id]);
        });
        this.loaderService.hide();
      }, error => {
        this.globalErrorHandlerService.error = error.error.message;
        this.loaderService.hide();
      });
  }

  loadVirtualSlots(date) {
    this.loaderService.show();
    let doctor_ids = this.physicians.map(a => a.id).join(',');
    this.slot_service.loadVirtualSlots({doctor_ids: doctor_ids, date: this.datePipe.transform(date, 'yyyy-MM-dd')}).subscribe(response => {
      this.physicians.forEach(doc => {
        doc.load_slots(response['slots'][doc.id]);
      });
      this.loaderService.hide();
    });
  }

  getDoctor(doctor_id) {
    let result = this.physicians.filter(p => p.id === doctor_id);
    return result && result[0];
  }

}
