import {Component, Injector, Input, OnInit} from '@angular/core';
import {EnrolmentPatientConsentComponent} from '../../program-enrolment/enrolment-patient-consent/enrolment-patient-consent.component';
import {Program} from '../../program';
import {ProgramService} from '../../program.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {ProgramEnrollment} from '../../eligible-patient';

@Component({
  selector: 'app-enrolled-patient-consent',
  templateUrl: './enrolled-patient-consent.component.html',
  styleUrls: ['./enrolled-patient-consent.component.scss']
})
export class EnrolledPatientConsentComponent implements OnInit {
  public consentComponent: any = EnrolmentPatientConsentComponent;
  custom_injector: Injector;
  @Input() patient_id;
  @Input() program: Program;
  @Input() programEnrollment: ProgramEnrollment;

  constructor(public injector: Injector, public programService: ProgramService,
              public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    this.setCustomInjectorForConsent();
  }
  setCustomInjectorForConsent() {
    this.custom_injector  = Injector.create([
      {provide: 'patient_id', useValue: this.patient_id},
      {provide: 'program', useValue: this.program},
      {provide: 'programEnrollment', useValue: this.programEnrollment},
      {provide: 'isEnrolledPatient', useValue: true},
    ], this.injector);
  }
}
