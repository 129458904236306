import {Component, Input, OnInit} from '@angular/core';
import {Patient} from '../../../health/doctor/patient';

@Component({
  selector: 'app-enrolled-patient-summary',
  templateUrl: './enrolled-patient-summary.component.html',
  styleUrls: ['./enrolled-patient-summary.component.scss']
})
export class EnrolledPatientSummaryComponent implements OnInit {
  @Input() patient: Patient;

  constructor() { }

  ngOnInit(): void {}

}
