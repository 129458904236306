import { Injectable } from '@angular/core';
import {CustomHttpClient} from "../../../../../services/custom-http-client.service";
import {Observable, Subject} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {PatientNotes} from "./patient-notes";
import {nullRemover} from "../../../../../services/nullRemover";


@Injectable({
  providedIn: 'root'
})
export class PatientNotesService {
  reloadNotes: Subject<any> = new Subject<any>();
  closeNewNoteForm: Subject<any> = new Subject<any>();
  reloadStickyNote: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient) { }

  search_patient_notes(term: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('term', term);
    return this._tokenService
      .get('patient_notes', {params: params})
      .map((r) => r );
  }
  create(patient_note: PatientNotes): Promise<any> {
    return this._tokenService
      .post(patient_note.get_url(), patient_note.to_params())
      .toPromise()
      .then(response => response);
  }

  save(patient_note: PatientNotes): Promise<any> {
    if (patient_note.is_new_record()) {

      return this.create(patient_note);
    }
    else {
      return this.update(patient_note);
    }
  }


  update(patient_note: PatientNotes) {
    return this._tokenService
      .put(patient_note.get_url(), patient_note.to_params())
      .toPromise()
      .then(response => response);
  }
  delete(id) {
    return this._tokenService
      .delete('patient_notes?id=' + id)
      .toPromise()
      .then(response => response)
  }
  getPatientNotes(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('patient_notes', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          patient_notes: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  //Method to Get All Patient Notes Types
  getPatientNoteType() : Promise<any>{
    return this._tokenService.get('patient_notes/patient_note_types')
      .toPromise()
      .then(response => response)
  }

  savePatientNoteType(patient_note_type): Promise<any> {
    return this._tokenService
      .post('patient_notes/patient_note_type', {patient_note_type: patient_note_type})
      .toPromise()
      .then(response => response);
  }
  getStickyNotes(patient_id): Promise<any> {
    return  this._tokenService.get('patient_notes/sticky_notes?id=' + patient_id)
      .toPromise()
      .then(response => response)
  }

  removeSticky(note_id: any) {
    return this._tokenService
      .put('patient_notes/remove_sticky', {id: note_id})
      .toPromise()
      .then(response => response);
  }


}
