<ng-container *ngIf="document">
  <form class="medicalChart p" (ngSubmit)="save()" #chartForm="ngForm">
    <div class="form-group row">
      <label class="col-sm-2 field-label control-label">Document</label>
      <div class="col-sm-10" style="display:flex; gap:20px; align-items:center;">
        <div class="btn-group">
          <a (click)="choose_file()" type="file" class="btn btn-tertiary btn-outline" *ngIf="!loaderService.uploading_in_process"><i class="fa fa-file-o fa-fw"></i> Chose File</a>
          <a *ngIf="loaderService.uploading_in_process" class="btn btn-tertiary btn-outline"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
        </div>
        <div *ngIf="!loaderService.uploading_in_process">
          <img *ngIf="document.data" [src]="document.data" alt="" class="img-circle thumb32">
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 field-label control-label">Type*</label>
      <div class="col-sm-3">
        <select class="form-control" [(ngModel)]="document.document_type_id" name="document_type_id" required>
          <option selected="selected" value="">
            Type
          </option>
          <option *ngFor="let documentType of documentsType; let i = index;" value={{documentType.id}}>
            {{documentType.name}}
          </option>
        </select>
      </div>
      <label class="col-sm-1 field-label control-label">Name*</label>
      <div class="col-sm-3">
        <input type="text" class="form-control" name="name" [(ngModel)]="document.name" required>
      </div>
      <label class="col-sm-1 field-label control-label">Date*</label>
      <div class="col-sm-2 dateTime">
        <input [(ngModel)]="document.date" name="document_date" class="form-control"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
               bsDatepicker
               autocomplete="off"
               placeholder="Date"
               required/>
        </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 field-label control-label">Doctor</label>
      <div class="col-sm-10">
        <select class="form-control" [(ngModel)]="document.doctor_id" name="doctor_id">
          <option selected="selected" value="">
            Doctor
          </option>
          <option *ngFor="let doctor of doctors; let i = index;" value={{doctor.id}}>
            {{doctor.complete_name}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 field-label control-label">Reason of Visit</label>
      <div class="col-sm-10 reasonInput">
        <ng-select [items]="items"
                   bindLabel="name"
                   autofocus
                   bindValue="id"
                   (change)="selected_service($event)" name="service"
                   (search)="typedServices($event)" placeholder="Search Reason"></ng-select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-2"></div>
      <div class="col-sm-10">
        <button type="submit" class="btn btn-secondary mr-sm" [disabled]="!isSubmitable()">Submit</button>
        <button class="btn btn-tertiary btn-outline" type="button" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </form>
  <app-attachments [auto_upload]="true" (document_uploaded)="handle_image_upload($event)" [fileType]="'document'" [only_modal]="true"
  [attachable_id]="document.id" [attachable_type]="'Document'" [show]="show_attachment_popup"></app-attachments>
</ng-container>
