<div *ngIf="attachable_type=='Client'" class="row">
  <div class="col-sm-8">
    <div class="col-sm-2">
      <img style="border: 1px solid  #0aa89e;background-color: #00b3ee"  class="img-responsive img-square" src="assets/img/dummy.png" alt="Image"/>
      <span style="padding-left:21px;" class="img-foot-note">Photo</span>
    </div>
    <div class="col-sm-2">
      <img style="border: 1px solid  #0aa89e;background-color: #00b3ee"  class="img-responsive img-square" src="assets/img/dummy.png" alt="Image"/>
      <span style="padding-left:21px;" class="img-foot-note">Banner</span>
    </div>
    <div class="col-sm-2">
      <img style="border: 1px solid  #0aa89e;background-color: #00b3ee"  class="img-responsive img-square" src="assets/img/dummy.png" alt="Image"/>
      <span style="padding-left:22px;" class="img-foot-note">Logo</span>
    </div>
  </div>
</div>

<div class="mt" *ngIf="!only_modal">
  Add: <a (click)="classicModal.show();setType('photo')">Photo</a>
  <a *ngIf="attachable_type=='Practice'" (click)="classicModal.show();setType('banner')"> | Banner | </a>
  <a *ngIf="attachable_type=='Practice'" (click)="classicModal.show();setType('logo')">Logo | </a>
</div>

<div class="modal fade" bsModal (onHide)="handler('onHide', $event)" #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:385px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide();"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">File Upload</h4>
      </div>
      <div class="modal-body">
        <form class="mr-lg ml-lg">
          <div class="form-group">
            <input #file type="file" id="exampleInputFile" (change)="selectFile($event)">
            <h6 class="text-left text-danger">supported ext i.e jpg, jpeg, png, gif, x-png, pdf.</h6>
          </div>
          <div class="form-group">
            <label>Title*</label>
            <input class="form-control" type="text" name="title" [(ngModel)]="title" placeholder="Type file name">
          </div>

          <div class="form-group mt-xl text-left">
            <label for="exampleInputEmail1">Description</label>
            <textarea cols="6" rows="4" type="description" class="form-control" id="exampleInputEmail1" name="description" [(ngModel)]="description"></textarea>
          </div>
          <div class="progress m0">
            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progress + '%' }"></div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <ng-container *ngIf="!auto_upload">
          <button type="button"  *ngIf="progress != 100" class="btn btn-primary" [disabled]="!title" (click)="upload();">Submit</button>
        </ng-container>
        <button type="button" class="btn btn-inverse" (click)="classicModal.hide();clear_variables();">Close</button>
      </div>
    </div>
  </div>
</div>
