<div class="filters-sec bg-lightest-gray clearfix">
  <a class="btn btn-primary btn-outline btn-filter mb" data-toggle="collapse" href="#collapsefilters"><i class="fa fa-filter mr-sm"></i> Filters</a>
  <div class="collapse" id="collapsefilters">
    <div class="row">
      <div class="col-md-2 col-sm-6" *ngIf="_userService.user_logged_in && _userService.current_user && _userService.current_user.isEnterprise">
        <!--<ng-select (data)="value" required #practiceInput allowClear="true" (selected)="selectedPractice($event)" (typed)="typedPractice($event)"
                   (removed)="removePractice($event)" placeholder="Practices">
        </ng-select>-->
        <ng-select  required
                    #practiceInput
                    [items]="practices"
                    allowClear="true" (change)="selectedPractice($event)" (search)="typedPractice($event)"
                  (remove)="removePractice($event)" placeholder="Practices">
       </ng-select>

      </div>
      <div class="col-md-2 col-sm-6">
      <!--  <label [typeahead]="patients" name="patients" [(ngModel)]="asyncSelected" typeaheadWaitMs="500"
                 (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadAsync]="true"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                 [typeaheadOptionField]="'name'" placeholder="Patients"
                 class="form-control" required autocomplete="off">
        </label>-->
        <input [(ngModel)]="asyncSelected"
               typeaheadOptionField="name"
               (typeaheadOnSelect)="typeaheadOnSelect($event)"
               (typeaheadNoResults)="changeTypeaheadNoResults($event)"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               [typeahead]="patients"
               [typeaheadAsync]="true"
               class="form-control"
               placeholder="Patients">
      </div>
      <div class="col-md-2 col-sm-6">
        <select placeholder="Patient" #ward class="form-control" (change)='selectWard(ward)'>
          <option value="" selected >Ward</option>
          <option *ngFor="let warde of wards; let i = index;" [value]="warde">
            {{warde}}
          </option>
        </select>
      </div>
      <div class="col-md-2 col-sm-6">
        <select #dep class="form-control" (change)='selectDepartment(dep)'>
         <option selected value="">Department</option>
           <option *ngFor="let department of departments; let i = index;" [value]="department.id">
            {{department.name}}
          </option>
        </select>
      </div>
      <div class="col-md-2 col-sm-6">
       <!-- <label>
          <input [(ngModel)]="filter.date" autocomplete="off" name="from" class="form-control"
                 ngui-datetime-picker
                 [disabled-dates]="date2DisabledDates"
                 [show-close-layer]="true"
                 placeholder="Admission Date"
                 date-only="true"/>
        </label>-->
        <input type="text"
               placeholder="Admission Date"
               autocomplete="off"
               name="from"
               [(ngModel)]="filter.date"
               class="form-control"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
               today-highlight="true"
               bsDatepicker
               [bsValue]="bsValue">
      </div>
      <div class="col-sm-2 col-sm-12 btns-area">
        <button class="btn btn-inverse" (click)="getCases()"><i class="fa fa-filter fa-fw"></i> Filter</button>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="cases && cases.length == 0"><app-no-result-found></app-no-result-found></ng-container>
<div class="todaysPatient-list clearfix">
  <ng-container *ngFor="let case of cases; let i = index;">
    <app-admissions-listing class="list clearfix" [view]="'listing'" [admission]="case"></app-admissions-listing>
  </ng-container>
  <div *ngIf="cases && totalItems > 10" class="text-center">
    <pagination [totalItems]="totalItems" [itemsPerPage]="100" (pageChanged)="pageChanged($event)"></pagination>
  </div>
</div>
