{{globalErrorHandlerService.error ? classicModal.show(): classicModal.hide()}}
<div class="modal-container" [hidden]="!globalErrorHandlerService.error">
  <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1" role="dialog"
       aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" style="height:200px;">
      <div class="modal-content">
        <div class="modal-body p-xl">
          <h4 class="text-danger text-center"><i class="fa fa-exclamation-triangle fs25"></i> &nbsp;{{globalErrorHandlerService.error}}</h4>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="classicModal.hide();clearErrors();">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
{{globalErrorHandlerService.message ? classicModalMessage.show(): classicModalMessage.hide()}}
<div class="modal-container" [hidden]="!globalErrorHandlerService.message">
  <div class="modal" bsModal #classicModalMessage="bs-modal" [config]="{backdrop: false}" tabindex="-1" role="dialog"
       aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" style="height:200px;">
      <div class="modal-content">
        <div class="modal-body p-xl">
          <h4 class="text-primary text-center"><i class="fa fa-exclamation-triangle fs25"></i> &nbsp;{{globalErrorHandlerService.message}}</h4>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="classicModalMessage.hide();clearErrors();">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
