import {Component, ElementRef, ViewChild, AfterViewInit, Renderer2, Output, EventEmitter, OnDestroy} from '@angular/core';
import {createLocalTracks, LocalDataTrack, LocalTrack, LocalVideoTrack} from 'twilio-video';
import {VideoChatService} from '../services/videochat.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-camera',
  styleUrls: ['./camera.component.scss'],
  templateUrl: './camera.component.html',
})
export class CameraComponent implements AfterViewInit, OnDestroy {

  @ViewChild('preview', { static: false }) previewElement: ElementRef;
  @Output() roomChanged = new EventEmitter<string>();
  @Output() callEnded = new EventEmitter<boolean>();

  isInitializing = true;
  private videoTrack: LocalVideoTrack;
  private localTracks: LocalTrack[] = [];
  showFullScreen = false;

  get tracks(): LocalTrack[] {
    return this.localTracks;
  }

  constructor(
    public videoChatService: VideoChatService,
    public userService: UserService,
    private readonly renderer: Renderer2) { }

  ngOnDestroy(): void {
    this.closeCamera();
  }

  toggleCall() {
    if (!this.videoChatService.activeRoom) {
      this.roomChanged.emit(this.videoChatService.room_name);
      this.videoChatService.inCall = true;
    }
    if (this.videoChatService.activeRoom) {
      this.callEnded.emit(true);
      this.videoChatService.inCall = false;
      this.videoChatService.call_ended = true;
      this.videoChatService.call_cancelled = false;
    }

  }
  async ngAfterViewInit() {
    if (this.videoChatService.inCall) {
      return ;
    }
    if (this.previewElement && this.previewElement.nativeElement) {
      await this.initializeDevice();
      console.log('camera after view init.')
    }
  }

  async initializePreview(deviceInfo ?: MediaDeviceInfo) {
    if (deviceInfo) {
      this.initializeDevice(deviceInfo.kind, deviceInfo.deviceId).then(r => console.log(r));
    } else {
      this.initializeDevice().then(r => console.log(r));
    }
  }

  async finalizePreview() {
    try {
      if (this.videoChatService.videoTrack) {
        this.videoChatService.videoTrack.detach().forEach(element => element.remove());
      }
      if (this.videoChatService.localTracks) {
        this.videoChatService.localTracks.forEach(t => this.closeStream(t));
      }
      this.videoChatService.localTracks = null;
      this.videoChatService.videoTrack = null;

    } catch (e) {
      console.error(e);
    }
  }
  closeCamera() {
    try {
      if (this.videoTrack) {
        this.videoTrack.stop();
        this.videoTrack.detach().forEach(element => element.remove());
      }
      if (this.localTracks) {
        this.localTracks.forEach(t => {
          this.closeStream(t)
        })
      }
    } catch (e) {
      console.error(e);
    }
  }

  closeStream(t: LocalTrack) {
    if (!(t instanceof LocalDataTrack)) {
      t.stop();
      t.mediaStreamTrack.stop();
    }
  }

  private async initializeDevice(kind ?: MediaDeviceKind, deviceId ?: string) {
    try {
      this.isInitializing = true;
      await this.finalizePreview();
      this.videoChatService.localTracks = kind && deviceId
        ? await this.initializeTracks(kind, deviceId)
        : await this.initializeTracks();
      console.log(this.videoChatService.localTracks);
      this.videoChatService.videoTrack = this.videoChatService.localTracks.find(t => t.kind === 'video') as LocalVideoTrack;
      const videoElement = this.videoChatService.videoTrack.attach();
      this.renderer.setStyle(videoElement, 'height', '100%');
      this.renderer.setStyle(videoElement, 'width', '100%');
      this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
    } finally {
      this.isInitializing = false;
    }
  }

  private initializeTracks(kind ?: MediaDeviceKind, deviceId ?: string) {
    if (kind) {
      switch (kind) {
        case 'audioinput':
          return createLocalTracks({logLevel: 'debug', audio: { deviceId }, video: true });
        case 'videoinput':
          return createLocalTracks({logLevel: 'debug', audio: true, video: { deviceId } });
      }
    }

    return createLocalTracks({logLevel: 'debug', audio: true, video: true });
  }
}
