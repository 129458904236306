import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import {Patient} from '../../../doctor/patient';
import { PatientService } from '../../../../services/patient.service'
import {Appointment} from '../../../models/appointment';
import {AppointmentService} from '../../../../services/appointment.service';
import {Router} from '@angular/router';
import {LoaderService} from '../../../../services/loader.service';
import {RatingType, PatientRating}  from '../../../doctor/patient_review';
import {UserService} from '../../../../services/user.service';
import {RatingsService} from '../../../../services/ratings.service';
import {PatientReview} from '../../../../reviews/patient-review';
import {PatientReviewService} from '../../../../reviews/patient-review.service';
import {VideoPanelComponent} from '../../../../video-chat/video-panel/video-panel.component';
import {BraintreeService} from '../../../../services/braintree.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';


@Component({
  selector: 'app-list-appointments',
  templateUrl: './list-appointments.component.html',
  styleUrls: ['./list-appointments.component.scss']
})
export class ListAppointmentsComponent implements OnInit, OnChanges {
  public ratingTypes: RatingType[] = [];
  public patientRatings: PatientRating[] = [];
  // public patientReview: PatientReview = new PatientReview();
  public patient_review: PatientReview = new PatientReview();
  @Input() patient: Patient;
  public appointments_array: Appointment[] = [new Appointment()];
  public medical_team_members: any;
  public values: string[] = ['pending', 'acknowledged', 'cancelled', 'scheduled', 'confirmed', 'checkedin', 'rescheduled', 'noshow', 'checkedout', 'admitted']
  public pending_statuses: string[] = ['pending'];
  public acknowledged_statuses: string[] = ['cancelled', 'rescheduled', 'confirmed', 'scheduled']
  public totalItems: string;
  public current_page = '1';
  showPayment = false;
  payableAppointment: Appointment;
  paymentSuccessful = false;
  @ViewChild('paymentModal', { static: false }) paymentModal: any;

  constructor(public patient_review_service: PatientReviewService, public  loader_service: LoaderService,
              private router: Router, private _patient_service: PatientService, private _appointment_service: AppointmentService,
              public _userService: UserService, public _ratingService: RatingsService,
              private braintreeService: BraintreeService, private errorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit() {
    /*if(this._userService.current_user && this._userService.current_user.isPatientUser()) {
      this._ratingService.patientRatingTypes().then(response => {
        var ratingTypes = response.filter(ratingType => new RatingType().load_from_json(ratingType));
        this.ratingTypes = ratingTypes;
        for(let ratingtype of this.ratingTypes){
          var patientRating = new PatientRating();
          patientRating.rating_type_id = ratingtype.id;
          this.patientRatings.push(patientRating);
        }
        //this.patientReview.patientRatings = this.patientRatings
      });
    }*/
  }

  ngOnChanges() {
    this.get_patient_appointments();
  }

  get_patient_appointments() {
    this.loader_service.show();
    this._patient_service.patient_appointments_with_pagination(this.patient,this.current_page).subscribe(appointments => {
      this.totalItems = appointments.total;
      this.current_page = appointments.current_page;
      this.appointments_array = [];
      for (let patient_appointment of appointments.appointments) {
        let appointment = new Appointment();
        appointment.load_from_json(patient_appointment);
        appointment['status'] = patient_appointment.status_id;
        appointment.status_id = this.defaultStatusValues(appointment.status_id);
        this.appointments_array.push(appointment);
        if (appointment.doctor) {
        appointment['count'] = this.calculate_count(appointment.doctor && appointment.doctor.id);
        }
      }
      console.log(this.appointments_array);
      this.medical_team_members = this.appointments_array.map(function(e) {return e.doctor}).filter((doctor, index, self) => self.findIndex(t => t && t.id === (doctor && doctor.id)) === index);

      for (let medical_team_member of this.medical_team_members ) {
        medical_team_member['count'] = this.calculate_count(medical_team_member.id);
      }
      this.loader_service.hide();

    });
  }
  pageChanged(page) {
    this.current_page = page.page;
    this.get_patient_appointments();
  }


  handle_review(review_added, appointment) {
    appointment.review = review_added;
  }

  calculate_count(doctor_id) {
    return this.appointments_array.filter(appointment => appointment.doctor && appointment.doctor.id === doctor_id).length;
  }

  canChangeStatus(appointment) {
    return this.acknowledged_statuses.filter(st => st.toLowerCase() == (appointment.status && appointment.status.toLowerCase())).length > 0
  }


  defaultStatusValues(status_id): any {
    if (status_id == 'acknowledged') {
      return 3;
    }
    if (status_id == 'pending') {
      let index_value = this.pending_statuses.indexOf(status_id);
      return index_value;
    }
    if (status_id != 'pending') {
      let index_value = this.acknowledged_statuses.indexOf(status_id);
      if (index_value < 0) {
        index_value =  this.values.indexOf(status_id)
      }
      return index_value;
    }
  }

  to_ymd(date) {
    let appointment_date = new Date(date).toDateString();
    return appointment_date;
  }

  changeStatus(value, appointment) {
    let backend_status;
    if (appointment.status == 'pending') {
      let status = this.pending_statuses[value];
      appointment.status = status;
      backend_status = status;
      appointment.status_id = status;
    }
    if (appointment.status != 'pending') {
      let status = this.acknowledged_statuses[value];
      appointment.status = status;
      backend_status = status;
      appointment.status_id = value;
    }
    this._appointment_service.change_status(appointment.id, backend_status, '').then(res => {
    });
  }

  iconMapping(index, status) {
    if (status == 'pending') {
      let status = this.pending_statuses[index];
      return status;
    }
    if (status != 'pending') {
      let status = this.acknowledged_statuses[index];
      return  status;
    }
  }
  gotoDetail(id): void {
    this.router.navigate(['/doctors', id]);
  }
  capitalize(value) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }


  saveReview(patient_review: PatientReview) {
    this.loader_service.small_loder_show();
    this.patient_review_service.save(patient_review)
      .then(resp => {
        patient_review = patient_review.load_from_json(resp)
        this.loader_service.small_loder_hide();
      })
  }

  checkedIn(appointment: Appointment) {
    this._appointment_service.markCheckedIn(appointment.id).then(resp => {
      appointment = appointment.load_from_json(resp);
      appointment['status'] = resp.status_id;
      appointment.status_id = this.defaultStatusValues(appointment.status_id);
    })
  }

  onPayment($event: any) {
    if ($event) {
      this.paymentSuccessful = true;
      this.payableAppointment = undefined;
      this.get_patient_appointments();
    }
  }


  pay(appointment: Appointment) {
    if(appointment.patient.user.braintree_customer_id) {
      this.braintreeService.appointmentPayment('', appointment.id).then(resp => {
        console.log(resp);
        if (resp['success']) {
          this.paymentSuccessful = true;
          this.payableAppointment = undefined;
          this.get_patient_appointments();        }
        else {
          this.errorHandlerService.error = 'Something went wrong.';
        }
      }).catch((resp) => {
        console.log(resp);
        this.errorHandlerService.error = resp.error.error;
        // perform custom validation here or log errors
      });
    } else {
      this.payableAppointment = appointment;
      this.paymentModal.show()
    }
  }
}
