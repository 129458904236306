import {Service} from '../routes/practices/practices/practice';

export class CampaignAssociationsAttributes {
  id: number;
  associationable_id: number;
  associationable_type: string;
  load_from_json(json) {
    this.id = json.id;
    this.associationable_id = json.associationable_id;
    this.associationable_type = json.associationable_type;
    return this;
  }

  to_params() {
    return {
      id: this.id,
      associationable_id: this.associationable_id,
      associationable_type: this.associationable_type
    }
  }

}

export class Campaign {

  id: number;
  name: string;
  sender_name: string;
  recipient_type = '';
  outreach_type: string;
  recipient: number;
  open = 0;
  open_percentage = 0;
  clicks = 0;
  clicks_percentage = 0;
  creator_name: string;
  status = 'draft';
  sent: string;
  action: string;
  subject: string;
  preview_text: string;
  sender_email: string;
  reply_to: string;
  patient_selection = 'all_patients';
  location_type = 'location_not_applicable';
  temp_locations_array: any[] = [];
  doctor_type = 'doctor_not_applicable';
  temp_doctors_array: any[] = [];
  temp_rating_array: any[] = [];
  temp_tags_array: any[] = [];
  temp_e_statuses_array: any[] = [];
  temp_city_array: any[] = [];
  temp_state_array: any[] = [];
  temp_zip_code_array: any[] = [];
  temp_programs_array: any[] = [];
  program_type = 'program_not_applicable';
  patient_review_type = 'review_not_applicable';
  patient_rating_type;
  demographic_type = 'demographic_not_applicable';
  gender_type = 'gender_not_applicable';
  service_type = 'service_not_applicable';
  temp_services_array: any[] = [];
  web_access_type = 'web_access_not_applicable';
  total_audience = 0;
  valid_audience = 0;
  invalid_audience = 0;
  unsubscribe_audience = 0;
  campaign_body = '';
  email_configuration_id: number;
  source_id: number;
  creator_id: number;
  created_at: number;
  runner_id: number;
  runner: string;
  run_at: number;
  source_type: string;
  campaign_message_attributes;
  delivered_count: any;
  delivered_percentage: any;
  failed_count: any;
  failed_percentage: any;
  bounced_count: any;
  bounced_percentage: any;


  selected_docs: any[];
  selected_loc: any[];
  selected_ratings: any[];
  selected_tags: object[];
  selected_city: object[];
  selected_state: object[];
  selected_zip_code: object[];
  selected_services: object[];
  campaign_associations: object[] = [];
  locations_name: string;
  doctors_names: string;
  service_names: string;
  tag_names: string;
  zip_code_names: string;
  state_names: string;
  cities_names: string;
  ratings_name: string;
  selected_e_statuses: any[];
  programs_names: any;
  selected_progs: any[];


  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.recipient_type = json.recipient_type;
    this.outreach_type = json.outreach_type;
    this.recipient = json.recipient_count;
    this.open = json.open;
    this.open_percentage =  json.open_percentage;
    this.clicks = json.clicks;
    this.clicks_percentage = json.clicks_percentage;
    this.status = json.status;
    this.creator_name = json.creator;
    this.status = json.status;
    this.sent = json.sent;
    this.action = json.action;
    this.subject = json.subject;
    this.preview_text = json.preview_text;
    this.sender_email = json.sender_email;
    this.reply_to = json.reply_to;
    this.patient_selection = json.patient_selection;
    this.location_type = json.location_type;
    this.doctor_type = json.doctor_type;
    this.program_type = json.program_type;
    this.patient_review_type = json.patient_review_type;
    this.patient_rating_type = json.patient_rating_type;
    this.demographic_type = json.demographic_type;
    this.gender_type = json.gender_type;
    this.service_type = json.service_type;
    this.web_access_type = json.web_access_type;
    this.total_audience = json.total_audience;
    this.valid_audience = json.audience['valid_audience'];
    this.invalid_audience = json.audience['invalid_audience'];
    this.unsubscribe_audience = json.audience['unsubscribed_audience'];
    this.campaign_body = json.campaign_body;
    this.email_configuration_id = json.email_configuration_id;
    this.sender_name = json.sender_name;
    this.source_id = json.source_id;
    this.runner = json.runner;
    this.run_at = json.ran_at;
    this.delivered_count = json.delivered_count;
    this.delivered_percentage = json.delivered_percentage;
    this.bounced_count = json.bounced_count;
    this.bounced_percentage = json.bounced_percentage;
    this.failed_count = json.failed_count;
    this.failed_percentage = json.failed_percentage;
    this.source_type = json.source_type;
    this.created_at = json.created_at;
    this.selected_docs = json.doctor_ids;
    this.selected_loc = json.location_ids;
    this.selected_e_statuses = json.e_status_ids;
    this.selected_ratings = json.ratings;
    this.selected_tags = json.tag_ids;
    this.selected_city = json.cities_ids;
    this.selected_state = json.state_ids;
    this.selected_zip_code = json.zip_code_ids;
    this.selected_services = json.service_ids;
    this.campaign_associations = json.campaign_associations_attributes;
    this.temp_locations_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Location');
    this.temp_doctors_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Doctor');
    this.temp_rating_array = this.campaign_associations.filter(s => s['associationable_type'] === 'PatientRating');
    this.temp_tags_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Tag');
    this.temp_city_array = this.campaign_associations.filter(s => s['associationable_type'] === 'City');
    this.temp_zip_code_array = this.campaign_associations.filter(s => s['associationable_type'] === 'ZipCode');
    this.temp_state_array = this.campaign_associations.filter(s => s['associationable_type'] === 'State');
    this.temp_services_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Service');
    this.temp_e_statuses_array = this.campaign_associations.filter(s => s['associationable_type'] === 'EStatus');
    this.temp_programs_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Programme');
    this.programs_names = json.programs_names.map(a => ' ' + a + ' ').toString(); ;
    this.locations_name = json.locations_name.map( a => ' ' + a + ' ').toString(); ;
    this.doctors_names = json.doctors_names.map( a => ' ' + a + ' ').toString(); ;
    this.ratings_name = json.ratings.map( a => ' ' + a + ' ').toString(); ;
    this.service_names = json.service_ids.map( a => ' ' + a.text + ' ').toString();
    this.tag_names = json.tag_ids.map( a => ' ' + a.text + ' ').toString();
    this.zip_code_names = json.zip_code_ids.map( a => ' ' + a.text + ' ').toString();
    this.state_names = json.state_ids.map( a => ' ' + a.text + ' ').toString();
    this.cities_names = json.cities_ids.map( a => ' ' + a.text + ' ').toString();
    return this;
  }

  to_params() {
    return {
      campaign: {
        id: this.id,
        name: this.name,
        recipient_type: this.recipient_type,
        outreach_type: this.outreach_type,
        status: this.status,
        sent: this.sent,
        subject: this.subject,
        preview_text: this.preview_text,
        reply_to: this.reply_to,
        campaign_body: this.campaign_body,
        patient_selection: this.patient_selection,
        location_type: this.location_type,
        doctor_type: this.doctor_type,
        program_type: this.program_type,
        patient_review_type: this.patient_review_type,
        patient_rating_type: this.patient_rating_type,
        demographic_type: this.demographic_type,
        gender_type: this.gender_type,
        service_type: this.service_type,
        web_access_type: this.web_access_type,
        email_configuration_id: this.email_configuration_id,
        sender_name: this.sender_name,
        sender_email: this.sender_email,
        source_id: this.source_id,
        source_type: this.source_type,
        creator_id: this.creator_id,
        runner_id: this.runner_id,
        campaign_message_attributes: {body: this.campaign_body, outreach_type: this.outreach_type},
        campaign_associations_attributes : this.set_campaign_associations(),
      }
    }
  }

  saveAsCampaign(campaign) {
    this.name = campaign.name;
    this.recipient_type = campaign.recipient_type;
    this.outreach_type = campaign.outreach_type;
    this.status = 'draft';
    this.creator_name = campaign.creator_name;
    this.subject = campaign.subject;
    this.preview_text = campaign.preview_text;
    this.sender_email = campaign.sender_email;
    this.sender_name = campaign.sender_name;
    this.reply_to = campaign.reply_to;
    this.patient_selection = campaign.patient_selection;
    this.location_type = campaign.location_type;
    this.doctor_type = campaign.doctor_type;
    this.program_type = campaign.program_type;
    this.patient_review_type = campaign.patient_review_type;
    this.patient_rating_type = campaign.patient_rating_type;
    this.demographic_type = campaign.demographic_type;
    this.gender_type = campaign.gender_type;
    this.service_type = campaign.service_type;
    this.web_access_type = campaign.web_access_type;
    this.campaign_body = campaign.campaign_body;
    this.email_configuration_id = campaign.email_configuration_id;
    this.selected_docs = campaign.selected_docs;
    this.selected_loc = campaign.selected_loc;
    this.selected_ratings = campaign.selected_ratings;
    this.selected_tags = campaign.selected_tags;
    this.selected_city = campaign.selected_city;
    this.selected_state = campaign.selected_state;
    this.selected_zip_code = campaign.selected_zip_code;
    this.selected_services = campaign.selected_services;
    this.temp_tags_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Tag');
    this.temp_city_array = this.campaign_associations.filter(s => s['associationable_type'] === 'City');
    this.temp_zip_code_array = this.campaign_associations.filter(s => s['associationable_type'] === 'ZipCode');
    this.temp_state_array = this.campaign_associations.filter(s => s['associationable_type'] === 'State');
    this.temp_services_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Service');
    this.campaign_associations = campaign.campaign_associations.length > 0 ? campaign.campaign_associations.map(s => {return { associationable_type : s.associationable_type, associationable_id : s.associationable_id}}) : [];
    this.temp_locations_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Location');
    this.temp_doctors_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Doctor');
    this.temp_rating_array = this.campaign_associations.filter(s => s['associationable_type'] === 'PatientRating');
    this.temp_e_statuses_array = this.campaign_associations.filter(s => s['associationable_type'] === 'EStatus');
    this.temp_programs_array = this.campaign_associations.filter(s => s['associationable_type'] === 'Programme');
    this.campaign_associations = [];
    return this;

  }
  set_campaign_associations() {
    let campaign_associations_attributes = [];
    if (this.recipient_type === 'patients') {
      this.temp_services_array.length > 0 ? this.temp_services_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_locations_array.length > 0 ? this.temp_locations_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_doctors_array.length > 0 ? this.temp_doctors_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_rating_array.length > 0 ? this.temp_rating_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_tags_array.length > 0 ? this.temp_tags_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_city_array.length > 0 ? this.temp_city_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_state_array.length > 0 ? this.temp_state_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_zip_code_array.length > 0 ? this.temp_zip_code_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_e_statuses_array.length > 0 ? this.temp_e_statuses_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      this.temp_programs_array.length > 0 ? this.temp_programs_array.forEach(a => {if (!this.containsObject(a, campaign_associations_attributes)) {campaign_associations_attributes.push(a)}}) : '';
      return campaign_associations_attributes;
    }
  }

   containsObject(obj, list) {
     let i;
     for (i = 0; i < list.length; i++) {
       if (list[i].associationable_type === obj.associationable_type && list[i].associationable_id === obj.associationable_id) {
         return true;
       }
     }
     return false;
  }
  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }
}
