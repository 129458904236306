import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {Practice} from '../practices/practice';
import {BraintreeService} from '../../../services/braintree.service';
import {InvoiceService} from '../../../services/invoice.service';
import {Invoice} from './invoice';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import {TabsService} from '../../../services/tabs.service';
import {Tab} from '../../../tabs/tab';
import {InvoicePrintComponent} from './invoice-print/invoice-print.component';
import {PackagesService} from '../../../services/packages.service';
import {UserService} from '../../../services/user.service';
import {AlertService} from '../../../_alert';
export interface Package {
  name: string;
  id: number;
  package_type: string;
}
export interface PracticePackagesInfo {
  basic_packages: Package[];
  add_on_packages: Package[];
  selected_basic_package: Package;
  selected_add_on_packages: Package[];
  practice_id: number;
}
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  @Input() practice: Practice;
  public practicePackagesInfo: PracticePackagesInfo;
  public update_card = false;
  public paymentMethod: any;
  invoices: Invoice[];
  addPaymentMethod = false;
  selectedInvoice: Invoice;
  paid = false;
  printableInvoice: Invoice;
  success = false;
  @ViewChild('bsModal') bsModal;
  @ViewChild('deleteModal') deleteModal;
  modalFor: string;
  MODAL_TITLES = {
    invoiceModel: 'Generate Invoice',
    card: 'Payment Information',
  };
  constructor(private loaderService: LoaderService, private globalErrorHandler: GlobalErrorHandlerService,
              private invoiceService: InvoiceService, private braintreeService: BraintreeService,
              private _tab_service: TabsService, public packageService: PackagesService,
              public userService: UserService, public alertService: AlertService) {

  }
  ngOnInit() {
    this.getPracticePackages();
    this.getPaymentMethod();
    this.getInvoices();
  }
  getInvoices() {
    this.invoiceService.invoices(this.practice.practice_package.id).then(resp => {
      this.invoices = resp.map(a => new Invoice().load_from_json(a));
    })
  }
  getPracticePackages() {
    this.packageService.getPracticePackages(this.practice.id).subscribe(
      resp => {
        this.practicePackagesInfo = resp;
        this.practicePackagesInfo.practice_id = this.practice.id;
      },error => {
        console.log('error', error)
      }
    )

  }
  changePackage(id) {
    this.practicePackagesInfo.selected_basic_package = this.practicePackagesInfo.basic_packages.filter(p => p.id === parseInt(id, 10))[0];
    if (this.practicePackagesInfo.selected_basic_package.name === 'free') {
      this.practicePackagesInfo.selected_add_on_packages = [];
      return;
    }
    if (this.practicePackagesInfo.selected_basic_package.name === 'ultimate') {
      this.practicePackagesInfo.selected_add_on_packages = this.practicePackagesInfo.add_on_packages.filter((p) =>
        (p.name !== 'template' && p.name !== 'care_management'));
      return;
    }
    this.practicePackagesInfo.selected_add_on_packages = [];
    return;
  }
  changeAddOns(e) {
    if (e.target.checked) {
      let selected_add_on = this.practicePackagesInfo.add_on_packages.filter(p => p.id === parseInt(e.target.value, 10))[0];
      this.practicePackagesInfo.selected_add_on_packages.push(selected_add_on);
    }
    if (!e.target.checked) {
      let selected_add_on = this.practicePackagesInfo.add_on_packages.filter(p => p.id === parseInt(e.target.value, 10))[0];
      // tslint:disable-next-line:max-line-length
      this.practicePackagesInfo.selected_add_on_packages = this.practicePackagesInfo.selected_add_on_packages.filter(p => p.id !== selected_add_on.id)
    }
    console.log(this.practicePackagesInfo.selected_add_on_packages);
    // let selected_add_on = this.practicePackagesInfo.add_on_packages.filter(p => p.id === e.target.value)[0];
    // if (this.practicePackagesInfo.selected_add_on_packages.filter(p => p.id === selected_add_on.id).length === 0) {
    //   this.practicePackagesInfo.selected_add_on_packages.push(selected_add_on);
    // }
  }

  capitalize(value) {
    return value.replace(/_/g, ' ');
  }

  updatePracticePackage() {
    let params = {
      selected_add_on_packages: this.practicePackagesInfo.selected_add_on_packages.map(p => p.id),
      selected_basic_package: this.practicePackagesInfo.selected_basic_package.id,
      practice_id: this.practicePackagesInfo.practice_id};
    this.packageService.updatePackage(params).subscribe(resp => {
      this.success = true;
      setTimeout(() => this.success = false, 5000)
    }, error => {
      console.log(error);
    })
  }


  getPaymentMethod() {
    this.loaderService.show();
    this.braintreeService.brainTreeCustomer(this.practice.id).then(resp => {
      this.paymentMethod = resp;
      this.loaderService.hide();
    }).catch(resp => {
      console.log(resp);
      this.loaderService.hide();
      this.globalErrorHandler.error = 'Payment method is not added.'
    })
  }

  openNewModal(modalFor: string) {
    this.modalFor = modalFor;
    this.bsModal.show();
  }

  showTitle() {
    return this.MODAL_TITLES[this.modalFor] || '';
  }
  newInvoice() {
    this.selectedInvoice = new Invoice();
  }
  paymentMethodUpdate() {
    this.addPaymentMethod = false;
    this.getPaymentMethod();
    this.bsModal.hide();
    this.modalFor = '';
  }

  payInvoice(invoice: Invoice) {
    if (!this.paymentMethod) {
      this.globalErrorHandler.error = 'No credit card available to process this payment.'
      return ;
    }
    invoice.paying = true;
    console.log(this.practice);
    this.braintreeService.invoicePayment(this.practice.braintree_customer_id, invoice.id).then(resp => {
        let invoice = this.invoices.filter(a => a.id === resp['id'])[0];
        invoice = invoice.load_from_json(resp);
        this.paid = true;
        invoice.paying = false;
        setTimeout(() => {
          this.paid = false;
        }, 3000)

    }).catch(resp => {
      invoice.paying = false;
      this.globalErrorHandler.error = resp.error.error;
    })
  }

  add_to_tabs(invoice: Invoice) {
    this._tab_service.add_tab(new Tab({
      title: 'Invoice',
      icon: 'fas fa-print'
    }, InvoicePrintComponent, invoice.id, {invoice: invoice}))
  }

  selectedAddOns(id: number) {
    return this.practicePackagesInfo.selected_add_on_packages.filter(add_on => add_on.id === id).length > 0
  }

  deleteInvoice(invoice_id: any) {
      this.invoiceService.delete(invoice_id).then(() => {
        const index = this.invoices.findIndex(i => i.id === invoice_id);
        if (index !== -1) {
          this.invoices.splice(index, 1);
          console.log('Invoice deleted successfully');
          this.deleteModal.hide();
          this.alertService.success('Invoice has been deleted successfully', this.alertService.options);
        }
      }).catch(error => {
        this.deleteModal.hide();
        this.globalErrorHandler.error = error.error.error
      });
  }

}
