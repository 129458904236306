<h4 class="title">Goals
  <a (click)="newGoalModal.show()" title="Add Goal"><i class="fa fa-plus ml-sm"></i></a>
</h4>
<ul class="collapse-data-list border-list list-unstyled m0">
  <ng-container *ngFor="let goal of enrolment_goals">
    <li class="list">
      <a (click)="setCustomInjector(goal)">{{goal.name}}</a>
      <a class="pull-right" (click)="startAction('comment');selected_goal = goal; commentsModal.show();" title="Add Comments">
        <i class="fa fa-plus"></i>
      </a>
      <span *ngIf="goal.comment_count > 0" title="Number Of Comments" class="label label-success label-counter pull-right mr">
        {{goal.comment_count}}
      </span>
      <input type="range" name="goal_progress" [(ngModel)]="goal.progress" (change)="setGoalProgress($event,goal)" tooltip="{{goal.progress}}"/>
    </li>
  </ng-container>
  <app-no-result-found *ngIf="enrolment_goals && enrolment_goals.length == 0"></app-no-result-found>
</ul>

<div class="modal fade" bsModal #commentsModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:300px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="commentsModal.hide(); trackingService.deleteTrackingObject(actionId)"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Add Comments</h4>
      </div>
      <div class="modal-body">
        <label class="field-label">Comment*</label>
        <textarea  rows="5" type="text" class="form-control" [(ngModel)]="comment_body" name="comment_body"></textarea>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" [disabled]="!comment_body" (click)="addCommentOnGoal();">Add</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="commentsModal.hide(); selected_goal = undefined; trackingService.deleteTrackingObject(actionId)">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #newGoalModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:405px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newGoalModal.hide(); trackingService.deleteTrackingObject(actionId)"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Add Goals</h4>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label class="field-label">Name*</label>
          <textarea  rows="1" cols="120" type="text" class="form-control" [(ngModel)]="goal.name" name="goal_name"></textarea>
        </div>
        <div class="form-group">
          <label class="field-label">Description*</label>
          <textarea  rows="5" type="text" class="form-control" [(ngModel)]="goal.description" name="comment_body"></textarea>
        </div>
        <div class="">
          <label class="field-label">Status*</label>
          <select #status class="form-control pl0 text-capitalize" name="status_id" [(ngModel)]="goal.status">
            <option *ngFor="let status of statuses; let i = index;" [ngValue]="status">
              {{userService.removeUnderScore(status)}}
            </option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" [disabled]="!checkVaildGoalForm()" (click)="newGoal();">Save</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="newGoalModal.hide(); trackingService.deleteTrackingObject(actionId)">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #viewGoalModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-lg" style="height:600px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="viewGoalModel.hide(); trackingService.deleteTrackingObject(actionId)"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Goal</h4>
      </div>
      <div class="modal-body" *ngIf="show_goal_model">
        <ng-container *ngComponentOutlet="goalDetailsComponent;injector: custom_injector;"></ng-container>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="viewGoalModel.hide(); trackingService.deleteTrackingObject(actionId)">Cancel</button>
      </div>
    </div>
  </div>
</div>
