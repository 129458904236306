<section class="main-wrapper">
  <a routerlink="/" class="logo" href="/"><img class="img-responsive" alt="WellWink" src="assets/img/branding/logo.png"></a>
  <div class="container">
    <div class="border-box shadow noscroll">
      <div class="row">
        <div class="col-sm-6">
          <h4 class="modal-header m0"><i class="icon icon-key fs22 vl-m"></i> Forgot Password?</h4>
          <div class="modal-body">
            <p class="text-tertiary fs14 mb-lg">No worries, we'll send you reset instructions.</p>
            <form #resetPasswordForm="ngForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <input type="email"
                       class="form-control"
                       placeholder="Enter your email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                       required
                       [(ngModel)]="_resetPasswordData.email"
                       name="email">
                <ng-container *ngIf="show_notice">
                  <p class="text-danger">{{notice}}</p>
                </ng-container>
              </div>
              <button type="submit" class="btn btn-primary btn-min" [disabled]="!resetPasswordForm.form.valid || disable_button">Reset</button>
              <ng-container *ngIf="show_message">
                <p class="alert alert-success p text-center">Email has been sent on your account with instructions to change the password.</p>
              </ng-container>
              <a href="/" class="center-block mt-xl"><i class="fa fa-long-arrow-left"></i> Back to home</a>
            </form>
          </div>
        </div>
        <div class="col-sm-6 hidden-xs bg-col"></div>
      </div>
      <div class="modal-footer text-muted text-center">&copy; {{settings.app.year}} - {{ settings.app.name }}</div>
    </div>
  </div>
</section>

<style>
  .main-wrapper{ display: flex; flex-direction: column; justify-content: center; align-items: center; height:100vh;}
  .main-wrapper .logo{ display:inline-flex; margin:0 0 20px;}
  .main-wrapper .logo img{ max-width:150px;}
  .main-wrapper .container{ max-width:900px;}
  .main-wrapper .bg-col{ background-size:cover; background-position:center top; background-image: url("assets/img/bg5.jpg");
    min-height:300px; margin:0 0 0 -15px; padding:0;
  }
</style>
