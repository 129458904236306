import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {PatientReview} from '../patient-review';
import {PracticeReviewService} from '../practice_review.service';
import {LoaderService} from '../../services/loader.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {PrintService} from '../../services/print.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-review-report-printer',
  templateUrl: './review-report-printer.component.html',
  styleUrls: ['./review-report-printer.component.scss']
})
export class ReviewReportPrinterComponent implements OnInit {
  @ViewChild('report', { static: false }) report: ElementRef | undefined;

  public reviews: PatientReview[];
  public filter: Object;
  public stats: Object;
  showReport = false;
  public currentTime = new Date();

  constructor(public reviewsService: PracticeReviewService, public injector: Injector, public printService: PrintService,
              public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public userService: UserService) { }
  ngOnInit(): void {
    this.filter = this.injector.get('filter', null);
    this.getReviewReport();
  }
  getReviewReport() {
    this.loaderService.show();
    this.reviewsService.getReviewReport(this.filter).then(resp => {
      this.reviews = resp.reviews.map(review => new PatientReview().load_from_json(review));
      this.stats = resp;
      this.showReport = true;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }
  generatePdf(action: string) {
    this.printService.generatePdf(action, this.report, true, 'ReputationManagement.pdf');
  }
}
