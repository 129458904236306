import { Injectable }     from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Staff } from '../routes/practices/contacts/contact'
import {CustomHttpClient} from './custom-http-client.service';


@Injectable()
export class ContactService {

  constructor(private _tokenService: CustomHttpClient) { }
  create(staff: Staff): Promise<any> {
    return this._tokenService
      .post(staff.get_url(), staff.to_params())
      .toPromise()
  }

  save(staff: Staff): Promise<any> {
    if (staff.is_new_record()) {

      return this.create(staff);
    }
    else {
      return this.update(staff);
    }
  }

  update(staff: Staff) {
    return this._tokenService
      .put(staff.get_url(), staff.to_params())
      .toPromise()
  }
  delete(staff_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('staff_id', staff_id);

    return this._tokenService.delete('staffs', {
       params: params
    }).toPromise().then(response => response);
  }

  getContactUsers(id: any): Promise<any[]> {

    let params: HttpParams = new HttpParams();
    params = params.set('practice_id', id);
    return this._tokenService.get('staffs/staff_with_users', {
      params: params
    }).toPromise()
      .then(response => response);
  }
  getContacts(id: any): Promise<any[]> {

    let params: HttpParams = new HttpParams();
    params = params.set('practice_id', id);
    return this._tokenService.get('staffs', {
       params: params
    }).toPromise()
      .then(response => response);
  }
  private handleError(error: any): Promise<any> {
    return Promise.resolve(error || error);
  }
  get_practice_users(): Promise<any[]> {
    return this._tokenService.get('staffs/practice_users')
      .toPromise()
      .then(response => response);
  }
  getAllstaffs(): Promise<any> {
    return this._tokenService.get('staffs/get_all_staffs')
      .toPromise()
      .then(response => response);
  }
  get_practice_staff(practice_id: any, term: any): Observable<any[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('practice_id', practice_id);
    return this._tokenService.get('staffs/practice_staffs/?term=' + term, {
       params: params
    }).map((r) => r );
  }
  getOnlyStaff(practice_id: any, term: any): Observable<any[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('practice_id', practice_id);
    return this._tokenService.get('staffs/only_staff/?term=' + term, {
       params: params
    }).map((r) => r);
  }
}
