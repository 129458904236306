<form (ngSubmit)="save()" #announcementForm="ngForm" class="form-horizontal mt-lg mb-lg p-relative">
  <div class="row">
    <div class="col-sm-12 form-group">
      <div class="col-sm-2">
        Title*
      </div>
      <div class="col-sm-7">
        <input type="text" class="form-control" id="title" [(ngModel)]="announcement.name"
               name="title"
               placeholder="Title" required>
      </div>
      <div *ngIf="customValidations.formErrors.title" class="text-danger">
        {{ customValidations.formErrors.title }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 form-group">
      <div class="col-sm-2 ">Detail</div>
      <div class="col-sm-7">
         <textarea cols="120" rows="8" type="text" class="form-control" id="detail"
                   [(ngModel)]="announcement.description"
                   name="detail"
                   required>
              </textarea>
      </div>
      <div *ngIf="customValidations.formErrors.detail" class="text-danger">
        {{ customValidations.formErrors.detail }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 form-group">
      <div class="col-sm-4 p0">
        <div class="col-sm-3">Portal*</div>
        <div class="col-sm-offset-2 col-sm-6">
          <select #role_type name="portal" (change)="onSelect(role_type)" [(ngModel)]="announcement.role_type_id"
                  class="pl0 form-control" required>
            <option  [selected]="announcement.role_type_id == roleType.id"  *ngFor="let roleType of roleTypes; let i = index;" [id]="roleType.name" [value]="roleType.id">
              {{roleType.name}}
            </option>
          </select>
          <!-- <div *ngIf="customValidations.formErrors.portal" class="text-danger">
             {{ customValidations.formErrors.portal }}
           </div>-->
        </div>
      </div>
      <div class="col-sm-4 p0" >
        <div class="col-sm-4" >User Type</div>
        <div class="col-sm-offset-2 col-sm-6">

          <angular2-multiselect *ngIf="announcement.role_type_id && roleTypes" [(ngModel)]="announcement.selected_roles"
                                [settings]="dropdownSettings"
                                [data]="getRolesOfSelectedRoleType(announcement.role_type_id)" name="test"
                                (onSelect)="OnItemSelect($event)"
                                (onDeSelect)="OnItemDeSelect($event)"
                                (onSelectAll)="onSelectAll($event)"
                                (onDeSelectAll)="onDeSelectAll($event)"></angular2-multiselect>

        </div>
      </div>
      <div class="col-sm-3">
        <div class="col-sm-4">Status</div>
        <div class="col-sm-offset-2 col-sm-6">
          <select class="form-control pl0" name="status" [(ngModel)]="announcement.status">
            <option [selected]="true" [value]="'active'">
              Active
            </option>
            <option [value]="'inactive'">
              Inactive
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8 text-center">
      <button type="submit" class="btn btn-secondary mr-sm">{{announcement.id ? 'Update' : 'Create'}}</button>
      <button class="btn btn-tertiary btn-outline" (click)="closeNewForm()">Cancel</button>
    </div>
  </div>
  <div *ngIf="errorMessage" class="row">
    <div class="mt-lg col-sm-8 text-center text-danger">
      {{errorMessage}}
    </div>
  </div>
</form>
