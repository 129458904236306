import {Component, OnInit, Injector} from '@angular/core';
import {AppointmentService} from '../../../services/appointment.service';
import {Appointment} from '../../../health/models/appointment';
import {VitalsService} from '../../../services/vitals.service';
import {PatientVital} from './PatientVital';
import {Vital} from './vital';
import {Patient} from '../../../health/doctor/patient';
import {PatientService} from '../../../services/patient.service';
import {VitalEntry} from './VitalEntry';
import {VitalPdfPrinterComponent} from './vital-pdf-printer/vital-pdf-printer.component';
import {TabsService} from '../../../services/tabs.service';
import {Tab} from '../../../tabs/tab';
import {ProgramService} from '../../../program/program.service';

@Component({
  selector: 'app-vital',
  templateUrl: './vital.component.html',
  styleUrls: ['./vital.component.scss']
})
export class VitalComponent implements OnInit {
  public appointment: Appointment;
  public patient: Patient;
  public patient_vital: PatientVital;
  public patient_vitals: PatientVital[] = [];
  public vitals: Vital[] = [];
  public message: string;
  public show_save_button = true;
  public disableMe = false;
  public spo2_error = false;

  constructor(private injector: Injector, private patientService: PatientService, private vitalsService: VitalsService,
              private _appointmentService: AppointmentService, private _tabs_service: TabsService,
              public programService: ProgramService) {}

  ngOnInit() {
    let patient_id = this.injector.get('patient_id', null);
    this.patient_vital = this.injector.get('patient_vital', null);
    if (patient_id) {
      this.fetchPatient(patient_id);
    } else {
      let appointment_id = this.injector.get('id', null);
      if (appointment_id) {
        this.fetchAppointment(appointment_id);
      }
    }
  }

  selectRhythem(e) {
    this.patient_vital.pulse.rhythm = e.target.value;
  }

  selectDifficulty(e) {
    this.patient_vital.respiration.difficulty = e.target.value;
  }

  setPatientWeight() {
    this.patient_vital.weight.value = this.patient_vital.weightInKgs();
  }
  CalBmi() {
    // if (this.patient_vital.weight.value === undefined ||
    //   this.patient_vital.weight.value === 0 ||
    //   this.patient_vital.heightInFeet === undefined ||
    //   this.patient_vital.heightInFeet === '' ||
    //   this.patient_vital.heightInFeet === 0) {
    //   return;
    // }
    if (!this.patient_vital.p_weight || !this.patient_vital.heightInInch ||
      this.patient_vital.p_weight == 0 || this.patient_vital.heightInInch == 0) {
      return;
    }
    let h = this.patient_vital.heightInInch;
    let w = this.patient_vital.weightInKgs();
     // let h = this.patient_vital.feetToInches();
    this.patient_vital.bodyMassIndex.value = (w / ((h * 0.0254) * (h * 0.0254))).toPrecision(4);
    this.changeVital('bmi')
  }

  fetchPatient(id) {
    this.patientService.getPatient(id).then(resp => {
      this.patient = new Patient().load_from_json(resp);
      this.fetchVitals();
    })
  }

  fetchAppointment(id) {
    this._appointmentService.getAppointment(id).then(json => {
      this.appointment = new Appointment().load_from_json(json);
      if (this.appointment) {
        this.vitalsService.getAppointmentVitals(this.appointment.id).then(resp => {
          this.patient_vitals = resp.map(patient_vital => new PatientVital().load_from_json(patient_vital));
          this.fetchVitals();
        })
      }
    })
  }

  fetchVitals() {
    let patient_id = this.appointment ? this.appointment.patient_id : this.patient.id;
    this.vitalsService.getVitals(patient_id).then(resp => {
      this.vitals = resp.map(vital => new Vital().load_from_json(vital));
      if (this.patient_vitals.length > 0 || this.patient_vital) {
        this.patient_vital = this.patient_vital || this.patient_vitals[0];
      } else {
        this.initializeVitalEntries();
      }
    })
  }

  initializeVitalEntries() {
    this.patient_vital = new PatientVital();
    this.patient_vital.vital_entries = this.vitals.filter(v => !Vital.independent_vitals.includes(v.short))
      .map(vital => new VitalEntry().initializeVitalEntry(vital))
    this.patient_vital.initializeIndependentVitalEntry(this.vitals);
    console.log(this.patient_vital.vital_entries);
  }

  save() {
    this.show_save_button = false;
    this.setVitalable();
    this.patient_vital.heightCalcInInches();
    this.setPatientWeight();
    this.vitalsService.save(this.patient_vital).then(resp => {
      this.programService.reloadEnrolledPatientHead.next(resp[0]);
      this.vitalsService.reloadEnrolledPatientVitalsList.next(resp[0]);
      this.patient_vitals = resp.map(patient_vital => new PatientVital().load_from_json(patient_vital));
      this.message = 'Vital has been saved successfully.';
      this.show_save_button = true;
      this.vitalsService.load_latest_vital = true;
      if (this.patient_vital.is_new_record()) {
        this.patient_vital = this.patient_vitals[0];
      } else {
        this.patient_vital = this.patient_vitals.filter(p_v => p_v.id === this.patient_vital.id)[0];
      }
      setTimeout(() => {
        this.message = '';
      }, 5000)
    }).catch(error => {
      this.show_save_button = true;
      console.log(error)
    })
  }

  setVitalable() {
    if (this.patient) {
      this.patient_vital.patient_id = this.patient.id;
    }
    if (this.appointment) {
      this.patient_vital.patient_id = this.appointment.patient_id;
      this.patient_vital.vitalable_id = this.appointment.id;
      this.patient_vital.vitalable_type = this.appointment.class_name;
    }

  }

  add_to_tabs(title) {
    let appointment_id = this.appointment.id + title;
    this._tabs_service.add_tab(new Tab({
        title: this.appointment.patient_name() + '-' + title,
        icon: 'fa fa-hospital', autorefresh: true
      }, VitalPdfPrinterComponent, appointment_id,
      {
        source_type: 'Appointment', source_id: this.appointment.id,
        patient_id: this.appointment.patient_id,
        patientVitalId: this.patient_vital.id,
        is_added_to_chart: this.patient_vital.added_to_medical_chart,
        template_name: "Vitals"
      })
    )
  }

  test() {
    if (!this.patient_vital.sBP.value || !this.patient_vital.dBP.value) {
      this.disableMe = true;
    }
    if (!this.patient_vital.sBP.value && !this.patient_vital.dBP.value) {
      this.disableMe = false;
    }
    if (this.patient_vital.sBP.value && this.patient_vital.dBP.value) {
      this.disableMe = false;
    }
  }

  selectGlucoseType(value) {
    let g_value = this.patient_vital.glucose_value;
    console.log(value);
    if (value === '') {
      return;
    }
    let vital_type = value === 'fasting' ? 'fbg' : 'rbg';
    let other_type = value === 'fasting' ? 'rbg' : 'fbg';
    let vital = this.vitals.filter(v => v.short === vital_type)[0];
    let vital_entry = this.patient_vital.vital_entries.filter(v => v.vital.short === vital_type)[0];
    if (vital_entry) {
      vital_entry.value = this.patient_vital.glucose_value;
      vital_entry.initializeVitalEntry(vital)
    } else {
      let v_entry = new VitalEntry().initializeVitalEntry(vital)
      v_entry.value = this.patient_vital.glucose_value;
      this.patient_vital.vital_entries.push(v_entry);
    }
    this.patient_vital.vital_entries.forEach(v => {
      if (v.vital.short === other_type) {
        if (v.id) {
          v._destroy = true
        } else {
          let index = this.patient_vital.vital_entries.indexOf(v);
          if (index !== -1) {
            this.patient_vital.vital_entries.splice(index, 1)
          }
        }
      }
    });
    console.log(this.patient_vital.vital_entries);
  }

  changeVital(type) {
    let entry = this.patient_vital.vital_entries.filter(e => e.vital.short === type)[0];
    if (type === 'spo2' && (entry.value < 1 || entry.value > 100)) {
      this.spo2_error = true;
      return;
    }
    this.spo2_error = false;
    let vital = this.vitals.filter(v => v.short === type).filter(v => entry.value >= v.min_val && entry.value <= v.max_val)[0];
    if (vital) {
      entry.vital = vital;
      entry.vital_id = vital.id;
    }
    console.log(this.patient_vital.vital_entries);
  }

  disabled() {
   return this.disableMe || !(this.patient_vital.temperature.value
     || this.patient_vital.respiration.value
     || this.patient_vital.pulse.value
     || this.patient_vital.sBP.value
     || this.patient_vital.dBP.value
     || this.patient_vital.p_weight
     || this.patient_vital.heightInInch
     || this.patient_vital.heightInFeet
     || this.patient_vital.glucose_value
     || this.patient_vital.nprs.value
     || this.patient_vital.spo2.value
   )
  }
}

