import { Injectable } from '@angular/core';
import {UserService} from './user.service';
import {Patient} from '../health/doctor/patient';
import {PatientService} from './patient.service';
import {GlobalErrorHandlerService} from './global-error.service';

@Injectable()
export class PatientAuthorizationService {
  public verified = true;
  public unathorizedPatient: Patient;
  public type: 'sms' | 'kba' | '';
  public data = {mobile_otp: '', email_otp: '', mother_name: ''};
  public message_class = '';
  public mobile_message = '';
  public email_message = '';
  public general_message = '';
  public hint: string;
  constructor(private _user_service: UserService, private _patient_service: PatientService, private globalErrorHandlerService: GlobalErrorHandlerService) { }
  checkAuthorization(patient, authorizationModal) {
    if (!this.isAuthorized(patient)) {
      this.verified = false;
      authorizationModal.show();
      this.unathorizedPatient = new Patient().load_from_json(patient);
    }
  }
  isAuthorized(patient): boolean {
    let result = patient.patient_practices_id.filter((n) => {
      return this._user_service.current_user.practices_id.indexOf(n) > -1
    });
    return result.length > 0;
  }
  authorizationType(type) {
    this.type = type;
    if (this.type == 'sms') {
      this.sendOtp('sms', '')
    }
  }

  sendOtp(type, data) {
    this.type = type;
    return this._patient_service.sendOtpForPatientAuthorization({
      patient_id: this.unathorizedPatient.id,
      type: type,
      data: data
    }).then(response => {
      this.general_message = response.message;
      this.message_class = 'success';
      this.clear_messages();
    }).catch(res => {
      this.globalErrorHandlerService.error = res.error.error;
      this.general_message = res.message;
      this.message_class = 'failed';
      this.clear_messages();
    })
  }
  clear_messages() {
    setTimeout(() => {
      this.mobile_message = '';
      this.email_message = '';
      this.general_message = '';
      this.data.mobile_otp = '';
      this.data.mother_name = '';
    }, 3500);
  }


  closeModal(authorizationModal) {
    authorizationModal.hide();
    this.type = '';
    this.message_class = '';
    this.unathorizedPatient = undefined;
    this.verified = true;
  }
  highlightQuery(str, query): any {
    query = query.join(' ');
    if (str.toLocaleLowerCase().indexOf(query) > -1) {
      let left_str = str.substring(0, str.toLocaleLowerCase().indexOf(query));
      let right_str = str.substring(str.substring(0, str.toLocaleLowerCase().indexOf(query)).length + query.length, str.lenght);
      console.log(left_str + query + right_str);
      return '<h5>' + left_str + '<strong>' + query + '</strong>' + right_str + '</h5>';
    }
    else {
      return '<h5>' + str + '</h5>';
    }
  }
}
