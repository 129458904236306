<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Appointment Booking</h1>
      <h4 class="sub-title fs22 text-primary">
        Book appointment anywhere anytime - office visit, virtual visit or walk-in.
      </h4>
      <h4 class="sub-title text-white">
        Despite having conventional phone booking, engage patients by providing innovative ways to search available
        locations / doctors and offer book appointment online for better patient satisfaction.
      </h4>
      <h4 class="sub-title text-white">
        An online booking system enables you to maintain an accurate calendar almost effortlessly. Staff can quickly
        access the system to check when they have appointments, the type of services they need to provide for each
        appointment, what patients they'll be seeing, and where they will be holding the appointments.
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad appointment-widget">
  <div class="container">
    <div class="text-center">
      <h1 class="mt0">Appointment Widget</h1>
      <br>
      <p>Bring more patients to your practice website</p>
      <p>
        Place online appointment booking widget on your website and let patients’ book office visit or virtual visit
        seamlessly from anywhere anytime. Appointment request is received instantly via email and accessible from WellWink.
        If WellWink is integrated, appointment is instantly pushed to Practice Management System. It is that simple.
      </p>
    </div>
  </div>
</section>
<section class="sec-pad bg-gray-lighter">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-12 text-container">
        <h1>Find A Doctor</h1>
        <br>
        <p class="m0">
          Patient can easily find a doctor by specialty, service type or doctors location and can book appointment from
          WellWink website. Patients just need to select a doctor from available doctors for office visit or select On
          Call doctor for virtual visit.
          <br><br>
          Before booking an appointment patients have the option to review doctor’s online
          profile and verified reviews all at one place.
        </p>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12 img-container">
        <img class="img-responsive" src="/assets/img/public/find-doctor.jpg"/>
      </div>
    </div>
  </div>
</section>
<section class="combine-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="text-sec">
          <h1>Walk-In Patients</h1>
          <br>
          <p class="m0">
            A Patient can walk-in without a scheduled appointment, front office staff can quickly check doctor’s availability
            and book walk-in patient directly on an available slot. However, if doctor is fully booked, front office has
            the option to check-in a patient in waiting list.
            <br><br>
            Doctors in the office can monitor patient’s waiting time in the waiting area.
          </p>
        </div>
      </div>
      <div class="col-md-6 waiting-col">
        <div class="text-sec">
          <h1>Waiting List</h1>
          <br>
          <p class="m0">
            Usually provider sees scheduled patients on priority. If doctor is fully booked, a patient can be placed in
            waiting list, if opted. Wellwink maintains a waiting list by doctor.
            <br><br>
            Waiting list is available for phone booking and walked-in patients. Doctor can set the priority after seeing scheduled patients.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
