import { Injectable } from '@angular/core';
import {TabsService} from '../../../services/tabs.service';
import {CustomHttpClient} from '../../../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {Amenity, AmenityAssociate, BookingMethod} from './amenity';
import {Subject} from 'rxjs/index';
import {nullRemover} from '../../../services/nullRemover';

@Injectable({
  providedIn: 'root'
})
export class AmenityService {

  reloadamenity: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient, public tabsService: TabsService) { }

  getAmenities(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('amenities', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          amenities: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  create(amenity: Amenity): Promise<any> {
    return this._tokenService
      .post('amenities', amenity.to_params())
      .toPromise()
      .then(response => response)
  }

  save(amenity: Amenity): Promise<any> {
    if (amenity.is_new_record()) {

      return this.create(amenity);
    }
    else {
      return this.update(amenity);
    }
  }

  update(amenity: Amenity) {
    return this._tokenService
      .put('amenities/' + amenity.id, amenity.to_params())
      .toPromise()
      .then(response => response)
  }

  delete(amenity: Amenity) {
    return this._tokenService
      .delete('amenities?id=' + amenity)
      .toPromise()
      .then(response => response)
  }

  getBookingTypes() {
    return this._tokenService.get('amenities/booking_types')
      .map((resp) => resp);

  }
  serachAmenities(token) {
    let args = new HttpParams();
    args = args.set('token', token);
    return this._tokenService.get('amenities/search', {params: args})
      .toPromise()
      .then(response => response)
  }

  fetchAmenities(location_id, doctor_id) {
    let args = new HttpParams();
    args = args.set('location_id', location_id);
    args = args.set('doctor_id', doctor_id);
    return this._tokenService.get('amenities/fetch_amenities', {params: args})
      .toPromise()
      .then(response => response)
  }

  createAssociateAmenity(amenity_associator: AmenityAssociate): Promise<any> {
    return this._tokenService
      .post('amenities/amenity_associator',
        {amenity_associator: {amenity_id: amenity_associator.id, doctor_id : amenity_associator.doctor_id}})
      .toPromise()
      .then(response => response)
  }
  updateAssociateAmenity(amenity_associator: AmenityAssociate): Promise<any> {
    return this._tokenService
      .put('amenities/amenity_associator/' + amenity_associator.id, {amenity_associator : amenity_associator.to_params()})
      .toPromise()
      .then(response => response)
  }
  deleteAssociateAmenity(amenity_associator: AmenityAssociate) {
    return this._tokenService
      .delete('amenities/amenity_associators?id=' + amenity_associator)
      .toPromise()
      .then(response => response)
  }


  updateAssociateAmenityFee(amenity_associator: BookingMethod): Promise<any> {
    return this._tokenService
      .put('amenities/update_fee/' + amenity_associator.amenity_method_id,
        {fee : amenity_associator.fee, id: amenity_associator.amenity_method_id})
      .toPromise()
      .then(response => response)
  }

  removeUnderScore(str: string) {
    if (str) {
      let i, frags = str.split('_');
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }
  }
}
