<section class="inner-public-banner sec-pad">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="text-container">
          <h1>Remote Patient Monitoring (PAP)</h1>
          <h4 class="sub-title">Receive respiratory signals from PAP therapy</h4>
        </div>
        <h4 class="sub-heading">CONNECTED WITH</h4>
        <div class="row">
          <div class="col-sm-6">
            <div class="border-box shadow p-lg text-center mb-lg">
              <img class="img-responsive" src="/assets/img/public/philips-logo.jpg"/>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="border-box shadow p-lg text-center mb-lg">
              <img class="img-responsive" src="/assets/img/public/resmed-logo.jpg"/>
            </div>
          </div>
        </div>
        <a class="btn btn-secondary btn-lg fs16 text-uppercase mt-xl" href="https://calendly.com/wellwink" target="_blank">Request A Demo</a>
      </div>
      <div class="col-sm-6">
        <img class="img-responsive" src="/assets/img/public/service-rtm-bg.jpg"/>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad cpt-billing">
  <div class="container text-center">
    <h1 class="fw-500">How it Works?</h1>
    <p class="mb-xl fs18">
      Patients respiratory signals are captured via CPAP machine and then data is shared with different care team members.
    </p>
  </div>
  <div class="container">
    <img class="img-responsive" src="/assets/img/public/RTM-Diagram-1.png"/>
  </div>
</section>
<section class="sec-pad main-points">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="border-box p-lg mt-lg">
          <h4 class="sub-heading text-uppercase m0 mb-lg">INTEGRATE</h4>
          <p class="m0">Receive data from PAP/NIV manufacturers daily, and generate the report. Discrete data help create additional KPIs and dashboards.</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="border-box p-lg mt-lg">
          <h4 class="sub-heading text-uppercase m0 mb-lg">STRATIFY</h4>
          <p class="m0">Stratify patients based on risk and focus on your underperformers and predict exacerbations of underlying chronic comorbidities, including heart failure, COPD, and asthma.</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="border-box p-lg mt-lg">
          <h4 class="sub-heading text-uppercase m0 mb-lg">COMMUNICATE</h4>
          <p class="m0">Get connected with your patients via HIPAA- compliant messaging, audio, or video capabilities via mobile app and desktop application from the cloud.</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="border-box p-lg mt-lg">
          <h4 class="sub-heading text-uppercase m0 mb-lg">ANALYZE</h4>
          <p class="m0">
            Analyze your patients’ adherence, risk categories, and practice metrics throughout time. Compare data over time, daily, weekly, monthly or quarterly.
          </p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="border-box p-lg mt-lg">
          <h4 class="sub-heading text-uppercase m0 mb-lg">TRACT & BILL</h4>
          <p class="m0">
            Track time spent via built-in time-tracking and reporting tools. Identify RTM billing codes automatically calculated by time spent in a month.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad cpt-billing">
  <div class="container">
    <h1 class="fw-500 text-center mb-xl">CPT Billing Codes and Reimbursement for CCM</h1>
    <h4>Non-Complex CPTs</h4>
    <div class="row col-height-same">
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99453</h4>
          <ul class="list-group">
            <li class="list-group-item">Initial set-up and patient education on use of equipment.</li>
            <li class="list-group-item">Billable once at time of setup.</li>
          </ul>
          <span class="pull-right fw-500">RVU 0.55</span>
          <h2 class="text-primary m0 mb">$19.03</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99454</h4>
          <ul class="list-group">
            <li class="list-group-item">
              Device(s) supply with daily recording(s) or programmed alert(s) transmission, 16 or more days a month.
            </li>
            <li class="list-group-item">Billable once every 30 days.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.61</span>
          <h2 class="text-primary m0 mb">$55.72</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99457</h4>
          <ul class="list-group">
            <li class="list-group-item">
              Remote physiologic monitoring treatment management services, first 20 minutes or more of clinical
              staff/physician/other qualified healthcare professional time in a calendar month requiring interactive
              communication with the patient/caregiver during the month.
            </li>
            <li class="list-group-item">
              The 20-minutes for 99457 can include time for interactive communication as well as for furnishing care management services
            </li>
            <li class="list-group-item">Billable once per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.45</span>
          <h2 class="text-primary m0 mb">$50.18</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="border-box shadow p-lg">
          <h4 class="m0 mb-lg text-secondary">CPT-99458</h4>
          <ul class="list-group">
            <li class="list-group-item">
              Reported when an additional 20 minutes of interactive communication is provided to the patient/caregiver,
              and is billed in conjunction with CPT code 99457.
            </li>
            <li class="list-group-item">
              Code 99458 is for “each additional 20 minutes” there is no cap on the number of minutes that can be billed per patient per month for RPM.
            </li>
            <li class="list-group-item">Billable for every additional 20 minutes per calendar month.</li>
          </ul>
          <span class="pull-right fw-500">RVU 1.18</span>
          <h2 class="text-primary m0 mb">$40.84</h2>
          <p class="m0">Medicare National Payment Amount (2022)</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad bg-secondary">
  <div class="container">
    <div class="row">
      <div class="col-sm-9">
        <h5 class="text-white m0">FROM MINIMAL TO 24/7 CARE</h5>
        <h2 class="text-white">Put Your Health in the Hands of a Caring Expert</h2>
        <h4 class="text-primary m0">Discover the best in chronic care management program</h4>
      </div>
      <div class="col-sm-3">
        <h5 class="text-white">Online Schedule</h5>
        <a class="btn btn-primary btn-lg btn-outline btn-block fs16 text-uppercase" href="https://calendly.com/wellwink" target="_blank">Request A Demo</a>
      </div>
    </div>
  </div>
</section>
