import { Component, OnInit } from '@angular/core';
import {TabsService} from "../../services/tabs.service";

@Component({
  selector: 'app-email-marketing',
  templateUrl: './email-marketing.component.html',
  styleUrls: ['./email-marketing.component.scss']
})
export class EmailMarketingComponent implements OnInit {
  constructor(public tabs_service : TabsService) {
    this.tabs_service.register('Email Marketing')
  }

  ngOnInit(): void {
  }

}
