import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from '../.././services/user.service';
import {Router} from '@angular/router';
import {MessageService} from '../../services/message.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopNavbarComponent implements OnInit {
  public practice_request = false;
  public show_home_practice = true;
  public display_home = false;
  showSolutions = false;
  showCustomers = false;
  // tslint:disable-next-line:max-line-length
  constructor(public _userService: UserService, private router: Router, private _message_service: MessageService) {
  }

  ngOnInit() {
  }

  message_notification_count() {
    return this._message_service.notifications['messages'] && this._message_service.notifications['messages']['count']
  }
  logout() {
   this._userService.logout();
  }
  showDropDown(event) {
    if (event === 'solutions') {
    this.showSolutions = ! this.showSolutions
    setTimeout(() => {
      this.showSolutions = false;
    }, 500);
    }
    if (event === 'customers') {
      this.showCustomers = ! this.showCustomers
      setTimeout(() => {
        this.showCustomers = false;
      }, 500);
    }

  }

  isPatientLogin(): boolean {
    return this._userService.user_logged_in && this._userService.current_user && this._userService.current_user.isPatient
  }
}
