<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Partner Program</h1>
      <h4 class="sub-title text-black">Become Our Business Development Partner and Earn Up to
        <span class="fs30 text-primary vl-m">25%</span> Commission on Referrals.
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad from-sec">
  <div class="container">
    <div class="white-container-box p-xl mb-xl">
      <h3 class="mt0">How It Works?</h3>
      <p>Get Higher Commissions with Tier System</p>
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <div class="white-container-box p">
            <h4 class="silver-package">Silver Partner</h4>
            <p class="m0 fs16 text-black text-center">( 3-25 Doctors )</p>
            <p class="fs12 mt mb">In tier one; you will have access to the following partner benefits</p>
            <ul class="list-group m0">
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;10% Monthly Recurring Commission</li>
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;Free Partner Account</li>
            </ul>
          </div>
        </div>
        <div class="col-sm-4 col-xs-12">
          <div class="white-container-box p">
            <h4 class="gold-package">Gold Partner</h4>
            <p class="m0 fs16 text-black text-center">( 25-100 Doctors )</p>
            <p class="fs12 mt mb">In tier two; you will have access to the following partner benefits</p>
            <ul class="list-group m0">
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;
                15% Monthly Recurring Commission
              </li>
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;
                A Link on Our Partner Page
              </li>
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;
                Custom Blog Posts for Your Site
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-4 col-xs-12">
          <div class="white-container-box p">
            <h4 class="platinum-package">Platinum Partner</h4>
            <p class="m0 fs16 text-black text-center">( 100+ Doctors )</p>
            <p class="fs12 mt mb">Tier three includes the benefits of tier two AND the following</p>
            <ul class="list-group m0">
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;
                25% Monthly Recurring Commission
              </li>
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;
                Hands-On Training Onsite
              </li>
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;
                Custom Partner Webinars
              </li>
              <li class="list-group-item fs12"><i class="fa fa-chevron-circle-right"></i>&nbsp;
                A Dedicated Partner Manager
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="white-container-box p-xl">
      <h3 class="mt0">How To Apply?</h3>
      <p>Apply to Become a Partner by Sending us eMail</p>
      <ul class="list-unstyled border-list m0">
        <li class="list">
          <h4 class="mt mb fs16 fw-500">Apply</h4>
          <p class="m0 fs14">
            Apply to become a partner by sending us email at
            <a class="btn-link">sales@wellwink.com</a> subject “Business Development Partner Program”.
          </p>
        </li>
        <li class="list">
          <h4 class="mt mb fs16 fw-500">Refer Us Three Doctors</h4>
          <p class="m0 fs14">Before you can become an official partner and earn a commission, you must refer three doctors for BETTER DEAL or higher via email.</p>
        </li>
        <li class="list">
          <h4 class="mt mb fs16 fw-500">Sign the Contract</h4>
          <p class="m0 fs14">Once you have referred three doctors and signed the contract, you will start earning commission in tier one.</p>
        </li>
      </ul>
    </div>
  </div>
</section>
