<div [ngClass]="parent_class_name" *ngIf="patient_loaded()">
  <h3 class="text-primary">Verify Your eMail Address</h3>
  <h4 class="text-muted">eMail verification code has been sent, enter Verification Code to verify</h4>

  <div class="row">
    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12">
      <div class="border-box p mt-xl mb-xl">
        <div class="row mb-xl">
          <div class="col-sm-6 col-xs-12 form-group">
            <label>Email address</label>
            <div class="input-group">
              <input type="email" class="form-control" [disabled]="!edit_email" [value]="patient.email" #edit_email_field/>
              <span class="input-group-btn">
                <a class="btn btn-primary" *ngIf="!patient.email_verified">
                  <span *ngIf="!edit_email" (click)="toggleEditEmail()"><i class="fa fa-pencil"></i> Edit</span>
                  <span *ngIf="edit_email" (click)="sendNewEmailOtp(edit_email_field.value)"><i class="fa fa-check" ></i> Update</span>
                </a>
              </span>
              <span class="input-group-btn" *ngIf="patient.email_verified">
                <button class="btn btn-success" style="border-color:transparent; margin-left:-5px;"><i class="fa fa-check fa-fw"></i> Verified</button>
              </span>
            </div>
          </div>
          <ng-container *ngIf="!patient.email_verified">
            <div class="col-sm-6 col-xs-12 form-group">
              <form (ngSubmit)="verify_email()" #EmailForm="ngForm">
                <label>Email Code*</label>
                <div class="input-group">
                  <input type="text" name="email_otp" class="form-control" [(ngModel)]="data.email_otp" required/>
                  <span class="input-group-btn">
                    <button type="submit" class="btn btn-primary" [disabled]="!EmailForm.form.valid">
                      <i class="fa fa-check-circle"></i> Verify
                    </button>
                  </span>
                </div>
                <p [ngClass]="message_class">{{email_message}}</p>
              </form>
            </div>
            <div class="col-xs-12 form-group">
              <h5 class="m0"><a class="btn-link" (click)="sendOtp('email','')"><i class="fa fa-share-square"></i> Resend</a></h5>
              <p class="m0 fs12">If you haven't received the Email Verification Code within one minute, click "Resend".</p>
              <p [ngClass]="message_class" *ngIf="type == 'email'">{{general_message}}</p>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-sm-6 col-xs-12 form-group">
            <label>Mobile Number</label>
            <div class="input-group">
              <input   mask="(000) 000-0000" placeholder="(565 ) 656-1321" type="text" class="form-control" #edit_phone_field [disabled]="!edit_phone" [(ngModel)]="patient.phonee" [value]="patient.phone"/>
              <span class="input-group-btn">
                <a class="btn btn-primary" *ngIf="!patient.mobile_verified">
                  <span *ngIf="!edit_phone" (click)="toggleEditPhone()"><i class="fa fa-pencil"></i> Edit</span>
                  <span *ngIf="edit_phone" (click)="sendNewMobileOtp(edit_phone_field.value)"><i class="fa fa-check" ></i> Update</span>
                </a>
              </span>
              <span class="input-group-btn" *ngIf="patient.mobile_verified">
                <button class="btn btn-success" style="border-color:transparent; margin-left:-5px;"><i class="fa fa-check fa-fw"></i> Verified</button>
              </span>
            </div>
          </div>
          <ng-container *ngIf="!patient.mobile_verified">
            <div class="col-sm-6 col-xs-12 form-group">
              <form (ngSubmit)="verifyOtp()" #PhoneForm="ngForm">
                <label>Mobile Verification Code*</label>
                <div class="input-group">
                  <input type="text" name="mobile_otp" class="form-control" [(ngModel)]="data.mobile_otp" required #mobile_otp/>
                  <span class="input-group-btn">
                    <button type="submit" class="btn btn-primary" [disabled]="!PhoneForm.form.valid">
                      <i class="fa fa-check-circle"></i> Verify
                    </button>
                  </span>
                </div>
                <p [ngClass]="message_class">{{mobile_message}}</p>
              </form>
            </div>
            <div class="col-xs-12 form-group">
              <h5 class="m0"><a class="btn-link" (click)="sendOtp('sms','')"><i class="fa fa-share-square"></i> Resend</a></h5>
              <p class="m0 fs12">If you haven't received the Mobile Verification Code within one minute, click "Resend".</p>
              <p [ngClass]="message_class" *ngIf="type == 'sms'">{{general_message}}</p>
            </div>
          </ng-container>
        </div>
        <hr class="hr-line">
        <button class="btn btn-primary btn-lg fs20" (click)="goto_profile()" [disabled]="!patient.verified()">Continue</button>
      </div>
    </div>
  </div>
</div>
