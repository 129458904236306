import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../../../services/nullRemover';
import {CustomHttpClient} from '../../../services/custom-http-client.service';
import {PatientEvent} from './patient-event';
import {UserService} from '../../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PatientEventService {
  public event_duration: Array<string> = ['5_mins', '10_mins', '15_mins', '20_mins', '25_mins', '30_mins',
    '35_mins', '40_mins', '45_mins', '50_mins', '55_mins', '60_mins', '90_mins', '2_hours', '3_hours',
    '4_hours', '5_hours', '6_hours', '7_hours', '8_hours', '9_hours', '10_hours', '11_hours', '12_hours'];
  public event_notify_before: Array<string> = ['0_min', '5_mins', '15_mins', '30_mins', '45_mins',
    '60_mins', '90_mins', '2_hours', '3_hours', '4_hours', '5_hours', '6_hours', '8_hours', '10_hours',
    '12_hours', '1_day', '2_days', '3_days', '4_days', '5_days', '1_week', '2_weeks'];
  public event_notify_before_Options = [ { id: '0_min', name: '0 min' }, { id: '5_mins', name: '5 mins' }, { id: '15_mins', name: '15 mins' },
    { id: '30_mins', name: '30 mins' }, { id: '45_mins', name: '45 mins' }, { id: '60_mins', name: '60 mins' },
    { id: '90_mins', name: '90 mins' }, { id: '2_hours', name: '2 hours' }, { id: '3_hours', name: '3 hours' },
    { id: '4_hours', name: '4 hours' }, { id: '5_hours', name: '5 hours' }, { id: '6_hours', name: '6 hours' },
    { id: '7_hours', name: '7 hours' }, { id: '8_hours', name: '8 hours' }, { id: '10_hours', name: '10 hours' },
    { id: '12_hours', name: '12 hours' }, { id: '1_day', name: '1 day' }, { id: '2_days', name: '2 days' },
    { id: '3_days', name: '3 days' }, { id: '4_days', name: '4 days' }, { id: '5_days', name: '5 days' },
    { id: '1_week', name: '1 week' },  { id: '2_weeks', name: '2 weeks' }];
  public event_type: Array<string> = ['medication', 'vital_sign', 'appointment', 'health_maintenance', 'other'];
  public event_status: Array<string> = ['done', 'to_do', 'cancelled', 'missed'];
  reloadPatientEventList: Subject<any> = new Subject<any>();
  scrollToLatestEvent: Subject<any> = new Subject<any>();
  showReportListener: Subject<any> = new Subject<any>();
  showDeleteConformation = false;

  constructor(private _tokenService: CustomHttpClient, public userService: UserService) { }

  create(patientEvent: PatientEvent): Promise<any> {
    patientEvent.creator_id = this.userService.current_user.id
    return this._tokenService
      .post('patient_events', patientEvent.to_params())
      .toPromise()
      .then(response => response)
  }

  save(patientEvent: PatientEvent): Promise<any> {
    if (patientEvent.is_new_record()) {
      return this.create(patientEvent);
    }
    else {
      return this.update(patientEvent);
    }
  }

  update(patientEvent: PatientEvent) {
    patientEvent.updater_id = this.userService.current_user.id;
    return this._tokenService
      .put('patient_events', patientEvent.to_params())
      .toPromise()
      .then(response => response)
  }

  delete(id: number) {
    return this._tokenService
      .delete('patient_events?id=' + id)
      .toPromise()
      .then(response => response)
  }

  getPatientEvents(filter: Object) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('patient_events',{ observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          patient_events: response.body,
        }
      });
  }

  getPatientDeviceForEvents(id: any) {
    return this._tokenService.get('patient_events/devices/' + id)
      .toPromise()
      .then(response => response);
  }

  updatePatientEventStatus(id: any, status: any, status_color: string): Promise<any> {
    return this._tokenService.put('patient_events/update_status', {id: id, status: status, status_color: status_color})
      .toPromise()
      .then(response => response);
  }

  deletePatientEvent(id: any, type: string) {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    params = params.set('type', type);
    return this._tokenService
      .delete('patient_events', {params: params})
      .toPromise()
      .then(response => response)
  }

  getPatientEvent(id: number): Promise<any> {
    return this._tokenService.get('patient_events/' + id)
      .toPromise()
      .then(response => response)
  }

  getPatientEventReport(filter: Object) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService
      .get('patient_events/report', {params: params})
      .toPromise()
      .then(res => res);
  }
}
