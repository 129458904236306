import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {TabsService} from '../../../../services/tabs.service';
import {VisitsService} from '../../../../services/visits.service';

@Component({
  selector: 'app-print-pchf',
  templateUrl: './print-pchf.component.html',
  styleUrls: ['./print-pchf.component.scss']
})
export class PrintPchfComponent implements OnInit {

  @ViewChild('patientInFlow') el: ElementRef;
  @Input() show_print: boolean;
  @Input() print_case_info: any;
  @Output() hide_mode: EventEmitter<boolean> = new EventEmitter<boolean>();
  public current_datetime: any
  constructor(public _tabs_service: TabsService, public _visitService: VisitsService) { }

  ngOnInit() {
    let monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];
    let objDate = new Date();
    let day_of_month = objDate.getDate();
    let monthIndex = objDate.getMonth();
    let year = objDate.getFullYear();
    this.current_datetime =  monthNames[monthIndex] + ' ' + day_of_month + ', ' + year;
  }

  hide_print() {
    this.hide_mode.emit(false)
  }

  generatePdf(action) {
    this._visitService.pchf_generatePdf(this.el);
  }

}
