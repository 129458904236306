import { Component, OnInit } from '@angular/core';
import {ProgramIntegration} from '../program-integration';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {UserService} from '../../../services/user.service';
import {ProgramIntegrationService} from '../program-integration.service';
import {AlertService} from '../../../_alert';

@Component({
  selector: 'app-program-integrations',
  templateUrl: './program-integrations.component.html',
  styleUrls: ['./program-integrations.component.scss']
})
export class ProgramIntegrationsComponent implements OnInit {
  public programIntegrations: ProgramIntegration[] = [];
  public program_integration: ProgramIntegration = new ProgramIntegration()
  public selected_program_integration: ProgramIntegration;


  constructor(public loaderService: LoaderService, public userService: UserService,
              private globalErrorHandlerService: GlobalErrorHandlerService, public alertService: AlertService,
              public programIntegrationService: ProgramIntegrationService) { }

  ngOnInit(): void {
    this.getProgramIntegrations();
  }

  getProgramIntegrations() {
    this.loaderService.show();
    this.programIntegrationService.getProgramIntegration(this.userService.current_user.profileable['practice_id']).then(
      resp => {
      this.programIntegrations = resp.map(programInt => new ProgramIntegration().load_from_json(programInt));
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  editProgramIntegration(programIntegration: ProgramIntegration) {
    this.program_integration = new ProgramIntegration().load_from_json(programIntegration);
    this.programIntegrationService.showIntegrationForm = true;
  }

  deleteProgramIntegration() {
    this.loaderService.show();
    if (this.selected_program_integration.id) {
      this.programIntegrationService.delete(this.selected_program_integration.id).then( resp => {
        if (resp) {
          this.selected_program_integration = new ProgramIntegration();
          this.getProgramIntegrations();
          this.alertService.success('Integration has been deleted successfully', this.alertService.options);
        }
      }).catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
    }
  }
}
