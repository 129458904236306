import {VitalEntry} from './VitalEntry';
import {Vital} from './vital';

export class PatientVital {
  id: number;
  patient_id: number;
  appointment_id: any;
  user_id: number;
  date: Date;
  vital_entries: VitalEntry[] = [];
  heightInFeet: any;
  heightInInch: any;
  p_weight: any;
  bmi: any;
  glucose_value: any;
  vitalable_type: string;
  vitalable_id: any;
  age_gender: any;
  creator_name: any;
  creator_id: any;
  updater_name: any;
  updater_id: any;
  created_at: any;
  updated_at: any;
  source_id: string;
  source_name: string;
  descriptor: string;
  reading_type = 'manual';
  created_at_datetime: any;
  reading_at_datetime: any;
  reading_date: string = new Date((Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()
    .substring(0, new Date((Date.now() - (new Date()).getTimezoneOffset() * 60000))
      .toISOString().length - 1).replace(/\.\d+/, '');
  isCollapsed = true;
  device_name: string;
  added_to_medical_chart: boolean;
  load_from_json(json) {
    this.id = json.id;
    this.reading_date = this.reading_date_format(json.reading_date);
    this.vitalable_type = json.vitalable_type;
    this.vitalable_id = json.vitalable_id;
    this.patient_id = json.patient_id;
    this.appointment_id = json.appointment_id;
    this.user_id = json.user_id;
    this.creator_name = json.creator_name;
    this.updater_name = json.updater_name;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
    this.source_id = json.source_id;
    this.descriptor = json.descriptor;
    this.source_name = json.source_name;
    this.reading_type = json.reading_type;
    this.date = new Date(json.reading_at_datetime);
    this.reading_at_datetime = new Date(json.reading_at_datetime);
    this.created_at_datetime = json.created_at_datetime;
    this.device_name = json.device_name;
    this.vital_entries = json.vital_entries.map(ve => new VitalEntry().load_from_json(ve));
    this.glucose_value = this.vital_entries.filter(ve => ve.vital.short === 'rbg' || ve.vital.short === 'fbg')
      .filter(v => v.value)[0]?.value;
    if (this.height) {
      this.heightInInch = this.height.value;
    }
    if (this.weight) {
      this.weight.value = this.weightInPounds();
      this.p_weight = this.weight.value;
    }
    if (this.avg_usage) {
      this.avg_usage.value = this.setHrAndMin(this.avg_usage.value)
    }
    this.added_to_medical_chart = json.added_to_medical_chart;
    return this;
  }

  reading_date_format(date): string {
    return new Date((Date.parse(date) - (new Date()).getTimezoneOffset() * 60000)).toISOString()
      .substring(0, new Date((Date.parse(date) - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().length - 1).replace(/\.\d+/, '');
  }

  heightCalcInInches() {
    this.height.value =  this.feetToInches();
  }
  feetToInches() {
    let h: any = (this.heightInFeet ? this.heightInFeet * 12 : null) + (this.heightInInch ? +this.heightInInch : null);
    if (h === 0) {
      return null;
    }
    else {
      return h.toString();
    }
  }
  ConvertHeightInFeetAndInch() {
    if (this.height.value) {
      this.heightInFeet = Math.floor((this.height.value) / 12);
      this.heightInInch = (((this.height.value) / 12 - this.heightInFeet) * 12).toFixed(0);
    }
  }
  weightInPounds() {
    let lb = (this.weight.value ? (this.weight.value * 2.2046).toFixed(2) : undefined);
    return lb;
  }

  weightInKgs() {
    let w: any = (this.p_weight ? (this.p_weight / 2.2046)  : 0 )
    if (w === 0) {
      return null;
    }
    else {
      return w.toString();
    }
  }
  to_params() {
    return {
      patient_vital: {
        patient_id: this.patient_id,
        reading_date: this.reading_date,
        creator_id: this.creator_id,
        updater_id: this.updater_id,
        appointment_id: this.appointment_id,
        vitalable_id: this.vitalable_id,
        vitalable_type: this.vitalable_type,
        vital_entries_attributes: this.vital_entries,
        reading_type: this.reading_type
      }
    }
  }

  day_view_label() {
    let date = new Date(this.reading_at_datetime).toLocaleString();
    if ( this.device_name ) {
      return this.device_name.toString() + ' ' + ' - ' + date
    } else {
      return date
    }
  }

  get temperature() {
    return this.vital_entries.filter(ve => ve.vital.short === 'temp')[0];
  }

  get pulse() {
    return this.vital_entries.filter(ve => ve.vital.short === 'pulse')[0];
  }

  get respiration() {
    return this.vital_entries.filter(ve => ve.vital.short === 'rr')[0];
  }

  get sBP() {
    return this.vital_entries.filter(ve => ve.vital.short === 'sbp')[0];
  }

  get dBP() {
    return this.vital_entries.filter(ve => ve.vital.short === 'dbp')[0];
  }

  get weight() {
    return this.vital_entries.filter(ve => ve.vital.short === 'wt')[0];
  }

  get height() {
    return this.vital_entries.filter(ve => ve.vital.short === 'ht')[0];
  }
  get spo2() {
    return this.vital_entries.filter(ve => ve.vital.short === 'spo2')[0];
  }
  get nprs() {
    return this.vital_entries.filter(ve => ve.vital.short === 'nprs')[0];
  }
  get bodyMassIndex() {
    return this.vital_entries.filter(ve => ve.vital.short === 'bmi')[0];
  }
  get bloodGlucose() {
    return this.vital_entries.filter(ve => ve.vital.short === 'rbg' || ve.vital.short === 'fbg')[0];
  }

  get fbg() {
    return this.vital_entries.filter(ve => ve.vital.short === 'fbg')[0];
  }

  get rbg() {
    return this.vital_entries.filter(ve => ve.vital.short === 'rbg')[0];
  }
  get avg_usage() {
    return this.vital_entries.filter(ve => ve.vital.short === 'useage')[0];
  }
  CalBmi() {
    if (this.height.value === undefined || this.height.value === undefined || this.weight.value === undefined) {
      return this.bmi =  '';
    }
    // tslint:disable-next-line:radix
    if (this.height.value === 0 || this.weight.value === 0 ) {
      return this.bmi =  0;
    }
    this.bmi =  ((this.weight.value) / (((this.height.value) * 0.0254) * ((this.height.value) * 0.0254))).toFixed(2);
    this.bmi = this.bmi === 'NaN' ?  '' : this.bmi;
    // tslint:disable-next-line:radix
    this.bodyMassIndex.value = parseInt(this.bmi)
  }
  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  get_url(): string {
    return this.is_new_record() ? 'vitals/' : ['vitals', this.id].join('/')
  }

  initializeIndependentVitalEntry(vitals: Vital[]) {
    Vital.independent_vitals.map(v => {
      if (this.vital_entries.filter(v_entry => v_entry.vital.short === v).length === 0) {
        let vital = vitals.filter(e => e.short === v)[0];
        let v_entry = new VitalEntry()
        v_entry.vital = vital;
        v_entry.vital_id = vital.id;
        this.vital_entries.push(v_entry);
      }
    })
  }

  private setHrAndMin(value: any) {
    let hrs = parseInt(String(Number(value)));
    let min = Math.round((Number(value) - hrs) * 60);
    return hrs + ':' + min;
  }
}
