import {Component, OnInit, } from '@angular/core';
import {PatientReview} from '../patient-review';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {PracticeReviewSetup} from '../practice_review_setup';
import {PracticeReviewService} from '../practice_review.service';
import {LoaderService} from '../../services/loader.service';
import {PatientReviewService} from '../patient-review.service';
import {AlertService} from '../../_alert';



@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  patient_review: PatientReview = new PatientReview();
  practice_review_setup: PracticeReviewSetup = new PracticeReviewSetup();
  rate: any;
  practice_id: number;
  patient_id: number;
  appointment_id: number;
  doctor_id: number;
  appointment_doctor_name: string;
  appointment_practice_name: string;
  practice_logo: string;

  public doctor_submited = false;
  public practice_submited = false;
  public end_review = false;
  public second_save = false;
  public allowed_rating_mapper: Object = { 'one_star': 1 , 'two_star': 2 , 'three_star': 3 , 'four_star': 4 , 'five_star': 5 };

  patient_review_id: number;
  requestPublicReview =  false;

  constructor(public patient_review_service: PatientReviewService,
              public loaderService: LoaderService, public alertService: AlertService,
              public practice_review_service: PracticeReviewService,
              private router: Router, private route: ActivatedRoute, ) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe((params: Params) => {
        this.patient_review_id = params['patient_review_id'];
        this.getPatientReview(this.patient_review_id);
      })

  }

  getPracticeSetup() {
    this.patient_review_service.getPracticeSetup(this.practice_id).then(resp => {
      this.practice_review_setup = this.practice_review_setup.load_from_json(resp);
    });
  }

  save() {
    this.loaderService.small_loder_show();
    this.patient_review_service.save(this.patient_review)
      .then(resp => {
        this.patient_review = this.patient_review.load_from_json(resp)
        this.loaderService.small_loder_hide();
        if (this.publicReviewApplicable()) {
          this.requestPublicReview = true;
        } else {
          this.end_review = true;
        }
      })
  }

  // requestSecondReview() {
  //   if (this.practice_review_setup.review_type === 'both' && !this.second_save) {
  //     this.second_review();
  //   }
  //   else {
  //     this.end_review = true;
  //   }
  // }
  //
  // second_review() {
  //   this.second_save = true;
  //   this.patient_review_service.appointmentSecondReview(this.patient_review.id).then(resp => {
  //     this.patient_review = this.patient_review.load_from_json(resp);
  //     if (this.patient_review.reviewable_type.toLowerCase() === 'doctor') {
  //       this.doctor_submited = true;
  //     }
  //     if (this.patient_review.reviewable_type.toLowerCase() === 'practice') {
  //       this.practice_submited = true;
  //     }
  //   }).catch(error => {
  //     console.log(error);
  //   });
  // }

  getPatientReview(patient_review_id) {
    this.patient_review_service.getReview(patient_review_id).then(resp => {
      this.patient_review = this.patient_review.load_from_json(resp);
      this.patient_review.reviewable_type = this.patient_review.reviewable_type.replace(/'/g, '');
      this.appointment_id = this.patient_review.appointment_id;
      this.patient_id = this.patient_review.patient_id;
      this.practice_id = resp.practice_id;
      this.doctor_id = resp.doctor_id;
      this.appointment_doctor_name = resp.appointment_doctor;
      this.appointment_practice_name = resp.appointment_practice_name;
      if (resp.practice_logo) {
        this.practice_logo = resp.practice_logo;
      }
      this.getPracticeSetup();
    });
  }


  copyToClipBoard(txt) {
    this.alertService.success('Review Copied', this.alertService.options);
    let dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = txt;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }


  publicReviewApplicable (): boolean {
    if ((this.patient_review.selected_links.length > 0) && (this.practice_review_setup.auto_approve_enabled)
      && (this.patient_review.rating >= this.allowed_rating_mapper[this.practice_review_setup.star_preference]) ) {
      return  true;
    }
    else {
      return false;
    }
  }

  continueNextStep() {
    this.requestPublicReview = false;
    this.end_review = true;
  }
}
