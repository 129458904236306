<div class="table-responsive" *ngIf="patient_vitals.length > 0 ; else noRecord">
  <table class="table table-hover table-bordered m0 text-center">
    <thead>
    <tr>
      <th class="text-center">Date & Time</th>
      <th class="text-center">Temp <br>(F)</th>
      <th class="text-center">Pulse <br>(BPM) <br> Rhythm</th>
      <th class="text-center">Respiration <br> (BPM) <br> Difficulty</th>
      <th class="text-center">B/P</th>
      <th class="text-center">Weight <br>(Lb)</th>
      <th class="text-center">Height <br>(In)</th>
      <th class="text-center">BMI <br>(Lb/m2)</th>
      <th class="text-center">Blood Glucose <br>(mg/dL)</th>
      <th class="text-center">SpO2 <br>(%)</th>
      <th class="text-center">Pain</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let pv of patient_vitals">
      <tr>
        <td><a (click)="selectPatientVital(pv)">
          {{pv.date | date:'shortDate'}}
          <br>
          {{pv.date | date:'shortTime'}}</a>
        </td>
        <td [tooltip]="pv.temperature?.descriptor" [class]="pv.temperature?.descriptor_class">{{pv.temperature?.value}}</td>
        <td [tooltip]="pv.pulse?.descriptor" [class]="pv.pulse?.descriptor_class">
          {{pv.pulse?.value}}
          <br>
          {{pv.pulse?.rhythm}}
        </td>
        <td [tooltip]="pv.respiration?.descriptor" [class]="pv.respiration?.descriptor_class">
          {{pv.respiration?.value}}
          <br>
          {{pv.respiration?.difficulty}}
        </td>
        <td>
          <span [tooltip]="pv.sBP?.descriptor" [class]="pv.sBP?.descriptor_class">{{pv.sBP?.value}}</span>
          <ng-container *ngIf="pv.dBP?.value">
            /
          </ng-container>
          <span [tooltip]="pv.dBP?.descriptor" [class]="pv.dBP?.descriptor_class">{{pv.dBP?.value}}</span>
        </td>
        <td>{{ pv.weight?.value}}</td>
        <td>
          {{pv.heightInInch}}
          <!--          {{pv.heightInFeet}}<ng-container *ngIf="pv.heightInFeet">'</ng-container>-->
          <!--          {{pv.heightInInch}}<ng-container *ngIf="pv.heightInInch">''</ng-container>-->
        </td>
        <td [tooltip]="pv.bodyMassIndex?.descriptor" [class]="pv.bodyMassIndex?.descriptor_class">{{pv.bodyMassIndex?.value}}</td>
        <td>
          <ng-container *ngIf="pv.fbg?.value">
            <span [tooltip]="pv.fbg?.descriptor" [class]="pv.fbg?.descriptor_class">{{pv.fbg?.value}}</span>
          </ng-container>
          <ng-container *ngIf="pv.rbg?.value">
            <span [tooltip]="pv.rbg?.descriptor" [class]="pv.rbg?.descriptor_class">{{pv.rbg?.value}}</span>
          </ng-container>
        </td>
        <td [tooltip]="pv.spo2?.descriptor"><span *ngIf="pv.spo2" [class]="pv.spo2?.descriptor_class">{{pv.spo2?.value}}</span></td>
        <td [tooltip]="pv.nprs?.descriptor"><span *ngIf="pv.nprs" [class]="pv.nprs?.descriptor_class">{{pv.nprs?.value}}</span></td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<div class="pagination-center" *ngIf="patient_vitals && patient_vitals.length > 0">
  <pagination class="m0"
              *ngIf="totalItems > 10 && totalItems != 0" [totalItems]="totalItems" [maxSize]="4" [itemsPerPage]="10" (pageChanged)="historyPageChange.emit($event)">
  </pagination>
</div>
<ng-template #noRecord><div class="alert alert-danger p m0 text-center">No Record Found</div></ng-template>
