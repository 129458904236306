import {Component, OnInit, Input,ViewChild} from '@angular/core';
import {Note} from "../note";
import {NoteService} from "../../../../services/note.service";
import {GlobalErrorHandlerService} from "../../../../services/global-error.service";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  @Input() notes: Note[];
  public selectedNote = 0;
  @ViewChild('classicModal') classicModal: ModalDirective;
  constructor(private noteService:NoteService,public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit() {
  }
  selectNote(index){
    this.selectedNote = index;
  }
  updateNote(){
    this.noteService.update(this.notes[this.selectedNote])
      .then(resp => {
          this.classicModal.hide();
        }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error );

  }
}
