import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-chronic-care-management',
  templateUrl: './public-chronic-care-management.component.html',
  styleUrls: ['./public-chronic-care-management.component.scss']
})
export class PublicChronicCareManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
