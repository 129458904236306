import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {Tab} from '../../../tabs/tab'

import {UserManagementService} from '../../../services/user-management.service'
import {TabsService} from '../../../services/tabs.service'
import {UserComponent} from '../user/user.component';
import {User} from '../user';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  public values: string[] = ['All', 'Active', 'In-Active'];
  public asyncName = '';
  public asyncPracticeName = '';
  public asyncGroupName = '';
  public asyncUserName = '';
  public asyncRoleName = '';
  public users_listing: any;
  public page: any = 1;
  public status: boolean = null;
  @Input() profile_type: any;
  totalItems: any;

  constructor(private _tabService: TabsService, private _user_management_service: UserManagementService, public user_service: UserService) {
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.apply_filters_search();
  }

  apply_filters_search() {
    this._user_management_service.apply_filters(this.profile_type, this.asyncName, this.asyncPracticeName, this.asyncGroupName,
      this.asyncUserName, this.asyncRoleName, this.status, this.page).then(resp => {
      this.users_listing = [];
      for (let user_profile of resp.users) {
        let user = new User();
        user.load_from_json(user_profile);
        this.users_listing.push(user);
      }
      this.totalItems = resp.total
      this.page = resp.current_page;
    });
  }

  changeStatus(value) {
    if (value == '1') {
      this.status = null;
    } else if (value == '2') {
      this.status = true;
    } else {
      this.status = false;
    }
  }

  addToTab() {
    this._tabService.add_tab(new Tab({title: 'Add ' + this.profile_type, icon: 'fa fa-hospital'}, UserComponent, 'New User' + this.profile_type, {profile_type: this.profile_type }))
  }

  pageChanged(page) {
    this.page = page;
    this.apply_filters_search();
  }
}
