export class EmailConfiguration {
  public id: number;
  public user_name: string;
  public practice_id: number;
  public password: string;
  public smtp_server : string;
  public smtp_port: number;
  public outgoing_domain : string;
  public authentication_type : string = '';
  public auto_start : boolean;
  public enable_auto_tls : boolean;
  public connection_security : number;
  public status : number;
  to_params() {
    return {
      email_configuration: {
        id: this.id,
        user_name: this.user_name,
        practice_id: this.practice_id,
        password: this.password,
        smtp_server: this.smtp_server,
        smtp_port: this.smtp_port,
        outgoing_domain: this.outgoing_domain,
        authentication_type: this.authentication_type,
        auto_start: this.auto_start,
        enable_auto_tls: this.enable_auto_tls,
        connection_security: this.connection_security,
        status: this.status
      }
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.user_name = json.user_name;
    this.practice_id = json.practice_id;
    this.password = json.password;
    this.smtp_server = json.smtp_server;
    this.smtp_port = json.smtp_port;
    this.outgoing_domain = json.outgoing_domain;
    this.authentication_type = json.authentication_type;
    this.auto_start = json.auto_start;
    this.enable_auto_tls = json.enable_auto_tls;
    this.connection_security = json.connection_security;
    this.status = json.status;
    return this;
  }
}
