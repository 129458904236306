<div [hidden]="!do_show">
  <div class="p-xl" *ngIf="location && location.custom_loaded">
    <form #locationForm="ngForm" (ngSubmit)="save()" class="form-horizontal">
      <div class="form-group">
        <label class="col-sm-1  p0 pt-sm">Name*:</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" name="name" id="name"
                 required
                 [(ngModel)]="location.name"
                 placeholder="Name">
          <div *ngIf="customValidations.formErrors.name" style="color: red">
            {{ customValidations.formErrors.name }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Address 1*:</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="line_1" [(ngModel)]="location.address.line_1"
                   name="line_1"  required
                 placeholder="Line 2">
          <div *ngIf="customValidations.formErrors.line_1" style="color: red">
            {{ customValidations.formErrors.line_1 }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Address 2:</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="line_2" [(ngModel)]="location.address.line_2"
                 [ngModelOptions]="{standalone: true}"
                 placeholder="Line 2">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm">City* :</label>
        <div class="col-sm-3">
          <input type="text" class="form-control" id="city" [(ngModel)]="location.address.city_area.city.name"
                 name="city" required
                 placeholder="City">
          <div *ngIf="customValidations.formErrors.city" style="color: red">
            {{ customValidations.formErrors.city }}
          </div>
        </div>
        <label class="col-sm-1 p0 pt-sm text-right">State* :</label>
        <div class="col-sm-2 pr0">
          <input type="text" class="form-control" id="state" [(ngModel)]="location.address.city_area.city.state"
                 name="state" required
                 placeholder="state">
          <div *ngIf="customValidations.formErrors.state" style="color: red">
            {{ customValidations.formErrors.state }}
          </div>
        </div>
        <label class="col-sm-1 p0 pt-sm text-right">Zip* :</label>
        <div class="col-sm-2">
          <input type="number" class="form-control" id="zip" [(ngModel)]="location.address.postal_code"
             name="zip" required placeholder="zip">
          <div *ngIf="customValidations.formErrors.zip" style="color: red">
            {{ customValidations.formErrors.zip }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Geo Location*:</label>
        <div class="col-sm-9">
          <input id="city_area_name" placeholder="search for Area" autocorrect="off" autocapitalize="off"
                 spellcheck="off" type="text" class="form-control" #search [(ngModel)]="location.address.city_area.name"
                 [ngModelOptions]="{standalone: true}" required>
          <div *ngIf="customValidations.formErrors.area" style="color: red">
            {{ customValidations.formErrors.area }}
          </div>
          <input type="hidden" placeholder="search for Area" autocorrect="off" autocapitalize="off" id="olat"
                 spellcheck="off" class="form-control" [(ngModel)]="location.address.city_area.lat"
                 [ngModelOptions]="{standalone: true}">
          <input  type="hidden" placeholder="search for Area" autocorrect="off" autocapitalize="off" id="olong"
                 spellcheck="off" class="form-control" [(ngModel)]="location.address.city_area.long"
                 [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1  p0 pt-sm">Phone* :</label>
        <div class="col-sm-4" *ngIf="location.phone">
          <input  mask="(000) 000-0000" type="tel" class="form-control" id="phone" [(ngModel)]="location.phone.value"
                 name="phone" required
                 placeholder="Phone">
          <div *ngIf="customValidations.formErrors.phone" style="color: red">
            {{ customValidations.formErrors.phone }}
          </div>
        </div>
        <label class="col-sm-1 p0 pt-sm ">Fax :</label>
        <div class="col-sm-4" *ngIf="location.fax">
          <input type="text" class="form-control" id="fax" [(ngModel)]="location.fax.value" name="fax" pattern="[0-9]+"
                 placeholder="Fax">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Email* :</label>
        <div class="col-sm-9">
          <input type="email" class="form-control"  id="email" [(ngModel)]="location.email"
                 pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                 name="email" required
                 placeholder="Email">
          <div *ngIf="customValidations.formErrors.email" style="color: red">
            {{ customValidations.formErrors.email }}
          </div>
          <div class="md-char-counter" *ngIf="customValidations.formErrors.email && customValidations.formErrors.email.pattern">
            {{customValidations.formErrors.email.pattern}}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1  p0 pt-sm">Welcome Message:</label>
        <div class="col-sm-11">
          <editor #editor apiKey="no-api-key" id="mesage" name="mesage" [(ngModel)]="location.message"
                  [init]="init_tiny">
          </editor>
          <div *ngIf="customValidations.formErrors.message" style="color: red">
            {{ customValidations.formErrors.message }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Operational Hours:</label>
        <div class="col-sm-11">
          <input type="text" class="form-control" id="operational_hours" [(ngModel)]="location.operational_hours"
                 [ngModelOptions]="{standalone: true}"
                 placeholder="Operational Hours">
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-xs-12" *ngFor="let specialty of get_specialties(); let i=index">
          <div class="form-group">
            <label class="col-sm-3 p0 pt-sm">Specialty:</label>
            <div class="col-sm-9">
              <div class="input-group">
                <input  name="specialty_{{i}}" [(ngModel)]="specialty.description" [typeaheadAsync]="true"
                        (keyup)="set_asyncSelected($event,specialty)" [typeahead]="specialties"
                        (typeaheadLoading)="changeTypeaheadLoading($event)" typeaheadWaitMs="500"
                        (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                        (typeaheadOnSelect)="typeaheadOnSelect($event,specialty)" [typeaheadOptionsLimit]="7"
                        [typeaheadOptionField]="'description'" placeholder="Specialty"
                        class="form-control" autocomplete="off">
                <span class="input-group-btn">
                  <a class="btn btn-danger btn-outline" *ngIf="i + 1 < get_specialties().length" (click)="removeSpecialty(specialty)" >
                    <i class="fa fa-trash"></i>
                  </a>
                  <a class="btn btn-secondary btn-outline" (click)="addSpecialty()" *ngIf="(i + 1  == get_specialties().length) && !customValidations.formErrors.specialty_0">
                    <i class="fa fa-plus"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Is Bookable:</label>
        <div class="col-sm-11">
          <input type="checkbox" id="is_bookable" [(ngModel)]="location.is_bookable" (click)="is_bookable_check($event)"
                 [ngModelOptions]="{standalone: true}">
          <div class="text-success" *ngIf="is_bookable_allow">{{is_bookable_message}}</div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Auto Acknowledge:</label>
        <div class="col-sm-11">
          <input type="checkbox" id="auto_acknowledge" [(ngModel)]="location.auto_acknowledge"
                 [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Appoitment Email:</label>
        <div class="col-md-2 col-sm-9 col-xs-12">
          <label class="white-container-box fw-500 mr-lg">
            Running
            <input type="radio" class="ml" name="running" value="reminder_running" [(ngModel)]="location.reminder_status"/>
          </label>
          <label class="white-container-box fw-500">
            Stopped
            <input type="radio" class="ml" name="stopped" value="reminder_stopped" [(ngModel)]="location.reminder_status"/>
          </label>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Appointment By Location:</label>
        <div class="col-sm-11">
          <input type="checkbox" id="is_calendarable" [(ngModel)]="location.is_calendarable"
                 [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">ID:</label>
        <div class="col-sm-5 pr-xl">
          <input class="form-control" name="third_party_location_id" id="third_party_location_id"
                 [(ngModel)]="location.third_party_location_id" />
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Resource ID:</label>
        <div class="col-sm-5 pr-xl">
          <input class="form-control" name="third_party_resource_id" id="third_party_resource_id"
                 [(ngModel)]="location.third_party_resource_id" />
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-1 p0 pt-sm ">Timezone:</label>
        <div class="col-sm-5 pr-xl">
          <select class="form-control" name="third_party_location_time_zone"
                 [(ngModel)]="location.time_zone" [attr.vala]="location.time_zone">
            <option [ngValue]="null">Select Timezone</option>
            <option *ngFor="let timezone of locationService.time_zones" [value]="timezone[1]">
              {{timezone[0]}}
            </option>
          </select>
        </div>
      </div>

      <ng-container *ngIf="location.public_review_links.length > 0">
        <div class="form-group" *ngFor="let review_link of location.public_review_links let link_index = index">
          <label class="col-sm-1 p0 pt-sm">Add Link:</label>
          <div class="col-sm-5 pr-xl">
            <div class="flex-control input-group">
              <select #site class="form-control" style="max-width:100px;" (change)='onChange(review_link,site)'>
                <option value="" selected>Sites</option>
                <ng-container *ngFor="let site of public_sites; let i = index;">
                  <option [selected]="site.id == review_link.public_site_id" [value]="site.id">{{site.site_name}}</option>
                </ng-container>
              </select>
              <input class="form-control" style="margin: 0 0 0 -1px; border-radius:0;" type="text"
                     id="link_url" [(ngModel)]="review_link.public_site_url" [ngModelOptions]="{standalone: true}">
              <span class="input-group-btn" style="width:auto;">
                <a class="btn btn-danger btn-outline" (click)="deleteLink(review_link)"><i class="fa fa-trash-o"></i></a>
              </span>
            </div>
            <label class="field-label control-label" style="font-weight:500; text-transform:capitalize;" for="auto_review_{{link_index}}">
              <input class="vl-m m0 mr-sm" id="auto_review_{{link_index}}" type="checkbox"
                     name="my_reviews_{{link_index}}" [(ngModel)]="review_link.default_auto_review">
              Select for Review
            </label>
          </div>
        </div>
      </ng-container>
      <div class="form-group">
        <button class="btn btn-primary" type="button" (click)="add_link()">Add Link</button>
      </div>

      <div class="text-center">
        <button class="btn btn-primary mr-sm" type="submit" [disabled]="!locationForm.form.valid">Submit</button>
        <button class="btn btn-inverse mr-sm" type="button" (click)="cancel()">Cancel</button>
        <button *ngIf="location.is_active && location.id" class="btn btn-danger" type="button" (click)="deactive()">Deactive</button>
        <button *ngIf="!location.is_active && location.id" class="btn btn-success" type="button" (click)="active()">Active</button>
      </div>
    </form>
  </div>
</div>
<style>
  .add-groups .col-xs-4{ width:33.33333333%;}
  .add-groups .col-xs-8{ width:66.66666667%;}
</style>
