<div class="flex-header filters-sec">
  <div class="row">
    <div class="col-md-2 col-sm-2"><h4 class="fs16 fw-500">Activity</h4></div>
    <div class="col-md-3 co-sm-4">
      <input [(ngModel)]="dateRangeValue" type="text"
             placeholder="Datepicker"
             class="form-control" [outsideClick]="true"
             (bsValueChange)="setDateRange($event)"
             [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false}"
             bsDaterangepicker/>
    </div>
    <div class="col-md-3 co-sm-4">
      <input type="text" [(ngModel)]="filter['keyword']" class="form-control" (keyup.enter)="search()" placeholder="Search keyword..." />
    </div>
    <div class="col-md-2 co-sm-2">
      <a class="btn btn-tertiary btn-outline" (click)="search()"><i class="fa fa-filter"></i> Filter</a>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th style="min-width:80px;">Time Log</th>
      <th class="col-xs-4">Activity</th>
      <th>Program</th>
      <th>Type</th>
      <th style="min-width:100px;">By</th>
      <th style="min-width:120px;">When</th>
      <th style="width:140px; min-width:140px;">Action</th>
    </tr>
    </thead>
    <tbody *ngIf="activities.length > 0">
    <ng-container *ngFor="let activity of activities">
      <tr>
        <td>{{activity.minutes}} {{activity.minutes == 'N/A' ? '' : 'min'}}</td>
        <td>{{activity.description}}</td>
        <td class="text-capitalize">
          <ng-container *ngIf="activity.time_log_attributes.programme_type">
            {{userService.removeUnderScore(activity.time_log_attributes.programme_type) | uppercase}}
          </ng-container>
        </td>
        <td class="text-capitalize">{{userService.removeUnderScore(activity.c_type)}}</td>
        <td>{{activity.actor_name}}</td>
        <td>{{activity.created_at | date: 'short'}}</td>
        <td>
          <a class="btn btn-secondary btn-outline btn-sm mr-sm" *ngIf="activity.minutes == 'N/A'"
             (click)="selected_activity = activity; logTimeFromList.show()" title="Log Time">
            <i class="fa icon-clock"></i>
          </a>
          <a class="btn btn-secondary btn-outline btn-sm mr-sm" tooltip="Edit"
             (click)="selected_activity = activity;editActivityModel.show();">
            <i class="fa icon-pencil"></i>
          </a>
          <a class="btn btn-danger btn-outline btn-sm" tooltip="Delete"
             (click)="selectedActivityToDelete = activity;deleteModal.show();">
            <i class="fa icon-trash"></i>
          </a>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <app-no-result-found *ngIf="activities && activities.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center">
  <pagination class="m0" *ngIf="activities && totalItems > 10" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
  <a *ngIf="loaderService.uploading_in_process"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i></a>
  <ng-container *ngIf="!loaderService.uploading_in_process">
    <p *ngIf="totalTime" class="mt">
      {{ formatResponse(totalTime) }}
    </p>
  </ng-container>
</div>

<div class="modal fade" bsModal #logTimeFromList="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:210px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="logTimeFromList.hide();selected_activity = undefined"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Log Time</h4>
      </div>
      <div class="modal-body">
        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label class="pt-sm">Time*</label>
          </div>
          <div class="col-md-4 col-sm-5">
            <input type="number" class="form-control" placeholder="Minutes" [(ngModel)]="time" name="minutes">
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!time" (click)="logTimeForActivity(selected_activity);logTimeFromList.hide()">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="logTimeFromList.hide();selected_activity = undefined">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Delete Activity</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p pt-sm pb-sm m0 text-center">“Are you sure you want to delete the Activity?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteActivity(selectedActivityToDelete);deleteModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #editActivityModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:400px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="editActivityModel.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Edit Activity</h4>
      </div>
      <form (ngSubmit)="saveActivity()" #activityLogTime="ngForm" *ngIf="selected_activity">
        <div class="modal-body">
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label control-label">Type</label>
            </div>
            <div class="col-md-4 col-sm-5">{{userService.removeUnderScore(selected_activity.activity_type)}}</div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label control-label">Note*</label>
            </div>
            <div class="col-md-10 col-sm-5">
              <textarea [(ngModel)]="selected_activity.description" placeholder="Description" class="form-control no-resize form-group"
                        style="height:120px;" name="description" required></textarea>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label control-label">Time*</label>
            </div>
            <div class="col-md-4 col-sm-5">
              <input type="number" class="form-control" placeholder="Minutes"
                     [(ngModel)]="selected_activity.time_log_attributes.minutes" name="minutes" required>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-secondary" [disabled]="!activityLogTime.form.valid">Save</button>
          <button type="button" class="btn btn-tertiary btn-outline" (click)="editActivityModel.hide()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
