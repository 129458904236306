import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {EligiblePatient, ProgramEnrollment} from '../../eligible-patient';
import {ProgramService} from '../../program.service';
import {EnrolmentsService} from '../../enrolments.service';
import {Subscription} from 'rxjs/Subscription';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import {VideoChatService} from '../../../video-chat/services/videochat.service';
import {PhoneService} from '../../../voice-call/phone.service';
import {TrackingService} from '../../../services/tracking.service';
import {TabsService} from '../../../services/tabs.service';
import {UserService} from '../../../services/user.service';
import {AppointmentService} from '../../../services/appointment.service';
import {PatientService} from '../../../services/patient.service';
import {TaskService} from '../../../services/task.service';
import {Patient} from '../../../health/doctor/patient';

@Component({
  selector: 'app-enrolled-patient-header',
  templateUrl: './enrolled-patient-header.component.html',
  styleUrls: ['./enrolled-patient-header.component.scss']
})
export class EnrolledPatientHeaderComponent implements OnInit, OnDestroy {
  @Input() patient: EligiblePatient;
  @Input() program_id: any;
  @Input() programEnrollment: ProgramEnrollment;
  @Input() isPatientDetailPage: boolean;
  @Input() programme_type: any;
  @Output() onPatientNameClick: EventEmitter<any> = new EventEmitter<any>();
  public color_code: string;
  public subscription: Subscription;
  public subscription_2: Subscription;
  public subscription_3: Subscription;
  public subscription_4: Subscription;
  dueTaskCount;

  constructor(public programService: ProgramService, public enrolmentsService: EnrolmentsService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public trackingService: TrackingService,
              public phoneService: PhoneService, private _tabs_service: TabsService,
              public loaderService: LoaderService, public videoChatService: VideoChatService,
              public userService: UserService, public appointmentService: AppointmentService,
              public patientService: PatientService, public taskService: TaskService) {
    this.subscription = this.enrolmentsService.reloadProgramEnrolmentTime.subscribe(item => {
      if (item && ((this.programEnrollment.id && this.isPatientDetailPage) || this.programEnrollment.id === item)) {
        this.getProgramEnrolmentTime(this.programEnrollment.id);
      }
    });
    this.subscription_2 = this.taskService.reloadTasks.subscribe(item => {
      if (item.patient_id == this.patient.id) {
        this.getTaskCount();
        console.log(item);
      }
    });
    this.subscription_4 = this.enrolmentsService.reloadProgramEnrolmentObject.subscribe(item => {
      if (item) {
        if (item.id === this.programEnrollment.id) {
          this.programEnrollment.load_from_json(item);
        }
      }
    });
    this.subscription_3 = this.enrolmentsService.reloadSummary.subscribe(item => {
      if (item['patient_id'] === this.patient.id) {
        if (item['chronic_diagnosis_name_code']) {
          this.patient.chronic_diagnosis_name_code = item['chronic_diagnosis_name_code']
        }
        if (item['allergies']) {
          this.patient.allergies = item['allergies']
        }
      }
    })
  }

  ngOnInit(): void {
    this.getTaskCount();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription_4.unsubscribe();
    this.subscription_2.unsubscribe();
    this.subscription_3.unsubscribe();
  }

  clickPatientName(patient: EligiblePatient) {
    this.onPatientNameClick.emit(patient)
  }

  getProgramEnrolmentTime(id: any) {
    this.loaderService.small_loder_show();
    this.enrolmentsService.getEnrolmentTime(id).then(resp => {
      this.enrolmentsService.timeLogSubscription.next({
        time: resp.time, patient_id: this.programEnrollment.patient_id, programe_id: this.programEnrollment.program_id
      });
      this.programEnrollment.time = resp.time;
      this.loaderService.small_loder_hide();
    }).catch( resp => {
      console.log(resp)
      this.loaderService.small_loder_hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  wait_class(ticker: any) {
    let waiting_time = Number(Math.ceil(ticker / 5));
    if (ticker > 20) {
      return '#01d5b0';
    }
    let val = {4: '#008000' , 3: '#e5e500' , 2: '#FF8C00', 1: '#ff0000', 0: '#ff0000'}
    let color_val = val[waiting_time];
    this.color_code = color_val;
    return this.color_code;
  }

  get formatClock(): any {
    // tslint:disable-next-line:max-line-length
    // let tabb: Tab = this._tabs_service.tabs.filter(tab => (tab.dependencies['patient']['id'] === this.programEnrollment.patient_id) && (tab.dependencies['program']['id'] === this.programEnrollment.program_id))[0];
    // let enrolment = tabb.dependencies['enrolment'];
    if (isNaN(parseInt(this.programEnrollment.session_time) )) {
      return '00:00'
    }
    return  new Date(this.programEnrollment.session_time * 1000).toISOString().substr(14, 5);
  }

  weightInPounds(w) {
    return (w * 2.2046).toFixed(2).toString();
  }

  showSeverityField(): boolean {
    // return (this.programEnrollment.enrollment_type === 'ccm' || this.patient.enrolled_in.toString().toLowerCase() === 'ccm')
    return (this.programEnrollment.enrollment_types.includes('ccm')  || this.patient.enrolled_in.includes('ccm'))
  }

  selectedClass(p: string) {
    if (this.isPatientDetailPage) {
      return this.programme_type.toLowerCase() == p.toLowerCase() ? 'label text-capitalize health-status stable' : ''
    }
  }
  getTaskCount() {
    this.dueTaskCount = '';
    this.taskService.dueTaskCount(this.patient.id).then(resp => {
      this.dueTaskCount = resp
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
}
