<div class="filters-sec flex-header">
  <div class="row">
    <div class="col-xs-4">
      <input class="form-control" [(ngModel)]="filter['name']" (keyup.enter)="search()" placeholder="Name">
    </div>
    <div class="col-xs-2">
      <select [(ngModel)]="filter['reminder_type']" class="form-control">
        <option value="">Reminders Type</option>
        <option value='notification'>Notification</option>
        <option value='reminder'>Reminder</option>
        <option value='birthday'>Birthday</option>
        <option value='recall'>Recall</option>
      </select>
    </div>

    <div class="col-xs-2 col-md-5 col-sm-12">
      <button type="button" class="btn btn-tertiary btn-outline" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      <button type="button" class="btn btn-secondary ml" (click)="addReminder()" >Add Reminder</button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover table-striped">
    <thead>
    <tr>
      <th>Name</th>
      <th>Type</th>
      <th>Source</th>
      <th>Outreach</th>
      <th>Trigger On</th>
      <th class="text-center">Status</th>
      <th class="text-center">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let template of reminders_template">
      <tr>
        <td><a (click)="editReminder(template)">{{template.name}}</a></td>
        <td class="text-capitalize">{{template.reminder_type}}</td>
        <td class="text-capitalize">{{template.source}}</td>
        <td><p class="m0 text-capitalize">{{template.outreach_type}}</p></td>
        <td>{{template.trigger_on}}</td>
        <td class="text-center">
          <label *ngIf="template.status == 'stopped'" class="label label-danger m0">{{capitalize(template.status)}}</label>
          <label *ngIf="template.status == 'running'" class="label label-success m0">{{capitalize(template.status)}}</label>
        </td>
        <td class="text-center">
          <a class="btn btn-secondary btn-outline btn-sm ml" tooltip="Start" (click)="updateStatus(template,'running')" *ngIf="template.status == 'stopped'">
            Start
          </a>
          <a class="btn btn-secondary btn-outline btn-sm ml" tooltip="Stop" (click)="updateStatus(template,'stopped')" *ngIf="template.status == 'running'">
            Stop
          </a>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <app-no-result-found *ngIf="reminders_template && reminders_template.length == 0"></app-no-result-found>
  <div class="text-center" *ngIf="reminders_template && totalItems > 10">
    <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
  </div>
</div>

