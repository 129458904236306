import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CampaignService} from '../../campaign.service';
import {Campaign} from '../../campaign';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {PatientProfileComponent} from '../../../health/patient-profile/patient-profile.component';
import {Tab} from '../../../tabs/tab';
import {TabsService} from '../../../services/tabs.service';


@Component({
  selector: 'app-email-campaign-recipients-list',
  templateUrl: './email-campaign-recipients-list.component.html',
  styleUrls: ['./email-campaign-recipients-list.component.scss']
})
export class EmailCampaignRecipientsListComponent implements OnInit {
  @Input() recipientsList;
  @Input() campaign;
  @Input() totalItems;
  @Input() filter;
  @Input() showAudienceTable;
  @Output() closed  = new EventEmitter<Boolean>();

  constructor(public campaignService: CampaignService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public tabsService: TabsService) { }

  ngOnInit(): void {
  }

  search() {
    this.filter['page'] = 1;
    if (this.showAudienceTable) {
      this.getAudienceList();

    } else {
      this.getRecipientsList();
    }
  }
  getRecipientsList() {
    this.loaderService.show();
    this.campaignService.campaignRecipientsList(this.campaign.id, this.filter)
      .then(
        resp => {
          this.recipientsList = resp.recipientsList;
          this.totalItems = resp.total;
          this.filter['page'] = resp.current_page;
          this.loaderService.hide();
        }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  getAudienceList() {
    this.loaderService.show();
    this.campaignService.campaignAudienceList(this.campaign.id, this.filter)
      .then(
        resp => {
          this.recipientsList = resp.audienceList;
          this.totalItems = resp.total;
          this.filter['page'] = resp.current_page;
          this.loaderService.hide();
        }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  pageChanged(page) {
    this.filter['page'] = page.page;
    if (this.showAudienceTable) {
      this.getAudienceList();
    } else {
      this.getRecipientsList();
    }
  }

  add_to_tabs(recipient) {
    if (recipient.contact_type === 'Patient') {
      let title = recipient.name + '-' + 'Profile';
      let id = recipient.patient_id;
      this.tabsService.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id, {id: id, recipient: recipient}))
    }
  }

  closeDiv() {
    this.closed.emit(true);
  }



}
