<div *ngIf="appointment" class="main-wrapper">
  <app-payment-header [appointment]="appointment"></app-payment-header>
</div>
<div class="bg-lightest-gray main-wrapper pt-lg">
  <h5 class="text-center mt0"><i class="fa fa-check"></i> &nbsp;Admit</h5>
  <div class="white-container-box p-lg">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <form #admissionForm="ngForm" (ngSubmit)="save()">
          <div class="row form-group">
            <label class="col-sm-3 pt-sm">Reason of Admission*</label>
            <div class="col-sm-9">
              <input [typeahead]="services" name="service" [(ngModel)]="case.service_name" typeaheadWaitMs="500"
                     (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadAsync]="true"
                     (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                     (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                     [typeaheadOptionField]="'name'" placeholder="Search Reason"
                     class="form-control" required autocomplete="off">
              <small *ngIf="customValidations.formErrors.service" class="text-danger">{{ customValidations.formErrors.service }}</small>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-sm-3 pt-sm">Department*</label>
            <div class="col-sm-9">
              <select #department required class="form-control" name="department" (change)='selectDepartment(department)'>
                <option selected [value]="0">Select Department</option>
                <option *ngFor="let department of departments" [selected]="case.department_id == department.id" value={{department.id}}>
                  {{department.name}}
                </option>
              </select>
              <small *ngIf="show_error" class="text-danger">Department is required</small>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-sm-3 pt-sm">Ward*</label>
            <div class="col-sm-6">
              <input type="text" class="form-control" id="ward" [(ngModel)]="case.ward" name="ward" placeholder="Ward" required>
              <small *ngIf="customValidations.formErrors.ward" class="text-danger">{{ customValidations.formErrors.ward }}</small>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-sm-3 pt-sm">Bed Number*</label>
            <div class="col-sm-6">
              <input type="text" class="form-control" id="bed_number" [(ngModel)]="case.bed_number" name="bed_number" placeholder="Bed Number" required>
              <small *ngIf="customValidations.formErrors.bed_number" class="text-danger">{{ customValidations.formErrors.bed_number }}</small>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-sm-3 pt-sm">Doctor</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="Doctor" name="doctor"
                     [(ngModel)]="case.doctor_name" typeaheadWaitMs="500" [typeahead]="doctors_data" (typeaheadLoading)="doctorTypeaheadLoading($event)"
                     (typeaheadNoResults)="doctorTypeaheadNoResults($event)" [typeaheadAsync]="true"
                     (typeaheadOnSelect)="doctortypeaheadOnSelect($event)"
                     [typeaheadOptionsLimit]="7"
                     autocomplete="off"
                     [typeaheadOptionField]="'doctor_full_name'"/>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-sm-3 pt-sm">Staff</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="Staff" name="staff"
                     [(ngModel)]="case.staff_name" typeaheadWaitMs="500" [typeahead]="data_source" (typeaheadLoading)="staffTypeaheadLoading($event)"
                     (typeaheadNoResults)="staffTypeaheadNoResults($event)" [typeaheadAsync]="true"
                     (typeaheadOnSelect)="stafftypeaheadOnSelect($event)"
                     [typeaheadOptionsLimit]="7"
                     autocomplete="off"
                     [typeaheadOptionField]="'name'"/>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-sm-3 pt-sm">Comment</label>
            <div class="col-sm-9">
              <textarea rows="4" cols="80" type="text" class="form-control" id="comment" [(ngModel)]="case.comment" name="comment" maxlength="500"
                        placeholder="Comment"></textarea>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-sm-3 pt-sm"></label>
            <div class="col-sm-9">
              <button [disabled]="!admissionForm.form.valid" class="btn btn-primary" type="submit">Admit Patient</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
