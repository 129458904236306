import { Component, OnInit , Input } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import  {Physician} from '../models/physician'
import {Router, ActivatedRoute} from '@angular/router';
import {SearchService} from '../../services/search.service';
@Component({
  selector: 'app-doctor-block',
  templateUrl: './doctor-block.component.html',
  styleUrls: ['./doctor-block.component.scss']
})
export class DoctorBlockComponent implements OnInit {

  @Input() physician: Physician;

  public  MAPPINGS = {
    query: '', q: '', specialty_id: '', page: '',
    lat: '', long: '', service_id: '', gender: '', shift: '',
    radius: '', sort_by: '', term: '', illness_term: '', area_text: '', area: ''
  };
  constructor(private route: ActivatedRoute, private router: Router, public searchService: SearchService) { }

  ngOnInit() {
  }

  setParams(id) {
    this.router.navigate([`doctors/${id}`], { queryParams: this.search_params });
  }

  get search_params() {
    this.route.queryParams.subscribe(params => {
      Object.keys(this.MAPPINGS).forEach(key => {
        if (params[key]) {
          this.MAPPINGS[key] = params[key];
        }
      });
      window.scrollTo(0, 0)
    });
    return this.MAPPINGS;
  }
}
