import {Component, OnInit, ElementRef, Input, Injector, ViewChild} from '@angular/core';
import {LoaderService} from '../../../../../services/loader.service';
import {ScanService} from '../../../../../services/scan.service';
import {DragulaService} from 'ng2-dragula';
import {Appointment} from '../../../../../health/models/appointment';
import {
  PatientDocument, DocumentType,
  DocumentPage
} from '../../../../../health/patient-profile/patient-dashboard/chart/document';
import {Service} from '../../../../practices/practices/practice';
import {DocumentsService} from '../../../../../services/document.service';
import {DoctorService} from '../../../../../services/doctor.service';
import {Doctor} from '../../../../practices/doctors/doctor';
import {Observable} from 'rxjs';
import {SpecialtySearchService} from '../../../../../services/specialty-search.service';
import {Case} from '../../../case';

import {NgForm} from '@angular/forms';
import { mergeMap } from 'rxjs/operators';


@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})

export class ScanComponent implements OnInit {
  @ViewChild('upload_form', {static: true}) public upload_form: NgForm;
  @ViewChild('upload_form1', { static: false }) uploadForm: NgForm;
  pages: DocumentPage[] = [];
  mouse_pressed =  false;
  public asyncSelected = '';
  show_attachment_popup = false;
  document: PatientDocument = new PatientDocument();
  appointment: Appointment;
  public admission: Case;
  public documentsType: DocumentType[] = [];
  public doctors: Doctor[] = [];
  message = '';
  public futureDate: string;
  message_type = 'text-primary';
  public services: Observable<any[]>;
  public date2DisabledDates;

  constructor(public loderService: LoaderService, public scan_service: ScanService, private dragulaService: DragulaService, private _elementRef: ElementRef, private injector: Injector, private _documentService: DocumentsService, private _doctorService: DoctorService
    , private specialtySearchService: SpecialtySearchService) {
    if (dragulaService.find('pages-bag') == null) {
      dragulaService.createGroup('pages-bag', {
        accepts: function (el, target, source, sibling) {
          return !(target.id == 'drag-pages' && source.id == 'drag-pages');
        },
      });
    }
  }

  create_document() {
    let pages = this._elementRef.nativeElement.querySelectorAll('#drop-pages .page');
    let ids = {};
    if (pages.length == 0) {
      this.message = 'No Page selected to create a document';
      this.message_type = 'text-danger';
      setTimeout(() => {
        this.message = '';
      }, 3000);
      return;
    }
    pages.forEach((a, i) => ids[a.getAttribute('data-id')] = i);
    let data = this.document.to_params();
    data['attachable_id'] = this.appointment ? this.appointment.id : this.admission.id;
    data['attachable_type'] = this.appointment ? 'Appointment' : 'Case';
    data['attachment_ids'] = ids;
    this.scan_service.create_document(data).then(response => {
      this.message = response.message;
      if (response.unscanned_pages <= 0) {
        this.pages = [];
      }
      this.scan_service.set_data(response.unscanned_pages, data['attachable_id']);
      this.message_type = 'text-primary';
      this._elementRef.nativeElement.querySelector('#drop-pages').innerHTML = '';
      this.document = new PatientDocument();
      this.uploadForm.resetForm();
      setTimeout(() => {
        this.message = '';
      }, 3000);
    }).catch((res: Response) => {
        let response = res;
        this.message = response['message'];
        this.message_type = 'text-danger';
        setTimeout(() => {
          this.message = '';
        }, 3000);
      })
  }


  ngOnInit() {
    this.appointment = this.injector.get('appointment', null);
    this.admission = this.injector.get('admission', null);
    this.scan_service.get_waiting(this.appointment ? this.appointment : this.admission).then(response => {
      response.forEach(page => this.pages.push(new DocumentPage().load_from_json(page)));
    });
    this.fetchDoctors();
    this.fetchDocumentTypes();
    this.services = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.get_services(token)));
    if(this.appointment && this.appointment.service.id){
      this.asyncSelected = this.appointment.service.name;
      this.document.service_id = this.appointment.service.id;
    }
    if (this.appointment && this.appointment.isCheckedOut() && this.appointment && this.appointment.doctor) {
      this.document.doctor_id =  this.appointment.doctor.id;
    }
  }
  handle_image_upload(data) {
    if (data == false) {
      return;
    }
     this.scan_service.scanning_in_process = true;
     this.scan_service.start_scan(data).then(response => {
       response.forEach(page => this.pages.push(new DocumentPage().load_from_json(page)));
       this.scan_service.set_data((this.scan_service.get_data(this.appointment ? this.appointment.id : this.admission.id) + response.length), this.appointment ? this.appointment.id : this.admission.id);
       this.scan_service.scanning_in_process = false;
     });
  }

  removeUnscannedPages() {
    this.scan_service.removePendingPages(this.appointment ? this.appointment : this.admission).then(response => {
      if (response.message) {
        this.scan_service.set_data(0, this.appointment ? this.appointment.id : this.admission.id);
        this.pages = [];
      }
    });
  }

  get_services(term: any) {
    return this.specialtySearchService.search_services(term);
  }
  fetchDocumentTypes() {
    this._documentService.getDocumentsType().then(resp => {
      let documentsType = resp.map(documentType => new DocumentType().load_from_json(documentType));
      this.documentsType = documentsType;
    })
  }
  selectService(event) {
    this.document.service_id = event.item.id;
  }
  fetchDoctors() {
    if (this.appointment && this.appointment.patient_id) {
      this._doctorService.getPatientDoctors(this.appointment.patient_id).then(res => this.doctors = res.map(doctor => new Doctor().load_from_json(doctor)))
    } else {
      if (this.admission && this.admission.appointment.patient_id) {
        this._doctorService.getPatientDoctors(this.admission.appointment.patient_id).then(res => this.doctors = res.map(doctor => new Doctor().load_from_json(doctor)))
      }
    }
  }

  click_started(event) {
    this.mouse_pressed = true;
  }

  mouse_released(event) {
    this.mouse_pressed = false;
  }
  choose_file() {
    this.show_attachment_popup = true;
    setTimeout( () => {
      this.show_attachment_popup = false;
    }, 75);
  }
  scrollToElement($element: HTMLHeadingElement){
    $element.scrollIntoView({ behavior:"smooth", block:"start"});
  }
}
