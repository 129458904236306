import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked } from '@angular/core';
import { Service, Practice } from '../practices/practice'
import { ServicesService } from '../../../services/services.service'
import { ContactService } from '../../../services/contact.service'
import { CustomValidations } from '../../customValidations/custom-validations'
import { NgForm } from '@angular/forms';
import {Observable} from 'rxjs';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import { SelectComponent } from 'ng2-select'
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit, AfterViewChecked {
  @Input() practice: Practice;
  @Input() service: Service;
  public total_services: any[];
  public technicians: any[];
  public total_service_technicians;
  customValidations: CustomValidations
  asyncSelected: any = '';
  services: any[];
  @ViewChild('recipientsInput') recipientsInput: SelectComponent

  @Output()  reset_service_form: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('serviceForm') serviceForm: NgForm;
  constructor(private servicesService: ServicesService, private contactService: ContactService, private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.customValidations = new CustomValidations();

    this.services = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.service.service.name);
    }).pipe(mergeMap((token: string) => this.getServices(token)));

    this.contactService.getAllstaffs()
      .then(response => {
        this.total_service_technicians = response;
      });
  }
  ngOnInit() {
  }
  getServices(term: any) {
    return this.servicesService.search_services(term);
  }
  public selected_service(value: any): void {
    this.service.service.name = value.text;
    this.service.service_id = (value.id);
  }
  public typeaheadOnSelect(value: any): void {
    this.service.service.name = value.item.description;
    this.service.service_id = (value.item.id);
  }
  public removed_service(value: any): void {
    this.service.service_id = null;
  }

  typedServices(text: string) {
    this.getServices(text).subscribe(a => this.total_services = a.map(sp => {return {id: sp.id, text: sp.name}}));
  }
  typedServiceTechnicians(text: string) {
    this.getServiceTechnicians(text).subscribe(a => {
      let technicians = a.map(sp => {return {id: sp.id, text: sp.name}});
      this.recipientsInput.items = technicians;
      (<any>this.recipientsInput).open();

    });
  }

  getServiceTechnicians(term: any) {return this.contactService.get_practice_staff(this.practice.id, term);
  }
  get get_service(): any {
    return this.service;
  }
  getPreselectedTechnicians() {
    return this.service.existing_service_technicians;
  }
  public selected_service_technician(value: any): void {
   let  service_technician = this.total_service_technicians.filter(service_technician => service_technician.id == value.id)[0];
    this.service.add_service_technician(service_technician);

  }

  public removed_service_technician(value: any): void {
    let index = this.get_service_technicians.map(function(service_technicians) {return service_technicians.id; }).indexOf(value.id);
    this.get_service_technicians.splice(index, 1);
  }
  get get_service_technicians(): any {
    return this.service.service_technicians;
  }
  typedTechnicians(text: string) {
    this.contactService.get_practice_staff(this.practice.id, text).subscribe(a => this.technicians = a.map(tech => {return {id: tech.id, text: tech.name}}));
  }
  save() {
    this.service.client_id = this.practice.client.id
    this.servicesService.save(this.service).then(res => {
      if (!this.service.id) {
        let service = new Service().load_from_json(res);
        this.reset_service_form.emit(service);
      }
      else {
        this.reset_service_form.emit(false)
      }
    }).catch(res =>  this.globalErrorHandlerService.error = res.error.error );
  }
  cancel() {
    this.reset_service_form.emit(false)
  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.serviceForm);
  }
}
