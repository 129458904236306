<div class="flex-control flex-column">
  <div class="flex-body scroll-browser p">
    <div><button class="btn btn-secondary" (click)="importLibrary()">Import Library</button></div>
    <ng-container *ngIf="show_form">
      <div *ngIf="library">
        <div class="ml-xl">
          <form (ngSubmit)="save()" #chartForm="ngForm">
            <div class="mt-lg form-group row">
              <label class="col-sm-1 field-label control-label">Name</label>
              <div class="col-sm-3">
                <input type="text" class="form-control" name="name" [(ngModel)]="library.name" required>
              </div>
              <label class="col-sm-1 field-label control-label">Version</label>
              <div class="col-sm-3">
                <input type="text" class="form-control" name="version" [(ngModel)]="library.version" required>
              </div>
              <label class="col-sm-1 field-label control-label">Type</label>
              <div class="col-sm-3">
                <select #type class="form-control" [(ngModel)]="library.library_type_id" name="library_type_id"
                        required>
                  <option selected="selected" value="">
                    Type
                  </option>
                  <option *ngFor="let libraryType of libraryTypes; let i = index;" value={{libraryType.id}}>
                    {{libraryType.lib_type.toUpperCase()}}
                  </option>
                </select>
              </div>
              <label class="col-sm-1 field-label control-label">Document</label>
              <div class="col-sm-3">
                <a (click)="show_attachment_popup = true" type="file" class="btn btn-link pl0"><i
                  class="fa fa-file-o fa-fw"></i> Chose File</a>
                <img *ngIf="library.library_data" [src]="library.library_data" alt="" class="thumb32">
              </div>
              <ng-container *ngIf="columns">
                <div class="row">
                  <div class="col-sm-12 mt-lg">
                    <table>
                      <tr>
                        <th class="col-sm-2">Header</th>
                        <th class="col-sm-2">Column</th>
                        <th class="col-sm-1">Is searchable</th>
                        <th class="col-sm-1">Show in search result</th>
                        <th class="col-sm-1">Codeable</th>
                        <th class="col-sm-2">Column</th>
                        <th class="col-sm-2">Parent</th>
                      </tr>
                      <tr *ngFor="let library_setting of library.library_settings; let i = index">
                        <td class="col-sm-2">{{library_setting.header_name}}</td>
                        <td class="col-sm-2">
                          <select class="form-control" name="mapper" (change)="onchange($event,library_setting)">
                            <ng-container *ngFor="let column of columns;">
                              <option [selected]="library_setting.column_number == column" [value]="column">{{column}}</option>
                            </ng-container>
                          </select>
                        </td>
                        <td class="col-sm-1">
                          <input type="checkbox" name="searchable" class="form-check-input"
                                 [(ngModel)]="library_setting.is_searchable">
                        </td>
                        <td class="col-sm-1">
                          <input type="checkbox" name="search_result" class="form-check-input"
                                 [(ngModel)]="library_setting.search_result">
                        </td>
                        <td class="col-sm-1">
                          <input type="radio" [value]="library_setting.header_name" [(ngModel)]="library.codeable_column"   name="codeable" class="form-check-input">
                        </td>
                        <td class="col-sm-2">{{library_setting.header_name}}</td>
                        <td class="col-sm-2">
                          <select class="form-control" name="mapper" (change)="setParent($event,library_setting)">
                            <option selected [value]="''">Select Parent</option>
                            <ng-container *ngFor="let header of headers;">
                              <option [value]="header">{{header}}</option>
                            </ng-container>
                          </select>
                        </td>
                        <td class="text-danger">{{library_setting.error_message}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row">
              <div class="col-sm-3 mt-lg">
                <button type="submit" class="btn btn-secondary mr-sm">Submit</button>
                <button class="btn btn-tertiary btn-outline" type="button" (click)="importLibrary()">Cancel</button>
              </div>
            </div>
          </form>
          <app-attachments [auto_upload]="true" (document_uploaded)="handle_image_upload($event)"
                           [fileType]="'Enote::Library'" [only_modal]="true"
                           [attachable_id]="library.id" [attachable_type]="'Enote::Library'"
                           [show]="show_attachment_popup"></app-attachments>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="libraries.length > 0"><app-libraries [libraries]="libraries"></app-libraries></ng-container>
  </div>
</div>

