<div class="flex-control flex-column flex-height" *ngIf="appointment.id">
  <app-payment-header class="flex-header" [appointment]="appointment"></app-payment-header>
  <div class="flex-body flex-control bg-lightest-gray p" *ngIf="_user_service.current_user && load_practice_data(_user_service.current_user)">
    <h4 class="mt0">Reschedule</h4>
    <div class="border-box flex-control flex-column scroll-browser p">
      <div class="row">
        <div class="col-sm-8 col-xs-12">
          <form (ngSubmit)="book_appointment('walkin')" #walkinForm="ngForm">
            <div class="row form-group">
              <label class="col-sm-3 field-label control-label">Patient</label>
              <div class="col-sm-7">
                <h5 class="m0 mt-sm">{{appointment.patient.name}} - {{appointment.patient.mobile_number_value}}</h5>
              </div>
            </div>
            <div class="row form-group" *ngIf="appointment.amenity_name">
              <label class="col-sm-3 field-label control-label">Service</label>
              <div class="col-sm-7">{{appointment.amenity_name}}</div>
            </div>
            <div class="row form-group">
              <label class="col-sm-3 field-label control-label">Reason of Visit</label>
              <div class="col-sm-7">
                <input [typeahead]="dataSourceServices" name="service" [typeaheadAsync]="true"
                       [(ngModel)]="asyncSelectedService" typeaheadWaitMs="500"
                       (typeaheadLoading)="changeTypeaheadLoadingService($event)"
                       (typeaheadNoResults)="changeTypeaheadNoResultsService($event)"
                       (typeaheadOnSelect)="typeaheadOnSelectService($event)" [typeaheadOptionsLimit]="7"
                       [typeaheadOptionField]="'name'" placeholder="Search Reason"
                       class="form-control" type='text' autocomplete="off">
              </div>
            </div>
            <div class="row form-group">
              <label class="col-sm-3 field-label control-label">Doctor</label>
              <div class="col-sm-7" *ngIf="appointment.amenity_id">{{appointment.doctor.complete_name}}</div>
              <div class="col-sm-7" *ngIf="!appointment.amenity_id">
                <ng-container *ngIf="user.isDoctor == false">
                  <select [(ngModel)]="doctor_id" name="doctor" #provider class="form-control"
                          (change)='change_provider(provider.value)'>
                    <option value="">Providers</option>
                    <option *ngFor="let provider of providers"
                            [value]="provider.id">{{provider.firstname}} {{provider.lastname}}</option>
                  </select>
                </ng-container>
                <ng-container *ngIf="user.isDoctor">
                  <select [(ngModel)]="doctor_id" name="doctor" #provider class="form-control"
                          (change)='change_provider(provider.value)'>
                    <option
                      [value]="doctor_id">{{user.profileable.first_name}} {{user.profileable.last_name}} </option>
                  </select>
                </ng-container>
              </div>
            </div>
            <div class="row form-group">
              <label class="col-sm-3"></label>
              <div class="col-sm-7">
                <app-slot-navigator [amenity_id]="appointment.amenity_id"
                                    [allow_past_navigation]="true"
                                    [location_id]="appointment.location_id"
                                    [appointment_type]='appointment.appointment_type'
                                    [resch_appointment_ids]="[appointment.parent_id]"
                                    [physicians]="[doctor.physician]" [autoload]="true"
                                    [refresh]="refresh"></app-slot-navigator>
                <app-slots [grouped_slots]="doctor.physician.grouped_slots" [no_navigate]="true"
                           (slot_clicked)="handle_slot($event)"></app-slots>
              </div>
            </div>
            <div class="row form-group">
              <label class="col-sm-3"></label>
              <div class="col-sm-7">
                <button type="submit" class="btn btn-secondary" [disabled]="is_disable">Reschedule</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
