import {Component, Input, OnInit} from '@angular/core';
import {PatientService} from '../../../services/patient.service';
import {Econtact} from '../../doctor/econtact';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {AlertService} from '../../../_alert';
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-econtact',
  templateUrl: './econtact.component.html',
  styleUrls: ['./econtact.component.scss']
})
export class EcontactComponent implements OnInit {
  public number_value: number;
  public econtactArray: Econtact[] = [];
  public generalEcontactArray: Econtact[] = [];
  public providersEcontactArray: Econtact[] = [];
  public econtact: Econtact = new Econtact();
  @Input() patient;
  @Input() intakeReport;
  public openContactForm = false;
  public openProviderForm = false;
  selectedEcontactToDelete: Econtact;

  constructor(public patientService: PatientService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public alertService: AlertService
              ) { }

  ngOnInit(): void {
    this.getPatientRelationships();
    if (this.patient.id) {
      this.getPatientEmergencyContacts();
    }
  }


  save() {
    this.loaderService.show();
    this.econtact.source_type = 'Patient';
    this.econtact.contact_type = 'emergency';
    this.econtact.source_id = this.patient.id;
/*    if (this.number_value) {
      this.econtact.contact_number.value = this.number_value.toString();
    }*/
    this.patientService.savePatientEcontact(this.econtact).then( resp => {
        this.getPatientEmergencyContacts();
        this.econtact = new Econtact();
        this.number_value = undefined;
        this.openContactForm = false;
        this.openProviderForm = false;
        this.loaderService.hide();
        this.alertService.success('Changes have been saved successfully.', this.alertService.options);
    }).catch(
        resp => {
          console.log(resp);
          this.loaderService.hide();
          this.globalErrorHandlerService.error = resp.error.error;
        }
    )
  }

  getPatientEmergencyContacts() {
    this.loaderService.show();
    this.patientService.getPatientEmergencyContacts(this.patient.id).then( resp => {
      this.econtactArray = resp.map(a => new Econtact().load_from_json(a));
      this.contactSeparator(this.econtactArray);
      this.loaderService.hide();
    }).catch(
        resp => {
          console.log(resp)
          this.loaderService.hide();
          this.globalErrorHandlerService.error = resp.error.error;
        }
    )

  }

  deleteEContact(contact: Econtact) {
    this.loaderService.show();
    this.patientService.deleteEContact(contact.id).then(resp => {
      this.econtactArray.splice(this.econtactArray.indexOf(contact), 1);
      this.contactSeparator(this.econtactArray);
      this.loaderService.hide();
        this.alertService.success('Deleted successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  editEContact(econtact: Econtact, type) {
    this.econtact = new Econtact().load_from_json(econtact);
    this.openForm(type);
  }

  addEcontactForm(type: string) {
    this.econtact = new Econtact();
    this.openForm(type);
  }

  openForm(type: string) {
    switch (type) {
      case 'econtact':
        this.openContactForm = true;
        this.openProviderForm = false
        break;
      case 'provider':
        this.openProviderForm = true;
        this.openContactForm = false;
        break;
      default:
        break;
    }
  }

  getPatientRelationships() {
    this.patientService.getPatientRelationships().then(resp => {
      this.separateRelationshipAndProviders(resp);
    }).catch( resp => {
      this.globalErrorHandlerService = resp.error.error;
    })
  }

  private contactSeparator(econtactArray: Econtact[]) {
    this.providersEcontactArray = [];
    this.generalEcontactArray = [];
    econtactArray.forEach(contact => {
      if (this.patientService.providers.includes(contact.relationship_name)) {
        this.providersEcontactArray.push(contact);
      } else {
        this.generalEcontactArray.push(contact);
      }
    });
  }

  separateRelationshipAndProviders(relationship: []) {
    this.patientService.patient_providers = relationship.filter(rel => this.patientService.providers.includes(rel['name']));
    this.patientService.patient_relationships = relationship.filter(rel => !this.patientService.providers.includes(rel['name']));
  }
   provider_name_address(econtact) {
    let addressParts = [econtact.name];

    if (econtact.facility_name) {
      addressParts.push(econtact.facility_name);
    }

    if (econtact.speciality) {
      addressParts.push(econtact.speciality);
    }

    if (econtact.address) {
      let address = econtact.address;
      let cityArea = address.city_area;

      if (address.line_1) {
        addressParts.push(address.line_1);
      }

      if (cityArea && cityArea.city) {
        let city = cityArea.city;

        if (city.name) {
          addressParts.push(city.name);
        }

        if (city.state) {
          addressParts.push(city.state);
        }
      }

      if (address.postal_code) {
        addressParts.push(address.postal_code);
      }
    }

    return addressParts.join(', ');
  }
}
