import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Program} from '../../program';
import {TabsService} from '../../../services/tabs.service';
import {Tab} from '../../../tabs/tab';
import {ProgramEnrolmentComponent} from '../../program-enrolment/program-enrolment.component';
import {EligiblePatient, ProgramEnrollment} from '../../eligible-patient';
import {LoaderService} from '../../../services/loader.service';
import {ProgramService} from '../../program.service';
import {EnrolledPatientComponent} from '../../enrolled-patient/enrolled-patient.component';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {EnrolmentsService} from '../../enrolments.service';
import {AppointmentService} from '../../../services/appointment.service';
import {UserService} from '../../../services/user.service';
import {Alert} from '../../alert';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-program-enrolled-patients',
  templateUrl: './program-enrolled-patients.component.html',
  styleUrls: ['./program-enrolled-patients.component.scss']
})
export class ProgramEnrolledPatientsComponent implements OnInit, OnDestroy {
  @Input() program: Program;
  public filter: Object = {id: '', page: 1, token: '', alert_type: '', compliance_level: '', health_status: ''};
  public totalItems: any;
  public alter_type_patients: any;
  public subscription: Subscription;
  public subscription_2: Subscription;
  public subscription_3: Subscription;
  public subscription_4: Subscription;
  public selectProgramType: any;
  public enrolled_in: any[];
  public selectedPatient: EligiblePatient;
  @ViewChild('selectTypeModal') selectTypeModal;
  constructor(public programService: ProgramService, public tabs_service: TabsService,
              public enrolmentService: EnrolmentsService,
              public appointmentService: AppointmentService, private enrolmentsService: EnrolmentsService,
              public loaderService: LoaderService, public userService: UserService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
    this.subscription = this.programService.enrolledPatientListener.subscribe(patient => {
      if (this.program.enrolledPatients?.filter(enrolled_patient => patient.id === enrolled_patient.id).length === 0) {
        this.program.enrolledPatients?.unshift(new EligiblePatient().load_from_json(patient));
      }
    })
    this.subscription_2 = this.programService.patientOptOutListener.subscribe(patient_id => {
      this.program.enrolledPatients = this.program.enrolledPatients.filter(patient => patient.id !== patient_id);
      this.totalItems -= 1;
    })
    this.subscription_3 = this.appointmentService.patientStatusObservable.subscribe(val => {
      const patientToUpdate = this.program.enrolledPatients?.find(patient => val.user_id === patient.user_id);
      if (patientToUpdate) {
        patientToUpdate.portal_online_status = val.portal_online_status;
        patientToUpdate.mobile_online_status = val.mobile_online_status;
      }
    });
    this.subscription_4 = this.enrolmentsService.alertPushed.subscribe((alert: Alert) => {
      if (alert) {
        let patient = this.program.enrolledPatients.filter(p => p.id === alert.patient_id)[0]
        if (patient.tem_programme_enrolment) {
          patient.tem_programme_enrolment.unread_alerts!.total += 1;
        }
      }
    });
  }

  ngOnInit(): void {
    this.getProgramEnrolledPatient();
    this.enrolmentService.timeLogSubscription.subscribe(item => {
      if (item) {
        this.program.enrolledPatients.filter(patient => {
          if (patient.id === item['patient_id']) {
            patient.tem_programme_enrolment.time = item['time'];
          }
        })
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription_2.unsubscribe();
    this.subscription_3.unsubscribe();
    this.subscription_4.unsubscribe();

  }

  enrollPatient( program: Program ) {
    this.tabs_service.add_tab(new Tab({title: 'Enroll Patient', icon: 'fa', autorefresh: false}, ProgramEnrolmentComponent,
      program.id, { program_id: program.id, program: program, patient: new EligiblePatient(), programEnrollment: new ProgramEnrollment() }))
  }

  getProgramEnrolledPatient() {
    this.loaderService.show();
    this.filter['id'] = this.program.id;
    console.log(this.filter)
    this.programService.getProgramsEnrolledPatients(this.filter).then(resp => {
      this.program.enrolledPatients = resp.programme_patients.map(p => new EligiblePatient().load_from_json(p));
      console.log(this.program.enrolledPatients);
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  openEnrolledPatient(patient: EligiblePatient) {
    this.selectedPatient = new EligiblePatient().load_from_json(patient)
    if (patient.enrolled_in.length == 1) {
      this.selectProgramType = patient.enrolled_in[0];
      this.openPatient(patient);
      } else {
      this.enrolled_in = patient.enrolled_in;
      this.selectProgramType= this.enrolled_in[0]
      this.selectTypeModal.show();
    }

  }
  openPatient(patient: EligiblePatient) {
    this.tabs_service.add_tab(new Tab({title: patient.name, icon: 'fa', autorefresh: false}, EnrolledPatientComponent,
      patient.id, {patient: patient, program: this.program,programme_type: this.selectProgramType,enrolment: patient.tem_programme_enrolment}))
  }

  search() {
    this.filter['page'] = 1
    this.alter_type_patients = this.filter['alert_type'];
    this.getProgramEnrolledPatient();
  }
  pageChanged($event: any) {
    this.filter['page'] = $event.page;
    this.getProgramEnrolledPatient();
  }

  selectProgramTypeToOpen() {
    this.openPatient(this.selectedPatient);
  }

  closeSelectProgramModel() {
    this.selectTypeModal.hide();
    this.selectedPatient = new EligiblePatient();
    this.selectProgramType = '';

  }
}
