import {IntegrationCredential} from './integration-credential';

export class ProgramIntegration {
  id: number;
  integration_id: number;
  integration_name: string;
  practice_id: number;
  creator_id: number;
  is_authenticated = false;
  integration_credentials: any[] = [];
  integrator_user_name: string;

  load_from_json(json) {
    this.id = json.id;
    this.integration_id = json.integration_id;
    this.integration_name = json.integration_name;
    this.practice_id = json.practice_id;
    this.creator_id = json.creator_id;
    this.integration_credentials = json.integration_credentials.map(credential => new IntegrationCredential().load_from_json(credential));
    this.integrator_user_name = this.setIntegratorUserName()
    return this;
  }

  to_params() {
    return {
      programme_integration: {
        id: this.id,
        integration_id: this.integration_id,
        creator_id: this.creator_id,
        practice_id: this.practice_id,
        is_authenticated: this.setIsAuthenticated(),
        integration_credentials_attributes: this.integration_credentials
      }
    }
  }
  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }
  setIsAuthenticated(): boolean {
    return this.is_authenticated ? false : this.is_authenticated
  }
  setIntegratorUserName() {
    let programIntegration = this.integration_credentials.filter(integration => integration.label === 'integrator_user')[0]
    if (programIntegration) {
      return programIntegration.value
    } else {
      return ''
    }
  }
}


