import {MultipartItem} from '../routes/plugins/multipart-upload/multipart-item';
import {MultipartUploader} from '../routes/plugins/multipart-upload/multipart-uploader';
import { Injectable }     from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {GlobalErrorHandlerService} from './global-error.service';
import {HttpTokenService} from './http.token.service';


@Injectable()
export class AttachmentService {

  public uploader: MultipartUploader;
  authHeaders: any;
  multipartItem: MultipartItem;
  public uploaded_pic_data: any;
  file: File;
  upload: (attachment, on, upload_callback) => any;
  uploadCallback: (data) => any;
  currentAuthData: any;
  api_path = '/api/v1/attachments';
  constructor( private _tokenService: HttpTokenService, private globalErrorHandlerService: GlobalErrorHandlerService) {


    if (_tokenService.userSignedIn()) {
      this.currentAuthData = _tokenService.currentAuthData;
    }
    this.uploader =  new MultipartUploader({
        path: this.api_path, currentAuthData: this.currentAuthData
      },
      this.globalErrorHandlerService, this._tokenService);
    this.multipartItem = new MultipartItem(this.uploader);
    this.upload = (attachment, onprogress: any, upload_callback: any) => {
      if (attachment.fileType === 'library_attachment') {
        this.multipartItem.uploader.options.path = '/api/v1/library_attachments';
      }
      if (_tokenService.userSignedIn()) {
        this.currentAuthData = _tokenService.currentAuthData;
      }
      if (this.multipartItem.formData == null) {
      this.multipartItem.formData = new FormData();
      }
      this.multipartItem.formData.append('description', attachment.description);
      this.multipartItem.formData.append('title', attachment.title);
      this.multipartItem.formData.append('attachable_type', attachment.attachable_type);
      this.multipartItem.formData.append('attachable_id', attachment.attachable_id);
      this.multipartItem.formData.append('file', attachment.file);
      this.multipartItem.formData.append('filetype', attachment.fileType);
      this.multipartItem.callback = upload_callback;
      this.multipartItem.upload(onprogress);
      return this.uploaded_pic_data;
    }
  }
}

