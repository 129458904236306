/**
 * Created by adil on 1/5/17.
 */
import { Observable, Subject } from 'rxjs';
import {Component, NgZone, ElementRef, Inject, Input, Output, EventEmitter, ViewChild, AfterViewInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {PracticesService} from '../../../services/practices.service';
import {SpecialtySearchService} from '../../../services/specialty-search.service';
import {Practice, Specialty} from './practice'
import { NgForm } from '@angular/forms';
import { CustomValidations } from '../../customValidations/custom-validations'
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import { OnChanges } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Component({
  moduleId: module.id.toString(),
  selector: 'new-practice',
  templateUrl: './new-practice.component.html',
  styleUrls: ['./new-practice.component.scss']
})
export class NewPracticeComponent implements OnChanges, AfterViewInit {
  public editor: any;
  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 300,
    relative_urls: false,
    remove_script_host: false,
    convert_urls: true,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    placeholder: 'Welcome Message...',
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
      'insertfile undo redo numlist outdent | bullist indent |contextmenu table | \ ' +
      'link forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.editor = editor;
    },
  }
  private searchTerms = new Subject<string>();
  public specialties: Observable<any[]>;
  public sp_id: Array<Number> = [];
  public latitude: number;
  public longitude: number;
  public zoom = 18;
  public selected = '';
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public searchElementRef: ElementRef;
  public customValidations: CustomValidations;
  @Input() practice: Practice;
  @Output()  edit_practice: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('practiceForm') practiceForm: NgForm;
  @ViewChild('search', { static: false }) searchElementLocationRef: ElementRef;
  constructor(private _fb: FormBuilder,
              private practiceService: PracticesService,
              private specialtySearchService: SpecialtySearchService,
              private ngZone: NgZone, private globalErrorHandlerService: GlobalErrorHandlerService,
              @Inject(ElementRef) elementRef: ElementRef) {
    this.customValidations = new CustomValidations();
    this.searchElementRef = elementRef;
    this.specialties = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.getSpecialties(token)));
  }
  ngOnChanges() {
  }

  ngAfterViewInit(): void {
    this.setGoogleMaps();
  }
  cancel() {
    this.edit_practice.emit(false);
  }
  getSpecialties(term: any) {
    return this.specialtySearchService.search(term);
  }

  find(term: string): void {
    this.searchTerms.next(term);
  }

  gotoDetail(specialty: any, i: number): void {
    this.sp_id[i] = specialty.id;
  }

  addSpecialty() {
    this.get_specialties().push(new Specialty())
  }
  removeSpecialty(specialty) {
    this.get_specialties().splice(this.get_specialties().indexOf(specialty), 1);
  }
  get_specialties() {
    return this.practice.client.specialties;
  }

  set_asyncSelected(event, specialty: Specialty) {

    this.asyncSelected = specialty.description;
  }

  save() {
    this.practice.client.email = this.practice.client.email.toLowerCase();
    this.practiceService.save(this.practice)
      .then(res => {
        this.practice = this.practice.load_from_json(res);
        this.edit_practice.emit(false);
      }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error );
  }
  setGoogleMaps() {
    this.setCurrentPosition();
    if (!this.searchElementLocationRef) {
      return;
    }
    let searchBox = new google.maps.places.Autocomplete(this.searchElementLocationRef.nativeElement);
    searchBox.addListener('place_changed', () => {
      const place = searchBox.getPlace();
      if (!place) {
        return;
      }
      this.practice.client.address.city_area.lat = place.geometry.location.lat().toString();
      this.practice.client.address.city_area.long = place.geometry.location.lng().toString();
      this.practice.client.address.city_area.name = place.formatted_address;
    });
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any, specialty: Specialty): void {
    specialty.specialty_id = e.item.id;
    specialty.id = e.item.id;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.practiceForm);
  }
  callme() {
    this.ngAfterViewChecked();
  }
}
