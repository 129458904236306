import {BaseModel} from '../../shared/base';

export class CaseInfo extends BaseModel {
    id: number;
    status_id: number;
    doctor_id: number;
    consultation_date: any;
    diagnosis: string;
    planned_treatment: string;
    recommendation: string;
    referred_by: string;
    when_diagnosed: string;
    diagnosed_during_pregnancy: string;
    expected_cost: string;
    case_condition: string;
    funds: string;
    pchf_share: string;
    parent_share: string;
    physician_name: string;
    third_party_share: string;
    third_party_name: string;
    extended_amount: string;
    overstay_pchf_share: string;
    overstay_parent_share: string;
    overstay_third_party_share: string;
    surgery_date: any;
    surgery_date_format: any;
    admission_date: any;
    admission_date_format: any;
    referred_to: string;
    approval_1: string;
    approval_2: string;
    approval_3: string;
    patient_id: number;
    created_at: string;
    patient_name: string;
    guardian_name: string;
    relationship_name: string;
    mrn_no: string;
    practice_complete_address: string;
    age: string;
    phone_number: string;

    to_params() {
        return {
            case_info: {
                id: this.id,
                status_id: this.status_id,
                doctor_id: this.doctor_id,
                consultation_date: this.consultation_date,
                diagnosis: this.diagnosis,
                planned_treatment: this.planned_treatment,
                recommendation: this.recommendation,
                referred_by: this.referred_by,
                funds: this.funds,
                when_diagnosed: this.when_diagnosed,
                diagnosed_during_pregnancy: this.diagnosed_during_pregnancy,
                expected_cost: this.expected_cost,
                case_condition: this.case_condition,
                pchf_share: this.pchf_share,
                parent_share: this.parent_share,
                third_party_share: this.third_party_share,
                third_party_name: this.third_party_name,
                extended_amount: this.extended_amount,
                overstay_pchf_share: this.overstay_pchf_share,
                overstay_parent_share: this.overstay_parent_share,
                overstay_third_party_share: this.overstay_third_party_share,
                surgery_date: this.surgery_date,
                admission_date: this.admission_date,
                referred_to: this.referred_to,
                approval_1: this.approval_1,
                approval_2: this.approval_2,
                approval_3: this.approval_3,
                patient_id: this.patient_id,
            }
        }
    }
    load_from_json(json) {
        this.id = json.id;
        this.status_id = json.status_id;
        this.doctor_id = json.doctor_id;
        this.consultation_date = json.consultation_date;
        this.diagnosis = json.diagnosis;
        this.planned_treatment = json.planned_treatment;
        this.recommendation = json.recommendation;
        this.referred_by = json.referred_by;
        this.funds = json.funds;
        this.physician_name = json.physician_name;
        this.when_diagnosed = json.when_diagnosed;
        this.diagnosed_during_pregnancy = json.diagnosed_during_pregnancy;
        this.expected_cost = json.expected_cost;
        this.case_condition = json.case_condition;
        this.pchf_share = json.pchf_share;
        this.parent_share = json.parent_share;
        this.third_party_share = json.third_party_share;
        this.third_party_name = json.third_party_name;
        this.extended_amount = json.extended_amount;
        this.overstay_pchf_share = json.overstay_pchf_share;
        this.overstay_parent_share = json.overstay_parent_share;
        this.overstay_third_party_share = json.overstay_third_party_share;
        this.surgery_date = json.surgery_date;
        this.surgery_date_format = json.surgery_date_format;
        this.admission_date = json.admission_date;
        this.admission_date_format = json.admission_date_format;
        this.referred_to = json.referred_to;
        this.approval_1 = json.approval_1;
        this.approval_2 = json.approval_2;
        this.approval_3 = json.approval_3;
        this.patient_id = json.patient_id;
        this.created_at = json.created_at;
        this.patient_name = json.patient_name;
        this.practice_complete_address = json.practice_complete_address;
        this.age = json.age;
        this.phone_number = json.phone_number;
        this.guardian_name = json.guardian_name;
        this.relationship_name = json.relationship_name;
        this.mrn_no = json.mrn_no;
        return this;
    }

    is_new_record() {
        return this.id == 0 || this.id == undefined;
    }
}
