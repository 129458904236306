import { Component, OnInit } from '@angular/core';
import {Practice} from '../../practices/practices/practice';
import {Location} from '../../practices/locations/location';
import {TabsService} from '../../../services/tabs.service';
import {UserService} from '../../../services/user.service';
import {AppointmentService} from '../../../services/appointment.service';
import {Tab} from '../../../tabs/tab';
import {AdmitComponent} from '../admit/admit.component';

@Component({
  selector: 'app-tele-medicine',
  templateUrl: './tele-medicine.component.html',
  styleUrls: ['./tele-medicine.component.scss']
})
export class TeleMedicineComponent implements OnInit {

  public user: any;
  public is_data_available = false;
  public practice: Practice = new Practice();
  public multiple_locations: Location[] = [new Location()];
  public doctor_id: string;
  constructor(public tabs_service: TabsService, public _user_service: UserService, private _appointment_service: AppointmentService) {
    this.tabs_service.register('reception');
    this.tabs_service.register('walkin');
  }

  ngOnInit() {
  }

  add_to_tabs() {
    let title = 'Admit';
    let appointment_id = 23;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, AdmitComponent, appointment_id, {id: 23}))
  }

  test(user) {
    if (user && user.id && !this.is_data_available) {
      this.is_data_available = true;
      this._appointment_service.get_todays_patient_filter(user).then(json => {
        if (json.locations && json.locations.length > 0) {
          json.locations = json.locations.filter(loc => loc.is_bookable == true)
        }
        if (json.id) {
          this.practice.load_from_json(json);
        } else {
          if (json.is_doctor) {
            this.multiple_locations = this.get_doctor_locations(json);
          } else {
            this.multiple_locations = this.get_doctor_multiple_locations(json);
          }

        }
        return true;
      });
    }
    if (user && user.id) {
      return true;
    }
    return false;
  }

  get providers() {
    if (this.practice.active_doctors && this.practice.active_doctors.length > 0) {
      return this.practice.active_doctors;
    }
    if (this.practice.id) {
      return this.practice.doctors;
    }

  }

  get locations() {
    if (this.practice.id) {
      return this.practice.locations;
    }
    return this.multiple_locations;
  }

  get_doctor_multiple_locations(json) {
    this.doctor_id = json.doctor_id;
    let multiple_locations = json.practices.filter(e => e.locations.length > 0);
    for (let single_location of multiple_locations) {
      let location = new Location();
      let locations = single_location.locations.map(loc => new Location().load_from_json(loc));
      for (let loc of locations) {
        this.multiple_locations.push(loc);
      }
    }
    let abc =  this.multiple_locations.filter(loc => loc.id && loc.is_bookable == true);
    return abc;
  }

  get_doctor_locations(json) {
    this.doctor_id = json.doctor_id;
    let locations = json.locations.map(loc => new Location().load_from_json(loc));
    for (let loc of locations) {
      this.multiple_locations.push(loc);
    }
    let abc =  this.multiple_locations.filter(loc => loc.id && loc.is_bookable == true);
    return abc;
  }

}
