import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class BraintreeService {

  constructor(private _tokenService: CustomHttpClient) {}

  get_client_token(package_type: string, appointment_id: string): Promise<any> {
    const url = package_type ? 'package_payments/braintree_client_authorization_token?package_type=' + package_type : 'package_payments/braintree_client_authorization_token?appointment_id=' + appointment_id
    return this._tokenService.get(url)
      .toPromise()
      .then(res => res);

  }

  pay(paymentMethodId: any, customer: Object, practice_id: any, package_type): Promise<any> {
    console.log(customer);
  return this._tokenService.post('package_payments/pay_package',
    {
    payment_method_id: paymentMethodId,
    customer: customer,
    practice_id: practice_id,
    package_type: package_type
  })
    .toPromise()
    .then(res => res);

  }

  appointmentPayment(paymentMethodId: any, appointment_id): Promise<any> {
    return this._tokenService.post('package_payments/appointment_payment', {payment_method_id: paymentMethodId, appointment_id: appointment_id})
      .toPromise()
      .then(res => res);

  }

  brainTreeCustomer(practice_id: any): Promise<any> {
    return this._tokenService.get('package_payments/braintree_customer/' + practice_id)
      .toPromise()
      .then(res => res);
  }
  updateCard(paymentMethodId: any, customer_id: any): Promise<any> {
    return this._tokenService.put('package_payments/update_card', {payment_method_id: paymentMethodId, customer_id: customer_id})
      .toPromise()
      .then(res => res);
  }

  braintree_customer(paymentMethodId: any, practice_id: any): Promise<any> {
    return this._tokenService.post('package_payments/braintree_customer', {payment_method_id: paymentMethodId, practice_id: practice_id})
      .toPromise()
      .then(res => res);
  }
  invoicePayment(customer_id: any, invoice_id: any): Promise<any> {
    return this._tokenService.post('package_payments/invoice_payment', {invoice_id: invoice_id, customer_id: customer_id})
      .toPromise()
  }
}
