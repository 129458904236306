import {Component, OnInit} from '@angular/core';
import {TabsService} from '../../../services/tabs.service';

@Component({
  selector: 'app-patient-intake-form-index',
  templateUrl: './patient-intake-form-index.component.html',
  styleUrls: ['./patient-intake-form-index.component.scss']
})
export class PatientIntakeFormIndexComponent implements OnInit {
  constructor(public tabsService: TabsService) {
    this.tabsService.register('Patient Intake Forms');
  }
  ngOnInit(): void {
  }

}
