import {Patient} from '../../health/doctor/patient';
import {Appointment} from '../../health/models/appointment';
import {Service} from '../practices/practices/practice';
import {Staff} from '../practices/contacts/contact';
import {Note} from './notes/note';
import {timer} from 'rxjs';
import {BaseModel} from '../../shared/base';
import {Doctor} from '../practices/doctors/doctor';

export class Case extends BaseModel {
  class_name = 'Case';
  id: number;
  service_id: string;
  service_name = '';
  department_id = '0';
  department_name: string;
  comment: string;
  ward: string;
  bed_number: string;
  doctor_id: string;
  doctor_name: string;
  staff_id: string;
  staff_name = '';
  practice_id: any;
  appointment_id: string;
  appointment: Appointment;
  service: Service;
  staff: Staff;
  doctor: Doctor;
  notes: Note[] = [];
  created_at: string;
  tick = 0;
  admitted_date_time: string;
  constructor() {
    super();
    timer(1000, 1000).subscribe(t => {
      this.tick += 1;
    });
  }
  to_params() {
    return {
      case: {
        id: this.id,
        service_id: this.service_id,
        department_id: this.department_id,
        ward: this.ward,
        bed_number: this.bed_number,
        staff_id: this.staff_id,
        doctor_id: this.doctor_id,
        comment: this.comment,
        appointment_id: this.appointment_id
      }
    }
  }
  load_from_json(json) {
    this.id = json.id;
    if (json.staff) {
      this.staff = new Staff().load_from_jason(json.staff);
    }
      this.staff_name = (this.staff ? this.staff.name : '');
    if (json.doctor) {
      this.doctor = new Doctor().load_from_json(json.doctor);
    }
      this.doctor_name = this.doctor ? this.doctor.prefix + ' ' + this.doctor.firstname + ' ' + this.doctor.lastname : '';
      this.doctor_id = json.doctor_id;
    if (json.service) {
      this.service = new Service().load_from_json(json.service);
    }
      this.service_name = this.service ? this.service.name : '';
    if (json.appointment) {
      this.appointment = new Appointment().load_from_json(json.appointment);
    }
    if (json.notes) {
      this.notes = json.notes.map(note => new Note().load_from_json(note));
    }
    this.service_id = json.service_id;
    this.department_id = json.department_id;
    this.comment = json.comment;
    this.ward = json.ward;
    this.bed_number = json.bed_number;
    this.staff_id = json.staff_id;
    this.appointment_id = json.appointment_id;
    if (json.department) {
    this.department_name = (json.department ? json.department.name : '');
    }
    this.practice_id = json.practice_id;
    this.created_at = json.created_at;
    this.admitted_date_time = json.admitted_date_time;
    this.calculate_ticking_time(json.created_at)
    return this;
  }
  calculate_ticking_time(log_time) {
    let d = new Date(log_time);
    this.tick = (new Date().getTime() - d.getTime()) / 1000
  }
  patient_name() {
    return this.appointment.patient.user.first_name;
  }
  waiting_time() {
    return Number(Math.round((this.tick) / 86400));
  }
  get appoint_id(): any {
    return this.appointment.id;
  }
  get_url(): string {
    return this.is_new_record() ? 'cases/' : ['cases', this.id].join('/')
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
}
