<app-program-integration [program_integration]="program_integration" (programIntegratorAuthorized)="getProgramIntegrations()"></app-program-integration>

<ul class="list-unstyled border-list m0">
  <ng-container *ngFor="let programIntegration of programIntegrations">
  <li class="list">
    Integrated with
    <a  (click)="editProgramIntegration(programIntegration)">{{programIntegration.integration_name}}</a>
    , API Integrator User is {{programIntegration.integrator_user_name}}
    <a (click)="selected_program_integration = programIntegration;deleteModal.show()">
      <i class="fa icon-trash text-danger" ></i>
    </a>
  </li>
  </ng-container>
</ul>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Integration</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p-sm m0 text-center">
          Are you sure you want to delete this integration?
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteProgramIntegration(); this.deleteModal.hide()">Yes</button>
        <button type="button" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide(); selected_program_integration = undefined">No</button>
      </div>
    </div>
  </div>
</div>
