export const backendApi = 'https://wellwink.com'
export const environment = {
  production: true,
  url: backendApi,
  versionCheckURL : `${backendApi}/version.json`,
  tinymce: {
    theme_url: '/dist/tinymce'
  },
  phone_number_prefix: '+1',
  recaptcha: {
    v3_siteKey: '6Lc1LacgAAAAALaBebhXCv0zENpdmGWoAB5NplFA',
    v2_siteKey: '6LfoYacgAAAAALQM3DKo6s1Omh7uTSOoUnqXwuIc'
  },
  STRIPE_PUBLIC_KEY: 'pk_live_51Mk9Y5DiX90j654z8kPm3n9GCKCpwiH0DuafHtXfL0rxUZfGaAE1JTvHXQJZyL9hQVFY6kzyhjTFpUqi7Y2VOpRf00Bv65XMtw'
};
