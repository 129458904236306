export class AlertVital {
  id: number;
  vital_type_id: number;
  vital_sign: any;
  value: number;
  min_value: number;
  max_value: number;
  vital_identifier: any;
  _destroy: boolean;
  short: any;
  show_min_max: boolean;
  to_params() {
    return {
      id: this.id,
      vital_type_id: this.vital_type_id,
      // value: this.short === 'ht' ? this.ConvertHeighToInchForParams(this.value) : this.value,
      // min_value: this.short === 'ht' ? this.ConvertHeighToInchForParams(this.min_value) : this.min_value,
      // max_value: this.short === 'ht' ? this.ConvertHeighToInchForParams(this.max_value) : this.max_value,
      value: this.short === 'wt' ? this.weightInkgs(this.value) : this.value,
      min_value: this.short === 'wt' ? this.weightInkgs(this.min_value) : this.min_value,
      max_value: this.short === 'wt' ? this.weightInkgs(this.max_value) : this.max_value,
      vital_identifier: this.vital_identifier,
      show_min_max: this.show_min_max,
      _destroy: this._destroy,
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.vital_sign = json.vital_sign;
    this.vital_type_id = json.vital_type_id;
    if (json.short === 'wt') {
      this.value = this.weightInPounds(json.value);
      this.min_value = this.weightInPounds(json.min_value);
      this.max_value = this.weightInPounds(json.max_value);
    } else {
      this.value = json.value;
      this.min_value = json.min_value;
      this.max_value = json.max_value;
    }
    this.vital_identifier = json.vital_identifier;
    this.show_min_max = json.show_min_max;
    this.short = json.short;
    this._destroy = json._destroy;
    return this;
  }

  ConvertHeightInFeetAndInchForJson(value) {
    if (value) {
      let heightInFeet = Math.floor((value) / 12);
      let heightInInch = (((value) / 12 - heightInFeet) * 12).toFixed(0);
      let height = heightInFeet + '.' + heightInInch
      return Number(height)
    } else {
      return undefined
    }
  }

  ConvertHeighToInchForParams(value) {
    if (value) {
      let val = String(value);
       if (val.length > 1) {
          let temp = val.split('.')
          return ( Number(temp[0]) * 12) + Number(temp[1]);
         }
        else {
          return Number (val) * 12; }
        }
    else {
      return undefined
    }
  }
  weightInPounds(weight) {

    let wt = ( Number(weight)  * 2.2046).toFixed(2);
    return Number(wt)
  }
  weightInkgs(w) {
    if (w) {
    let wt = ( w  / 2.2046).toFixed(2);
    return wt.toString();
    }
    else {
      return undefined
    }
  }
}
