import {Calendar, Doctor} from '../../routes/practices/doctors/doctor';
import {Location} from '../../routes/practices/locations/location';
import {Practice, Service} from '../../routes/practices/practices/practice';
import {Patient} from '../doctor/patient';
import {timer} from 'rxjs';
import {BaseModel} from '../../shared/base';
import {PatientReview} from '../../reviews/patient-review';
export  class Appointment extends BaseModel {
  class_name = 'Appointment';
  tick = 0;
  id = '';
  appointment_type = 'office_visit';
  patient_id: number;
  calendar_id: number;
  doctor_id: number;
  location_id: number;
  service_name = '';
  service_id: number;
  date: any = '';
  date_with_time_zone: any = '';
  end_date_with_time_zone: any = '';
  created_by_id: number;
  status_id: any;
  notes_for_doctor = '';
  complete_name = '';
  slot_size: string;
  message = '';
  calendar: Calendar;
  doctor: Doctor;
  location: Location;
  practice: Practice;
  patient: Patient;
  service: Service;
  appointment_time: any;
  appointment_booking_time: any;
  appointment_time_only: any;
  actioned_by: string;
  log_time: string;
  review_type: string;
  practice_review_status: string;
  patient_reviews: PatientReview[];
  patient_doctor_review: PatientReview;
  patient_practice_review: PatientReview;
  actioned_on: Date;
  requested_on;
  reason_of_cancellation;
  review = false;
  amount_present;
  public appointment_charges = 0;
  public appointment_payments = 0;
  public appointment_discount = 0;
  public appointment_balance = 0;
  public charges = 0;
  appointment_with: string;
  payment_status: string;
  patient_online_status: string;
  portal_online_status: string;
  mobile_online_status: string;
  public amenity_name: string;
  public pending_statuses: string[] = ['pending'];
  public acknowledged_statuses: string[] = ['cancelled', 'rescheduled', 'confirmed', 'scheduled'];
  public values: string[] = ['pending', 'acknowledged', 'cancelled', 'scheduled', 'confirmed', 'checkedin', 'rescheduled', 'noshow', 'checkedout', 'admitted']
  public doctor_virtual_visit_charges = 0;
  amenity_id: any;
  appointment_from: string;
  parent_id: string;
  public static getIds(appointments) {
    return appointments.map(appointment => appointment.id);
  }
  constructor() {
    super();
    timer(1000, 1000).subscribe(t => {
      this.tick += 1;
    });
  }

  get user_id(): number {
    return this.patient.parent_user_id || this.patient.user.id;
  }

  defaultStatusValues(status_id): any {
    if (status_id == 'acknowledged') {
      return 3;
    }
    if (status_id == 'pending') {
      let index_value = this.pending_statuses.indexOf(status_id);
      return index_value;
    }
    if (status_id != 'pending') {
      let index_value = this.acknowledged_statuses.indexOf(status_id);
      if (index_value < 0) {
        index_value =  this.values.indexOf(status_id)
      }
      return index_value;
    }
  }


  get timeZone() {
    if (this.location) {
      return this.location.time_zone;
    }
  }

  get time_zone_offset() {
    if (this.location) {
      return this.location.time_zone_offset;
    }
  }

  is_today_appointment(): boolean {
    return new Date(this.date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
  }

  get showCheckIn(): boolean {
    return this.hideCheckIn(10) && (this.status_id === 'confirmed' || this.status_id === 'scheduled');
  }

  hideCheckIn(delay?: number) {
    if (this.date) {
      let myDate = new Date(this.date);
      if (delay) {
        myDate = new Date(myDate.setMinutes(myDate.getMinutes() - delay));
      }
      let today = new Date();
      if (myDate > today) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }


  load_from_json(json) {
    this.appointment_from = json.appointment_from;
    this.id = json.id;
    this.amenity_name = json.amenity_name;
    this.amenity_id = json.amenity_id;
    this.patient_online_status = json.patient_online_status;
    this.mobile_online_status = json.mobile_online_status;
    this.portal_online_status = json.portal_online_status;
    this.review = json.review;
    this.notes_for_doctor = json.notes_for_doctor;
    this.message = json.message;
    this.slot_size = json.slot_size;
    this.patient_id = json.patient_id;
    this.appointment_type = json.appointment_type;
    this.status_id = json.status_id;
    this.date = json.date;
    this.date_with_time_zone = json.date_with_time_zone;
    this.end_date_with_time_zone = json.end_date_with_time_zone;
    this.doctor_id = json.doctor_id;
    this.location_id = json.location_id;
    this.reason_of_cancellation = json.reason_of_cancellation;
    this.appointment_charges = json.appointment_charges;
    this.appointment_payments = json.appointment_payments;
    this.appointment_discount = json.appointment_discount;
    this.appointment_balance = json.appointment_balance;
    this.charges = json.amount;
    this.appointment_with = json.appointment_with;
    this.review_type = json.review_type;
    this.practice_review_status = json.practice_review_status;
    this.amount_present = json.amount_present;
    this.payment_status = json.payment_status;
    if (json.doctor_virtual_visit_charges) {
      this.doctor_virtual_visit_charges = json.doctor_virtual_visit_charges;
    }
    if (json.actioned_by) {
      this.actioned_by = json.actioned_by;
    }
    if (json.user) {
      this.actioned_by = json.user.first_name + ' ' + json.user.last_name;
    }
    this.actioned_on = json.actioned_on_in_time_zone;
    if (json.service) {
      this.service = new Service().load_from_json(json.service);
    }
    if (json.patient) {
    this.patient = new Patient().load_from_json(json.patient);
    }
    if (json.doctor ) {
      this.doctor = new Doctor().load_from_json(json.doctor);
    }

    if (json.calendar) {
    this.calendar = new Calendar().load_from_json(json.calendar);
    }
    if (json.calendar || json.location) {
      let json_data = json.calendar ? json.calendar.location : json.location;
      this.location = new Location().load_from_json(json_data);
    }

    if (json.appointment_time) {
    this.appointment_time = json.appointment_time;
    }
    if (json.appointment_booking_time) {
    this.appointment_booking_time = json.appointment_booking_time;
    }
    if (json.only_time) {
    this.appointment_time_only = json.only_time;
    }
    this.requested_on = json.requested_on;
    if (json.log_time) {
      this.log_time = json.log_time
      this.calculate_ticking_time(this.log_time);
    } else {
      this.log_time = json.created_at
      this.calculate_ticking_time(this.log_time);
    }
    if (json.patient_reviews && json.review_type) {
      this.review_analizer(json);
    }
    return this;
  }

  review_analizer(json) {
    if (json.patient_reviews.length > 0) {
      this.patientReviews(json);
    }
    else {
      if (json.review_type.toLowerCase() == 'doctor' || json.review_type.toLowerCase() == 'both') {
        this.setDoctorReviewObject(json);
      }
      if (json.review_type.toLowerCase() == 'practice' || json.review_type.toLowerCase() == 'both') {
        this.setPracticeReviewObject(json);
      }
    }
  }

  patientReviews(json) {
    this.patient_reviews = json.patient_reviews.map(review => new PatientReview().load_from_json(review));
    this.patient_doctor_review = this.patient_reviews
      .filter(a => a.reviewable_type.toLowerCase() == 'doctor' && a.reviewable_id == this.doctor_id)[0];
    this.patient_practice_review = this.patient_reviews.filter(a => a.reviewable_type.toLowerCase() == 'practice' && a.reviewable_id == this.location.practice_id)[0];
      if (!this.patient_doctor_review && (json.review_type == 'both' || json.review_type == 'doctor')) {
        this.setDoctorReviewObject(json);
      }
      if (!this.patient_practice_review && (json.review_type == 'both' || json.review_type == 'practice')) {
        this.setPracticeReviewObject(json);
      }
  }

  setDoctorReviewObject(json) {
    this.patient_doctor_review = new PatientReview();
    this.patient_doctor_review.reviewable_type = 'Doctor';
    this.patient_doctor_review.patient_id = json.patient_id;
    this.patient_doctor_review.appointment_id = json.id;
    this.patient_doctor_review.reviewable_id = json.doctor_id;
  }

  setPracticeReviewObject(json) {
    this.patient_practice_review = new PatientReview();
    this.patient_practice_review.reviewable_type = 'Practice';
    this.patient_practice_review.patient_id = json.patient_id;
    this.patient_practice_review.appointment_id = json.id;
    this.patient_practice_review.reviewable_id = json.location.practice_id;
  }

  showBookingTimeAndSlot() {
    if (this.appointment_booking_time && this.slot_size && this.slot_size > '0') {
      return this.appointment_booking_time + ' (' + this.slot_size + ' ' + 'mins)'
    }
    return this.appointment_booking_time;
  }

  isCheckedOut() {
    return this.status_id == 'checkedout'
  }

  setInitalWaitingTime(datePipe) {
    return this.calculateWaitingMin(datePipe.transform(new Date(), 'HH:mm'));
  }

  calculateWaitingMin(current_date) {
    let hour, addMinutes;
    addMinutes = (+current_date.split(':')[1] + 2);
    if (addMinutes >= '60') {
      addMinutes = addMinutes - 60;
      hour = +current_date.split(':')[0] + 1;
    } else {
      hour = current_date.split(':')[0];
    }
    return hour + ':' + this.setMinutes(addMinutes);
  }

  setMinutes(min) {
    min = min > 9 ? min : ('0' + min);
    return min
  }

  calculate_ticking_time(log_time) {
    let d = new Date(log_time);
    this.tick = (new Date().getTime() - d.getTime()) / 1000
  }

  to_params() {
    return {
      appointment:
        {
          appointment_from: this.appointment_from,
          patient_id: this.patient_id,
          calendar_id: this.calendar_id,
          amenity_id: this.amenity_id,
          appointment_type: this.appointment_type,
          service_id: this.service_id,
          service_name: this.service_name,
          date: this.date,
          status_id: this.status_id,
          created_by_id: this.created_by_id,
          notes_for_doctor: this.notes_for_doctor,
          slot_size: this.slot_size,
          parent_id: this.parent_id,
          doctor_id: this.doctor_id,
          appointment_with: this.appointment_with,
          location_id: this.location_id
        }
    }
  }
  patient_name() {
    return this.patient.user.first_name;
  }
  patient_full_name(user) {
    let names = [user.first_name, user.last_name];
    let full_name = names.join(' ');
    return full_name;
  }
  waiting_time() {
    return Number(Math.round((this.tick) / 60));
  }

  age_and_gender() {
    let age_gender;
    let age = parseInt(this.patient.user.age_gender);
    if (age > 0) {
      age_gender = age + ' Years - ' + this.patient.user.gender;
    } else {
      age_gender = this.patient.user.gender;
    }
    return age_gender;
  }
}
