import {Component, Input, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { ProgramEnrollment} from '../../../eligible-patient';
import {LoaderService} from '../../../../services/loader.service';
import {EnrolmentsService} from '../../../enrolments.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {Subscription} from 'rxjs/Subscription';
import {Tab} from '../../../../tabs/tab';
import {EdittaskComponent} from '../../../../routes/practice-site/tasks/edittask/edittask.component';
import {Task} from '../../../../routes/practice-site/tasks/newtask/task';
import {TabsService} from '../../../../services/tabs.service';
import {TaskService} from '../../../../services/task.service';
import {VideoChatService} from '../../../../video-chat/services/videochat.service';
import {AlertTemplateService} from '../../../../routes/alert-template-index/alert-template.service';
import {AlertIntervention} from '../../../../routes/alert-template-index/alert-template';
import {AlertService} from '../../../../_alert';
import {TrackingService} from '../../../../services/tracking.service';
import {Alert} from '../../../alert';
import {PhoneService} from '../../../../voice-call/phone.service';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-enrolled-patient-alerts',
  templateUrl: './enrolled-patient-alerts.component.html',
  styleUrls: ['./enrolled-patient-alerts.component.scss']
})
export class EnrolledPatientAlertsComponent implements OnInit, OnDestroy {
  @ViewChild('resolveAlertModal') resolveAlertModal;
  public enrolment_alerts: Alert[] = [];
  @Input() programEnrollment: ProgramEnrollment;
  @Input() patient;
  @Input() programme_type;
  public selected_alert: Alert;
  public subscription: Subscription;
  public interventions: AlertIntervention[] = [];
  resolvedInterventions: any[] = [];
  public actionId: any;
  public totalAlertCount: number;
  public alertLimit = 10;
  public loadingAlert: boolean;

  constructor(public loaderService: LoaderService, public enrolmentsService: EnrolmentsService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public alertService: AlertService,
              public tabs_service: TabsService, public taskService: TaskService,
              public trackingService: TrackingService, public videoChatService: VideoChatService,
              public alertTemplateService: AlertTemplateService,
              public phoneService: PhoneService,
              public userService: UserService) {
    this.subscription = this.enrolmentsService.alertPushed.subscribe((alert: Alert) => {
      if (alert) {
        if (this.programEnrollment.id === alert.notifier_id) {
          this.enrolment_alerts.unshift(new Alert().load_from_json(alert));
          this.totalAlertCount += 1;
        }
      }
    });
  }

  ngOnInit(): void {
    this.getAllEnrolmentAlerts();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getAllEnrolmentAlerts() {
    this.loaderService.show();
    this.enrolmentsService.getEnrolmentAlerts(this.programEnrollment.id, this.alertLimit).then(resp => {
      this.enrolment_alerts = resp.alerts.map(alert => new Alert().load_from_json(alert));
      this.totalAlertCount = resp.total_alerts;
      console.log(this.enrolment_alerts);
      this.loadingAlert = false;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  markAllAlertsRead() {
    this.loaderService.show();
    this.enrolmentsService.markAlertsRead(this.programEnrollment.id).then(resp => {
      this.programEnrollment.unread_alerts_count = 0;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  deleteSingleAlert(alert) {
    this.loaderService.show();
    this.enrolmentsService.deleteSingleAlert(alert.id).then((resp: ProgramEnrollment) => {
      this.programEnrollment.alerts = resp.alerts
      this.enrolment_alerts.splice(this.enrolment_alerts.indexOf(alert), 1);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  openTaskInNewTab(alert: Alert) {
    let newTask = new Task();
    this.taskService.getTask(alert.source_id).then( resp => {
      newTask.load_from_json(resp);
      this.tabs_service.add_tab(new Tab({title: 'Edit Task', icon : 'fa'}, EdittaskComponent,
        alert.source_id, {id: alert.source_id, task: newTask}))
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  openVideoInNewTab(alert: Alert) {
    this.updateAlertStatusAsRead(alert.id);
    this.videoChatService.start_call_message = true;
    this.videoChatService.showLayoutVideo = false;
    this.videoChatService.source_type = 'ProgrammeEnrolment';
    this.videoChatService.source_id = this.programEnrollment.id;
    setTimeout(() => {
      this.videoChatService.patient_user_id = this.patient.user.id;
      this.videoChatService.patient_full_name = this.patient.name;
      this.videoChatService.patient_profile_pic = this.patient.profile_pic;
      this.videoChatService.mobile_online_status = this.patient.mobile_online_status;
      this.videoChatService.portal_online_status = this.patient.portal_online_status;
      this.videoChatService.showLayoutVideo = true;
      this.videoChatService.showSmallWindow = false;
    }, 300)

  }

  openResolveModel(alert: Alert) {
    this.selected_alert = alert;
    if (alert.status !== 'intervened') {
      this.startAction('intervention');
    }
    this.getAlertTemplateInterventions(alert);
  }

  getAlertTemplateInterventions(alert) {
    this.loaderService.show();
    this.alertTemplateService.getAlertTemplateInterventions(alert).then(resp => {
      this.interventions = resp;
      if (alert.status === 'intervened') {
        alert.interventions.map(a =>  this.pushResolvedInterventions(a))
      }
      this.resolveAlertModal.show();
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  resolveAlert() {
    this.resolvedInterventions = this.selected_alert.interventions.map(a => {
      return {intervention_id: Number(a.id), notification_id: this.selected_alert.id}})
    if (this.resolvedInterventions.length === 0) {
      return  this.globalErrorHandlerService.error = 'Please select or add intervention';
    }
    this.alertTemplateService.resolvedInterventionsForPatient(this.resolvedInterventions)
      .then(resp => {
        this.updateEnrollmentAndAlertObject(resp);
        this.stopAction(resp.alert.noti_text, resp.alert.id, 'Intervention');
        this.loaderService.hide();
        this.closeResolvedModel();
        this.alertService.success('Alert has been resolved successfully', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  closeResolvedModel() {
    this.trackingService.deleteTrackingObject(this.actionId)
    this.selected_alert = undefined;
    this.resolvedInterventions = [];
    this.resolveAlertModal.hide();
  }

  startAction(description) {
    this.actionId = this.trackingService.startTracking(description);
  }

  stopAction(description, id, source_type) {
    const trackingResult = this.trackingService.stopTracking(this.actionId);
    this.trackingService.logTimeForPatientActivity({duration: trackingResult.duration,
      description: description, activity_type: trackingResult.description,
      source_id: id, activiable_id: this.programEnrollment.id, source_type: source_type, programme_type: this.programme_type })
  }
  objectExists(id) {
    return this.interventions.some(function(ai) {
      return ai.id === id;
    });
  }

  pushResolvedInterventions(a) {
    if (!this.objectExists(a.id)) {
      this.interventions.push(a)
    }
  }

  interventions_name(interventions: any[]) {
    if (interventions.length > 0) {
      return interventions.map(a => (a.description + ' ')).toString();
    }
  }

  updateAlertStatusAsRead(alert_id: number) {
    this.alertTemplateService.updateAlertStatusAsRead(alert_id)
      .then(resp => {
        this.updateEnrollmentAndAlertObject(resp)
        console.log(resp);
        this.loaderService.hide();
        this.alertService.success('Alert has been resolved successfully', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }
  updateEnrollmentAndAlertObject(resp: any) {
    if (resp.programme_enrolment) {
      this.programEnrollment.load_from_json(resp.programme_enrolment);
    }
    if (resp.alert) {
      this.enrolment_alerts.find(a => a.id === resp.alert.id).load_from_json(resp.alert);
    }
  }

  loadMoreAlerts() {
    this.alertLimit = this.alertLimit + 10;
    this.getAllEnrolmentAlerts();
    this.loadingAlert = true
  }
}

