import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TabsService} from '../../../services/tabs.service';
import {LoaderService} from '../../../services/loader.service';
import {EnrolmentsService} from '../../enrolments.service';
import {Activity} from '../../activity';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {ProgramEnrollment} from '../../eligible-patient';
import {TimeLog} from '../../time-log';
import {Subscription} from 'rxjs/Subscription';
import {TaskService} from '../../../services/task.service';
import {UserService} from '../../../services/user.service';


@Component({
  selector: 'app-enrolled-patient-care-management',
  templateUrl: './enrolled-patient-care-management.component.html',
  styleUrls: ['./enrolled-patient-care-management.component.scss']
})
export class EnrolledPatientCareManagementComponent implements OnInit, OnDestroy {
  @Input() programEnrollment: ProgramEnrollment;
  @Input() patient;
  public dateRangeValue: Date[];
  public filter: Object = {page: 1, from: '', to: '', keyword: '', id: ''};
  public totalItems;
  public activities: Activity[] = [];
  time: any;
  selected_activity: Activity;
  public time_to_log: TimeLog;
  public subscription: Subscription;
  public subscriptionForTask: Subscription;
  selectedActivityToDelete: Activity;
  @ViewChild('deleteModal') deleteModal;
  @ViewChild('editActivityModel') editActivityModel;
  public totalTime: any;


  constructor(public tabs_service: TabsService, public enrolmentsService: EnrolmentsService,
              public loaderService: LoaderService, public taskService: TaskService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public userService: UserService) {
    this.subscription = this.enrolmentsService.reloadActivityList.subscribe(item => {
      if (item) {
        this.enrolmentsService.reloadActivityList.next(0);
        this.getActivities();
        this.getTotalActivityTime();
      }
    });
    this.subscriptionForTask = this.taskService.reloadTasks.subscribe(item => {
      if (item) {
        this.getActivities();
        this.getTotalActivityTime();
      }
    });
  }

  ngOnInit(): void {
    this.getActivities();
    this.getTotalActivityTime();
  }

  getActivities() {
    this.loaderService.show();
    this.filter['id'] = this.programEnrollment.id;
    this.enrolmentsService.getActivities(this.filter).then(resp => {
      this.activities = resp.activities.map(activity => new Activity().load_from_json(activity));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }
  getTotalActivityTime() {
    this.loaderService.small_loder_show();
    this.enrolmentsService.getTotalActivityTime(this.programEnrollment.id).then(resp => {
      this.totalTime = resp;
      this.loaderService.small_loder_hide();
    }).catch(resp => {
      this.loaderService.small_loder_hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }
  setDateRange(event: Date[]) {
    if (event) {
      this.filter['from'] = String(event[0]);
      this.filter['to'] = String(event[1]);
    }
  }
  search() {
    this.filter['page'] = 1;
    this.getActivities();
    console.log(this.filter)
  }

  pageChanged(page) {
    this.filter['page'] = page.page;
    this.getActivities();
  }

  logTimeForActivity(activity) {
    this.loaderService.show();
    this.time_to_log = new TimeLog();
    this.time_to_log.source_id = activity.id;
    this.time_to_log.source_type = 'Activity';
    this.time_to_log.minutes = this.time;
    this.enrolmentsService.logTimeForActivity(this.time_to_log).then(resp => {
      this.enrolmentsService.reloadProgramEnrolmentTime.next(1);
      let objIndex = this.activities.findIndex((obj => obj.id === resp.id));
      this.activities[objIndex].load_from_json(resp);
      this.time = undefined;
      this.selected_activity = undefined;
      this.loaderService.hide();
    }).catch(res => {
      console.log(res);
      this.loaderService.hide();
      this.globalErrorHandlerService.error = res.error.error;
    })
  }

  deleteActivity(activity: Activity) {
    console.log(activity);
    this.loaderService.show();
    this.enrolmentsService.deleteActivity(activity.id).then(resp => {
      this.getTotalActivityTime();
      this.enrolmentsService.reloadProgramEnrolmentTime.next(1);
      this.activities.splice(this.activities.indexOf(activity), 1);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.deleteModal.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  saveActivity() {
    this.loaderService.show();
    this.enrolmentsService.updateActivity(this.selected_activity).then(resp => {
      this.getTotalActivityTime();
      this.enrolmentsService.reloadProgramEnrolmentTime.next(1);
      this.selected_activity.load_from_json(resp);
      this.editActivityModel.hide();
      this.selected_activity = undefined;
      this.loaderService.hide();
    }).catch(res => {
      console.log(res);
      this.loaderService.hide();
      this.globalErrorHandlerService.error = res.error.error;
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptionForTask.unsubscribe();
  }

  formatResponse(response: { [key: string]: number }): string {
    return Object.entries(response)
      .map(([key, value]) => `${key.toUpperCase()} = ${value} min`)
      .join(', ');
  }
}
