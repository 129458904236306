import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {CustomHttpClient} from '../../../../services/custom-http-client.service';
import {Device} from './device';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../../../../services/nullRemover';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  reloadDeviceList: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient) { }

  create(device: Device): Promise<any> {
    return this._tokenService
      .post('devices', device.to_params())
      .toPromise()
      .then(response => response)
  }

  save(device: Device): Promise<any> {
    if (device.is_new_record()) {

      return this.create(device);
    }
    else {
      return this.update(device);
    }
  }

  update(device: Device) {
    return this._tokenService
      .put('devices', device.to_params())
      .toPromise()
      .then(response => response)
  }
  delete(id: number) {
    return this._tokenService
      .delete('devices?id=' + id)
      .toPromise()
      .then(response => response)
  }

  getPatientDevices(filter: Object) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('devices', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          devices: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  getDevicesToSelect(practice_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('practice_id', practice_id);
    return this._tokenService.get('devices/device_infos', {params: params})
      .toPromise()
      .then(response => response);
  }

  getPatientSleepDevice(patient_id: any, source_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('patient_id', patient_id);
    params = params.set('integration_id', source_id);
    return this._tokenService.get('devices/sleep_device', {params: params})
      .toPromise()
      .then(response => response);
  }
}
