<ng-container *ngIf="admission">
  <div class="main-wrapper">
    <div class="row">
      <div class="col-sm-10 col-xs-12">
        <div class="todaysPatient-list headerPatient-list clearfix">
          <div class="col-sm-3 picName-area">
            <div class="cell-holder">
              <img class="img-rounded thumbnail thumb64 pic" [src]="admission.appointment.patient.profile_pic" alt="Image"/>
              <h4 class="m0 mb fw-500 text-primary">{{admission.appointment.patient.name}}</h4>
              <h5 class="m0 mb">
                <i [ngClass]="(admission.appointment.patient.user.gender == 'female')?'fa icon-user-female':'fa icon-user'"></i>&nbsp;
                {{admission.appointment.patient.show_age_and_gender()}}
              </h5>
              <h5 class="m0 mb"><i class="fa icon-calendar"></i>&nbsp; {{admission.appointment.patient.customize_dob}}</h5>
              <h5 class="m0"><i class="fa icon-phone"></i>&nbsp; {{admission.appointment.patient.mobile_number_value  | mask: ' (000) 000-0000'}}</h5>
            </div>
          </div>
          <div class="col-sm-6 patient-info">
            <div class="row mb-sm">
              <div class="col-sm-4 col-xs-12 pr0"><label class="fw-500 m0">Reason of Admission:</label></div>
              <div class="col-sm-8 col-xs-12">
                <span class="text-primary fw-500">{{admission.service.name}}</span>
              </div>
            </div>
            <div class="row mb-sm">
              <div class="col-sm-4 col-xs-12 pr0"><label class="fw-500 m0">Bed / Ward:</label></div>
              <div class="col-sm-8 col-xs-12">{{admission.bed_number}} / {{admission.ward}}</div>
            </div>
            <div class="row mb-sm">
              <div class="col-sm-4 col-xs-12 pr0"><label class="fw-500 m0">Department:</label></div>
              <div class="col-sm-8 col-xs-12">{{admission.department_name}}</div>
            </div>
            <div class="row mb-sm" *ngIf="admission.staff">
              <div class="col-sm-4 col-xs-12 pr0"><label class="fw-500 m0">Staff:</label></div>
              <div class="col-sm-8 col-xs-12">{{admission.staff.name}}</div>
            </div>
            <div class="row" *ngIf="admission.comment">
              <div class="col-sm-4 col-xs-12 pr0"><label class="fw-500 m0">Comment:</label></div>
              <div class="col-sm-8 col-xs-12">{{admission.comment}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-2 col-xs-12 text-right">
        <button class="btn btn-primary mt-lg" (click)="classicModal.show()">Add Note</button>
      </div>
    </div>
  </div>
  <div class="bg-lightest-gray main-wrapper pt-sm">
    <h5 class="text-center"><i class="fa fa-edit"></i> &nbsp;Notes</h5>
    <app-note [notes]="admission.notes"></app-note>
  </div>
</ng-container>

<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:306px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Add Note</h4>
      </div>
      <div class="modal-body">
        <textarea cols="10" rows="6" class="form-control no-resize" placeholder="Type your note here..." [(ngModel)]="note.comment"></textarea>
      </div>
      <div class="modal-footer">
        <div class="text-center">
          <button type="submit" class="btn btn-primary" (click)="addNote();">Add</button>
          <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
