import {Component, OnDestroy, OnInit} from '@angular/core';
import {Tab} from '../../../tabs/tab';
import {AlertTemplate} from '../alert-template';
import {TabsService} from '../../../services/tabs.service';
import {AlertTemplateComponent} from '../alert-template/alert-template.component';
import {PageChangedEvent} from 'ngx-bootstrap/pagination/pagination.component';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {AlertTemplateService} from '../alert-template.service';
import {UserService} from '../../../services/user.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-alert-templates',
  templateUrl: './alert-templates.component.html',
  styleUrls: ['./alert-templates.component.scss']
})
export class AlertTemplatesComponent implements OnInit, OnDestroy {
  public filter: Object = {token: '', practice_id: undefined, type: undefined, page: 1, status: undefined,
    severity: undefined, protocol_id: undefined};
  public alertTemplates: AlertTemplate[] = [];
  public totalItems: any;
  public subscription: Subscription;

  constructor(public tabs_service: TabsService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public alertTemplateService: AlertTemplateService,
              public userService: UserService) {
    this.subscription = this.alertTemplateService.reload_alert_template_list.subscribe(item => {
      if (item) {
        this.getAlerts();
      }
    });
  }

  ngOnInit(): void {
    if (this.userService.current_user.isPracticeUser) {
      console.log(this.userService.current_user.profileable_type)
      this.filter['practice_id'] = this.userService.current_user.profileable['practice_id'];
    }
    this.getVitalProtocols();
    this.getAlerts();
  }

  addAlert() {
    let alertTemplate = new AlertTemplate();
    this.tabs_service.add_tab(new Tab({title: 'Add Alert', icon: 'fa'}, AlertTemplateComponent,
      alertTemplate.id, {id: alertTemplate.id, alertTemplate: alertTemplate}))
  }

  search() {
    this.filter['page'] = 1;
    this.getAlerts();
  }

  pageChanged(event: PageChangedEvent) {
    this.filter['page'] = event.page;
    this.getAlerts();
  }

  editAlert(alertTemplate: AlertTemplate) {
    this.globalErrorHandlerService.tempObjCopy = new AlertTemplate().load_from_json(alertTemplate);
    this.tabs_service.add_tab(new Tab({title: 'Edit Alert Template', icon: 'fa'}, AlertTemplateComponent, alertTemplate.id,
      {id: alertTemplate.id, alertTemplate: alertTemplate}))
  }

  getAlerts() {
    this.loaderService.show();
    this.alertTemplateService.getAlertTemplates(this.filter).then(resp => {
      this.alertTemplates = resp.alertTemplates.map(alertTemplate => new AlertTemplate().load_from_json(alertTemplate));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  changeStatusOfAlertTemplate(alertTemplate: AlertTemplate) {
    this.loaderService.show();
    this.alertTemplateService.save(alertTemplate)
      .then(resp => {
        alertTemplate = alertTemplate.load_from_json(resp)
        this.loaderService.hide();
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getVitalProtocols() {
    this.alertTemplateService.getVitalProtocols().then(resp => {
      this.alertTemplateService.protocols = resp;
    })
  }

  showSign(trend: any) {
    if (trend === 'greater_than') {
      return '>'
    }
    if ( trend === 'less_than') {
      return '<'
    }
    if ( trend === 'equals_to') {
      return '='
    }
  }
}
