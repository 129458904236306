import { Component, OnInit } from '@angular/core';
import {PracticeReviewSetup} from '../practice_review_setup';
import {PracticeReviewService} from '../practice_review.service';
import {UserService} from '../../services/user.service';
import {LoaderService} from '../../services/loader.service';
import {PracticesService} from '../../services/practices.service';
import {Practice} from '../../routes/practices/practices/practice';
import {Clipboard} from "@angular/cdk/clipboard";
import {AlertService} from '../../_alert';
import {GlobalErrorHandlerService} from '../../services/global-error.service';



@Component({
  selector: 'app-reviews-setup',
  templateUrl: './reviews-setup.component.html',
  styleUrls: ['./reviews-setup.component.scss']
})
export class ReviewsSetupComponent implements OnInit {
  practices: Practice[];
  text: string;
  showPracticeDashboard = false;
  practice_review_setup: PracticeReviewSetup = new PracticeReviewSetup().initializePreferences();
  star_ratings = ['one_star', 'two_star', 'three_star', 'four_star', 'five_star']
  public star_rating_mapper: Object = { 'one_star': '1 Star & Above', 'two_star': '2 Star & Above', 'three_star': '3 Star & Above', 'four_star': '4 & 5 Star', 'five_star': '5 Star'};


  constructor(public practiceService: PracticesService, public loaderService: LoaderService,
              public practice_review_service: PracticeReviewService, public userService: UserService,
              public alertService: AlertService, public globalErrorHandlerService: GlobalErrorHandlerService) { }


  ngOnInit() {
    if (this.userService.current_user.isEnterprise) {
      this.loaderService.show()
      this.allPractices();
    }
    if (this.userService.current_user.isPractice) {
      this.getPracticeSetup(this.userService.current_user.profileable['practice_id'])
      this.practice_review_setup.practice_id = this.userService.current_user.profileable['practice_id'];
    }
  }
  getSetup(id) {
    this.showPracticeDashboard = !this.showPracticeDashboard;
    this.practice_review_setup.practice_id = id;
    this.getPracticeSetup(id);
  }
  back() {
    this.showPracticeDashboard = !this.showPracticeDashboard;
    this.practice_review_setup = new PracticeReviewSetup().initializePreferences();
  }
  allPractices() {
    this.practiceService.getAllPracticeForReviews().then(resp => {
      this.practices = resp;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
  clearPracticeFilter() {
    this.text = '';
    this.searchAllPracticeForReviews();
  }
  searchAllPracticeForReviews() {
    this.practiceService.searchAllPracticeForReviews(this.text).then(resp => {
      this.loaderService.show();
      this.practices = resp;
      this.loaderService.hide();
    })
  }


  save() {
    this.loaderService.small_loder_show();
    this.practice_review_service.save(this.practice_review_setup)
      .then(resp => {
        this.practice_review_setup = this.practice_review_setup.load_from_json(resp)
        this.loaderService.small_loder_hide();
        this.alertService.success('Setup has been saved successfully', this.alertService.options);
      }).catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  getPracticeSetup(id) {
    this.practice_review_service.getPracticeSetup(id).then(resp => {
      this.practice_review_setup = this.practice_review_setup.load_from_json(resp);
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }


}
