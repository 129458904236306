<div class="main-wrapper clearfix">
  <ng-container *ngIf="userService.current_user.isEnterprise">
    <div class="row" *ngIf="practicePackagesInfo">
      <div class="col-sm-6 col-xs-12">
        <h3 class="m0">Current Package</h3>
        <hr class="hr-line mt mb">
        <label class="fw-500 mr-lg" *ngFor="let pr of practicePackagesInfo.basic_packages; index as i">
          <input type="radio" [value]="pr.id" name="practicePackage.selected_package"
                 (change)="changePackage($event.target.value)"
                 [checked]="pr.id == practicePackagesInfo.selected_basic_package.id">
          {{capitalize(pr.name) | titlecase }}
        </label>
      </div>
      <div class="col-sm-6 col-xs-12">
        <h3 class="m0">Add-Ons</h3>
        <hr class="hr-line mt mb">
        <ng-container *ngIf="['better_deal','easy_going','ultimate'].includes(practicePackagesInfo.selected_basic_package.name)">
          <label class="fw-500 mr-lg" *ngFor="let pr of practicePackagesInfo.add_on_packages; let i = index">
            <input type="checkbox" [value]="pr.id" name="add_ons" (change)="changeAddOns($event)" [checked]="selectedAddOns(pr.id)"/>
            {{capitalize(pr.name)  | titlecase}}
          </label>
        </ng-container>
      </div>
      <div class="col-xs-12">
        <button class="btn btn-primary mt-lg mb-lg" (click)="updatePracticePackage()">Save</button>
      </div>
    </div>
    <h4 class="alert alert-success pt pb fs12" *ngIf="success">Package updated successfully.</h4>
  </ng-container>



  <div class="mb-lg" *ngIf="userService.current_user.isPractice && practicePackagesInfo">
    <h3 class="m0">Current Package
      &nbsp;
      <small class="status-colors scheduled vl-m text-center" style="display:inline-block; min-width:105px;">
        {{practicePackagesInfo.selected_basic_package.name.toUpperCase()}}
      </small>
    </h3>
    <hr class="hr-line mt">
  </div>
  <div *ngIf="paid" class="alert alert-success p text-center">Invoice Paid Successfully</div>
  <div class="mt-lg">
    <div class="row">
      <div class="col-xs-9" *ngIf="paymentMethod"><h3 class="m0">Card Details</h3></div>
      <div class="col-xs-3 text-right" *ngIf="!paymentMethod">
        <button type="button" class="btn btn-inverse" (click)="openNewModal('card');addPaymentMethod = true">Add New Payment
          Method
        </button>
      </div>
    </div>
    <hr class="hr-line mt mb">
    <div class="border-box shadow p-lg pr-xl pl-xl" *ngIf="paymentMethod">
      <div class="row">
        <div class="col-sm-5 col-xs-5">
          <h3 class="mt0">{{paymentMethod['cardholder_name']}}</h3>
        </div>
        <div class="col-sm-5 col-xs-5">
          <div class="media">
            <div class="media-left media-middle"><img src="assets/img/credit-card.png" style="max-width:70px;" alt="Icons"></div>
            <div class="media-body media-middle">
              <h4 class="mt0">XXX XXX XXX {{paymentMethod['last_4']}}</h4>
              <h5>Expires {{paymentMethod['expiration_month']}} / {{paymentMethod['expiration_year']}}</h5>
              <h5>{{paymentMethod['cardholder_name']}}</h5>
            </div>
          </div>
        </div>
        <div class="col-sm-2 col-xs-2 text-right">
          <a class="btn btn-primary btn-outline" (click)="openNewModal('card');update_card = true">
            <i class="fa fa-pencil mr-sm"></i> Edit
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-xl">
    <div class="col-xs-9"><h3 class="m0">Invoices</h3></div>
    <div class="col-xs-3 text-right">
      <button *ngIf="userService.current_user.isEnterprise" type="button" class="btn btn-secondary" (click)="newInvoice();openNewModal('invoiceModel')">
        Generate Invoice
      </button>
      <!--<a class="btn btn-inverse ml" hidden>Pay All</a>-->
    </div>
  </div>
  <hr class="hr-line mt mb">
  <table class="table table-hover mt-lg">
    <thead>
    <tr>
      <th class="text-center">Created At</th>
      <th class="text-center">Invoice#</th>
      <th class="text-center">Invoice Type</th>
      <th class="text-center">Billing Date</th>
      <th class="text-center">Due Date</th>
      <th class="text-center">Package</th>
      <th class="text-center">Amount</th>
      <th class="text-center">Status</th>
      <th class="text-center">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let invoice of invoices">
      <td class="text-center">{{invoice.created_at | date: 'shortDate'}}</td>
      <td class="text-center" (click)="selectedInvoice = invoice; openNewModal('invoiceModel')">
        <a>{{invoice.invoice_number}}</a></td>
      <td class="text-center">Default</td>
      <td class="text-center">{{invoice.bill_date | date: 'shortDate'}}</td>
      <td class="text-center">{{invoice.due_date  | date: 'shortDate'}}</td>
      <td class="text-center">{{practice.account_name}}</td>
      <td class="text-center">${{invoice.amount}}</td>
      <td class="text-center">{{invoice.status}}</td>
      <td class="text-center">
        <ng-container *ngIf="!invoice.paying">
          <a class="btn btn-primary btn-outline" (click)="printableInvoice = invoice;printInvoice.show();">Download</a>
          <ng-container *ngIf="invoice.status === 'unpaid'">
            <a (click)="payInvoice(invoice)" class="btn btn-primary btn-outline ml">Pay
              Now</a>
            <a class="btn btn-danger btn-outline ml" type="button" (click)="selectedInvoice = invoice;deleteModal.show()" *ngIf="userService.current_user.isEnterprise">
              <i class="fa icon-trash"></i>
            </a>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="invoice.paying">
          <i class="fa fa-spinner fa-spin"></i>
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="modal fade" bsModal #printInvoice="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="width:auto; max-width:1024px;">
    <div class="modal-content">
      <div class="modal-body">
        <app-invoice-print class="flex-control flex-column print-invoice" [invoice]="printableInvoice"></app-invoice-print>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-tertiary btn-outline btn-min" (click)="printInvoice.hide();">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade flex-modal" bsModal #bsModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="bsModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">{{showTitle()}}</h4>
      </div>
      <ng-container *ngIf="modalFor === 'invoiceModel'">
        <app-invoice class="flex-control flex-column" (invoiceCreated)="getInvoices()" [invoice]="selectedInvoice" [practice]="practice"></app-invoice>
      </ng-container>
      <ng-container *ngIf="modalFor === 'card'">
        <app-braintree-payment [addPaymentMethod]="addPaymentMethod" [token]="paymentMethod ? paymentMethod['token']: null"
                               [update_card]="update_card" (paymentMethodUpdated)="paymentMethodUpdate()"
                               [practice]="practice"></app-braintree-payment>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Invoice</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to delete the Invoice?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteInvoice(selectedInvoice.id);">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
