import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {CaseFeedback} from '../routes/practice-site/admissions/discharge/discharge_feedback';

@Injectable()
export class CaseFeedBackService {
  public error_message: string;
  constructor(private _tokenService: CustomHttpClient) { }
  getCaseCheckLists(): Promise<any> {
    return this._tokenService.get('case_feedbacks/discharge_checklists')
      .toPromise()
      .then(response => response);
  }
  getCaseFeedback(id: number): Promise<any> {
    return this._tokenService.get('case_feedbacks/' + id)
      .toPromise()
      .then(response => response);
  }
  save(caseFeedback: CaseFeedback): Promise<any> {
    return this._tokenService.post('case_feedbacks/', caseFeedback.to_params())
      .toPromise()
      .then(response => response)
      .catch(error => {
        this.handleError(error);
        this.error_message = error.error;
      });


  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
