<table class="table table-hover">
  <thead>
    <tr>
      <th>Name</th>
      <th>DOB</th>
      <th>Gender</th>
      <th>Address</th>
      <th>Contact</th>
      <th class="text-center">Type</th>
      <th class="text-center">Registered On</th>
      <th class="text-center">Web Access</th>
      <th class="text-center">Appointments</th>
      <th class="text-center">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let patient of patients">
      <tr>
        <td><a (click)="sendPatient(patient)">{{patient.name}}</a></td>
        <td>{{patient.customize_dob}}</td>
        <td><p class="m0 text-capitalize">{{patient.gender_for_display()}}</p></td>
        <td>{{patient.address}}</td>
        <td>
          <a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(patient.phone, {name: patient.name, pic: patient.profile_pic})">
            {{patient.phone | mask: ' (000) 000-0000'}}<br>{{patient.email}}
          </a>
        </td>
        <td class="text-center">{{patient.patient_type}}</td>
        <td class="text-center">{{patient.requested_at_date | date: 'mediumDate'}}</td>
        <td class="text-center">{{(patient.can_login ? 'Yes' : 'No')}}</td>
        <td class="text-center">{{patient.appointments_count}}</td>
        <td>
          <app-patient-communication [patient]="patient"></app-patient-communication>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<app-no-result-found *ngIf="patients && patients.length == 0"></app-no-result-found>
<div class="text-center" *ngIf="totalItems > 25">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="25" (pageChanged)="pageChanged($event)"></pagination>
</div>
