<div class="todaysPatient-list headerPatient-list" *ngIf="practice">
  <div class="list p0 b0">
    <div class="col-sm-3 pl0">
      <div class="cell-holder">
        <h4 class="text-black m0 mb fw-500">{{practice.requester_firstname}} {{practice.request_lastname}}</h4>
        <h6 class="m0 mb"><a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(practice?.phone?.value,
                  {name: (practice.requester_firstname + ' ' + practice.request_lastname), pic: practice.profile_pics})">
          <i class="fa icon-phone"></i>&nbsp; {{practice?.phone?.value | mask: '(000) 000-0000'}}</a>
        </h6>
        <h6 *ngIf="practice.fax?.value" class="m0 mb"><i class="fa fa-fax"></i>&nbsp; {{practice?.fax?.value}}</h6>
        <h6 class="m0"><i class="fa icon-envelope"></i>&nbsp; {{practice.email}}</h6>
      </div>
    </div>
    <div class="col-sm-5 border-col">
      <div class="cell-holder pl-sm pr-sm">
        <div class="row">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Practice</label></div>
          <div class="col-sm-9 col-xs-12">{{practice.name}}</div>
        </div>
        <div class="row">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Specialty</label></div>
          <div class="col-sm-9 col-xs-12">{{practice.client.specialties[0] ? practice.client.specialties[0].description : ""}}</div>
        </div>
        <div class="row">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">No of Doctors</label></div>
          <div class="col-sm-9 col-xs-12">{{practice ? practice.no_of_doctors : 0}}</div>
        </div>
        <div class="row">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Area</label></div>
          <div class="col-sm-9 col-xs-12">{{practice ? practice.client.address.city_area.name : 'NA'}}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 border-col">
      <div class="cell-holder pl-sm pr-sm">
        <div class="row">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Status</label></div>
          <div class="col-sm-4 col-xs-12">{{practice.capitalize(practice.client.status_id)}}</div>
          <div class="col-sm-4 col-xs-12">
            <progressbar value={{practice.profile_percentage}} class="status_bar m0" [ngClass]="practice.statusClass">{{practice.profile_percentage}} %</progressbar>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Locations</label></div>
          <div class="col-sm-4 col-xs-12">{{practice ? practice.locations.length : 0}}</div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Doctors</label></div>
          <div class="col-sm-4 col-xs-12">{{practice ? practice.doctors.length : 0}}</div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xs-12 pr0"><label class="field-label">Account Type</label></div>
          <div class="col-sm-4 col-xs-12">{{practice.account_type}}</div>
          <div class="col-sm-4 col-xs-12">
            <a class="btn btn-primary btn-outline btn-sm" *ngIf="practice.account_name?.toLowerCase() != 'ultimate' && practice.account_name != 'NA'" routerLink='/pricing-info' [queryParams]="{practice_id: practice.hashid}">
              <i class="fa fa-refresh mr-sm"></i> Upgrade
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
