<div class="flex-control flex-column" *ngIf="show_pdf">
  <div class="row flex-header b0">
    <div class="col-md-5 col-sm-5">
      <h5><i class="fa icon-home-medical-history fs20 mr-sm vl-m"></i> Sleep Compliance Report</h5>
    </div>
    <div class="col-md-7 col-sm-7 text-right">
      <button class="btn btn-primary btn-outline mr" (click)="download()">Download</button>
      <!--<button class="btn btn-secondary btn-outline mr-sm" (click)="associateWithMedicalChart()">Add to Chart</button>-->
    </div>
  </div>
  <div class="flex-body">
    <pdf-viewer [src]="url"
                [render-text]="true"
                [original-size]="false"
                style="width:100%; height:100%">
    </pdf-viewer>
  </div>
</div>
