import {Component, OnInit, ViewChild, ElementRef, Injector} from '@angular/core';
import * as jsPdf from 'jspdf';
import {PaymentService} from "../../services/payment.service";
import {Appointment} from "../../health/models/appointment";
import {LoaderService} from "../../services/loader.service";
import {TabsService} from "../../services/tabs.service";
import {Patient} from "../../health/doctor/patient";

@Component({
  selector: 'app-pdf-printer',
  templateUrl: './pdf-printer.component.html',
  styleUrls: ['./pdf-printer.component.scss']
})
export class PdfPrinterComponent implements OnInit {
  @ViewChild('receipt') receipt: ElementRef;
  @ViewChild('statement') statement: ElementRef;
  public payment_data;
  public patient = new Patient();
  public appointment = new Appointment();
  public printType: string = '';
  public today = new Date().toLocaleDateString("en-US", {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  constructor(private loader: LoaderService, private injector: Injector,
              private paymentService: PaymentService, private tabService: TabsService) {
  }

  ngOnInit() {
    this.loader.show();
    let ledger = this.injector.get('ledger', null);
    this.printType = this.injector.get('type', null) || '';
    if (ledger)
      this.paymentService.getPrintPaymentDate(ledger.appointment_id).then(resp => {
        this.payment_data = resp;
        this.loader.hide();
      });

  }

  generatePdf(action) {
    let el = this.printType === 'Receipt' ? this.receipt : this.statement;
    let pdf = new jsPdf();
    let options = {
      format: "PNG",
      margin: {top: 150, right: 10, bottom: 10, left: 10, useFor: 'page'}
    };
    pdf.addHTML(el.nativeElement, 0, 0, options, () => {
      if (action == 'print') {
        pdf.autoPrint();
        var string = pdf.output('datauristring');
        var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
        var x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
      }
      if (action == 'save') {
        pdf.save(this.printType);
      }
    });
  }

  payment_date(created_at) {
    var date = new Date(created_at);
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!
    var yyyy = date.getFullYear();
    var today = dd + '/' + mm + '/' + yyyy;
    return today;

  }
}
