<div class="flex-control flex-column flex-height">
  <div class="filters-sec flex-header text-center">
    <button class="btn btn-secondary btn-min mr-sm" (click)="generatePdf('print')">Print</button>
    <button class="btn btn-tertiary btn-min btn-outline mr-sm" (click)="generatePdf('save')">Download</button>
    <button type="button" class="btn btn-tertiary btn-outline" *ngIf="this.userService.current_user.isPatient"
            (click)="patientEventService.showReportListener.next(false)">Back</button>
  </div>
  <div class="flex-body scroll-browser p-lg">
    <div class="container printer-wrapper" #report id="reports">
      <div class="text-center">
        <h2 class="m0">Health Calendar Compliance Report</h2>
        <h4>{{patientName}}</h4>
        <div class="prac-info">
          <div class="col" *ngIf="filter['type']"><span class="field-label">Type of Event</span> {{userService.removeUnderScore(filter['type'])}}</div>
          <div class="col" *ngIf="filter['date_type'] && filter['date_type'] !== 'custom'"><span class="field-label">Time</span> {{userService.removeUnderScore(filter['date_type'])}}</div>
          <div class="col" *ngIf="filter['to'] && filter['from']"><span class="field-label">Time</span> {{filter['from'] | date}} to {{filter['to'] | date}}</div>
        </div>
      </div>
      <div class="row bar-holder">
        <div class="col-sm-5 mb-lg">
          <h4 class="text-center">All Time Compliance</h4>
          <div class="prac-info">
            <div class="col"><span class="field-label">AVG. COMPLIANCE RATE</span> {{stats['all_time_stats']['avg_compliance_rate']}}%</div>
          </div>
          <ul class="list-unstyled progress-list">
            <li>
              <h5>Completed</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-striped active"  [style.width.%]="stats['all_time_stats']['completed'] > 0 ? stats['all_time_stats']['completed_width'] : 0">
                  {{stats['all_time_stats']['completed'] > 0 ? (stats['all_time_stats']['completed_width'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['all_time_stats']['completed']}}</h5>
            </li>
            <li>
              <h5>Not Completed</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-success progress-bar-striped active" [style.width.%]="stats['all_time_stats']['not_completed'] > 0 ? stats['all_time_stats']['not_completed_width'] : 0">
                  {{ stats['all_time_stats']['not_completed'] > 0 ? (stats['all_time_stats']['not_completed_width'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['all_time_stats']['not_completed']}}</h5>
            </li>
            <li>
              <h5>Missed</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-info progress-bar-striped active" [style.width.%]="stats['all_time_stats']['missed'] > 0 ? stats['all_time_stats']['missed_width'] : 0">
                  {{stats['all_time_stats']['missed'] > 0 ? (stats['all_time_stats']['missed_width'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['all_time_stats']['missed']}}</h5>
            </li>
            <li>
              <h5>Cancelled</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-warning progress-bar-striped active" [style.width.%]="stats['all_time_stats']['cancelled'] > 0 ? stats['all_time_stats']['cancelled_width'] : 0">
                  {{stats['all_time_stats']['cancelled'] > 0 ? (stats['all_time_stats']['cancelled_width'] | number : '1.0-0') : 0 }}%</div>
              </div>
              <h5 class="counter">{{stats['all_time_stats']['cancelled']}}</h5>
            </li>
          </ul>
        </div>
        <div class="col-sm-2 hidden-xs"></div>
        <div class="col-sm-5 mb-lg">
          <h4 class="text-center">Current Report Compliance</h4>
          <div class="prac-info">
            <div class="col"><span class="field-label">AVG. COMPLIANCE RATE</span> {{stats['stats']['avg_compliance_rate']}}%</div>
          </div>
          <ul class="list-unstyled progress-list">
            <li>
              <h5>Completed</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-striped active" [style.width.%]="stats['stats']['completed'] > 0 ? stats['stats']['completed_width'] : 0">
                  {{stats['stats']['completed'] > 0 ? (stats['stats']['completed_width'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['stats']['completed']}}</h5>
            </li>
            <li>
              <h5>Not Completed</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-success progress-bar-striped active" [style.width.%]="stats['stats']['not_completed'] > 0 ? stats['stats']['not_completed_width'] : 0">
                  {{stats['stats']['not_completed'] > 0 ? (stats['stats']['not_completed_width'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['stats']['not_completed']}}</h5>
            </li>
            <li>
              <h5>Missed</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-info progress-bar-striped active" [style.width.%]="stats['stats']['missed'] > 0 ? stats['stats']['missed_width'] : 0">
                  {{stats['stats']['missed'] > 0 ? (stats['stats']['missed_width'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['stats']['missed']}}</h5>
            </li>
            <li>
              <h5>Cancelled</h5>
              <div class="progress">
                <div class="progress-bar progress-bar-warning progress-bar-striped active" [style.width.%]="stats['stats']['cancelled'] > 0 ? stats['stats']['cancelled_width'] : 0">
                  {{stats['stats']['cancelled_width'] > 0 ? (stats['stats']['cancelled_width'] | number : '1.0-0') : 0}}%</div>
              </div>
              <h5 class="counter">{{stats['stats']['cancelled']}}</h5>
            </li>
          </ul>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Duration</th>
            <th>Event</th>
            <th>Type</th>
            <th style="width:200px; max-width:200px;">Description</th>
            <th>Notify</th>
            <th>Status</th>
            <th style="width:200px; max-width:200px;">Comments</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let date of stats['grouped_events_dates']">
            <tr>
              <td colspan="20">
                <h5 class="m0">{{stats['group_events'][date][0]['start_date'] | date: 'fullDate'}}</h5>
              </td>
            </tr>
            <tr *ngFor="let event of stats['group_events'][date]">
              <td>{{event.start_date | date: 'M/d/yy'}}</td>
              <td>{{event.start_date | date: 'h:mm a'}}</td>
              <td>{{userService.removeUnderScore(event.duration)}}</td>
              <td>{{event.subject}}</td>
              <td>{{userService.removeUnderScore(event.event_type)}}</td>
              <td>{{event.description}}</td>
              <td>
              <span *ngFor="let notify of event.notify_before;let i=index">
                {{userService.removeUnderScore(notify)}}
                <span *ngIf="i != event.notify_before.length - 1">,</span>
              </span>
              </td>
              <td>
                <label class="label fs12 fw-500" [style.background]="event.status_color">
                  {{userService.removeUnderScore(event.status)}}
                </label>
              </td>
              <td>
                <ng-container *ngFor="let comment of event.comments; let i=index">
                  <p class="mb-sm">{{comment.body}}</p>
                </ng-container>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <app-no-result-found *ngIf="stats['group_events'].length == 0"></app-no-result-found>
    </div>
  </div>
</div>

