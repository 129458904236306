export class IntegrationCredential {
  id: number;
  value: string;
  label: string;
  programme_integration_id: number;

  load_from_json(json) {
    this.id = json.id;
    this.value = json.value;
    this.label = json.label;
    this.programme_integration_id = json.programme_integration_id;
    return this;
  }

  to_params() {
    return {
      id: this.id,
      value: this.value,
      label: this.label
    }
  }

  load_for_new(json) {
    this.value = json.value;
    this.label = json.label;
    return this;
  }
}
