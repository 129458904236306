import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {Appointment} from '../../../health/models/appointment';
import {UserService} from '../../../services/user.service';
import {Location} from '@angular/common';
import { Router} from  '@angular/router'
import {HttpClient} from '@angular/common/http';
import {TabsService} from '../../../services/tabs.service';
import {PatientService} from '../../../services/patient.service';
@Component({
  selector: 'app-todays-patients',
  templateUrl: './todays-patients.component.html',
  styleUrls: ['./todays-patients.component.scss']
})

export class TodaysPatientsComponent implements OnInit {

  @Input() practice;
  @Input() providers;
  @Input() locations;
  @Input() doctor_id;
  @Input() app_type;
  @Output() dynamic_component_handle: EventEmitter<any> = new EventEmitter<any>();

  public totalItems: string;
  public pagination: string;
  public user: any;
  public refresh_data = false;
  public pagination_active = true;
  public appointments: Appointment[];
  public current_page = '1';
  constructor(private cd: ChangeDetectorRef, public tab_service: TabsService,
              private _tokenService: HttpClient, private _router: Router,
              public _user_service: UserService, private location: Location,
              public _patient_service: PatientService) { }

  ngOnInit() {
  }

  appointments_data(appointments) {
    if (appointments) {
      this.appointments = appointments;
    }
  }

  total_appointment_count(count) {
    if (count) {
      this.totalItems = count;
    }
  }

  page_no(no) {
    if (no) {
      this.pagination = no;
    }
  }

  get_current_user(user) {
    this.user = user;
    if (this.user) {
      return true;
    }
  }

  refresh_tab_data(data_condition) {
    if (data_condition) {
      this.refresh_data = data_condition;
      this.tab_service.set_data(false);
      return this.refresh_data;
    }
    this.refresh_data = false;
    return this.refresh_data;
  }

  pageChanged(page) {
    this.current_page = page.page;
    this.pagination_active = !this._patient_service.call_for_appointments;
  }
}
