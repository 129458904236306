import { Component, OnInit } from '@angular/core';
import {TabsService} from "../../../services/tabs.service";

@Component({
  selector: 'app-note-components',
  templateUrl: './note-components.component.html',
  styleUrls: ['./note-components.component.scss']
})
export class NoteComponentsComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('Note Component');
  }

  ngOnInit(): void {
  }

}
