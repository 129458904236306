import { Injectable }     from '@angular/core';
import { Observable } from 'rxjs';
import {CustomHttpClient} from './custom-http-client.service';



@Injectable()
export class SpecialtySearchService {
  constructor(private _tokenService: CustomHttpClient) { }

  search(term: string): Observable<any> {

    return this._tokenService
        .get('specialties/?term=' + term)
        .map((r) => r);

  }

  search_services(term: string): Observable<any> {
    return this._tokenService
      .get('services/?term=' + term)
      .map((r) => r );

  }

  search_amenities(term: string): Observable<any> {
    return this._tokenService
      .get('amenities/search/?token=' + term)
      .map((r) => r );

  }

  public_search_services(term: string): Observable<any> {
    return this._tokenService
        .get('lead_integration/get_services/?term=' + term)
        .map((r) => r );
  }
}
