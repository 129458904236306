<div style="display:none">
  <h4 class="display-4"><i class="fas fa-cogs"></i> Settings</h4>
  <form class="form">
    <div class="form-group" *ngIf="hasAudioInputOptions">
      <app-device-select [kind]="'audioinput'"
                         [label]="'Audio Input Source'" [devices]="devices"
                         (settingsChanged)="onSettingsChanged($event)"></app-device-select>
    </div>
    <div class="form-group" *ngIf="hasAudioOutputOptions">
      <app-device-select [kind]="'audiooutput'"
                         [label]="'Audio Output Source'" [devices]="devices"
                         (settingsChanged)="onSettingsChanged($event)"></app-device-select>
    </div>
    <div class="form-group" *ngIf="hasVideoInputOptions">
      <app-device-select [kind]="'videoinput'" #videoSelect
                         [label]="'Video Input Source'" [devices]="devices"
                         (settingsChanged)="onSettingsChanged($event)"></app-device-select>
    </div>
  </form>
</div>
<app-camera (callEnded)="onCallEnded()" #camera [style.display]="isPreviewing ? 'block' : 'none'"></app-camera>
<div class="row" [style.display]="isPreviewing ? 'block' : 'none'">
  <div class="col-xs-6" *ngIf="videoChatService.clock">
    <div class="white-container-box timer-box" >
      <h6 class="m0">Time Spent</h6>
    </div>
  </div>
<!--  <div class="col-xs-6" *ngIf="videoChatService.counter">-->
<!--    <div class="white-container-box timer-box">-->
<!--      <h2>{{videoChatService.counter | formatTime}}</h2>-->
<!--      <h6 class="m0">Time Remaining</h6>-->
<!--    </div>-->
<!--  </div>-->
</div>
