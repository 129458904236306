<ng-container *ngIf="!template.is_rich_text">
  <h6 class="text-bold text-capitalize">{{template.temp_type}}</h6>
  <ng-container *ngIf="selectedTemplate?.terms">
    <ng-container *ngFor="let term of selectedTemplate?.terms; let index=index">
      <div contenteditable="false" [id]="'whole-wrapper-'+term.id">
        <div contenteditable="false" *ngIf="term">
          <app-print-enote [term]="term" [term_index]="index" class="mb-lg"
                           [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-print-enote>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="template.is_rich_text">
  <h6 class="text-bold text-capitalize">{{template.text_type}}</h6>
  <div  [innerHTML]="template.body | safeHtml"></div>
</ng-container>
