<span contenteditable="false" >
<input #control_typeahead [(ngModel)]="asyncSelected"
       typeaheadWaitMs="1000"
       [id]="term.id ? 'typeahead-'+term.id : term.index_counter "
       [typeahead]="dataSource" [typeaheadAsync]="true"
       (typeaheadLoading)="changeTypeaheadLoading($event)"
       autocomplete="off"
       (typeaheadNoResults)="changeTypeaheadNoResults($event)"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"
       [typeaheadOptionsLimit]="7"
       [typeaheadMinLength]="3"
       [typeaheadOptionField]="'name'"
       (keydown)="checkString($event)"
       style="border:0;"
       class="pl">
  </span>
