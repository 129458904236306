export class PatientCommunicationLog {
  id: number;
  created_at: any;
  sent_on: any;
  scheduled_on: string;
  outreach_type: string;
  source: string;
  status: string;
  reason = '';
  message: any;
  subject: any;
  patient_email: string;
  patient_phone: string;

  load_from_json(json) {
    this.id = json.id;
    this.created_at = json.created_at;
    this.sent_on = json.sent_on;
    this.scheduled_on = json.scheduled_on;
    this.outreach_type = json.outreach_type;
    this.source = json.source;
    this.status = json.status;
    this.reason = json.reason;
    this.message =  json.message;
    this.subject =  json.subject;
    this.patient_email =  json.patient_email;
    this.patient_phone =  json.patient_phone;
    return this;
  }
}
