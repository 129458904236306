<div class="flex-control flex-column flex-height">
  <div class="flex-header text-center">
    <button type="button" class="btn btn-tertiary btn-outline" (click)="generatePdf()">Download</button>
  </div>
  <div class="flex-body scroll-browser">
    <div #patientInFlow class="main-wrapper center-block">
      <div class="text-center mb-lg">
        <h2 class="text-primary m0 fs40">{{_visitService.practice_name}}</h2>
        <h4 class="text-black">Daily Patient Inflow</h4>
        <h5 class="text-black">{{_visitService.start_date}} - {{_visitService.end_date}}</h5>
        <div class="text-black">
          <span><label>Visit Type:</label> {{_visitService.visitTypeMapping[_visitService.visit_type]}}</span>
          <ng-container *ngIf="_visitService.status_id">
            <span><label>Status:</label>, {{_visitService.status_id}}</span>
          </ng-container>
          <ng-container *ngIf="_visitService.doctor_name">
            <span><label>Doctor:</label>, {{_visitService.doctor_name}}</span>
          </ng-container>
        </div>
        <div class="text-black">
          <ng-container *ngIf="_visitService.group_by != '0' ">
            <span><label>Group By:</label> {{_visitService.groupByMapping[_visitService.group_by]}}</span>,
          </ng-container>
          <span><label>Sorted By:</label> {{_visitService.sort_by}}</span>
        </div>
      </div>
      <div class="table-print">
        <table class="table m0" cellspacing="0" cellpadding="0">
          <ng-container>
            <thead>
            <tr>
              <th>Date</th>
              <th>Doctor</th>
              <th>Patient</th>
              <th>Location</th>
              <th>Reason</th>
              <th>Booking</th>
              <th>Status</th>
              <th class="text-right">Charges</th>
              <th class="text-right">Payments</th>
              <th class="text-right">Discount</th>
              <th class="text-right">Balance</th>
            </tr>
            </thead>
          </ng-container>
          <tbody>
            <ng-container *ngFor="let key of visitsKeys()">
              <tr *ngIf="key != 'All' " class="group-by">
                <td colspan="20" class="p0"><h4 class="m0 p-sm bg-gray-light font-w">{{key}} <span class="pl-sm" *ngIf="+_visitService.group_by == 1 && +_visitService.practice_id > 0 && _visitService.doctorShare(key) > 0">({{_visitService.doctorShare(key)}} @ {{_visitService.visits[key][0].share}}%)</span></h4></td>
              </tr>
              <ng-container *ngFor="let visit of _visitService.visits[key]">
                <tr>
                  <td>{{visit.visit_date}}</td>
                  <td>{{visit.doctor_name}}</td>
                  <td>{{visit.patient_name}}</td>
                  <td>{{visit.location_name}}</td>
                  <td>{{visit.visit_reason}}</td>
                  <td>{{visit.appointment_type}}</td>
                  <td>{{visit.status_id}}</td>
                  <td class="text-right">{{visit.charges | number:'1.0-0'}}</td>
                  <td class="text-right">{{visit.payment | number:'1.0-0'}}</td>
                  <td class="text-right">{{visit.discount | number:'1.0-0'}}</td>
                  <td class="text-right">{{visit.balance | number:'1.0-0'}}</td>
                </tr>
              </ng-container>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">Sub Total</td>
                <td class="font-w fs16 text-right">{{_visitService.visits[key].total_charges | number:'1.0-0'}}</td>
                <td class="font-w fs16 text-right">{{_visitService.visits[key].total_payment | number:'1.0-0'}}</td>
                <td class="font-w fs16 text-right">{{_visitService.visits[key].total_discount | number:'1.0-0'}}</td>
                <td class="font-w fs16 text-right">{{_visitService.visits[key].total_balance | number:'1.0-0'}}</td>
              </tr>
            </ng-container>
            <tr *ngIf="_visitService.visits">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-w fs16 text-right">Grand Total</td>
              <td class="font-w fs16 text-right bg-gray-darker">{{_visitService.grand_total_charges | number:'1.0-0'}}</td>
              <td class="font-w fs16 text-right bg-gray-darker">{{_visitService.grand_total_payment | number:'1.0-0'}}</td>
              <td class="font-w fs16 text-right bg-gray-darker">{{_visitService.grand_total_discount | number:'1.0-0'}}</td>
              <td class="font-w fs16 text-right bg-gray-darker">{{_visitService.grand_total_balance | number:'1.0-0'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<style type="text/css">
  .main-wrapper{ color:#000!important; background-color:white !important;
    padding:30px 40px 0 !important; font-weight:500; width:1150px; margin:0 auto;
  }
  .table{ border:1px solid #bfbfbf; border-bottom:0 !important; border-right:0 !important;}
  th{ font-weight:bold !important; }
  tr{ border-bottom:1px solid #bfbfbf !important;}
  td, th{ border:0!important; border-right:1px solid #bfbfbf !important; padding:6px !important;}
  .group-by{ border:0 !important;}
  .group-by td{ padding:0 !important; border:0!important; border-right:1px solid #bfbfbf !important;}
  .group-by td h4{ border-bottom:1px solid #bfbfbf !important;}
  .text-primary{ color:#000!important;}
</style>
