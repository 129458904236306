<form #credentialForm="ngForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="authenticate()" class="mb-xl">
  <div class="row">
    <div class="col-sm-3 col-xs-12 form-group">
      <label>Practice URL*</label>
      <input class="form-control" autocomplete="off" [(ngModel)]="credential.practice_url"
             name="practice_url"/>
      <div class="label label-danger" *ngIf="customValidations.formErrors.practice_url">
        {{ customValidations.formErrors.practice_url }}
      </div>
    </div>
    <div class="col-sm-3 col-xs-12 form-group">
      <label>Auth URL*</label>
      <input class="form-control" autocomplete="off" [(ngModel)]="credential.auth_url"
             name="auth_url"/>
      <div class="label label-danger" *ngIf="customValidations.formErrors.auth_url">
        {{ customValidations.formErrors.auth_url }}
      </div>
    </div>
    <div class="col-sm-3 col-xs-12 form-group">
      <label>API URL*</label>
      <input class="form-control" autocomplete="off" [(ngModel)]="credential.api_url"
             name="api_url"/>
      <div class="label label-danger" *ngIf="customValidations.formErrors.api_url">
        {{ customValidations.formErrors.api_url }}
      </div>
    </div>
    <div class="col-sm-3 col-xs-12 form-group">
      <label>Client ID*</label>
      <input class="form-control" autocomplete="off" [(ngModel)]="credential.client_id"
             name="client_id"/>
      <div class="label label-danger" *ngIf="customValidations.formErrors.client_id">
        {{ customValidations.formErrors.client_id }}
      </div>
    </div>
    <div class="col-sm-3 col-xs-12 form-group">
      <label>Client Secret*</label>
      <input class="form-control" [autocomplete]="true" [(ngModel)]="credential.client_secret"
             name="client_secret"/>
      <div class="label label-danger" *ngIf="customValidations.formErrors.client_secret">
        {{ customValidations.formErrors.client_secret }}
      </div>
    </div>
    <div class="col-sm-3 col-xs-12 form-group">
      <label>Username*</label>
      <input class="form-control" [autocomplete]="true" [(ngModel)]="credential.user_name" name="user_name"/>
      <div class="label label-danger" *ngIf="customValidations.formErrors.user_name">
        {{ customValidations.formErrors.username }}
      </div>
    </div>
    <div class="col-sm-3 col-xs-12 form-group">
      <label>Password*</label>
      <input type="password" class="form-control" [autocomplete]="true" [(ngModel)]="credential.password"
             name="password"/>
      <div class="label label-danger" *ngIf="customValidations.formErrors.password">
        {{ customValidations.formErrors.password }}
      </div>
    </div>
  </div>
  <div class="mt-xl" *ngIf="!credential.is_authenticated">
    <button class="btn btn-primary mr" type="submit" [disabled]="!credentialForm.form.valid">Authenticate</button>
    <div *ngIf="message && !credential.is_authenticated" class="p alert alert-danger text-center mt-lg">{{message}}</div>
    <div *ngIf="message && credential.is_authenticated" class="p alert alert-success text-center mt-lg">{{message}}
    </div>
  </div>
  <div *ngIf="credential.is_authenticated" class="p alert alert-success text-center mt-lg">
    This Practice is successfully authenticated with {{credential.pms_vendor}}.
  </div>
</form>

<h3>Practice Providers</h3>
<hr class="hr-line mt-lg">
<div class="todaysPatient-list" *ngFor="let doctor of doctors">
  <div class="list clearfix pl0">
    <div class="col-xs-5">
      <div class="media">
        <div class="media-left">
          <img class="img-rounded thumbnail thumb64" [src]="doctor.profile_pic.modal.img" alt="Image"/>
        </div>
        <div class="media-body">
          <h4 class="mb fw-500">{{doctor.complete_name}}</h4>
          <h5 class="m0">{{doctor.designation}}</h5>
        </div>
      </div>
    </div>
    <div class="col-xs-3 pt">
      <span class="pr-lg" *ngIf="doctor.client.contact_numbers[1] && doctor.client.contact_numbers[1].value">
        <em class="fa fa-fax"></em>&nbsp; {{doctor.client.contact_numbers[1].value}}
      </span>
      <span *ngIf="doctor.client.contact_numbers[0] && doctor.client.contact_numbers[0].value">
        <em class="fa icon-phone"></em>&nbsp; {{doctor.client.contact_numbers[0].value  | mask: ' (000) 000-0000'}}
      </span>
      <h5 class="mb0"><em class="fa icon-envelope vl-m"></em>&nbsp; {{doctor.client.email}}</h5>
    </div>
    <div class="col-xs-2 pt">
      <input type="text" class="form-control" id="integration_id" [(ngModel)]="doctor.third_party_doctor_id"
             name="third_party_doctor_id"
             placeholder="Third Party Doctor ID">
    </div>
    <div class="col-xs-2 pt text-right">
      <button class="btn btn-primary" (click)="saveDoctor(doctor)">Save</button>
    </div>
  </div>
</div>

<div class="row mt-xl">
  <div class="col-xs-8"><h3 class="mt0">Providers</h3></div>
  <div class="col-xs-4 text-right"><button class="btn btn-inverse" (click)="getProviders()">Get</button></div>
</div>
<hr class="hr-line mt-lg">
<table class="table table-bordered table-striped table-hover mb-xl">
  <thead *ngIf="providers">
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Phone</th>
      <th>Address</th>
      <th>NPI</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody *ngIf="providers">
    <tr *ngFor="let provider of providers">
      <td class="pl-xxl">{{provider.Id}}</td>
      <td>{{provider.FirstName}} {{provider.MiddleName}} {{provider.LastName}}</td>
      <td>{{provider.Phone}}</td>
      <td>{{provider.Address.Address1}} {{provider.Address.Address2}}, {{provider.Address.City}},
        {{provider.Address.State}}, {{provider.Address.ZipCode}}
      </td>
      <td>{{provider.NPI}}</td>
      <td *ngIf="!practice_provider_ids.includes(provider.Id.toString())">
        <button class="btn btn-primary" (click)="addProviderToProfile(provider)">Add to Profile</button>
      </td>
      <td *ngIf="practice_provider_ids.includes(provider.Id.toString())">Added to Profile</td>
    </tr>
  </tbody>
</table>

<h3>Practice Locations</h3>
<hr class="hr-line mt-lg">
<div class="todaysPatient-list" *ngFor="let location of practice_locations">
  <div class="list clearfix pl0" [class.dexpanded]="location.show_profile_detail">
    <div class="col-xs-5">
      <div class="media">
        <div class="media-body">
          <h4 class="mb mt0 fw-500">{{location.name}}</h4>
          <h5 class="m0">{{location.address.line_1_line_2()}}<br>{{location.address.city_state_zip()}}</h5>
        </div>
      </div>
    </div>
    <div class="col-xs-4 pt">
          <span class="pr-lg" *ngIf="location.fax && location.fax.value">
            <em class="fa fa-fax"></em>&nbsp; {{location.fax.value}}
          </span>
      <span *ngIf="location.phone && location.phone.value">
            <em class="fa icon-phone"></em>&nbsp; {{location.phone.value}}
          </span>
      <h5 class="mb0"><em class="fa icon-envelope vl-m"></em>&nbsp; {{location.email}}</h5>
    </div>
    <div class="col-xs-2 pt">
      <input type="text" class="form-control" id="location_id" [(ngModel)]="location.third_party_location_id" name="third_party_doctor_id"
             placeholder="Third Party Location ID">
    </div>
    <div class="col-xs-1 pt">
      <button class="btn btn-primary mr" (click)="saveLocation(location)">Save</button>
    </div>
  </div>
</div>


<div class="row mt-xl">
  <div class="col-xs-8"><h3 class="mt0">Locations</h3></div>
  <div class="col-xs-4 text-right"><button class="btn btn-inverse" (click)="getLocations()">Get</button></div>
</div>
<hr class="hr-line mt-lg">
<table class="table table-bordered table-striped table-hover mb-xl">
  <thead *ngIf="locations">
    <tr>
      <th>ID</th>
      <th>Name</th>
      <th>Phone</th>
      <th>Address</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody *ngIf="locations">
    <tr *ngFor="let location of locations">
      <td class="pr-xxl">{{location.Id}}</td>
      <td>{{location.Name}}</td>
      <td>{{location.Phone}}</td>
      <td>{{location.Address.Address1}} {{location.Address.Address2}}, {{location.Address.City}},
        {{location.Address.State}}, {{location.Address.ZipCode}}
      </td>
      <td *ngIf="!practice_location_ids.includes(location.Id.toString())">
        <button class="btn btn-primary mr" (click)="addLocationToProfile(location)">Add to Profile</button>
      </td>
      <td *ngIf="practice_location_ids.includes(location.Id.toString())">Added to Profile</td>
    </tr>
  </tbody>
</table>


<h3>Practice Locations</h3>
<hr class="hr-line mt-lg">
<div class="todaysPatient-list" *ngFor="let location of practice_resource_locations">
  <div class="list clearfix pl0" [class.dexpanded]="location.show_profile_detail">
    <div class="col-xs-5">
      <div class="media">
        <div class="media-body">
          <h4 class="mb mt0 fw-500">{{location.name}}</h4>
          <h5 class="m0">{{location.address.line_1_line_2()}}<br>{{location.address.city_state_zip()}}</h5>
        </div>
      </div>
    </div>
    <div class="col-xs-4 pt">
          <span class="pr-lg" *ngIf="location.fax && location.fax.value">
            <em class="fa fa-fax"></em>&nbsp; {{location.fax.value}}
          </span>
      <span *ngIf="location.phone && location.phone.value">
            <em class="fa icon-phone"></em>&nbsp; {{location.phone.value}}
          </span>
      <h5 class="mb0"><em class="fa icon-envelope vl-m"></em>&nbsp; {{location.email}}</h5>
    </div>
    <div class="col-xs-2 pt">
      <input type="text" class="form-control" id="resouce_id" [(ngModel)]="location.third_party_resource_id" name="third_party_resource_id"
             placeholder="Resource ID">
    </div>
    <div class="col-xs-1 pt">
      <button class="btn btn-primary mr" (click)="saveResourceLocation(location)">Save</button>
    </div>
  </div>
</div>


<div class="row mt-xl">
  <div class="col-xs-8"><h3 class="mt0">Resources</h3></div>
  <div class="col-xs-4 text-right"><button class="btn btn-inverse" (click)="getResources()">Get</button></div>
</div>
<hr class="hr-line mt-lg">
<table class="table table-bordered table-striped table-hover mb-xl">
  <thead *ngIf="resources">
    <tr>
      <th>ID</th>
      <th>Name</th>
    </tr>
  </thead>
  <tbody *ngIf="resources">
    <tr *ngFor="let resource of resources">
      <td class="pr-xxl">{{resource.Id}}</td>
      <td>{{resource.Name}}</td>
    </tr>
  </tbody>
</table>
