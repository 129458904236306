<ng-container *ngIf="!show_verification">
  <h2 class="fs22 mt-xl mb-lg">Sign Up</h2>
  <form class="mb" (ngSubmit)="save()" #SignUpForm="ngForm">
    <div class="row form-group">
      <div class="col-sm-4 col-xs-6">
        <label>Title*</label>
        <select name='title' class="form-control" [(ngModel)]="patient.title">
          <option value="Mr.">Mr.</option>
          <option value="Ms.">Ms.</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <label>First Name*</label>
        <input (click)="callme();" type="text" class="form-control" id="firstname" [(ngModel)]="patient.user.first_name"
               name="firstname" pattern="^[a-zA-Z ]+$" maxlength="20" required>
        <div *ngIf="customValidations.formErrors.firstname" style="color: red">
          {{ customValidations.formErrors.firstname }}
        </div>
      </div>
      <div class="col-sm-6 col-xs-12 form-group">
        <label>Last Name*</label>
        <input type="text" class="form-control" id="lastname" [(ngModel)]="patient.user.last_name" name="lastname"
               pattern="^[a-zA-Z ]+$" maxlength="20" required>
        <div *ngIf="customValidations.formErrors.lastname" style="color: red">
          {{ customValidations.formErrors.lastname }}
        </div>
      </div>
    </div>
    <div class="clearfix signup-dob">
      <app-date-of-birth [dob]="patient.user.dob" [dob_stage]="patient.user.dob_stage" (dob_and_stage)="patient.handle_dob_and_dob_stage($event)"></app-date-of-birth>
      <div *ngIf="patient.year_error" class="text-danger">Invalid Date of Birth</div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <label>Gender*</label>
        <select name="gender" [(ngModel)]="patient.user.gender" class="form-control" id="gender">
          <option value="Gender" [value]="'Gender'">Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        <div *ngIf="gender_error" class="text-danger">Select Gender</div>
      </div>
      <div class="col-sm-6 col-xs-12 form-group">
        <label>Mother's Maiden Name *</label>
        <input type="text" class="form-control" id="mother_name" [(ngModel)]="patient.mother_name"
               name="mother_name" maxlength="15" pattern="[^\s]+"
               required>
        <div *ngIf="customValidations.formErrors.mother_name" class = "text-danger">
          {{ customValidations.formErrors.mother_name }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <label>Email Address*</label>
        <input type="text" class="form-control" id="email" [(ngModel)]="patient.user.email" name="email"
               pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" required>
        <div class="text-danger" *ngIf="customValidations.formErrors.email">{{ customValidations.formErrors.email }}</div>
      </div>
      <div class="col-sm-6 col-xs-12 form-group">
        <label>Mobile Number*</label>
        <input type="text" class="form-control" [(ngModel)]="patient.user.phone.value" mask="(000) 000-0000"  [autocomplete]="'off'" name="phone" required>
        <div class="text-danger" *ngIf="customValidations.formErrors.phone">{{ customValidations.formErrors.phone }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <label>Password*</label>
        <input id="password" type="password" class="form-control"
               [(ngModel)]="patient.user.password" name="password" maxlength="15" minlength="6" required>
      </div>
      <div class="col-sm-6 col-xs-12 form-group">
        <label>Confirm Password*</label>
        <input id="password_confirmation" type="password" class="form-control" [(ngModel)]="patient.user.password_confirmation" name="password_confirmation" >
      </div>
    </div>
    <span *ngIf="messageAfterSave" class="text-center">
        <strong class="text-success">{{patient.message}}</strong>
    </span>
    <div *ngIf="show_error">
      <strong class="pl-lg text-danger">Password Mismatch Try Again</strong>
    </div>
    <hr class="hr-line">
    <div class="mt-xl">
      <button type="button" class="btn btn-primary btn-lg fs20" [disabled]="!SignUpForm.form.valid" (click)="save()">Sign Up</button>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="show_verification">
  <app-signup-presetup [patient]="patient" (verified)="handle_patient_verification($event)"></app-signup-presetup>
</ng-container>
