export class MedicalCenter {
  id: number;
  name: string;
  center_type = 'pharmacy';
  address: string;
  city: string;
  state: any;
  zip: string;
  phone: string;
  fax: string;
  email: string;
  patient_id: number;
  created_at: Date;
  updated_at: Date;
  constructor() {
  }
  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.center_type = json.center_type;
    this.address = json.address;
    this.email = json.email;
    this.city = json.city;
    this.state = json.state;
    this.phone = json.phone;
    this.fax = json.fax;
    this.zip = json.zip;
    this.patient_id = json.patient_id;
    this.updated_at = json.updated_at;
    this.created_at = json.created_at;
    return this;
  }
  to_params() {
    return {
      medical_center: {
        id: this.id,
        name: this.name,
        center_type: this.center_type,
        address: this.address,
        email: this.email,
        city: this.city,
        state: this.state,
        phone: this.phone,
        fax: this.fax,
        zip: this.zip,
        patient_id: this.patient_id
      }
    }
  }

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  get_url(): string {
    return this.is_new_record() ? 'medical_centers/' : ['medical_centers', this.id].join('/')
  }
}
