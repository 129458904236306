<ng-container *ngIf="!edit_task_model">
  <form class="m0" (ngSubmit)="save()" #taskForm="ngForm">


    <div class="row form-group">
      <label class="col-sm-3 control-label field-label">Name*</label>
      <div class="col-sm-9">
        <textarea class="form-control no-resize" id="task_name" [(ngModel)]="task.task_name" name="task_name" [disabled]="readOnlyForDelegateAssignee()" required></textarea>
        <div *ngIf="customValidations.formErrors.task_name" class="text-danger">
          {{ customValidations.formErrors.task_name }}
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-3 control-label field-label">Description</label>
      <div class="col-sm-9">
      <textarea  cols="120" rows="6" type="text" class="form-control" id="task_description"
                 [(ngModel)]="task.task_description"
                 name="task_description" [disabled]="readOnlyForDelegateAssignee()">
      </textarea>
      </div>
    </div>
    <div class="row form-group" *ngIf="c_user && c_user.isDoctor">
      <label class="col-sm-3 control-label field-label">Assigned To*</label>
      <div class="col-sm-9">
        <select  name="assigned_to" placeholder="Assigned To" #assigned_to class="test form-control p0"
                 (change)='selectAssignedTo(assigned_to)' [disabled]="readOnlyForDelegateAssignee()" required>
          <ng-container *ngFor="let practice of practices">
            <option disabled>{{practice}}</option>
            <option *ngIf="c_user.id == task.assigned_to">{{c_user.full_name}} (Doctor)</option>
            <option [selected]="staff.user.id == task.assigned_to" *ngFor="let staff of practice_staff(practice); let i = index;" [value]="staff.user.id">
              {{staff.user.full_name}} ({{staff.user.role_title}})
            </option>
          </ng-container>
        </select>
        <div class="text-danger" *ngIf="customValidations.formErrors.assigned_to">{{ customValidations.formErrors.assigned_to }}</div>
      </div>
    </div>
    <div class="row form-group" *ngIf="c_user && !c_user.isDoctor">
      <label class="col-sm-3 control-label field-label">Assigned To{{isAssignedToRequired ? '*' : ''}}</label>
      <div class="col-sm-9">
        <select  name="assigned_to" placeholder="Assigned To" #assigned_to class="test form-control p0"
                 (change)='selectAssignedTo(assigned_to)' [disabled]="readOnlyForDelegateAssignee()"
                 [required]="isAssignedToRequired" >
          <option *ngIf="!isAssignedToRequired" value="" [selected]="!task.assigned_to">Select User</option>
          <option [selected]="user.id == task.assigned_to" *ngFor="let user of practice_users; let i = index;" [value]="user.id">
            {{user.full_name}} ({{user.role_title}})
          </option>
        </select>
        <p class="text-danger" *ngIf="customValidations.formErrors.assigned_to">{{ customValidations.formErrors.assigned_to }}</p>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-3 control-label field-label">Associate Patient</label>
      <div class="col-sm-7 col-xs-9">
        <ng-container *ngIf="add_patient_button">
        <input  type="text" class="form-control" id="patient" [(ngModel)]="task.patient.name"
                [typeahead]="patients" typeaheadWaitMs="500"
                (typeaheadOnSelect)="typeaheadOnSelect($event)"
                [typeaheadOptionsLimit]="7" [typeaheadAsync]="true"
                [typeaheadOptionField]="'name'"
                name="patient"
                autocomplete="off"
                placeholder="Patient"
                [disabled]="readOnlyForDelegateAssignee()">
          </ng-container>

            <ng-container *ngIf="!add_patient_button">
          <input type="text" class="form-control" id="disable_patient" [(ngModel)]="task.patient.name"
           name="disable_patient"
           [disabled]= "true">
            </ng-container>
      </div>
      <div class="col-sm-2 col-xs-3 text-right" >
        <button *ngIf="add_patient_button " type="button" class="btn btn-secondary btn-outline" (click)="addPatientModal = true">Add</button>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-3 control-label field-label">Priority*</label>
      <div class="col-sm-9">
        <select  #priority name="priority" [(ngModel)]="task.priority"
                 (change)="selectPriority(priority)"
                 class="pl0 form-control" [disabled]="readOnlyForDelegateAssignee()" required>
          <option *ngFor="let priority of priorities; let i = index;" [value]="priority">
            {{priority}}
          </option>
        </select>
        <div *ngIf="customValidations.formErrors.priority" class="text-danger">
          {{ customValidations.formErrors.priority }}
        </div>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-sm-3 control-label field-label">Due Date*</label>
      <div class="col-sm-4 col-xs-9">
        <input  name="due_date" autocomplete="off"
                placeholder="Due Date"
                class="form-control"
                [(ngModel)]="task.due_date"
                (ngModelChange)="setDueDateAsNotifyOn(task.due_date)"
                [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false, adaptivePosition:true}"
                bsDatepicker
                required
                [disabled]="readOnlyForDelegateAssignee()"/>
        <div *ngIf="customValidations.formErrors.due_date" class="text-danger">
          {{ customValidations.formErrors.due_date }}
        </div>
      </div>
      <div class="col-sm-2 col-xs-3">
        <label class="field-label control-label">
          <input class="m0" type="checkbox" name="notify" [value]=true [(ngModel)]="task.notify" [disabled]="readOnlyForDelegateAssignee()">
          &nbsp;Notify
        </label>
      </div>
    </div>
    <div class="row form-group" *ngIf="task.notify">
      <label class="col-sm-3 col-xs-12 control-label field-label">Notify on*</label>
      <div class="col-sm-4 col-xs-7">
        <input  name="notify_date" autocomplete="off"
                placeholder="Notify Date"
                type="text"
                class="form-control"
                [(ngModel)]="task.notify_date"
                [bsConfig]="{customTodayClass: 'today-date-select', showWeekNumbers:false, adaptivePosition:true}"
                bsDatepicker required
                [disabled]="readOnlyForDelegateAssignee()"/>
      </div>
      <div class="col-sm-5 col-xs-5">
        <input type="time" autocomplete="off" name="notify_time" placeholder="Notify Time" class="form-control"
               [(ngModel)]="task.notify_time" [disabled]="readOnlyForDelegateAssignee()" required>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-3 control-label field-label">Status</label>
      <div class="col-sm-9">
        <select #status class="form-control pl0" name="status_id" [(ngModel)]="task.status_id">
          <option *ngFor="let status of statuses; let i = index;" [ngValue]="status">
            {{status}}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-xl">
      <div class="col-sm-3"></div>
      <div class="col-sm-9">
        <a *ngIf="loaderService.uploading_in_process" class="btn btn-default mr"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
        <button type="submit" class="btn btn-secondary btn-min mr" [disabled]="!taskForm.form.valid" *ngIf="!loaderService.uploading_in_process">{{task.id ? 'Update' : 'Create'}}</button>
        <button type="button" name="cancel" class="btn btn-tertiary btn-outline ml" (click)="closeNewForm()">Cancel</button>
      </div>
    </div>
    <div class="row" *ngIf="globalErrorHandlerService.error">
      <div class="mt-lg col-sm-12 text-center text-danger">{{globalErrorHandlerService.error}}</div>
    </div>
  </form>
</ng-container>
<div class="addPatientModal fade" [ngClass]="addPatientModal ? 'show in' : '' " *ngIf="addPatientModal">
  <div class="modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close_modal()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Add Patient</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-9">
            <app-demographics *ngIf="show_patient_form" [show_extras]="true" [patient]="patient" (new_user)="handle_user($event);close_modal();"></app-demographics>
          </div>
          <div class="col-sm-3">
            <div class="user-profile-pic border-box shadow text-center">
              <a class="icon-holder" (click)="show_attachment_popup= true">
                <i class="fa fa-upload fa-2x"></i>
                <h5>Upload Your Picture</h5>
              </a>
              <img *ngIf="patient.user && patient.user.profile_pic" class="img-rounded img-responsive"
                   [src]="patient.user.profile_pic" alt="Image"/>
            </div>
            <app-attachments (document_uploaded)="handle_image_upload($event)"
                             (popup_closed)="show_attachment_popup= false" [fileType]="'pchf_files'"
                             [only_modal]="true" [attachable_id]="patient.id" [attachable_type]="'Patient'"
                             [show]="show_attachment_popup" [auto_upload]="true"></app-attachments>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #editTaskModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog model-" style="height:680px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="editTaskModal.hide(); edit_task_model = false;"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Task</h4>
      </div>
      <div class="modal-body program-task p0" *ngIf="edit_task_model">
        <ng-container *ngComponentOutlet="editTaskComponent; injector: custom_injector;"></ng-container>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
