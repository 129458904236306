<ng-container>
  <span contenteditable="false">
    <span [ngClass]="term_control.control.is_focus ? 'bg-gray' : '' " contenteditable="false">
      <span contenteditable="false">
        <span contenteditable="true" [tooltip]="term_control.control.value_name"
              [class]="term_control.control.control_class" (keydown.delete)="onBackSpaceDown(term_control)"
              (keydown)="stopEditing($event)" (keydown.backspace)="onBackSpaceDown(term_control)"
              [id]="term_control.control.id+ '' + term.library_datum_id"
              (focus)="handleCommaClick(term,term_control.control);"
              (click)="handleCommaClick(term,term_control.control);openModal(template);"
              [ngClass]="'control-'+term_control.control.id">{{term_control.control ? term_control.control.name : ''}}</span>
      </span>
    </span>
  </span>
</ng-container>
<ng-template #template>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <i class="fa fa-times fs16"></i>
    </button>
    <h4 class="modal-title">Term: {{term.name}}</h4>
  </div>
  <div class="modal-body">
    <div class="row form-group">
      <div class="col-xs-4"><label class="field-label control-label">Default Control</label></div>
      <div class="col-xs-8">
        <select class="form-control" (change)="onChange($event.target.value)">
          <ng-container *ngFor="let d_control of _enote_service.default_controls">
            <option [selected]="d_control.id == term_control.control_id" [value]="d_control.id">
              {{d_control.name}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-xs-4"><label class="field-label control-label">{{normal_term_control_value_property.value.name}}</label></div>
      <div class="col-xs-4">
        <input #typeahead [(ngModel)]="normal_term_control_value_property.prop_value"
               typeaheadWaitMs="800"
               [typeahead]="first_data_source"
               [typeaheadAsync]="true"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               autocomplete="off"
               (typeaheadNoResults)="changeTypeaheadNoResults($event)"
               (typeaheadOnSelect)="typeaheadOnFirstSelect($event,normal_term_control_value_property)"
               [typeaheadOptionsLimit]="20"
               [disabled]="term.term_type == 'keyword'"
               [typeaheadOptionField]="'sname'"
               class="form-control">
      </div>
      <div class="col-xs-4">
        <label class="pt-sm fs12">
          <input type="checkbox" [(ngModel)]="normal_term_control_value_property.enable_custom_control">
          &nbsp;Enable Custom Control
        </label>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-xs-4"><label class="field-label control-label">{{abnormal_term_control_value_property.value.name}}</label></div>
      <div class="col-xs-4">
        <input #typeahead [(ngModel)]="abnormal_term_control_value_property.prop_value"
               typeaheadWaitMs="800"
               [typeahead]="second_data_source"
               [typeaheadAsync]="true"
               (typeaheadLoading)="changeTypeaheadLoading($event)"
               autocomplete="off"
               (typeaheadNoResults)="changeTypeaheadNoResults($event)"
               (typeaheadOnSelect)="typeaheadOnSecondSelect($event,abnormal_term_control_value_property)"
               [typeaheadOptionsLimit]="20"
               [typeaheadOptionField]="'sname'"
               [disabled]="term.term_type == 'keyword'"
               class="form-control">
      </div>
      <div class="col-xs-4">
        <label class="pt-sm fs12">
          <input type="checkbox" [(ngModel)]="abnormal_term_control_value_property.enable_custom_control">
          &nbsp;Enable Custom Control
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="save(template)" class="btn btn-secondary">Save</button>
    <button (click)="cancel(template)" class="btn btn-tertiary btn-outline">Cancel</button>
  </div>
</ng-template>
