import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Patient} from '../../../doctor/patient';
import {PatientEvent} from '../patient-event';
import {UserService} from '../../../../services/user.service';
import {PatientEventService} from '../patient-event.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {Tab} from '../../../../tabs/tab';
import {TabsService} from '../../../../services/tabs.service';
import {HealthCalendarReportPrinterComponent} from '../health-calendar-report-printer/health-calendar-report-printer.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-health-calendar-report',
  templateUrl: './health-calendar-report.component.html',
  styleUrls: ['./health-calendar-report.component.scss']
})
export class HealthCalendarReportComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;
  public filter: Object = {type: '', date_type: 'last_7_days', from: '', to: '', patient_id: ''};
  public totalItems;
  public  patientEvents: PatientEvent[] = [];
  @Output() printReport = new EventEmitter<boolean>();
  public durations: Array<string> = ['last_7_days', 'current_month', 'last_month', 'last_3_months', 'last_6_months', 'last_12_months', 'this_year', 'last_year', 'custom'];
  public isOpen: boolean;
  dateRangeValue: Date[];
  @ViewChild('dp') dp: any;
  public stats: any;
  public all_time_stats: any;
  public custom_injector: Injector;
  public showReport: boolean;
  public healthCalendarReportPrinterComponent = HealthCalendarReportPrinterComponent;
  public closeReportSubscription: Subscription;

  constructor(public userService: UserService, public patientEventService: PatientEventService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public tabService: TabsService,
              public injector: Injector) {
    this.closeReportSubscription = this.patientEventService.showReportListener.subscribe( resp => {
        this.showReport = resp;
    })
  }

  ngOnInit(): void {
    this.filter['patient_id'] = this.patient.id;
    this.getPatientEvents();
  }
  ngOnDestroy(): void {
    this.showReport = false;
    this.closeReportSubscription.unsubscribe();
  }

  search() {
    this.getPatientEvents()
  }

  getPatientEvents() {
    this.loaderService.show();
    this.patientEventService.getPatientEventReport(this.filter).then(resp => {
      this.patientEvents = resp.events.map(event => new PatientEvent().load_from_json(event));
      this.stats = resp;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  openReport() {
    this.userService.current_user.isPatient ? this.setReportCustomInjector() : this.addReportToTab();
  }

  addReportToTab() {
    this.tabService.add_tab(new Tab({
      title: 'Report',
      icon: 'fas fa-print'
    }, HealthCalendarReportPrinterComponent, this.patient.id, {stats: this.stats, patientName: this.patient.name,
      patientEvents: this.patientEvents, filter: this.filter}))
  }

  setReportCustomInjector() {
    this.custom_injector  = Injector.create([
      {provide: 'stats', useValue: this.stats},
      {provide: 'patientName', useValue:  this.patient.name},
      {provide: 'filter', useValue:  this.filter},
    ], this.injector);
    this.showReport = true;
  }

  selectDuration(time) {
    if (time.value === 'custom') {
      this.isOpen = true;
      setTimeout( () => {
        this.dp.show();
      }, 150);
    }
    else {
      this.isOpen = false;
      delete this.filter['to'];
      delete this.filter['from'];
    }
    this.filter['date_type'] = time.value;
  }

  setDateRange(event: Date[]) {
    if (event) {
      this.filter['from'] = String(event[0]);
      this.filter['to'] = String(event[1]);
    }
  }


}
