import {Component, Input, OnInit} from '@angular/core';
import {CampaignService} from "../../campaign.service";
import {GlobalErrorHandlerService} from "../../../services/global-error.service";
import {LoaderService} from "../../../services/loader.service";
import {Campaign} from "../../campaign";
interface Recipient {
  email? : string;
  name? : string;
  contact_type? : string;
  contact_id? : number;
  dob? : string;
  status? : string;
  gender? : string;
  patient_id? : number;
  registered_on? : string;
  web_access? : string;
  location? : string;
  appointments? : number;
  sent_on? : string;
  count? : number;
  phone_number? : string;
  designation? : string;
  role? : string;
  user_name? : string;
  patient_type? : string;
}

interface ClickedLink {
  link :string;
  total_clicks : number;
  uniq_clicks : number;
}

@Component({
  selector: 'app-email-campaign-recipients-analytics',
  templateUrl: './email-campaign-recipients-analytics.component.html',
  styleUrls: ['./email-campaign-recipients-analytics.component.scss']
})
export class EmailCampaignRecipientsAnalyticsComponent implements OnInit {
  @Input() recipients;
  @Input() campaign;
  public totalItems;
  public recipientsList: Recipient[] = [];
  public clickedLinks: ClickedLink[] = [];
  public showLinksTable : boolean  = false;
  public filter: Object = {keyword: undefined, page: 1, status: undefined,link: undefined};
  constructor(public campaignService : CampaignService,public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
  }
  campaignRecipients(campaign_id,status,link?){
    this.recipientsList = [];
    delete this.filter['keyword'];
    link ? this.filter['link'] = link : delete this.filter['link'];
    this.filter['status'] = status;
    this.loaderService.show();
    this.showLinksTable = false;
    this.campaignService.campaignRecipientsList(campaign_id,this.filter)
      .then(resp => {
        this.recipientsList = resp.recipientsList;
        this.totalItems = resp.total;
        this.filter['page'] = resp.current_page;
        this.loaderService.hide();
      }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  clickedData(campaign_id,status){
    this.loaderService.show();
    this.filter['status'] = status;
    this.showLinksTable = true;
    this.clickedLinks = [];
    this.campaignService.campaignClickedData(campaign_id).then(resp => {
      this.clickedLinks = resp
      this.loaderService.hide()
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
}
