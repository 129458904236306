export class Invoice {
  id: number;
  invoice_number: any;
  amount: number = 0;
  practice_package_id: number;
  bill_date: any = new Date();
  due_date: any = new Date();
  created_at: any = new Date();
  generator_id: number;
  invoice_for_id: number;
  discount: number = 0;
  status: 'unpaid' | 'paid' = 'unpaid';
  invoice_items: InvoiceItem[] = [new InvoiceItem()];
  paying: boolean = false;
  payer_name: string;
  practice_name: string;
  practice_address: string;
  practice_phone: string;
  practice_email: string;
  constructor() {
    this.invoice_number = Math.round(Math.random() * 10000000);
  }

  load_from_json(json) {
    this.payer_name = json.payer_name;
    this.practice_name = json.practice_name;
    this.practice_address = json.practice_address;
    this.practice_phone = json.practice_phone;
    this.created_at = new Date(json.created_at);
    this.id = json.id;
    this.status = json.status;
    this.invoice_number = json.invoice_number;
    this.amount = json.amount;
    this.bill_date = new Date(json.bill_date);
    this.due_date = new Date(json.due_date);
    this.practice_package_id = json.practice_package_id;
    this.generator_id = json.generator_id;
    this.discount = json.discount;
    this.practice_email = json.practice_email;
    if (json.invoice_items)
      this.invoice_items = json.invoice_items.map(invoice_item => new InvoiceItem().load_from_json(invoice_item));
    this.invoice_for_id = json.invoice_for_id;
    return this;
  }

  to_params() {
    return {
      id: this.id,
      invoice_number: this.invoice_number,
      amount: this.amount,
      practice_package_id: this.practice_package_id,
      bill_date: this.bill_date,
      due_date: this.due_date,
      generator_id: this.generator_id,
      invoice_for_id: this.invoice_for_id,
      discount: this.discount,
      invoice_items_attributes: this.invoice_items.map(a => a.to_params())
    }
  }

  get itemsTotalAmount() {
    return this.invoice_items.reduce((a, b) => +a + +b.amount, 0);
  }

  get filtered_invoice_items() {
    return this.invoice_items.filter(a => !a._destroy);
  }

  get_url() {
    return this.is_new_record() ? "invoices" : ["invoices", this.id].join('/')
  }

  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }


  addItem() {
    this.invoice_items.push(new InvoiceItem());
  }

  removeItem(item) {
    if (item.amount > 0) {
      this.amount = this.amount - item.amount;
    }
    if (item.id) {
      item._destroy = true;
    } else {
      let index = this.invoice_items.indexOf(item);
      this.invoice_items.splice(index, 1);
    }
  }

  discountCalc() {
    this.amount = this.itemsTotal - this.discount;
  }

  get itemsTotal() {
    return this.invoice_items.reduce(function (total, item) {
      if (!item._destroy) {
        return total + item.amount;
      }
      return total;
    }, 0);
  }

}

export class InvoiceItem {
  id: number;
  invoice_id: number;
  description: string;
  rate: number;
  quantity: number;
  amount: number;
  _destroy: boolean = false;

  load_from_json(json) {
    this.id = json.id;
    this.invoice_id = json.invoice_id;
    this.description = json.description;
    this.rate = json.rate;
    this.quantity = json.quantity;
    this.amount = json.amount;
    return this;
  }

  to_params() {
    return {
      id: this.id,
      invoice_id: this.invoice_id,
      description: this.description,
      rate: this.rate,
      _destroy: this._destroy,
      quantity: this.quantity,
      amount: this.amount
    }
  }

  calculateAmount(invoice: Invoice) {
    if (this.rate && this.quantity)
      this.amount = this.rate * this.quantity;
    else
      this.amount = 0;
    invoice.amount = invoice.itemsTotal;
    invoice.discountCalc();
  }
}
