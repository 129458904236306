import { Component, OnInit } from '@angular/core';
import {VisitsService} from '../../../../services/visits.service';
import {TabsService} from '../../../../services/tabs.service';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {Tab} from '../../../../tabs/tab';
import {PaymentComponent} from '../../payment/payment.component';
@Component({
  selector: 'app-visits-listing',
  templateUrl: './visits-listing.component.html',
  styleUrls: ['./visits-listing.component.scss']
})
export class VisitsListingComponent implements OnInit {
  status_mapper = {noshow: 'No Show', checkedin : 'Checked In', checkedout : 'Checked Out', cancelled: 'Cancelled',
    scheduled : 'Scheduled', pending: 'Pending', confirmed : 'Confirmed', rescheduled: 'Rescheduled' , admitted : 'Admitted'}
  appointment_type_mapper = {office_visit: 'Office Visit' }

  constructor(public _visitService: VisitsService, public _tabs_service: TabsService) { }
  ngOnInit() {
  }

  sorting(sorting_by) {
    this._visitService.applySortingKeys(sorting_by);
    this._visitService.applyFilters();
  }

  pageChanged(page) {
    this._visitService.resetClientSideKeys();
    this._visitService.current_page = page.page;
    this._visitService.applyFilters();
  }

  visitsKeys() {
    if (this._visitService.keys_array && this._visitService.keys_array.length > 0) {
      return this._visitService.keys_array;
    }
  }

  noVisitsFound() {
    if (this._visitService.keys_array && this._visitService.keys_array.length < 1) {
      return true;
    }
  }

  add_to_tabs(visit) {
    let title = visit.patient_name + '-' + 'Profile';
    let id = visit.patient_id;
    this._tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id, { id: id, appointment: visit}))
  }

  add_payment_to_tabs(title, visit) {
    let appointment_id = visit.id;
    this._tabs_service.add_tab(new Tab({title: visit.patient_name + '-' + title, icon: 'fa fa-hospital', autorefresh: false}, PaymentComponent, appointment_id, {id: appointment_id, callFromVisit: true, paymentFor: 'Appointment', status: visit.patient_name + '-' + title}))
  }
  removeUnderScore(str : string) {
    if(str) {
      var i, frags = str.split('_');
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }
  }
}
