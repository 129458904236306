
<div class="row form-group">
  <div class="col-md-2 col-sm-6">
    <h4 class="fs16">Consents
      <a class="fs16 p" (click)="createConsent()" tooltip="Add Consent"><i
        class="fa fa-plus fs12"></i> Add</a>
    </h4>
  </div>
<!--  <div class="col-md-8 col-sm-6">-->
<!--    <button class="btn btn-secondary" (click)="createConsent()" title="Add Consent"><i class="fa fa-plus"></i> Add</button>-->
<!--  </div>-->
</div>
<ng-container>
  <div class="row form-group" >
    <table class="table table-hover m0">
      <thead>
      <tr>
        <th>Date</th>
        <th>Program</th>
        <th>Consent</th>
        <th>Created By</th>
        <th>Updated By</th>
        <th>Updated on</th>
        <th></th>
      </tr>
      </thead>
      <tbody *ngIf="consents.length > 0">
      <ng-container *ngFor="let consent of consents">
        <tr>
          <td>{{consent.created_at | date: 'short'}}</td>
          <td class="text-uppercase">{{userService.removeUnderScore(consent.programme_type) | uppercase}}</td>
          <td>
            <span class="media-body media-middle m0" [innerHTML]="truncateText(consent.consent_text, 100)"></span>
          </td>
          <td>{{consent?.created_by}}</td>
          <td>{{consent.updated_by}}</td>
          <td>{{consent.updated_at | date: 'short'}}</td>
          <td>
            <a class="btn btn-secondary btn-outline btn-sm mr" tooltip="Edit" (click)="editConsent(consent)" >
              <i class="fa fa-pencil"></i>
            </a>
            <a class="btn btn-danger btn-outline btn-sm"  tooltip="Delete" (click)="selectedConsentToDelete = consent;deleteConsentModal.show();">
              <i class="fa fa-trash"></i>
            </a>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <app-no-result-found *ngIf="consents && consents.length == 0"></app-no-result-found>
  </div>
</ng-container>


<div class="modal fade" bsModal #newConsentModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="consent modal-dialog" style="height:608px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newConsentModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Create Consent</h4>
      </div>
      <div class="modal-body">
        <ng-container>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3"><label >Program*</label></div>


            <ng-container *ngIf="programme_category == 'private_programme'">
              <div class="col-md-6 col-sm-5">
                <label class="fw-500 mr-lg">
                  <input type="radio" class="ml" name="private_pay" value="private_pay" [(ngModel)]="consent.programme_type" />
                  Private Pay
                </label>
              </div>

            </ng-container>
            <ng-container *ngIf="programme_category == 'cms_programme'">
              <div class="col-md-6 col-sm-5">
                <label class="fw-500 mr-lg">
                  <input type="radio" class="ml" name="ccm" value="ccm" [(ngModel)]="consent.programme_type" />
                  CCM
                </label>
                <label class="fw-500 mr-lg">
                  <input type="radio" class="ml" name="pcm" value="pcm" [(ngModel)]="consent.programme_type" />
                  PCM
                </label>
                <label class="fw-500 mr-lg">
                  <input type="radio" class="ml" name="rpm" value="rpm" [(ngModel)]="consent.programme_type" />
                  RPM
                </label>
                <label class="fw-500 mr-lg">
                  <input type="radio" class="ml" name="bhi" value="bhi" [(ngModel)]="consent.programme_type" />
                  BHI
                </label>
                <label class="fw-500 mr-lg">
                  <input type="radio" class="ml" name="tcm" value="tcm" [(ngModel)]="consent.programme_type" />
                  TCM
                </label>
              </div>
            </ng-container>

          </div>

          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label>Consent*</label>
            </div>
            <div class="col-md-10 col-sm-5">
              <editor #consentEditor apiKey="no-api-key" id="consent" name="consent_text" [(ngModel)]="consent.consent_text"
                      [init]="init_tiny" required="">
              </editor>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-10 modal-footer">
              <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
              <button name="submitConsent" class="btn btn-secondary btn-min mr" (click)="saveConsent()" [disabled]="!(consent.consent_text && consent.programme_type)" *ngIf="!loaderService.uploading_in_process">{{consent.id ? 'Update' : 'Create'}}</button>
              <button class="btn btn-tertiary btn-outline btn-min" (click)="closeNewForm()">Cancel</button>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #deleteConsentModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteConsentModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Delete Consent</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to delete the Consent?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteConsent(selectedConsentToDelete);deleteConsentModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteConsentModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
