import { Component, OnInit , Output, EventEmitter} from '@angular/core';
import  {Tab} from './tab'
import  {TabsService} from '../services/tabs.service'

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  tabs: Tab[];
  @Output() tab_opened: EventEmitter<Tab> = new EventEmitter<Tab>();
  @Output() tab_closed: EventEmitter<Tab> = new EventEmitter<Tab>();
  @Output() tabs_cleared: EventEmitter<Tab> = new EventEmitter<Tab>();

  constructor(private tabs_service: TabsService) {
  }

  ngOnInit() {
    this.tabs = this.tabs_service.tabs;
  }

  open_tab(tab) {
    this.tabs_service.open_tab(tab);
    this.tab_opened.emit(tab);
  }

  close_tab(tab) {
    let index = this.tabs_service.tab_index(tab);
    this.tabs_service.close_tab(tab);
    this.tab_closed.emit(tab);
    if (this.tabs.length == 0) {
      this.tabs_cleared.emit(tab);
    }
    if (tab.active) {
      let new_active_tab = this.tabs_service.after_close(index)
      if (new_active_tab) {
        this.tab_opened.emit(new_active_tab);
      }
    }
  }

  active_tab() {
   return this.tabs_service.active_tab();
  }

  open_next_tab(tab) {

  }

}
