import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Calendar } from '../../../practices/doctors/doctor'

@Component({
  selector: 'app-calendars-listing',
  templateUrl: './calendars-listing.component.html',
  styleUrls: ['./calendars-listing.component.scss']
})
export class CalendarsListingComponent implements OnInit {
  @Input() permissions: string
  @Input() calendars: Calendar[];
  @Input() calendar_locations: any;
  @Input() clients_service: any;
  public show_calendar_form = false;
  public show_calendar_listing = true;
  public calendar: Calendar = new Calendar();

  constructor() { }

  ngOnChanges() {
  }

  ngOnInit() {
  }

  location_name(calendar) {
    return calendar.calendar_locations[0].name;
  }

  appoitment_days_schedule(calendar) {

    let appointment_days_for_calendar = null;
    let a = calendar.week_days_mapping;
    for (let i = 0, len = 7 ; i < len; i++) {
      let day = calendar.week_days_mapping[i];
      appointment_days_for_calendar = calendar.appoitment_days(day);
    }
    calendar.final_appointment_days = [];
    return appointment_days_for_calendar;
  }

  edit_calendar(calendar) {
    this.show_calendar_form = true;
    this.show_calendar_listing = false;
    this.calendar = calendar;
  }

  handle_hide_form(calendar) {
    this.show_calendar_form = false;
    this.show_calendar_listing = true;
  }

  handle_deactivated_calendar(value) {
    this.calendar.is_active = value;
  }

  calendar_saved() {
    this.show_calendar_form = false;
    this.show_calendar_listing = true;
  }


}
