import { Component, OnInit } from '@angular/core';
import {PublicServiceService} from '../public-service.service';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss']
})
export class SearchButtonComponent implements OnInit {

  constructor(public publicService: PublicServiceService) { }

  ngOnInit(): void {
  }

}
