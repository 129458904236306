export class Device {
  id: number;
  name: string;
  d_id: string;
  d_model: string;
  d_type: string;
  mac_address: string;
  language = '';
  status = 'allocated';
  trainer_id: string;
  trainer_name: string;
  comments: string;
  training_status = false;
  patient_id: number;
  device_assigning_time: string;
  current_date_time: string = new Date((Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()
    .substring(0, new Date((Date.now() - (new Date()).getTimezoneOffset() * 60000))
      .toISOString().length - 1).replace(/\.\d+/, '');
  config_date: string = this.current_date_time;
  training_date: string;
  config_at_datetime: string;
  training_datetime: string;
  uniq_identifier: any;
  device_info_id: any;
  trained = false;

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  to_params() {
    return {
      device: {
        id: this.id,
        d_id: this.d_id,
        name: this.name,
        d_model: this.d_model,
        mac_address: this.mac_address,
        language: this.language,
        status: this.status,
        trainer_id: this.trainer_id,
        comments: this.comments,
        training_status: this.training_status,
        patient_id: this.patient_id,
        config_date: this.config_date,
        trainer_name: this.trainer_name,
        training_date: this.training_date,
        device_info_id: this.device_info_id,
        uniq_identifier: this.uniq_identifier,
        d_type: this.d_type
      }
    }
  }

  load_from_json(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.d_id = json.d_id;
    this.d_model = json.d_model;
    this.mac_address = json.mac_address;
    this.language = json.language;
    this.status = json.status;
    this.trainer_id = json.trainer_id;
    this.trainer_name = json.trainer_name;
    this.comments = json.comments;
    this.training_status = json.training_status;
    this.trained = json.training_status;
    this.patient_id = json.patient_id;
    this.device_assigning_time = json.device_assigning_time;
    this.config_at_datetime = json.config_at_datetime;
    this.training_datetime = json.training_datetime;
    this.uniq_identifier = json.uniq_identifier;
    this.device_info_id = json.device_info_id;
    this.d_type = json.d_type;
    if (json.config_date) {
      this.config_date = this.reading_date_format(json.config_date);
    }
    if (json.training_date) {
      this.training_date = this.reading_date_format(json.training_date);
    }
    return this;
  }

  reading_date_format(date): string {
    return new Date((Date.parse(date) - (new Date()).getTimezoneOffset() * 60000)).toISOString()
      .substring(0, new Date((Date.parse(date) - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().length - 1).replace(/\.\d+/, '');
  }

}
