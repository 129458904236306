import {BaseModel} from '../../../shared/base';
export class PracticeSetting extends BaseModel {
  public id: number;
  public settingsable_id: number;
  public settingsable_type: string;
  public setting_type: string;
  public integration_flow: string;
  public button_color = '';
  public doctor_name_color = '';
  public slots_color = '';
  public highlight_color = '';
  public hover_color = '';
  public select_button_color = '';
  to_params() {
    return {
      setting: {
        id: this.id,
        settingsable_id: this.settingsable_id,
        settingsable_type: this.settingsable_type,
        setting_type: this.setting_type,
        button_color: this.button_color,
        slots_color: this.slots_color,
        highlight_color: this.highlight_color,
        doctor_name_color: this.doctor_name_color,
        integration_flow: this.integration_flow,
        hover_color: this.hover_color
      }
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.settingsable_id = json.settingsable_id;
    this.settingsable_type = json.settingsable_type;
    this.integration_flow = json.integration_flow;
    this.button_color = json.button_color;
    this.setting_type = json.setting_type;
    this.select_button_color = json.button_color;
    this.highlight_color = json.highlight_color;
    this.slots_color = json.slots_color;
    this.doctor_name_color = json.doctor_name_color;
    this.hover_color = json.hover_color;
    return this;
  }

}
