export class CaseFeedback {
  id: number;
  case_id: number;
  doctor_id: number;
  doctor_name = '';
  discharge_checklist_ids: Array<number>[] = [];
  instructions: string;
  load_from_json(json) {
  return this;
  }
  to_params() {
    return {
      case_feedback: {
        case_id: this.case_id,
        doctor_id: this.doctor_id,
        discharge_checklist_ids: this.discharge_checklist_ids,
        instructions: this.instructions
      }

    }
  }
}
export class DischargeCheckList {
  id: number;
  description: string
  load_from_json(json) {
    this.id = json.id;
    this.description = json.description;
     return this;
  }
}

