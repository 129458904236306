import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
  public dirty_user: boolean;

  constructor() { }

  mark_user_dirty() {
    this.dirty_user = true;
  }
  mark_user_fresh() {
    this.dirty_user = false;
  }

}
