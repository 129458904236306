<div class="todaysPatient-list headerPatient-list" *ngIf="admission">
  <app-admissions-listing class="list p0 b0" [view]="'payment'" [admission]="admission"></app-admissions-listing>
</div>
<div class="todaysPatient-list headerPatient-list" *ngIf="appointment">
  <div class="list p0 b0">
    <div class="col-sm-3 picName-area">
      <div class="cell-holder">
        <div class="pic img-holder text-center">
          <img class="img-circle thumb64" [src]="appointment.patient.profile_pic" alt="Image"/>
        </div>
        <h4 class="m0 mb fw-500 fs16"><a (click)="addToTab()">{{appointment.patient.name}}</a></h4>
        <h6 class="m0 mb text-capitalize">
          <i class="mr-sm" [ngClass]="(appointment.patient.user.gender == 'female')?'fa icon-user-female':'fa icon-user'"></i>
          {{appointment.patient.show_age_and_gender()}}
          &nbsp;
          {{appointment.patient.customize_dob}}
        </h6>
        <h6 class="m0"><i class="fa icon-phone mr-sm"></i> {{appointment.patient.mobile_number_value |  mask: ' (000) 000-0000'}}</h6>
      </div>
    </div>
    <div class="col-sm-6 border-col">
      <div class="cell-holder pl-sm pr-sm">
        <div class="row mb-sm" *ngIf="appointment.service">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Reason of Visit</label></div>
          <div class="col-sm-9 col-xs-12">{{appointment.service.name}}</div>
        </div>
        <div class="row mb-sm">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Appointment</label></div>
          <div class="col-sm-9 col-xs-12">
            <span>{{appointment.appointment_time}}</span>
            <span class="text-secondary fw-500" *ngIf="appointment.doctor"> with {{appointment?.doctor?.prefix}} {{appointment?.doctor?.name}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Location</label></div>
          <div class="col-sm-9 col-xs-12">{{appointment?.location?.address.line_1_line_2()}} {{appointment?.location?.address.city_state_zip()}}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 col-xs-12 border-col">
      <div class="cell-holder pl-sm pr-sm">
        <div class="row mb-sm">
          <div class="col-sm-5 col-xs-5 pr0"><label class="field-label">Status</label></div>
          <div class="col-sm-7 col-xs-7">
        <span class="text-center status-colors checkedin visible-lg-inline-block visible-md-inline-block visible-sm-inline-block visible-xs-inline-block">
          {{appointment.status_id}}
        </span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5 col-xs-5 pr0"><label class="field-label">{{appointment.status_id}} time</label></div>
          <div class="col-sm-7 col-xs-7">{{ appointment.log_time | date:'shortTime' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
