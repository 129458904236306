import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ProgramService} from '../../program.service';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {formatDate} from '@angular/common';
import {Program} from '../../program';
import {AssociateCarePlanWithChartService} from '../../../routes/practice-site/todays-patients/associate-care-plan-with-chart.service';
import {DocumentType, PatientDocument} from '../../../health/patient-profile/patient-dashboard/chart/document';
import {DocumentsService} from '../../../services/document.service';
import {ProgramDocument} from '../program-document';
import {EnrolmentsService} from '../../enrolments.service';
import {ProgramEnrollment} from '../../eligible-patient';
import {Consent} from '../../program/program-consents/consent';

@Component({
  selector: 'app-enrolment-patient-consent',
  templateUrl: './enrolment-patient-consent.component.html',
  styleUrls: ['./enrolment-patient-consent.component.scss']
})
export class EnrolmentPatientConsentComponent implements OnInit {
  public show_attachment_popup = false;
  @Input() program: Program;
  @Input() patient_id: any;
  public consents: Consent[] = [];
  public programEnrollment: ProgramEnrollment;
  public isEnrolledPatient = false;
  public programAttachmentsArray: any[] = [];
  public date: Date = new Date();
  public today_date: any;
  public current_time: any;
  public document: PatientDocument = new PatientDocument();
  public documentsType: DocumentType[];
  public programDocument: ProgramDocument = new ProgramDocument();
  @Output() continue_to_next_step: EventEmitter<any> = new EventEmitter<any>();
  constructor(public loaderService: LoaderService, public programService: ProgramService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public documentWithChartService: AssociateCarePlanWithChartService,
              public enrolmentService: EnrolmentsService, public injector: Injector,
              public documentsService: DocumentsService) { }

  ngOnInit(): void {
    this.program = this.injector.get('program', this.program);
    this.patient_id = this.injector.get('patient_id', this.patient_id);
    this.isEnrolledPatient = this.injector.get('isEnrolledPatient', false);
    this.programEnrollment = this.injector.get('programEnrollment', this.programService.currentProgramEnrollmentObject);
    this.getDocumentType();
    if ( this.programEnrollment.id ) {
      this.getConsents();
      if (this.isEnrolledPatient) {
        this.getProgramAttachments(this.programEnrollment.id); }
    }
  }

  saveConsentOfPatient() {
    this.loaderService.show();
    this.programService.saveConsentOfPatient(this.programEnrollment.id,
      this.today_date, this.current_time, this.program.consent_text,
      this.programEnrollment.consent_from).then( resp => {
      console.log(resp);
      this.programEnrollment.load_from_json(resp);
      this.programService.currentProgramEnrollmentObject.load_from_json(resp);
      if (this.isEnrolledPatient) {
        this.enrolmentService.reloadProgramEnrolmentObject.next(this.programEnrollment);
      }
      this.loaderService.hide();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  choose_file() {
    this.show_attachment_popup = true;
  }

  handle_image_upload(e: any) {
    if (e) {
      this.setDocumentAttributes(e);
      this.setProgramDocument();
    }
    this.show_attachment_popup = false;
  }

  getConsents() {
    this.loaderService.show();
    const params = {
      consentable_type: "ProgrammeEnrolment",
      consentable_id: this.programEnrollment.id
    };
    this.programService.getConsents(params).then(resp => {
      this.consents = resp.map(a => new Consent().load_from_json(a));
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  getProgramAttachments(id: any) {
    this.loaderService.show();
    this.programService.getProgramAttachments(id).then(resp => {
      this.programAttachmentsArray = resp.map(a => {return {id: a.id, document_url: a.document_url,
        title: a.title, creator_name: a.creator_name}});
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  removeAttachment(attachment: any) {
    this.loaderService.show();
    this.programService.deleteProgramAttachment(attachment.id).then(resp => {
      this.programAttachmentsArray.splice(this.programAttachmentsArray.indexOf(attachment), 1);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  setDocumentAttributes(attachment: any) {
    this.document.patient_id = this.patient_id;
    this.document.attachment_id = attachment.id;
    this.document.document_type_id = this.documentsType.filter(type => type.name == 'Consent')[0]['id'];
    this.document.doctor_id = this.program.care_manager_doctor_id;
    this.document.date = new Date().toString();
    this.document.name = attachment.document_file_name;
    return this.document
  }

  getDocumentType() {
    this.documentsService.getDocumentsType().then(resp => {
      this.documentsType = resp.map(type => new DocumentType().load_from_json(type));
      console.log(this.documentsType)
    })
  }

  setProgramDocument() {
    this.programDocument.programme_enrolment_id = this.programEnrollment.id;
    this.programDocument.document_attributes = this.document;
    this.saveEnrolmentDocument();
  }

  saveEnrolmentDocument() {
    this.loaderService.show();
    this.enrolmentService.saveEnrolmentDocument(this.programDocument).then( resp => {
      console.log(resp)
      this.getProgramAttachments(this.programEnrollment.id);
      this.loaderService.hide();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  continue() {
    this.continue_to_next_step.emit(true);
  }

  disabledButton(): boolean {
    return this.checkObjects(this.consents)
  }

  private checkObjects(array: Consent[]): boolean {
    for (const obj of array) {
      if (!(obj.signed_by_id && obj.consent_from && obj.consentable_type === 'ProgrammeEnrolment')) {
        return false;
      }
    }
    return true;
  }
}
