import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {GlobalErrorHandlerService} from "../../../../services/global-error.service";
import {Amenity} from "../amenity";
import {AmenityService} from "../amenity.service";
import {LoaderService} from "../../../../services/loader.service";
import {TabsService} from "../../../../services/tabs.service";

@Component({
  selector: 'app-view-amenity',
  templateUrl: './view-amenity.component.html',
  styleUrls: ['./view-amenity.component.scss']
})
export class ViewAmenityComponent implements OnInit {
  public amenity : Amenity;
  public editAmenity : boolean = false;
  @ViewChild('deleteModal') deleteModal;

  constructor(public loaderService: LoaderService,public amenityService : AmenityService,
              public injector : Injector,public globalErrorHandlerService: GlobalErrorHandlerService,
              public tabsService : TabsService) {
    this.amenity = this.injector.get('amenity', null);
    console.log(this.amenity)
  }

  ngOnInit(): void {
  }

  updateAmenity(amenity){
    console.log(amenity);
    this.amenity = amenity;
    this.editAmenity =! this.editAmenity
  }

  closeNewForm(event) {
    this.editAmenity =! this.editAmenity
  }

  deleteAmenity(amenity) {
    this.loaderService.show()
    this.amenityService.delete(amenity.id).then(resp => {
      this.amenityService.reloadamenity.next(1);
      this.tabsService.close_tab(this.tabsService.active_tab());
    }).catch(resp => {
      this.loaderService.hide();
      this.deleteModal.hide();
      this.globalErrorHandlerService.error = resp.error.message;
    })
  }

}
