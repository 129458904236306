import { Component, OnInit, Input } from '@angular/core';
import {Appointment} from '../../../../health/models/appointment';
import {Case} from '../../case';
import {TabsService} from '../../../../services/tabs.service';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {Tab} from '../../../../tabs/tab';

@Component({
  selector: 'app-payment-header',
  templateUrl: './payment-header.component.html',
  styleUrls: ['./payment-header.component.scss']
})
export class PaymentHeaderComponent implements OnInit {
  @Input() admission: Case;
  @Input() appointment: Appointment;
  constructor(private _tabs_service: TabsService) { }

  ngOnInit() {
  }

  addToTab() {
    let patient_id =  this.appointment.patient_id;
    this._tabs_service.add_tab(new Tab({title: this.appointment.patient.name, icon: 'fa fa-hospital'}, PatientProfileComponent, patient_id, {id: patient_id, appointment: this.appointment}))
  }

}
