<div class="modal fade in document-attach-viewer" [ngStyle]="isPatientUser() ? {} : {}" [class.fullscreen]="document_service.is_fullscreen" *ngIf="document_service.is_fullscreen">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="document_service.is_pchf == false">
        <a class="fa fa-times fs20 text-black pull-right" (click)="trigger_preview_closed()"></a>
        <h3 class="m0 text-center">{{document_service.document_name()}}</h3>
        <span class="input-field">
          <input #current_page class="form-control" type="text" name="current_page" [ngModel]="document_service.selected_page" (change)="gotoPage($event,current_page.value)">
          <span class="total-pages">of {{document_service.total_pages}}</span>
        </span>
      </div>
      <div class="modal-body scroll-browser document_viewer" #document_container (scroll)="page_scrolling($event)">
        <ul class="list-unstyled m0" *ngIf="isLoadedAttachment">
          <li *ngFor="let page of document_service.pages;let i = index" [attr.page_number]="i+1" [id]="'page_'+(i+1)" class="page">
            <img [src]="page.url" [alt]="page.text" *ngIf="page.url" class="img-responsive page-img">
            <span class="page-counter">Page - {{i+1}} / {{document_service.total_pages}} </span>
          </li>
        </ul>
        <ul class="list-unstyled m0" *ngIf="!isLoadedAttachment">
          <li *ngFor="let page of document_service.pages;let i = index" [attr.page_number]="i+1" [id]="'page_'+(i+1)" class="page">
            <img [src]="page.url" [alt]="page.text" *ngIf="page.loaded && page.is_image()" class="img-responsive page-img">
            <video controls *ngIf="page.is_video()">
              <source [src]="page.url">
              Your browser does not support the video tag.
            </video>
            <audio controls *ngIf="page.is_audio()">
              <source [src]="page.url">
              Your browser does not support the video tag.
            </audio>
            <div class="border-box text-center p-sm text-tertiary" *ngIf="!page.loaded">
              <i class="fa fa-spinner fa-spin"></i>&nbsp; {{page.text}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
