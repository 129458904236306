<h2>Libraries</h2>
<table class="table table-hover">
  <thead>
  <th>Name</th>
  <th>Library Type</th>
  <th>Searchable Columns</th>
  </thead>
  <tbody>
  <tr *ngFor="let library of libraries">
    <td>{{library.name}}</td>
    <td>{{library.libraryType.lib_type}}</td>
    <td>
      <ng-container *ngFor="let searcable_enote_library_setting of library.library_settings">
        {{searcable_enote_library_setting.header_name}}<br>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>
