<div [ngSwitch]="view" class="view-holder">
  <ng-container  *ngIf="showHeader">
    <mwl-calendar-month-view class="views"  *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events"
                             [tooltipTemplate]="tooltipTemplate"
                              [weekendDays]="[0,6]">

    </mwl-calendar-month-view>
  </ng-container>
  <mwl-calendar-week-view [headerTemplate]="showHeader ? '' : empty " class="views" *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events"
                          [tooltipTemplate]="tooltipTemplate"
                          [refresh]="refresh"
                          (beforeViewRender)="beforeWeekViewRender($event)"
                          [hourDuration]="hourDuration"
                          [hourSegments]="1"
                          [dayStartHour]="dayStartHour" [dayStartMinute]="dayStartMinute" [dayEndHour]="dayEndHour"
                          [dayEndMinute]="dayEndMinute"
                          (eventClicked)="clicked_event($event)"
                          (hourSegmentClicked)="hourSegmentClicked($event)">

  </mwl-calendar-week-view>
  <mwl-calendar-day-view class="views" *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events"
                         [tooltipTemplate]="tooltipTemplate"
                         [refresh]="refresh"
                         [eventTitleTemplate]="title_template"
                         [hourDuration]="hourDuration"
                         (beforeViewRender)="beforeDayViewRender($event)"
                         (eventClicked)="clicked_event($event)"
                         [hourSegments]="1"
                         [dayStartMinute]="dayStartMinute" [dayEndMinute]="dayEndMinute"
                         [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour"
                         (hourSegmentClicked)="hourSegmentClicked($event)"
  >
  </mwl-calendar-day-view>
</div>

<ng-template #empty></ng-template>

<ng-template #title_template let-event="event">
  {{event.meta.appointment.patient.name}}
  <i *ngIf="event.meta.appointment.appointment_type === 'video' || event.meta.appointment.appointment_type === 'virtual_visit'" class="fa icon-camrecorder"></i>
  <i *ngIf="event.meta.appointment.appointment_type === 'phone_visit'" class="fa icon-call-in"></i>
  <i *ngIf="event.meta.appointment.appointment_type === 'office_visit'" class="fa fa-building-o"></i>
  <i *ngIf="event.meta.appointment.appointment_type === 'Book'" class="fa fa-building-o"></i>
</ng-template>

<ng-template #tooltipTemplate let-event="event">
  <div class="popover top">
    <div class="arrow"></div>
    <div class="popover-content media m0">
      <div class="media-left thumb64">
        <img class="img-rounded thumbnail thumb64" src="{{event.meta.appointment.patient.profile_pic}}" alt="Profile Pic">
      </div>
      <div class="media-body">
        <h5 class="m0 mb-sm fs16 text-black">{{event.title}}</h5>
        <h6 class="m0 mb-sm">
          <span class="pr" style="display:inline-block;">
            <i [ngClass]="(event.meta.appointment.patient.user.gender == 'female')?'fa icon-user-female':'fa icon-user'"></i>
            &nbsp;
            {{event.meta.appointment.patient.user?.dob | date: 'mediumDate'}},
            {{event.meta.appointment.patient.show_age_and_gender()}}
          </span>
          <span style="display:inline-block;" *ngIf="event.meta.appointment.patient.mobile_number_value">
            <i class="fa icon-phone"></i>
            &nbsp; {{event.meta.appointment.patient.mobile_number_value | mask: ' (000) 000-0000'}}
          </span>
        </h6>
        <h6 class="m0 mb-sm" >
          <label class="fw-500 m0">Reason of visit:</label>
          &nbsp;{{event.meta.appointment.service?.name}}
        </h6>
        <h6 class="m0 mb-sm">
          <label class="fw-500 m0">Appointment:</label>
          &nbsp;{{event.meta.appointment.appointment_time}}
        </h6>
        <h6 class="m0 mb-sm">
          <label class="fw-500 m0 pr">Status:</label>
          <span class="text-center status-colors" style="display:inline-block;" [ngClass]="event.meta.appointment.status_id">
            {{capitalize(event.meta.appointment.status_id)}}
          </span>
        </h6>
        <h6 class="m0 mb-sm" *ngIf="event.meta.appointment.amenity_name">
          <label class="fw-500 m0">Service:</label>
          {{event.meta.appointment.amenity_name}}
        </h6>
        <h6 class="m0">
          <label class="fw-500 m0">Duration:</label>
          &nbsp;{{event.meta.appointment.slot_size}} min
        </h6>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #template let-event="event">

  <div class="modal-header">
    <button type="button" class="close pull-right" (click)="modalRef.hide()"><i class="fa fa-times"></i></button>
    <h4 class="modal-title">{{ appointment.patient.name }} ({{ appointment.slot_size }}) {{ appointment.service.name}}</h4>
  </div>
  <div class="modal-body">
    <app-status-actions [appointment]="appointment" (statusClicked)="handleAction($event)" ></app-status-actions>
  </div>

</ng-template>

<div class="info-popover" *ngIf="selectedEvent && show_popover">
  <div class="popover">
    <div class="popover-content media m0">
      <button type="button" class="close" (click)="show_popover=false"><i class="fa fa-times fs16"></i></button>
      <div class="media-left thumb64">
        <img class="img-rounded thumbnail thumb64" src="{{selectedEvent.meta.appointment.patient.profile_pic}}" alt="Profile Pic">
      </div>
      <div class="media-body">
        <h5 class="m0 mb-sm fs16 text-black"><a (click)="add_to_tabs(selectedEvent.meta.appointment)">{{selectedEvent.title}}</a></h5>
        <h6 class="m0 mb-sm">
          <span class="pr" style="display:inline-block;">
            <i [ngClass]="(selectedEvent.meta.appointment.patient.user.gender == 'female')?'fa icon-user-female':'fa icon-user'"></i>

            &nbsp;{{selectedEvent.meta.appointment.patient.user?.dob | date: 'mediumDate'}},
            {{selectedEvent.meta.appointment.patient.show_age_and_gender()}}
          </span>
          <span style="display:inline-block;" *ngIf="selectedEvent.meta.appointment.patient.mobile_number_value">
            <i class="fa icon-phone"></i>
            &nbsp; {{selectedEvent.meta.appointment.patient.mobile_number_value | mask: ' (000) 000-0000'}}
          </span>
        </h6>
        <h6 class="m0 mb-sm">
          <label class="fw-500 m0">Reason of visit:</label>
          &nbsp;{{selectedEvent.meta.appointment.service?.name}}
        </h6>
        <h6 class="m0 mb-sm">
          <label class="fw-500 m0">Appointment:</label>
          &nbsp;{{selectedEvent.meta.appointment.appointment_time}}
        </h6>
        <h6 class="m0 mb-sm">
          <label class="fw-500 m0 pr">Status:</label>
          <span class="text-center status-colors" style="display:inline-block;" [ngClass]="selectedEvent.meta.appointment.status_id">
            {{capitalize(selectedEvent.meta.appointment.status_id)}}
          </span>
        </h6>
        <h6 class="m0 mb-sm" *ngIf="selectedEvent.meta.appointment.amenity_name">
          <label class="fw-500 m0">Service:</label>
          {{selectedEvent.meta.appointment.amenity_name}}
        </h6>
        <h6 class="m0">
          <label class="fw-500 m0">Duration:</label>
          &nbsp;{{selectedEvent.meta.appointment.slot_size}} min
        </h6>
      </div>
    </div>
    <app-status-actions  [appointment]="selectedEvent.meta.appointment" (statusClicked)="handleAction($event);" ></app-status-actions>
  </div>
</div>



