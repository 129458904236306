export class DateMapper {
  year: any;
  month: any;
  day: any;
  stage_id: string;
  is_active = false;
  public year_error = false;

  public years: Array<number> = [];
  public months: Array<number> = [];
  public days: Array<number> = [];
  public menu_items: Object = { 1: 'Year', 2: 'Month', 3: 'Day'};
  public months_list: Object = { 0: 'Month', 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'June', 7: 'July', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
  public reverse_months_list: Object = { 0: 'Month', 'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'June': 6, 'July': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12};

  get_years_months_days() {
    this.years = this.get_years_desc('Year', (new Date()).getFullYear(), new Date( '01 ' + 'January 1900').getFullYear(), Array());
    this.months = this.get_months_by_name('Month', 1, 12, Array());
    this.days = this.populate_leap_year_days();
    return [this.years, this.months, this.days]
  }

  get_years_desc(label, start_year, end_year, years) {
    return this.generate_series_desc(label, start_year, end_year, years);
  }

  get_years(label, start_year, end_year, years) {
    return this.generate_series(label, start_year, end_year, years);
  }

  get_years_from_latest(label, start_year, end_year, years) {
    return this.generate_series_from_latest_year(label, start_year, end_year, years);
  }

  get_months(label, start_month, end_month, months) {
    return this.generate_series(label, start_month, end_month, months);
  }

  get_months_by_name(label, start_month, end_month, months) {
    return this.generate_series_months(label, start_month, end_month, months);
  }

  get_days(label, start_day, end_day, days) {
    return this.generate_series(label, start_day, end_day, days);
  }

  generate_series_months(label, start, end, final_result) {
    final_result.push(label);
    for (let i = start; i <= end; i++) {
      final_result.push(this.months_list[i]);
    }
    return final_result;
  }

  generate_series(label, start, end, final_result) {
    final_result.push(label);
    for (let i = start; i <= end; i++) { final_result.push(i); }
    return final_result;
  }

  generate_series_desc(label, start, end, final_result) {
    final_result.push(label);
    for (let i = start; i >= end; i--) {
      final_result.push(i);
    }
    return final_result;
  }

  generate_series_from_latest_year(label, start, end, final_result) {
    final_result.push(label);
    for (let i = start; i >= end ; i--) {
      final_result.push(i)
    };
    return final_result;
  }

  patient_dob(dob, dob_stage) {
    this.initalize_empty_dob();
    if (dob) {
      this.set_patient_dob(new Date(dob), dob_stage);
    }
  }

  initalize_empty_dob() {
    this.year = this.menu_items[1];
    this.month = this.menu_items[2];
    this.day = this.menu_items[3];
    return this;
  }

  set_patient_dob(date_of_birth, dob_stage) {
    // switch (dob_stage) {
    //   case '0': {
    //     break;
    //   }
    //   case '1': {
    //     this.year = date_of_birth.getFullYear();
    //     break;
    //   }
    //   case '2': {
    //     this.year = date_of_birth.getFullYear();
    //     if (date_of_birth.getMonth() > 0) {
    //       this.month = date_of_birth.getMonth() + 1;
    //     } else {
    //       this.month = date_of_birth.getMonth();
    //     }
    //     break;
    //   }
    //   case '3': {
    //     this.year = date_of_birth.getFullYear();
    //     if (date_of_birth.getMonth() > 0) {
    //       this.month = date_of_birth.getMonth() + 1;
    //     } else {
    //       this.month = date_of_birth.getMonth();
    //     }
    //     this.day = date_of_birth.getDate();
    //     break;
    //   }
    //   case '4': {
    //     this.year = date_of_birth.getFullYear();
    //     break;
    //   }
    //   default: {
    //     this.year = date_of_birth.getFullYear();
    //     this.month = date_of_birth.getMonth() + 1;
    //     this.day = date_of_birth.getDate();
    //   }
    // }
    this.year = date_of_birth.getFullYear();
    if (date_of_birth.getMonth() >= 0) {
      this.month = date_of_birth.getMonth() + 1;
    } else {
      this.month = date_of_birth.getMonth();
    }
    this.day = date_of_birth.getDate();
    return this;
  }

  populate_leap_year_days() {
    // if (this.is_leap_year(this.year) && this.month == 2 && this.year != this.menu_items[1]) {
    //   this.days = this.get_days('Day', 1, 29, Array());
    // } else if (this.month == 2 && this.year != this.menu_items[1]) {
    //   this.days = this.get_days('Day', 1, 28, Array());
    // } else if (this.month && this.normal_months().indexOf(this.month.toString()) > -1) {
    //   this.days = this.get_days('Day', 1, 30, Array());
    // } else {
      this.days = this.get_days('Day', 1, 31, Array());
    // }
    return this.days;
  }

  apply_dob_rules() {
    let date_obj;
    // if (this.check_availability(this.year, 1) && this.check_availability(this.month, 2) && this.check_availability(this.day, 3)) {
    //   date_obj = new Date(this.year, this.monthHandle(), this.day)
    //   this.stage_id = '3';
    // } else if (this.check_availability(this.year, 1) && this.check_availability(this.month, 2)) {
    //   date_obj = new Date(this.year, this.checkForValue(), 1)
    //   this.stage_id = '2';
    // } else if (this.check_availability(this.year, 1) && this.check_availability(this.day, 3)) {
    //   date_obj = new Date(this.year, 0, this.day)
    //   this.day = this.menu_items[3];
    //   this.stage_id = '4';
    // } else if (this.check_availability(this.year, 1)) {
    //   date_obj = new Date(this.year, 0, 1)
    //   this.stage_id = '1';
    // } else {
    //   console.log('No value is selected');
    // }
    if(this.year == 'Year' || this.month == 'Month' || this.day == 'Day'){
      return ['','0']
    }
    date_obj = new Date(this.year, this.monthHandle(), this.day)
    this.stage_id = '3';
    return [this.formate_date_yyyy_mm_dd(date_obj), this.stage_id];
  }

  monthHandle() {
    if (this.month >= 0) {
      return (this.month - 1);
    } else {
      return this.month;
    }
  }

  formate_date_yyyy_mm_dd(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
  }

  checkForValue() {
    if (this.months_list[this.month] != 'Month' && this.months_list[this.month] != undefined) {
      return this.month - 1
    }
    return 0;
  }

  check_availability(target_source, value) {
    if (target_source != this.menu_items[value]) {
      return true;
    }
    return false;
  }

  is_leap_year(year) {
    let is_leap_year = (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);
    return is_leap_year;
  }

  normal_months() {
    return ['2', '4', '6', '9', '11'];
  }
}
