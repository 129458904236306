<span class="enote-title" contenteditable="false" *ngIf="!term.initial_component" >
  <span *ngIf="term.is_open_text || term.name || term.keyword.name" contenteditable="false">
    <span #term_name_span
          [class]="term.control_value_class"
          [class.term]="term.isTermTypeChild()"
          [class.text-bold]="term.isComponentTypeChild()"
          [class.primary-title]="term.component_level == 2"
          [class.secondary-title]="term.component_level == 3"
          [class.tertiary-title]="term.component_level == 4"
          [ngStyle]="{'text-decoration-line': term.term_type == 'keyword' ? 'underline' : ''}">
      {{term.is_open_text ? term.open_text : term.controlText}}
    </span>
  </span>
</span>

<ng-container *ngIf="term.isTermTypeChild()">
<span class="enote-control" *ngIf="term.term_controls.length > 0">
  <span>[</span>
  <ng-container *ngFor="let term_control of term.term_controls; let i=index">
    <app-read-only-controls  [term]="term" [term_control]="term_control" [control_index]="i"></app-read-only-controls>
    <span *ngIf="i != (term.term_controls.length -1)">,</span>
  </ng-container>
  <span>]</span>
</span>
</ng-container>

<ng-container *ngIf="term.hasChildren()">
  <ng-container *ngFor="let child_terms of term.children_groups;let i=index">
    <span [class.d-block]="i > 0">
      <ng-container *ngFor="let child_term of child_terms">
        <app-read-only-template class="inline-comp" *ngIf="!child_term._destroy" [term]="child_term"></app-read-only-template>
      </ng-container>
    </span>
  </ng-container>
</ng-container>
