import {ElementRef, Injectable} from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class TableToExcelService {

  constructor() { }

  exportToExcel(table: ElementRef) {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table.nativeElement);
    var wscols = [
      {wch: 18},
      {wch: 10},
      {wch: 6},
      {wch: 13},
      {},
      {wch: 13},
      {wch: 18},
      {wch: 25},
      {wch: 18},
      {wch: 10},
      {wch: 8},
      {wch: 10},
      {wch: 8},
      {hidden: true},
    ];
    var rows = [{hpx: 30}]
    ws['!cols'] = wscols;
    ws['!rows'] = rows;

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Bill.xlsx');
  }
}
