<h4>
  <i class="fa icon-home-medical-history fs20 vl-m mr"></i> Insurance
  <a class="fs14 ml-lg" *ngIf="!intakeReport" (click)="addInsurance()" tooltip="Add Insurance"><i
    class="fa fa-plus fs12"></i> Add</a>
</h4>

<form *ngIf="openInsuranceForm" #addInsuranceForm='ngForm' (ngSubmit)="save()">
  <div class="row">
    <div class="col-md-4 col-sm-4 col-xs-12 form-group">
      <input typeaheadWaitMs="500" [typeahead]="insurances" name="insurance"
             [(ngModel)]="patientInsurance.insurance_name" [typeaheadAsync]="true"
             (typeaheadLoading)="changeTypeaheadLoading($event)"
             (typeaheadNoResults)="changeTypeaheadNoResults($event)"
             (typeaheadOnSelect)="typeaheadOnInsuranceSelection($event)" [typeaheadOptionsLimit]="7"
             [typeaheadOptionField]="'name'" placeholder="Search Insurance*"
             class="form-control" type='text' autocomplete="off" required>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-6 form-group">
      <select name="insurance_type" [(ngModel)]="patientInsurance.insurance_type" class="form-control"
              id="insurance_type" required>
        <option value=undefined>Payor*</option>
        <ng-container *ngFor="let type of patientService.insurance_types">
          <option class="text-capitalize" value='{{type}}'>{{ userService.removeUnderScore(type) }}</option>
        </ng-container>
      </select>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
      <select name="importance_type" [(ngModel)]="patientInsurance.importance_type" required class="form-control"
              id="importance_type">
        <option value=undefined>Priority*</option>
        <ng-container *ngFor="let imp_type of patientService.insurance_importance_types">
          <option class="text-capitalize" value='{{imp_type}}'>{{ userService.removeUnderScore(imp_type) }}</option>
        </ng-container>
      </select>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="Insurance ID*" id="insurance_id"
             [(ngModel)]="patientInsurance.patient_insurance_id" name="insurance_id" required/>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="Group Number" id="group_number"
             [(ngModel)]="patientInsurance.group_number" name="group_number"/>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="BIN" id="bin_number"
             [(ngModel)]="patientInsurance.bin_number" name="bin_number"/>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="PCN" id="rx_pcn" [(ngModel)]="patientInsurance.rx_pcn"
             name="rx_pcn"/>
    </div>
    <div class="col-md-2 col-sm-4 col-xs-6 form-group">
      <input type="text" placeholder="Effective Date" name="effective_date"
             [(ngModel)]="patientInsurance.effective_date"
             class="form-control"
             bsDatepicker
             [bsValue]="bsValue"
             [bsConfig]="{ customTodayClass: 'today-date-select',
             dateInputFormat: 'YYYY-MM-DD', showWeekNumbers:false, adaptivePosition: true}">
    </div>
    <div class="col-md-2 col-sm-4 col-xs-6 form-group">
      <input type="text" placeholder="Expiry Date" name="expiry_date"
             [(ngModel)]="patientInsurance.expiry_date"
             class="form-control"
             bsDatepicker
             [bsValue]="bsValue"
             [bsConfig]="{ customTodayClass: 'today-date-select',
             dateInputFormat: 'YYYY-MM-DD', showWeekNumbers:false, adaptivePosition: true}">
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 form-group">
      <input type="text" class="form-control" placeholder="Address" id="address" [(ngModel)]="patientInsurance.address"
             name="address"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="City" id="city" [(ngModel)]="patientInsurance.city"
             name="city"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="State" id="state" [(ngModel)]="patientInsurance.state"
             name="state"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="Zip" id="zip" [(ngModel)]="patientInsurance.zip" name="zip"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group inline-table">
      <span class="input-group-addon"><i class="fa fa-phone"></i></span>
      <input type="text" mask="(000) 000-0000" class="form-control phone" placeholder="Phone" id="phone"
             [(ngModel)]="patientInsurance.phone" name="phone"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group inline-table">
      <span class="input-group-addon"><i class="fa fa-fax"></i></span>
      <input type="text" mask="(000) 000-0000" class="form-control fax" placeholder="Fax" id="fax"
             [(ngModel)]="patientInsurance.fax" name="fax"/>
    </div>
    <div class="col-xs-12 form-group">
      <p>Upload Insurance Card</p>
      <div class="row">
        <div class="col-sm-4 col-xs-6 text-center">
          <div class="border-box shadow p">
            <a (click)="uploadCardPicture('insurance_front_pic')">Front Picture</a>
            <div class="center-block mt" *ngIf="patientInsurance.card_front_pic">
              <img class="img-responsive" [src]="patientInsurance.card_front_pic" alt="">
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-xs-6 text-center">
          <div class="border-box shadow p">
            <a (click)="uploadCardPicture('insurance_back_pic')">Back Picture</a>
            <div class="center-block mt" *ngIf="patientInsurance.card_back_pic">
              <img class="img-responsive" [src]="patientInsurance.card_back_pic" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 form-group">
      <ng-container *ngIf="!loaderService.uploading_in_process">
        <button type="submit" class="btn btn-secondary btn-sm mr"
                [disabled]="!(addInsuranceForm.form.valid && patient.id)">
          {{ patientInsurance.is_new_record() ? 'Save' : 'Update' }}
        </button>
        <button type="button" (click)="openInsuranceForm = false" class="btn btn-tertiary btn-outline btn-sm">Cancel
        </button>
      </ng-container>
      <ng-container *ngIf="loaderService.uploading_in_process">
        <p class="fs12">
          <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
        </p>
      </ng-container>
    </div>
  </div>
</form>

<div class="table-responsive" *ngIf="patientInsurances.length > 0; else notfound">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Name</th>
      <th>Phone</th>
      <th>Fax</th>
      <th>Payor</th>
      <th>Priority</th>
      <th>ID</th>
      <th class="text-center" style="min-width:90px;">Expiry Date</th>
      <th class="text-center" style="min-width:100px;" *ngIf="!intakeReport">Card</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let patientInsurance of patientInsurances">
      <td *ngIf="intakeReport">
        {{ patientInsurance.insurance_name }}
      </td>
      <td *ngIf="!intakeReport">
        <a tooltip="Edit" (click)="editInsurance(patientInsurance)">
          {{ patientInsurance.insurance_name }}
        </a>
      </td>
      <td>{{ patientInsurance?.phone | mask: '(000) 000-0000' }}</td>
      <td>{{ patientInsurance?.fax | mask: '(000) 000-0000' }}</td>
      <td *ngIf="patientInsurance.insurance_type">{{ userService.removeUnderScore(patientInsurance.insurance_type) }}
      </td>
      <td
        *ngIf="patientInsurance.importance_type">{{ userService.removeUnderScore(patientInsurance.importance_type) }}
      </td>
      <td>{{ patientInsurance.patient_insurance_id }}</td>
      <td class="text-center">{{ patientInsurance.expiry_date }}</td>
      <td class="text-center" *ngIf="!intakeReport">
        <a *ngIf="patientInsurance.card_front_pic" (click)="showPicture(patientInsurance.card_front_pic)">Front</a>
        <a *ngIf="patientInsurance.card_back_pic" (click)="showPicture(patientInsurance.card_back_pic)" class="mr">
          <span *ngIf="patientInsurance.card_front_pic && patientInsurance.card_back_pic"> | </span> Back
        </a>
      </td>
      <td *ngIf="intakeReport">
        <div class="row">
          <div class="col-md-5 col-sm-5 col-xs-5" *ngIf="patientInsurance.card_front_pic">
            <img class="center-block img-responsive" [src]="patientInsurance.card_front_pic" alt="Image">
          </div>
          <div class="col-md-5 col-sm-5 col-xs-5" *ngIf="patientInsurance.card_back_pic">
            <img class="center-block img-responsive" [src]="patientInsurance.card_back_pic" alt="Image">
          </div>
        </div>
      </td>
      <td *ngIf="!intakeReport">
        <a class="btn btn-danger btn-outline btn-sm"
           (click)="selectedInsuranceToDelete = patientInsurance;deleteModal.show();">
          <i class="fa fa-trash"></i>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<ng-template #notfound>
  <ul class="list-group">
    <li class="list-group-item disabled p"><span class="text-gray-dark">No insurance record found.</span></li>
  </ul>
</ng-template>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Insurance</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p pt-sm pb-sm m0 text-center">“Are you sure you want to delete the Insurance?”
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary"
                (click)="deleteInsurance(selectedInsuranceToDelete);deleteModal.hide()">Yes
        </button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>

<app-attachments (document_uploaded)="handle_file_upload($event)"
                 [fileType]="fileType" [only_modal]="true"
                 [auto_upload]="true" [attachable_id]="patientInsurance.id"
                 [show]="show_attachment_popup" [attachable_type]="'PatientInsurance'">
</app-attachments>


<div class="modal fade flex-modal" bsModal #cardPicViewModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="cardPicViewModal.hide();"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Insurance Card</h4>
      </div>
      <div class="modal-body">
        <img class="center-block img-responsive" [src]="pictureUrl" alt="Image">
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="cardPicViewModal.hide();">Close</button>
      </div>
    </div>
  </div>
</div>
