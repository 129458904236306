import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable }        from 'rxjs/Observable';
import { Subject }           from 'rxjs/Subject';
import { PermissionsService, RoleType, Role}    from './permissions.service';
import { PracticeSearchService, Practice }    from './practice-search.service';
import {UserService} from '../../services/user.service';
import { mergeMap } from 'rxjs/operators';

@Component({
    // moduleId: module.id.toString(),
    selector: 'role-type-parent',
    templateUrl: './permissions.component.html',
    styleUrls: [ './permissions.component.scss' ],
    providers: [PermissionsService, PracticeSearchService]
})

export class PermissionsComponent implements OnInit {

    query = '';
    show_search = true;
    role_id: any;
    practice_id: any;
    isVisible: boolean;
    practices: Observable<Practice[]>;
    private searchTerms = new Subject<string>();
    selected_menu: string;
    role_types: RoleType[];
    roles: Role[];
    specificRoles: any;
    role_type_id: any;
    public dataSourcePractices: Observable<any>;
    public dataSourceGroup: Observable<any>;
    public asyncSelected = '';
    public typeaheadLoading = false;
    public typeaheadNoResults = false;

    constructor(private permissionService: PermissionsService, private practiceSearchService: PracticeSearchService, private _userService: UserService) {
        this.dataSourcePractices = Observable.create((observer: any) => {

            observer.next(this.asyncSelected);
        }).pipe(mergeMap((token: string) => this.getPractices(token)));

        this.dataSourceGroup = Observable.create((observer: any) => {

            observer.next(this.asyncSelected);
        }).pipe(mergeMap((token: string) => this.getGroups(token)));
    }

    public getPractices(token): Observable<any> {
        return this.permissionService.getPracticesAsObservable(token, this.role_type_id);
    }

    public getGroups(token): Observable<any> {
        return this.permissionService.getGroupAsObservable(token, this.role_type_id);
    }

    public setPermissionableId(value) {
        if (value == '') {
             this.practice_id = '';
         }
    }

    ngOnInit(): void {
        this.getRoleTypeAndRoles();
    }

    public changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    public changeTypeaheadNoResults(e: boolean): void {
        this.typeaheadNoResults = e;
    }

    public typeaheadOnSelect(e: any): void {
        this.practice_id = e.item.id;
        console.log('Selected value: ', e.item.id);
    }


    getRoleTypeAndRoles(): void {
        this.permissionService.getRoleTypes().then(permissions => {
            this.role_types = permissions.dynamic_role_types;
            this.roles = permissions.roles;
            if (!this.role_type_id && this.role_types.length > 0) {
              this.onSelect(this.role_types[0].id, this.role_types[0].name);
              if (this._userService.current_user.isGroup || this._userService.current_user.isPractice) {
                this.practice_id = this._userService.current_user.owner_id;
                this.show_search = false;
              }
            }
        });
    }

    onSelect(id: number, tab: string): void {
        this.selected_menu = tab;
        this.query = '';
        this.specificRoles = this.roles[id];
        this.role_type_id = id;
        this.asyncSelected = '';
        this.role_id = '';
        this.practice_id = '';
    }

    selectPractice(pratice: Practice): void {
        this.practice_id = pratice.id;
        this.query = pratice.name;
        this.isVisible = false;
    }

    onChange(role_id, practice_id): void {
        this.role_id = role_id;
        this.practice_id = practice_id;
    }

    iconMapping(role_type_name): any {
        let iconMappingHash = { 'patient': 'fa icon-user', 'doctor': 'fa fa-user-md', 'practice': 'fa fa-hospital-o', 'group': 'fa fa-group', 'enterprise': 'fa fa-key'}
        let icon = iconMappingHash[role_type_name];
        return icon;
    }

}
