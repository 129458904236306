export class NoteComponent {
  id: any;
  component: any;
  sub_component_1: any;
  sub_component_2: any;
  head: any;
  category: any;
  library: any;
  default_control: any;
  library_id: any;
  parent_id: any;
 // status: any = 'active';

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  to_params() {
    return {
      note_component: {
        id: this.id,
        component: this.component,
        sub_component_1: this.sub_component_1,
        sub_component_2: this.sub_component_2,
        head: this.head,
        category: this.category,
        library: this.library,
        default_control: this.default_control,
        library_id: this.library_id,
        parent_id: this.parent_id
      }

      }
  }

  load_from_json(json: any) {
     this.id = json.id;
     this.component = json.component;
     this.sub_component_1 = json.sub_component_1;
     this.sub_component_2 = json.sub_component_2;
     this.head = json.head;
     this.category = json.category;
     this.library = json.library;
     this.default_control = json.default_control;
     this.library_id = json.library_id;
     this.parent_id = json.parent_id;
     return this;
}
}
