import {Component, Input, OnInit} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-program-enrolled-header',
  templateUrl: './program-enrolled-header.component.html',
  styleUrls: ['./program-enrolled-header.component.scss']
})
export class ProgramEnrolledHeaderComponent implements OnInit {
  @Input() program: Program;
  @Input() showVitals: boolean;

  constructor() { }

  ngOnInit(): void {
    console.log(this.program)
  }

}
