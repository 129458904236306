import {AlertTemplateRule} from './alert-template-rule';

export class AlertTemplate {
  id: any;
  subject: any;
  alert_type: any;
  description: any;
  protocol_name: any;
  protocol_id: any;
  age: any;
  practice_id: any;
  creator_id: any;
  gender: any;
  severity: any;
  status: any = 'active';
  guideline: any;
  color_code: any = '#0000FF';
  alert_rule: AlertTemplateRule = new AlertTemplateRule();
  alert_template_interventions: AlertIntervention[] = [];

  load_from_json(json) {
    this.id = json.id;
    this.subject = json.subject;
    this.alert_type = json.alert_type;
    this.practice_id = json.practice_id;
    this.description = json.description;
    this.age = json.age;
    this.protocol_name = json.protocol_name;
    this.protocol_id = json.protocol_id;
    this.gender = json.gender;
    this.severity = json.severity;
    this.creator_id = json.creator_id;
    this.status = json.status;
    this.guideline = json.guideline;
    this.color_code = json.color_code;
    if (json.alert_rule) {
      this.alert_rule = this.alert_rule.load_from_json(json.alert_rule);
    }
    if (json.alert_template_interventions) {
      this.alert_template_interventions = json.alert_template_interventions.map(a => new AlertIntervention().load_from_json(a))
    }
    return this;
  }

  to_params() {
    return {
      alert_template: {
        id: this.id,
        subject: this.subject,
        alert_type: this.alert_type,
        practice_id: this.practice_id,
        description: this.description,
        creator_id: this.creator_id,
        age: this.age,
        protocol_id: this.protocol_id,
        gender: this.gender,
        severity: this.severity,
        status: this.status,
        guideline: this.guideline,
        color_code: this.color_code,
        alert_rule_attributes: this.alert_rule.to_params(),
        alert_template_interventions_attributes: this.alert_template_interventions.map(a => a.to_params_for_create_template()),
      }
    }
  }

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }
}

export class AlertIntervention {
  intervention_id: any;
  id: any;
  alert_template_id: any;
  description: any;
  _destroy: boolean;

  to_params() {
    return {
      description: this.description
    }
  }
  to_params_for_create_template() {
    return {
      id: this.id,
      intervention_id: this.intervention_id,
      _destroy: this._destroy
    }
  }
  load_from_json(json) {
    this.intervention_id = json.intervention_id;
    this.alert_template_id = json.alert_template_id;
    this.id = json.id;
    this.description = json.description;
    return  this
  }
  load_form_json_for_create_intervention(json) {
    this.intervention_id = json.id;
    this.description = json.description;
    return this
  }
}
