import { Component, OnInit , Input} from '@angular/core';
import {AmenityAssociate} from '../amenity';
import {LoaderService} from '../../../../services/loader.service';
import {AmenityService} from '../amenity.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';

@Component({
  selector: 'app-amenity-associate',
  templateUrl: './amenity-associate.component.html',
  styleUrls: ['./amenity-associate.component.scss']
})
export class AmenityAssociateComponent implements OnInit {
  @Input() associate_ammenity_array: AmenityAssociate[];
  @Input() locations: any;
  public selected_associate_amenity: AmenityAssociate;
  constructor(public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService,
              public amenityService: AmenityService) { }

  ngOnInit(): void {
  }

  updateAssociateAmenity(associate_amenity) {
    console.log(associate_amenity)
    this.loaderService.show();
    this.amenityService.updateAssociateAmenity(associate_amenity)
      .then(resp => {
        associate_amenity = associate_amenity.load_from_json(resp)
        this.loaderService.hide();
      })
      .catch(resp => {
        console.log(resp);
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  delete_doctor_association_with_service(associate_amenity) {
    this.loaderService.show()
    this.amenityService.deleteAssociateAmenity(associate_amenity.id).then(resp => {
      this.associate_ammenity_array.splice(this.associate_ammenity_array.indexOf(associate_amenity), 1);
      this.amenityService.reloadamenity.next(1);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }


  focusOutFunction(associate_amenity) {
    this.amenityService.updateAssociateAmenityFee(associate_amenity)
      .then(resp => {
        associate_amenity = associate_amenity.load_from_json(resp)
      })
      .catch(resp => {
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

}
