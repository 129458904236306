import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import {TabsService} from '../../../../services/tabs.service';
import {AdmitComponent} from '../../../practice-site/admit/admit.component';
import {CancelComponent} from '../status-actions/cancel/cancel.component';
import {CheckInComponent} from '../status-actions/check-in/check-in.component';
import {CheckoutComponent} from '../status-actions/checkout/checkout.component';
import {ConfirmComponent} from '../status-actions/confirm/confirm.component';
import {HistoryComponent} from '../status-actions/history/history.component';
import {NoshowComponent} from '../status-actions/noshow/noshow.component';
import {PrintEncounterComponent} from '../status-actions/print-encounter/print-encounter.component';
import {RescheduleComponent} from '../status-actions/reschedule/reschedule.component';
import {ScanComponent} from '../status-actions/scan/scan.component';
import {Appointment} from '../../../../health/models/appointment';

import {Tab} from '../../../../tabs/tab';
import {PaymentComponent} from '../../payment/payment.component';
import {AppointmentService} from '../../../../services/appointment.service';
import {Case} from '../../case';
import {ScanService} from '../../../../services/scan.service';
import {VitalComponent} from '../../vital/vital.component';
import {PatientNoteComponent} from '../../../../enote/patient-note/patient-note.component';
import {PatientDocument, DocumentPage} from '../../../../health/patient-profile/patient-dashboard/chart/document';
import {LoaderService} from '../../../../services/loader.service';
import {DocumentsService} from '../../../../services/document.service';
import {EnoteService} from '../../../../services/enote.service';
import {VideoPanelComponent} from '../../../../video-chat/video-panel/video-panel.component';
import {VideoChatService} from '../../../../video-chat/services/videochat.service';
import {UserService} from '../../../../services/user.service';
import {PhoneService} from '../../../../voice-call/phone.service';
import {PatientReviewService} from '../../../../reviews/patient-review.service';
import {AlertService} from '../../../../_alert';
import {RichTextTemplateService} from "../../../rich-text-template-index/rich-text-template.service";
import {RichTextTemplate} from "../../../rich-text-template-index/rich-text-template";
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {IntakeFormTemplateService} from '../../../intake-form-template-index/intake-form-template.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';

@Component({
  selector: 'app-status-actions',
  templateUrl: './status-actions.component.html',
  styleUrls: ['./status-actions.component.scss']
})
export class StatusActionsComponent implements OnInit {
  public iconMappingHash = {'Check-In': CheckInComponent, 'Confirm': ConfirmComponent,
    'Reschedule': RescheduleComponent, 'Cancel': CancelComponent, 'No Show': NoshowComponent,
    'History': HistoryComponent, 'Scan': ScanComponent, 'Upload': ScanComponent, 'Check-Out': CheckoutComponent,
    'Admit': AdmitComponent, 'Print Encounter': PrintEncounterComponent, 'Video': VideoPanelComponent
  }
  @Input() appointment: Appointment;
  @Input() app_type: string;

  public enotes: any[];
  public label_value: string;
  public document: PatientDocument = new PatientDocument();
  public rejection_reason = '';
  public checkedin_cancel_reason;
  public pending_pages: any = '';
  public unique_tab_id = 1;
  public attachable_id_document = 0;
  show_attachment_popup = false;
  scanning_in_process = false;
  public showSelect = true;
  selected_note_id: any;
  @Output() statusClicked = new EventEmitter<boolean>();
  @Output() edited_appointment: EventEmitter<Appointment> = new EventEmitter<Appointment>();

  constructor(private _documentsService: DocumentsService, private loaderService: LoaderService,
              public scan_service: ScanService, private _appointment_service: AppointmentService,
              private _tabs_service: TabsService, private enoteService: EnoteService,
              public phoneService: PhoneService, private patientReviewService: PatientReviewService,
              public videoChatService: VideoChatService, public userService: UserService,
              public alertService: AlertService, public richTextTemplateService: RichTextTemplateService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {

    this.enoteService.reloadEnotes.subscribe(item => {
      if (item) {
        this.enoteService.reloadEnotes.next(0);
        this.get_drafted_enotes();
      }
    });
  }

  ngOnInit() {
    this.scan_service.get_waiting(this.appointment).then(response => {
      this.pending_pages = response;
      this.scan_service.set_data(this.pending_pages.length, this.appointment.id);
    });
    this.get_drafted_enotes();
  }

  get_drafted_enotes() {
    /*this.enoteService.get_drafted_enotes(this.appointment.id).then(resp => {
      this.enotes = resp.map(enote => { return  {name: enote.name, id: enote.id, stage: enote.stage}})
    })*/
    this.enoteService.get_drafted_enotes(this.appointment.id).then(resp => {
      this.enotes = resp.map(enote => { return  {name: enote.name, id: enote.id, stage: enote.stage,
        is_rich_text: enote.is_rich_text, rich_text_template: enote.is_rich_text ? enote : ''}})
    })
  }

  onChange(value) {
    if (value === 'Enote') {
      return false;
    }
    if (value === '0') {
      this.selected_note_id = null;
      delete this.enoteService.template
      this.add_enote_to_tabs('Create New', 'create_enote');
    }
    else {
      this.selected_note_id = value;
      let enote = this.enotes.filter(e => e.id == value)[0];
      if(enote.is_rich_text)
      {
        let rich_text = new RichTextTemplate()
        this.richTextTemplateService.getRichText(enote.id).then(resp => {
          rich_text.load_from_json(resp);
          enote.rich_text_template = rich_text;
          this.add_enote_to_tabs(enote.name, enote.stage, enote.is_rich_text, enote.rich_text_template)
        }).catch(resp => {
          console.log("error is getting single RichText", resp.error.error )
        })
      }
      else
      {
        this.add_enote_to_tabs(enote.name, enote.stage, enote.is_rich_text, enote.rich_text_template)
      }

    }
    this.showSelect = false;
    setTimeout(() => {
      this.showSelect = true;
    }, 500)
  }

  add_enote_to_tabs(title, stage, is_rich_text?, rich_text_template?) {
    let appointment_id = this.appointment.id + title;
    this._tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital', autorefresh: true},
      PatientNoteComponent, appointment_id, {
        patientID: this.appointment.patient_id, stage: stage, source_type: 'Appointment',
        is_rich_text: is_rich_text, rich_text_template: rich_text_template, viewRichText: true,
        source_id: this.appointment.id, showVideo: true, doctor_id: this.appointment.doctor_id,
        appointment: this.appointment, note_id: this.selected_note_id}))
  }

  add_to_tabs(title) {
    this.statusClicked.emit(true);
    this._tabs_service.add_tab(new Tab({title: this.appointment.patient_name() + '-' + title,
        icon: 'fa fa-hospital'}, this.iconMappingHash[title], this.appointment.id,
      {id: this.appointment.id, appointment: this.appointment, case: new Case()}))
  }

  add_video_to_tabs(title) {
    if (!this.userService.user.twilio_configurations.video) {
      return;
    }
    this.videoChatService.start_call_message = true;
    this.videoChatService.showSmallWindow = false;
    this.videoChatService.showLayoutVideo = false;
    setTimeout(() => {
      this.videoChatService.appointment_id = this.appointment.id;
      this.videoChatService.copy_appointment = this.appointment;
      this.videoChatService.patient_user_id = this.appointment.user_id;
      this.videoChatService.patient_full_name = this.appointment.patient.name;
      this.videoChatService.patient_profile_pic = this.appointment.patient.user.profile_pic
      this.videoChatService.mobile_online_status = this.appointment.mobile_online_status;
      this.videoChatService.portal_online_status = this.appointment.portal_online_status;
      this.videoChatService.showLayoutVideo = true;
    }, 300)
  }

  change_status() {
    let status = this.update_status();
    this._appointment_service.change_status(this.appointment.id, status, this.checkedin_cancel_reason).then(res => {
      this.appointment = this.appointment.load_from_json(res.appointment);
    });
    this.statusClicked.emit(true);
  }

  choose_file() {
    this.show_attachment_popup = true;
  }

  update_status() {
    if (this.label_value === 'Cancel') {
      return 'cancelled'
    }
    if (this.label_value === 'No Show') {
      return 'noshow';
    }
    if (this.label_value === 'Confirm') {
      return 'confirmed';
    }
  }

  set_label(label_text) {
    this.label_value = label_text;
  }

  add_payment_to_tabs(title) {
    this.statusClicked.emit(true);
    let appointment_id = this.appointment.id + title;
    this._tabs_service.add_tab(new Tab({
        title: this.appointment.patient_name() + '-' + title,
        icon: 'fa fa-hospital',
        autorefresh: false
      },
      PaymentComponent,
      appointment_id,
      {
        id: this.appointment.id,
        paymentFor: 'Appointment',
        status: title
      }))
  }
  add_vital_to_tabs(title) {
    this.statusClicked.emit(true);
    let appointment_id = this.appointment.id + title;
    this._tabs_service.add_tab(new Tab({
        title: this.appointment.patient_name() + '-' + title,
        icon: 'fa fa-hospital', autorefresh: false},
      VitalComponent,
      appointment_id,
      {
        id: this.appointment.id
      }
      )
    )
  }

  check_dob(appointment_date) {
    if (appointment_date) {
      let myDate = new Date(appointment_date);
      let today = new Date();
      if (myDate.getDate() > today.getDate()) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }

  check_dob_with_time(appointment_date) {
    if (appointment_date) {
      let myDate = new Date(appointment_date);
      let today = new Date();
      if (myDate > today) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }


  handle_image_upload(data) {
    if (data === false) {
      return;
    } else {

      this.scanning_in_process = true;

      let today = new Date();
      this.document.document_type_id = 1;
      this.document.name = '';
      this.document.date = today.toDateString();
      this.document.doctor_id = this.appointment.doctor_id;
      this.document.service_id = this.appointment.service_id || 18;
      this.document.attachment_id = data.id;
      this.document.patient_id = this.appointment.patient_id;
      this.loaderService.show();
      this._documentsService.save(this.document).then(resp => {
        this.document.load_from_json(resp);
        // this.update_documents_array.emit(this.document);
        this.loaderService.hide();
      })
    }
  }


  request_review() {
    this.patientReviewService.request_review(this.appointment.id).then(resp => {
      this.alertService.success('Review request has been sent', this.alertService.options);
      console.log(resp);
    })
  }

}
