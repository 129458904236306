import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {AlertService} from '../../../_alert';
import {IntakeFormTemplateService} from '../../intake-form-template-index/intake-form-template.service';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-email-intake-form',
  templateUrl: './email-intake-form.component.html',
  styleUrls: ['./email-intake-form.component.scss']
})
export class EmailIntakeFormComponent implements OnInit {
  @Input() inputId;
  @Input() patient_id;
  public typeaheadLoading: boolean;
  public typeaheadNoResults: boolean;
  public template_form_token = '';
  public data_source_template_form: Observable<any>;
  constructor(public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public alertService: AlertService, public inTakeFormTemplateService: IntakeFormTemplateService) {
    this.data_source_template_form = Observable.create((observer: any) => {
      observer.next(this.template_form_token);
    }).pipe(mergeMap((token: string) => this.inTakeFormTemplateService.searchFormTemplates(token)));
  }
  ngOnInit(): void {
  }

  changeTypeaheadLoading(e: boolean) {
    this.typeaheadLoading = e;
  }
  changeTypeaheadNoResults(e: boolean) {
    this.typeaheadNoResults = e;
  }

  typeaheadFromTemplateOnSelect($event: any) {
    this.sendIntakeFormEmail($event.item.id);

  }

  private sendIntakeFormEmail(id) {
    this.loaderService.show();
    this.inTakeFormTemplateService.sendIntakeFormEmail(id, this.patient_id).then(resp => {
        this.template_form_token = '';
        this.loaderService.hide();
        this.alertService.success('eMail has been sent successfully', this.alertService.options);
      }
    ).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })

  }

}
