<ng-container *ngIf="!showEditConfirmation">
  <form class="m0" (ngSubmit)="savePatientEvent()" #newPatientEventForm="ngForm">
    <div>
      <div class="row">
        <div class="col-md-2 col-sm-3">
          <label class="field-label control-label">Date Time*</label>
        </div>
        <div class="col-md-4 col-sm-5 form-group">
          <input type="text"
                 placeholder="Datepicker"
                 class="form-control"
                 name="start_date"
                 [(ngModel)]="patientEvent.start_date"
                 [bsValue]="patientEvent.start_date"
                 [bsConfig]="{ dateInputFormat: 'MMMM Do YYYY, h:mm:ss a' }"
                 bsDatepicker>
          <!--<input type="datetime-local" name="config_date" [value]="patientEvent.start_date"
                 [(ngModel)]="patientEvent.start_date" class="form-control" required/>-->
        </div>
        <div class="col-md-2 col-sm-5">
          <label class="field-label control-label">
            <input class="m0" type="checkbox" name="event_notify" [value]=true [(ngModel)]="patientEvent.is_notify">
            &nbsp;Notify
          </label>
        </div>
        <div class="col-md-4 col-sm-5 form-group">
          <ss-multiselect-dropdown [options]="patientEventService.event_notify_before_Options"
                                   [disabled]="!patientEvent.is_notify"
                                   [(ngModel)]="patientEvent.notify_before"
                                   [settings]="selectSettings"
                                   [texts]="myTexts"
                                   (ngModelChange)="selectNotifyBefore($event)"
                                   [required]="patientEvent.is_notify"
                                   name="event_notify_before">
          </ss-multiselect-dropdown>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label class="field-label control-label">Type*</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <select class="form-control" name="event_Type" [(ngModel)]="patientEvent.event_type"
                  (change)="changeTypeCall()" [disabled]="patientEvent.id" required>
            <option value=undefined selected>Type</option>
            <option *ngFor="let type of patientEventService.event_type; let i = index;" [value]="type">
              {{userService.removeUnderScore(type)}}
            </option>
          </select>
        </div>
      </div>
      <div class="row form-group" *ngIf="patientEvent.event_type === 'medication'">
        <div class="col-md-2 col-sm-3">
          <label class="field-label control-label">Medication*</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <select class="form-control" name="event_medication" [(ngModel)]="patientEvent.eventable_id"
                  [disabled]="patientEvent.id"
                  (change)="changeMedicationDropdown()" required>
            <option value=undefined selected>Select Medication</option>
            <ng-container *ngIf="patient_summary.length > 0">
              <option *ngFor="let summary of patient_summary; let i = index;" [value]="summary.ps_id">
                {{userService.removeUnderScore(summary.d_name)}}
                <ng-container *ngIf="summary?.term_custom_controls.length > 0">,</ng-container>
                <ng-container *ngFor="let term_control of summary?.custom_term_control_with_values(); let i=index">
                  <app-note-custom-control-value class="fs12 fw-400" [term]="summary" [term_control]="term_control" [control_index]="i"></app-note-custom-control-value>
                  <span class="fs12 fw-400" *ngIf="i < summary.custom_term_control_with_values().length - 3 ">,</span>
                  <span class="fs12 fw-400" *ngIf="(summary.custom_term_control_with_values().length - 2) == i">and</span>
                  <span class="fs12 fw-400" *ngIf="(summary.custom_term_control_with_values().length -1) == i">.</span>
                </ng-container>
              </option>
            </ng-container>
            <ng-container *ngIf="patient_summary.length === 0">
              <option disabled>
                <div class="alert alert-info text-center fw-500">No Record Found!</div>
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="row form-group" *ngIf="patientEvent.event_type === 'vital_sign'">
        <div class="col-md-2 col-sm-3">
          <label class="field-label control-label">Medical Device*</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <select class="form-control" name="event_medical_device" [(ngModel)]="patientEvent.eventable_id"
                  [disabled]="patientEvent.id"
                  (change)="changeMedicalDeviceDropdown()" required>
            <option value=undefined selected>Device</option>
            <ng-container *ngIf="patient_devices.length > 0">
              <option *ngFor="let summary of patient_devices; let i = index;" [value]="summary.id">
                {{summary.name}}, {{summary?.d_model}}
              </option>
            </ng-container>
            <ng-container *ngIf="patient_devices.length === 0">
              <option disabled>
                <div class="alert alert-info text-center fw-500">No Record Found!</div>
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label class="field-label control-label">Subject*</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <input type="text" class="form-control" name="subject" placeholder="Subject of Event"
                 [(ngModel)]="patientEvent.subject" required>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label class="field-label control-label">Duration*</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <select class="form-control" name="event_duration" [(ngModel)]="patientEvent.duration" required>
            <option *ngFor="let duration of patientEventService.event_duration; let i = index;" [value]="duration">
              {{userService.removeUnderScore(duration)}}
            </option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label class="field-label control-label">Description</label>
        </div>
        <div class="col-md-10 col-sm-5">
              <textarea [(ngModel)]="patientEvent.description" placeholder="Description"
                        class="form-control no-resize" style="height:120px;" name="comments">
              </textarea>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label class="field-label control-label">Repeat</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <select class="form-control" [(ngModel)]="patientEvent.recur_type" name="event_repeat">
            <option value='never' selected>Never</option>
            <option value="day">Daily</option>
            <option value="week">Weekly</option>
            <option value="month">Monthly</option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="patientEvent.recur_type !== 'never'">
        <ng-container *ngIf="patientEvent.recur_type === 'day'">
          <div class="row form-group">
            <div class="col-sm-2 hidden-xs"></div>
            <div class="col-sm-2 col-xs-3">
              <label class="field-label control-label">Every</label>
            </div>
            <div class="col-sm-2 col-xs-5 p0">
              <input type="number" class="form-control" name="daily_repert_number" placeholder="Days" [min]="1" [max]="31"
                     [(ngModel)]="patientEvent.repeat_number" [required]="patientEvent.recur_type === 'day'">
            </div>
            <div class="col-sm-2 col-xs-4">
              <label class="field-label control-label">day<span *ngIf="patientEvent.repeat_number > 1">(s)</span></label>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="patientEvent.recur_type === 'week'">
          <div class="row form-group">
            <div class="col-sm-2 hidden-xs"></div>
            <div class="col-sm-3 col-xs-3">
              <label class="field-label control-label">Recur Every</label>
            </div>
            <div class="col-sm-2 col-xs-9 p0">
              <input type="number" class="form-control" name="week_recur_every" placeholder="Week" [min]="1" [max]="5"
                     [(ngModel)]="patientEvent.repeat_number" [required]="patientEvent.recur_type === 'week'">
            </div>
          </div>
          <div class="row form-group">
            <div class="col-sm-2 hidden-xs"></div>
            <div class="col-sm-3 col-xs-3">
              <label class="field-label control-label">Weeks On</label>
            </div>
            <div class="col-sm-7 col-xs-9 p0">
              <ul class="list-unstyled m0 mt-sm">
                <li>
                  <input id="day_monday" type="checkbox" [(ngModel)]="patientEvent.monday" [checked]="patientEvent.monday" name="monday" [value]="patientEvent.monday ? 1 : 0"/>
                  <label class="ml-lg" for="day_monday">Monday</label>
                </li>
                <li>
                  <input type="checkbox" [(ngModel)]="patientEvent.tuesday" [checked]="patientEvent.tuesday" name="tuesday" [value]="patientEvent.tuesday ? 1 : 0" id="day_tuesday"/>
                  <label for="day_tuesday" class="ml-lg">Tuesday</label>
                </li>
                <li>
                  <input type="checkbox" [(ngModel)]="patientEvent.wednesday" [checked]="patientEvent.wednesday" name="wednesday" [value]="patientEvent.wednesday ? 1 : 0" id="day_wednesday"/>
                  <label for="day_wednesday" class="ml-lg">Wednesday</label>
                </li>
                <li>
                  <input type="checkbox" [(ngModel)]="patientEvent.thursday" [checked]="patientEvent.thursday" name="thursday" [value]="patientEvent.thursday ? 1 : 0" id="day_thrusday"/>
                  <label class="ml-lg" for="day_thrusday">Thursday</label>
                </li>
                <li>
                  <input type="checkbox" [(ngModel)]="patientEvent.friday" [checked]="patientEvent.friday" name="friday" [value]="patientEvent.friday ? 1 : 0" id="day_friday"/>
                  <label class="ml-lg" for="day_friday">Friday</label>
                </li>
                <li>
                  <input type="checkbox" [(ngModel)]="patientEvent.saturday" [checked]="patientEvent.saturday" name="saturday" [value]="patientEvent.saturday ? 1 : 0" id="day_saturday"/>
                  <label class="ml-lg" for="day_saturday">Saturday</label>
                </li>
                <li>
                  <input type="checkbox" [(ngModel)]="patientEvent.sunday" [checked]="patientEvent.sunday" name="sunday" [value]="patientEvent.sunday ? 1 : 0" id="day_sunday"/>
                  <label class="ml-lg" for="day_sunday">Sunday</label>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="patientEvent.recur_type === 'month'">
          <div class="row form-group">
            <div class="col-sm-2 hidden-xs"></div>
            <div class="col-sm-2 col-xs-2">
              <label class="field-label control-label">Day</label>
            </div>
            <div class="col-sm-2 col-xs-3 p0">
              <input type="number" class="form-control" name="monthly_Recur" placeholder="Day" [max]="31"
                     [(ngModel)]="patientEvent.month_repeat_day">
            </div>
            <div class="col-sm-2 col-xs-2">
              <label class="field-label control-label">of every</label>
            </div>
            <div class="col-sm-2 col-xs-3 p0">
              <input type="number" class="form-control" name="month_recur_every" placeholder="Month" [max]="12"
                     [(ngModel)]="patientEvent.repeat_number">
            </div>
            <div class="col-sm-2 col-xs-2">
              <label class="field-label control-label">month <span *ngIf="patientEvent.repeat_number > 1">(s)</span></label>
            </div>
          </div>
        </ng-container>
        <div class="row form-group">
          <div class="col-md-2 col-sm-3"></div>
          <div class="col-md-10 col-sm-9">
            <h5 class="m0">Range</h5>
            <label class="field-label control-label">
              <input type="radio" class="m0 ml" name="no_end_type_radio" [value]="'no_end'" [(ngModel)]="patientEvent.end_type"/>
              No End
            </label>
            <div class="row form-group">
              <div class="col-md-4 col-sm-5">
                <label class="field-label control-label">
                  <input type="radio" class="m0 ml" name="end_after_type_radio" [value]="'end_after'" [(ngModel)]="patientEvent.end_type"/>
                  Ends after
                </label>
              </div>
              <div class="col-md-2 col-sm-3">
                <input type="number" class="form-control" name="no_of_occurrences" placeholder="Occurrences"
                       [disabled]="patientEvent.end_type !== 'end_after'"
                       [(ngModel)]="patientEvent.no_of_occurrences">
              </div>
              <div class="col-md-6 col-sm-4">
                <label class="field-label control-label">Occurrences</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-5">
                <label class="field-label control-label">
                  <input type="radio" class="m0 ml" name="ends_by_type_radio" [value]="'ends_by'" [(ngModel)]="patientEvent.end_type"/>
                  Ends by
                </label>
              </div>
              <div class="col-md-4 col-sm-5">
                <input type="text" placeholder="End Date" name="end_date"
                       [(ngModel)]="patientEvent.end_by_date"
                       class="form-control"
                       [disabled]="patientEvent.end_type !== 'ends_by'"
                       bsDatepicker
                       [bsValue]="bsValue"
                       [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false, adaptivePosition: true}">
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <ng-container>
        <ng-container *ngIf="patientEvent.id; else saveButton">
          <button *ngIf="patientEvent.recur_type === 'never'" type="submit" class="btn btn-secondary"
                  [disabled]="!newPatientEventForm.form.valid">Update</button>
          <button *ngIf="patientEvent.recur_type !== 'never'" type="button" class="btn btn-secondary"
                  (click)="showEditConfirmation = true"
                  [disabled]="!newPatientEventForm.form.valid">Update</button>
        </ng-container>
        <ng-template #saveButton>
          <button type="submit" class="btn btn-secondary" [disabled]="!newPatientEventForm.form.valid">Save</button>
        </ng-template>
        <button type="button" class="btn btn-tertiary btn-outline" (click)="closeForm()">Cancel</button>
      </ng-container>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="showEditConfirmation">
  <div class="modal-body">
    <div class="alert alert-warning p pt-sm pb-sm m0 text-center">
      “This is a recurring event, any changes may effect future recurring events, do you want to continue?”
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-secondary" (click)="savePatientEvent(); showEditConfirmation = false">Yes</button>
    <button type="submit" class="btn btn-tertiary btn-outline" (click)="showEditConfirmation = false">No</button>
  </div>
</ng-container>

