<ul class="flex-header pd-custom-tabs nav nav-pills pb0">
  <li *ngFor="let stage_id of stage_ids"  [class.active]="selected_stage_id == stage_id ">
    <button class="btn" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="stage_id"
            (click)="onSelect(stage_id)">{{menu_items[stage_id]}}
    </button>
  </li>
</ul>
<app-outreach-communication-log class="flex-control flex-column flex-body" [patient]="patient" *ngIf="selected_stage_id === 0"></app-outreach-communication-log>
<app-patient-task-communication-log class="flex-control flex-column flex-body"  [patient]="patient" *ngIf="selected_stage_id === 1"></app-patient-task-communication-log>
<app-patient-notes class="flex-control flex-column flex-body"  [patient]="patient" *ngIf="selected_stage_id === 2"></app-patient-notes>
