<div class="flex-control flex-column" *ngIf="patient.id">
  <div class="p">
    <div class="user-profile-pic thumbnail">
      <div class="loader-pic" *ngIf="loaderService.uploading_in_process">
        <i class="fa fa-spinner fa-spin fs22"></i>
      </div>
      <span *ngIf="!loaderService.uploading_in_process" class="icon-holder" (click)="choose_file()">
        <i class="fa fa-upload fa-2x"></i>
        <h5>Upload Your Picture</h5>
      </span>
      <img *ngIf="patient.profile_pic" class="img-rounded" [src]="patient.profile_pic" alt="Image"/>
      <i *ngIf="patient.programmeable" class="eligible-icon" tooltip="Eligible for Program" style="top:78px">
        <img class="img-responsive" src="/assets/img/eligible-icon.png" alt="Image"/>
      </i>
    </div>
    <!--<div class="status-label" *ngIf="userService.current_user.isPatient">
      <label *ngIf="patient.portal_online_status" [ngClass]="patient.patient_online_status">
        Portal {{patient.patient_online_status}}
      </label>
    </div>-->
    <div class="status-label" *ngIf="userService.current_user.isPracticeUser">
      <label *ngIf="patient.mobile_online_status" [ngClass]="_appointment_service.show_status_class(patient.mobile_online_status)">
        {{_appointment_service.show_status_class(patient.mobile_online_status)}}
        <i class="fa icon-arrow-up"></i>
      </label>
      <div class="popover fade top">
        <div class="arrow" style="left:50%;"></div>
        <h3 class="popover-title">Login Status</h3>
        <div class="popover-content pt0">
          <label *ngIf="patient.mobile_online_status" [ngClass]="_appointment_service.show_status_class(patient.mobile_online_status)">
            Mobile {{_appointment_service.show_status_class(patient.mobile_online_status)}}
          </label>
          <label *ngIf="patient.portal_online_status" [ngClass]="_appointment_service.show_status_class(patient.portal_online_status)">
            Portal {{_appointment_service.show_status_class(patient.portal_online_status)}}
          </label>
        </div>
      </div>
    </div>
    <div class="text-center text-capitalize">
      <h4 class="fs20 text-secondary mt m0">{{patient.user.full_name}}</h4>
      <div class="age-code">
        <h5 class="fs16 m0 mt-sm fw-300">{{patient.show_age_and_gender()}}</h5>
        <h6 *ngIf="patient.mrn_no">{{patient.mrn_no}}</h6>
      </div>
    </div>
    <ng-container *ngIf="!userService.current_user.isPatient">
      <app-patient-communication [patient]="patient"></app-patient-communication>
    </ng-container>
    <!--    <ng-container *ngIf="userService.current_user && userService.current_user.isPatientUser()">
          <button [routerLink]="['/search']" [queryParams]="{app_type: 'office_visit'}" class="btn btn-secondary btn-block mt-lg" type="submit">BOOK APPOINTMENT</button>
          <button [routerLink]="['/search']" [queryParams]="{app_type: 'virtual_visit'}" class="btn btn-secondary btn-outline btn-block mt-lg" type="submit">BOOK VIRTUAL APPOINTMENT</button>
        </ng-container>-->
    <app-attachments (document_uploaded)="handle_image_upload($event)"
                     (popup_closed)="show_attachment_popup= false" [fileType]="'profile_pic'"
                     [only_modal]="true" [auto_upload]="true" [attachable_id]="patient.user.id" [attachable_type]="'User'"
                     [show]="show_attachment_popup"></app-attachments>
  </div>
  <ng-container  *ngIf="userService.current_user.isPracticeUser">
    <ng-container *ngIf="sticky_notes.length > 0">

      <div class="alert alert-warning verification-alert sticky-flex">
      <span class="scroll-browser sticky-span">
        <ng-container>
          <ul class="list-unstyled sticky-flex flex-control flex-column">
          <li *ngFor="let sticky of sticky_notes"><i class="fa fa-sticky-note"></i>

            <a (click)="openStickyNote(sticky)" class="note-text">
              {{sticky.note_body}}
            </a>
            <a class="pull-right p-lr" (click)="selectedStickyNoteId = sticky.id;deleteNoteModal.show()"><span style="color:#f05050;" ><i class="fa fa-trash"></i> </span></a>
          </li>
            </ul>
        </ng-container>
      </span>
      </div>



    </ng-container>
  </ng-container>

  <div class="flex-control flex-column">
    <h4 class="title text-black m0 hidden-xs">My Family
      <a (click)="handle_extra_fields();classicModal.show()"><i class="fa fa-plus fs12"></i> Add</a>
    </h4>
    <ul class="border-list list-unstyled flex-control flex-column scroll-browser hidden-xs">
      <li class="list" *ngFor="let family_member of patient.family_members">
        <a class="media" (click)="onSelect(family_member)">
          <span class="media-left thumb">
            <img class="img-circle thumb" [src]="family_member.profile_pic" alt="Image"/>
          </span>
          <span class="media-body media-middle">
            <small class="m0 mb-sm d-block fs14">{{family_member.name}}</small>
            <small class="m0 text-capitalize d-block">{{family_member.relationship_name}}</small>
          </span>
        </a>
        <span class="btn-hover-right" *ngIf="family_member.parent_id">
          <a class="btn text-danger fa fa-trash" (click)="deleteModal.show(); deleteCall(family_member)"></a>
        </span>
      </li>
    </ul>
  </div>
  <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
    <div class="modal-dialog modal-lg" style="height:606px">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
          <h4 class="m0 text-center">Add Family Member</h4>
        </div>
        <div class="modal-body">
          <app-demographics [patient]="family_patient" [show_extras]="show_extras_field" [add_family_member]="true" (new_user)="handle_family_addition($event);classicModal.hide()"></app-demographics>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
    <div class="modal-dialog" style="height:200px;">
      <div class="modal-content">
        <div class="modal-header">
          <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
          <h4 class="modal-title">Remove Family member</h4>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger p-sm m0 text-center">
            <span *ngIf="selectedFamilyMember.phone">
              Are you sure you want to remove family member
            </span>
            <span *ngIf="!selectedFamilyMember.phone">
              Mobile phone is required to make a patient independent of a family.
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-secondary" *ngIf="selectedFamilyMember.phone" (click)="removeRelationShip(selectedFamilyMember); this.deleteModal.hide()">Yes</button>
          <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">{{!selectedFamilyMember.phone ? 'Ok' : 'No'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteNoteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px; width:500px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteNoteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Unstick Note</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to unstick the note?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="unSricky(selectedStickyNoteId);deleteNoteModal.hide();">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="deleteNoteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #newNoteModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:400px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newNoteModel.hide(); show_node_model = false;"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Note</h4>
      </div>
      <ng-container *ngIf="show_node_model">
        <ng-container *ngComponentOutlet="newNoteComponent; injector: custom_injector;"></ng-container>
      </ng-container>
    </div>
  </div>
</div>
