import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AlertTemplateRule} from '../alert-template-rule';
import {AlertTemplateService} from '../alert-template.service';
import {UserService} from '../../../services/user.service';
import {Observable} from 'rxjs/Observable';
import {mergeMap} from 'rxjs/operators';
import {ProgramService} from '../../../program/program.service';
import {AlertDiagnosis} from '../alert-diagnosis';
import {VitalsService} from '../../../services/vitals.service';
import {AlertVital} from '../alert-vital';
import {AlertTemplate} from '../alert-template';
import {NgForm} from '@angular/forms';
import {AlertService} from '../../../_alert';

@Component({
  selector: 'app-alert-template-rule',
  templateUrl: './alert-template-rule.component.html',
  styleUrls: ['./alert-template-rule.component.scss']
})
export class AlertTemplateRuleComponent implements OnInit {
  @ViewChild('alertRulesForm') alertRulesForm: NgForm;
  @Input() alertTemplateRules: AlertTemplateRule;
  @Input() alertTemplate: AlertTemplate;
  public token = '';
  public data_source: Observable<any>;
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public vital_entries = [];
  public showAgeMinMaxField: boolean;
  @Output() ngSubmit: EventEmitter<boolean> = new EventEmitter()

  constructor(public alertTemplateService: AlertTemplateService, public userService: UserService,
              public programService: ProgramService, public vitalService: VitalsService,
              public alertService: AlertService) {
  }

  ngOnInit(): void {
    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).pipe(mergeMap((token: string) => this.programService.searchDiagnosis(token)));

    this.getVitalEntries();
    if (this.alertTemplateRules.age_trend === 'between') {
      this.showAgeMinMaxField = true;
    };
    if (this.alertTemplateRules.id) {
      this.ngSubmit.emit(true);
    }
  }
  changeTypeaheadLoading(e: boolean) {
    this.typeaheadLoading = e;
  }
  changeTypeaheadDiagnosisNoResults(e: boolean) {
    this.typeaheadNoResults = e
  }
  typeaheadOnSelect(e) {
    if (!this.diagnosisExists(e.item)) {
      this.alertTemplateRules.alert_diagnosis_rules.push(new AlertDiagnosis().load_from_new_object(e.item));
      this.checkFormIsValid();
    }
    this.token = '';
  }
  diagnosisExists(item) {
    return this.alertTemplateRules.alert_diagnosis_rules.some(function(el) {
      return el.library_datum_id === item.library_datum_id;
    });
  }

  removeTempDiagnosis(diagnosis: AlertDiagnosis) {
    if (diagnosis.id) {
      let objIndex = this.alertTemplateRules.alert_diagnosis_rules.findIndex((obj => obj.id === diagnosis.id));
      this.alertTemplateRules.alert_diagnosis_rules[objIndex]._destroy = true;
    } else {
      let index = this.alertTemplateRules.alert_diagnosis_rules.indexOf(diagnosis);
      this.alertTemplateRules.alert_diagnosis_rules.splice(index, 1)
    }
    this.checkFormIsValid();
  }

  onVitalEntryChange(id: any) {
    let vitalEntry = this.vital_entries.filter(a => a.id.toString() === id)[0];
    if (vitalEntry) {
      let vital_entry = new AlertVital();
      vital_entry.vital_sign = vitalEntry.name;
      vital_entry.vital_type_id = vitalEntry.id;
      vital_entry.short = vitalEntry.short.toLowerCase();
      vital_entry.show_min_max = false;
      if (!this.vitalsExists(vital_entry)) {
        this.alertTemplateRules.alert_vital_rules.push(vital_entry);
      } else {
        this.alertService.info('Vital Already Selected', this.alertService.options);
      }
    }
  }

  vitalsExists(item) {
    return this.alertTemplateRules.alert_vital_rules.some(function(el) {
      return (el.vital_type_id === item.vital_type_id && el._destroy === item._destroy);
    });
  }

  getVitalEntries() {
    this.vitalService.getVitals('').then(resp => {
      if (resp) {
        this.vital_entries = resp.filter((v, i, a) => a.findIndex(t => (t.short.toLowerCase() === v.short.toLowerCase())) === i);
        this.vital_entries = this.vital_entries.filter(a => a.short !== 'sleep');
      }
    });
  }
  ageDropdownChange(value: any) {
    if (value === 'between') {
      this.alertTemplateRules.age_value = undefined;
      this.showAgeMinMaxField = true;
    }
    if (value !== 'between') {
      this.alertTemplateRules.age_max_value = undefined;
      this.alertTemplateRules.age_min_value = undefined;
      this.showAgeMinMaxField = false;
    }
  }
  checkFormIsValid() {
    if (this.alertTemplate.alert_type === 'disease_management') {
      return this.ngSubmit.emit((this.alertTemplateRules.alert_diagnosis_rules.length > 0 && this.alertRulesForm.form.valid) ? true : false)
    } else {
      return this.ngSubmit.emit(this.alertRulesForm.form.valid)
    }
  }
}
