<div class="slots-panel flex-control flex-column">
  <ng-container *ngIf="Object.keys(grouped_slots) && Object.keys(grouped_slots).length > 0">
    <ng-container *ngFor="let location_id of Object.keys(grouped_slots)">
      <div class="flex-body flex-control flex-column">
        <div class="flex-header slots-head">
          <h5 class="text-center m0 lhn">
            <span class="fw-700 pr">{{locations_names[location_id]}}</span>
            <span class="text-tertiary">
            <i class="fa icon-clock"></i> {{locationService.timeZoneName(location_time_zones[location_id])}}
          </span>
          </h5>
          <h6 class="text-center m0 mt-sm vl-m lhn"><i class="fa icon-location-pin"></i> {{location_address[location_id]}}</h6>
        </div>
        <ul class="list-unstyled flex-body slots-body scroll-browser">
          <li *ngFor="let date of dates_text;let i = index">
            <ng-container *ngFor="let time of Array(this.max_slots).fill(1);let counter = index">
              <ng-container *ngIf="slots_options.per_location > counter || slots_options.show_all">
                <ng-container *ngIf="grouped_slots[location_id] && grouped_slots[location_id][date] && grouped_slots[location_id][date][counter]; else empty_slot">
                  <div *ngIf="grouped_slots[location_id][date][counter].is_available;" [class.clearfix]="i == 0">
                    <a (mouseleave)="deselectHoveredDate()" (mouseenter)="selectHoveredDate(grouped_slots[location_id][date][counter])" class="btn btn-secondary btn-outline" [class.selected]="grouped_slots[location_id][date][counter] == selected_slot"
                       (click)="show_future_slot ? book_appointment(grouped_slots[location_id][date][counter]): today_slots_only(date,grouped_slots[location_id][date][counter])">
                      {{grouped_slots[location_id][date][counter].datetime | date: 'shortTime'}}
                    </a>
                  </div>
                </ng-container>
                <ng-template #empty_slot>
                  <div class="dash-slot">---</div>
                </ng-template>
              </ng-container>
            </ng-container>
          </li>
        </ul>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="Object.keys(grouped_slots) && Object.keys(grouped_slots).length < 1">
    <h5 class="no-available alert alert-danger">Not Available</h5>
  </ng-container>

  <div class="more-btn flex-footer text-center" *ngIf="slots_options.has_more">
    <button *ngIf="!slots_options.show_all" class="btn btn-tertiary btn-outline" (click)="slots_options.show_all = true">
      More <i class="fa fa-chevron-down"></i>
    </button>
    <button *ngIf="slots_options.show_all" class="btn btn-tertiary btn-outline" (click)="slots_options.show_all = false">
      Less <i class="fa fa-chevron-up"></i>
    </button>
  </div>
</div>
