import { Injectable } from '@angular/core';
import {Tab} from '../tabs/tab'
import {EnoteService} from './enote.service';
import {Subject} from 'rxjs';
import {EnrolledPatientComponent} from '../program/enrolled-patient/enrolled-patient.component';
import {ProgramEnrollment} from '../program/eligible-patient';
@Injectable()
export class TabsService {
  public tabs_directory: Object = {}
  public active_type: string;
  public show_print = false;
  public tab_data: Object = {};
  startStopEnrollmentObjectObservable: Subject<any> = new Subject<any>();
  closeEnrolledPatientComponent: Subject<any> = new Subject<any>();
  constructor(private enoteService: EnoteService) {
  }


  close_tab(tab) {
    if (tab.dependencies['enrolment']) {
      this.closeEnrolledPatientComponent.next(tab.dependencies['enrolment']);
    }
    this.tabs.splice(this.tab_index(tab), 1);
    if (this.enoteService.template && !this.enoteService.template.isPrintAble()) {
      delete this.enoteService.template
    }
  }

  onlyCloseTab(tab) {
    this.tabs.splice(this.tab_index(tab), 1);
  }

  set_data(data) {
    this.tab_data[this.active_type] = data;
  }

  get_data() {
    return this.tab_data[this.active_type];
  }

  open_tab(tab) {
    this.tabs?.forEach((t: Tab) => t.active = false);
    tab.active = true;
    this.startStop()
  }

  startStop() {
    this.tabs?.forEach((tab: Tab) => {
      if (tab.dependencies['enrolment']) {
        this.startStopEnrollmentObjectObservable.next({program_id: tab.dependencies['program']['id'],
          patient_id: tab.dependencies['patient']['id'], enrolment: tab.dependencies['enrolment'], status: tab.active ? 'active' : 'inactive'})
      }
    })
  }
  stop() {
    this.tabs?.forEach((tab: Tab) => {
      if (tab.dependencies['enrolment']) {
        this.startStopEnrollmentObjectObservable.next({program_id: tab.dependencies['program']['id'],
          patient_id: tab.dependencies['patient']['id'], enrolment: tab.dependencies['enrolment'], status: 'inactive'})
      }
    })
  }

  clearAllTabsOnceForAll() {
    this.tabs_directory = {}
  };

  add_tab(tab) {
    let existing_tab = this.tabs.filter(t => t.component === tab.component && t.unique_id === tab.unique_id)[0];
    if (existing_tab) {
      this.open_tab(existing_tab);
    } else {
      this.tabs.push(tab);
      this.open_tab(tab);
    }
  }

  register(type: string) {
    this.active_type = type;
    if (!this.tabs_directory[this.active_type]) {
      this.tabs_directory[this.active_type] = []
    }
  }

  clear_tabs() {
    this.tabs = []
  }

  set tabs(data) {
    this.tabs_directory[this.active_type] = data;
  }

  deactivate_tab() {
    let active_tab = this.active_tab();
    if (active_tab) {
      active_tab.active =  false
    }
  }

  get tabs() {
    return this.tabs_directory[this.active_type];
  }

  is_active() {
    return this.active_tab()
  }

  not_active() {
    return !this.is_active();
  }

  tab_index(tab) {
    return  this.tabs.indexOf(tab)
  }

  active_tab() {
    return this.tabs.filter(t => t.active)[0]
  }


  after_close(index) {
    let next_tab = this.tabs[index];
    if (!next_tab && this.tabs.length) {
      next_tab = this.tabs[index - 1]
    }
    if (next_tab) {
      this.open_tab(next_tab);
      return next_tab;
    }
  }

}
