<div class="main-wrapper" [ngSwitch]="practice.client.status_id != 'completed' && practice.staffs.length > 0">
  <div [ngSwitch]="practice.client.status_id != 'completed' && practice.staffs.length > 0">
    <div *ngSwitchCase="true" class="text-center white-container-box p-lg">
      <h4 class="mb-xl mt">Please confirm Practice</h4>
      <button *userCan="practice_permissions" class="btn btn-primary btn-lg" type="button" (click)="classicModal.show();">Confirm</button>
    </div>
    <div *ngSwitchCase="false">
      <div [ngSwitch]="practice.staffs.length > 0">
        <div class="confirm alert alert-success fs16 text-center" *ngSwitchCase="true">Practice has been successfully Registered</div>
        <div class="alert alert-warning fs16 text-center" *ngSwitchCase="false">Please Add Staff First</div>
      </div>
    </div>
  </div>
</div>

<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:280px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Confirm Practice</h4>
      </div>
      <div class="modal-body">
        <p class="text-center m0">Status of the practice will change to Complete. Record will move to practice tab.Email is sent to the person who
          sent registration request. A Practice Admin user will be created for the first contact.</p>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-primary" (click)="confirm();classicModal.hide();">Confirm</button>
        <button type="button" class="btn btn-inverse" (click)="classicModal.hide();">Cancel</button>
      </div>
    </div>
  </div>
</div>
