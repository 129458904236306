<div class="filters-sec flex-header">
  <a class="btn btn-primary btn-outline btn-filter mb" data-toggle="collapse" href="#collapsefilters">
    <i class="fa fa-filter mr-sm"></i> Filters
  </a>
  <div class="collapse" id="collapsefilters">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-6">
        <input class="form-control" placeholder="Name" [(ngModel)]="filter['name']" (keyup.enter)="search()" type="text">
      </div>
      <div class="col-lg-4 col-md-3 col-sm-6">
        <button class="btn btn-tertiary btn-outline mr-sm" type="button" (click)="search()"><i class="fa fa-filter fa-fw"></i> Filter</button>
        <button class="btn btn-secondary" type="button" (click)="addAmenity()">Add</button>
      </div>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
      <tr>
        <th>Name</th>
        <th>Short Description</th>
        <th style="min-width:150px;">Method</th>
        <th class="text-center" style="min-width:50px;">Duration</th>
        <th class="text-center">No of booking/day</th>
        <th class="text-center">Auto Acknowledge</th>
        <th class="text-center">Payment Method</th>
        <th class="text-center">Created On</th>
        <th class="text-center" style="min-width:90px;">Created By</th>
        <th class="text-center">Modified On</th>
        <th class="text-center" style="min-width:100px;">Modified By</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody *ngIf="amenity_list.length > 0">
      <ng-container *ngFor="let amenity of amenity_list">
        <tr>
          <td><a (click)="viewAmenity(amenity)">{{amenity.name}}</a></td>
          <td><span class="media-body media-middle m0" [innerHTML]="amenity.short_description | safeHtml"></span></td>
          <td>
            <ng-container *ngFor="let method of amenity.amenity_methods">
              <p class="m0">{{method.name}} <span class="vl-m pl-sm">{{method.fee}}$</span></p>
            </ng-container>
          </td>
          <td class="text-center"><ng-container *ngIf="amenity.duration">{{amenity.duration}} min</ng-container></td>
          <td class="text-center">{{amenity.no_of_bookings_per_day}}</td>
          <td class="text-center text-capitalize">{{amenity.auto_acknowledge}}</td>
          <td class="text-center">{{amenityService.removeUnderScore(amenity.payment_method)}}</td>
          <td class="text-center">{{amenity.created_on}}</td>
          <td class="text-center text-capitalize">{{amenity.created_by}}</td>
          <td class="text-center">{{amenity.modified_on}}</td>
          <td class="text-center">{{amenity.modified_by}}</td>
          <td>
            <select #status [(ngModel)]="amenity.status" (change)="amenityStatusChange(amenity)" name="active" [ngClass]="amenity.status === 'active' ? 'active' : 'inactive'" class="form-control short-select">
              <option value="active">Active</option>
              <option value="deactive">In Active</option>
            </select>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <app-no-result-found *ngIf="amenity_list && amenity_list.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center" *ngIf="amenity_list && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>

