<h2 class="fs22 mt-xl">Sign In</h2>

<form (ngSubmit)="doLogin()" #LoginForm="ngForm" class="form-horizontal mb">

  <div class="row form-group">
    <div class="col-xs-12">
      <label>Email*</label>
      <input (click)="callme();" type="text" class="form-control" id="email" [(ngModel)]="user.email"
             name="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" required>
      <div *ngIf="customValidations.formErrors.email" style="color: red">
        {{ customValidations.formErrors.email }}
      </div>
    </div>
  </div>

  <div class="row form-group">
    <div class="col-xs-12">
      <label>Password*</label>
      <input type="password" class="form-control" id="password" [(ngModel)]="user.password" name="password" required>
      <div *ngIf="customValidations.formErrors.password" style="color: red">
        {{ customValidations.formErrors.password }}
      </div>
    </div>
    <ng-container *ngIf="error_message" class="pl-sm">
      <p style="color: red">Invalid email or password</p>
    </ng-container>
    <ng-container *ngIf="show_error">
      <div>
        <span class="text-danger">{{_output}}</span>
      </div>
    </ng-container>
  </div>

  <div class="row">
    <div class="col-xs-6">
      <div class="checkbox c-checkbox">
        <label>
          <input type="checkbox" value="" name="account_remember"/>
          <span class="fa fa-check"></span>Remember Me</label>
      </div>
    </div>
    <div class="col-xs-6">
      <div class="pull-right"><a class="" [routerLink]="'/recover'">Forgot your password?</a></div>
    </div>
  </div>

  <div class="mt-xl">
    <button type="submit" class="btn btn-primary btn-lg fs20" [disabled]="!LoginForm.form.valid">Sign In</button>
  </div>
</form>
