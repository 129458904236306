import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-health-calendar-header',
  templateUrl: './health-calendar-header.component.html',
  styleUrls: ['./health-calendar-header.component.scss']
})
export class HealthCalendarHeaderComponent implements OnInit {
  @Input() view: CalendarView;
  @Input() viewDate: Date;
  @Input() weekStartsOn;
  @Input() locale = 'en';
  @Output() viewChange = new EventEmitter<CalendarView>();
  @Output() viewDateChange = new EventEmitter<Date>();
  @Output() refresh = new EventEmitter<Date>();
  CalendarView = CalendarView;
  @Output() printReport = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void { }

  onValueChange(value: Date) {
    this.viewDate = value;
    this.viewDateChange.next(this.viewDate)
  }
}
