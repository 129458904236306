import {Component, Injectable, Input, OnInit, TemplateRef} from '@angular/core';
import {
  CalendarDateFormatter,
  CalendarDayViewBeforeRenderEvent,
  CalendarEvent,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent, DateFormatterParams,
} from 'angular-calendar';
import {Appointment} from '../../../../health/models/appointment';
import {AppointmentService} from '../../../../services/appointment.service';
import {Tab} from '../../../../tabs/tab';
import {TabsService} from '../../../../services/tabs.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {WalkInComponent} from '../../todays-patients/walk-in/walk-in.component';
import {LoaderService} from '../../../../services/loader.service';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {formatDate} from '@angular/common';

@Injectable() export class CustomDateFormatter extends CalendarDateFormatter {

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'shortTime', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }
}


@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],

})
export class CalendarViewComponent implements OnInit {
  @Input() view: CalendarView;
  @Input() viewDate;
  selectedEvent;
  show_popover = false;
  @Input() showHeader = false;
  isCollapsed = false;
  hourSegments;
  dayEndHour;
  dayEndMinute;
  dayStartHour;
  dayStartMinute;
  break_starts_at;
  break_ends_at;
  start: Date;
  end: Date;
  show_this = false;
  @Input() calendar_start: Date;
  @Input() calendar_end: Date;
  @Input() refresh
  c_time;
  public appointment: Appointment = new Appointment();

  @Input() public events: CalendarEvent[] = [];
  @Input() public doctorInfo;
  @Input() public calenderInfo;
  tooltipPlacement = 'top';

  modalRef: BsModalRef;
  days_mapping = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
  };
  hourDuration: any;

  constructor(public loaderService: LoaderService,
              private modalService: BsModalService,
              public appointmnet_service: AppointmentService,
              public tabs_service: TabsService) {

  }

  ngOnInit(): void {
    let time  = this.viewDate.toLocaleString('en-US', {timeZone: this.doctorInfo.location.time_zone})
    this.viewDate = new Date(time);
    this.c_time = this.current_time();
    this.start = new Date(this.calenderInfo.from)
    this.end = new Date(this.calenderInfo.to)
    this.break_starts_at = this.calenderInfo.break_starts_at == null ? 0 : this.calenderInfo.break_starts_at.split(':')[0];
    this.break_ends_at = this.calenderInfo.break_ends_at == null ? 0 : this.calenderInfo.break_ends_at.split(':')[0];
    this.dayStartHour = this.calenderInfo.day_starts_at.split(':')[0];
    this.dayStartMinute = this.calenderInfo.day_starts_at.split(':')[1];
    this.dayEndHour = this.calenderInfo.day_ends_at.split(':')[0];
    this.dayEndMinute = this.calenderInfo.day_ends_at.split(':')[1];
    // this.hourSegments = 60 / this.calenderInfo.slot_size;
    this.hourDuration = this.calenderInfo.slot_size;
  }


  setView(view: CalendarView) {
    this.view = view;

  }
  fetchEvents(): void {
  }
  capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  on(event, template) {
    this.loaderService.show();

    this.appointment = event.event.meta.appointment
    this.modalRef = this.modalService.show(template);
    this.loaderService.hide();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  clicked_event(event) {
    this.selectedEvent = event.event;
    this.show_popover = true;
  }

  handleAction(type) {
    this.show_popover = false;
    if (type && this.modalRef) {
      this.modalRef.hide();
    }
  }

  hourSegmentClicked(event) {
    let title = 'Book'
    let current_time = this.current_time();
    if ( (eval('this.calenderInfo.' + this.days_mapping[event.date.getDay()])) && !(this.checkBreakMin(event.date)) && (event.date  >= this.start && event.date <= this.end) && !(this.blockCalendarExist(event)) && (this.calenderInfo.is_active)) {

      this.tabs_service.add_tab(new Tab(
        {title: title, icon: 'fa fa-hospital', autorefresh: false},
        WalkInComponent,
        title,
        {
          flow_type: title,
          appointment: new Appointment(),
          booking_from_calendar: true,
          selected_doctor: this.doctorInfo,
          selected_location: this.calenderInfo['location_id'],
          calendar_info: this.calenderInfo,
          event : event
        })
      )
    }
    this.refresh.next();
  }

  beforeDayViewRender(renderEvent: CalendarDayViewBeforeRenderEvent) {
    let current_time = this.current_time();
    this.checkEveryDay(renderEvent, current_time)
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    let current_time = this.current_time();
    this.checkEveryDay(renderEvent, current_time)
  }

  checkEveryDay(renderEvent, current_time) {
    renderEvent.hourColumns.forEach((hourColumn) => {
      hourColumn.hours.forEach((hour) => {

        hour.segments.forEach((segment) => {
          if ( (eval('this.calenderInfo.' + this.days_mapping[segment.date.getDay()])) && (segment.date  >= this.start && segment.date <= this.end) && (this.calenderInfo.is_active)) {
            if ((this.checkBreakMin(segment.date))) {
              segment.cssClass = 'disable-slots';
            }
            if (this.blockCalendarExist(segment)) {
              segment.cssClass = 'disable-slots';
            }
          }
          else {
            segment.cssClass = 'disable-slots'
          }
        });

      });
    });
  }


  add_to_tabs(appointment) {
    let title = appointment.patient_name() + '-' + 'Profile';
    let id = appointment.patient_id;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id, {id: id, appointment: appointment}))
  }
  current_time() {
    let time = new Date();
    let c_time  = time.toLocaleString('en-US', {timeZone: this.doctorInfo.location.time_zone})
    time = new Date(c_time);
    return time.getTime();

  }
  dateCheck(check, from, to) {
    let val: any = new Date(check.getTime());
    val.setHours(0);
    val.setMinutes(0);
    let fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(val);

    return (cDate <= lDate && cDate >= fDate);
  }
  blockCalendarExist(event): boolean {
    if (this.doctorInfo.doctor.block_calendars_array) {
      let res = this.doctorInfo.doctor.block_calendars_array.some(block => {
        if (this.dateCheck(event.date, block.from, block.to) && (this.checkBlockCalendarMin(event.date, block))) {
          return true
        }
        else {
          return false;
        }
      })
      return res;
    } else {
      return false;
    }
  }
  checkBlockCalendarMin(event, block): boolean {
    let startTime  = block.start_time;
    let endTime = block.end_time;
    let startDate: any = new Date(event.getTime());
    startDate.setHours(startTime.split(':')[0]);
    startDate.setMinutes(startTime.split(':')[1]);

    let endDate: any = new Date(event.getTime());
    endDate.setHours(endTime.split(':')[0]);
    endDate.setMinutes(endTime.split(':')[1]);


    return Date.parse(startDate) <= Date.parse(event) && Date.parse(endDate) > Date.parse(event);
  }
  checkBreakMin(event): boolean {
    if (this.calenderInfo.break_starts_at && this.calenderInfo.break_ends_at) {
      let startTime = this.calenderInfo.break_starts_at;
      let endTime = this.calenderInfo.break_ends_at;
      let startDate = new Date(event.getTime());
      startDate.setHours(startTime.split(':')[0]);
      startDate.setMinutes(startTime.split(':')[1]);

      let endDate = new Date(event.getTime());
      endDate.setHours(endTime.split(':')[0]);
      endDate.setMinutes(endTime.split(':')[1]);
      let valid = startDate <= event && endDate > event;
      return valid;
    } else {
      return false
    }
  }
}
