import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationComponent } from './integration/integration.component';
import {IntegrationService} from './integration.service';
import { CuremdIntegrationCredentialsComponent } from './curemd-integration-credentials/curemd-integration-credentials.component';
import { FormsModule } from '@angular/forms';
import { IntegratedVendorComponent } from './integrated-vendor/integrated-vendor.component';
import {NgxMaskModule} from 'ngx-mask';
import { ProgramIntegrationIndexComponent } from './program-integration/program-integration-index/program-integration-index.component';
import { ProgramIntegrationComponent } from './program-integration/program-integration/program-integration.component';
import { ProgramIntegrationsComponent } from './program-integration/program-integrations/program-integrations.component'
import {ModalModule} from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [IntegrationComponent, CuremdIntegrationCredentialsComponent, IntegratedVendorComponent, ProgramIntegrationIndexComponent, ProgramIntegrationComponent, ProgramIntegrationsComponent],
  exports: [IntegrationComponent, CuremdIntegrationCredentialsComponent, IntegratedVendorComponent, ProgramIntegrationIndexComponent],
  imports: [CommonModule, FormsModule, NgxMaskModule.forRoot(), ModalModule],
  providers: [IntegrationService]
})
export class IntegrationModule { }
