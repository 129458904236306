import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {PaymentService} from '../../../../services/payment.service';
import {LoaderService} from '../../../../services/loader.service';
import {Appointment} from '../../../../health/models/appointment';
import {Payment} from '../payment';
import * as jsPdf from 'jspdf';
import {AppointmentService} from '../../../../services/appointment.service';

@Component({
  selector: 'app-single-payment',
  templateUrl: './single-payment.component.html',
  styleUrls: ['./single-payment.component.scss']
})
export class SinglePaymentComponent implements OnInit {
  @ViewChild('print') print: ElementRef;
  public payment: Payment = new Payment();
  public appointment: Appointment;
  public today = new Date();
  public payment_info: any = [];


  constructor(private injector: Injector, private paymentService: PaymentService, private appointmentService: AppointmentService) { }

  ngOnInit() {

    let payment = this.injector.get('payment', null);
    if (payment) {
    this.payment = this.payment.load_from_json(payment);
    }




    let appointment = this.injector.get('appointment', null);

    if (appointment) {
      this.appointmentService.getAppointment(appointment).then(resp => {
        this.appointment = new Appointment().load_from_json(resp);
        this.get_single_payment();
      })
    }

  }


  get_single_payment() {

      this.paymentService.getPrintPaymentDate(this.appointment.id).then(resp => {
        this.payment_info = resp;

      });

  }

  generatePdf(action) {
    let pdf = new jsPdf({orientation: 'p', unit: 'in', format: [3, 7] } );
    let options = {format: 'PNG'};
      pdf.addHTML(this.print.nativeElement, 0, 0,  options
        , () => {
      if (action == 'print') {
        pdf.autoPrint();
        let string = pdf.output('datauristring');
        let iframe = '<iframe width=\'100%\' height=\'100%\' src=\'' + string + '\'></iframe>';
        let x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
      }
      if (action == 'save') {
        pdf.save('recipt.pdf');
      }
    });
  }

}
