import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {ProgramService} from '../../program.service';
import {PatientVital} from '../../../routes/practice-site/vital/PatientVital';
import {Program} from '../../program';
import {VitalComponent} from '../../../routes/practice-site/vital/vital.component';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {EligiblePatient} from '../../eligible-patient';
import {VitalsService} from '../../../services/vitals.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-enrolled-patient-vitals',
  templateUrl: './enrolled-patient-vitals.component.html',
  styleUrls: ['./enrolled-patient-vitals.component.scss']
})
export class EnrolledPatientVitalsComponent implements OnInit, OnDestroy {
  public patientVitals: PatientVital[] = [];
  public dateRangeValue: Date[];
  @Input() patient_id: any;
  @Input() patient: EligiblePatient;
  @Input() program: Program;
  public filter = {no_of_days: 'last_seven_days', duration: '', program_id: '', patient_id: '', from: '', to: ''};
  public vital_data: any;
  public custom_injector: Injector;
  public add_patient_vital = false;
  public vitalComponent = VitalComponent;
  public show_patient_vitals: boolean;
  @ViewChild('vitalModal') vitalModal: ModalDirective;
  public vitals_view = 'daily';
  public daily_view_month = 'current'
  public minDate: Date;
  public maxDate: Date;
  public today_date = new Date();
  public graph_no_of_days =  'last_30_days';
  public customFromDateGraph: string;
  public customToDateGraph: string;
  public subscription: Subscription;
  public report_no_of_days =  'current_month';
  public customToDateReport: string;
  public customFromDateReport: string;
  public report_type = 'summary';
  constructor(public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public programService: ProgramService, public injector: Injector, public vitalsService: VitalsService) {
    this.subscription = this.vitalsService.reloadEnrolledPatientVitalsList.subscribe(item => {
      if (item) {
        console.log(item)
        if (this.vitals_view === 'flowSheet') {
          this.getPatientVitals();
        }
      }
    });
  }

  ngOnInit(): void {
    let inputProviders = [{provide: 'patient_id', useValue: this.patient_id}];
    this.custom_injector  = Injector.create(inputProviders, this.injector);
    this.setDailyViewMonth('current')
  }

  getPatientVitals() {
    this.loaderService.show();
    this.setFilters();
    this.programService.getPatientVitals(this.filter).then(resp => {
      this.vital_data = resp;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
      console.log(resp);
    });
  }

  search() {
    this.getPatientVitals();
    console.log(this.filter)
  }

  setDateRange(event: Date[]) {
    if (event) {
      this.filter['from'] = String(event[0]);
      this.filter['to'] = String(event[1]);
    }
  }
  setFilters() {
    this.filter['program_id'] = String(this.program.id);
    this.filter['patient_id'] = String(this.patient_id);
    if (this.filter['no_of_days'] !== 'custom') {
      delete this.filter['from'];
      delete this.filter['to'];
    }
    return this.filter
  }
  addVital() {
    this.loaderService.show();
    let inputProviders = [{provide: 'patient_id', useValue: this.patient_id}];
    this.custom_injector  = Injector.create(inputProviders, this.injector);
    this.add_patient_vital = true;
    this.vitalModal.show();
    setTimeout(() => {
      this.loaderService.hide();
    }, 4000);
  }

  close() {
    this.programService.programmePatient(this.program.id, this.patient_id).then(resp => {
      this.patient = this.patient.load_from_json(resp)
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
    this.vitalModal.hide();
    setTimeout(() => {
      this.show_patient_vitals = false;
      this.add_patient_vital = false;
    }, 100);
  }
  setDailyViewMonth(month: any) {
    this.daily_view_month = month;
    if ( month === 'current') {
      this.minDate = new Date(this.today_date.getFullYear(), this.today_date.getMonth() , 1);
      this.maxDate = new Date();
    }
    if ( month === 'previous' ) {
      this.minDate = new Date(this.today_date.getFullYear(), this.today_date.getMonth() - 1, 1);
      this.maxDate =  new Date(this.today_date.getFullYear(), this.today_date.getMonth() , 0);
    }
    if ( month === 'two_months_ago' ) {
      this.minDate = new Date(this.today_date.getFullYear(), this.today_date.getMonth() - 2, 1);
      this.maxDate =  new Date(this.today_date.getFullYear(), this.today_date.getMonth() - 1, 0);
    }
  }

  setGraphViewDays(value: string) {
    this.customToDateGraph = undefined;
    this.customFromDateGraph = undefined;
    return this.graph_no_of_days = value;
  }
  setDateRangeForGraph(event: Date[]) {
    if (event) {
      this.customFromDateGraph = String(event[0]);
      this.customToDateGraph = String(event[1]);
    }
  }

  setView(daily: string) {
    this.filter['no_of_days'] = 'last_seven_days';
    if (daily === 'flowSheet') {
      this.search();
    }
    this.vitals_view = daily;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setReportViewDays(value: string) {
    this.customToDateReport = undefined;
    this.customFromDateReport = undefined;
    return this.report_no_of_days = value;
  }
  setDateRangeForReport(event: Date[]) {
    if (event) {
      this.customFromDateReport = String(event[0]);
      this.customToDateReport = String(event[1]);
    }
  }

  createSleepReport() {
    this.programService.createReportListener.next(1);
  }
}
