import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {LocationService} from '../../../services/location.service';
import {Location} from './location';
import {Practice} from '../practices/practice'
import {SimpleChanges} from '@angular/core';
import {PracticesService} from '../../../services/practices.service';
import {Attachment} from '../attachments/attachment';
import {UserService} from '../../../services/user.service';
import {Calendar} from '../doctors/doctor';
import {BlockCalendar} from '../doctors/doctor';
import {PhoneService} from '../../../voice-call/phone.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})

export class LocationsComponent implements OnInit, OnChanges {
  public locations = Array<Location>();
  show_location = false;
  @Input() practice: Practice;
  @Input() practice_permissions: string;
  @Input()   doctor_permissions: string;

  public new_location: Location = new Location();
  public selected_index: number;
  public dom_count: number;
  public pencil: boolean;
  data: any = {};
  public selected_location: Location;
  public show_calendar_listing = true;
  public show_calendar_form = false;
  public calendar: Calendar = new Calendar();
  public show_block_calendar_listing = true;
  public show_block_calendar_form = false;
  public block_calendar: BlockCalendar = new BlockCalendar();

  constructor(public user_service: UserService, private locationsService: LocationService,
              public practiceService: PracticesService, public phoneService: PhoneService) {
  }
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      let cur = chng.currentValue;
      cur && this.locationsService.getLocations(cur.id).then(locations => {
        for (let location of locations) {
          this.locations.push(new Location().load_from_json(location));
          this.practice.locations = this.locations;
        }
      })
    }
  }
  gotoLocation() {
    this.show_location = !this.show_location;
  }
  ngOnInit() {
  }
  show_pencil(index) {
    this.dom_count = index;
    this.pencil = true;

  }
  hide_pencil() {
    this.pencil = false;
  }
  toggle_location() {
    this.selected_location = null;
    if (!(this.selected_index || this.selected_index == 0) && this.show_location) {
      this.hide_form();
    } else {
      this.selected_index = null;
      this.show_form();
    }
  }
  sync_location(location) {
    this.locations.push(location.load_from_json(location));
    this.practice.locations = this.locations;
    this.hide_form();
    this.new_location = new Location();
  }
  select_location(index) {
    this.selected_location.show_profile_detail = !this.selected_location.show_profile_detail
    this.show_form();
  }
  hide_form() {
    this.show_location = false;
  }
  show_form() {
    this.show_location = true;
  }
  reset_show_location(event) {
    this.hide_form();
  }
  delete(location, classicModal) {
      let status;
      this.locationsService.delete(location.id).then(response => {
          if (response.status) {
           this.locations.splice(this.locations.indexOf(location), 1);
          }
           this.practice.locations = this.locations;
           this.selected_location.toggle_view();
           this.selected_location = null;
           classicModal.hide()
      }
      )
  }

  removeLocationImage(location, attachment, index) {
    this.practiceService.removePhoto(attachment).then(res => {
      let loc = this.locations.filter(e => e.id == location.id)[0]
      if (loc.attachments) {
        loc.attachments.splice(index, 1)
      }
      return;
    });
  }

  makeActiveInactive(location_image) {
    location_image.active_class = !location_image.active_class;
    return;
  }

  handle_image_upload(data) {
    if (data.attachment_type == 'Location' && data.document_type == 'photo' && data.hasOwnProperty('error') == false) {
      let attach = new Attachment();
      attach = data;
      attach.img = data.profile_pic;
      attach.picture_type = 'photo';
      let loc = this.locations.filter(e => e.id == data.attachment_id)[0];
      if (loc.attachments) {
        attach.attachment_id = data.id;
        loc.attachments.push(attach);
      }
    } else {
      console.log('No condition match')
    }
  }
  selectLocation(location) {
    if (location.show_profile_detail) {
      this.selected_location = location;
    }
  }
  calendar_saved() {
    this.selected_location.calendars.push(this.calendar);
    this.calendar = new Calendar();
    this.show_calendar_listing = true;
    this.show_calendar_form = false;
  }
  calendar_form() {
    this.show_calendar_form = true;
    this.show_calendar_listing = false;
  }
  handle_hide_form() {
    this.show_calendar_form = false;
    this.show_calendar_listing = true;
  }

  block_calendar_saved() {
    this.selected_location.block_calendars.push(this.block_calendar);
    this.block_calendar = new BlockCalendar();
    this.show_block_calendar_listing = true;
    this.show_block_calendar_form = false;
  }

  handle_block_calendar_hide_form() {
    this.show_block_calendar_form = false;
    this.show_block_calendar_listing = true;
  }
  block_calendar_form() {
    this.show_block_calendar_form = true;
    this.show_block_calendar_listing = false;
  }

}
