import {Component, OnInit, OnDestroy, EventEmitter, Input, Output, ViewChild, Pipe, PipeTransform} from '@angular/core';
import { CameraComponent } from '../camera/camera.component';
import { DeviceSelectComponent } from './device-select.component';
import { DeviceService } from '../services/device.service';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { VideoChatService } from '../services/videochat.service';

@Component({
  selector: 'app-settings',
  styleUrls: ['./settings.component.scss'],
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit, OnDestroy {
  public devices: MediaDeviceInfo[] = [];
  private subscription: Subscription;
  private videoDeviceId: string;

  @ViewChild('camera', { static: false }) camera: CameraComponent;
  @ViewChild('videoSelect', { static: false }) video: DeviceSelectComponent;

  @Input('isPreviewing') isPreviewing: boolean;
  @Output() settingsChanged = new EventEmitter<MediaDeviceInfo>();
  @Output() callEnded = new EventEmitter<boolean>();
  get hasAudioInputOptions(): boolean {
    return this.devices && this.devices.filter(d => d.kind === 'audioinput').length > 0;
  }
  get hasAudioOutputOptions(): boolean {
    return this.devices && this.devices.filter(d => d.kind === 'audiooutput').length > 0;
  }
  get hasVideoInputOptions(): boolean {
    return this.devices && this.devices.filter(d => d.kind === 'videoinput').length > 0;
  }

  constructor(
    private readonly deviceService: DeviceService, public videoChatService: VideoChatService) { }

  ngOnInit() {
    this.subscription =
      this.deviceService
        .$devicesUpdated
        .pipe(debounceTime(350))
        .subscribe(async deviceListPromise => {
          this.devices = await deviceListPromise;
          console.log('Devices', this.devices);
          this.handleDeviceAvailabilityChanges();
        });
    // tslint:disable-next-line:radix
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.camera.closeCamera();
    this.videoChatService.countDown?.unsubscribe();
  }




  async onSettingsChanged(deviceInfo: MediaDeviceInfo) {
    if (this.isPreviewing) {
      await this.showPreviewCamera();
    } else {
      this.settingsChanged.emit(deviceInfo);
    }
  }

  async showPreviewCamera() {
    this.isPreviewing = true;
    this.videoDeviceId = this.video.selectedId;
    const videoDevice = this.devices.find(d => d.deviceId === this.video.selectedId);
    await this.camera.initializePreview(videoDevice);
    return this.camera.tracks;
  }

  hidePreviewCamera() {
    this.isPreviewing = false;
    return this.devices.find(d => d.deviceId === this.video.selectedId);
  }

  private handleDeviceAvailabilityChanges() {
    if (this.devices && this.devices.length && this.video && this.video.selectedId) {
      let videoDevice = this.devices.find(d => d.deviceId === this.video.selectedId);
      if (!videoDevice) {
        videoDevice = this.devices.find(d => d.kind === 'videoinput');
        if (videoDevice) {
          this.video.selectedId = videoDevice.deviceId;
          this.onSettingsChanged(videoDevice).then(r => console.log(r));
        }
      }
    }
  }

  onCallEnded() {
    this.callEnded.emit(true);
  }
}
@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: number): string {
    console.log(value);
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    console.log(('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2));
    return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}
