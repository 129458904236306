<ng-container *ngIf="announcements">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>Title</th><th>Detail</th><th>For</th><th>Created On</th>
      </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let announcement of announcements">
      <tr>
        <td><a (click)="open_in_tab(announcement)">{{announcement.name}}</a></td>
        <td>{{announcement.description}}</td>
        <td>
          <ng-container *ngFor="let role of announcement.selected_roles">{{role.itemName}}</ng-container>
        </td>
        <td>{{announcement.created_at | date: 'medium'}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <div class="text-center">
    <pagination class="m0" [totalItems]="totalItems" [itemsPerPage]="5" (pageChanged)="pageChanged($event)"></pagination>
  </div>
</ng-container>
