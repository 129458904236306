import {Component, OnInit, Input} from '@angular/core';
import {EnoteService} from '../../services/enote.service';
import {TabsService} from '../../services/tabs.service';
import {EnoteComponent} from '../enote/enote.component';
import {Tab} from '../../tabs/tab';
import {Template} from '../enote_models/template';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-templates-listing',
  templateUrl: 'templates-listing.component.html',
  styleUrls: ['./templates-listing.component.scss']
})
export class TemplatesListingComponent implements OnInit {
  @Input() template: Template;
  constructor(public _enote_service: EnoteService, public _tabs_service: TabsService, public userService: UserService) {

  }

  ngOnInit() {
    this._enote_service.get_templates();
  }

  add_enote(template, label) {
    let stage = (this.userService.current_user.isEnterprise && template && template.is_practice_template ) ? 'read_only_template' : label;
    let template_id
    if (template && template.id) {
      template_id = template.id;
    }
    this._tabs_service.add_tab(new Tab({title: 'Template-' + template_id, icon: 'fa fa-hospital', autorefresh: false},
      EnoteComponent, template_id, {id: template_id, template: template_id, stage: stage, label: stage}))
  }
  pageChanged(event) {
    this._enote_service.pageObservable.next(event.page)
  }
}
