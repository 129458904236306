import { Component, OnInit } from '@angular/core';
import { RatingsService } from '../../../services/ratings.service'
import {PatientReview} from '../../../health/doctor/patient_review';
@Component({
  selector: 'app-patient-reviews',
  templateUrl: './patient-reviews.component.html',
  styleUrls: ['./patient-reviews.component.scss']
})
export class PatientReviewsComponent implements OnInit {
  public stage_ids = [0, 1, 2];
  public menu_items: Object = {0: 'New', 1: 'Accepted', 2: 'Rejected'};
  public selected_stage_id: number;
  public patientReviews: PatientReview[] = [];
  public totalPatientReviews: PatientReview[] = [];
  public doctor_average_rating: number;
  public patientReview: PatientReview;
  public status: string;
  constructor(private _ratingService: RatingsService) {
    this.selected_stage_id = 0;
    this.fetchPatientReviews();

  }
  fetchPatientReviews() {
    this._ratingService.getPatientReviews(this.selected_stage_id).then(res => {
      let patientReviews = res.map(patientReview => new PatientReview().load_from_json(patientReview));
      this.patientReviews = patientReviews;
    });
  }
  ngOnInit() {
    this._ratingService.allPatientReviews().then(res => {
      let totalPatientReviews = res.map(patientReview => new PatientReview().load_from_json(patientReview));
      this.totalPatientReviews = totalPatientReviews;
      this.calc_average_rating();
    });

  }
  calc_average_rating() {
    let total_rating = 0;
    this.totalPatientReviews.map(patientReview => {
      total_rating = total_rating + patientReview.average_rating;
    });
    this.doctor_average_rating =  (total_rating / this.totalPatientReviews.length);
  }

  onSelect(stage_id) {
    this.selected_stage_id = stage_id;
    this.fetchPatientReviews();

  }
  changeStatus(index, status, text) {
     this.patientReviews[index].status = status;
     this.patientReview =  this.patientReviews[index];
     this.status = text;
  }
  update() {
    this._ratingService.update(this.patientReview, this.patientReview.id).then(res => {
      if (res) {
        this.patientReviews.splice(this.patientReviews.indexOf(this.patientReview), 1)
      }

    });
  }

}
