<div class="flex-control flex-column flex-height">
  <div class="todaysPatient-list headerPatient-list" *ngIf="programEnrollment?.id">
    <app-enrolled-patient-header [patient]="patient" [program_id]="program.id"
                                 [programEnrollment]="programEnrollment"
                                 [programme_type]="programme_type"
                                 (onPatientNameClick)="openEnrolledPatientProfile($event)"
                                 [isPatientDetailPage]="true">
    </app-enrolled-patient-header>
  </div>
  <ng-container *ngIf="sticky_notes.length > 0">

    <div class="alert alert-warning verification-alert sticky-flex hor-scroll-browser scroll-browser">
      <span>
        <ng-container>
          <ul class="list-unstyled">
          <li *ngFor="let sticky of sticky_notes"><i class="fa fa-sticky-note "></i>

            <a (click)="openStickyNote(sticky)" class="note-text">
              {{sticky.note_body}}
            </a>
            <a class="pull-right p-lr" (click)="selectedStickyNoteId = sticky.id;deleteModal.show()"><span style="color:#f05050;" ><i class="fa fa-trash"></i> </span></a>
          </li>
            </ul>
        </ng-container>
      </span>
    </div>



  </ng-container>

  <div class="flex-control bg-lightest-gray p-sm">
    <div class="flex-body flex-control">
      <ul class="flex-header pd-custom-tabs nav nav-pills p0 mb">
        <li *ngFor="let stage_id of stage_ids" [class.active]="selected_stage_id == stage_id">
          <button class="btn m0" *ngIf="stage_id !== 1 || program.vitals && program.vitals.length > 0"
                  [(ngModel)]="selected_stage_id"
                  name="selected_stage_id"
                  ngDefaultControl [value]="stage_id"
                  (click)="onMenuSelect(stage_id)">{{ menu_items[stage_id] }}
          </button>
        </li>
      </ul>
      <div class="flex-body border-box">
        <app-enrolled-patient-care-management class="flex-control flex-column"
                                              *ngIf="programEnrollment && selected_stage_id === 0"
                                              [programEnrollment]="programEnrollment"
                                              [patient]="patient">
        </app-enrolled-patient-care-management>
        <div class="flex-control flex-column" *ngIf="selected_stage_id === 4">
          <div class="flex-body scroll-browser p">
            <app-enrolled-patient-profile [patient_id]="patient.id"></app-enrolled-patient-profile>
            <app-patient-program *ngIf="program" [program]="program"
                                 [programEnrollment]="programEnrollment"></app-patient-program>
            <app-device-training [patient]="patient"></app-device-training>
          </div>
        </div>
        <app-enrolled-patient-vitals class="flex-control flex-column program-vitals" *ngIf="selected_stage_id === 1"
                                     [program]="program" [patient]="patient"
                                     [patient_id]="patient.id"></app-enrolled-patient-vitals>
        <app-enrolled-patient-care-plan class="flex-control flex-column program-detail-enote"
                                        *ngIf="selected_stage_id === 2" [program]="program" [patient]="patient"
                                        [programEnrollment]="programEnrollment"></app-enrolled-patient-care-plan>
        <app-documents class="flex-control flex-column" *ngIf="selected_stage_id == 3"
                       [patient]="patient"></app-documents>
        <app-enrolled-patient-consent class="flex-control flex-column" *ngIf="selected_stage_id == 5"
                                      [patient_id]="patient.id" [program]="program"
                                      [programEnrollment]="programEnrollment"></app-enrolled-patient-consent>
        <app-patient-setting class="flex-control flex-column" [patient]="patient"
                             *ngIf="selected_stage_id == 6"></app-patient-setting>
        <app-health-calendar class="flex-body flex-control flex-column calendar-component program-calendar"
                             [patient]="patient" *ngIf="selected_stage_id == 7"></app-health-calendar>
        <div class="flex-control flex-column" *ngIf="selected_stage_id == 8">
          <div class="flex-body scroll-browser p">
            <app-enrolled-patient-summary [patient]="patient"></app-enrolled-patient-summary>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-right flex-control flex-column overflow-visible">
      <div class="flex-header p0 mb b0">
        <div class="pull-right">
          <!--<a class="btn btn-secondary btn-outline btn-sm ml-sm" (click)="deleteAllAlerts()">Review All</a>-->
          <a class="btn btn-secondary btn-outline btn-sm ml-sm" (click)="changeStatus()">Update Status</a>
          <a class="btn btn-secondary btn-outline btn-sm ml-sm" (click)="logTimeFromButton.show();">Time Log</a>
          <a class="btn btn-secondary btn-outline btn-sm ml-sm"
             (click)="reportModel.openCloseModal(false , programEnrollment)" title="Report">
            <i class="fa icon-chart vl-m"></i>
          </a>
          <a class="btn btn-danger btn-outline btn-sm ml-sm" (click)="optOutModel.show()" title="Opt Out Request">
            <i class="fa icon-logout vl-m"></i>
          </a>
        </div>
        <ng-container
          *ngIf="patientForCommunication && patientForCommunication.id && programEnrollment && programEnrollment.id">
          <app-patient-communication
            [patient]="patientForCommunication"
            [isPatientDetailPage]="true"
            [sourceable_type]="'ProgrammeEnrolment'"
            [programme_type]="programme_type"
            [sourceable_id]="programEnrollment.id">
          </app-patient-communication>
        </ng-container>
      </div>
      <div class="flex-body border-box" *ngIf="programEnrollment && programEnrollment.id">
        <app-enrolled-patient-right-panel class="flex-control flex-column overflow-visible"
                                          [patient]="patientForCommunication" [programEnrollment]="programEnrollment"
                                          [programme_type]="programme_type"></app-enrolled-patient-right-panel>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #logTimeFromButton="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:400px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="logTimeFromButton.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Add Activity</h4>
      </div>
      <form (ngSubmit)="saveActivity()" #activityLogTime="ngForm">
        <div class="modal-body">
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label control-label">Type*</label>
            </div>
            <div class="col-md-4 col-sm-5">
              <select class="form-control" [(ngModel)]="add_activity.activity_type" name="activity_type" required>
                <option value='' selected>Activity Type</option>
                <option value="chart_review">Chart Review</option>
                <option value="email">Email</option>
                <option value="im">IM</option>
                <option value="phone_call">Phone Call</option>
                <option value="video_call">Video Call</option>
                <option value="sms">SMS</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label control-label">Note*</label>
            </div>
            <div class="col-md-10 col-sm-5">
              <textarea [(ngModel)]="add_activity.description" placeholder="Description"
                        class="form-control no-resize form-group"
                        style="height:120px;" name="description" required></textarea>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label control-label">Time*</label>
            </div>
            <div class="col-md-4 col-sm-5">
              <input type="number" class="form-control" placeholder="Minutes"
                     [(ngModel)]="add_activity.time_log_attributes.minutes" name="minutes" required>
            </div>
            <div class="col-xs-2 pl0"><label class="field-label control-label">min</label></div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-secondary" [disabled]="!activityLogTime.form.valid">Save</button>
          <button type="button" class="btn btn-tertiary btn-outline" (click)="logTimeFromButton.hide()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #optOutModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:260px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="optOutModel.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Remove patient from the program</h4>
      </div>
      <div class="modal-body">
        <p class="alert alert-danger p pt-sm pb-sm mb-lg text-center">Are you sure you want to remove patient from the
          program?</p>
        <div class="row">
          <div class="col-md-2 col-sm-3">
            <label class="field-label control-label">Reason*</label>
          </div>
          <div class="col-md-8 col-sm-5">
            <select class="form-control" [(ngModel)]="reason_opt_out" name="optOut" required>
              <option value='' selected>Reason</option>
              <option value="Patient Opt-Out">Patient Opt-Out</option>
              <option value="Patient is deceased">Patient is deceased</option>
              <option value="Patient joined another program">Patient joined another program</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary btn-min" [disabled]="!reason_opt_out"
                (click)="patientOptOut(program);">Yes
        </button>
        <button type="submit" class="btn btn-tertiary btn-outline btn-min" (click)="optOutModel.hide();">No</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #statusChangeModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:260px;">
    <div class="modal-content" *ngIf="showStatusModel">
      <div class="modal-header">
        <button class="close" type="button" (click)="statusChangeModel.hide();showStatusModel = false"><i
          class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Update Patient's Status</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2 col-sm-3">
            <label class="field-label control-label">Health Status</label>
          </div>
          <div class="col-xs-4">
            <select [(ngModel)]="programEnrollment.health_status" class="form-control" name="health_status"
                    id="health_status">
              <option value='stable'>Stable</option>
              <option value='moderate'>Moderate</option>
              <option value='unstable'>Unstable</option>
              <option value='critical'>Critical</option>
            </select>
          </div>
          <div class="col-md-2 col-sm-3">
            <label class="field-label control-label">Compliance Level</label>
          </div>
          <div class="col-xs-4">
            <select [(ngModel)]="programEnrollment.compliance_level" class="form-control" name="compliance_level"
                    id="compliance_level">
              <option value='compliant'>Compliant</option>
              <option value='partially_compliant'>Partially Compliant</option>
              <option value='non_compliant'>Non-Compliant</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary btn-min"
                (click)="updateHealthComplianceStatus(programEnrollment)">Yes
        </button>
      </div>
    </div>
  </div>
</div>

<app-report-selection #reportModel></app-report-selection>

<div class="modal fade" bsModal #newNoteModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:400px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newNoteModel.hide(); show_node_model = false;"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Note</h4>
      </div>
      <ng-container *ngIf="show_node_model">
        <ng-container *ngComponentOutlet="newNoteComponent; injector: custom_injector;"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px; width:500px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Unstick Note</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to unstick the note?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="unSricky(selectedStickyNoteId);deleteModal.hide();">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>


