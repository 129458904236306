import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Program} from '../../program';
import {TabsService} from '../../../services/tabs.service';
import {ProgramComponent} from '../../program/program.component';
import {Tab} from '../../../tabs/tab';
import {ProgramService} from '../../program.service';
import {LoaderService} from '../../../services/loader.service';
import {ProgramEnrolledDetailComponent} from '../../program-enrolled-detail/program-enrolled-detail.component';
import {Subscription} from 'rxjs/Subscription';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';

@Component({
  selector: 'app-programs-listing',
  templateUrl: './programs-listing.component.html',
  styleUrls: ['./programs-listing.component.scss']
})
export class ProgramsListingComponent implements OnInit {
  public filter: Object = {name: '', page: 1, status: 'opened', programme_category: ''};
  public totalItems;
  public selectedProgramToDelete;
  public programs: Program[] = [];
  @ViewChild('deleteModal') deleteModal;
  @Input() programme_category: any;
  public subscription: Subscription;

  constructor(public tabs_service: TabsService, public programService: ProgramService,
              public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService) {
    this.subscription = this.programService.reloadProgram.subscribe(item => {
      if (item) {
        this.programService.reloadProgram.next(0);
        this.getPrograms();
      }
    });
  }

  ngOnInit(): void {
    this.filter['programme_category'] = this.programme_category;
    this.getPrograms();
  }

  search() {
    this.filter['page'] = 1;
    this.getPrograms();
  }

  addProgram() {
    let program = new Program();
    this.tabs_service.add_tab(new Tab({title: 'Add Program', icon: 'fa'}, ProgramComponent,
      program.id, {id: program.id, program: program, programme_category: this.programme_category}))
  }

  pageChanged($event: any) {
    this.filter['page'] = $event.page;
    this.getPrograms();
  }

  viewProgram(program: Program) {
    this.tabs_service.add_tab(new Tab({title: program.name, autorefresh: false}, ProgramEnrolledDetailComponent, program.id,
      {program_id: program.id, program: program, programme_category: this.programme_category}))
  }

  deleteProgram(program: Program) {
    this.loaderService.show()
    this.programService.delete(program.id).then(resp => {
      this.programs.splice(this.programs.indexOf(program), 1);
      this.programService.reloadProgram.next(1);
      this.selectedProgramToDelete = ''
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.deleteModal.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }



  editProgram(program: Program) {
    this.tabs_service.add_tab(new Tab({title: program.name, icon: 'fa'}, ProgramComponent,
      program.id, {id: program.id, program: program, programme_category: this.programme_category}))
  }

  getPrograms() {
    this.loaderService.show();
    this.programService.getPrograms(this.filter).then(resp => {
      this.programs = resp.programmes.map(program => new Program().load_from_json(program));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  changeStatusOfProgram(program: Program) {
    this.loaderService.show();
    this.programService.save(program)
      .then(resp => {
        program = program.load_from_json(resp)
        this.loaderService.hide();
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }
}
