<div class="flex-control flex-column flex-height">
  <div class="flex-header">
    <h4 class="fs20 m0">{{ program.id ? 'Edit' : 'Add' }} Program</h4>
  </div>
  <div class="flex-body p scroll-browser">
    <form (ngSubmit)="save()" #programForm="ngForm" class="mb-lg">
      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label>Name*</label>
        </div>
        <div class="col-md-4 col-sm-5">
          <input type="text" class="form-control" placeholder="Name of Program"
                 [(ngModel)]="program.name" name="name" required>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label>Health Metrics to Monitor</label>
        </div>
        <div class="col-xs-10">
          <div class="row">
            <label class="col-md-2 col-sm-5 text-capitalize" *ngFor="let vital of temp_vitals_array; let i = index">
              <input [id]="vital.short" type="checkbox" [value]="vital.id" [name]="vital.short"
                     (change)="setProgramVitals($event)" [checked]="selectedProgramVitals([vital.short])"/>
              {{ vital.name }}
            </label>
            <label class="col-md-2 col-sm-5">
              <input id="bp" type="checkbox" [value]="'bp'" [name]="'bp'" (change)="setProgramVitals($event)"
                     [checked]="selectedProgramVitals(['sbp', 'dbp'])"/>
              Blood Pressure
            </label>
            <label class="col-md-2 col-sm-5">
              <input id="bg" type="checkbox" [value]="'bg'" [name]="'bg'" (change)="setProgramVitals($event)"
                     [checked]="selectedProgramVitals(['rbg', 'fbf'])"/>
              Blood Glucose
            </label>
          </div>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label>Billing Provider*</label>
        </div>
        <div class="col-md-4 col-sm-5">
          <select class="form-control" [(ngModel)]="program.billing_provider_id" name="billing_provider" required>
            <option value="" selected>Billing Provider Name</option>
            <option *ngFor="let doctor of providers; let i = index;" [value]="doctor.id">
              {{ doctor.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label>Rendering Provider</label>
        </div>
        <div class="col-md-4 col-sm-5">
          <select class="form-control" [(ngModel)]="program.care_manager_id" name="rendering_provider">
            <option value="" selected>Rendering Provider Name</option>
            <option *ngFor="let doctor of providers; let i = index;" [value]=[doctor.id]>
              {{ doctor.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-2 col-sm-3">
          <label>Care Team*</label>
        </div>
        <div class="ss-dropdown">
          <div class="col-md-4 col-sm-5">
            <ss-multiselect-dropdown [options]="temp_care_team_array"
                                     name="care_teams"
                                     [(ngModel)]="selectedCareTeam"
                                     (ngModelChange)="selectCareTeam($event)"
                                     required>
            </ss-multiselect-dropdown>
          </div>
        </div>
      </div>

      <ng-container *ngIf="programme_category == 'private_programme'">
        <div class="row form-group package-row">
          <div class="col-md-2 col-sm-3">
            <label>Package*</label>
          </div>
          <div class="col-md-3 col-sm-5">
            <input type="text" class="form-control" placeholder="Enter Package Name"
                   [(ngModel)]="program.private_package_name" name="package_name" required>
          </div>

          <div class="col-md-2 col-sm-2 flex-control">
            <label>Fee*</label>
            <input type="number" class="form-control ml-lg" placeholder="Enter fee"
                   [(ngModel)]="program.private_pay_amount" name="fee" required>
          </div>

          <div class="col-md-5 col-sm-5 flex-control">
            <label>Billed On Every*</label>
            <input style="width:200px;" type="number" class="form-control ml-lg mr-lg" placeholder="Enter Days"
                   [(ngModel)]="program.private_pay_bill_day" name="bill_date" required>
            <label class="enrol-days-label">Days of Enrollment</label>
          </div>
        </div>
      </ng-container>

      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i
            class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
          <button type="submit" class="btn btn-secondary btn-min mr" [disabled]="!programForm.form.valid"
                  *ngIf="!loaderService.uploading_in_process">{{ program.id ? 'Update' : 'Create' }}
          </button>
          <button class="btn btn-tertiary btn-outline btn-min" (click)="closeNewForm()">Cancel</button>
        </div>
      </div>

    </form>

    <ng-container *ngIf="program.id">
      <app-program-consents [program_id]="program.id" [consents]="program.consents"
                            [programme_category]="programme_category"></app-program-consents>
    </ng-container>
  </div>

</div>
