<ng-container *ngIf="!is_profile_setting_component; else formOnly">
  <div class="flex-control flex-column flex-height">
    <div class="flex-header" *ngIf="profileable_type">
      <div class="row">
        <label class="col-sm-1 field-label control-label">{{profileable_type}}</label>
        <div class="col-sm-3">
          <input type="text" class="form-control" placeholder="Search"
                 [(ngModel)]="token" [typeaheadAsync]="true"
                 (keyup)="setPracticeId()"
                 [typeahead]="data_source"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7" typeaheadWaitMs="500"
                 [typeaheadOptionField]="column_name" placeholder="Search">
        </div>
      </div>
      <p *ngIf="alert_mesage_activation" class="text-danger">{{alert_message_active}}</p>
    </div>
    <div class="flex-body p-lg scroll-browser">
      <div class="row">
        <div class="col-sm-6">
          <ng-container *ngTemplateOutlet="formOnly"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #formOnly>
  <ng-container *ngIf="show_user_form">
    <form (ngSubmit)="save()" #UserForm="ngForm" class="form-horizontal">
      <div class="form-group" *ngIf="object_id">
        <label class="col-sm-2 field-label control-label">{{user.profileable_type}}</label>
        <div class="col-sm-4">
          <label class="form-control" id="fullname" disabled><a href="">{{user.first_name}} {{user.last_name}}</a> {{user_age_gender}}</label>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-2 field-label control-label">Roles</label>
        <div class="col-sm-4">
          <select #selectedLocation [(ngModel)]="user.roles_users[0] && user.roles_users[0].role_id" name="role" class="form-control">
            <option *ngFor="let role of user.roles" value={{role.id}}>{{role.name}}</option>
          </select>
        </div>
      </div>
      <div class="form-group" *ngIf="profileable_type == 'Practice'">
        <label class="col-sm-2 field-label control-label">Staff</label>
        <div class="col-sm-10">
          <select #selectedLocation name="staff" (change)="selectStaff(selectedLocation.value)" class="form-control">
            <option value="">Please Select</option>
            <option *ngFor="let staff of user.staffs" value={{staff.id}}>{{staff.first_name}} {{staff.last_name}}</option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="show_complete_form">
        <div class="form-group">
          <label class="col-sm-2 field-label control-label">First Name*</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" id="firstname" [(ngModel)]="user.first_name" name="first_name" required placeholder="FirstName">
            <p *ngIf="customValidations.formErrors.firstname" class="text-danger">{{ customValidations.formErrors.firstname }}</p>
          </div>
          <label class="col-sm-2 field-label control-label">Last Name*</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" id="lastname" [(ngModel)]="user.last_name" name="last_name" required placeholder="LastName">
            <p *ngIf="customValidations.formErrors.lastname" class="text-danger">{{ customValidations.formErrors.lastname }}</p>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 field-label control-label">Address 1*</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="address1" [(ngModel)]="user.address.line_1" name="line_1" required placeholder="Address 1">
            <p *ngIf="customValidations.formErrors.line_1" class="text-danger">{{ customValidations.formErrors.line_1 }}</p>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 field-label control-label">Address 2*</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" id="address2" [(ngModel)]="user.address.line_2" [ngModelOptions]="{standalone: true}" placeholder="Address 2">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 field-label control-label">City*</label>
          <div class="col-sm-3">
            <input type="text" class="form-control" id="city" [(ngModel)]="user.address.city_area.city.name" name="city" required placeholder="City">
            <p *ngIf="customValidations.formErrors.city" class="text-danger">{{ customValidations.formErrors.city }}</p>
          </div>
          <label class="col-sm-1 field-label control-label">State</label>
          <div class="col-sm-2">
            <input type="text" class="form-control" id="state" [(ngModel)]="user.address.city_area.city.state" name="state" placeholder="state">
          </div>
          <label class="col-sm-1 field-label control-label">Zip</label>
          <div class="col-sm-3">
            <input type="text" class="form-control" id="zip" [(ngModel)]="user.address.postal_code" name="postal_code" placeholder="zip">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 field-label control-label">Area</label>
          <div class="col-sm-10">
            <app-city-area selector="#search_area" (changed)="area_select($event)">
              <input id="search_area" placeholder="Search for Area" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search [value]="user.address.city_area.name">
            </app-city-area>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 field-label control-label">Phone*</label>
          <div class="col-sm-4" *ngIf="user.phone">
            <input type="text" class="form-control" id="phone" [(ngModel)]="user.phone.value" pattern="\d+" minlength="1" maxlength="13" name="phone" required placeholder="Phone">
            <p class="text-danger" *ngIf="customValidations.formErrors.phone">{{ customValidations.formErrors.phone }}</p>
          </div>
          <label class="col-sm-2 field-label control-label">Fax</label>
          <div class="col-sm-4" *ngIf="user.fax">
            <input type="text" class="form-control" id="fax" [(ngModel)]="user.fax.value" [ngModelOptions]="{standalone: true}" placeholder="fax">
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 field-label control-label">Email*</label>
          <div class="col-sm-10">
            <input id="email" placeholder="Email" type="text" class="form-control" [(ngModel)]="user.email" name="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" required>
            <p class="text-danger" *ngIf="customValidations.formErrors.email">{{ customValidations.formErrors.email }}</p>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 field-label control-label">User Name*</label>
          <div class="col-sm-10">
            <input id="username" placeholder="Username" type="text" class="form-control" [(ngModel)]="user.username" name="username" disabled>
            <p class="text-danger" *ngIf="customValidations.formErrors.username">{{ customValidations.formErrors.username }}</p>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2"></label>
          <div class="col-sm-10">
            <p *ngIf="error_message" [ngClass]="message_class">
              <span [ngClass]="message_class">{{alert_mesage}}</span>
            </p>
          </div>
        </div>
        <div class="form-group" *ngIf="profileable_type == 'Patient'">
          <label class="col-sm-2"></label>
          <div class="col-sm-10">
            <button *userCan="'edit,users,settings'" type="button" class="btn btn-secondary" [disabled]="!UserForm.form.valid" (click)="active_user()">Active User</button>
          </div>
        </div>
        <div class="form-group" *ngIf="profileable_type != 'Patient' && show_save_button">
          <label class="col-sm-2"></label>
          <div class="col-sm-10">
            <button *userCan="'edit,users,settings'" type="submit" class="btn btn-secondary" [disabled]="!UserForm.form.valid">{{label}}</button>
          </div>
        </div>
        <div class="alert alert-success" *ngIf="messageAfterSave">{{alert_mesage}}</div>
      </ng-container>
    </form>
  </ng-container>
</ng-template>
