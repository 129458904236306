<div class="modal-dialog modal-lg" style="height:635px;">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close_modal()"><i class="fa fa-times fs16"></i></button>
      <h4 class="m0 text-center">Add Patient</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-9">
          <app-demographics *ngIf="show_patient_form" [show_extras]="true" [patient]="patient"  (new_user)="handle_user($event);close_modal();"></app-demographics>
        </div>
        <div class="col-sm-3">
          <div class="user-profile-pic border-box shadow text-center">
            <a class="icon-holder" (click)="show_attachment_popup= true">
              <i class="fa fa-upload fa-2x"></i>
              <h5>Upload Your Picture</h5>
            </a>
            <img *ngIf="patient.user && patient.user.profile_pic" class="img-rounded img-responsive"
                 [src]="patient.user.profile_pic" alt="Image"/>
          </div>
          <app-attachments (document_uploaded)="handle_image_upload($event)"
                           (popup_closed)="show_attachment_popup= false" [fileType]="'profile_pic'"
                           [only_modal]="true" [attachable_id]="patient.id" [attachable_type]="'User'"
                           [show]="show_attachment_popup" [auto_upload]="true"></app-attachments>
        </div>
      </div>
    </div>
  </div>
</div>

