<!-- Shared Credentials Section -->
<h3>Twilio Integration</h3>
<form (ngSubmit)="saveSharedCredentials()">
  <div class="form-group">
    <label for="accountSid">Account SID:</label>
    <input class="form-control" type="text" id="accountSid" [(ngModel)]="sharedCredentials.accountSid" name="accountSid" required>
  </div>
  <div class="form-group">
    <label for="authToken">Auth Token:</label>
    <input class="form-control" type="text" id="authToken" [(ngModel)]="sharedCredentials.authToken" name="authToken" required>
  </div>
  <div class="form-group">
    <button class="btn btn-primary" class="btn btn-primary" type="submit">
      {{configurationIds['TwilioConfiguration'] ? 'Update' : 'Authenticate'}}</button>
    <!--<p *ngIf="configurationIds['TwilioConfiguration']">Authenticated</p>-->
  </div>
</form>

<!-- Delete Integration Section -->
<ul *ngIf="configurationIds['TwilioConfiguration']" class="list-unstyled border-list m0">
  <li class="list">
    Integrated with Twilio.
    <a (click)="deleteModal.show()">
      <i class="fa icon-trash text-danger" ></i>
    </a>
  </li>
</ul>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Integration</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p-sm m0 text-center">
          Are you sure you want to delete this integration?
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteTwilioIntegration(); this.deleteModal.hide()">Yes</button>
        <button type="button" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide()">No</button>
      </div>
    </div>
  </div>
</div>
<!-- Voice Configuration Section -->
<ng-container *ngIf="configurationIds['TwilioConfiguration']">
  <h3>Voice Configuration</h3>
  <form (ngSubmit)="saveVoiceConfiguration()">
    <div class="form-group">
      <label for="voiceApiKey">API Key:</label>
      <input class="form-control" type="text" id="voiceApiKey" [(ngModel)]="voiceConfig.apiKey" name="voiceApiKey" required>
    </div>
    <div class="form-group">
      <label for="voiceApiSecret">API Secret:</label>
      <input class="form-control" type="text" id="voiceApiSecret" [(ngModel)]="voiceConfig.apiSecret" name="voiceApiSecret" required>
    </div>
    <div class="form-group">
      <label for="voiceNumber">Twilio Number:</label>
      <input class="form-control" type="text" id="voiceNumber" [(ngModel)]="voiceConfig.twilioNumber" name="voiceNumber" required>
    </div>
    <div class="form-group">
      <label for="twiml_app_sid">Twiml App Sid:</label>
      <input class="form-control" type="text" id="twiml_app_sid" [(ngModel)]="voiceConfig.twimlAppSid" name="twiml_app_sid" required>
    </div>
    <div class="form-group">
      <button class="btn btn-primary" type="submit">Save Voice Configuration</button>
    </div>
  </form>

  <!-- Video Configuration Section -->
  <h3>Video Configuration</h3>
  <form (ngSubmit)="saveVideoConfiguration()">
    <div class="form-group">
      <label for="videoApiKey">API Key:</label>
      <input class="form-control" type="text" id="videoApiKey" [(ngModel)]="videoConfig.apiKey" name="videoApiKey" required>
    </div>
    <div class="form-group">
      <label for="videoApiSecret">API Secret:</label>
      <input class="form-control" type="text" id="videoApiSecret" [(ngModel)]="videoConfig.apiSecret" name="videoApiSecret" required>
    </div>
    <div class="form-group">
      <button class="btn btn-primary" type="submit">Save Video Configuration</button>
    </div>
  </form>

  <!-- SMS Configuration Section -->
  <h3>SMS Configuration</h3>
  <form (ngSubmit)="saveSMSConfiguration()">
    <div class="form-group">
      <label for="smsNumber">Twilio Number:</label>
      <input class="form-control" type="text" id="smsNumber" [(ngModel)]="smsConfig.twilioNumber" name="smsNumber" required>
    </div>
    <div class="form-group">
      <button class="btn btn-primary" type="submit">Save SMS Configuration</button>
    </div>
  </form>
</ng-container>
