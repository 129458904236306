import {Component, HostListener, Input, OnInit, Inject, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import $ from 'jquery';

@Component({
  selector: '[app-sticky-section=""]',
  templateUrl: './sticky-section.component.html',
  styleUrls: ['./sticky-section.component.scss']
})
export class StickySectionComponent implements OnInit {

  @Input() top = 71;
  @Input() bottom = 0;
  @Input() initial_top = undefined;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    let top_position =  this.component_element.nativeElement?.getBoundingClientRect()?.top;
    let footer_top =  $('footer')[0]?.getBoundingClientRect()?.top;
    let bottom_position =  this.component_element.nativeElement?.getBoundingClientRect()?.bottom;
    let window_top = window.pageYOffset;
    let bottom_sticky = bottom_position + this.bottom > footer_top;
    let top_sticky_applies = top_position < this.top || (window_top + this.top) >= this.initial_top
    if (top_sticky_applies) {
      this.renderer.addClass(this.component_element.nativeElement, 'sticky-section')
    }

    if (window_top + this.top <= this.initial_top) {
      this.renderer.removeClass(this.component_element.nativeElement, 'sticky-section')
    }
    if (bottom_sticky) {
      this.renderer.addClass(this.component_element.nativeElement, 'bottom-sticky');
      // this.renderer.setStyle(this.component_element.nativeElement,"bottom",`${footer_top}px`)
    }
    if (top_position > this.top) {
      this.renderer.removeClass(this.component_element.nativeElement, 'bottom-sticky');
    }

    // This function is Only for Home page
    let healthcare_top = $('.health-banner')[0]?.getBoundingClientRect()?.top;
    let top_class   = this.component_element.nativeElement?.getBoundingClientRect()?.top;
    let remove_class   = top_class + this.top > healthcare_top;
    if  (remove_class){
      this.renderer.removeClass(this.component_element.nativeElement, 'sticky-section');
    }
    // End this function is Only for Home page

  }
  constructor(@Inject(DOCUMENT) private document: any, private component_element: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.top = parseInt(this.top.toString());
  }

  ngAfterViewChecked() {
    if (this.initial_top == undefined) {
      this.initial_top = this.component_element.nativeElement?.getBoundingClientRect()?.top + window.pageYOffset;
    }
  }

}
