import { NgModule } from '@angular/core';
import  {LoginComponent} from './login.component'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
// import  {SharedModule} from  '../../shared/shared.module'
// import { SelectModule } from "ng2-select";
@NgModule({
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, RouterModule
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent]
})
export class LoginModule { }
