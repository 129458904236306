<div class="flex-control flex-column flex-height view-campaign" *ngIf="!editAmenity">
  <div class="flex-header">
    <div class="media">
      <div class="media-body media-middle">
        <h3 class="m0">{{amenity.name}}
          <span *ngIf="amenity.status === 'active'" class="label label-success fs13 text-capitalize vl-m">{{amenity.status}}</span>
          <span *ngIf="amenity.status === 'inactive'" class="label label-info fs13 text-capitalize vl-m">{{amenity.status}}</span>
        </h3>
      </div>
      <div class="media-right text-right" style="min-width:200px;">
        <a (click)="updateAmenity(amenity)" class="btn btn-secondary btn-outline mr-lg">
          <i class="fa fa-pencil vl-m mr-sm fs14"></i> Edit
        </a>
        <a class="btn btn-danger btn-outline"  type="button" (click)="deleteModal.show()">Delete</a>
      </div>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <ul class="list-unstyled border-list m0">
        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-layers vl-m mr"></i>
              <span>Method</span>
            </div>
            <ng-container *ngFor="let method of amenity.amenity_methods">
              <h4 class="media-body media-middle m0 fs13">{{method.name}} <span class="text-black">({{method.fee}}$)</span></h4>
            </ng-container>
          </div>
        </li>

        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-book-open vl-m mr"></i>
              <span>Short description</span>
            </div>
            <h4 class="fs13 media-body media-middle m0" [innerHTML]="amenity.short_description | safeHtml"></h4>
          </div>
        </li>

        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-book-open vl-m mr"></i>
              <span>Full Description</span>
            </div>
            <h4 class="media-body media-middle m0 fs13">
              <div class="media-body media-middle m0" [innerHTML]="amenity.long_description | safeHtml"></div>
            </h4>
          </div>
        </li>

        <li class="list">
          <div class="media">
            <div class="media-left media-middle col-xs-2 pl0">
              <i class="fa icon-calendar vl-m mr"></i>
              <span>No of booking/day</span>
            </div>
            <h4 class="fs13 media-body media-middle m0">{{amenity.no_of_bookings_per_day}}</h4>
          </div>
        </li>

      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-clock vl-m mr"></i>
            <span>Duration</span>
          </div>
          <h4 class="fs13 media-body media-middle m0"><ng-container *ngIf="amenity.duration">{{amenity.duration}} min</ng-container></h4>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-clock vl-m mr"></i>
            <span>OffSet</span>
          </div>
          <h4 class="fs13 media-body media-middle m0"><ng-container *ngIf="amenity.offset_time">{{amenity.offset_time}} {{amenity.offset_type}}</ng-container></h4>
        </div>
      </li>

      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-check vl-m mr"></i>
            <span>Auto Acknowledge</span>
          </div>
          <h4 class="fs13 media-body media-middle text-capitalize m0">{{amenity.auto_acknowledge}}</h4>
        </div>
      </li>

      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-credit-card vl-m mr"></i>
            <span>Payment Method</span>
          </div>
          <h4 class="fs13 media-body media-middle text-capitalize m0">{{amenityService.removeUnderScore(amenity.payment_method)}}</h4>
        </div>
      </li>

      <li class="list">
        <div class="media">
          <div class="media-left media-middle col-xs-2 pl0">
            <i class="fa icon-people vl-m mr"></i>
            <span>Applicable to New Patients only</span>
          </div>
          <h4 class="fs13 media-body media-middle m0">{{amenityService.removeUnderScore(amenity.applicable_patients)}}</h4>
        </div>
      </li>

    </ul>
  </div>
</div>


<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Service</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to delete the service?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="deleteAmenity(amenity);">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="editAmenity">
  <app-new-amenity [amenity] = "amenity"  (closeform)="closeNewForm($event)"></app-new-amenity>
</ng-container>
