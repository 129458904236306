<div class="text-right mb-lg">
  <button class="btn btn-primary mr-sm" (click)="generatePdf('print')">Print</button>
  <button class="btn btn-inverse" (click)="hide_print()">Cancel</button>
</div>

<div #patientInFlow class="print-wrapper" style="max-width:8.5in; margin:auto;">
  <div class="print-header">
    <div class="row">
      <div class="col-xs-8">
        <img style="max-width:250px;" src="/assets/img/PCHF-logo-latest.png" alt="">
      </div>
      <div class="col-xs-4">
        <h5>{{print_case_info.practice_complete_address}}</h5>
        <h5> Tel: {{print_case_info.phone_number}}</h5>
      </div>
    </div>
  </div>
  <div class="print-body">
    <h4 class="text-bold text-center">Admission Form</h4>
    <div class="row mt-xl">
      <div class="col-xs-7">
        <div class="row mb-sm">
          <label class="col-xs-6 text-normal">GR#</label>
          <span class="col-xs-6 text-bold">{{print_case_info.mrn_no}}</span>
        </div>
        <div class="row mb-sm">
          <label class="col-xs-6 text-normal">Patient Name</label>
          <span class="col-xs-6 text-bold">{{print_case_info.patient_name}}</span>
        </div>
        <div class="row mb-sm">
          <label class="col-xs-6 text-normal">{{print_case_info.relationship_name}}</label>
          <span class="col-xs-6 text-bold">{{print_case_info.guardian_name}}</span>
        </div>
        <div class="row mb-sm">
          <label class="col-xs-6 text-normal">Age</label>
          <span class="col-xs-6 text-bold">{{print_case_info.age}}</span>
        </div>
        <div class="row mb-sm">
          <label class="col-xs-6 text-normal">Reffered To</label>
          <span class="col-xs-6 text-bold">{{print_case_info.physician_name}}</span>
        </div>
      </div>
      <div class="col-xs-5">
        <div class="row mb-sm">
          <label class="col-xs-5 text-normal">Date/Time</label>
          <span class="col-xs-7 text-bold">{{current_datetime}}</span>
        </div>
        <div class="row mb-sm">
          <label class="col-xs-5 text-normal">Admission Date</label>
          <span class="col-xs-7 text-bold">{{print_case_info.admission_date_format}}</span>
        </div>
        <div class="row mb-sm">
          <label class="col-xs-5 text-normal">Operation Date</label>
          <span class="col-xs-7 text-bold">{{print_case_info.surgery_date_format}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-xs-3 text-normal" style="width:29.3%;">Doctor's Recommendation</label>
      <span class="col-xs-9 text-bold" style="width:70%;">{{print_case_info.recommendation}}</span>
    </div>
    <h5 class="text-bold mt-xl">Cost of Surgery/Treatment</h5>
    <div class="row">
      <label class="col-xs-3 text-normal">Total</label>
      <span class="col-xs-9 text-bold">Rs. {{print_case_info.expected_cost}}/-</span>
    </div>
    <div class="row">
      <label class="col-xs-3 text-normal">PCHF Share</label>
      <span class="col-xs-9 text-bold">Rs. {{print_case_info.pchf_share}}/-</span>
    </div>
    <div class="row">
      <label class="col-xs-3 text-normal">Patient Share</label>
      <span class="col-xs-9 text-bold">Rs. {{print_case_info.parent_share}}/-</span>
    </div>
    <div class="row">
      <label class="col-xs-3 text-normal">Third Party Share</label>
      <span class="col-xs-9 text-bold">Rs. {{print_case_info.third_party_share}}/-</span>
    </div>

    <div class="row">
      <div class="col-xs-8"></div>
      <div class="col-xs-4 text-center">
        <h5>........................................................</h5>
        <h5 class="text-bold">Director Operations</h5>
      </div>
    </div>

  </div>
</div>
