<div class="filters-sec flex-header">
  <div class="row">
    <div class="col-xs-3">
      <input class="form-control" [(ngModel)]="filter['token']" (keyup.enter)="search()" placeholder="Subject">
    </div>
    <div class="col-xs-2">
      <select [(ngModel)]="filter['type']" class="form-control">
        <option value=undefined selected>Type</option>
        <option *ngFor="let type of alertTemplateService.alert_types; let i = index;" [value]="type">
          {{userService.removeUnderScore(type)}}
        </option>
      </select>
    </div>
    <div class="col-xs-1">
      <select [(ngModel)]="filter['status']" class="form-control">
        <option value=undefined selected>Status</option>
        <option value='active'>Active</option>
        <option value='in_active'>Inactive</option>
      </select>
    </div>
    <div class="col-xs-1">
      <select class="form-control text-capitalize" [(ngModel)]="filter['severity']" name="severity_filter">
        <option value=undefined selected>Severity</option>
        <option *ngFor="let severity of alertTemplateService.severities; let i = index;" [value]="severity">
          {{severity}}
        </option>
      </select>
    </div>
    <div class="col-xs-2">
      <select class="form-control text-capitalize" [(ngModel)]="filter['protocol_id']" name="protocol_filter">
        <option  value=undefined selected>Protocols</option>
        <option *ngFor="let protocol of alertTemplateService.protocols; let i = index;" [value]="protocol.id">
          {{alertTemplateService.mapProtocolsToUpperCase(protocol.name)}}
        </option>
      </select>
    </div>
    <div class="col-md-3 col-xs-5">
      <button type="button" class="btn btn-tertiary btn-outline btm-min" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      <button type="button" class="btn btn-secondary btn-min ml pull-right" (click)="addAlert()" >
        <i class="fa fa-plus mr-sm"></i> Add
      </button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Subject</th>
      <th>Type</th>
      <th>Alert</th>
      <th>Protocol</th>
      <th>Age</th>
      <th>Gender</th>
      <th>Severity</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let alertTemplate of alertTemplates">
      <td><a (click)="editAlert(alertTemplate)">{{alertTemplate.subject}}</a></td>
      <td>{{userService.removeUnderScore(alertTemplate.alert_type)}}</td>
      <td>{{alertTemplate.description}}</td>
      <td class="text-capitalize">{{alertTemplateService.mapProtocolsToUpperCase(alertTemplate.protocol_name)}}</td>
      <td>
        <ng-container *ngIf="alertTemplate.alert_rule.age_trend === 'between'">
          {{alertTemplate.alert_rule?.age_min_value}} - {{alertTemplate.alert_rule?.age_max_value}}
        </ng-container>
        <ng-container *ngIf="alertTemplate.alert_rule.age_trend !== 'between'">
          {{showSign(alertTemplate.alert_rule.age_trend)}} {{alertTemplate.alert_rule?.age_value}}
        </ng-container>
        {{alertTemplate.alert_rule?.age_type}}
      </td>
      <td class="text-capitalize">{{userService.removeUnderScore(alertTemplate.alert_rule.gender)}}</td>
      <td class="text-capitalize">{{alertTemplate.severity}}</td>
      <td>
        <select #status [(ngModel)]="alertTemplate.status" (change)="changeStatusOfAlertTemplate(alertTemplate)" name="alertTemplate_status" [ngClass]="alertTemplate.status === 'active' ? 'active' : 'inactive'" class="form-control short-select">
          <option value="active">Active</option>
          <option value="in_active">In Active</option>
        </select>
      </td>
    </tr>
    </tbody>
  </table>
  <app-no-result-found *ngIf="alertTemplates && alertTemplates.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center" *ngIf="alertTemplates && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
