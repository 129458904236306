import { Component, OnInit, OnChanges } from '@angular/core';
import { VisitsService } from '../../../services/visits.service';
import {TabsService} from '../../../services/tabs.service';
import {Tab} from '../../../tabs/tab';
@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss']
})
export class VisitsComponent implements OnInit {

  constructor(public _visitService: VisitsService, public tabs_service: TabsService) {
    this.tabs_service.register('visits_components');
  }

  ngOnInit() {
    this.setVisitFilters();
  }

  ngOnChanges() {
    this.setVisitFilters();
  }

  setVisitFilters() {
    this._visitService.getVisitFilters().then(res => {

    });
  }




}
