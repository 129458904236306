<div class="flex-control flex-column flex-height">
  <div class="flex-header text-center">
    <button *ngIf="isLoading; else notLoading" class="btn btn-default">
      <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...
    </button>
    <ng-template #notLoading>
      <button class="btn btn-secondary btn-outline btn-min mr-sm"
              *ngIf="!isLoading"
              (click)="callAssociateWithMedicalChartMethod()"
              [disabled]="is_added_to_chart || isLoading">
        Add to Chart
      </button>
      <button class="btn btn-secondary btn-min mr-sm" (click)="generatePdf('print')">Print</button>
      <button class="btn btn-tertiary btn-min btn-outline" (click)="generatePdf('save')">Download</button>
    </ng-template>
    <p class="text-center text-success mr-sm hidden" *ngIf="carePlanWithChart.successMessage">Care Plan successfully added to Medical Chart</p>
    <p class="text-center text-success mr-sm hidden">{{carePlanWithChart.statusMessage}}</p>
  </div>
  <div class="flex-body scroll-browser container vitals-pdf-print">
    <div class="p-xl" #vital *ngIf="vital_print_data">
      <div class="row" *ngIf="source_type === 'Appointment'">
        <div class="col-xs-6">
          <h3 class="fw-500 m0">{{vital_print_data.doctor_prefix}} {{vital_print_data.doctor_name}}</h3>
          <h6 class="fs14 fw-500">{{vital_print_data.doctor_sufix}}</h6>
          <h4>{{vital_print_data.doctor_designation}}</h4>
        </div>
        <div class="col-xs-6">
          <div class="media">
            <div class="media-body text-right">
              <h4 class="fw-500 mt0">{{vital_print_data.practice_name}}</h4>
              <h5>{{vital_print_data.practice_address}}</h5>
              <h5>Tel:{{vital_print_data.practice_phone}}<span *ngIf="vital_print_data.practice_fax">, Fax:{{vital_print_data.practice_fax}}</span></h5>
            </div>
            <div class="media-right" *ngIf="vital_print_data.practice_logo">
              <img class="thumb96" [src]="vital_print_data.practice_logo" [alt]="vital_print_data.practice_name">
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="display:flex; border-top:1px solid #000; border-bottom:1px solid #000;">
        <div class="col-xs-3 pt-sm" style="border-right:1px solid #000;">
          <h4 class="fw-500">
            {{ vital_print_data['appointment_booking_time'] || (today | date:'shortDate') + ' ' + (today | date:'shortTime') }}
          </h4>
          <div class="media" *ngIf="patientVital">
            <div class="media-left">
              <h4 *ngIf="patientVital.temperature.value">Temp</h4>
              <h4 *ngIf="patientVital.pulse.value">Pulse</h4>
              <h4 *ngIf="patientVital.respiration.value">Resp.</h4>
              <h4 *ngIf="patientVital.sBP.value">B/P</h4>
              <h4 *ngIf="patientVital.weight.value">Wt</h4>
              <h4 *ngIf="patientVital.heightInFeet || patientVital.heightInInch">Ht</h4>
              <h4 *ngIf="patientVital.bodyMassIndex.value">BMI</h4>
              <h4 *ngIf="patientVital.spo2.value">SpO2</h4>
              <h4 *ngIf="patientVital.nprs.value">Pain</h4>
              <h4 *ngIf="patientVital.bloodGlucose.value">BG</h4>
            </div>
            <div class="media-body">
              <h4 [ngClass]="patientVital.temperature.descriptor_class" class=" fw-500 fs20"
                  *ngIf="patientVital.temperature.value">{{patientVital.temperature.value}}
                <small>F</small>
              </h4>
              <h4 [ngClass]="patientVital.pulse.descriptor_class" class="fw-500 fs22"
                  *ngIf="patientVital.pulse.value">{{patientVital.pulse.value}}
                <small>{{patientVital.pulse.descriptor}}</small>
              </h4>
              <h4 [ngClass]="patientVital.respiration.descriptor_class" class="fw-500 fs22"
                  *ngIf="patientVital.respiration.value">{{patientVital.respiration.value}}
                <small>{{patientVital.respiration.descriptor}}</small>
              </h4>
              <h4  class="fw-500 fs22" *ngIf="patientVital.sBP.value">
                <span class="fs22" [ngClass]="patientVital.sBP.descriptor_class">{{patientVital.sBP.value}}</span>/<span class="fs22" [ngClass]="patientVital.dBP.descriptor_class">
              {{patientVital.dBP.value}}</span>
              </h4>
              <h4 [ngClass]="patientVital.weight.descriptor_class" class="fw-500 fs22"
                  *ngIf="patientVital.weight.value">{{patientVital.weight.value}} <small>Kg</small>
              </h4>
              <h4  class="fw-500 fs22" *ngIf="patientVital.heightInFeet || patientVital.heightInInch">
                {{patientVital.heightInFeet}}'{{patientVital.heightInInch}}''
              </h4>
              <h4 [ngClass]="patientVital.bodyMassIndex.descriptor_class" class="fw-500 fs22"
                  *ngIf="patientVital.bodyMassIndex && patientVital.bodyMassIndex.value">
                {{patientVital.bodyMassIndex.value}} <small>{{patientVital.bodyMassIndex.descriptor}}</small>
              </h4>
              <h4 [ngClass]="patientVital.spo2.descriptor_class" class="fw-500 fs22"
                  *ngIf="patientVital.spo2 && patientVital.spo2.value">{{patientVital.spo2.value}}
                <small>{{patientVital.spo2.descriptor?.toLowerCase()}}</small>
              </h4>
              <h4 [ngClass]="patientVital.nprs.descriptor_class" class="fw-500 fs22"
                  *ngIf="patientVital.nprs && patientVital.nprs.value">{{patientVital.nprs.value}}
                <small>{{patientVital.nprs.descriptor?.toLowerCase()}}</small>
              </h4>
              <h4 [ngClass]="patientVital.bloodGlucose.descriptor_class" class="fw-500 fs22"
                  *ngIf="patientVital.bloodGlucose && patientVital.bloodGlucose.value">{{patientVital.bloodGlucose.value}}
                <small>{{patientVital.bloodGlucose.descriptor?.toLowerCase()}}</small>
              </h4>

            </div>
          </div>
        </div>
        <div class="col-xs-9 pt-lg">
          <div class="media">
            <div class="media-left" *ngIf="vital_print_data.patient_profile_pic">
              <img class="thumbnail thumb96" [src]="vital_print_data.patient_profile_pic" [alt]="vital_print_data.practice_name">
            </div>
            <div class="media-body">
              <h3 class="fw-500 text-black mt0">{{vital_print_data.patient_name}}</h3>
              <h5>{{age_gender}}, {{vital_print_data.patient_gender.toUpperCase()}}</h5>
              <h5>{{vital_print_data.patient_address}}.<span *ngIf="vital_print_data.patient_phone">Cell: {{vital_print_data.patient_phone}}</span></h5>
            </div>
          </div>
          <ng-container *ngIf="enotePrinter">
            <ng-container *ngComponentOutlet="enoteComponent;injector: custom_injector;"></ng-container>
          </ng-container>
          <ng-container *ngIf="is_rich_text">
            <div  [innerHTML]="selectedTemplate.body | safeHtml"></div>
          </ng-container>
        </div>
      </div>
      <h5 class="text-muted text-center">Powered by www.wellwink.com {{today | date:'shortDate'}} {{today | date:'shortTime'}}</h5>
    </div>
  </div>
</div>
<style>
  h3{ color:#000; font-size:28px;}
  h4{ color:#000; font-size:22px;}
  h5{ color:#000; font-size:18px;}
  .media-left{ min-width:100px;}
  .media-left h4{ min-height:24px;}
  .text-danger{ color:#f05050;}
  .text-primary{ color:#0aa89e;}
</style>
