export class AlertDiagnosis {
  id: number;
  library_datum_id: number;
  d_name: number;
  diagnosis_trend: any = 'both';
  _destroy: boolean;
  to_params() {
    return {
      id: this.id,
      library_datum_id: this.library_datum_id,
      d_name: this.d_name,
      diagnosis_trend: this.diagnosis_trend,
      _destroy: this._destroy,
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.d_name = json.d_name;
    this.library_datum_id = json.library_datum_id;
    this.diagnosis_trend = json.diagnosis_trend;
    this._destroy = json._destroy;
    return this;
  }
  load_from_new_object(json) {
    this.d_name = json.d_name;
    this.library_datum_id = json.library_datum_id;
    this._destroy = json._destroy;
    return this;
  }
}

