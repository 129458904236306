import {Component, OnInit} from '@angular/core';
import {SmsService} from '../sms.service';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-sms-listing',
  templateUrl: './sms-listing.component.html',
  styleUrls: ['./sms-listing.component.scss']
})
export class SmsListingComponent implements OnInit{
  filteredConversation;
  public token = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public typeheadResult = [];
  public middle_selected = false;
  private tokenSubject = new BehaviorSubject<string>('');
  public data_source: Observable<any>;

  constructor(public smsService: SmsService) {
  }

  ngOnInit() {
    this.data_source = this.tokenSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(token => this.getPracticePatient(token))
    );
    this.setFilteredConversations();
  }
  public onInputChange(value: string): void {
    this.tokenSubject.next(value);
    this.checkFilters();
  }

  getPracticePatient(token): Observable<any> {
    this.setFilteredConversations();
    let result =  this.smsService.getPracticePatient(token);
    console.log(result)
    result.subscribe(data => {
      console.log(data);
      this.typeheadResult = data;
    });
    return result ;
  }

  setFilteredConversations() {
    if (!this.token || this.token == '') {
      this.filteredConversation  = this.smsService.conversations;
    } else {
      this.filteredConversation = this.smsService.conversations.filter(c => c.title.toLowerCase().includes(this.token.toLowerCase()) || c.patient.phone.includes(this.token) );
    }
  }

  public typeaheadOnSelect(e: any) {
    this.smsService.set_user_on_selection(e.item)
    this.token = '';
    this.setFilteredConversations();
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }
  checkFilters() {
    if (this.token.length == 0) {
      this.setFilteredConversations();
      this.typeheadResult = [];
    }
  }

  loadMessages(conversation) {
    this.smsService.load_sms(conversation);
  }

  addToChatroom(recipient) {
    this.smsService.set_user_on_selection(recipient);
    this.token = '';
    this.typeheadResult = [];
    this.setFilteredConversations();
  }


}
