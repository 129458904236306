import {connect, ConnectOptions, LocalDataTrack, LocalTrack, LocalVideoTrack, Room} from 'twilio-video';
import {Injectable, Injector} from '@angular/core';
import {ReplaySubject, Observable, timer, Subscription} from 'rxjs';
import {CustomHttpClient} from '../../services/custom-http-client.service';
import {Appointment} from '../../health/models/appointment';
import {UserService} from '../../services/user.service';
import {CameraComponent} from '../camera/camera.component';
import {HttpParams} from '@angular/common/http';

interface AuthToken {
  token: string;
}

export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
  providedIn: 'root'
})
export class VideoChatService {
  start_call_message = false;
  public showLayoutVideo = false;
  public call_ended = false;
  public call_cancelled = false;
  public callAnswered = false;
  public answerTimer: any;
  disable_button = false;
  activeRoom: Room;
  public showVideo = false;
  $roomsUpdated: Observable<boolean>;
  public room_name: string;
  private roomBroadcast = new ReplaySubject<boolean>();
  public callStatusObservable = new ReplaySubject<string>();
  call_status = 'end';
  inCall = false;
  public callEnded = false;
  appointment: Appointment;
  caller_profile_pic: any;
  caller_full_name: string;
  countDown: Subscription;
  counter: number;
  timer: Subscription;
  clock;
  tick = 1000;
  remoteParticipentRemoved = false;
  remoteVideoTrackStatus = 'enabled';
  remoteAudioTrackStatus = 'enabled';
  public appointment_id: string;
  public patient_user_id: any;
  public localTracks: LocalTrack[] = [];
  videoTrack: LocalVideoTrack;
  hidden = true;
  public audio = new Audio('../../../assets/audio/call.mp3');
  hidePatientVideo = true;
  calling = false;
  micEnable = true;
  camEnable = true;
  showSmallWindow = false;
  connectionError = false;
  connectionLost = false;
  camera: CameraComponent;
  copy_appointment: Appointment;
  patient_cancelled_call = false;
  mobile_online_status: string;
  portal_online_status: string;
  caller_id: any;
  patient_full_name: any;
  patient_profile_pic: string;
  public source_type: any;
  public source_id: any;
  constructor(private http: CustomHttpClient, private userService: UserService) {
    this.$roomsUpdated = this.roomBroadcast.asObservable();
  }

  broadcastRoom(patient_user_id: any) {
    this.http.get('video/broadcast?patient_user_id=' + patient_user_id).subscribe(resp => {
      console.log(resp);
    })
  }
  broadcastCallCancelled(patient_user_id: any, caller_id?: any): Promise<any> {
    return this.http
      .get('video/broadcast_call_cancelled?patient_user_id=' + patient_user_id + '&caller_id=' + caller_id)
      .toPromise()
  }

  startCountDown() {
    // tslint:disable-next-line:radix
    // this.counter = parseInt(this.appointment.slot_size) * 60;
    this.countDown = timer(1000, this.tick)
      .subscribe(() => {})
  }

  startTimer(): void {
    this.clock = 0;
    this.timer = timer(1000, 1000)
      .subscribe(() => {
        ++this.clock;
      });
  }

  async playAudio() {
    this.loadAudio();
    await this.audio.play();
  }

  pauseAudio() {
    this.audio.pause();
    this.audio.currentTime = 0 ;
    this.loadAudio();
  }

  loadAudio() {
    this.audio.load();
  }

  async stopAudio() {
    await this.audio.pause();
  }

  get formatClock(): string {
    if (isNaN(parseInt(this.clock) )) {
      return '00:00:00'
    }
    return  new Date(this.clock * 1000).toISOString().substr(11, 8);
  }

  private async getAuthToken() {
    const auth =
      await this.http
        .get('video/auth_token?id='+ this.caller_id)
        .toPromise();

    return auth.token;
  }

  getAllRooms() {
    return this.http
      .get('video/rooms')
      .toPromise();
  }
  getRoom(name: string) {
    let args = new HttpParams();
    args = args.set('room_name', name);
    return this.http
      .get('video/existed_room', {params: args})
      .toPromise();
  }

  toggleMic() {
    this.activeRoom.localParticipant.audioTracks.forEach((trackMap) => {
      trackMap.track.isEnabled ? trackMap.track.disable() : trackMap.track.enable();
      this.micEnable = trackMap.track.isEnabled;
    });
  }
  toggleCam() {
    this.activeRoom.localParticipant.videoTracks.forEach((trackMap) => {
      trackMap.track.isEnabled ? trackMap.track.disable() : trackMap.track.enable();
      this.camEnable = trackMap.track.isEnabled;
    });
  }

  async joinOrCreateRoom(name: string, tracks: LocalTrack[]) {
    if (this.activeRoom) {
      return  this.activeRoom;
    }
    let room: Room = null;
    try {
      const token = await this.getAuthToken();
      console.log('room name', name);
      room =
        await connect(
          token, {
            name: name,
            tracks: tracks,
            dominantSpeaker: true
          } as ConnectOptions);
    } catch (error) {
      console.log('room connection error', error);
      this.connectionError = true;
      setTimeout(() => {
        this.connectionError = false;
      }, 5000)
    } finally {
      if (room) {
        this.roomBroadcast.next(true);
      }
    }
    this.activeRoom = room;
    return this.activeRoom;
  }

  nudge() {
    this.roomBroadcast.next(true);
  }

  toggleCall() {
    this.call_status = this.call_status === 'end' ? 'started' : 'end';
    this.callStatusObservable.next(this.call_status);
  }

  cancelCall() {
    if (this.userService.current_user.isPatient) {
      this.showLayoutVideo = false;
    }
    if (this.userService.current_user?.isPracticeUser) {
      this.patient_cancelled_call = true;
    }
    this.hidden = true;
    this.pauseAudio();
    this.callEnded = false;
    if (this.userService.current_user?.isPatient) {
      this.call_cancelled = true;
    }
    this.inCall =  false;
    this.calling = false;
    this.activeRoom?.disconnect();
    this.activeRoom = null;
    this.countDown = undefined;
    this.counter = undefined;
    this.timer?.unsubscribe();
    this.timer = undefined;
    if (this.userService.current_user.isPatient) {
      this.localTracks?.forEach(t => {
        if (!(t instanceof LocalDataTrack)) {
          t.stop();
          t.mediaStreamTrack.stop();
        }
      });
    }
  }
}
