import {BaseModel} from '../shared/base';

export class PracticeReviewSetup extends BaseModel {
  id: number;
  status: string;
  practice_id: number;
  review_type: string;
  first_preference: string;
  auto_approve_enabled: boolean;
  star_preference = 'four_star';


  load_from_json(json) {
    this.id = json.id;
    this.status = json.status;
    this.practice_id = json.practice_id;
    this.review_type = json.review_type;
    this.first_preference = json.first_preference;
    this.auto_approve_enabled = json.auto_approve_enabled;
    if (json.star_preference) {
      this.star_preference = json.star_preference; }
    else {
      this.star_preference = 'four_star';
    }
    return this;
  }

  initializePreferences() {
    this.status = 'start';
    this.first_preference = 'practice_first';
    this.review_type = 'practice';
    this.auto_approve_enabled = false;
    return this;
  }


  to_params() {
    return {
      practice_review: {
        id: this.id,
        status: this.status,
        practice_id : this.practice_id,
        first_preference: this.first_preference,
        review_type: this.review_type,
        auto_approve_enabled: this.auto_approve_enabled,
        star_preference: this.star_preference
      }
    }
  }

  is_new_record() {
    return  this.id == undefined;
  }


}
