import { Injectable }     from '@angular/core';
import {  HttpParams } from '@angular/common/http';
import {CustomHttpClient} from './custom-http-client.service';


@Injectable()
export class DashboardsService {

  constructor(private _tokenService: CustomHttpClient) { }
  getCurrentUserPractice(): Promise<any[]> {
    return this._tokenService.get('dashboards/current_user_practice')
      .toPromise()
      .then(response => response);
  }
  getDoctorOrPractice(search_term: any, type: any) {
    let args = new HttpParams();
    args = args.set('search_term', search_term);
    args = args.set('type', type);
    return this._tokenService.get('dashboards/search_doctor_or_practice', {params: args})
      .toPromise()
      .then(response => response);
  }
  enterprise_dashboard_data() {
    return this._tokenService
      .get('/dashboards/enterprise_data', )
      .toPromise().then(response => response)
  }
  enterprise_appointments_data(data) {
    let params = new HttpParams({fromObject: data});
    return this._tokenService
      .get('/dashboards/enterprise_appointments_data', {params: params})
      .toPromise().then(response => response)
  }
  practice_dashboard_data() {
    return this._tokenService
      .get('/dashboards/practice_data')
      .toPromise().then(response => response)
  }
  appointment_graph_data(data) {
    let params = new HttpParams({fromObject: data});
    return this._tokenService
      .get('/dashboards/appointment_graph_data', {params: params})
      .toPromise().then(response => response)
  }
}
