<ng-container *ngIf="noVisitsFound()"><app-no-result-found></app-no-result-found></ng-container>
<ng-container *ngIf="visitsKeys()">
  <table class="table table-hover">
    <thead>
    <tr>
      <th><a class="text-black" href="javascript:void(0)" (click)="sorting('appointments.date::date')">Visit date <i class="fa fa-caret-down" *ngIf="_visitService.applyOrder('appointments.date::date','desc')"></i> <i class="fa fa-caret-up" *ngIf="_visitService.applyOrder('appointments.date::date','asc')"></i></a></th>
      <th><a class="text-black" href="javascript:void(0)" (click)="sorting('doctors.first_name')">Doctor <i class="fa fa-caret-down" *ngIf="_visitService.applyOrder('doctors.first_name','desc')"></i> <i class="fa fa-caret-up" *ngIf="_visitService.applyOrder('doctors.first_name','asc')"></i></a></th>
      <th><a class="text-black" href="javascript:void(0)" (click)="sorting('patients.name')">Pateint <i class="fa fa-caret-down" *ngIf="_visitService.applyOrder('patients.name','desc')"></i> <i class="fa fa-caret-up" *ngIf="_visitService.applyOrder('patients.name','asc')"></i></a></th>
      <th><a class="text-black" href="javascript:void(0)" (click)="sorting('locations.name')">Location <i class="fa fa-caret-down" *ngIf="_visitService.applyOrder('locations.name','desc')"></i> <i class="fa fa-caret-up" *ngIf="_visitService.applyOrder('locations.name','asc')"></i></a></th>
      <th><a class="text-black" href="javascript:void(0)" (click)="sorting('services.name')">Visit reason <i class="fa fa-caret-down" *ngIf="_visitService.applyOrder('services.name','desc')"></i> <i class="fa fa-caret-up" *ngIf="_visitService.applyOrder('services.name','asc')"></i></a></th>
      <th><a class="text-black" href="javascript:void(0)" (click)="sorting('appointments.appointment_type')">Booking type <i class="fa fa-caret-down" *ngIf="_visitService.applyOrder('appointments.appointment_type','desc')"></i> <i class="fa fa-caret-up" *ngIf="_visitService.applyOrder('appointments.appointment_type','asc')"></i></a></th>
      <th><a class="text-black" href="javascript:void(0)" (click)="sorting('appointments.status_id')">Status <i class="fa fa-caret-down" *ngIf="_visitService.applyOrder('appointments.status_id','desc')"></i> <i class="fa fa-caret-up" *ngIf="_visitService.applyOrder('appointments.status_id','asc')"></i></a></th>
      <th class="text-right text-black">Charges</th>
      <th class="text-right text-black">Payments</th>
      <th class="text-right text-black">Discount</th>
      <th class="text-right text-black">Balance</th>
    </tr>
    </thead>
    <tbody>

    <ng-container *ngFor="let key of visitsKeys()">
      <tr *ngIf="key != 'All'">
        <td colspan="20" class="p0"><h4 class="m0 p-sm bg-gray-light"><strong>{{key}}</strong></h4></td>
      </tr>
      <ng-container *ngFor="let visit of _visitService.visits[key]">
        <tr>
          <td (click)="add_payment_to_tabs('Payment',visit)"><a>{{visit.visit_date}}</a></td>
          <td>{{visit.doctor_name}}</td>
          <td>{{visit.patient_name}}</td>
          <td>{{visit.location_name}}</td>
          <td>{{visit.visit_reason}}</td>
          <td>{{removeUnderScore(visit.appointment_type)}}</td>
          <td>{{status_mapper[visit.status_id]}}</td>
          <td class="text-right">{{visit.charges | number:'1.0-0'}}</td>
          <td class="text-right">{{visit.payment | number:'1.0-0'}}</td>
          <td class="text-right">{{visit.discount | number:'1.0-0'}}</td>
          <td class="text-right">{{visit.balance | number:'1.0-0'}}</td>
        </tr>
      </ng-container>
    </ng-container>
    </tbody>
  </table>
  <div class="text-center">
    <pagination  [totalItems]="_visitService.totalItems" [itemsPerPage]="25" (pageChanged)="pageChanged($event)"></pagination>
  </div>
</ng-container>
