import {Component, OnInit, Input} from '@angular/core';
import {Practice} from '../../routes/practices/practices/practice';
import {IntegrationService} from '../integration.service';
import {IntegrationCredential} from '../integration_credential';
import {DoctorService} from '../../services/doctor.service';
import {Doctor} from '../../routes/practices/doctors/doctor';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {
  @Input() practice: Practice;
  public credential: IntegrationCredential;
  public pms_vendors: any[];
  public doctor_listing_array = [];
  constructor(private integrationService: IntegrationService, private doctorService: DoctorService) { }

  ngOnInit() {
    this.getPracticeIntegration();
    this.getVendors();
    this.fetch_doctors();
  }

  fetch_doctors() {
    this.doctorService.getAllDoctorForPractice(this.practice.id).then(json => {
      this.doctor_listing_array = [];
      for (let doctor_profile of json) {
        let doctor = new Doctor();
        doctor.load_from_json(doctor_profile);
        doctor.practice_id = this.practice.id;
        if (!doctor.third_party_doctor_id) {
           this.doctor_listing_array.push(doctor);
        }
      }
    });
  }

  getPracticeIntegration() {
    this.integrationService.getPracticeIntegration(this.practice.id).then(resp => {
      this.credential = new IntegrationCredential().load_from_json(resp.practice_credentials);
      this.credential.practice_id = this.practice.id;
    })
  }
  setVendor(e: any) {
    if (e !== 0) {
      this.credential.pms_vendor = this.pms_vendors.filter(a => a.id == e)[0].name;
    }
    else {
      this.credential.pms_vendor = null;
    }
  }
  getVendors() {
    this.integrationService.getVendors().then(resp => {
      this.pms_vendors = resp;
    })
  }

}
