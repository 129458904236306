import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Device} from '../device';
import {LoaderService} from '../../../../../services/loader.service';
import {DeviceService} from '../device.service';
import {GlobalErrorHandlerService} from '../../../../../services/global-error.service';
import {AlertService} from '../../../../../_alert';
import {UserService} from '../../../../../services/user.service';
import {Patient} from '../../../../doctor/patient';
import {EnrolmentsService} from '../../../../../program/enrolments.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {
  @Output() closeDeviceModel: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Input() device: Device;
  @Input() patient: Patient;
  public device_status: Array<string> = ['allocated', 'ordered', 'delivered', 'in_use', 'discontinued'];
  public devicesToSelect: any[] = [];
  @Input() isTrainingDevice: boolean;

  constructor(public loaderService: LoaderService, public deviceService: DeviceService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public injector: Injector,
              public alertService: AlertService, public userService: UserService,
              public enrolmentsService: EnrolmentsService) { }

  ngOnInit(): void {
    this.getDevicesToSelect();
  }

  saveDevice() {
    this.loaderService.small_loder_show();
    this.deviceService.save(this.device)
      .then(resp => {
        this.device = this.device.load_from_json(resp)
        this.loaderService.small_loder_hide();
        this.deviceService.reloadDeviceList.next(1);
        this.enrolmentsService.reloadEnrolledPatient.next(1)
        this.closeForm();
        this.alertService.success('Device has been saved successfully', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  closeForm() {
    this.closeDeviceModel.emit(true);
  }

  deleteDevice(device: Device) {
    this.loaderService.show();
    this.deviceService.delete(device.id).then(resp => {
      this.deviceService.reloadDeviceList.next(1);
      this.closeForm();
      this.enrolmentsService.reloadEnrolledPatient.next(1);
      this.alertService.success('Device has been deleted successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  selectDevice(value: any) {
    let vendor = this.devicesToSelect.filter(v => v.id.toString() === value.toString())[0];
    if (vendor) {
      if (vendor.device_type === 'sleep') {
        this.getPatientSleepDevice(vendor.source_id);
      } else {
        this.device.name = vendor.name
      }
      this.device.uniq_identifier = vendor.identifier;
    }
  }

  getPatientSleepDevice(source_id: any) {
    this.loaderService.show();
    this.deviceService.getPatientSleepDevice(this.patient.id, source_id).then(resp => {
      this.setSleepDeviceFields(resp[0]);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  getDevicesToSelect() {
    this.loaderService.show();
    this.deviceService.getDevicesToSelect(this.userService.current_user.profileable['practice_id']).then(resp => {
      this.devicesToSelect = resp;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  private setSleepDeviceFields(resp: any) {
    if (resp.serialNumber) {
      this.device.mac_address = resp.serialNumber
    }
    if (resp.equipmentDataSource === 'connected' ) {
      this.device.status = 'in_use'
    }
    if (resp.assignmentDate) {
      this.device.config_date = this.device.reading_date_format(resp.assignmentDate);
    }
    if (resp.equipmentType) {
      this.device.name = resp.equipmentType
    }
    return this.device
  }
}
