import {Component, EventEmitter, Inject, Input, OnInit, Output, OnDestroy } from '@angular/core';
import {ActivatedRoute, DefaultUrlSerializer, Router} from '@angular/router'
import {AppointmentService} from '../../../../services/appointment.service';
import {Appointment} from '../../../../health/models/appointment';
import {TabsService} from '../../../../services/tabs.service';
import {UserService} from '../../../../services/user.service';
import {WalkInComponent} from '../walk-in/walk-in.component';
import {Tab} from '../../../../tabs/tab';
import {DOCUMENT} from '@angular/common';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {LoaderService} from '../../../../services/loader.service';
import {DatepickerDateCustomClasses} from 'ngx-bootstrap/datepicker';
import {HttpTokenService} from '../../../../services/http.token.service';
import baseApiUrl from '../../../../globals';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';

@Component({
  selector: 'app-todays-patients-filter',
  templateUrl: './todays-patients-filter.component.html',
  styleUrls: ['./todays-patients-filter.component.scss']
})
export class TodaysPatientsFilterComponent implements OnInit, OnDestroy {
  static  MAPPINGS = {
    doctor_id: 'doctor_id', location_id: 'location_id', status_id: 'status_id', order_by: 'order_by'
  };
  static  MAPPINGS2 = {
    doctor_id: '', location_id: '', status_id: '', order_by: ''
  };

  dateCustomClasses: DatepickerDateCustomClasses[];
  public optionsModel: string[];
  public myOptions: IMultiSelectOption[];

  public appointment: Appointment = new Appointment();
  public show_filter = true;
  public async: string;
  public order = '';
  public totalItems: string;
  public display_orders: string[] = ['Ascending', 'Descending'];
  public display_orders_mapping = {'Ascending': 'created_at asc', 'Descending': 'created_at desc'};
  public page_no = '1';
  public values: string[] = ['cancelled', 'scheduled', 'confirmed', 'checkedin', 'rescheduled', 'noshow', 'checkedout', 'admitted']
  public status = '';
  public is_open = true;
  public location_id = '';
  public status_id = '';
  public order_by = 'created_at asc';
  public date_filter: any;
  public date2DisabledDates;
  bsValue = new Date();
  @Input() app_type;
  @Input() refresh_data;
  @Input() practice;
  @Input() providers;
  @Input() locations;
  @Input() doctor_id;
  @Input() user;
  @Input() pagination_active;
  @Input() current_page;

  @Output() total_appointments: EventEmitter<Appointment[]> = new EventEmitter<Appointment[]>();
  @Output() totalcount: EventEmitter<string> = new EventEmitter<string>();
  @Output() pagination: EventEmitter<string> = new EventEmitter<string>();
  @Output() dynamic_component: EventEmitter<any> = new EventEmitter<any>();

  public show_save_filter = true;
  public appointments: Appointment[];

  // Settings configuration
  selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false
  };

  // Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Choose Status',
    allSelected: 'All selected',
  };
  private user_subscription;
  public chatroom_host: string = baseApiUrl + '/cable';
  constructor(@Inject(DOCUMENT) document: any, private loader: LoaderService,
              public _user_service: UserService, private route: ActivatedRoute,
              private router: Router, private _appointment_service: AppointmentService,
              private _tabs_service: TabsService, private token_service: HttpTokenService,
              private globalErrorHandlerService: GlobalErrorHandlerService) {
  }

  ngOnDestroy() {
    this.user_subscription!.unsubscribe();
    console.log('Cable', this.token_service.cable)
  }

  ngOnInit() {
    this.myOptions = [
      { id: 'cancelled', name: 'cancelled' },
      { id: 'scheduled', name: 'scheduled' },
      { id: 'confirmed', name: 'confirmed' },
      { id: 'checkedin', name: 'checkedin' },
      { id: 'rescheduled', name: 'rescheduled' },
      { id: 'noshow', name: 'noshow' },
      { id: 'checkedout', name: 'checkedout' },
      { id: 'admitted', name: 'admitted' }
    ];

    this.appointments = [];
    this.route.queryParams.subscribe(params => {
      Object.keys(TodaysPatientsFilterComponent.MAPPINGS).forEach(key => {
        if (params[key]) {
          this[TodaysPatientsFilterComponent.MAPPINGS[key]] = params[key];
        }
      });
    });
    this.set_mappings2();
    let filter = this.app_type === 'video' ? this.user.telemed_filter : this.user.filter;
    this.router.navigateByUrl(`${this.router.url.split('?')[0]}?${filter}`);

    this.token_service.subscriptionObserver.subscribe((c) => {
      console.log('subscribing in today patient');
      this.subscribeToChannel();
    });
    this.getAppointmentsDates();
    if (!this.user_subscription) {
      this.subscribeToChannel();
    }
  }

  subscribeToChannel() {
    this.user_subscription = this.token_service.cable.subscriptions.create(
      'UserChannel',
      {
        connected: () => console.log('connected'),
        disconnected: () => console.log('disconnected'),
        received: (data) => this.receivedHandler(data)
      });
    console.log('today patient', this.token_service.cable)
  }
  receivedHandler(broadcasting) {
    console.log('broadcasting', broadcasting);
    if ((broadcasting as any).channel_action === 'appointment_channel') {
      let app = this.appointments.filter(a => a.id === (broadcasting as any).appointment_id)[0];
      app.tick = 0;
      if (app) {
        app.status_id = (broadcasting as any).status_id;
      }
    }
  }

  private set_mappings2() {
    let filter = this.app_type === 'video' ? this.user.telemed_filter : this.user.filter;
    let c = new DefaultUrlSerializer().parse(`${this.router.url.split('?')[0]}?${filter}`);
    Object.keys(c.queryParams).forEach(key => {
      TodaysPatientsFilterComponent.MAPPINGS2[key] = c.queryParams[key];
    });
    this.show_save_filter = this.check_filter_change();
  }

  selectStatuses(event) {
    this.status_id = this.optionsModel.sort().join(',');
  }

  ngOnChanges() {
    this.optionsModel = this.status_id.length > 0 ? this.status_id.split(',') : [];
    this.apply_filters();
    this.getAppointmentsDates();
  }

  performSearch() {
    this.router.navigate([`${this.router.url.split('?')[0]}`], { queryParams: this.search_params });
    this.apply_filters();
  }

  get search_params() {
    if (this.date_filter == '' || this.date_filter == undefined) {
      this.date_filter = new Date();
    }
    this.date_filter = new Date(this.date_filter);
    return {
      doctor_id: this.doctor_id, location_id: this.location_id, app_type: this.app_type,
      status_id: this.status_id, date_filter: this.date_filter, order_by: this.order_by.replace('+', ' '), page: this.current_page
    };
  }

  apply_filters() {
    if (this.doctor_id || this.doctor_id == '') {
      this.doctor_id = this.doctor_id;
    } else if (this.doctor_id == undefined) {
      this.doctor_id = '';
      return;
    }
    this.loader.show();
    this.appointments = [];
    this._appointment_service.getAppointments(this.search_params).then(json_response => {
        if (json_response.appointments && json_response.appointments.length > 0) {
          this.appointments = json_response.appointments.filter(e => e.appointment_from !== 'emergency')
        }
        this.appointments = this.appointments.map(appointment => new Appointment().load_from_json(appointment));
        this.totalItems = json_response.total;
        this.page_no = json_response.current_page;

        this.total_appointments.emit(this.appointments);
        this.totalcount.emit(this.totalItems);
        this.pagination.emit(this.page_no);
        this.loader.hide();
      }).catch(
      resp => {
        console.log(resp);
        this.loader.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      }
    );
    this.set_mappings2();
  }

  add_to_tabs(title) {
    if (this.appointment.id) {
      this.appointment = new Appointment();
    }
    this._tabs_service.add_tab(
      new Tab({title: title, icon: 'fa fa-hospital', autorefresh: false},
      WalkInComponent, title,
        {flow_type: title, appointment: new Appointment(), locations: this.locations, practice: this.practice}
        )
    )
  }


  change_provider(id) {
    if (id == '') {
      this.doctor_id = '';
      return;
    }
    this.doctor_id = id;
  }

  change_location(id) {
    if (id == '0') {
      this.location_id = '0';
      return;
    }
    this.location_id = id;
  }

  change_status(status) {
    if (status == 'status') {
      this.status_id = '';
      return;
    }
    this.status_id = status;
  }

  capitalize(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
  }

  iconMapping(index) {
    return this.values[index];
  }

  save_filter() {
    let query_string = document.location.href.split('?')[1];
    this._user_service.save_filter(query_string).then(json_response => {
      this.user.filter =  query_string;
      this.set_mappings2();
    });
  }
  save_telemed_filter() {
    let query_string = document.location.href.split('?')[1];
    this._user_service.save_telemed_filter(query_string).then(json_response => {
      this.user.telemed_filter =  query_string;
      this.set_mappings2();
    });
  }

  check_filter_change() {
    if (this.status_id != TodaysPatientsFilterComponent.MAPPINGS2['status_id'] || this.doctor_id != TodaysPatientsFilterComponent.MAPPINGS2['doctor_id'] || this.location_id != TodaysPatientsFilterComponent.MAPPINGS2['location_id'] || this.order_by.replace('+', ' ') != TodaysPatientsFilterComponent.MAPPINGS2['order_by'].replace('+', ' ')) {
     return true;
    }
    return false;
  }

  fetchVendorAppointments() {
    this.loader.show();
    this._appointment_service.fetch_vendor_appointments(this.date_filter).then(resp => {
      this.apply_filters();
    })
  }

  async getAppointmentsDates() {
    this.dateCustomClasses = [];
    return this.dateCustomClasses = await this._appointment_service.getAppointmentDates( this.app_type).then
      (resp =>
        resp.map(a => ({date: new Date(a['date']), classes: a['classes']}))
      )
  }


}
