import { Component, OnInit, ViewChild } from '@angular/core';
import {SettingsService} from '../../core/settings/settings.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import { NgForm } from '@angular/forms';
import { CustomValidations } from '../customValidations/custom-validations'
import {HttpTokenService} from '../../services/http.token.service';
export interface ResetPasswordData {
  login: string;
  userType?: string;
  email: string;
}
@Component({
    selector: 'recover',
    templateUrl: 'recover.component.html'
})
export class RecoverComponent implements OnInit  {

    public _resetPasswordData: ResetPasswordData = <ResetPasswordData>{};
    private _output: any;
    public show_message = false;
    public email_error = false;
    public customValidations: CustomValidations;
    public disable_button = false;
    public show_notice = false;
    public notice = '';

  @ViewChild('resetPasswordForm') resetPasswordForm: NgForm;
    constructor(private activatedRoute: ActivatedRoute, private globalErrorHandlerService: GlobalErrorHandlerService, private _tokenService: HttpTokenService, public settings: SettingsService, private router: Router) {
      this.customValidations = new CustomValidations();
    }

    // Submit Data to Backend
    onSubmit() {
        this._output = null;
        this.disable_button = true;
        this._resetPasswordData.login = this._resetPasswordData.email;
        this._tokenService.resetPassword(this._resetPasswordData).subscribe(
            res => {
              this._resetPasswordData    = <ResetPasswordData>{};
              this._output                = res;
              this.show_message = true;
              this.disable_button = false;
              setTimeout(() => {
                this.router.navigate(['home'])
              }, 5000)
              }, resp => {
                // this.email_error = true;
                this.globalErrorHandlerService.error = resp.error.errors[0];
                this._resetPasswordData    = <ResetPasswordData>{};
                this._output                = resp.error.errors[0];
                this.disable_button = false;
          }
        );
    }
    ngOnInit() {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        this.notice = params['notice'];
        this.setTimeOutFailsNotice();
      });
    }

  setTimeOutFailsNotice(): any {
    this.show_notice = true;
    setTimeout(() => {
      this.show_notice = false;
    }, 4000)
  }
  /*ngAfterViewChecked() {
    this.customValidations.formChanged(this.resetPasswordForm);
  }*/
}
