import { Component, OnInit } from '@angular/core';
import { RegisteredPracticesService } from '../../../services/registered-practices.service'
import { PermissionsService } from '../.././permissions/permissions.service';
import { Observable }        from 'rxjs/Observable';
import { Practice } from '../../practices/practices/practice'
import {Tab} from '../../../tabs/tab'
import {TabsService} from '../../../services/tabs.service'
import  {WizardComponent} from  '../wizard/wizard.component'
import {LoaderService} from '../../../services/loader.service';
import { mergeMap } from 'rxjs/operators';
import {Globals} from '../../../globals';
import {UserService} from '../../../services/user.service';
import {AdminLoginService} from '../../../services/admin_login_service';

@Component({
  selector: 'app-registered-practices',
  templateUrl: './registered-practices.component.html',
  styleUrls: ['./registered-practices.component.scss']
})
export class RegisteredPracticesComponent implements OnInit {
  public practices: Practice[];
  public practice: Practice = new Practice();
  public practice_array: Practice[] = [];
  public practice_id = '0';
  public dataSource: Observable<any>;
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public is_active: boolean[] = [true];
  public totalItems: string;
  public auth_token: string;
  public currentPage = '1';
  public values: string[] = ['All', 'Active', 'In-Active']

  constructor(public globals: Globals, private loader: LoaderService,
              private _permissionService: PermissionsService,
              private _registeredPracticeService: RegisteredPracticesService,
              public tabs_service: TabsService, private userService: UserService) {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.getPractices(token)));
    this.getPracticesList('1');
    this.tabs_service.register('regisitered_practices_components');
  }

  ngOnInit() {
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  getPracticeIntegrationCode() {
    return;
  }

  set_token(practice) {
    this.auth_token = practice.auth_token;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.practice_id = e.item.id;
  }

  public getPractices(token): Observable<any> {
    return this._permissionService.getPracticesAsObservable(token, 3);
  }

  getPracticesList(page_no) {
    this.loader.show();
    this._registeredPracticeService.getAllPractices(page_no, this.practice_id, this.is_active).then(res => {
      this.loader.hide();
      this.practice_array = [];
      this.practices = res.practices;
      for (let practice of this.practices) {
        let pract = new Practice();
        pract.load_from_json(practice);
        this.practice_array.push(pract);
      }
      this.totalItems = res.total;
      this.currentPage = res.current_page;
      this.practice_id = '0';
    })
  }

  filtered_practices() {
    return this.practice_array;
  }

  public pageChanged(event: any): void {
    this.getPracticesList(event.page);
  };

  public changeStatus(status_value) {
    this.is_active = (status_value == '3') ? [false] : [true]
    if (status_value == '1') {
      this.is_active = [];
    }
  }

  setPracticeId(e: any) {
    this.practice_id = e.item.id;
  }

  public search_filters() {
    this.currentPage = '1';
    this.getPracticesList(this.currentPage);
  }

  public changePracticeState(practice) {
    practice.client.is_active = (practice.client.is_active == 'true') ? true : false;
    this._registeredPracticeService.updatePracticeState(practice).then(res => {
    })
  }

  add_to_tabs(practice) {
    let title = practice.id ? practice.name : 'Add';
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'},
      WizardComponent, practice.id,
      {id: practice.id, practice_permissions: 'edit,practices,manage', doctor_permissions: 'edit,doctors,manage'}))
  }
}
