<ng-container *ngIf="emergency_service.appointments">
  <ng-container *ngIf="emergency_service.appointments && emergency_service.appointments.length == 0"><app-no-result-found></app-no-result-found></ng-container>
  <div class="todaysPatient-list">
    <div class="list clearfix" *ngFor="let appointment of emergency_service.appointments;let i = index">
      <div class="col-sm-3 picName-area">
        <div class="cell-holder">
          <img class="img-rounded thumbnail thumb64 pic" [src]="appointment.patient.profile_pic" alt="Image"/>
          <h4 class="m0 mb fw-500"><a (click)="add_to_tabs(appointment)">{{appointment.patient.name}}</a></h4>
          <h5 class="m0 mb">
            <i [ngClass]="(appointment.patient.user.gender == 'female')?'fa icon-user-female':'fa icon-user'"></i>&nbsp;
            {{appointment.patient.show_age_and_gender()}}
          </h5>
          <h5 class="m0 mb"><i class="fa icon-calendar"></i>&nbsp; {{appointment.patient.customize_dob}}</h5>
          <ng-container *ngIf="appointment.patient.mobile_number_value">
            <h5 class="m0"><i class="fa icon-phone"></i>&nbsp; {{appointment.patient.mobile_number_value}}</h5>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-2 time-area">
        <div class="cell-holder text-center" [title]="appointment.timeZone">
          <h4 class="mt0 fs20">{{appointment.appointment_time_only}}</h4>
          <h5 class="m0">{{ appointment.slot_size }} Mins</h5>
        </div>
      </div>
      <div class="col-sm-6 patient-info">
        <div class="row">
          <div class="col-sm-10 col-xs-10">
            <div class="row mb-sm" *ngIf="appointment.service">
              <div class="col-sm-3 col-xs-12 pr0"><label class="fw-500 m0">Reason of Visit:</label></div>
              <div class="col-sm-9 col-xs-12">{{appointment.service.name}}</div>
            </div>
            <div class="row mb-sm">
              <div class="col-sm-3 col-xs-12 pr0"><label class="fw-500 m0">Appointment:</label></div>
              <div class="col-sm-9 col-xs-12">
                <span class="text-primary fw-500">{{appointment.doctor.prefix}} {{appointment.doctor.name}}</span>
                <span class="text-muted fw-500">({{appointment.appointment_type}})</span>
              </div>
            </div>
            <div class="row mb-sm">
              <div class="col-sm-3 col-xs-12 pr0"><label class="fw-500 m0">Location:</label></div>
              <div class="col-sm-9 col-xs-12">{{appointment.location.complete_address}}</div>
            </div>
            <div class="row mb-sm">
              <div class="col-sm-3 col-xs-12 pr0"><label class="fw-500 m0">Status:</label></div>
              <div class="col-sm-9 col-xs-12">
                <div class="media">
                  <div class="media-left">
                    <span class="text-center status-colors" [ngClass]="appointment.status_id">{{capitalize(appointment.status_id)}}</span>
                  </div>
                  <small class="media-body vl-m">
                    at {{ appointment.log_time | date: 'hh:mm a MMM dd, y':appointment.time_zone_offset }}
                    &nbsp;
                    <ng-container *ngIf="appointment.actioned_by">by {{ appointment.actioned_by}}</ng-container>
                  </small>
                </div>
              </div>
            </div>
            <div class="row mb-sm" *ngIf="appointment.notes_for_doctor">
              <div class="col-sm-3 col-xs-12 pr0"><label class="fw-500 m0">Note:</label></div>
              <div class="col-sm-9 col-xs-12">{{capitalize(appointment.notes_for_doctor)}}</div>
            </div>
          </div>
          <ng-container *ngIf="appointment.appointment_charges > 0">
            <div class="col-sm-2 col-xs-2 pl0">
              <div class="fs12 cell-holder text-center">
                <span class="text-primary">{{appointment.appointment_charges | number}}</span>
                <span class="text-danger">({{appointment.appointment_balance | number}})</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-2 btn-area">
        <ng-container *ngIf="appointment">
          <div class="cell-holder">
            <app-emergency-statuses [appointment]="appointment" (edited_appointment)="change_status($event)" ></app-emergency-statuses>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
