import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {User} from '../../../../users/user';
import {Appointment} from '../../../../../health/models/appointment';
import {Observable} from 'rxjs';
import {Practice, Service} from '../../../../practices/practices/practice';
import {Doctor} from '../../../../practices/doctors/doctor';
import {SlotDatesService} from '../../../../../services/slot-dates.service';
import {UserService} from '../../../../../services/user.service';
import {AppointmentService} from '../../../../../services/appointment.service';
import {PatientService} from '../../../../../services/patient.service';
import {SpecialtySearchService} from '../../../../../services/specialty-search.service';
import {DoctorService} from '../../../../../services/doctor.service';
import {TabsService} from '../../../../../services/tabs.service';
import {mergeMap} from 'rxjs/operators';


@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styleUrls: ['./reschedule.component.scss']
})
export class RescheduleComponent implements OnInit {

  public user: User = new User();
  public appointment: Appointment;
  public dataSourceServices: Observable<any>;
  public asyncSelected = '';
  public asyncSelectedService = '';
  public typeaheadLoading = false;
  public typeaheadLoadingService = false;
  public typeaheadNoResults = false;
  public typeaheadNoResultsService = false;
  public is_data_available = false;
  public is_disable = true;
  public practice: Practice = new Practice();
  public doctor_id: string;
  public  refresh = false;
  public doctor = new Doctor();
  constructor(private _tab_service: TabsService, private _slot_date_service: SlotDatesService,
              private injector: Injector, public _user_service: UserService,
              private _appointment_service: AppointmentService,
              private _patient_service: PatientService,
              private specialtySearchService: SpecialtySearchService,
              private doctorService: DoctorService, private ref: ChangeDetectorRef) {
    this.dataSourceServices = Observable.create((observer: any) => {
      observer.next(this.asyncSelectedService);
    }).pipe(mergeMap((token: string) => this.get_services(token)));
  }

  ngOnInit() {
    this.initialize_appointment_data();
  }

  fetchDoctor() {
    this.doctorService.getDoctor(parseInt(this.doctor_id)).then(json => {
      this.doctor.load_from_json(json);
      this._slot_date_service.got2Today();
      this.refresh = !this.refresh
    });
  }

  initialize_appointment_data() {
    this.appointment = this.injector.get('appointment', null);
    console.log(this.appointment);
    this.appointment.status_id = 'scheduled';
    this.appointment.parent_id = this.appointment.id;
    this.change_provider(this.appointment.doctor.id);
    this.appointment.service_id = this.appointment.service ? this.appointment.service.id : null;
    this.asyncSelectedService = this.appointment.service ? this.appointment.service.name : '';
  }

  book_appointment(appointment_type) {
    this.appointment.appointment_from = appointment_type;
    this.appointment.appointment_with = 'doctor';
    this._appointment_service.save(this.appointment).then(res => {
      this.appointment = res;
      this._tab_service.set_data(true);
      this._tab_service.close_tab(this._tab_service.active_tab());
    });
  }

  get_services(term: any) {
    return this.specialtySearchService.search_services(term);
  }

  set_asyncSelected_service(event, service: Service) {
    this.asyncSelectedService = this.appointment.service_name;
  }

  public changeTypeaheadNoResultsService(e: boolean): void {
    this.typeaheadNoResultsService = e;
  }

  public typeaheadOnSelectService(e: any): void {
    this.appointment.service_id = e.item.id;
  }

  public changeTypeaheadLoadingService(e: boolean): void {
    this.typeaheadLoadingService = e;
  }

  handle_user(new_user) {
    this.asyncSelected = new_user.first_name + ' ' + new_user.last_name;
    this.appointment.patient_id = new_user.profileable_id;
  }

  load_practice_data(user) {
    if (user && user.id && !this.is_data_available) {
      this.user = this.user.load_from_json(user);
      this.is_data_available = true;
      this._appointment_service.get_todays_patient_filter(user).then(json => {
        if (json.id) {
          this.practice.load_from_json(json);
          this.appointment.created_by_id = user.id;
        } else {
          this.doctor_id = user.profileable_id;
        }
        return true;
      });
    }
    return !!(user && user.id);
  }

  get providers() {
    if (this.practice.id) {
      return this.practice.active_doctors;
    }
  }

  change_provider(id) {
    if (id === '') {
      this.doctor_id = '';
      return;
    }
    this.doctor_id = id;
    this.appointment.doctor_id = parseInt(this.doctor_id);
    this.fetchDoctor();
  }

  handle_slot(slot) {
    this.appointment.date = slot.slot;
    this.appointment.calendar_id = slot.calendar_id;
    this.is_disable = false;
  }

}
