import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Conversation} from '../conversation';
import {SmsService} from '../sms.service';
import {SMSMessage} from '../sms';
import {UserService} from '../../../../services/user.service';
import {Tab} from '../../../../tabs/tab';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {Appointment} from '../../../../health/models/appointment';
import {TabsService} from '../../../../services/tabs.service';
import {PhoneService} from '../../../../voice-call/phone.service';

@Component({
  selector: 'app-sms-details',
  templateUrl: './sms-details.component.html',
  styleUrls: ['./sms-details.component.scss']
})
export class SmsDetailsComponent implements OnInit, OnDestroy {
  @Input() conversation: Conversation;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('unread_label') private unreadContainer: ElementRef;
  public show_top_date: boolean;
  public timer;
  public timer2;
  public first_message: SMSMessage;
  public center_dates: any;
  temp_sms_body: any;
  sms_message: SMSMessage = new SMSMessage();

  constructor(public smsService: SmsService, public userService: UserService,
              public tabsService: TabsService, public phoneService: PhoneService) {

  }

  ngOnInit() {
    this.scrollToBottom();
    this.smsService.getScrollToBottom.subscribe(conversation => {
      if (this.conversation && conversation.id === this.conversation.id) {
        this.scrollToBottom();
      }
    });
  }

  renderedHTML(body): string {
    return body.replace(/\n/g, '<br>');
  }


  ngOnDestroy() {
    this.smsService.changeSelectedConversation(undefined);
    this.smsService.resetScrollToBottom();
  }

  scrollToBottom(): void {
    setTimeout( () => {
      let scrollTopPos = this.myScrollContainer && this.myScrollContainer.nativeElement.scrollHeight + 550;
      try {
        if (this.unreadContainer && this.unreadContainer.nativeElement) {
          console.log('setting scroll for', this.unreadContainer.nativeElement);
          scrollTopPos = Math.max(this.unreadContainer.nativeElement.offsetTop - 165, 0);
        }
        console.log('set scroll to', scrollTopPos);
        if (this.myScrollContainer) {
          this.myScrollContainer.nativeElement.scrollTop = scrollTopPos;
        }
      } catch (err) { }
    }, 10);

  }

  chatroom_messages() {
    if (this.smsService.selected_conversation) {
      return this.smsService.selected_conversation.sms_messages;
    } else {
      return [];
    }
  }

  submit() {
    console.log('submitting sms');
    if (this.smsService.selected_conversation && this.smsService.selected_conversation.id) {
      this.update_chatroom_messages();
    } else {
      this.create_new_chatroom();
    }
  }

  update_chatroom_messages() {
    this.sms_message.owner_id = this.userService.current_user.id;
    this.sms_message.conversation_id = this.smsService.selected_conversation.id;
    this.sms_message.sms_type = 'outgoing';
    this.sms_message.status = 'read';
    this.smsService.create({sms_messages: this.sms_message}).subscribe(message => {
      this.smsService.selected_conversation.push_message(new SMSMessage().load_from_json(message));
      this.smsService.smsChanged();
      this.scrollToBottom();
    });
    this.sms_message = new SMSMessage();
  }

  create_new_chatroom() {
    let sms = this.conversation.setNewConversationAndMesssage(this.userService.current_user, this.smsService.patient_id, this.sms_message.body, 'outgoing');
    this.conversation.push_message(new SMSMessage().load_from_json(sms));
    this.smsService.createConversation(this.conversation).subscribe(data => {
      this.smsService.changeSelectedConversation(this.smsService.selected_conversation.load_from_json(data, true));
      this.sms_message = new SMSMessage();
    });
  }
  applySenderRecieverClass(type, message?) {
    if (type === 'outgoing') {
      return 'green_bubble';
    }
    if (type === 'incoming') {
      return 'white_bubble';
    }
  }

  @HostListener('scroll', ['$event'])
  handleThreadScroll(event) {
    const scrollTop = this.myScrollContainer.nativeElement.scrollTop;

    if (scrollTop < 40 && !this.conversation.all_loaded && !this.conversation.load_in_progress) {
      if (this.conversation.id) {
        this.smsService.load_prevMessage(this.conversation);
      }
    }

    this.page_scrolling();
  }

  page_scrolling() {
    this.show_top_date = true;
    clearTimeout(this.timer);
    clearTimeout(this.timer2);

    this.timer2 = setTimeout(() => {
      console.log('hide top date');
      this.show_top_date = false;
    }, 2000);

    this.timer = setTimeout(() => {
      const messageElements = $('.messages-container li.message');
      const firstVisibleMessage = messageElements.filter((i, e) => {
        return $(e).position().top > 30 && $(e).position().top <= 80;
      }).first();

      if (firstVisibleMessage.length > 0) {
        const message_id = parseInt(firstVisibleMessage.attr('message-id'));
        this.first_message = this.chatroom_messages().find(m => m.id === message_id);
      }

      if (this.first_message) {
        this.center_dates = this.first_message.renderDate(this.first_message.created_at);
      }
    }, 10);
  }

  add_to_tabs(patinet) {
    this.tabsService.add_tab(new Tab({title: patinet.name, icon: 'fa fa-hospital'}, PatientProfileComponent,
      patinet.user.profileable_id, {id: patinet.user.profileable_id, appointment: new Appointment}))
  }



}
