<ng-container *ngIf="!is_profile_setting_component; else formOnly">
  <div class="container flex-control flex-column">
    <div class="modal-content" style="overflow:hidden;">
      <div class="modal-header">
        <h4 class="text-center m0"><img src="assets/img/branding/logo-icon-green.png" alt="App Logo" /></h4>
      </div>
      <ng-container *ngTemplateOutlet="formOnly"></ng-container>
    </div>
    <div class="p-lg text-center fs12">
      <span>&copy;</span>
      <span>{{ settings.app.year }}</span>
      <span>-</span>
      <span>{{ settings.app.name }}</span>
    </div>
  </div>
</ng-container>

<ng-template #formOnly>
  <div class="flex-control mobile-flex">
    <div class="flex-left col-md-6 col-sm-6 bg-lightest-gray p-xl">
      <div class="media">
        <div class="media-left"><i class="fa icon-key fa-3x"></i></div>
        <div class="media-body">
          <h3 class="m0 mb-sm fw-500">Change Your Password!</h3>
          <p class="m0">Type your New Password & Current Password</p>
          <div class="mt-lg">
            <h6 class="text-secondary">Password Details</h6>
            <p class="alert alert-success fs12 p" *ngIf="show_message">Your password has been successfully updated</p>
            <ng-container *ngIf="!show_message">
              <mat-password-strength-info
                [lowerCaseCriteriaMsg]="'At least one lowercase character.'"
                [upperCaseCriteriaMsg]="'At least one uppercase character.'"
                [digitsCriteriaMsg]="'At least one digit character.'"
                [specialCharsCriteriaMsg]="'At least one special character.'"
                [minCharsCriteriaMsg]="'At least 8 characters.'"
                [enableScoreInfo]="true"
                [passwordComponent]="passwordComponent">
              </mat-password-strength-info>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <form class="flex-body col-md-6 col-sm-6 p-xl" #changePasswordForm="ngForm" (ngSubmit)="onSubmit($event)">
      <p class="alert alert-warning fs12 p" *ngIf="hippa">
        <span class="fw-500">Password expired.</span>
        Wellwink security guidelines requires you to change your password every <b>3 months</b>.
        Please change your password to continue using the system.
      </p>
      <div class="form-group"  *ngIf="resetPasswordToken">
        <input type="hidden"
               class="form-control"
               [(ngModel)]="_updatePasswordData.resetPasswordToken"
               name="reset-token">
      </div>
      <div class="form-group"  *ngIf="!resetPasswordToken">
        <label class="field-label control-label" for="current-password">Current Password*</label>
        <input type="password"
               id="current-password"
               class="form-control"
               placeholder="Current Password"
               required
               [(ngModel)]="_updatePasswordData.passwordCurrent"
               name="current-password">
      </div>
      <div class="form-group">
        <label class="field-label control-label" for="new-password">New Password*</label>
        <input id="new-password" matInput #password type="password" class="form-control mb-sm"
               [(ngModel)]="_updatePasswordData.password"
               placeholder="New Password"
               name="new-password" maxlength="25" minlength="8" required>
        <mat-password-strength #passwordComponent (onStrengthChanged)="checkPasswordStrength($event)"
                               [password]="password.value">
        </mat-password-strength>
        <div class="visible-sm visible-xs">
          <mat-slide-toggle [color]="'primary'" #toggleInfo>Show Password Details</mat-slide-toggle>
          <mat-password-strength-info *ngIf="toggleInfo.checked"
                                      [lowerCaseCriteriaMsg]="'At least one lowercase character.'"
                                      [upperCaseCriteriaMsg]="'At least one uppercase character.'"
                                      [digitsCriteriaMsg]="'At least one digit character.'"
                                      [specialCharsCriteriaMsg]="'At least one special character.'"
                                      [minCharsCriteriaMsg]="'At least 8 characters.'"
                                      [enableScoreInfo]="true"
                                      [passwordComponent]="passwordComponent">
          </mat-password-strength-info>
        </div>
      </div>
      <div class="form-group">
        <label class="field-label control-label" for="password-confirmation">New Password Confirmation*</label>
        <input type="password"
               class="form-control"
               placeholder="New Password Confirmation"
               required
               [(ngModel)]="_updatePasswordData.passwordConfirmation"
               id="password-confirmation"
               name="password-confirmation">
      </div>
      <button type="submit" class="btn btn-secondary btn-block" [disabled]="!changePasswordForm.form.valid">
        Update Password
      </button>
    </form>
  </div>
</ng-template>

<style>
  .container{ max-width:1000px; justify-content:center; overflow:visible;}
  .modal-header{ padding:8px 15px;}
  .modal-header img{ width:40px; height:40px;}
  .border-list > li.list{ min-height:auto; padding:6px 15px;}
  .flex-control{ height:auto; flex:1;}
  .flex-control .flex-left{ max-width:none; flex:1;}
  .modal-content{ border:1px solid #CACACA; box-shadow:0 0 10px rgb(0 0 0 / 20%);}

  @media (max-width:768px){
    .mobile-flex{ flex-direction:column;}
    .mobile-flex .flex-body{ flex:1;}
  }
</style>
