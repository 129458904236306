import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {PrintService} from '../../../../services/print.service';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {PatientEventService} from '../patient-event.service';


@Component({
  selector: 'app-health-calendar-report-printer',
  templateUrl: './health-calendar-report-printer.component.html',
  styleUrls: ['./health-calendar-report-printer.component.scss']
})

export class HealthCalendarReportPrinterComponent implements OnInit {
  @ViewChild('report', { static: false }) report: ElementRef | undefined;
  public stats: any;
  public filter: Object;
  public patientName: string;
  public events: any[];

  constructor(public injector: Injector, public printService: PrintService, public userService: UserService, public patientEventService: PatientEventService,
              public loaderService: LoaderService) { }

  ngOnInit(): void {
    this.stats = this.injector.get('stats', null);
    this.filter = this.injector.get('filter', null);
    this.patientName = this.injector.get('patientName', null);
  }

  generatePdf(action: string) {
    this.loaderService.show();
    this.printService.generatePdf(action, this.report, true, 'HealthCalendarReport.pdf');
  }
}
