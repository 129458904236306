import { Component, OnInit, Input } from '@angular/core';
import {Library} from '../library_setting';

@Component({
  selector: 'app-libraries',
  templateUrl: './libraries.component.html',
  styleUrls: ['./libraries.component.scss']
})
export class LibrariesComponent implements OnInit {
  @Input() libraries: Library;
  constructor() { }

  ngOnInit() {
  }

}
