<ul class="flex-header pd-custom-tabs nav nav-pills pb0" [ngClass]="dynamicClassForVerificationBanner()">
  <li *ngFor="let stage_id of stage_ids"  [class.active]="selected_stage_id == stage_id ">
    <button class="btn" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="stage_id"
            (click)="onSelect(stage_id)">{{menu_items[stage_id]}}
    </button>
  </li>
  <li [class.active]="selected_stage_id === 10 " *ngIf="!_userService.current_user.isPatient">
    <button class="btn" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="10"
            (click)="onSelect(10)">{{menu_items[10]}}
    </button>
  </li>
  <li [class.active]="selected_stage_id === 6 " *ngIf="!_userService.current_user.isPatient">
    <button class="btn" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="stage_id"
            (click)="onSelect(6)">{{menu_items[6]}}
    </button>
  </li>
  <li [class.active]="selected_stage_id === 7">
    <button class="btn" [(ngModel)]="selected_stage_id" name="programs" ngDefaultControl [value]="7"
            (click)="onSelect(7)">{{menu_items[7]}}
    </button>
  </li>
  <ng-container *ngIf="_userService.current_user && _userService.current_user.is_pchf">
    <li [class.active]="selected_stage_id == 4 ">
      <button class="btn" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="stage_id"
              (click)="onSelect(4)">{{menu_items[4]}}
      </button>
    </li>
    <li [class.active]="selected_stage_id == 5 ">
      <button class="btn" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="stage_id"
              (click)="onSelect(5)">{{menu_items[5]}}
      </button>
    </li>
  </ng-container>
  <li [class.active]="selected_stage_id == 8 " *ngIf="_userService.current_user.isPracticeUser">
    <button class="btn" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="8"
            (click)="onSelect(8)">{{menu_items[8]}}
    </button>
  </li>
</ul>
<app-summary class="flex-body scroll-browser p" *ngIf="selected_stage_id == 0 && patient.id" [patient]="patient"></app-summary>
<app-documents class="flex-body" *ngIf="selected_stage_id == 2 && patient.id" [appointment]="appointment" [patient]="patient" ></app-documents>
<app-demographics class="flex-body scroll-browser p" *ngIf="selected_stage_id == 3 && patient.id" [patient]="patient"></app-demographics>
<app-list-appointments class="flex-body scroll-browser" *ngIf="selected_stage_id == 1 && patient.id" [patient]="patient"></app-list-appointments>
<app-addiotional-info class="flex-body scroll-browser p" *ngIf="selected_stage_id == 4" [patient]="patient"></app-addiotional-info>
<app-case-info class="flex-body scroll-browser p" *ngIf="selected_stage_id == 5" [patient]="patient"></app-case-info>
<app-patient-communication-log class="flex-body flex-control flex-column" *ngIf="selected_stage_id === 6" [patient]="patient"></app-patient-communication-log>
<app-patient-programs class="flex-body scroll-browser p" *ngIf="selected_stage_id === 7" [patient]="patient"></app-patient-programs>
<app-patient-setting  class="flex-body flex-control flex-column" *ngIf="selected_stage_id === 8" [patient]="patient"></app-patient-setting>
<app-health-calendar class="flex-body flex-control flex-column calendar-component program-calendar" *ngIf="selected_stage_id === 9" [patient]="patient"></app-health-calendar>
<app-patient-provider-enotes class="flex-body"  *ngIf="selected_stage_id === 10" [patient]="patient"></app-patient-provider-enotes>
