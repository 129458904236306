import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../services/nullRemover';
import {Program} from './program';
import {Observable, Subject} from 'rxjs';
import {ProgramEnrollment} from './eligible-patient';
import {PatientSummary} from '../health/doctor/patient-summary';
import {ProgramDocument} from './program-enrolment/program-document';
import {Consent} from './program/program-consents/consent';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  reloadProgram: Subject<any> = new Subject<any>();
  reloadEnrolledPatientHead: Subject<any> = new Subject<any>();
  eligiblePatientListener: Subject<any> = new Subject<any>();
  enrolledPatientListener: Subject<any> = new Subject<any>();
  patientOptOutListener: Subject<any> = new Subject<any>();
  createReportListener: Subject<any> = new Subject<any>();
  public currentProgramEnrollmentObject: ProgramEnrollment = new ProgramEnrollment();

  constructor(private _tokenService: CustomHttpClient) { }

  create(program: Program): Promise<any> {
    return this._tokenService
      .post('programmes', program.to_params())
      .toPromise()
      .then(response => response)
  }

  save(program: Program): Promise<any> {
    if (program.is_new_record()) {

      return this.create(program);
    }
    else {
      return this.update(program);
    }
  }

  update(program: Program) {
    return this._tokenService
      .put('programmes', program.to_params())
      .toPromise()
      .then(response => response)
  }
  delete(program: number) {
    return this._tokenService
      .delete('programmes?id=' + program)
      .toPromise()
      .then(response => response)
  }

  getPracticePrograms(): Promise<any> {
    return this._tokenService.get('programmes/practice_programs')
      .toPromise()
      .then(response => response)
  }

  getPrograms(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('programmes', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          programmes: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  getProgram(id: any): Promise<any> {
    return this._tokenService.get('programmes/programme?id=' + id)
      .toPromise()
      .then(response => response)
  }

  getPatient(id: any): Promise<any> {
    return this._tokenService.get('programmes/patient?id=' + id)
      .toPromise()
      .then(response => response)
  }

  getCPTCodes(type: any) {
    let params = new HttpParams();
    params =  params.set('code_type', type);
    return this._tokenService.get('programmes/cpt_codes', {params: params})
      .map((resp) => resp);
  }

  getPracticeDoctorUsers() {
    return this._tokenService.get('practices/get_practice_doctor_users')
      .toPromise()
      .then(response => response)
  }

  getPracticeDoctorWithUsers() {
    return this._tokenService.get('practices/practice_doctor_users')
      .toPromise()
      .then(response => response)
  }

  getVitals() {
    return this._tokenService.get('vitals')
      .toPromise()
      .then(response => response)
  }

  getCareTeam() {
    return this._tokenService.get('users/practice_users')
      .toPromise()
      .then(response => response)
  }

  getAllPracticePrograms(filter?: any) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.get('programmes/programme_list', { params: params})
      .toPromise()
      .then(response => response)
  }

  getProgramEligiblePatients(filter: any) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('programmes/eligible_patients', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          eligible_patients: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  getTypeAheadProgramEligiblePatients(filter) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.get('programmes/search_patients', { params: params})
      .toPromise()
      .then(response => { return response })
  }
  enrollPatientWithProgram(programEnrolment: ProgramEnrollment) {
    if (programEnrolment.is_new_record()) {

      return this.createEnrollPatientWithProgram(programEnrolment);
    }
    else {
      return this.updateEnrollPatientWithProgram(programEnrolment);
    }
  }

  createEnrollPatientWithProgram(programEnrolment: ProgramEnrollment) {
    return this._tokenService
      .post('programmes/programme_enrolment', programEnrolment.to_params())
      .toPromise()
      .then(response => response)
  }

  updateEnrollPatientWithProgram(programEnrolment: ProgramEnrollment) {
    return this._tokenService
      .put('programmes/programme_enrolment', programEnrolment.to_params())
      .toPromise()
      .then(response => response)
  }

  searchDiagnosis(token) {
    let args = new HttpParams();
    args = args.set('token', token);
    return this._tokenService.get('programmes/diagnosis', {params: args})
      .toPromise()
      .then(response => response)
  }

  createPatientSummary(patientSummary: PatientSummary): Promise<any> {
    return this._tokenService
      .post('patient_summaries', patientSummary.to_params())
      .toPromise()
      .then(response => response)
  }

  deletePatientSummary(id: any) {
    return this._tokenService
      .delete('patient_summaries?id=' + id)
      .toPromise()
      .then(response => response)
  }

  saveConsentOfPatient(programEnrollmentId, signed_at_date, signed_at_time, consent_text, consent_from) {
    let  params = {id: programEnrollmentId, signed_at_date: signed_at_date, signed_at_time: signed_at_time,
      consent_text: consent_text, consent_from: consent_from}
    return this._tokenService.post('programmes/consent', nullRemover(params))
      .toPromise()
      .then(resp => resp)
  }

  getProgramAttachments(id: any): Promise<any> {
    return this._tokenService.get('programmes/programme_enrolment_attachments?id=' + id)
      .toPromise()
      .then(response => response)
  }

  deleteProgramAttachment(id: any) {
    return this._tokenService
      .delete('programmes/programme_enrolment_attachment?id=' + id)
      .toPromise()
      .then(response => response)
  }

  getProgramCarePlan(program_id: any, patient_id: any): Promise<any> {
    let  param = {program_id: program_id, patient_id: patient_id}
    return this._tokenService.get('programmes/care_plan' , {params: param})
      .toPromise()
      .then(response => response)
  }

  getProgramCarePlansForDropdown(enrolment_id: any): Promise<any> {
    let param = {enrolment_id: enrolment_id}
    return this._tokenService.get('programme_reports/care_plans' , {params: param})
      .toPromise()
      .then(response => response)
  }

  getProgramCarePlans(program_id: any, patient_id: any, page: number): Promise<any> {
    let param = {program_id: program_id, patient_id: patient_id, page: page}
    let params = new HttpParams({fromObject: nullRemover(param)});
    return this._tokenService.getWithHeaders('programmes/care_plans', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          care_plans: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }




  confirmEnrollment(id: number) {
    let  params = {id: id};
    return this._tokenService
      .put( 'programmes/confirm_enrolment' , params)
      .toPromise()
      .then(response => response)
  }

  getEligiblePatient(id: any): Promise<any> {
    return this._tokenService.get('programmes/eligible_patient?id=' + id)
      .toPromise()
      .then(response => response)
  }

  programmePatient(program_id, patient_id) {
    return this._tokenService.get('programmes/' + program_id + '/programme_patient/' + patient_id)
      .toPromise()
      .then(response => response)
  }

  getProgramsEnrolledPatients(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('programmes/programme_patients', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          programme_patients: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  /*get Patient Vitals*/

  getPatientVitals(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.get('programmes/patient_vitals', { params: params})
      .toPromise()
      .then(response => { return response })
  }

  /*
   get Program Enrollment Object
  */

  getProgramEnrollmentObject(program_id: any, patient_id: any): Promise<any> {
    let  param = {program_id: program_id, patient_id: patient_id}
    return this._tokenService.get('programmes/programme_enrolment' , {params: param})
      .toPromise()
      .then(response => response)
  }

  /*Get Dashboard Data*/

  getDashboardData(): Promise<any> {
    return this._tokenService.get('programmes/dashboard_data')
      .toPromise()
      .then(response => response);
  }
  getCMSData(filter: Object): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.get('programmes/programme_count_data', {params: params})
      .toPromise()
      .then(response => response);
  }

  programme_graph_data(data) {
    let params = new HttpParams({fromObject: data});
    return this._tokenService
      .get('/programmes/programme_graph_data', {params: params})
      .toPromise().then(response => response)
  }

  getSleepData(patient_id: any, date: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('patient_id', patient_id);
    params = params.set('date', date);
    return this._tokenService.get('vitals/sleep_vitals_on_demand', {params: params})
      .toPromise()
      .then(response => response);
  }

  getSleepReport(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getFile('vitals/get_sleep_report', {observe: 'response', responseType: 'blob', params:  params})
      .toPromise()
      .then(response => response);
  }

  createConsent(consent: Consent): Promise<any> {
    return this._tokenService
      .post('consents', consent.to_params())
      .toPromise()
      .then(response => response)
  }

  saveConsent(consent: Consent): Promise<any> {
    if (consent.is_new_record()) {

      return this.createConsent(consent);
    }
    else {
      return this.updateConsent(consent);
    }
  }

  updateConsent(consent: Consent) {
    return this._tokenService
      .put('consents', consent.to_params())
      .toPromise()
      .then(response => response)
  }

  deleteConsent(id: number) {
    return this._tokenService
      .delete('consents?id=' + id)
      .toPromise()
      .then(response => response)
  }

  getConsents(filter: any) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.get('consents', { params: params})
      .toPromise()
      .then(response => { return response })
  }
}
