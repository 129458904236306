import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Announcement} from '../announcement';
import {AnnouncementsService} from '../../../services/announcements.service';
import {TabsService} from '../../../services/tabs.service';
import {Tab} from '../../../tabs/tab';
import {AnnouncementComponent} from '../announcement/announcement.component';

@Component({
  selector: 'app-announcements-listing',
  templateUrl: './announcements-listing.component.html',
  styleUrls: ['./announcements-listing.component.scss']
})
export class AnnouncementsListingComponent implements OnInit {
  @Input() announcements: Announcement[];
  @Input() totalItems: string;
  @Output() nextPage = new EventEmitter<any>();
  constructor(private tabs_service: TabsService) { }

  ngOnInit() {
  }
  open_in_tab(announcement: Announcement) {
    this.tabs_service.add_tab(new Tab({title: 'Add', icon: 'fa fa-hospital'}, AnnouncementComponent, announcement, {announcement: announcement}))

  }
  pageChanged(page) {
    this.nextPage.emit(page.page);
  }

}
