export class Role {
  id: number;
  name: string;
  role_type: string;

  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.role_type = json.role_type;
    return this;

  }
}
