<div class="flex-control flex-column flex-height">
  <app-payment-header class="flex-header" [appointment]="appointment" [admission]="admission"></app-payment-header>
  <div class="flex-body bg-lightest-gray p-sm flex-control upload-docs-sec" [class.mouse-pressed]="mouse_pressed">
    <div class="flex-body flex-control flex-column border-box">
      <div class="flex-header">
        <div class="pull-right">
          <button class="btn btn-secondary btn-sm upload-btn" *ngIf="!scan_service.scanning_in_process" (click)="choose_file()">
            Upload
          </button>
          <button class="btn btn-inverse btn-sm upload-btn disabled" *ngIf="scan_service.scanning_in_process">
            <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
          </button>
        </div>
        <h5 class="pull-right mr">Only PDF files are compatible</h5>
        <h4 class="fs16 fw-500 mb0 mt-sm lhn">Scanning & Indexing</h4>
      </div>
      <div class="flex-body scroll-browser">
        <div class="docs-container" dragula="pages-bag" id="drag-pages" (mouseup)="mouse_released($event)">
          <lib-ngx-image-zoom class="doc-file border-box shadow page" *ngFor="let page of pages" [attr.data-id]="page.id"
                              [thumbImage]=page.url [fullImage]=page.url
                              [magnification]=".5"
                              [zoomMode]="'hover'">
          </lib-ngx-image-zoom>
        </div>
      </div>
      <div class="flex-footer text-center pb-sm" *ngIf="pages.length > 0">
        <button class="btn btn-inverse" type="button" (click)="classicModal.show()">
          <i class="fa icon-trash"></i> &nbsp;Remove All
        </button>
      </div>
    </div>
    <div class="flex-right flex-control flex-column">
      <form class="flex-control flex-column border-box" action="" (submit)="create_document()" #upload_form="ngForm">
        <div class="flex-header">
          <h4 class="fs16 fw-500 mt-sm mb-sm lhn">Drag/Drop to Create Document</h4>
        </div>
        <div class="flex-body scroll-browser docs-container" dragula="pages-bag" id="drop-pages"></div>
      </form>
      <form class="flex-control flex-column border-box mt" (submit)="create_document()" #upload_form1="ngForm">
        <div class="flex-header">
          <h4 class="fs16 fw-500 mt-sm mb-sm lhn">Document Information</h4>
        </div>
        <div class="flex-body scroll-browser p">
          <div class="row form-group">
            <label class="col-md-2 field-label control-label">Date*</label>
            <div class="col-md-10">
              <input [(ngModel)]="document.date" autocomplete="off" name="document_date" class="form-control"
                     placeholder="Date"
                     [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                     bsDatepicker
                     required/>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-2 field-label control-label">Type*</label>
            <div class="col-md-10">
              <select #type class="form-control p0" [(ngModel)]="document.document_type_id" name="document_type_id" required>
                <option selected="selected" value="">Type</option>
                <option *ngFor="let documentType of documentsType; let i = index;" value={{documentType.id}}>
                  {{documentType.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-2 field-label control-label">Name*</label>
            <div class="col-md-10">
              <input type="text" class="form-control" name="name" [(ngModel)]="document.name" required>
            </div>
          </div>
          <div class="row form-group">
            <label class="col-md-2 field-label control-label">Doctor</label>
            <div class="col-md-10">
              <select #problem class="form-control p0" [(ngModel)]="document.doctor_id" name="doctor_id">
                <option selected="selected" value="">
                  Doctor
                </option>
                <option *ngFor="let doctor of doctors; let i = index;" value={{doctor.id}}>
                  {{doctor.complete_name}}
                </option>
              </select>
            </div>
          </div>
          <div class="row form-group mb">
            <label class="col-md-2 field-label control-label">Reason of Visit</label>
            <div class="col-md-10">
              <input [typeahead]="services" name="service" [typeaheadAsync]="true"
                     [(ngModel)]="asyncSelected" typeaheadWaitMs="500"
                     [typeaheadOptionsLimit]="7" (typeaheadOnSelect)="selectService($event)"
                     [typeaheadOptionField]="'name'" placeholder="Search Reason"
                     class="form-control" type='text' autocomplete="off">
            </div>
          </div>
          <p #msgtext class="fs12 m0 pb-sm text-center">
            <span *ngIf="message" [ngClass]="message_type">{{message}}</span>
          </p>
        </div>
        <div class="flex-footer text-center p-sm" *ngIf="upload_form1.form.valid">
          <button type="submit" class="btn btn-secondary" (click)="scrollToElement(msgtext)"
                  [disabled]="!upload_form1.form.valid">Create
          </button>
        </div>
      </form>
    </div>

  </div>
</div>
<app-attachments (document_uploaded)="handle_image_upload($event)"
                 (popup_closed)="show_attachment_popup= false" [fileType]="'document'" [allowedFileType]="'application/pdf'"
                 [only_modal]="true"  [attachable_type]="(appointment ? 'Appointment': 'Case')" [attachable_id]="(appointment ? appointment.id: admission.id)" [auto_upload]="true"
                 [show]="show_attachment_popup">
</app-attachments>
<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Remove All</h4>
      </div>
      <div class="modal-body text-center">Are you sure you want to remove unscanned pages?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="removeUnscannedPages();classicModal.hide()">Confirm</button>
        <button type="button" class="btn btn-tertiary btn-outline" (click)="classicModal.hide()">Cancel</button>
      </div>
    </div>
  </div>
</div>
