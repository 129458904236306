<ng-container *ngIf="chatroom">
  <div class="header" *ngIf="messageService.active_chatroom()">
    <div class="pic-name-sec">
      <div class="flex-grow">
        <div class="pic-holder pointer" (click)="toggleGroupInfo();"><img [src]="chatroom.thumbnail"/></div>
        <div class="grow p0 pl">
          <h4 [title]="chatroom.complete_title" class="name m0 pointer" (click)="toggleGroupInfo();">
            {{chatroom.title}}
            <small *ngIf="chatroom.suffix"> - {{chatroom.suffix}}</small>
          </h4>
          <h6 class="online-status m0 mt-sm fw-500" *ngIf="!chatroom.someone_typing">
            <span class="status-label mr-sm">
              <label class="label" [ngClass]="chatroom.status_css_class">{{chatroom.status_label}}</label>
            </span>
            {{chatroom.last_seen_label}}
          </h6>
          <h6 class="m0 fw-500" *ngIf="chatroom.someone_typing">
            <i class="text-success">{{chatroom.typing_message}}</i>
          </h6>
        </div>
      </div>
    </div>
    <div class="icon-sec">
      <div *ngIf="chatroom.isActive" style="display:inline-block;">
        <a *ngIf="!loaderService.uploading_in_process"
           href="javascript:"
           class="btn btn-link-secondary"
           title="Attach" (click)="handleFileUpload()" >
          <i class="fa fa-paperclip" [title]="'Upload File'"></i>
        </a>
        <a *ngIf="loaderService.uploading_in_process"
           class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm">
        </i></a>

        <app-attachments (document_uploaded)="handle_file_upload($event)"
                         [fileType]="'message'"
                         [only_modal]="true" [auto_upload]="true"
                         [attachable_id]="message.id" (popup_closed)="show_attachment_popup= false"
                         [show]="show_attachment_popup" [attachable_type]="'Message'">
        </app-attachments>
      </div>
      <a *ngIf="canBlock()" href="javascript:" class="btn btn-link" title="Block" (click)="handleBlock(true)" >
        <i class="fa fa-ban text-red" [title]="'Block'"></i>
      </a>
      <a *ngIf="canUnBlock()" href="javascript:" class="btn btn-link" title="Unblock" (click)="handleBlock(false)" >
        <i class="fa fa-unlock text-green" [title]="'Unblock this Chat'"></i>
      </a>
      <a class="btn btn-link btn-close-back visible-sm visible-xs" (click)="notify_cross_clicked()" href="javascript:">
        <i class="fa fa-arrow-left text-gray-dark"></i>
      </a>
    </div>
  </div>
  <div class="chat-thread scroll-browser messages-container" #scrollMe (scroll)="handleThreadScroll($event)">
    <div class="chat-fixed-date" [hidden]="!show_top_date">
      <span *ngIf="first_message">{{center_dates}}</span>
    </div>
    <h5 *ngIf="chatroom.load_in_progress" class="text-center mt0">Loading messages...</h5>
    <ul class="p0">
      <ng-container *ngFor="let message of chatroom_messages();let i = index">
        <li class="clearfix message" [class.in_progress]="message.in_process" #message_box [attr.message-id]="message.id">
          <div class="message-date" *ngIf="i == 0 || chatroom_messages()[i-1].created_at_date_stamp != message.created_at_date_stamp">
            <span>{{message.center_date}}</span>
          </div>
          <div class="bubble" [ngClass]="applySenderRecieverClass(message.user_id,message)">
            <ng-container *ngIf="current_user() && current_user().id != message.user_id">
              <div class="message sender">
                <span>{{message.sender_name}}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="message.isTextType">
              <div dropdown class="more-menu"  *ngIf="current_user() && current_user().id == message.user_id">
                <button type="button" class="btn" dropdownToggle><span class="fa fa-chevron-down"></span></button>
                <ul class="dropdown-menu animated slideInDown">
                  <li><a href="javascript:" (click)="deleteMessage(message)"><i class="fa fa-trash"></i> Delete</a></li>
                </ul>
              </div>
            <app-text-message [message]="message"></app-text-message>
            </ng-container>

            <ng-container *ngIf="message.isSystemType">
              <h6 class="text system fw-400">{{message.body}}</h6>
            </ng-container>

            <ng-container *ngIf="message.isTaggedType">
              <div dropdown class="more-menu"  *ngIf="current_user() && current_user().id == message.user_id">
                <button type="button" class="btn" dropdownToggle><span class="fa fa-chevron-down"></span></button>
                <ul class="dropdown-menu animated slideInDown">
                  <li><a (click)="deleteMessage(message)"><i class="fa fa-trash"></i> Delete</a></li>
                </ul>
              </div>
              <h5 class="text">
                <span *ngFor="let msplit of message.split_messages;let i = index">
                  {{msplit}}<a *ngIf="message.message_tags[i]" (click)="handleTagClick(message.message_tags[i])" [routerLink]="current_user() && current_user().isPatient ? '/patients/'+message.message_tags[i].taggable_id : null"> {{message.message_tags[i].name}}</a>
                </span>
              </h5>
            </ng-container>

            <ng-container *ngIf="message.attachment_type || message.is_image">
              <div dropdown class="more-menu">
                <button type="button" class="btn" dropdownToggle><span class="fa fa-chevron-down"></span></button>
                <ul class="dropdown-menu animated slideInDown">
                  <li><a [href]="message.file_path" download target="_blank"><i class="fa fa-download"></i> Download</a></li>
                  <li *ngIf="current_user() && current_user().id == message.user_id"><a (click)="deleteMessage(message)"><i class="fa fa-trash"></i> Delete</a></li>
                </ul>
              </div>
              <div class="media m0 pointer" *ngIf="message.attachment_type" (click)="openViewer(message)">
                <div class="media-left"><i class="fa doc-icon" [ngClass]="message.file_icon"></i></div>
                <div class="media-right media-middle p0"><h5 class="text">{{message.file_name || ' Download'}}</h5></div>
              </div>
              <img [src]="message.file_path" class="pointer" alt="" width="100%;" *ngIf="message.is_image" (click)="openViewer(message)">
            </ng-container>
            <span class="date_time">{{message.created_at | date: 'shortTime'}}</span>
          </div>
        </li>
        <ng-container *ngIf="chatroom.lastReadMessageIndex == i && i + 1 < chatroom.messages.length">
          <li #unread_label class="text-center text-primary">
            <h6 class="m0 fw-500">{{chatroom.last_unread_count}} Unread Message(s)</h6>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
  <div class="footer">
    <form class="grid-grow" (ngSubmit)="submitInput()" #input_form *ngIf="chatroom.subscribed && chatroom.isActive && !show_verification_box()">
      <app-input class="form-control scroll-browser" [term]="term" [literals]="literals" (enter_pressed)="submitInput()"></app-input>
      <button class="btn btn-secondary col-xs-12" type="submit"><i class="fa fa-paper-plane-o"></i></button>
    </form>
    <app-contact-verification (phone_verified)="verifyChatroom()" [chatroom]="chatroom"
                              class="form-control" style="background-color:transparent;" *ngIf="show_verification_box()">
    </app-contact-verification>
    <h5 *ngIf="!chatroom.subscribed">You are not allowed to post in this group</h5>
    <h5 *ngIf="!chatroom.isActive">This chat is disabled for new messages.</h5>
  </div>
  <app-attachment-viewer (closed)="preview_closed()" [active]="true"></app-attachment-viewer>
  <app-patient-documents *ngIf="show_documents" [patient_id]="patient_id_for_documents" [show_documents]="show_documents" (upload_clicked)="showFileBox()" (send_document)="sendDocument($event)"></app-patient-documents>

  <div class="modal fade" bsModal #block_modal="bs-modal" [config]="{backdrop: false}">
    <div class="modal-dialog" style="height:200px;">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="block_modal.hide()"><i class="fa fa-times fs16"></i></button>
          <h4 class="m0 text-center">Confirmation Alert</h4>
        </div>
        <div class="modal-body">
          <h5 class="alert alert-danger text-center" *ngIf="block_status">Are you sure you want to block this patient?</h5>
            <h5 class="alert alert-success text-center" *ngIf="!block_status">Are you sure you want to unblock this patient?</h5>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="submit" (click)="triggerYes()">Yes</button>
          <button type="submit" class="btn btn-tertiary btn-outline" (click)="block_modal.hide();">No</button>
        </div>
      </div>
    </div>
  </div>

</ng-container>
