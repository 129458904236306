
<div class="row">
  <div class="col-md-2 col-sm-0"></div>
  <div class="mb col-md-8 col-sm-0" [innerHTML]="consent.consent_text | safeHtml"></div>
  <div class="col-md-2 col-sm-0"></div>
</div>
<h5>Which way does the patient prefer to sign the consent?</h5>
<div class="row form-group">
  <div class="col-md-2 col-sm-3"></div>
  <div class="col-md-10 col-sm-5">
      <label class="text-center">
        <input class="mr" id="{{consent.id}} mobile_portal" type="radio" value="mobile_portal" name="{{consent.id}} mobile_portal"
               [(ngModel)]="consent.consent_from" disabled>
        <span>Sign consent through mobile app or patient portal</span>
      </label>
    <div>
      <label class="text-center">
        <input class="mr" id="{{consent.id}} care_team" type="radio" value="care_team" name="{{consent.id}} care_team"
               [disabled]="consent.signed_by_id"
               [(ngModel)]="consent.consent_from">
        <span>Sign consent via care team members device</span>
      </label>
    </div>

    <div>
      <label class="text-center">
        <input class="mr" id="{{consent.id}} verbally" type="radio" value="verbally" name="{{consent.id}} verbally"
               [disabled]="consent.signed_by_id"
               [(ngModel)]="consent.consent_from">
        <span>Patient verbally consents</span>
      </label>
    </div>

    <div>
      <label class="text-center">
        <input class="mr" id="{{consent.id}} paper" type="radio" value="paper" name="{{consent.id}} paper"
               [disabled]="consent.signed_by_id"
               [(ngModel)]="consent.consent_from">
        <span>Paper consent has been signed</span>
      </label>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-2 col-sm-3">
    <label class="field-label control-label">Sign Date & Time</label>
  </div>
  <div class="col-md-3 col-sm-5">
    <input  type="date" name="date" placeholder="Date" class="form-control" [(ngModel)]="today_date" [disabled]="consent.signed_by_id">
  </div>
  <div class="col-md-3 col-sm-5">
    <input type="time" name="time" placeholder="Time" class="form-control"  [(ngModel)]="current_time" [disabled]="consent.signed_by_id">
  </div>
  <div class="col-md-3 col-sm-5">
    <button type="submit" (click)="saveConsentOfPatient()" class="btn btn-secondary mr" [disabled]="
      consent.signed_by_id ||
      !consent.consent_from">Sign</button>
  </div>
</div>
