import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-non-loggedin-user',
  templateUrl: './non-loggedin-user.component.html',
  styleUrls: ['./non-loggedin-user.component.scss']
})
export class NonLoggedinUserComponent implements OnInit {
  @Output() patientCreated = new EventEmitter<boolean>();

  new_patient: any;
  constructor() { }

  ngOnInit() {
  }

  onPatientCreated($event: boolean) {
  if($event) {
    this.patientCreated.emit(true);
  }
  }
}
