import { Injectable }     from '@angular/core';
import { Observable } from 'rxjs';
import {HttpHandler, HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {HttpResponse} from '@angular/common/http';
import baseApiUrl from  '../globals'


@Injectable()
export class CustomHttpClient {
  public api_url = 'api/v1';
  constructor(handler: HttpHandler, private httpClient: HttpClient) {
    this.api_url = `${baseApiUrl}/${this.api_url}`
  }

  get(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    url = `${this.api_url}/${url}`;
    return this.httpClient.get(url, options) as any
  }

  outerSideGet(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    url = `${url}`;
    return this.httpClient.get(url, options) as any
  }

  postWithHeaders(url: string, body: any | null, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Object>> {
    url = `${this.api_url}/${url}`;
    return this.httpClient.post(url, body, options)
  }

  post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    url = `${this.api_url}/${url}`;
  return this.httpClient.post(url, body, options) as any
  }

  put(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<any> {
    url = `${this.api_url}/${url}`;
    return this.httpClient.put(url, body, options) as any
  }
  delete(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {
    url = `${this.api_url}/${url}`;
    return this.httpClient.delete(url, options) as any
  }
  deleteWithHeaders(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<any>> {
    url = `${this.api_url}/${url}`;
    return this.httpClient.delete(url, options) as any
  }

  getWithHeaders(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<any>> {
    url = `${this.api_url}/${url}`;
    return this.httpClient.get(url, options) as any
  }

  getFile(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'blob';
    withCredentials?: boolean;
  }): Observable<HttpResponse<any>> {
    url = `${this.api_url}/${url}`;
    return this.httpClient.get(url, options) as any
  }
}
