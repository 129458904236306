import { Component, Input } from '@angular/core';
import { TwilioConfiguration } from './twilio_configuration';
import { TwilioConfigurationService } from '../../../services/twilio_configuration.service';
import { Practice } from '../practices/practice';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import {AlertService} from '../../../_alert';

@Component({
  selector: 'app-twilio-configuration',
  templateUrl: './twilio-configuration.component.html',
  styleUrls: ['./twilio-configuration.component.scss']
})
export class TwilioConfigurationComponent {
  @Input() practice: Practice;
  sharedCredentials: {
    accountSid: string,
    authToken: string
  } = {
    accountSid: '',
    authToken: ''
  };

  voiceConfig: {
    twimlAppSid: string;
    apiKey: string,
    apiSecret: string,
    twilioNumber: string
  } = {
    twimlAppSid: '',
    apiKey: '',
    apiSecret: '',
    twilioNumber: ''
  };

  videoConfig: {
    apiKey: string,
    apiSecret: string,
  } = {
    apiKey: '',
    apiSecret: '',
  };

  smsConfig: {
    twilioNumber: string
  } = {
    twilioNumber: ''
  };
  public configurationIds: { [key: string]: number } = {};

  constructor(private twilioConfigService: TwilioConfigurationService,
              private loaderService: LoaderService,
              public alertService: AlertService,
              private globalErrorHandler: GlobalErrorHandlerService) { }

  ngOnInit() {
    this.fetchAndSetTwilioConfigurations();
  }

  fetchAndSetTwilioConfigurations(): void {
    this.loaderService.show();
    this.twilioConfigService.fetchTwilioConfigurations(this.practice.id)
      .then(configs => {
        configs.forEach(config => {
          // Store the ID of each configuration
          this.configurationIds[config.type] = config.id;
          if (config.type === 'TwilioConfiguration') {
            this.sharedCredentials.accountSid = config.account_sid;
            this.sharedCredentials.authToken = config.auth_token;
          }
          if (config.type === 'VoiceConfiguration') {
            this.voiceConfig.apiKey = config.api_key;
            this.voiceConfig.apiSecret = config.api_secret;
            this.voiceConfig.twilioNumber = config.twilio_number;
            this.voiceConfig.twimlAppSid = config.twiml_app_sid;
          }
          if (config.type === 'VideoConfiguration') {
            this.videoConfig.apiKey = config.api_key;
            this.videoConfig.apiSecret = config.api_secret;
          }
          if (config.type === 'SmsConfiguration') {
            this.smsConfig.twilioNumber = config.twilio_number;
          }
          this.loaderService.hide();
        });
      })
      .catch(error => {
        this.loaderService.hide();
        console.error('Error fetching configurations:', error);
        // Optionally, you can show an error message to the user
      });
  }

  saveSharedCredentials(): void {
    this.loaderService.show();
    const twilioConfig = new TwilioConfiguration();
    twilioConfig.configurable_id = this.practice.id;
    twilioConfig.configurable_type = 'Practice';
    twilioConfig.account_sid = this.sharedCredentials.accountSid;
    twilioConfig.auth_token = this.sharedCredentials.authToken;
    twilioConfig.type = 'TwilioConfiguration';

    if (this.configurationIds['TwilioConfiguration']) {
      // Update the configuration
      twilioConfig.id = this.configurationIds['TwilioConfiguration'];
      this.twilioConfigService.updateTwilioConfiguration(twilioConfig)
        .then(response => {
          this.showSaveMessages('Shared credentials updated.');
          console.log('Shared credentials updated:', response);
        })
        .catch(error => {
          this.showError(error.error.error);
          console.error('Error updating shared credentials:', error);
        });
    } else {
      // Create a new configuration
      this.twilioConfigService.createTwilioConfiguration(twilioConfig)
        .then(response => {
          console.log('Shared credentials created:', response);
          this.configurationIds['TwilioConfiguration'] = response.id;
          this.showSaveMessages('Changes have been saved successfully.');
        })
        .catch(error => {
          console.error('Error creating shared credentials:', error);
          this.showError(error.error.error);
        });
    }
  }

  saveVoiceConfiguration(): void {
    this.loaderService.show();
    const voiceConfig = new TwilioConfiguration();
    voiceConfig.configurable_id = this.practice.id;
    voiceConfig.configurable_type = 'Practice';
    voiceConfig.account_sid = this.sharedCredentials.accountSid;
    voiceConfig.auth_token = this.sharedCredentials.authToken;
    voiceConfig.api_key = this.voiceConfig.apiKey;
    voiceConfig.api_secret = this.voiceConfig.apiSecret;
    voiceConfig.twilio_number = this.voiceConfig.twilioNumber;
    voiceConfig.twiml_app_sid = this.voiceConfig.twimlAppSid;
    voiceConfig.type = 'VoiceConfiguration';

    if (this.configurationIds['VoiceConfiguration']) {
      // Update the configuration
      voiceConfig.id = this.configurationIds['VoiceConfiguration'];
      this.twilioConfigService.updateTwilioConfiguration(voiceConfig)
        .then(response => {
          console.log('Voice configuration updated:', response);
          this.showSaveMessages('Voice configuration updated.');
        })
        .catch(error => {
          this.showError(error.error.error);
          console.error('Error updating voice configuration:', error);
        });
    } else {
      // Create a new configuration
      this.twilioConfigService.createTwilioConfiguration(voiceConfig)
        .then(response => {
          console.log('Voice configuration created:', response);
          this.configurationIds['VoiceConfiguration'] = response.id;
          this.showSaveMessages('Voice configuration created.');
        })
        .catch(error => {
          this.showError(error.error.error);
          console.error('Error creating voice configuration:', error);
        });
    }
  }

  saveVideoConfiguration(): void {
    this.loaderService.show();
    const videoConfig = new TwilioConfiguration();
    videoConfig.configurable_id = this.practice.id;
    videoConfig.configurable_type = 'Practice';
    videoConfig.account_sid = this.sharedCredentials.accountSid;
    videoConfig.auth_token = this.sharedCredentials.authToken;
    videoConfig.api_key = this.videoConfig.apiKey;
    videoConfig.api_secret = this.videoConfig.apiSecret;
    videoConfig.type = 'VideoConfiguration';

    if (this.configurationIds['VideoConfiguration']) {
      // Update the configuration
      videoConfig.id = this.configurationIds['VideoConfiguration'];
      this.twilioConfigService.updateTwilioConfiguration(videoConfig)
        .then(response => {
          console.log('Video configuration updated:', response);
          this.showSaveMessages('Video configuration updated');
        })
        .catch(error => {
          this.showError(error.error.error);
          console.error('Error updating video configuration:', error);
        });
    } else {
      // Create a new configuration
      this.twilioConfigService.createTwilioConfiguration(videoConfig)
        .then(response => {
          console.log('Video configuration created:', response);
          this.configurationIds['VideoConfiguration'] = response.id;
          this.showSaveMessages('Video configuration created.');
        })
        .catch(error => {
          console.error('Error creating video configuration:', error);
          this.showError(error.error.error);
        });
    }
  }

  saveSMSConfiguration(): void {
    this.loaderService.show();
    const smsConfig = new TwilioConfiguration();
    smsConfig.configurable_id = this.practice.id;
    smsConfig.configurable_type = 'Practice';
    smsConfig.account_sid = this.sharedCredentials.accountSid;
    smsConfig.auth_token = this.sharedCredentials.authToken;
    smsConfig.twilio_number = this.smsConfig.twilioNumber;
    smsConfig.type = 'SmsConfiguration';

    if (this.configurationIds['SmsConfiguration']) {
      // Update the configuration
      smsConfig.id = this.configurationIds['SmsConfiguration'];
      this.twilioConfigService.updateTwilioConfiguration(smsConfig)
        .then(response => {
          console.log('SMS configuration updated:', response);
          this.showSaveMessages('SMS configuration updated.');
        })
        .catch(error => {
          this.showError(error.error.error);
          console.error('Error updating SMS configuration:', error);
        });
    } else {
      // Create a new configuration
      this.twilioConfigService.createTwilioConfiguration(smsConfig)
        .then(response => {
          console.log('SMS configuration created:', response);
          this.configurationIds['SmsConfiguration'] = response.id;
          this.showSaveMessages('SMS configuration created.');
        })
        .catch(error => {
          console.error('Error creating SMS configuration:', error);
          this.showError(error.error.error);
        });
    }
  }

  deleteTwilioIntegration() {
    this.loaderService.show();
   this.twilioConfigService.deleteTwilioConfiguration()
     .then(response => {
       console.log('Integrations Deleted:', response);
       this.configurationIds = {};
       this.sharedCredentials = {accountSid: '', authToken: ''};
       this.voiceConfig = {twimlAppSid: '', apiKey: '', apiSecret: '', twilioNumber: ''};
       this.videoConfig = {apiKey: '', apiSecret: ''};
       this.smsConfig = {twilioNumber: ''};
       this.showSaveMessages('Twilio Integration Deleted');
     })
     .catch(error => {
       console.error('Error deleting configuration:', error);
       this.showError(error.error.error);
     });
  }

  showSaveMessages(message: string) {
    this.loaderService.hide();
    this.alertService.success(message, this.alertService.options);
  }

  showError(message: string) {
    this.loaderService.hide();
    const uninitialized_message = "uninitialized constant API::Error";
    const blankFieldMessage = "can't be blank";
    const genericErrorMessage = "Invalid credentials, please provide the necessary information.";
    this.globalErrorHandler.error = message.includes(blankFieldMessage) || message.includes(uninitialized_message)  ? genericErrorMessage : message;
  }
}
