<div class="container flex-control flex-column">
  <div class="modal-content" style="overflow:hidden;">
    <div class="modal-header">
      <h4 class="text-center m0"><img src="assets/img/branding/logo-icon-green.png" alt="App Logo" /></h4>
    </div>
    <div class="flex-control mobile-flex">
      <div class="flex-left col-md-6 col-sm-6 bg-lightest-gray p-xl">
        <div class="media">
          <div class="media-left"><i class="fa icon-key fa-3x"></i></div>
          <div class="media-body">
            <h3 class="m0 mb-sm fw-500 text-capitalize" *ngIf="user.full_name">{{user.full_name}}</h3>
            <h5 class="m0 mb-sm fw-500">Set Your Password!</h5>
            <p class="m0">Type your Password & Confirmation Password</p>
            <div class="mt-lg">
              <h6 class="text-secondary">Password Details</h6>
              <ng-container>
                <mat-password-strength-info
                  [lowerCaseCriteriaMsg]="'At least one lowercase character.'"
                  [upperCaseCriteriaMsg]="'At least one uppercase character.'"
                  [digitsCriteriaMsg]="'At least one digit character.'"
                  [specialCharsCriteriaMsg]="'At least one special character.'"
                  [minCharsCriteriaMsg]="'At least 8 characters.'"
                  [enableScoreInfo]="true"
                  [passwordComponent]="passwordComponent">
                </mat-password-strength-info>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <form class="flex-body col-md-6 col-sm-6 p-xl" #setPasswordForm="ngForm" (ngSubmit)="onSubmit($event)">
        <div class="form-group">
          <label for="user-email">Email*</label>
          <input type="email"
                 id="user-email"
                 class="form-control"
                 placeholder="Email"
                 [(ngModel)]="user.email"
                 disabled
                 name="user-email">
        </div>
        <div class="form-group">
          <label for="user-new-password">Password*</label>
          <input id="user-new-password" matInput #password type="password" class="form-control mb-sm"
                 [(ngModel)]="new_password"
                 placeholder="New Password"
                 name="user-new-password" maxlength="25" minlength="8" required>
          <mat-password-strength #passwordComponent (onStrengthChanged)="checkPasswordStrength($event)"
                                 [password]="password.value">
          </mat-password-strength>
          <div class="visible-sm visible-xs">
            <mat-slide-toggle [color]="'primary'" #toggleInfo>Show Password Details</mat-slide-toggle>
            <mat-password-strength-info *ngIf="toggleInfo.checked"
                                        [lowerCaseCriteriaMsg]="'At least one lowercase character.'"
                                        [upperCaseCriteriaMsg]="'At least one uppercase character.'"
                                        [digitsCriteriaMsg]="'At least one digit character.'"
                                        [specialCharsCriteriaMsg]="'At least one special character.'"
                                        [minCharsCriteriaMsg]="'At least 8 characters.'"
                                        [enableScoreInfo]="true"
                                        [passwordComponent]="passwordComponent">
            </mat-password-strength-info>
          </div>
        </div>
        <div class="form-group">
          <label for="user-password-confirmation">Password Confirmation*</label>
          <input type="password"
                 class="form-control"
                 placeholder="New Password Confirmation"
                 required
                 [(ngModel)]="passwordConfirmation"
                 id="user-password-confirmation"
                 name="user-password-confirmation">
          <div *ngIf="show_error" class="text-danger">Password does not match</div>
        </div>
        <button type="submit" class="btn btn-secondary btn-block" [disabled]="!setPasswordForm.form.valid">
          Save
        </button>
      </form>
    </div>
  </div>
  <div class="p-lg text-center fs12">
    <span>&copy;</span>
    <span>{{ settings.app.year }}</span>
    <span>-</span>
    <span>{{ settings.app.name }}</span>
  </div>
</div>
