import { Component, OnInit } from '@angular/core';
import {CreateNoteComponent} from '../create-note/create-note.component';
import {EnoteService} from '../../services/enote.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-read-only-template',
  templateUrl: './read-only-template.component.html',
  styleUrls: ['./read-only-template.component.scss']
})
export class ReadOnlyTemplateComponent extends CreateNoteComponent implements OnInit {

  constructor(public _enote_service: EnoteService, public modalService: BsModalService, public loaderService: LoaderService) {
    super(_enote_service, modalService, loaderService);
  }
  ngOnInit() {
  }

}
