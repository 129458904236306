<div class="filters-sec flex-header">
  <div class="row">
    <div class="col-sm-2">
      <input [(ngModel)]="asyncName" (keyup.enter)="apply_filters_search()" placeholder="Name or Email" class="form-control">
    </div>
    <div class="col-sm-2" *ngIf="profile_type == 'practice' && user_service.current_user.profileable_type != 'Staff' ">
      <input [(ngModel)]="asyncPracticeName"  (keyup.enter)="apply_filters_search()" placeholder="Practice Name" class="form-control">
    </div>
    <div class="col-sm-2" *ngIf="profile_type == 'group' ">
      <input [(ngModel)]="asyncGroupName" (keyup.enter)="apply_filters_search()" placeholder="Group Name" class="form-control">
    </div>
    <div class="col-sm-2">
      <input [(ngModel)]="asyncUserName" (keyup.enter)="apply_filters_search()" placeholder="User Name" class="form-control">
    </div>
    <div class="col-sm-2">
      <select #status class="form-control" (change)='changeStatus(status.value)'>
        <option *ngFor="let value of values; let i = index;" value={{i+1}}>
          {{value}}
        </option>
      </select>
    </div>
    <div class="col-sm-2">
      <button type="button" class="btn btn-tertiary btn-outline mr-sm" (click)="apply_filters_search()">
        <i class="fa fa-filter"></i> Filter
      </button>
      <button *userCan="'add,users,settings'" type="button" (click)="addToTab()" class="btn btn-secondary">Add</button>
    </div>
  </div>
</div>
<app-index class="flex-body p scroll-browser" [filter_users_listing]="users_listing" [totalItems]="totalItems" [page]="page" (pageChange)="pageChanged($event)"></app-index>
