<ng-container *ngIf="!programIntegrationService.showIntegrationForm">
  <div class="row form-group">
    <div class="col-sm-3 col-xs-12">
      <label>Integrate with</label>
      <select class="form-control" name="integration_flow_vender"
              (change)="setIntegrationVendor($event.target.value)">
        <option [value]="'0'">Select</option>
        <ng-container *ngFor="let vendor of integration_vendors">
          <option [value]="vendor.id">{{userService.removeUnderScore(vendor.name)}}</option>
        </ng-container>
      </select>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="programIntegrationService.showIntegrationForm">
  <form #credentialForm="ngForm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="authenticate()" class="mb-xl">
    <div class="row">
      <div class="col-sm-3 col-xs-12">
        <label>Integrate with</label>
        <select class="form-control" name="integration_form_flow_vender"
                (change)="setIntegrationVendor($event.target.value)" [(ngModel)]="program_integration.integration_id">
          <option [value]="'0'">Select</option>
          <ng-container *ngFor="let vendor of integration_vendors">
            <option [value]="vendor.id">{{userService.removeUnderScore(vendor.name)}}</option>
          </ng-container>
        </select>
      </div>
      <ng-container *ngFor="let integration_crendital of program_integration.integration_credentials">
        <div class="col-sm-3 col-xs-12 form-group">
          <label>{{userService.removeUnderScore(integration_crendital.label)}}*</label>
          <input class="form-control" [(ngModel)]="integration_crendital.value"
                 name="{{integration_crendital.label}}" id="{{integration_crendital.label}}" required/>
        </div>
      </ng-container>
    </div>
    <div class="mt-xl">
      <button class="btn btn-primary mr" type="submit" [disabled]="!credentialForm.form.valid">
        {{program_integration.id ? 'Update' : 'Authenticate'}}
      </button>
      <button class="btn btn-tertiary mr" type="button" (click)="closeIntegrationForm()">Cancel</button>
    </div>
  </form>
</ng-container>
