<div class="filters-sec bg-lightest-gray clearfix p-sm">
  <div class="row">

    <div class="col-xs-2 date-field">
      <input type="text"
             placeholder="From Date"
             [(ngModel)]="filter['from_date']"
             class="form-control"
             bsDatepicker
             [bsValue]="bsValue"
             [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }">
    </div>

    <div class="col-xs-2 date-field">
      <input type="text"
             placeholder="To Date"
             [(ngModel)]="filter['to_date']"
             class="form-control"
             bsDatepicker
             [bsValue]="bsValue"
             [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }">
    </div>

    <div class="col-xs-3">
      <input class="form-control" [(ngModel)]="filter['patient_name']" placeholder="Patient Name">
    </div>

    <div class="col-xs-2">
      <select [(ngModel)]="filter['outreach_type']" class="form-control">
        <option value="">Outreach</option>
        <option value='email'>Email</option>
        <option value='sms'>SmS</option>
      </select>
    </div>

    <div class="col-xs-2">
      <select [(ngModel)]="filter['status']" class="form-control">
        <option value="">Status</option>
        <option value='failed'>Failed</option>
        <option value='invalid_reminder'>Invalid</option>
        <option value='successful'>Successful</option>
        <option value='canceled'>Canceled</option>
        <option value='scheduled'>Scheduled</option>
      </select>
    </div>

    <div class="col-xs-2">
      <button type="button" class="btn btn-tertiary btn-outline" (click)="search()" ><i class="fa fa-filter mr-sm"></i> Filter</button>
    </div>
  </div>
</div>
<div class="table-responsive leads">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>CREATED ON</th>
        <th>SCHEDULED ON</th>
        <th>SENT ON</th>
        <th>OUTREACH</th>
        <th>SENT TO</th>
        <th>STATUS</th>
        <th>REASON</th>
        <th class="text-center">MESSAGE</th>
      </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let reminder of reminders">
      <tr>
        <td>{{reminder.created_at}}</td>
        <td>{{reminder.scheduled_on}}</td>
        <td>{{reminder.sent_on}}</td>
        <td>{{reminder.outreach_type}}</td>
        <td><p class="m0"><span class="text-capitalize">{{reminder.recipient_title}}</span> <br> {{checkAnyLetter(reminder.recipient_contact) ? reminder.recipient_contact : reminder.recipient_contact | mask: '(000) 000-0000'}}</p></td>
        <td>
          <label *ngIf="reminder.status == 'Failed'" class="label label-danger m0">{{reminder.status}}</label>
          <label *ngIf="reminder.status == 'Invalid Reminder'" class="label label-danger m0">Invalid</label>
          <label *ngIf="reminder.status == 'Successful'" class="label label-success m0">{{reminder.status}}</label>
          <label *ngIf="reminder.status == 'Scheduled'" class="label label-info m0">{{reminder.status}}</label>
          <label *ngIf="reminder.status == 'Canceled'" class="label label-warning m0">{{reminder.status}}</label>
        </td>
        <td>{{reminder.reason}}</td>
        <td class="text-center"><button class="btn btn-link" (click)="selected_reminder = reminder;reminder.outreach_type.toLowerCase() === 'sms' ? show_sms_popup = true : show_popup = true">Message</button></td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <div class="text-center" *ngIf="reminders && totalItems > 10">
    <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
  </div>
</div>
<ng-container *ngIf="reminders && reminders.length == 0" ><app-no-result-found></app-no-result-found></ng-container>
<div class="modal fade in d-block" *ngIf="show_popup">
  <div class="modal-dialog" style="margin-top:10em;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close text-right" (click)="show_popup = false"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center text-capitalize">Email</h4>
      </div>
      <div class="modal-body">
        <div class="row form-group" *ngIf="selected_reminder.subject">
          <label class="col-sm-2">Subject</label>
          <div class="col-sm-8">{{selected_reminder.subject}}</div>
        </div>
        <div class="reminder-modal scroll-browser" [innerHTML]="selected_reminder.message | safeHtml"></div>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="show_popup = false;selected_reminder = undefined">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade in d-block" *ngIf="show_sms_popup">
  <div class="modal-dialog" style="margin-top:10em;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close text-right" (click)="show_sms_popup = false"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center text-capitalize">SMS</h4>
      </div>
      <div class="modal-body">
        <div class="reminder-modal" [innerHTML]="selected_reminder.message"></div>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-primary" (click)="show_sms_popup = false;selected_reminder = undefined">Close</button>
      </div>
    </div>
  </div>
</div>
