<form (change)="checkFormIsValid()" #alertRulesForm="ngForm" class="flex-body p scroll-browser">
  <span class="h4">Demographics</span>
  <hr class="hr-line mt">

  <div class="row form-group">
    <div class="col-md-2 col-sm-3">
      <label>Gender*</label>
    </div>
    <div class="col-md-4 col-sm-5">
      <select #gender class="form-control" [(ngModel)]="alertTemplateRules.gender" name="gender" required>
        <option value=undefined selected>Gender</option>
        <option value='all_gender'>All</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="trans">Trans</option>
        <option value="trans_male">Transgender male/Trans man/Female-to-male</option>
        <option value="trans_female">Transgender female/Trans woman/Male-to-female</option>
        <option value="non_binary">Genderqueer/Non-binary</option>
        <option value="decline">Decline to answer</option>
        <option value="other">Other</option>
      </select>
    </div>
  </div>

  <div class="row form-group">
    <div class="col-md-2 col-sm-3">
      <label>Age*</label>
    </div>
    <div class="col-md-2 col-sm-5">
      <select #age_trend class="form-control text-capitalize" (change)="ageDropdownChange(age_trend.value)" [(ngModel)]="alertTemplateRules.age_trend" name="age_trend" required>
        <option value=undefined selected>Trend</option>
        <option *ngFor="let trend of alertTemplateService.alert_age_trends; let i = index;" [value]="trend">
          {{userService.removeUnderScore(trend)}}
        </option>
      </select>
    </div>
    <ng-container *ngIf="showAgeMinMaxField">
      <div class="col-md-1 col-sm-5">
        <input type="number" class="form-control" placeholder="Min Age"
               [(ngModel)]="alertTemplateRules.age_min_value" [max]="alertTemplateRules.age_max_value" name="age_min_value" required>
      </div>
      <div class="col-md-1 col-sm-5">
        <input type="number" class="form-control" placeholder="Max Age"
               [(ngModel)]="alertTemplateRules.age_max_value"  [min]="alertTemplateRules.age_min_value"  name="age_max_value" required>
      </div>
    </ng-container>
    <ng-container *ngIf="!showAgeMinMaxField">
      <div class="col-md-1 col-sm-5">
        <input type="number" class="form-control" placeholder="Age"
               [(ngModel)]="alertTemplateRules.age_value"  name="age_value" required>
      </div>
    </ng-container>

    <div class="col-md-2 col-sm-5">
      <select class="form-control text-capitalize" [(ngModel)]="alertTemplateRules.age_type" name="age_type" required>
        <option *ngFor="let type of alertTemplateService.alert_age_type; let i = index;" [value]="type">
          {{type}}s
        </option>
      </select>
    </div>
  </div>


  <span class="h4">Clinical</span>
  <hr class="hr-line mt">

  <div class="row form-group">
    <div class="col-md-2 col-sm-3">
      <label>Diagnosis <span *ngIf="alertTemplate.alert_type === 'disease_management'">*</span></label>
    </div>
    <div class="col-md-5 col-sm-5">
      <div class="input-group">
        <input class="form-control" placeholder="Search" name="serch"
               [(ngModel)]="token" [typeahead]="data_source"
               typeaheadWaitMs="500" (typeaheadLoading)="changeTypeaheadLoading($event)"
               [typeaheadAsync]="true"
               (typeaheadNoResults)="changeTypeaheadDiagnosisNoResults($event)"
               (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
               [typeaheadOptionField]="'d_name'"/>
        <div class="input-group-addon" *ngIf="typeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
      </div>
      <p class="text-danger fs12 mt-sm" *ngIf="typeaheadNoResults">No Results Found!</p>
    </div>
  </div>
  <ng-container *ngIf="alertTemplateRules.alert_diagnosis_rules.length > 0">
    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
      </div>
      <div class="col-md-10 col-sm-5">
        <ul class="list-unstyled border-list m0 scroll-browser" >
          <ng-container *ngFor="let diagnosis of alertTemplateRules.alert_diagnosis_rules">
            <ng-container *ngIf="!diagnosis._destroy">
              <li class="list row">
                <div class="col-md-5 col-sm-5">
                  {{diagnosis?.d_name}}
                </div>
                <div class="col-md-1 col-sm-5">
                  <label>Trend*</label>
                </div>
                <div class="col-md-3 col-sm-5">
                  <select id="{{diagnosis.library_datum_id}}" class="form-control" [(ngModel)]="diagnosis.diagnosis_trend"
                          name="{{diagnosis.library_datum_id}}" required>
                    <option value='both' selected>All</option>
                    <option value="current">Current</option>
                    <option value="past">Past</option>
                  </select>
                </div>
                <div class="col-md-1 col-sm-5">
                  <a (click)="removeTempDiagnosis(diagnosis)"><i class="fa fa-times vl-m ml text-danger"></i></a>
                </div>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-container>

  <div class="row form-group">
    <div class="col-md-2 col-sm-3">
      <label>Vitals</label>
    </div>
    <div class="col-md-2 col-sm-5 text-capitalize" *ngIf="vital_entries">
      <select  #vital_entry  (change)="onVitalEntryChange(vital_entry.value)" class="form-control" name="vital_entries">
        <option value=''>Vital</option>
        <option   *ngFor="let vital of vital_entries; let i = index;"  [value]="vital.id">
          {{vitalService.vital_mapper_with_unit[vital?.short]}}
        </option>
      </select>
    </div>
  </div>
  <ng-container *ngFor="let vital_entry of alertTemplateRules.alert_vital_rules ; let i = index;">
    <ng-container *ngIf="!vital_entry._destroy">
      <div class="row form-group" >
        <div class="col-md-2 col-sm-3">
        </div>
        <div class="col-md-2 col-sm-5 ">
          <h6>{{vitalService.vital_mapper_with_unit[vital_entry?.short]}}</h6>
        </div>
        <div class="col-md-2 col-sm-5">
          <select  id="{{'vital_identifier_'+vital_entry.short}}" name="{{'vital_identifier_'+vital_entry.short}}"
                   class="form-control text-capitalize"
                   (change)="alertTemplateService.vitalDropdownChange(vital_entry.vital_identifier, vital_entry)"
                   [(ngModel)]="vital_entry.vital_identifier"  required>
            <option value=undefined selected>Trend</option>
            <option *ngFor="let trend of alertTemplateService.alert_age_trends; let i = index;" [value]="trend">
              {{userService.removeUnderScore(trend)}}
            </option>
          </select>
        </div>
        <div class="col-md-2 col-sm-5" *ngIf="!vital_entry.show_min_max">
          <input type="number" class="form-control" placeholder="Value" id="{{i + 'vital_value'}}"
                 [(ngModel)]="vital_entry.value" name="{{i + 'vital_value'}} " required>
        </div>
        <ng-container *ngIf="vital_entry.show_min_max">
          <div class="col-md-2 col-sm-5">
            <input type="number" class="form-control" placeholder="Min Value" id = "{{i + 'vital_min_value'}}"
                   [(ngModel)]="vital_entry.min_value" [max]="vital_entry.max_value" name="{{i + 'vital_min_value'}} " required>
          </div>
          <div class="col-md-2 col-sm-5">
            <input type="number" class="form-control" placeholder="Max Value" id = "{{i + 'vital_max_value'}}"
                   [(ngModel)]="vital_entry.max_value" [min]="vital_entry.min_value" name="{{i + 'vital_max_value'}} " required>
          </div>
        </ng-container>
        <a (click)="alertTemplateService.removeTempObjectFromArray(vital_entry, alertTemplateRules.alert_vital_rules)"><i class="fa fa-times vl-m ml text-danger"></i></a>
      </div>
    </ng-container>
  </ng-container>

  <div class="row form-group" >
    <div class="col-md-2 col-sm-3">
      <label>Trend <span *ngIf="alertTemplateRules.alert_vital_rules.length > 0 ">*</span> </label>
    </div>
    <div class="col-md-4 col-sm-5">
      <select #rule_trend class="form-control" [(ngModel)]="alertTemplateRules.trend" [required]="alertTemplateRules.alert_vital_rules.length > 0" name="rule_trend">
        <option value=undefined selected>Trend</option>
        <option value="every_occurrence">Every Occurrence</option>
        <option value="two_consecutive_occurrences">Two Consecutive Occurrences</option>
        <option value="three_consecutive_occurrences">Three Consecutive Occurrences</option>
      </select>
    </div>
  </div>

  <div class="row form-group" *ngIf="alertTemplate.alert_type === 'preventive_care'">
    <div class="col-md-2 col-sm-3">
      <label>Frequency</label>
    </div>
    <div class="col-md-2 col-sm-5">
      <select class="form-control text-capitalize" [(ngModel)]="alertTemplateRules.frequency_trend" name="frequency_trend" required>
        <option value="once" selected>Once</option>
        <option value="every">Every</option>
      </select>
    </div>
    <div class="col-md-1 col-sm-5">
      <input type="number" class="form-control" placeholder="Frequency" [disabled]="alertTemplateRules.frequency_trend === 'once'"
             [(ngModel)]="alertTemplateRules.frequency_value" name="frequency_value" required>
    </div>
    <div class="col-md-2 col-sm-5">
      <select class="form-control text-capitalize" [disabled]="alertTemplateRules.frequency_trend === 'once'" [(ngModel)]="alertTemplateRules.frequency_type" name="frequency_type" required>
        <option value="f_day" selected>Day</option>
        <option value="f_month">Month</option>
        <option value="f_year">Year</option>
      </select>
    </div>
  </div>
</form>

