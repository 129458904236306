<h4 class="text-tertiary "><i class="fa icon-speech fs22 vl-m mr-sm"></i> Comments</h4>
<ul class="list-unstyled border-list">
  <li class="list media m0" *ngFor="let comment of comments">
    <div class="media-left">
      <img alt="Image" class="img-circle thumb48" src="{{comment.commenter_profile_pic}}">
    </div>
    <div class="media-body media-middle" *ngIf="!comment.edit_comment">
      <h5 class="fw-500 m0 fs16">{{comment.commenter_name}}</h5>
      <p class="m0 hidden-sm hidden-xs">{{comment.body}}</p>
    </div>
    <div class="media-body media-middle" *ngIf="comment.edit_comment">
      <textarea [(ngModel)]="comment.body" class="form-control no-resize form-group" style="height:60px;"></textarea>
      <button class="btn btn-secondary mr" (click)="comment.edit_comment = !comment.edit_comment;updateComment(comment)">Update</button>
      <button class="btn btn-tertiary btn-outline" (click)="cancelEditComment(comment)">Cancel</button>
    </div>
    <div class="media-right text-right" *ngIf="!comment.edit_comment" style="min-width:150px;">
      <h6 class="fw-400 m0">{{comment.updated_at | date:'MMMM d, y, h:mm a'}}</h6>
      <div *ngIf="userService.current_user.id == comment.commenter_id">
        <a (click)="editComment(comment)" class="btn btn-sm text-tertiary"><i class="fa icon-pencil"></i></a>
        <a (click)="delete_comment(comment)" class="btn btn-sm text-danger"><i class="fa icon-trash"></i></a>
      </div>
    </div>
    <p class="m0 visible-sm visible-xs" *ngIf="!comment.edit_comment">{{comment.body}}</p>
  </li>
</ul>
<div class="media border-box shadow p">
  <div class="media-left">
    <img alt="Image" class="img-circle thumb48" src="{{userService.current_user.profile_pic}}">
  </div>
  <div class="media-body media-middle">
    <textarea [(ngModel)]="comment.body" (focusin)="startAction('comment')" class="form-control no-resize form-group" placeholder="Add a comment..."></textarea>
    <button class="btn btn-secondary mr" (click)="addComment()" [disabled]="(comment.body.length === 0)">Add</button>
    <a class="btn btn-tertiary btn-outline" (click)="clearComments()">Cancel</a>
  </div>
</div>
