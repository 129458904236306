import {BaseModel} from '../../shared/base';
import {toUpper} from 'lodash';

export  class PatientInsurance extends BaseModel {
  id: number ;
  patient_id: number;
  insurance_id: number;
  patient_insurance_id: number;
  insurance_name: string;
  insurance_type: string;
  importance_type: string;

  group_number: any;
  bin_number: any;
  rx_pcn: any;
  effective_date: Date;
  expiry_date: Date;
  address: string;
  city: string;
  state: string;
  zip: any;
  phone: string;
  fax: string;
  card_front_pic: any;
  card_back_pic: any;
  card_back: any;
  card_front: any;
  attachments_ids: any[] = [];

  load_from_json(json) {
    this.id = json.id;
    this.patient_id = json.patient_id;
    this.insurance_id = json.insurance_id;
    this.patient_insurance_id = json.patient_insurance_id;
    this.insurance_name = json.insurance_name;
    this.insurance_type = this.setPpoandHmo(json.insurance_type);
    this.importance_type = json.importance_type;

    this.group_number = json.group_number;
    this.bin_number = json.bin_number;
    this.rx_pcn = json.rx_pcn;
    this.effective_date = json.effective_date;
    this.expiry_date = json.expiry_date;
    this.address = json.address;
    this.city = json.city;
    this.state = json.state;
    this.zip = json.zip;
    this.phone = json.phone;
    this.fax = json.fax;
    this.card_front_pic = json.card_front_pic;
    this.card_back_pic = json.card_back_pic;
    this.card_back = json.card_back;
    this.card_front = json.card_front;
    return this;
  }

  to_params() {
    return {
      patient_insurance: {
        insurance_id: this.insurance_id,
        insurance_name: this.insurance_name,
        insurance_type: this.insurance_type,
        importance_type: this.importance_type,
        patient_insurance_id: this.patient_insurance_id,
        patient_id: this.patient_id,
        group_number: this.group_number,
        bin_number: this.bin_number,
        rx_pcn: this.rx_pcn,
        effective_date: this.effective_date,
        expiry_date: this.expiry_date,
        address: this.address,
        city: this.city,
        state: this.state,
        zip: this.zip,
        phone: this.phone,
        fax: this.fax,
        card_front_pic: this.card_front_pic,
        card_back_pic: this.card_back_pic,
        attachments_ids: this.attachments_ids
      }
    }
  }

  setPpoandHmo(insurance_type) {
    if (insurance_type === 'ppo' || insurance_type === 'hmo' ) {
      return  this.insurance_type = toUpper(insurance_type);
    } else {
      return this.insurance_type = insurance_type
    }
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
}
