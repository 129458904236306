<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="show_age_option">
    <label class="fw-500">Age*</label>
    <div class="row form-group">
      <div class="col-sm-5 col-xs-6">
        <select name="year" class="form-control" [(ngModel)]="age_year"  (change)="change_selection('age_year', $event.target.value)">
          <option *ngFor="let year of patient_age_years" [value]="year">{{year}}</option>
        </select>
      </div>
      <div class="col-sm-5 col-xs-6">
        <select name="month" class="form-control" [(ngModel)]="age_month"  (change)="change_selection('age_month', $event.target.value)">
          <option *ngFor="let month of months" [value]="reverse_months_list[month]">{{reverse_months_list[month]}}</option>
        </select>
      </div>
      <div class="col-sm-2 col-xs-12"><label class="pt-sm text-normal">OR</label></div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-12 date-of-birth">
    <label>Date of Birth*</label>
    <div class="row form-group">
      <div class="col-xs-4">
        <select name="month" class="form-control"  (change)="change_selection('Month', $event.target.value)"  >
          <option *ngFor="let month of months; let i = index" [value]="month" [selected]="month == months_list[date.month]">
            <span *ngIf="i === 0, else monthsOptions">{{month}}</span>
            <ng-template #monthsOptions>{{months?.indexOf(month)}}</ng-template>
          </option>
        </select>
      </div>
      <div class="col-xs-4">
        <select name="day" class="form-control" [(ngModel)]="date.day" (change)="change_selection('Day', $event.target.value)">
          <option *ngFor="let day of days" [value]="day">
            {{day}}
          </option>
        </select>
      </div>
      <div class="col-xs-4">
        <select name="year" class="form-control" [(ngModel)]="date.year" (change)="change_selection('', '')" >
          <option *ngFor="let year of years" [value]="year" [selected]="year == date.year">
            {{year}}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
