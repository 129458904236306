import { Component, OnInit } from '@angular/core';
import {Tab} from '../../tabs/tab';
import {TabsService} from '../../services/tabs.service';
import {EditReminderComponent} from '../edit-reminder/edit-reminder.component';
import {ReminderTemplate} from '../reminder-template';
import {NewReminderComponent} from '../new-reminder/new-reminder.component';
import {RemindersService} from '../reminders.service';
import {LoaderService} from '../../services/loader.service';
import {Subscription} from 'rxjs/index';


@Component({
  selector: 'app-reminders-listing',
  templateUrl: './reminders-listing.component.html',
  styleUrls: ['./reminders-listing.component.scss']
})
export class RemindersListingComponent implements OnInit {
  public filter: Object = {name: '', page: 1, reminder_type: ''};
  public totalItems;
  public subscription: Subscription;
  reminders_template: ReminderTemplate[] = [new ReminderTemplate()]

  constructor(public loaderService: LoaderService, public tabs_service: TabsService, public reminder_template_service: RemindersService)  {
    this.subscription = this.reminder_template_service.reloadReminder.subscribe(item => {
      if (item) {
        this.reminder_template_service.reloadReminder.next(0);
        this.getRemindersTemplate();
      }
    });
  }


  ngOnInit(): void {
    this.getRemindersTemplate()
  }
  search() {
    console.log(this.filter)
    this.getRemindersTemplate()
  }
  getRemindersTemplate() {
    this.loaderService.show();
    this.reminder_template_service.getRemindersTemplate(this.filter).then(resp => {
      this.reminders_template = resp.reminders_template.map(reminder => new ReminderTemplate().load_from_json(reminder));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    });
  }

  addReminder() {
    let reminder = new ReminderTemplate();
    this.tabs_service.add_tab(new Tab({title: 'Add Reminder', icon: 'fa'}, NewReminderComponent, reminder.id,
      {id: reminder.id, reminder: reminder}))
    console.log(this.filter)
  }

  editReminder(reminder) {
    let rem = reminder;
    this.tabs_service.add_tab(new Tab({title: reminder.name, icon : 'fa'}, EditReminderComponent, rem.id, {id: rem.id, reminder: rem}))
  }

  pageChanged(page) {
    this.filter['page'] = page.page;
    this.getRemindersTemplate()
  }


  updateStatus(template, status) {
    let temp = template;
    temp.status = status
    this.reminder_template_service.save(temp)
      .then(resp => {this.getRemindersTemplate()})
  }

  deleteTemplate(template) {
    this.reminder_template_service.delete(template.id).then(resp => {
      this.reminders_template.splice(this.reminders_template.indexOf(template), 1);
      this.getRemindersTemplate();
    })

  }

  capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

}
