import {Vital} from './vital';
export class VitalEntry {
  id: number;
  patient_vital_id: number;
  value: any;
  descriptor: string;
  difficulty: string;
  rhythm: string;
  vital_id: number;
  vital: Vital;
  date: Date;
  reading_date: Date;
  descriptor_class: string;
  _destroy = false;

  load_from_json(json) {
    this.rhythm = json.rhythm;
    this.difficulty = json.difficulty;
    if (json.descriptor_class) {
      this.descriptor_class = json.descriptor_class;
    }
    this.id = json.id;
    this.patient_vital_id = json.patient_vital_id;
    this.value = json.value;
    this.descriptor = json.descriptor;
    this.vital_id = json.vital_id;
    if (json.vital) {
      this.vital = new Vital().load_from_json(json.vital);
    }
    this.date = new Date(json.created_at);
    this.reading_date = new Date(json.reading_date);
    return this;
  }

  initializeVitalEntry(vital: Vital) {
    this.vital_id = vital.id;
    this.vital = vital;
    this.descriptor = null;
    return this;
  }

  to_params() {

  }
}
