import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Patient} from '../../doctor/patient';
import {UserService} from '../../../services/user.service';
import {IntakeFormTemplate, IntakeFromStep} from '../../../routes/intake-form-template-index/intake-form-template';
import {PatientIntakeFormService} from '../../../routes/practice-site/patient-intake-form-index/patient-intake-form.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import {timer} from 'rxjs';

@Component({
  selector: 'app-patient-intake-workflow',
  templateUrl: './patient-intake-workflow.component.html',
  styleUrls: ['./patient-intake-workflow.component.scss']
})
export class PatientIntakeWorkflowComponent implements OnInit {
  public selectedStepIndex: number = 0;
  public selectedStep: IntakeFromStep = new IntakeFromStep();
  @Input() patient: Patient;
  @Input() pendingForm: IntakeFormTemplate;
  @Output() formCompleted : EventEmitter<void> = new EventEmitter<void>();
  public selected_stage_id: any;
  public formFillSuccessMessage: string = '';

  constructor(public _userService: UserService, public patientIntakeFormService: PatientIntakeFormService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public loaderService: LoaderService) {
  }
  ngOnInit(): void {
    this.selectedStepIndex = this.pendingForm.pending_index_number;
    this.selectedStep = this.pendingForm.steps[this.selectedStepIndex];
    this.selected_stage_id = this.selectedStep.name;
    this.updateFormStatus(this.pendingForm.id, 'being_filled');
  }

  onSelect(stageIndex: number, name) {
    this.selected_stage_id = name;
    this.selectedStepIndex = stageIndex;
    this.selectedStep = this.pendingForm.steps[this.selectedStepIndex];
  }

  onNext() {
    this.updateStepStatus(this.selectedStep.id, 'done');
  }

  onSkip() {
    this.updateStepStatus(this.selectedStep.id, 'skipped');
  }

  updateFormStatus(form_id, status: string) {
    this.patientIntakeFormService.updatePatientFormStatus(form_id, status).then(resp => {
      this.pendingForm.load_from_json(resp);
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  updateStepStatus(stepId: number, status: string) {
    this.loaderService.small_loder_show();
    this.patientIntakeFormService.updateStepStatus(stepId, status).then(resp => {
      const updatedStep = resp.step;
      const foundStep = this.pendingForm.steps.find(step => step.id === updatedStep.id);
      if (foundStep) {
        foundStep.load_from_json(updatedStep);
      }
      this.moveToNextStep();
      this.loaderService.small_loder_hide();
    }).catch(error => {
      this.loaderService.small_loder_hide();
      this.globalErrorHandlerService.error = error.error.error;
    })
  }

  moveToNextStep() {
    if (this.selectedStepIndex + 1 < this.pendingForm.steps.length) {
      this.selectedStepIndex++;
      this.selectedStep = this.pendingForm.steps[this.selectedStepIndex];
      this.selected_stage_id = this.selectedStep.name;

    } else {
      this.updateFormStatus(this.pendingForm.id, 'filled');
      this.displayCompletionMessageAndEmitEvent();
    }
  }
  displayCompletionMessageAndEmitEvent() {
    this.formFillSuccessMessage = 'You have successfully filled the form.';
    timer(3000).subscribe(() => {
      this.formFillSuccessMessage = '';
      this.formCompleted.emit();
    });
  }

  onPrevious() {
    this.selectedStepIndex--;
    this.selectedStep = this.pendingForm.steps[this.selectedStepIndex];
    this.selected_stage_id = this.selectedStep.name;
  }
}
