import { Injectable } from '@angular/core';
import {DocumentsService} from './document.service';
import {CustomHttpClient} from './custom-http-client.service';
import {DocumentPage} from '../health/patient-profile/patient-dashboard/chart/document';
import {LoaderService} from './loader.service';
import {GlobalErrorHandlerService} from './global-error.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentViewerService extends DocumentsService {

  constructor(_tokenService: CustomHttpClient,
              private globalErrorHandlerService: GlobalErrorHandlerService,
              private loaderService: LoaderService) {
    super(_tokenService);
    console.log('Document Service Created');
  }

  loadAttachment(id) {
    this.loaderService.show();
    this.pages = []
    return this._tokenService.get('attachments/get_pages?id=' + id)
      .toPromise()
      .then(res => {
          res.map(page => {
            this.pages.push(new DocumentPage().load_from_json(page));
            this.selected_page = 1;
            this.is_fullscreen = true;
          })
        this.loaderService.hide()
        if (this.pages.length === 0) {
          this.globalErrorHandlerService.error = 'Document not ready for review.'
        }
      }). catch(() => {
        this.loaderService.hide();
      })
  }

  viewLoadedAttachment(pages) {
    this.loaderService.show();
    this.pages = [];
    pages.map(page => {
      this.pages.push(new DocumentPage().load_from_json(page));
      this.selected_page = 1;
      this.is_fullscreen = true;
    })
    this.loaderService.hide();
  }

  closeLoadedAttachment() {
    this.is_fullscreen = false;
  }
}
