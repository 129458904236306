import {Component, Input, OnInit} from '@angular/core';
import {Consent} from '../../../program/program-consents/consent';
import {formatDate} from '@angular/common';
import {ProgramService} from '../../../program.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-enrollment-consent',
  templateUrl: './enrollment-consent.component.html',
  styleUrls: ['./enrollment-consent.component.scss']
})
export class EnrollmentConsentComponent implements OnInit {
  @Input() consent: Consent;
  @Input() programEnrollmentId: any;
  @Input() isEnrolledPatient: any;
  public date: Date = new Date();
  public today_date: any;
  public current_time: any;

  constructor(public programService: ProgramService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public userService: UserService) {

  }

  ngOnInit(): void {
    this.today_date = formatDate(this.date, 'yyyy-MM-dd', 'en');
    this.current_time = formatDate(this.date, 'HH:mm', 'en');
    if (this.consent.signed_by_id) {
      this.date = this.consent.signed_at_date;
      this.current_time = this.consent.signed_at_time;
      this.today_date = this.consent.signed_at_date;
    }
  }

  saveConsentOfPatient() {
    this.save();
  }

  save() {
    this.loaderService.show();
    this.consent.signed_by_id = this.userService.current_user.id;
    this.consent.signed_at_date = this.today_date;
    this.consent.signed_at_time = this.current_time;
    this.programService.saveConsent(this.consent)
      .then(resp => {
        this.consent.load_from_json(resp);
        this.loaderService.hide();
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }
}
