<div class="flex-left">
  <div class="input-group p-sm" *ngIf="locations">
    <span class="input-group-addon"><i class="fa icon-location-pin"></i></span>
    <select [(ngModel)]="location_id" #location class="form-control" (change)='select_location(location.value)'>
      <option value='0'>Select Locations</option>
      <option *ngFor="let location of locations" selected="location_id" [value]="location.id">{{location.name}}</option>
    </select>
  </div>
  <div class="input-group p-sm" *ngIf="doctors && showDoctorDropdown">
    <span class="input-group-addon"><i class="fa icon-user"></i></span>
    <select [(ngModel)]="doctor_id" #provider class="form-control" (change)='select_doctor(provider.value)'>
      <option value='0' selected>Select Providers</option>
      <option *ngFor="let provider of doctors" [value]="provider.id">{{provider.firstname}} {{provider.lastname}}</option>
    </select>
  </div>
  <ul class="list-unstyled border-list">
    <li class="list" *ngFor="let selectedCalendar of selectedCalendars; let i=index">
      <div *ngIf="selectedCalendar.doctor">
        <a class="remove-btn text-danger" (click)="deleteSelectedCalender(selectedCalendar)"><i class="fa fa-times"></i></a>
        <h4 class="text-black m0 mb-sm fs16">{{selectedCalendar.doctor.firstname}} {{selectedCalendar.doctor.lastname}}</h4>
        <p class="m0 text-muted fs13">{{selectedCalendar.doctor.designation}}</p>
        <p class="mb0 text-primary">{{ selectedCalendar.location.name }}</p>
      </div>
    </li>
    <li class="p" *ngIf="show_save_button">
      <a class="btn btn-tertiary center-block" (click)="saveSelectedCalendar()">Save Filter</a>
    </li>
  </ul>
</div>
<div class="flex-body flex-control flex-column">
  <app-calendar-header class="header-date"
                       [(view)]="view" [(viewDate)]="viewDate" (viewDateChange)="fetchEvents()">
  </app-calendar-header>
  <div class="flex-body main-body scroll-browser" *ngIf="selected && show_calendar">
    <div class="calendar-boxes" *ngFor="let select of selected; let i = index ">
      <h4 class="dr-name">
        <small class="pull-right mr-lg"><i class="fa icon-clock"></i> {{locationService.timeZoneName(select.doctor.location.time_zone)}}</small>
        {{select.doctor.doctor.firstname}} {{select.doctor.doctor.lastname}}
        <a class="box-close" (click)="deleteSelectedCalender(select.doctor)" ><i class="fa fa-times"></i></a>
      </h4>
      <div class="hosp-name">
        <h5 class="m0">{{select.doctor.location.name}}</h5>
        <div class="note-view hidden" dropdown>
          <a dropdownToggle><i class="fa icon-note"></i></a>
          <div class="dropdown-menu animated slideInDown" onclick="event.stopPropagation();">
            <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed.
              <a><i class="fa fa-pencil"></i></a>
            </p>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Type your note here...">
              <span class="input-group-btn">
                <button class="btn btn-primary btn-outline" type="button"><i class="fa fa-chevron-right"></i></button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-view-box scroll-browser">
        <ng-container *ngFor="let sel of select.doctor.doctor.getFilteredCalendarFor(select.doctor.location.id,view == 'day' && viewDate); let i = index">
          <app-calendar-view class="calendar-view"
                             [calendar_start]="calendar_start_date" [refresh]="refresh" [calendar_end]="calendar_end_date"
                             [showHeader]="i == 0" *ngIf="show_calendar" [view]="view" [viewDate]="viewDate"
                             [events]="select.events" [calenderInfo]="sel"  [doctorInfo]="select.doctor">
          </app-calendar-view>
        </ng-container>
        <h5 class="cal-exp alert alert-info" *ngIf="!select.doctor.doctor.getFilteredCalendarFor(select.doctor.location.id,view == 'day' && viewDate)?.length">
          <i class="fa icon-calendar center-block fs22 mb-lg"></i>
          No calendar available
        </h5>
      </div>
    </div>
  </div>
</div>
