import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {Subscription, timer} from 'rxjs';
import {Activity} from '../program/activity';
import {EnrolmentsService} from '../program/enrolments.service';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Econtact} from '../health/doctor/econtact';
import {TrackingService} from '../services/tracking.service';
import {UserService} from '../services/user.service';
export interface PatientInfo {
  name: string;
  pic;
  phone?: string;
  mobile?: string;
  e_contacts?: Econtact[];
  show_selection?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  hidden = true;
  to_name: string;
  calling = false;
  receiver_profile_pic: any;
  receiver_name: any;
  in_call = false;
  phone_number: any;
  token: any;
  speaker_devices: MediaDeviceInfo[] = [];
  ringtone_devices: MediaDeviceInfo[] = [];
  timer: Subscription;
  clock;
  source_id: number;
  viewable_number: any;
  patient_info: PatientInfo;
  showSmallWindow = false;

  constructor(private http: CustomHttpClient,
              private userService: UserService,
              private enrolmentsService: EnrolmentsService,
              public trackingService: TrackingService) { }

  getAuthToken(): Promise<any> {
      return this.http.get('video/voice_token').toPromise();
  }

  getCallSummary(call_sid): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + btoa('AC2ceb18d36525532d2ab9afa6fcb00bc9:a8f8d08a46b08deb842ad11d1f1ebb77')
      })
    };
    return this.http.outerSideGet(`https://insights.twilio.com/v1/Voice/${call_sid}/Summary`, httpOptions)
      .toPromise()
      .then((resp) => { console.log('Summary is', resp)});
  }

  startTimer(): void {
    this.clock = 0;
    this.timer = timer(1000, 1000)
      .subscribe(() => {
        ++this.clock;
      });
  }

  stopTimer() {
    this.timer?.unsubscribe();
    if (this.source_id) {
      this.logTimeForPatientTime();
    }
  }

  get formatClock(): string {
    if (isNaN(parseInt(this.clock) )) {
      return '00:00:00'
    }
    return  new Date(this.clock * 1000).toISOString().substr(11, 8);
  }

  initiatePhoneCall(phone, patient_info: PatientInfo, source_id?: number) {
    if (!this.userService.user.twilio_configurations.sms) {
      return;
    }
    if (patient_info.e_contacts?.length === 0 && !patient_info.phone) {
      patient_info.show_selection = false;
    }
    this.patient_info = patient_info;
    this.source_id = source_id;
    this.hidden = false;
    this.viewable_number = phone;
    this.phone_number = `${environment.phone_number_prefix}${phone.replace(/^0+/, '')}`;
    console.log(phone, this.phone_number)
  }

  logTimeForPatientTime() {
    let add_activity: Activity = new Activity();
    add_activity.time_log_attributes.minutes = this.clock > 0 ? ( this.clock / 60 ) : '';
    add_activity.activiable_type = 'ProgrammeEnrolment';
    add_activity.activiable_id = this.source_id;
    add_activity.activity_type = 'phone_call';
    add_activity.description = 'Phone call';
    this.enrolmentsService.addActivityAndLogTime(add_activity).then(resp => {
      this.enrolmentsService.reloadActivityList.next(1);
      this.enrolmentsService.reloadProgramEnrolmentTime.next(1);
      this.trackingService.enrollmentLogTime(resp.activiable_id, add_activity.time_log_attributes.minutes);
    }).catch(res => {
      console.log(res);
    })
  }
}
