import {ElementRef, Injectable} from '@angular/core';
import html2canvas from 'html2canvas';
import {LoaderService} from './loader.service';
import * as jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(public loaderService: LoaderService) { }

  generatePdf(action: string, view: ElementRef, addFooter: boolean, file_name?: string) {
    this.loaderService.show()
    view.nativeElement.style.height = view.nativeElement.style.offsetHeight;
    html2canvas(view.nativeElement, {
      width: view.nativeElement.offsetWidth,
      height: view.nativeElement.offsetHeight})
      .then((canvas) => {
        let imgData = canvas.toDataURL('image/png');
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let doc = new jsPDF('p', 'mm');
        let position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage('a4');
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        if (addFooter) {
          this.addFooter(doc)
        }

        if (action === 'print') {
          doc.autoPrint();
          window.open(doc.output('bloburl'), '_blank');
        } else {
          doc.save(file_name);
        }
        this.loaderService.hide();
      });
  }
  addFooter(doc) {
    // const pageCount = doc.internal.getNumberOfPages();
    // let currentTime = new Date()
    // for (var i = 1; i <= pageCount; i++) {
    //   doc.setPage(i);
    //   doc.setFontSize(7);
    //   doc.text('' + currentTime.toLocaleString().split(',')[0], 4, 295, 'left')
    //   doc.text('Page ' + String(i) + ' of ' + String(pageCount), 205, 295, 'right');
    //   doc.text('www.wellwink.com ', 95, 295, 'justify');
    // }
  }
}
