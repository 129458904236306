import {Component, OnDestroy, OnInit} from '@angular/core';
import {IntakeFormTemplate} from '../intake-form-template';
import {LoaderService} from '../../../services/loader.service';
import {IntakeFormTemplateService} from '../intake-form-template.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {Tab} from '../../../tabs/tab';
import {TabsService} from '../../../services/tabs.service';
import {IntakeFormTemplateComponent} from '../intake-form-template/intake-form-template.component';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-intake-form-templates',
  templateUrl: './intake-form-templates.component.html',
  styleUrls: ['./intake-form-templates.component.scss']
})
export class IntakeFormTemplatesComponent implements OnInit, OnDestroy {
  public filter: Object = {name: '', page: 1};
  public intakeFormTemplates: IntakeFormTemplate[] = [];
  public totalItems;
  public subscription: Subscription;

  constructor(public loaderService: LoaderService, public intakeFormTemplateService: IntakeFormTemplateService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public tabs_service: TabsService) {
    this.subscription = this.intakeFormTemplateService.reloadIntakeFormTemplates.subscribe(item => {
      if (item) {
        this.getIntakeFormTemplates();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit(): void {
    this.search();
  }

  search() {
    this.filter['page'] = 1;
    this.getIntakeFormTemplates();
  }

  getIntakeFormTemplates() {
    this.loaderService.show();
    this.intakeFormTemplateService.getIntakeFormTemplates(this.filter).then(resp => {
      this.intakeFormTemplates = resp.intakeFormTemplates.map(template => new IntakeFormTemplate().load_from_json(template));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  viewIntakeFormTemplate(intakeFormTemplate: IntakeFormTemplate) {
    this.tabs_service.add_tab(new Tab({title: intakeFormTemplate.name}, IntakeFormTemplateComponent, intakeFormTemplate.id,
      {id: intakeFormTemplate.id, intakeFormTemplate: intakeFormTemplate}))
  }

  editIntakeFormTemplate(intakeFormTemplate: IntakeFormTemplate) {
    this.tabs_service.add_tab(new Tab({title: intakeFormTemplate.name, icon: 'fa'}, IntakeFormTemplateComponent,
      intakeFormTemplate.id, {id: intakeFormTemplate.id, intakeFormTemplate: intakeFormTemplate}))
  }

  addIntakeFormTemplate() {
    let intakeFormTemplate = new IntakeFormTemplate();
    this.tabs_service.add_tab(new Tab({title: 'Add Intake Template', icon: 'fa'}, IntakeFormTemplateComponent,
      intakeFormTemplate.id, {id: intakeFormTemplate.id, intakeFormTemplate: intakeFormTemplate}))
  }

  pageChanged($event: any) {
    this.filter['page'] = $event.page;
    this.getIntakeFormTemplates();
  }

}
