<app-tabs>
  <li *ngFor="let stage_id of stage_ids" [class.active]="selected_stage_id == stage_id && tabs_service.not_active()">
    <button class="btn btn-default" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl
            [value]="stage_id" (click)="onSelect(stage_id)"><i [ngClass]="iconMapping(stage_id)"></i>{{menu_items[stage_id]}}
    </button>
  </li>
</app-tabs>

<div class="main-wrapper" [hidden]="tabs_service.is_active()">
  <div class="table-responsive leads">
    <table class="table table-bordered table-striped table-hover">
      <thead>
      <tr>
        <th style="width:280px;">Contact</th>
        <th style="width:120px;">Phone</th>
        <th style="width:150px;">Practice</th>
        <th style="width:200px;">Specialty</th>
        <th class="text-center" style="width:100px;">Doctors</th>
        <th style="width:180px;">Area</th>
        <th style="width:180px;">Package</th>
        <th *ngIf="selected_stage_id == 1" style="width: 142px;">Actions</th>
        <th *ngIf="selected_stage_id == 2" style="width: 142px;">Actions</th>
        <th *ngIf="selected_stage_id == 3" style="width: 142px;">Status</th>
        <th *ngIf="selected_stage_id == 3" style="width: 142px;">Action Info</th>
      </tr>
      </thead>
      <tbody *ngIf="practice_array">
      <ng-container *ngFor="let practice of filtered_practices()">
        <tr>
          <td *ngIf="selected_stage_id == 1">
            <h5 class="text-primary fw-500 m0">{{practice.requester_title}} {{practice.requester_firstname}} {{practice.request_lastname}}</h5>
            <div><i class="icon icon-envelope"></i> <small>{{practice.client.email}}</small></div>
          </td>
          <td *ngIf="selected_stage_id == 2" (click)="add_to_tabs(practice)" class="pointer">
            <h5 class="text-primary fw-500 m0">{{practice.requester_firstname}} {{practice.request_lastname}}</h5>
            <div><i class="icon icon-envelope"></i> <small>{{practice.client.email}}</small></div>
          </td>
          <td *ngIf="selected_stage_id == 3">
            <h5 class="text-primary fw-500 m0">{{practice.requester_title}} {{practice.requester_firstname}} {{practice.request_lastname}}</h5>
            <div><i class="icon icon-envelope"></i> <small>{{practice.client.email}}</small></div>
          </td>
          <td><i class="icon-phone"></i> <span *ngIf="practice.phone">{{practice.phone.value}}</span></td>
          <td>{{practice.name}}</td>
          <td>
            <div *ngIf="practice.get_primary_specialty" [tooltip]="practice.get_primary_specialty.description">
              {{practice.get_primary_specialty.description}}
            </div>
          </td>
          <td class="text-center">{{practice.no_of_doctors}}</td>
          <td>
            <div>{{practice.client.address.line_1}}</div>
            {{practice.client.address.city_area.name}}
          </td>
          <td>{{practice.account_type}}</td>
          <td colspan="2" *ngIf="selected_stage_id == 1">
            <button *userCan="'approvereject,leads,online'" class="btn-sm btn btn-outline btn-primary"
                    (click)="changePracticeStaage(practice,2)">
              Accept
            </button>
            <button *userCan="'approvereject,leads,online'" class="btn-sm btn btn-outline btn-danger"
                    (click)="setPractice(practice);classicModal.show()">
              Reject
            </button>
          </td>
          <td colspan="2" *ngIf="selected_stage_id == 2">
            <select #selectedCategory [(ngModel)]="practice.client.status_id" class="form-control short-select"
                    id="practice-{{practice.id}}"
                    [ngClass]="{'pending': practice.pending, 'on-hold': practice.onhold,'in-progress': practice.inprogress, 'completed': practice.completed}"
                    (change)='changeStatus(selectedCategory.value,practice)'>
              <option *ngFor="let value of values; let i = index;" value={{i+1}}>
                {{value}}
              </option>
            </select>
            <progressbar value={{practice.profile_percentage}} id="progress-{{practice.id}}"
                         [ngClass]="{'pending': practice.pending, 'on-hold': practice.onhold,'in-progress': practice.inprogress, 'completed': practice.completed}">
              {{practice.profile_percentage}} %
            </progressbar>
          </td>
          <td *ngIf="selected_stage_id == 3">
            <div class="text-danger">Rejected</div>
            <p class="text-danger m0" [tooltip]="practice.rejection_reason">{{practice.rejection_reason}}</p>
          </td>
          <td *ngIf="selected_stage_id == 3">
            <div>{{practice.updated_at | date: 'dd/MM/yyyy'}}</div>
            <small>{{this.getRejectedByUserName(practice.rejected_by_id)}}</small>
          </td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="20" class="text-center">
          <app-no-result-found *ngIf="+totalItems == 0"></app-no-result-found>
          <pagination  *ngIf="+totalItems > 10 && +totalItems !=0" [totalItems]="totalItems" [itemsPerPage]="10"   (pageChanged)="pageChanged($event)"></pagination>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-container">
  <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1" role="dialog"
       aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md" style="height:275px;">
      <div class="modal-content modal-border">
        <div class="modal-heading mb-lg">
          <button type="button" class="close" aria-label="Close" (click)="classicModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title"><span class="col-sm-6">Reject Practice Request</span></h4>
        </div>
        <div class="modal-body">
        <span class="col-sm-1">
          Reason
        </span>
          <span class="col-sm-10">
          <textarea [(ngModel)]="rejection_reason" name="rejection_reason" cols="50" rows="7" class="ml-xl"></textarea>
        </span>
        </div>
        <div class="row">
          <div class="col-sm-12 mt-lg">
            <div class="col-sm-6"><span class="pull-right"><button type="button"
                                                                   (click)="classicModal.hide();changePracticeStaage(practice,3)"
                                                                   data-dismiss="classicModal"
                                                                   class="btn btn-default-ilaaj">Submit</button></span>
            </div>
            <div class="col-sm-6"><span class="pull-left"><button type="button" (click)="classicModal.hide()"
                                                                  class="mb-sm btn btn-inverse">Cancel</button></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
