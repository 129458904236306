<div class="flex-control flex-column flex-height">
  <div class="flex-header">
    <h4 class="fs20 m0">{{richTextTemplate.id ? 'Edit' : 'Add'}} Rich Text Template</h4>
  </div>
  <form (ngSubmit)="save()" #richTextTemplateForm="ngForm" class="flex-body p scroll-browser" >
    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Name*</label>
      </div>
      <div class="col-md-4 col-sm-5">
        <input type="text" class="form-control" placeholder="Name of Rich Text Template"
               [(ngModel)]="richTextTemplate.name" name="subject" [disabled]="readOnlyForm()" required>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Type*</label>
      </div>
      <div class="col-md-4 col-sm-5">
        <select class="form-control text-capitalize" [(ngModel)]="richTextTemplate.text_type" name="type"
                [disabled]="readOnlyForm()"  (change)="changeTypeCall()"  required>
          <option value=undefined selected>Type</option>
          <option *ngFor="let type of richTextTemplateService.rich_text_types; let i = index;" [value]="type">
            {{userService.removeUnderScore(type)}}
          </option>
        </select>
      </div>
    </div>
    <span class="h4">Content</span>
    <hr class="hr-line mt">
    <div class="row form-group" *ngIf="richTextTemplate.text_type !== 'clinical_note'">
      <div class="col-sm-2"><label>Fields</label></div>
      <div class="col-sm-10">
        <div class="row">
          <div class="col-sm-5 col-xs-8">
            <select  class="form-control pl0" name="status_id"
                     (change)="insertAtCursor('[[[' + $event.target.value + ']]]');"
                     [disabled]="readOnlyForm()">
              <ng-container *ngIf="userService.current_user.isDoctor">
                <option *ngFor="let custom_field of richTextTemplateService.doctor_custom_fields; let i = index;" [value]="custom_field">
                  {{custom_field}}
                </option>
              </ng-container>
              <ng-container *ngIf="!userService.current_user.isDoctor">
                <option *ngFor="let custom_field of richTextTemplateService.practice_admin_custom_fields; let i = index;" [value]="custom_field">
                  {{custom_field}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-2"><label>Body*</label></div>
      <div class="col-sm-8">
        <ng-container *ngIf="richTextTemplate.text_type === 'text_message'">
            <textarea  #s_body cols="120" rows="6" type="text" class="form-control" id="s_body" maxlength="265"
                       [disabled]="readOnlyForm()"
                       [(ngModel)]="richTextTemplate.body" name="sms_body" required>
            </textarea>
        </ng-container>
        <ng-container *ngIf="richTextTemplate.text_type !== 'text_message'">
          <editor #editor apiKey="no-api-key" id="template_body" name="template_body"
                  [(ngModel)]="richTextTemplate.body"
                  [disabled]="readOnlyForm()"
                  [init]="init_tiny" required>
          </editor>
          <div class="mt-lg" *ngIf="richTextTemplate.body">
            <a class="btn btn-tertiary btn-outline" (click)="previewModal.show()">Preview</a>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex-footer pl-lg">
      <div class="form-group">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
          <button type="submit" class="btn btn-secondary btn-min mr" [disabled]="!richTextTemplateForm.form.valid"
                  *ngIf="!loaderService.uploading_in_process"
                  [disabled]="readOnlyForm()">{{richTextTemplate.id ? 'Update' : 'Create'}}</button>
          <button class="btn btn-tertiary btn-outline btn-min mr" (click)="closeForm()">Cancel</button>
          <button *ngIf="readOnlyForm();" class="btn btn-secondary btn-outline btn-min mr" (click)="makeCopy(richTextTemplate)">Create Copy</button>
          <ng-container *ngIf="!richTextTemplate.is_import">
            <button type="button" *ngIf="showDeleteButton(richTextTemplate)" class="btn btn-danger btn-outline btn-min mr" (click)="deleteModal.show()">Delete</button>
          </ng-container>
        </div>
      </div>
    </div>
  </form>



  <app-attachments
    (popup_closed)="show_attachment_popup= false" [fileType]="'profile_pic'"
    [only_modal]="true" [auto_upload]="true" [attachable_id]="richTextTemplate.id"
    [attachable_type]="'TextTemplate'"
    [show]="show_attachment_popup">
  </app-attachments>
</div>


<div class="modal fade" bsModal #previewModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-lg preview-modal" style="height:calc(100vh - 22%);">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" (click)="previewModal.hide()"><i class="fa fa-times fs16"></i></button>
        <div [innerHTML]="richTextTemplate.body | safeHtml"></div>
      </div>
      <div class="modal-footer text-center p">
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="previewModal.hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-sm" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Rich Text Template</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p-sm m0 text-center">
          Are you sure you want to delete this rich text template?
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteTemplate(richTextTemplate.id); this.deleteModal.hide()">Yes</button>
        <button type="button" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
