<div class="flex-control flex-column flex-height">
  <app-payment-header class="flex-header" *ngIf="appointment"  [appointment]="appointment"></app-payment-header>
  <div class="flex-body scroll-browser bg-lightest-gray p" *ngIf="patient_vital && patient_vital.vital_entries.length>0">
    <h4 class="mt0" *ngIf="appointment">Vital</h4>
    <div class="border-box p">
      <div class="row">
        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-12 pull-right text-right" *ngIf="appointment">
          <button class="btn btn-tertiary btn-outline mr-sm" (click)="add_to_tabs('Print')">Print</button>
          <button class="btn btn-secondary" (click)="initializeVitalEntries()">Add</button>
        </div>
        <div class="col-lg-10 col-md-9 col-sm-9 col-xs-12 add-col-vital">
          <form (ngSubmit)="save()" #vitalForm="ngForm">
            <div class="row">
              <div class="col-sm-7 col-xs-12">
                <div class="row form-group">
                  <label class="col-sm-3 col-xs-12 field-label control-label">Date Time</label>
                  <div class="col-sm-6 col-xs-12">
                    <input type="datetime-local" name="datetime"  id="start-date" [value]="patient_vital.reading_date"
                           [(ngModel)]="patient_vital.reading_date"
                           class="form-control"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-3 col-xs-12 field-label control-label">Temperature (F)</label>
                  <div class="col-sm-4 col-xs-12">
                    <input type="text" pattern="^\d+\.?\d{0,2}$" name="temp" [(ngModel)]="patient_vital.temperature.value"
                           class="form-control"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-3 col-xs-12 field-label control-label">Pulse (bpm)</label>
                  <div class="col-sm-4 col-xs-6">
                    <input type="text" pattern="\d+" name="pulse" [(ngModel)]="patient_vital.pulse.value"
                           class="form-control"/>
                  </div>
                  <div class="col-sm-4 col-xs-6">
                    <select [disabled]="!patient_vital.pulse.value" name="rhythem" (change)="selectRhythem($event)"
                            [(ngModel)]="patient_vital.pulse.rhythm" class="form-control">
                      <option [ngValue]=undefined>Select Rhythm</option>
                      <option value="regular">Regular</option>
                      <option value="irregular">Irregular</option>
                      <option value="strong">Strong</option>
                      <option value="weak">Weak</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-3 col-xs-12 field-label control-label">Respiration (bpm)</label>
                  <div class="col-sm-4 col-xs-6">
                    <input type="text" pattern="\d+" name="respiration" [(ngModel)]="patient_vital.respiration.value"
                           class="form-control"/>
                  </div>
                  <div class="col-sm-4 col-xs-6">
                    <select name="difficulty" [disabled]="!patient_vital.respiration.value"
                            (change)="selectDifficulty($event)"
                            [(ngModel)]="patient_vital.respiration.difficulty" class="form-control">
                      <option [ngValue]=undefined>Select Difficulty</option>
                      <option value="normal">Normal</option>
                      <option value="shallow">Shallow</option>
                       
                      <option value="labored">Labored</option>
                       
                      <option value="noisy">Noisy</option>
                       
                      <option value="kussmaul">Kussmaul</option>
                       
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-3 col-xs-12 field-label control-label">BP (Sys/Dia)</label>
                  <div class="col-sm-3 col-xs-6">
                    <input type="text" name="sbp" pattern="\d+" (change)="test()" [(ngModel)]="patient_vital.sBP.value"
                           class="form-control"/>
                  </div>
                  <div class="col-sm-3 col-xs-6">
                    <input type="text" name="dbp" pattern="\d+" (change)="test()" [(ngModel)]="patient_vital.dBP.value"
                           class="form-control"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-3 col-xs-12 field-label control-label">SpO2 (%)</label>
                  <div class="col-sm-3 col-xs-6">
                    <input type="number" min="1" max="100" name="spo" (change)="changeVital('spo2')"
                           [(ngModel)]="patient_vital.spo2.value"
                           class="form-control"/><span class="text-danger" *ngIf="spo2_error">Select Value between 1-100.</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-5 col-xs-12">
                <div class="row form-group">
                  <label class="col-sm-4 col-xs-12 field-label control-label">Weight (Lb)</label>
                  <div class="col-sm-6 col-xs-12">
                    <input type="text" pattern="^\d+\.?\d{0,4}$" name="weight" (change)="CalBmi()"
                           [(ngModel)]="patient_vital.p_weight"
                           class="form-control"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-4 col-xs-12 field-label control-label">Height (in)</label>
<!--                  <div class="col-sm-3 col-xs-6">-->
<!--                    <input type="text" name="feet" pattern="\d+" (change)="CalBmi()" [(ngModel)]="patient_vital.heightInFeet"-->
<!--                           class="form-control"/>-->
<!--                  </div>-->
                  <div class="col-sm-3 col-xs-6">
                    <input type="text" pattern="^\d+\.?\d{0,2}$" name="inches" (change)="CalBmi()"
                           [(ngModel)]="patient_vital.heightInInch"
                           class="form-control"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-4 col-xs-12 field-label control-label">BMI</label>
                  <div class="col-sm-6 col-xs-12 rmal">
                    <input readonly type="text" [value]="patient_vital.bodyMassIndex.value" class="form-control"/>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-4 col-xs-12 field-label control-label">Blood Glucose (mg/dL)</label>
                  <div class="col-sm-3 col-xs-6">
                    <input type="number" min="0" max="1000" class="form-control"pattern="\d+"
                           name="glucose"
                           (change)="selectGlucoseType(glucose.selectedOptions[0].value)"
                           [(ngModel)]="patient_vital.glucose_value"
                           class="form-control"/>
                  </div>
                  <div class="col-sm-3 col-xs-6 pl0">
                    <select #glucose name="glucose_type" [value]="patient_vital.fbg ? 'fasting' : 'random'"
                            (change)="selectGlucoseType($event.target.value)" class="form-control">
                      <option selected value="fasting">Fasting</option>
                      <option value="random">Random</option> 
                    </select>
                  </div>
                </div>
                <div class="row form-group">
                  <label class="col-sm-4 col-xs-12 field-label control-label">Pain Scale</label>
                  <div class="col-sm-4 col-xs-6">
                    <select name="pain"
                            (change)="changeVital('nprs')" [(ngModel)]="patient_vital.nprs.value" class="form-control">
                      <option value=undefined>Select</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-secondary btn-min" *ngIf="show_save_button" [disabled]="!vitalForm.form.valid || disabled()">
                {{patient_vital.is_new_record() ? 'Save' : 'Update'}}
              </button>
              <p class="text-primary">{{message}}</p>
            </div>
            <h6 *ngIf="patient_vital.updater_name">Vitals Signs modified by
              <span class="text-capitalize fw-500">{{patient_vital.updater_name}}</span> on {{patient_vital.updated_at | date: 'short'}}
            </h6>
            <h6 *ngIf="patient_vital.creator_name">Vitals Signs added by
              <span class="text-capitalize fw-500">{{patient_vital.creator_name}}</span> on {{patient_vital.created_at  | date: 'short'}}
            </h6>
          </form>
        </div>
      </div>
    </div>
    <div class="border-box p mt" *ngIf="appointment">
      <h4 class="mt0">History</h4>
      <app-vitals (selectedPatientVital)="patient_vital = $event" [patient_vitals]="patient_vitals"></app-vitals>
    </div>
  </div>
</div>
