import {Component, Input, OnInit} from '@angular/core';
import {CampaignAnalytic} from '../email-campaign-result/email-campaign-result.component';

@Component({
  selector: 'app-email-campaign-analytics',
  templateUrl: './email-campaign-analytics.component.html',
  styleUrls: ['./email-campaign-analytics.component.scss']
})
export class EmailCampaignAnalyticsComponent implements OnInit {
  @Input() campaignAnalytics: CampaignAnalytic;
  @Input() campaign;

  constructor() { }

  ngOnInit(): void {
  }

}
