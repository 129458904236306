import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../../services/tabs.service';
import {Tab} from '../../../tabs/tab';
import {EmergencyService} from '../../../services/emergency.service';

@Component({
  selector: 'app-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.scss']
})
export class EmergencyComponent implements OnInit {

  constructor(public tabs_service: TabsService, public emergency_service: EmergencyService) {
    this.tabs_service.register('emergency_components');
  }

  ngOnInit() {
    this.setEmergencyFilters();
  }

  ngOnChanges() {
    this.setEmergencyFilters();
  }

  setEmergencyFilters() {
    this.emergency_service.getVisitFilters().then(res => {
    });
  }

}
