<ng-container *ngIf="!showReport">
  <div class="filters-head flex-header" >
    <div class="row">
      <div class="col-sm-2 col-xs-6 mar-b">
        <select class="form-control" name="event_Type" [(ngModel)]="filter['type']">
          <option value=undefined selected>Type of Event</option>
          <option *ngFor="let type of patientEventService.event_type; let i = index;" [value]="type">
            {{userService.removeUnderScore(type)}}
          </option>
        </select>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-6 mar-b">
        <select #duration class="form-control" (change)='selectDuration(duration)'>
          <option *ngFor="let duration of durations; let i = index;" [selected]="duration == filter['date_type']" [value]="duration">{{userService.removeUnderScore(duration)}}</option>
        </select>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-6 mar-b" *ngIf="isOpen">
        <input type="text"
               [bsValue]="dateRangeValue"
               placeholder="Datepicker"
               class="form-control" [outsideClick]="true"
               (bsValueChange)="setDateRange($event)"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false}"
               #dp="bsDaterangepicker"
               bsDaterangepicker/>
      </div>
      <div class="col-md-2 col-xs-5">
        <button type="button" class="btn btn-tertiary btn-outline" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      </div>
      <div class="col-md-4 col-xs-7 text-right pull-right">
        <button type="button" class="btn btn-secondary" (click)="openReport()">Submit</button>
        <button type="button" class="btn btn-tertiary btn-outline ml" (click)="printReport.emit(false)">Back</button>
      </div>
    </div>
  </div>
  <div class="flex-body scroll-browser hor-scroll-browser">
    <app-health-calendar-table [tableFor]="'health_calendar'" [events]="patientEvents" [tableClass]="'table table-hover m0'"></app-health-calendar-table>
    <app-no-result-found *ngIf="patientEvents && patientEvents.length == 0"></app-no-result-found>
  </div>
</ng-container>

<ng-container *ngIf="showReport">
  <div class="flex-body">
    <ng-container *ngComponentOutlet="healthCalendarReportPrinterComponent; injector: custom_injector;"></ng-container>
  </div>
</ng-container>

