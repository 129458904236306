import {Component, OnChanges, OnInit, Output, EventEmitter, Input, ViewChild, Injector, OnDestroy} from '@angular/core';
import {TaskService} from '../../../../services/task.service';
import {ContactService} from '../../../../services/contact.service';
import {Task} from '../newtask/task';
import {User} from '../../../users/user';
import {TabsService} from '../../../../services/tabs.service';
import {NewtaskComponent} from '../newtask/newtask.component';
import {Subscription} from 'rxjs/index';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {EdittaskComponent} from '../edittask/edittask.component';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
  selector: 'app-tasksfilter',
  templateUrl: './tasksfilter.component.html',
  styleUrls: ['./tasksfilter.component.scss'],
  animations: [
    trigger('collapseExpand', [
      state('collapsed', style({ height: '0px', overflow: 'hidden' })),
      state('expanded', style({ height: '*' })),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')])
    ])
  ]
})
export class TasksfilterComponent implements OnInit, OnDestroy {
  selectSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false
  };
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    defaultTitle: 'Status',
    allSelected: 'All selected',
  };
  @Input() patient;
  @Input() isPatientProfile;
  @Input() isPatientDetailPage: boolean;
  @Input() isProgramDashboard: boolean;
  public users:  User[];
  public priorities: Array<string> = ['High', 'Low', 'Medium'];
  public due_date_filter: Array<string> = ['due_today', 'due_tomorrow', 'due_in_7_days', 'past_due'];
  public statuses: Array<string> = ['Not Started', 'In Progress', 'Completed'];
  public newTask = false;
  public filter: Object = {keyword: '', page: '1', assigned_by_me: '', assigned_to_me: '', assigned_to: '', priority: '',
    status_id: '', patient_id: '', due_date: '', status_ids: []};
  public totalItems: string;
  public subscription: Subscription;
  public closeNewFormSubscription: Subscription;
  public task_count: Object = {
    assigned_to_me_in_progress_count: 0, assigned_to_me_completed_count: 0, assigned_to_me_not_started_count: 0,
    assigned_by_me_in_progress_count: 0, assigned_by_me_completed_count: 0, assigned_by_me_not_started_count: 0,
    assigned_to_me_count: 0, assigned_by_me_count: 0 };
  public tasks: Task[];
  public task: Task;
  @ViewChild('newTaskModel') newTaskModel;
  @ViewChild('editTaskModal') editTaskModal;
  customInjectorForPopup: Injector;
  show_view_task_model = false;
  public show_task_model = false;
  public custom_injector: Injector;
  newTaskComponent = NewtaskComponent;
  public ediTaskComponent = EdittaskComponent;
  public optionsModel: string[];
  public myOptions: IMultiSelectOption[] = [{ id: 0, name: 'Not Started' }, { id: 1, name: 'In Progress' }, { id: 2, name: ' Completed' }];
  groupTask: { [key: string]: any[] } = {};
  arrayName = ['due_today', 'due_past', 'upcoming']
  groupTaskCollapsed : any[] = [{name: 'due_today', isCollapsed: false},{name: 'due_past', isCollapsed: true},{name: 'upcoming', isCollapsed: true}]

  constructor(public loaderService: LoaderService, public userService: UserService,
              private tabs_service: TabsService, private taskService: TaskService,
              private staffService: ContactService, public injector: Injector,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
    this.subscription = this.taskService.reloadTasks.subscribe(item => {
      if (item) {
        this.methodToGetTasks();
      }
    });
    this.closeNewFormSubscription = this.taskService.closeNewTaskForm.subscribe( resp => {
      if (resp) {
        this.newTaskModel.hide();
        this.show_task_model = false;
        this.editTaskModal.hide();
        this.show_view_task_model = false;
      }
    })
  }

  ngOnInit() {
    if(this.isPatientProfile && this.patient.id) {
      this.filter['patient_id'] = this.patient.id;
    }
    this.methodToGetTasks();
    this.getStaffs();

  }
  methodToGetTasks() {
    if (this.isPatientDetailPage) {
      this.getPatientGroupTasks()
    } else {
      this.getTasks();
      this.getTaskCount();
    }

  }
  filterTasks() {
    this.getTasks();
    this.getTaskCount();
  }
  getTaskCount() {
    delete this.filter['status_id'];
    delete this.filter['assigned_by_me'];
    delete this.filter['assigned_to_me'];
    this.taskService.getTaskCount(this.filter).then(resp => {
      this.task_count['assigned_to_me_in_progress_count'] = (
        resp['assigned_to_me']['in_progress'] == undefined) ? 0 :resp['assigned_to_me']['in_progress'];
      this.task_count['assigned_to_me_completed_count'] = (
        resp['assigned_to_me']['completed'] == undefined) ? 0 : resp['assigned_to_me']['completed'];
      this.task_count['assigned_to_me_not_started_count'] = (
        resp['assigned_to_me']['not_started'] == undefined) ? 0 : resp['assigned_to_me']['not_started'];
      this.task_count['assigned_by_me_in_progress_count'] = (
        resp['assigned_by_me']['in_progress'] == undefined) ? 0 : resp['assigned_by_me']['in_progress'];
      this.task_count['assigned_by_me_completed_count'] = (
        resp['assigned_by_me']['completed'] == undefined) ? 0 : resp['assigned_by_me']['completed'];
      this.task_count['assigned_by_me_not_started_count'] = (
        resp['assigned_by_me']['not_started'] == undefined) ? 0 : resp['assigned_by_me']['not_started'];
      this.task_count['assigned_to_me_count'] = this.task_count['assigned_to_me_completed_count'] + this.task_count['assigned_to_me_not_started_count'] + this.task_count['assigned_to_me_in_progress_count'];
      this.task_count['assigned_by_me_count'] = this.task_count['assigned_by_me_completed_count'] + this.task_count['assigned_by_me_not_started_count'] + this.task_count['assigned_by_me_in_progress_count'];
    })
  }

  getTasks() {
    this.loaderService.show()
    this.taskService.getTasks(this.filter).then(resp => {
      this.tasks = resp.tasks.map(task => new Task().load_from_json(task));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }
  getStaffs() {
    this.staffService.get_practice_users().then(resp => {
      this.users =  resp.map(user => new User().load_from_json(user))
      this.users = this.users.filter(u => u.id != this.userService.current_user.id);
    })
  }
  selectAssignedTo(assigned_to) {
    delete this.filter['assigned_to_me'];
    this.filter['assigned_to'] = assigned_to.value;
  }
  addTask() {
    this.task = new Task()
    this.newTask = !this.newTask
  }
  addNewTaskInPopup() {
    this.task = new Task();
    if (this.isPatientProfile) {
      this.task.patient = this.patient;
      this.task.patient_id = this.patient.id;
    }
    this.setTaskCustomInjector(this.task);
  }
  setTaskCustomInjector(task: Task) {
    this.custom_injector  = Injector.create([
      {provide: 'task', useValue: task},
      {provide: 'id', useValue:  task.id},
      {provide: 'add_patient_button', useValue:  this.isPatientProfile ? false : true}
    ], this.injector);
    this.show_task_model = true;
    this.newTaskModel.show();
    this.ngOnInit();
  }
  closeNewForm(event) {
    this.newTask = false;
    this.getTasks();
    this.ngOnInit();
  }
  selectStatus(status) {
    this.filter['status_id'] = status.value;
  }
  selectPriority(priority) {
    this.filter['priority'] = priority.value;
  }
  updateTasksArray(tasks) {
    this.totalItems = tasks.totalItems;
    this.filter['page'] = tasks.current_page;
    this.tasks = tasks.tasks;
  }
  updateTask(task) {
    this.task = task;
    this.newTask = !this.newTask
    this.getTasks();
  }
  pageChanged(page) {
    this.filter['page'] = page.page;
    this.getTasks();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.closeNewFormSubscription.unsubscribe();
  }
  selectStatuses(event) {
    this.filter['status_ids'] = this.optionsModel.sort().join(',');
  }
  getPatientGroupTasks() {
    this.groupTask = {};
    this.loaderService.show();
    this.taskService.getGroupTasks(this.patient.id).then(resp => {
      this.groupTask = resp;
      this.arrayName.forEach(group => {
        if (this.groupTask[group]) {
          this.groupTask[group] = this.groupTask[group].map(task => {
            const taskObj = new Task();
            taskObj.load_from_json(task);
            return taskObj;
          });
        }
      });
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }
  setCustomInjector(task) {
    this.customInjectorForPopup  = Injector.create([
      {provide: 'task', useValue: task},
      {provide: 'id', useValue:  task.id},
      {provide: 'add_patient_button', useValue:  this.isPatientProfile ? false : true},
    ], this.injector);
    this.show_view_task_model = true;
    this.editTaskModal.show();
  }
  viewTask(task: Task) {
    this.setCustomInjector(task);
  }
  viewTaskForProgram(task: Task) {
    this.setCustomInjectorForProgram(task);
  }
  setCustomInjectorForProgram(task) {
    this.customInjectorForPopup  = Injector.create([
      {provide: 'task', useValue: task},
      {provide: 'id', useValue:  task.id},
      {provide: 'add_patient_button', useValue:  false},
    ], this.injector);
    this.show_view_task_model = true;
    this.editTaskModal.show();
  }
  getGroupTaskLength(groupName: string): number {
    return this.groupTask && this.groupTask[groupName] ? this.groupTask[groupName].length : 0;
  }
  toggleCollapse(group: any): void {
    group.isCollapsed = !group.isCollapsed;
  }
}
