<div class="main-wrapper clearfix">
  <div class="row">
    <div class="col-xs-3"><h3 class="mt0">Doctors</h3></div>
    <div class="col-xs-6">
      <div class="input-group">
      <input class="form-control" placeholder="Associate doctor from library"
             [(ngModel)]="token" [typeahead]="data_source" typeaheadWaitMs="500" (typeaheadLoading)="changeTypeaheadLoading($event)"
             [typeaheadAsync]="true"
             (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
             [typeaheadOptionField]="'doctor_full_name'"/>
        <div class="input-group-addon" *ngIf="typeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
      </div>
      <div *ngIf="typeaheadNoResults" class="text-danger fs13">No Results Found!</div>
    </div>

    <div class="col-xs-3 text-right">
      <a class="btn btn-inverse" (click)="doctor_form()">Add Doctor</a>
    </div>
  </div>
  <hr class="hr-line mt-lg">
  <ng-container *ngIf="show_doctor_form">
    <h3 class="mt0 mb-xl text-primary">Add New Doctor</h3>
    <app-doctor-form (edited_doctor)="doctor_saved($event)" [doctor]="new_doctor" (form_hidden)="handle_new_doctor_hidden()"></app-doctor-form>
  </ng-container>
  <div class="media white-container-box p-lg mb-lg" *ngIf="associate_doctor_profile">
    <h4 class="mt0">Associate Doctor</h4>
    <hr class="hr-line mt0">
    <div class="media-left">
      <img class="img-circle thumb150" [src]="associate_doctor_profile.profile_pic.modal.img" alt="Image" />
    </div>
    <div class="media-body pl-lg">
      <h3 class="mt0">{{associate_doctor_profile.complete_name}}</h3>
      <h4>{{associate_doctor_profile.designation}}</h4>
      <div class="mt-lg">
        <span class="mr" *ngIf="associate_doctor_profile.client.contact_numbers[1] && associate_doctor_profile.client.contact_numbers[1].value">
          <em class="fa fa-fax"></em>&nbsp; {{associate_doctor_profile.client.contact_numbers[1].value}}
        </span>
        <span class="mr" *ngIf="associate_doctor_profile.client.contact_numbers[0] && associate_doctor_profile.client.contact_numbers[0].value">
          <em class="icon-phone"></em>&nbsp; {{associate_doctor_profile.client.contact_numbers[0].value | mask: '(000) 000-0000'}}
        </span>
        <span><em class="fa icon-envelope fa-fw"></em> &nbsp;{{associate_doctor_profile.client.email}}</span>
      </div>
      <div class="clearfix mt-lg">
        <a class="btn btn-primary mr" (click)="associate_doctor_with_practice()">Accept</a>
        <a class="btn btn-inverse" (click)="renew_associate_doctor()">Cancel</a>
      </div>
    </div>
  </div>
  <ng-container *ngIf="show_doctors_listing">
    <ng-container *ngFor="let doctor_group of group_array_for_pair">
      <ng-container *ngFor="let doctor of doctor_group;let i = index;">
        <app-doctor-profile-detail class="practice-list-doctor" (expanded)="doctor_expanded($event)" [doctor]="doctor" [doctor_permissions]="doctor_permissions" [practice]="practice" [index]="i"></app-doctor-profile-detail>
      </ng-container>
    </ng-container>
  </ng-container>
</div>








