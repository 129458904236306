import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {Tag} from '../reviews/tag';

@Injectable()
export class TagService {

  constructor(private _tokenService: CustomHttpClient) { }

  tags(): Promise<any> {
    return this._tokenService.get('tags/all_tags')
      .toPromise()
      .then(response => response)
  }


  search_tags(token: string): Observable<any> {
    let args = new HttpParams();
    args = args.set('token', token);
    return this._tokenService.get('tags/search_tag', {params: args})
      .map((resp) => resp);
  }
  create(tag: Tag): Promise<any> {
    return this._tokenService.post('tags/', tag.to_params())
      .toPromise()
      .then(response => response)
  }

  removePatientReviewTag(tag_id: any, patient_review_id: any): Promise<any> {
    return this._tokenService.delete('tags/' + tag_id + '?patient_review_id=' + patient_review_id)
      .toPromise()
      .then(response => response)
  }

  createPatientReviewTag(data: any): Promise<any> {
    return this._tokenService.post('tags/patient_review_tag', data)
      .toPromise()
      .then(response => response)  }
}
