<li dropdown>
  <a dropdownToggle (click)="refreshNotifications()">
    <i [ngClass]="notifications[key].icon"></i>
    <span class="text">Notifications</span>
    <span class="number-counter" *ngIf="get_count_for(notifications[key].count) > 0">
      {{get_count_for(notifications[key].count)}}
    </span>
  </a>
  <div class="dropdown-menu animated slideInDown noti-dropdown">
    <h4 class="noti-title fw-300">Notifications</h4>
    <ul class="list-unstyled border-list m0 scroll-browser">
      <ng-container *ngIf="notifications[key]?.push_notifications && notifications[key]?.push_notifications.length > 0; else noNotification">
        <li class="list" *ngFor="let notification of notifications[key].push_notifications">
          <a (click)="viewNotification(key, notification)">
            <small class="text-muted fs11 pull-right">{{notification?.notification_time}}</small>
            <p class="m0" [style.color]="notification?.event_color">{{notification?.event_type}}</p>
            <h5 class="text-black mt-sm mb-sm">{{notification.subject}}</h5>
            <p class="fs12 text-gray-dark m0" *ngIf="notification.message">{{notification.message}}</p>
          </a>
        </li>
      </ng-container>
      <ng-template #noNotification>
        <li class="list">
          <h5 class="text-center text-tertiary p-lg">No Notifications</h5>
        </li>
      </ng-template>
    </ul>
  </div>
</li>

<div class="modal fade test" bsModal #viewPatientEventNotificationModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:608px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="closeViewPopup()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Event</h4>
      </div>
      <app-health-calendar-event-view *ngIf="show_view_patient_event_notification_model"
                                      class="scroll-browser"
                                      [event]="patientEvent" (closeViewPopup)="closeViewPopup()" [isPatientPortal]="true">
      </app-health-calendar-event-view>
    </div>
  </div>
</div>
