<div class="modal flex-modal fade" bsModal  #updateTermStatus="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md">
    <div class="modal-content" *ngIf="showUpdateTermModel">
      <div class="modal-header">
        <button class="close" type="button" (click)="closeUpdateTermModel()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Change Status</h4>
      </div>
      <ng-container *ngIf="!changeStatusWarning">
        <div class="modal-body scroll-browser">
          <div class="row">
            <div class="col-sm-2 col-xs-3"><label class="field-label control-label">Name</label></div>
            <div class="col-sm-10 col-xs-9">
              <span class="field-value">{{selectedTerm?.d_name}}</span>
            </div>
          </div>
          <div class="row mb">
            <div class="col-sm-2 col-xs-3"><label class="field-label control-label">Directions</label></div>
            <div class="col-sm-10 col-xs-9">
              <button type="button" class="pull-right btn btn-secondary btn-outline btn-sm fs12 pt0 pb0 mt-sm" (click)="editControl(selectedTerm)">
                <i class="fa fa-pencil"></i>
              </button>
              <div class="field-value">
                <ng-container *ngFor="let term_control of selectedTerm?.custom_term_control_with_values(); let i=index">
                  <app-note-custom-control-value class="fs12 fw-400" [term]="selectedTerm" [term_control]="term_control" [control_index]="i"></app-note-custom-control-value>
                  <span class="fs12 fw-400" *ngIf="i < selectedTerm.custom_term_control_with_values().length - 3 ">,</span>
                  <span class="fs12 fw-400" *ngIf="(selectedTerm.custom_term_control_with_values().length - 2) == i">and</span>
                  <span class="fs12 fw-400" *ngIf="(selectedTerm.custom_term_control_with_values().length -1) == i">.</span>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 col-xs-3"><label class="field-label control-label">Status</label></div>
            <div class="col-sm-5 col-xs-9">
              <select [(ngModel)]="selectedTerm.status" class="form-control" name="compliance_level" id="compliance_level">
                <option value='current'>Current</option>
                <option value='past'>Past</option>
              </select>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="modal-body" *ngIf="changeStatusWarning">
        <div class="alert alert-warning p-sm m0 text-center">
          "Any changes may effect future events on patient calendar. Do you want to continue?"
        </div>
      </div>
      <div class="modal-footer">
        <a *ngIf="smallStatusLoader" class="btn btn-default">
          <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
        </a>
        <ng-container *ngIf="!smallStatusLoader">
          <button type="submit" *ngIf="!updateMedication" class="btn btn-secondary btn-min" (click)="updateTermsStatus(selectedTerm)">Save</button>
          <ng-container *ngIf="!changeStatusWarning">
            <button type="submit" *ngIf="updateMedication" class="btn btn-secondary btn-min" (click)="changeStatusWarning = true">Save</button>
          </ng-container>
          <ng-container *ngIf="changeStatusWarning">
            <button type="submit" class="btn btn-secondary" (click)="updateTermsStatus(selectedTerm)">Yes</button>
            <button type="submit" class="btn btn-tertiary btn-outline" (click)="changeStatusWarning = false">No</button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal  #addProblem="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:556px;">
    <div class="modal-content" *ngIf="showAddProblemModel">
      <div class="modal-header">
        <button class="close" type="button" (click)="closeAddProblemModel();"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center"> {{editPatientSummary ? 'Edit' : 'Add'}} {{textMapper[term_for]}}</h4>
      </div>
      <ng-container>
        <div class="modal-body">
          <ng-container *ngIf="!editPatientSummary">
            <div class="row form-group" *ngIf="term_for === 'problems'">
              <div class="col-sm-3"><label class="field-label control-label">Diagnosis*</label></div>
              <div class="col-sm-9">
                <div class="input-group">
                  <input class="form-control" placeholder="Search Diagnosis by description or ICD10 code" name="serch"
                         [disabled]="baseTerm.id"
                         [(ngModel)]="diagnosis_token" [typeahead]="data_source_diagnosis"
                         typeaheadWaitMs="500" (typeaheadLoading)="changeTypeaheadLoading($event)"
                         [typeaheadAsync]="true"
                         (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                         (typeaheadOnSelect)="typeaheadDiagnosisOnSelect($event)" [typeaheadOptionsLimit]="7"
                         (keydown)="checkString($event, diagnosis_token)"
                         [typeaheadOptionField]="'d_name'"/>
                  <div class="input-group-addon" *ngIf="typeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
                </div>
                <p class="text-danger fs12 mt-sm" *ngIf="typeaheadNoResults">No Results Found!</p>
              </div>
            </div>
            <div class="row form-group" *ngIf="term_for !== 'problems'">
              <div class="col-sm-3"><label class="field-label control-label text-capitalize">{{term_for}}*</label></div>
              <div class="col-sm-9">
                <div class="input-group">
                  <input class="form-control" placeholder="Search {{term_for}} by description" name="termsSummary"
                         [(ngModel)]="term_token" [typeahead]="data_term_source"
                         typeaheadWaitMs="500" (typeaheadLoading)="changeTypeaheadLoading($event)"
                         [typeaheadAsync]="true"
                         (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                         (typeaheadOnSelect)="typeaheadTermOnSelect($event)" [typeaheadOptionsLimit]="7"
                         (keydown)="checkString($event, term_token)"
                         [typeaheadOptionField]="'sname'"/>
                  <div class="input-group-addon" *ngIf="typeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
                </div>
                <p class="text-danger fs12 mt-sm" *ngIf="typeaheadNoResults">No Results Found!</p>
              </div>
            </div>
          </ng-container>

          <div class="row form-group" *ngIf="editPatientSummary">
            <label class="col-sm-3 field-label control-label">Name</label>
            <div class="col-sm-9">
              <input type="text"
                     class="form-control"
                     name="Medication_for"
                     [(ngModel)]="baseTerm.name"
                     disabled>
            </div>
          </div>

          <div class="row form-group" *ngIf="term_for === 'problems'">
            <label class="col-sm-3 col-xs-3 field-label control-label">Chronic</label>
            <div class="col-sm-9 col-xs-9">
              <input type="checkbox" class="mt"
                     [(ngModel)]="baseTerm.is_chronic"
                     [checked]="baseTerm.is_chronic"
                     name="is_chronic" [value]="baseTerm.is_chronic ? true : false"
                     id="is_chronic_checkbox"/>
            </div>
          </div>

          <ng-container *ngFor="let term_control of baseTerm.term_controls">

            <div class="row form-group" *ngIf="term_control.control.native_type === 'dropdown'">
              <label class="col-sm-3 field-label control-label">{{term_control.control.name}}</label>
              <div class="col-sm-9">
                <select  class="form-control pl0" (change)="setValue($event.target.value,term_control.control_id)">
                  <option value="">Select</option>
                  <option  *ngFor="let custom_field of term_control.term_control_values; let i = index;" [selected]="custom_field.recent_value" [value]="custom_field.control_value_id">
                    {{userService.removeHyphens(custom_field.val_name)}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row form-group" *ngIf="term_control.control.native_type === 'input_text'">
              <label class="col-sm-3 field-label control-label">{{showLabel(term_control.control.name)}}</label>
              <div class="col-sm-9">
                <input type="text"
                       [id]="term_control.term_control_values[0].control_value_id"
                       class="form-control"
                       [type]="term_control.control.input_type.toLowerCase()"
                       [placeholder]="textMapper[term_control.control.name]"
                       (change)="setValue(term_control.term_control_values[0].control_value_id, term_control.control_id)"
                       [(ngModel)]="term_control.term_control_values[0].val_name">
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div class="modal-footer">
        <ng-container>
          <button *ngIf="!baseTerm.id" [disabled]="disabledAddForm()" type="submit" class="btn btn-secondary" (click)="addTerm()">Save</button>
          <button *ngIf="baseTerm.id" [disabled]="!baseTerm.library_datum_id" type="submit" class="btn btn-secondary" (click)="updateTerm()">Update</button>
          <button type="submit" class="btn btn-tertiary btn-outline" (click)="closeAddProblemModel()">Close</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
