import { Component, OnInit } from '@angular/core';
import {DashboardsService} from '../../services/dashboards.service';
import {UserService} from '../../services/user.service';
import {Observable} from 'rxjs';
import {LoaderService} from '../../services/loader.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-practice-dashboard',
  templateUrl: './practice-dashboard.component.html',
  styleUrls: ['./practice-dashboard.component.scss']
})
export class PracticeDashboardComponent implements OnInit {
  show_attachment_popup = false;
  data_available = false;
  public top_data: any = {}
  json = JSON;
  selected_type  = 'month';
  duration;

  public lineChartOptions: any = {
    maintainAspectRatio: false,
    responsive: true, legend: {position: 'bottom'}, scales: {
      yAxes: [{
        ticks: {
        }
      }]
    }, tooltips: {
      mode: 'label'
    }, layout: {
      padding: {
        left: 0
      }, margin: {left: 0}
    }
  };
  // lineChart
  public lineChartData: Array<any>;
  public lineChartLabels: Array<any>;
  public lineChartType = 'line';
  public term;
  public result;
  public profileable = 'all';
  public placeholder;
  public profileable_id;

  public randomizeType(): void {
    this.lineChartType = this.lineChartType === 'line' ? 'bar' : 'line';
  }

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  change_duration(type, from_type?) {
    if (from_type && this.selected_type == type) {
      return;
    }
    this.selected_type = type;
    if (this.userService.current_user.isEnterprise) {
      this.getAppointmentsData()
    }
    else {
      this._dashboard_service.appointment_graph_data({months: this.duration, type: this.selected_type}).then(data => {
        this.data_available = false;
        this.lineChartData = data.data;
        this.lineChartLabels = data.keys;
        setTimeout(() => {
          this.data_available = true;
        }, 200);
      });
    }
  }

  constructor(private _dashboard_service: DashboardsService, public userService: UserService, private route: ActivatedRoute,
              public loaderService: LoaderService, ) {
    this.result = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.term);
    }).debounceTime(300).distinctUntilChanged().switchMap((token: string) => this._dashboard_service.getDoctorOrPractice(this.term, this.profileable));
  }

  change_search_criteria(value) {
    this.profileable = value;
    this.term = null;
    this.placeholder = 'Search ' + this.profileable.charAt(0).toUpperCase() + this.profileable.slice(1);
    if (this.profileable == 'all') {
      this.getAppointmentsData();
    }
  }

  typeaheadOnSelect(event) {
    this.profileable_id = event.item.id;
    this.getAppointmentsData();
  }

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      if ( params['refresh'] ) {
        location.replace(location.pathname);
      }
    });
    setTimeout(() => {
      if (this.userService.current_user && this.userService.current_user.isEnterprise) {
        this.getEnterpriseDashBoardData();
        this.getAppointmentsData()
      }
      else {
        this.getPracticeDashBoardData();
      }
    }, 500)
  }

  getAppointmentsData() {
    this._dashboard_service.enterprise_appointments_data({months: this.duration, type: this.selected_type, profileable: this.profileable, profileable_id: this.profileable_id}).then(data => {
      this.data_available = false;
      this.lineChartData = [{data: data.graph_data.data,  fill: false}];
      this.lineChartLabels =  data.graph_data.keys;
      setTimeout(() => {
        this.data_available = true;
      }, 200);
    });
  }
  getEnterpriseDashBoardData() {
    this._dashboard_service.enterprise_dashboard_data().then(data => {
      this.top_data = data;
    });
  }
  getPracticeDashBoardData() {
    this._dashboard_service.practice_dashboard_data().then(data => {
      this.top_data = data;
      this.lineChartData = this.top_data.graph_data.data;
      this.lineChartLabels =  this.top_data.graph_data.keys;
      this.data_available = true;
    });
  }
  handle_image_upload(data) {
    if (data && data.hasOwnProperty('error') === false) {
      this.top_data.user.profile_pic =  data.profile_pic;
      this.userService.current_user.profile_pic =  data.profile_pic;
    }
  }
  choose_file() {
    this.show_attachment_popup = true;
    setTimeout( () => {
      this.show_attachment_popup = false;
    }, 75); }

}
