export class Alert {
  alert_type: any;
  category: any;
  created_at: any;
  icon_class: any;
  id: number;
  noti_text: any;
  notifier_id: number;
  notifier_type: string;
  practice_id: number;
  source_id: number;
  source_type: string;
  status: string;
  updated_at: any;
  user_id: number;
  interventions: any[] = [];
  color_code: any;
  guidelines: any;
  patient_id: number;

  load_from_json(json) {
    this.alert_type = json.alert_type;
    this.category = json.category;
    this.created_at = json.created_at;
    this.icon_class = json.icon_class;
    this.id = json.id;
    this.noti_text = json.noti_text;
    this.notifier_id = json.notifier_id;
    this.notifier_type = json.notifier_type;
    this.practice_id = json.practice_id;
    this.source_id = json.source_id;
    this.source_type = json.source_type;
    this.status = json.status;
    this.updated_at = json.updated_at;
    this.user_id = json.user_id;
    this.interventions = json.interventions;
    this.color_code = json.color;
    this.guidelines = json.guidelines;
    this.patient_id = json.patient_id;
    return this
  }
}
