import { Injectable }     from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {CustomHttpClient} from './custom-http-client.service';
import {Observable} from 'rxjs';

@Injectable()
export class ValidationService {
  constructor(private _tokenService: CustomHttpClient) { }

  change_status(doctor) {
    let args = new HttpParams();
    args = args.set('id', doctor.id.toString());
    args = args.set('status', doctor.is_active);
    return this._tokenService.get('doctors/change_status', {params: args})
      .toPromise()
      .then(response => response)
  }

  validate_duplication(validation_variable, value) {
    let args = new HttpParams();
    args = args.set('validation_variable', validation_variable);
    args = args.set('value', value);
    return this._tokenService.get('doctors/validate_duplication', {params: args})
      .toPromise()
      .then(response => response)
  }
}
