import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramRoutingModule } from './program-routing.module';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {FormsModule} from '@angular/forms';
import { ProgramDashboardComponent } from './program-dashboard/program-dashboard.component';
import { ProgramsComponent } from './programs/programs.component';
import { EligiblePatientsComponent } from './eligible-patients/eligible-patients.component';
import {TabsModule} from '../tabs/tabs.module';
import { ProgramsListingComponent } from './programs/programs-listing/programs-listing.component';
import {SharedModule} from '../shared/shared.module';
import { ProgramComponent } from './program/program.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {RoutesModule} from '../routes/routes.module';
import { ProgramEnrolmentComponent } from './program-enrolment/program-enrolment.component';
import { ProgramEnrolmentHeaderComponent } from './program-enrolment/program-enrolment-header/program-enrolment-header.component';
import { ProgramWizardComponent } from './program-enrolment/program-wizard/program-wizard.component';
import { ProgramEnrolmentRegistrationComponent } from './program-enrolment/program-enrolment-registration/program-enrolment-registration.component';
import { EnrolmentPatientConsentComponent } from './program-enrolment/enrolment-patient-consent/enrolment-patient-consent.component';
import { EnrolmentPatientCarePlanComponent } from './program-enrolment/enrolment-patient-care-plan/enrolment-patient-care-plan.component';
import { EnrolmentPatientConfirmationComponent } from './program-enrolment/enrolment-patient-confirmation/enrolment-patient-confirmation.component';
import { ProgramEnrolledDetailComponent } from './program-enrolled-detail/program-enrolled-detail.component';
import { ProgramEnrolledHeaderComponent } from './program-enrolled-detail/program-enrolled-header/program-enrolled-header.component';
import { ProgramEnrolledPatientsComponent } from './program-enrolled-detail/program-enrolled-patients/program-enrolled-patients.component';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import {EnoteModule} from '../enote/enote.module';
import {NgxMaskModule} from 'ngx-mask';
import {HealthModule} from '../health/health.module';
import {ChartsModule} from 'ng2-charts';
import { EnrolledPatientComponent } from './enrolled-patient/enrolled-patient.component';
import { EnrolledPatientProfileComponent } from './enrolled-patient/enrolled-patient-profile/enrolled-patient-profile.component';
import { EnrolledPatientCarePlanComponent } from './enrolled-patient/enrolled-patient-care-plan/enrolled-patient-care-plan.component';
import { EnrolledPatientVitalsComponent } from './enrolled-patient/enrolled-patient-vitals/enrolled-patient-vitals.component';
import { EnrolledPatientCareManagementComponent } from './enrolled-patient/enrolled-patient-care-management/enrolled-patient-care-management.component';
import { EnrolledPatientRightPanelComponent } from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-right-panel.component';
import { EnrolledPatientPlanComponent } from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-plan/enrolled-patient-plan.component';
import { EnrolledPatientHeaderComponent } from './enrolled-patient/enrolled-patient-header/enrolled-patient-header.component';
import { EnrolledPatientGoalsComponent } from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-plan/enrolled-patient-goals/enrolled-patient-goals.component';
import { EnrolledPatientTaskComponent } from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-plan/enrolled-patient-task/enrolled-patient-task.component';
import { EnrolledPatientAlertsComponent } from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-alerts/enrolled-patient-alerts.component';
import { EnrolledPatientChatComponent } from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-chat/enrolled-patient-chat.component';
import { PatientProgramComponent } from './patient-program/patient-program.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PatientCommunicationComponent} from '../shared/patient-communication/patient-communication.component';
import { GoalDetailsComponent } from './goal-details/goal-details.component';
import {TasksModule} from '../routes/practice-site/tasks/tasks/tasks.module';
import {EditorModule} from '@tinymce/tinymce-angular';
import { BillsComponent } from './bills/bills.component';
import { PrintBillComponent } from './bills/print-bill/print-bill.component';
import {ProgramEnrollment} from './eligible-patient';
import { PatientDiagnosisComponent } from './patient-diagnosis/patient-diagnosis.component';
import { EnrolledPatientConsentComponent } from './enrolled-patient/enrolled-patient-consent/enrolled-patient-consent.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { VitalsDailyViewComponent } from './enrolled-patient/enrolled-patient-vitals/vitals-daily-view/vitals-daily-view.component';
import { VitalsFlowsheetViewComponent } from './enrolled-patient/enrolled-patient-vitals/vitals-flowsheet-view/vitals-flowsheet-view.component';
import { VitalsGraphViewComponent } from './enrolled-patient/enrolled-patient-vitals/vitals-graph-view/vitals-graph-view.component';
import { VitalsReportViewComponent } from './enrolled-patient/enrolled-patient-vitals/vitals-report-view/vitals-report-view.component';
import { PdfViewerModule} from 'ng2-pdf-viewer';
import { EnrolledPatientSummaryComponent } from './enrolled-patient/enrolled-patient-summary/enrolled-patient-summary.component';
import { ReportSelectionComponent } from './bills/print-bill/report-selection/report-selection.component';
import { VitalsReportTableComponent } from './bills/print-bill/report-selection/vitals-report-table/vitals-report-table.component';
import { CareManagementTableComponent } from './bills/print-bill/report-selection/care-management-table/care-management-table.component';
import { PrintTemplateContentComponent } from './bills/print-bill/report-selection/print-template-content/print-template-content.component';
import { AllergiesTableComponent } from './bills/print-bill/report-selection/allergies-table/allergies-table.component';
import { DiagnosisTableComponent } from './bills/print-bill/report-selection/diagnosis-table/diagnosis-table.component';
import { MedicationsTableComponent } from './bills/print-bill/report-selection/medications-table/medications-table.component';
import { PrintReportSectionComponent } from './bills/print-bill/print-report-section/print-report-section.component';
import { EnrolledPatientSmsComponent } from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-sms/enrolled-patient-sms.component';
import { ProgramConsentsComponent } from './program/program-consents/program-consents.component';
import { EnrollmentConsentComponent } from './program-enrolment/enrolment-patient-consent/enrollment-consent/enrollment-consent.component';
import { PrivateProgramsComponent } from './private-programs/private-programs.component';
import { PrivateProgramBillsComponent } from './private-program-bills/private-program-bills.component';



@NgModule({
  declarations: [ProgramDashboardComponent, ProgramsComponent, EligiblePatientsComponent, ProgramsListingComponent,
    ProgramComponent, ProgramEnrolmentComponent, ProgramEnrolmentHeaderComponent, ProgramWizardComponent,
    ProgramEnrolmentRegistrationComponent, EnrolmentPatientConsentComponent,
    EnrolmentPatientCarePlanComponent, EnrolmentPatientConfirmationComponent, ProgramEnrolledDetailComponent,
    ProgramEnrolledHeaderComponent, ProgramEnrolledPatientsComponent, EnrolledPatientComponent,
    EnrolledPatientProfileComponent, EnrolledPatientCarePlanComponent, EnrolledPatientVitalsComponent,
    EnrolledPatientCareManagementComponent, EnrolledPatientRightPanelComponent, EnrolledPatientPlanComponent,
    EnrolledPatientHeaderComponent, EnrolledPatientGoalsComponent, EnrolledPatientTaskComponent,
    EnrolledPatientAlertsComponent, EnrolledPatientChatComponent, PatientProgramComponent, GoalDetailsComponent,
    BillsComponent, PrintBillComponent, PatientDiagnosisComponent, EnrolledPatientConsentComponent,
    VitalsDailyViewComponent, VitalsFlowsheetViewComponent, VitalsGraphViewComponent, VitalsReportViewComponent, EnrolledPatientSummaryComponent, ReportSelectionComponent, VitalsReportTableComponent, CareManagementTableComponent, PrintTemplateContentComponent, AllergiesTableComponent, DiagnosisTableComponent, MedicationsTableComponent, PrintReportSectionComponent, EnrolledPatientSmsComponent, ProgramConsentsComponent, EnrollmentConsentComponent, PrivateProgramsComponent, PrivateProgramBillsComponent],
  imports: [
    CommonModule, TabsModule, SharedModule, NgSelectModule, PdfViewerModule,
    ProgramRoutingModule, PaginationModule.forRoot(), FormsModule, MultiselectDropdownModule,
    RoutesModule, RoundProgressModule, EnoteModule, NgxMaskModule, HealthModule, ChartsModule, BsDatepickerModule,
    TasksModule, EditorModule, CollapseModule, NgbTooltipModule
  ],
  providers: [ProgramEnrollment, ProgramEnrolmentComponent, ProgramWizardComponent, EnrolledPatientComponent, GoalDetailsComponent]
})
export class ProgramModule { }
