<div class="flex-body scroll-browser hor-scroll-browser">
  <table class="table table-hover table-bordered m0" *ngIf="vital_data && vital_data['dates'].length > 0">
    <thead>
    <tr>
      <th style="width:230px;min-width:230px;max-width:230px;"></th>
      <ng-container *ngFor="let date of vital_data['dates']">
        <th class="text-center">{{date}}</th>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let vital of vital_data['vitals']">
      <td class="fw-700">{{vitalService.vital_mapper_with_unit[vital]}}</td>
      <ng-container *ngFor="let date of vital_data['dates']">
        <td class="text-center">
          <ng-container *ngFor="let entry of vital_data[date][vital]; let i=index">
              <span *ngIf="entry['value']" [ngClass]="entry['descriptor_class']"
                    [tooltip]="(entry['descriptor'] ? entry['descriptor'] : '') + (entry['vital']['min_val'] ? ', Range ' + entry['vital']['min_val'] : '') + '-' + (entry['vital']['max_val'] ? entry['vital']['max_val'] : '')">
              {{showValueOfVital(vital, entry['value'])}}  <span *ngIf="i < vital_data[date][vital].length - 1 && entry['value']" >,</span>
            </span>
          </ng-container>
        </td>
      </ng-container>
    </tr>
    </tbody>
  </table>
  <app-no-result-found *ngIf="vital_data && vital_data['dates'] && vital_data['dates'].length == 0"></app-no-result-found>
</div>
