import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Patient} from '../../doctor/patient';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-patient-setting',
  templateUrl: './patient-setting.component.html',
  styleUrls: ['./patient-setting.component.scss']
})
export class PatientSettingComponent implements OnInit {
  @Input() patient: Patient;
  @Output() showPatientSettings: EventEmitter<boolean> = new EventEmitter<boolean>();
  public stage_ids: number[] = [0, 1, 2];
  public selected_stage_id: number;
  public menu_items: Object = {0: 'Vital Ranges', 1: 'Alerts', 2: 'Devices'};


  constructor(public userService: UserService) {
    this.selected_stage_id = 0;
  }

  ngOnInit(): void {}

  onSelect(stage_id: number) {
    this.selected_stage_id = stage_id;
  }

}
