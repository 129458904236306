import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-device-training',
  templateUrl: './device-training.component.html',
  styleUrls: ['./device-training.component.scss']
})
export class DeviceTrainingComponent implements OnInit {
  @Input() isTrainingDevice;
  @Input() patient;
  constructor() { }

  ngOnInit(): void {
  }

}
