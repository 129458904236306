import {Component, Input, OnInit} from '@angular/core';
import {Term} from '../../../../../enote/enote_models/term';

@Component({
  selector: 'app-diagnosis-table',
  templateUrl: './diagnosis-table.component.html',
  styleUrls: ['./diagnosis-table.component.scss']
})
export class DiagnosisTableComponent implements OnInit {
  @Input() diagnosis: Term[];
  constructor() {
  }
  ngOnInit(): void {
    if (this.diagnosis) {
      this.diagnosis = this.diagnosis.map(term => new Term('', '').load_from_json2(term));
    }
  }

}
