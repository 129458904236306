<ng-container *ngIf="all_permissions">
  <table *ngIf="show_dynamic_permissions" class="table table-bordered table-hover">
    <thead>
    <tr>
      <td>
      </td>
      <td *ngFor="let action of child_names()">
        <label>{{action}}</label>
      </td>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let key of keys">
      <tr>
        <td colspan="9" style="text-align: left !important;">
              <span _ngcontent-dui-79="" class="checkbox-permission c-checkbox">

              <label _ngcontent-dui-79="">
                <input type="checkbox" [value]="key" #someVar
                       (change)="subCategorySelections($event,key)" [attr.grand_parent]="key"
                       [checked]="isValidForSelection(key)"
                       [disabled]="keyDisabled(key)">
                <span class="fa fa-check"></span>
              </label>
                 </span>
          <label>{{key}}</label>
        </td>
      </tr>
      <ng-container *ngFor="let p of role_type_permissions[key]">
        <tr *ngIf="p.name != 'Dashboard'">
          <td *ngIf="!p.parent_id" style="padding-left: 70px !important;text-align: left !important;">
            <span class="">{{p.name}}</span>
          </td>
          <ng-container *ngIf="!p.parent_id">
            <td *ngFor="let top_name of child_names()">
                <span *ngIf="get_childs_for(p.id,top_name)" _ngcontent-dui-79="" class="checkbox-permission c-checkbox">
                  <label _ngcontent-dui-79="">
                    <input *ngIf="permissionAllowedInPackage(get_childs_for(p.id,top_name).id)"_ngcontent-dui-79="" type="checkbox"
                           [(ngModel)]="get_childs_for(p.id,top_name).is_checked"
                           name="get_childs_for(p.id,top_name).name"
                           [value]="get_childs_for(p.id,top_name).id"
                           (change)="GrandCategoryUncheck($event,key)"/>
                    <input *ngIf="!permissionAllowedInPackage(get_childs_for(p.id,top_name).id)" type="checkbox"
                           [name]="'check_'+ get_childs_for(p.id,top_name).id"
                           [(ngModel)]="per_false"
                           [value]="per_false"
                           (click)="$event.preventDefault();"
                           (change)="showPermissionAlert($event)"
                           [tooltip]="'Please upgrade your account or email at info@wellwink.com to subscribe for additional features.'"/>
                    <span _ngcontent-dui-79="" class="fa fa-check bbbc"></span>
                  </label>
                </span>

              <span *ngIf="!get_childs_for(p.id,top_name)" _ngcontent-dui-80=""
                    class="checkbox-permission c-checkbox">
                  <label _ngcontent-dui-80="">
                  </label>
                </span>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </ng-container>
    </tbody>
  </table>
  <div class="text-center" *userCan="'edit,access control,settings'">
    <button [disabled]="isValid" (click)="save(role_id,permissionable_id)" class="btn btn-primary" type="button">
      Submit
    </button>
    <div *ngIf="messageAfterSave" class="alert alert-success p mt">Your changes has been saved.</div>
  </div>
</ng-container>
<style>
  .table > tbody > tr > td{ text-align:center;}
  .table > thead > td{ text-align:center;}
  .c-checkbox span{ margin-left:0!important;}
</style>
