<app-header class="topnavbar-wrapper"></app-header>
<app-sidebar class="aside"></app-sidebar>
<div class="flex-container content-layout">
  <router-outlet></router-outlet>
</div>
<!--<div class="wrapper" [class.inner_fullscreen]="document_service.is_fullscreen">-->
    <!--&lt;!&ndash; Main section&ndash;&gt;-->
    <!--<section class="main-section clearfix">-->
      <!--<app-sub-header class="sub-header hidden"></app-sub-header>-->
      <!---->
    <!--</section>-->
<!--</div>-->
