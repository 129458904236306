<ul class="flex-header graph-vitals-name list-unstyled m0">
  <li  [class.active]="type === 'bp'"><a (click)="getChartData('bp')">Blood Pressure</a></li>
  <li [class.active]="type === 'spo2'"><a (click)="getChartData('spo2')">Pulse Oxygen</a></li>
  <li [class.active]="type === 'blood_glucose'">
    <a (click)="getChartData('blood_glucose')">Blood Glucose</a>
  </li>
  <li [class.active]="type === 'wt'">
    <a (click)="getChartData('wt')">Weight</a>
    <ng-container *ngIf="type === 'wt'">
      <label class="fw-400">Kg
        <input type="radio" class="ml-sm" name="unit" [value]="'kg'" [(ngModel)]="wtUnit" (change)="convertWtUnit('lbs')"/>
      </label>
      <label class=" fw-400">lbs
        <input type="radio" class="ml-sm" name="unit" [value]="'lbs'" [(ngModel)]="wtUnit" (change)="convertWtUnit('kg')"/>
      </label>
    </ng-container>
  </li>
  <li [class.active]="type === 'temp'">
    <a (click)="getChartData('temp')">Temperature</a>
    <ng-container *ngIf="type === 'temp'">
      <label class="fw-400">C
        <input type="radio" class="ml-sm" name="temp_unit" [value]="'C'" [(ngModel)]="tempUnit" (change)="convertTemptUnit('F')"/>
      </label>
      <label class="fw-400">F
        <input type="radio" class="ml-sm" name="temp_unit" [value]="'F'" [(ngModel)]="tempUnit" (change)="convertTemptUnit('C')"/>
      </label>
    </ng-container>
  </li>
</ul>
<canvas class="flex-body" baseChart *ngIf="data_available"
        [chartType]="'line'" [legend]="true" [datasets]="lineChartData" [labels]="lineChartLabels">
</canvas>
