import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {Practice} from '../practices/practice';
import {PracticeSetting} from './practice_setting';
import {PracticesService} from '../../../services/practices.service';
import {NgForm} from '@angular/forms';
import {CustomValidations} from '../../customValidations/custom-validations';
import {LoaderService} from "../../../services/loader.service";

@Component({
  selector: 'app-practice-settings',
  templateUrl: './practice-settings.component.html',
  styleUrls: ['./practice-settings.component.scss']
})
export class PracticeSettingsComponent implements OnInit {
  @Input() practice: Practice;
  @Input() practice_permissions: string;
  public practice_settings: PracticeSetting[] = [];
  public customValidations: CustomValidations;
  public message: string;
  public show_office_loader: boolean = false;
  public show_virtual_loader: boolean = false;

  @ViewChild('practiceSettingForm') practiceSettingForm: NgForm;

  constructor(private practicesService: PracticesService, public loaderService: LoaderService) {
    this.customValidations = new CustomValidations();
  }

  ngOnInit() {
    this.practicesService.getPracticeSetting(this.practice.id).then(resp => {
      if (resp.length > 0) {
        this.practice_settings = resp.map(setting => new PracticeSetting().load_from_json(setting));
      }
      else {
        ['office', 'virtual'].forEach(setting_type => {
          let practice_setting = new PracticeSetting();
        practice_setting.settingsable_id = this.practice.id;
        practice_setting.settingsable_type = 'Practice';
        practice_setting.setting_type = setting_type;
        this.practice_settings.push(practice_setting);
        })
      }
    });

  }

  save(practice_setting) {
    if (practice_setting.id) {
      this.toger_loader_button(practice_setting.setting_type);
      this.practicesService.update_practice_setting(practice_setting).then(resp => {
          this.practice_settings = resp.map(setting => new PracticeSetting().load_from_json(setting));
          this.message = 'Settings successfully updated.';
          this.toger_loader_button(practice_setting.setting_type);
        setTimeout(() => {
            this.message = null;
          }, 5000)
        }
      ).catch(resp => {
        this.toger_loader_button(practice_setting.setting_type);
        console.log(resp)
      });
    }
    else {
      this.toger_loader_button(practice_setting.setting_type);
      this.practicesService.save_practice_setting(practice_setting).then(resp => {
          practice_setting = practice_setting.load_from_json(resp);
          this.toger_loader_button(practice_setting.setting_type);
        }
      ).catch(resp => {
        this.toger_loader_button(practice_setting.setting_type);
        console.log(resp);
      });
    }
  }
  toger_loader_button(setting_type) : boolean{
    return setting_type === 'office' ? this.show_office_loader = !this.show_office_loader : this.show_virtual_loader = !this.show_virtual_loader;
  }
}
