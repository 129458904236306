import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Patient} from '../../doctor/patient';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-new-patient',
  templateUrl: './add-new-patient.component.html',
  styleUrls: ['./add-new-patient.component.scss']
})
export class AddNewPatientComponent implements OnInit {
  public patient: Patient = new Patient();
  public show_attachment_popup = false;
  public show_patient_form = true;
  @Output() new_user: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModel: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  handle_user(new_patient) {
    this.new_user.emit(new_patient);
  }

  handle_image_upload(data) {
    if (data) {
      this.patient.user.profile_pic = data.profile_pic;
      this.patient.user.profile_pic_id = data.id;
    }
    this.show_attachment_popup = false;
  }

  close_modal() {
   this.closeModel.emit(true);
   setTimeout(() => {
      this.show_patient_form = false;
      this.patient = new Patient();
      setTimeout(() => {
        this.show_patient_form = true;
      }, 200);
    }, 200);
  }

}
