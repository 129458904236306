<div class="flex-control flex-column flex-height">
  <div class="flex-header text-center">
    <button class="btn btn-secondary btn-min mr-sm" (click)="generatePdf('print')">Print</button>
    <button class="btn btn-tertiary btn-outline btn-min" (click)="generatePdf('save')">Download</button>
  </div>
  <div class="flex-body scroll-browser" *ngIf="payment_data">
    <div #statement *ngIf="printType === 'Statement'; else receiptLabel" class="main-wrapper" style="max-width:8.5in; margin:auto;">
      <div class="text-center mb-sm">
        <h3 class="mt0">{{payment_data.practice_name}}</h3>
        <span>{{payment_data.practice_address}}</span><br>
        <span>{{payment_data.practice_phone}}</span>
      </div>
      <table class="table m0">
        <tbody>
        <tr>
          <td style="width:50%; vertical-align:top;">
            <div class="row mb-lg">
              <label class="col-xs-4">Bill To:</label>
              <div class="col-xs-8">
                <h4 class="mt0 mb-sm">{{payment_data.patient_name}}</h4>
                <span>{{payment_data.patient_address}}</span>
                <br>
                <span>{{payment_data.patient_phone}}</span>
              </div>
            </div>
            <div class="row">
              <label class="col-xs-4">MRN:</label>
              <div class="col-xs-8">
                <span>{{payment_data.patient_mrn}}</span>
              </div>
            </div>
          </td>
          <td style="width:50%">
            <div class="row mb-sm">
              <label class="col-xs-4">Date:</label>
              <div class="col-xs-8"><span>{{today}}</span></div>
            </div>
            <div class="row mb-sm">
              <label class="col-xs-4">Statement#:</label>
              <div class="col-xs-8"><span>{{payment_data.bill_number}}</span></div>
            </div>
            <div class="row mb-sm">
              <label class="col-xs-4">Doctor:</label>
              <div class="col-xs-8"><h4 class="m0">{{payment_data.doctor_name}}</h4></div>
            </div>
            <div class="row mb-sm">
              <label class="col-xs-4">Reason:</label>
              <div class="col-xs-8"><span>{{payment_data.reason_of_visit}}</span></div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <table class="table table-bordered table-striped m0">
        <thead>
        <tr>
          <th style="width:100px;">Date</th>
          <th>Description</th>
          <th class="text-right">Amount</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let payment of payment_data.payments_and_charges">
          <tr>
            <td>{{payment_date(payment.created_at)}}</td>
            <td>{{payment.description}}</td>
            <td class="text-right">{{(payment.payment_type_id == 1 || payment.amount == 0) ? '': '-'}}{{payment.amount | number}}</td>
          </tr>
        </ng-container>
        <tr>
          <td>&nbsp;</td>
          <td><h4 class="mb0 fw-500 pull-right">Total</h4></td>
          <td class="bg-gray-light text-right">
            <label>{{payment_data.total_amount_without_discount | number}}</label>
          </td>
        </tr>
        </tbody>
      </table>
      <table class="table table-bordered m0">
        <tbody>
        <tr>
          <td class="b0"><span>Thank you and visit us again.</span></td>
          <td class="b0">
            <span>Discount</span>
            <h4 class="mb0 fw-500">US$.{{payment_data.discount_amount | number}}</h4>
          </td>
          <td class="b0 text-right" style="min-width:113px;">
            <span>Total Payable</span>
            <h4 class="mb0 fw-500">US$.{{payment_data.total_payable | number}}</h4>
          </td>
        </tr>
        <tr>
          <td class="b0">
            <span>Payment Mode</span>
            <h4 class="mb0 fw-500">{{payment_data.payment_mode}}</h4>
          </td>
          <td class="b0 text-right">
            <span>Total Outstanding</span>
            <h4 class="mb0 fw-500">US$.{{payment_data.total_outstanding | number}}</h4>
          </td>
          <td class="b0">&nbsp;</td>
        </tr>
        <tr>
          <td class="b0">
            <span>Referral Doctor</span>
          </td>
          <td class="b0 text-right">
            <span>{{payment_data.referring_doctor_name}}</span>
          </td>
          <td class="b0">&nbsp;</td>
        </tr>
        </tbody>
      </table>
    </div>
    <ng-template #receiptLabel>
      <div #receipt class="main-wrapper" style="max-width:8.5in; margin:auto;">
        <div class="text-center mb-sm">
          <h3 class="mt0">{{payment_data.practice_name}}</h3>
          <span>{{payment_data.practice_address}}</span><br>
          <span>{{payment_data.practice_phone}}</span>
        </div>
        <table class="table m0">
          <tbody>
          <tr>
            <td style="width:50%; vertical-align:top;">
              <div class="row mb-lg">
                <label class="col-xs-4">Patient:</label>
                <div class="col-xs-8">
                  <h4 class="mt0 mb-sm">{{payment_data.patient_name}}</h4>
                  <span>{{payment_data.patient_address}}</span>
                  <br>
                  <span>{{payment_data.patient_phone}}</span>
                </div>
              </div>
              <div class="row">
                <label class="col-xs-4">MRN:</label>
                <div class="col-xs-8">
                  <span>{{payment_data.patient_mrn}}</span>
                </div>
              </div>
            </td>
            <td style="width:50%">
              <div class="row mb-sm">
                <label class="col-xs-4">Date:</label>
                <div class="col-xs-8"><span>{{today}}</span></div>
              </div>
              <div class="row mb-sm">
                <label class="col-xs-4">Receipt#:</label>
                <div class="col-xs-8"><span>{{payment_data.receipt_number}}</span></div>
              </div>
              <div class="row mb-sm">
                <label class="col-xs-4">Doctor:</label>
                <div class="col-xs-8"><h4 class="m0">{{payment_data.doctor_name}}</h4></div>
              </div>
              <div class="row mb-sm">
                <label class="col-xs-4">Reason:</label>
                <div class="col-xs-8"><span>{{payment_data.reason_of_visit}}</span></div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered table-striped m0">
          <thead>
          <tr>
            <th style="width:100px;">Date</th>
            <th>Description</th>
            <th class="text-right">Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let service_payment of payment_data.service_payments">
            <td>{{payment_date(service_payment.created_at)}}</td>
            <td>{{service_payment.description}}</td>
            <td class="text-right">{{service_payment.amount | number}}</td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered m0">
          <tbody>
          <tr>
            <td class="b0"><span>Thank you and visit us again.</span></td>
            <td class="b0">
              <span>Discount</span>
              <h4 class="mb0 fw-500">US$.{{payment_data.discount_amount | number}}</h4>
            </td>
            <td class="b0 text-right" style="min-width:113px;">
              <span>Total Payable</span>
              <h4 class="mb0 fw-500">US$.{{payment_data.total_payable | number}}</h4>
            </td>
          </tr>
          <tr>
            <td class="b0">
              <span>Payment Mode</span>
              <h4 class="mb0 fw-500">{{payment_data.payment_mode}}</h4>
            </td>
            <td class="b0 text-right">
              <span>Total Paid</span>
              <h4 class="mb0 fw-500">US$.{{payment_data.total_paid | number}}</h4>
            </td>
            <td class="b0">&nbsp;</td>
          </tr>
          <tr>
            <td class="b0">
              <span>Referral Doctor</span>
            </td>
            <td class="b0 text-right">
              <span>{{payment_data.referring_doctor_name}}</span>
            </td>
            <td class="b0">&nbsp;</td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </div>
</div>
