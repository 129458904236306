import { Injectable }     from '@angular/core';
import {  HttpParams } from '@angular/common/http';
import {CustomHttpClient} from './custom-http-client.service';
import {Ledger} from '../routes/practice-site/payment/payment';



@Injectable()
export class PaymentService {
  public selected_patient: number;
  constructor(private _tokenService: CustomHttpClient) {}
  getPrintPaymentDate(appointment_id: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('appointment_id', appointment_id);
    return this._tokenService
      .get('payments/print_data', { params: args})
      .toPromise()
      .then(res => res)
  }
  getPayment(appointment_id: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('appointment_id', appointment_id);
    return this._tokenService
      .get('payments/' + appointment_id, { params: args})
      .toPromise()
      .then(res => res)
  }
  getPaymentTypes(): Promise<any> {
    return this._tokenService
      .get('payments/payment_types')
      .toPromise()
      .then(res => res)
  }
  create(ledger: Ledger): Promise<any> {
    return this._tokenService
      .post(ledger.get_url(), ledger.to_params())
      .toPromise()
      .then(res => res)
  }

  update(ledger: Ledger): Promise<any> {
    return this._tokenService
      .put(ledger.get_url(), ledger.to_params())
      .toPromise()
      .then(res => res)
  }

  save(ledger: Ledger): Promise<any> {
    if (ledger.is_new_record()) {
      return this.create(ledger);
    }
    else {
      return this.update(ledger);
    }
  }
  delete(payment_id: any): Promise<any> {
    return this._tokenService.delete('payments?id=' + payment_id)
      .toPromise()
      .then(res => res);
  }
}
