<div class="filters-sec flex-header">
  <div class="row">
    <div class="col-xs-3">
      <input class="form-control" [(ngModel)]="filter['name']" (keyup.enter)="search()" placeholder="Name">
    </div>
    <div class="col-xs-2">
      <select [(ngModel)]="filter['status']" class="form-control">
        <option value=undefined selected>Status</option>
        <option value=''>All</option>
        <option value='opened'>Open</option>
        <option value='closed'>Closed</option>
      </select>
    </div>
    <div class="col-md-7 col-xs-5">
      <button type="button" class="btn btn-tertiary btn-outline btm-min" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      <button type="button" class="btn btn-secondary btn-min ml pull-right" (click)="addProgram()" >
        <i class="fa fa-plus mr-sm"></i> Add
      </button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Name</th>
      <th>Billing Provider</th>
      <th>Rendering Provider</th>
      <th>Care Team</th>
      <ng-container *ngIf="programme_category === 'private_programme'">
        <th>Package</th>
        <th>Fee</th>
      </ng-container>
      <th class="text-center">Patients</th>
      <th>Status</th>
      <th style="width:106px; min-width:110px;">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let program of programs">
      <td><a (click)="viewProgram(program)">{{program.name}}</a></td>
      <td>{{program.billing_provider_name}}</td>
      <td>{{program.care_manager_name}}</td>
      <td class="text-capitalize" *ngIf="program.care_team.length == 1">{{program.care_team}}</td>
      <td class="text-capitalize" *ngIf="program.care_team.length > 1">
          <span *ngFor="let name of program.care_team; let i = index;">
            {{name}}<span class="mr" *ngIf="i != program.care_team.length - 1">,</span>
          </span>
      </td>
      <ng-container  *ngIf="programme_category === 'private_programme'">
        <td>{{program.private_package_name}}</td>
        <td>${{program.private_pay_amount}}</td>
      </ng-container>
      <td class="text-center">{{program.patient_count}}</td>
      <td>
        <select #status [(ngModel)]="program.status" (change)="changeStatusOfProgram(program)" name="program_status" [ngClass]="program.status === 'opened' ? 'active' : 'inactive'" class="form-control short-select">
          <option value="opened">Opened</option>
          <option value="closed">Closed</option>
        </select>
      </td>
      <td>
        <a class="btn btn-secondary btn-outline btn-sm mr" tooltip="Edit" (click)="editProgram(program)" >
          <i class="fa fa-pencil"></i>
        </a>
        <a class="btn btn-danger btn-outline btn-sm"  tooltip="Delete" *ngIf="!program.is_deleted" (click)="selectedProgramToDelete = program;deleteModal.show();">
          <i class="fa fa-trash"></i>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
  <app-no-result-found *ngIf="programs && programs.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center" *ngIf="programs && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Delete Program</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to delete the Program?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteProgram(selectedProgramToDelete);deleteModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
