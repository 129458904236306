<div class="list pr0" *ngIf="programEnrollment && patient">
  <div class="col-sm-3 picName-area">
    <div class="cell-holder">
      <div class="pic img-holder text-center">
        <img class="img-circle thumb64" [src]="patient.patient_profile_pic" alt="Image"/>
        <div class="status-label">
          <label *ngIf="patient.mobile_online_status" [ngClass]="appointmentService.show_status_class(patient.mobile_online_status)">
            {{appointmentService.show_status_class(patient.mobile_online_status)}}
            <i class="fa icon-arrow-up"></i>
          </label>
          <div class="popover fade top">
            <div class="arrow" style="left:50%;"></div>
            <h3 class="popover-title">Login Status</h3>
            <div class="popover-content pt0">
              <label *ngIf="patient.mobile_online_status" [ngClass]="appointmentService.show_status_class(patient.mobile_online_status)">
                Mobile {{appointmentService.show_status_class(patient.mobile_online_status)}}
              </label>
              <label *ngIf="patient.portal_online_status" [ngClass]="appointmentService.show_status_class(patient.portal_online_status)">
                Portal {{appointmentService.show_status_class(patient.portal_online_status)}}
              </label>
            </div>
          </div>
        </div>
      </div>
      <h4 class="m0 mb fw-500 fs16">
        <a (click)="clickPatientName(patient)">{{patient.name}}</a>
      </h4>
      <h6 class="m0 mb">
        <i class="fa icon-user mr-sm"></i> {{patient.customize_age}}
      </h6>
      <h6 class="m0 mb call-anchor" *ngIf="patient.mobile_number_value" (click)="phoneService.initiatePhoneCall(patient.mobile_number_value, {name: patient.name, pic: patient.patient_profile_pic} ,programEnrollment.id)">
        <i class="fa icon-screen-smartphone mr-sm"></i> {{patient.mobile_number_value | mask: '(000) 000-0000'}}
      </h6>
      <h6 class="m0 call-anchor" *ngIf="patient.landline_number_value" (click)="phoneService.initiatePhoneCall(patient.landline_number_value,{name: patient.name, pic: patient.patient_profile_pic}, programEnrollment.id)">
        <i class="fa icon-phone mr-sm"></i> {{patient.landline_number_value | mask: '(000) 000-0000'}}
      </h6>
    </div>
  </div>
  <div class="col-sm-2 border-col">
    <div dropdown class="cell-holder text-center">
      <h6 class="text-uppercase text-warning m0 mb-sm fs10">Emergency Contacts</h6>
      <ng-container *ngIf="patient.contacts && patient.contacts.length > 0">
        <h6 class="fw-500 mt-sm mb-sm text-capitalize">{{patient.contacts[0].name}}</h6>
        <h6 class="m0 mb-sm fw-400 call-anchor" (click)="phoneService.initiatePhoneCall(patient.contacts[0].contact_number.value, {name: patient.name, pic: patient.patient_profile_pic} ,programEnrollment.id)">
          {{patient.contacts[0].contact_number.value | mask: '(000) 000-0000'}}
        </h6>
        <a class="fs12" dropdownToggle *ngIf="patient.contacts.length > 1"><i class="fa fa-chevron-down"></i> more</a>
        <ul class="dropdown-menu animated slideInDown scroll-browser">
          <ng-container *ngFor="let contact of patient.contacts">
            <li>
              <a href="javascript:void(0)" (click)="phoneService.initiatePhoneCall(contact.contact_number.value, {name: patient.name, pic: patient.patient_profile_pic}, programEnrollment.id)">
                <label class="field-label">{{contact.name}}</label>
                <h5 class="m0 fw-400">{{contact.contact_number.value | mask: '(000) 000-0000'}}</h5>
              </a>
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </div>
  </div>
  <div class="col-sm-2 border-col">
    <div class="cell-holder bio-sec">
      <h6 class="m0 fs13 fw-400" *ngIf="patient.weight"><label class="field-label">Weight</label> {{weightInPounds(patient.weight)}}Lb</h6>
      <h6 class="m0 fs13 fw-400" *ngIf="patient.height"><label class="field-label">Height</label> {{patient.height}}In</h6>
      <h6 class="m0 fs13 fw-400" *ngIf="patient.bmi"><label class="field-label">BMI</label> {{patient.bmi}}</h6>
    </div>
  </div>
  <div class="col-sm-3 border-col">
    <div class="cell-holder pl-sm pr-sm prob-allerg-sev-holder scroll-browser">
      <div class="info-holder" *ngIf="patient.chronic_diagnosis_name_code.length > 0">
        <label class="field-label">Problems</label>
        <ng-container *ngFor="let diagnosis of patient.chronic_diagnosis_name_code;let i=index">
          <span class="code" placement="bottom" ngbTooltip="{{diagnosis[0]}}">
            {{diagnosis[1]}}<span *ngIf="i != patient.chronic_diagnosis_name_code.length - 1">,</span>
          </span>
        </ng-container>
      </div>
      <div class="info-holder" *ngIf="patient?.allergies?.length > 0">
        <label class="field-label">Allergies</label>
        <ng-container *ngFor="let allergy of patient.allergies;let i=index">
          <span class="fs13">
            {{allergy}}<span *ngIf="i != patient.allergies.length - 1">,</span>
          </span>
        </ng-container>
      </div>
      <div class="info-holder" *ngIf="showSeverityField()">
        <label class="field-label">Severity</label>
        <span *ngIf="!isPatientDetailPage" class="fs13">{{userService.removeUnderScore(patient.severity)}}</span>
        <span *ngIf="isPatientDetailPage" class="fs13">{{userService.removeUnderScore(programEnrollment.severity)}}</span>
      </div>
    </div>
  </div>
  <div class="col-sm-3 border-col">
    <div class="cell-holder pl-sm">
      <h6 class="m0 mb-sm fs13 fw-400"><label class="field-label mr">Provider</label> {{patient.provider_name}}</h6>
      <h6 class="m0 mb-sm fs13 fw-400">
        <label class="field-label mr">Enrolled In</label>
        <span class="text-capitalize fw-400 fs13" *ngIf="patient.enrolled_in?.length == 1">{{patient.enrolled_in}}</span>
        <span class="text-capitalize fs13 fw-400" *ngIf="patient.enrolled_in?.length > 1">
          <span *ngFor="let p of patient.enrolled_in ;let i=index" >
            <span *ngIf="isPatientDetailPage" [ngClass]="selectedClass(p)" >
              {{p}}
            </span>
            <span *ngIf="!isPatientDetailPage">
              {{p}}
            </span>
            <span *ngIf="i != patient.enrolled_in?.length - 1" class="mr"></span>
          </span>
        </span>
      </h6>

      <h6 class="m0 fs13 fw-400"><label class="field-label mr">On</label> {{patient.programme_enrolment_date}}</h6>
      <h6 class="m0 fs13 fw-400"><label class="field-label mr">Days</label> {{patient.no_of_days}}</h6>
    </div>
  </div>
  <div class="col-sm-1 border-col" style="min-width:122px;">
    <div class="cell-holder pl-sm pr-sm">
      <label *ngIf="!isPatientDetailPage" class="label text-capitalize health-status" [ngClass]="patient.health_status">
        {{userService.removeUnderScore(patient.health_status)}}
      </label>
      <label *ngIf="isPatientDetailPage" class="label text-capitalize health-status" [ngClass]="programEnrollment.health_status">
        {{userService.removeUnderScore(programEnrollment.health_status)}}
      </label>
      <label *ngIf="!isPatientDetailPage" class="label text-capitalize health-status" [ngClass]="patient.compliance_level">
        {{userService.removeUnderScore(patient.compliance_level)}}
      </label>
      <label *ngIf="isPatientDetailPage" class="label text-capitalize health-status" [ngClass]="programEnrollment.compliance_level">
        {{userService.removeUnderScore(programEnrollment.compliance_level)}}
      </label>
    </div>
  </div>
  <div class="col-sm-2 border-col">
    <div class="cell-holder">
      <ul class="icons-value anchor-list list-unstyled m0">
        <li>
          <h6 class="text-uppercase">Alerts</h6>
          <a [ngClass]="programEnrollment?.unread_alerts?.total == 0 ? 'btn btn-default' : 'btn btn-danger'">
            <i class="fa icon-bell"></i>
            <span class="badge" *ngIf="programEnrollment?.unread_alerts?.total">
              {{programEnrollment.unread_alerts.total ? programEnrollment.unread_alerts.total : '0'}}
            </span>
          </a>
        </li>
       <!-- <li>
          <h6 class="text-uppercase">Video</h6>
          <a  [ngClass]="(programEnrollment?.unread_alerts?.video_call == 0 || !programEnrollment?.unread_alerts?.video_call) ? 'btn btn-default' : 'btn btn-primary'">
            <i class="fa icon-camrecorder"></i>
            <span class="badge" *ngIf="programEnrollment?.unread_alerts?.video_call">
              {{programEnrollment.unread_alerts.video_call ? programEnrollment.unread_alerts.video_call : '0'}}
            </span>
          </a>
        </li>
        <li>
          <h6 class="text-uppercase">Phone</h6>
          <a [ngClass]="(programEnrollment?.unread_alerts?.phone_call == 0 || !programEnrollment?.unread_alerts?.phone_call) ? 'btn btn-default' : 'btn btn-tertiary'">
            <i class="fa icon-phone"></i>
            <span class="badge" *ngIf="programEnrollment?.unread_alerts?.phone_call">
              {{programEnrollment.unread_alerts.phone_call ? programEnrollment.unread_alerts.phone_call : '0'}}
            </span>
          </a>
        </li>-->
        <li>
          <h6 class="text-uppercase">Tasks</h6>
          <a [ngClass]="(dueTaskCount == 0 || !dueTaskCount) ? 'btn btn-default' : 'btn btn-tertiary'">
            <i class="fa icon-notebook"></i>
            <span class="badge" *ngIf="dueTaskCount">
              {{dueTaskCount ? dueTaskCount : '0'}}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-sm-2 border-col" style="max-width:125px;" *ngIf="isPatientDetailPage">
    <div class="cell-holder pr-sm pl-sm">
      <div class="border-box session-logged text-center">
        <h6 class="m0 fs10 text-uppercase text-tertiary">Current Session</h6>
        <h5 class="text-secondary m0 fs12">{{formatClock}}</h5>
      </div>
      <div class="border-box session-logged text-center mt">
        <h6 class="m0 fs10 text-uppercase text-tertiary">Logged Time</h6>
        <h5 class="text-secondary m0 fs12" *ngIf="programEnrollment.id">{{trackingService.getLoggedEnrollmentTime(programEnrollment.id)}} min</h5>
      </div>
    </div>
  </div>
  <div class="col-sm-1 border-col">
    <div class="cell-holder text-center">
      <h6 class="text-uppercase m0 mb-sm fs10">Billable</h6>
      <div class="waiting-counter text-center" *ngIf="programEnrollment">
        <ng-container>
          <a *ngIf="loaderService.uploading_in_process"><i class="fa fa-spinner fa-spin fs25 vl-m mr-sm"></i></a>
        </ng-container>
        <ng-container *ngIf="!loaderService.uploading_in_process">
          <div class="minutes-cont">
            <span class="counter">{{programEnrollment.time ? programEnrollment.time : '0'}}</span> Mins
          </div>
          <round-progress *ngIf="programEnrollment.time > 0" [color]="wait_class(programEnrollment.time)"
                          [background]="'#eaeaea'" [stroke]="4" [current]="programEnrollment.time" [max]="20" [radius]="30">
          </round-progress>
          <round-progress *ngIf="!programEnrollment.time" role="progressbar" aria-valuemax="60">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
              <circle fill="none" cx="125" cy="125" r="117.5" style="stroke:rgb(1 213 176); stroke-width:12;"></circle>
            </svg>
          </round-progress>
        </ng-container>
      </div>
    </div>
  </div>
</div>
