import { Injectable } from '@angular/core';
import {CustomHttpClient} from "../../services/custom-http-client.service";

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor( public _tokenService: CustomHttpClient) { }

  sendEmail(formData): Promise<any> {
    return this._tokenService
      .post('users/contact_us', formData)
      .toPromise()
      .then(res => res)
  }
}
