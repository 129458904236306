import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PatientNotes} from "../patient-notes";
import {TabsService} from "../../../../../../services/tabs.service";
import {LoaderService} from "../../../../../../services/loader.service";
import {GlobalErrorHandlerService} from "../../../../../../services/global-error.service";
import {UserService} from "../../../../../../services/user.service";
import {PatientNotesService} from "../patient-notes.service";
import {EnrolmentsService} from '../../../../../../program/enrolments.service';
import {TrackingService} from '../../../../../../services/tracking.service';
import {AlertService} from '../../../../../../_alert';

@Component({
  selector: 'app-new-patient-notes',
  templateUrl: './new-patient-notes.component.html',
  styleUrls: ['./new-patient-notes.component.scss']
})
export class NewPatientNotesComponent implements OnInit {
  public patient_note: PatientNotes = new PatientNotes();
  public add_note_type_input_show: boolean = false;
  public add_note_type_input_text: string;
  public patient_id;
  public patient_note_types = [];
  @ViewChild('deleteModal') deleteModal;
  @ViewChild('validationModel') validationModel;
  public source_id: any;
  public source_type: any;
  public programme_type: any;
  public isprogramme_profile: any;

  constructor(public patientNotesService: PatientNotesService, public injector: Injector,
              public tabs_service: TabsService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public currentUser: UserService,
              public enrolmentsService: EnrolmentsService, public trackingService: TrackingService,
              public alertService: AlertService) {
    this.patientNotesService.getPatientNoteType().then(resp => {
      this.patient_note_types = resp;
    });
  }

  ngOnInit(): void {
    this.patient_note = this.injector.get('patient_note', null);
    this.patient_id = this.injector.get('patient_id', null);
    this.source_id = this.injector.get('source_id', null);
    this.source_type = this.injector.get('source_type', null);
    this.programme_type = this.injector.get('programme_type', null);
    this.isprogramme_profile = this.injector.get('isprogramme_profile', null);
    if (this.source_type == 'ProgrammeEnrolment' && this.source_id ) {
      this.patient_note.source_type = this.source_type;
      this.patient_note.source_id = this.source_id;
    }
  }

  closeNewForm() {
    this.patientNotesService.closeNewNoteForm.next(true);
  }

  save() {
    if (this.patient_note.patient_note_type_id) {
      console.log(this.patient_note);
      this.loaderService.show();
      this.patient_note.noteable_id = this.currentUser.current_user.profileable_id;
      this.currentUser.current_user.isDoctor ? this.patient_note.noteable_type = 'Doctor' : this.patient_note.noteable_type = 'Practice';
      this.patient_note.user_id = this.currentUser.current_user.id;
      this.patient_note.patient_id = this.patient_id;
      this.patientNotesService.save(this.patient_note).then(resp => {
        this.patient_note = this.patient_note.load_from_json(resp);
        if (resp.source_type == 'ProgrammeEnrolment') {
          this.stopAction(resp);
        }
        this.loaderService.hide();
        this.closeNewForm();
        this.alertService.success('Note has been saved successfully',
          {autoClose: true, keepAfterRouteChange: true});
        this.patientNotesService.reloadNotes.next(1);
        this.enrolmentsService.reloadActivityList.next(1);
        this.enrolmentsService.reloadProgramEnrolmentTime.next(1);
        this.patientNotesService.reloadStickyNote.next(1);
      })
        .catch(resp => {
          this.loaderService.hide();
          this.globalErrorHandlerService.error = resp.error.error;
        })
    } else {
      this.validationModel.show();
    }
  }

  deleteNote(patient_note) {
    this.loaderService.show();
    this.patientNotesService.delete(patient_note.id).then(resp => {
      this.patientNotesService.reloadNotes.next(1);
      this.patientNotesService.reloadStickyNote.next(1);
      this.closeNewForm();
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.deleteModal.hide();
    })
  }

  add_note_type() {
    this.add_note_type_input_show = false;
    this.loaderService.show();
    this.patientNotesService.savePatientNoteType(this.add_note_type_input_text).then(resp => {
      this.patient_note_types.push(resp);
      this.patient_note.patient_note_type_id = resp.id;
      this.add_note_type_input_text = '';
      this.loaderService.hide();
    })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }
  stopAction(note: PatientNotes) {
    const trackingResult = this.trackingService.stopTracking(this.patient_note.temp_activity_id);
    this.trackingService.logTimeForPatientActivity({duration: trackingResult.duration, description: note.note_body,
      activity_type: trackingResult.description, source_id: note.id, activiable_id: this.source_id, programme_type: this.programme_type})
  }
}


