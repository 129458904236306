export class Note {
  id: number;
  comment: string;
  added_by: string;
  case_id: number;
  date_and_time: string;

  to_params() {
    return {
      note: {
        comment: this.comment,
        case_id: this.case_id
      }
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.added_by = json.added_by;
    this.comment = json.comment;
    this.case_id = json.case_id;
    this.date_and_time = json.date_and_time;
    return this;
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
  get_url(): string {
    return this.is_new_record() ? 'notes/' : ['notes', this.id].join('/')
  }
}
