<div class="container main-wrapper">
  <div class="row" *ngIf="showPayment">
    <div class="col-md-6 col-sm-12 patient-payment-flow" *ngIf="!paymentSuccessful">
      <app-braintree-payment *ngIf="!paymentSuccessful" (paymentSuccessful)="onPayment($event)" [appointment_id]="this.appointment.id" [appointmentPayment]="true" [patient]="user_service.current_user"></app-braintree-payment>
    </div>
    <div class="col-md-6 col-sm-12 patient-payment-flow" *ngIf="paymentSuccessful">
      <p class="text-primary">
        Payment has been processed successfully.
        Your request has been received for a virtual visit. Please stay on Patient Profile, your doctor will contact you
        shortly. DO NOT leave application.
      </p>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="white-container-box p-xl">
        <h4 class="fs22 mt0"><i class="fa fa-user-md fa-fw fs28"></i> Your Appointment With</h4>
        <hr class="hr-line mt0">
        <div class="row">
          <div class="col-sm-7 mb-lg">
            <div class="media">
              <div class="media-left" style="min-width:75px;">
                <img [src]="doctor.profile_pic.modal.img" alt="" class="img-circle thumb64 center-block">
                <rating class="small-stars-10" [ngModel]="doctor.average_rating" [max]="5" [readonly]="true"></rating>
              </div>
              <div class="media-body">
                <a class="text-primary fw-500 fs18 m0 mb-sm" [routerLink]="['/doctors',doctor.id]">{{doctor.complete_name}}</a>
                <p>{{doctor.designation}}</p>
              </div>
            </div>
            <ng-container *ngIf="doctor.location">
              <div class="row mb-lg">
                <h5 class="col-xs-3 m0">At</h5>
                <div class="col-xs-9">
                  <h5 class="m0"><i class="fa icon-location-pin"></i> {{doctor.location.practice_name}}</h5>
                  <h5><i class="fa icon-phone"></i> {{doctor.location.phone.value | mask: '(000) 000-0000'}}</h5>
                  <h5 class="mb0"><i class="fa icon-pin"></i> {{doctor.location.address.humanize()}}</h5>
                </div>
              </div>
            </ng-container>
            <div class="row mb-lg">
              <h5 class="col-xs-3 m0">On</h5>
              <div class="col-xs-9">
                <h5 class="m0 text-primary">{{(appointment.date) | date: 'MMM dd y hh:mm a'}} ({{appointment.slot_size}} mins)</h5>
              </div>
            </div>
            <div class="row mb-lg">
              <h5 class="col-xs-3 m0">For</h5>
              <div class="col-xs-9">
                <h5 class="m0">{{appointment.complete_name}}</h5>
              </div>
            </div>
            <div class="row">
              <h5 class="col-xs-3 m0 mb-sm">Reason of visit</h5>
              <div class="col-xs-9"><p class="m0">{{appointment.service_name}}</p></div>
            </div>
            <div class="row">
              <h5 class="col-xs-3 m0 mb-sm">Charges</h5>
              <div class="col-xs-9"><p class="m0">{{appointment.doctor.doctor_practice_visit_charges}}</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <ng-container *ngIf="handleUser() && user_service.current_user && user_service.current_user.profileable_type != 'Patient' ">
        <div class="white-container-box p-xl mb-lg">
          <h4>You do not have access to make an appointment from website.</h4>
        </div>
      </ng-container>
      <ng-container *ngIf="handleUser() && user_service.current_user && user_service.current_user.profileable_type == 'Patient' ">
        <ng-container *ngIf="verified_patient()">
          <form *ngIf="!showPayment" #appointmentForm="ngForm">
            <h4 class="fs28 mt font-28"><i class="fa icon-calendar fa-fw"></i> Book an Appointment</h4>
            <p class="">Enter the following information to continue</p>
            <div class="mt clearfix">
              <h5 class="fw-500">Who will be seeing the doctor?</h5>
              <div>
                <input id="default" type="radio" name="patient" value="option1" (click)="update_patient_id(0,user_service.current_user.full_name)" checked=""/>
                <label class="pl-sm" for="default">{{user_service.current_user.full_name}}</label>
              </div>
              <div *ngIf="patient_family.family_members">
                <ng-container *ngFor="let family_member of family_members()">
                  <div>
                    <input id="{{family_member.id}}" type="radio" name="patient" value="{{family_member.id}}"
                           [checked]="family_member.id == appointment_Service.selected_patient || family_member.id == appointment.patient_id"
                           (click)="update_patient_id(family_member.id,family_member.name)"/>
                    <label class="pl-sm" for="{{family_member.id}}">{{family_member.name}}</label>
                  </div>
                </ng-container>
              </div>
              <div class="mt-sm">
                <input id="new-patient" type="radio" name="patient" value="option1" (click)="add_new_relative_patient()"/>
                <label class="pl-sm text-primary fw-500" for="new-patient"><i class="fa icon-user mr-sm"></i>Add New Patient</label>
              </div>
            </div>
            <div class="mt" *ngIf="!new_relative">
              <ng-container *ngIf="selected_slot_calendar">
                <h5 class="fw-500">Booking Method</h5>
                <div class="mb">
                  <label class="border-box fw-500 mr-lg p-sm text-center" *ngIf="selected_slot_calendar.booking_type === 'sab' || selected_slot_calendar.booking_type === 'office'">
                    <input type="radio" name="office_visit" value="office_visit" [(ngModel)]="appointment.appointment_type" (change)="set_appointment_type_and_fee('office_visit')" required/>
                    Office Visit
                    <span class="h4 fw-300 m0 center-block">${{selected_slot_calendar.office_visit_fee}}</span>
                  </label>
                  <label class="border-box fw-500 mr-lg p-sm text-center" *ngIf="selected_slot_calendar.booking_type === 'sab' || selected_slot_calendar.booking_type === 'virtual'">
                    <input type="radio" name="virtual_visit" value="virtual_visit" [(ngModel)]="appointment.appointment_type" (change)="set_appointment_type_and_fee('virtual_visit')" required/>
                    Video Call
                    <span class="h4 fw-300 m0 center-block">${{selected_slot_calendar.virtual_visit_fee}}</span>
                  </label>
                  <label class="border-box fw-500 p-sm text-center" *ngIf="selected_slot_calendar.booking_type === 'sab' || selected_slot_calendar.booking_type === 'phone'">
                    <input type="radio" name="phone_visit" value="phone_visit" [(ngModel)]="appointment.appointment_type" (change)="set_appointment_type_and_fee('phone_visit')" required/>
                    Phone Call
                    <span class="h4 fw-300 m0 center-block">${{selected_slot_calendar.phone_visit_fee}}</span>
                  </label>
                </div>
              </ng-container>
              <h5 class="fw-500">Reason of Visit</h5>
              <div class="form-group">
                <input (keyup)="set_asyncSelected()" typeaheadWaitMs="500" [typeahead]="services" name="service"
                       [(ngModel)]="appointment.service_name" [typeaheadAsync]="true"
                       (typeaheadLoading)="changeTypeaheadLoading($event)"
                       (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                       (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                       [typeaheadOptionField]="'name'" placeholder="Search Reason"
                       class="form-control" type='text' autocomplete="off">
              </div>
              <h5 class="fw-500">Notes for the doctor</h5>
              <div class="form-group">
                <textarea rows="3" cols="5" type="text" class="form-control" id="notes-for-doctor"
                          [(ngModel)]="appointment.notes_for_doctor"
                          [ngModelOptions]="{standalone: true}"></textarea>
              </div>
              <div *ngIf="messageAfterSave" class="text-success">
                <strong *ngIf="appointment.appointment_type === 'virtual_visit'" class="text-success">
                  Your request has been sent to your doctor successfully. Please stay on this page and your doctor will contact you shortly. DO NOT leave application.</strong>
                <strong *ngIf="appointment.appointment_type !== 'virtual_visit'" class="text-success">{{appointment.message}}</strong>
              </div>
              <div class="mt-xl mb-xl">
                <button type="button" class="btn btn-primary fs16" (click)="book_appointment()">BOOK APPOINTMENT</button>
              </div>
            </div>
          </form>
          <div *ngIf="new_relative">
            <app-patient-relative-form (new_added_family_member)="family_member_saved($event)"></app-patient-relative-form>
          </div>
        </ng-container>
        <ng-container *ngIf="!verified_patient()">
          <h4 class="fs28 mt0"><i class="fa icon-calendar fa-fw"></i> Book an Appointment</h4>
          <p class="mb0">Enter the following information to continue</p>
          <app-patient-verification [skip_auto_navigation]="true" (continue)="go_to_next_step($event)" parent_class_name="appointment-noContainer"></app-patient-verification>
        </ng-container>
        <ng-container *ngIf="handleUser() && user_service.current_user && user_service.current_user.profileable_type != 'Patient' ">
          <div class="col-sm-6 bg-white pl-xxl pt-xl pr-xxl">
            <strong class="fs16">Note:</strong>
            <div class="text-danger"><strong>You are not eligible to book an appointment. Kindly signup as patient to make an appointment.</strong></div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!user_service.current_user">
        <app-non-loggedin-user (patientCreated)="onPatientCreate($event)"></app-non-loggedin-user>
      </ng-container>
    </div>
    <div class="col-md-6 col-sm-12" *ngIf="!showPayment">
      <div class="white-container-box p-xl">
        <h4 class="fs22 mt0"><i class="fa fa-user-md fa-fw fs28"></i> Your Appointment With</h4>
        <hr class="hr-line mt0">
        <div class="row">
          <div class="col-sm-7 mb-lg">
            <div class="media">
              <div class="media-left" style="min-width:75px;">
                <img [src]="doctor.profile_pic.modal.img" alt="" class="thumbnail thumb64 center-block">
                <rating class="small-stars-10" [ngModel]="doctor.average_rating" [max]="5" [readonly]="true"></rating>
              </div>
              <div class="media-body">
                <a class="text-primary fw-500 fs18 m0 mb-sm" [routerLink]="['/doctors',doctor.id]">{{doctor.complete_name}}</a>
                <p>{{doctor.designation}}</p>
              </div>
            </div>
            <ng-container *ngIf="doctor.location">
              <div class="row mb-lg">
                <h5 class="col-xs-3 m0">At</h5>
                <div class="col-xs-9">
                  <h5 class="m0"><i class="fa icon-location-pin"></i> {{doctor.location.practice_name}}</h5>
                  <h5><i class="fa icon-phone"></i> {{doctor.location.phone.value | mask: '(000) 000-0000'}}</h5>
                  <h5 class="mb0"><i class="fa icon-pin"></i> {{doctor.location.address.humanize()}}</h5>
                </div>
              </div>
            </ng-container>
            <div class="row mb-lg">
              <h5 class="col-xs-3 m0">On</h5>
              <div class="col-xs-9">
                <h5 class="m0 text-primary">{{(appointment.date) | date: 'MMM dd y hh:mm a'}} ({{appointment.slot_size}} mins)</h5>
              </div>
            </div>
            <div class="row mb-lg">
              <h5 class="col-xs-3 m0">For</h5>
              <div class="col-xs-9">
                <h5 class="m0">{{appointment.complete_name}}</h5>
              </div>
            </div>
            <div class="row">
              <h5 class="col-xs-3 m0 mb-sm">Reason of visit</h5>
              <div class="col-xs-9"><p class="m0">{{appointment.service_name}}</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
