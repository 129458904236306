<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Partners</h1>
      <h4 class="sub-title fs22 text-primary">Expand your business | Team up with leaders</h4>
      <h4 class="sub-title text-white mb-xl">
        WellWink partnerships deliver competitive advantage for our clients by developing relationships with best of the
        breed clinical, business, technology and research institutions.
      </h4>
      <a [routerLink]="'/public-partner-program'" class="btn btn-primary btn-lg text-uppercase" style="max-width:200px;">Become Our Partner</a>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <h1 class="mt0">Practice Management</h1>
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.athenahealth.com/" target="_blank">
          <img class="logo" src="/assets/img/public/partners-logo-1.gif" alt="">
          <h4>Athena Health</h4>
          <p>Move beyond siloed IT systems and tap into clinical and financial...</p>
        </a>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.carecloud.com/" target="_blank">
          <img class="logo" src="/assets/img/public/partners-logo-2.gif" alt="">
          <h4>CareCloud</h4>
          <p>CareCloud’s award-winning financial, clinical, patient experience...</p>
        </a>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.practicefusion.com/" target="_blank">
          <img class="logo" src="/assets/img/public/partners-logo-3.gif" alt="">
          <h4>Practice Fusion</h4>
          <p>We make running your practice simpler and more efficient so you can focus...</p>
        </a>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.greenwayhealth.com/" target="_blank">
          <img class="logo" src="/assets/img/public/partners-logo-4.gif" alt="">
          <h4>Greenway Health</h4>
          <p>Behind every medical interaction, we're there, creating EHR software solutions...</p>
        </a>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.eclinicalworks.com/" target="_blank">
          <img class="logo" src="/assets/img/public/partners-logo-5.gif" alt="">
          <h4>Eclinical works</h4>
          <p>Reinventing your practice doesn’t require tearing down walls or installing...</p>
        </a>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.curemd.com/" target="_blank">
          <img class="logo" src="/assets/img/public/partners-logo-6.gif" alt="">
          <h4>CureMD</h4>
          <p>CureMD is a leading provider of innovative health information systems and services...</p>
        </a>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.navetor.com/" target="_blank">
          <img class="logo" src="/assets/img/public/partners-logo-7.gif" alt="">
          <h4>Navetor</h4>
          <p>Well-designed, cloud-based veterinary software. A natural, intuitive way to streamline...</p>
        </a>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <h1 class="mt0">Communication</h1>
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="white-container-box partner-holder no-anchor" href="https://www.athenahealth.com/" target="_blank">
          <img class="logo" src="https://salesdoor.com/assets/site/partners-logo-1.gif" alt="">
          <h4>Twilio</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="white-container-box partner-holder no-anchor" href="https://www.athenahealth.com/" target="_blank">
          <img class="logo" src="https://salesdoor.com/assets/site/partners-logo-2.gif" alt="">
          <h4>SendGrid</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="white-container-box partner-holder no-anchor" href="https://www.athenahealth.com/" target="_blank">
          <img class="logo" src="https://salesdoor.com/assets/site/partners-logo-4.gif" alt="">
          <h4>Gmail</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <h1 class="mt0">Data</h1>
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="white-container-box partner-holder no-anchor" href="https://www.athenahealth.com/" target="_blank">
          <img class="logo" src="https://salesdoor.com/assets/site/partners-logo-7.gif" alt="">
          <h4>Google Drive</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="white-container-box partner-holder no-anchor" href="https://www.athenahealth.com/" target="_blank">
          <img class="logo" src="https://salesdoor.com/assets/site/partners-logo-8.gif" alt="">
          <h4>Dropbox</h4>
        </div>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="white-container-box partner-holder no-anchor" href="https://www.athenahealth.com/" target="_blank">
          <img class="logo" src="https://salesdoor.com/assets/site/partners-logo-9.gif" alt="">
          <h4>OneDrive</h4>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <h1 class="mt0">Industry Accolades</h1>
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.capterra.com/p/214175/WellWink/#reviews" target="_blank">
          <img class="logo" src="/assets/img/public/accolades-logo-1.gif" alt="">
        </a>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.getapp.com/healthcare-pharmaceuticals-software/a/wellwink/#reviews" target="_blank">
          <img class="logo" src="/assets/img/public/accolades-logo-2.gif" alt="">
        </a>
      </div>
      <div class="col-md-3 col-sm-4 col-xs-12">
        <a class="white-container-box partner-holder" href="https://www.softwareadvice.com/sms-marketing/wellwink-profile/" target="_blank">
          <img class="logo" src="/assets/img/public/accolades-logo-3.gif" alt="">
        </a>
      </div>
    </div>
  </div>
</section>
