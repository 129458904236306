import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import {Patient} from '../../health/doctor/patient';
import {DateMapper} from '../../routes/users/date'
@Component({
  selector: 'app-date-of-birth',
  templateUrl: './date-of-birth.component.html',
  styleUrls: ['./date-of-birth.component.scss']
})
export class DateOfBirthComponent implements OnInit {

  @Input() dob: string;
  @Input() dob_stage: string;
  @Input() age_year: string;
  @Input() age_month: string;
  @Input() show_age_option: any;
  public date: DateMapper = new DateMapper();
  public years: Array<number> = [];
  public months: Array<any> = [];
  public days: Array<number> = [];
  @Output() dob_and_stage: EventEmitter<any> = new EventEmitter<any>();
  public reverse_months_list: Object = { 'Month': 'Month', 'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'June': 6, 'July': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12};
  public months_list: Object = { 0: 'Month', 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'June', 7: 'July', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
  public patient_age_years: any = ['Year', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99]
  constructor() {
  }

  ngOnInit() {
    this.date.patient_dob(this.dob, this.dob_stage);
    [this.years, this.months, this.days] = this.date.get_years_months_days();
  }

  ngOnChanges() {
    // this.days = this.date.populate_leap_year_days();
    // console.log(this.months);
  }

  change_selection(label, value) {
    if (label == 'Month') {
      this.date.month = this.reverse_months_list[value];
      // this.days = this.date.populate_leap_year_days();
    }
    if (label == 'Day') {
      this.date.day = value;
    }
    [this.dob, this.dob_stage] = this.date.apply_dob_rules();
    this.dob_and_stage.emit([this.dob, this.dob_stage]);
  }

  // year_compulsary() {
  //   return ['', '0'];
  // }
}
