import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToClickableLinks'
})
export class ConvertToClickableLinksPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    // Regular expression pattern to match URLs starting with http, https, ftp, or www
    const pattern = /((http|https|ftp):\/\/[^\s]+)|(www\.[^\s]+)/g;

    return value.replace(pattern, (match) => {
      if (match.startsWith('www')) {
        return `<a href="http://${match}" target="_blank">${match}</a>`;
      } else {
        return `<a href="${match}" target="_blank">${match}</a>`;
      }
    });
  }
}
