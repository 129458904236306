<h5 class="m0 mb-sm fs16 text-black">{{userService.removeUnderScore(event.event_type)}}
  <button type="button" class="btn btn-secondary btn-outline btn-sm ml fs12" style="padding:0 6px;" *ngIf="showEditButton()" (click)="editEvent.emit(event)">
    <i class="fa fa-pencil"></i>
  </button>
</h5>
<h5 class="m0 text-black fw-400">{{event.subject}}</h5>
<hr class="hr-line mt mb">
<h6 class="m0 mb-sm flex-control">
  <label class="field-label mr">Time</label>
  <span>{{event.start_date | date:'MMMM d, y, h:mm a'}}</span>
</h6>
<h6 class="m0 mb-sm flex-control">
  <label class="field-label mr">Duration</label>
  <span>{{userService.removeUnderScore(event.duration)}}</span>
</h6>
<h6 class="m0 mb-sm flex-control" *ngIf="event.description">
  <label class="field-label mr">Description</label>
  <span [innerHTML]="event.description | convertToClickableLinks"></span>
</h6>
<h6 class="m0 mb-sm flex-control" *ngIf="event.is_notify">
  <label class="field-label mr">Notify</label>
  <span>
    <ng-container *ngFor="let notify of event.notify_before;let i=index">
            {{userService.removeUnderScore(notify)}}
      <span *ngIf="i != event.notify_before.length - 1">,</span>
    </ng-container>
  </span>
</h6>
<h6 class="m0 flex-control">
  <label class="field-label mr">Status</label>
  <label class="label fs12 fw-500" [style.background]="event.status_color">{{userService.removeUnderScore(event.status)}}</label>
</h6>
<div class="m0 flex-control" *ngIf="!isViewMode && event.comments.length > 0">
  <label class="field-label mr">Comments</label>
  <div>
    <div *ngFor="let comment of event.comments">
      <h6 class="m0">{{comment.body}}</h6>
      <p class="lhn fs10 mb-sm">By {{comment.commenter_name}} - {{comment.updated_at | date:'MMMM d, y, h:mm a'}}</p>
    </div>
  </div>
</div>
