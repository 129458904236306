<div class="flex-control">
  <div class="flex-left wizard-component">
    <div class="f1-steps">
      <div class="f1-progress">
        <div class="f1-progress-line" data-now-value="12.5" data-number-of-steps="7"  [style.width]="width"></div>
      </div>
      <div class="f1-step" [class.active]="step >= 1">
        <a (click)="setStep(1)" class="f1-step-icon"><i  class="fa icon-user"></i></a>
        <p>Patient Registration</p>
      </div>
      <div class="f1-step" [class.active]="step >= 2">
        <a (click)="setStep(2)" class="f1-step-icon"><i  class="fa fa-map-marker"></i></a>
        <p>Consent</p>
      </div>
      <div class="f1-step" [class.active]="step >= 3">
        <a (click)="setStep(3)" class="f1-step-icon"><i class="fa fa-user-md"></i></a>
        <p>Care Plan</p>
      </div>
      <div class="f1-step" [class.active]="step >= 4">
        <a (click)="setStep(4)" class="f1-step-icon"><i  class="fa icon-check"></i></a>
        <p>Confirmation</p>
      </div>
    </div>
  </div>
  <div class="flex-body">
    <app-program-enrolment-registration class="flex-control flex-column" *ngIf="step === 1" [patient]="patient"  [program]="program" (registerPatient)="registeredPatient($event)" (continue_to_next_step)="setStep(2)"></app-program-enrolment-registration>
    <app-enrolment-patient-consent class="flex-control flex-column" *ngIf="step === 2" [program]="program" [patient_id]="patient.id" (continue_to_next_step)="setStep(3)"></app-enrolment-patient-consent>
    <app-enrolment-patient-care-plan class="flex-control flex-column program-enote" *ngIf="step === 3" [program]="program"></app-enrolment-patient-care-plan>
    <app-enrolment-patient-confirmation class="flex-control flex-column" *ngIf="step === 4"></app-enrolment-patient-confirmation>
  </div>
</div>
