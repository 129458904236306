import {Component, Injector, OnInit} from '@angular/core';
import {SettingsService} from '../../core/settings/settings.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {UpdatePasswordData} from 'angular-token';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {HttpTokenService} from '../../services/http.token.service';

@Component({
  selector: 'change-password',
  templateUrl: 'change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  public _updatePasswordData: UpdatePasswordData = <UpdatePasswordData>{};
  private _output: any;
  public resetPasswordToken: string;
  public show_message: boolean = false;
  public hippa = false;
  public strength = 0;
  public is_profile_setting_component: boolean;

  constructor(private globalErrorHandlerService: GlobalErrorHandlerService,
              private _tokenService: HttpTokenService,
              public settings: SettingsService, private injector: Injector,
              private router: Router, private  route: ActivatedRoute) {
    this.is_profile_setting_component = this.injector.get('is_profile_setting_component', false);
  }

  // Submit Data to Backend
  onSubmit(event) {
    if (this.strength !== 100) {
      this.globalErrorHandlerService.error = 'Password is not strong enough.';
      return;
    }
    let target = event.target || event.srcElement || event.currentTarget;
    target.getElementsByTagName('button')[0].setAttribute('disabled', true);
    this._output = null;
    this._tokenService.updatePassword(this._updatePasswordData).subscribe(
      res => {
        this.setTimeOutFailsMessage();
        this._updatePasswordData = <UpdatePasswordData>{};
        this._output = res;

      }, error => {
        this.globalErrorHandlerService.error = error.error.errors.full_messages[0] === "doesn't match Password" ? "Password doesn't match" : error.error.errors.full_messages[0] ;
        console.log('Error', this.globalErrorHandlerService.error);
        this._updatePasswordData = <UpdatePasswordData>{};
        this._output = error;
      })
  }


  setTimeOutFailsMessage(): any {
    this.show_message = true;
    setTimeout(() => {
      this.show_message = false;
      this.router.navigate(['home'])
    }, 3000)
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.resetPasswordToken = params['reset_password'];
      this.hippa = params['hippa'];
      if (!this._tokenService.userSignedIn() && !this.resetPasswordToken) {
        this.router.navigate(['login']);
      }
    });
  }

  checkPasswordStrength($event: number) {
   this.strength = $event;
  }
}
