import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import baseApiUrl from '../globals';
import {
  Chatroom,
  ChatroomUser,
  Message
} from '../routes/practice-site/chat/chatroom';
import { CustomHttpClient } from './custom-http-client.service';
import { UserService } from './user.service';
import {PushNotification} from '../interfaces/push_notification';
import {HttpTokenService} from './http.token.service';

export interface Notification {
  messages?: NotificationData,
  reviews?: NotificationData,
  tasks?: NotificationData,
  patient_events?: NotificationData,
}
export interface NotificationData {
  icon: string,
  link_to: string,
  count: number,
  push_notifications: PushNotification[];
}


@Injectable()
export class MessageService {
  chatrooms_count = {};
  user_in_foreground = true;
  detail_listener;
  chat_loaded = false;
  receiver_id = {};
  already_subscribed_to_channel = {};
  message_subscriber = {};
  already_broadcast = {};
  messageState = {};
  triggered = false;
  user_trigger = false;
  refresh_list = false;
  sendMessageObserver: Subject<Object> = new Subject<Object>();
  public sendMessageSubscribers = {}
  notifications: Notification = {
    messages: {
      icon: 'custom-icon im-icon',
      link_to: '/enterprise/home/messages',
      count: 0,
      push_notifications: []
    },
    reviews: {
      icon: 'fa fa-star-o',
      link_to: '/enterprise/reviews/dashboard',
      count: 0,
      push_notifications: []
    },
    tasks: {
      icon: 'fa icon-bell',
      link_to: '',
      count: 0,
      push_notifications: []
    },
    patient_events: {
      icon: 'fa icon-bell',
      link_to: '',
      count: 0,
      push_notifications: []
    }
  };
  chatrooms: Array<Chatroom> = [];
  selected_chatroom: Chatroom;
  messages: Array<any> = [];
  chatroom_host: string = baseApiUrl + '/cable';
  channel = 'ChatChannel';
  user_channel = 'UserChannel';
  chatroom: any;
  reciever_id: number;
  subscriptions = {};
  private orderChange: Subject<any> = new ReplaySubject(1);
  public scrollToBottom: Subject<Chatroom> = new ReplaySubject(1);
  constructor(
    private _tokenService: CustomHttpClient,
    private token_service: HttpTokenService,
    private _user_service: UserService
  ) {
    _user_service.messageService = this;
    Chatroom.message_service = this;
  }

  messageChanged(chatroom) {
    this.chatrooms = this.chatrooms.sort((ch1, ch2) => {
      if (!ch1.last_message && ch2.last_message) {
        return 1;
      }
      if (ch1.last_message && !ch2.last_message) {
        return -1;
      }
      if (!ch1.last_message && !ch2.last_message) {
        return 0;
      }

      return ch1.last_message.created_at > ch2.last_message.created_at ? -1 : 1;
    });
    this.orderChange.next(true);
  }

  resetYourSelf() {
    this.chatrooms_count = {};
    this.chat_loaded = false;
    this.reciever_id = null;
    this.already_subscribed_to_channel = {};
    this.message_subscriber = {};
    this.already_broadcast = {};
    this.messageState = {};
    this.triggered = false;
    this.user_trigger = false;
    this.refresh_list = false;
    this.notifications = {
      messages: {
        icon: 'custom-icon im-icon',
        link_to: '/enterprise/home/messages',
        count: 0,
        push_notifications: []
      },
      reviews: {
        icon: 'fa fa-star-o',
        link_to: '/enterprise/reviews/dashboard',
        count: 0,
        push_notifications: []
      },
      tasks: {
        icon: 'fa icon-bell',
        link_to: '',
        count: 0,
        push_notifications: []
      },
      patient_events: {
        icon: 'fa icon-bell',
        link_to: '',
        count: 0,
        push_notifications: []
      }
    };
    this.chatrooms = [];
    this.selected_chatroom = undefined;
    this.messages = [];
    this.chatroom = null;
    this.reciever_id = undefined;
    Object.keys(this.subscriptions).forEach(key => {
      this.subscriptions[key].unsubscribe();
    });
    if (this.detail_listener) {
      this.detail_listener.unsubscribe();
      this.detail_listener = undefined;
    }
  }

  unsubscribeChannel(chatroom) {
    if (chatroom && chatroom.id) {
      this.subscriptions[chatroom.id].unsubscribe();
    }
  }

  // set observable of this subject
  get getOrderChange(): Observable<any> {
    return this.orderChange.asObservable();
  }

  get getScrollToBottom() {
    return this.scrollToBottom.asObservable();
  }

  resetScrollToBottom() {
    this.scrollToBottom = new ReplaySubject(1);
  }

  resetOrderChange() {
    this.orderChange = new ReplaySubject(1);
  }

  // get already_subscribed_to_userchannel(){
  //   return (this._tokenService as any).already_subscribed_to_userchannel;
  // }

  restartUnreadCount(chatroom_id, force_minus?) {
    if (!force_minus && !this.triggered) {
      return false;
    }
    let args = new HttpParams();
    args = args.set('chatroom_id', chatroom_id);
    // if(this.chatrooms_count[chatroom_id] > 0 || force_minus)
    // this.notifications['messages'].count = Math.max((this.notifications['messages'].count || 0)-1,0);
    this.setChatroomCount(chatroom_id, 0);

    return this._tokenService
      .get('messages/restart_unread_count', { params: args })
      .subscribe(response =>
        this.setMessagesNotificationsCount(response.messages.count)
      );
  }

  setChatroomCount(id, count, skipPrevious?) {
    const chtrm = this.find_by_chatroom_id(id);
    if (chtrm) {
      chtrm.previos_unread_count = chtrm.skip_previous
        ? count
        : chtrm.unread_count || count;
      if (skipPrevious) {
        chtrm.skip_previous = skipPrevious;
      } else {
        chtrm.skip_previous = false;
      }
      // console.log("setting prev count",chtrm.previos_unread_count,count,skipPrevious);
      chtrm.unread_count = count;
      this.chatrooms_count[id] = count;
    }
  }

  incrementChatroomCount(id, skip_previous?) {
    const prev_count = this.chatrooms_count[id] || 0;
    this.setChatroomCount(id, prev_count + 1, skip_previous);
  }

  set_user_status(data) {
    this.chatrooms.map(chatroom => chatroom.set_status_data(data));
  }
  set_patient_review_count() {

    this.notifications.reviews.count += 1;
  }
  pushTaskNotification(noti: PushNotification) {
    this.notifications.tasks.count += 1;
    this.notifications.tasks.push_notifications.unshift(noti);
  }
  popTaskNotification(noti: PushNotification) {
    this.notifications.tasks.count -= 1;
    this.notifications.tasks.push_notifications = this.notifications.tasks.push_notifications.filter(n => noti.id !== n.id);
  }
  pushPatientEventNotification(noti: PushNotification) {
    if (!this.objectExists(this.notifications.patient_events.push_notifications, noti.id)) {
      this.notifications.patient_events.count += 1;
      this.notifications.patient_events.push_notifications.unshift(noti);
    }
  }
  popPatientEventNotification(id: number) {
    if (this.objectExists(this.notifications.patient_events.push_notifications, id)) {
      this.notifications.patient_events.count -= 1;
      this.notifications.patient_events.push_notifications = this.notifications.patient_events.push_notifications.filter(n => id !== n.id);
    }
  }
  objectExists(arr: any[], id: any) {
    return arr.some(function(ai) {
      return ai.id === id;
    });
  }

  query(chatroom_id: any, last_id: any, page_number?: number) {
    return this._tokenService
      .get(
        `messages?chatroom_id=${chatroom_id}&last_mid=${last_id ||
        ''}&page=${page_number || ''}`
      )
      .map(res => res);
  }

  create(message: any) {
    return this._tokenService.post('messages', message).map(res => res);
  }

  saveSettings(params) {
    return this._tokenService.post('messages/settings', params);
  }

  handleBlock(params) {
    return this._tokenService.post('messages/handle_block', params);
  }

  find_by_chatroom_id(cid) {
    return this.chatrooms.filter(c => c.id == cid)[0];
  }

  createChatroom(chatroom: Chatroom) {
    return this._tokenService
      .post('messages/chatroom_creation', chatroom.to_params())
      .map(res => res);
  }
  deleteMessage(message: Message) {
    return this._tokenService
      .post('messages/delete', { message_id: message.id })
      .map(res => res);
  }

  notifyTyping() {
    return this._tokenService.post('messages/notify_typing', {
      chatroom_id: this.selected_chatroom.id
    });
  }

  update_chatroom_title(chatroom: Chatroom) {
    return this._tokenService
      .put('messages/set_chatroom_title', {
        chatroom_id: chatroom.id,
        title: chatroom.topic
      })
      .map(res => res);
  }

  update_chatroom(chatroom: Chatroom) {
    return this._tokenService
      .put('messages/update_chatroom', chatroom.to_params(true))
      .map(res => {
        chatroom.merge_recipients();
        chatroom.load_from_json(res.chatroom);

        return res;
      });
  }

  handleSystemMessage(message: Message) {
    // console.log("handling system message");
    const chatroom = this.find_by_chatroom_id(message.chatroom_id);
    if (!chatroom) {
      console.log('No chatroom found for add user');

      return;
    }
    if (message.isAdded) {
      chatroom.push_message(message);
      if (message.data['chat_users']) {
        message.data['chat_users'].forEach(cu =>
          chatroom.addChatUser(new ChatroomUser().load_from_json(cu))
        );
      }

      return;
    } else if (message.isPictureChanged) {
      if (message.data && message.data['extra']) {
        chatroom.thumbnail = message.data['extra']['thumbnail'];
      }
      chatroom.push_message(message);
    } else if (message.isTitleChanged) {
      chatroom.topic = message.chatroom.topic;
      chatroom.push_message(message);
    } else if (message.isRemoved) {
      const user_id = message.removed_user_id;
      if (user_id) {
        chatroom.removByUserId(user_id);
        if (user_id == this._user_service.current_user.id) {
          const index = this.chatrooms.findIndex(
            chrm => chrm.id == chatroom.id
          );
          if (index >= 0) {
            this.chatrooms.splice(index, 1);
            if (this.subscriptions[chatroom.id]) {
              this.subscriptions[chatroom.id].unsubscribe();
              this.already_subscribed_to_channel[chatroom.id] = undefined;
            }
            if (
              !this.selected_chatroom ||
              this.selected_chatroom.id == chatroom.id
            ) {
              this.changeSelectedChatroom(this.chatrooms[0]);
            }
          }

          return;
        } else {
          chatroom.push_message(message);
        }
      }
    } else if (message.isBlockedType) {
      chatroom.status = message.chatroom.status;
      chatroom.blocked_by_id = message.chatroom.blocked_by_id;
      chatroom.push_message(message);
    } else if (message.isFileProcessed) {
      const msg = chatroom.messages.filter(m => m.id === message.id)[0];
      msg && msg.file_processed();
    } else {
      chatroom.push_message(message);
    }

    this.scrollToBottom.next(chatroom);
  }

  get current_user() {
    return this._user_service.current_user;
  }

  deleteSubscriber(chatroom_user: ChatroomUser, chatroom: Chatroom) {
    console.log({ chatroom_user_id: chatroom_user.id });

    return this._tokenService
      .post('messages/delete_recipient', { chatroom_user_id: chatroom_user.id })
      .map(res => res);
  }

  getPraticeStaffAndPatient(token): Observable<any> {
    const query = new RegExp(token, 'ig');

    return this._tokenService
      .get(
        'practices/staff_and_patients?token=' + token)
      .share()
      .map(r => r);
  }

  getRecipients(token?, is_group?) {
    if (!token) {
      token = '';
    }

    return this._tokenService
      .get('messages/recipients?token=' + token + '&is_group=' + is_group)
      .toPromise();
  }

  getUser(id: number) {
    let args = new HttpParams();
    args = args.set('id', id.toString());

    return this._tokenService
      .get('users/get_user', { params: args })
      .toPromise()
      .then(response => response);
  }

  notifyUserFocus(foreground) {
    this.user_in_foreground = foreground;
    if (foreground && this.selected_chatroom) {
      this.load_messages(this.selected_chatroom);
    }

    return this._tokenService
      .post('users/set_online_status', { online_status: foreground, portal: true })
      .toPromise()
      .then(response => response);
  }

  userChatroomSubscriberList() {
    return this._tokenService
      .get('users/chatrooms_subscribers_data')
      .toPromise()
      .then(response => this.set_chatrooms(response));
  }

  set_chatrooms(chatrooms) {
    this.chatrooms = [];
    this.changeSelectedChatroom(undefined);
    if (chatrooms && chatrooms.length > 0) {
      this.chatrooms = chatrooms.map(chatroom_user =>
        new Chatroom().load_from_json(chatroom_user)
      );
      this.changeSelectedChatroom(this.chatrooms[0]);
      this.inialitize_unread_messages_count();
    }

    return this.chatrooms;
  }

  inialitize_unread_messages_count() {
    this.chatrooms.forEach(chatroom => {
      this.chatrooms_count[chatroom.id] = chatroom.unread_count;
    });
  }

  refresh_chatroom_list(chatroom) {
    let new_chatroom = new Chatroom();
    new_chatroom = new_chatroom.load_from_json(chatroom);
    this.chatrooms.unshift(new_chatroom);

    return new_chatroom;
  }

  addNewGroup(chatroom) {
    let nwchatroom = this.chatrooms.filter(c => c.id == chatroom.id)[0];
    if (!nwchatroom) {
      this.chatrooms.unshift(chatroom);
      nwchatroom = chatroom;
    }
    // this.chatrooms.unshift(chatroom);
    if (this.selected_chatroom != nwchatroom) {
      this.make_chatroom_connection(nwchatroom);
    }
    this.changeSelectedChatroom(nwchatroom);
    // this.receiver_id["user_id"] = this.selected_chatroom.subscribed_user.id;
    // this.reciever_id = this.selected_chatroom.subscribed_user.id;
  }

  get selected_chatroom_data() {
    return this.selected_chatroom;
  }

  get selected_chatroom_subscribed_user() {
    return this.selected_chatroom.subscribed_user;
  }

  get chatrooms_listing() {
    return this.chatrooms;
  }

  active_chatroom() {
    return this.selected_chatroom_data;
  }

  chatrooms_list() {
    return this.chatrooms_listing;
  }

  user_full_name() {
    return this.selected_chatroom_subscribed_user
      ? this.selected_chatroom.title
      : '';
  }

  loadUser(id: any) {
    let args = new HttpParams();
    args = args.set('id', id.toString());

    return this._tokenService
      .get('users/chatrooms_subscribers_data', { params: args })
      .toPromise()
      .then(response => response);
  }

  make_chatroom_connection(chatroom) {
    if (this.is_new_chatroom(chatroom)) {
      let cable = this.token_service.createChatRoomCables(chatroom.id)
      let subscription =  cable.subscriptions.create(
        'ChatChannel',
        {
          connected: () => console.log('connected'),
          disconnected: () => console.log('disconnected'),
          received: (data) => console.log('received', data)
        });
      console.log('message service', cable)
      this.already_subscribed_to_channel[chatroom.id] = true;
      this.subscriptions[chatroom.id] = subscription;
    }

    return chatroom;
  }

  is_new_user(user) {
    const already_subscribed = (this._tokenService as any)
      .already_subscribed_to_userchannel[user.id];
    if (typeof already_subscribed == 'undefined') {
      return true;
    }

    return false;
  }

  is_new_chatroom(chatroom) {
    if (!chatroom) {
      return false;
    }
    const already_subscribed = this.already_subscribed_to_channel[chatroom.id];
    return typeof already_subscribed === 'undefined';
  }

  already_added_chatroom(id) {
    const chatroom = this.chatrooms_list().filter(
      e => e.is_single_user && e.subscribed_user.id == id
    )[0];
    this.changeSelectedChatroom(chatroom);
    if (this.selected_chatroom && this.selected_chatroom.id) {
      return true;
    } else {
      return false;
    }
  }

  populate_already_added_chatroom(id) {
    if (this.chatrooms_list() && this.chatrooms_list().length > 0) {
      this.changeSelectedChatroom(
        this.chatrooms_list().filter(e => e.subscribed_user.id == id)[0]
      );
      let idx = this.chatrooms.indexOf(this.selected_chatroom);
      if (idx !== -1) {
        this.chatrooms.splice(idx, 1);
        this.chatrooms.unshift(this.selected_chatroom);
      }

      return;
    }
  }

  set_notifications() {
    if (!this.notifications) {
      this.notifications = {
        messages: {
          icon: 'custom-icon im-icon',
          link_to: '/enterprise/home/messages',
          count: 0,
          push_notifications: []
        },
        reviews: {
          icon: 'fa fa-star-o',
          link_to: '/enterprise/reviews/dashboard',
          count: 0,
          push_notifications: []
        },
        tasks: {
          icon: 'fa icon-bell',
          link_to: '',
          count: 0,
          push_notifications: []
        },
        patient_events: {
          icon: 'fa icon-bell',
          link_to: '',
          count: 0,
          push_notifications: []
        }
      };
    }
    if (this._user_service.current_user && this._user_service.current_user.isStaff) {
      this._tokenService
        .get('users/unread_notis')
        .toPromise()
        .then(response =>
          this.setMessagesNotificationsCount(response.messages.count)
        );
      this._tokenService
        .get('practice_reviews/unread_reviews')
        .toPromise()
        .then(response =>
          this.setReviewsNotificationsCount(response.reviews.count)
        );
    }
    if (this._user_service.current_user) {
      this._tokenService
        .get('notifications')
        .toPromise()
        .then(response => {
            if (!this._user_service.current_user.isPatient) {
              this.notifications.tasks.count = response.length;
              this.notifications.tasks.push_notifications = response;
            }
            if (this._user_service.current_user.isPatient) {
              this.notifications.patient_events.count = response.length;
              this.notifications.patient_events.push_notifications = response;
            }
          }
        );
    }

    return this.notifications;
  }

  setMessagesNotificationsCount(count) {
    this.notifications.messages.count = count;
  }

  setReviewsNotificationsCount(count) {
    this.notifications.reviews.count = count;
  }

  load_messages(chatroom) {
    if (chatroom) {
      this.changeSelectedChatroom(chatroom);
      this.receiver_id['user_id'] = this.selected_chatroom.subscribed_user.id;
      this.reciever_id = parseInt(chatroom.subscribed_user.id);
      if (chatroom.physical_exist()) {
        this.query(chatroom.id, chatroom.last_message_id).subscribe(
          response => {
            chatroom.loadNewMessages(response.messages, true);
            this.scrollToBottom.next(chatroom);
          }
        );
      } else {
        this.selected_chatroom.messages = [];
      }
    }
  }

  changeSelectedChatroom(chatroom) {
    // console.log("setting selected chatroom",chatroom);
    this.selected_chatroom = chatroom;
    if (!chatroom) {
      return;
    }
    if (chatroom.id) {
      this.restartUnreadCount(this.selected_chatroom.id);
    }
  }

  load_prevMessage(chatroom: Chatroom) {
    chatroom.load_in_progress = true;
    this.query(chatroom.id, null, chatroom.page_loaded + 1).subscribe(
      response => {
        this.selected_chatroom.loadPaginatedMessages(
          response.messages,
          response.headers
        );
      }
    );
  }

  populate_added_chatroom_messages(id) {
    this.populate_already_added_chatroom(id);
    this.load_messages(this.selected_chatroom);
  }

  getTaggable(params) {
    if (this.selected_chatroom && this.selected_chatroom.recipient_chat_user) {
      params.recipient_id = this.selected_chatroom.recipient_chat_user.user_id;
    }

    return this._tokenService
      .get('messages/get_taggable', { params })
      .share()
      .map(r => r);
  }

  set_user_on_selection(id) {
    let chat_Room;
    if (this.already_added_chatroom(id)) {
      this.populate_added_chatroom_messages(id);
    } else if ((chat_Room = this.alreadyEmptyAdded(id))) {
      this.changeSelectedChatroom(chat_Room);
      this.reciever_id = id;
      return true;
    } else {
      this.loadUser(id).then(json => {
        const chatroom = this.refresh_chatroom_list(json);
        this.changeSelectedChatroom(chatroom);
        this.receiver_id['user_id'] = this.selected_chatroom.subscribed_user.id;
        this.reciever_id = this.selected_chatroom.subscribed_user.id;

        return this.chatrooms_list();
      });
    }
  }

  alreadyEmptyAdded(user_id) {
    return this.chatrooms_list().filter(
      e =>
        e.is_single_user && e.subscribed_user && e.subscribed_user.id == user_id
    )[0];
  }

  add_or_push_new_chatroom(id) {
    const new_chatroom = this.chatrooms_list().filter(
      e => e.subscribed_user.id == id
    )[0];
    if (new_chatroom && new_chatroom.id) {
      return true;
    } else {
      return false;
    }
  }

  verifyNumber(chatroom: Chatroom, token) {
    return this._tokenService.post('messages/verify_phone', {
      phone: token,
      recipient_id: chatroom.subscribed_user && chatroom.subscribed_user.id
    });
  }

  async  initializeChatrooms() {
    if (this.chat_loaded) {
      return false
    }
    return  this.userChatroomSubscriberList().then(chatrooms => {
      chatrooms.forEach(chatroom => {
        this.make_chatroom_connection(chatroom);
      });
      this.chat_loaded = true;
      return true;
    });
  }

  subscribeToMessageObserver(identifier, callback) {
    console.log('subscribing to subject', identifier, this.sendMessageSubscribers, callback);
    this.sendMessageSubscribers[identifier] = this.sendMessageSubscribers[identifier] || []
    this.sendMessageSubscribers[identifier].forEach( subscriber => {
      subscriber.unsubscribe();
    });
    this.sendMessageSubscribers[identifier] = [this.sendMessageObserver.subscribe(callback)];
  }
}
