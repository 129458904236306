import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

import { CameraComponent } from './camera/camera.component';
import { ParticipantsComponent } from './participants/participants.component';
import { RoomsComponent } from './rooms/rooms.component';
import {FormatTimePipe, SettingsComponent} from './settings/settings.component';
import { DeviceSelectComponent } from './settings/device-select.component';
import { VideoPanelComponent } from './video-panel/video-panel.component';
import {NgxMaskModule} from 'ngx-mask';
import {RoutesModule} from '../routes/routes.module';



@NgModule({
  declarations: [CameraComponent, ParticipantsComponent, FormatTimePipe,
    RoomsComponent, SettingsComponent, DeviceSelectComponent, VideoPanelComponent
  ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        NgxMaskModule,
        RoutesModule
    ],
  exports: [CameraComponent, ParticipantsComponent,
    RoomsComponent, SettingsComponent, DeviceSelectComponent, VideoPanelComponent
  ]
})
export class VideoChatModule { }
