<div class="main-wrapper">
  <div class="table-responsive">
    <table class="table table-bordered table-striped table-hover">
      <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Billing Provider</th>
        <th>Rendering Provider</th>
        <th>Care Team</th>
        <th class="text-center"># of Patients</th>
        <th class="text-center"># of Alerts</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td><a>Chronic Hypertensive Care Program</a></td>
        <td><a>Hypertensive</a></td>
        <td>Dr. Adam Galaxy</td>
        <td>Dr. Adam Galaxy</td>
        <td>Sara Smith</td>
        <td class="text-center">100</td>
        <td class="text-center">
          <span class="label label-danger fs12">2</span>
          <span class="label label-warning fs12 ml mr">4</span>
          <span class="label label-primary fs12">7</span>
        </td>
        <td>Open</td>
        <td class="text-center">
          <a class="btn btn-primary btn-outline btn-sm"><i class="fa fa-pencil"></i></a>
          <a class="btn btn-danger btn-outline btn-sm ml"><i class="fa fa-times"></i></a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="row form-group">
    <div class="col-md-2"><label>Name</label></div>
    <div class="col-md-4">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-2"><label>Type</label></div>
    <div class="col-md-4">
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-2"><label>Vitals to Monitor</label></div>
    <div class="col-md-4">
      <label class="pr fw-500"><input type="checkbox"> BP</label>
      <label class="pr fw-500"><input type="checkbox"> Glucose</label>
      <label class="pr fw-500"><input type="checkbox"> SPO2</label>
      <label class="pr fw-500"><input type="checkbox"> Pulse</label>
      <label class="pr fw-500"><input type="checkbox"> Temp</label>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-2"><label>Billing Provider</label></div>
    <div class="col-md-4">
      <input type="text" class="form-control" placeholder="Search...">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-2"><label>Rendering Provider</label></div>
    <div class="col-md-4">
      <input type="text" class="form-control" placeholder="Search...">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-2"><label>Care Team</label></div>
    <div class="col-md-4">
      <input type="text" class="form-control" placeholder="Search multiple team members...">
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-2"></div>
    <div class="col-md-4">
      <a class="btn btn-primary mr">Save</a>
      <a class="btn btn-inverse">Cancel</a>
    </div>
  </div>
</div>

<div class="main-wrapper">
  <div class="row">
    <div class="col-md-5 col-sm-5 col-xs-12">
      <h4 class="text-black m0 fw-500">Elderly Hypertensive Care Program</h4>
      <h5 class="text-muted">Hypertension</h5>
      <ul class="list-inline m0">
        <li>BP</li>
        <li>Blood Glucose</li>
      </ul>
    </div>
    <div class="col-md-5 col-sm-4 col-xs-12">
      <div class="row mb-sm">
        <div class="col-sm-4 col-xs-12 pr0">
          <label class="fw-500 m0">Billing Provider:</label>
        </div>
        <div class="col-sm-8 col-xs-12">Dr. Adam Smith</div>
      </div>
      <div class="row mb-sm">
        <div class="col-sm-4 col-xs-12 pr0">
          <label class="fw-500 m0">Rendering Provider:</label>
        </div>
        <div class="col-sm-8 col-xs-12">Dr. Jack Smith</div>
      </div>
      <div class="row mb-sm">
        <div class="col-sm-4 col-xs-12 pr0">
          <label class="fw-500 m0">Care Team:</label>
        </div>
        <div class="col-sm-8 col-xs-12">Sara Smith (RN), Jack Brewer (MA)</div>
      </div>
    </div>
    <div class="col-md-2 col-sm-3 col-xs-12">
      <div class="row mb-sm">
        <div class="col-sm-5 col-xs-5 pr0">
          <label class="fw-500 m0">Status:</label>
        </div>
        <div class="col-sm-7 col-xs-7">
          <span class="text-center status-colors checkedin" style="display:inline-block;">Open</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-lightest-gray main-wrapper pt pb">
  <div class="row">
    <div class="col-md-7 col-sm-5">
      <h4 class="m0 mt-sm">Enrolled Patients <span class="label label-primary">10</span></h4>
    </div>
    <div class="col-md-5 col-sm-7">
      <div class="row">
        <div class="col-md-5 col-sm-2">
          <select class="form-control">
            <option>Alerts Only</option>
            <option>Call Requests</option>
            <option>No Alerts</option>
            <option>Today</option>
          </select>
        </div>
        <div class="col-md-4 col-sm-6"><input type="text" class="form-control" placeholder="Search Patient..."></div>
        <div class="col-md-3 col-sm-4"><a class="btn btn-primary">Enroll Patient</a></div>
      </div>
    </div>
  </div>
</div>

<div class="todaysPatient-list">
  <div class="list clearfix">
    <div class="col-lg-2 col-md-2 col-sm-2">
      <div class="media">
        <div class="media-left thumb64">
          <img alt="Image" class="img-rounded thumbnail thumb64" src="http://public.aks.com:7005/system/attachments//documents/profile_pic/a61726f4fcee0a7cae899925b49411e26edd5326/Thomas.jpg?1592371453">
        </div>
        <div class="media-body">
          <h4 class="m0 mb fw-500"><a>Joe Smith</a></h4>
          <h5 class="m0 mb"><i class="fa icon-user"></i>&nbsp; 23 Yrs - Male</h5>
          <h5 class="m0"><i class="fa icon-phone"></i>&nbsp;  (212) 050-2020</h5>
        </div>
      </div>
    </div>
    <div class="col-sm-2 border-col">
      <div class="cell-holder text-center">
        <h6 class="text-uppercase m0 mb-sm fs10">Emergency Contacts</h6>
        <h5 class="fw-500">Alexa Smith</h5>
        <h5 class="m0">(201) 949-0209</h5>
      </div>
    </div>
    <div class="col-sm-5 border-col">
      <div class="cell-holder">
        <ul class="icons-value list-unstyled m0">
          <li>
            <h6 class="text-uppercase">Heart Rate</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">25</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Pulse</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">80</h4>
          </li>
          <li>
            <h6 class="text-uppercase">SAO2</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">90</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Sugar</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">100</h4>
          </li>
          <li class="high-value">
            <h6 class="text-uppercase">BP</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">140/80</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Temp</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">97.6</h4>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-2 border-col">
      <div class="cell-holder">
        <ul class="icons-value anchor-list list-unstyled m0">
          <li>
            <h6 class="text-uppercase">Alerts</h6>
            <a class="btn btn-danger"><i class="fa icon-bell"></i></a>
            <h4 class="text-black">4</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Video</h6>
            <a class="btn btn-default"><i class="fa icon-camrecorder"></i></a>
            <h4 class="text-black">0</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Chat</h6>
            <a class="btn btn-info"><i class="fa icon-bubble"></i></a>
            <h4 class="text-black">2</h4>
          </li>

        </ul>
      </div>
    </div>
    <div class="col-sm-1 border-col">
      <div class="cell-holder text-center">
        <h6 class="text-uppercase m0 mb-sm fs10">Time</h6>
        <div class="waiting-counter text-center">
          <div class="minutes-cont">
            <span class="counter">  19</span> Mins
          </div>
          <round-progress role="progressbar" aria-valuemax="60">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
              <circle fill="none" cx="125" cy="125" r="117.5" style="stroke:rgb(1, 213, 176);stroke-width: 15;"></circle>
            </svg>
          </round-progress>
        </div>
      </div>
    </div>
  </div>
  <div class="list clearfix">
    <div class="col-lg-2 col-md-2 col-sm-2">
      <div class="media">
        <div class="media-left thumb64">
          <img alt="Image" class="img-rounded thumbnail thumb64" src="http://public.aks.com:7005/system/attachments//documents/profile_pic/a61726f4fcee0a7cae899925b49411e26edd5326/Thomas.jpg?1592371453">
        </div>
        <div class="media-body">
          <h4 class="m0 mb fw-500"><a>Alexa Bay</a></h4>
          <h5 class="m0 mb"><i class="fa icon-user"></i>&nbsp; 23 Yrs - Male</h5>
          <h5 class="m0"><i class="fa icon-phone"></i>&nbsp;  (212) 050-2020</h5>
        </div>
      </div>
    </div>
    <div class="col-sm-2 border-col">
      <div class="cell-holder text-center">
        <h6 class="text-uppercase m0 mb-sm fs10">Emergency Contacts</h6>
        <h5 class="fw-500">Alexa Smith</h5>
        <h5 class="m0">(201) 949-0209</h5>
      </div>
    </div>
    <div class="col-sm-5 border-col">
      <div class="cell-holder">
        <ul class="icons-value list-unstyled m0">
          <li>
            <h6 class="text-uppercase">Heart Rate</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">25</h4>
          </li>
          <li>
            <h6 class="text-uppercase">SAO2</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">90</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Sugar</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">100</h4>
          </li>
          <li>
            <h6 class="text-uppercase">BP</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">120/70</h4>
          </li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
    <div class="col-sm-2 border-col">
      <div class="cell-holder">
        <ul class="icons-value anchor-list list-unstyled m0">
          <li>
            <h6 class="text-uppercase">Alerts</h6>
            <a class="btn btn-danger"><i class="fa icon-bell"></i></a>
            <h4 class="text-black">4</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Video</h6>
            <a class="btn btn-warning"><i class="fa icon-camrecorder"></i></a>
            <h4 class="text-black">1</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Chat</h6>
            <a class="btn btn-info"><i class="fa icon-bubble"></i></a>
            <h4 class="text-black">2</h4>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-1 border-col">
      <div class="cell-holder text-center">
        <h6 class="text-uppercase m0 mb-sm fs10">Time</h6>
        <div class="waiting-counter text-center">
          <div class="minutes-cont">
            <span class="counter">21</span> Mins
          </div>
          <round-progress role="progressbar" aria-valuemax="60">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
              <circle fill="none" cx="125" cy="125" r="117.5" style="stroke:rgb(1, 213, 176);stroke-width: 15;"></circle>
            </svg>
          </round-progress>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="todaysPatient-list mt-xl">
  <div class="list clearfix">
    <div class="col-lg-2 col-md-2 col-sm-2">
      <div class="media">
        <div class="media-left thumb64">
          <img alt="Image" class="img-rounded thumbnail thumb64" src="http://public.aks.com:7005/system/attachments//documents/profile_pic/a61726f4fcee0a7cae899925b49411e26edd5326/Thomas.jpg?1592371453">
        </div>
        <div class="media-body">
          <h4 class="m0 mb fw-500"><a>Joe Smith</a></h4>
          <h5 class="m0 mb"><i class="fa icon-user"></i>&nbsp; 23 Yrs - Male</h5>
          <h5 class="m0"><i class="fa icon-phone"></i>&nbsp;  (212) 050-2020</h5>
        </div>
      </div>
    </div>
    <div class="col-sm-2 border-col">
      <div class="cell-holder text-center">
        <h6 class="text-uppercase m0 mb-sm fs10">Emergency Contacts</h6>
        <h5 class="fw-500">Alexa Smith</h5>
        <h5 class="m0">(201) 949-0209</h5>
      </div>
    </div>
    <div class="col-sm-5 border-col">
      <div class="cell-holder">
        <ul class="icons-value list-unstyled m0">
          <li>
            <h6 class="text-uppercase">Heart Rate</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">25</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Pulse</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">80</h4>
          </li>
          <li>
            <h6 class="text-uppercase">SAO2</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">90</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Sugar</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">100</h4>
          </li>
          <li class="high-value">
            <h6 class="text-uppercase">BP</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">140/80</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Temp</h6>
            <i class="fa icon-heart"></i>
            <h4 class="text-black">97.6</h4>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-2 border-col">
      <div class="cell-holder">
        <ul class="icons-value anchor-list list-unstyled m0">
          <li>
            <h6 class="text-uppercase">Alerts</h6>
            <a class="btn btn-danger"><i class="fa icon-bell"></i></a>
            <h4 class="text-black">4</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Video</h6>
            <a class="btn btn-default"><i class="fa icon-camrecorder"></i></a>
            <h4 class="text-black">0</h4>
          </li>
          <li>
            <h6 class="text-uppercase">Chat</h6>
            <a class="btn btn-info"><i class="fa icon-bubble"></i></a>
            <h4 class="text-black">2</h4>
          </li>

        </ul>
      </div>
    </div>
    <div class="col-sm-1 border-col">
      <div class="cell-holder text-center">
        <h6 class="text-uppercase m0 mb-sm fs10">Time</h6>
        <div class="waiting-counter text-center">
          <div class="minutes-cont">
            <span class="counter">  19</span> Mins
          </div>
          <round-progress role="progressbar" aria-valuemax="60">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
              <circle fill="none" cx="125" cy="125" r="117.5" style="stroke:rgb(1, 213, 176);stroke-width: 15;"></circle>
            </svg>
          </round-progress>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="bg-lightest-gray main-wrapper">
  <div class="tab-pane"></div>
  <div class="tab-pane active">
    <div class="row">
      <div class="col-lg-9 col-md-8 pr">
        <ul class="pd-custom-tabs nav nav-pills">
          <li><button class="btn fs16">Profile</button></li>
          <li class="active"><button class="btn fs16">Monitoring</button></li>
          <li><button class="btn fs16">Medical Summary</button></li>
          <li><button class="btn fs16">Vitals Ranges & Frequency</button></li>
        </ul>
        <div class="white-container-box">
          <div class="flex-control">
            <div class="flex-left p-lg">
              <ul class="left-tabs m0 list-unstyled">
                <li class="active"><a class="btn">BP <span>130/50</span></a></li>
                <li><a class="btn">Glucose <span>200 ul/ml</span></a></li>
                <li><a class="btn">Oxygen Saturation <span>90 mm HG</span></a></li>
              </ul>
            </div>
            <div class="flex-body p-lg">
              <div class="row mb-lg">
                <div class="col-md-5 col-sm-5"><h4 class="fs16 fw-500">Blood Pressure Readings</h4></div>
                <div class="col-md-7 col-sm-7">
                  <div class="row">
                    <div class="col-md-5 co-sm-4">
                      <select class="form-control">
                        <option>Time Period</option>
                      </select>
                    </div>
                    <div class="col-md-5 co-sm-4"><input type="text" class="form-control" placeholder="Search Keyword..."/></div>
                    <div class="col-md-2 co-sm-4"><a class="btn btn-inverse btn-sm btn-block">Filter</a></div>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">20-Feb-2020 <span class="center-block fs12">09:55 am</span></th>
                      <th class="text-center">20-Feb-2020 <span class="center-block fs12">09:55 am</span></th>
                      <th class="text-center">20-Feb-2020 <span class="center-block fs12">09:55 am</span></th>
                      <th class="text-center">20-Feb-2020 <span class="center-block fs12">09:55 am</span></th>
                      <th class="text-center">20-Feb-2020 <span class="center-block fs12">09:55 am</span></th>
                      <th class="text-center">20-Feb-2020 <span class="center-block fs12">09:55 am</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BP Systolic</td>
                      <td class="text-danger">150</td>
                      <td></td>
                      <td class="text-danger">153</td>
                      <td></td>
                      <td>131</td>
                      <td>114</td>
                    </tr>
                    <tr>
                      <td>BP Diastolic</td>
                      <td class="text-danger">150</td>
                      <td></td>
                      <td class="text-danger">153</td>
                      <td></td>
                      <td>131</td>
                      <td>114</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-lg-3 col-md-4 pl0">
        <div class="text-right mb">
          <div dropdown class="ml-sm p-relative" style="display:inline-block;">
            <button type="button" dropdownToggle class="btn btn-primary btn-sm">Activity &nbsp;<i class="fa fa-plus"></i></button>
            <ul class="dropdown-menu animated slideInDown">
              <li><a><i class="fa icon-note fa-fw"></i>&nbsp; Note</a></li>
              <li><a><i class="fa icon-bubble fa-fw"></i>&nbsp; SMS</a></li>
              <li><a><i class="fa icon-envelope fa-fw"></i>&nbsp; Email</a></li>
              <li><a><i class="fa icon-camrecorder fa-fw"></i>&nbsp; Video Call</a></li>
            </ul>
          </div>
          <a class="btn btn-primary btn-outline btn-sm ml-sm fs12">Review All</a>
          <a class="btn btn-primary btn-outline btn-sm ml-sm fs12">Time Log</a>
        </div>

        <div class="white-container-box pt">
          <ul class="pd-custom-tabs nav nav-pills">
            <li class="active"><button class="btn fs16">Alerts <span class="label label-danger fs10">4</span></button></li>
            <li><button class="btn fs16">Activity</button></li>
            <li><button class="btn fs16">Chat <span class="label label-info fs10">2</span></button></li>
          </ul>
          <div class="tab-pane active">
            <ul class="list-unstyled border-list m0">
              <li class="list">
                <div class="row">
                  <div class="col-xs-9">
                    <h5 class="m0">BP values is Critical High - <span class="text-danger">200/140</span></h5>
                    <span class="center-block text-muted fs12">2 mins</span>
                  </div>
                  <div class="col-xs-3 text-right">
                    <a class="btn btn-primary btn-sm"><i class="fa icon-user fs14"></i></a>
                  </div>
                </div>
              </li>
              <li class="list">
                <div class="row">
                  <div class="col-xs-9">
                    <h5 class="m0">BP values is High - <span class="text-warning">150/95</span></h5>
                    <span class="center-block text-muted fs12">2 mins</span>
                  </div>
                  <div class="col-xs-3 text-right">
                  </div>
                </div>
              </li>
              <li class="list">
                <div class="row">
                  <div class="col-xs-9">
                    <h5 class="m0">Patient has requested video call</h5>
                    <span class="center-block text-muted fs12">2 mins</span>
                  </div>
                  <div class="col-xs-3 text-right">
                    <a class="btn btn-primary btn-sm"><i class="fa icon-camrecorder fs14"></i></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
