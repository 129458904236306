<div class="filters-sec flex-header">
  <div class="row">
    <div class="col-xs-3">
      <input class="form-control" [(ngModel)]="filter['component']" (keyup.enter)="search()" placeholder="Component">
    </div>
    <div class="col-xs-2">
      <select [(ngModel)]="filter['category']" class="form-control" placeholder="Select Category">
        <option value=undefined selected >Category</option>
        <option class="text-capitalize" *ngFor="let category of categories" value="{{category}}"> {{category}}</option>
      </select>
    </div>
    <div class="col-md-7 col-xs-5">
      <button type="button" class="btn btn-tertiary btn-outline btm-min" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
      <button type="button" class="btn btn-secondary btn-min ml pull-right" (click)="adNoteComponent()" >
        <i class="fa fa-plus mr-sm"></i> Add
      </button>
    </div>
  </div>
</div>

<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Component</th>
      <th>Sub-Component-1</th>
      <th>Sub-Component-2</th>
      <th>Head</th>
      <th>Category</th>
      <th>Library</th>
      <th>Default Control</th>
<!--      <th>Status</th>-->
    </tr>
    </thead>
    <tbody>
    <tr  class="text-capitalize" *ngFor="let noteComponent of noteComponents">
      <td ><a (click)="editNoteComponent(noteComponent)">{{noteComponent?.component}}</a></td>
      <td >{{noteComponent?.sub_component_1}}</td>
      <td >{{noteComponent?.sub_component_2}}</td>
      <td >{{noteComponent?.head}}</td>
      <td >{{noteComponent?.category}}</td>
      <td class="text-uppercase">{{noteComponent?.library}}</td>
      <td >{{noteComponent?.default_control}}</td>
<!--      <td>-->
<!--        <select #note_status [(ngModel)]="noteComponent.status" (change)="changeStatusOfNoteComponent(noteComponent.status)" name="note_component_status" [ngClass]="noteComponent.status === 'active' ? 'active' : 'inactive'" class="form-control short-select">-->
<!--          <option value="active">Active</option>-->
<!--          <option value="in-active">In Active</option>-->
<!--        </select>-->
<!--      </td>-->
    </tr>
    </tbody>
  </table>
  <app-no-result-found *ngIf="noteComponents && noteComponents.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center" *ngIf="noteComponents && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
