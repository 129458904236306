import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent implements OnInit {
  @Input() view: CalendarView;
  @Input() viewDate: Date;
  @Input() locale = 'en';
  @Output() viewChange = new EventEmitter<CalendarView>();
  @Output() viewDateChange = new EventEmitter<Date>();
  @Output() refresh = new EventEmitter<Date>();
  CalendarView = CalendarView;

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange(value: Date) {
     this.viewDate = value;
     this.viewDateChange.next(this.viewDate)
  }

}
