import { Component, OnInit, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { IMultiSelectOption , IMultiSelectSettings, IMultiSelectTexts } from 'angular-2-dropdown-multiselect';
import {VisitsService} from '../../../../services/visits.service';
import {Observable, Subscription} from 'rxjs';
import { PrintInflowComponent } from '../print-inflow/print-inflow.component';
import {Tab} from '../../../../tabs/tab';
import {TabsService} from '../../../../services/tabs.service';
import { mergeMap } from 'rxjs/operators';

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-visits-filters',
  templateUrl: './visits-filters.component.html',
  styleUrls: ['./visits-filters.component.scss']
})
export class VisitsFiltersComponent implements OnInit, AfterViewInit {
  public show_notice = false;
  public no_filter = false;

  // Multi-Select
  public optionsModel: string[];
  public myOptions: IMultiSelectOption[];
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Choose Status',
    allSelected: 'All selected',
  };

  selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false
  };
  // Multi-Select

  // Type-Ahead
  public typeaheadLoading = false;
  public typeaheadLoadingPractice = false;
  public asyncSelectedDoctor = '';
  public asyncSelectedPractice = '';
  // Type-Ahead
  public keys_array: any;
  public date2DisabledDates;
  typeaheadNoResultsDoctor = false;
  typeaheadNoResultsPractice = false;
  constructor(public _visitService: VisitsService, private elRef: ElementRef, public tabs_service: TabsService) {
    this.initializePractices();
    this.initializeDoctors();
    this._visitService.reSetParams();
  }

  ngOnChanges() {
    this.optionsModel = this._visitService.status_id.length > 0 ? this._visitService.status_id.split(',') : [];
    this.performSearch();
  }

  ngOnInit() {
    this.setStatusOnVisitType();
    this.performSearch();
  }

  ngAfterViewInit() {
  }

  add_to_tabs(title) {
    this._visitService.calulcation_obj = {};
    this.tabs_service.add_tab(new Tab({title: title, autorefresh: false}, PrintInflowComponent, title, {}))
  }

  initializePractices() {
    this._visitService.practices = Observable.create((observer: any) => {
      observer.next(this.asyncSelectedPractice);
    }).pipe(mergeMap((token: string) => this._visitService.getPractices(token)));
  }

  initializeDoctors() {
    this._visitService.doctors = Observable.create((observer: any) => {
      observer.next(this.asyncSelectedDoctor);
    }).pipe(mergeMap((token: string) => this._visitService.getDoctors(token, this._visitService.practice_id)));
  }

  selectStatuses(event) {
    this._visitService.status_id = this.optionsModel.sort().join(',');
    if (this._visitService.status_id.length < 1) {
      this.no_filter = false;
      this.show_notice = false;
    }
  }

  performSearch() {
    this._visitService.resetClientSideKeys();
    this._visitService.current_page = '1';
    this._visitService.applyFilters();
  }

  setStatusOnVisitType() {
    if (this._visitService.statusLength()) {
      this.no_filter = true;
      this.show_notice = true;
      return;
    } else {
      this.no_filter = false;
      this.show_notice = false;
    }
    this._visitService.clearStatusOnChange();

    if (this._visitService.visit_type == 1) {
      this.admittedStatuses();
    }

    if (this._visitService.visit_type == 0) {
      this.officeStatuses();
    }

    if (this._visitService.visit_type == 2) {
      this.emergencyStatuses();
    }
  }

  officeStatuses() {
    this.myOptions = this._visitService.officeStatusesList();
  }

  admittedStatuses() {
    this.myOptions = this._visitService.admittedStatusesList();
  }

  emergencyStatuses() {
    this.myOptions = this._visitService.emergencyStatusesList();
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  changeTypeaheadLoadingPractice(e: boolean): void {
    this.typeaheadLoadingPractice = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this._visitService.resetDoctor();
    this.typeaheadNoResultsDoctor = e;
  }


  changeTypeaheadNoResultsPractice(e: boolean): void {
    this._visitService.resetPractice();
    this._visitService.resetLocation();
    this.typeaheadNoResultsPractice = e;
  }

  typeaheadOnSelect(e: any): void {
    this._visitService.doctor_name = e.value;
    this._visitService.doctor_id = e.item.id;
  }

  typeaheadOnSelectPractice(e: any): void {
    this._visitService.practice_name = e.value;
    this._visitService.practice_id = e.item.id;
    this._visitService.updateLocations(this._visitService.practice_id).then(visits => {
      console.log('locations updated sucessfully');
    });
  }

}
