import {Econtact} from '../health/doctor/econtact';
import {VitalEntry} from '../routes/practice-site/vital/VitalEntry';
import {Subscription} from 'rxjs/Subscription';
import { timer } from 'rxjs';
import {Injectable} from '@angular/core';
import {Consent} from './program/program-consents/consent';

export class EligiblePatient {
  id: number;
  name: string;
  title: string;
  customize_age: any;
  diagnosis_names: any;
  gender: string;
  follow_up: string;
  mobile_number_value: any;
  landline_number_value: any;
  patient_profile_pic: any;
  program_enrolments: any[] = [];
  insurance_plans: any;
  time: any;
  alerts: any;
  severity: any;
  user_id: any;
  status = '';
  portal_online_status: any;
  mobile_online_status: any;
  action: any;
  eligible_for: any;
  address: any;
  contacts: any[] = [];
  latest_programme_vitals: VitalEntry[] = [];
  tem_programme_enrolment: ProgramEnrollment = new ProgramEnrollment();
  diagnosis_codes: any;
  enrolment_date: any;
  programme_name: any;
  provider_name: any;
  programme_enrolment_date: any;
  diagnosis_name_code: any;
  chronic_diagnosis_name_code: any[];
  allergies: any;
  weight: any;
  height: any;
  bmi: any;
  heightInFeet: any;
  heightInInch: any;
  enrolled_in: any[] = [];
  compliance_level: any;
  health_status: any;
  no_of_days: any;
  program_status: string;
  third_party_patient_id: string;
  programme_enrolment_id: any;


  load_from_json(json: any) {
    this.id = json.id;
    this.program_status = json.program_status;
    this.user_id = json.user_id;
    this.weight = json.weight;
    if (json.height) {
      this.height = json.height;
      // this.ConvertHeightInFeetAndInch()
    }
    this.bmi = json.bmi;
    this.enrolled_in = json.enrolled_in;
    this.status = json.enrolment_status;
    this.programme_enrolment_date = json.programme_enrolment_date;
    this.allergies = json.allergies_name;
    this.provider_name = json.provider_name;
    this.name = json.name;
    this.title = json.title;
    this.compliance_level = json.compliance_level;
    this.no_of_days = json.no_of_days;
    this.health_status = json.health_status;
    this.customize_age = json.customize_age;
    this.diagnosis_names = json.diagnosis_names;
    this.diagnosis_name_code = json.diagnosis_name_code;
    this.chronic_diagnosis_name_code = json.chronic_diagnosis_name_code;
    this.diagnosis_codes = json.diagnosis_codes;
    this.gender = json.gender;
    this.severity = json.severity;
    this.mobile_number_value = json.mobile_number_value;
    this.landline_number_value = json.landline_number_value;
    this.patient_profile_pic = json.patient_profile_pic;
    this.insurance_plans = json.insurance_plans;
    this.eligible_for = json.eligible_for;
    this.follow_up = json.follow_up;
    this.programme_name = json.programme_name;
    this.enrolment_date = json.enrolment_date;
    this.mobile_online_status = json.mobile_online_status;
    this.portal_online_status = json.portal_online_status;
    this.third_party_patient_id = json.third_party_patient_id;
    this.programme_enrolment_id = json.programme_enrolment_id;
    if (json.time || json.alerts || json.unread_alerts) {
      this.tem_programme_enrolment = new ProgramEnrollment().load_from_json(
        {
          id: json.programme_enrolment_id, time: json.time, alerts: json.alerts, unread_alerts: json.unread_alerts
        }
      );
    }
    if (json.enrolments) {
      this.program_enrolments = json.enrolments.map(p => new ProgramEnrollment().load_from_json(p));
    }
    if (json.contacts && json.contacts.length > 0) {
      this.contacts = json.contacts.map(c => new Econtact().load_from_json(c));
    }
    if (json.latest_programme_vitals) {
      this.latest_programme_vitals = json.latest_programme_vitals.map(v => new VitalEntry().load_from_json(v))
    }
    return this;
  }

  ConvertHeightInFeetAndInch() {
    if (this.height) {
      this.heightInFeet = Math.floor(parseInt(this.height) / 12);
      this.heightInInch = parseInt(((parseInt(this.height) / 12 - this.heightInFeet) * 12).toFixed(2));
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProgramEnrollment {
  id: number
  session_active: boolean;
  program_id: number;
  patient_id: number;
  status: any;
  signer_name: string;
  signed_at_date: any;
  signed_at_time: any;
  signed_by: any;
  time_logged: any;
  time: any;
  name: string;
  practice_name: string;
  patient_name: string;
  care_team: any;
  type: any;
  created_at: any;
  alerts: any;
  unread_alerts: any;
  consent_text: any;
  severity: any;
  tcm_decision: string = 'tcm_moderate';
  tcm_discharge_date: Date = new Date();
  unread_alerts_count: number;
  ccm: boolean;
  pcm: boolean;
  rpm: boolean;
  tcm: boolean;
  bhi: boolean;
  private_pay: boolean;
  session_time: any = 0;
  logged_time: any;
  compliance_level: any;
  health_status: any;
  no_of_days: any;
  idle_time: any = 0;
  enrollment_type: any;
  enrollment_types: any[] = [];
  consents: Consent[] = [];
  public timer: Subscription;
  public idle_timer: Subscription = new Subscription();
  consent_from: string;
  selected_enrollment_type: any;
  mappings: { [key: string]: string } = {
    'ccm': 'Chronic Care Management',
    'rpm': 'Remote Patient Monitoring',
    'pcm': 'Principal Care Management',
    'tcm': 'Transitional Care Management',
    'bhi': 'Behavioral Health Integration'
  };
  load_from_json(json: any) {
    this.id = json.id;
    this.program_id = json.programme_id;
    this.patient_id = json.patient_id;
    this.status = json.status;
    this.signer_name = json.signer_name;
    this.signed_at_date = json.signed_at_date;
    this.signed_at_time = json.signed_at_time;
    this.signed_by = json.signed_by;
    this.time_logged = json.time_logged;
    this.name = json.name;
    this.severity = json.severity;
    this.practice_name = json.practice_name;
    this.patient_name = json.patient_name;
    this.care_team = json.care_team;
    this.type = json.type;
    this.created_at = json.created_at;
    this.alerts = json.alerts;
    this.unread_alerts = json.unread_alerts;
    this.unread_alerts_count = json.unread_alerts_count;
    this.consent_text = json.consent_text;
    this.consent_from = json.consent_from;
    this.compliance_level = json.compliance_level;
    this.health_status = json.health_status;
    this.no_of_days = json.no_of_days;
    this.time = json.time;
    this.ccm = json.ccm;
    this.rpm = json.rpm;
    this.pcm = json.pcm;
    this.bhi = json.bhi;
    this.tcm = json.tcm;
    this.private_pay = json.private_pay;
    this.tcm_discharge_date = json.tcm_discharge_date;
    this.tcm_decision = json.tcm_decision;
    if (json.consents) {
      this.consents = json.consents.map(a => new Consent().load_from_json(a));
    }
    //this.enrollment_type = this.setEnrollmentType(json);

    this.enrollment_types = this.setEnrollmentTypes(json);

    return this;
  }
  to_params() {
    return {
      programme_enrolment: {
        id: this.id,
        programme_id: this.program_id,
        patient_id: this.patient_id,
        severity: this.severity,
        status: this.status,
        tcm_decision: this.setDecisionMaking(),
        tcm_discharge_date: this.setDischargeDate(),
        ccm: this.setTypeTrue('ccm'),
        pcm: this.setTypeTrue('pcm'),
        rpm: this.setTypeTrue('rpm'),
        tcm: this.setTypeTrue('tcm'),
        bhi: this.setTypeTrue('bhi'),
        private_pay: this.setTypeTrue('private_pay'),
      }
    }
  }
  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }
  startTimer(): void {
    this.session_active = true;
    if (!this.timer || this.timer.closed) {
      this.timer = timer(1000, 1000)
        .subscribe(() => {
          ++this.session_time;
        });
    }
  }
  startIdleTimer(): void {
    this.idle_timer = timer(1000, 1000)
      .subscribe(() => {
        ++this.idle_time;
      });
  }

  unsubscribe() {
    this.timer.unsubscribe()
  }

  private setEnrollmentType(json) {
    if (json.ccm) {
      return 'ccm'
    }
    if (json.pcm) {
      return 'pcm'
    }
    if (json.rpm) {
      return 'rpm'
    }
    return ''
  }

  public setTypeTrue(type): boolean {
    return this.enrollment_types.includes(type);
  }

  private setDecisionMaking() {
    return this.enrollment_types.includes('tcm') ? this.tcm_decision : '';
  }
  private setDischargeDate() {
    return this.enrollment_types.includes('tcm') ? this.tcm_discharge_date : '';

  }

  private setEnrollmentTypes(json: any) {
    const enrollmentTypes = [];

    // Check if any of ccm, pcm, rpm, tcm, bhi, private_pay is true
    if (json.ccm || json.pcm || json.rpm || json.tcm || json.bhi || json.private_pay) {
      // Add labels for all the true values
      if (json.ccm) {
        enrollmentTypes.push('ccm');
      }
      if (json.pcm) {
        enrollmentTypes.push('pcm');
      }
      if (json.rpm) {
        enrollmentTypes.push('rpm');
      }
      if (json.tcm) {
        enrollmentTypes.push('tcm');
      }
      if (json.bhi) {
        enrollmentTypes.push('bhi');
      }
      if (json.private_pay) {
        enrollmentTypes.push('private_pay');
      }
    }

    // Update enrollment_types array
    this.enrollment_types = enrollmentTypes;

    return enrollmentTypes;
  }
  getFullProgramName(shortForm: string): string {
    const fullForm = this.mappings[shortForm.toLowerCase()];
    if (fullForm === undefined) {
      return '';
    }
    return fullForm;
  }

}
