import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../../services/custom-http-client.service';
import {ProgramIntegration} from './program-integration';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProgramIntegrationService {
  public showIntegrationForm: boolean;

  constructor(private _tokenService: CustomHttpClient) { }

  getIntegrationVendors(): Promise<any> {
    return this._tokenService.get('programme_integrations/integrate_with')
      .toPromise()
      .then(response => response);
  }

  create(programIntegration: ProgramIntegration): Promise<any> {
    return this._tokenService
      .post('programme_integrations', programIntegration.to_params())
      .toPromise()
      .then(response => response)
  }

  save(programIntegration: ProgramIntegration): Promise<any> {
    if (programIntegration.is_new_record()) {

      return this.create(programIntegration);
    }
    else {
      return this.update(programIntegration);
    }
  }

  update(programIntegration: ProgramIntegration) {
    return this._tokenService
      .put('programme_integrations/' + programIntegration.id, programIntegration.to_params())
      .toPromise()
      .then(response => response)
  }

  getProgramIntegration(practice_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('practice_id', practice_id);
    return this._tokenService.get('programme_integrations', {params: params})
      .toPromise()
      .then(response => response)
  }

  delete(id: number) {
    return this._tokenService
      .delete('programme_integrations/' + id)
      .toPromise()
      .then(response => response)
  }
}
