import { Component, OnInit } from '@angular/core';
import {EmergencyService} from '../../../../services/emergency.service';
import {AppointmentService} from '../../../../services/appointment.service';
import {TabsService} from '../../../../services/tabs.service';
import {Tab} from '../../../../tabs/tab';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';

@Component({
  selector: 'app-emergency-listing',
  templateUrl: './emergency-listing.component.html',
  styleUrls: ['./emergency-listing.component.scss']
})
export class EmergencyListingComponent implements OnInit {

  constructor(public _tabs_service: TabsService, public emergency_service: EmergencyService, private _appointment_service: AppointmentService) { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  change_status(appointment) {
    this._appointment_service.change_status(appointment.id, appointment.status_id, '').then(res => {
      appointment = appointment.load_from_json(res.appointment);
    });
  }

  add_to_tabs(appointment) {
    let title = appointment.patient_name() + '-' + 'Profile';
    let id = appointment.patient_id;
    this._tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id, {id: id, appointment: appointment}))
  }

}
