<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Integration</h1>
      <h4 class="sub-title fs22 text-primary">Experience the power of integration, get 360-degree insight.</h4>
      <h4 class="sub-title">
        A redundant and inefficient software can add to the healthcare costs. When doctors have access to a software that
        can combine their clinical, financial and administrative insight into one program, they can save time on repeatedly
        entering the same data.
        <br><br>
        Not only does this increase time savings and helps productivity, but also reduces the
        likelihood that errors will be made when transferring data.
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <div class="white-container-box p-xl">
      <h1 class="mt0">Practice Management</h1>
      <p>
        WellWink focuses on the development of a system that provides comprehensive platform for patient engagement.
        Integration with medical practice management system is beneficial for practitioners, administrative staff and management.
        At the same time making it streamlined, it enhances patient satisfaction with better patient care.
      </p>
      <p>By integrating PMS of your choice, a practice is able to access and perform all of the following from a single application:</p>
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <ul class="list-group m0">
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Online Patients</li>
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; All Scheduled Patients</li>
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Patient Check-in/Checkout</li>
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Insurance plan Information</li>
          </ul>
        </div>
        <div class="col-sm-4 col-xs-12">
          <ul class="list-group m0">
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Perform Post visit activities</li>
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Automated Reminders</li>
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Patient Communication</li>
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; e-Mail Marketing</li>
          </ul>
        </div>
        <div class="col-sm-4 col-xs-12">
          <ul class="list-group m0">
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Patient Reviews</li>
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Patient Intake Management</li>
            <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Practice Insight</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <div class="white-container-box p-xl">
      <div class="row">
        <div class="col-sm-6 col-xs-12 pull-right">
          <h1 class="mt0">File Storage</h1>
          <p>
            Digital information is more secure than paper files. It is crucial to deliver excellent healthcare services
            and important for a patient too, in order to have a complete data of their medical records.
            <br><br>
            Wellwink helps maintain and store medical records and other important healthcare files through its hassle-free and responsive medical document storage solution.
            <br><br>
            It also provides the ability to integrates with third party file storage platforms like Google Drive, OneDrive and DropBox.
          </p>
        </div>
        <div class="col-sm-6 col-xs-12">
          <img class="img-responsive" src="/assets/img/public/file-storage.jpg"/>
        </div>
      </div>
    </div>
  </div>
</section>
