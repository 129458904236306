import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RichTextTemplate} from '../rich-text-template';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {RichTextTemplateService} from '../rich-text-template.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-embed-rich-text-template',
  templateUrl: './embed-rich-text-template.component.html',
  styleUrls: ['./embed-rich-text-template.component.scss']
})
export class EmbedRichTextTemplateComponent implements OnInit {
  @Input() embedFor: string;
  @Input() showWarning: boolean;
  @Output() exportTemplate: EventEmitter<RichTextTemplate> = new EventEmitter<RichTextTemplate>();
  @ViewChild('confirmationModal') confirmationModal;
  public selectedTemplate: RichTextTemplate = new RichTextTemplate();
  public filter: Object = {token: '', type: undefined, owner_id: undefined};

  public typeaheadNoResultsTemplate = false;
  public typeaheadLoadingTemplate = false;
  public templates: Observable<RichTextTemplate[]>;

  constructor(public richTextTemplateService: RichTextTemplateService, public userService: UserService ) {
    this.templates = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.filter['token']);
    }).pipe(mergeMap((token: string) => this.importPublicTemplates(token)));
  }

  ngOnInit(): void {
    this.filter['type'] = this.embedFor;
    this.filter['owner_id'] = this.userService.current_user.id;
  }

  changeTypeaheadLoadingTemplate(e: boolean) {
    this.typeaheadLoadingTemplate = e;
  }

  changeTypeaheadNoResultsTemplate(e: boolean) {
    this.typeaheadNoResultsTemplate = e;
  }

  typeaheadOnTemplateSelect(template) {
    this.selectedTemplate = new RichTextTemplate().load_from_json(template);
    this.filter['token'] = '';
    if (this.showWarning) {
      this.confirmationModal.show();
    } else {
      this.exportRichTextTemplate();
    }
  }

  exportRichTextTemplate() {
    this.exportTemplate.emit(this.selectedTemplate);
    this.selectedTemplate = new RichTextTemplate();
  }

  importPublicTemplates(token: any) {
    return this.richTextTemplateService.embed_rich_templates(this.filter);
  }

}
