import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailMarketingComponent } from './email-marketing/email-marketing.component';
import { SmsMarketingComponent } from './sms-marketing/sms-marketing.component';
import { EmailCampaignTemplateIndexComponent } from './email-marketing/email-campaign-template-index/email-campaign-template-index.component';
import { NewEmailCampaignComponent } from './email-marketing/new-email-campaign/new-email-campaign.component';
import { ViewEmailCampaignComponent } from './email-marketing/view-email-campaign/view-email-campaign.component';
import { EmailCampaignResultComponent } from './email-marketing/email-campaign-result/email-campaign-result.component';
import { EmailCampaignRecipientsAnalyticsComponent } from './email-marketing/email-campaign-recipients-analytics/email-campaign-recipients-analytics.component';
import { EmailCampaignAnalyticsComponent } from './email-marketing/email-campaign-analytics/email-campaign-analytics.component';
import { EmailCampaignRecipientsListComponent } from './email-marketing/email-campaign-recipients-list/email-campaign-recipients-list.component';
import {SharedModule} from "../shared/shared.module";
import {TabsModule} from "../tabs/tabs.module";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {MultiselectDropdownModule} from "angular-2-dropdown-multiselect";
import {EditorModule} from "@tinymce/tinymce-angular";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule} from "@angular/forms";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {TagInputModule} from "ngx-chips";
import {RoutesModule} from '../routes/routes.module';



@NgModule({
  declarations: [EmailMarketingComponent, SmsMarketingComponent, EmailCampaignTemplateIndexComponent, NewEmailCampaignComponent, ViewEmailCampaignComponent, EmailCampaignResultComponent, EmailCampaignRecipientsAnalyticsComponent, EmailCampaignAnalyticsComponent, EmailCampaignRecipientsListComponent],
    imports: [
        CommonModule, TabsModule, SharedModule,
        PaginationModule, FormsModule,
        BsDropdownModule, BsDatepickerModule, NgSelectModule, MultiselectDropdownModule, EditorModule, TagInputModule, RoutesModule
    ]
})
export class MarketingModule { }
