import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TasksComponent} from '../tasks.component';
import {TasksleftpanelComponent} from '../tasksleftpanel/tasksleftpanel.component';
import {TasksfilterComponent} from '../tasksfilter/tasksfilter.component';
import {TaskslistingComponent} from '../taskslisting/taskslisting.component';
import {NewtaskComponent} from '../newtask/newtask.component';
import {EdittaskComponent} from '../edittask/edittask.component';
import {TaskService} from '../../../../services/task.service';
import {CommentService} from '../comment.service';
import {CommentComponent} from '../comment/comment.component';
import {TabsModule} from '../../../../tabs/tabs.module';
import {SharedModule} from '../../../../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {CollapseModule} from 'ngx-bootstrap/collapse';



@NgModule({
  declarations: [TasksComponent, TasksfilterComponent, TasksleftpanelComponent, TaskslistingComponent, NewtaskComponent,
    EdittaskComponent, CommentComponent
  ],
  providers: [
    TaskService, CommentService
  ],
  exports: [
    TasksComponent, TasksfilterComponent, TasksleftpanelComponent, TaskslistingComponent, NewtaskComponent,
    EdittaskComponent, CommentComponent
  ],
  imports: [
    CommonModule, TabsModule, SharedModule, FormsModule, BsDatepickerModule, MultiselectDropdownModule, CollapseModule
  ]
})
export class TasksModule { }
