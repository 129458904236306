import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, ViewChildren} from '@angular/core';
import {PermissionsService, Role, RoleTypePermission} from './permissions.service';
import {UserService} from '../../services/user.service';
import {AlertService} from '../../_alert';

@Component({
  moduleId: module.id.toString(),
  selector: 'permission-detail',
  templateUrl: 'permission-detail.component.html',
  styleUrls: [ 'permission-detail.component.css' ],
  providers: [PermissionsService]
})

export class PermissionDetailComponent implements OnInit, OnChanges {
  all_permissions: any;
  selected_permission: any;
  permissionable_type: string;
  keys: string[];
  selected_permissons: RoleTypePermission[];
  roles: Role[];
  role_type_permissions: RoleTypePermission;
  @ViewChildren('someVar') elements;
  role_key: string;
  permissionable_key: number;
  role_type_key: string;
  show_dynamic_permissions: boolean;
  isValid = false;
  messageAfterSave = false;
  @Input() role_id: any;
  @Input() permissionable_id: any;
  @Input() role_type_id: any;
  package_permissions = [];
  per_false = false;
  public add_on: string[];

  constructor(private renderer: Renderer2, private el: ElementRef, private permissionService: PermissionsService,
              private _userService: UserService, private ref: ChangeDetectorRef, public alertService: AlertService) {}

  ngOnChanges(parent_wrapper) {
    this.setInputValuesFromParent(parent_wrapper);

    this.role_id = this.role_key;
    this.permissionable_id =  this.permissionable_key;
    this.role_type_id = this.role_type_key;

    let role = this.role_id;
    let permissionsable = this.permissionable_id;
    if (this.role_id == undefined) {
      role = '';
    } else {
      role = (this.role_id).toString();
    }
    if ( this.permissionable_id == undefined) {
      permissionsable = '';
    } else {
      permissionsable = (this.permissionable_id).toString();
    }

    this.permissionService.getRoleTypePermissions(role, permissionsable).subscribe(permissions => {
      this.selected_permissons = permissions;
      this.roles = permissions.roles[permissions.role_type];
      this.package_permissions = permissions.packages_permissions_ids;
      this.add_on = permissions.add_on;
      this.setPermissionData(this.selected_permissons, this.roles);
    });
  }


  permissionAllowedInPackage(permission_id) {
    return this.package_permissions.includes(permission_id)
  }
  showPermissionAlert(e) {
    e.preventDefault();
    this.per_false = false;
    alert('Please email at info@wellwink.com or call on 222-121312312 to subscribe for this feature.');
    return;
  }

  setInputValuesFromParent(parent_wrapper): void {
    if (parent_wrapper.role_id && parent_wrapper.permissionable_id && parent_wrapper.role_type_id) {
      this.role_key = parent_wrapper.role_id.currentValue;
      this.permissionable_key = parent_wrapper.permissionable_id.currentValue;
      this.role_type_key = parent_wrapper.role_type_id.currentValue;
      this.showHidePermissions(true);
    } else if (parent_wrapper.role_id) {
      this.role_key = parent_wrapper.role_id.currentValue;
      this.showHidePermissions(true);
    } else if (parent_wrapper.permissionable_id) {
      this.permissionable_key = parent_wrapper.permissionable_id.currentValue;
      this.showHidePermissions(true);
    } else if (parent_wrapper.role_type_id) {
      this.role_type_key = parent_wrapper.role_type_id.currentValue;
      this.role_key = null;
      this.permissionable_key = null;
      this.showHidePermissions(false);
    }
  }

  showHidePermissions(value): void {
    this.show_dynamic_permissions = value;
  }


  setPermissionData(permission_data, get_roles) {
    if (permission_data && get_roles) {
      let per = [];
      this.permissionable_type = permission_data.role_type_name;
      let vals = Object.keys(permission_data.permissions).map(function(key) {
        return permission_data.permissions[key];
      });
      vals.forEach(i => per = per.concat(i));
      for (let p of per) {
        p['is_checked'] = this.isPermissionChecked(p.id, permission_data.selected_permission);
      }
      this.role_type_permissions = permission_data.permissions;
      this.keys = Object.keys(this.role_type_permissions);
      this.all_permissions = per;
      this.selected_permission = permission_data.selected_permission;
      this.roles = get_roles;
    }
  }

  isPermissionChecked(id: number, selected_permissions: Object[]): any {
    let is_exist = selected_permissions.indexOf(id);
    return (is_exist >= 0);
  }

  child_permissions(): RoleTypePermission[] {
    return this.all_permissions.filter(e => e.parent_id != null)
  }

  child_names(): string[] {
    return Array.from(new Set(this.child_permissions().map(function(e) {return e.name})))
  }

  subCategorySelections(event, key): any {
    if (!this.keyDisabled(key)) {
      let dashboard_permission = this.all_permissions.filter(e => e.name == 'Dashboard');
      let main_category_permissions = this.all_permissions.filter(e => e.category_name == key && e.parent_id != null );
      let is_grand_category_checked = event.target.checked;
      if (is_grand_category_checked == true) {
        this.subPermissionsUpdate(is_grand_category_checked, main_category_permissions);
      }
      else {
        main_category_permissions = main_category_permissions.filter(e =>  e.parent_id != dashboard_permission[0].id );
        this.subPermissionsUpdate(is_grand_category_checked, main_category_permissions);
      }
    } else {
      this.alertService.success('Please email at info@wellwink.com or call on 222-121312312 to subscribe for this feature.', this.alertService.options);
    }
  }

  subPermissionsUpdate(is_checked, main_permisions): any {
    for (let sub_permission of main_permisions) {
      sub_permission.is_checked = is_checked;
    }
  }

  get_childs_for(id: number, name: string) {
    return this.child_permissions().filter(p => p.parent_id == id && p.name == name)[0];
  }

  GrandCategoryUncheck(event, key): any {
    let elements = this.elements._results;
    for (let el of elements) {
      if (el.nativeElement.value == key) {
        el.nativeElement.checked = false
      }
    }
  }

  save(role_id: string, permissionable_id: string): any {
    this.isValid = true;
    if (permissionable_id == undefined) {
      permissionable_id = '';
    }

    let selected_checboxes = this.getSelectedCheckboxes(this.all_permissions);
    let response = this.permissionService.create(selected_checboxes, role_id, permissionable_id).subscribe(result => {
      result = result;
      this.isValid = false;
      if (result.message == 'success') {
        this.setTimeOutSuccessMessage();
        this._userService.validateToken();
      }
    });
  }

  setTimeOutSuccessMessage(): any {
    this.messageAfterSave = true;
    setTimeout(function() {
      this.messageAfterSave = false;
    }.bind(this), 3000);
  }

  getSelectedCheckboxes(permissions) {
    let selected_chkboxes =  permissions.filter(option => option.is_checked).map(option => option.id);
    return selected_chkboxes;
  }

  isValidForSelection(grand_parent_name) {
    let permissions = this.role_type_permissions[grand_parent_name];
    let total_permission_key = permissions.filter(e => e.parent_id != null);
    let total_permission_key_length = permissions.filter(e => e.parent_id != null).length;
    let total_checked = total_permission_key.filter(e => e.is_checked).length;
    let is_check = (total_permission_key_length == total_checked) ? true : false;
    return is_check;
  }

  ngOnInit(): void {
  }

  keyDisabled(key: string): boolean {
    if (key === 'Care Management' || key === 'Reputation Management') {
      return !this.add_on.includes(key);
    } else {
      return false;
    }
  }
}
