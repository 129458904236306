<app-verification-banner></app-verification-banner>
<div class="top-bar" *ngIf="!_userService.current_user && !_userService.patientSignUp">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-4 col-xs-12 phone-col hidden-xs">
        <p class="phone">
          <i class="fa fa-phone fa-fw"></i>
          <span class="fw-500">Call Us Today: </span>
          <a href="tel:+1-848-291-2430">+1 (848) 291-2430</a>
        </p>
      </div>
      <div class="col-md-8 col-sm-8 col-xs-12">
        <ul class="nav navbar-nav navbar-right mr0">
          <li *ngIf="_userService.iAmPatient"><a routerLink='/'>Home</a></li>
          <li><a routerLink='/faqs' (click)="_userService.hideFooterForPatient = false">FAQs</a></li>
          <li><a routerLink='/why-wellwink' (click)="_userService.hideFooterForPatient = false">Why WellWink?</a></li>
          <li *ngIf="!_userService.iAmPatient">
            <a routerLink='/patient' class="btn btn-secondary btn-outline text-uppercase fw-500" (click)="_userService.iAmPatient = true">
              <i class="fa fa-user-circle-o fs16 mr-sm"></i> I am A Patient
            </a>
          </li>
          <li *ngIf="!(_userService.user_logged_in && _userService.current_user)">
            <a href="javascript:" class="btn btn-primary btn-outline text-uppercase fw-500" (click)="_userService.toggleLogin()">
              <i class="icon-login fs16 mr-sm"></i> Sign In
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-default" [ngClass]="isPatientLogin() ? 'hide-on-patient' : ''">
  <div class="container">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header m0">
      <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapsing">
        <span class="sr-only">Toggle navigation</span><i class="fa fa-bars"></i>
      </button>
      <a class="navbar-brand" routerLink='/'>
        <img [src]="_userService.iAmPatient ? 'assets/img/branding/logo-patient.png' : 'assets/img/branding/logo.png'" alt="WellWink">
      </a>
    </div>
    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse scroll-browser" id="navbar-collapsing">
      <ul class="nav navbar-nav">
        <ng-container *ngIf="!_userService.iAmPatient">
          <ng-container *ngIf="!_userService.current_user && !_userService.patientSignUp">
            <li dropdown class="menu-hover solutions-link">
              <a dropdownToggle>Patient Engagement <i class="fa icon-arrow-down fa-fw fs10"></i></a>
              <div class="dropdown-menu container" [ngClass]="showSolutions ? 'dropmenu-close'  : ''">
                <ul class="menu-holder list-unstyled p0"(click)="showDropDown('solutions')">
                  <li>
                    <a routerLink='/public-appointments-booking'>Appointments Booking <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Book appointment anywhere any time, office visit, virtual visit or walk-in.</p>
                  </li>
                  <li>
                    <a routerLink='/public-reputation-management'>Reputation Management <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Get feedback from your customers, rank and make it public.</p>
                  </li>
                  <li>
                    <a routerLink='/public-patient-reminders'>Patient Reminders <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Inform patients in a timely manner and make wise decisions.</p>
                  </li>
                  <li>
                    <a routerLink='/public-patient-communication'>Patient Communication <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Make best use of technology to communicate with patient, securely.</p>
                  </li>
                  <li>
                    <a routerLink='/public-telemedicine'>Telemedicine <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Expand our virtual presence with end-to-end telehealth solution.</p>
                  </li>
                  <li>
                    <a routerLink='/public-practice-marketing'>Practice Marketing <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Simplified marketing which you can handle yourself.</p>
                  </li>
                  <li>
                    <a routerLink='/public-integration'>Integration <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Experience the power of integration, get 360-degree insight.</p>
                  </li>
                  <li class="hidden-xs"></li>
                </ul>
              </div>
            </li>
            <!--CUSTOMERS BUTTON-->
            <li *ngIf="!_userService.current_user" dropdown class="menu-hover hidden">
              <a dropdownToggle>Customers <i class="fa icon-arrow-down fa-fw fs10"></i></a>
              <div class="dropdown-menu container" [ngClass]="showCustomers ? 'dropmenu-close'  : ''">
                <ul class="menu-holder list-unstyled p0" (click)="showDropDown('customers')">
                  <li>
                    <a routerLink='/public-patient-portal'>Patients <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Book appointment anywhere any time, office visit, virtual visit or walk-in.</p>
                  </li>
                  <li>
                    <a>Doctors <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Get feedback from your customers, rank and make it public.</p>
                  </li>
                  <li>
                    <a>Medical Centers <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Inform patients in a timely manner and make wise decisions.</p>
                  </li>
                </ul>
              </div>
            </li>
            <li *ngIf="!_userService.current_user" dropdown class="menu-hover">
              <a dropdownToggle>Care Management <i class="fa icon-arrow-down fa-fw fs10"></i></a>
              <div class="dropdown-menu container" [ngClass]="showCustomers ? 'dropmenu-close'  : ''">
                <ul class="menu-holder list-unstyled p0" (click)="showDropDown('customers')">
                  <li>
                    <a routerLink='/public-chronic-care-management'>Chronic Care Management <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Streamline your CCM process, improve patient health outcomes and maximize revenue seamlessly with a scalable CCM platform.</p>
                  </li>
                  <li>
                    <a routerLink='/public-remote-patient-monitoring'>Remote Patient Monitoring <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Create and manage RPM programs and enhance your revenue bottom-line.</p>
                  </li>
                  <li>
                    <a routerLink='/public-remote-therapeutic-monitoring'>Remote Therapeutic Monitoring <i class="fa fa-caret-right fa-fw"></i></a>
                    <p>Receive respiratory signals from PAP therapy.</p>
                  </li>
                </ul>
              </div>
            </li>
            <!--PARTNERS BUTTON-->
            <li><a routerLink='/partner'>Partners</a></li>
            <!--PRICING BUTTON-->
            <li><a routerLink='/contact-us'>Pricing</a></li>
            <!--CONTACT US BUTTON-->
            <li><a routerLink='/contact-us'>Contact Us</a></li>
            <!--REGISTER AS BUTTON-->
            <li class="last-li">
              <a class="btn btn-secondary"  href="https://calendly.com/wellwink/30min" target="_blank">
                Request A Demo <i class="fa fa-long-arrow-right fa-fw"></i>
              </a>
            </li>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="_userService.iAmPatient">
          <ng-container *ngIf="!_userService.current_user && !_userService.patientSignUp">
            <li><a routerLink='/patient'>Find a Doctor</a></li>
            <li><a routerLink='/public-patient-portal'>Patient Portal</a></li>
            <li><a routerLink='/mobile-app'>Mobile App</a></li>
            <li><a routerLink='/contact-us'>Contact Us</a></li>
            <li class="last-li" *ngIf="!(_userService.user_logged_in && _userService.current_user)">
              <a class="btn btn-secondary" routerLink='/patient/sign-up'>
                <i class="fa fa-user-circle-o fa-fw"></i> Patient Signup
              </a>
            </li>
          </ng-container>
        </ng-container>

        <li *ngIf="_userService.current_user && _userService.current_user.profileable_id && _userService.current_user.isPatient">
          <a [routerLink]="['/patients',_userService.current_user.profileable_id]">
            <i class="fa icon-user fa-fw"></i> My Profile
          </a>
        </li>
        <li *ngIf="(_userService.user_logged_in && _userService.current_user && _userService.current_user.isPatient)">
          <a routerLink='/messages'>
            <i class="fa icon-bubbles fa-fw"></i> Messages
            <span *ngIf="message_notification_count()" class="number-counter">{{message_notification_count()}}</span>
          </a>
        </li>
        <app-patient-event-notifications-dropdown class="notifications-dropdown"
                                                  *ngIf="(_userService.user_logged_in && _userService.current_user && _userService.current_user.isPatient)">
        </app-patient-event-notifications-dropdown>

        <ng-container *ngIf="_userService.user_logged_in && _userService.current_user">
          <li *ngIf="_userService.user_logged_in && _userService.current_user && _userService.current_user.isPractice || _userService.current_user.isDoctor">
            <a [routerLink]="'/enterprise'" routerLinkActive="active" href="javascript:">
              <i class="fa icon-user fa-fw"></i> MY PRACTICE
            </a>
          </li>
          <li *ngIf="_userService.user_logged_in && _userService.current_user && _userService.current_user.isEnterprise">
            <a [routerLink]="'/enterprise'" routerLinkActive="active" href="javascript:">
              <i class="fa icon-user fa-fw"></i> MY ENTERPRISE
            </a>
          </li>
        </ng-container>

        <!--SIGN IN DROPDOWN-->
        <ng-container *ngIf="_userService.user_logged_in && _userService.current_user">
          <li dropdown class="user-dropdown last-li">
            <div dropdownToggle class="dropdown-btn btn">
              <div class="thumb24 pull-left mr-sm"><img class="img-circle" alt="Avatar" [src]=" _userService.current_user.profile_pic"></div>
              <h6 class="m0 text-uppercase">
                <span>{{_userService.current_user.first_name}} {{_userService.current_user.last_name}}</span>
                <i class="fa fa-chevron-down fa-fw fs10"></i>
              </h6>
            </div>
            <ul class="dropdown-menu animated slideInDown" role="menu">
              <li><a href="javascript:" [routerLink]="'/change-password'"><i class="fa icon-key fa-fw"></i> CHANGE PASSWORD</a></li>
              <li class="logout-list-link"><a (click)="logout()"><i class="fa icon-logout fa-fw"></i> LOG OUT</a></li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
<ng-container *ngIf="isPatientLogin()">
  <!-- Mobile Menu For Patient Only After Login -->
  <ul class="patient-mobile-nav">
    <li *ngIf="_userService.current_user && _userService.current_user.profileable_id && _userService.current_user.isPatient">
      <a [routerLink]="['/patients',_userService.current_user.profileable_id]">
        <i class="fa icon-user fa-fw"></i>
        <span class="text">Profile</span>
      </a>
    </li>
    <li>
      <a routerLink='/messages'>
        <i class="fa icon-bubbles fa-fw"></i>
        <span class="text">Messages</span>
        <span *ngIf="message_notification_count()" class="number-counter">{{message_notification_count()}}</span>
      </a>
    </li>
    <app-patient-event-notifications-dropdown class="notifications-dropdown"></app-patient-event-notifications-dropdown>
    <!-- Mobile User Pic Menu -->
    <li dropdown *ngIf="_userService.user_logged_in && _userService.current_user">
      <div class="avatar" dropdownToggle>
        <img class="img-responsive border-box shadow" alt="Avatar" [src]=" _userService.current_user.profile_pic">
      </div>
      <ul class="dropdown-menu dropdown-right animated slideInUp">
        <li class="fs18 bg-lightest-gray p-sm">{{_userService.current_user.first_name}} {{_userService.current_user.last_name}}</li>
        <li><a href="javascript:" [routerLink]="'/change-password'"><i class="fa icon-key fa-fw"></i> CHANGE PASSWORD</a></li>
        <li class="logout-list-link"><a (click)="logout()"><i class="fa icon-logout fa-fw"></i> LOG OUT</a></li>
      </ul>
    </li>
  </ul>
</ng-container>
