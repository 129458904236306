import {Component, Injector, OnInit} from '@angular/core';
import {Program} from '../program';
import {ProgramService} from '../program.service';
import {LoaderService} from '../../services/loader.service';
import {EligiblePatient, ProgramEnrollment} from '../eligible-patient';
import {GlobalErrorHandlerService} from '../../services/global-error.service';

@Component({
  selector: 'app-program-enrolment',
  templateUrl: './program-enrolment.component.html',
  styleUrls: ['./program-enrolment.component.scss']
})
export class ProgramEnrolmentComponent implements OnInit {
  public program: Program;
  public patient: EligiblePatient;
  public programEnrollment: ProgramEnrollment;
  public program_id: any;

  constructor(public injector: Injector, public programService: ProgramService,
              public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    this.patient = this.injector.get('patient');
    console.log(this.patient);
    this.program_id = this.injector.get('program_id');
    if ( this.program_id ) {
      this.getProgram(this.program_id);
    }
    this.programEnrollment = this.injector.get('programEnrollment');
    this.programService.currentProgramEnrollmentObject = this.programEnrollment;
  }

  getProgram(id: any) {
    this.loaderService.show();
    this.programService.getProgram(id).then(
      resp => {
        this.program = new Program().load_from_json(resp);
        this.loaderService.hide();
      }
    ).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
      this.loaderService.hide();
      console.log(resp)
    })
  }

}
