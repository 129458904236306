import { Injectable }     from '@angular/core';
import {  HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {CustomHttpClient} from './custom-http-client.service';
import * as jsPdf from 'jspdf';
import {LoaderService} from './loader.service';
import {promise} from 'selenium-webdriver';
import fulfilled = promise.fulfilled;
import {nullRemover} from './nullRemover';

@Injectable()
export class VisitsService {
  public practices: any;
  public practice_id = '';
  public visits: any;
  public keys_array: any;
  public statuses_count: any;
  public totalItems: any;
  public current_page = '1';
  public locations: any;
  public location_id = 'Locations';
  public doctors: Observable<any[]>;
  public doctor_id = '';
  public patient_name = '';
  public visit_type: any = 'all';
  public gender: any = 'gender';
  public appointment_type = 'Booking Type';
  public appointment_from = 'Booking From';
  public status_id = '';
  public start_date: any;
  public end_date: any;
  public visit_user_type = '';
  public doctor_name = '';
  public practice_name = 'All Practices';
  public sort_by = 'asc';
  public group_by: any = '0';
  public th_sort_by = 'appointments.date::date';
  public th_sort_asc_or_desc = '';
  public load_filter = false;
  public visitTypeMapping = { 0: 'Office Visit', 1: 'Video' }
  public groupByMapping = { 0: 'Select', 1: 'Doctor', 2: 'Visit Type', 3: 'Practice', 4: '', 5: '' }

  public calulcation_obj = {};
  public visit_array = [];
  public total_charges = 0;
  public total_payment = 0;
  public total_discount = 0;
  public total_balance = 0;

  public grand_total_charges = 0;
  public grand_total_payment = 0;
  public grand_total_discount = 0;
  public grand_total_balance = 0;

  public status_length: any;
  public disable_filter_test = false;


  public tblSorting = { 'appointments.date::date' : { order: 'desc', is_applied: true},
                        'locations.name' : { order: '', is_applied: false},
                        'appointments.appointment_type' : { order: '', is_applied: false},
                        'patients.name' : { order: '', is_applied: false},
                        'services.name' : { order: '', is_applied: false},
                        'appointments.status_id' : { order: '', is_applied: false},
                        'payments.amount' : { order: '', is_applied: false},
                        'doctors.first_name' : { order: '', is_applied: false }}

  constructor(public loader: LoaderService, private _tokenService: CustomHttpClient) {}

  getVisitFilters(): Promise<any> {
    return this._tokenService.get('visits/user_type_visit_filters')
      .toPromise()
      .then(response => this.setFilters(response));
  }

  setFilters(json_filters) {
    if (json_filters.user_type == 'Staff') {
      this.practice_name = json_filters.practice_name
      this.practice_id = json_filters.practices.id;
      this.defaultFilters(json_filters);
    }
    if (json_filters.user_type == 'Enterprise') {
      this.defaultFilters(json_filters);
    }
    if (json_filters.user_type == 'Doctor') {
      this.doctor_id = json_filters.doctor.id;
      this.doctor_name = json_filters.full_name;
      this.defaultFilters(json_filters);
    }
  }

  defaultFilters(json_filters) {
    this.locations = json_filters.locations;
    this.visit_user_type = json_filters.user_type;
    this.start_date = new Date();
    // this.start_date = new Date(this.start_date.setMonth(this.start_date.getMonth() - 1));
    this.start_date = new Date(this.start_date);
    this.end_date = new Date();
    this.end_date = new Date(this.end_date);
    this.load_filter = true;
  }

  getPractices(token) {
    let args = new HttpParams();
    args = args.set('token', token);
    return this._tokenService.get('visits/getPractices', { params: args}).share()
        .map(r => r);
  }

  getDoctors(token: string, practice_id?: any): Observable<any> {
    let args = new HttpParams();
    args = args.set('token', token);
    args = args.set('practice_id', practice_id);
    return this._tokenService.get('visits/practice_doctors', { params: args}).share()
      .map(r => r);
  }

  isFilterLoaded() {
    return this.load_filter;
  }

  visitAppointment(search_params) {
    let params = new HttpParams({fromObject: nullRemover(search_params)});
    this.loader.show();
    return this._tokenService.getWithHeaders('/visits/visits_appointments',
      {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
        .then(res => {
          this.loader.hide();
          return {
            total: res.headers.get('X-Total'),
            per_page: res.headers.get('X-Per-Page'),
            current_page: res.headers.get('X-Page'),
            total_pages: res.headers.get('X-Total-Pages'),
            previous_page: res.headers.get('X-Prev-Page'),
            filters: res.body
          }
        });
  }

  updateLocations(practice_id) {
    let args = new HttpParams();
    args = args.set('practice_id', practice_id);
    return this._tokenService.get('visits/selected_practice_locations', { params: args}).toPromise()
        .then(res => this.practiceLocation(res));
  }

  practiceLocation(json) {
    this.locations = json.locations;
  }

  officeStatusesList() {
    this.resetStatus();
    return [{ id: 'cancelled', name: 'cancelled' },
     { id: 'scheduled', name: 'scheduled' },
     { id: 'pending', name: 'pending' },
     { id: 'confirmed', name: 'confirmed' },
     { id: 'checkedin', name: 'checkedin' },
     { id: 'rescheduled', name: 'rescheduled' },
     { id: 'noshow', name: 'noshow' },
     { id: 'checkedout', name: 'checkedout' },
     { id: 'admitted', name: 'admitted' }
    ];
  }

  admittedStatusesList() {
    this.resetStatus();
    return [{ id: 'hospitalized', name: 'hospitalized' }, { id: 'discharged', name: 'discharged' }];
  }

  emergencyStatusesList() {
    this.resetStatus();
    return [{ id: 'checkedin', name: 'checkedin' }, { id: 'checkedout', name: 'checkedout' }, {id: 'cancelled', name: 'cancelled'}, {id: 'admitted', name: 'admitted'}];
  }

  resetStatus() {
    this.status_id = '';
  }

  resetLocation() {
    this.locations = [];
    this.location_id = 'Locations';
  }

  resetDoctor() {
    this.doctor_id = '';
    this.doctor_name = '';
  }

  resetPractice() {
    this.practice_id = '';
    this.practice_name = 'All Practices';
  }

  clearStatusOnChange() {
    this.status_id = '';
  }

  get practiceLocations() {
    return this.locations;
  }

  get searchParams() {
    let search_params = {
      practice_id: this.practice_id, location_id: this.location_id, doctor_id: this.doctor_id, gender: this.gender,
      patient_name: this.patient_name, visit_type: this.visit_type, appointment_type: this.appointment_type,
      status_id: this.status_id, start_date: this.start_date.toDateString(), end_date: this.end_date.toDateString(),
      sort_by: this.sort_by, group_by: this.group_by, th_sort_by: this.th_sort_by,
      th_sort_asc_or_desc: this.th_sort_asc_or_desc, page: this.current_page, appointment_from: this.appointment_from
    };
    return search_params;
  }
  reSetParams() {
    this.practice_id = '';
    this.location_id = 'Locations';
    this.doctor_id = '';
    this.gender = 'gender';
    this.patient_name = '';
    this.visit_type = 'all';
    this.appointment_type = 'Booking Type',
    this.status_id = '';
    this.start_date = new Date();
    this.end_date = new Date();
    this.sort_by = 'asc';
    this.group_by = '0';
    this.th_sort_by = 'appointments.date::date',
    this.th_sort_asc_or_desc = '';
    this.current_page = '1'
  }
  generatePdf(el) {
    let pdf = new jsPdf('p', 'in', 'a4');
    pdf.internal.scaleFactor = 150;
    let options = {
      format: 'PNG',
      pagesplit: true,
      width: 1155,
      margins: {top: 0, right: 0, bottom: 0, left: 0, }
    };
    pdf.addHTML(el.nativeElement, 0, 0, options, () => {
      pdf.save('Patient_Inflow.pdf');
    });
  }

  pchf_generatePdf(el) {
    let pdf = new jsPdf('p', 'in', 'a4');
    pdf.internal.scaleFactor = 150;
    let options = {
      format: 'PNG',
      pagesplit: true,
      width: 1155,
      margins: {top: 0, right: 0, bottom: 0, left: 0, }
    };
    pdf.addHTML(el.nativeElement, 0, 0, options, () => {
      pdf.save('Pchf_Print.pdf');
    });
  }

  applyOrder(filter, ordering) {
    return this.tblSorting[filter].is_applied && this.tblSorting[filter].order == ordering
  }

  ascendingOrder() {

  }

  tableHeadSorting(search_params) {
    let params = new HttpParams({fromObject: search_params});
    return this._tokenService.get('/visits/visits_appointments', {params: search_params}).toPromise()
        .then(res => res);
  }


  calculationOnGroupBy(key, item, is_reinitialize) {
    if (is_reinitialize) {
      this.reInitializeVariable();
    }
     if (key in this.calulcation_obj) {
       if (this.calulcation_obj[key] && this.calulcation_obj[key].length > 0) {
         this.calulcation_obj[key].push(item);
       }
     } else {
       this.visit_array.unshift(item);
       this.calulcation_obj[key] = this.visit_array;
     }

    this.calulcation_obj[key]['total_charges'] = this.total_charges += item.charges;
    this.calulcation_obj[key]['total_payment'] = this.total_payment += item.payment;
    this.calulcation_obj[key]['total_discount'] = this.total_discount += item.discount;
    this.calulcation_obj[key]['total_balance'] = this.total_balance += item.balance;
  }

  reInitializeVariable() {
    this.visit_array = [];
    this.total_charges = 0;
    this.total_payment = 0;
    this.total_discount = 0;
    this.total_balance = 0;
  }

  doctorShare(key) {
   return (this.visits[key][0].share / 100) * (this.visits[key].total_payment)
  }

  generateKeyValue() {
    if (this.group_by > 0) {
      this.visits.forEach((item, index) => {
        let key = item.group_key;
        if (key in this.calulcation_obj) {
          this.calculationOnGroupBy(key, item, false);
        } else {
          this.calculationOnGroupBy(key, item, true);
        }
      });
      return this.calulcation_obj;
    } else {
      this.visits.forEach((item, index) => {
        let key = 'All';
        if (key in this.calulcation_obj) {
          this.calculationOnGroupBy(key, item, false);
        } else {
          this.calculationOnGroupBy(key, item, true);
        }
      });
      return this.calulcation_obj;
    }
  }

  resetClientSideKeys() {
    this.visit_array = [];
    this.calulcation_obj = {};
  }

  applySortingKeys(sorting_by) {
    this.resetClientSideKeys();
    Object.keys(this.tblSorting).forEach(key => {
      if (sorting_by != key) {
        this.tblSorting[key].order = ''
      }
      this.tblSorting[key].is_applied = false;
    });
    this.applySorting(sorting_by);
  }

  applySorting(sorting_by) {
    let ordering = this.setOrder(sorting_by);
    this.tblSorting[sorting_by].order = ordering
    this.tblSorting[sorting_by].is_applied = true;
    this.th_sort_by = sorting_by;
    this.th_sort_asc_or_desc = ordering;
  }

  setOrder(sorting_by) {
    let ordering = this.tblSorting[sorting_by].order;
    ordering = ordering == '' ? 'desc' : (ordering == 'desc' ? 'asc' : 'desc')
    return ordering;
  }

  applyFilters() {

    this.visitAppointment(this.searchParams).then(visits => {
      // this.statuses_count = visits.filters.splice(-1,1);
      this.totalItems = visits.total;
      this.current_page = visits.current_page;
      this.visits = visits.filters;
      this.visits = this.generateKeyValue();
      this.keys_array = Object.keys(this.visits);
      this.grandTotal();
    });
  }

  grandTotal() {
    this.reInitializeGrandTotal();
    this.keys_array.forEach(key => {
      this.grand_total_charges = this.grand_total_charges += this.visits[key].total_charges;
      this.grand_total_payment = this.grand_total_payment += this.visits[key].total_payment;
      this.grand_total_discount = this.grand_total_discount += this.visits[key].total_discount;
      this.grand_total_balance = this.grand_total_balance += this.visits[key].total_balance;
    });
  }

  reInitializeGrandTotal() {
    this.grand_total_charges = 0;
    this.grand_total_payment = 0;
    this.grand_total_discount = 0;
    this.grand_total_balance = 0;
  }

  statusLength() {
    if (this.visit_type == 1) {
      if (this.status_id.length > 0) {
        let test =  ['cancelled', 'scheduled', 'pending', 'confirmed', 'checkedin', 'rescheduled', 'noshow', 'checkedout', 'admitted'];
        let stat = this.status_id.split(',');

        if (this.checkFilterStatus(stat, test)) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (this.visit_type == 0) {
      if (this.status_id.length > 0) {
        let test =  ['discharged', 'hospitalized'];
        let statuses =  this.status_id.split(',');
        if (this.checkFilterStatus(statuses, test)) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (this.visit_type == 2) {
      if (this.status_id.length > 0) {
        let test =  ['checkedin', 'checkedout'];
        let statuses =  this.status_id.split(',');
        if (this.checkFilterStatus(statuses, test)) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  checkFilterStatus(stat, test) {
    return !stat.some(function(item) {
      return test.indexOf(item) === -1;
    });
  }

  statusValue(status) {
    // if(this.statuses_count && this.statuses_count[0]){
    //   return this.statuses_count[0].statuses[status] ? this.statuses_count[0].statuses[status].length : 0
    // } else {
    //   return 0;
    // }
    if (this.statuses_count && this.statuses_count[0]) {
      return this.statuses_count[0].statuses[status] ? this.statuses_count[0].statuses[status] : 0
    } else {
      return 0;
    }
  }

  dailySummaryReportValid() {
    return this.statuses_count && this.statuses_count.length > 0 && this.statuses_count[0].hasOwnProperty('statuses')
  }
}
