import {Role} from '../roles/role';
import {RoleType} from '../permissions/permissions.service';
export class Announcement {
  id: number;
  name: string;
  description: string;
  created_at: Date;
  role_type_id: number;
  role_id: number;
  roles: Role[];
  status: any;
  roleType: RoleType;
  selected_roles: any[] = [];

  test() {
    setTimeout(() => {
      this.status = this.status == 'active'  ? 'inactive' : 'active';
    }, (10 * 1000))
  }
  to_params() {
    return {
      announcement: {
        id: this.id,
        name: this.name,
        description: this.description,
        role_type_id: this.role_type_id,
        status: this.status,
        role_ids: this.selected_roles.map(role => role.id),
      }
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.status = json.status;
    this.name = json.name;
    if (json.created_at) {
    this.created_at = new Date(json.created_at)
    }
    this.description = json.description;
    if (json.roles) {
    this.selected_roles = json.roles.map(role => {return {'id': role.id, 'itemName': role.name}});
    }
    if (json.role_type) {
      this.roleType = new RoleType().load_from_json(json.role_type);
    }
    this.role_type_id = json.role_type_id;
    return this;
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
  get_url(): string {
    return this.is_new_record() ? 'announcements/' : ['announcements', this.id].join('/')
  }
}
