import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class LoaderService {
  public  visible: Boolean = false;
  public uploading_in_process = false;

  constructor() { }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
    return true;
  }

  small_loder_show() {
   this.uploading_in_process = true; }

  small_loder_hide() {
    this.uploading_in_process = false;

  }

}
