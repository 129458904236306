<div class="flex-control flex-height" *ngIf="dashboardData">
  <div class="flex-left">
    <div class="p-lg">
      <div class="user-profile-pic thumbnail"
           [ngStyle]="{'background-image': 'url(' + userService.current_user.profile_pic + ')'}">
      </div>
      <h2
        class="user-name text-center text-secondary text-capitalize mt m0">{{ userService.current_user.full_name }}</h2>
      <h5
        class="fs16 m0 mt-sm text-capitalize text-center fw-300">{{ userService.current_user.profileable?.designation }}</h5>
    </div>
    <div>
      <h4 class="title text-black m0">Eligible Patients
        <span class="fs16">{{ dashboardData.eligible_patients > 0 ? dashboardData.eligible_patients : '0' }}</span>
      </h4>
      <ul class="border-list list-unstyled">
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-user-following fs16 mr vl-m"></i> Enrolled</h5>
          <h4
            class="m0 fw-300 fs14">{{ dashboardData.enrolled_patients > 0 ? dashboardData.enrolled_patients : '0' }}</h4>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-user-unfollow fs16 mr vl-m"></i> Not Enrolled</h5>
          <h4
            class="m0 fw-300 fs14">{{ dashboardData.not_enrolled_patients > 0 ? dashboardData.not_enrolled_patients : '0' }}</h4>
        </li>
      </ul>
    </div>
  </div>
  <div class="flex-body pt-lg pr-lg pl-lg scroll-browser">
    <h4 class="title text-black m0 mb p0">Care Management</h4>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-3">
        <div class="border-box shadow p" style="background:#bad5fb;">
          <h4 class="m0 mb-xl text-center fs16"><i class="fa icon-people vl-m mr fs18"></i>
            Patients
          </h4>
          <h4 class="m0 mb-xl text-center fs16"></h4>
          <div class="row">
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Ready To Enroll</h5>
              <h4
                class="m0 fw-300 fs16">{{ dashboardData.ready_to_enrol_patients > 0 ? dashboardData.ready_to_enrol_patients : '0' }}</h4>
            </div>
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Visit Needed</h5>
              <h4
                class="m0 fw-300 fs16">{{ dashboardData.visit_required_patients > 0 ? dashboardData.visit_required_patients : '0' }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-3">
        <div class="border-box shadow p" style="background:#fbd1bb;">
          <h4 class="m0 mb-xl text-center fs16"><i class="fa icon-check vl-m mr fs18"></i>Compliance
          </h4>
          <div class="row">
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Non Compliant</h5>
              <h4 class="m0 fw-300 fs16">{{ dashboardData?.non_compliant }}</h4>
            </div>
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Partially Compliant</h5>
              <h4 class="m0 fw-300 fs16">{{ dashboardData?.partially_compliant }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-3">
        <div class="border-box shadow p" style="background: #b6f8ef;">
          <h4 class="m0 mb-xl text-center fs16"><i class="fa icon-heart vl-m mr fs18"></i>Health Status
          </h4>
          <div class="row">
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Critical</h5>
              <h4 class="m0 fw-300 fs16">{{ dashboardData?.critical }}</h4>
            </div>
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Stable</h5>
              <h4 class="m0 fw-300 fs16">{{ dashboardData?.stable }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-3">
        <div class="border-box shadow p" style="background: #fff7c8;">
          <h4 class="m0 mb-xl text-center fs16"><i class="fa icon-wallet vl-m mr fs18"></i>Revenue</h4>
          <div class="row">
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Current Month</h5>
              <h4 class="m0 fw-300 fs16">{{ dashboardData.current_month_revenue | currency: 'USD': '$':'1.0-0' }}</h4>
            </div>
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Previous Month</h5>
              <h4 class="m0 fw-300 fs16">{{ dashboardData.previous_month_revenue | currency: 'USD': '$':'1.0-0' }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ul class="flex-header pd-custom-tabs nav nav-pills pb0 mt-lg mb">
        <!--        ***** Tabs *****-->
        <li [class.active]="showTab == 'myTask'">
          <button class="btn" (click)="showTab = 'myTask'"> My Tasks</button>
        </li>
        <li [class.active]="showTab == 'byProgram'">
          <button class="btn" (click)="showTab = 'byProgram'"> Patient by Program</button>
        </li>
        <li [class.active]="showTab == 'overTime'">
          <button class="btn" (click)="showTab = 'overTime'">Progress over Time</button>
        </li>
        <li *ngIf="showTab == 'byProgram'" class="month-filter">
          <label class="fw-500 form-control border-none ">Filter By Month:
          <input [bsConfig]="{ minMode:'month',dateInputFormat: 'MM-YYYY', clearBtn: true}"
                 [(ngModel)]="selectMonth"
                 (ngModelChange)="setMonthAndYear($event)"
                 [maxDate]="maxDate"
                 bsDatepicker
                 placement="left"
                 class="fw-400 border-none"
                 placeholder="Month">
            <i class="fa icon-calendar vl-m mr fs18 text-secondary"></i>
          </label>
        </li>
      </ul>
    </div>

    <div class="flex-body scroll-browser pt-0">
      <ng-container *ngIf="showTab == 'myTask'">
        <app-tasksfilter class="flex-control flex-column scroll-browser" [isProgramDashboard]="true"></app-tasksfilter>
      </ng-container>

      <ng-container *ngIf="showTab == 'byProgram'">

        <h4 class="title text-black m0 mb p0">CMS Programs</h4>


        <table class="table table-hover m0">
          <thead>
          <tr>
            <th>Program</th>
            <th>Enrolled</th>
            <th>< 16 Readings</th>
            <th>> 60 Mins</th>
            <th>40-59 Mins</th>
            <th>30-39 Mins</th>
            <th>20-29 Mins</th>
            <th>10-19 Mins</th>
            <th>1-9 Mins</th>
            <th>0 Min</th>
          </tr>
          </thead>
          <tbody>

          <tr *ngFor="let program of cmsData">
            <td>{{ program.programme }}</td>
            <td>{{ program.enrolled }}</td>
            <td>{{ program.less_than_16_reading }}</td>
            <td>{{ program.greater_than_60_min }}</td>
            <td>{{ program.between_40_to_59_min }}</td>
            <td>{{ program.between_30_to_39_min }}</td>
            <td>{{ program.between_20_to_29_min }}</td>
            <td>{{ program.between_10_to_19_min }}</td>
            <td>{{ program.between_1_to_9_min }}</td>
            <td>{{ program.zero_min }}</td>
          </tr>
          </tbody>
        </table>
        <app-no-result-found *ngIf="cmsData.length == 0"></app-no-result-found>
        <h4 class="title text-black mt-lg mb p0">Private Pay Programs</h4>
        <table class="table table-hover m0">
          <thead>
          <tr>
            <th>Program</th>
            <th>Enrolled</th>
            <th>< 16 Readings</th>
            <th>> 60 Mins</th>
            <th>40-59 Mins</th>
            <th>30-39 Mins</th>
            <th>20-29 Mins</th>
            <th>10-19 Mins</th>
            <th>1-9 Mins</th>
            <th>0 Min</th>
          </tr>
          </thead>
          <tbody>

          <tr *ngFor="let program of privateTableData">
            <td>{{ program.programme }}</td>
            <td>{{ program.enrolled }}</td>
            <td>{{ program.less_than_16_reading }}</td>
            <td>{{ program.greater_than_60_min }}</td>
            <td>{{ program.between_40_to_59_min }}</td>
            <td>{{ program.between_30_to_39_min }}</td>
            <td>{{ program.between_20_to_29_min }}</td>
            <td>{{ program.between_10_to_19_min }}</td>
            <td>{{ program.between_1_to_9_min }}</td>
            <td>{{ program.zero_min }}</td>
          </tr>
          </tbody>
        </table>
        <app-no-result-found *ngIf="privateTableData.length == 0"></app-no-result-found>
      </ng-container>

      <ng-container *ngIf="showTab == 'overTime'">
        <div class="border-box shadow p">
          <div class="row">
            <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-md-5 col-sm-5 col-xs-6 form-group">
                  <select name="duration" id="" class="form-control" #duration_field
                          (change)="duration = duration_field.value;change_duration(selected_type);">
                    <option value="3">In Last 3 months</option>
                    <option value="6">In Last 6 months</option>
                    <option value="12">In Last 12 months</option>
                    <option value="24">In Last 2 years</option>
                    <option value=''>In All Years</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-9 col-xs-9">
              <ul class="graph-tabs">
                <li [class.active]="selected_type == 'month'" (click)="change_duration('month',true);">Monthly</li>
                <li [class.active]="selected_type == 'quarter'" (click)="change_duration('quarter',true);">Quartly</li>
                <li [class.active]="selected_type == 'year'" (click)="change_duration('year',true);">Yearly</li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-1 col-sm-3 col-xs-3 text-right">
              <button class="btn btn-secondary btn-outline btn-sm">
            <span (click)="randomizeType()" class="fa fs20 fa-bar-chart" [class.fa-bar-chart]="lineChartType == 'line'"
                  [class.fa-area-chart]="lineChartType == 'bar'"></span>
              </button>
            </div>
          </div>
          <div class="mt">
            <canvas baseChart
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [chartType]="lineChartType"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)" style="height: 350px"></canvas>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
