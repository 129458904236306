import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {AppointmentService} from '../../../services/appointment.service';
import {Appointment} from '../../../health/models/appointment';
import {PracticesService} from '../../../services/practices.service';
import {Practice} from '../../practices/practices/practice';
import {DoctorService} from '../../../services/doctor.service';
import {Doctor} from '../../practices/doctors/doctor';
import {UserService} from '../../../services/user.service';
import { SelectComponent } from 'ng2-select'
import {LoaderService} from '../../../services/loader.service';
import {NgSelectComponent} from '@ng-select/ng-select';

@Component({
  selector: 'app-appointment-listing',
  templateUrl: './appointment-listing.component.html',
  styleUrls: ['./appointment-listing.component.scss']
})
export class AppointmentListingComponent implements OnInit {
  @ViewChild('practiceInput') practiceInput: NgSelectComponent;
  @ViewChild('doctorInput') doctorInput: NgSelectComponent;

  public filter: Record<string, any> = {doctor_id: '', practice_id: '', status_id: '0', date: '', location_id: '', page: '1'};
  public appointments: Appointment[];
  public params: Object = {reason_of_cancellation: '', appointments_ids: [], status_id: '', page: '1'};
  public practices: any[];
  public doctors: Doctor[];
  public totalItems: string;
  public status_array = [{2: 'Cancelled'}, {3: 'Acknowledged'}];
  @Input() providers;
  @Input() locations;
  constructor(private loader: LoaderService,
              public userService: UserService,
              private _doctorService: DoctorService,
              private _appointmentService: AppointmentService,
              private _practicesService: PracticesService) {
  }

  ngOnInit() {
    this.getAppointments();
  }
  getPractices() {
    this._practicesService.getPractices().then(resp => {
      this.practices = resp.map(practice => new Practice().load_from_json(practice))
    })
  }
  getAppointments() {
    this.loader.show();
    this._appointmentService.getPendingAppointments(this.filter).then(resp => {
      this.appointments = resp.appointments.map(appointment => new Appointment().load_from_json(appointment));
        this.totalItems = resp.total;
        this.filter['page'] = resp.current_page;
      this.loader.hide();
    }

    )
  }
  typedPractice(text: { term: string; practices: any }) {
    this._practicesService.searchPractices(text.term).subscribe(practices => {
      return this.practices = practices.map(practice => {return {id: practice.id, text: practice.name}})
    });
  }
  selectedPractice(practice) {
  this.filter['practice_id'] = practice.id;
  }
  removePractice(event) {
    this.filter['practice_id'] = '';
  }
  typedDoctor(text: { term: string; doctors: Doctor[] }) {
    if (this.filter['practice_id'] == '') {
      this._doctorService.searchDoctor(text.term).subscribe(doctors => {
        return this.doctors = doctors.map(doctor => {return {id: doctor.id, text: doctor.name}})
      });
    }
    else {
      this._doctorService.getAllDoctorForPractice(this.filter['practice_id']).then(doctors => {
        return this.doctors = doctors.map(doctor => {return {id: doctor.id, text: doctor.name}});
      } )
    }
  }
  selectedDoctor(doctor) {
    this.filter['doctor_id'] = doctor.id;
  }
  selectStatus(status) {
    this.filter['status_id'] = status.value;

  }
  filterAppointments() {
    this.getAppointments();
  }
  acknowledgeAll(appointments?) {
    this.params['status_id'] = 3
    this.params['appointments_ids'] = Appointment.getIds(this.appointments)
    this._appointmentService.update(this.params).then(resp => {
      let appointments = resp.map(appointment => new Appointment().load_from_json(appointment));
      appointments.map(appointment => {
        this.appointments.splice(this.appointments.indexOf(appointment), 1);
      });
    })
  }
  pageChanged(page) {
    this.filter['page'] = page;
    this.getAppointments();
  }
  removeDoctor(event) {
    this.filter['doctor_id'] = '';
  }
  change_provider(id) {
    if (id != 0 ) {
     this.filter['doctor_id'] = id;
    }
    else {
      this.filter['doctor_id'] = ''
    }
  }

  change_location(id) {
    if (id != 0 ) {
      this.filter['location_id'] = id;
    }
    else {
      this.filter['location_id'] = ''
    }
  }
}
