<div class="flex-control flex-column flex-height" *ngIf="reminder">
  <form class="flex-body p scroll-browser" (ngSubmit)="save()" #reminderForm="ngForm">
    <div class="row form-group">
      <label class="col-sm-2 field-label control-label">Name*</label>
      <div class="col-sm-4">
              <textarea  cols="120" rows="1" type="text" class="form-control" id="task_name"
                         [(ngModel)]="reminder.name"
                         name="name"
                         required>
              </textarea>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-2 field-label control-label">Type*</label>
      <div class="col-sm-3">
        <select [(ngModel)]="reminder.reminder_type" [disabled]="reminder.source === 'system'" name="reminder_type" class="form-control" required>
          <option value="">Reminders Type</option>
          <option value='notification'>Notification</option>
          <option value='reminder'>Reminder</option>
          <option value='birthday'>Birthday</option>
          <option value='recall'>Recall</option>
        </select>
      </div>
    </div>

    <div class="row form-group" *ngIf="reminder.reminder_type == 'recall' || reminder.reminder_type == 'notification' || reminder.reminder_type == 'reminder'">
      <label class="col-sm-2 field-label control-label">Statues</label>
      <div class="col-sm-3 status-field">
        <ss-multiselect-dropdown [options]="myOptions"
                                 name="statues"
                                 [disabled]="reminder.source === 'system'"
                                 [(ngModel)]="optionsModel"
                                 (ngModelChange)="selectStatuses($event)">
        </ss-multiselect-dropdown>
      </div>
    </div>

    <div class="row form-group" *ngIf="reminder.reminder_type == 'recall' || reminder.reminder_type == 'notification' || reminder.reminder_type == 'reminder'">
      <label class="col-sm-2 field-label control-label">Reason of Visit</label>
      <div class="col-sm-10">
        <ng-select bindLabel="text" name="servie"
                   [multiple]="true"
                   [disabled]="reminder.source === 'system'"
                   [(ngModel)]="selectedServices"
                   [items]="total_services"
                   (add)="add_selected_service($event)"
                   (remove)="removeService($event)"
                   (search)="typedServices($event)"
                   placeholder="No Reason of visit Selected"></ng-select>
      </div>
    </div>
    <div class="row form-group" *ngIf="reminder.reminder_type == 'recall' || reminder.reminder_type == 'notification' || reminder.reminder_type == 'reminder'">
      <label class="col-sm-2 field-label control-label">Services</label>
      <div class="col-sm-10">
        <ng-select bindLabel="text" name="amenity"
                   [multiple]="true"
                   [disabled]="reminder.source === 'system'"
                   [(ngModel)]="selectedAmenities"
                   [items]="total_amenities"
                   (add)="add_selected_amenity($event)"
                   (remove)="removeAmenity($event)"
                   (search)="typedAmenities($event)"
                   placeholder="No Service Selected"></ng-select>
      </div>
    </div>
    <div class="row form-group" *ngIf="reminder.reminder_type == 'birthday'">
      <label class="col-sm-2 field-label control-label">Trigger at</label>
      <div class="col-sm-3">
        <select [disabled]="reminder.source === 'system'"  class="form-control pl0" name="status_id" [(ngModel)]="reminder.trigger_ref">
          <option *ngFor="let trigger of btrigger; let i = index;" [ngValue]="trigger.value">
            {{trigger.name}}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-group" *ngIf="reminder.reminder_type == 'notification' || reminder.reminder_type == 'reminder' || reminder.reminder_type == 'recall'">
      <label class="col-sm-2 field-label control-label">Trigger On</label>
      <div class="col-sm-10">
        <label class="pr col-sm-1">
          <input type="number" [disabled]="reminder.source === 'system'"
                 class="form-control"
                 [(ngModel)]="reminder.trigger_time_value"
                 name="trigger_time_value">
        </label>

        <label class="pr" *ngIf="reminder.reminder_type == 'notification' || reminder.reminder_type == 'reminder'">
          <select [disabled]="reminder.source === 'system'"  class="form-control pl0" name="status_id" [(ngModel)]="reminder.trigger_time_type">
            <option *ngFor="let trigger of atrigger; let i = index;" [ngValue]="trigger.value">
              {{trigger.name}}
            </option>
          </select>
        </label>

        <label class="pr" *ngIf="reminder.reminder_type == 'recall'">
          <select [disabled]="reminder.source === 'system'"  class="form-control pl0" name="status_id" [(ngModel)]="reminder.trigger_time_type">
            <option *ngFor="let trigger of rtrigger; let i = index;" [ngValue]="trigger.value">
              {{trigger.name}}
            </option>
          </select>
        </label>
        <label class="pr" *ngIf="reminder.reminder_type == 'notification' || reminder.reminder_type == 'reminder'">
          <select [disabled]="reminder.source === 'system'" name="occurrence_rule" id="sadfasdf" class="form-control" [(ngModel)]="reminder.occurrence_rule">
            <option *ngFor="let rule of rules;let i = index;"
                    [selected]="reminder.occurrence_rule == rule.value"
                    [ngValue]="rule.value">{{rule.name}}
            </option>
          </select>
        </label>

        <label class="pr" *ngIf="reminder.reminder_type == 'recall'">
          <select [disabled]="reminder.source === 'system'" name="occurrence_rule" class="form-control" [(ngModel)]="reminder.occurrence_rule">
            <option value='after' [selected]="true">After</option>
          </select>
        </label>

        <label class="pr" *ngIf="reminder.statuses.length != 0 && reminder.reminder_type != 'recall'" >
          <select [disabled]="reminder.source === 'system'" [(ngModel)]="reminder.trigger_ref" name="ref" class="form-control" >
            <option value='appointment_datetime'>Appointment date/time</option>
            <option value='status_change_datetime'>Status Change Date/time</option>
          </select>
        </label>


        <label class="pr" *ngIf="reminder.statuses.length == 0 || reminder.reminder_type === 'recall'">
          <select [disabled]="reminder.source === 'system'" [(ngModel)]="reminder.trigger_ref" name="ref" class="form-control" >
            <option value='appointment_datetime'>Appointment date/time</option>
          </select>
        </label>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-2 field-label control-label">Outreach*</div>
      <div class="col-sm-10">
        <label class="pr">
          <input type="checkbox" name="email" value="email" [(ngModel)]="reminder.outreach_email">
          Email
        </label>
        <label>
          <input type="checkbox" name="sms" value="sms" [(ngModel)]="reminder.outreach_sms">
          SMS
        </label>
        <p>
          <label>
            <input type="checkbox" name="patient_preference_method" value="patient_preference_method" [(ngModel)]="reminder.patient_preferred_method">
            Apply Patient Preferred method of communication
          </label>
        </p>
      </div>
    </div>

    <div class="row form-group" *ngIf="reminder.outreach_email">
      <label class="col-sm-2 field-label control-label">Subject*</label>
      <div class="col-sm-4">
              <textarea  cols="120" rows="1" type="text" class="form-control" id="subject"
                         [(ngModel)]="reminder.email_subject"
                         name="subject"
                         [required]="reminder.outreach_email">
              </textarea>
      </div>
    </div>
    <div class="row form-group" *ngIf="reminder.outreach_email">
      <label class="col-sm-2 field-label control-label">Email*</label>
      <div class="col-sm-10">
        <label *ngIf="reminder.reminder_type == 'recall' || reminder.reminder_type == 'notification' || reminder.reminder_type == 'reminder'">
          <select  class="form-control pl0" name="status_id" (change)="insertAtCursor('[[[' + $event.target.value + ']]]');">
            <option *ngFor="let custom_field of a_custom_fields; let i = index;" [value]="custom_field">
              {{custom_field}}
            </option>
          </select>
        </label>

        <label *ngIf="reminder.reminder_type == 'birthday'">
          <select  class="form-control pl0" name="status_id" (change)="insertAtCursor('[[[' + $event.target.value + ']]]');">
            <option *ngFor="let custom_field of b_custom_fields; let i = index;" [value]="custom_field">
              {{custom_field}}
            </option>
          </select>
        </label>

        <p>
          <editor #editor
                  apiKey="no-api-key"
                  id="email_body"
                  name="email_body"
                  [(ngModel)]="reminder.email_body"
                  [init]="init_tiny"
                  required
          ></editor>
        </p>
      </div>
    </div>

    <div class="row form-group" *ngIf="reminder.outreach_sms">
      <label class="col-sm-2 field-label control-label">SMS</label>
      <div class="col-sm-10">
        <label *ngIf="reminder.reminder_type == 'recall' || reminder.reminder_type == 'notification' || reminder.reminder_type == 'reminder'">
          <select  class="form-control pl0" name="status_id" (change)="insertAtCursorSmS('[[[' + $event.target.value + ']]]');">
            <option *ngFor="let custom_field of a_custom_fields; let i = index;" [value]="custom_field">
              {{custom_field}}
            </option>
          </select>
        </label>

        <label *ngIf="reminder.reminder_type == 'birthday'">
          <select  class="form-control pl0" name="status_id" (change)="insertAtCursorSmS('[[[' + $event.target.value + ']]]');">
            <option *ngFor="let custom_field of b_custom_fields; let i = index;" [value]="custom_field">
              {{custom_field}}
            </option>
          </select>
        </label>
        <p>
              <textarea  #s_body cols="120" rows="6" type="text" class="form-control" id="s_body"
                         [(ngModel)]="reminder.sms_body"
                         name="sms_body" required>
              </textarea>
        </p>
      </div>
    </div>

    <div class="row form-group">
      <label class="col-sm-2 field-label control-label"></label>
      <div class="col-sm-10">
        <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
        <button type="submit" class="btn btn-secondary mr" [disabled]="!reminderForm.form.valid" *ngIf="!loaderService.uploading_in_process">{{reminder.id ? 'Update' : 'Create'}}</button>
        <a class="btn btn-danger btn-outline btn-sm mr" tooltip="Delete" *ngIf="reminder.deleteable" (click)="deleteTemplate(reminder)">
          Delete
        </a>
        <button class="btn btn-tertiary btn-outline" (click)="closeNewForm()">Cancel</button>
      </div>
    </div>
    <app-attachments
       (popup_closed)="show_attachment_popup= false" [fileType]="'profile_pic'"
       [only_modal]="true" [auto_upload]="true" [attachable_id]="reminder.id"
       [attachable_type]="'ReminderTemplate'"
       [show]="show_attachment_popup">
    </app-attachments>
  </form>
</div>
