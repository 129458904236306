<div class="flex-header filters-sec">
  <div class="row">

    <div class="col-sm-2 col-xs-6">
      <input class="form-control" [(ngModel)]="filter['keyword']" placeholder="Keyword">
    </div>

    <div class="col-sm-2 col-xs-6">
      <select [(ngModel)]="filter['patient_note_type_id']" class="form-control">
        <option value="undefined">Type</option>
        <option *ngFor="let patient_note_type of patient_note_types; let i = index;" [ngValue]="patient_note_type.id">
          {{patient_note_type.name}}
        </option>
      </select>
    </div>

    <div class="col-sm-6 col-xs-12">
      <button type="button" class="btn btn-tertiary btn-outline" (click)="search()" ><i class="fa fa-filter"></i> Filter</button>
      <button class="btn btn-secondary ml-sm" type="button" (click)="addNewNoteInPopup()">Add</button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
      <tr>
        <th>Note</th>
        <th>Time Spent</th>
        <th>Type</th>
        <th>By</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngIf="patient_notes_list_array && patient_notes_list_array.length == 0"><td colspan="12"><app-no-result-found></app-no-result-found></td></tr>
    <ng-container *ngFor="let patient_note of patient_notes_list_array">
      <tr>
        <td><a class="text-primary" (click)="edit_patient_note(patient_note)">{{patient_note.note_body}}</a></td>
        <td>{{patient_note.time_spent}}</td>
        <td>{{patient_note.type}}</td>
        <td>{{patient_note.created_by}}</td>
        <td>{{patient_note.created_at |  date:'mediumDate'}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
<div class="flex-footer text-center" *ngIf="patient_notes_list_array && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
<div class="modal fade" bsModal #newNoteModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog" style="height:400px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newNoteModel.hide(); show_node_model = false;"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Note</h4>
      </div>
      <ng-container *ngIf="show_node_model">
        <ng-container *ngComponentOutlet="newNoteComponent; injector: custom_injector;"></ng-container>
      </ng-container>
    </div>
  </div>
</div>
