<h4>Recipients <span class="text-primary">{{campaign.valid_audience}}</span> </h4>
<hr class="hr-line mt">
<div class="row">
  <div class="col-md-2 col-sm-3 col-xs-12">
    <a class="white-container-box media audience-sec" [ngClass]="filter['status'] === 'sent' ? 'active' : ''" (click)="campaignRecipients(campaign.id,'sent')">
      <div class="media-left media-middle"><i class="fa icon-user"></i></div>
      <div class="media-body media-middle text-center">
        <h5 class="text-black">Sent</h5>
        <h4>{{recipients.sent}}</h4>
      </div>
    </a>
  </div>
  <ng-container *ngIf="campaign.outreach_type === 'email'">
    <div class="col-md-2 col-sm-3 col-xs-12">
      <a class="white-container-box media audience-sec" [ngClass]="filter['status'] === 'opened' ? 'active' : ''" (click)="campaignRecipients(campaign.id,'opened')">
        <div class="media-left media-middle"><i class="fa icon-user"></i></div>
        <div class="media-body media-middle text-center">
          <h5 class="text-black">Open</h5>
          <h4>{{recipients.open}}</h4>
        </div>
      </a>
    </div>
    <div class="col-md-2 col-sm-3 col-xs-12">
      <a class="white-container-box media audience-sec" [class.active]="filter['status'] === 'clicked'" (click)="clickedData(campaign.id,'clicked')">
        <div class="media-left media-middle"><i class="fa fa-hand-o-up"></i></div>
        <div class="media-body media-middle text-center">
          <h5 class="text-black">Clicks</h5>
          <h4>{{recipients.click}}</h4>
        </div>
      </a>
    </div>
    <div class="col-md-2 col-sm-3 col-xs-12">
      <a class="white-container-box media audience-sec" [ngClass]="filter['status'] === 'bounced' ? 'active' : ''" (click)="campaignRecipients(campaign.id,'bounced')">
        <div class="media-left media-middle"><i class="fa icon-ban"></i></div>
        <div class="media-body media-middle text-center">
          <h5 class="text-black">Bounced</h5>
          <h4>{{recipients.bounced}}</h4>
        </div>
      </a>
    </div>
    <div class="col-md-2 col-sm-3 col-xs-12">
      <a class="white-container-box media audience-sec" [ngClass]="filter['status'] === 'unsubscribed' ? 'active' : ''" (click)="campaignRecipients(campaign.id,'unsubscribed')">
        <div class="media-left media-middle"><i class="fa icon-user-unfollow"></i></div>
        <div class="media-body media-middle text-center">
          <h5 class="text-black">Unsubscribed</h5>
          <h4>{{recipients.unsubscribe}}</h4>
        </div>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="campaign.outreach_type === 'sms'">
    <div class="col-md-2 col-sm-3 col-xs-12">
      <a class="white-container-box media audience-sec" [ngClass]="filter['status'] === 'delivered' ? 'active' : ''" (click)="campaignRecipients(campaign.id,'delivered')">
        <div class="media-left media-middle"><i class="fa icon-user-unfollow"></i></div>
        <div class="media-body media-middle text-center">
          <h5 class="text-black">Delivered</h5>
          <h4>{{recipients.delivered}}</h4>
        </div>
      </a>
    </div>
    <div class="col-md-2 col-sm-3 col-xs-12">
      <a class="white-container-box media audience-sec" [ngClass]="filter['status'] === 'failed' ? 'active' : ''" (click)="campaignRecipients(campaign.id,'failed')">
        <div class="media-left media-middle"><i class="fa icon-user-unfollow"></i></div>
        <div class="media-body media-middle text-center">
          <h5 class="text-black">Failed</h5>
          <h4>{{recipients.failed}}</h4>
        </div>
      </a>
    </div>
  </ng-container>
</div>


<span *ngIf="showLinksTable">
  <table class="table table-hover table-striped" >
    <thead>
    <tr>
      <th>Link</th>
      <th class="text-center">Total Clicks</th>
      <th class="text-center">Unique Clicks</th>
    </tr>
    </thead>
    <tbody *ngIf="clickedLinks && clickedLinks.length > 0">
    <tr *ngFor="let clickedLink of clickedLinks">
      <td><a (click)="campaignRecipients(campaign.id,'clicked',clickedLink.link)">{{clickedLink.link}}</a></td>
      <td class="text-center">{{clickedLink.total_clicks}}</td>
      <td class="text-center">{{clickedLink.uniq_clicks}}</td>
    </tr>
    </tbody>
  </table>
  <ng-container><app-no-result-found *ngIf="!clickedLinks && clickedLinks.length < 1"></app-no-result-found></ng-container>
</span>


<app-email-campaign-recipients-list *ngIf="!showLinksTable" (closed)="showLinksTable =! showLinksTable" [campaign]="campaign" [totalItems]="totalItems" [filter]="filter" [recipientsList]="recipientsList"></app-email-campaign-recipients-list>
