export class Amenity {
  id: number;
  name: string;
  amenity_methods: BookingMethod[] = [];
  short_description: string;
  long_description: string;
  no_of_bookings_per_day: number;
  duration: number;
  auto_acknowledge: boolean = true;
  payment_method: string;
  applicable_patients : string = 'all_patients';
  status : string = 'active';
  created_on : string;
  created_by : string;
  modified_on : string;
  modified_by : string;
  questionnaire: any;
  offset_time: string;
  offset_type = 'mins';
  load_from_json(json) {
    this.id = json.id;
    this.offset_time = json.offset_time;
    this.offset_type = json.offset_type;
    this.name = json.name;
    this.amenity_methods = json.amenity_methods.map(a => new BookingMethod().load_from_json_for_amenities_methods(a));
    this.short_description = json.short_description;
    this.long_description = json.long_description;
    this.no_of_bookings_per_day = json.no_of_bookings_per_day;
    this.duration = json.duration;
    this.auto_acknowledge = json.auto_acknowledge;
    this.payment_method = json.payment_method;
    this.applicable_patients = json.applicable_patients;
    this.created_on = json.created_on;
    this.created_by = json.created_by;
    this.modified_on = json.modified_on;
    this.modified_by = json.modified_by;
    this.questionnaire = json.questionnaire;
    this.status = json.status;
    return this;
  }

  to_params() {
    return {
      amenity: {
        id: this.id,
        name: this.name,
        offset_time: this.offset_time,
        offset_type: this.offset_type,
        amenity_methods_attributes: this.amenity_methods.map(a => a.to_params()),
        short_description: this.short_description,
        long_description: this.long_description,
        no_of_bookings_per_day: this.no_of_bookings_per_day,
        auto_acknowledge: this.auto_acknowledge,
        payment_method: this.payment_method,
        applicable_patients: this.applicable_patients,
        duration: this.duration,
        status: this.status

      }
    }
  }

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }
}

export class ReminderAmenity {
  id: number;
  amenity_id: number;
  reminder_template_id: number;
  name: string;
  _destroy: boolean;
  to_params() {
    return {
      id: this.id,
      amenity_id: this.amenity_id,
      reminder_template_id: this.reminder_template_id,
      _destroy: this._destroy
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.amenity_id = json.amenity_id;
    this.reminder_template_id = json.reminder_template_id;
    return this;
  }
}

export class BookingMethod {
  id: number;
  method_type: string;
  amenity_method_id: number;
  booking_method_id: number;
  amenity_id: number;
  _destroy: boolean;
  name: string;
  fee: string;
  load_from_json(json) {
    this.id = json.id;
    this.method_type = json.method_type;
    this.name = json.name;
    this.fee = json.fee;
    return this;
  }
  load_from_json_for_amenities_methods(json) {
    this.amenity_method_id = json.id;
    this.amenity_id = json.amenity_id;
    this.name = json.name;
    this.method_type = json.method_type;
    this.booking_method_id = json.booking_method_id;
    this.id = this.booking_method_id;
    this.fee = json.fee;
    return this;
  }

  to_params() {
    return {
      id: this.amenity_method_id,
      booking_method_id: this.id,
      amenity_id: this.amenity_id,
      _destroy: this._destroy,
      fee: this.fee,
    }
  }

}

export class AmenityAssociate {
  id: number;
  name: string;
  short_description: string;
  location_id: number;
  doctor_id: number;
  amenity_id: number;
  office_visit_fee: number;
  virtual_visit_fee: number;
  phone_visit_fee: number;
  amenity_methods: BookingMethod[] = [];
  d_order: number;

  load_from_json(json) {
    this.id = json.id;
    this.d_order = json.d_order;
    this.name = json.name;
    this.amenity_id = json.amenity_id;
    this.short_description = json.short_description;
    this.location_id = json.location_id;
    this.doctor_id = json.doctor_id;
    this.office_visit_fee = json.office_visit_fee;
    this.virtual_visit_fee = json.virtual_visit_fee;
    this.phone_visit_fee = json.phone_visit_fee;
    if(json.amenity_methods){
      this.amenity_methods = json.amenity_methods.map(a => new BookingMethod().load_from_json_for_amenities_methods(a))
    }
    return this;
  }

  to_params() {
    return {
      id: this.id,
      amenity_id: this.amenity_id,
      d_order: this.d_order,
      location_id: this.location_id,
      doctor_id: this.doctor_id,
      office_visit_fee: this.office_visit_fee,
      virtual_visit_fee: this.virtual_visit_fee,
      phone_visit_fee: this.phone_visit_fee,

    }
  }
}
