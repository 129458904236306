<ng-container *ngIf="reportDetails && reportDetails['medications']?.length">
  <app-medications-table [medications]="reportDetails['medications']"></app-medications-table>
</ng-container>

<ng-container *ngIf="reportDetails && reportDetails['diagnosis']?.length">
  <app-diagnosis-table [diagnosis]="reportDetails['diagnosis']"></app-diagnosis-table>
</ng-container>

<ng-container *ngIf="reportDetails && reportDetails['allergies']?.length">
  <app-allergies-table [allergies]="reportDetails['allergies']"></app-allergies-table>
</ng-container>

<ng-container *ngIf="reportDetails && reportDetails['care_management']?.length">
  <app-care-management-table [activities]="reportDetails['care_management']"></app-care-management-table>
</ng-container>

<ng-container *ngIf="reportDetails && reportDetails['care_plan']">
  <h4 class="text-bold">Initial Assessment for CCM</h4>
  <ng-container *ngFor="let plan of reportDetails['care_plan']">
    <app-print-template-content [template]="plan"></app-print-template-content>
  </ng-container>
</ng-container>

<ng-container *ngIf="reportDetails && reportDetails['monitoring']?.length">
  <app-vitals-report-table [monitoring]="reportDetails['monitoring']"></app-vitals-report-table>
</ng-container>

<ng-container *ngIf="reportDetails && reportDetails['health_calendar']?.length">
  <h4 class="text-bold">Health Schedule</h4>
  <div class="table-responsive">
    <app-health-calendar-table [tableFor]="'bill'" [events]="reportDetails['health_calendar']" [tableClass]="'table table-bordered table-striped table-hover'">

    </app-health-calendar-table>
  </div>
</ng-container>

<ng-container *ngIf="reportDetails && reportDetails['consent']">
  <h4 class="text-bold">Patient Consent</h4>
  <h6 class="text-bold">Agreement to Receive Chronic Care Management Services</h6>
  <ng-container *ngFor="let consent of reportDetails['consent']">
    <h4 class="fs30 text-center mb-lg mt-lg">{{ programEnrollment.getFullProgramName(consent['programme_type']) }}</h4>
    <div class="mb" [innerHTML]="consent['consent_text'] | safeHtml"></div>
    <h6 class="text-bold">{{ showConsentFrom(consent['consent_from']) }}
      <span *ngIf="consent['signed_at_date']"> on {{consent['signed_at_date']}} </span>
      <span *ngIf="consent['signed_at_time']"> at {{consent['signed_at_time']}}</span></h6>
  </ng-container>
  </ng-container>
