<div class="container pt pb hidden-sm hidden-xs">
  <a (click)="backToListing()" class="btn-link fs12"><i class="fa fa-chevron-left fs10"></i> Back to search results</a>
</div>
<div class="profile-header">
  <div class="container">
    <div class="row">
      <div class="profile-pic col-sm-3 col-xs-12">
        <ks-modal-gallery [id]="1" [modalImages]="[doctor.profile_pic]" [plainGalleryConfig]="plainGalleryRow" (close)="cancelImageModal()"></ks-modal-gallery>
        <ng-container *ngIf="doctor.doctor_pics.length > 0">
          <ks-modal-gallery [id]="1" [modalImages]="doctor.doctor_pics" [plainGalleryConfig]="plainGalleryRow1" (close)="cancelImageModal()"></ks-modal-gallery>
          <span class="counter"><i class="fa icon-picture fa-fw"></i> {{doctor.doctor_pics.length}}</span>
        </ng-container>
      </div>
      <div class="info col-sm-9 col-xs-12">
        <div class="row">
          <div class="col-sm-8 col-xs-12" *ngIf="doctor.id">
            <h2 class="fs28 color-black mb0">{{doctor.complete_name}}
              <label class="badge label label-primary" *ngIf="doctor.video_consultation === 'enable'">Available Online</label>
            </h2>
            <h3 class="profile-desig">{{doctor.designation}}</h3>
            <h4 class="fw-300" *ngIf="doctor.physician.specialties">{{doctor.physician.specialties}}</h4>
            <div class="review-star-ui mb">
              <rating class="review-star" [tooltip]="doctor.averageExactRating" [(ngModel)]="doctor.averageRating" [max]="5.00" [readonly]="true"></rating>
            </div>
            <a href="javascript:void(0)" [scrollTo] ="'reviews'" [duration]="700" [offset]="100">Read patient reviews</a>
            <div *ngFor="let practice of doctor.practices" class="col-sm-12 p0 mt-lg hidden">
              <div class="col-sm-12 p0">
                <p class="m0 pull-left fs16"><em class="pr-sm fa fa-map-marker"></em>&nbsp;{{practice.name}}</p>
                <ul class="list-unstyled clearfix pl-lg fs16">
                  <li>
                    {{practice.client.complete_address}}
                  </li>
                  <li>
                    {{practice.phone?.value | mask: '(000) 000-0000'}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12 price-section">
            <span class="price-tag" *ngIf="doctor.visit_charges">$ {{doctor.visit_charges}}</span>
            <h5 class="font-w">{{doctor.getFormattedExperience()}} of Experience</h5>
          </div>
          <ul class="qualification-list list-unstyled col-xs-12 mt-lg">
            <ng-container *ngFor="let degree of doctor.degrees">
              <li><span class="font-w" *ngIf="degree.title">{{degree.title}},</span> {{degree.institution_name}} {{doctor.degreeYearsRange(degree)}}</li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row p-relative">
    <div class="col-md-4 col-sm-5 col-xs-12 pull-right bookAppointment-col">
      <div class="bookAppointment-box border-box shadow flex-control flex-column">
        <h2>Book an Appointment</h2>
        <app-slot-navigator class="flex-header p0" *ngIf="doctor.id" [physicians]="[doctor.physician]" [autoload]="true"></app-slot-navigator>
        <app-slots class="flex-body" *ngIf="doctor.id" [grouped_slots]="doctor.physician.grouped_slots"></app-slots>
      </div>
    </div>
    <div class="col-md-8 col-sm-7 col-xs-12">
      <ul class="pd-custom-tabs nav nav-pills mt-xl hidden-sm hidden-xs">
        <ng-container *ngIf="doctor.summary">
          <li class="active"><button class="btn fs14">Personal</button></li>
        </ng-container>
        <ng-container *ngIf="doctor.achievement_categories_array && doctor.achievement_categories_array.length > 0">
          <li><button class="btn fs14" [scrollTo] ="'qualification'" [duration]="700" [offset]="100">Qualification and Experience</button></li>
        </ng-container>
        <ng-container *ngIf="doctor.physician && doctor.physician.locations">
          <li><button class="btn fs14" [scrollTo] ="'location'" [duration]="700" [offset]="100">Location</button></li>
        </ng-container>
        <ng-container>
          <li><button class="btn fs14" [scrollTo] ="'articles'" [duration]="700" [offset]="100">My Articles</button></li>
        </ng-container>
        <ng-container>
          <li><button class="btn fs14" [scrollTo] ="'reviews'" [duration]="700" [offset]="100">Patient Reviews</button></li>
        </ng-container>
      </ul>

      <ng-container *ngIf="doctor.summary">
        <div class="clearfix">
          <h3 id="summary">Professional Summary</h3>
          <div [innerHTML]="doctor.summary | safeHtml"></div>
        </div>
      </ng-container>

      <ng-container *ngIf="doctor.achievement_categories_array && doctor.achievement_categories_array.length > 0">
        <div class="clearfix">
          <h3 id="qualification">Qualifications & Experience</h3>
          <ul class="border-list list-unstyled">
            <ng-container *ngFor="let category of doctor.achievement_categories_array">
              <ng-container *ngIf="category.doctors_achievements[0].title">
                <ng-container *ngIf="category.parent_id != null">
                  <li class="list">
                    <div class="row">
                      <h4 class="col-md-5 col-sm-12 col-xs-12 m0 mb-sm text-primary">{{category.name}}</h4>
                      <div class="col-md-7 col-sm-12 col-xs-12">
                        <ng-container *ngFor="let doctor_achievement of category.doctors_achievements; let i=index;">
                          <div class="clearfix" *ngIf="doctor_achievement.title">
                            <h4 class="m0">{{doctor_achievement.title}}</h4>
                            <div class="row">
                              <div class="col-sm-8">
                                <p class="mb-sm">
                                  <span class="d-block" *ngIf="doctor_achievement.institution_name">{{doctor_achievement.institution_name}}</span>
                                  <span class="d-block" *ngIf="doctor_achievement.start_date">{{doctor_achievement.yearsRange()}}</span>
                                </p>
                              </div>
                              <div class="col-sm-4">
                                <p class="mb-sm">
                                  <span>{{calculateExperience(doctor_achievement)}}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </ng-container>

      <ng-container *ngIf="doctor.physician && doctor.physician.locations">
        <div class="clearfix mb-lg">
          <h3 id="location">My Location</h3>
          <div class="row mt-lg">
            <div class="col-sm-6 mb-lg" *ngFor="let location of doctor.physician.locations">
              <a [routerLink]="['/practices',location.practice_id]" class="fw-500">
                <div class="media p0">
                  <div class="media-left p0"><i class="fa icon-location-pin" style="margin-top:1px;"></i></div>
                  <div class="media-right pl-sm"><span class="text-gray-dark">{{location.practice_name}}</span></div>
                </div>
              </a>
              <p class="m0 fs12" *ngIf="location.distance != 0">
                <i class="fa fa-space-shuttle"></i> &nbsp;<strong>{{(location.distance/1000) | number : '1.1-1'}} KM</strong>
              </p>
              <p class="m0 fs12"><i class="fa icon-phone"></i> &nbsp;{{location.phone_value | mask: '(000) 000-0000'}}</p>
              <div class="media p0 m0 fs12">
                <div class="media-left p0"><i class="fa icon-pin"></i></div>
                <div class="media-right pl-sm">{{location.complete_address}}</div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="doctor.physician.locations.length > 0">
            <google-map style="width: 100%;display: block;position: relative;overflow: hidden;height: 300px;"
                        [center]="center"
                        [zoom]="zoom"
                        #map>
              <map-marker *ngFor="let markerPosition of markerPositions"
                          [position]="markerPosition"
                          [options]="markerOptions"></map-marker>
            </google-map>
          </ng-container>
        </div>
      </ng-container>

      <div class="clearfix">
        <h3 id="articles">My Articles</h3>
        <div class="main-wrapper">
          <div class="alert alert-info text-center fw-500" style="max-width:500px; margin:5% auto 0; line-height:normal;">
            No Articles Found!
          </div>
        </div>
        <div class="row hidden">
          <div class="col-md-4 col-sm-6 mb-sm">
            <div class="white-container-box p-sm clearfix">
              <div class="col-sm-3 col-xs-2 mt-lg"><i class="fa fs25 icon-h-secondary-wheelchair text-primary"></i></div>
              <div class="col-xs-9 p0 pr-sm">
                <h5><a> 13 Important Questions for an Allergist, Answered</a></h5>
                <h5 class="text-warning mb0">by Dr. Asif Rashid</h5>
                <h5 class="mt-sm"><span class="text-muted">Category:</span> <span class="text-primary">Allergy</span></h5>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-sm">
            <div class="white-container-box p-sm clearfix">
              <div class="col-sm-3 col-xs-2 mt-lg"><i class="fa fs25 icon-h-secondary-wheelchair text-primary"></i></div>
              <div class="col-xs-9 p0 pr-sm">
                <h5><a> 13 Important Questions for an Allergist, Answered</a></h5>
                <h5 class="text-warning mb0">by Dr. Asif Rashid</h5>
                <h5 class="mt-sm"><span class="text-muted">Category:</span> <span class="text-primary">Allergy</span></h5>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-sm">
            <div class="white-container-box p-sm clearfix">
              <div class="col-sm-3 col-xs-2 mt-lg"><i class="fa fs25 icon-h-secondary-wheelchair text-primary"></i></div>
              <div class="col-xs-9 p0 pr-sm">
                <h5><a> 13 Important Questions for an Allergist, Answered</a></h5>
                <h5 class="text-warning mb0">by Dr. Asif Rashid</h5>
                <h5 class="mt-sm"><span class="text-muted">Category:</span> <span class="text-primary">Allergy</span></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <h3>Other Articles</h3>
        <div class="main-wrapper">
          <div class="alert alert-info text-center fw-500" style="max-width:500px; margin:5% auto 0; line-height:normal;">
            No Articles Found!
          </div>
        </div>
        <div class="row hidden">
          <div class="col-md-4 col-sm-6 mb-sm">
            <div class="white-container-box p-sm clearfix">
              <div class="col-sm-3 col-xs-2 mt-lg"><i class="fa fs25 icon-h-secondary-wheelchair text-primary"></i></div>
              <div class="col-xs-9 p0 pr-sm">
                <h5><a> 13 Important Questions for an Allergist, Answered</a></h5>
                <h5 class="text-warning mb0">by Dr. Asif Rashid</h5>
                <h5 class="mt-sm"><span class="text-muted">Category:</span> <span class="text-primary">Allergy</span></h5>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-sm">
            <div class="white-container-box p-sm clearfix">
              <div class="col-sm-3 col-xs-2 mt-lg"><i class="fa fs25 icon-h-secondary-wheelchair text-primary"></i></div>
              <div class="col-xs-9 p0 pr-sm">
                <h5><a> 13 Important Questions for an Allergist, Answered</a></h5>
                <h5 class="text-warning mb0">by Dr. Asif Rashid</h5>
                <h5 class="mt-sm"><span class="text-muted">Category:</span> <span class="text-primary">Allergy</span></h5>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 mb-sm">
            <div class="white-container-box p-sm clearfix">
              <div class="col-sm-3 col-xs-2 mt-lg"><i class="fa fs25 icon-h-secondary-wheelchair text-primary"></i></div>
              <div class="col-xs-9 p0 pr-sm">
                <h5><a> 13 Important Questions for an Allergist, Answered</a></h5>
                <h5 class="text-warning mb0">by Dr. Asif Rashid</h5>
                <h5 class="mt-sm"><span class="text-muted">Category:</span> <span class="text-primary">Allergy</span></h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h3 id="reviews">Patient Reviews</h3>
      <ng-container *ngIf="(doctor.acceptedPatientReviews) && (doctor.acceptedPatientReviews.length > 0) ;else no_record">
        <ul class="border-list list-unstyled">
          <li class="list" *ngFor="let patient_review of doctor.acceptedPatientReviews;let i = index;">
            <div class="media overflow-visible" *ngIf="(i < 3 && i >= 0) || show_all">
              <div class="media-left">
                <img alt="Image" class="img-rounded thumbnail thumb64 pic" src="{{ patient_review.patient_profile_pic }}">
              </div>
              <div class="media-body overflow-visible">
                <span class="pull-right fs12"><i class="icon icon-clock vl-m pr-sm"></i> On {{patient_review.review_date | date:'MMMM d, y, h:mm a' }}</span>
                <h4 class="m0 mb-sm fw-500">{{patient_review.patient_name}}</h4>
                <div class="review-star-ui pull-right"><rating [(ngModel)]="patient_review.rating" [max]="5" [readonly]="true"></rating></div>
                <p class="m0">{{patient_review.description}}</p>
              </div>
            </div>
          </li>
        </ul>
        <ng-container *ngIf="doctor.acceptedPatientReviews && doctor.acceptedPatientReviews.length > 3">
          <a *ngIf="!show_all" class="col-sm-offset-6" (click)="show_all_reviews()">Show all {{doctor.acceptedPatientReviews.length}} Reviews</a>
          <a *ngIf="show_all" class="col-sm-offset-6" (click)="show_all_reviews()">Show less Reviews</a>
        </ng-container>
      </ng-container>
      <ng-template #no_record class="border-list list-unstyled">
        <div class="main-wrapper">
          <div class="alert alert-info text-center fw-500" style="max-width:500px; margin:5% auto 0; line-height:normal;">No Patient Reviews Found!</div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
