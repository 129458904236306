import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-public-telemedicine',
  templateUrl: './public-telemedicine.component.html',
  styleUrls: ['./public-telemedicine.component.scss']
})
export class PublicTelemedicineComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0)
      }
    });
  }

  ngOnInit() {
  }

}
