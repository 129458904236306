<div class="filters-sec">
  <a class="btn btn-primary btn-outline btn-filter mb" data-toggle="collapse" href="#collapsefilters"><i
    class="fa fa-filter mr-sm"></i> Filters</a>
  <div class="collapse" id="collapsefilters">
    <div class="row fields">
      <h4 class="col-">Patients
        <label class="label label-secondary">{{totalItems}}</label>
      </h4>
      <ng-container *ngIf="providers && providers.length > 0">
        <div class="col-sm-4">
          <select [(ngModel)]="doctor_id" #provider class="form-control" (change)='change_provider(provider.value)'>
            <option value="">Providers</option>
            <option *ngFor="let provider of providers" [value]="provider.id">
              {{provider.firstname}} {{provider.lastname}}
            </option>
          </select>
        </div>
      </ng-container>
      <div class="col-sm-4" *ngIf="app_type !== 'video'">
        <select [(ngModel)]="location_id" #location class="form-control" (change)='change_location(location.value)'>
          <option value="0">Locations</option>
          <option *ngFor="let location of locations" selected="location_id" [value]="location.id" [hidden]="location.name == 'Video Consultation'">
            <span>{{location.name}}</span>
          </option>
        </select>
      </div>
      <div class="col-sm-4">
        <ss-multiselect-dropdown [options]="myOptions"
                                 [texts]="myTexts"
                                 [(ngModel)]="optionsModel"
                                 [settings]="selectSettings"
                                 (ngModelChange)="selectStatuses($event)">

        </ss-multiselect-dropdown>
      </div>
      <div class="col-sm-4">
        <input type="text"
               placeholder="Requested On"
               [(ngModel)]="date_filter"
               [daysDisabled]="date2DisabledDates"
               class="form-control"
               bsDatepicker
               [bsValue]="bsValue"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false}"
               [dateCustomClasses]="dateCustomClasses">
      </div>
      <div class="col-xs-12">
        <button type="button" class="btn btn-tertiary btn-outline" (click)="performSearch()"><i class="fa fa-filter"></i> Filter</button>
        <ng-container *ngIf="app_type !== 'video'">
          <button class="btn btn-primary btn-outline ml-sm" (click)="save_filter()" *ngIf="show_save_filter">Save Filter</button>
        </ng-container>
        <ng-container *ngIf="app_type === 'video'">
          <button class="btn btn-primary btn-outline ml-sm" (click)="save_telemed_filter()" *ngIf="show_save_filter">Save Filter</button>
        </ng-container>
        <ng-container *ngIf="app_type !== 'video'">
          <button *userCan="'add,Today Patients,reception'" type="button"
                  class="btn btn-danger btn-outline ml-sm hidden-sm hidden-xs" (click)="add_to_tabs('Walk In')">Walk-In
          </button>
          <button *userCan="'add,Today Patients,reception'" class="btn btn-secondary ml-sm hidden-sm hidden-xs"
                  (click)="add_to_tabs('Book')">Appointment
          </button>
        </ng-container>
        <ng-container *ngIf="app_type !== 'video'">
          <button *ngIf="_user_service.current_user.isPractice && _user_service.current_user.extra['is_integrated_and_authenticated']"
            class="btn ml-sm btn-secondary mr hidden-sm hidden-xs" (click)="fetchVendorAppointments()"> Sync
          </button>
        </ng-container>
      </div>
      <div class="order-field">
        <span>Sort by:</span>
        <select #order [(ngModel)]="order_by" name="order_order" [attr.valll]="order_by" (change)="performSearch()">
          <option *ngFor="let order of display_orders; let i = index;" [value]="display_orders_mapping[order]">
            <span>{{order}}</span>
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="visible-sm visible-xs pb" *ngIf="app_type !== 'video'">
    <button *userCan="'add,Today Patients,reception'" type="button" class="btn btn-danger"
            (click)="add_to_tabs('Walk In')">Walk-In
    </button>
    <button *userCan="'add,Today Patients,reception'" class="btn btn-primary ml-sm" (click)="add_to_tabs('Book')">
      Book an Appointment
    </button>
  </div>
</div>
