import {
  Component, OnInit, Input, ElementRef, Inject, Output, EventEmitter, OnChanges, ViewChild,
  Injector, TemplateRef
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Doctor} from './doctor'
import { DoctorService } from '../../../services/doctor.service';
import { SpecialtySearchService } from '../../../services/specialty-search.service';
import {Router} from '@angular/router';
import { CustomValidations } from '../../customValidations/custom-validations'
import { NgForm } from '@angular/forms';
import {TabsService} from '../../../services/tabs.service';
import {ValidationService} from '../../../services/validation.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {DateMapper} from '../../../routes/users/date'
import { mergeMap } from 'rxjs/operators';
import { Image } from '@ks89/angular-modal-gallery';
import {LocationService} from '../../../services/location.service';
import {PublicReviewLink} from '../../../reviews/public_review_link';
import {LoaderService} from '../../../services/loader.service';
import { NgSelectComponent} from '@ng-select/ng-select';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-doctor-form',
  templateUrl: './doctor-form.component.html',
  styleUrls: ['./doctor-form.component.scss']
})

export class DoctorFormComponent implements OnInit, OnChanges {
  public editor: any;
  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 300,
    relative_urls: false,
    remove_script_host: false,
    convert_urls: true,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    placeholder: 'Summary...',
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
    'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
    'insertfile undo redo numlist outdent | bullist indent |contextmenu table | \ ' +
    'link forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.editor = editor;
    },
  }
  @ViewChild('specialtiesInput') specialtiesInput: NgSelectComponent;
  @ViewChild('servicesInput') servicesInput: NgSelectComponent;

  description = '';
  public specialties: Observable<any[]>;
  public items: any;
  public total_services: any;
  @Input() doctor: Doctor;
  @Output() edited_doctor: EventEmitter<Doctor> = new EventEmitter<Doctor>();
  @Output() form_hidden: EventEmitter<Doctor> = new EventEmitter<Doctor>();
  public active: any;
  private value: any = {};
  private _disabledV = '0';
  private disabled = false;
  public isCnicInValid = false;
  public isRegnoInValid = false;
  public error_true = false;
  public token = '';
  public latitude: number;
  public longitude: number;
  public zoom = 18;
  public searchElementRef: ElementRef;
  public category_id: number;
  public search_type: number;
  public customValidations: CustomValidations;
  public data_source: Observable<any>;
  public show_attachment_popup = false;
  public global_error = false;
  public years: any[];
  public months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public date: DateMapper = new DateMapper();
  Json = JSON;
  public_sites: any[];
  private specialtiesLoading = false;

  @ViewChild('doctorForm') doctorForm: NgForm;

  constructor(public loaderService: LoaderService, private _validation_service: ValidationService , private tabs_service: TabsService, private injector: Injector, private doctorService: DoctorService, private specialtySearchService: SpecialtySearchService,
              @Inject(ElementRef) elementRef: ElementRef, private router: Router, private globalErrorHandlerService: GlobalErrorHandlerService, private locationService: LocationService) {

    this.years = this.date.get_years_from_latest('', new Date( '01 ' + 'January' + (new Date()).getFullYear()).getFullYear(), new Date( '01 ' + 'January 1900').getFullYear(), Array());
    this.years = this.years.filter(function(e) {return e});
    this.customValidations = new CustomValidations();
    this.searchElementRef = elementRef;
    this.setGoogleMaps();
    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).pipe(mergeMap((token: string) => this.doctorService.search_achievements(token, this.category_id, this.search_type)));
  }

  ngOnChanges() {
    this.setGoogleMaps();
  }

  ngOnInit() {
    this.locationService.public_sites().then(resp => {
      this.public_sites = resp;
    })
    if (this.doctor == undefined) {
        this.doctor = new Doctor();
    }
    let id = this.injector.get('id', null);
    let doctor_id;
    if (id > 0 && this.doctor.id == '') {
      doctor_id = id;
    } else {
      doctor_id = this.doctor.id;
    }
    this.doctorService.getDoctor(doctor_id).then(json => {
      this.doctor.load_from_json(json);
    });
  }

  call_func(s) {
    return s.start_date;
  }

  save() {
    if (this.formValid()) {
      this.doctor.client.email = this.doctor.client.email.toLowerCase();
      this.doctorService.save(this.doctor).then(res => {
        this.edited_doctor.emit(this.doctor);
        return res;
      }).catch(resp =>  {
        if (resp.error.error.includes('upgrade your account')) {
          this.globalErrorHandlerService.message = resp.error.error.replace('Validation failed: ', 'Information Alert:').replace('Doctors practices is invalid,',  '');
        }
        else {
          this.globalErrorHandlerService.error = resp.error.error;
        }
      });
    } else {
      this.global_error = true;
      setTimeout(() => {
        this.global_error = false
      }, 2000)
      return;
    }
  }

  duplication_check(validation_variable, value) {
    this._validation_service.validate_duplication(validation_variable, value).then(json => {
      if (validation_variable == 'cnic' && json.status == 'invalid') {
        this.isCnicInValid = true;
      }
      else if (validation_variable == 'regno' && json.status == 'invalid') {
        this.isRegnoInValid = true;
      }
      return;
    });
  }

  onCheckboxChange(doctor_achievement, is_true) {
      doctor_achievement.currently_present = is_true;
      doctor_achievement.end_date = '';
      doctor_achievement.end_month = '';
    return doctor_achievement;
  }

  onFocus(validation_variable) {
    if (validation_variable == 'cnic') {
      this.isCnicInValid = false;
    } else {
      this.isRegnoInValid = false;
    }
    return;
  }

  close_dynamic_tab() {
    let tab = this.tabs_service.tabs.filter(e => e.unique_id = this.doctor.id)[0];
      if (tab) {
        let index = this.tabs_service.tab_index(tab);
        this.tabs_service.close_tab(tab);
      }
    return;
  }

  get achievement_categories_array() {
    return this.doctor.achievement_categories_array;
  }

  get achievement_categories() {
    return this.doctor.achievement_categories;
  }

  set achievement_categories(val) {
    this.doctor.achievement_categories = val;
  }

  getSpecialties(term: any) {
    return this.specialtySearchService.search(term);
  }

  getServices(term: any) {
    return this.specialtySearchService.search_services(term);
  }

  getPreselectedSpecialties() {
    return this.doctor.client.existing_specialties;
  }

  getPreselectedServices() {
    return this.doctor.client.existing_services;
  }

  setGoogleMaps() {
    this.setCurrentPosition();
      let searchBox = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement.querySelector('#city_area_name'));
      google.maps.event.addListener(searchBox, 'places_changed', function () {
        function closure(elem) {
          let place = searchBox.getPlace();
          if (!place) {
            return;
          }
          elem.doctor.client.address.city_area.lat = place.geometry.location.lat();
          elem.doctor.client.address.city_area.long = place.geometry.location.lng();
          elem.doctor.client.address.city_area.name = place.formatted_address;
        }
        closure(this);
      })
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  addMore(category) {
    this.doctor.add_achievement(category);
  }

  removeAchievement(category, index) {
    this.doctor.remove_achievement(category, index);
  }

  public set_search_data(search_type, category_id) {
    this.search_type = search_type;
    this.category_id = category_id;
  }

  private get disabledV(): string {
    return this._disabledV;
  }

  private set disabledV(value: string) {
    this._disabledV = value;
    this.disabled = this._disabledV === '1';
  }

  get get_specialties(): any {
    return this.doctor.client.specialties;
  }

  get get_services(): any {
    return this.doctor.client.services;
  }

  public selected(value: any): void {
    this.doctor.add_specialty(value.id);
  }

  public removed(value: any): void {
    let index = this.get_specialties.map(function(specialty) {return specialty.id; }).indexOf(value.id);
    this.get_specialties.splice(index, 1);
  }

  public refreshValue(value: any): void {
    this.value = value;
  }

  set_token(event) {
    this.token = event.target.value;
  }

  typed(text: { term: string; items: any }) {
    this.specialtiesLoading = true;
    this.getSpecialties(text.term).subscribe(a => {
      this.specialtiesLoading = false
      return this.items = a.map(sp => {return {id: sp.id, text: sp.description}})
    });
  }

  public selected_service(value: any): void {
    this.doctor.add_service(value.id);
  }

  public removed_service(value: any): void {
    let index = this.get_services.map(function(service) {return service.id; }).indexOf(value.id);
    this.get_services.splice(index, 1);
  }

  typedServices(text: { term: string; total_services: any }) {
    this.specialtiesLoading = true;
    this.getServices(text.term).subscribe(a => {
      this.specialtiesLoading = false
      return this.total_services = a.map(sp => {return {id: sp.id, text: sp.name}})
    });
  }

  public typeaheadOnSelect(e: any, doctor_achievement, type): void {
    this.doctor.add_doctor_achievement(e, doctor_achievement, type)
  }

  hide_form() {
    this.form_hidden.emit(this.doctor);
  }

  choose_file() {
    this.show_attachment_popup = true;
    setTimeout( () => {
      this.show_attachment_popup = false;
    }, 75); }

  handle_image_upload(data) {
    if (data && data.hasOwnProperty('error') == false) {
        this.doctor.profile_pic =  new Image(0, {img: data.profile_pic});
        this.doctor.profile_pic_id = data.id;
    }
  }

  formValid() {
    let formValid = true;
    this.achievement_categories_array.forEach(cat => {
      let invalid = cat.doctors_achievements.filter(d_achievement => !d_achievement.isValid()).length
      if (invalid > 0) {
        formValid = false;
        return false;
      }
    });
    return formValid;
  }
  ngAfterViewChecked() {
    this.customValidations.formChanged(this.doctorForm);
  }

  add_link() {
    let public_review_link = new PublicReviewLink();
    public_review_link.linkable_type = 'Doctor';
    this.doctor.public_review_links.push(public_review_link);
  }
  onChange(review, site) {
    review.public_site_id = site.value;
    review.public_site_url = null;
  }
  deleteLink(reviewLink) {
    if (reviewLink.id) {
      this.locationService.deleteLink(reviewLink.id).then(resp => {
        this.doctor.public_review_links = this.doctor.public_review_links.filter(l => l.id != reviewLink.id);
      })
    }
    else {
      this.doctor.public_review_links.splice(this.doctor.public_review_links.indexOf(reviewLink), 1)
    }
  }

  uncheckOther(link_index) {
    for ( let i = 0; i < this.doctor.public_review_links.length; i++ ) {
      if ( i !== link_index) {
        this.doctor.public_review_links[i].default_auto_review = false;
      }
    }
  }
}
