import {Component, Input, OnInit} from '@angular/core';
import {Patient} from "../../../../doctor/patient";

@Component({
  selector: 'app-patient-task-communication-log',
  templateUrl: './patient-task-communication-log.component.html',
  styleUrls: ['./patient-task-communication-log.component.scss']
})
export class PatientTaskCommunicationLogComponent implements OnInit {
  @Input() patient: Patient;
  public  isPatientProfile : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
