<ul class="custom-tabs nav">
  <ng-content></ng-content>
  <ng-container *ngIf="tabs">
    <li *ngFor="let tab of tabs" (click)="open_tab(tab)" [class.active]="tab.active" class="custom tab customer-chrome-title">
      <svg class="close-btn" viewBox="0 0 329 329" (click)="close_tab(tab)">
        <path xmlns="http://www.w3.org/2000/svg" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
      </svg>
      {{tab.tab_data.title}}
    </li>
  </ng-container>
</ul>
<div class="tabs-counter hidden">
  <ul class="todaysPatient-counter">
    <li class="total">4</li>
    <li class="cancelled">1</li>
    <li class="scheduled">3</li>
    <li class="confirmed">3</li>
    <li class="checkedin">2</li>
    <li class="rescheduled">4</li>
    <li class="noshow">2</li>
    <li class="checkedout">2</li>
    <li class="admitted">1</li>
  </ul>
</div>
<ng-container *ngFor="let tab of tabs">
  <app-tab class="flex-control" [tab]="tab" *ngIf="!tab.autorefresh || tab.active"></app-tab>
</ng-container>
