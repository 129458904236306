import {Component, OnInit, ViewChild} from '@angular/core';
import {SpecialtySearchService} from '../../../services/specialty-search.service';
import {Observable} from 'rxjs/Observable';
import {Calendar, Doctor} from '../../../routes/practices/doctors/doctor';
import {Appointment} from '../../models/appointment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {AppointmentService} from '../../../services/appointment.service';
import {PatientService} from '../../../services/patient.service';
import {NgForm} from '@angular/forms';
import {Patient} from '../../doctor/patient';
import {CustomValidations} from '../../../routes/customValidations/custom-validations'
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {mergeMap} from 'rxjs/operators';
import {LocationService} from '../../../services/location.service';
import {BraintreeService} from '../../../services/braintree.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
  public services: Observable<any[]>;
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public go_next = false;
  public new_relative = false;
  messageAfterSave = false;
  pending_request = false;
  zoom = 13;
  scrollwheel = false;
  area_text = '';
  current_patient_id: any;
  public appointment: Appointment = new Appointment();
  public doctor: Doctor = new Doctor();
  public patient_family: Patient = new Patient();
  public customValidations: CustomValidations;
  public showPayment = false;

  @ViewChild('appointmentForm') appointmentForm: NgForm;
  paymentSuccessful = false;
  public selected_slot_calendar: Calendar;

  constructor(private _patient_service: PatientService,
              private router: Router,
              public appointment_Service: AppointmentService,
              private activatedRoute: ActivatedRoute,
              private specialtySearchService: SpecialtySearchService,
              public user_service: UserService,
              private locationService: LocationService,
              private braintreeService: BraintreeService,
              private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.customValidations = new CustomValidations();
    this.services = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.get_services(token)));
  }

  ngOnInit() {
    this.get_query_params();
    this.handleUser(true);
  }

  verified_patient() {
    if (this.appointment.appointment_type === 'virtual_visit') {
      return true;
    }
    return this.go_next && this.user_service.current_user && this.user_service.current_user.isPatient && this.user_service.patient_to_verify && this.user_service.patient_to_verify.verified()
  }

  go_to_next_step(patient) {
    this.go_next = true;
  }

  handleUser(skip_user?) {
    if (this.pending_request) {
      return true;
    }
    if (!skip_user && !this.user_service.current_user) {
      this.appointment.patient_id = undefined;
      return true;
    }
    if (this.user_service.current_user && this.appointment.patient_id) {
      return true;
    }
    this.pending_request = true;
    this.current_logged_in_user();
    if (this.appointment && this.appointment.calendar_id) {
      this.doctor_detail()
    }
    if (this.appointment && this.appointment.patient_id) {
      this.get_patient_relatives();
    }
    return true;
  }

  get_query_params() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let calendar_id = params['calendar_id'];
      let slot_datetime = params['slot'];
      let slot_size = params['slot_size'];
      this.appointment.appointment_type = params['app_type'] ? params['app_type'] : undefined;
      if (!this.appointment.appointment_type) {
        this.slot_calendar(calendar_id);
      }
      this.appointment.slot_size = slot_size;
      this.appointment.calendar_id = calendar_id;
      this.appointment.date = slot_datetime;
    });
  }

  doctor_detail() {
    this.appointment_Service.get_doctor_profile(this.appointment.calendar_id).then(doctor => {
      this.doctor.load_from_json(doctor);
      this.pending_request = false;
    })
  }

  get_patient_relatives() {
    this._patient_service.getPatient(this.appointment.patient_id).then(res => {
      this.patient_family = new Patient().load_from_json(res);
      if (this.appointment_Service.selected_patient) {
        let selected_fms = this.family_members().filter((fm) => fm.id == this.appointment_Service.selected_patient)
        if (selected_fms.length > 0) {
          this.update_patient_id(selected_fms[0].id, selected_fms[0].name);
        }
      }
    })
  }

  current_logged_in_user() {
    let user = this.user_service.current_user;
    if (user) {
      this.appointment.complete_name = user.full_name;
      this.appointment.patient_id = user.profileable_id;
      this.current_patient_id = user.profileable_id;
      this.appointment.created_by_id = user.id;
      this.appointment.status_id = 1;
    }
  }

  update_patient_id(id, name) {
    if (id == 0) {
      this.appointment.patient_id = this.current_patient_id;
    } else {
      this.appointment.patient_id = id;
    }
    this.appointment.complete_name = name;
    if (this.new_relative) {
      this.new_relative = !this.new_relative;
    }
  }

  family_members() {
    return this.patient_family.family_members;
  }

  get_services(term: any) {
    return this.specialtySearchService.search_services(term);
  }

  set_asyncSelected() {
    this.asyncSelected = this.appointment.service_name;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.appointment.service_id = e.item.id;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  book_appointment() {
    this.appointment_Service.save(this.appointment).then(res => {
      this.appointment = this.appointment.load_from_json(res);
      if (this.appointment.patient.user.braintree_customer_id && res.amount_present) {
        this.braintreeService.appointmentPayment('', this.appointment.id).then(resp => {
          console.log(resp);
          if (resp['success']) {
            this.paymentSuccessful = true;
            this.setTimeOutFailsMessage();
          } else {
            this.globalErrorHandlerService.error = 'Something went wrong.';
          }
        }).catch((resp) => {
          console.log(resp);
          this.globalErrorHandlerService.error = resp.error.error;
        });
      } else {
        this.showPayment = true;
      }
    }).catch(resp => this.globalErrorHandlerService.error = resp.error.error);
  }


  add_new_relative_patient() {
    this.new_relative = !this.new_relative;
  }

  setTimeOutFailsMessage(): any {
    this.messageAfterSave = true;
    setTimeout(() => {
      this.messageAfterSave = false;
      this.router.navigate(['/patients', this.current_patient_id]).then(r => console.log(r));
    }, 6000)
  }

  family_member_saved(family_member) {
    this.new_relative = !this.new_relative;
    this.patient_family.family_members.push(family_member);
    this.appointment.patient_id = family_member.id;
    this.appointment.complete_name = family_member.user.full_name;
  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.appointmentForm);
  }

  onPayment($event: any) {
    console.log($event);
    if ($event) {
      if (this.appointment.appointment_type === 'virtual_visit') {
        this.paymentSuccessful = true;
        setTimeout(() => {
          this.paymentSuccessful = false;
          this.showPayment = false;
          this.router.navigate(['/patients', this.current_patient_id]).then(r => console.log(r));
        }, 10000)
      } else {
        this.router.navigate(['/patients', this.current_patient_id]).then(r => console.log(r));
      }
    }
  }

  onPatientCreate($event: boolean) {
    if ($event) {
      if (this.appointment.appointment_type === 'virtual_visit') {
        this._patient_service.markVerified(this.user_service.current_user.profileable_id).then(resp => {
          console.log(resp);
          this.user_service.patient_to_verify.email_verified = true;
          this.user_service.patient_to_verify.mobile_verified = true;
        })
      }
    }
  }

  slot_calendar(id) {
    this.locationService.get_slot_calendar(id).then(resp => {
      console.log(resp);
      this.selected_slot_calendar = resp;
    })
  }

  set_appointment_type_and_fee(type: any) {
    this.appointment.appointment_type = type;
  }
}
