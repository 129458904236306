<div class="flex-control flex-column flex-height" *ngIf="!editReminder">
  <div class="flex-header">
    <div class="media">
      <div class="media-body media-middle"><h4 class="m0">{{reminder_template.name}}</h4></div>
      <div class="media-body text-right">
        <a (click)="updateReminder(reminder_template)" class="btn btn-secondary btn-outline mr"><i class="fa fa-pencil vl-m mr-sm"></i> Edit</a>
        <a class="btn btn-danger btn-outline btn-sm" tooltip="Delete" *ngIf="reminder_template.deleteable" (click)="deleteTemplate(reminder_template)">
          Delete
        </a>
      </div>
    </div>
  </div>
  <div class="flex-body scroll-browser">
    <ul class="reminder-list list-unstyled border-list">
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-check fs14 vl-m mr"></i>
            <label class="field-label">Type</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{capitalize(reminder_template.reminder_type)}}</h5>
        </div>
      </li>
      <ng-container *ngIf="reminder_template.reminder_type !== 'birthday'">
        <li class="list">
          <div class="media">
            <div class="media-left media-middle">
              <i class="fa icon-check fs14 vl-m mr"></i>
              <label class="field-label">Statuses</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400"><ng-container *ngFor="let status of reminder_template.statuses; let i = index"><span class="pl-sm">{{capitalize(status.status_id)}}<span *ngIf="i < reminder_template.statuses.length -1">, </span></span></ng-container></h5>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <div class="media-left media-middle">
              <i class="fa icon-check fs14 vl-m mr"></i>
              <label class="field-label">Reason Of Visits</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400"><ng-container *ngFor="let service of reminder_template.services; let i=index"><span class="pl-sm">{{capitalize(service.name)}}<span *ngIf="i < reminder_template.services.length -1">, </span></span></ng-container></h5>
          </div>
        </li>
        <li class="list">
          <div class="media">
            <div class="media-left media-middle">
              <i class="fa icon-check fs14 vl-m mr"></i>
              <label class="field-label">Services</label>
            </div>
            <h5 class="media-body media-middle m0 fw-400"><ng-container *ngFor="let amenity of reminder_template.amenities; let i=index"><span class="pl-sm">{{capitalize(amenity.name)}}<span *ngIf="i < reminder_template.amenities.length -1">, </span></span></ng-container></h5>
          </div>
        </li>
      </ng-container>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-calendar fs14 vl-m mr"></i>
            <label class="field-label">Trigger On</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{reminder_template.trigger_on}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-cursor fs14 vl-m mr"></i>
            <label class="field-label">Outreach</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{capitalize(reminder_template.outreach_type)}}</h5>
        </div>
      </li>
      <li class="list">
        <div class="media">
          <div class="media-left media-middle">
            <i class="fa icon-user fs14 vl-m mr"></i>
            <label class="field-label">Patient Preference</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{reminder_template.patient_preferred_method ? 'Applied' : 'Not Applied'}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="reminder_template.outreach_email">
        <div class="media">
          <div class="media-left">
            <i class="fa icon-notebook fs14 vl-m mr"></i>
            <label class="field-label">Email Subject</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400">{{reminder_template.email_subject}}</h5>
        </div>
      </li>
      <li class="list" *ngIf="reminder_template.outreach_email">
        <div class="media">
          <div class="media-left">
            <i class="fa icon-envelope fs14 vl-m mr"></i>
            <label class="field-label">Email Message</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400" [innerHTML]="reminder_template.email_body | safeHtml"></h5>
        </div>
      </li>
      <li class="list" *ngIf="reminder_template.outreach_sms">
        <div class="media">
          <div class="media-left">
            <i class="fa icon-bubble fs14 vl-m mr"></i>
            <label class="field-label">SMS Message</label>
          </div>
          <h5 class="media-body media-middle m0 fw-400" style="white-space:pre-line; word-break:break-all;">{{reminder_template.sms_body}}</h5>
        </div>
      </li>
    </ul>
    <div class="p">
      <h4>Reminder Recipients</h4>
      <app-reminder-listing [template_id] = "reminder_template.id" ></app-reminder-listing>
    </div>
  </div>
</div>
<ng-container *ngIf="editReminder">
  <app-new-reminder [reminder] = "reminder"  (closeform)="closeNewForm($event)"></app-new-reminder>
</ng-container>
