import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../../services/tabs.service';

@Component({
  selector: 'app-all-doctors',
  templateUrl: './all-doctors.component.html',
  styleUrls: ['./all-doctors.component.scss']
})
export class AllDoctorsComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('all_doctors_components');
  }

  ngOnInit() {
  }

}
