import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import { Observable } from 'rxjs';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class DepartmentService {

  constructor(private _tokenService: CustomHttpClient) { }

  getDepartments(practice_id?: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('practice_id', practice_id);
    return this._tokenService.get('cases/departments', {params: args})
      .toPromise()
      .then(r => r);
  }
}
