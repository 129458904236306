import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {Campaign} from './campaign';
import {Subject} from 'rxjs';
import {nullRemover} from '../services/nullRemover';
import {Tab} from '../tabs/tab';
import {TabsService} from '../services/tabs.service';
import {NewEmailCampaignComponent} from './email-marketing/new-email-campaign/new-email-campaign.component';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  reloadCampaigns: Subject<any> = new Subject<any>();
  constructor(private _tokenService: CustomHttpClient, public tabsService: TabsService) { }

  getCampaigns(filter: any): Promise<any> {

    let params = new HttpParams({fromObject: filter});
    return this._tokenService.getWithHeaders('campaigns', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          campaigns: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  create(campaign: Campaign): Promise<any> {
    return this._tokenService
      .post('campaigns', campaign.to_params())
      .toPromise()
      .then(response => response)
  }

  save(campaign: Campaign): Promise<any> {
    if (campaign.is_new_record()) {

      return this.create(campaign);
    }
    else {
      return this.update(campaign);
    }
  }

  update(campaign: Campaign) {
    return this._tokenService
      .put('campaigns/' + campaign.id, campaign.to_params())
      .toPromise()
      .then(response => response)
  }

  updateCampaignBody(campaign: Campaign) {
    return this._tokenService
      .post('campaigns/' + campaign.id + '/campaign_message', {body: campaign.campaign_body})
      .toPromise()
      .then(response => response)
  }
  delete(campaign: Campaign) {
    return this._tokenService
      .delete('campaigns/' + campaign)
      .toPromise()
      .then(response => response)
  }

  getSmtpEmails() {
    return this._tokenService.get('campaigns/email_configurations')
      .map((resp) => resp);

  }
  removeUnderScore(str: string) {
    if (str) {
      let i, frags = str.split('_');
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }
  }

  runCampaign(campaign_id) {
    return this._tokenService.get('campaigns/run_campaign/' + campaign_id)
      .toPromise()
      .then(response => response)
  }

  // campaign Analytics

  campaignAnalytics(campaign_id) {
    return this._tokenService.get('campaigns/' + campaign_id + '/campaign_analytics')
      .toPromise()
      .then(response => response)
  }

  campaignRecipients(campaign_id) {
    return this._tokenService.get('campaigns/' + campaign_id + '/recipient_statistics')
      .toPromise()
      .then(response => response)
  }

  campaignRecipientsList(campaign_id , filter: any) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('campaigns/' + campaign_id + '/campaign_recipients', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return{
          recipientsList: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }
  campaignAudienceList(campaign_id , filter: any) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('campaigns/' + campaign_id + '/recipients', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return{
          audienceList: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page'),
        }
      })
  }
  campaignClickedData(campaign_id) {
    return this._tokenService.get('campaigns/' + campaign_id + '/clicked_data')
      .toPromise()
      .then(response => response)
  }

  testRun(campaign_id, contact_type, contact_array, campaign_body) {
    let  params = {id: campaign_id, contacts: contact_array, contact_type: contact_type, body: campaign_body}
    return this._tokenService.post('campaigns/test_run', params)
      .toPromise()
      .then(resp => resp)
  }

  // Save As

  public saveAsCampaign(campaign) {
    delete campaign.id;
    let template = new Campaign().saveAsCampaign(campaign);
    this.tabsService.add_tab(new Tab({title: 'Save As', icon: 'fa'}, NewEmailCampaignComponent, '',
      { campaign: template}))
  }
}
