import {Component, Input, OnInit} from '@angular/core';
import {AlertTemplateRule} from '../../../../routes/alert-template-index/alert-template-rule';
import {AlertTemplateService} from '../../../../routes/alert-template-index/alert-template.service';
import {UserService} from '../../../../services/user.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {AlertService} from '../../../../_alert';
import {VitalsService} from "../../../../services/vitals.service";

@Component({
  selector: 'app-patient-alerts-settings',
  templateUrl: './patient-alerts-settings.component.html',
  styleUrls: ['./patient-alerts-settings.component.scss']
})
export class PatientAlertsSettingsComponent implements OnInit {
  @Input() patient;
  alertTemplateRules: AlertTemplateRule[] = [];
  totalItems: any;

  public filter: Object = {practice_id: undefined,  patient_id: undefined, page: 1};

  constructor(public alertTemplateService: AlertTemplateService, public userService: UserService,
              public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public alertService: AlertService, public vitalService: VitalsService) { }

  ngOnInit(): void {
    this.getPatientCustomizeRules()
  }
  getPatientCustomizeRules() {
    this.loaderService.show();
    this.filter['practice_id'] = this.userService.current_user.profileable['practice_id'];
    this.filter['patient_id'] = this.patient.id;
    this.alertTemplateService.getPatientCustomizeRules(this.filter).then(resp => {
      this.alertTemplateRules = resp.alertTemplateRules.map(alertTemplateRule => new AlertTemplateRule().load_from_json(alertTemplateRule));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  saveTemplateRuleForPatient(alertTemplateRule: AlertTemplateRule) {
    this.loaderService.show();
    this.alertTemplateService.saveAlertTemplateRuleForPatient(alertTemplateRule)
      .then(resp => {
        alertTemplateRule.load_from_json(resp)
        this.loaderService.hide();
        this.alertService.success('Alert has been saved successfully', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })

  }
  pageChanged(e: any) {
    this.filter['page'] = e.page;
    this.getPatientCustomizeRules();
  }
}
