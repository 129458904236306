import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {LocationService} from '../../services/location.service';
import {Location} from '../../routes/practices/locations/location';
import {Practice} from '../../routes/practices/practices/practice'
import {SimpleChanges} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'practice-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, OnChanges {
  public locations = Array<Location>();
  @Input() practice: Practice;
  public pencil: boolean;
  constructor(private locationsService: LocationService, public userService: UserService) {
  }
  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      let chng = changes[propName];
      let cur = chng.currentValue;
      this.locationsService.getLocations(cur.id).then(locations => {
        for (let location of locations) {
          this.locations.push(new Location().load_from_json(location))

        }
      })
    }
  }
  ngOnInit() {
  }

}
