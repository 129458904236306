import {Location} from '../../routes/practices/locations/location'
import {PatientReview} from '../doctor/patient_review';

export  class Slot {
  slot = '';
  calendar_id: number;
  location_id: number;
  location_name: string;
  appt_per_slot: number;
  slot_size: number;
  booked_appt: number;
  doctor_id: number;
  calendarable_id: number;
  calendarable_type: string;
  in_break: boolean;
  blocked_calendars = 0;
  datetime: Date;
  is_available: Boolean;
  location_address: string;
  location_time_zone: string;
  date: any;

  load_from_json(json) {
    this.slot = json.slot;
    this.calendar_id = json.calendar_id;
    this.location_id = json.location_id;
    this.appt_per_slot = json.appt_per_slot;
    this.slot_size = json.slot_size;
    this.booked_appt = json.booked_appt;
    this.doctor_id = json.doctor_id;
    this.calendarable_id = json.calendarable_id;
    this.calendarable_type = json.calendarable_type;
    this.location_name = json.location_name;
    this.location_address = json.location_address;
    this.location_time_zone = json.location_time_zone;
    this.in_break = json.in_break;
    this.blocked_calendars = json.blocked_calendars;
    this.datetime = new Date(this.slot.replace(' ', 'T') + this.time_zone());
    this.is_available = this.appt_per_slot > this.booked_appt && !this.in_break && this.matches_margin() && this.blocked_calendars == 0;
    this.date = `${this.datetime.getFullYear()}-${this.datetime.getMonth() + 1}-${this.datetime.getDate()}`
    return this;
  }

  time_zone(){
    var timezone_offset_min = new Date(this.slot.replace(' ', 'T')).getTimezoneOffset(),
      offset_hrs = parseInt(Math.abs(timezone_offset_min/60).toString()),
      offset_min = Math.abs(timezone_offset_min%60),
      timezone_standard;

    if(offset_hrs < 10)
      var offset_hrs_1 = '0' + offset_hrs.toString();

    if(offset_min < 10)
      var offset_min_2 = '0' + offset_min.toString();

// Add an opposite sign to the offset
// If offset is 0, it means timezone is UTC
    if(timezone_offset_min < 0)
      timezone_standard = '+' + offset_hrs_1 + ':' + offset_min_2;
    else if(timezone_offset_min > 0)
      timezone_standard = '-' + offset_hrs_1 + ':' + offset_min_2;
    else if(timezone_offset_min == 0)
      timezone_standard = 'Z';

// Timezone difference in hours and minutes
// String such as +5:30 or -6:00 or Z
    return timezone_standard;
  }

  matches_margin() {
    return true;
    // var d = new Date();
    // var v = new Date();
    // v.setMinutes(d.getMinutes());
    // return this.datetime >= v
  }
}
export  class Physician {
  id: number;
  name = '';
  doctor_full_name = '';
  suffix = '';
  mdistance = 500000;
  specialty = '';
  specialties = '';
  designation = '';
  phone_number = '';
  overall_rating: number ;
  profile_pic = '';
  locations: Location[];
  practice_address_name_phone: any[];
  slots: any;
  grouped_slots: Object = {};
  totalPatientReviews: PatientReview[];
  average_score = 5;
  averageExactRating = 0;
  averageRating = 0;
  virtual_slots: Slot[];
  c_index = 0;
  limit = 3;
  load_from_json(json, slots) {
    this.averageExactRating = json.averageExactRating;
    this.averageRating = json.averageRating;
    this.id = json.id;
    this.name = this.physician_full_name(json);
    this.doctor_full_name = this.full_name(json);
    this.suffix = this.physician_suffix(json);
    this.specialty = json.specialty_name;
    this.specialties = json.specialties_text;
    this.designation = json.designation;
    this.phone_number = json.phone_number;
    this.overall_rating = json.overall_ratings;
    this.profile_pic = json.profile_pic;
    this.locations = [];
    this.practice_address_name_phone = json.practice_address_name_phone;
    this.mdistance = 500000;
    if (json.locations) {
      json.locations.forEach(location_json => {
        let location = new Location().load_from_json(location_json);
        if (location.distance < this.mdistance) {
          this.mdistance = location.distance;
        }
        this.locations.push(location);
      });
    }
    if(json.patient_reviews && json.patient_reviews.length > 0){
      var totalPatientReviews = json.patient_reviews.map( patientreview => new PatientReview().load_from_json(patientreview));
      this.totalPatientReviews = totalPatientReviews;
    }
    this.load_slots(slots);
    return this;
  }

  load_slots(slots_json) {
    this.slots = [];
    slots_json && Object.keys(slots_json).forEach(date => {
      let single_day_slots = []
      slots_json[date] && slots_json[date].forEach(json => {
        let location = this.find_location(json.location_id);
        json.location_name = location && location.name;
        let slot = new Slot().load_from_json(json);
        if (slot.is_available) {
          single_day_slots.push(slot);
        }
      });
      this.slots.push(single_day_slots)
    });
    this.setGroupedSlots();
  }

  physician_full_name(json){
    if(json.suffix){
      return `${json.prefix} ${json.name}, ${json.suffix}`;
    }
    return `${json.prefix} ${json.name}`;
  }

  full_name(json){
    return `${json.prefix} ${json.name} `;
  }

  physician_suffix(json){
    if(json.suffix){
      return `${json.suffix}`;
    }
  }

  get average_rating(): number{
    var total_rating = 0;
    if(this.totalPatientReviews){
      this.totalPatientReviews.map(patientReview => {
        total_rating = total_rating + patientReview.average_rating
      })
      this.average_score = total_rating/this.totalPatientReviews.length
      return this.average_score;
    }else{
      this.average_score = 0;
      return this.average_score;
    }
  }

  set average_rating(val){

  }

  find_location(id){
    return this.locations.filter(loc => loc.id == id)[0]
  }

  setGroupedSlots(){
    this.grouped_slots = {};
    this.slots.forEach(slots => {
      slots.forEach(slot => {
        if (this.grouped_slots[slot.location_id] && this.grouped_slots[slot.location_id][slot.date]){
          this.grouped_slots[slot.location_id][slot.date].push(slot);
        } else {
          if(!this.grouped_slots[slot.location_id])
          this.grouped_slots[slot.location_id] = {};
          this.grouped_slots[slot.location_id][slot.date] = [slot];
        }
      })
    });
    Object.keys(this.grouped_slots).forEach(location_id => {
      Object.keys(this.grouped_slots[location_id] || {}).forEach(date => {
        this.grouped_slots[location_id][date] = this.grouped_slots[location_id][date].sort((s1,s2) => s1.datetime - s2.datetime);
      })
  })
  }
  get limited_virtual_slots(): Slot[] {
    let slots = this.virtual_slots.slice(this.c_index, this.limit);
    return slots;
  }
  forward(): void {
    this.c_index = this.limit;
    this.limit = this.limit + 3;
  }
  backward(): void {
    this.limit = this.c_index;
    this.c_index  = this.c_index - 3;
  }

  setVirtualSlots(): void {
    this.locations.forEach(location => {
      if (this.grouped_slots[location.id]) {
        const today = Object.keys(this.grouped_slots[location.id])[0];
        this.virtual_slots = this.grouped_slots[location.id][today];
      }
    })
  }
}
