<table class="table" *ngIf="show_block_calendar_listing && block_calendars && block_calendars.length > 0">
  <thead>
    <tr>
      <th>Name</th>
      <th>At</th>
      <th>From</th>
      <th>To</th>
      <th class="text-center">Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let block_calendar of block_calendar_listing()">
      <tr>
        <td class="pointer">
          <a href="javascript:void(0)" (click)="edit_calendar(block_calendar)">{{block_calendar.name}}</a>
        </td>
        <td>{{block_calendar.location_name}}</td>
        <td>
          {{block_calendar.from | date: 'mediumDate'}}, <span>{{block_calendar.start_time}}</span>
        </td>
        <td>
          {{block_calendar.to | date: 'mediumDate'}}, <span>{{block_calendar.end_time}}</span>
        </td>
        <td class="text-center">
          <a *userCan="permissions" (click)="classicModal.show();set_reference_for_block(block_calendar)">
            <i class="fa fa-times text-danger"></i>
          </a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<app-block-calendar-form [permissions]="permissions" *ngIf="show_block_calendar_form" (block_form_hide)="handle_hide_form($event)"
                         (edited_block_calendar)="calendar_saved()" [calendarable_id]="block_calendar.calendarable_id"
                         [calendarable_type]="block_calendar.calendarable_type" [block_calendar]="block_calendar"
                         [block_calendar_locations]="block_calendar_locations">
</app-block-calendar-form>

<div class="modal" model="classicModal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:222px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Remove</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger text-center">Are you sure you want to delete block calendar?</div>
      </div>
      <div class="modal-footer">
        <button type="submit" (click)="delete_block_calendar(referece_block_calendar);classicModal.hide()"
                data-dismiss="classicModal" class="btn btn-primary">Yes</button>
        <button type="button" (click)="classicModal.hide()" class="btn btn-inverse">Cancel</button>
      </div>
    </div>
  </div>
</div>
