<div class="filters-sec flex-header">
  <div class="row">
    <div class="col-xs-3">
      <input class="form-control" [(ngModel)]="filter['keyword']" (keyup.enter)="search()" placeholder="Patient">
    </div>
<!--    <div class="col-xs-2">-->
<!--      <select #status class="form-control" (change)='setStatusFilter(status)'>-->
<!--        <option value="">Status</option>-->
<!--        <option *ngFor="let status of statusArray; let i = index;" [selected]="status == filter['status']" [value]="status">{{userService.removeUnderScore(status)}}</option>-->
<!--      </select>-->
<!--    </div>-->
    <div class = "col-xs-2">
      <ss-multiselect-dropdown [options]="myOptions"
                               [texts]="myTexts"
                               [(ngModel)]="optionsModel"
                               [settings]="selectSettings"
                               (ngModelChange)="selectStatuses($event)">

      </ss-multiselect-dropdown>
    </div>
    <div class="col-xs-3">
      <input type="text"
             [bsValue]="dateRangeValue"
             placeholder="Datepicker"
             class="form-control" [outsideClick]="true"
             (bsValueChange)="setDateRange($event)"
             [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false}"
             #dp="bsDaterangepicker"
             bsDaterangepicker/>
    </div>
    <div class="col-md-2 col-xs-5">
      <button type="button" class="btn btn-tertiary btn-outline btm-min" (click)="search()"><i class="fa fa-filter"></i> Filter</button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Date</th>
      <th>Patient</th>
      <th>DOB</th>
      <th>Cell</th>
      <th>Intake Template</th>
      <th>Requested By</th>
      <th>Status</th>
      <th>Last updated by</th>
      <th>Last updated on</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let form of intakeFormTemplates">
      <td>
        <ng-container *ngIf="form.status === 'filled' && form.status !== 'added_to_chart'; else elseBlock">
          <a (click)="viewPatientIntakeForm(form)">{{form.send_date | date:'MMMM d, y, h:mm a'}}</a>
        </ng-container>
        <ng-template #elseBlock>
          {{form.send_date | date:'MMMM d, y, h:mm a'}}
        </ng-template>
      </td>
      <td>
        <a class="text-capitalize" (click)="add_to_tabs(form)">{{form.patient_name}}</a>
      </td>
      <td>{{form.patient_dob | date:'MMMM d, y'}}</td>
      <td>{{form.patient_phone | mask: ' (000) 000-0000'}}</td>
      <td>{{form.name}}</td>
      <td>{{form.requested_by_name}}</td>
      <td>{{userService.removeUnderScore(form.status)}}</td>
      <td>{{form.updater_name}}</td>
      <td>{{form.last_updated_at | date:'MMMM d, y, h:mm a'}}</td>
      <td>
        <button  class="btn btn-danger btn-outline" type="button" name="{{form.id}}" *ngIf="form.status !== 'discarded'" (click)="setActionFilter(form.id, 'discarded')">Discard</button>
      </td>
    </tr>
    </tbody>
  </table>
  <app-no-result-found *ngIf="intakeFormTemplates && intakeFormTemplates.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center" *ngIf="intakeFormTemplates && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>

