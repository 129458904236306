import {Component, Input, OnInit} from '@angular/core';
import {PatientVital} from '../../../../../routes/practice-site/vital/PatientVital';

@Component({
  selector: 'app-vitals-report-table',
  templateUrl: './vitals-report-table.component.html',
  styleUrls: ['./vitals-report-table.component.scss']
})
export class VitalsReportTableComponent implements OnInit {
  @Input() monitoring: any[]; // Assign your array of monitoring objects here
  patientVitals: PatientVital[];
  constructor() {  }

  ngOnInit(): void {
    this.patientVitals = this.monitoring.map(vital => new PatientVital().load_from_json(vital));
    }

  checkColumnData(columnName: string): boolean {
    return this.patientVitals.some(pv => pv[columnName] && pv[columnName].value);
  }

}
