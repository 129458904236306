import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {SettingsService} from '../../core/settings/settings.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Globals} from '../../globals'
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../routes/users/user';
import {UserService} from '../../services/user.service';
import {DataService} from '../../services/data.service';
import {HttpTokenService, SignInData} from '../../services/http.token.service';
import {MessageService} from '../../services/message.service';
import {AlertService} from '../../_alert';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {LoaderService} from '../../services/loader.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private userType: any = [
    { name: 'patient', path: '#' },
    { name: 'doctor', path: '/enterprise/home/dashboard' },
    { name: 'practice', path: '/enterprise/home/dashboard' },
    { name: 'group', path: '/enterprise' },
    { name: 'enterprise', path: '/enterprise/home/dashboard' }
  ];

  show_error = false;
  account_not_verified = false;
  valForm: FormGroup;
  public _signInData: SignInData = <SignInData>{};
  public _output: any;
  user: User = new User();
  @Input() show;
  @Output() login_popup_closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  browser = <any>navigator;
  public unverified_hashid;


  constructor(public settings: SettingsService, fb: FormBuilder, public alertService: AlertService,
              private _tokenService: HttpTokenService, private globals: Globals,
              private router: Router, public _userService: UserService, private data_service: DataService,
              private route: ActivatedRoute, private _message_service: MessageService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService) {


    this.valForm = fb.group({
      'email': [null, Validators.required],
      'password': [null, Validators.required]
    });


  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this._output = null;
      this._signInData.email = this._signInData.email.toLowerCase();
      this._tokenService.signIn(this._signInData).subscribe(
        res => {
          this._userService.user_email_verified_successfully = false;
          this._userService.force_close = false;
          this.data_service.mark_user_dirty();
          this._signInData = <SignInData>{};
          this._output = res;
          this._message_service.set_notifications();

          let redirect_to_role = this.userType.filter(item => (item.name === this._tokenService.currentUserData['userType']));
          let user = this.user.load_from_json(this._tokenService.currentUserData);
          this.trigger_login_close();
          console.log('check redirect', user);
          if (user.extra['redirect_to_change_password']) {
            this.router.navigate(['change-password'], { queryParams: {hippa: true} });
            return false;
          }
          // if (false && localStorage.getItem('redirectTo')) {
          //
          //   // logged in so return true
          //   this.trigger_login_close();
          //   this.router.navigateByUrl(localStorage.getItem('redirectTo'));
          //   return true;
          // }

          // not logged in so redirect to login page with the return url and return false
          let re_director = (redirect_to_role  && redirect_to_role[0]) ? redirect_to_role[0].path : 'home';
          if (re_director != '#') {
            this.router.navigateByUrl(re_director);
            return false;
          } else {
            this.router.navigateByUrl('/patients/' + user.profileable_id);
            return false;
          }
        }, error => {
          if (error && error.status === 401) {
            this.setTimeOutFailsMessage();
          }
          if (error && error.status === 409) {
            this.setTimeOutFailsMessageInactiveUser();
          }
          if (error && error.status === 408) {
            this.account_not_verified = true;
            this._output = error.error.errors[0];
            this.unverified_hashid = error.error.id;
          }
        }
      );
    }
  }
  setTimeOutFailsMessage(): any {
    this.show_error = true;
    this._output = 'Invalid email or password.';
    setTimeout(() => {
      this._output = null;
      this.show_error = false;
    }, 3000)
  }

  setTimeOutFailsMessageInactiveUser(): any {
    this.show_error = true;
    this._output = 'User is inactive/blocked. Please contact our customer support to activate/unblock this user.';
    setTimeout(() => {
      this._output = null;
      this.show_error = false;
    }, 3000)
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['email']) {
        this._signInData.email = params['email'];
      }
    });
    // if (this._userService.current_user) {
    //   this.router.navigate(['home']);
    // }
  }
  trigger_login_close() {
    this._userService.toggleLogin();
  }

  resendVerificationEmail() {
    if (this.unverified_hashid) {
      this.loaderService.show();
      this._userService.resendVerificationEmail(this.unverified_hashid).then(response => {
        this.unverified_hashid = undefined;
        this._output = null;
        this.account_not_verified = false;
        this.loaderService.hide();
        this.alertService.success('eMail has been sent successfully', this.alertService.options);
      }).catch(res => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = res.error.error;
      })
    }
  }
}
