import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProgramIntegration} from '../program-integration';
import {ProgramIntegrationService} from '../program-integration.service';
import {UserService} from '../../../services/user.service';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {IntegrationCredential} from '../integration-credential';
import {AlertService} from '../../../_alert';

@Component({
  selector: 'app-program-integration',
  templateUrl: './program-integration.component.html',
  styleUrls: ['./program-integration.component.scss']
})
export class ProgramIntegrationComponent implements OnInit {
  @Input() program_integration: ProgramIntegration;
  public integration_vendors: any[];
  @Output() programIntegratorAuthorized: EventEmitter<boolean> = new EventEmitter<boolean>()
  constructor(public programIntegrationService: ProgramIntegrationService, public userService: UserService,
              public loaderService: LoaderService, private globalErrorHandlerService: GlobalErrorHandlerService,
              public alertService: AlertService) { }

  ngOnInit(): void {
    this.getVendors();

  }

  getVendors() {
    this.programIntegrationService.getIntegrationVendors().then(resp => {
      this.integration_vendors = resp;
    })
  }
  authenticate() {
    this.loaderService.show();
    this.program_integration.practice_id = this.userService.current_user.profileable['practice_id'];
    this.program_integration.creator_id = this.userService.current_user.id;
    this.programIntegrationService.save(this.program_integration)
      .then(resp => {
        this.program_integration = this.program_integration.load_from_json(resp);
        this.closeIntegrationForm();
        this.loaderService.hide();
        this.alertService.success('Integration has been saved successfully', this.alertService.options);
      })
      .catch(resp => {
        this.loaderService.hide();
        this.returnCustomError(resp.error.error);
      })
  }
  setIntegrationVendor(value: any) {
    this.programIntegrationService.showIntegrationForm = true
    let vender = this.integration_vendors.filter(v => v.id.toString() === value.toString())[0];
    if (vender) {
      this.program_integration.integration_id = vender.id;
      this.program_integration.integration_credentials = vender.integration_settings
        .map(credential => new IntegrationCredential().load_for_new(credential))
    } else {
      this.closeIntegrationForm();
    }
  }

  closeIntegrationForm() {
    this.programIntegrationService.showIntegrationForm = false;
    this.program_integration = new ProgramIntegration();
    this.programIntegratorAuthorized.emit(true)
  }

  private returnCustomError(error) {
    if (error.includes('duplicate record')) {
      this.globalErrorHandlerService.error = 'Duplicate Record try again'
    } else if (error.includes('not valid')) {
      this.globalErrorHandlerService.error = 'Invalid credentials';
    } else if (error.includes('service not known')) {
      this.globalErrorHandlerService.error = 'Incorrect URL OR Invalid credentials'
    } else if (error.includes('unauthorized')) {
      this.globalErrorHandlerService.error = 'You are unauthorized. Enter valid Integrator User or Integrator Key'
    } else {
      this.globalErrorHandlerService.error = error
    }
  }
}
