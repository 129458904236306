import baseApiUrl from './../../globals'
import { Term } from '../enote_models/term';
import {EnoteService} from '../../services/enote.service';
export class Template {
    id: number;
    name = '';
    owner_id: number;
    author_id: number;
    author_name: string;
    owner_name: string;
    updated_by_id: number;
    public active_term: Term;
    is_active = true;
    terms: Term[] = [];
    signed_by: string;
    temp_type: string;
    stage: string;
    appointmentID: any;
    source_id: any;
    source_type: any;
    documentTypeID;
    specialtyID: number;
    specialtyName = '';
    signature: string;
    time_passed: string;
    created_at: string;
    template_id: any;
    patient_id: any;
    document_type_name: string;
    is_practice_template: boolean;
    is_enterprise_template: boolean;
    is_rich_text: boolean;
    formatted_created_at: string;
    reason_of_visit: string;
    signed_by_id: any;
    added_to_medical_chart: boolean;
    load_from_json(json, enoteService?: EnoteService) {
        this.id = json.id;
        this.is_enterprise_template = json.is_enterprise_template;
        this.is_practice_template = json.is_practice_template;
        this.document_type_name = json.document_type_name;
        this.name = json.name;
        this.template_id = json.template_id
        this.appointmentID = json.appointment_id;
        this.source_id = json.source_id;
        this.source_type = json.source_type;
        this.specialtyName = json.specialty_name;
        this.specialtyID = json.specialty_id;
        this.documentTypeID = json.document_type_id;
        this.temp_type = json.temp_type;
        this.signed_by = json.signed_by;
        this.signature = json.signature;
        this.author_id = json.author_id;
        this.owner_id = json.owner_id;
        this.owner_name = json.owner_name;
        this.author_name = json.author_name;
        this.stage = json.stage;
        this.time_passed = json.time_passed;
        this.created_at = json.created_at;
        this.is_rich_text = json.is_rich_text;
        this.reason_of_visit = json.reason_of_visit;
        this.formatted_created_at = json.formatted_created_at;
        this.patient_id = json.patient_id;
        this.signed_by_id = json.signed_by_id;
        this.added_to_medical_chart = json.added_to_medical_chart;
        if (json.terms && json.terms.length > 0) {
            this.terms = json.terms.map(term => new Term('', '').load_from_json2(term, null, null, enoteService));
        }
        return this;
    }

    to_params(is_copy?) {
        return {
          is_copy: is_copy,
          template: {
            id: this.id,
            name: this.name,
            document_type_id: this.documentTypeID,
            specialty_id: this.specialtyID,
            owner_id: this.owner_id,
            author_id: this.author_id,
            temp_type: this.temp_type,
            template_id: this.template_id,
            appointment_id: this.appointmentID,
            source_type: this.source_type,
            source_id: this.source_id,
            updated_by_id: this.updated_by_id,
            is_active: this.is_active,
            patient_id: this.patient_id,
            stage: this.stage,
            signed_by_id: this.signed_by_id,
            enote_base_terms_attributes: this.terms.map((term, index) => term.to_params(index))
        }
      }
    }
  isNote() {
    return this.stage === 'signed_enote';
  }

  isPrintAble() {
    return this.stage === 'print_enote';
  }

  isCarePlan() {
      return this.source_type === 'ProgrammeEnrolment'
  }
  get isReadOnly() {
    return this.stage === 'read_only_template';
  }
}


