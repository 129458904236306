<div class="flex-control flex-column flex-height">
  <div class="tabs-counter">
    <ul *ngIf="role_types" class="custom-tabs nav">
      <li *ngFor="let role_type of role_types">
        <button style="height:36px;"
                [(ngModel)]=selected_menu name="selected_menu" [value]="role_type.id" ngDefaultControl
                (click)="onSelect(role_type.id,role_type.name)"
                [class.active]="selected_menu == role_type.name ? 'active' : '' " class="btn">
          <i [ngClass]="iconMapping(role_type.name)" class="pad"></i>
          {{role_type.name.charAt(0).toUpperCase()+ role_type.name.slice(1)}}
        </button>
      </li>
    </ul>
  </div>
  <div class="flex-control flex-column" *ngIf="specificRoles">
    <div class="flex-header">
      <div class="row">
        <div class="col-sm-3" *ngIf="show_search">
          <div *ngIf="selected_menu == 'practice'" class="pl-lg">
            <input #searchBox [(ngModel)]="asyncSelected" (keyup)="setPermissionableId(searchBox.value)" [typeahead]="dataSourcePractices" (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7" [typeaheadOptionField]="'name'" placeholder="Search Practice" class="form-control">
          </div>
          <div *ngIf="selected_menu == 'group'" class="pl-lg">
            <input #searchBox [(ngModel)]="asyncSelected" (keyup)="setPermissionableId(searchBox.value)" [typeahead]="dataSourceGroup" (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7" typeaheadWaitMs="500" [typeaheadOptionField]="'name'" placeholder="Search Group" class="form-control">
          </div>
        </div>
        <div class="col-sm-2">
          <select #selectedCategory (change)="onChange(selectedCategory.value,practice_id)" class="form-control">
            <option value="">Please Select</option>
            <option *ngFor="let role of specificRoles" value={{role.id}}>{{role.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="flex-body p scroll-browser" *ngIf="role_id">
      <permission-detail [role_id]="role_id" [permissionable_id]="practice_id" [role_type_id]="role_type_id"></permission-detail>
    </div>
  </div>
</div>
