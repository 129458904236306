import {Component, OnInit, Input, OnDestroy, Injector, ViewChild} from '@angular/core';
import {Patient} from '../../doctor/patient';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {Appointment} from '../../models/appointment';
import {Tab} from '../../../tabs/tab';
import {PatientProfileComponent} from '../patient-profile.component';
import {TabsService} from '../../../services/tabs.service';
import {LoaderService} from '../../../services/loader.service';
import {AppointmentService} from '../../../services/appointment.service';
import {PatientService} from '../../../services/patient.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {Subscription} from 'rxjs/Subscription';
import {PatientNotes} from '../patient-dashboard/patient-communication-log/patient-notes/patient-notes';
import {PatientNotesService} from '../patient-dashboard/patient-communication-log/patient-notes/patient-notes.service';
import {NewPatientNotesComponent} from '../patient-dashboard/patient-communication-log/patient-notes/new-patient-notes/new-patient-notes.component';

@Component({
  selector: 'app-patient-panel',
  templateUrl: './patient-panel.component.html',
  styleUrls: ['./patient-panel.component.scss']
})
export class PatientPanelComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;
  @Input() appointment: Appointment;
  public show_attachment_popup = false;
  public show_extras_field = false;
  public  family_patient = new Patient();
  public selectedFamilyMember: Patient = new Patient();
  public patientStatusObservable: Subscription;
  public sticky_notes: any[] = [];
  public reloadStickyNote: Subscription;
  public closeNewNoteForm: Subscription;
  selectedStickyNoteId: any;
  custom_injector: Injector;
  @ViewChild('newNoteModel') newNoteModel;
  @ViewChild('deleteModal') deleteModal;
  public show_node_model = false;
  newNoteComponent = NewPatientNotesComponent;
  constructor(private _tabs_service: TabsService, public _appointment_service: AppointmentService,
              private router: Router, public userService: UserService, public injector: Injector,
              public loaderService: LoaderService, public patientService: PatientService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public patientNotesService: PatientNotesService) {

    this.reloadStickyNote = this.patientNotesService.reloadStickyNote.subscribe(item => {
      if (item) {
        this.getStickyNotes();
      }
    });
    this.closeNewNoteForm = this.patientNotesService.closeNewNoteForm.subscribe(item => {
      if (item) {
        this.newNoteModel.hide();
        this.deleteModal.hide()
      }
    });
  }

  ngOnInit() {
    this.patientStatusObservable = this._appointment_service.patientStatusObservable.subscribe(val => {
      if (val.user_id === this.patient.user.id) {
        this.patient.user.online_status = val.status;
        this.patient.mobile_online_status = val.mobile_online_status;
        this.patient.portal_online_status = val.portal_online_status;
      }
    })
    this.family_patient.parent_id = this.patient.parent_id ? this.patient.parent_id : this.patient.id;
    if (this.userService.current_user.isPracticeUser) {
      this.getStickyNotes();
    }

  }
  onSelect(family_member: Patient) {
    if (this.appointment || !this.userService.current_user.isPatient) {
      this.add_to_tabs(this.appointment, family_member);
    }
    else {
      this.router.navigate(['/patients', family_member.id]);
      this.show_attachment_popup = false;
    }
  }

  handle_extra_fields() {
    this.show_extras_field = true;
  }

  handle_image_upload(data) {
    if (data && data.hasOwnProperty('error') == false) {
      this.patient.profile_pic =  data.profile_pic;
      if (this.patient.user.id == this.userService.current_user.id) {
        this.patient.user.profile_pic = data.profile_pic;
        this.userService.current_user.profile_pic = data.profile_pic;
      }
    }
  }
  choose_file() {
    this.show_attachment_popup = true;
    setTimeout( () => {
      this.show_attachment_popup = false;
    }, 75); }
  add_to_tabs(appointment: Appointment, family_member: Patient) {
    let title = family_member.user.first_name + '-' + 'Profile';
    let id = family_member.id;
    this._tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id, {id: id, appointment: appointment}))
  }

  capitalize_letter(value) {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }
  handle_family_addition(family_member) {
    this.family_patient = new Patient();
    this.family_patient.parent_id = this.patient.id;
    this.patient.family_members.push(family_member)
  }

  removeRelationShip(familyMember: Patient) {
    if (familyMember.parent_id) {
      this.loaderService.show();
      familyMember.parent_id = null;
      this.patientService.removeParentId(familyMember.id).then(res => {
        if (this.userService.current_user && this.userService.current_user.id === this.patient.user.id) {
          this.userService.refresh_patient(this.patient);
        }
        this.patient.family_members = this.patient.family_members.filter(({ id }) => id !== familyMember.id);
        this.selectedFamilyMember = new Patient();
        this.loaderService.hide();
      }).catch(resp =>  {
        this.globalErrorHandlerService.error = resp.error.error;
        this.loaderService.hide();
      });
    }
  }

  deleteCall(family_member: Patient) {
    this.selectedFamilyMember = this.selectedFamilyMember.load_from_json(family_member)
  }

  ngOnDestroy(): void {
    this.patientStatusObservable.unsubscribe();
    this.reloadStickyNote.unsubscribe();
    this.closeNewNoteForm.unsubscribe();
  }
  getStickyNotes(){
    this.patientNotesService.getStickyNotes(this.patient.id).then(resp => {
      this.sticky_notes = resp.map(patient_notes => new PatientNotes().load_from_json(patient_notes));
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }
  openStickyNote(note: PatientNotes) {
    this.setCustomInjectorForNote(note);
  }
  setCustomInjectorForNote(note: PatientNotes) {
    this.custom_injector  = Injector.create([
      {provide: 'patient_note', useValue: note},
      {provide: 'id', useValue:  note.id},
      {provide: 'patient_id', useValue:  note.patient_id},
      {provide: 'isprogramme_profile', useValue:  true}
    ], this.injector);
    this.show_node_model = true;
    this.newNoteModel.show();
  }
  unSricky(noteId) {
    this.loaderService.show();
    this.patientNotesService.removeSticky(noteId).then(resp => {
      this.patientNotesService.reloadStickyNote.next(1);
      this.selectedStickyNoteId = '';
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
}
