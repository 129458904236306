<ng-controller  [ngClass]="user_service.showHideSideBar ? 'hidden' : ''">
  <nav [ngClass]="settings.layout.isCollapsed ? 'show-scrollbar' : ''" class="sidebar" sidebar-anyclick-close="" [class.show-scrollbar]="settings.layout.asideScrollbar">
    <div class="selected-mmenu hidden">{{menu.selected_menu | uppercase}}</div>
    <!-- START sidebar nav-->
    <ul class="nav" *ngIf="user_service.rerender">

      <li *ngFor='let item of menu.getMenu()' [ngClass]="{'nav-heading': item.heading}" [routerLinkActive]="['active']">
        <ng-container *userCan="item.permission">
          <!-- menu heading -->
          <span *ngIf="item.heading">{{(item.translate | translate) || item.text}}</span>
          <!-- single menu item -->
          <a *ngIf="!item.heading && !item.submenu" class="wrap-text" [routerLink]="item.link" [attr.route]="item.link"
             (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
            <span class="pull-right" *ngIf="item.alert" [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
            <em class="{{item.icon}}" *ngIf="item.icon"></em>
            <span class="link-text hidden-sm hidden-xs"><i class="fa fa-caret-left"></i>{{(item.translate | translate) || item.text}}</span>
            <span class="only-text visible-sm visible-xs">{{(item.translate | translate) || item.text}}</span>
          </a>
          <!-- has submenu -->
          <a *ngIf="!item.heading && item.submenu" title="{{item.text}}"
             (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
            <span class="pull-right" *ngIf="item.alert" [ngClass]="item.label || 'label label-success'">{{item.alert}}</span>
            <em class="{{item.icon}}" *ngIf="item.icon"></em>
            <span class="link-text">{{(item.translate | translate) || item.text}}</span>
          </a>
          <!-- SUBLEVEL -->
          <ul *ngIf="item.submenu" class="nav sidebar-subnav" [routerLinkActive]="['opening']">
            <li class="sidebar-subnav-header">{{(item.translate | translate) || item.text}}</li>
            <li *ngFor='let subitem of item.submenu' [routerLinkActive]="['active']">
              <!-- sublevel: single menu item  -->
              <a *ngIf="!subitem.submenu" [routerLink]="subitem.link" [attr.route]="subitem.link" title="{{subitem.text}}">
                <span class="pull-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                <span>{{(subitem.translate | translate) || subitem.text}}</span>
              </a>
              <!-- sublevel: has submenu -->
              <a *ngIf="subitem.submenu" title="{{subitem.text}}"
                 (click)="toggleSubmenuClick($event)" (mouseenter)="toggleSubmenuHover($event)">
                <span class="pull-right" *ngIf="subitem.alert" [ngClass]="subitem.label || 'label label-success'">{{subitem.alert}}</span>
                <em class="{{subitem.icon}}" *ngIf="subitem.icon"></em>
                <span>{{(subitem.translate | translate) || subitem.text}}</span>
              </a>
              <!-- SUBLEVEL 2 -->
              <ul *ngIf="subitem.submenu" class="nav sidebar-subnav" [routerLinkActive]="['opening']">
                <li class="sidebar-subnav-header">{{(subitem.translate | translate) || subitem.text}}</li>
                <li *ngFor='let subitem2 of subitem.submenu' [routerLinkActive]="['active']">
                  <!-- sublevel 2: single menu item  -->
                  <a [routerLink]="subitem2.link" [attr.route]="subitem2.link" title="{{subitem2.text}}">
                    <span class="pull-right" *ngIf="subitem2.alert" [ngClass]="subitem2.label || 'label label-success'">{{subitem2.alert}}</span>
                    <em class="{{subitem2.icon}}" *ngIf="subitem2.icon"></em>
                    <span>{{(subitem2.translate | translate) || subitem2.text}}</span>
                  </a>
                <li>
              </ul>
            <li>
          </ul>
        </ng-container>
      </li>

    </ul>
    <!-- END sidebar nav-->
    <footer app-footer class="hidden-xs"></footer>
  </nav>

</ng-controller>

