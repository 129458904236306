import {EligiblePatient} from './eligible-patient';
import {Consent} from './program/program-consents/consent';

export class Program {
  id: number;
  name: any;
  care_manager_name: any;
  billing_provider_name: any;
  patient_count: any;
  care_team: any;
  billing_provider_id = '';
  care_manager_id = '';
  care_manager_doctor_id: any;
  started_on: any;
  care_teams: CareTeam[] = [];
  vitals: ProgramVital[] = [];
  enrolledPatients: EligiblePatient[] = [];
  status = 'opened';
  creator_id: number;
  practice_id: number;
  consent_text: any;
  is_deleted = false;
  total_revenue = 0;
  current_month_revenue = 0;
  previous_month_revenue = 0;
  consents: Consent[] = [];
  programTypes: string[] = [];
  programme_category = '';
  private_pay_bill_day: number;
  private_pay_amount: number;
  private_package_name: string;


  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  to_params() {
    return {
      programme: {
        id: this.id,
        name: this.name,
        status: this.status,
        billing_provider_id: this.billing_provider_id,
        care_manager_id: this.care_manager_id,
        consent_text: this.consent_text,
        programme_category: this.programme_category,
        private_pay_bill_day: this.private_pay_bill_day,
        private_pay_amount: this.private_pay_amount,
        private_package_name: this.private_package_name,
        care_teams_attributes: this.care_teams.map(a => a.to_params()),
        programme_vitals_attributes: this.vitals.map(a => a.to_params()),
      }
    }
  }

  load_from_json(json: any) {
    this.id = json.id;
    this.total_revenue = json.total_revenue;
    this.previous_month_revenue = json.previous_month_revenue;
    this.current_month_revenue = json.current_month_revenue;
    this.name = json.name;
    this.care_manager_name = json.care_manager_name;
    this.care_manager_doctor_id = json.care_manager_doctor_id;
    this.billing_provider_name = json.billing_provider_name;
    this.patient_count = json.patient_count;
    this.care_team = json.care_team;
    this.billing_provider_id = json.billing_provider_id;
    this.care_manager_id = json.care_manager_id;
    this.started_on = json.started_on;
    this.private_package_name = json.private_package_name;
    this.private_pay_amount = json.private_pay_amount;
    this.programme_category = json.programme_category;
    this.private_pay_bill_day = json.private_pay_bill_day;
    this.care_teams = json.care_teams.map(a => new CareTeam().load_from_json(a));
    this.vitals = json.programme_vitals.map(a => new ProgramVital().load_from_json(a));
    if (json.consents) {
      this.consents = json.consents.map(c => new Consent().load_from_json(c));
    }
    if (json.available_programme_types) {
      this.programTypes = json.available_programme_types
    }
    this.status = json.status;
    this.is_deleted = json.is_deleted;
    this.consent_text = json.consent_text;
    return this;
  }
}

export class ProgramCodes {
  id: number;
  program_id: number;
  cpt_code_id: number;
  code: number;
  _destroy: boolean;
  to_params() {
    return {
      id: this.id,
      cpt_code_id: this.cpt_code_id,
      _destroy: this._destroy,
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.program_id = json.programme_id;
    this.cpt_code_id = json.cpt_code_id;
    this.code = json.code;
    this._destroy = json._destroy;
    return this;
  }
}

export class CareTeam {
  id: number;
  program_id: number;
  user_id: number;
  _destroy: boolean;
  to_params() {
    return {
      id: this.id,
      user_id: this.user_id,
      _destroy: this._destroy,
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.program_id = json.programme_id;
    this.user_id = json.user_id;
    this._destroy = json._destroy;
    return this;
  }
}

export class ProgramVital {
  id: number;
  program_id: number;
  vital_type_id: number;
  name: string;
  short: string;
  _destroy: boolean;
  to_params() {
    return {
      id: this.id,
      vital_type_id: this.vital_type_id,
      _destroy: this._destroy,
    }
  }
  load_from_json(json) {
    this.id = json.id;
    this.program_id = json.programme_id;
    this.vital_type_id = json.vital_type_id;
    this.name = json.name;
    this.short = json.short;
    this._destroy = json._destroy;
    return this;
  }

}
