import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../services/tabs.service';

@Component({
  selector: 'app-rich-text-template',
  templateUrl: './rich-text-template-index.component.html',
  styleUrls: ['./rich-text-template-index.component.scss']
})
export class RichTextTemplateIndexComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('Rich Text Template')
  }

  ngOnInit(): void {
  }

}
