import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import {ServicesService} from '../../../services/services.service'
import {Practice, Service} from '../practices/practice'
import {Calendar} from '../../practices/doctors/doctor'
@Component({
  moduleId: module.id.toString(),
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnChanges {
  @Input() practice: Practice;
  @Input() practice_permissions: string;
  public perm = 'edit,Practices,manage';
  public show_service_form = false;
  public clients_services = Array<Service>();
  public grouped_services = []
  public show_edit_icon = false;
  public current_dom: number;
  public selected_clients_service_index;
  public new_service: Service = new Service();
  public new_calendar = new Calendar();
  public expanded_service: any;
  public calendar_technicians: any;
  public show_calendar_listing = true;

  constructor(private servicesService: ServicesService) {
  }

  ngOnChanges(changes: SimpleChanges) {
  }
  ngOnInit() {
    this.servicesService.getServices(this.practice.client.id).then(services => {
      for (let service of services) {
        this.clients_services.push(new Service().load_from_json(service));
        this.grouped_services = this.group_in(this.clients_services, 2);
      }
    })
  }
  toggle_service_form() {
    this.show_service_form = !this.show_service_form;
  }
  sync_clients_services(service) {
    if (service != false) {
      this.clients_services.push(service);
      this.practice.client.services = this.clients_services;
    }
    this.show_service_form = false;
  }
  edit_service(i) {
    this.current_dom = i;
    this.show_edit_icon = !this.show_edit_icon;
  }
  toggleService(index?) {
    if (!(this.selected_clients_service_index || this.selected_clients_service_index == 0) && this.show_service_form) {
      this.new_service = new Service();
      this.show_service_form  = false;
    } else {
      this.selected_clients_service_index = null;
      this.show_service_form = true;
    }
  }
  selectService(index) {
    this.selected_clients_service_index = index;
    this.show_service_form = true;

  }
  delete(client_service) {
      let status;
      this.servicesService.delete(client_service.id).then(response => {
          if (response.ok) {
            this.clients_services.splice(this.clients_services.indexOf(client_service), 1);
          }
            this.practice.client.services = this.clients_services;

        }
      )
  }

  toggle_view(service) {
    this.calendar_technicians = service.service_technicians.map(sp => {return {technician_id: sp.technician_id, name: sp.name}});
    service.show_calendars = !service.show_calendars;
    if (service.show_calendars && this.expanded_service && this.expanded_service != service) {
      this.expanded_service.show_calendars = false;
    }
    this.expanded_service = service;
  }

  toggle_calendar_form(service) {
    this.show_calendar_listing = !this.show_calendar_listing;
    service.show_calendar_form = !service.show_calendar_form;
  }
  group_in(array , group_length) {
    let result = [];
    for (let i = 0; i < array.length / group_length ; i ++) {
      result.push([])
    };

    array.forEach((item , index) => {
      result[Math.floor(index / group_length)].push(item);
    });
    return result;
  }

  calendar_saved(calendar, client_service) {
    client_service.calendars.push(calendar);
    this.toggle_calendar_form(client_service);
    this.new_calendar = new Calendar();
  }

  practice_calendar_locations() {
    return this.practice.locations;
  }

  form_hide(clients_service) {
    clients_service.show_calendar_form = !clients_service.show_calendar_form;
  }

}
