<div class="flex-control flex-column flex-height">
  <app-tabs><li (click)="tabs_service.deactivate_tab()" [class.active]="!tabs_service.is_active()">All</li></app-tabs>
  <div class="flex-control flex-column" [hidden]="tabs_service.is_active()">
    <div class="filters-sec flex-header">
      <div class="row">
        <div class="col-sm-3">
          <div class="input-group">
            <input [(ngModel)]="asyncSelected" [typeahead]="dataSource"
                   (typeaheadLoading)="changeTypeaheadLoading($event)" typeaheadWaitMs="500"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)"
                   [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'name'"
                   placeholder="Search Practice" class="form-control">
            <div class="input-group-addon" *ngIf="typeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
          </div>
        </div>
        <div class="col-sm-2">
          <select #status class="form-control" (change)='changeStatus(status.value)'>
<!--            <option *ngFor="let value of values; let i = index;" value={{i+1}}>{{value}}</option>-->
            <option value="1" >All</option>
            <option value="2" selected >Active</option>
            <option value="3" >In Active</option>
          </select>
        </div>
        <div class="col-sm-7">
          <button type="button" class="btn btn-tertiary btn-outline" (click)="search_filters()">Filter</button>
          <button *userCan="'Add,Practices,manage'" type="button" (click)="add_to_tabs(practice)" class="btn btn-secondary ml-sm" >Add</button>
        </div>
      </div>
    </div>
    <div class="flex-body p scroll-browser">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Specialty</th>
            <th>Address</th>
            <th>Contact</th>
            <th>No. of Doctors</th>
            <th>Status</th>
            <th>Office Visit Code</th>
          </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let practice of filtered_practices()">
          <tr>
            <td class="pointer"><a href="javascript:void(0)" (click)="add_to_tabs(practice)">{{practice.name}}</a></td>
            <td [title]="practice.client.specialties[0]?.description">{{practice.client.specialties[0]?.description | truncate:35:'...'}}</td>
            <td [title]="practice.client.complete_address">{{practice.client.complete_address}}</td>
            <td><div>{{practice.requester_firstname}} {{practice.request_lastname}}</div>{{practice.client.contact_numbers[0]?.value}}</td>
            <td class="text-center">{{practice.practice_total_doctors}}</td>
            <td>
              <select *userCan="'activeinactive,practices,manage'" #state class="form-control short-select" [(ngModel)]="practice.client.is_active" id="new-{{practice.id}}" [ngClass]="practice.client.is_active == true ? 'active' : 'inactive'" (change)='changePracticeState(practice)'>
                <option value="true">Active</option>
                <option value="false">In Active</option>
              </select>
            </td>
            <td class="pointer"><a href="javascript:void(0)" (click)="set_token(practice);officeclassicModal.show()">Show</a></td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <app-no-result-found *ngIf="filtered_practices() && filtered_practices().length == 0"></app-no-result-found>
    </div>
    <div class="flex-footer text-center" *ngIf="filtered_practices().length > 10">
      <pagination class="m0" [totalItems]="totalItems" (pageChanged)="pageChanged($event)"></pagination>
    </div>
  </div>
</div>



<div class="modal-container">
  <div class="modal" bsModal #officeclassicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content modal-border" style="margin-top: 250px">
        <div class="modal-heading mb-lg">
          <button type="button" class="close" aria-label="Close" (click)="officeclassicModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>Javascript</div>
          <textarea rows="10" cols="70" disabled style="resize: none">
            <script>(function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = "{{globals.baseApiUrl}}/api_js.js?&practice_identifier={{auth_token}}&visit_type='office'";
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'ilaaj-jssdk'));</script></textarea>
          <div>Html</div>
          <textarea rows="4" cols="70" disabled style="resize: none">
            <button type="button" class="book-appointment-api">Book Appointment</button>
          </textarea>
        </div>
        <div class="row">
          <div class="col-sm-12 mt-lg">
            <div class="col-sm-6"><span class="pull-left"><button type="button" (click)="officeclassicModal.hide()" class="mb-sm btn btn-inverse">Cancel</button></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-container">
  <div class="modal" bsModal #virtualclassicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content modal-border" style="margin-top: 250px">
        <div class="modal-heading mb-lg">
          <button type="button" class="close" aria-label="Close" (click)="virtualclassicModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>Javascript</div>
          <textarea rows="10" cols="70" disabled style="resize: none">
            <script>(function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = "{{globals.baseApiUrl}}/api_js.js?&practice_identifier={{auth_token}}&visit_type='virtual'";
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'ilaaj-jssdk'));</script></textarea>
          <div>Html</div>
          <textarea rows="4" cols="70" disabled style="resize: none">
            <button type="button" class="book-appointment-api">Book Appointment</button>
          </textarea>
        </div>
        <div class="row">
          <div class="col-sm-12 mt-lg">
            <div class="col-sm-6"><span class="pull-left"><button type="button" (click)="virtualclassicModal.hide()" class="mb-sm btn btn-inverse">Cancel</button></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

