<div class="main-wrapper clearfix">
  <div class="row">
    <div class="col-xs-9"><h3 class="mt0">Locations</h3></div>
    <div class="col-xs-3 text-right">
      <button *userCan="practice_permissions" class="btn btn-inverse" (click)="toggle_location()">Add Location</button>
    </div>
  </div>
  <hr class="hr-line mt-lg">
  <div class="todaysPatient-list">
    <ng-container *ngFor="let location of locations">
      <div class="list clearfix pl0" [class.dexpanded]="location.show_profile_detail">
        <div class="col-xs-6">
          <div class="media">
            <div class="media-body">
              <h4 class="mb mt0 fw-500"><a href="javascript:void(0)"
                                           (click)="location.toggle_view();selectLocation(location)">{{location.name}}</a>
              </h4>
              <h5 class="m0">{{location.address.line_1_line_2()}}<br>{{location.address.city_state_zip()}}</h5>
            </div>
          </div>
        </div>
        <div class="col-xs-4 pt">
          <span class="pr-lg" *ngIf="location.fax && location.fax.value">
            <em class="fa fa-fax"></em>&nbsp; {{location.fax.value}}
          </span>
          <span *ngIf="location.phone && location.phone.value">
            <a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(location.phone.value,
                  {name: location.name, pic: ''})">
            <em class="fa icon-phone"></em>&nbsp; {{location.phone.value | mask: '(000) 000-0000'}}
            </a>
          </span>
          <h5 class="mb0"><em class="fa icon-envelope vl-m"></em>&nbsp; {{location.email}}</h5>
        </div>
        <div class="col-xs-2">
          <a class="mt-lg d-block text-right" href="javascript:void(0)"
             (click)="location.toggle_view();selectLocation(location)">
            {{location.show_profile_detail ? "Show Less Info" : "See Complete Info"}}
            <em class="fa fs20 vl-m pl-sm" [class.fa-plus-square-o]="!location.show_profile_detail"
                [class.fa-minus-square-o]="location.show_profile_detail"></em>
          </a>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="p-lg clearfix bg-lightest-gray" *ngIf="selected_location && selected_location.show_profile_detail">
    <div permittedClass [condition]="practice_permissions">
      <div class="row">
        <div class="col-xs-8"><h3 class="mt0">{{selected_location.name}}</h3></div>
        <div class="col-xs-4 text-right">
          <a *userCan="practice_permissions" class="btn btn-primary btn-outline mr" (click)="select_location(selected_location.id)">
            <i class="fa fa-pencil mr-sm"></i> Edit
          </a>
          <ng-container *ngIf="selected_location.can_delete">
            <a *userCan="practice_permissions" class="btn btn-danger btn-outline" (click)="classicModal.show();">
              <i class="fa fa-trash mr-sm"></i> Delete
            </a>
          </ng-container>
        </div>
      </div>
      <div class="doc-user-info">
        <span class="mr">
          <i class="fa icon-location-pin"></i>
          &nbsp;{{selected_location.address.line_1_line_2()}}, {{selected_location.address.city_state_zip()}}
        </span>
        <span class="mr" *ngIf="selected_location.phone && selected_location.phone.value">
          <i class="fa icon-phone"></i>&nbsp; {{selected_location.phone.value }}
        </span>
        <span class="mr" *ngIf="selected_location.fax && selected_location.fax.value">
          <i class="fa fa-fax"></i>&nbsp; {{selected_location.fax.value}}
        </span>
        <span>
          <i class="fa icon-envelope vl-m"></i> &nbsp;{{selected_location.email}}
        </span>
      </div>
    </div>
    <hr class="hr-line">
    <div class="row mb-xl">
      <div class="col-xs-3">
        <label class="fw-500 d-block">Hours of Operation</label>
        <span class="fs18">{{selected_location.operational_hours}}</span>
      </div>
      <div class="col-xs-9">
        <label class="fw-500 d-block">Specialties</label>
        <span class="fs18" *ngFor="let specialty of selected_location.specialties;">{{specialty.description}}</span>
      </div>
    </div>
    <h5 class="fw-500">Media</h5>
    <ul class="practice-attach-img clearfix p0 m0 list-unstyled" *ngIf="practice && practice.id">
      <ng-container *ngIf="selected_location.attachments.length > 0">
        <li class="active white-container-box pull-left p mr-lg">
          <ng-container *ngFor="let location_image of selected_location.attachments ; let im = index">
            <div class="holder text-center" [ngClass]="{active: location_image.active_class}">
              <div class="thumbnail thumb80 p-relative">
                <a class="icon icon-del text-danger" href="javascript:void(0)"
                   (click)="makeActiveInactive(location_image)"><i class="fa fa-trash"></i></a>
                <a class="icon icon-yes text-success" href="javascript:void(0)"
                   (click)="removeLocationImage(selected_location, location_image, im)"><i
                  class="fa fa-check"></i>
                  Yes</a>
                <a class="icon icon-no text-danger" href="javascript:void(0)"
                   (click)="makeActiveInactive(location_image)"><i class="fa fa-times"></i> No</a>
                <img [src]="location_image.img" alt="">
              </div>
              <h5 class="text-capitalize mb0">{{location_image.picture_type}}</h5>
            </div>
          </ng-container>
        </li>
      </ng-container>
    </ul>
    <app-attachments *userCan="practice_permissions" (document_uploaded)="handle_image_upload($event)" [attachable_type]="'Location'" [attachable_id]="selected_location.id"></app-attachments>
    <ng-container *ngIf="selected_location.is_calendarable">
      <div class="clearfix pt-lg">
        <a class="pull-right btn btn-inverse"
           *userCan="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
           (click)="calendar_form()">Add Calendar</a>
        <h4 class="fs20">Calendar</h4>
        <app-calendar-form
          [permissions]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
          *ngIf="show_calendar_form" (form_hide)="handle_hide_form()"
          (edited_calendar)="calendar_saved()" [calendarable_id]="selected_location.id"
          [calendarable_type]="'Location'" [calendar]="calendar"
          [calendar_locations]="[selected_location]">
        </app-calendar-form>
        <ng-container *ngIf="show_calendar_listing">
          <app-calendars-listing
            [permissions]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
            [calendars]="selected_location.calendars" [calendar_locations]="[selected_location]">
          </app-calendars-listing>
        </ng-container>
      </div>
      <div class="clearfix pt-lg">
        <a class="pull-right btn btn-inverse"
           *userCan="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
           (click)="block_calendar_form()">Add Block Calendar</a>
        <h4 class="fs20">Block Calendar</h4>
        <app-block-calendar-form
          [permissions]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' :doctor_permissions"
          *ngIf="show_block_calendar_form" (block_form_hide)="handle_block_calendar_hide_form()"
          (edited_block_calendar)="block_calendar_saved()"
          [block_calendar_locations]="[selected_location]" [calendarable_id]="selected_location.id"
          [calendarable_type]="'Location'" [block_calendar]="block_calendar"></app-block-calendar-form>
        <ng-container *ngIf="show_block_calendar_listing">
          <app-block-calendars
            [permissions]="user_service.current_user.isDoctor ? 'edit,Doctor Profile,settings' : doctor_permissions"
            [block_calendars]="selected_location.block_calendars"
            [block_calendar_locations]="[selected_location]"></app-block-calendars>
        </ng-container>
      </div>
    </ng-container>
    <div class="modal fade" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
      <div class="modal-dialog modal-md" style="height:200px;">
        <div class="modal-content">
          <div class="modal-header"><h4 class="modal-title">Location</h4></div>
          <div class="modal-body text-center text-danger"><h4>Do you want to delete location?</h4></div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary mr-sm" (click)="delete(selected_location,classicModal);">Yes</button>
            <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="locations">
    <app-location [do_show]="show_location"
                  [location]="selected_location  ? selected_location : new_location"
                  [practice_id]="practice.id" (sync_location)="sync_location($event)"
                  (reset_do_show)="reset_show_location($event)"></app-location>
  </ng-container>
</div>


