<div class="">
  <div class="jumbotron">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <div class="text-center pt-xl">
          <i class="icon-ilaaj fs-10x text-generic"></i>
        </div>
      </div>
      <div class="col-lg-9 col-md-8 home-text">
        <h1>Ilaaj</h1>
        <p>Ilaaj <strong>.pk</strong>
          <br> <small>One solution for everyone</small>
        </p>
        <p><a class="btn btn-primary btn-lg" href="https://angular.io/" target="_blank">Learn more</a></p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="panel b">
        <div class="panel-heading">
          <h3>Angular-cli</h3></div>
        <div class="panel-body">
          A Webpack powered command line interface with tons of utilities. Serve and build your project with a single command.
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="panel b">
        <div class="panel-heading">
          <h3>Router</h3></div>
        <div class="panel-body">
          Powerful and modern routes definitions. Easily declare routes and nested routes with components association.
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="panel b">
        <div class="panel-heading">
          <h3>Generators</h3></div>
        <div class="panel-body">
          Angular-CLI tool provides generators to create components, services, directives and pipe from the command line.
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="panel b">
        <div class="panel-heading">
          <h3>Modularized</h3></div>
        <div class="panel-body">
          This project has been carefully designed to provide modules for Core, Layout, Shared and Routed components.
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="panel b">
        <div class="panel-heading">
          <h3>Environments</h3></div>
        <div class="panel-body">
          Ready to work with multiple environments, from first stage development through test and production.
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="panel b">
        <div class="panel-heading">
          <h3>Style Guide</h3></div>
        <div class="panel-body">
          Better practices for Code organization and Project structure based on the official Angular2 style guide.
        </div>
      </div>
    </div>
  </div>
</div>
