import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Chatroom} from '../chatroom';
import {MessageService} from '../../../../services/message.service';

@Component({
  selector: 'app-contact-verification',
  templateUrl: './contact-verification.component.html',
  styleUrls: ['./contact-verification.component.scss']
})
export class ContactVerificationComponent implements OnInit {
  @Input() public chatroom: Chatroom;
  @Output() public phone_verified = new EventEmitter<Boolean>();
  public input_token;
  public error_message = '';
  public verification_inprogress = false;

  constructor(private message_service: MessageService) { }

  ngOnInit() {
  }

  verifyNumber() {
    if (this.input_token == '' || this.input_token == undefined) {
      this.setMessage('Enter mobile number');
      return;
    }
    this.verification_inprogress = true;
    this.message_service.verifyNumber(this.chatroom, this.input_token).subscribe(r => {
      this.verification_inprogress = false;
      if (r['success'] == true) {
        this.chatroom.verification_token = r['token'];
        this.phone_verified.emit(true);
      } else {
        this.setMessage('Mobile Number could not be verified');
      }
    });
  }

  setMessage(msg) {
    this.error_message = msg;
    setTimeout((a => {
      this.error_message = '';
    }), 5000);
  }

}
