<section class="home-main-sec">
  <div class="container">
    <div class="text-container">
      <h4 class="text-primary sub-heading">Take control of your health</h4>
      <h1>Effective patient-doctor engagement</h1>
      <br>
      <p class="fs16">
        WellWink patient engagement app is a technology used by patients to automate and increase communication with
        their caregivers and makes it easy for patients to access their medical history, personalized health guidelines
        and care team when they need them.
      </p>
      <p class="m0 fs16">
        WellWink facilitates engagement through effective patient-focused care, personalized experiences & knowledge
        sharing between providers and patients. Patients can access and monitor their health details from the convenience
        of their own homes without visiting doctors office.
      </p>
      <br><br>
      <div class="row">
        <div class="col-sm-4 col-xs-6">
          <a href="https://play.google.com/store/apps/details?id=com.app.wellwink&hl=en&gl=US&pli=1" class="btn p0" target="_blank">
            <img class="img-responsive" src="/assets/img/public/android-btn-img.webp"/>
          </a>
        </div>
        <div class="col-sm-4 col-xs-6">
          <a href="https://apps.apple.com/us/app/wellwink/id1623810665" class="btn p0" target="_blank">
            <img class="img-responsive" src="/assets/img/public/applestore-btn-img.webp"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="benefits-sec bg-gray-lighter sec-pad">
  <div class="container">
    <div class="text-center">
      <h4 class="text-primary sub-heading">MODERNIZE YOUR HEALTHCARE EXPERIENCE</h4>
      <h1>Benefits of using WellWink App</h1>
      <br>
    </div>
    <div class="sec-pad pb0 hidden-xs"></div>
    <div class="row">
      <div class="col-sm-3 col-xs-12">
        <div class="border-box">
          <span class="icon-wrapper bg-primary">
            <svg class="fa" viewBox="0 0 512 512">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path d="M3075 4936 c-84 -21 -147 -57 -210 -121 -102 -101 -135 -197 -135 -390 l0 -115 -973 0 c-937 0 -977 -1 -1051 -20 -113 -29 -190 -73 -276 -160 -87 -86 -131 -163 -160 -276 -19 -75 -20 -115 -20 -1294 0 -1171 1 -1220 19 -1293 11 -41 29 -94 41 -116 l20 -41 -105 0 c-128 0 -173 -18 -205 -80 -17 -34 -20 -59 -20 -183 0 -240 44 -362 180 -497 86 -87 163 -131 276 -160 75 -20 117 -20 1779 -20 1662 0 1704 0 1779 20 113 29 190 73 276 160 136 135 180 257 180 497 0 124 -3 149 -20 183 -31 62 -77 80 -200 80 l-100 0 20 41 c12 22 30 75 41 116 18 73 19 120 19 1118 l0 1042 23 10 c74 34 170 160 200 264 l12 40 145 -92 c268 -169 321 -199 357 -199 52 0 110 34 133 80 19 38 20 58 20 630 0 572 -1 592 -20 630 -13 26 -34 47 -62 61 -73 37 -97 28 -348 -130 l-224 -141 -19 56 c-50 153 -179 270 -335 303 -78 16 -970 14 -1037 -3z m1015 -306 c26 -13 47 -34 60 -60 19 -37 20 -58 20 -410 0 -352 -1 -373 -20 -410 -13 -26 -34 -47 -60 -60 -38 -19 -58 -20 -491 -20 -449 0 -451 0 -491 22 -77 44 -78 47 -78 468 0 352 1 373 20 410 12 24 35 47 58 59 35 19 59 20 490 21 435 0 454 -1 492 -20z m730 -469 l0 -290 -175 111 -175 110 0 68 0 67 173 111 c94 60 173 111 175 111 1 1 2 -129 2 -288z m-2090 -266 c0 -193 33 -289 135 -390 65 -65 137 -105 223 -124 34 -7 191 -11 448 -11 l394 0 -2 -1017 c-3 -1011 -3 -1018 -24 -1064 -31 -66 -78 -114 -142 -146 l-57 -28 -1465 0 -1465 0 -46 21 c-66 31 -114 78 -146 142 l-28 57 -3 1195 c-2 843 0 1210 8 1246 22 101 93 181 193 215 49 17 117 18 1015 19 l962 0 0 -115z m1437 -3146 c-10 -113 -74 -206 -176 -253 l-46 -21 -1710 0 -1710 0 -46 21 c-102 47 -166 140 -176 253 l-6 61 1938 0 1938 0 -6 -61z"/>
                <path d="M1545 3576 c-119 -28 -224 -87 -313 -177 -212 -213 -240 -545 -69 -796 l35 -52 -77 -78 c-83 -83 -131 -157 -175 -267 -45 -112 -56 -196 -56 -427 0 -191 2 -213 20 -249 13 -26 34 -47 60 -60 38 -19 57 -20 710 -20 653 0 672 1 710 20 26 13 47 34 60 60 18 36 20 58 20 249 0 231 -11 315 -56 427 -43 108 -92 183 -175 267 l-76 79 35 51 c50 75 79 144 98 238 62 306 -116 611 -418 716 -90 31 -242 40 -333 19z m267 -313 c65 -30 133 -95 165 -162 24 -48 28 -68 28 -141 -1 -65 -6 -96 -22 -132 -30 -66 -96 -133 -162 -165 -48 -24 -68 -28 -141 -28 -74 0 -92 4 -143 29 -68 35 -130 98 -160 164 -30 66 -31 198 -1 263 40 85 119 156 209 186 56 18 169 11 227 -14z m-257 -918 c91 -19 151 -19 256 1 l86 17 58 -38 c94 -63 169 -172 200 -292 9 -34 15 -102 15 -170 l0 -113 -490 0 -490 0 0 113 c0 157 32 265 109 366 30 40 149 131 171 131 8 0 47 -7 85 -15z"/>
                <path d="M2888 3009 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 36 -19 58 -20 310 -20 252 0 274 1 310 20 45 23 80 80 80 130 0 50 -35 107 -80 130 -37 19 -58 20 -312 20 -252 -1 -276 -2 -310 -21z"/>
                <path d="M2888 2369 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 36 -19 58 -20 310 -20 252 0 274 1 310 20 45 23 80 80 80 130 0 50 -35 107 -80 130 -37 19 -58 20 -312 20 -252 -1 -276 -2 -310 -21z"/>
                <path d="M2888 1729 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 36 -19 58 -20 310 -20 252 0 274 1 310 20 45 23 80 80 80 130 0 50 -35 107 -80 130 -37 19 -58 20 -312 20 -252 -1 -276 -2 -310 -21z"/>
              </g>
            </svg>
          </span>
          <h4 class="mt-0">Multi-channel communication</h4>
          <p class="m0">Connect with your doctor, office staff and care coordinator using secure text messaging, voice
            communications, email, video and other communication capabilities.
          </p>
        </div>
        <div class="border-box">
          <span class="icon-wrapper bg-primary">
            <svg class="fa" viewBox="0 0 512 512">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path d="M589 5102 c-267 -70 -418 -368 -316 -621 l24 -61 -47 -30 c-97 -62 -178 -168 -221 -286 -19 -50 -23 -87 -27 -250 -4 -179 -3 -193 17 -232 14 -28 35 -49 61 -62 38 -19 58 -20 630 -20 572 0 592 1 630 20 26 13 47 34 61 62 20 39 21 53 17 232 -4 163 -8 200 -27 250 -43 118 -124 224 -221 286 l-47 30 24 61 c88 217 -15 484 -227 587 -110 54 -213 64 -331 34z m195 -302 c74 -36 112 -126 87 -203 -16 -46 -80 -104 -125 -113 -61 -11 -106 4 -152 50 -67 67 -72 154 -11 223 56 64 127 79 201 43z m171 -637 c102 -34 165 -134 165 -260 l0 -63 -410 0 -410 0 0 63 c0 86 24 152 75 202 65 66 99 73 331 74 162 1 210 -2 249 -16z"/>
                <path d="M4289 5102 c-267 -70 -418 -368 -316 -621 l24 -61 -47 -30 c-97 -62 -178 -168 -221 -286 -19 -50 -23 -87 -27 -250 -4 -179 -3 -193 17 -232 14 -28 35 -49 61 -62 38 -19 58 -20 630 -20 572 0 592 1 630 20 26 13 47 34 61 62 20 39 21 53 17 232 -4 163 -8 200 -27 250 -43 118 -124 224 -221 286 l-47 30 24 61 c88 217 -15 484 -227 587 -110 54 -213 64 -331 34z m195 -302 c74 -36 112 -126 87 -203 -16 -46 -80 -104 -125 -113 -61 -11 -106 4 -152 50 -67 67 -72 154 -11 223 56 64 127 79 201 43z m171 -637 c102 -34 165 -134 165 -260 l0 -63 -410 0 -410 0 0 63 c0 86 24 152 75 202 65 66 99 73 331 74 162 1 210 -2 249 -16z"/>
                <path d="M2420 3744 c-322 -40 -591 -171 -815 -398 -199 -201 -313 -415 -372 -696 -24 -118 -24 -382 0 -500 59 -281 174 -496 372 -696 201 -203 421 -322 705 -381 117 -24 380 -24 502 0 270 54 494 174 694 372 202 199 322 421 381 705 24 118 24 382 0 500 -39 185 -110 356 -212 510 -63 95 -258 291 -351 352 -161 107 -335 180 -506 213 -81 16 -328 28 -398 19z m180 -311 c45 -32 80 -79 124 -168 83 -167 134 -375 154 -617 l8 -98 -327 0 -327 0 4 38 c35 353 85 550 180 717 37 66 66 101 104 128 31 21 49 21 80 0z m-549 -219 c-50 -148 -87 -331 -102 -494 -6 -69 -13 -135 -15 -147 -4 -23 -5 -23 -209 -23 l-204 0 5 33 c42 238 184 480 367 625 61 49 189 132 195 127 1 -1 -16 -56 -37 -121z m1114 42 c136 -91 288 -276 354 -433 30 -70 65 -184 75 -240 l6 -33 -205 0 c-204 0 -205 0 -209 23 -2 12 -9 78 -15 147 -15 163 -52 346 -101 494 -22 65 -40 120 -40 123 0 6 54 -27 135 -81z m-1231 -1028 c2 -13 9 -79 15 -148 15 -163 52 -346 101 -494 22 -65 40 -120 40 -122 0 -8 -110 62 -178 113 -195 149 -342 393 -386 641 l-5 32 204 0 c204 0 205 0 209 -22z m944 -75 c-26 -326 -126 -636 -244 -756 -61 -62 -96 -59 -165 16 -32 35 -109 185 -133 260 -46 139 -81 319 -94 480 l-8 97 326 0 326 0 -8 -97z m716 65 c-47 -274 -226 -543 -465 -698 -51 -33 -94 -58 -96 -56 -2 2 15 57 36 122 50 148 87 331 102 494 6 69 13 135 15 148 4 22 5 22 209 22 l205 0 -6 -32z"/>
                <path d="M589 1562 c-267 -70 -418 -368 -316 -621 l24 -61 -47 -30 c-97 -62 -178 -168 -221 -286 -19 -50 -23 -87 -27 -250 -4 -179 -3 -193 17 -232 14 -28 35 -49 61 -62 38 -19 58 -20 630 -20 572 0 592 1 630 20 26 13 47 34 61 62 20 39 21 53 17 232 -4 163 -8 200 -27 250 -43 118 -124 224 -221 286 l-47 30 24 61 c88 217 -15 484 -227 587 -110 54 -213 64 -331 34z m195 -302 c74 -36 112 -126 87 -203 -16 -46 -80 -104 -125 -113 -61 -11 -106 4 -152 50 -67 67 -72 154 -11 223 56 64 127 79 201 43z m171 -637 c102 -34 165 -134 165 -260 l0 -63 -410 0 -410 0 0 63 c0 86 24 152 75 202 65 66 99 73 331 74 162 1 210 -2 249 -16z"/>
                <path d="M4289 1562 c-267 -70 -418 -368 -316 -621 l24 -61 -47 -30 c-97 -62 -178 -168 -221 -286 -19 -50 -23 -87 -27 -250 -4 -179 -3 -193 17 -232 14 -28 35 -49 61 -62 38 -19 58 -20 630 -20 572 0 592 1 630 20 26 13 47 34 61 62 20 39 21 53 17 232 -4 163 -8 200 -27 250 -43 118 -124 224 -221 286 l-47 30 24 61 c88 217 -15 484 -227 587 -110 54 -213 64 -331 34z m195 -302 c74 -36 112 -126 87 -203 -16 -46 -80 -104 -125 -113 -61 -11 -106 4 -152 50 -67 67 -72 154 -11 223 56 64 127 79 201 43z m171 -637 c102 -34 165 -134 165 -260 l0 -63 -410 0 -410 0 0 63 c0 86 24 152 75 202 65 66 99 73 331 74 162 1 210 -2 249 -16z"/>
              </g>
            </svg>
          </span>
          <h4 class="mt-0">Eliminate emotional gaps</h4>
          <p class="m0">Allows you to get closer with your caregivers well to share your fears and other stress.</p>
        </div>
      </div>
      <div class="col-sm-3 col-xs-12">
        <div class="border-box">
          <span class="icon-wrapper bg-primary">
            <svg class="fa" viewBox="0 0 512 512">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path d="M2360 5102 c-150 -39 -263 -143 -322 -294 -16 -40 -22 -87 -27 -192 l-6 -138 -75 -28 c-445 -163 -805 -533 -956 -987 -77 -230 -76 -218 -84 -993 -7 -773 -4 -733 -79 -885 -52 -105 -122 -189 -214 -258 -41 -31 -87 -71 -102 -89 -44 -51 -77 -134 -83 -203 -11 -146 74 -288 210 -351 l63 -29 510 -5 c500 -5 510 -5 513 -25 7 -49 58 -176 95 -240 53 -90 182 -219 272 -271 411 -241 916 -84 1119 347 20 41 42 99 49 129 l14 55 509 5 c405 4 516 8 544 19 92 37 165 106 207 196 24 51 28 72 28 145 -1 137 -48 218 -186 321 -91 67 -159 152 -214 264 -67 137 -75 195 -75 530 l0 285 308 0 c370 0 409 7 518 96 81 65 144 198 144 303 0 107 -16 139 -513 1020 -418 742 -490 863 -539 914 -160 163 -418 168 -586 11 -38 -36 -76 -91 -141 -204 -49 -85 -93 -154 -97 -153 -5 1 -47 18 -93 37 -46 19 -92 37 -102 39 -17 5 -19 16 -19 110 0 132 -12 195 -52 277 -96 198 -321 299 -538 242z m200 -305 c63 -33 90 -88 90 -181 l0 -71 -170 0 -170 0 0 67 c0 78 11 112 48 152 56 59 130 71 202 33z m1189 -245 c29 -16 95 -126 508 -858 312 -550 477 -852 480 -876 5 -33 1 -42 -30 -73 l-35 -35 -982 0 -982 0 -35 35 c-31 31 -35 40 -30 73 3 24 168 326 480 876 506 896 493 876 567 876 14 0 40 -8 59 -18z m-1080 -318 c106 -15 301 -72 334 -97 17 -11 -8 -59 -304 -582 -182 -321 -330 -595 -341 -628 -65 -210 55 -431 270 -497 60 -19 95 -20 603 -20 l539 0 0 -284 c0 -157 5 -320 11 -362 36 -264 192 -526 404 -679 71 -51 81 -79 45 -115 -20 -20 -33 -20 -1750 -20 -1717 0 -1730 0 -1750 20 -39 39 -28 62 62 129 152 114 286 304 346 491 45 138 45 132 52 855 8 758 6 735 66 920 35 106 121 273 186 360 119 158 275 290 448 379 147 75 269 112 460 140 66 9 223 4 319 -10z m266 -3621 c-64 -154 -183 -257 -342 -298 -233 -61 -484 75 -573 311 l-10 24 470 0 470 0 -15 -37z"/>
                <path d="M3618 4129 c-23 -12 -46 -35 -58 -59 -18 -36 -20 -59 -20 -270 0 -212 2 -234 20 -270 23 -45 80 -80 130 -80 50 0 107 35 130 80 18 36 20 58 20 270 0 212 -2 234 -20 270 -37 73 -127 99 -202 59z"/>
                <path d="M3618 3249 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 21 -11 53 -20 70 -20 50 0 107 35 130 80 11 21 20 53 20 70 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z"/>
                <path d="M865 5015 c-429 -79 -753 -439 -782 -870 -5 -80 -3 -95 16 -133 14 -28 34 -48 61 -62 108 -55 220 20 220 147 0 80 27 183 71 273 59 119 170 230 289 289 90 44 193 71 273 71 66 0 122 30 147 80 25 50 25 90 0 140 -39 76 -128 96 -295 65z"/>
                <path d="M937 4459 c-152 -36 -279 -182 -293 -340 -6 -54 -3 -72 15 -107 24 -47 80 -82 131 -82 76 0 150 74 150 150 0 43 47 90 90 90 76 0 150 74 150 150 0 17 -9 49 -20 70 -35 69 -117 94 -223 69z"/>
              </g>
            </svg>
          </span>
          <h4 class="mt-0">Improved access to care</h4>
          <p class="m0">Get timely reminders, access to health records, guides and resources.</p>
        </div>
        <div class="border-box">
          <span class="icon-wrapper bg-primary">
            <svg class="fa" viewBox="0 0 512 512">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path d="M755 4700 c-194 -41 -352 -179 -417 -368 l-23 -67 0 -1310 0 -1310 26 -67 27 -68 -125 0 c-149 0 -190 -15 -224 -81 -20 -40 -20 -53 -17 -313 4 -248 6 -276 26 -330 62 -168 186 -291 350 -348 l67 -23 2115 0 2115 0 67 23 c164 57 288 180 350 348 20 54 22 82 26 330 3 260 3 273 -17 313 -34 66 -75 81 -224 81 l-125 0 27 67 26 68 0 1310 0 1310 -23 67 c-57 163 -178 286 -348 350 l-59 23 -1790 1 c-984 1 -1808 -2 -1830 -6z m3590 -307 c29 -9 66 -33 90 -58 80 -80 75 15 75 -1375 0 -1390 5 -1295 -75 -1375 -81 -81 75 -75 -1875 -75 -1950 0 -1794 -6 -1875 75 -80 80 -75 -15 -75 1375 0 1390 -5 1295 75 1375 79 79 -76 73 1871 74 1595 1 1744 0 1789 -16z m475 -3326 c0 -172 -13 -220 -75 -282 -81 -82 112 -75 -2185 -75 -2297 0 -2104 -7 -2185 75 -62 62 -75 110 -75 282 l0 143 2260 0 2260 0 0 -143z"/>
                <path d="M2443 4096 c-88 -22 -164 -66 -234 -135 -172 -172 -197 -422 -62 -635 18 -28 18 -28 -7 -44 -54 -35 -165 -150 -205 -212 -86 -133 -114 -225 -122 -405 -5 -120 -4 -134 16 -173 14 -28 34 -48 61 -62 38 -19 58 -20 670 -20 612 0 632 1 670 20 27 14 47 34 61 62 20 39 21 53 16 173 -8 175 -38 277 -121 404 -41 63 -152 178 -206 213 -25 16 -25 16 -7 44 134 212 110 463 -62 635 -126 126 -299 176 -468 135z m227 -320 c59 -39 85 -89 85 -166 0 -78 -26 -127 -88 -168 -56 -37 -153 -39 -210 -3 -76 47 -111 140 -88 229 14 51 75 117 123 131 53 16 135 6 178 -23z m-18 -677 c167 -35 306 -171 344 -336 l12 -53 -448 0 -448 0 12 53 c19 83 56 147 121 212 111 111 257 155 407 124z"/>
                <path d="M1488 2089 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 39 -20 57 -20 1070 -20 1013 0 1031 0 1070 20 45 23 80 80 80 130 0 50 -35 107 -80 130 -39 20 -56 20 -1072 20 -1009 -1 -1034 -1 -1070 -21z"/>
              </g>
            </svg>
          </span>
          <h4 class="mt-0">Health compliance</h4>
          <p class="m0">Comply with health guidelines by getting access to personalized content, health tools, tracking and reporting.</p>
        </div>
      </div>
      <div class="col-sm-1 hidden-xs"></div>
      <div class="col-sm-5 col-xs-12">
        <h3 class="mb-xl">Why you should use mobile app?</h3>
        <p class="fs16">
          <i class="fa fa-quote-left fs30 text-secondary"></i>
          Gordon et al. found that smartphone-based communication between healthcare providers and their patients
          greatly improves satisfaction with the perioperative experience. In fact, all types of users, including
          healthcare professionals, recorded high levels of satisfaction when a mobile communication platform was implemented.
          <br><br>
          Another study found that health apps improve the overall patient experience with better communication,
          access to health information, short-term outcomes, and general satisfaction.
          <i class="fa fa-quote-right fs30 text-secondary"></i>
        </p>
        <br><br>
        <div class="row">
          <div class="col-sm-4 col-xs-6">
            <a href="https://play.google.com/store/apps/details?id=com.app.wellwink&hl=en&gl=US&pli=1" class="btn p0" target="_blank">
              <img class="img-responsive" src="/assets/img/public/android-btn-img.webp"/>
            </a>
          </div>
          <div class="col-sm-4 col-xs-6">
            <a href="https://apps.apple.com/us/app/wellwink/id1623810665" class="btn p0" target="_blank">
              <img class="img-responsive" src="/assets/img/public/applestore-btn-img.webp"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-white sec-pad features-sec">
  <div class="container">
    <div class="text-center">
      <h4 class="text-primary sub-heading">DESIGNED FOR ELDERLY, USEFUL FOR ALL</h4>
      <h1>Our Features</h1>
      <div class="sec-pad pb0"></div>
    </div>
    <div class="row flex-row">
      <div class="col-sm-6 col-xs-12">
        <img class="img-responsive infographic" src="/assets/img/public/mobileApp-infographic.webp"/>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-5 col-xs-12 phones-col">
        <img class="img-responsive infographic" src="/assets/img/public/mobileApp-phones.webp"/>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad bg-primary mobileApp-screen">
  <div class="container">
    <div class="row flex-row">
      <div class="col-sm-2"></div>
      <div class="col-sm-3 col-xs-12">
        <img class="img-responsive app-img" src="/assets/img/public/mobileApp-page-img2.webp"/>
      </div>
      <div class="col-sm-5 col-xs-12">
        <h2 class="text-white">Download the WellWink app</h2>
        <p class="fs16">Search for doctors, specialities, treatments, hospitals and services. Find doctor reviews and book appointment
          online, all using the WellWink app.
        </p>
        <br><br>
        <div class="row">
          <div class="col-sm-4 col-xs-6">
            <a href="https://play.google.com/store/apps/details?id=com.app.wellwink&hl=en&gl=US&pli=1" class="btn p0" target="_blank">
              <img class="img-responsive" src="/assets/img/public/android-btn-img.webp"/>
            </a>
          </div>
          <div class="col-sm-4 col-xs-6">
            <a href="https://apps.apple.com/us/app/wellwink/id1623810665" class="btn p0" target="_blank">
              <img class="img-responsive" src="/assets/img/public/applestore-btn-img.webp"/>
            </a>
          </div>
        </div>
      </div>
      <div class="col-sm-2"></div>
    </div>

  </div>
</section>
