import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {Case} from '../routes/practice-site/case';
import {Observable, Subject} from 'rxjs';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class CaseService {
  static errorMessage: string;
  reloadCases: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient) { }

  save(admission: Case): Promise<any> {
    if (admission.is_new_record()) {
      return this.create(admission);
    }
    else {
      return this.update(admission);
    }
  }
  create(admission: Case): Promise<any> {
    return this._tokenService
      .post(admission.get_url(), admission.to_params())
      .toPromise()
      .then(response => response)
      .catch(this.handleError);

  }
  update(admission: Case): Promise<any> {
    return this._tokenService
      .put(admission.get_url(), admission.to_params())
      .toPromise()
      .then(response => response)
      .catch(this.handleError);

  }
  getCases(filter: any): Promise<any> {
    let params = new HttpParams();
    params = params.set('filter', filter);
    return this._tokenService.getWithHeaders('cases', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          cases: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      });
  }
  getCase(id: any): Promise<any> {
    return this._tokenService.get('cases/' + id)
      .toPromise()
      .then(response => response);
  }
  getPracticePatients(token: string, practice_id?: any): Observable<any> {
    let args = new HttpParams();
    args = args.set('token', token);
    args = args.set('practice_id', practice_id);
    return this._tokenService.get('cases/patients', {params: args}).share()
      .map(r => r);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
