import { Injectable } from '@angular/core';
import {Conversation} from './conversation';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {CustomHttpClient} from '../../../services/custom-http-client.service';
import {LoaderService} from '../../../services/loader.service';
import {SMSMessage} from './sms';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  conversations_count = {};
  conversations: Array<Conversation> = [];
  selected_conversation: Conversation;
  public scrollToBottom: Subject<Conversation> = new ReplaySubject(1);
  patient_id: any;
  showConversations = false;
  unReadConversationCount: any;

  constructor(
    private _tokenService: CustomHttpClient,
    private loaderService: LoaderService,
  ) { }

  getPracticePatient(token): Observable<any> {
    return this._tokenService
      .get(
        'sms_messages/search?token=' + token)
      .share()
      .map(r => r);
  }

  createConversation(conversation: Conversation) {
    return this._tokenService
      .post('sms_messages/conversation', conversation.to_params())
      .map(res => res);
  }

  create(message: any) {
    return this._tokenService.post('sms_messages', message).map(res => res);
  }

  userConversationsList() {
    this.loaderService.show();
    return this._tokenService
      .get('sms_messages/conversations')
      .toPromise()
      .then(response => this.set_conversations(response));
  }

  getPatientConversation(patient_id: any) {
    let params: HttpParams = new HttpParams();
    params = params.set('patient_id', patient_id);
    return this._tokenService.get('sms_messages/patient_conversation', {params: params})
      .toPromise()
      .then(response => response);
  }


  handleSMSReply(response) {
    console.log(response);
    this.getUnreadSmsConversationCount();
    const smsMessage = new SMSMessage().load_from_json(response);
    let conversationToUpdate = null;
    if (this.selected_conversation && this.selected_conversation.id === response.conversation_id) {
      conversationToUpdate = this.selected_conversation;
    } else {
      const conversation = this.find_by_conversation_id(response.conversation_id);
      if (conversation) {
        conversationToUpdate = conversation;
      }
    }

    if (conversationToUpdate) {
      console.log("conversationToUpdate=====" , conversationToUpdate)
      conversationToUpdate.push_message(smsMessage);
      this.incrementConversationCount(conversationToUpdate.id)
      this.smsChanged();
      return conversationToUpdate;
    }


    return null;
  }



  set_conversations(conversations) {
    this.conversations = [];
    if (conversations && conversations.length > 0) {
      this.conversations = conversations.map(conversation =>
        new Conversation().load_from_json(conversation)
      );
      this.changeSelectedConversation(this.conversations[0]);
      this.initials_unread_sms_count();
    }
    this.showConversations = true;
    this.loaderService.hide();
    return this.conversations;
  }
  initials_unread_sms_count() {
    this.conversations.forEach(chatroom => {
      this.conversations_count[chatroom.id] = chatroom.unread_count;
    });
  }

  set_user_on_selection(user) {
    this.patient_id = user.id
    if (this.already_added_chatroom(user.id)) {
      this.populate_added_chatroom_messages(user.id);
    } else {
      this.changeSelectedConversation(this.createTempConversations(user.patient));
      return
    }

  }

  refresh_chatroom_list() {
    let new_conversation = new Conversation();
    this.conversations.unshift(new_conversation);
    return new_conversation;
  }

  already_added_chatroom(id) {
    const chatroom = this.conversations_list().filter(
      e => e.patient_id == id
    )[0];
    this.changeSelectedConversation(chatroom);
    if (this.selected_conversation && this.selected_conversation.id) {
      return true;
    } else {
      return false;
    }
  }

  populate_added_chatroom_messages(id) {
    this.populate_already_added_chatroom(id);
  }

  populate_already_added_chatroom(id) {
    if (this.conversations_list() && this.conversations_list().length > 0) {
      this.changeSelectedConversation(
        this.conversations_list().filter(e => e.patient_id == id)[0]
      );
      let idx = this.conversations.indexOf(this.selected_conversation);
      if (idx !== -1) {
        this.conversations.splice(idx, 1);
        this.conversations.unshift(this.selected_conversation);
      }
      return;
    }
  }

  conversations_list() {
    return this.conversations;
  }

  changeSelectedConversation(conversation: Conversation) {
    this.selected_conversation = conversation;
    if (!conversation) {
      return;
    }
    if (conversation.id && conversation.unread_count > 0) {
      this.restartUnreadCount(this.selected_conversation.id);
    }
  }
  restartUnreadCount(conversation_id, force_minus?) {
    return this._tokenService
      .put('sms_messages/mark_sms_read/' + conversation_id, { id: conversation_id })
      .toPromise()
      .then(res => {
        this.setConversationCount(conversation_id, 0);
      })
  }

  setConversationCount(id, count, skipPrevious?) {
    const chtrm = this.find_by_conversation_id(id);
    if (chtrm) {
      chtrm.last_unread_count = chtrm.skip_previous
        ? count
        : chtrm.unread_count || count;
      if (skipPrevious) {
        chtrm.skip_previous = skipPrevious;
      } else {
        chtrm.skip_previous = false;
      }
      chtrm.unread_count = count;
      this.conversations_count[id] = count;
      return
    }
  }

  countUnreadConversation(): number {
    let count = 0;
    for (const key in this.conversations_count) {
      if (this.conversations_count.hasOwnProperty(key) && this.conversations_count[key] > 0) {
        count++;
      }
    }
    return count;
  }

  find_by_conversation_id(cid) {
    return this.conversations.filter(c => c.id == cid)[0];
  }

  load_sms(conversation: Conversation) {
    if (conversation) {
      this.changeSelectedConversation(conversation);
      if (conversation.physical_exist()) {
        this.query(conversation.id, conversation.last_sms_id()).subscribe(
          response => {
            conversation.loadNewMessages(response.messages, true);
            this.scrollToBottom.next(conversation);
          }
        );
      } else {
        this.selected_conversation.sms_messages = [];
      }
    }
  }

  query(conversation_id: any, last_id: any, page_number?: number) {
    return this._tokenService

      .get(
        `sms_messages?conversation_id=${conversation_id}&last_mid=${last_id ||
        ''}&page=${page_number || ''}`
      )
      .map(res => res);
  }

  // @ts-ignore
  get getScrollToBottom() {
    return this.scrollToBottom.asObservable();
  }

  resetScrollToBottom() {
    this.scrollToBottom = new ReplaySubject(1);
  }

  load_prevMessage(conversation: Conversation) {
    conversation.load_in_progress = true;
    this.query(conversation.id, null, conversation.page_loaded + 1).subscribe(
      response => {
        this.selected_conversation.loadPaginatedMessages(
          response.messages,
          response.headers
        );
      }
    );
  }

  smsChanged(conversation?) {
    console.log('smsChanged')
    this.conversations = this.conversations.sort((ch1, ch2) => {
      if (!ch1.last_sms && ch2.last_sms) {
        return 1;
      }
      if (ch1.last_sms && !ch2.last_sms) {
        return -1;
      }
      if (!ch1.last_sms && !ch2.last_sms) {
        return 0;
      }
      return ch1.last_sms.created_at > ch2.last_sms.created_at ? -1 : 1;
    });
  }

  createTempConversations(patient: any) {
    this.patient_id = patient.id;
    const conversation = this.refresh_chatroom_list();
    conversation.patient = patient;
    conversation.title = patient.name;
    conversation.thumbnail = patient.profile_pic;
    return conversation;

  }

  incrementConversationCount(id, skip_previous?) {
    const prev_count = this.conversations_count[id] || 0;
    this.setConversationCount(id, prev_count + 1, skip_previous);
  }


  getUnreadSmsConversationCount() {
    this._tokenService
      .get('sms_messages/unread_conversations')
      .toPromise()
      .then(response => {
        console.log(response, 'unread_conversations')
          this.unReadConversationCount = response;
        }
      );
  }

  unreadSMSCounter() {
    console.log("unreadSMSCounter")
  }
}
