import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {PatientDocument, DocumentPage} from '../health/patient-profile/patient-dashboard/chart/document';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs/Subject';
import {HttpParams } from '@angular/common/http';
import {nullRemover} from './nullRemover';

@Injectable()
export class DocumentsService {

  public  selected_page_broadcaster: Subject<number> =  new Subject();
  documents: PatientDocument[] = [];
  pages: DocumentPage[] = [];
  _selected_page = 0;
  is_fullscreen =  false;
  is_pchf =  false;
  constructor(public _tokenService: CustomHttpClient) {
    console.log('Document Service Created');
  }


  toggleView() {
    this.is_fullscreen = !this.is_fullscreen;
  }

  is_pchf_viewer(is_active) {
    this.is_pchf = is_active;
  }


  goto_document(page_number) {
    this.selected_page =  page_number + 1;
    this.selected_page_broadcaster.next(this.selected_page);
    this.toggleView();
  }

  set selected_page(value) {
    if (value <= 0) {
      value = 1;
    }
    if (value > this.total_pages) {
      value = this.total_pages;
    }
    this._selected_page = value;
    this.loadPage(value);
  }

  get selected_page() {
    return this._selected_page;
  }

  document_name() {
    return this.getSelectedPage() && this.getSelectedPage().parent_name;
  }

  loadPage(index) {
    if (this.pages.length == 0) {
      return;
    }
    this.pages[index - 1].loaded = true;
    if (index > 1 ) {
      this.pages[index - 2].loaded = true;
    }
    if (index < this.total_pages) {
      this.pages[index].loaded = true;
    }
  }

  getSelectedPage(): DocumentPage {
    return this.pages[this.selected_page - 1];
  }

  get total_pages() {
    return this.pages.length;
  }



  getDocumentsType(): Promise<any[]> {
    return this._tokenService.get('documents/documents_type')
      .toPromise()
      .then(response => response);
  }
  getDocuments(patient_id: number, page: any) {
    return this._tokenService.getWithHeaders('documents?patient_id=' + patient_id + '&page=' + page, {observe: 'response', responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          documents: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      });
  }
  getDocumentServices(patient_id?: number): Observable<any[]> {
    return this._tokenService.get('documents/document_services?patient_id=' + patient_id)
      .map(response => response);
  }
  save(document: PatientDocument): Promise<any> {
    return this._tokenService.post('documents', document.to_params())
      .toPromise()
      .then(response => response);
  }
  delete(document_id: any): Promise<any> {
    return this._tokenService.delete('documents?document_id=' + document_id)
      .toPromise()
      .then(res => res);
  }

  filter_documents(filter: any) {
    let args = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('documents/filter_documents', {observe: 'response', params: args, responseType: 'json'})
      .toPromise()
      .then(response => {
        this.documents = response.body.map(doc => new PatientDocument().load_from_json(doc));
        this.copy_pages();
        return {
          documents: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      });
  }

  private copy_pages() {
    this.pages = [];
    this.documents.forEach(document => {
      document.pages.forEach(page => {
        this.pages.push(page);
      })
    });
    console.log(this.pages.map(page => page.url));
    this.selected_page = 1;
  }

}
