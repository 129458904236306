<div class="app-chat-listing">
  <ng-container *ngIf="patient?.can_login">
    <div class="chat-flex" *ngIf="messageService.selected_chatroom">
      <a class="visible-xs msg-chat-btn btn btn-inverse" (click)="toggleChat()">ssssss<i class="fa icon-bubbles"></i></a>
      <app-chat-detail class="body-panel p-relative" [chatroom]="messageService.selected_chatroom"></app-chat-detail>
    </div>
  </ng-container>
  <ng-container *ngIf="!patient?.can_login">
    <div class="main-wrapper">
      <div class="alert alert-info text-center fw-500" style="max-width:500px; margin:5% auto 0; line-height:normal;">{{show_im}}</div>
    </div>
  </ng-container>
</div>
