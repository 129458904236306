<h4 class="m0 mb">Customize Vital Ranges</h4>
<ul class="list-unstyled border-list m0 alerts-list" *ngIf="showVitalsRanges">
  <ng-container *ngFor="let vital of vitals; let i = index;">
    <ng-container *ngIf="!hideVitals.includes(vital.short)">
      <li class="list">
        <div class="row">
          <div class="col-xs-4">
            <div class="fw-700">{{vitalService.vital_mapper_with_unit[vital?.short]}}</div>
          </div>
          <div class="col-xs-2">
            <label class="field-label control-label">{{vital?.descriptors}}</label>
          </div>
          <div class="col-xs-2">
            <input type="number" class="form-control" [ngClass]="vital.is_customized ? 'text-secondary' : ''" placeholder="Min Value" id = "{{i + 'vital_min_value'}}"
                   [(ngModel)]="vital.min_val" name="{{i + 'vital_min_value'}} ">
          </div>
          <div class="col-xs-2">
            <input type="number" class="form-control" [ngClass]="vital.is_customized ? 'text-secondary' : ''" placeholder="Max Value" id = "{{i + 'vital_max_value'}}"
                   [(ngModel)]="vital.max_val" name="{{i + 'vital_max_value'}} " >
          </div>
          <div class="col-xs-2">
            <button type="submit" class="btn btn-secondary btn-outline btn-min" id = "{{i + 'save_button'}}" (click)="saveVitalForPatient(vital)" >{{vital.is_customized ? 'Update' : 'Save'}}</button>
          </div>
        </div>
      </li>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let short of shortVital;">
    <li class="list">
      <div class="row" *ngIf="newVitalArray">
        <div class="col-xs-4">
          <div class="fw-700">{{vitalService.vital_mapper_with_unit[newVitalArray[short][0]?.short]}}</div>
        </div>
        <div class="col-xs-8">
          <div class="row mb-sm" *ngFor="let vital of newVitalArray[short];  let i = index;">
<!--            <div class="col-xs-4" *ngIf="i !== 0"></div>-->
            <div class="col-xs-3">
              <label class="field-label control-label">{{vital?.descriptors}}</label>
            </div>
            <div class="col-xs-3">
              <input type="number" class="form-control" [ngClass]="vital.is_customized ? 'text-secondary' : ''" placeholder="Min Value" id = "{{i + 'vital_a_min_value'}}"
                     [(ngModel)]="vital.min_val" name="{{i + 'vital_min_value'}} ">
            </div>
            <div class="col-xs-3">
              <input type="number" class="form-control" [ngClass]="vital.is_customized ? 'text-secondary' : ''" placeholder="Max Value" id = "{{i + 'vital_a_max_value'}}"
                     [(ngModel)]="vital.max_val" name="{{i + 'vital_max_value'}} " >
            </div>
            <div class="col-xs-3">
              <button type="submit" class="btn btn-secondary btn-outline btn-min" id = "{{i + 'save_a_button'}}" (click)="saveVitalForPatient(vital)" >{{vital.is_customized ? 'Update' : 'Save'}}</button>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
