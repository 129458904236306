<div class="flex-control flex-column flex-height">
  <div class="flex-header">
    <h4 class="fs20 m0">{{alertTemplate.id ? 'Edit' : 'Add'}} Alert</h4>
  </div>
  <form (ngSubmit)="save()" #alertTemplateForm="ngForm" class="flex-body p scroll-browser">

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Type*</label>
      </div>
      <div class="col-md-4 col-sm-5">
        <select class="form-control text-capitalize" [(ngModel)]="alertTemplate.alert_type" name="type" required>
          <option value=undefined selected>Type</option>
          <option *ngFor="let type of alertTemplateService.alert_types; let i = index;" [value]="type">
            {{userService.removeUnderScore(type)}}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Subject*</label>
      </div>
      <div class="col-md-4 col-sm-5">
        <input type="text" class="form-control" placeholder="Subject of Alert"
               [(ngModel)]="alertTemplate.subject" name="subject" required>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Alert Text/Colour*</label>
      </div>
      <div class="col-md-4 col-sm-5">
        <input type="text" class="form-control" placeholder="Alert Text"
               [(ngModel)]="alertTemplate.description" name="description" required>
      </div>
      <div class="col-md-1 col-sm-1">
        <input class="form-control" autocomplete="off" [cpPosition]="'auto'"
               [cpOutputFormat]="'hex'"
               [cpCloseClickOutside]="true"
               [(colorPicker)]="alertTemplate.color_code" name="alert_color"
               [style.background]="alertTemplate.color_code"/>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Severity*</label>
      </div>
      <div class="col-md-4 col-sm-5">
        <select class="form-control text-capitalize" [(ngModel)]="alertTemplate.severity" name="severity" required>
          <option value=undefined selected>Severity</option>
          <option *ngFor="let severity of alertTemplateService.severities; let i = index;" [value]="severity">
            {{severity}}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-group" *ngIf="alertTemplateService.protocols.length > 0">
      <div class="col-md-2 col-sm-3">
        <label>Protocol*</label>
      </div>
      <div class="col-md-4 col-sm-5">
        <select class="form-control text-capitalize" [(ngModel)]="alertTemplate.protocol_id" name="protocol" required>
          <option value=undefined selected>Protocols</option>
          <option *ngFor="let protocol of alertTemplateService.protocols; let i = index;" [value]="protocol.id">
            {{alertTemplateService.mapProtocolsToUpperCase(protocol.name)}}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-md-2 col-sm-3">
        <label>Guideline</label>
      </div>
      <div class="col-md-10 col-sm-5">
        <textarea  #guideline rows="4" type="text" class="form-control"
                   [(ngModel)]="alertTemplate.guideline"
                   name="guideline">
        </textarea>
      </div>
    </div>


    <app-alert-template-rule [alertTemplateRules]="alertTemplate.alert_rule" [alertTemplate]="alertTemplate" (ngSubmit)="validRulesFrom($event)"></app-alert-template-rule>


    <span class="h4">Interventions</span>
    <hr class="hr-line mt">
    <app-alert-template-intervention [alert_template_interventions]="alertTemplate.alert_template_interventions" [isResolveModel]="false"></app-alert-template-intervention>
    <div class="row">
      <div class="col-sm-2"></div>
      <div class="col-sm-10">
        <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
        <button type="submit" class="btn btn-secondary btn-min mr" [disabled]="!(alertTemplateForm.form.valid && isRulesFormValid)" *ngIf="!loaderService.uploading_in_process">{{alertTemplate.id ? 'Update' : 'Create'}}</button>
        <button class="btn btn-tertiary btn-outline btn-min" (click)="closeNewForm()">Cancel</button>
      </div>
    </div>
  </form>
</div>
