<div class="flex-container" style="height:100%;" (click)="videoChatService.showSmallWindow = true">
  <div cdkDrag class="videoCall-popout" [ngClass]="{'mini-popout': videoChatService.showSmallWindow}" *ngIf="videoChatService.showLayoutVideo">
    <app-video-panel #video_panel (click)="$event.stopPropagation()" class="container"></app-video-panel>
  </div>
  <ng-container *ngIf="user_service.current_user?.isPatient">
    <div class="call-receive-modal" [hidden]="videoChatService.hidden" (click)="$event.stopPropagation();">
      <div class="modal-body" *ngIf="videoChatService.caller_id">
        <img class="img-circle" [src]="videoChatService.caller_profile_pic"/>
        <div class="flex-control flex-column">
          <div class="flex-body" style="justify-content:center;">
            <h5 class="text-center text-black">{{videoChatService.caller_full_name}}</h5>
            <div class="calling-text">
              <h5 class="text-center fw-500">calling you</h5>
              <div class="loading-dots">
                <h6 class="dot one">.</h6><h6 class="dot two">.</h6><h6 class="dot three">.</h6>
              </div>
            </div>
          </div>
          <div class="flex-footer text-center pb">
            <button type="submit" class="btn btn-secondary btn-min" (click)="receiveCall()" [disabled]="videoChatService.disable_button">
              <i class="fa icon-call-out"></i>
            </button>
            <button type="submit" class="btn btn-danger btn-min" (click)="declineCall()">
              <i class="fa icon-close"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-md" style="height:401px;">
      <div class="modal-content">
        <div class="modal-header" *ngIf="enrollmentObject">
          <button type="button" class="close" (click)="closeClassicModal(enrollmentObject)"><i class="fa fa-times fs16"></i></button>
          <h4 class="m0 text-center">Remaining Log Time</h4>
        </div>
        <div class="modal-body">
          <ul class="reminder-list list-unstyled border-list m0">
            <li class="list">
              <label class="field-label">Patient Name</label>
              <h4 class="m0">{{enrollmentObject.patient_name}}</h4>
            </li>
            <li class="list">
              <label class="field-label">Total Session time</label>
              <h4 class="m0">{{enrollmentObject.session_time}} min</h4>
            </li>
            <li class="list">
              <label class="field-label">Logged time</label>
              <h4 class="m0">{{enrollmentObject.logged_time}} min</h4>
            </li>
            <li class="list" *ngIf="enrollmentObject.time_for_log !== 0">
              <label class="field-label">Remaining unlogged time</label>
              <h4 class="m0">{{enrollmentObject.adjust_unlogged_time}} min</h4>
              <input type="range" name="unlogged_time" min="0"
                     [attr.max]="getValue"
                     [step]="0.01"
                     [(ngModel)]="enrollmentObject.adjust_unlogged_time"/>
            </li>
            <li class="list" >
              <label class="field-label">Description</label>
              <input type="text" class="form-control" name="subject" [(ngModel)]="description">
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-secondary btn-min mr" (click)="saveRemainingTime(enrollmentObject)">Log</button>
          <button type="submit" class="btn btn-tertiary btn-outline" (click)="closeClassicModal(enrollmentObject)">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <alert></alert>
  <app-phone-call></app-phone-call>
  <router-outlet></router-outlet>
  <app-loader></app-loader>
  <app-error-modal></app-error-modal>
  <app-reloader></app-reloader>
</div>
