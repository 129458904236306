<div class="flex-control" *ngIf="top_data.user">
  <div class="flex-left">
    <div class="pic-holder p-lg">
      <div class="user-profile-pic thumbnail" [ngStyle]="{'background-image': 'url(' + top_data.user.profile_pic + ')'}">
        <div class="loader-pic" *ngIf="loaderService.uploading_in_process">
          <i class="fa fa-spinner fa-spin fs22"></i>
        </div>
        <span class="icon-holder" *ngIf="!loaderService.uploading_in_process" (click)="choose_file()">
          <i class="fa fa-upload fa-2x"></i>
          <h5>Upload Your Picture</h5>
        </span>
      </div>
      <h2 class="user-name text-center text-secondary text-capitalize mt m0">{{top_data.user.name}}</h2>
      <h5 class="fs16 m0 mt-sm text-capitalize text-center fw-300">{{top_data.user.designation}}</h5>
    </div>
    <div *ngIf="!userService.current_user.isEnterprise">
      <h4 class="title text-black m0">
        Patients <a class="fs16" [routerLink]="'/enterprise/patients/search'">{{top_data.patients_data.total || 0}}</a>
      </h4>
      <ul class="border-list list-unstyled">
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-user fs16 mr vl-m"></i> Male</h5>
          <h4 class="m0 fw-300 fs14">{{top_data.patients_data.male || 0}}</h4>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-user-female fs16 mr vl-m"></i> Female</h5>
          <h4 class="m0 fw-300 fs14">{{top_data.patients_data.female || 0}}</h4>
        </li>
        <li class="list hidden">
          <h5 class="m0 fw-400"><i class="fa fa-user-circle-o fs16 mr vl-m"></i> Other</h5>
          <h4 class="m0 fw-300 fs14">{{top_data.patients_data.other || 0}}</h4>
        </li>
      </ul>
    </div>
    <div class="flex-control flex-column" *ngIf="!userService.current_user.isEnterprise">
      <h4 class="title text-black m0">Triage
        <span>{{top_data.appointments_data?.pending + top_data.telemed_appointments?.pending + top_data.phone_appointments?.pending + top_data.telemed_appointments?.todays + top_data.phone_appointments?.todays + top_data.appointments_data?.todays}}</span>
      </h4>
      <ul class="border-list list-unstyled m0 flex-body scroll-browser">
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa fa-building-o  fs16 mr vl-m"></i> Office Visit</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/reception/today'">{{top_data.appointments_data?.todays || 0}}</a>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-camrecorder fs16 mr vl-m"></i> Video Calls</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/reception/telemed'">{{top_data.telemed_appointments?.todays || 0}}</a>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-call-in fs16 mr vl-m"></i> Phone Calls</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/reception/telemed'">{{top_data.phone_appointments?.todays || 0}}</a>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-hourglass fs16 mr vl-m"></i> Requests</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/reception/appointments'">{{top_data.appointments_data?.pending + top_data.telemed_appointments?.pending + top_data.phone_appointments?.pending || 0}}</a>
        </li>
      </ul>
    </div>

    <div class="flex-control flex-column scroll-browser" *ngIf="userService.current_user.isEnterprise">
      <h4 class="title text-black m0">Online
        <span>{{(top_data.online_data.leads + top_data.online_data.requests + top_data.online_data.reviews)|| 0}}</span>
      </h4>
      <ul class="border-list list-unstyled">
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-graph fs16 mr vl-m"></i> Leads</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/online/leads'">{{top_data.online_data.leads || 0}}</a>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-check fs16 mr vl-m"></i> Requests</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/online/appointments'">{{top_data.online_data.requests || 0}}</a>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-star fs16 mr vl-m"></i> Reviews</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/online/reviews'">{{top_data.online_data.reviews || 0}}</a>
        </li>
      </ul>
      <h4 class="title text-black m0">Account
        <span>{{(top_data.accounts_data.practices + top_data.accounts_data.doctors)|| 0}}</span>
      </h4>
      <ul class="border-list list-unstyled">
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-briefcase fs16 mr vl-m"></i> Practices</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/manage/practices'">{{top_data.accounts_data.practices || 0}}</a>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa fa-stethoscope fs16 mr vl-m"></i> Doctors</h5>
          <a class="fw-300 fs14" [routerLink]="'/enterprise/manage/doctors'">{{top_data.accounts_data.doctors || 0}}</a>
        </li>
      </ul>
      <h4 class="title text-black m0">Patients
        <a class="fs16" [routerLink]="'/enterprise/patients/search'">{{(top_data.patients_data.walkin + top_data.patients_data.online)|| 0}}</a>
      </h4>
      <ul class="border-list list-unstyled">
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa icon-briefcase fs16 mr vl-m"></i> Online</h5>
          <h4 class="m0 fw-300 fs14">{{top_data.patients_data.online || 0}}</h4>
        </li>
        <li class="list">
          <h5 class="m0 fw-400"><i class="fa fa-stethoscope fs16 mr vl-m"></i> Walkin</h5>
          <h4 class="m0 fw-300 fs14">{{top_data.patients_data.walkin || 0}}</h4>
        </li>
      </ul>
    </div>
  </div>
  <div class="flex-body p-lg scroll-browser">
    <h4 class="title text-black m0 mb p0">To-Do</h4>
    <div class="row">
      <div class="col-sm-4 col-xs-12 mb-sm">
        <div class="border-box shadow p">
          <h4 class="m0 mb-xl text-center fs16"><i class="fa icon-docs vl-m mr fs18"></i> Documents</h4>
          <div class="row">
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">New</h5>
              <h4 class="m0 fw-300 fs16">{{top_data.todos_data.documents.total}}</h4>
            </div>
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Unsigned</h5>
              <h4 class="m0 fw-300 fs16">0</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-xs-12 mb-sm">
        <div class="border-box shadow p">
          <h4 class="m0 mb-xl text-center fs16">
            <i class="fa icon-notebook vl-m mr fs18"></i>
            <a [routerLink]="'/enterprise/home/tasks'">Tasks</a>
          </h4>
          <div class="row">
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">Not Started</h5>
              <h4 class="m0 fw-300 fs16">{{top_data.todos_data.tasks.not_started}}</h4>
            </div>
            <div class="col-xs-6 text-center">
              <h5 class="m0 mb-sm fw-400">In Progress</h5>
              <h4 class="m0 fw-300 fs16">{{top_data.todos_data.tasks.in_progress}}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-xs-12 mb-sm">
        <div class="border-box shadow p">
          <h4 class="m0 mb-xl text-center fs16">
            <i class="fa fa-envelope-open-o vl-m mr fs18"></i>
            <a [routerLink]="'/enterprise/home/messages'">Messages</a>
          </h4>
          <div class="row">
            <div class="col-xs-12 text-center">
              <h5 class="m0 mb-sm fw-400">Unread</h5>
              <h4 class="m0 fw-300 fs16">{{top_data.todos_data.messages.unread}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h4 class="title text-black m0 mt-lg mb p0 pt-sm">Visits</h4>
    <div class="border-box shadow p">
      <div class="row">
        <div class="col-lg-6 col-md-7 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-md-5 col-sm-5 col-xs-6 form-group">
              <select name="duration" id="" class="form-control" #duration_field (change)="duration = duration_field.value; change_duration(selected_type);">
                <option value=''>In All Years</option>
                <option value="3">In Last 3 months</option>
                <option value="6">In Last 6 months</option>
                <option value="12">In Last 12 months</option>
                <option value="24">In Last 2 years</option>
              </select>
            </div>
            <div class="col-md-5 col-sm-5 col-xs-4 form-group" *ngIf="userService.current_user.isEnterprise" >
              <div class="row">
                <div class="col-xs-12">
                  <select class="form-control" #selector (change)="change_search_criteria(selector.value)">
                    <option value="all" selected>All</option>
                    <option value="doctor">Doctor</option>
                    <option value="practice">Practice</option>
                  </select>
                </div>
                <div class="col-xs-12" *ngIf="profileable && profileable != 'all'">
                  <input name="pactice-doctor" [(ngModel)]="term"
                         [typeahead]="result" typeaheadWaitMs="500" [typeaheadAsync]="true"
                         (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                         [typeaheadOptionField]="'name'" [placeholder]="placeholder"
                         class="form-control" autocomplete="off">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-9 col-xs-9">
          <ul class="graph-tabs">
            <li [class.active]="selected_type == 'month'" (click)="change_duration('month',true);">Monthly</li>
            <li [class.active]="selected_type == 'quarter'" (click)="change_duration('quarter',true);">Quartly</li>
            <li [class.active]="selected_type == 'year'" (click)="change_duration('year',true);">Yearly</li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-1 col-sm-3 col-xs-3 text-right">
          <button class="btn btn-secondary btn-outline btn-sm">
            <span (click)="randomizeType()" class="fa fs20" [class.fa-bar-chart]="lineChartType == 'line'"
                  [class.fa-area-chart]="lineChartType == 'bar'"></span>
          </button>
        </div>
      </div>
      <div class="mt">
        <canvas baseChart
                *ngIf="data_available"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [chartType]="lineChartType"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)" style="height:350px"></canvas>
      </div>
    </div>
  </div>
</div>
<app-attachments (document_uploaded)="handle_image_upload($event)"
                 (popup_closed)="show_attachment_popup= false" [fileType]="'profile_pic'"
                 [only_modal]="true" [auto_upload]="true" [attachable_id]="top_data?.user?.id"
                 [attachable_type]="'User'"
                 [show]="show_attachment_popup">
</app-attachments>
<app-dashboard *ngIf="!top_data.user && data_available"></app-dashboard>
