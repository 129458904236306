<ng-container *ngIf="!formFillSuccessMessage">
  <ol class="flex-header counter-steps">
    <li *ngFor="let step of pendingForm.steps; let i = index" [class.active]="i === selectedStepIndex"
        (click)="onSelect(i, step.name)">{{ step.name }}
    </li>
  </ol>
  <div class="flex-body scroll-browser pt main-wrapper intake-components">
    <h3 class="m0 mb">{{selectedStep.name}}</h3>
    <app-demographics class="flex-body" *ngIf="selected_stage_id == 'Demographics' && patient.id" [patient]="patient" [intakeForm]="true"></app-demographics>
    <app-medication-summaries  class="flex-body" *ngIf="selected_stage_id == 'Current Medications'" [patient]="patient" [intakeForm]="true" [summary_of]="'medications'"></app-medication-summaries>
    <app-medication-summaries  class="flex-body" *ngIf="selected_stage_id == 'Current Allergies'" [patient]="patient" [intakeForm]="true" [summary_of]="'allergies'"></app-medication-summaries>
    <app-medication-summaries  class="flex-body" *ngIf="selected_stage_id == 'Current Diagnosis'" [patient]="patient" [intakeForm]="true" [summary_of]="'problems'"></app-medication-summaries>
  </div>
  <div class="flex-footer pb-sm text-center">
    <a *ngIf="loaderService.uploading_in_process" class="btn btn-default">
      <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
    </a>
    <ng-container *ngIf="!loaderService.uploading_in_process">
      <button class="btn btn-secondary mr" *ngIf="selectedStepIndex !== 0" (click)="onPrevious()">
        <i class="fa fa-chevron-left mr-sm"></i> Previous
      </button>
      <button class="btn btn-tertiary btn-outline btn-min mr" (click)="onSkip()">Skip</button>
      <button class="btn btn-secondary" (click)="onNext()">
        {{ selectedStepIndex === pendingForm.steps.length - 1 ? 'Submit' : 'Next' }}
        <i class="fa fa-chevron-right ml-sm"></i>
      </button>
    </ng-container>
  </div>
</ng-container>
<div *ngIf="formFillSuccessMessage" class="alert alert-success">{{ formFillSuccessMessage }}</div>
