<table class="table" *ngIf="show_calendar_listing && calendars && calendars.length > 0">
  <thead>
    <tr>
      <th>Name</th>
      <th>At</th>
      <th class="text-center" style="width:170px;">From</th>
      <th class="text-center" style="width:170px;">To</th>
      <th style="width:350px;">Days Of Week</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let calendar of calendars">
      <tr>
        <td><a href="javascript:void(0)" (click)="edit_calendar(calendar)">{{calendar.name}}</a></td>
        <td>{{calendar.location_name}}</td>
        <td class="text-center">{{calendar.from | date: 'dd-MM-yyyy'}}, {{calendar.day_starts_at}}</td>
        <td class="text-center">{{calendar.to | date: 'dd-MM-yyyy'}}, {{calendar.day_ends_at}}</td>
        <td>{{appoitment_days_schedule(calendar)}}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
<app-calendar-form [permissions]="permissions" *ngIf="show_calendar_form" [clients_service]="clients_service"
                   (form_hide)="handle_hide_form($event)" (edited_calendar)="calendar_saved()"
                   [calendarable_id]="calendar.calendarable_id" [calendarable_type]="calendar.calendarable_type"
                   [calendar]="calendar" [calendar_locations]="calendar_locations" [calendars_array]="calendars">
</app-calendar-form>
