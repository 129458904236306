<div class="flex-container flex-column">
  <div class="flex-header filters-sec">
    <div class="row">
      <div class="col-md-4 col-sm-6">
        <h4 class="fs16 fw-500">Care Plan</h4>
      </div>
      <div class="col-md-8 col-sm-6 text-right">
        <button *ngIf = "!(selectedTemplate || selectedRichTextTemplate)" class="btn btn-secondary" (click)="addCarePlan()" title="Add More Vitals"><i class="fa fa-plus"></i> Add</button>
        <a *ngIf="selectedTemplate || selectedRichTextTemplate" class="btn btn-tertiary btn-outline ml" (click)="viewListOfCarePlans()">Back</a>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!show_enote">
    <div class="flex-body scroll-browser" >
      <table class="table table-hover m0">
        <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Created at</th>
        </tr>
        </thead>
        <tbody *ngIf="programCarePlans.length > 0">
        <ng-container *ngFor="let carePlan of programCarePlans">
          <tr>
            <td *ngIf="!carePlan.is_rich_text"> <a href="javascript:void(0)" (click)="viewCarePlan(carePlan)">{{carePlan.name}}</a></td>
            <td *ngIf="carePlan.is_rich_text"> <a href="javascript:void(0)" (click)="viewRichText(carePlan)">{{carePlan.name}}</a></td>
            <td>{{carePlan?.document_type_name}}</td>
            <td>{{carePlan.created_at | date: 'short'}}</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <app-no-result-found *ngIf="programCarePlans && programCarePlans.length == 0"></app-no-result-found>
    </div>
    <div class="flex-footer text-center" *ngIf="programCarePlans && totalItems > 10">
      <pagination class="m0"
                  [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)">
      </pagination>
    </div>
  </ng-container>
  <div class="flex-body" *ngIf="show_enote">
    <ng-container *ngComponentOutlet="componentToRender;injector: custom_injector;"></ng-container>
  </div>
</div>
