<h4 class="title">Interventions
  <a (click)="addTasktab()" title="Add Task"><i class="fa fa-plus ml-sm"></i></a>
</h4>
<ul class="collapse-data-list border-list list-unstyled m0">
  <ng-container *ngFor="let task of enrolment_tasks">
    <li class="list">
      <div class="pull-right" *ngIf="task.comment_count > 0" tooltip="Number Of Comments">
        <span class="label label-success label-counter">{{task.comment_count}}</span>
      </div>
      <a (click)="viewTask(task)">{{task.task_name}}</a>
      <div class="label-badge">
        <small>{{task.created_at | date: 'short'}}</small>
        <label *ngIf="task.status_id == 'Not Started'" class="label label-danger m0 ml-sm">{{task.status_id}}</label>
        <label *ngIf="task.status_id == 'In Progress'" class="label label-info m0 ml-sm">{{task.status_id}}</label>
        <label *ngIf="task.status_id == 'Completed'" class="label label-success m0 ml-sm">{{task.status_id}}</label>
      </div>
    </li>
  </ng-container>
  <app-no-result-found *ngIf="enrolment_tasks && enrolment_tasks.length == 0"></app-no-result-found>
</ul>

<div class="modal fade" bsModal #newTaskModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog" style="height:608px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newTaskModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Create Task</h4>
      </div>
      <div class="modal-body" *ngIf="show_new_task_model">
        <ng-container *ngComponentOutlet="newTaskComponent;injector: custom_injector;"></ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #editTaskModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-lg" style="height:616px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="editTaskModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Task</h4>
      </div>
      <div class="program-task" *ngIf="show_view_task_model">
        <ng-container *ngComponentOutlet="ediTaskComponent;injector: view_custom_injector;"></ng-container>
      </div>
    </div>
  </div>
</div>
