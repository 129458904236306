import { Injectable } from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {GlobalErrorHandlerService} from "../services/global-error.service";
import {CustomHttpClient} from "../services/custom-http-client.service";
import {ReminderTemplate} from "./reminder-template";
import {Subject} from "rxjs";
import {nullRemover} from '../services/nullRemover';

@Injectable({
  providedIn: 'root'
})
export class RemindersService {

  reloadReminder: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient, private globalErrorHandlerService: GlobalErrorHandlerService) { }

  getRemindersTemplate(filter: any): Promise<any> {

    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('reminders/reminder_templates', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          reminders_template: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  create(reminder: ReminderTemplate): Promise<any> {
    return this._tokenService
      .post('reminders/reminder_template', reminder.to_params())
      .toPromise()
      .then(response => response)
  }

  save(reminder: ReminderTemplate): Promise<any> {
    if (reminder.is_new_record()) {

      return this.create(reminder);
    }
    else {
      return this.update(reminder);
    }
  }

  update(reminder: ReminderTemplate) {
    return this._tokenService
      .put('reminders/reminder_template/'+reminder.id, reminder.to_params())
      .toPromise()
      .then(response => response)
  }
  delete(reminder: ReminderTemplate) {
    return this._tokenService
      .delete('reminders/reminder_template/'+reminder)
      .toPromise()
      .then(response => response)
  }

  getReminders(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('reminders', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          reminders: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }


}
