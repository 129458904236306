import {Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewChecked, OnChanges} from '@angular/core';
import {UserManagementService} from '../../../../services/user-management.service';
import { NgForm } from '@angular/forms';
import {CustomValidations} from '../../../../routes/customValidations/custom-validations';
import {UserService} from '../../../../services/user.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {PatientService} from '../../../../services/patient.service';
import {Patient} from '../../../doctor/patient';
import {LoaderService} from '../../../../services/loader.service';
import { PatientInsurance} from '../../../models/patientInsurance';
import {isEmpty} from '../../../../services/nullRemover';
import {Router} from '@angular/router';
import {PhoneService} from '../../../../voice-call/phone.service';
import {toLower} from 'lodash';
import {AlertService} from '../../../../_alert';
import {IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss']
})
export class DemographicsComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() patient: Patient;
  @Input() intakeForm;
  @Input() intakeReport = false;
  originalPatientObj: Patient = new Patient();
  @Input() add_family_member = false;
  @Input() show_extras = false;
  @Input() injected_practice: any;
  public duplicate_patient: Patient = new Patient();
  @Output() new_user: EventEmitter<any> = new EventEmitter<any>();
  public gender_error = false;
  public  duplicate_detetced = false;
  public edit = false;
  public customValidations: CustomValidations;
  public email_copy = '';

  @ViewChild('UserForm') userForm: NgForm;
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  bsValue: Date = new Date;
  selectSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 1,
    displayAllSelectedText: false
  };
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    defaultTitle: 'Select',
    allSelected: 'All selected',
  };
  maxDate: Date = new Date;

  constructor(
    private loader: LoaderService, public patientService: PatientService, public alertService: AlertService,
    public userService: UserService, public phoneService: PhoneService,
    private _userManagementService: UserManagementService,
    private globalErrorHandlerService: GlobalErrorHandlerService, private router: Router) {
    this.customValidations = new CustomValidations();
  }

  ngOnInit() {
    this.initialize_fields();
    this.email_copy = this.patient.user.email;
    if (this.intakeForm) {
      this.editProfile();
    }
  }

  ngOnChanges() {
    this.initialize_fields();
    this.edit = false;
    if (!this.patient.parent_id && this.userService.current_user.isPatient) {
      this.add_family_member = false;
    }
  }

  initialize_fields() {
    // if (this.injected_practice && this.injected_practice.id) {
    //   this.patient.user.address.city_area.city.state = this.injected_practice.practice_state;
    //   this.patient.user.address.postal_code = this.injected_practice.practice_zip_code;
    //   this.patient.user.address.city_area.city.name = this.injected_practice.practice_address;
    //   this.patient.user.address.line_1 = this.injected_practice.practice_address;
    // }
  }

  checkDuplicate(query) {
    if (query) {
      this.patientService.check_duplicate(
        {query: query,
          dob: this.patient.dob,
          first_name: this.patient.user.first_name,
          last_name: this.patient.user.last_name
        }).then(res => {
        // if(this.duplicate_patient.hashid != res['hashid']) {
        this.duplicate_patient.load_from_json(res);
        if (this.duplicate_patient && this.duplicate_patient.id) {
          this.duplicate_detetced = true;
        } else {
          this.scroll();
        }
        // }
      }).catch(res => {
        this.duplicate_detetced = false;
      });
    }
  }

  hide_duplicate() {
    this.duplicate_detetced = false;
    let el = document.getElementById('scroll_to');
    el.style.cssText = 'display: none';
    return false;
    // el.scrollIntoView();
  }

  save() {
    let gender = this.patient.user.gender;
    if ( gender === 'Select Gender' || isEmpty(gender)) {
      this.gender_error = true;
      return;
    }

    if (this.patient.check_valid_dob(this.patient.dob)) {
      this.patient.patient_dob_year();
      return;
    }

    this.patient.user.email = this.patient.user.email.toLowerCase();
    this.loader.show();
    this.patientService.save(this.patient).then(res => {
      this.patient = this.patient.load_from_json(res);
      if (this.userService.current_user && this.userService.current_user.id == this.patient.user.id) {
        this.userService.refresh_patient(this.patient);
      }
      // this.userService.user = this.patient.user;
      this.new_user.emit(this.patient);
      setTimeout(() => {
        this.patient.success = '';
        this.edit = false;

      }, 2000)
      this.loader.hide();
    }).catch(resp =>  {
      this.globalErrorHandlerService.error = resp.error.error;
      if (resp.status == 416) {
        this.router.navigateByUrl('/');
        this.userService.logout();
        this.userService.showLogin();
      }
      this.patient.user.email = this.email_copy;
      this.loader.hide();
    });
  }
  changeGender(value) {
    this.gender_error = false;
    this.patient.user.gender = value;
  }

  addAsFamilyMember() {
    this.add_family_member = true;
    this.patient.parent_id = this.duplicate_patient.parent_id ? this.duplicate_patient.parent_id : this.duplicate_patient.id ;
    this.patient.user.phone = '';
    this.patient.user.email = '';
    this.patient.user.fax = '';
    this.hide_duplicate();
    return false;
    // this.save();
  }

  scroll() {
    let el = document.getElementById('scroll_to');
    el.style.cssText = 'display: block';
    el.scrollIntoView();
  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.userForm);
  }

  editProfile() {
    this.edit = !this.edit;
    if (this.patient.parent_id) {
      this.add_family_member = true;
    }
    this.originalPatientObj = new Patient().load_from_json(this.patient);
  }

  removeUnderScore(str: any) {
    if (str) {
      var i, frags = str.split('_');
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }
  }

  signUpRequestForPatient(id: number) {
    this.loader.show();
    let practice_id = this.userService.current_user.profileable['practice_id'];
    this.patientService.patientPortalActivateMail(id, practice_id).then(resp => {
        if (resp['message'] === 'successful') {
          this.patient.activation_mail_date = new Date();
        }
        this.loader.hide();
        this.alertService.success('eMail has been sent successfully', this.alertService.options);
      }
    ).catch(resp => {
      this.loader.hide();
      this.globalErrorHandlerService.error = resp.error.message;
    })
  }

  checkValidEmail(): boolean {
    return this.patient.user.email.includes('@dummy-wellwink.com') || this.patient.user.email.includes('@dummy-ilaaj.pk')
  }

  isEmailRequired(): boolean {
    return (this.userService.current_user && this.userService.current_user.isPatient && !this.add_family_member)
  }

  selectLanguages(notify: any) {
    this.patient.user.preferred_language = notify;
  }

  cancelEditForm() {
    this.patient = this.patient.load_from_json(this.originalPatientObj);
    this.edit = !this.edit;
  }
}
