import { Component, OnInit } from '@angular/core';
import { RatingType } from '../../health/doctor/patient_review'
import { RatingsService } from '../../services/ratings.service'
@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  ratingType: RatingType;
  ratingTypes: RatingType[] = [];

  constructor(private _ratingService: RatingsService) {
    this.ratingType = new RatingType();
  }

  ngOnInit() {
    this._ratingService.getRatingTypes().then( response => {
      let ratingTypes = response.filter(ratingType => this.ratingType.load_from_json(ratingType))
      this.ratingTypes = ratingTypes;
      this.ratingType = new RatingType();
    })
  }
  add() {
   this._ratingService.create(this.ratingType).then( json => {
     if (json.id) {
     this.ratingTypes.push(this.ratingType.load_from_json(json))
     this.ratingType = new RatingType();
     }
   })
  }
  remove(rating) {
    this._ratingService.delete(rating).then( json => {
      if (json.ok) {
        this.ratingTypes.splice(this.ratingTypes.indexOf(rating), 1)
      }
    })

  }
}
