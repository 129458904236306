import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Patient} from '../doctor/patient';
import {UserService} from '../../services/user.service';
import {PatientService} from '../../services/patient.service';

@Component({
  selector: 'app-mr-number',
  templateUrl: './mr-number.component.html',
  styleUrls: ['./mr-number.component.scss']
})
export class MrNumberComponent implements OnInit {
  message = '';
  public  json = JSON;
  patient = new Patient();
  general_message = '';
  status = 'pending';
  mr_number = '';
  type = 'email';
  data = {mobile_otp: '', email_otp: '', mobile_number: ''};
  patient_data = {};
  message_class = 'success';

  constructor(private patient_service: PatientService, private user_service: UserService, private router: Router, private route: ActivatedRoute) { }


  ngOnInit() {
    this.route.params
      .subscribe((params: Params) =>  {this.mr_number = params['mr_number']});
    this.verifyMRN();
  }

  verifyMRN() {
    this.patient_service.checkMRN({mr_number:  this.mr_number, mobile_number: this.data.mobile_number}).then(res => {
      this.status = res.status;
      this.message = res.message;
      this.clear_messages();
      this.patient_data =  res.patient_data;
    }).catch(res => {
      this.status = res.status;
      this.message =  res.message;
    })

  }

  sendOtp() {
    this.patient_service.sendOtpForMRN({mr_number: this.mr_number, secret_key: this.patient_data['secret_key']}).
    then(response => {
      this.message = response.message;
      this.message_class = 'success';
      this.clear_messages();
    }).catch(res => {
      this.general_message =  res.message;
      this.message_class = 'failed';
      this.clear_messages();
    })
  }

  verifyOtpMRN() {
    this.patient_service.verifyOtpMRN({mr_number: this.mr_number, secret_key: this.patient_data['secret_key'], otp: this.data.mobile_otp}).
    then(res => {
      this.patient.load_from_json(res.patient);
      this.message_class = 'success';
      this.status = 'verified';
      this.clear_messages();
    }).catch(res => {
      this.message =  res.message;
      this.message_class = 'failed';
      this.clear_messages();
    })
  }

  clear_messages() {
    setTimeout(() => {
      this.message = '';
      this.message = '';
      this.message = '';
    }, 3500);
  }


}
