import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  Input,
  EventEmitter,
  Renderer2, OnInit
} from '@angular/core';
import {
  Participant,
  RemoteTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
  RemoteParticipant,
  RemoteTrackPublication
} from 'twilio-video';
import {UserService} from '../../services/user.service';
import {VideoChatService} from '../services/videochat.service';

@Component({
  selector: 'app-participants',
  styleUrls: ['./participants.component.scss'],
  templateUrl: './participants.component.html',
})
export class ParticipantsComponent implements OnInit {

  @ViewChild('list', { static: false }) listRef: ElementRef;
  @Output('participantsChanged') participantsChanged = new EventEmitter<boolean>();
  @Output('participantsRemoved') participantsRemoved = new EventEmitter<boolean>();
  @Input('activeRoomName') activeRoomName: string;
  @Output() roomChanged = new EventEmitter<string>();
  @Output() onleaveRoom = new EventEmitter<Object>();
  private participants: Map<Participant.SID, RemoteParticipant>;
  private dominantSpeaker: RemoteParticipant;
  public participantRemoved = false;

  get participantCount() {
    return !!this.participants ? this.participants.size : 0;
  }

  get isAlone() {
    return this.participantCount === 0;
  }

  constructor(private readonly renderer: Renderer2,
              public userService: UserService,
              public videoChatService: VideoChatService) {
  }

  ngOnInit(): void {
    this.videoChatService.remoteVideoTrackStatus = 'enabled';
    this.videoChatService.remoteAudioTrackStatus = 'enabled';
  }

  clear() {
    if (this.participants) {
      this.participants.clear();
    }
  }

  initialize(participants: Map<Participant.SID, RemoteParticipant>) {
    this.participants = participants;
    if (this.participants) {
      this.participants.forEach(participant => this.registerParticipantEvents(participant));
    }
  }

  add(participant: RemoteParticipant) {
    console.log('participant connected.');
    if (this.participants && participant) {
      this.participants.set(participant.sid, participant);
      this.registerParticipantEvents(participant);
    }
  }

  remove(participant: RemoteParticipant) {
    if (this.participants && this.participants.has(participant.sid)) {
      this.participants.delete(participant.sid);
    }
  }

  loudest(participant: RemoteParticipant) {
    this.dominantSpeaker = participant;
  }

  private registerParticipantEvents(participant: RemoteParticipant) {
    console.log('Participant registering', participant);
    if (participant) {
      participant.tracks.forEach(publication => this.subscribe(publication));
      participant.on('trackAdded', publication => this.subscribe(publication));
      participant.on('trackDisabled', track => {
        if (track.kind === 'video') {
          this.videoChatService.remoteVideoTrackStatus = 'disabled';
          console.log('video track disabled.');
        }
        if (track.kind === 'audio') {
          this.videoChatService.remoteAudioTrackStatus = 'disabled';
          console.log('audio track disabled.');
        }
      });
      participant.on('trackEnabled', track => {
        if (track.kind === 'video') {
          this.videoChatService.remoteVideoTrackStatus = 'enabled';
          console.log('video track enabled.');
        }
        if (track.kind === 'audio') {
          this.videoChatService.remoteAudioTrackStatus = 'enabled';
          console.log('audio track enabled.');
        }
      });
      participant.on('trackRemoved', publication => {
        console.log('track removed.');
        this.subscribe(publication);
      });
      participant.on('trackPublished', publication => {
          console.log('track puplished');
          this.subscribe(publication)
        }
      );
      participant.on('trackUnpublished',
        publication => {
          console.log('track un puplished');
          if (publication && publication.track) {
            this.detachRemoteTrack(publication.track);
          }
        });
      participant.on('reconnecting', () => {
        this.videoChatService.connectionLost = true;
        console.log(`${participant.identity} is reconnecting the signaling connection to the Room!`);
        /* Update the RemoteParticipant UI here */
      });
      participant.on('reconnected', () => {
        this.videoChatService.connectionLost = false;
        console.log(`${participant.identity} has reconnected the signaling connection to the Room!`);
        /* Update the RemoteParticipant UI here */
      });
    }
  }

  private subscribe(publication: RemoteTrackPublication | any) {
    if (publication && publication.on) {
      publication.on('subscribed', track => this.attachRemoteTrack(track));
      publication.on('unsubscribed', track => this.detachRemoteTrack(track));
    }
  }

  private attachRemoteTrack(track: RemoteTrack) {
    if (this.isAttachable(track)) {
      const element = track.attach();
      if (track.kind === 'video') {
        element.controls = false;
        if (!this.videoChatService.inCall) {
          this.videoChatService.startTimer();
        }
        // this.videoChatService.startCountDown();
        this.participantsChanged.emit(true);
      }
      this.renderer.data.id = track.sid;
      this.renderer.setStyle(element, 'width', '95%');
      this.renderer.setStyle(element, 'margin-left', '2.5%');
      this.renderer.appendChild(this.listRef.nativeElement, element);
    }
  }

  private detachRemoteTrack(track: RemoteTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach(el => el.remove());
      this.participantsRemoved.emit(true);
      this.participantRemoved = true;
      this.videoChatService.remoteParticipentRemoved = true;
      setTimeout(() => {
        this.participantRemoved = false;
        this.videoChatService.remoteParticipentRemoved = false;
      }, 5000)
    }
  }

  private isAttachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
    return !!track &&
      ((track as RemoteAudioTrack).attach !== undefined ||
        (track as RemoteVideoTrack).attach !== undefined);
  }

  private isDetachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
    return !!track &&
      ((track as RemoteAudioTrack).detach !== undefined ||
        (track as RemoteVideoTrack).detach !== undefined);
  }
}
