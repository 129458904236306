import { Component, OnInit } from '@angular/core';
import {TabsService} from "../../services/tabs.service";

@Component({
  selector: 'app-sms-marketing',
  templateUrl: './sms-marketing.component.html',
  styleUrls: ['./sms-marketing.component.scss']
})
export class SmsMarketingComponent implements OnInit {

  constructor(public tabs_service : TabsService) {
    this.tabs_service.register('SMS Marketing')
  }

  ngOnInit(): void {
  }
}
