<div class="todaysPatient-list headerPatient-list main-wrapper">
  <app-admissions-listing class="clearfix" [admission]="admission"></app-admissions-listing>
</div>
<div class="bg-lightest-gray main-wrapper pt-sm">
  <h5 class="text-center" *ngIf="caseFeedback"><i class="fa fa-user-circle"></i> &nbsp;Discharge Patient</h5>
  <div class="white-container-box clearfix p-lg">
    <form #dischargeForm="ngForm" (ngSubmit)="checkPayment()">
      <div class="row form-group">
        <label class="col-sm-2 col-xs-12">Approved by</label>
        <div class="col-sm-4 col-xs-12">
          <input class="form-control" placeholder="Doctor" name="doctor"
                 [(ngModel)]="caseFeedback.doctor_name" [typeahead]="data_source" typeaheadWaitMs="500"
                 [typeaheadOptionsLimit]="7" [typeaheadAsync]="true"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 autocomplete="off"
                 [typeaheadOptionField]="'doctor_full_name'"/>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-sm-2 col-xs-12">Discharge Checklist</label>
        <div class="col-sm-10 col-xs-12">
          <div class="checkbox c-checkbox clearfix m0">
            <ng-container *ngFor="let dischargeChecklist of dischargeChecklists">
              <label class="d-block mb-sm fw-500">
                <input name="checklist" type="checkbox" [checked]="caseFeedback.discharge_checklist_ids.indexOf(dischargeChecklist.id) > -1"
                       (click)="markChecklist(dischargeChecklist)"/>
                <span class="fa fa-check"></span>
                {{dischargeChecklist.description}}
              </label>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-sm-2 col-xs-12">Instructions</label>
        <div class="col-sm-10 col-xs-12">
          <textarea class="form-control no-resize" rows="8" cols="110" name="instructions" [(ngModel)]="caseFeedback.instructions"></textarea>
        </div>
      </div>
      <div class="row">
        <label class="col-xs-2 hidden-xs"></label>
        <div class="col-sm-4">
          <button [disabled]="!dischargeForm.form.valid" class="btn btn-primary btn-lg" type="submit">Discharge</button>
          <h5 *ngIf="caseFeedbackService.error_message" class="text-danger mt-lg">{{caseFeedbackService.error_message}}</h5>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:300px;">
    <div class="modal-content modal-border">
      <div class="modal-heading mb-lg">
        <h4 class="modal-title"><span class="col-sm-12">Patient has outstanding balance. Are you sure you want to continue?</span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-sm-offset-2 col-sm-10">
              <button type="submit" class="btn btn-default" (click)="saveDischarge();classicModal.hide()">Yes</button>
              <button type="submit" class="btn btn-default" (click)="classicModal.hide();">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
