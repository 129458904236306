<form (ngSubmit)="onSubmit()" #contactForm="ngForm" *ngIf="show_form">
  <div class="form-group">
    <label>First Name *</label>
    <div class="input-group">
      <span class="input-group-addon"><i class="fa icon-user"></i></span>
      <input type="text" class="form-control" required
             [(ngModel)]="contact_us.first_name" name="first_name">
    </div>
  </div>
  <div class="form-group">
    <label>Last Name *</label>
    <div class="input-group">
      <span class="input-group-addon"><i class="fa icon-user"></i></span>
      <input type="text" class="form-control" required name="last_name"
             [(ngModel)]="contact_us.last_name">
    </div>
  </div>
  <div class="form-group">
    <label>Email *</label>
    <div class="input-group">
      <span class="input-group-addon"><i class="fa icon-envelope"></i></span>
      <input type="email" class="form-control" required pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
             [(ngModel)]="contact_us.email" name="email">
    </div>
  </div>
  <div class="form-group">
    <label>Phone Number *</label>
    <div class="input-group">
      <span class="input-group-addon"><i class="fa icon-phone"></i></span>
      <input mask="(000) 000-0000" type="text" class="form-control"
             [(ngModel)]="contact_us.phone_number" name="phone_number" required>
    </div>
  </div>
  <div class="form-group comment-form">
    <label>Comments </label>
    <textarea class="form-control" style="height:73px;" id="comments"
              [(ngModel)]="contact_us.comments"  name="comments"></textarea>
  </div>
  <div class="form-group">
    <re-captcha
      [(ngModel)]="contact_us.captcha"
      id="captcha"
      name="captcha"
      required
      siteKey="6Lfk1NIZAAAAAAvclWKjDqRa2QoroCz_9BTTmP61"
    ></re-captcha>
  </div>
  <button type="submit" [disabled]="!contactForm.form.valid" class="btn btn-primary btn-block btn-lg fs20 mt-lg">Submit</button>
</form>
<p class="alert alert-success mb0 p mt text-center" *ngIf="!show_form">{{message}}</p>
