import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {CustomHttpClient} from '../../services/custom-http-client.service';


export class Permission {
    message: string;
    role_types: RoleType[];
     roles: Role[];
}


export class RoleType {
    id: number;
    name: string;
    roles: Role[] = [];
  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.roles = json.roles.map(role => new Role().load_from_json(role))
    return this;
  }
}

export class Role {
    id: number;
    name: string;
  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    return this;
  }
}

export class RoleTypePermission {
    id: number
    message: string;
    parent_id: number;
    name: string;
    role_type: string;
    permissions: PermissionFields;
    roles: Object;
    is_checked: boolean;
}

export class RolePermission {
    home: PermissionFields;
    patient: PermissionFields;
    schedule: PermissionFields;
    setting: PermissionFields;
}

export class PermissionFields {
    id: number;
    name: string;
    parent_id: number;
    category_name: string;
}

export class SetRolePermisson {
    message: string
}


@Injectable()
export class PermissionsService {
    private roleTypeUrl =  'permissions/access_control_panel';
    private roleTypePermissionsUrl = 'permissions/role_type_permissions';
    private createPermissionsUrl = 'permissions/set_role_permissions';
    constructor(private _tokenService: CustomHttpClient) {}


    public getPracticesAsObservable(token: string, role_type_id?: any): Observable<any> {
        let query = new RegExp(token, 'ig');
        return this._tokenService
            .get('permissions/get_practices/?name=' + token + '&role_type_id=' + role_type_id).share()
            .map(r => r as string[]);
    }

  public getSpecialtiesAsObservable(token: string, role_type_id?): Observable<any> {
    let query = new RegExp(token, 'ig');
    return this._tokenService
      .get('permissions/get_specialties/?name=' + token).share()
      .map(r => r as string[]);
  }

    public getGroupAsObservable(token: string, role_type_id): Observable<any> {
        let query = new RegExp(token, 'ig');
        return this._tokenService
            .get('permissions/get_practices/?name=' + token + '&role_type_id=' + role_type_id).share()
            .map(r => r as string[]);
    }

    getRoleTypes(): any {
        return this._tokenService.get(this.roleTypeUrl).share()
            .toPromise()
            .then(response => response as Permission[]);
    }
    userRoleTypes(): any {
      return this._tokenService.get( 'permissions/get_role_types').share()
        .toPromise()
        .then(response => response as Permission[]);
    }

    getRoleTypePermissions(role_id: number, permissionable_id: number): any {
        let params = new HttpParams();
        params =  params.set('role_id', role_id.toString());
        params =  params.set('permissionable_id', permissionable_id.toString());
        let url = this.roleTypePermissionsUrl;

        return this._tokenService.get(url, { params: params }).share()
            .map(response => response);
    }

    create(permission_ids: any, role_id: string, permissionable_id: string): Observable<SetRolePermisson> {

        return this._tokenService.put(this.createPermissionsUrl, {'permissionable_id': permissionable_id.toString(), 'role_id': role_id.toString(), 'permission_ids': permission_ids.toString()}).share()
            .map(response => response as SetRolePermisson);
    }

    generateArray(obj) {
        return Object.keys(obj).map((key) => { return obj[key]});
    }
}
