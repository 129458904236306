<ul class="flex-header pd-custom-tabs nav nav-pills pb0">
  <li *ngFor="let stage_id of stage_ids"  [class.active]="selected_stage_id == stage_id ">
    <button class="btn" [(ngModel)]="selected_stage_id" name="selected_stage_id" ngDefaultControl [value]="stage_id"
            (click)="onSelect(stage_id)">{{menu_items[stage_id]}}
    </button>
  </li>
</ul>
<app-vitals-ranges class="flex-body scroll-browser p" *ngIf="selected_stage_id === 0" [patient]="patient"></app-vitals-ranges>
<app-patient-alerts-settings class="flex-body scroll-browser p" *ngIf="selected_stage_id === 1" [patient]="patient"></app-patient-alerts-settings>
<app-devices class="flex-body scroll-browser p" *ngIf="selected_stage_id === 2" [patient]="patient" [isTrainingDevice]="false"></app-devices>


