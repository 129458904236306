<h4 class="text-bold">Patient Medications</h4>
<div class="table-responsive">
  <table class="table table-bordered table-striped table-hover">
    <thead>
    <tr>
      <th>Name</th>
      <th>SIG</th>
      <th>Updated</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let medication of medications">
      <td>{{medication['d_name']}}</td>
      <td>
        <ng-container *ngFor="let term_control of medication.custom_term_control_with_values(); let i=index">
          <app-note-custom-control-value class="fs12 fw-400" [term]="term_control" [term_control]="term_control" [control_index]="i"></app-note-custom-control-value>
          <span class="fs12 fw-400" style="margin-left:-3px" *ngIf="i < medication.custom_term_control_with_values().length - 3 ">,</span>
          <span class="fs12 fw-400" *ngIf="(medication.custom_term_control_with_values().length - 2) == i">and</span>
          <span class="fs12 fw-400" *ngIf="(medication.custom_term_control_with_values().length -1) == i">.</span>
        </ng-container>
      </td>
      <td>{{medication['last_updated_at'] | date: 'shortDate'}}</td>
      <td>{{medication['status']}}</td>
    </tr>
    </tbody>
  </table>
</div>
