import {Component, OnInit, ViewChild} from '@angular/core';
import {CaseService} from '../../../../services/case.service';
import {Case} from '../../case';
import {Observable, Subscription} from 'rxjs';
import {CaseFilter} from '../case-filter';
import {DepartmentService} from '../../../../services/department.service';
import {Department} from '../../department';
import {PracticesService} from '../../../../services/practices.service';
import {UserService} from '../../../../services/user.service';
import { SelectComponent } from 'ng2-select'
import {LoaderService} from '../../../../services/loader.service';
import { mergeMap } from 'rxjs/operators';
import {Doctor} from '../../../practices/doctors/doctor';

@Component({
  selector: 'app-admissions-filter',
  templateUrl: './admissions-filter.component.html',
  styleUrls: ['./admissions-filter.component.scss']
})
export class AdmissionsFilterComponent implements OnInit {
  @ViewChild('practiceInput') practiceInput: SelectComponent;

  public filter: CaseFilter = new CaseFilter();
  public cases: Case[];
  public patients: Observable<any[]>;
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public wards = [];
  public departments = [];
  practice_id = '';
  public subscription: Subscription;
  bsValue = new Date();
  public reloadCases;
  totalItems: any = '';
  public date2DisabledDates;
  public practices: any[];
  constructor(public loader_service: LoaderService, private caseService: CaseService, private departmentService: DepartmentService,
              public _userService: UserService , private _practicesService: PracticesService) {
    this.patients = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(
      mergeMap((token: string) => this.caseService.getPracticePatients(token, this.filter.practice_id))
    );
    this.subscription = this.caseService.reloadCases.subscribe(item => {
      if (item) {
        this.caseService.reloadCases.next(0);
        this.getCases();
      }
    });

  }
  ngOnInit() {
    this.getCases();
    this.getDepartments();
    this.getWards();
    console.log(this.patients , 'y praxtice hye')
  }
  getDepartments(practice_id?: any) {
    this.departmentService.getDepartments(practice_id).then(resp => {
      this.departments = resp.map(department => new Department().load_from_json(department))
    })
  }
  getCases() {
    this.loader_service.show();
    this.caseService.getCases(this.filter).then(resp => {
      this.cases = resp.cases.map(admission => new Case().load_from_json(admission));
      this.totalItems = resp.total;
      this.filter.page = resp.current_page;
      this.loader_service.hide();
      })
  }
  getWards() {
    this.caseService.getCases(this.filter).then(resp => {
      this.wards = resp.cases.map(admission => admission.ward);
      if (this.wards.length > 0) {
        this.wards = this.wards.filter((elem, pos) => {return this.wards.indexOf(elem) == pos; })
      }
    })
  }
  public changeTypeaheadNoResults(e: boolean): void {
    delete this.filter.patient_id;
  }

  public typeaheadOnSelect(e: any): void {
    this.filter.patient_id = e.item.id;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  selectWard(ward) {
    this.filter.ward = ward.value
  }
  selectDepartment(department) {
   this.filter.department_id = department.value;
  }
  pageChanged(page) {
    this.filter.page = page.page;
    this.getCases();
  }
  typedPractice(text: { term: string; practices: any }) {
    this._practicesService.searchPractices(text.term).subscribe(practices => {
      return this.practices = practices.map(practice => {return {id: practice.id, text: practice.name}})
    });
  }
  selectedPractice(practice) {
    this.filter.practice_id = practice.id;
    this.getDepartments(practice.id);
    this.getWards();
  }
  removePractice(event) {
   delete this.filter.practice_id;
    this.departments = [];
    delete this.filter.department_id;
    this.getWards();
  }
}
