import {Component, Input, Output, EventEmitter, ElementRef, Inject, AfterViewInit} from '@angular/core';
@Component({
  selector: 'app-city-area',
  templateUrl: './city-area.component.html',
  styleUrls: ['./city-area.component.scss']
})
export class CityAreaComponent implements AfterViewInit {

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Input() selector;
  public latitude: number;
  public longitude: number;
  public  name: string;
  public zoom = 18;
  public searchElementRef: ElementRef;
  constructor(@Inject(ElementRef) elementRef: ElementRef) {
    this.searchElementRef = elementRef;
  }

  ngAfterViewInit() {
    this.setGoogleMaps();
  }
  setGoogleMaps() {
    this.setCurrentPosition();
      let component = this;
      let searchBox = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement.querySelector(this.selector));
      google.maps.event.addListener(searchBox, 'places_changed', function () {
          function closure(elem) {
            let place = searchBox.getPlace();
            if (!place) {
              return;
            }
          elem.latitude = place.geometry.location.lat();
          elem.longitude = place.geometry.location.lng();
          elem.name = place.name;
          elem.trigger_change();
        }
        closure(component);
      })
  }


  trigger_change() {
    this.changed.emit({lat: this.latitude, long: this.longitude, name: this.name})
  }

  private setCurrentPosition() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.zoom = 12;
    });
  }
}
}
