<div class="call-receive-modal" [ngClass]="{'mini-popout': phoneService.showSmallWindow}" [hidden]="phoneService.hidden" (click)="$event.stopPropagation();">
  <div class="modal-body pt-sm pb-sm" *ngIf="phoneService.patient_info?.show_selection">
    <h4 class="text-center text-black fw-400">
      <i class="fa icon-call-out mr"></i> Call {{phoneService.patient_info?.name}}
    </h4>
    <h6 class="text-center text-warning fw-400">Select your desired number</h6>
    <ul class="list-unstyled border-list scroll-browser">
      <li class="list" *ngIf="phoneService.patient_info?.phone">
        <label class="field-label m0">Phone</label>
        <a href="javascript:void(0)" (click)="phoneService.initiatePhoneCall(phoneService.patient_info.phone,
            {name:phoneService.patient_info.name, pic:phoneService.patient_info.pic, show_selection: false}, phoneService.source_id)">
          {{phoneService.patient_info.phone | mask: '(000) 000-0000'}}
          <i class="fa fa-chevron-right ml-sm"></i>
        </a>
      </li>
      <li class="list" *ngIf="phoneService.patient_info?.mobile">
        <label class="field-label m0">Mobile</label>
        <a href="javascript:void(0)" (click)="phoneService.initiatePhoneCall(phoneService.patient_info.mobile,
            {name: phoneService.patient_info.name, pic: phoneService.patient_info.pic, show_selection: false}, phoneService.source_id)">
          {{phoneService.patient_info.mobile | mask: '(000) 000-0000'}}
          <i class="fa fa-chevron-right ml-sm"></i>
        </a>
      </li>
      <li class="list" *ngFor="let contact of phoneService.patient_info.e_contacts">
        <label class="field-label m0">{{contact.name}} ({{contact.relationship_name}})</label>
        <a href="javascript:void(0)" (click)="phoneService.initiatePhoneCall(contact.contact_number.value,{name: phoneService.patient_info.name, pic: phoneService.patient_info.pic, show_selection: false}, phoneService.source_id)">
          {{contact.contact_number.value | mask:'(000) 000-0000'}}<i class="fa fa-chevron-right ml-sm"></i>
        </a>
      </li>
    </ul>
    <div class="text-center">
      <button type="submit" class="btn btn-tertiary btn-outline fs14" (click)="closePopUp()">Cancel</button>
    </div>
  </div>
  <div class="modal-body" *ngIf="!phoneService.patient_info?.show_selection">
    <a class="resize-icon minz-icon" (click)="phoneService.showSmallWindow = !phoneService.showSmallWindow">
      <svg viewBox="0 0 512 512">
        <g transform="translate(0.000000,512) scale(0.100000,-0.100000)">
          <path d="M442 5100 c-106 -28 -187 -75 -267 -155 -81 -81 -127 -161 -155 -271 -20 -75 -20 -117 -20 -1894 0 -1262 3 -1834 11 -1876 39 -212 220 -405 434 -459 50 -13 161 -15 711 -15 l651 0 33 22 c48 33 72 70 77 120 7 58 -23 118 -74 149 l-38 24 -650 5 c-647 5 -650 5 -691 27 -23 12 -58 41 -77 64 -72 83 -67 -36 -67 1671 l0 1538 2240 0 2240 0 2 -1119 c3 -1118 3 -1120 24 -1148 73 -98 195 -98 268 0 l21 28 3 1387 c3 1544 6 1461 -65 1604 -46 90 -153 201 -240 246 -34 18 -96 41 -139 52 -76 20 -117 20 -2118 19 -1977 0 -2043 -1 -2114 -19z m4203 -325 c51 -25 105 -80 130 -130 15 -31 20 -68 23 -157 l4 -118 -2241 0 -2241 0 0 98 c0 53 5 115 11 136 23 83 99 161 180 184 22 7 750 10 2064 9 1997 -2 2031 -2 2070 -22z"/>
          <path d="M934 3391 c-39 -24 -61 -55 -75 -103 -22 -82 -26 -76 471 -573 253 -253 460 -463 460 -467 0 -3 -45 -8 -99 -10 -116 -4 -142 -17 -179 -89 -40 -77 -9 -169 72 -208 40 -20 59 -21 337 -21 335 0 355 4 400 76 24 39 24 39 24 349 0 295 -1 312 -21 345 -63 108 -215 109 -277 2 -16 -27 -19 -51 -18 -130 l1 -97 -462 462 c-255 254 -476 467 -491 472 -44 17 -109 13 -143 -8z"/>
          <path d="M2605 1260 c-111 -36 -207 -133 -240 -243 -22 -74 -22 -680 0 -754 33 -112 130 -208 245 -243 64 -20 89 -20 1145 -18 l1080 3 67 32 c82 39 142 99 182 183 l31 65 0 355 0 355 -31 65 c-40 84 -100 144 -182 183 l-67 32 -1085 2 c-1048 2 -1087 1 -1145 -17z m2177 -317 c17 -15 18 -38 18 -301 0 -252 -2 -288 -17 -304 -15 -17 -61 -18 -1049 -18 -925 0 -1034 2 -1048 16 -14 13 -16 55 -16 304 0 249 2 291 16 304 14 14 122 16 1046 16 946 0 1033 -1 1050 -17z"/>
        </g>
      </svg>
    </a>
    <a class="resize-icon maxz-icon" (click)="phoneService.showSmallWindow = !phoneService.showSmallWindow">
      <svg viewBox="0 0 512 512">
        <g transform="translate(0.000000,512) scale(0.100000,-0.100000)">
          <path d="M442 5100 c-106 -28 -187 -75 -267 -155 -81 -81 -127 -161 -155 -271 -20 -75 -20 -117 -20 -1894 0 -1262 3 -1834 11 -1876 39 -212 220 -405 434 -459 50 -13 161 -15 711 -15 l651 0 33 22 c48 33 72 70 77 120 7 58 -23 118 -74 149 l-38 24 -650 5 c-647 5 -650 5 -691 27 -23 12 -58 41 -77 64 -72 83 -67 -36 -67 1671 l0 1538 2240 0 2240 0 2 -1119 c3 -1118 3 -1120 24 -1148 73 -98 195 -98 268 0 l21 28 3 1387 c3 1544 6 1461 -65 1604 -46 90 -153 201 -240 246 -34 18 -96 41 -139 52 -76 20 -117 20 -2118 19 -1977 0 -2043 -1 -2114 -19z m4203 -325 c51 -25 105 -80 130 -130 15 -31 20 -68 23 -157 l4 -118 -2241 0 -2241 0 0 98 c0 53 5 115 11 136 23 83 99 161 180 184 22 7 750 10 2064 9 1997 -2 2031 -2 2070 -22z"/>
          <g style="transform:rotate(291deg);">
            <path style="transform:scale(-1, 1);" d="M934 3391 c-39 -24 -61 -55 -75 -103 -22 -82 -26 -76 471 -573 253 -253 460 -463 460 -467 0 -3 -45 -8 -99 -10 -116 -4 -142 -17 -179 -89 -40 -77 -9 -169 72 -208 40 -20 59 -21 337 -21 335 0 355 4 400 76 24 39 24 39 24 349 0 295 -1 312 -21 345 -63 108 -215 109 -277 2 -16 -27 -19 -51 -18 -130 l1 -97 -462 462 c-255 254 -476 467 -491 472 -44 17 -109 13 -143 -8z"/>
          </g>
          <path d="M2605 1260 c-111 -36 -207 -133 -240 -243 -22 -74 -22 -680 0 -754 33 -112 130 -208 245 -243 64 -20 89 -20 1145 -18 l1080 3 67 32 c82 39 142 99 182 183 l31 65 0 355 0 355 -31 65 c-40 84 -100 144 -182 183 l-67 32 -1085 2 c-1048 2 -1087 1 -1145 -17z m2177 -317 c17 -15 18 -38 18 -301 0 -252 -2 -288 -17 -304 -15 -17 -61 -18 -1049 -18 -925 0 -1034 2 -1048 16 -14 13 -16 55 -16 304 0 249 2 291 16 304 14 14 122 16 1046 16 946 0 1033 -1 1050 -17z"/>
        </g>
      </svg>
    </a>
    <img class="img-circle border-box shadow circle-border" [src]="phoneService.patient_info?.pic || baseUrl + '/ilaaj.png'">
    <div class="flex-control flex-column" *ngIf="phoneService.phone_number">
      <div class="flex-body">
        <h5 class="text-center text-black">{{phoneService.patient_info?.name}}</h5>
        <h4 class="text-center m0 mb fw-300">{{phoneService.viewable_number | mask: '(000) 000-0000'}}</h4>
        <div class="calling-text" *ngIf="call">
          <h5 class="text-center fw-500 text-secondary">{{callStatus}}</h5>
          <div class="loading-dots" *ngIf="callStatus === 'Ringing' || callStatus == 'Connecting'">
            <h6 class="dot one">.</h6><h6 class="dot two">.</h6><h6 class="dot three">.</h6>
          </div>
        </div>
        <h6 class="text-center fw-400 mt-sm" *ngIf="phoneService.clock">Call Time: {{phoneService.formatClock}}</h6>
      </div>
      <div class="text-center flex-footer b0">
        <button class="btn btn-secondary" type="submit" (click)="makeOutgoingCall()" *ngIf="!phoneService.in_call">
          <i class="fa icon-call-out"></i>
        </button>
        <button class="btn btn-danger" type="submit" *ngIf="phoneService.in_call" (click)="hangup()">
          <i class="fa icon-call-end"></i>
        </button>
        <button class="btn btn-tertiary btn-outline" type="submit" (click)="closePopUp()">
          <i class="fa icon-close"></i>
        </button>
      </div>
      <h6 class="text-center fs10 flex-footer">Please press Blue Phone icon to initiate a call.</h6>
    </div>
    <div class="mini-content" *ngIf="phoneService.phone_number">
      <h5 class="text-black m0 mb">{{phoneService.patient_info?.name}}</h5>
      <button class="btn btn-secondary" type="submit" (click)="makeOutgoingCall()" *ngIf="!phoneService.in_call">
        <i class="fa icon-call-out"></i>
      </button>
      <button class="btn btn-danger" type="submit" (click)="hangup()" *ngIf="phoneService.in_call">
        <i class="fa icon-call-end"></i>
      </button>
      <button class="btn btn-tertiary btn-outline" type="submit" (click)="closePopUp()">
        <i class="fa icon-close"></i>
      </button>
    </div>
    <ng-container *ngIf="!phoneService.phone_number"><h5 class="text-center">Phone Number is not Valid.</h5></ng-container>
  </div>
</div>
