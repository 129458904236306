<div class="main-wrapper clearfix services-admin">
  <div class="row">
    <div class="col-xs-3"><h3 class="mt0">Resources</h3></div>
    <div class="col-xs-6">
<!--      <div class="input-group">
        <input type="text" class="form-control" placeholder="Search by keyword...">
        <span class="input-group-btn"><button class="btn btn-primary" type="button">Search</button></span>
      </div>-->
    </div>
    <div class="col-xs-3 text-right">
      <button *userCan="practice_permissions" class="btn btn-inverse" (click)="toggleService()">Add Resource</button>
    </div>
  </div>
  <hr class="hr-line mt-lg">
  <ng-container *ngIf="show_service_form && practice">
    <app-service (reset_service_form)="sync_clients_services($event)" [practice]="practice"
                 [service]="(selected_clients_service_index ||  selected_clients_service_index == 0)  ? clients_services[selected_clients_service_index] : new_service">
    </app-service>
  </ng-container>
  <div class="table-responsive" *ngIf="clients_services">
    <table class="table table-striped table-hover table-bordered m0">
      <thead>
      <tr>
        <th style="width:200px;">Name</th>
        <th>Associated Resource</th>
        <th style="width:500px;">Description</th>
        <th style="width:300px;">Technician/Doctor</th>
        <th class="text-center" style="width:170px; min-width:170px;">Actions</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let clients_service of clients_services; let i=index">
        <tr>
          <td permittedClass [condition]="practice_permissions">
            <a class="fs16" (click)="toggle_view(clients_service)">{{clients_service.name}}</a>
          </td>
          <td>{{clients_service.service.name}}</td>
          <td><read-more [text]="clients_service.description" [maxLength]="50"></read-more></td>
          <td>
            <span class="technician" *ngFor="let technician of clients_service.service_technicians">{{technician.name}}</span>
          </td>
          <td class="text-center">
            <a class="btn btn-primary btn-outline" title="View" (click)="toggle_view(clients_service)">
              <em class="fa fs16" [class.fa-plus-square-o]="!clients_service.show_calendars"
                  [class.fa-minus-square-o]="clients_service.show_calendars"></em>
            </a>
            <a class="btn btn-primary btn-outline" title="Edit" *userCan="practice_permissions" (click)="selectService(i)">
              <i class="fa fa-pencil"></i>
            </a>
            <a class="btn btn-danger btn-outline" title="Remove" *userCan="practice_permissions" (click)="classicModal.show();">
              <i class="fa fa-trash-o"></i>
            </a>
          </td>
        </tr>
        <ng-container *ngIf="clients_service.show_calendars">
        <tr>
          <td colspan="20" class="p-lg">
            <div class="row">
              <div class="col-xs-6"><h4 class="m0 mt-sm"><i class="fa icon-calendar"></i> Calendar</h4></div>
              <div class="col-xs-6 text-right">
                <a *userCan="practice_permissions" class="btn btn-warning" (click)="toggle_calendar_form(clients_service)">Add Calendar</a>
              </div>
            </div>
            <app-calendar-form  *ngIf="clients_service.show_calendar_form" [clients_service]="clients_service" (form_hide)="toggle_calendar_form(clients_service)" (edited_calendar)="calendar_saved($event,clients_service)" [calendarable_id]="clients_service.id"  [permissions]="practice_permissions" [calendarable_type]="'ClientsService'" [calendar]="new_calendar" [calendar_locations]="practice.locations"></app-calendar-form>
            <ng-container *ngIf="show_calendar_listing">
              <app-calendars-listing [permissions]="practice_permissions" [calendars]="clients_service.calendars" [calendar_locations]="practice.locations" [clients_service]="clients_service"></app-calendars-listing>
            </ng-container>
          </td>
        </tr>
        </ng-container>
        <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
          <div class="modal-dialog modal-md" style="height:200px;">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
                <h4 class="m0 text-center">Remove</h4>
              </div>
              <div class="modal-body">
                <div class="alert alert-danger m0 text-center">Are You Sure?</div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary"  (click)="delete(clients_service);">Yes</button>
                <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">No</button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
