import { Component, OnInit } from '@angular/core';
import {GlobalErrorHandlerService} from '../services/global-error.service';

@Component({
  selector: 'app-reloader',
  templateUrl: './reloader.component.html',
  styleUrls: ['./reloader.component.scss']
})
export class ReloaderComponent implements OnInit {

  constructor(public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
  }

  reload() {
    this.globalErrorHandlerService.reload_app = false;
    window.location.reload();
  }
}
