import {Value} from './control';
/**
 * Created by qaisarnadeem on 6/11/19.
 */
export class TermControlValueProperty {
  id: number;
  prop_value: string;
  prop_type: any;
  control_value_id: number;
  term_id: number;
  enable_custom_control: boolean;
  term_control_id: number;
  is_normal_value_prop: boolean;
  value: Value;
  get is_text() {
    return this.prop_type == 0 || this.prop_type == null || this.prop_type == 'text';
  }
  get is_color() {
    return this.prop_type == 1 || this.prop_type == null;
  }
  to_params() {
    return{
      id: this.id,
      prop_value: this.prop_value,
      prop_type: this.prop_type,
      control_value_id: this.control_value_id,
      library_datum_id: this.term_id,
      base_term_control_id: this.term_control_id,
      enable_custom_control: this.enable_custom_control
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.prop_type = json.prop_type;
    this.prop_value = json.prop_value;
    this.control_value_id = json.control_value_id;
    this.term_control_id = json.base_term_control_id;
    this.enable_custom_control = json.enable_custom_control;
    this.term_id = json.library_datum_id;
    this.value = new Value().load_from_json(json.value);
    return this;
  }

  copy_properties(json) {
    this.prop_type = json.prop_type;
    this.prop_value = null
    this.control_value_id = json.control_value_id;
    this.term_control_id = json.base_term_control_id;
    this.enable_custom_control = json.enable_custom_control;
    this.term_id = json.library_datum_id;
    this.value = new Value().load_from_json(json.value);
    return this;
  }

}
