<div class="modal modal_popup d-block" *ngIf="_userService.show_login_popup">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="row">
        <div class="col-md-5">
          <div class="modal-header">
            <button class="close" (click)="trigger_login_close();"><i class="fa fa-times fs16"></i></button>
            <h4 class="text-center m0"><img src="assets/img/branding/logo-icon-green.png" alt="App Logo" /></h4>
          </div>
          <form [formGroup]="valForm" class="form-validate modal-body" role="form" name="loginForm" novalidate=""
                (submit)="submitForm($event, valForm.value)">
            <div class="form-group has-feedback">
              <label>Email*</label>
              <input class="form-control" id="exampleInputEmail1" type="email" name="email" placeholder="Enter email"
                     pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" autocomplete="off" formControlName="email" required="" [(ngModel)]="_signInData.email"/>
              <span class="fa fa-envelope form-control-feedback text-muted"></span>
              <span class="text-danger"
                    *ngIf="valForm.controls['email'].hasError('required') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field is required</span>
              <span class="text-danger"
                    *ngIf="valForm.controls['email'].hasError('email') && (valForm.controls['email'].dirty || valForm.controls['email'].touched)">This field must be a valid email address</span>
            </div>
            <div class="form-group has-feedback">
              <label>Password*</label>
              <input class="form-control" id="exampleInputPassword1" type="password" name="password"
                     placeholder="Password" formControlName="password" required="" [(ngModel)]="_signInData.password"/>
              <span class="fa fa-lock form-control-feedback text-muted"></span>
              <span class="text-danger"
                    *ngIf="valForm.controls['password'].hasError('required') && (valForm.controls['password'].dirty || valForm.controls['password'].touched)">This field is required</span>
            </div>

            <ng-container *ngIf="show_error">
              <div>
                <span class="text-danger">{{_output}}</span>
              </div>
            </ng-container>

            <ng-container *ngIf="account_not_verified">
              <div>
                <span class="text-danger">{{_output}}</span>
                - <a class="btn-link" (click)="resendVerificationEmail()"><i class="fa fa-share-square"></i> Resend eMail</a>
              </div>
            </ng-container>

            <ng-container *ngIf="_userService.user_email_verified_successfully">
              <div>
                <span class="text-success">Account has been verified, you can login</span>
              </div>
            </ng-container>

            <div class="clearfix">
              <div class="checkbox c-checkbox pull-left mt0">
                <label>
                  <input type="checkbox" value="" name="account_remember"/>
                  <span class="fa fa-check"></span>Remember Me</label>
              </div>
              <div class="pull-right"><a class="" [routerLink]="'/recover'">Forgot your password?</a>
              </div>
            </div>
            <button class="btn btn-secondary btn-block" type="submit">Sign in <i class="fa icon-login"></i></button>
          </form>
        </div>
        <div class="col-md-7 hidden-sm hidden-xs bg-img"></div>
      </div>
    </div>
  </div>
</div>
