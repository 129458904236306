import {Component, OnInit, Injector, ViewChild} from '@angular/core';
import {Appointment} from '../../../../health/models/appointment'
import {Location} from '../../../practices/locations/location';
import {UserService} from '../../../../services/user.service';
import { Observable }        from 'rxjs/Observable';
import {PatientService} from '../../../../services/patient.service';
import {Patient} from '../../../../health/doctor/patient';
import {EmergencyService} from '../../../../services/emergency.service';
import {DoctorService} from '../../../../services/doctor.service';
import {LoaderService} from '../../../../services/loader.service';
import {AppointmentService} from '../../../../services/appointment.service';
import {TabsService} from '../../../../services/tabs.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import { DatePipe } from '@angular/common';
import {SpecialtySearchService} from '../../../../services/specialty-search.service';
import {PatientAuthorizationService} from '../../../../services/patient-authorization.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-arrive',
  templateUrl: './arrive.component.html',
  styleUrls: ['./arrive.component.scss']
})
export class ArriveComponent implements OnInit {
  public patient: Patient = new Patient();
  public dataSourcePatients: Observable<any>;
  public dataSourceServices: Observable<any>;
  public locations: Location[];
  public appointment: Appointment = new Appointment();
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public asyncSelectedService = 'Emergency Visit';
  public typeaheadLoadingService = false;
  public doctor_id: string;
  public location_id: string;
  public typeaheadNoResultsService = false;
  public  show_patient_form = true;
  public appointment_from = 'emergency';
  public selected_patient: Patient = new Patient();
  public show_attachment_popup = false;


  @ViewChild('authorizationModal') authorizationModal: ModalDirective;

  constructor(private specialtySearchService: SpecialtySearchService, private globalErrorHandlerService: GlobalErrorHandlerService,
              private _tab_service: TabsService, private _appointment_service: AppointmentService, private loader: LoaderService,
              private injector: Injector, public _user_service: UserService, private _patient_service: PatientService,
              public emergency_service: EmergencyService, public patientAuthService: PatientAuthorizationService) {
    this.dataSourcePatients = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.getPatients(token)));

    this.dataSourceServices = Observable.create((observer: any) => {
      observer.next(this.asyncSelectedService);
    }).pipe(mergeMap((token: string) => this.get_services(token)));

  }
  ngOnInit() {
    let appointment = this.injector.get('appointment', null);
    let locations = this.injector.get('locations', null);
    this.locations = locations;
    this.appointment = appointment;
    if (this.locations && this.locations.length == 1) {
      this.location_id = (this.locations[0].id).toString();
      this.appointment.location_id = parseInt(this.location_id);
      this.emergency_service.locationDoctors(this.location_id);
    }
    this.appointment.service_id = 731;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  get_services(term: any) {
    return this.specialtySearchService.search_services(term);
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public changeTypeaheadLoadingService(e: boolean): void {
    this.typeaheadLoadingService = e;
  }

  getPatients(token): Observable<any> {
    return this._patient_service.getPatientAsObservable(token);
  }

  public typeaheadOnSelect(e: any): void {
    this.patientAuthService.checkAuthorization(e.item, this.authorizationModal);
    this.patientAuthService.hint = e.item.mother_name_hint;
    this.selectPatient(e.item.id);
  }

  show_gender_age() {
    if (this.selected_patient.age > 0) {
      return '/ ' + this.selected_patient.age + ' Years';
    } else {
      return '';
    }
  }

  get providers() {
    if (this.locations && this.locations.length > 0) {
      return this.emergency_service.location_doctors;
    } else {
      return [];
    }
  }

  change_provider(id) {
    if (id == '') {
      this.doctor_id = '';
      this.appointment.doctor_id = parseInt('');
      return;
    }
    this.doctor_id = id;
    this.appointment.doctor_id = id;
  }

  change_location(id) {
    if (id == '' || id == 'undefined') {
      this.location_id = '';
      this.appointment.location_id = parseInt('');
      return;
    }
    this.location_id = id;
    this.appointment.location_id = id;
    this.emergency_service.locationDoctors(this.location_id);
  }

  book_appointment(appointment_from) {
    this.loader.show();
    if ((<HTMLInputElement> document.getElementById('emergencybtn'))) {
      (<HTMLInputElement> document.getElementById('emergencybtn')).disabled = true;
    }
    this.SetDateWaitingListAndAppointmentType();
    this._appointment_service.save(this.appointment).then(appointment => {
      if (this.appointment.appointment_from == 'emergency') {
        this.appointment = appointment;
      } else {
        this._tab_service.close_tab(this._tab_service.active_tab());
      }
      this._patient_service.call_for_appointments = !this._patient_service.call_for_appointments;
      this.emergency_service.applyFilters();
      this.loader.hide();
    }).catch(resp =>  {
      this.globalErrorHandlerService.error = resp.error.error
      this.loader.hide();
    });
  }

  isEnabled() {
    if (this.appointment.patient_id > 0
      && this.appointment.service_id > 0
      && this.appointment.doctor_id > 0
      && this.appointment.location_id > 0) {
      return false;
    } else {
      return true;
    }
  }

  SetDateWaitingListAndAppointmentType() {
    this.appointment.slot_size = '15';
    this.appointment.appointment_from = 'emergency';
    this.appointment.status_id = 'checkedin';
    let datePipe = new DatePipe('en-US');
    this.appointment.date = this.appointment.setInitalWaitingTime(datePipe);
    let rightNow = new Date();
    let ymd = this.formatDate(rightNow);
    this.appointment.date = ymd + ' ' + this.appointment.date + ':00'
  }


  setInitalWaitingTime(datePipe) {
    return this.calculateWaitingMin(datePipe.transform(new Date(), 'HH:mm'));
  }

  calculateWaitingMin(current_date) {
    let hour, addMinutes;
    addMinutes = (+current_date.split(':')[1] + 2);
    if (addMinutes >= '60') {
      addMinutes = addMinutes - 60;
      hour = +current_date.split(':')[0] + 1;
    } else {
      hour = current_date.split(':')[0];
    }
    return hour + ':' + this.setMinutes(addMinutes);
  }

  setMinutes(min) {
    min = min > 9 ? min : ('0' + min);
    return min
  }

  formatDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
  }

  public changeTypeaheadNoResultsService(e: boolean): void {
    this.typeaheadNoResultsService = e;
  }

  public typeaheadOnSelectService(e: any): void {
    this.appointment.service_id = e.item.id;
  }

  handle_user(new_patient) {
    this.selected_patient = new_patient;
    this.asyncSelected = this.selected_patient.user.full_name_with_information(this.selected_patient.age);
    this.appointment.patient_id = this.selected_patient.id;
  }

  close_modal(classicModal) {
    classicModal.hide();
    setTimeout(() => {
      this.show_patient_form = false;
      this.patient = new Patient();
      setTimeout(() => {
        this.show_patient_form = true;
      }, 200);
    }, 200)
  }

  handle_image_upload(data) {
    if (data) {
      this.patient.user.profile_pic =  data.profile_pic;
      this.patient.user.profile_pic_id = data.id;
    }
    this.show_attachment_popup = false;
  }


  verifyOtp() {
    this._patient_service.verifyPatientAuthorizationOtp({
      patient_id: this.patientAuthService.unathorizedPatient.id,
      otp: this.patientAuthService.data.mobile_otp
    }).then(res => {
      this.patientAuthService.mobile_message = res.message;
      this.patientAuthService.message_class = 'success';
      this.patientAuthService.verified = true;
      this.patientAuthService.clear_messages();
      this.selectPatient(this.patientAuthService.unathorizedPatient.id);
    }).catch(res => {
      this.patientAuthService.mobile_message = res.message;
      this.patientAuthService.message_class = 'failed';
      this.patientAuthService.clear_messages();
    })
  }

  verifyMotherName() {
    this._patient_service.verifyPatientMotherName({
      patient_id: this.patientAuthService.unathorizedPatient.id,
      mother_name: this.patientAuthService.data.mother_name
    }).then(res => {
      this.patientAuthService.mobile_message = res.message;
      this.patientAuthService.message_class = 'success';
      this.patientAuthService.verified = true;
      this.selectPatient(this.patientAuthService.unathorizedPatient.id);
      this.patientAuthService.clear_messages();
    }).catch(res => {
      this.patientAuthService.mobile_message = res.message;
      this.patientAuthService.message_class = 'failed';
      this.patientAuthService.clear_messages();
    })
  }
  hideModal() {
    this.asyncSelected = null;
    this.patientAuthService.closeModal(this.authorizationModal);
  }
  selectPatient(id) {
    this.appointment.patient_id = id;
    if (this.appointment.patient_id && this.patientAuthService.verified) {
      this._patient_service.selected_patient(this.appointment.patient_id).then(res => {
        this.selected_patient = this.selected_patient.load_from_json(res);
        this.appointment.patient_id = this.selected_patient.id;
      });
    }
  }
}
