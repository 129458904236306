import { Injectable } from '@angular/core';
import { HttpTokenService } from './http.token.service';
import { LoaderService } from './loader.service';
import {CustomHttpClient} from './custom-http-client.service';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class ScanService {
  scanning_in_process: boolean;
  public unscanned_pages_directory: Object = {}

  constructor(private _tokenService: CustomHttpClient) { }
  start_scan(data) {
    let params = new HttpParams();
    params = params.set('id', data.id);

    return this._tokenService.get('attachments/get_pages', {params: params}).toPromise()
      .then(response => response)
  }
  get_waiting(source: any) {
    let params = new HttpParams();
    params = params.set('attachable_type', source.class_name);
    params = params.set('attachable_id', source.id);

    return this._tokenService.get('attachments/pending_pages', {params: params}).toPromise()
      .then(response => response)
  }

  removePendingPages(source: any) {
    let params = new HttpParams();
    params = params.set('attachable_type', source.class_name);
    params = params.set('attachable_id', source.id);
    return this._tokenService.get('attachments/remove_pending_pages', {params: params}).toPromise()
      .then(response => response)
  }
  create_document(data) {
    return this._tokenService.post('documents/scanned', data).toPromise()
      .then(response => response)
  }

  set_data(no, appointment_id) {
    this.unscanned_pages_directory[appointment_id] = no
  }

  get_data(appointment_id) {
    return this.unscanned_pages_directory[appointment_id]
  }
}
