import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Program} from '../program';
import {ProgramEnrollment} from '../eligible-patient';
import {EnrolmentsService} from '../enrolments.service';
import {LoaderService} from '../../services/loader.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {UserService} from '../../services/user.service';
import {ProgramService} from '../program.service';

@Component({
  selector: 'app-patient-program',
  templateUrl: './patient-program.component.html',
  styleUrls: ['./patient-program.component.scss']
})
export class PatientProgramComponent implements OnInit {
  @Input() program: Program;
  @Input() programEnrollment: ProgramEnrollment;
  editTypeAndSeverity = false;
  severity: any;
  maxDate: Date = new Date;
  bsValue: Date = new Date;
  constructor(public enrolmentsService: EnrolmentsService, public loaderService: LoaderService, public programService: ProgramService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public userService: UserService) { }

  ngOnInit(): void {
    this.severity = this.programEnrollment.severity;
  }
  updateTypeAndSeverity() {
    this.loaderService.show();
    this.enrolmentsService.updateEnrollmentTypeAndSeverity(this.programEnrollment.id, this.severity).then(resp => {
      this.programEnrollment.load_from_json(resp);
      this.editTypeAndSeverity = false;
      this.enrolmentsService.reloadProgramEnrolmentObject.next(this.programEnrollment)
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
  update() {
    this.loaderService.show();
    this.programService.enrollPatientWithProgram(this.programEnrollment).then(resp => {
      this.programEnrollment.load_from_json(resp);
      this.editTypeAndSeverity = false;
      this.enrolmentsService.reloadProgramEnrolmentObject.next(this.programEnrollment);
      this.enrolmentsService.reloadProgramEnrolmentTime.next(this.programEnrollment.id);
      this.loaderService.hide();
    }).catch(res => {
      console.log(res);
      this.loaderService.hide();
      this.globalErrorHandlerService.error = res.error.error;
    })  }
  setTypeButtonCall(e) {
    const selectedType = e.target.value;
    if (e.target.checked) {
      this.programEnrollment.enrollment_types.push(selectedType);
    } else {
      const index = this.programEnrollment.enrollment_types.indexOf(selectedType);
      if (index !== -1) {
        this.programEnrollment.enrollment_types.splice(index, 1);
      }
    }
  }

  selectedType(type) {
    return this.programEnrollment.enrollment_types.includes(type);
  }
}
