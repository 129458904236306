import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-encounter',
  templateUrl: './print-encounter.component.html',
  styleUrls: ['./print-encounter.component.scss']
})
export class PrintEncounterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
