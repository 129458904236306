<section class="sec-pad pb0">
  <div class="container">
    <p class="m0">
      <i class="fa icon-calendar fa-fw"></i> <span class="fw-500">Last updated: August 10, 2020</span>
      <br><br>
      Thank you for using WellWink. Through www.WellWink.com (the “Site”), WellWink LLC, ("WellWink.com", “We,” “Us" or “Our”)
      is dedicated to helping you easily connect with a board-certified physician, physician assistant, nurse, pharmacist,
      healthcare provider, or associated care team to assist with your care (together with the Site, the “Services”) through
      the use of the WellWink portal of WellWink’s website located at www.WellWink.com (the "Portal").
      <br><br>
      These Terms of Use (“Terms”) govern your access to and use of the Site and related services, so please read them
      carefully before using the Site and services. As we add new functionality to the Site and Services, we may revise
      these Terms periodically. If we do, we will post the changes here. Your continued use of the Site after any changes
      are posted constitutes your acceptance of the new Terms. These Terms are a part of and inclusive of the terms set
      forth in WellWink’s Privacy Policy. Neither document shall be considered independent of the other, and in application
      both the Privacy Policy and Terms of Use shall be considered a singular agreement.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Your Use of the Service</h1>
    <p>
      In order to access or use the Service, you may be required to provide current, accurate, identification, contact
      and other information, including images and past medical history. You are responsible for maintaining the accuracy
      and completeness of this information. You hereby certify that the information you provide is, to best of your
      knowledge, true and accurate. Neither WellWink nor its providers shall have any liability for a diagnosis or
      opinion offered based on inaccurate information that you provide through the Site. Your use of the Service is not
      intended in any way to be a substitute for an in-person visit with a physician or other qualified health care provider.
    </p>
    <p>
      You are also responsible for maintaining the confidentiality of your phone number and email address. In order to
      use the Book Appointment Online Service, you must provide WellWink with your phone number and email address.
      In order to get access to WellWink Portal and make appointment request online, WellWink will send an initial
      six-digit verification code to the stated phone number and email address separately. This six-digit verification
      code will be used to verify your phone number and email address. You will then use the authorized email address
      to log-on to your account to access the Service. You will be able to book appointment with any doctor or medical
      facility until your email and mobile phone number is verified. However you will be able use all other services
      available online including but not limited to upload medical chart, add family, demographic/picture etc.
    </p>
    <p>
      In the event your phone number or email changes, you must update in demographic section and will then be prompted
      to verify update email or phone number by following verification process. WellWink assumes no liability for, or
      relating to, the integrity, privacy, security, confidentiality, or use of your information through an unauthorized phone number.
    </p>
    <p>
      You will be fully responsible for all activities that occur under your account, including activities of others to
      whom you have provided your authorized phone number or password so please notify WellWink immediately of any
      unauthorized use of your account or any other breach of security.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Patient Master Index (PMI)</h1>
    <p>
      By signing up as patient from www.WellWink.com, you automatically become part of Patient Master Index (PMI), which
      is used to maintain unique profile and MRN for each patient. Patient name, DOB, Gender, Mobile Number, eMail,
      Address, City, State, Zip can be used as single parameter or combination of these parameters to identify and
      maintain patient record uniquely nationwide.
    </p>
    <p>
      By sending an online appointment request to a medical center, a doctor or any other medical facility using WellWink
      portal, you are giving your consent to WellWink and the Medical Center to grant access to your profile and medical
      chart to all the staff members and doctors of the medical facility. If you are registered member of WellWink portal
      and visiting a new medical center, a doctor or any other medical facility who is already using WellWink for Patient
      Relationship Management, Practice Management or Electronic Health Record, can search you as patient by name or phone number.
      However they will not be authorized to access your medical record until you provide them your consent either by giving
      them Verification Code which you may receive through SMS as part of patient authorization process or you may be asked
      few question related to your data from your profile or medical record as part of Knowledge Base Authentication (KBA).
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Age</h1>
    <p>
      You must be 18 years of age to use this Service. If you are under 18 years of age, an appropriate legal guardian
      must act on your behalf for you to use this Service.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Description of Services</h1>
    <p>
      WellWink is online appointment booking and messaging service that acts as a gateway portal to facilitate the exchange
      of patient appointments and health information between physician and patient, and among physicians and other medical
      service providers, including without limitation pharmacies, health care facilities, and medical staff within a facility
      or practice. WellWink may also use the information received via the gateway portal for research purposes, which shall
      be limited to demonstrating the benefit of the Portal to improve patient care coordination and/or patient care management.
    </p>
    <p>
      WellWink is not a medical provider or telehealth service as such terms may be defined under state or federal laws.
      WELLWINK DOES NOT PROVIDE MEDICAL ADVICE. DO NOT USE THE SITE FOR EMERGENCY MEDICAL NEEDS. IF YOU THINK YOU ARE
      HAVING A MEDICAL EMERGENCY, CALL 911 OR EMERGENCY MEDICAL SERVICES. The information on the Site provided by WellWink
      does not constitute medical advice of any kind and it is not intended to be, and should not be, used to diagnose or
      identify treatment for a medical or mental health condition. Nothing on the Site should be construed as an attempt
      to offer or render a medical or mental health opinion or otherwise engage in the practice of medicine by WellWink.
    </p>
    <p>
      WELLWINK DOES NOT RECOMMEND, REFER, ENDORSE, VERIFY, EVALUATE OR GUARANTEE ANY ADVICE, INFORMATION, TREATMENT,
      INSTITUTION, PRODUCT, HEALTHCARE PROVIDER, OPINION OR OTHER INFORMATION OR SERVICES PROVIDED BY ANY PROVIDER USING
      THE SITE, AND NOTHING SHALL BE CONSIDERED AS A REFERRAL, ENDORSEMENT, RECOMMENDATION OR GUARANTEE OF ANY PROVIDER.
      WELLWINK DOES NOT WARRANT THE VALIDITY, ACCURACY, COMPLETENESS, SAFETY, LEGALITY, QUALITY, OR APPLICABILITY OF THE
      CONTENT OR ANYTHING SAID OR WRITTEN BY ANY PROVIDER OR ANY ADVICE PROVIDED. WELLWINK WILL NOT BE LIABLE FOR ANY
      DAMAGES SUSTAINED DUE TO RELIANCE BY THE USER ON SUCH INFORMATION OR ADVICE PROVIDED BY ANY PROVIDER. YOU AGREE
      THAT BY USING THIS SERVICE YOU ARE AT LEAST 18 YEARS OF AGE AND ARE LEGALLY ABLE TO ENTER INTO A BINDING CONTRACT.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">User Submissions — Image, Video, Audio Files</h1>
    <p>
      You agree to only post or upload media (like photos, videos or audio) that you have taken yourself or that you
      have all rights to transmit and license and which do not violate trademark, copyright, privacy or any other rights
      of any other person. Photos or videos of celebrities and cartoon or comic images are usually copyrighted by the owner.
    </p>
    <p>
      You agree that any Personally Identifiable Information (like name, phone number, email address or web site URL)
      contained in any media that you submit belongs only to you or your family members that are part of your online
      profile and not to anyone else. Uploading media like images or video of other people without their permission is
      strictly prohibited. By uploading any media on the site, you warrant that you have permission from all persons
      appearing in your media for you to make this contribution and grant rights described herein.
    </p>
    <p>
      It is strictly prohibited to upload media of any kind that contain expressions of hate, abuse, offensive images or
      conduct, obscenity, pornography, sexually explicit or any material that could give rise to any civil or criminal
      liability under applicable law or regulations or that otherwise may be in conflict with these Terms of Use and the
      WellWink Privacy Policy.
    </p>
    <p>
      You agree that you will not upload any material that contains software viruses or any other computer code, files or
      programs designed to interrupt, destroy or limit the functionality of any computer software or this Web site.
    </p>
    <p>
      By uploading any media like a photo or video, (a) you grant to WellWink a perpetual, non-exclusive, worldwide,
      royalty-free license to use, copy, print, display, reproduce, modify, publish, post, transmit and distribute the
      media and any material included in the media as follows: (i) for purposes of providing the Service; (ii) as permitted
      or required by law; and (iii) in accordance with our On-Line Privacy Policy,
      which is located at <a href="www.WellWink.com/privacypolicy.html" target="_blank">www.WellWink.com/privacypolicy.html</a>,
      and incorporated by reference into these Terms and Conditions. You agree to indemnify WellWink and its affiliates,
      directors, officers and employees and hold them harmless from any and all claims and expenses, including attorneys'
      fees, arising from the media and/or your failure to comply with these the terms described in this document.
    </p>
    <p>
      WELLWINK reserves the right to review all media prior to submission to the site and to remove any media for any
      reason, at any time, without prior notice, at our sole discretion.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Fees</h1>
    <p>
      WellWink will use a third-party to process any payments from you to the Provider. The third-party payment processor
      (and not WellWink) is responsible for collecting and processing credit card and other payment information and WellWink
      does not collect or store your credit or debit card number or other financial information. If you experience any
      problems with payment, or wish to contact the third-party payment processor directly for information on its terms
      of use and privacy policy or other questions, please contact info@wellwink@gmail.com You hereby agree to indemnify,
      defense and hold harmless WellWink from and against any claims, causes of action, fines, fees (include attorney’s fees)
      liabilities of any kind resulting from your use or failure to utilize the third-party payment processor.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Service Content, Software and Trademarks</h1>
    <p>
      The Service may contain content that is protected by copyright, patent, trademark, trade secret or other proprietary
      rights and laws (“Service Content”). Except as expressly authorized by WellWink, you agree not to modify, copy, frame,
      scrape, rent, lease, loan, sell, distribute or create derivative works based on the Service or the Service Content,
      in whole or in part. If you are eligible to use the Service, you are granted a limited license to access and use the
      Site and the Service Content and to download or print a copy of the Service Content you have provided or to which
      another user has properly granted you access solely for your personal, non-commercial use, provided that you keep
      all copyright or other proprietary notices intact. You are not permitted to use any data mining, robots, scraping
      or similar data gathering or extraction methods. Any use of the Site or the Service Content other than as authorized
      by these Terms is strictly prohibited and will terminate the license granted here. The technology and software
      underlying the Service is the property of WellWink LLC, our affiliates and our partners (the “Software”). You agree
      not to reverse engineer, reverse assemble or otherwise attempt to discover any source code version of the Software.
      We reserve all right, title and interest in and to the Software and Service Content, except for the limited rights
      expressly granted in these Terms.
    </p>
    <p>
      The WellWink name and logo are trademarks and service marks of WellWink LLC (collectively the “WELLWINK Trademarks”).
      Other company, product, and service names and logos used and displayed via the Service may be trademarks or service
      marks of their respective owners who may or may not endorse or be affiliated with or connected to WellWink.
      You may not use any of WellWink Trademarks without our prior written permission.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Third Party Material</h1>
    <p>
      WellWink will not be liable for any content posted by third parties, healthcare providers, or at the direction of
      users; you must evaluate the accuracy and usefulness of this content. WellWink does not pre-screen content, but
      WellWink and its designees will have the right (but not the obligation) to refuse or remove any content that is
      available via the Service, including the right to remove any content that violates the Terms or is deemed by
      WellWink to be objectionable.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">User Content Posted on the Site</h1>
    <p>
      You are solely responsible for the content and other materials you post on or through the Service or the Site or
      transmit to or share with other users or recipients (collectively, “User Content”). You may not post any content
      that you did not create or that you do not own. By posting User Content you hereby grant and will grant WellWink
      and  its affiliated companies a nonexclusive, worldwide, royalty free, fully paid up, transferable, sublicense-able,
      perpetual, irrevocable license to copy, display, transmit, distribute, store, modify and otherwise use your User
      Content in connection with the operation, advertising or marketing of the Service, in any form now known or later developed.
    </p>
    <p>
      We welcome your comments, questions, suggestions and feedback about the Site or the Service (“Submissions”).
      But be aware that any comments or suggestions you make to WellWink are non-confidential and become the property of
      WellWink, which will be entitled to the unrestricted use and dissemination of these Submissions for any purpose,
      commercial or otherwise, without acknowledgment or compensation to you.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Copyright Complaints</h1>
    <p>
      WellWink respects the intellectual property of others, and we ask our users to do the same. WellWink will promptly
      process and investigate notices of alleged infringement and will take appropriate actions under the Digital
      Millennium Copyright Act (“DMCA”) and other applicable intellectual property laws with respect to any alleged or
      actual infringement. If you believe that your work has been copied in a way that constitutes copyright infringement,
      or that your intellectual property rights have been otherwise violated, please provide our Copyright Agent with
      the following information:
    </p>
    <ul class="list-group">
      <li class="list-group-item">
        An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest.
      </li>
      <li class="list-group-item">
        A description of the copyrighted work or other intellectual property that you claim has been infringed.
      </li>
      <li class="list-group-item">
        A description of where the material that you claim is infringing is located on the Site, with enough detail that we may find it on the Site.
      </li>
      <li class="list-group-item">Your address, telephone number, and email address.</li>
      <li class="list-group-item">
        A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or
        intellectual property owner, its agent, or the law.
      </li>
      <li class="list-group-item">
        A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that
        you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.
      </li>
    </ul>
    <p>WellWink's Copyright Agent for Notice of claims of copyright or other intellectual property infringement can be reached as follows:</p>
    <p class="m0">Simon Lorenz WELLWINK</p>
    <p>s.lorenz@WELLWINK.com </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Repeat Infringer Policy</h1>
    <p>
      In accordance with the DMCA and other applicable law, WellWink has adopted a policy of terminating, in appropriate
      circumstances and at WellWink's sole discretion, members who are deemed to be repeat infringers. WellWink may also
      at its sole discretion limit access to the Site and/or terminate the memberships of any users who infringe any
      intellectual property rights of others, whether or not there is any repeat infringement.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Third Party Links and Content</h1>
    <p>
      The Service, Healthcare Providers or third parties may provide content, links or otherwise direct users to other
      sites and resources on the Internet. WellWink has no control over such sites and resources and is not responsible
      for and does not endorse such sites and resources. WellWink will not be responsible or liable, directly or indirectly,
      for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content,
      events, goods or services available on or through such site or resource. Information regarding healthcare providers,
      including biographical and contact information, is provided to WellWink by the provider and WellWink does not
      verify the accuracy of such information.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Release</h1>
    <p>
      By using the Service you agree to release, discharge and hold harmless WellWink and its subsidiaries and affiliates
      (the “WellWink Entities”) from any and all losses, damages, rights, claims, actions of any kind and injury
      (including death) arising out of or relating to the Service or any act or omission by any person, including without
      limitation, any dispute between you and any other person or regarding any content posted on the Site. If you are a
      California resident, you waive California Civil Code §1542, which says: “A general release does not extend to claims
      which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if
      known by him must have materially affected his settlement with the debtor.” This release includes, without limitation,
      any claim resulting from delay and the criminal acts of others.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Indemnification</h1>
    <p>
      You agree to release, indemnify and hold WellWink harmless from and against any losses, damages, liabilities, demands, administrative actions, government investigations, payor audits, costs, fines, fees, expenses (including reasonable attorneys’ fees, expert fees and disbursements) penalties, claims, suits and actions (collectively “Claims”), caused by, asserted to have been caused by, arising out of, as a result of, or related to, directly or indirectly, any act or omission by You, Your employees, affiliates, subcontractors or assigns, including but not limited to (i) the use, non-use misuse, access or unauthorized access of WellWink; (ii) breach by You of this Terms of Use; (iii) arising out of or relating to the Services, including without limitation, any content posted to or transmitted through the Service, or publicly distributed on the web, your use of the Service, your connection to the Service; (iv) any violation of law, rule or regulation; or (v) any other acts or omissions.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Disclaimer of Warranties</h1>
    <p>
      YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WELLWINK EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WELLWINK MAKES NO WARRANTY THAT (I) THE SERVICE WILL BE EFFECTIVE, WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS OR ERRORS, WILL BE RELIABLE OR ACCURATE, OR WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE, OR ACCESSIBLE AT TIMES OR LOCATIONS OF YOUR CHOOSING, (III) THE RESULTS OR INFORMATION THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE, RELIABLE TIMELY, OR COMPLETE, (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, ADVICE, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, AND (V) ANY ERRORS WILL BE CORRECTED. EXCEPT AS EXPRESSLY SET FORTH HEREIN, WELLWINK MAKES NO WARRANTIES ABOUT THE SOFTWARE AND FUNCTIONS MADE ACCESSIBLE THROUGH THE SITE OR ANY OTHER SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION.
      YOU ACKNOWLDEGE THAT ACCESS TO THE PORTAL WILL BE PROVIDED OVER VARIOUS FACILITIES AND COMMUNICATIONS LINES, AND INFORMATION WILL BE TRANSMITTED OVER LOCAL EXCHANGE AND INTERNET BACKBONE CARRIER LINES AND THROUGH ROUTERS, SWITCHES, AND OTHER DEVICES (COLLECTIVELY, “CARRIER LINES”) OWNED, MAINTAINED, AND SERVICED BY THIRD-PARTY CARRIERS, UTILITIES, INTERNET SERVICE PROVIDERS, ALL OF WHICH ARE BEYOND WELLWINK’s CONTROL. WELLWINK ASSUMES NO LIABILITY FOR OR RELATING TO THE INTEGRITY, PRIVACY, SECURITY, CONFIDENTIALITY, OR USE OF ANY INFORMATION WHILE IT IS TRANSMITTED ON THE CARRIER LINES, OR ANY DELAY, FAILURE, INTERRUPTION, INTERCEPTION, LOSS, TRANSMISSION, OR CORRUPTION OF ANY DATA OR OTHER INFORMATION ATTRIBUTABLE TO TRANSMISSION ON THE CARRIER LINES. USE OF THE CARRIER LINES IS SOLELY AT YOUR RISK AND IS SUBJECT TO ALL APPLICABLE LOCAL, STATE, NATIONAL, AND INTERNATIONAL LAWS.
      WELLWINK IS NOT RESPONSIBLE FOR UNAUTHORIZED ACCESS TO YOUR DATA, FACILITIES OR EQUIPMENT BY INDIVIDUALS OR ENTITIES USING THE PORTAL FOR UNAUTHORIZED ACCESS TO, ALTERATION, THEFT, CORRUPTION, LOSS OR DESTRUCTION OF YOUR DATA FILES, PROGRAMS, PROCEDURES, OR INFORMATION THROUGH THE SYSTEM, WHETHER BY ACCIDENT, FRAUDULENT MEANS OR DEVICES, OR ANY OTHER MEANS BY ANY AUTHORIZED USER, OR ANY OTHER PERSON, ENTITY, THIRD PARTY OR OTHER. YOU ARE SOLELY RESPONSIBLE FOR VALIDATING THE ACCURACY OF ALL OUTPUT AND REPORTS, AND FOR PROTECTING YOUR DATA AND PROGRAMS FROM LOSS BY IMPLEMENTING APPROPRIATE SECURITY MEASURES, INCLUDING ROUTINE BACKUP PROCEDURES. YOU HEREBY WAIVE ANY DAMAGES OCCASIONED BY LOST OR CORRUPT DATA, INCORRECT REPORTS, OR INCORRECT DATA FILES RESULTING FROM PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT OR SOFTWARE MALFUNCTION, SECURITY VIOLATIONS, OR THE USE OF THIRD-PARTY SOFTWARE. WELLWINK IS NOT RESPONSIBLE FOR THE CONTENT OF ANY INFORMATION TRANSMITTED OR RECEIVED THROUGH OUR PROVISION OF THE PORTAL.
      ALWAYS USE CAUTION WHEN GIVING OUT ANY PERSONALLY IDENTIFYING INFORMATION ABOUT YOURSELF OR YOUR CHILDREN.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Limitation of Liability</h1>
    <p>
      YOU EXPRESSLY UNDERSTAND AND AGREE THAT WELLWINK AND THE WELLWINK ENTITIES WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WELLWINK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR INABILITY TO USE THE SERVICE; (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; OR (III) ANY OTHER MATTER RELATING TO THE SERVICE.
      WellWink is not assuming liability, and, therefore shall not be liable to the You for any claims, debts, liabilities, obligations, costs, expenses, actions, causes of action and claims for relief as a result of any claim, audit, investigation, litigation or arbitration, or any other cause, whatsoever, regardless of whether or not such loss or damage was caused by or contributed to by WellWink’s negligent performance, or failure to perform any obligation. You hereby release WellWink from any claims for contribution or indemnity. Notwithstanding the foregoing, WellWink’s total liability for losses suffered by You or any of its affiliates under or related to this Agreement shall in no event exceed an amount equal to the greater of: (i) average monthly fee paid by You under this Agreement at time of loss or (ii) ONE HUNDRED DOLLARS ($100).
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
      IN THE EVENT YOU TRANSMIT, INTRODUCE, OR OTHERWISE CAUSE ANY TECHNICAL DISRUPTION OF THE PORTAL, YOU AGREE TO BE RESPONSIBLE FOR ANY AND ALL LIABILITIES AND COSTS AND EXPENSES (INCLUDING ATTORNEYS’ FEES AND EXPENSES) ARISING FROM ANY AND ALL CLAIMS BROUGHT BY THIRD PARTIES BASED UPON SUCH TECHNICAL DISRUPTIONS. "TECHNICAL DISRUPTION" MEANS DISTRIBUTION OF UNSOLICITED ADVERTISING OR CHAIN LETTERS, PROPAGATION OF COMPUTER WORMS, VIRUSES OR OTHER HARMFUL CODE, AND/OR USING THE SITE TO MAKE UNAUTHORIZED ENTRY TO ANY OTHER MACHINE ACCESSIBLE VIA THE SITE. YOU ARE FURTHER SOLELY RESPONSIBLE FOR THE CONTENT OF ANY TRANSMISSIONS USING THE SITE AND AGREE NOT TO UPLOAD, POST OR OTHERWISE MAKE AVAILABLE ON THE PORTAL ANY MATERIAL PROTECTED BY A PROPRIETARY RIGHT OF A THIRD PARTY WITHOUT FIRST OBTAINING THE EXPRESS PERMISSION OF THE OWNER OF SUCH PROPRIETARY RIGHT. YOU SHALL BE SOLELY LIABLE FOR ANY DAMAGES, LOSSES, COSTS OR EXPENSES (INCLUDING ATTORNEYS' FEES AND EXPENSES) ARISING OUT OF INFRINGEMENT OF PROPRIETARY RIGHTS OR ANY OTHER HARM ARISING FROM THE UPLOADING, POSTING OR OTHER SUBMISSION OF MATERIALS BY YOU.
      YOU ACKNOWLEDGE AND AGREE THAT THE FEES AND OTHER CHARGES WHICH WELLWINK IS CHARGING UNDER THIS AGREEMENT DO NOT INCLUDE  ANY CONSIDERATION FOR ASSUMPTION BY WELLWINK OF THE RISK OF YOUR'S DIRECT, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR OF UNLIMITED DIRECT DAMAGES.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">No Liability for Content</h1>
    <p>
      YOU AGREE THAT WELLWINK WILL NOT BE LIABLE FOR ANY CONTENT, INCLUDING BUT NOT LIMITED TO CONTENT THAT IS SENT, RECEIVED, HELD, RELEASED OR OTHERWISE CONNECTED IN ANY RESPECT TO THE PORTAL OR ITS RELATED SERVICES, CONTENT THAT IS UPLOADED OR DOWNLOADED BUT NOT RECEIVED, AND CONTENT SENT USING AND/OR INCLUDED IN THE SERVICES, INCLUDING WITHOUT LIMITATION ANY THREATENING, DEFAMATORY, OBSCENE, OFFENSIVE, OR ILLEGAL CONTENT; FURTHER, YOU AGREE THAT WELLWINK WILL NOT BE LIABLE FOR ANY ACCESS TO OR ALTERATION OF SUCH CONTENT OR FOR ANY INFRINGEMENT OF ANOTHER'S RIGHTS, INCLUDING PRIVACY, INTELLECTUAL PROPERTY, OR DATA PROTECTION RIGHTS ARISING FROM SUCH PROVIDER-GENERATED OR PATIENT USER-GENERATED CONTENT, OR FROM THE CONDUCT OF PHYSICIAN USERS, PATIENT USERS, OR THIRD PARTIES OTHER THAN WHICH MAY BE THE RESULT OF WELLWINK’S WILLFUL CONDUCT.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">No Warranty</h1>
    <p>
      YOU AGREE THAT THE PORTAL IS PROVIDED ON “AS IS” AND “AS AVAILABLE” BASIS ONLY, WITHOUT WARRANTY OF ANY KIND, AND ALL EXPRESS, IMPLIED OR STATUTORY WARRANTIES, CONDITIONS, REPRESENTATIONS, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, TIMELINESS, COMPLETENESS, ADEQUACY AND NONINFRINGEMENT OR WARRANTY ARISING OUT OF COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OR TRADE ARE EXCLUDED BY WELLWINK, AND WELLWINK DOES NOT WARRANT THAT THE PROGRAMS WILL MEET THE REQUIREMENTS OF ANY PERSON AND OPERATE ON AN UNINTERRUPTED OR ERROR-FREE BASIS. YOU ACKNOWLEDGE YOU ARE SOLELY RESPONSIBLE FOR ANY AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE PORTAL, INCLUDING INACCURATE OR INCOMPLETE INFORMATION.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Termination</h1>
    <p>
      You agree that WellWink, in its sole discretion, may suspend or terminate your account (or any part thereof) or use of the Service and remove and discard any content within the Service, for any reason. Furthermore, any healthcare provider may terminate their association with the Service or with you at any time and for any reason. If we suspect fraudulent, abusive or illegal activity, we may refer content or accounts to law enforcement authorities. WellWink will not be liable to you or any third-party for any termination of your access to the Service.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Dispute Resolution by Binding Arbitration</h1>
    <p>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</p>
    <p>
      Most user concerns can be resolved quickly and to the user’s satisfaction by emailing user support at info@wellwink@gmail.com. In the unlikely event that our user care team is unable to resolve a complaint you may have (or if WellWink has not been able to resolve a dispute it has with you after attempting to do so informally), we each agree to resolve those disputes through binding arbitration, mediation, or small claims court instead of in courts of general jurisdiction. Arbitration, which is often cheaper , faster and less formal than a lawsuit in court, uses a neutral arbitrator instead of a judge or jury. Arbitrators can award the same damages and relief that a court can award. If you initiate an arbitration in which you seek more than $75,000 in damages, the payment of attorneys’ fees will be governed by the AAA Rules (as defined below). Any arbitration under this Agreement will take place on an individual basis; class arbitrations and class actions are not permitted.
    </p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Arbitration Agreement</h1>
    <p>WellWink and you agree to arbitrate all disputes and claims between us. This agreement to arbitrate is intended to be broadly interpreted. It includes, but is not limited to:</p>
    <p>References to ‘WellWink’, ‘you’, and ‘us’ include our respective subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or devices under this or prior agreements between us. Notwithstanding the foregoing, either party may bring an individual action in small claims court. This arbitration agreement does not preclude you from bringing issues to the attention of federal, state or local agencies, including, for example, the Federal Communications Commission. Such agencies can, if the law allows, seek relief against us on your behalf. You agree that, by entering into these Terms, you and WellWink are each waiving the right to a trial by jury or to participate in a class action. These Terms evidence a transaction in the interstate commerce, and thus the Federal Arbitration Act governs the interpretation and enforcement of this provision. This arbitration provision shall survive termination of these Terms.</p>
    <p>A party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute (‘Notice’). The Notice to WellWink should be addressed  to WellWink LLC at 414 Hackensack Ave, Hackensack, NJ, 07601 (‘Arbitration Notice Address’). The Notice must (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (‘Demand’). If WellWink and you do not reach an agreement to resolve the claim within sixty (60) calendar days after the Notice is received, you or WellWink may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by WellWink or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or WellWink is entitled.</p>
    <p>The arbitration will be governed by the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (collectively, ‘AAA Rules’) of the American Arbitration Association (‘AAA’), as modified by these Terms, and will be administered by the AAA. The AAA Rules are available online at adr.org, by calling the AAA at 1-800-778-7879, or by writing to the Arbitration Notice Address. The arbitrator is bound by the terms of these Terms. All issues are for the arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and arbitrability of the arbitration provision. Unless WellWink and you agree otherwise, any arbitration hearings will take place in a reasonably convenient location for both parties with due consideration of their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location, the determination shall be made by AAA. If your claim is for $10,000 or less, we agree that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds $10,000, the right to a hearing will be determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the award is based. WellWink will pay all AAA filing, administration, and arbitrator fees for any arbitration initiated in accordance with the AAA Rules. However, if you initiate an arbitration in which you seek more than $75,000 in damages, the payment of these fees will be governed by the AAA Rules.</p>
    <p>The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees and expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator's ruling on the merits.</p>
    <p>The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim. YOU AND WELLWINK AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and WELLWINK agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this arbitration provision shall be null and void.</p>
    <p>Notwithstanding any provision in these Terms to the contrary, we agree that if WellWink makes any future change to this arbitration provision (other than a change to the Arbitration Notice Address) while you are a user of the Service, you may reject any such change by sending us written notice within thirty (30) calendar days of the change to the Arbitration Notice Address provided above. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this provision.</p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">General</h1>
    <p>The Terms constitute the entire agreement between you and WellWink and govern your use of the Service, superseding any prior agreements between you and WellWink with respect to the Service. You also may be subject to additional terms and conditions that may apply when you use affiliate services, third-party content or third-party software. The Terms will be governed by the laws of the State of New Jersey without regard to its conflict of law provisions. You and WellWink agree to submit to the personal and exclusive jurisdiction of the state and federal courts located within Bergen County, New Jersey. EACH OF THE PARTIES HEREBY KNOWINGLY, VOLUNTARILY AND INTENTIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LITIGATION (INCLUDING BUT NOT LIMITED TO ANY CLAIMS, COUNTERCLAIMS, CROSS-CLAIMS, OR THIRD PARTY CLAIMS) ARISING OUT OF, UNDER OR IN CONNECTION WITH THIS AGREEMENT. FURTHER, EACH PARTY HERETO CERTIFIES THAT NO REPRESENTATIVE OR AGENT OF EITHER PARTY HAS REPRESENTED, EXPRESSLY OR OTHERWISE, THAT SUCH PARTY WOULD NOT IN THE EVENT OF SUCH LITIGATION, SEEK TO ENFORCE THIS WAIVER OF RIGHT TO JURY TRIAL PROVISION. Any claim or cause of action arising out of or related to use of the Service or the Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred. A printed version of this agreement and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <h1 class="title">Force Majure</h1>
    <p>WellWink has no liability to You if the Portal is inaccessible or data destroyed by fire, strike, theft, acts of God, or any other cause. In the event of system malfunction, for whatever reasons, or inability to access the Portal, WellWink shall not be liable for damage to or loss of any of Your data and You acknowledge that You have been advised that You are responsible for maintaining Your own data by use of regular backup procedures. You agree to hold WellWink harmless from any liability resulting from violations of local, state or federal regulation relating to the inaccessibility to the Portal for reasons set forth in this Section. You agree to indemnify and hold WellWink harmless from costs associated with the defense of WellWink, including attorney’s fees, in any such local state or federal proceeding.</p>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <h1 class="title">Your Privacy</h1>
    <p>At WellWink we respect the privacy of our users and have outlined our collection, use and disclosure of personal information and registration data in our Privacy Policy. By using the Services, you accept and agree with all provisions of the Privacy Policy.</p>
  </div>
</section>
