import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {Invoice} from "../billing/invoice";
import {UserService} from "../../../services/user.service";
import {Practice} from "../practices/practice";
import {InvoiceService} from "../../../services/invoice.service";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnChanges {
  @Input() invoice: Invoice = new Invoice();
  admin_user: any;
  @Input() practice: Practice;
  saved: boolean = false;
  @Output() invoiceCreated = new EventEmitter<boolean>();
  constructor(private userService: UserService,
              private invoiceService: InvoiceService) { }

  ngOnInit() {  }

  getAdminUser(){
    this.userService.getAdminUser(this.practice.id).then(resp => {
      this.admin_user = resp;
      this.invoice.invoice_for_id = this.admin_user['id'];
      this.invoice.generator_id = this.userService.current_user.id;
    })
  }

  save(){
    this.invoiceService.save(this.invoice).then(resp => {
      this.invoice = this.invoice.load_from_json(resp);
      this.invoiceCreated.emit(true);
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      },3000)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAdminUser();
    this.invoice.practice_package_id = this.practice.practice_package.id;
  }

  disabledCriteria():boolean {
    return  this.invoice.status === 'paid' || !this.userService.current_user.isEnterprise
  }
}
