import { Injectable } from '@angular/core';
import  {Practice} from '../routes/practices/practices/practice'
import {CustomHttpClient} from './custom-http-client.service';
import { HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {PracticeSetting} from '../routes/practices/practice-settings/practice_setting';
import {EmailConfiguration} from '../routes/practices/email-configuration/email-configuration';
import {Campaign} from '../marketing/campaign';


@Injectable()
export class PracticesService {
  constructor(private _tokenService: CustomHttpClient) { }
  getPractices(): Promise<any[]> {
    return this._tokenService.get('practices')
        .toPromise()
        .then(response => response);
  }
  getAllPracticeForReviews() {
    return this._tokenService.get('practices/get_all_practices_for_reviews')
      .toPromise()
      .then(response => response);
  }
  searchAllPracticeForReviews(text) {
    let params = new HttpParams();
    params = params.set('term', text);
    return this._tokenService.get('practices/search_practices_for_reviews', {params: params})
      .toPromise()
      .then(response => response);
  }
  getPractice(id: any | string, searchParams?: Object): Promise<any> {
    let args = new HttpParams();
    args =  args.set('id', id);
    args =  args.set('include_physicians', searchParams ? searchParams['include_physicians'] : null);
    return this._tokenService
        .get('practices/get_practice', { params: args})
        .toPromise()
        .then(res => res);
  }
  getPracticeByHashid(id: any): Promise<any> {
    let args = new HttpParams();
    args =  args.set('hashid', id);
    return this._tokenService
      .get('practices/practice_by_hashid', { params: args})
      .toPromise()
      .then(res => res);
  }
  searchPractices(text: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('term', text);
    return this._tokenService.get('practices/search', {params: params})
      .map((resp) => resp);
  }

  create(practice: Practice): Promise<any> {
    return this._tokenService
        .post(practice.get_url(), practice.to_params())
        .toPromise()
        .then(res => res)
  }
  save_practice_setting(practiceSetting: PracticeSetting): Promise<any> {
    return this._tokenService
      .post('practices/practice_setting', practiceSetting.to_params())
      .toPromise()
      .then(res => res)
  }
  update_practice_setting(practiceSetting: PracticeSetting): Promise<any> {
    return this._tokenService
      .put('practices/practice_setting', practiceSetting.to_params())
      .toPromise()
      .then(res => res)
  }
  save_practice_email_configuration(practiceEmail: EmailConfiguration): Promise<any> {
    return this._tokenService
      .post('practices/email_configuration', practiceEmail.to_params())
      .toPromise()
      .then(res => res)
  }
  update_practice_email_configuration(practiceEmail: EmailConfiguration): Promise<any> {
    return this._tokenService
      .put('practices/email_configuration/' + practiceEmail.id, practiceEmail.to_params())
      .toPromise()
      .then(res => res)
  }
  getPracticeEmail(practice_id: any): Promise<any> {
    return this._tokenService
      .get('practices/email_configuration/' + practice_id)
      .toPromise()
      .then(res => res);
  }
  removePracticeEmailConiguration(practiceEmail: EmailConfiguration) {
    return this._tokenService
      .delete('practices/email_configuration/' + practiceEmail.id)
      .toPromise()
      .then(response => response)
  }
  getPracticeSetting(practice_id: any): Promise<any> {
    let args = new HttpParams();
    args =  args.set('practice_id', practice_id);
    return this._tokenService
      .get('practices/practice_setting', { params: args})
      .toPromise()
      .then(res => res);
  }
  save(practice: Practice): Promise<any> {
    if (practice.is_new_record()) {
      return this.create(practice);
    }
    else {
      return this.update(practice);
    }
  }

  update(practice: Practice) {
    return this._tokenService
        .put(practice.get_url(), practice.to_params())
        .toPromise()
        .then(res => res)
  }

  removePhoto(attachment): Promise<any> {
    let args = new HttpParams();
    args = args.set('id', attachment.attachment_id);
    return this._tokenService.get('attachments/delete_attachment', {params: args}).toPromise()
      .then(response => response)
  }

  removePracticeBanner(practice): Promise<any> {
    let args = new HttpParams();
    args = args.set('practice_id', practice.id);
    return this._tokenService.get('attachments/delete_banner', {params: args}).toPromise()
      .then(response => response)
  }

  removePracticeLogo(practice): Promise<any> {
    let args = new HttpParams();
    args = args.set('practice_id', practice.id);
    return this._tokenService.get('attachments/delete_logo', {params: args}).toPromise()
      .then(response => response)
  }

  public getThirdPartyDoctor(doctor_id) {
    let args = new HttpParams();
    args = args.set('doctor_id', doctor_id);
    return this._tokenService.get('third_party_integration/third_party_practice_physician', {params: args})
      .toPromise()
      .then(response => response)
  }
  public third_party_practice_settings(practice_id: any) {
    let args = new HttpParams();
    args = args.set('practice_id', practice_id);
    return this._tokenService.get('third_party_integration/practice_setting', {params: args})
      .toPromise()
      .then(response => response)
  }
}
