<ng-container *ngIf="get_current_user(_user_service.current_user)">
  <app-todays-patients-filter class="flex-header" [app_type]="app_type"
                              [current_page]="current_page"
                              [pagination_active]="_patient_service.call_for_appointments"
                              [user]="user"
                              [refresh_data]="refresh_tab_data(tab_service.get_data())"
                              [doctor_id]="doctor_id"
                              [locations]="locations"
                              [practice]="practice"
                              (pagination)="page_no($event)"
                              (totalcount)="total_appointment_count($event)"
                              (total_appointments)="appointments_data($event)"
                              [providers]="providers">

  </app-todays-patients-filter>
  <app-todays-patients-listing class="flex-body scroll-browser"
                               [providers]="providers"
                               [appointments]="appointments"
                               [totalItems]="totalItems"
                               [pagination]="pagination">

  </app-todays-patients-listing>
  <div class="flex-footer text-center" *ngIf="+totalItems > 10">
    <pagination class="m0" [totalItems]="totalItems"
                [itemsPerPage]="100"
                (pageChanged)="pageChanged($event)">
    </pagination>
  </div>
</ng-container>
