import {Component, Input, OnInit} from '@angular/core';
import {Term} from '../../../../../enote/enote_models/term';

@Component({
  selector: 'app-medications-table',
  templateUrl: './medications-table.component.html',
  styleUrls: ['./medications-table.component.scss']
})
export class MedicationsTableComponent implements OnInit {
  @Input() medications: Term[];
  constructor() {
  }
  ngOnInit(): void {
    this.medications = this.medications.map(term => new Term('', '').load_from_json2(term));
  }

}
