<div class="flex-control flex-column flex-height">
  <div class="flex-header">
    <h4 class="fs20 m0">{{ intakeFormTemplate.id ? 'Edit' : 'Add' }} Form Template</h4>
  </div>
  <div class="flex-body p scroll-browser">
    <form (ngSubmit)="save()" #newForm="ngForm" class="mb-lg">
      <div class="row form-group">
        <label class="col-md-2 col-sm-3 field-label control-label">Name*</label>
        <div class="col-md-4 col-sm-5">
          <input type="text" class="form-control" id="intake-form-name" name="intake-form-name"
                 placeholder="Enter Intake Form Template Name" [(ngModel)]="intakeFormTemplate.name" required>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-2 col-sm-3"></div>
        <div class="col-xs-6">
          <ng-container *ngIf="intakeFormTemplate.steps.length > 0">
            <div cdkDropList class="drag-list mt-lg mb-lg" (cdkDropListDropped)="drop($event)">
              <div class="holder" *ngFor="let step of intakeFormTemplate.steps; let i = index" cdkDrag>
                <div class="steps" *ngIf="!step._destroy">
                  <div class="counter">{{ i + 1 }}</div>
                  <div class="title form-control">{{ step.name }}</div>
                  <div cdkDragHandle class="step-drag-handle" tooltip="Drag to reorder">
                    <i class="icon-menu"></i>
                  </div>
                  <button type="button" class="btn btn-danger btn-outline btn-sm" (click)="removeStep(i)">
                    <i class="icon-trash fs16"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row form-group">
        <label class="col-md-2 col-sm-3 field-label control-label">Add Step*</label>
        <div class="col-md-4 col-sm-5">
          <select class="form-control" (change)="onStepSelected($event.target.value)" name="addStepDropdown">
            <option [value]="" selected>Add Step</option>
            <option *ngFor="let stepName of availableSteps" [value]="stepName">{{ stepName }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-10">
          <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i
            class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
          <ng-container *ngIf="!loaderService.uploading_in_process">
            <button type="submit" name="submit" class="btn btn-secondary btn-min mr" [disabled]="!newForm.form.valid || isButtonDisabled()">{{ intakeFormTemplate.id ? 'Update' : 'Create' }}
            </button>
            <button type="button" name="copy" class="btn btn-secondary btn-min mr" *ngIf='intakeFormTemplate.id' (click)="createCopy(intakeFormTemplate)"> Create a Copy</button>
            <button  name="cancel" class="btn btn-tertiary btn-outline btn-min" (click)="closeNewForm()">Cancel</button>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</div>
