<form class="mt-xl" (ngSubmit)="save_relative_patient()" #familyrelativeForm="ngForm">
  <div class="row">
    <div class="col-md-3 col-sm-3 col-xs-6 form-group">
      <label>Title</label>
      <select name='title' class="form-control" [(ngModel)]="patient.title">
        <option value="Mr.">Mr.</option>
        <option value="Ms.">Ms.</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-xs-12 form-group">
      <label>First Name*</label>
      <input (click)="callme();" type="text" class="form-control" id="firstnamerelative" pattern="^[a-zA-Z ]+$" [(ngModel)]="patient.user.first_name"
             name="first_name" required>
      <span *ngIf="customValidations.formErrors.first_name" class="text-danger">{{ customValidations.formErrors.first_name }}</span>
    </div>
    <div class="col-sm-6 col-xs-12 form-group">
      <label>Last Name*</label>
      <input type="text" class="form-control" id="lastnamerelative" pattern="^[a-zA-Z ]+$" [(ngModel)]="patient.user.last_name" name="last_name" required>
      <span *ngIf="customValidations.formErrors.last_name" class="text-danger">{{ customValidations.formErrors.last_name }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-xs-12 form-group">
      <label>Mother's Maiden Name*</label>
      <input type="text" class="form-control" id="mother_name" [(ngModel)]="patient.mother_name" name="mother_name" maxlength="15" pattern="[^\s]+" required>
      <span *ngIf="customValidations.formErrors.mother_name" class="text-danger">{{ customValidations.formErrors.mother_name }}</span>
    </div>
    <div class="col-sm-6 col-xs-12 form-group">
      <label>Email</label>
      <input type="email" class="form-control" id="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" [(ngModel)]="patient.user.email" name="email">
      <span *ngIf="customValidations.formErrors.email" class="text-danger">{{ customValidations.formErrors.email }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-xs-12 form-group">
      <label>Gender</label>
      <select name="patient_relative_gender" [(ngModel)]="patient.user.gender" class="form-control" id="genderelative">
        <option value="Gender" [value]="'Gender'">Select</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </select>
      <span *ngIf="gender_error" class="text-danger">Select Gender</span>
    </div>
    <div class="col-sm-6 col-xs-12 form-group">
      <label>Relationship</label>
      <select name="relationship" [(ngModel)]="patient.relationship_id" class="form-control" id="relationship">
        <option *ngFor="let relationship of relationships; let i = index;" value={{i+1}} [value]="1">{{relationship}}</option>
      </select>
    </div>
  </div>
  <div class="signup-dob">
    <app-date-of-birth class="new-patient" [dob]="patient.user.dob" [dob_stage]="patient.user.dob_stage" (dob_and_stage)="patient.handle_dob_and_dob_stage($event)"></app-date-of-birth>
    <span *ngIf="patient.year_error" class="text-danger">Invalid Date of Birth</span>
  </div>
  <div class="mt-lg">
    <button type="button" class="btn btn-primary btn-lg fs16 text-uppercase" (click)="save_relative_patient()" [disabled]="!familyrelativeForm.form.valid">
      Create Patient
    </button>
  </div>
</form>
