<footer class="bg-gray-dark">
  <div class="container">
    <div class="row">
      <div class="col-md-7 col-sm-8 col-xs-12">
        <h1 class="fw-500">Discover the only complete practice growth platform in healthcare.</h1>
      </div>
      <div class="col-md-2 hidden-sm hidden-xs"></div>
      <div class="col-md-3 col-sm-4 col-xs-12"><a class="btn btn-primary mt-xl" href="https://calendly.com/wellwink/30min" target="_blank">Request a Demo</a></div>
    </div>
  </div>
  <hr class="hr-line">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 footer-links">
        <a class="footer-logo" routerLink='/'><img src="assets/img/branding/footer_logo.png" alt="WellWink"></a>
        <p class="mt-lg mb-sm ff-open-sans-light text-white mb-xl fs13 company-info">
          WellWink, LLC is a leading technology company that specializes in providing innovative solutions for the
          healthcare industry. With a dedicated focus on patient engagement and care coordination (CCM/RPM), our mission
          is to empower medical practices and healthcare facilities to deliver exceptional patient experiences and improve
          overall health outcomes.
          <br><br>
          Through our cutting-edge patient engagement software and comprehensive suite of digital marketing, website design,
          and business process outsourcing, we enable healthcare providers to enhance their online visibility, attract
          new patients, and optimize their operations. Our team of experts is committed to delivering personalized
          solutions that meet the unique needs of each client, and our advanced technology ensures seamless integration
          and maximum efficiency.
        </p>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6 footer-links">
        <h5 class="text-white mb-xl">Our Menu</h5>
        <ul class="list-unstyled fs12">
          <li><a routerLink='/'>Home</a></li>
          <li><a routerLink='/why-wellwink'>Why WellWink?</a></li>
          <li><a routerLink='/contact-us' (click)="_userService.iAmPatient = false">Pricing</a></li>
          <li><a routerLink='/partner' (click)="_userService.iAmPatient = false">Partners</a></li>
          <li><a routerLink='/contact-us' (click)="_userService.iAmPatient = false">Contact Us</a></li>
        </ul>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6 footer-links">
        <h5 class="text-white mb-xl">Security & Privacy</h5>
        <ul class="list-unstyled fs12">
          <li><a routerLink='/p&p'>Privacy & Policy</a></li>
          <li><a routerLink='/t&c'>Terms & Conditions</a></li>
          <li><a routerLink='/GDPR'>GDPR</a></li>
        </ul>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-12 footer-links">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-6">
            <h5 class="text-white mb-xl">Contact Us</h5>
            <ul class="footer-phone list-unstyled mb-lg">
              <li>
                <a href="tel:+1-848-291-2430"><i class="fa fa-phone fa-fw"></i> +1 (848) 291-2430</a>
              </li>
              <li>
                <a href="https://wa.me/+12019490209" target="_blank"><i class="fa fa-whatsapp fa-fw"></i> +1 (201) 949-0209</a>
              </li>
            </ul>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-6">
            <h5 class="text-white">Get Social with WellWink</h5>
            <ul class="footer-social list-inline mb0">
              <li><a class="fb" href="https://web.facebook.com/wellwink/" target="_blank"><i class="fa fa-facebook"></i></a></li>
              <li><a class="twi" href="https://twitter.com/well_wink" target="_blank"><i class="fa fa-twitter"></i></a></li>
              <li><a class="lkd" href="https://www.linkedin.com/company/wellwink/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
              <li><a class="ytb" href="https://www.youtube.com/channel/UCt6DUknfqCv84VmS-xzvLLw" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row certificate-section">
      <div class="col-md-2 col-sm-4 col-xs-6">
        <div class="white-container-box">
          <img class="us-sheild" src="assets/img/public/us-sheild.png" alt="EU - U.S. Privacy Shield Certified">
          <h5>EU - U.S.</h5>
          <h5>Privacy Shield Certified</h5>
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6">
        <div class="white-container-box">
          <i class="fa fa-shield"></i>
          <h5>SSAE 16 SOC-2</h5>
          <h5>Compliant</h5>
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6">
        <div class="white-container-box">
          <i class="fa fa-lock"></i>
          <h5>256-bit AES</h5>
          <h5>Military Grade Encryption</h5>
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6">
        <div class="white-container-box">
          <i class="fa fa-amazon"></i>
          <h5>Hosted on</h5>
          <h5>Amazon Web Services</h5>
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6">
        <div class="white-container-box">
          <svg width="512" viewBox="0 0 512 512" style="max-width:30px; margin:0 0 5px">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#FFF" stroke="none">
              <path d="M2469 5107 c-236 -67 -336 -350 -192 -544 20 -28 52 -60 70 -73 l33 -23 0 -1053 c0 -580 2 -1054 4 -1054 2 0 81 25 175 55 94 30 174 55 176 55 3 0 5 200 5 444 l0 444 34 21 c36 23 107 95 125 129 10 19 14 17 73 -42 79 -79 148 -108 256 -108 110 1 212 54 278 146 l21 28 49 -37 c200 -154 531 -151 716 6 l33 28 -145 145 c-80 80 -142 146 -138 146 3 0 46 -16 96 -35 118 -46 208 -59 296 -45 127 20 225 69 309 155 53 53 113 148 103 163 -3 5 -96 46 -208 93 l-203 84 70 6 c163 15 284 67 380 164 33 33 72 86 87 117 30 62 58 164 58 216 l0 33 -657 -3 c-640 -4 -660 -5 -736 -26 -148 -41 -306 -144 -407 -264 -30 -36 -91 -125 -135 -198 -123 -203 -206 -282 -322 -305 l-33 -7 0 250 0 249 33 23 c18 13 50 45 70 73 127 171 65 423 -127 517 -74 37 -175 47 -247 27z"/>
              <path d="M90 4738 c0 -52 28 -154 58 -216 15 -31 54 -84 87 -117 96 -97 217 -149 380 -164 l70 -6 -203 -84 c-112 -47 -205 -88 -208 -93 -10 -15 50 -110 103 -163 84 -86 183 -136 311 -155 88 -14 176 0 294 45 50 19 93 35 96 35 4 0 -58 -66 -138 -146 l-145 -145 33 -28 c185 -157 516 -160 716 -6 l49 37 21 -28 c37 -51 79 -85 142 -115 130 -63 287 -30 371 78 70 89 83 150 83 391 l0 174 -49 54 c-26 30 -93 126 -147 212 -163 259 -311 383 -532 445 -74 20 -99 21 -734 25 l-658 3 0 -33z"/>
              <path d="M1780 3154 c-98 -26 -161 -65 -250 -154 -143 -142 -186 -253 -177 -460 7 -180 58 -293 194 -432 l66 -67 56 38 c91 61 148 92 244 132 l90 37 -44 22 c-108 55 -198 131 -231 193 -10 22 -18 40 -16 42 2 1 26 10 54 19 157 51 306 217 333 371 16 94 -6 163 -69 217 -65 57 -146 70 -250 42z m45 -179 c33 -32 34 -89 3 -122 -31 -33 -91 -32 -123 3 -76 82 42 198 120 119z"/>
              <path d="M3162 3152 c-94 -34 -141 -101 -142 -203 0 -110 46 -205 148 -306 50 -49 194 -133 228 -133 37 0 -11 -78 -92 -149 -83 -73 -222 -131 -679 -281 -551 -181 -593 -196 -687 -250 -198 -113 -313 -265 -340 -447 -23 -157 28 -306 149 -434 l65 -69 72 59 c75 62 185 136 217 146 29 9 24 17 -29 47 -94 53 -141 131 -125 209 14 73 136 155 331 223 l102 35 0 -190 c0 -178 1 -191 18 -185 9 4 90 39 179 79 l163 72 0 112 0 112 102 -35 c316 -110 406 -227 281 -365 -67 -74 -197 -144 -522 -279 -327 -135 -428 -190 -528 -284 -45 -42 -74 -80 -100 -133 -33 -67 -38 -86 -41 -168 -6 -123 12 -177 83 -253 67 -73 96 -89 130 -74 31 14 32 38 5 115 -49 139 1 236 164 316 35 17 66 31 70 31 3 0 6 -77 6 -171 0 -154 2 -175 21 -208 69 -124 251 -117 310 13 16 34 19 67 19 204 0 89 3 162 8 162 15 0 147 -82 175 -108 71 -66 90 -168 48 -249 -25 -50 -27 -76 -5 -97 27 -27 53 -18 113 39 79 75 105 129 109 232 7 143 -41 251 -159 364 l-59 56 47 18 c144 53 302 157 386 253 170 193 170 469 0 678 -45 55 -184 166 -223 179 -31 10 -24 21 28 40 263 99 465 286 538 500 24 70 28 95 27 195 0 96 -5 128 -26 195 -86 263 -382 459 -585 387z m253 -177 c54 -53 16 -145 -60 -145 -49 0 -85 36 -85 85 0 76 92 114 145 60z"/>
            </g>
          </svg>
          <h5>HIPAA</h5>
          <h5>Compliant</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="copy-bg fs12 container-fluid text-center">
    &copy; Copyright 2020 | All Rights Reserved | Powered by <span class="llc-text">WellWink LLC</span>
  </div>
</footer>
