import { Injectable }     from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {Note} from '../routes/practice-site/notes/note';


@Injectable()
export class NoteService {

  constructor(private _tokenService: CustomHttpClient ) { }
  create(note: Note): Promise<any> {
    return this._tokenService
      .post(note.get_url(), note.to_params())
      .toPromise()
      .then(resp => resp)
  }
  update(note: Note): Promise<any> {
    return this._tokenService
      .put(note.get_url(), note.to_params())
      .toPromise()
  }
}
