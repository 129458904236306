import { NgModule } from '@angular/core';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import appMenu from './menu';
import { ChangePasswordComponent } from './change-password/change-password.component'
import { RecoverComponent } from './recover/recover.component'
import {LeadsComponent} from './leads/leads.component'
import {routes} from './routes';
// import arr from './arr';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PermissionsModule} from './permissions/permissions.module'
import { PracticesModule }    from './practices/practices.module';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { LocationService } from '../services/location.service'
import { ContactService } from '../services/contact.service'
import { ServicesService } from '../services/services.service'
import { ConfirmationService } from '../services/confirmation.service'
import {HttpClient} from '@angular/common/http';
import { DoctorService } from '../services/doctor.service';
import { InvitationsService } from '../services/invitations.service';
import { CalendarService } from '../services/calendar.service';
import { RatingComponent } from './rating/rating.component';
import { PatientReviewsComponent } from './rating/patient-reviews/patient-reviews.component'
import { UsersComponent } from './users/users.component';
import { IndexComponent } from './users/index/index.component';
import { FilterComponent } from './users/filter/filter.component';
import { UserComponent } from './users/user/user.component'
import { UserManagementService } from '../services/user-management.service'
import { AppointmentService } from '../services/appointment.service';
import { EnterpriseAppointmentsComponent } from './enterprise-appointments/enterprise-appointments.component';
import { AppointmentListingComponent } from './enterprise-appointments/appointment-listing/appointment-listing.component'
// import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
import { AppointmentsComponent } from './enterprise-appointments/appointments/appointments.component';
import { PatientsComponent } from './patients/patients.component';
import { PatientsFilterComponent } from './patients/patients-filter/patients-filter.component';
import { PatientListingComponent } from './patients/patient-listing/patient-listing.component';
import { ReceptionComponent } from './practice-site/reception/reception.component';
import { AdmitComponent } from './practice-site/admit/admit.component';
import {CaseService} from '../services/case.service';
import { AdmissionsComponent } from './practice-site/admissions/admissions.component';
import { AdmissionsFilterComponent } from './practice-site/admissions/admissions-filter/admissions-filter.component';
import { AdmissionsListingComponent } from './practice-site/admissions/admissions-listing/admissions-listing.component';
import { TodaysPatientsFilterComponent } from './practice-site/todays-patients/todays-patients-filter/todays-patients-filter.component';
import { TodaysPatientsComponent } from './practice-site/todays-patients/todays-patients.component';
import { TodaysPatientsListingComponent } from './practice-site/todays-patients/todays-patients-listing/todays-patients-listing.component';
import { WalkInComponent } from './practice-site/todays-patients/walk-in/walk-in.component';
import { StatusActionsComponent } from './practice-site/todays-patients/status-actions/status-actions.component';
import { NotesComponent } from './practice-site/notes/notes.component';
import { CheckInComponent } from './practice-site/todays-patients/status-actions/check-in/check-in.component';
import { ConfirmComponent } from './practice-site/todays-patients/status-actions/confirm/confirm.component';
import { RescheduleComponent } from './practice-site/todays-patients/status-actions/reschedule/reschedule.component';
import { CancelComponent } from './practice-site/todays-patients/status-actions/cancel/cancel.component';
import { NoshowComponent } from './practice-site/todays-patients/status-actions/noshow/noshow.component';
import { HistoryComponent } from './practice-site/todays-patients/status-actions/history/history.component';
import { ScanComponent } from './practice-site/todays-patients/status-actions/scan/scan.component';
import { UploadComponent } from './practice-site/todays-patients/status-actions/upload/upload.component';
import { CheckoutComponent } from './practice-site/todays-patients/status-actions/checkout/checkout.component';
import { PrintEncounterComponent } from './practice-site/todays-patients/status-actions/print-encounter/print-encounter.component';
import {NoteService} from '../services/note.service';
import { NoteComponent } from './practice-site/notes/note/note.component';
import { PaymentComponent } from './practice-site/payment/payment.component';
import { PaymentInvoiceComponent } from './practice-site/payment/payment-invoice/payment-invoice.component';
import {PaymentService} from '../services/payment.service';
import {ScanService} from '../services/scan.service';
import {DragulaModule, DragulaService} from 'ng2-dragula';
import {PaymentHeaderComponent} from './practice-site/payment/payment-header/payment-header.component';
import { UpdateTotalBalanceDirective } from './practice-site/payment/update-total-balance.directive';
import { DischargeComponent } from './practice-site/admissions/discharge/discharge.component';
import {CaseFeedBackService} from '../services/case-feed-back.service';
import {MessageService} from '../services/message.service';
import { ChatListingComponent } from './practice-site/chat/chat-listing/chat-listing.component';
import { ChatDetailComponent } from './practice-site/chat/chat-detail/chat-detail.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { AnnouncementsFilterComponent } from './announcements/announcements-filter/announcements-filter.component';
import { AnnouncementsListingComponent } from './announcements/announcements-listing/announcements-listing.component';
import { AnnouncementComponent } from './announcements/announcement/announcement.component';
import {AnnouncementsService} from '../services/announcements.service';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { ChatComponent } from './practice-site/chat/chat.component';
import { FileUploadModule } from 'ng2-file-upload';
import {UppercasrDirective} from '../uppercasr.directive';
import {EqualValidator} from '../directives/equal-validator.directive';
import {OffsidebarComponent} from '../layout/offsidebar/offsidebar.component';
import {ModalComponent} from '../modal/modal.component';
import {RedComponentComponent} from './red-component/red-component.component';
import { PracticeDashboardComponent } from './practice-dashboard/practice-dashboard.component';
import {DashboardsService} from '../services/dashboards.service';
import { ChartsModule } from 'ng2-charts';
import { PracticeProfileComponent } from './practice-profile/practice-profile.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { HtmlComponent } from './html/html.component';
import { VisitsComponent } from './practice-site/visits/visits.component';
import { VisitsFiltersComponent } from './practice-site/visits/visits-filters/visits-filters.component';
import { VisitsListingComponent } from './practice-site/visits/visits-listing/visits-listing.component';
import { PrintInflowComponent } from './practice-site/visits/print-inflow/print-inflow.component';
import { FilteredStatusCountComponent } from './practice-site/visits/filtered-status-count/filtered-status-count.component';
import { EmergencyComponent } from './practice-site/emergency/emergency.component';
import { EmergencyFiltersComponent } from './practice-site/emergency/emergency-filters/emergency-filters.component';
import { EmergencyListingComponent } from './practice-site/emergency/emergency-listing/emergency-listing.component';
import { EmergencyService } from '../services/emergency.service';
import { PchfService } from '../services/pchf.service';
import { ArriveComponent } from './practice-site/emergency/arrive/arrive.component';
import { EmergencyStatusesComponent } from './practice-site/todays-patients/emergency-statuses/emergency-statuses.component';
import { VitalComponent } from './practice-site/vital/vital.component';
import { VitalPdfPrinterComponent } from './practice-site/vital/vital-pdf-printer/vital-pdf-printer.component';
import { EnoteModule } from '../enote/enote.module';
import { LibraryImporterComponent } from './library-importer/library-importer.component';
import {LibraryImportService} from '../services/library-import.service';
import { LibrariesComponent } from './library-importer/libraries/libraries.component';
import {NgxMaskModule} from 'ngx-mask';
import { ChatUsersComponent } from './practice-site/chat/chat-users/chat-users.component';
import { RightPanelComponent } from './practice-site/chat/right-panel/right-panel.component';
import { InputComponent } from './practice-site/chat/input/input.component'
import { SinglePaymentComponent } from './practice-site/payment/single-payment/single-payment.component'
import { TextMessageComponent } from './practice-site/chat/text-message/text-message.component';
import { ContactVerificationComponent } from './practice-site/chat/contact-verification/contact-verification.component';
import { PatientDocumentsComponent } from './practice-site/chat/patient-documents/patient-documents.component'
// import {IntegratedVendorComponent} from "../integration/integrated-vendor/integrated-vendor.component";
import {IntegrationModule} from '../integration/integration.module';
import {CommentService} from './practice-site/tasks/comment.service';
import { CommentComponent } from './practice-site/tasks/comment/comment.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {NgSelectModule} from '@ng-select/ng-select';
import { SchedulerComponent } from './practice-site/scheduler/scheduler.component';
import { SchedulerDashboardComponent } from './practice-site/scheduler/scheduler-dashboard/scheduler-dashboard.component';
import { CalendarViewComponent } from './practice-site/scheduler/calendar-view/calendar-view.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarHeaderComponent } from './practice-site/scheduler/calendar-header/calendar-header.component';
import {TeleMedicineComponent} from './practice-site/tele-medicine/tele-medicine.component';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TasksModule} from './practice-site/tasks/tasks/tasks.module';
import {AddNewPatientComponent} from '../health/patient-profile/add-new-patient/add-new-patient.component';
import { NoteComponentComponent } from './note-component/note-component.component';
import { NoteComponentsComponent } from './note-component/note-components/note-components.component';
import { NoteComponentsListingComponent } from './note-component/note-components/note-componets-listing/note-components-listing.component';
import { AlertTemplateIndexComponent } from './alert-template-index/alert-template-index.component';
import { AlertTemplatesComponent } from './alert-template-index/alert-templates/alert-templates.component';
import { AlertTemplateComponent } from './alert-template-index/alert-template/alert-template.component';
import {ColorPickerModule} from 'ngx-color-picker';
import { AlertTemplateRuleComponent } from './alert-template-index/alert-template-rule/alert-template-rule.component';
import { AlertTemplateInterventionComponent } from './alert-template-index/alert-template-intervention/alert-template-intervention.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { RichTextTemplateIndexComponent } from './rich-text-template-index/rich-text-template-index.component';
import { RichTextTemplateComponent } from './rich-text-template-index/rich-text-template/rich-text-template.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { UserProfileSettingsComponent } from './user-profile-settings/user-profile-settings.component'
import {NgxImageZoomModule} from 'ngx-image-zoom';
import { SmsComponent } from './practice-site/sms/sms.component';
import { SmsListingComponent } from './practice-site/sms/sms-listing/sms-listing.component';
import { SmsDetailsComponent } from './practice-site/sms/sms-details/sms-details.component';
import { IntakeFormTemplateIndexComponent } from './intake-form-template-index/intake-form-template-index.component';
import { IntakeFormTemplatesComponent } from './intake-form-template-index/intake-form-templates/intake-form-templates.component';
import { IntakeFormTemplateComponent } from './intake-form-template-index/intake-form-template/intake-form-template.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PatientIntakeFormIndexComponent } from './practice-site/patient-intake-form-index/patient-intake-form-index.component';
import { PatientIntakeFormsComponent } from './practice-site/patient-intake-form-index/patient-intake-forms/patient-intake-forms.component';
import {CommonModule} from '@angular/common';
import { IntakeFormReportComponent } from './practice-site/patient-intake-form-index/intake-form-report/intake-form-report.component';


@NgModule({
    imports: [
        FormsModule, NgxMaskModule.forRoot(), IntegrationModule, TasksModule, GoogleMapsModule,
        MultiselectDropdownModule, DragulaModule,
        RoundProgressModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        SharedModule, PracticesModule, PermissionsModule, BsDropdownModule, EnoteModule,
        ChartsModule, RouterModule.forRoot(routes), FileUploadModule, BsDatepickerModule, NgSelectModule,
        AngularMultiSelectModule, MatPasswordStrengthModule, MatSlideToggleModule, ColorPickerModule, EditorModule,
        NgxImageZoomModule, DragDropModule, TooltipModule, CommonModule
    ],
  declarations: [
    LeadsComponent, NotesComponent,
    DashboardComponent, ChangePasswordComponent, RecoverComponent,
    RatingComponent, PatientReviewsComponent, UsersComponent,
    IndexComponent, FilterComponent, UserComponent,
    EnterpriseAppointmentsComponent, AppointmentListingComponent,
    AppointmentsComponent, PatientsComponent, PatientsFilterComponent,
    PatientListingComponent, ReceptionComponent, AdmitComponent,
    AdmissionsComponent, AdmissionsFilterComponent, AdmissionsListingComponent,
    TodaysPatientsFilterComponent, TodaysPatientsComponent,
    TodaysPatientsListingComponent, WalkInComponent, StatusActionsComponent,
    CheckInComponent, ConfirmComponent, RescheduleComponent,
    CancelComponent, NoshowComponent, HistoryComponent,
    ScanComponent, UploadComponent, CheckoutComponent,
    PrintEncounterComponent, NoteComponent,
    PaymentComponent, PaymentHeaderComponent, PaymentInvoiceComponent, UpdateTotalBalanceDirective,
    DischargeComponent, ChatComponent, ChatListingComponent, ChatDetailComponent, AnnouncementsComponent,
    AnnouncementsFilterComponent, TeleMedicineComponent, AddNewPatientComponent,
    AnnouncementsListingComponent, AnnouncementComponent, UppercasrDirective, EqualValidator,
    RedComponentComponent, OffsidebarComponent, ModalComponent, PracticeDashboardComponent,
    PracticeProfileComponent, HtmlComponent, VisitsComponent, VisitsFiltersComponent, VisitsListingComponent,
    PrintInflowComponent, FilteredStatusCountComponent, EmergencyComponent, EmergencyFiltersComponent,
    EmergencyListingComponent, ArriveComponent, EmergencyStatusesComponent, VitalComponent, TeleMedicineComponent,
    VitalPdfPrinterComponent, LibraryImporterComponent, LibraryImporterComponent, LibrariesComponent, ChatUsersComponent, RightPanelComponent, InputComponent, SinglePaymentComponent, TextMessageComponent, ContactVerificationComponent, PatientDocumentsComponent, SchedulerComponent, SchedulerDashboardComponent, CalendarViewComponent, CalendarHeaderComponent, NoteComponentComponent, NoteComponentsComponent, NoteComponentsListingComponent, AlertTemplateIndexComponent, AlertTemplatesComponent, AlertTemplateComponent, AlertTemplateRuleComponent, AlertTemplateInterventionComponent, SetPasswordComponent, RichTextTemplateIndexComponent, RichTextTemplateComponent, UserProfileSettingsComponent, SmsComponent, SmsListingComponent, SmsDetailsComponent, IntakeFormTemplateIndexComponent, IntakeFormTemplatesComponent, IntakeFormTemplateComponent, PatientIntakeFormIndexComponent, PatientIntakeFormsComponent, IntakeFormReportComponent
  ],
  providers: [
    PaymentService, NoteService , LibraryImportService,
    CaseService, AppointmentService,
    UserManagementService, CalendarService,
    ConfirmationService, InvitationsService,
    ServicesService, LocationService,
    ContactService, HttpClient,
    DoctorService, ScanService, CaseFeedBackService,
    AnnouncementsService, DashboardsService, EmergencyService, PchfService,
    MessageService, DragulaService
  ],
  exports: [
    MultiselectDropdownModule, RouterModule, LeadsComponent,
    PracticesModule, AddNewPatientComponent, PermissionsModule, FileUploadModule, ModalComponent,
    RedComponentComponent, AdmitComponent, ChatComponent, ChatListingComponent, ChatDetailComponent,
    HtmlComponent, AlertTemplateInterventionComponent, SmsDetailsComponent
  ]
})

export class RoutesModule {
  constructor(private menu: MenuService) {
    menu.add_group_menu(appMenu);
  }
}
