import {Component, ContentChild, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Tab} from '../../tabs/tab';
import {ChatComponent} from '../../routes/practice-site/chat/chat.component';
import {TabsService} from '../../services/tabs.service';
import {NewtaskComponent} from '../../routes/practice-site/tasks/newtask/newtask.component';
import {Task} from '../../routes/practice-site/tasks/newtask/task';
import {environment} from '../../../environments/environment';
import {LoaderService} from '../../services/loader.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {UserService} from '../../services/user.service';
import {PatientCommunicationService} from './patient-communication.service';
import {PatientNotes} from '../../health/patient-profile/patient-dashboard/patient-communication-log/patient-notes/patient-notes';
import {NewPatientNotesComponent} from '../../health/patient-profile/patient-dashboard/patient-communication-log/patient-notes/new-patient-notes/new-patient-notes.component';
import {Patient} from '../../health/doctor/patient';
import {VideoChatService} from '../../video-chat/services/videochat.service';
import {EnrolmentsService} from '../../program/enrolments.service';
import {PhoneService} from '../../voice-call/phone.service';
import {TrackingService} from '../../services/tracking.service';
import {PatientNotesService} from '../../health/patient-profile/patient-dashboard/patient-communication-log/patient-notes/patient-notes.service';
import {Subscription} from 'rxjs/index';
import {TaskService} from '../../services/task.service';
import {AlertService} from '../../_alert';
import {RichTextTemplate} from '../../routes/rich-text-template-index/rich-text-template';

interface SmtpAssociatePracticeEmail {
  id: number;
  name: string;
}

@Component({
  selector: 'app-patient-communication',
  templateUrl: './patient-communication.component.html',
  styleUrls: ['./patient-communication.component.scss']
})
export class PatientCommunicationComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;
  @Input() sourceable_id;
  @Input() sourceable_type;
  @Input() programme_type;
  @Input() isPatientDetailPage = false;
  public editor;
  temp_email_body;
  temp_subject;
  temp_sms_body;
  show_email_msg = false;
  show_sms_msg = false;
  public smtp_associate_practice_emails: SmtpAssociatePracticeEmail[] = [];
  public selected_smtp_practice_id;
  public actionId;
  custom_injector: Injector;
  newNoteComponent = NewPatientNotesComponent;
  newTaskComponent = NewtaskComponent;

  public action;
  private subscription: Subscription;
  private newTasksubscription: Subscription;
  @ViewChild('emailModel') public emailModel;
  @ViewChild('smsModel') public smsModel;
  @ViewChild('newNoteModel') newNoteModel;
  @ViewChild('newTaskModal') newTaskModel;


  public  init_tiny: any;
  phone_call = false;
  public show_node_model = false;
  public show_task_model = false;
  public source_id_for_phone_call: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(public patientCommunicationService: PatientCommunicationService, public userService: UserService,
              public tabs_service: TabsService, public loaderService: LoaderService, public alertService: AlertService,
              public phoneService: PhoneService, public taskService: TaskService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public injector: Injector,
              public videoChatService: VideoChatService, public enrolmentsService: EnrolmentsService,
              public trackingService: TrackingService, public patientNotesService: PatientNotesService) {
    if (this.userService.current_user.isDoctor) {
      this.patientCommunicationService.getSmtpPracticeEmails()
        .subscribe(
          resp => {
            this.smtp_associate_practice_emails = resp;
            this.selected_smtp_practice_id = resp[0]['id'];
          },
          error => console.log(error)
        );
    };
    this.subscription = this.patientNotesService.closeNewNoteForm.subscribe( resp => {
      if (resp) {
        this.newNoteModel.hide();
        this.show_node_model = false;
      }
    });
    this.newTasksubscription = this.taskService.closeNewTaskForm.subscribe( resp => {
      if (resp) {
        this.newTaskModel.hide();
        this.show_task_model = false;
      }
    });
  }

  ngOnInit(): void {
    this.init_tiny = {
      base_url: environment.tinymce.theme_url,
      suffix: '.min',
      height: 300,
      relative_urls : false,
      remove_script_host : false,
      convert_urls : true,
      menubar: false,
      branding: false,
      toolbar_mode: 'sliding',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'image',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
        'insertfile undo redo numlist outdent | bullist indent |contextmenu table | uploadimage media | \ ' +
        'link image forecolor backcolor hr charmap | print search searchreplace',
      setup: editor => {
        this.editor = editor;
      },
    }
    this.source_id_for_phone_call =  this.sourceable_type === 'ProgrammeEnrolment' ? this.sourceable_id : '';
  }

  send_message(patient) {
    let id;
    if (patient.user_id) {
      id = patient.user_id;
    } else {
      id = patient.user.id;
    }
    this.tabs_service.add_tab(new Tab({title: patient.name, icon: 'fa'},
      ChatComponent , id, {id: id }))
  }

  /*  addTasktab(patient) {
      let task = new Task();
      task.temp_activity_id = this.trackingService.startTracking('task');
      task.patient = patient;
      task.patient_id = patient.id;
      this.tabs_service.add_tab(new Tab({title: 'Add Task', icon: 'fa'}, NewtaskComponent, task.id,
        {id: task.id, task: task, source_type: this.sourceable_type, source_id: this.sourceable_id}))
    }*/
  addNewTaskInPopup(patient) {
    let task = new Task();
    task.patient = patient;
    task.patient_id = patient.id;
    this.setTaskCustomInjector(task);
  }
  setTaskCustomInjector(task: Task) {
    this.custom_injector  = Injector.create([
      {provide: 'task', useValue: task},
      {provide: 'id', useValue:  task.id},
      {provide: 'source_type', useValue:  this.isPatientDetailPage ? '' : this.sourceable_type},
      {provide: 'source_id', useValue:  this.isPatientDetailPage ? '' : this.sourceable_id},
      {provide: 'add_patient_button', useValue:  false},
    ], this.injector);
    this.show_task_model = true;
    this.newTaskModel.show();
  }
  addNewNoteInPopup(patient) {
    let patient_note = new PatientNotes();
    if (this.sourceable_type === 'ProgrammeEnrolment') {
      patient_note.temp_activity_id = this.trackingService.startTracking('patient_note');
    }
    this.setNoteCustomInjector(patient_note);
  }
  addNotestab(patient) {
    let patient_note = new PatientNotes();
    this.tabs_service.add_tab(new Tab({title: 'Add Note', icon: 'fa'}, NewPatientNotesComponent,
      patient_note.id, {id: patient_note.id, patient_note: patient_note,
        patient_id: this.patient.id, source_type: this.sourceable_type, source_id: this.sourceable_id }))
  }
  setNoteCustomInjector(note: PatientNotes) {
    this.custom_injector  = Injector.create([
      {provide: 'patient_note', useValue: note},
      {provide: 'id', useValue:  note.id},
      {provide: 'patient_id', useValue:  this.patient.id},
      {provide: 'source_type', useValue:  this.sourceable_type},
      {provide: 'source_id', useValue:  this.sourceable_id},
      {provide: 'programme_type', useValue:  this.programme_type},
    ], this.injector);
    this.show_node_model = true;
    this.newNoteModel.show();
  }

  sendEmail() {
    this.loaderService.show();
    this.patientCommunicationService.testRun('email', this.patient, this.temp_email_body, this.temp_subject,
      this.selected_smtp_practice_id, this.sourceable_type, this.sourceable_id).then(resp => {
        console.log(resp);
        if (this.sourceable_type) {
          this.stopAction(this.temp_subject, 'email');
        }
        this.temp_email_body = '';
        this.temp_subject = '';
        this.emailModel.hide();
        this.loaderService.hide();
        this.alertService.success('eMail has been sent successfully', this.options);
        this.enrolmentsService.reloadActivityList.next(1);
      }
    ).catch(resp => {
      console.log(resp)
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.message;
    })
  }


  sendSms() {
    if (!this.userService.user.twilio_configurations.sms) {
      return;
    }
    this.loaderService.show();
    this.patientCommunicationService.testRun('sms', this.patient, this.temp_sms_body, '',
      '', this.sourceable_type, this.sourceable_id).then(resp => {
        console.log(resp);
        if (this.sourceable_type) {
          this.stopAction(this.temp_sms_body, 'sms');
        }
        this.temp_sms_body = '';
        this.smsModel.hide();
        this.loaderService.hide();
        this.alertService.success('SMS has been sent successfully', this.options);
        this.enrolmentsService.reloadActivityList.next(1);
      }
    ).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  addVideoToTab(patient) {
    this.videoChatService.start_call_message = true;
    this.videoChatService.showLayoutVideo = false;
    if (this.sourceable_type === 'ProgrammeEnrolment') {
      this.videoChatService.source_type = this.sourceable_type;
      this.videoChatService.source_id = this.sourceable_id;
    }
    setTimeout(() => {
      this.videoChatService.patient_user_id = this.patient.parent_user_id || this.patient.user.id;
      this.videoChatService.patient_full_name = this.patient.name;
      this.videoChatService.patient_profile_pic = this.patient.user.profile_pic
      this.videoChatService.mobile_online_status = this.patient.mobile_online_status;
      this.videoChatService.portal_online_status = this.patient.portal_online_status;
      this.videoChatService.showLayoutVideo = true;
      this.videoChatService.showSmallWindow = false;
    }, 300)
  }

  startAction(action) {
    this.actionId = this.trackingService.startTracking(action);
  }

  stopAction(description: any, activity_type: any) {
    const trackingResult = this.trackingService.stopTracking(this.actionId);
    this.trackingService.logTimeForPatientActivity({duration: trackingResult.duration, description: description,
      activity_type: activity_type, source_id: this.sourceable_id, activiable_id: this.sourceable_id, programme_type: this.programme_type})
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.newTasksubscription.unsubscribe();
  }

  embedEmailBody(template: RichTextTemplate) {
    this.temp_email_body = template.body;
  }

  embedSmsBody(template: RichTextTemplate) {
    this.temp_sms_body = template.body
  }
}
