<ul class="list-unstyled border-list" *ngIf="staffs">
  <li class="list" *ngFor="let staff of staffs;let i = index">
    <h4 class="m0">{{staff.name}}</h4>
    <h5 class="fw-400 mt-sm text-secondary">{{staff.designation}}</h5>
    <span class="pr"><em class="fa fa-map-marker"></em>&nbsp; {{staff.address.humanize()}}</span>
    <span class="pr"><em class="fa fa-phone"></em>&nbsp; {{userService.showNumber('phone', staff.contact_numbers)}}</span>
    <span class="pr"><em class="fa fa-fax" *ngIf="userService.showNumber('fax', staff.contact_numbers)"></em>&nbsp; {{userService.showNumber('fax', staff.contact_numbers)}}</span>
    <span><em class="fa fa-envelope"></em> &nbsp;{{staff.email}}</span>
  </li>
</ul>
