<div *ngIf="practice && service" class="panel panel-default">
  <div class="panel-heading"><h3 class="panel-title fs20">Add Service</h3></div>
  <div class="panel-body">
    <div class="col-sm-8 p0">
      <form (ngSubmit)="save()" #serviceForm="ngForm">
        <div class="row">
          <div class="col-xs-6">
            <div class="row form-group">
              <label class="col-xs-4 pt-sm">Name*:</label>
              <div class="col-xs-8">
                <input name="name" required type="text" class="form-control" id="name" [(ngModel)]="service.name" placeholder="Name" #name="ngModel">
                <span *ngIf="customValidations.formErrors.name" class="text-danger">{{ customValidations.formErrors.name }}</span>
              </div>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="row form-group">
              <label class="col-xs-2 pt-sm">Service:</label>
              <div class="col-xs-10">
                <input  name="service" [(ngModel)]="service.service.name"
                        [typeahead]="services" typeaheadWaitMs="500"
                        (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                        [typeaheadOptionField]="'description'" placeholder="Specialty"
                        class="form-control" autocomplete="off">
                <span *ngIf="customValidations.formErrors.service" class="text-danger">{{ customValidations.formErrors.service }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-xs-2 pt-sm">Description*:</label>
          <div class="col-xs-10">
            <textarea name="description" required rows="4" cols="50" type="text" class="form-control" id="description"
                        [(ngModel)]="service.description"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="Description"></textarea>
            <span *ngIf="customValidations.formErrors.description" class="text-danger">{{ customValidations.formErrors.description }}</span>
          </div>
        </div>
        <div class="row form-group">
          <label class="col-xs-2 pt-sm">Technicians:</label>
          <div class="col-xs-10">
            <!--<ng-select class="multi-select" [multiple]="true" [active]="getPreselectedTechnicians()"  #recipientsInput
            (selected)="selected_service_technician($event)" (removed)="removed_service_technician($event)"
                       (typed)="typedServiceTechnicians($event)" placeholder="No service selected"></ng-select>-->
          </div>
        </div>
        <div class="row">
          <label class="col-xs-2"></label>
          <div class="col-xs-10">
            <button type="submit" class="btn btn-primary mr-sm" [disabled]="!serviceForm.form.valid">Submit</button>
            <button class="btn btn-inverse" type="button" (click)="cancel()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
