<ng-container *ngIf="practice && practice.custom_loaded && !showPayment">
  <div class="container" *ngIf="!practice.id && !show_success">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <h3 class="text-primary">Register with us and get access to wellwink.com</h3>
        <h5 class="text-muted">Enter your name, practice name, email and password</h5>
        <div class="white-container-box p-xl mt-xl mb-xl">
          <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="save()" #registerPracticeForm="ngForm">
            <div class="row">
              <div class="col-md-2 col-sm-3 col-xs-6 form-group">
                <label>Title*</label>
                <select name='title' class="form-control" [(ngModel)]="practice.requester_title">
                  <option value="Dr.">Dr.</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Miss">Miss</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Esq.">Esq.</option>
                  <option value="Mx.">Mx.</option>
                  <option value="Sir">Sir</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 form-group">
                <label>First name*</label>
                <input type="text" class="form-control" [(ngModel)]="practice.requester_firstname" name="firstname"
                       maxlength="20" required>
                <p class="text-danger"
                   *ngIf="customValidations.formErrors.firstname">{{ customValidations.formErrors.firstname }}</p>
              </div>
              <div class="col-sm-6 form-group">
                <label>Last name*</label>
                <input type="text" class="form-control" [(ngModel)]="practice.request_lastname" name="lastname"
                       maxlength="20" required>
                <p class="m0 text-danger"
                   *ngIf="customValidations.formErrors.lastname">{{ customValidations.formErrors.lastname }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 form-group">
                <label>Name of your practice*</label>
                <input type="text" class="form-control" [(ngModel)]="practice.name" name="name" required>
                <p class="text-danger"
                   *ngIf="customValidations.formErrors.name">{{ customValidations.formErrors.name }}</p>
              </div>
              <div class="col-sm-6 form-group">
                <label>Number of Doctors*</label>
                <input type="number" class="form-control" name="no_of_doctors" [(ngModel)]="practice.no_of_doctors"
                       required>
                <p class="text-danger"
                   *ngIf="customValidations.formErrors.no_of_doctors">{{ customValidations.formErrors.no_of_doctors }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 form-group">
                <label>Contact number*</label>
                <input mask="(000) 000-0000" type="text" class="form-control" [(ngModel)]="practice.phone.value"
                       name="phone" required>
                <p class="text-danger"
                   *ngIf="customValidations.formErrors.phone">{{ customValidations.formErrors.phone }}</p>
              </div>
              <div class="col-sm-6 form-group">
                <label>Email address*</label>
                <input type="email" class="form-control" [(ngModel)]="practice.client.email"
                       name="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" required>
                <p class="text-danger"
                   *ngIf="customValidations.formErrors.email">{{ customValidations.formErrors.email }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 form-group">
                <label>Password*</label>
                <input id="password" matInput #password type="password" class="form-control"
                       [(ngModel)]="practice.password" name="password" maxlength="25" minlength="8" required>
                <mat-password-strength #passwordComponent (onStrengthChanged)="checkPasswordStrength($event)"
                                       [password]="password.value">
                </mat-password-strength>
                <mat-slide-toggle [color]="'primary'" #toggleInfo>Show Password Details</mat-slide-toggle>
                <mat-password-strength-info *ngIf="toggleInfo.checked"
                                            [lowerCaseCriteriaMsg]="'At least one lowercase character.'"
                                            [upperCaseCriteriaMsg]="'At least one uppercase character.'"
                                            [digitsCriteriaMsg]="'At least one digit character.'"
                                            [specialCharsCriteriaMsg]="'At least one special character.'"
                                            [minCharsCriteriaMsg]="'At least 8 characters.'"
                                            [enableScoreInfo]="true"
                                            [passwordComponent]="passwordComponent">
                </mat-password-strength-info>
              </div>
              <div class="col-sm-6 form-group">
                <label>Confirm Password*</label>
                <input id="password_confirmation" type="password" class="form-control"
                       [(ngModel)]="practice.password_confirmation" name="password_confirmation" required>
                <div *ngIf="password_mismatch" class="text-danger">Password does not match</div>
              </div>
            </div>
            <hr class="hr-line mb0">
            <p class="fs12 text-muted">
              *Your security is important to us. We encrypt, safeguard and secure your personal information and never
              share without permission. Please view our
              <a href="/p&p" class="btn-link" target="_blank">Privacy Policy</a>
              and
              <a href="/t&c" class="btn-link" target="_blank">Terms of Use</a>
              for more information.
            </p>

            <p class="fs12 text-muted">
              *By signing up with us, you agree with our End-user License Agreement (
              <a href="/wellwink_eula.pdf" class="btn-link" target="_blank">EULA</a>
              ).
            </p>

            <p>
              <re-captcha [(ngModel)]="declarativeFormCaptchaValue" name="captcha" required
                          (resolved)="addTokenLog('Declarative form mode resolved', $event)">
              </re-captcha>
            </p>
            <button type="submit" class="btn btn-primary btn-lg fs20" [disabled]="!registerPracticeForm.form.valid">
              Continue
            </button>
            <p class="m0" *ngIf="show_message">{{this.message}}</p>
          </form>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 patient-banner hidden-sm hidden-xs">
        <img class="img-responsive" style="margin-top:-8px;" src="assets/img/patient-banner-img.png"/>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="show_success">
    <div class="row">
      <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <div class="white-container-box p-xl mt-xl mb-xl">
          <div class="media">
            <div class="media-left"><i class="fa fa-check-circle fa-5x text-success"></i></div>
            <div class="media-right">
              <h4>{{this.package_message[message_for]}}</h4>
            </div>
          </div>
          <hr class="hr-line">
          <a
            *ngIf="userService.user_logged_in && userService.current_user && userService.current_user?.isPractice || userService.current_user?.isDoctor"
            [routerLink]="'/enterprise'" class="btn btn-primary btn-lg fs20">Continue</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showPayment">
  <app-braintree-payment [payment]="true" [pre_package_type]="p_package_name" [package_type]="package_name"
                         [practice]="practice"
                         (paymentSuccessful)="onPaymentSuccesfull($event)"></app-braintree-payment>
</ng-container>



