import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from './nullRemover';
@Injectable()
export class SlotsService {
  constructor(private _tokenService: CustomHttpClient) { }
  loadSlots(search_params) {
    let params = new HttpParams({fromObject: nullRemover(search_params)});
    return this._tokenService.get('/search/slots', { params: params}).
    map((r) => r);
  }
  loadVirtualSlots(search_params) {
    let params = new HttpParams({fromObject: search_params});
    return this._tokenService.get('/search/video_consultants', { params: params}).
    map((r) => r);
  }
}
