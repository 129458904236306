import {Address, Specialty, ContactNumber, Practice} from '../practices/practice'
import {BaseModel} from '../../../shared/base';
import {Attachment} from '../attachments/attachment';
import {Calendar, BlockCalendar} from '../doctors/doctor';
import {PublicReviewLink} from '../../../reviews/public_review_link';

export class Location extends BaseModel {
  show_marker_info = false;
  id: number;
  name: string;
  time_zone: string = null;
  time_zone_offset: string = null;
  email: string;
  message: string;
  lat: number;
  long: number;
  distance: number;
  practice_name: string;
  practice_number: string;
  is_bookable = true;
  practice_id: number;
  operational_hours: string;
  address: Address = new Address();
  specialties: Specialty[] = [new Specialty()];
  attachments: Attachment[] = [new Attachment()];
  contact_numbers: ContactNumber[] = [new ContactNumber('', 'phone'), new ContactNumber('', 'fax')];
  is_active: boolean;
  third_party_location_id: string;
  third_party_resource_id: string;
  is_calendarable: boolean;
  auto_acknowledge: boolean;
  show_profile_detail = false;
  calendars: Calendar[] = [];
  block_calendars: BlockCalendar[] = [];
  public_review_links: PublicReviewLink[] = [];
  can_delete = false;

  toggle_view() {
    this.show_profile_detail = !this.show_profile_detail;
  }

  to_params() {
    return {
      location: {
        id: this.id,
        reminder_status: this.reminder_status,
        name: this.name,
        time_zone: this.time_zone,
        is_active: this.is_active,
        email: this.email,
        is_bookable: this.is_bookable,
        message: this.message,
        practice_id: this.practice_id,
        operational_hours: this.operational_hours,
        address_attributes: this.address.to_params(),
        specialty_associations_attributes: this.specialties,
        contact_numbers_attributes: this.contact_numbers,
        third_party_location_id: this.third_party_location_id,
        third_party_resource_id: this.third_party_resource_id,
        is_calendarable: this.is_calendarable,
        auto_acknowledge: this.auto_acknowledge,
        public_review_links_attributes: this.public_review_links.map(l => l.to_params())

      }
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.reminder_status = json.reminder_status;
    this.name = json.name;
    this.can_delete = json.can_delete;
    if (json.time_zone) {
    this.time_zone = json.time_zone;
    }
    this.time_zone_offset = json.time_zone_offset;
    this.lat = json.lat;
    this.long = json.long;
    this.email = json.email;
    this.is_active = json.is_active;
    this.message = json.message;
    this.practice_number = json.practice_number;
    this.practice_name = json.practice_name;
    this.practice_id = json.practice_id;
    this.operational_hours = json.operational_hours;
    this.distance =  json.mdistance;
    this.is_bookable =  json.is_bookable;
    if (json.third_party_location_id) {
      this.third_party_location_id = json.third_party_location_id;
    }
    if (json.third_party_resource_id) {
      this.third_party_resource_id = json.third_party_resource_id;
    }
    if (json.address) {
    this.address.load_from_json(json.address);
    }
    if (json.contact_numbers) {
      let contact_numbers = json.contact_numbers.map(c_number => new ContactNumber('', '').load_from_json(c_number));
      this.contact_numbers = contact_numbers;
    }
    if (json.specialties && json.specialties.length > 0) {
      let specialties = json.specialties.map(specialty => new Specialty().load_from_json(specialty));
      this.specialties = specialties;
    }
    if (json.attachments) {
      let attachments = json.attachments.filter(e => e.document_url != '').map(attachment => new Attachment().load_from_json(attachment));
      this.attachments = attachments;
    }
    this.is_calendarable = json.is_calendarable;
    this.auto_acknowledge = json.auto_acknowledge;
    if (json.calendars) {
      this.calendars = json.calendars.map(calendar => new Calendar().load_from_json(calendar));
    }
    if (json.block_calendars) {
      this.block_calendars = json.block_calendars.map(block_calendar => new BlockCalendar().load_from_json(block_calendar));
    }
    if (json.public_review_links) {
      this.public_review_links = json.public_review_links.map(l => new PublicReviewLink().load_from_json(l))
    }
    return this;
  }

  get phone(): any {
    return this.contact_numbers.filter(c => c.type == 'phone')[0]
  }

  set phone(value: any) {
    this.phone.value = value;
  }

  get phone_value() {
    return this.phone && this.phone.value;
  }
  get fax(): any {
    return this.contact_numbers.filter(c => c.type == 'fax')[0]
  }

  set fax(value: any) {
    this.fax.value = value;
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }

  is_persisted() {
    return  !this.is_new_record();
  }

  get_url(): string {
    return this.is_new_record() ? 'locations/' : ['locations', this.id].join('/')
  }

  get complete_address() {
    return this.address.humanize();
  }

  toggle_info_window() {
    this.show_marker_info = !this.show_marker_info;
  }

  get marker_opacity() {
    return this.show_marker_info ? 1.0 : 0.5
  }


}
