import {Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import { Observable, of } from 'rxjs';
import {EnoteService} from '../../services/enote.service';
import {Control} from '../enote_models/control';
import {TermControl} from '../enote_models/term_control';

@Component({
  selector: 'app-controls-searchable',
  templateUrl: './controls-searchable.component.html',
  styleUrls: ['./controls-searchable.component.scss']
})
export class ControlsSearchableComponent implements OnInit, AfterViewInit {

  @ViewChild('typeahead') typeahead: ElementRef;
  @Input() term: any;
  public dataSource: Observable<Control[]>;
  public token = '';
  public custom_controls: Control[];
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  @ViewChild('control_typeahead') control_typeahead: ElementRef;

  constructor(public _enote_service: EnoteService) {
  }

  ngOnInit() {
    this.custom_controls = this._enote_service.custom_controls;
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    }).mergeMap((token: string) => this.getControlsAsObservable(token));
  }
  ngAfterViewInit() {
    this.setFocus();
  }


  setFocus() {
    this.control_typeahead.nativeElement.focus();
  }

  getControlsAsObservable(token: string): Observable<Control[]> {
    const query = new RegExp(token, 'i');

    return of(
      this.custom_controls.filter((control: Control) => {
        return query.test(control.name);
      })
    );
  }


  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }


  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }
  public checkString(event) {
    if (event.key === 'Backspace' && this.asyncSelected.length === 0) {
      this.term.show_control_typeahead = false;
    }
  }

  public typeaheadOnSelect(e: any): void {
    let term_control = new TermControl();
    term_control.control_id = e.item.id;
    term_control.control = new Control('', '').load_from_json(e.item);
    this.term.term_controls.push(term_control);
    this.term.show_control_typeahead = false;
    console.log(this.term.term_controls)
  }

}
