import {Component, Input, OnInit, Injector} from '@angular/core'
import { PracticesService } from '../../../services/practices.service';
import  {Practice} from './practice'
import { Params, ActivatedRoute}   from '@angular/router';
import {PhoneService} from '../../../voice-call/phone.service';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})

export class PracticeComponent implements OnInit {
  public pencil: boolean;
  public banner_active = false;
  public logo_active = false;
  public current_step = 1;
  @Input() practice: Practice;
  @Input() practice_permissions: string;
  constructor(private practiceService: PracticesService, private route: ActivatedRoute,
              private injector: Injector, public phoneService: PhoneService
  ) { }

  ngOnInit() {
  }
  getpracticeFromRoute(): void {
    this.route.params
      .subscribe((params: Params) =>  {this.fetchPractice(+params['id'])})
  }
  fetchPractice(id: number) {
    this.practiceService.getPractice(id).then(practice => this.practice.load_from_json(practice))
  }
  show_pencil() {
    this.pencil = true;
  }
  hide_pencil() {
    this.pencil = false;
  }
  editPractice() {
    this.practice.edit_practice = !this.practice.edit_practice;
  }

  get edit_practice() {
    return this.practice.edit_practice;
  }

  makeActiveInactive(gallery_image) {
    gallery_image.active = !gallery_image.active;
    return;
  }

  make_banner_active_deactive() {
    this.banner_active = !this.banner_active
    return;
  }

  make_logo_active_deactive() {
    this.logo_active = !this.logo_active
    return;
  }

  resetEditPractice(event) {
    this.practice.edit_practice = event;
  }
  set_current_step(event) {
    this.current_step = event;

  }

  handle_image_upload(data) {
    if (this.logo_active) {
    this.logo_active = !this.logo_active
    }
    if (this.banner_active) {
    this.banner_active = !this.banner_active
    }
    if (data) {
      if (data.attachment_type == 'Practice' && data.document_type == 'photo') {
        let new_object = new Object();
        new_object = data
        new_object['attachment_id'] = data.id;
        new_object['img'] = data.profile_pic;
        new_object['picture_type'] = 'photo';
        this.practice.gallery_images.push(new_object)
      } else if (data.attachment_type == 'Practice' && data.document_type == 'banner') {
          this.practice.practice_banner = data.profile_pic
      } else if (data.attachment_type == 'Practice' && data.document_type == 'logo') {
          this.practice.logo = data.profile_pic
      } else {
        console.log('No condition match')
      }
    }
  }

  removePicture(attachment, index) {
    this.practiceService.removePhoto(attachment).then(res => {
      this.practice.gallery_images.splice(index, 1);
      return;
    });
  }

  removeDocument(attachment, index) {
    this.practiceService.removePhoto(attachment).then(res => {
      this.practice.documents.splice(index, 1);
      return;
    });
  }

  removeBanner(practice) {
    this.practiceService.removePracticeBanner(practice).then(res => {
      this.practice.practice_banner = null;
      return
    });
  }

  removeLogo(practice) {
    this.practiceService.removePracticeLogo(practice).then(res => {
      this.practice.logo = '';
      return;
    });
  }
}
