<div class="flex-header filters-sec">
  <div class="row">
    <div class="col-sm-2 col-xs-6">
      <input type="text"
             placeholder="From Date"
             [(ngModel)]="filter['from_date']"
             class="form-control"
             bsDatepicker
             [bsValue]="bsValue"
             [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }">
    </div>

    <div class="col-sm-2 col-xs-6">
      <input type="text"
             placeholder="To Date"
             [(ngModel)]="filter['to_date']"
             class="form-control"
             bsDatepicker
             [bsValue]="bsValue"
             [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }">
    </div>

    <div class="col-sm-2 col-xs-6">
      <select [(ngModel)]="filter['outreach_type']" class="form-control">
        <option value="undefined">Outreach</option>
        <option value='email'>Email</option>
        <option value='sms'>SmS</option>
      </select>
    </div>

    <div class="col-sm-2 col-xs-6">
      <select [(ngModel)]="filter['source']" class="form-control">
        <option value="undefined">Source</option>
        <option value='reminder'>Reminder</option>
        <option value='recall'>Recall</option>
        <option value='profile'>Profile</option>
        <option value='birthday'>Birthday</option>
        <option value='notification'>Notification</option>
      </select>
    </div>

    <div class="col-sm-2 col-xs-6">
      <select [(ngModel)]="filter['status']" class="form-control">
        <option value="undefined">Status</option>
        <option value='pending'>Pending</option>
        <option value='failed'>Failed</option>
        <option value='successful'>Successful</option>
      </select>
    </div>

    <div class="col-sm-2 col-xs-12">
      <button type="button" class="btn btn-tertiary btn-outline" (click)="search()" ><i class="fa fa-filter"></i> Filter</button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Created On</th>
      <th>Scheduled On</th>
      <th>Sent On</th>
      <th>Outreach</th>
      <th>Source</th>
      <th>Status</th>
      <th>Reason</th>
      <th class="text-center">Message</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let communicationLog of patientCommunicationLogs">
      <tr>
        <td>{{communicationLog.created_at}}</td>
        <td>{{communicationLog.scheduled_on}}</td>
        <td>{{communicationLog.sent_on}}</td>
        <td>{{communicationLog.outreach_type}}</td>
        <td>{{communicationLog.source}}</td>
        <td>
          <label *ngIf="communicationLog.status == 'Failed'" class="label label-danger m0">{{communicationLog.status}}</label>
          <label *ngIf="communicationLog.status == 'Successful'" class="label label-success m0">{{communicationLog.status}}</label>
          <label *ngIf="communicationLog.status == 'Pending'" class="label label-info m0">{{communicationLog.status}}</label>
        </td>
        <td>{{communicationLog.reason}}</td>
        <td class="text-center">
          <button class="btn btn-secondary btn-outline btn-sm fa fa-eye" (click)="selected_log = communicationLog; show_popup = true"></button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="patientCommunicationLogs && patientCommunicationLogs.length == 0" ><app-no-result-found></app-no-result-found></ng-container>
</div>
<div class="text-center flex-footer" *ngIf="patientCommunicationLogs && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
<div class="modal fade in d-block" *ngIf="show_popup">
  <div class="modal-dialog" style="margin-top:10em;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="show_popup = false"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center text-capitalize">{{selected_log.outreach_type}}</h4>
      </div>
      <div class="modal-body">
        <div class="row form-group" *ngIf="selected_log.patient_email && selected_log.outreach_type === 'Email'">
          <label class="col-sm-2">Email</label>
          <div class="col-sm-10">{{selected_log.patient_email}}</div>
        </div>
        <div class="row form-group" *ngIf="selected_log.patient_phone && selected_log.outreach_type === 'Sms'">
          <label class="col-sm-3">Mobile Number</label>
          <div class="col-sm-8">{{selected_log.patient_phone | mask: '(000) 000-0000'}}</div>
        </div>
        <div class="row form-group" *ngIf="selected_log.subject && selected_log.outreach_type === 'Email'">
          <label class="col-sm-2">Subject</label>
          <div class="col-sm-10">{{selected_log.subject}}</div>
        </div>
        <div class="reminder-modal scroll-browser" [innerHTML]="selected_log.message | safeHtml"></div>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="show_popup = false;selected_log = undefined">Close</button>
      </div>
    </div>
  </div>
</div>

