import {Component, OnInit, Input, Output , EventEmitter} from '@angular/core';
import {PatientVital} from '../PatientVital';

@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.scss']
})
export class VitalsComponent implements OnInit {
  @Input() patient_vitals: PatientVital[];
  @Output() selectedPatientVital: EventEmitter<PatientVital> = new EventEmitter();
  @Input() currentPage;
  @Input() totalItems;
  @Output() historyPageChange: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  selectPatientVital(pv) {
    this.selectedPatientVital.emit(pv);
  }
}
