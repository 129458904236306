<span [class.enote-title]="term.isComponentTypeChild() && !term.is_open_text" *ngIf="!term.initial_component">
  <span *ngIf="term.is_open_text || term.name || term.keyword?.name" #term_name_span [tooltip]="term.control_value_class"
        [class]="term.control_value_class"
        [class.term]="term.isTermTypeChild()"
        [class.primary-title]="term.component_level == 2"
        [class.secondary-title]="term.component_level == 3"
        [class.tertiary-title]="term.component_level == 4">
      {{term.is_open_text ? term.open_text : term.controlText}}
       <span style="margin-left: -2px;color: #555;" class="fs16">{{(!term.isComponentTypeChild() && !term.is_open_text && !term.has_term_control_values()) ? '.' : ''}}</span>
  </span>
  <span *ngIf="term.isComponentTypeChild() && term.parent_library_datum_id && !term.is_open_text && !term.initial_component">:</span>
</span>
<ng-container *ngFor="let term_control of term.custom_term_control_with_values(); let i=index">
    <app-note-custom-control-value [term]="term" [term_control]="term_control" [control_index]="i"></app-note-custom-control-value>
    <span class="fs16" style="margin-left: -3px"  *ngIf="i < term.custom_term_control_with_values().length - 3 ">,</span>
    <span class="fs16" *ngIf="(term.custom_term_control_with_values().length - 2) == i">and</span>
    <span class="fs16" *ngIf="(term.custom_term_control_with_values().length -1) == i">.</span>
</ng-container>

<ng-container *ngIf="term.hasChildren()">
  <ng-container *ngFor="let child_terms of term.children_groups;let i=index">
    <span [class.indent-component]="i > 0" [class.enote-term]="child_terms.length > 1 && !child_terms[1].isComponentTypeChild() || term.is_open_text">
      <ng-container *ngFor="let child_term of child_terms">
        <ng-container *ngIf="child_term.showTerm()">
          <app-template-note [ngClass]="child_term.isComponentTypeChild() && !child_term.is_open_text ? 'flex-component' : '' " *ngIf="!child_term._destroy" [term]="child_term"></app-template-note>
        </ng-container>
      </ng-container>
    </span>
  </ng-container>
</ng-container>
