import {Address, ContactNumber} from '../../routes/practices/practices/practice';

export class Econtact {
  id: number;
  source_type: string;
  source_id: number;
  contact_type: string;
  name: string;
  relationship_id: any = '';
  contact_number: ContactNumber = new ContactNumber('', 'phone');
  relationship_name: string;
  facility_name: string;
  speciality: string;
  fax: string;
  address: Address = new Address();
  load_from_json(json) {
    this.id = json.id;
    this.source_type = json.source_type;
    this.source_id = json.source_id;
    this.contact_type = json.contact_type;
    this.name = json.name;
    this.fax = json.fax;
    this.relationship_id = json.relationship_id;
    this.relationship_name = json.relationship_name;
    this.contact_number = new ContactNumber('', '').load_from_json(json.contact_number);
    this.facility_name = json.facility_name;
    this.speciality = json.speciality;
    if(json.address)
    {
      this.address = new Address().load_from_json(json.address)
    }

    return this
  }

  to_params() {
    return {
      contact: {
        id: this.id,
        source_type: this.source_type,
        source_id: this.source_id,
        contact_type: this.contact_type,
        name: this.name,
        fax: this.fax,
        relationship_id: this.relationship_id,
        contact_number_attributes: this.contact_number,
        facility_name: this.facility_name,
        speciality: this.speciality,
        address_attributes: this.address.to_params()
      }
    }
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
}
