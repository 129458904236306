<ng-container *ngIf="practice.id">
  <div class="cover-photo" [ngStyle]="{ 'background-image': 'url(' + practice.practice_banner.modal.img + ')'}"></div>
  <div class="container">
    <div class="profile-header white-container-box p-lg mb-lg">
      <div class="row">
        <div class="col-sm-3 col-xs-12">
          <div class="profile-pic border-box">
            <a class="center-block"><img [src]="practice.practice_profile_pic.modal.img" alt="Image"/></a>
          </div>
        </div>
        <div class="col-sm-9 col-xs-12">
          <div class="row">
            <div class="col-sm-8 col-xs-12">
              <h2 class="fs28 text-black">{{practice.name}}</h2>
              <div class="review-star-ui">
                <rating class="review-star" [tooltip]="practice.averageExactRating" [(ngModel)]="practice.averageRating" [max]="5.00" [readonly]="true"></rating>
              </div>
              <ul class="qualification-list list-unstyled">
                <li><i class="fa icon-location-pin"></i> &nbsp;{{practice.client.address.line_1_line_2()}}<br>{{practice.client.address.city_state_zip()}}</li>
                <ng-container *ngIf="practice.phone && practice.phone?.value">
                  <li>
                    <i class="fa icon-phone"></i> &nbsp;{{practice.phone?.value | mask: ' (000) 000-0000'}}
                  </li>
                </ng-container>
              </ul>
            </div>
            <div class="col-sm-4 col-xs-12 price-section">
              <ul class="qualification-list list-unstyled">
                <ng-container *ngIf="practice.fax && practice.fax.value">
                  <li><i class="fa fa-fax"></i> &nbsp;{{practice.fax.value}}</li>
                </ng-container>
                <li><i class="fa icon-envelope"></i> &nbsp;{{practice.client.email}}</li>
                <li *ngIf="practice.url">
                  <a class="link-attach" href="{{practice.url}}" target="_blank"><i class="fa icon-link"></i> &nbsp;{{practice?.url}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9 mb-lg">
        <h3>{{practice.tagline}}</h3>
        <div [innerHTML]="practice.raw_message | safeHtml" class="mb-lg"></div>
        <ul class="pd-custom-tabs nav nav-pills">
          <li *ngFor="let stage_id of stage_ids" [class.active]="selected_stage_id == stage_id">
            <button href="javascript:" class="btn fs14" [class.text-botton]="selected_stage_id == stage_id " name="selected_stage_id" ngDefaultControl
                    (click)="onSelect(stage_id)">{{menu_items[stage_id]}}
            </button>
          </li>
        </ul>
        <div [hidden]="!(practice.id && selected_stage_id == 1)"><practice-locations [practice]="practice"></practice-locations></div>
        <div [hidden]="!(practice.id && selected_stage_id == 2)"><practice-contacts [practice]="practice"></practice-contacts></div>

        <div [hidden]="!(selected_stage_id == 0)">
          <div class="docSrch-slot-navigator bg-white">
            <div class="row">
              <div class="col-xs-6 hidden-xs"></div>
              <div class="col-sm-6 col-xs-12">
                <app-slot-navigator [physicians]="practice.physicians" [autoload]="true"></app-slot-navigator>
              </div>
            </div>
          </div>
          <ng-container *ngFor="let physician of practice.physicians"><app-doctor-block [physician]="physician"></app-doctor-block></ng-container>
        </div>

        <div [hidden]="selected_stage_id != 3">
          <h3 id="reviews">Patient Reviews</h3>
          <ng-container *ngIf="(practice.acceptedPatientReviews) && (practice.acceptedPatientReviews.length > 0) ;else no_record">
            <ul class="border-list list-unstyled">
              <ng-container *ngFor="let patient_review of practice.acceptedPatientReviews;let i = index;">
                <li class="list" *ngIf="(i < 3 && i >= 0) || show_all">
                  <div class="media overflow-visible">
                    <div class="media-left">
                      <img alt="Image" class="img-rounded thumbnail thumb64 pic" src="{{ patient_review.patient_profile_pic }}">
                    </div>
                    <div class="media-body overflow-visible">
                      <span class="pull-right fs12"><i class="icon icon-clock vl-m pr-sm"></i> On {{patient_review.review_date | date:'MMMM d, y, h:mm a'}}</span>
                      <h4 class="m0 mb-sm fw-500">{{patient_review.patient_name}}</h4>
                      <div class="review-star-ui pull-right"><rating [(ngModel)]="patient_review.rating" [max]="5" [readonly]="true"></rating></div>
                      <p class="m0">{{patient_review.description}}</p>
                    </div>
                  </div>
                </li>

              </ng-container>
            </ul>
            <ng-container *ngIf="practice.acceptedPatientReviews && practice.acceptedPatientReviews.length > 3">
              <a *ngIf="!show_all" class="col-sm-offset-6" (click)="show_all_reviews()">Show all {{practice.acceptedPatientReviews.length}} Reviews</a>
              <a *ngIf="show_all" class="col-sm-offset-6" (click)="show_all_reviews()">Show less Reviews</a>
            </ng-container>
          </ng-container>
          <ng-template #no_record class="border-list list-unstyled">
            <div class="main-wrapper">
              <div class="alert alert-info text-center fw-500" style="max-width:500px; margin:5% auto 0; line-height:normal;">No Patient Reviews Found!</div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="white-container-box p-lg">
          <h3 class="m0 mb-sm">Specialties</h3>
          <ul class="list-unstyled">
            <li *ngFor="let specialty of practice.client.specialties;let i=index">
              <span *ngIf="specialty.id">{{specialty.description}}</span>
            </li>
          </ul>
          <hr class="hr-line">
          <h3>Services</h3>
          <ul class="list-unstyled">
            <li *ngFor="let service of practice.client.clients_services;let i=index">
              <span *ngIf="service.id">{{service.name}}</span>
            </li>
          </ul>
          <hr class="hr-line">
          <ng-container *ngIf="practice.practice_pics && practice.practice_pics.length">
            <div class="">
              <img class="img-responsive thumbnail" src="{{practice.practice_pics[0]?.modal.img}}" alt='Image'/>
              <h5><a (click)="openModalViaService(1, 0)">show all {{practice.practice_pics.length}} photos</a></h5>
              <ng-container><ks-modal-gallery [id]="1" [modalImages]="practice.practice_pics" (close)="cancelImageModal()"></ks-modal-gallery></ng-container>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</ng-container>

