import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DocumentsService} from '../../services/document.service';
import $ from 'jquery';
import {AttachmentViewerService} from '../../services/attachment-viewer-service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-attachment-viewer',
  templateUrl: './attachment-viewer.component.html',
  styleUrls: ['./attachment-viewer.component.scss']
})
export class AttachmentViewerComponent implements OnInit {
  @ViewChild('document_container') private document_viewer: ElementRef;
  @Input() active: Boolean;
  @Input() isLoadedAttachment: Boolean;
  @Output() closed  = new EventEmitter<Boolean>();
  timer;


  constructor(public document_service: AttachmentViewerService, private _elementRef: ElementRef, private user_service: UserService) {
    document_service.selected_page_broadcaster.subscribe(selected_page => this.gotoPage(null, selected_page));
  }

  ngOnInit() {
  }

  isPatientUser() {
    return this.user_service.current_user && this.user_service.current_user.isPatient;
  }


  scrollViewerTo(offset) {
    this.document_viewer.nativeElement.scrollTop = offset;
  }

  gotoPage(event, page, delay= 0) {
    this.document_service.selected_page = +page;
    let result = this._elementRef.nativeElement.querySelector('.document_viewer #page_' + page);
    if (result) {
      setTimeout(() => {
        this.scrollViewerTo(result.offsetTop);
      }, delay)
    }
  }
  page_in_view(page_index) {
    if (this.active) {
      this.document_service.selected_page = page_index;
    }
  }

  trigger_preview_closed() {
    this.document_service.is_pchf = false;
    this.closed.emit(true);
  }

  page_scrolling(event, page_number?) {
    if (!this.isLoadedAttachment) {
      clearTimeout(this.timer);
      this.timer = setTimeout( () => {
        let page_in_view = $('.document_viewer .page').filter(function(i, e) {
          let difference = $(e).position().top - $('.document_viewer').scrollTop();
          return difference >= -500 && difference <= 500
        });
        if (page_in_view.length > 0 ) {
          let page_number = parseInt($(page_in_view[0]).attr('page_number') );
          this.page_in_view(page_number);
        }

      } , 150 );
    }
  }

  preview_closed() {
    this.document_service.toggleView();
  }

}
