<h4 class="text-primary text-capitalize">{{filter['status']}}</h4>
<div class="filters-sec bg-lightest-gray p" *ngIf="filter['status']">
  <div class="row">
    <div class="col-xs-4">
      <input class="form-control" [(ngModel)]="filter['keyword']" placeholder="Search by name or email">
    </div>
    <div class="col-xs-2 col-md-5 col-sm-12">
      <button type="button" class="btn btn-primary mr-lg" (click)="search()" ><i class="fa fa-search mr-sm"></i>Search</button>
      <button type="button" class="btn btn-primary" *ngIf="filter['status'] === 'clicked'" (click)="closeDiv()" ><i class="fa fa-backward mr-sm"></i>Back</button>
    </div>
  </div>
</div>
<table class="table table-hover" *ngIf="campaign.recipient_type ==='patients'">
  <thead>
    <tr>
      <th *ngIf="campaign.outreach_type === 'sms'">Cell Phone</th>
      <th *ngIf="campaign.outreach_type === 'email'">Email</th>
      <th>Name</th>
      <th>DOB</th>
      <th>Gender</th>
      <th *ngIf="campaign.outreach_type != 'sms'">Cell Phone</th>
      <th *ngIf="campaign.outreach_type != 'email'">Email</th>
      <th>Location</th>
      <th>Patient Type</th>
      <th>Registered On</th>
      <th>Web Access</th>
      <th>Appointments</th>
      <ng-container *ngIf="!showAudienceTable">
        <th class="text-capitalize">{{filter['status']}} Count</th>
        <th>Sent On</th>
      </ng-container>
    </tr>
  </thead>
  <tbody *ngIf="recipientsList && recipientsList.length > 0">
    <tr *ngFor="let recipient of recipientsList">
      <td *ngIf="campaign.outreach_type === 'sms'">{{recipient.phone_number}}</td>
      <td *ngIf="campaign.outreach_type === 'email'">{{recipient.email}}</td>
      <td><a (click)="add_to_tabs(recipient)">{{recipient.name}}</a></td>
      <td>{{recipient.dob}}</td>
      <td class="text-capitalize text-center">{{recipient.gender}}</td>
      <td *ngIf="campaign.outreach_type != 'sms'">{{recipient.phone_number}}</td>
      <td *ngIf="campaign.outreach_type != 'email'">{{recipient.email}}</td>
      <td>{{recipient.location}}</td>
      <td class="text-capitalize text-center">{{recipient.patient_type}}</td>
      <td>{{recipient.registered_on}}</td>
      <td class="text-capitalize text-center">{{recipient.web_access}}</td>
      <td>{{recipient.appointments}}</td>
      <ng-container *ngIf="!showAudienceTable">
        <td>{{recipient.count}}</td>
        <td>{{recipient.sent_on}}</td>
      </ng-container>
    </tr>
  </tbody>
</table>

<table class="table table-hover" *ngIf="campaign.recipient_type != 'patients'">
  <thead>
  <tr>
    <th *ngIf="campaign.outreach_type === 'sms'">Cell Phone</th>
    <th *ngIf="campaign.outreach_type === 'email'">Email</th>
    <th>Name</th>
    <th>User Name</th>
    <th>Designation</th>
    <th>Role</th>
    <th *ngIf="campaign.outreach_type != 'sms'">Cell Phone</th>
    <th *ngIf="campaign.outreach_type != 'email'">Email</th>
    <th>Location</th>
    <ng-container *ngIf="!showAudienceTable">
      <th class="text-capitalize">{{filter['status']}} Count</th>
      <th>Sent On</th>
    </ng-container>
  </tr>
  </thead>
  <tbody *ngIf="recipientsList && recipientsList.length > 0">
  <tr *ngFor="let recipient of recipientsList">
    <td *ngIf="campaign.outreach_type === 'sms'">{{recipient.phone_number}}</td>
    <td *ngIf="campaign.outreach_type === 'email'">{{recipient.email}}</td>
    <td>{{recipient.name}}</td>
    <td>{{recipient.user_name}}</td>
    <td class="text-capitalize">{{recipient.designation}}</td>
    <td class="text-capitalize">{{recipient.role}}</td>
    <td *ngIf="campaign.outreach_type != 'sms'">{{recipient.phone_number}}</td>
    <td *ngIf="campaign.outreach_type != 'email'">{{recipient.email}}</td>
    <td>{{recipient.location}}</td>
    <ng-container *ngIf="!showAudienceTable">
      <td>{{recipient.count}}</td>
      <td>{{recipient.sent_on}}</td>
    </ng-container>
  </tr>
  </tbody>
</table>

<div class="flex-footer text-center" *ngIf="recipientsList && totalItems > 10">
  <pagination [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="25" (pageChanged)="pageChanged($event)"></pagination>
</div>

<ng-container><app-no-result-found *ngIf="recipientsList && recipientsList.length < 1"></app-no-result-found></ng-container>
