import { Component, OnInit, Injector } from '@angular/core';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  appointment: any;
  constructor(private injector: Injector) { }

  ngOnInit() {
    this.appointment = this.injector.get('appointment');
  }

  change_status() {
    this.appointment.status_id = 'checkedout';
  }

}
