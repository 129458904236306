import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {PatientService} from '../../../services/patient.service';
import {PhoneService} from '../../../voice-call/phone.service';
import {Patient} from '../../../health/doctor/patient';

@Component({
  selector: 'app-patient-listing',
  templateUrl: './patient-listing.component.html',
  styleUrls: ['./patient-listing.component.scss']
})
export class PatientListingComponent implements OnInit {
  @Input() patients: Patient[];
  @Input() totalItems;
  @Output() sender: EventEmitter<any> = new EventEmitter();
  @Output() page: EventEmitter<any> = new EventEmitter();

  constructor(private patientService: PatientService,
              public phoneService: PhoneService) { }

  ngOnInit() {
  }
 sendPatient(patient) {
   this.sender.emit(patient);
 }
  pageChanged(event) {
    this.page.emit(event.page);
  }
  changePatientState(patient) {
    patient.user.is_active = (patient.user.is_active == 'true') ? true : false;
    this.patientService.save(patient).then()
  }
}
