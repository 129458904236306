import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {ProgramService} from '../program.service';
import {LoaderService} from '../../services/loader.service';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {Bill} from './bill';
import {BillService} from '../bill.service';
import {Tab} from '../../tabs/tab';
import {TabsService} from '../../services/tabs.service';
import {PrintBillComponent} from './print-bill/print-bill.component';
import {EligiblePatient} from '../eligible-patient';
import {EnrolledPatientComponent} from '../enrolled-patient/enrolled-patient.component';
import {Program} from '../program';
import {TableToExcelService} from './table-to-excel.service';
import {PhoneService} from '../../voice-call/phone.service';
import {AlertService} from '../../_alert';
import {UserService} from '../../services/user.service';
export interface DoctorUser {
  id: number;
  name: string;
}
@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.scss']
})
export class BillsComponent implements OnInit {
  public filter: Object = {token: '', page: 1, billing_provider_id: '', program_id: '',
    year: '', month: '', status: '', per_page: 20, programme_category: ''};
  public totalItems;
  public programsList: any;
  public bills: Bill[] = [];
  public providers: DoctorUser[];
  public maxDate: any = new Date();
  public selectMonth: any = new Date();
  public months_list  = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', ' Aug', 'Sep', 'Oct', 'Nov',
    'Dec' ];
  public displayItemsNumber: any;
  @ViewChild('TABLE') table: ElementRef;
  @ViewChild('signBillModal') signBillModal;
  public showExportButton: boolean;
  public selectedBill: Bill;
  public signNonComplianceBill: boolean;
  @Input() programme_category: any;
  public selectProgramType: any;
  public enrolled_in: any[];
  @ViewChild('selectTypeFromBillModal') selectTypeFromBillModal;
  public selectedPatient: EligiblePatient;
  public program: Program;

  constructor(public globalErrorHandlerService: GlobalErrorHandlerService,
              public programService: ProgramService, public loaderService: LoaderService,
              public billService: BillService, public tabsService: TabsService, public userService: UserService,
              public tableTOExcelService: TableToExcelService, public alertService: AlertService,
              public phoneService: PhoneService) {
    this.tabsService.register('Eligible Patient');
  }

  ngOnInit(): void {
    this.programme_category = this.programme_category ? this.programme_category : 'cms_programme'
    this.filter['programme_category'] = this.programme_category;
    this.getBills();
    this.getAllPracticePrograms();
    this.getPracticeDoctor();
  }

  getBills() {
    this.loaderService.show();
    this.billService.getBills(this.filter).then(resp => {
      this.bills = resp.bills;
      this.totalItems = resp.total;
      this.displayItemsNumber = resp.bills.length;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  getAllPracticePrograms() {
    let filter = {programme_category: this.programme_category}
    this.programService.getAllPracticePrograms(filter).then(resp => {
      this.programsList = resp;
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  getPracticeDoctor() {
    this.programService.getPracticeDoctorWithUsers().then(resp => {
      this.providers = resp;
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  search() {
    this.filter['page'] = 1;
    this.showHideExportButton();
    this.getBills();
  }

  showHideExportButton() {
    if (this.filter['status'] === 'billed') {
      this.showExportButton = true;
    } else {
      this.showExportButton = false;
    }
  }

  setMonthAndYear(e) {
    this.filter['year'] = new Date(this.selectMonth).getFullYear();
    this.filter['month'] = new Date(this.selectMonth).getMonth() + 1;
    return this
  }

  pageChanged(event: PageChangedEvent) {
    this.filter['page'] = event.page;
    this.getBills();
  }

  signBill(bill: Bill) {
    if (this.billValidator(bill)) {
      this.signedSuperBill(bill);
    }
  }
  openEnrolledPatient(patient: EligiblePatient, program: Program) {
    this.selectedPatient = new EligiblePatient().load_from_json(patient)
    this.program = new Program().load_from_json(program);
    if (this.selectedPatient.enrolled_in.length == 1) {
      this.selectProgramType = this.selectedPatient.enrolled_in[0];
      this.openPatient(this.selectedPatient, this.program);
    } else {
      this.enrolled_in = this.selectedPatient.enrolled_in;
      this.selectProgramType = this.enrolled_in[0];
      this.selectTypeFromBillModal.show();
    }

  }
  selectProgramTypeToOpen() {
    this.openPatient(this.selectedPatient, this.program);
  }
  openPatient(patient: EligiblePatient, program: Program) {
    this.tabsService.add_tab(new Tab({title: patient.name, icon: 'fa', autorefresh: false}, EnrolledPatientComponent,
      patient.id, {patient: patient, program: program,programme_type: this.selectProgramType}))
  }

  closeSelectProgramModel() {
    this.selectTypeFromBillModal.hide();
    this.selectedPatient = new EligiblePatient();
    this.program = new Program();
    this.selectProgramType = '';

  }

  exportTableToExcel() {
    this.tableTOExcelService.exportToExcel(this.table);
  }

  private billValidator(bill: Bill): boolean {
    if (this.checkDiagnosisCount(bill)) {
      if (this.checkComplianceLevel(bill.compliance_level)) {
        this.signNonComplianceBill = true;
        this.selectedBill = bill;
        this.signBillModal.show();
        return
      } else {
        return true;
      }
    } else {
      this.globalErrorHandlerService.error = 'Bill cannot be signed as it doesn\'t meet eligibility criteria.';
      return false;
    }
  }

  private checkDiagnosisCount(bill: Bill): boolean {
    let isValid = false;

    // Check each enrolled program individually
    bill.enrolled_in.forEach(program => {
      switch (program) {
        case 'CCM':
          isValid = isValid || (bill.diagnosis.length >= 2);
          break;
        case 'RPM':
          isValid = isValid || (bill.diagnosis.length >= 1);
          break;
        case 'BHI':
          isValid = isValid || this.checkDiagnosis(bill.diagnosis);
          break;
        case 'PCM':
        case 'TCM':
        case 'Private Pay':
          isValid = true; // For these programs, simply set isValid to true
          break;
        default:
          // Handle any other cases if necessary
          break;
      }
    });

    return isValid;
  }

  private checkComplianceLevel(compliance_level: any): boolean {
    return compliance_level === 'non_compliant' ?  true : false;
  }

  checkDiagnosis(diagnosis: any[]): boolean {
    return diagnosis.some(code => {
      const codeNumber = parseInt(code[0].substring(1));
      return code[0].startsWith('F') && codeNumber >= 1 && codeNumber <= 99;
    });
  }

  signedSuperBill(bill: Bill) {
    bill.uploading = true;
    if (this.signNonComplianceBill) {
      this.closeSignBillModal();
    }
    this.billService.signSuperBill(bill.id).then(resp => {
      let itemIndex = this.bills.findIndex(item => item.id.toString() === bill.id.toString());
      this.bills[itemIndex] = resp;
      this.bills[itemIndex].uploading = false;
      this.alertService.success('Bill has been signed successfully', this.alertService.options);
    }).catch(resp => {
      bill.uploading = false;
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  closeSignBillModal() {
    this.signBillModal.hide();
    this.selectedBill = undefined;
    this.signNonComplianceBill = false;
  }
}
