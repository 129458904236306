import {Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { Calendar } from '../../../practices/doctors/doctor'
import { CalendarService } from './../../../../services/calendar.service';
import { ServiceTechnician } from '../../../practices/practices/practice';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';

@Component({
  selector: 'app-calendar-form',
  templateUrl: './calendar-form.component.html',
  styleUrls: ['./calendar-form.component.scss']
})
export class CalendarFormComponent implements OnInit {
  public show_form = true;
  public calendar_loc: any;
  @Input() permissions: any;
  @Input() calendar: Calendar;
  @Input() clients_service: any;
  @Input() calendar_locations: any;
  @Input() calendarable_id: number;
  @Input() calendarable_type: string;
  @Output() edited_calendar: EventEmitter<Calendar> = new EventEmitter<Calendar>();
  @Output() form_hide: EventEmitter<Calendar> = new EventEmitter<Calendar>();
  @Output() calendar_deactive: EventEmitter<any> = new EventEmitter<any>();

  @Input() calendars_array: Calendar[];
  public appts_per_slot: any = [1, 2, 3, 4, 5];
  public slot_sizes: Array<any> = this.initialize_slot_sizes();
  public technicians: Array<any> = [];
  public calendar_technicians: any;
  service_technician: ServiceTechnician = new ServiceTechnician();
  messageAfterSave = false;
  dateRange = false;
  weekdaysExist = false;
  isDeactive = false;
  public error = '';
  public today: any;
  date2MaxDate: any;
  minFromDate: Date;
  toDate: Date;
  show_popup: boolean = false;
  confirmCheck: boolean = false;

  constructor(private _calendarService: CalendarService, private globalErrorHandlerService: GlobalErrorHandlerService) {
  }

  ngOnInit() {
    this.toDate = this.calendar.to
    if (this.clients_service) {
    this.calendar_technicians = this.clients_service.service_technicians.map(sp => {return {technician_id: sp.technician_id, name: sp.name}});
    }
    this.calendar.calendarable_id = this.calendarable_id;
    this.calendar.calendarable_type = this.calendarable_type;
    if(this.calendar.id){
      this.minFromDate = this.calendar.from;
    }else{
      this.minFromDate = new Date();
      this.minFromDate.setDate(this.minFromDate.getDate() - 1);
    }
  }

  ngOnChanges() {
  }

  delete_calendar() {
    this._calendarService.delete(this.calendar.id).then(res => {
        if (res && res.deleted) {
          this.calendars_array.splice(this.calendars_array.indexOf(this.calendar), 1);
        }
        if (res && res.message) {
          this.calendar.is_active = res.status;
          this.calendar_deactive.emit(res.status);
        }
      this.calendars_array = this.calendars_array;
      this.form_hide.emit(this.calendar);
      }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error );
  }

  onChange(selectedValue) {
    this.calendar.location_id = parseInt(selectedValue);
  }

  onChangeAppt(selectedValue) {
    this.calendar.appt_per_slop = selectedValue;
  }

  onChangeSlotSize(selectedValue) {
    this.calendar.slot_size = selectedValue;
  }


  initialize_slot_sizes() {
    return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120, 180, 240];
  }

  slotSizeConversion(slot_size) {
    if (slot_size > 90) {
      return (slot_size / 60 + ' Hours');
    }
    return (slot_size + ' Mins');
  }

  save() {
    if(this.calendar.id){
      if(this.toDate > this.calendar.to){
        if(!this.confirmCheck){
          this.show_popup = true
          return
        }
      }
    }

    if (this.calendar.convert_binary_to_base10() <= 0) {
      this.weekdaysIssue();
      return;
    }
    if (this.calendar.validationCheck()) {
      this.setTimeOutDateRange();
      return;
    }
    this._calendarService.save(this.calendar).then(res => {
      if (res.id) {
        this.edited_calendar.emit(this.calendar);
        return res;
      } else {
        this.error = res;
        this.setTimeOutSuccessMessage();
      }
    }).catch(resp =>  {
      (resp.error.error.includes('overlapping') || resp.error.error.includes('overlapped')) ? this.globalErrorHandlerService.error = 'Calendars cannot be overlapped. Please check your calendars and try again'
        : this.globalErrorHandlerService.error = resp.error.error;
    } );
  }

  setTimeOutSuccessMessage(): any {
    this.messageAfterSave = true;
    setTimeout(function() {
      this.messageAfterSave = false;
    }.bind(this), 3000);
  }

  setTimeOutDateRange(): any {
    this.dateRange = true;
    setTimeout(function() {
      this.dateRange = false;
    }.bind(this), 3000);
  }

  weekdaysIssue(): any {
    this.weekdaysExist = true;
    setTimeout(function() {
      this.weekdaysExist = false;
    }.bind(this), 3000);
  }

  hide_form(calendar) {
    this.form_hide.emit(this.calendar);
  }

  changeTechnician(calendar, technician_id) {
      let service_technician = this.clients_service.service_technicians.filter(e => e.technician_id == parseInt(technician_id))[0];
      if (service_technician) {
        calendar.service_technician_id = service_technician.technician_id;
      }
  }
}
