import {nullRemover} from '../services/nullRemover';
export class IntegrationCredential {
  pms_vendor_id = '0';
  practice_id: number;
  id: number;
  auth_url: string;
  api_url: string;
  user_name: string;
  password: string;
  client_id: string;
  client_secret: string;
  practice_url: string;
  pms_vendor: string;
  is_authenticated: boolean;
  constructor() {}
  load_from_json(json) {
    this.is_authenticated = json.is_authenticated;
    this.id = json.integrated_practice_id;
    this.pms_vendor = json.pms_vendor;
    this.pms_vendor_id = json.pms_vendor_id;
    this.auth_url = json.auth_url;
    this.api_url = json.api_url;
    this.user_name = json.user_name;
    this.password = json.password;
    this.client_id = json.client_id;
    this.client_secret = json.client_secret;
    this.practice_url = json.practice_url;
    return this;
  }

  to_params() {
    return {
      integrated_practice_attributes: {
        id: this.id,
        practice_id: this.practice_id,
        pms_vendor_id: this.pms_vendor_id,
        integrated_practice_credentials_attributes: this.credential_attributes()
      }
    }
  }

  credential_attributes() {
    let a = nullRemover(this);
    let credential_names = Object.getOwnPropertyNames(a);
    let array = credential_names.map(name => {
      let obj = {};
      obj['credential_name'] = name;
      obj['credential_value'] = this[name];
      return obj;
    })
    return array;
  }
}
