import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {UserService} from '../.././services/user.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  constructor(public  _userService: UserService, private _tokenService: HttpClient, private router: Router) { }

  ngOnInit() {
  }
}
