import { Injectable }     from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';



@Injectable()
export class RegisteredPracticesService {
  constructor(private _tokenService: CustomHttpClient) {}

  public getAllPractices(page_no, practice_id, is_active) {
    return this._tokenService.getWithHeaders('leads/get_registered_practices?page=' + page_no + '&practice_id=' + practice_id + '&is_active=' + is_active, {observe: 'response', responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          practices: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  public updatePracticeState(practice) {
    return this._tokenService.get('leads/changePracticeState?is_active=' + practice.client.is_active + '&practice_id=' + practice.id)
      .toPromise()
      .then(response => response)
  }
}
