<ul class="flex-header pd-custom-tabs nav nav-pills pb0">
  <li [class.active]="selected_stage_id === 4">
    <button class="btn" [(ngModel)]="selected_stage_id" name="task" ngDefaultControl [value]="4"
            (click)="onMenuSelect(4)">Tasks
    </button>
  </li>
  <li [class.active]="selected_stage_id === 0">
    <button class="btn" [(ngModel)]="selected_stage_id" name="plan" ngDefaultControl [value]="0"
            (click)="onMenuSelect(0)">Plan
    </button>
  </li>
  <li [class.active]="selected_stage_id === 1 " >
    <button class="btn" [(ngModel)]="selected_stage_id" name="activity" ngDefaultControl [value]="1"
            (click)="onMenuSelect(1)">Alerts
    </button>
    <span class="label label-success label-counter" *ngIf="programEnrollment && programEnrollment.unread_alerts?.total > 0">{{programEnrollment?.unread_alerts?.total}}</span>
  </li>
  <li [class.active]="selected_stage_id === 2 ">
    <button class="btn" [ngClass]="patient?.can_login ? 'text-black' : 'text-danger'" [(ngModel)]="selected_stage_id" name="{{programEnrollment.id}} selected_stage_id" ngDefaultControl [value]="2"
            (click)="onMenuSelect(2)">Chat
    </button>
  </li>
  <li class="sms-tooltip" *userCan="'view,sms,home'" [class.active]="selected_stage_id === 3">
    <button class="btn"
            [disabled]="!userService.user.twilio_configurations.sms"
            [tooltip]="userService.user.twilio_configurations.sms ? 'SMS' : 'Twilio is not configured'"
            [ngClass]="patient?.phone ? 'text-black' : 'text-danger'"
            [(ngModel)]="selected_stage_id"
            name="sms" ngDefaultControl
            [value]="3"
            (click)="onMenuSelect(3)">SMS
    </button>
  </li>
</ul>
<div class="flex-body scroll-browser">
  <ng-container *ngIf="selected_stage_id === 4">
    <app-tasksfilter *ngIf="patient && patient.id" class="flex-control flex-column scroll-browser" [patient]="patient" [isPatientProfile]="true" [isPatientDetailPage]="true"></app-tasksfilter>
  </ng-container>
  <app-enrolled-patient-plan *ngIf="selected_stage_id === 0" [patient]="patient" [programEnrollment]="programEnrollment" [programme_type]="programme_type"></app-enrolled-patient-plan>
  <app-enrolled-patient-alerts *ngIf="selected_stage_id === 1" [patient]="patient" [programEnrollment]="programEnrollment" [programme_type]="programme_type"></app-enrolled-patient-alerts>
  <app-enrolled-patient-chat class="flex-control flex-column" *ngIf="selected_stage_id === 2" [patient]="patient"></app-enrolled-patient-chat>
  <app-enrolled-patient-sms class="flex-control flex-column" *ngIf="selected_stage_id === 3" [patient]="patient"></app-enrolled-patient-sms>
</div>
