<td class="first-task"><a class="fw-500" (click)="update.emit(task)">{{task.task_name | truncate : 65:'...'}}</a></td>
<!--
<td *ngIf="!isPatientDetailPage"><a class="fw-500" (click)="edit_Task(task)">{{task.task_name | truncate : 50:'...'}}</a></td>
-->
<td class="text-capitalize" *ngIf="!isPatientDetailPage">{{task.assigned_to_name}}</td>
<td class="text-capitalize">{{task.assigned_by_name}}</td>
<td class="text-capitalize" *ngIf="!isPatientProfile">{{task.patient.name ? task.patient.name:"None" }}</td>
<td class="text-center">{{task.priority}}</td>
<td class="text-center">
  <span [ngClass]="task.after_due_date_color ">
    {{task?.due_date |  date:'mediumDate'}}
  </span>
</td>
<td class="text-center">
  <label *ngIf="task.status_id == 'Not Started'" class="label label-danger m0">{{task.status_id}}</label>
  <label *ngIf="task.status_id == 'In Progress'" class="label label-info m0">{{task.status_id}}</label>
  <label *ngIf="task.status_id == 'Completed'" class="label label-success m0">{{task.status_id}}</label>
</td>
<td class="text-center">
  <ng-container *ngIf="!isPatientDetailPage && !isProgramDashboard">
    <a  class="btn btn-secondary btn-outline mr" data-toggle="tooltip" data-placement="auto" title="Send Instant Message" (click)="send_message(task)" *ngIf="task.assigned_to != task.assigned_by">
      <i class="fa fa-comment-o"></i>
    </a>
  </ng-container>
  <a class="btn btn-secondary btn-outline"  data-toggle="tooltip" data-placement="auto" title="Mark Task Completed" (click)="markCompletedModel.show()" *ngIf="!(task.status_id == 'Completed')">
    <i class="fa icon-check vl-m"></i>
  </a>
</td>

<div class="modal fade" bsModal #markCompletedModel="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="markCompletedModel.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Mark Task Completed</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0 text-center">“Are you sure you want to mark this task as completed?”</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="markCompleted();markCompletedModel.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="markCompletedModel.hide();">No</button>
      </div>
    </div>
  </div>
</div>

