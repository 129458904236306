import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../../services/nullRemover';
import {CustomHttpClient} from '../../services/custom-http-client.service';
import {RichTextTemplate} from './rich-text-template';

@Injectable({
  providedIn: 'root'
})
export class RichTextTemplateService {
  public reload_rich_text_template_list: Subject<any> = new Subject<any>();
  public rich_text_types: string[] = [];
  public practice_admin_custom_fields: Array<string> = ['Tags', 'Patient First Name', 'Patient Last Name', 'Patient DOB',
    'Patient Gender', 'Patient Birth Day', 'Patient Birth Month', 'Practice Name', 'Practice Full Address',
    'Practice Phone Number', 'Sender Prefix', 'Sender First Name', 'Sender Last Name', 'Sender Designation'];
  public doctor_custom_fields: Array<string> = ['Tags', 'Patient First Name', 'Patient Last Name', 'Patient DOB',
    'Patient Gender', 'Patient Birth Day', 'Patient Birth Month', 'Prefix', 'First Name', 'Last Name', 'Designation',
    'Sender Prefix', 'Sender First Name', 'Sender Last Name', 'Sender Designation'];


  constructor(private _tokenService: CustomHttpClient) { }

  create(richTextTemplate: RichTextTemplate): Promise<any> {
    return this._tokenService
      .post('rich_text_templates', richTextTemplate.to_params())
      .toPromise()
      .then(response => response)
  }

  save(richTextTemplate: RichTextTemplate): Promise<any> {
    if (richTextTemplate.is_new_record()) {

      return this.create(richTextTemplate);
    }
    else {
      return this.update(richTextTemplate);
    }
  }

  update(richTextTemplate: RichTextTemplate) {
    return this._tokenService
      .put('rich_text_templates/' + richTextTemplate.id, richTextTemplate.to_params())
      .toPromise()
      .then(response => response)
  }

  getAlertTemplates(filter: Object): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('rich_text_templates', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          richTextTemplates: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  getRichTextTypes() {
    return this._tokenService.get('rich_text_templates/text_types')
      .toPromise()
      .then(response => response)
  }

  import_public_rich_templates(token: any): Observable<any>   {
    let args = new HttpParams();
    args = args.set('token', token);
    return this._tokenService.get('rich_text_templates/import', {params: args})
      .map(res => res);
  }

  embed_rich_templates(filter: Object): Observable<any>   {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.get('rich_text_templates/embed', {params: params})
      .map(res => res);
  }

  delete(id: number) {
    return this._tokenService
      .delete('rich_text_templates?id=' + id)
      .toPromise()
      .then(response => response)
  }

  getRichText(id: any): Promise<any> {
    return this._tokenService.get('rich_text_templates/single?id=' + id)
      .toPromise()
      .then(response => response)
  }
}
