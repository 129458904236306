<form class="m0" (ngSubmit)="saveDevice()" #activityLogTime="ngForm">
  <div class="modal-body">
    <ng-container *ngIf="!isTrainingDevice">
      <div class="row form-group">
        <div class="col-md-4 col-sm-3">
          <label class="field-label control-label">Date Time</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <input type="datetime-local" name="config_date" [value]="device.config_date" [(ngModel)]="device.config_date"
                 class="form-control"/>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4 col-sm-3">
          <label class="field-label control-label">Device*</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <select class="form-control" name="select_device" [(ngModel)]="device.device_info_id"
                  (change)="selectDevice($event.target.value)" required>
            <option value=undefined selected>Select</option>
            <ng-container *ngFor="let vendor of devicesToSelect">
              <option [value]="vendor.id">{{userService.removeUnderScore(vendor.name)}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4 col-sm-3">
          <label class="field-label control-label">Name*</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <input type="text" class="form-control" placeholder="Device Name" [(ngModel)]="device.name" name="name" required>
        </div>
      </div>

      <!--      <div class="row form-group">
              <div class="col-md-4 col-sm-3">
                <label class="field-label control-label">ID</label>
              </div>
              <div class="col-md-6 col-sm-5">
                <input type="text" class="form-control" placeholder="Device ID" [(ngModel)]="device.d_id" name="d_id">
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-4 col-sm-3">
                <label class="field-label control-label">Model</label>
              </div>
              <div class="col-md-6 col-sm-5">
                <input type="text" class="form-control" placeholder="Device Model" [(ngModel)]="device.d_model" name="d_model">
              </div>
            </div>-->

      <div class="row form-group">
        <div class="col-md-4 col-sm-3">
          <label class="field-label control-label">MAC Address</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <input type="text" class="form-control" placeholder="Device MAC Address " [(ngModel)]="device.mac_address" name="mac_address">
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4 col-sm-3">
          <label class="field-label control-label">Language</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <select class="form-control" [(ngModel)]="device.language" name="activity_type">
            <option value='' selected>Select Language</option>
            <option value="english">English</option>
            <option value="spanish">Spanish</option>
            <option value="bi_lingual">Bi-lingual</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4 col-sm-3">
          <label class="field-label control-label">Status*</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <select class="form-control" name="status_id" [(ngModel)]="device.status" required>
            <option *ngFor="let status of device_status; let i = index;" [value]="status">
              {{userService.removeUnderScore(status)}}
            </option>
          </select>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isTrainingDevice">
      <div class="row form-group">
        <div class="col-md-4 col-sm-3">
          <label class="field-label control-label">Date Time</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <input type="datetime-local" name="training_date" [value]="device.training_date"
                 [disabled]="device.trained"
                 [(ngModel)]="device.training_date" class="form-control"/>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4 col-sm-3"><label class="field-label control-label">Training</label></div>
        <div class="col-md-6 col-sm-5">
          <label class="fw-500 mr-lg">
            <input type="radio" class="ml" name="yes_training_status" [value]=true [(ngModel)]="device.training_status"
                   [disabled]="device.trained"/>
            Yes
          </label>
          <label class="fw-500 mr-lg">
            <input type="radio" class="ml" name="no_training_status" [value]=false [(ngModel)]="device.training_status"
                   [disabled]="device.trained"/>
            No
          </label>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-4 col-sm-3">
          <label class="field-label control-label">Trainer</label>
        </div>
        <div class="col-md-6 col-sm-5">
          <input type="text" class="form-control" placeholder="Trainer Name" [(ngModel)]="device.trainer_name" name="trainer_name">
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-3"><label class="field-label control-label">Comments</label></div>
        <div class="col-md-8 col-sm-5">
              <textarea [(ngModel)]="device.comments" placeholder="Description"
                        class="form-control no-resize" style="height:120px;" name="comments">
              </textarea>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <a *ngIf="loaderService.uploading_in_process" class="btn btn-default">
      <i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i> Uploading...
    </a>
    <ng-container *ngIf="!loaderService.uploading_in_process">
      <ng-container *ngIf="isTrainingDevice">
        <button type="submit" class="btn btn-secondary" [disabled]="!activityLogTime.form.valid">{{device.id ? 'Update' : 'Save'}}</button>
      </ng-container>
      <ng-container *ngIf="!isTrainingDevice">
        <ng-container *ngIf="device.id else saveButton">
          <ng-container *ngIf="device.status === 'discontinued'">
            <button type="button" class="btn btn-secondary" (click)="editConfirmation.show()" [disabled]="!activityLogTime.form.valid">Update</button>
          </ng-container>
          <ng-container *ngIf="device.status !== 'discontinued'">
            <button type="submit" class="btn btn-secondary" [disabled]="!activityLogTime.form.valid">Update</button>
          </ng-container>
        </ng-container>
        <ng-template #saveButton>
          <button type="submit" class="btn btn-secondary" [disabled]="!activityLogTime.form.valid">Save</button>
        </ng-template>
      </ng-container>
      <button type="button" class="btn btn-tertiary btn-outline" (click)="closeForm()">Cancel</button>
      <button *ngIf="device.id > 0 && !isTrainingDevice" class="btn btn-danger btn-outline" type="button" (click)="deleteModal.show()">Delete</button>
    </ng-container>
  </div>
</form>
<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-sm" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete <span *ngIf="isTrainingDevice"> Training</span>
          <span *ngIf="!isTrainingDevice"> Device</span> </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p-sm m0 text-center">
          Are you sure you want to delete this <span *ngIf="isTrainingDevice"> training</span>
          <span *ngIf="!isTrainingDevice"> device? </span> <br>
          "This device has scheduled events on patient calendar, any changes may effect future events. Do you want to continue?"
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteDevice(device); this.deleteModal.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" bsModal #editConfirmation="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-sm" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="editConfirmation.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Edit Device</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-warning p-sm m0 text-center">
          "This device has scheduled events on patient calendar, any changes may effect future events. Do you want to continue?"
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="saveDevice(); this.editConfirmation.hide()">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="editConfirmation.hide();">No</button>
      </div>
    </div>
  </div>
</div>
