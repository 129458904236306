import { Component, OnInit } from '@angular/core';
import {IntegrationService} from '../integration.service';

@Component({
  selector: 'app-integrated-vendor',
  templateUrl: './integrated-vendor.component.html',
  styleUrls: ['./integrated-vendor.component.scss']
})
export class IntegratedVendorComponent implements OnInit {
  public integrated_practices: any[];
  constructor(private integrationService: IntegrationService) { }

  ngOnInit() {
    this.getIntegratedPractices();
  }

  getIntegratedPractices() {
    this.integrationService.getIntegratePractices().then(resp => {
     this.integrated_practices = resp;
    })
  }

  update(ip) {
    this.integrationService.updateIntegratedPractice(ip.id, ip.active ? false : true).then(resp => {
      let index = this.integrated_practices.indexOf(ip);
      if (index !== -1) {
        this.integrated_practices[index] = resp;
      }
    })
  }

}
