import { Component, OnInit } from '@angular/core';
import {TabsService} from '../../services/tabs.service';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('Reminders')
  }

  ngOnInit(): void {
  }

}
