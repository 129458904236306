import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {PatientVital} from '../routes/practice-site/vital/PatientVital';
import {UserService} from './user.service';
import {Vital} from '../routes/practice-site/vital/vital';
import {nullRemover} from './nullRemover';
import {Subject} from 'rxjs';

@Injectable()
export class VitalsService {
  reloadEnrolledPatientVitalsList: Subject<any> = new Subject<any>();
  public load_latest_vital = false;
  public vital_mapper_with_unit = {'temp': 'Temperature (F)', 'pulse': 'Pulse (bpm)', 'rr': 'Respiration (breaths)',
    'wt': 'Weight (lbs)', 'ht': 'Height (Inches)', 'bmi': 'Body Mass Index (lb/m2)', 'spo2': 'SPO2 (%)',
    'rbg': 'Blood Glucose-Random (mg/dL)', 'fbg': 'Blood Glucose-Fasting (mg/dL)', 'nprs': 'Pain Scale (1-10)',
    'sbp': 'Blood Pressure-Systolic (mmHg)', 'dbp': 'Blood Pressure-Diastolic (mmHg)',
    'sbp_min': 'Minimum Blood Pressure-Systolic (mmHg)', 'sbp_max': 'Maximum Blood Pressure-Systolic (mmHg)',
    'dbp_min': 'Minimum Blood Pressure-Diastolic (mmHg)', 'dbp_max': 'Maximum Blood Pressure-Diastolic (mmHg)',
    'pulse_min': 'Minimum Pulse (bpm)', 'pulse_max': 'Maximum Pulse (bpm)', 'spo2_min': 'Minimum SPO2 (%)',
    'spo2_max': 'Maximum SPO2 (%)', 'fbg_min': 'Minimum Blood Glucose-Fasting (mg/dL)',
    'fbg_max': 'Maximum Blood Glucose-Fasting (mg/dL)', 'rbg_min': 'Minimum Blood Glucose-Random(mg/dL)' ,
    'rbg_max': 'Maximum Blood Glucose-Random (mg/dL)', 'wt_min': 'Minimum Weight (lbs)', 'wt_max': 'Maximum Weight (lbs)',
    'temp_min': 'Minimum Temperature (F)', 'temp_max': 'Maximum Temperature (F)', 'ahi': 'Avg AHI (events/hr)', 'useage': 'Avg Usage (hrs:mins)',
    'leak': 'Avg Unintentional Leak (l/min)', 'cai': 'Avg CA Index', 'br': 'Avg breath rate (BPM)', 'ptb': 'Avg patient triggered breaths (%)',
    'vte': 'Avg Vte (ml)', 'mv': 'Avg minute ventilation (l/min)', 'ipap': 'Avg Pressure IPAP (cmH₂O)', 'ipap_pct': 'Avg 90% IPAP (cmH₂O)',
    'epap': 'Avg Pressure EPAP (cmH₂O)', 'epap_pct': 'Avg Pressure 90% EPAP (cmH₂O)', 'pbt': 'Periodic Breathing Time (ms)',
    'cpap': 'Avg Pressure CPAP (cmH₂O)', 'cpap_pct': 'Avg Pressure 90% CPAP (cmH₂O)'
  };
  public vital_kg_unit_mapper = {'kg': 'Weight (Kg)', 'lbs': 'Weight (lbs)'};
  public vital_temp_unit_mapper = {'temp': 'Temperature', 'temp_min': 'Minimum Temperature', 'temp_max': 'Maximum Temperature'};
  public custom_graph_vital_mapper = {'temp': 'Temperature (F)', 'pulse': 'Pulse (bpm)', 'rr': 'Respiration (breaths)',
    'wt': 'Weight (Kg)', 'ht': 'Height (Inches)', 'bmi': 'Body Mass Index (lb/m2)', 'spo2': 'SPO2 (%)',
    'rbg': 'BG-R (mg/dL)', 'fbg': 'BG-F (mg/dL)', 'nprs': 'Pain Scale (1-10)',
    'sbp': 'BP-S (mmHg)', 'dbp': 'BP-D (mmHg)',
    'sbp_min': 'Min BP-S', 'sbp_max': 'Max BP-S',
    'dbp_min': 'Min BP-D', 'dbp_max': 'Max BP-D',
    'pulse_min': 'Min Pulse', 'pulse_max': 'Max Pulse', 'spo2_min': 'Min SPO2',
    'spo2_max': 'Max SPO2', 'fbg_min': 'Min BG-F',
    'fbg_max': 'Max BG-F', 'rbg_min': 'Min BG-R',
    'rbg_max': 'Max BG-R', 'wt_min': 'Minimum Weight (lbs)', 'wt_max': 'Maximum Weight (lbs)',
    'temp_min': 'Min Temperature (F)', 'temp_max': 'Maximum Temperature (F)'

  };
  constructor(private _tokenService: CustomHttpClient, public userService: UserService) { }

  getPatientLatestVitals(patientId: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patient_id', patientId);
    return this._tokenService.get('vitals/patient_latest_vitals', { params: args})
      .toPromise()
      .then(response => response);
  }
  getVitalPrintData(patientId: any, patientVitalId: any, appointmentId?: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patient_id', patientId);
    args = args.set('patient_vital_id', patientVitalId);
    if (appointmentId) {
      args = args.set('appointment_id', appointmentId);
    }
    return this._tokenService.get('vitals/patient_vital_print_data', {params: args})
      .toPromise()
      .then(response => response);
  }
  getPatientVitals(patientId: any, date?: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patient_id', patientId);
    if (date) {
      args = args.set('date', date);
    }
    return this._tokenService.get('vitals/patient_vitals', { params: args})
      .toPromise()
      .then(response => response);
  }
  getPatientVitalsWithPagination(patientId: any, current_page: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patient_id', patientId);
    args = args.set('page', current_page);
    return this._tokenService.getWithHeaders('vitals/patient_vitals_with_pagination', {observe: 'response' , params: args, responseType: 'json' })
      .toPromise()
      .then(response => {
        return {
          vitals: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }
  getAppointmentVitals(appointmentId: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('appointment_id', appointmentId);
    return this._tokenService.get('vitals/appointment_vitals', { params: args})
      .toPromise()
      .then(response => response);
  }
  getAppointmentLatestVitals(appointmentId: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('appointment_id', appointmentId);
    return this._tokenService.get('vitals/appointment_latest_vitals', { params: args})
      .toPromise()
      .then(response => response);
  }
  getPatientVitalEntries(patientId: any, short: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patient_id', patientId);
    args = args.set('short', short);

    return this._tokenService.get('vitals/patient_vital_entries', { params: args})
      .toPromise()
      .then(response => response);
  }
  vitalEntriesOfLatestPatientVital(patientId: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patient_id', patientId);

    return this._tokenService.get('vitals/vital_entries_of_latest_patient_vital', { params: args})
      .toPromise()
      .then(response => response);
  }
  create(patientVital: PatientVital) {
    patientVital.creator_id = this.userService.current_user.id
    return this._tokenService
      .post('vitals', patientVital.to_params())
      .toPromise()
      .then(res => res)
  }
  save(patientVital: PatientVital): Promise<any> {
    if (patientVital.is_new_record()) {
      return this.create(patientVital);
    }
    else {
      return this.update(patientVital);
    }
  }

  update(patientVital: PatientVital) {
    patientVital.updater_id = this.userService.current_user.id;
    return this._tokenService
      .put(patientVital.get_url(), patientVital.to_params())
      .toPromise()
      .then(res => res)
  }

  getVitals(patientId: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patient_id', patientId);
    return this._tokenService.get('vitals/', {params: args})
      .toPromise()
      .then(response => response);
  }

  getVital(vital: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('id', vital);
    return this._tokenService.get('vitals/patient_vital/', {params: args})
      .toPromise()
      .then(response => response);
  }

  getLatestVital() {
    return this.load_latest_vital
  }

  getPatientCustomizedVitalsForRange(patientId: any): Promise<any> {
    let args = new HttpParams();
    args = args.set('patient_id', patientId);
    return this._tokenService.get('vitals/customize_vitals_ranges', {params: args})
      .toPromise()
      .then(response => response);
  }

  createPatientCustomizedVitalsForRange(vitals: Vital[]) {
    return this._tokenService
      .post('vitals/customized_vitals', {vitals: vitals})
      .toPromise()
      .then(res => res)
  }

  updatePatientCustomizedVitalsForRange(vital: Vital) {
    return this._tokenService
      .put('vitals/customized_vital/' + vital.id, {id: vital.id , vital: vital})
      .toPromise()
      .then(res => res)
  }

  getVitalEntryDates(month: string, patient_id: string): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('month', month);
    params = params.set('patient_id', patient_id);
    return this._tokenService.get('vitals/patient_vital_calendar_data', {params: params})
      .toPromise()
      .then(response => response);
  }

  getVitalsGraphData(filter) {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService
      .get('vitals/patient_vital_graph_data', { params: params})
      .toPromise().then(response => response)
  }

  deletePatientVital(id: any) {
    return this._tokenService
      .delete('vitals/' + id)
      .toPromise()
      .then(response => response)
  }

  cToF(celsius) {
    const cTemp = celsius;
    const cToFahr = cTemp * 9 / 5 + 32;
    return cToFahr.toFixed(2);
  }

  fToC(fahrenheit) {
    const fTemp = fahrenheit;
    const fToCel = (fTemp - 32) * 5 / 9;
    return fToCel.toFixed(2);
  }

  kgTolb(kg) {
    const lb = kg * 2.2046;
    return lb.toFixed(2);
  }

  lbToKg(lb) {
    const kg = lb / 2.2046;
    return kg.toFixed(2);
  }

  vitalUnit(vital: Vital) {
    switch (vital.short.toLowerCase()) {
      case 'wt':
        return 'LB';
      case 'bmi':
        return 'Lb/m2';
      case 'ht':
        return 'In';
      case 'useage':
        return 'hrs:mins'
      case 'fbg':
      case 'rbg':
        return 'mg/dL'
      default:
        return vital.unit;
    }
  }

  setHrAndMin(value: any) {
    let hrs = parseInt(String(Number(value)));
    let min = Math.round((Number(value) - hrs) * 60);
    return hrs + ':' + min;
  }
}
