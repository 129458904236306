<div class="todaysPatient-list" *ngIf="appointments">
  <ng-container *ngIf="appointments && appointments.length == 0"><app-no-result-found></app-no-result-found></ng-container>
  <div class="list" *ngFor="let appointment of appointments;let i = index">
    <div class="col-sm-3 picName-area">
      <div class="cell-holder">
        <div class="pic img-holder text-center">
          <img class="img-circle thumb64" [src]="appointment.patient.profile_pic" alt="Image"/>
          <div class="status-label">
            <label *ngIf="appointment.mobile_online_status" [ngClass]="_appointment_service.show_status_class(appointment.mobile_online_status)">
              {{_appointment_service.show_status_class(appointment.mobile_online_status)}}
              <i class="fa icon-arrow-up"></i>
            </label>
            <div class="popover fade top">
              <div class="arrow" style="left:50%;"></div>
              <h3 class="popover-title">Login Status</h3>
              <div class="popover-content pt0">
                <label *ngIf="appointment.mobile_online_status" [ngClass]="_appointment_service.show_status_class(appointment.mobile_online_status)">
                  Mobile {{_appointment_service.show_status_class(appointment.mobile_online_status)}}
                </label>
                <label *ngIf="appointment.portal_online_status" [ngClass]="_appointment_service.show_status_class(appointment.portal_online_status)">
                  Portal {{_appointment_service.show_status_class(appointment.portal_online_status)}}
                </label>
              </div>
            </div>
          </div>
          <a *ngIf="appointment.patient.programmeable" href="javascript:void(0)" class="eligible-icon" tooltip="Eligible for Program">
            <img class="img-responsive" src="/assets/img/eligible-icon.png" alt="Image"/>
          </a>
        </div>
        <h4 class="m0 mb fw-500 fs16"><a (click)="add_to_tabs(appointment)">{{appointment.patient.name}}</a></h4>
        <h6 class="m0 mb text-capitalize">
          <i class="mr-sm" [ngClass]="(appointment.patient.user.gender == 'female')?'fa icon-user-female':'fa icon-user'"></i>
          {{appointment.patient.customize_dob}}, &nbsp;
          {{appointment.patient.show_age_and_gender()}}
        </h6>
        <ng-container *ngIf="appointment.patient.mobile_number_value">
          <a href="javascript:void(0);" class="h6 m0" (click)="phoneService.initiatePhoneCall(appointment.patient.mobile_number_value,  {name: appointment.patient.name, pic: appointment.patient.profile_pic})">
            <i class="fa icon-screen-smartphone mr-sm"></i> {{appointment.patient.mobile_number_value | mask: ' (000) 000-0000'}}
          </a>
        </ng-container>
        <ng-container *ngIf="appointment.patient.landline_number_value">
          <a href="javascript:void(0);" class="h6 m0 mt" (click)="phoneService.initiatePhoneCall(appointment.patient.landline_number_value,  {name: appointment.patient.name, pic: appointment.patient.profile_pic})">
            <i class="fa icon-phone mr-sm"></i> {{appointment.patient.landline_number_value | mask: ' (000) 000-0000'}}
          </a>
        </ng-container>
        <ng-container>
          <app-patient-communication [patient]="appointment.patient"></app-patient-communication>
        </ng-container>
      </div>
    </div>
    <div class="col-sm-1 border-col time-area">
      <div class="cell-holder text-center pl-sm pr-sm"  [attr.title]="appointment.timeZone">
        <h4 class="mt0 fs16 fw-400">{{appointment.appointment_time_only}}</h4>
        <h5 class="m0 fw-300">{{ appointment.slot_size }} Mins</h5>
        <i *ngIf="appointment.appointment_type === 'video' || appointment.appointment_type === 'virtual_visit'" class="fa icon-camrecorder border-box"></i>
        <i *ngIf="appointment.appointment_type === 'phone_visit'" class="fa icon-call-in border-box"></i>
        <i *ngIf="appointment.appointment_type === 'office_visit'" class="fa fa-building-o border-box"></i>
        <i *ngIf="appointment.appointment_type === 'Book'" class="fa fa-building-o border-box"></i>
      </div>
    </div>
    <div class="col-sm-5 border-col">
      <div class="cell-holder pl-sm pr-sm">
        <div class="row mb-sm" *ngIf="appointment.service">
          <div class="col-sm-3 col-xs-12"><label class="field-label">Reason of Visit</label></div>
          <div class="col-sm-9 col-xs-12">{{appointment.service.name}}</div>
        </div>
        <div class="row mb-sm">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Appointment</label></div>
          <div class="col-sm-9 col-xs-12" [attr.title]="appointment.timeZone">
            {{appointment.appointment_time}} with
            <span class="pointer text-primary fw-500" (click)="showDropDown(appointment);"  *ngIf="appointment.doctor || !appointment.appointment_with">
                {{appointment?.doctor?.complete_name}}</span><span class="fw-500 pointer">
            </span>
            <span class="text-primary fw-500" *ngIf="!appointment.doctor">
              <a (click)="showDropDown(appointment);" >any available doctor</a>
            </span>
            <ng-container *ngIf="show_doctor_dropdown && selected_appointment_id == appointment.id">
              <select #provider class="form-control" (change)="providerSelected(provider.value)">
                <option value="">Doctors</option>
                <option *ngFor="let provider of providers" [value]="provider.id">
                  {{provider.firstname}} {{provider.lastname}}
                </option>
              </select>
            </ng-container>
          </div>
        </div>
        <div class="row mb-sm" *ngIf="appointment.amenity_name">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Service</label></div>
          <div class="col-sm-9 col-xs-12">{{appointment.amenity_name}}</div>
        </div>
        <div class="row mb-sm">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Location</label></div>
          <div class="col-sm-9 col-xs-12">{{appointment?.location?.address.line_1_line_2()}} {{appointment?.location?.address.city_state_zip()}}</div>
        </div>
        <div class="row mb-sm">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Status</label></div>
          <div class="col-sm-9 col-xs-12">
            <div class="media">
              <div class="media-left">
                <span class="text-center status-colors" [ngClass]="appointment.status_id">{{capitalize(appointment.status_id)}}</span>
              </div>
              <small class="media-body vl-m">
                at {{ appointment.log_time | date: 'hh:mm a MMM dd, y':appointment.time_zone_offset }}
                &nbsp;
                <ng-container *ngIf="appointment.actioned_by">by {{ appointment.actioned_by}}</ng-container>
              </small>
            </div>
          </div>
        </div>
        <div class="row mb-sm" *ngIf="appointment.notes_for_doctor">
          <div class="col-sm-3 col-xs-12 pr0"><label class="field-label">Note</label></div>
          <div class="col-sm-9 col-xs-12">{{capitalize(appointment.notes_for_doctor)}}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-1 border-col time-sec">
      <div class="cell-holder text-center date-counter pl-sm pr-sm">
        <ng-container *ngIf="appointment.status_id == 'checkedin'">
          <h5 class="fs12 m0 mb-sm">Waiting</h5>
          <div class="waiting-counter text-center">
            <div class="minutes-cont"><span class="counter">{{ appointment.waiting_time() }}</span> Mins</div>
            <round-progress [color]="wait_class(appointment.waiting_time())" [background]="'#eaeaea'" [stroke]="4" [current]="appointment.waiting_time()" [max]="60" [radius]="30"></round-progress>
          </div>
        </ng-container>
        <ng-container *ngIf="appointment.appointment_charges > 0">
          <div class="clearfix fs12">
            <span class="text-primary">{{appointment.appointment_charges | number}}</span>
            <span class="text-danger">({{appointment.appointment_balance | number}})</span>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="appointment">
      <div class="col-sm-3 border-col btn-area" (click)="$event.stopPropagation()" >
        <div class="cell-holder pl-sm pr-sm">
          <app-status-actions [app_type]="app_type" [appointment]="appointment" (edited_appointment)="change_status($event)" ></app-status-actions>
        </div>
      </div>
    </ng-container>
  </div>
</div>
