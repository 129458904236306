import { Component, OnInit } from '@angular/core';
import { Tab} from '../../../tabs/tab';
import {TabsService} from '../../../services/tabs.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  constructor(public tabs_service: TabsService) { }

  ngOnInit() {
    this.tabs_service.register('tasks');
  }

}
