<div *ngIf="practice && practice.loaded" class="main-wrapper clearfix">

  <div *ngIf="(edit_practice && practice)"><new-practice [practice]="practice" (edit_practice)="resetEditPractice($event)"></new-practice></div>

  <div *ngIf="edit_practice == false">
    <h3 class="m0">Profile</h3>
    <hr class="hr-line mt">
  </div>

  <div *ngIf="(practice && !edit_practice)" class="mb-xl">
    <div class="row mb-lg" permittedClass [condition]="practice_permissions">
      <div class="col-xs-9"><h3 class="m0 mb text-primary vl-m"><i class="fa fa-hospital-o"></i> {{practice.name}}</h3></div>
      <div class="col-xs-3 text-right">
        <a *userCan="practice_permissions" class="btn btn-primary btn-outline" (click)="editPractice()" >
          <i class="fa fa-pencil mr-sm"></i> Edit
        </a>
      </div>
      <div class="col-xs-4">
        <div class="media">
          <div class="media-left"><em class="fa fa-map-marker"></em></div>
          <div class="media-body">{{practice.client.address.line_1_line_2()}}<br>{{practice.client.address.city_state_zip()}}</div>
        </div>
      </div>
      <div class="col-xs-4">
        <div *ngIf="practice.phone?.value">
          <a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(practice.phone?.value,
                  {name: (practice.requester_firstname + ' ' + practice.request_lastname),
                  pic: practice?.profile_pics})">
            <em class="fa icon-phone"></em>&nbsp; {{practice.phone?.value | mask: '(000) 000-0000'}}
          </a>
        </div>
        <div *ngIf="practice.fax?.value"><em class="fa fa-fax"></em>&nbsp; {{practice.fax?.value}}</div>
      </div>
      <div class="col-xs-4"><em class="fa icon-envelope"></em> &nbsp;{{practice.client.email}} <br>
        <a *ngIf="practice.url" href="{{practice.url}}" target="_blank"><i class="fa icon-link"></i> &nbsp;{{practice?.url}}</a>
      </div>
    </div>
    <div class="mb-lg">
      <h4 class="fw-500 fs16">{{practice.tagline}}</h4>
      <div [innerHTML]="practice.raw_message | safeHtml"></div>
      <h4 class="fw-500 fs16">Specialties</h4>
      <div class="row">
        <div class="col-xs-3" *ngFor="let specialty of practice.client.specialties;let i=index">
          <span *ngIf="specialty.id">{{specialty.description}}</span>
        </div>
      </div>
    </div>
    <h4 class="fw-500 fs16">Media</h4>
    <ul class="practice-attach-img clearfix p0 list-unstyled">
      <ng-container *ngIf="practice && practice.id && practice.gallery_images.length > 0">
        <li class="white-container-box pull-left p-lg mr-lg">
          <ng-container *ngFor="let gallery of practice.gallery_images ; let i = index">
            <div class="holder text-center" [ngClass]="{active: gallery.active}">
              <div class="thumbnail thumb80 p-relative">
                <a class="icon icon-del text-danger" href="javascript:void(0)" (click)="makeActiveInactive(gallery)"><i class="fa fa-trash"></i></a>
                <a class="icon icon-yes text-success" href="javascript:void(0)" (click)="removePicture(gallery,i)"><i class="fa fa-check"></i> Yes</a>
                <a class="icon icon-no text-danger" href="javascript:void(0)" (click)="makeActiveInactive(gallery)"><i class="fa fa-times"></i> No</a>
                <img [src]="gallery.img" alt="">
              </div>
              <h5 class="text-capitalize mb0">{{gallery.picture_type}}</h5>
            </div>
          </ng-container>
        </li>
      </ng-container>
      <li class="white-container-box pull-left p-lg mr-lg" *ngIf="practice.practice_banner">
        <div class="holder text-center" [ngClass]="{active: banner_active}">
          <div class="thumbnail thumb80 p-relative">
            <a class="icon icon-del text-danger" href="javascript:void(0)" (click)="make_banner_active_deactive()"><i class="fa fa-trash"></i></a>
            <a class="icon icon-yes text-success" href="javascript:void(0)" (click)="removeBanner(practice)"><i class="fa fa-check"></i> Yes</a>
            <a class="icon icon-no text-danger" href="javascript:void(0)" (click)="make_banner_active_deactive()"><i class="fa fa-times"></i> No</a>
            <img [src]="practice.practice_banner.modal?.img" alt="">
          </div>
          <h5 class="text-capitalize mb0">Banner</h5>
        </div>
      </li>
      <li class="active white-container-box pull-left p-lg mr-lg" *ngIf="practice.logo">
        <div class="active holder text-center" [ngClass]="{active: logo_active}">
          <div class="thumbnail thumb80 p-relative">
            <a class="icon icon-del text-danger" href="javascript:void(0)" (click)="make_logo_active_deactive()"><i class="fa fa-trash"></i></a>
            <a class="icon icon-yes text-success" href="javascript:void(0)" (click)="removeLogo(practice)"><i class="fa fa-check"></i> Yes</a>
            <a class="icon icon-no text-danger" href="javascript:void(0)" (click)="make_logo_active_deactive()"><i class="fa fa-times"></i> No</a>
            <img [src]="practice.logo" alt="">
          </div>
          <h5 class="text-capitalize mb0">Logo</h5>
        </div>
      </li>
    </ul>
    <div *ngIf="practice">
      <app-attachments *userCan="practice_permissions" (document_uploaded)="handle_image_upload($event)" [attachable_type]="'Practice'" [attachable_id]="practice.id"></app-attachments>
    </div>
  </div>

  <div *ngIf="practice.id">
    <app-contacts [practice_permissions]="practice_permissions" [practice]="practice"></app-contacts>
  </div>

</div>
