export class TimeLog {
  id: number;
  source_type: string;
  source_id: number;
  minutes: any;
  logger_id: any;
  logged_at: any;
  created_at: any;
  updated_at: any;
  logger_name: any;
  programme_type: string;

  load_from_json(json) {
    this.id = json.id;
    this.source_type = json.source_type;
    this.source_id = json.source_id;
    this.minutes = json.minutes;
    this.logger_id = json.logger_id;
    this.logged_at = json.logged_at;
    this.created_at = json.created_at;
    this.updated_at = json.updated_at;
    this.logger_name = json.logger_name;
    this.programme_type = json.programme_type;
    return this
  }

  to_params() {
    return {
      time_log_attributes: {
        id: this.id,
        source_type: this.source_type,
        source_id: this.source_id,
        minutes: this.minutes,
        logger_id: this.logger_id,
        programme_type: this.programme_type
      }
    }
  }

  to_params_for_time_log() {
    return {
      time_log: {
        id: this.id,
        source_type: this.source_type,
        source_id: this.source_id,
        minutes: this.minutes,
        logger_id: this.logger_id,
        programme_type: this.programme_type,
      }
    }
  }

}
