<div class="white-container-box clearfix p-sm" *ngIf="notes.length > 0">
  <div class="table-responsive">
    <table class="table table-bordered table-striped table-hover m0">
      <thead>
        <tr>
          <th style="min-width:180px;">Date/Time</th>
          <th>Notes</th>
          <th style="min-width:180px;">Added By</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let note of notes let i=index">
          <td><a (click)="selectNote(i);classicModal.show();">{{note.date_and_time}}</a></td>
          <td>{{note.comment}}</td>
          <td>{{note.added_by}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
    <div class="modal-dialog modal-md" style="height:324px;">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
          <h4 class="m0 text-center">Update Note</h4>
        </div>
        <div class="modal-body">
          <textarea cols="10" rows="6" class="form-control no-resize" [(ngModel)]="this.notes[selectedNote].comment"></textarea>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="updateNote();">Update</button>
          <button type="button" class="btn btn-inverse" (click)="classicModal.hide()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
