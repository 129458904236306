<ng-container *ngIf="!show_verification">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <h3 class="text-primary">Patient Sign-Up Using eMail</h3>
        <h5 class="text-muted">Enter your name, date of birth, email and password</h5>
        <div class="white-container-box p-xl mt-xl mb-xl">
          <form (ngSubmit)="save()" #UserForm="ngForm">
            <div class="row">
              <div class="col-md-2 col-sm-2 col-xs-6 form-group">
                <label>Title</label>
                <select name='title' class="form-control" [(ngModel)]="patient.title">
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                </select>
              </div>
              <div class="col-md-5 col-sm-5 col-xs-12 form-group">
                <label>First Name*</label>
                <input type="text" class="form-control" id="first_name" [(ngModel)]="patient.user.first_name" name="firstname" required>
                <div *ngIf="customValidations.formErrors.firstname" class = "text-danger">{{ customValidations.formErrors.firstname }}</div>
              </div>
              <div class="col-md-5 col-sm-5 col-xs-12 form-group">
                <label>Last Name*</label>
                <input type="text" class="form-control" id="last_name" [(ngModel)]="patient.user.last_name" name="lastname" required>
                <div *ngIf="customValidations.formErrors.lastname" class = "text-danger">{{ customValidations.formErrors.lastname }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="clearfix signup-dob">
                  <app-date-of-birth [dob]="patient.user.dob" [dob_stage]="patient.user.dob_stage" (dob_and_stage)="patient.handle_dob_and_dob_stage($event)"></app-date-of-birth>
                  <div *ngIf="patient.year_error" class="text-danger">Invalid Date of Birth</div>
                </div>
              </div>
              <div class="col-sm-6 form-group">
                <label>Gender*</label>
                <div>
                  <select #selectedGender name="gender" [(ngModel)]="patient.user.gender" (change)="changeGender(selectedGender.value)" class="form-control">
                    <option [value]="'Select Gender'">Select</option>
                    <option [value]="'male'">Male</option>
                    <option [value]="'female'">Female</option>
                    <option [value]="'trans'">Trans</option>
                    <option [value]="'trans_male'">Transgender male/Trans man/Female-to-male</option>
                    <option [value]="'trans_female'">Transgender female/Trans woman/Male-to-female</option>
                    <option [value]="'non_binary'">Genderqueer/Non-binary</option>
                    <option [value]="'decline'">Decline to answer</option>
                    <option [value]="'other'">Other</option>
                  </select>
                  <div *ngIf="gender_error" class="text-danger">Select Gender</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12 form-group">
                <label>Mother's Maiden Name</label>
                <input type="text" class="form-control" id="mother_name" [(ngModel)]="patient.mother_name"
                       name="mother_name" maxlength="15" pattern="[^\s]+">
                <div *ngIf="customValidations.formErrors.mother_name" class = "text-danger">{{ customValidations.formErrors.mother_name }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 form-group">
                <label>Email Address*</label>
                <input type="text" class=" form-control" id="email" [(ngModel)]="patient.user.email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                       name="email" required>
                <div *ngIf="customValidations.formErrors.email" class = "text-danger">{{ customValidations.formErrors.email }}</div>
              </div>
              <div class="col-sm-6 form-group">
                <label>Mobile Number*</label>
                <input  mask="(000) 000-0000"  [autocomplete]="'off'" class="form-control" id="phone" [(ngModel)]="patient.user.phone.value" name="phone" required>
                <div *ngIf="customValidations.formErrors.phone" class="text-red">{{ customValidations.formErrors.phone }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 form-group">
                <label>Password*</label>
                <input id="password" matInput #password type="password" class="form-control"
                       [(ngModel)]="patient.user.password" name="password" maxlength="25" minlength="8" required>
                <mat-password-strength #passwordComponent (onStrengthChanged)="checkPasswordStrength($event)"
                                       [password]="password.value">
                </mat-password-strength>
                <mat-slide-toggle [color]="'primary'" #toggleInfo>Show Password Details</mat-slide-toggle>
                <mat-password-strength-info *ngIf="toggleInfo.checked"
                                            [lowerCaseCriteriaMsg]="'At least one lowercase character.'"
                                            [upperCaseCriteriaMsg]="'At least one uppercase character.'"
                                            [digitsCriteriaMsg]="'At least one digit character.'"
                                            [specialCharsCriteriaMsg]="'At least one special character.'"
                                            [minCharsCriteriaMsg]="'At least 8 characters.'"
                                            [enableScoreInfo]="true"
                                            [passwordComponent]="passwordComponent">
                </mat-password-strength-info>
              </div>
              <div class="col-sm-6 form-group">
                <label>Confirm Password*</label>
                <input id="password_confirmation"  type="password" class="form-control"
                       [(ngModel)]="patient.user.password_confirmation" name="password_confirmation"  required>
                <div *ngIf="show_error" class="text-danger">Password does not match</div>
                <input type="hidden" name="can_login"  [(ngModel)]="patient.can_login" value="true">
              </div>
            </div>
            <hr class="hr-line mb0">
            <p class="fs12 text-muted">
              *Your security is important to us. We encrypt, safeguard and secure your personal information and never
              share without permission. Please view our
              <a href="/p&p" class="btn-link" target="_blank">Privacy Policy</a>
              and
              <a href="/t&c" class="btn-link" target="_blank">Terms of Use</a>
              for more information.
            </p>
            <p class="fs12 text-muted">
              *By signing up with us, you agree with our End-user License Agreement (
              <a href="/wellwink_eula.pdf" class="btn-link" target="_blank">EULA</a>
              ).
            </p>
            <p>
            <re-captcha [(ngModel)]="declarativeFormCaptchaValue" name="captcha" required
                        (resolved)="addTokenLog('Declarative form mode resolved', $event)">
            </re-captcha>
            </p>
            <button type="submit" class="btn btn-primary btn-lg fs20"  [disabled]="!UserForm.form.valid || in_progress">Continue</button>
          </form>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 patient-banner hidden-sm hidden-xs">
        <img class="img-responsive" src="assets/img/patient-banner-img.png"/>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="show_verification">
  <app-signup-presetup [patient]="patient" (verified)="handle_patient_verification($event)"></app-signup-presetup>
</ng-container>
