import { Component, OnInit, Input } from '@angular/core';
import {ReminderTemplate} from '../reminder-template';
import {Reminder} from '../reminder';
import {RemindersService} from '../reminders.service';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-reminder-listing',
  templateUrl: './reminder-listing.component.html',
  styleUrls: ['./reminder-listing.component.scss']
})
export class ReminderListingComponent implements OnInit {
  public filter: Object = {reminder_template_id: '', patient_name: '', page: 1, from_date: '' , to_date : '', status: '', outreach_type: ''};
  public bsValue = new Date();
  reminders: Reminder[] = [new Reminder()]
  public totalItems
  show_popup = false;
  @Input() template_id
  show_sms_popup = false;
  selected_reminder: Reminder;
  popupReminder : Reminder = new Reminder();

  constructor(public reminder_template_service: RemindersService) { }

  ngOnInit(): void {
    this.filter['reminder_template_id'] = this.template_id
    this.getReminders()
  }

  getReminders() {
    this.reminder_template_service.getReminders(this.filter).then(resp => {
      this.reminders = resp.reminders.map(reminder => new Reminder().load_from_json(reminder));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
    });
  }

  search() {
    this.getReminders()
  }

  pageChanged($event: PageChangedEvent) {
    this.filter['page'] = $event.page;
    this.getReminders();
  }
  showPopup(reminder){
    this.popupReminder = reminder
    this.show_popup = true
  }
  checkAnyLetter(string){
    let regExp = /[a-zA-Z]/g;
    let testString = string;
    return (regExp).test(testString) ? true : false;
  }
}
