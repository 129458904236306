import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {routes} from './routes';
import  {SharedModule} from  '../../shared/shared.module'
import {LoginModule} from  '../../shared/login/login.module'
import { TopNavbarComponent } from '../top-navbar/top-navbar.component'
import { FooterComponent } from '../footer/footer.component'
import { FaqComponent } from '../faq/faq.component';
import { ForumComponent } from '../forum/forum.component';
import { BlogComponent } from '../blog/blog.component';
import { ProblemComponent } from '../problem/problem.component';
import { DoctorBlockComponent } from '../doctor-block/doctor-block.component';
import { HealthPortalPracticeComponent } from '../practice/practice.component';
import { LocationsComponent } from '../locations/locations.component'
import { ContactsComponent } from '../contacts/contacts.component';
import { HealthPortalDoctorComponent } from '../doctor/doctor.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AppointmentService } from '../../services/appointment.service';
import { PatientService } from '../../services/patient.service';
import { PatientRelativeFormComponent } from './patient-relative-form/patient-relative-form.component';
import { NonLoggedinUserComponent } from './non-loggedin-user/non-loggedin-user.component'
import { LoginFormComponent } from './login-form/login-form.component';
import { AnnouncerComponent } from '../announcer/announcer.component';
import { SearchComponent } from '../search';
import { LayoutComponent } from '../layout';
import { HomeComponent } from './home';
import { InvitationsComponent } from './home/invitations';
import { RegisterPracticeComponent } from './home/register-practice';
import {CanActivatePatientProfile} from '../../services/user.service';
import {VerificationBannerComponent} from '../verification-banner/verification-banner.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import {NgxMaskModule} from 'ngx-mask'
import {NgSelectModule} from '@ng-select/ng-select';
import {VirtualDoctorsListingComponent} from '../virtual-doctors-listing/virtual-doctors-listing.component';
import {PricingInfoComponent} from './home/pricing-info/pricing-info.component';
import {PublicContentPagesModule} from '../../public-content-pages/public-content-pages.module';
import {VideoChatModule} from '../../video-chat/video-chat.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TasksModule} from '../../routes/practice-site/tasks/tasks/tasks.module';
import {MatPasswordStrengthModule} from '@angular-material-extensions/password-strength';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {PatientEventNotificationsDropdownComponent} from '../top-navbar/patient-event-notifications-dropdown/patient-event-notifications-dropdown.component';
import {HealthCalendarEventViewComponent} from '../patient-profile/health-calendar/health-calendar-event-view/health-calendar-event-view.component';
import {HealthCalendarEventTooltipComponent} from '../patient-profile/health-calendar/health-calendar-event-tooltip/health-calendar-event-tooltip.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { PatientHomeComponent } from './patient-home/patient-home.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';

@NgModule({
  imports: [NgxMaskModule.forRoot(), GoogleMapsModule,
    SharedModule, RouterModule.forRoot(routes), LoginModule, BsDropdownModule.forRoot(),
    GalleryModule,
    NgSelectModule,
    PublicContentPagesModule,
    VideoChatModule,
    DragDropModule,
    MatPasswordStrengthModule,
    MatSlideToggleModule,
    TasksModule
  ],
  declarations: [LayoutComponent, SearchComponent, HomeComponent, InvitationsComponent,
    RegisterPracticeComponent, AnnouncerComponent, TopNavbarComponent, DoctorBlockComponent,
    HealthPortalPracticeComponent, LocationsComponent, ContactsComponent, HealthPortalDoctorComponent,
    AppointmentsComponent, PatientRelativeFormComponent, NonLoggedinUserComponent, LoginFormComponent,
    FooterComponent, FaqComponent, ForumComponent, BlogComponent, ProblemComponent, PricingInfoComponent,
    VerificationBannerComponent, VirtualDoctorsListingComponent, PatientEventNotificationsDropdownComponent,
    HealthCalendarEventViewComponent, HealthCalendarEventTooltipComponent, PatientHomeComponent, MobileAppComponent],
  providers: [AppointmentService, PatientService, CanActivatePatientProfile],
  exports: [
    GalleryModule, RouterModule, LayoutComponent, SearchComponent, PublicContentPagesModule,
    HomeComponent, InvitationsComponent, RegisterPracticeComponent, DoctorBlockComponent,
    BsDropdownModule, HealthCalendarEventViewComponent, HealthCalendarEventTooltipComponent
  ]
})

export class RoutesModule {
}
