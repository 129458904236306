import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {PatientService} from '../../../services/patient.service';
import {Patient} from '../../../health/doctor/patient';
import {Observable} from 'rxjs';
import {LoaderService} from '../../../services/loader.service';

@Component({
  selector: 'app-patients-filter',
  templateUrl: './patients-filter.component.html',
  styleUrls: ['./patients-filter.component.scss']
})
export class PatientsFilterComponent implements OnInit {

  public patients: Patient[];
  public filter: Object = {token: '', page: 1, patient_type: '0', can_login: '0'};
  @Output() sender: EventEmitter<any> = new EventEmitter();
  public totalItems;

  constructor(public loader_service: LoaderService, private _PatientService: PatientService) {}

  ngOnInit() {
    this.getAllPatients();
  }

  getAllPatients() {
    this.loader_service.show();
    this._PatientService.getPatients(this.filter).subscribe(resp => {
    this.patients = resp.body.map(patient => new Patient().load_from_json(patient));
      this.filter['id'] = '';
      if (resp.headers) {
        this.totalItems = resp.headers.get('X-Total');
        this.filter['page'] = resp.headers.get('X-Page');
      }
      this.loader_service.hide();
    }, error => {
      console.log(error);
      this.loader_service.hide();
    })
  }

  patientGetter(patient) {
    this.sender.emit(patient);
  }

  pageChanged(page) {
    this.filter['page'] = page;
    this.getAllPatients();
  }

  search() {
    this.filter['page'] = 1;
    this.getAllPatients();
  }

  handle_user(e: any) {
    console.log(e)
  }
}
