import {Component, Input, OnInit} from '@angular/core';
import {Practice} from '../practices/practice';
import {EmailConfiguration} from './email-configuration';
import {PracticesService} from '../../../services/practices.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {UserService} from '../../../services/user.service';
import {User} from '../../users/user';
import {LoaderService} from "../../../services/loader.service";

@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss']
})
export class EmailConfigurationComponent implements OnInit {
  @Input() practice: Practice;
  emailConfiguration: EmailConfiguration =  new EmailConfiguration();
  editConfiguration = false;
  message = '';
  delete_message = '';
  c_user: User;

  constructor(public loaderService: LoaderService,private currentUser: UserService , private practicesService: PracticesService,
              public globalErrorHandlerService: GlobalErrorHandlerService, ) { }

  ngOnInit(): void {
    this.c_user = this.currentUser.current_user;
    this.practicesService.getPracticeEmail(this.practice.id).then(practiceEmail => {
      if (practiceEmail && practiceEmail.id) {
        this.emailConfiguration = new EmailConfiguration().load_from_json(practiceEmail)
      }
      else {
        this.emailConfiguration = new EmailConfiguration()
      }
    });
  }

  saveTwilioNumber(practice) {
    this.practicesService.save(practice).then(res => {
      practice.load_from_json(res)
    }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error );
  }
  save() {
    this.loaderService.small_loder_show();
    if (this.emailConfiguration.id) {
      this.practicesService.update_practice_email_configuration(this.emailConfiguration).then(resp => {
        if (resp.status) {
          this.editConfiguration = ! this.editConfiguration
          this.loaderService.small_loder_hide();
          this.message = 'Email successfully updated.';
          setTimeout(() => {
            this.message = null;
          }, 5000)
        } else {
          this.loaderService.small_loder_hide();
          this.globalErrorHandlerService.error = resp.error.error;
        }
        }
      ).catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      });
    }
    else {
      this.emailConfiguration.practice_id = this.practice.id
      this.practicesService.save_practice_email_configuration(this.emailConfiguration).then(resp => {
          this.loaderService.small_loder_hide();
          this.emailConfiguration = this.emailConfiguration.load_from_json(resp);
        }
      ).catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      });
    }
  }

  removeEmailConfiguration(){
    this.loaderService.show();
    this.practicesService.removePracticeEmailConiguration(this.emailConfiguration).then(resp => {
      this.loaderService.hide();
      this.emailConfiguration = new EmailConfiguration();
      this.delete_message = 'Email Deleted successfully.';
      setTimeout(() => {
        this.delete_message = null;
      }, 5000)
      }
    ).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

}
