import { Component, OnInit } from '@angular/core';
import {ProgramService} from '../../program.service';
import {LoaderService} from '../../../services/loader.service';
import {ProgramEnrollment} from '../../eligible-patient';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';

@Component({
  selector: 'app-enrolment-patient-confirmation',
  templateUrl: './enrolment-patient-confirmation.component.html',
  styleUrls: ['./enrolment-patient-confirmation.component.scss']
})
export class EnrolmentPatientConfirmationComponent implements OnInit {

  constructor(public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService,
              public programService: ProgramService) { }

  ngOnInit(): void {
  }


  confirmEnrollment() {
    this.loaderService.show();
    this.programService.confirmEnrollment(this.programService.currentProgramEnrollmentObject.id).then(resp => {
      this.programService.enrolledPatientListener.next(resp.patient);
      this.programService.currentProgramEnrollmentObject = new ProgramEnrollment().load_from_json(resp);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }


}
