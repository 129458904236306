import {Client, Practice} from '../../practices/practices/practice'
import {Attachment} from '../attachments/attachment';
import {Physician} from '../../../health/models/physician';
import baseApiUrl from './../../../globals';
import {Location} from '../../../routes/practices/locations/location';
import {BaseModel} from '../../../shared/base';
import {Image} from '@ks89/angular-modal-gallery';
import {PublicReviewLink} from '../../../reviews/public_review_link';
import {PatientReview} from '../../../reviews/patient-review';
import {AmenityAssociate} from '../amenity/amenity';

export class Doctor extends BaseModel {
  days_mapping = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
  }
  public profile_pic: Image = new Image(0, {img: baseApiUrl + '/missing.png'})
  public doctor_banners: string = baseApiUrl + '/missing.png';
  doctor_pics: Image[] = [];
  public profile_pic_id = 0;
  public averageRating = 0;
  averageExactRating = 0;
  public achievement_categories: AchievementCategory[];
  public achievement_categories_array: AchievementCategory[] = new Array();
  public calendars: Calendar[];
  public filtered_calendars = {}
  public calendars_array: Calendar[] = new Array();
  public block_calendars: BlockCalendar[];
  public block_calendars_array: BlockCalendar[] = new Array();
  public amenity_associators: AmenityAssociate[] = [];
  public years_experience = 0;
  public date_difference: any;
  public doctor_practice_visit_charges: any;
  id: any;
  gender = '';
  public degrees: any[] = [];
  data_fetched = false;
  firstname = '';
  lastname = '';
  name = '';
  suffix = '';
  prefix = '';
  designation = '';
  visit_charges = '';
  summary = '';
  cnic = '';
  regno = '';
  practices_name = '';
  full_name = '';
  specialties_name = '';
  doctor_email = '';
  doctor_phone_number = '';
  is_active: boolean;
  public show_profile_detail = false;
  public edit_doctor = false;
  public practice_id: number;
  client: Client = new Client();
  // attachments: Attachment = new Attachment();
  public attachments: Attachment[];
  practices: Practice[];
  acceptedPatientReviews: PatientReview[];
  totalPatientReviews: PatientReview[];
  average_score: number;
  physician: Physician = new Physician();
  location: Location = new Location();
  third_party_doctor_id: string;
  public_review_links: PublicReviewLink[] = [];
  video_consultation: string;
  public user_id: number;
  advance_booking_limit: string;
  amenities: any[];
  map_name_id(json) {
    this.id = json.id;
    this.name = json.name;
    return this;
  }

  get average_rating(): number {
    return 0;
  }

  validCalendars(from, to) {
    this.filtered_calendars = {};
    this.calendars_array.filter(calendar => new Date(calendar.from) <= to && new Date(calendar.to) >= from).sort((c1, c2) => {
      if (c1.day_starts_at > c2.day_starts_at) {
        return 1;
      }

      if (c1.day_starts_at < c2.day_starts_at) {
        return -1;
      }

      return 0;
    }).forEach((calendar) => {
      this.filtered_calendars[calendar.location_id] || (this.filtered_calendars[calendar.location_id] = [])
      this.filtered_calendars[calendar.location_id].push(calendar);
    });
    console.log('filtered calendar', this.filtered_calendars);
  }

  get complete_name() {
    if (this.suffix) {
      return `${this.prefix} ${this.firstname}  ${this.lastname}, ${this.suffix}`;
    }
    return `${this.prefix} ${this.firstname}  ${this.lastname}`;
  }

  getExperience() {
    if (this.doctors_achievements.length > 0) {
      let lowest = this.lowestExperienceYear();
      let highest = this.highestExperienceYear();
      if (lowest && highest) {
        return this.overAllExperienceInMonths(lowest, highest)
      } else {
        return 0
      }
    }
    return 0;
  }

  overAllExperienceInMonths(doc_start, doc_end) {
    let hash, start, end, starting_month, ending_month, date1, date2, noOfStartMonths, noOfEndMonths;

    hash = {
      'Jan': 0,
      'Feb': 1,
      'Mar': 2,
      'Apr': 3,
      'May': 4,
      'June': 5,
      'July': 6,
      'Aug': 7,
      'Sep': 8,
      'Oct': 9,
      'Nov': 10,
      'Dec': 11
    };

    start = parseInt(doc_start?.start_date);

    if (doc_end?.currently_present) {
      end = (new Date).getFullYear();
      ending_month = (new Date).getMonth();
    } else {
      end = parseInt(doc_end.end_date);
      ending_month = parseInt(hash[doc_end.end_month]);
    }
    starting_month = parseInt(hash[doc_start.start_month]);

    date1 = new Date(start, starting_month);
    date2 = new Date(end, ending_month, 28);

    noOfStartMonths = date1.getFullYear() * 12 + date1.getMonth();
    noOfEndMonths = date2.getFullYear() * 12 + date2.getMonth();

    this.date_difference = noOfEndMonths + 1 - noOfStartMonths;
    return this.date_difference;
  }

  degreeYearsRange(degree) {
    if (degree) {
      if (degree.start_date && degree.end_date) {
        return `${degree.start_date}-${degree.end_date}`;
      }
      if (degree.start_date) {
        return `${degree.start_date}`;
      }
    }
    return '';
  }

  getFilteredCalendarFor(location_id, day=undefined) {
    if (day && this.filtered_calendars[location_id]) {
      return this.filtered_calendars[location_id].filter(calendar => {
        return eval("calendar."+this.days_mapping[day.getDay()])
      })
    } else {
      return this.filtered_calendars[location_id];
    }
  }

  lowestExperienceYear() {
    return this.experienceAchievementsList.filter(e => e.start_date == Math.min.apply(Math, this.experienceAchievementsList.map(function (o) {
      return o.start_date
    })))[0];
  }

  highestExperienceYear() {
    return this.experienceAchievementsList.filter(e => e.currently_present == true).length > 0 ? this.experienceAchievementsList.filter(e => e.currently_present == true).pop() : this.experienceAchievementsList.filter(e => e.end_date == Math.max.apply(Math, this.experienceAchievementsList.map(function (o) {
      return o.end_date
    }))).pop();
  }

  get experienceAchievementsList() {
    return this.doctors_achievements.filter(e => e.category_name.toLowerCase() === 'experience');
  }

  getFormattedExperience() {
    let total_months = this.getExperience();
    let years = total_months / 12;
    let months = total_months % 12;
    let years_message = years > 1 ? 'Years ' : (years == 0 ? 'Yr ' : '')
    let months_message = months > 1 ? months + ' mos' : (months <= 0 ? '' : months + ' mo')
    if (months > 6) {
      return Math.ceil(years) + ' ' + years_message
    }
    return Math.floor(years) + ' ' + years_message
  }

  collapse() {
    this.show_profile_detail = false;
  }

  expand() {
    this.show_profile_detail = true;
  }

  add_doctor_achievement(e, doctor_achievement, type) {
    if (type == 1) {
      doctor_achievement.title = e.value;
    }
    if (type == 2) {
      doctor_achievement.institution_name = e.value;
    }
  }

  add_doctor_achievement_years(value, doctor_achievement) {
    doctor_achievement.title = value;
    doctor_achievement.institution_name = value;
  }

  load_from_json(json) {
    this.reminder_status = json.reminder_status;
    this.amenities = json.amenities;
    console.log(this);
    this.advance_booking_limit = json.advance_booking_limit;
    if (json.public_review_links) {
      this.public_review_links = json.public_review_links.map(l => new PublicReviewLink().load_from_json(l))
    }
    if (!json) {
      return this;
    }
    if (json.third_party_doctor_id) {
      this.third_party_doctor_id = json.third_party_doctor_id;
    }
    if (json.doctor_practice_visit_charges) {
      this.doctor_practice_visit_charges = json.doctor_practice_visit_charges;
    }
    if (json.accepted_reviews && json.accepted_reviews.length > 0) {
      this.acceptedPatientReviews = json.accepted_reviews.map(patientreview => new PatientReview().load_from_json(patientreview));
      console.log(this.acceptedPatientReviews);
    }
    if (json.patient_reviews && json.patient_reviews.length > 0) {
      this.totalPatientReviews = json.patient_reviews.map(patientreview => new PatientReview().load_from_json(patientreview));
    }
    json.images ? this.doctor_pics = json.images.map((data, index) => new Image(index, {
      img: data.img,
      description: data.picture_type
    })) : new Image(0, {img: baseApiUrl + '/missing.png'});
    this.profile_pic = new Image(0, {img: json.profile_pics});
    this.averageRating = json.averageRating;
    this.averageExactRating = json.averageExactRating;
    this.gender = json.gender;
    this.doctor_banners = json.banners;
    this.id = json.id;
    this.firstname = json.first_name;
    this.lastname = json.last_name;
    this.is_active = json.is_active;
    this.name = json.name;
    this.suffix = json.suffix;
    this.prefix = json.prefix;
    this.designation = json.designation;
    this.visit_charges = json.visit_charges;
    this.summary = json.summary;
    this.cnic = json.cnic;
    this.video_consultation = json.video_consultation;
    this.regno = json.regno;
    if (json.user_id) {
      this.user_id = json.user_id;
    }
    if (json.client) {
      this.client.load_from_json(json.client);
    }
    if (json.attachments) {
      let attachments = json.attachments.map(attachment => new Attachment().load_from_json(attachment));
      this.attachments = attachments.filter(e => e.picture_type !== 'profile_pic');
    }
    if (json.achievement_categories) {
      this.load_achievements(json.achievement_categories);
    }
    if (json.practices) {
      this.practices = json.practices.map(practice => new Practice().load_from_json(practice));
    }
    if (json.calendars) {
      this.load_calendars(json.calendars);
    }
    if (json.block_calendars) {
      this.block_calendars_array = json.block_calendars.map(block_calendar => new BlockCalendar().load_from_json(block_calendar));
    }
    if (json.amenity_associators) {
      this.amenity_associators = json.amenity_associators.map(associate_ammenity => new AmenityAssociate().load_from_json(associate_ammenity));
    }
    if (json.charges_range) {
      this.visit_charges = json.charges_range;
    }
    if (json.physician) {
      this.physician.load_from_json(json.physician, []);
    }
    if (json.location) {
      this.location.load_from_json(json.location);
    }
    return this;
  }

  index_load_from_json(json) {
    this.id = json.id;
    this.full_name = json.full_name;
    this.specialties_name = json.specialty_name;
    this.doctor_email = json.email;
    this.practices_name = json.practices;
    this.doctor_phone_number = json.phone;
    this.is_active = json.is_active;
    return this;
  }

  to_params() {
    return {
      doctor: {
        id: this.id,
        reminder_status: this.reminder_status,
        advance_booking_limit: this.advance_booking_limit,
        first_name: this.firstname,
        last_name: this.lastname,
        suffix: this.suffix,
        designation: this.designation,
        visit_charges: this.visit_charges,
        gender: this.gender,
        prefix: this.prefix,
        summary: this.summary,
        cnic: this.cnic,
        regno: this.regno,
        third_party_doctor_id: this.third_party_doctor_id,
        doctors_achievements_attributes: this.doctors_achievements,
        doctors_practices_attributes: [{practice_id: this.practice_id}],
        attachments_attributes: {id: this.profile_pic_id},
        client_attributes: this.client.to_params(),
        public_review_links_attributes: this.public_review_links.map(l => l.to_params()),
      }
    }
  }

  load_calendars(calendars) {
    this.calendars_array = [];
    this.calendars = calendars;
    for (let calendar of this.calendars) {
      let calendar_category = new Calendar();
      calendar_category.load_from_json(calendar);
      this.calendars_array.push(calendar_category);
    }
    this.validCalendars(new Date(), new Date());
  }

  load_achievements(achievement_categories) {
    this.achievement_categories_array = [];
    this.achievement_categories = achievement_categories;
    for (let achievement of this.achievement_categories) {
      let achievement_category = new AchievementCategory();
      achievement_category.load_from_json(achievement);
      this.achievement_categories_array.push(achievement_category);
    }
  }

  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }

  add_achievement(category) {
    category.doctors_achievements.push(new DoctorAchievement());
  }

  set_category_ids() {
    this.achievement_categories_array.forEach(category => category.doctors_achievements.forEach(ach => ach.category_id = category.id))
  }

  get doctors_achievements(): DoctorAchievement[] {
    this.set_category_ids();
    let data = [];
    this.achievement_categories_array.forEach(category => data = data.concat(category.doctors_achievements))
    return data.filter(ach => ach.achievements_title_id || ach.title != '');
  }

  remove_achievement(category, index) {
    category.doctors_achievements.splice(index, 1);
  }

  add_specialty(id) {
    this.client.add_specialty(id);
  }

  add_service(id) {
    this.client.add_service(id);
  }

  get phone(): any {
    return this.client.contact_numbers.filter(c => c.type == 'phone')[0];
  }

  set phone(value: any) {
    this.phone.value = value;
  }

  get fax(): any {
    return this.client.contact_numbers.filter(c => c.type == 'fax')[0]
  }

  set fax(value: any) {
    this.fax.value = value;
  }

  humanize() {
    return `${this.prefix} ${this.firstname} ${this.lastname}, ${this.suffix}`
  }

}

export class BlockCalendar extends BaseModel {
  id: number;
  name: string;
  is_active = false;
  from: any;
  to: any;
  start_time: string;
  end_time: string;
  location_id: number;
  location_name: string;
  calendarable_id: number;
  calendarable_type: string;

  load_from_json(json) {
    if (json.id) {
      this.id = json.id;
      this.name = json.name;
      this.is_active = json.is_active;
      this.from = new Date(json.from);
      this.to = new Date(json.to);
      this.start_time = json.start_time;
      this.end_time = json.end_time;
      this.location_id = json.location_id;
      this.location_name = json.location_name;
      this.calendarable_id = json.calendarable_id;
      this.calendarable_type = json.calendarable_type;
      return this;
    }
  }

  to_params() {
    return {
      block_calendar: {
        id: this.id,
        name: this.name,
        from: this.from.toDateString(),
        to: this.to.toDateString(),
        start_time: this.start_time,
        end_time: this.end_time,
        location_id: this.location_id,
        calendarable_id: this.calendarable_id,
        calendarable_type: this.calendarable_type,
      }
    }
  }

  validationCheck() {
    return (this.from > this.to) || (this.start_time > this.end_time);
  }

  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
}

export class Calendar extends BaseModel {
  id = 0;
  name = '';
  weekdays: any;
  is_active = false;
  monday = false;
  tuesday = false;
  wednesday = false;
  thursday = false;
  friday = false;
  saturday = false;
  sunday = false;
  from: any = '';
  to: any = '';
  day_starts_at = '';
  day_ends_at = '';
  break_starts_at = '';
  break_ends_at = '';
  appt_per_slop: any = '';
  slot_size: any = '';
  location_id = 0;
  location_name = '';
  calendarable_id = 0;
  calendarable_type = '';
  service_technician_id = 0;
  booking_type = 'both';
  virtual_visit_fee: string;
  office_visit_fee: string;
  phone_visit_fee: string;
  week_days_mapping = {
    0: 'monday',
    1: 'tuesday',
    2: 'wednesday',
    3: 'thursday',
    4: 'friday',
    5: 'saturday',
    6: 'sunday'
  }
  final_appointment_days: any = [];

  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }

  load_from_json(json) {
    this.booking_type = json.booking_type;
    this.phone_visit_fee = json.phone_visit_fee;
    this.virtual_visit_fee = json.virtual_visit_fee;
    this.office_visit_fee = json.office_visit_fee;
    if (json.error) {
      return json.error;
    }
    this.id = json.id;
    this.name = json.name;
    if (json.weekdays_binary) {
      this.weekdays = this.set_calendar_weekdays(json.weekdays_binary);
    }
    this.weekdays = json.weekdays;
    this.from = new Date(json.from);
    this.to = new Date(json.to);
    this.is_active = json.is_active;
    this.location_name = json.location_name;
    this.day_starts_at = json.day_starts_at;
    this.day_ends_at = json.day_ends_at;
    this.break_starts_at = json.break_starts_at;
    this.break_ends_at = json.break_ends_at;
    this.appt_per_slop = json.appt_per_slop;
    this.slot_size = json.slot_size;
    this.location_id = json.location_id;
    this.calendarable_id = json.calendarable_id;
    this.calendarable_type = json.calendarable_type;
    this.service_technician_id = json.service_technician_id;
    return this;
  }

  validationCheck() {
    return (this.from > this.to) || (this.day_starts_at > this.day_ends_at) || (this.break_starts_at > this.break_ends_at);
  }

  set_calendar_weekdays(weekdays_binary) {
    if (weekdays_binary == '0000000') {
      return
    }
    for (let i = 0, len = weekdays_binary.length; i < len; i++) {
      let binary_value = weekdays_binary.charAt(i);
      let is_selected = (binary_value == '0') ? false : true;
      let day = this.week_days_mapping[i];
      this.set_days_values(day, is_selected);
    }
  }

  set_days_values(day, is_selected) {
    if (day == 'monday') {
      this.monday = is_selected;
    } else if (day == 'tuesday') {
      this.tuesday = is_selected;
    } else if (day == 'wednesday') {
      this.wednesday = is_selected;
    } else if (day == 'thursday') {
      this.thursday = is_selected;
    } else if (day == 'friday') {
      this.friday = is_selected;
    } else if (day == 'saturday') {
      this.saturday = is_selected;
    } else if (day == 'sunday') {
      this.sunday = is_selected;
    }
  }

  appoitment_days(day) {
    if (day == 'monday' && this.monday) {
      this.final_appointment_days.push(day);
    } else if (day == 'tuesday' && this.tuesday) {
      this.final_appointment_days.push(day);
    } else if (day == 'wednesday' && this.wednesday) {
      this.final_appointment_days.push(day);
    } else if (day == 'thursday' && this.thursday) {
      this.final_appointment_days.push(day);
    } else if (day == 'friday' && this.friday) {
      this.final_appointment_days.push(day);
    } else if (day == 'saturday' && this.saturday) {
      this.final_appointment_days.push(day);
    } else if (day == 'sunday' && this.sunday) {
      this.final_appointment_days.push(day);
    }
    return this.final_appointment_days;
  }

  to_params() {
    return {
      calendar: {
        id: this.id,
        name: this.name,
        weekdays: this.convert_binary_to_base10(),
        from: this.from.toDateString(),
        to: this.to.toDateString(),
        day_starts_at: this.day_starts_at,
        day_ends_at: this.day_ends_at,
        break_starts_at: this.break_starts_at,
        break_ends_at: this.break_ends_at,
        appt_per_slop: this.appt_per_slop,
        slot_size: this.slot_size,
        location_id: this.location_id,
        calendarable_id: this.calendarable_id,
        calendarable_type: this.calendarable_type,
        service_technician_id: this.service_technician_id,
        booking_type: this.booking_type,
        virtual_visit_fee: this.virtual_visit_fee,
        office_visit_fee: this.office_visit_fee,
        phone_visit_fee: this.phone_visit_fee
      }
    }
  }

  convert_binary_to_base10() {
    let str: any;
    let list: Array<boolean> = [];
    let convert_into_binary;
    let convert_into_base2;

    list.push(this.monday, this.tuesday, this.wednesday, this.thursday, this.friday, this.saturday, this.sunday);
    convert_into_binary = list.map(function (x) {
      return x ? 1 : 0
    }).join('');
    convert_into_base2 = parseInt(convert_into_binary, 2);
    return convert_into_base2;
  }
}

export class DoctorAchievement extends BaseModel {
  static totalExperienceCount = 0;
  id: number;
  start_date = '';
  category_id: number;
  category_name: string;
  end_date = '';
  doctor_id: number;
  achievements_title_id: number;
  achievements_institution_id: number;
  title = '';
  institution_name = '';
  start_month = '';
  end_month = '';
  experience = '';
  no_of_months = 0;
  public date_difference: any;
  currently_present = false;

  static isEmpty(value) {
    return value == undefined || value == '' || value == 'year' || value == 'month';
  }

  static isNonEmpty(value) {
    return !this.isEmpty(value);
  }

  load_from_json(json) {
    this.id = json.id;
    this.category_id = json.category_id;
    this.category_name = json.category_name;
    // this.experience = this.convertToDates(json.start_date, json.start_month, json.end_date, json.end_month)
    this.start_date = json.start_date;
    this.end_date = json.end_date;
    this.doctor_id = json.doctor_id;
    this.achievements_title_id = json.achievements_title_id;
    this.achievements_institution_id = json.achievements_institution_id;
    this.title = json.title;
    this.institution_name = json.institution_name;
    this.start_month = json.start_month;
    this.end_month = json.end_month;
    this.currently_present = json.currently_present;
    return this;
  }

  yearsRange() {
    let hash = {
      0: 'Jan',
      1: 'Feb',
      2: 'Mar',
      3: 'Apr',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'Aug',
      8: 'Sep',
      9: 'Oct',
      10: 'Nov',
      11: 'Dec'
    }
    if (this) {
      if (this.start_date && this.end_date) {
        return `${this.start_month} ${this.start_date} - ${this.end_month} ${this.end_date}`;
      }
      if (this.start_date && this.currently_present) {
        return `${this.start_month} ${this.start_date} - Present`;
      }
      if (this.start_date) {
        return `${this.start_month} ${this.start_date}`;
      }
    }
    return '';
  }

  convertToDates(start_date, start_month, end_date, end_month) {
    let hash = {
      'Jan': 0,
      'Feb': 1,
      'Mar': 2,
      'Apr': 3,
      'May': 4,
      'June': 5,
      'July': 6,
      'Aug': 7,
      'Sep': 8,
      'Oct': 9,
      'Nov': 10,
      'Dec': 11
    };

    if ((this.isLengthGreater(start_date) && this.isLengthGreater(start_month)) && (this.isLengthSmaller(end_date) && this.isLengthSmaller(end_month)) && (this.currently_present == false)) {
      return start_month + ' ' + start_date;
    }

    if (this.isLengthGreater(start_date) && this.isLengthGreater(start_month) && this.isLengthGreater(end_date) && this.isLengthGreater(end_month)) {
      let starting_month = hash[start_month];
      let ending_month = hash[end_month];
      let date1 = new Date(start_date, starting_month);
      let date2 = new Date(end_date, ending_month, 28);
      return this.noOfMonths(date1, date2);
    }

    if ((this.isLengthGreater(start_date) && this.isLengthGreater(start_month)) && (this.isLengthSmaller(end_date) && this.isLengthSmaller(end_month)) && (this.currently_present == true)) {
      let hash = {
        'Jan': 0,
        'Feb': 1,
        'Mar': 2,
        'Apr': 3,
        'May': 4,
        'June': 5,
        'July': 6,
        'Aug': 7,
        'Sep': 8,
        'Oct': 9,
        'Nov': 10,
        'Dec': 11
      };
      let current_date = new Date();
      let starting_month = hash[start_month];
      let date1 = new Date(start_date, starting_month);
      let date2 = new Date(current_date.getFullYear(), current_date.getMonth(), 28);
      return this.noOfMonths(date1, date2);
    }
  }

  experienceCalculation(experience) {
    experience = experience.split(' ');
    let no_of_years = experience[0];
    let no_of_months = experience[1];

    if (no_of_years > 1 && no_of_months == 12) {
      return no_of_years + ' yrs ';
    }

    if (no_of_years > 1 && no_of_months > 1) {
      return no_of_years + ' yrs ' + no_of_months + ' mons'
    }

    if (no_of_years > 1 && no_of_months == 1) {
      return no_of_years + ' yrs ' + no_of_months + ' mon'
    }

    if (no_of_years == 1 && no_of_months > 1) {
      return no_of_years + ' yr ' + no_of_months + ' mons'
    }

    if (no_of_years == 1 && no_of_months == 1) {
      return no_of_years + ' yr ' + no_of_months + ' mon'
    }

    if (no_of_years == 1 && no_of_months < 1) {
      return no_of_years + ' yr'
    }

    if (no_of_years > 1 && no_of_months < 1) {
      return no_of_years + ' yrs '
    }

    if (no_of_years < 1 && no_of_months < 12) {
      return no_of_months + ' mons '
    }

    if (no_of_years < 1 && no_of_months == 12) {
      return '1 Year'
    }

    return '';
  }

  isLengthGreater(value) {
    return value.length > 0;
  }

  isLengthSmaller(value) {
    return value.length < 1;
  }

  noOfMonths(date1, date2) {
    let diff_date = date2 - date1;
    let num_years = diff_date / 31536000000;
    let num_months = (diff_date % 31536000000) / 2628000000;
    return this.experienceCalculation(Math.floor(num_years) + ' ' + Math.ceil(num_months));
  }

  getExperienceInMonths() {
    let hash = {
      'Jan': 0,
      'Feb': 1,
      'Mar': 2,
      'Apr': 3,
      'May': 4,
      'June': 5,
      'July': 6,
      'Aug': 7,
      'Sep': 8,
      'Oct': 9,
      'Nov': 10,
      'Dec': 11
    };
    if (this.isLengthGreater(this.start_date) && this.isLengthGreater(this.start_month) && this.isLengthGreater(this.end_date) && this.isLengthGreater(this.end_month)) {

      let start = parseInt(this.start_date);
      let end = parseInt(this.end_date);

      let starting_month = parseInt(hash[this.start_month]);
      let ending_month = parseInt(hash[this.end_month]);

      let date1 = new Date(start, starting_month);
      let date2 = new Date(end, ending_month, 28);

      let noOfStartMonths = date1.getFullYear() * 12 + date1.getMonth();
      let noOfEndMonths = date2.getFullYear() * 12 + date2.getMonth();

      this.date_difference = noOfEndMonths + 1 - noOfStartMonths;

      return this.date_difference;
    }

    if ((this.isLengthGreater(this.start_date) && this.isLengthGreater(this.start_month)) && (this.isLengthSmaller(this.end_date) && this.isLengthSmaller(this.end_month)) && (this.currently_present == true)) {
      let hash = {
        'Jan': 0,
        'Feb': 1,
        'Mar': 2,
        'Apr': 3,
        'May': 4,
        'June': 5,
        'July': 6,
        'Aug': 7,
        'Sep': 8,
        'Oct': 9,
        'Nov': 10,
        'Dec': 11
      };

      let current_date = new Date();
      let start_date = parseInt(this.start_date);
      let starting_month = hash[this.start_month];

      let date1 = new Date(start_date, starting_month);
      let date2 = new Date(current_date.getFullYear(), current_date.getMonth(), 28);

      let noOfStartMonths = date1.getFullYear() * 12 + date1.getMonth();
      let noOfEndMonths = date2.getFullYear() * 12 + date2.getMonth();

      this.date_difference = noOfEndMonths + 1 - noOfStartMonths;

      return this.date_difference;
    }
    return this.start_date + ' ' + this.start_month;
  }

  startValid() {
    if (DoctorAchievement.isEmpty(this.start_date) && DoctorAchievement.isEmpty(this.start_month) && DoctorAchievement.isEmpty(this.end_date)) {
      return true;
    }
    return DoctorAchievement.isNonEmpty(this.start_date) && DoctorAchievement.isNonEmpty(this.start_month);
  }

  endValid() {
    if (DoctorAchievement.isEmpty(this.end_date) && DoctorAchievement.isEmpty(this.end_month)) {
      return true;
    }
    return DoctorAchievement.isNonEmpty(this.end_date) && DoctorAchievement.isNonEmpty(this.end_month)
  }

  isValid() {
    return this.startValid() && this.endValid();
  }

  get start_date_error_message() {
    if (DoctorAchievement.isEmpty(this.start_date) && DoctorAchievement.isEmpty(this.start_month) && DoctorAchievement.isEmpty(this.end_date)) {
      return '';
    }
    if (DoctorAchievement.isNonEmpty(this.start_month) && DoctorAchievement.isEmpty(this.start_date) || (DoctorAchievement.isEmpty(this.start_month) && DoctorAchievement.isNonEmpty(this.end_date))) {
      return 'start year is required';
    }
  }

  get start_month_error_message() {
    if (DoctorAchievement.isEmpty(this.start_date) && DoctorAchievement.isEmpty(this.start_month)) {
      return '';
    }
    if (DoctorAchievement.isEmpty(this.start_month) && DoctorAchievement.isNonEmpty(this.start_date)) {
      return 'start month is required';
    }
  }

  get end_date_error_message() {
    if (DoctorAchievement.isEmpty(this.end_date) && DoctorAchievement.isEmpty(this.end_month)) {
      return '';
    }
    if (DoctorAchievement.isNonEmpty(this.end_month) && DoctorAchievement.isEmpty(this.end_date)) {
      return 'end year is required';
    }
  }

  get end_month_error_message() {
    if (DoctorAchievement.isEmpty(this.end_date) && DoctorAchievement.isEmpty(this.end_month)) {
      return '';
    }
    if (DoctorAchievement.isEmpty(this.end_month) && DoctorAchievement.isNonEmpty(this.end_date)) {
      return 'end year is required';
    }
  }
}

export class CalendarLocation extends BaseModel {
  id = 0;
  name = '';

  load_from_json(json) {
    this.id = json[0].id;
    this.name = json[0].name;
    return this;
  }
}

export class AchievementCategory extends BaseModel {
  id = 0;
  name = '';
  parent_id = 0;
  doctors_achievements: DoctorAchievement[] = [new DoctorAchievement()];

  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.parent_id = json.parent_id;
    if (!json.doctors_achievements) {
      return;
    }
    let achievements = json.doctors_achievements.map(achievement => new DoctorAchievement().load_from_json(achievement));
    if (json.doctors_achievements && json.doctors_achievements.length) {
      this.doctors_achievements = achievements;
    }
  }
}

export class AchievementTitle extends BaseModel {
  id = 0;
  name = '';
  parent_id = 0;
  achievement_category_id = 0;

  load_from_json(json) {
    this.id = json.id,
      this.name = json.name,
      this.parent_id = json.parent_id,
      this.achievement_category_id = json.achievement_category_id
    return this;
  }
}

export class AchievementInstituition extends BaseModel {
  id = 0;
  name = '';
  parent_id = 0;
  achievement_category_id = 0;

  load_from_json(json) {
    this.id = json.id,
      this.name = json.name,
      this.parent_id = json.parent_id,
      this.achievement_category_id = json.achievement_category_id
    return this;
  }
}
