<div class="flex-control flex-column flex-height">
  <div class="flex-header text-center">
    <button class="btn btn-secondary btn-min mr-sm" (click)="generatePdf('print')">Print</button>
    <button class="btn btn-tertiary btn-outline btn-min" (click)="generatePdf('save')">Download</button>
  </div>
  <div class="flex-body scroll-browser">
    <div #receipt *ngIf="print_type == 'Report'" class="main-wrapper print-wrapper" style="max-width:10.5in; margin:auto;">
      <div class="print-header mb">
        <div class="row">
          <h3 class="m0 col-xs-6 pb fw-500">Super Bill</h3>
          <h4 class="m0 col-xs-6 text-right pb fw-500">Billing Month: {{report_date | date: 'MMMM, y'}}</h4>
        </div>
      </div>
      <div class="print-body">
        <div class="row">
          <div class="col-xs-6">
            <h4 class="text-bold mb-lg">Patient Information</h4>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Name:</h5>
              <h5 class="col-xs-8 fw-500 text-capitalize m0">{{bill.name}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Address:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.patient_address}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Phone:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.phone_number | mask: '(000) 000-0000'}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Date of Birth:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.dob}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Consultant:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.billing_provider_name}}</h5>
            </div>
            <ng-container *ngIf="programme_category === 'private_programme'">
              <div class="row mb">
                <h5 class="col-xs-4 m0">Package Name:</h5>
                <h5 class="col-xs-8 fw-500 m0">{{bill?.programme_json?.private_package_name}}</h5>
              </div>
              <div class="row mb">
                <h5 class="col-xs-4 m0">Fee:</h5>
                <h5 class="col-xs-8 fw-500 m0">{{bill?.programme_json?.private_pay_amount}}</h5>
              </div>
              <div class="row mb">
                <h5 class="col-xs-4 m0">Minutes Spent:</h5>
                <h5 class="col-xs-8 fw-500 m0">{{bill.time_spent}}</h5>
              </div>
            </ng-container>
          </div>
          <div class="col-xs-6">
            <h4 class="text-bold mb-lg">Provider Information</h4>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Practice Name:</h5>
              <h5 class="col-xs-8 fw-500 text-capitalize m0">{{bill.practice_name}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Practice Address:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.practice_address}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Provider:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.billing_provider_name}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">NPI:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.billing_provider_npi}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Phone:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.practice_phone | mask: '(000) 000-0000'}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Email:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.practice_email}}</h5>
            </div>
            <div class="row mb">
              <h5 class="col-xs-4 m0">Referring provider:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.care_manager_name}}</h5>
            </div>
            <div class="row">
              <h5 class="col-xs-4 m0">Referring provider NPI:</h5>
              <h5 class="col-xs-8 fw-500 m0">{{bill.care_manager_npi}}</h5>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="bill && bill.diagnosis?.length">
        <h4 class="text-bold">Diagnosis</h4>
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr>
              <th class="text-center col-xs-2">ICD-10</th>
              <th>ICD-10 Description</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let diagnos of bill.diagnosis">
              <td class="text-center col-xs-2">{{diagnos[0]}}</td>
              <td>{{diagnos[1]}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-container>


      <ng-container *ngIf="programme_category !== 'private_programme'">
        <h4 class="text-bold">Applicable CTPs - Total Time Spent {{bill.time_spent}} Mins</h4>
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover">
            <thead>
            <tr>
              <th class="text-center col-xs-2">CPT Code</th>
              <th>CRT Description</th>
              <th class="text-center">Mins</th>
              <th class="text-right">Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let cpt of bill.cpt_codes">
              <td class="text-center col-xs-2">{{cpt[0]}}</td>
              <td>{{cpt[1]}}</td>
              <td class="text-center">{{cpt[2]}}</td>
              <td class="text-right">${{cpt[3]}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
      <app-print-report-section [reportDetails]="reportDetails"></app-print-report-section>
      <div class="fs12 text-center">Printed on {{today | date: 'medium'}} by <b>wellwink.com</b></div>
    </div>
  </div>
</div>
