<div *ngIf="staff && staff.custom_loaded" [hidden]="!do_show" class="white-container-box pt-lg pl-xl pr-xl mb-lg">
  <form #contactForm="ngForm" (ngSubmit)="save()" class="form-horizontal">
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">First Name*:</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" id="first_name" [(ngModel)]="staff.first_name"
               name="first_name" required
               placeholder="First Name">
        <div *ngIf="customValidations.formErrors.first_name" style="color: red">
          {{ customValidations.formErrors.first_name }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm ">Last Name*:</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" id="last_name" [(ngModel)]="staff.last_name"
               name="last_name" required
               placeholder="Last Name">
        <div *ngIf="customValidations.formErrors.last_name" style="color: red">
          {{ customValidations.formErrors.last_name }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Prefix*:</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" id="prefix" [(ngModel)]="staff.prefix"
               name="prefix" required
               placeholder="prefix">
        <div *ngIf="customValidations.formErrors.prefix" style="color: red">
          {{ customValidations.formErrors.prefix }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm ">Suffix:</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" id="sufix" [(ngModel)]="staff.sufix"
               name="sufix"
               placeholder="Suffix">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Designation*:</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="designation" [(ngModel)]="staff.designation"
               name="designation" required
               placeholder="designation">
        <div *ngIf="customValidations.formErrors.designation" style="color: red">
          {{ customValidations.formErrors.designation }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1  p0 pt-sm">Address 1*:</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="line_1" [(ngModel)]="staff.address.line_1"
               name="line_1" required
               placeholder="Line 1">
        <div *ngIf="customValidations.formErrors.line_1" style="color: red">
          {{ customValidations.formErrors.line_1 }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Address 2:</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="line_2" [(ngModel)]="staff.address.line_2"
               name="line_2"
               placeholder="Line 2">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm">City* :</label>
      <div class="col-sm-3">
        <input type="text" class="form-control" id="city" [(ngModel)]="staff.address.city_area.city.name"
               name="city" required
               placeholder="City">
        <div *ngIf="customValidations.formErrors.city" style="color: red">
          {{ customValidations.formErrors.city }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm text-right">State* :</label>
      <div class="col-sm-2 pr0">
        <input type="text" class="form-control" id="state" [(ngModel)]="staff.address.city_area.city.state"
               name="state" required
               placeholder="state">
        <div *ngIf="customValidations.formErrors.state" style="color: red">
          {{ customValidations.formErrors.state }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm text-right">Zip* :</label>
      <div class="col-sm-2">
        <input type="number" class="form-control" id="zip" [(ngModel)]="staff.address.postal_code"
               name="zip" required
               placeholder="zip">
        <div *ngIf="customValidations.formErrors.zip" style="color: red">
          {{ customValidations.formErrors.zip }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1  p0 pt-sm">Geo Location*:</label>
      <div class="col-sm-9">
        <input id="city_area_name" placeholder="search for Area" autocorrect="off" autocapitalize="off"
               spellcheck="off" type="text" class="form-control" #search [(ngModel)]="staff.address.city_area.name"
               [ngModelOptions]="{standalone: true}" required>
        <div *ngIf="customValidations.formErrors.area" style="color: red">
          {{ customValidations.formErrors.area }}
        </div>
        <input type="hidden" placeholder="search for Area" autocorrect="off" autocapitalize="off" id="olat"
               spellcheck="off" class="form-control" [(ngModel)]="staff.address.city_area.lat"
               [ngModelOptions]="{standalone: true}">
        <input type="hidden" placeholder="search for Area" autocorrect="off" autocapitalize="off" id="olong"
               spellcheck="off" class="form-control" [(ngModel)]="staff.address.city_area.long"
               [ngModelOptions]="{standalone: true}">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Mobile* :</label>
      <div class="col-sm-4" *ngIf="staff.phone">
        <input  mask="(000) 000-0000"  type="text" class="form-control" id="phone" [(ngModel)]="staff.phone.value"
               name="phone" required
               placeholder="Mobile Number">
        <div *ngIf="customValidations.formErrors.phone" style="color: red">
          {{ customValidations.formErrors.phone }}
        </div>
      </div>
      <label class="col-sm-1 p0 pt-sm ">Fax :</label>
      <div class="col-sm-4" *ngIf="staff.fax">
        <input mask="(000) 000-0000" type="text" class="form-control" id="fax" [(ngModel)]="staff.fax.value" name="fax" pattern="[0-9]+"
               placeholder="Fax">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Phone :</label>
      <div class="col-sm-9">
        <input mask="(000) 000-0000" type="text" class="form-control" id="cell" [(ngModel)]="staff.cell"
               [ngModelOptions]="{standalone: true}"
               placeholder="Phone">
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-1 p0 pt-sm ">Email* :</label>
      <div class="col-sm-9">
        <input type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
               class="form-control" id="email" [(ngModel)]="staff.email"
               name="email" required
               placeholder="Email">
        <div *ngIf="customValidations.formErrors.email" style="color: red">
          {{ customValidations.formErrors.email }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-xs-1"></label>
      <div class="col-xs-11">
        <button class="btn btn-primary mr" type="submit" [disabled]="!contactForm.form.valid">Submit</button>
        <button class="btn btn-inverse" type="button" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </form>
</div>
