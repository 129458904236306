import {Injectable}     from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {CustomHttpClient} from './custom-http-client.service';
import {Doctor} from '.././routes/practices/doctors/doctor';
import {Observable} from 'rxjs';


@Injectable()
export class DoctorService {
  constructor(private _tokenService: CustomHttpClient) {
  }

  getPracticeDoctors(practice_id?: any) {
    let args = new HttpParams();
    if (practice_id) {
       args = args.set('practice_id', practice_id);
    }
    return this._tokenService.get('doctors/get_practice_doctors', {params: args})
      .toPromise()
      .then(response => response)
  }

  public searchDoctor(term): Observable<any> {
    let args = new HttpParams();
    args = args.set('term', term);
    return this._tokenService.get('doctors', {params: args})
      .map((r) => r);
  }
  getPatientDoctors(patient_id: any) {
    let args = new HttpParams();
    args = args.set('patient_id', patient_id);
    return this._tokenService.get('doctors/get_patient_doctors', {params: args})
      .toPromise()
      .then(response => response)
  }
  getDoctorLocations(doctor_id: any) {
    let args = new HttpParams();
    args = args.set('doctor_id', doctor_id);
    return this._tokenService.get('doctors/doctor_locations', {params: args})
      .toPromise()
      .then(response => response)
  }
  public getAllDoctorForPractice(practice_id) {
    let args = new HttpParams();
    args = args.set('practice_id', practice_id);
    return this._tokenService.get('doctors/get_practice_doctors', {params: args})
      .toPromise()
      .then(response => response)
  }

  public apply_filters(name, practice_name, specialty_id, status, page) {
    return this._tokenService.getWithHeaders('doctors/list_doctors?doctor_name=' + name + '&practice_name=' + practice_name + '&status=' + status + '&specialty_id=' + specialty_id + '&page=' + page, {observe: 'response', responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          doctors: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  public associate_doctor_to_practice(practice_id, doctor_id) {
    let args = new HttpParams();
    args = args.set('practice_id', practice_id);
    args = args.set('doctor_id', doctor_id);
    return this._tokenService
      .get('doctors/assign_doctor_to_practice', {params: args})
      .toPromise()
      .then(res => res)
  }

  public updateDoctorCharges(doctor_id, practice_id, visit_charges, virtual_visit_charges, share) {
    let args = new HttpParams();
    args = args.set('doctor_id', doctor_id);
    args = args.set('practice_id', practice_id);
    args = args.set('visit_charges', visit_charges);
    args = args.set('virtual_visit_charges', virtual_visit_charges);
    args = args.set('fees_share', share);
    return this._tokenService
        .get('doctors/update_doctor_share', {params: args})
        .toPromise()
        .then(res => res)
  }

  public get_associated_doctors(token, practice_id) {
    let args = new HttpParams();
    args = args.set('token', token);
    args = args.set('practice_id', practice_id);
    return this._tokenService.get('doctors/associated_doctors', {params: args})
      .toPromise()
      .then(response => response)
  }

  public get_locations_doctors(search_params) {
    let args = new HttpParams();
    args = args.set('location_id', search_params.location_id);
    args = args.set('practice_id', search_params.practice_id);
    return this._tokenService.get('doctors/location_doctors', {params: args})
      .toPromise()
      .then(response => response)
  }

  public get_Doctor_location_calendar(search_params) {
    let args = new HttpParams();
    args = args.set('location_id', search_params.location_id);
    args = args.set('doctor_id', search_params.doctor_id);
    args = args.set('practice_id', search_params.practice_id);
    return this._tokenService.get('doctors/doctors_location_calendar', {params: args})
      .toPromise()
      .then(response => response)

  }

  public get_practice_doctors(token, practice_id) {
    let args = new HttpParams();
    args = args.set('token', token);
    args = args.set('practice_id', practice_id);
    return this._tokenService.get('doctors/practice_doctors', {params: args})
      .toPromise()
      .then(response => response)
  }
  public getDoctor(id?: number) {
    return this._tokenService.get(id ? `doctors/edit?id=` + id : 'doctors/achievements_data')
      .toPromise()
      .then(response => response)
  }

  public disassociate_doctor_from_practice(doctor_id?: number, practice_id?: number) {
    let args = new HttpParams();
    args = args.set('doctor_id', doctor_id.toString());
    args = args.set('practice_id', practice_id.toString());
    return this._tokenService.get('doctors/disassociate_doctor_practice', {params: args})
      .toPromise()
      .then(response => response)
  }

  public search_achievements(token, category_id, search_type) {
    let args = new HttpParams();
    args = args.set('token', token);
    args = args.set('category_id', category_id);
    args = args.set('search_type', search_type);
    return this._tokenService.get('doctors/achievements_search', {params: args})
      .toPromise()
      .then(response => response)
  }

  create(doctor: Doctor): Promise<any> {
    return this._tokenService
      .post('doctors/', doctor.to_params())
      .toPromise()
      .then(res => doctor.load_from_json(res))
  }

  update(doctor: Doctor) {
    let data = doctor.to_params();
    delete data['doctor']['doctors_practices_attributes'];
    console.log(data);
    return this._tokenService
      .put('doctors/update_doctor_profile/', data)
      .toPromise()
      .then(res => doctor.load_from_json(res))
  }

  save(doctor: Doctor): Promise<any> {
    if (doctor.is_new_record()) {
      return this.create(doctor);
    }
    else {
      return this.update(doctor);
    }
  }

  change_status(doctor) {
    let args = new HttpParams();
    args = args.set('id', doctor.id.toString());
    args = args.set('status', doctor.is_active);
    return this._tokenService.get('doctors/change_status', {params: args})
      .toPromise()
      .then(response => response)
  }

  updateVirtualVisitStatus(id: number) {
    let args = new HttpParams();
    args = args.set('doctor_practice_id', id.toString());
    return this._tokenService.get('doctors/change_virtual_visit_status', {params: args})
      .toPromise()
      .then(response => response)
  }
}
