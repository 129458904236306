import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ColorsService } from './colors/colors.service';
import { SpecialtySearchService } from '../services/specialty-search.service';
import { RatingModule } from 'ngx-bootstrap/rating';
import  {TabsModule} from '../tabs/tabs.module'
import  {TruncatePipe} from '../pipes/truncate.pipe'
import  {CapitalizePipe} from '../pipes/capitalize.pipe'
import  {ConvertToClickableLinksPipe} from '../pipes/convertToClickableLinks.pipe'
import { CityAreaComponent } from './city-area/city-area.component';
import { LoaderComponent } from './loader/loader.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RatingsService } from '../services/ratings.service'
import{ PatientService } from '../services/patient.service'
import{ DocumentsService } from '../services/document.service'
import {AttachmentsComponent} from '../routes/practices/attachments/attachments.component';
import {UserCanDirective, UserPermittedDirective} from '../directives/user.can.directive';
import { PatientRatingsComponent } from '../routes/rating/patient-ratings/patient-ratings.component';
import {DepartmentService} from '../services/department.service';
import { DemographicsComponent } from '../health/patient-profile/patient-dashboard/demographics/demographics.component'
import {SlotNavigatorComponent} from '../health/slot-navigator/slot-navigator.component';
import {SlotsComponent} from '../health/routes/slots/slots.component';
import { ErrorModalComponent } from '../shared/error-modal/error-modal.component';
import { SmoothScrollToDirective, SmoothScrollDirective } from 'ng2-smooth-scroll';
import { NoResultFoundComponent } from '../shared/no-result-found/no-result-found.component';
import {RegistrationModule} from '../health/registration/registration.module';
import { PdfPrinterComponent } from './pdf-printer/pdf-printer.component';
import {PatientAuthorizationService} from '../services/patient-authorization.service';
import {VitalsService} from '../services/vitals.service';
import {VitalsComponent} from '../routes/practice-site/vital/vitals/vitals.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import {HttpClientModule} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {ModalModule} from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {LoginModule} from './login/login.module';
import {NgxMaskModule} from 'ngx-mask';
import { AttachmentViewerComponent } from './attachment-viewer/attachment-viewer.component';
import { BraintreePaymentComponent } from './braintree-payment/braintree-payment.component'
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import { PatientCommunicationComponent } from './patient-communication/patient-communication.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {AdminLoginService} from '../services/admin_login_service';
import {EcontactComponent} from '../health/patient-profile/econtact/econtact.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AssociateCarePlanWithChartService} from '../routes/practice-site/todays-patients/associate-care-plan-with-chart.service';
import {VoiceCallModule} from '../voice-call/voice-call.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {DeviceTrainingComponent} from '../health/patient-profile/patient-setting/devices/device-training/device-training.component';
import {DevicesComponent} from '../health/patient-profile/patient-setting/devices/devices.component';
import {DeviceComponent} from '../health/patient-profile/patient-setting/devices/device/device.component';
import {EmbedRichTextTemplateComponent} from '../routes/rich-text-template-index/embed-rich-text-template/embed-rich-text-template.component';
import {EnoteRichTextTemplateComponent} from '../routes/rich-text-template-index/enote-rich-text-template/enote-rich-text-template.component';
import {RichTextTemplatesComponent} from '../routes/rich-text-template-index/rich-text-templates/rich-text-templates.component';
import {PatientInsuranceComponent} from '../health/patient-profile/patient-insurance/patient-insurance.component';
import {PatientProviderEnotesComponent} from '../health/patient-profile/patient-dashboard/patient-provider-enotes/patient-provider-enotes.component';
import {PatientMedicalCenterComponent} from '../health/patient-profile/patient-medical-center/patient-medical-center.component';
import {MedicalCenterComponent} from '../health/patient-profile/patient-medical-center/medical-center/medical-center.component';
import {MedicalCentersComponent} from '../health/patient-profile/patient-medical-center/medical-centers/medical-centers.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import { EmailConfigurationComponent } from '../routes/practices/email-configuration/email-configuration.component';
import {TwilioConfigurationComponent} from '../routes/practices/twilio-configuration/twilio-configuration.component';
import {EmailIntakeFormComponent} from '../routes/practice-site/email-intake-form/email-intake-form.component';
import { PatientIntakeComponent } from './patient-intake/patient-intake.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n', '.json');
}

@NgModule({
    imports: [LoginModule, NgxMaskModule.forRoot(),
        ModalModule.forRoot(), RatingModule.forRoot(), TypeaheadModule.forRoot(), ProgressbarModule.forRoot(),
        PaginationModule.forRoot(), TooltipModule.forRoot(), CarouselModule.forRoot(),
        InfiniteScrollModule, NgbTooltipModule,
        EditorModule,
        TabsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }), RegistrationModule, BsDropdownModule, VoiceCallModule, BsDatepickerModule, MultiselectDropdownModule
    ],
  providers: [
    TranslateService, DepartmentService, PatientAuthorizationService,
    ColorsService, SpecialtySearchService, DatePipe, RatingsService,
    TranslateService, PatientService, DocumentsService, VitalsService, CustomHttpClient, AdminLoginService,
    AssociateCarePlanWithChartService
  ],
  declarations: [
    CapitalizePipe,
    ConvertToClickableLinksPipe,
    SafeHtmlPipe,
    SmoothScrollToDirective,
    SmoothScrollDirective,
    TruncatePipe, EcontactComponent, DeviceTrainingComponent,
    CityAreaComponent, PatientCommunicationComponent,
    AttachmentsComponent, PatientRatingsComponent, DemographicsComponent, DevicesComponent, DeviceComponent,
    LoaderComponent, UserCanDirective, UserPermittedDirective, VitalsComponent,
    SlotNavigatorComponent, SlotsComponent, ErrorModalComponent, NoResultFoundComponent, PdfPrinterComponent,
    AttachmentViewerComponent, BraintreePaymentComponent, EmbedRichTextTemplateComponent,
    EnoteRichTextTemplateComponent, RichTextTemplatesComponent, PatientInsuranceComponent,
    PatientMedicalCenterComponent,
    MedicalCenterComponent,
    MedicalCentersComponent,
    EmailConfigurationComponent,
    TwilioConfigurationComponent,
    EmailIntakeFormComponent,
    PatientIntakeComponent
  ],
  exports: [
    RegistrationModule, RichTextTemplatesComponent,
    InfiniteScrollModule, TypeaheadModule, ProgressbarModule, TooltipModule,
    TabsModule, PaginationModule, ModalModule, RatingModule,
    CommonModule, NgbTooltipModule,
    DemographicsComponent, DevicesComponent, DeviceComponent,
    FormsModule, EnoteRichTextTemplateComponent,
    ReactiveFormsModule, EmailIntakeFormComponent,
    HttpClientModule,EmailConfigurationComponent, TwilioConfigurationComponent, PatientIntakeComponent,
    TranslateModule, EcontactComponent, DeviceTrainingComponent,
    PatientCommunicationComponent,
    RouterModule, CapitalizePipe, ConvertToClickableLinksPipe, SafeHtmlPipe, PdfPrinterComponent,
    TruncatePipe, CityAreaComponent, PatientRatingsComponent, NoResultFoundComponent, BraintreePaymentComponent,
    AttachmentsComponent, SmoothScrollToDirective, SmoothScrollDirective, VitalsComponent,
    LoaderComponent, UserCanDirective, UserPermittedDirective, SlotNavigatorComponent, SlotsComponent,
    ErrorModalComponent, RegistrationModule, AttachmentViewerComponent, EmbedRichTextTemplateComponent
  ]
})
export class SharedModule { }
