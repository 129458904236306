import { Component, OnInit, AfterViewChecked } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { UserService } from '../../../services/user.service';
import {HttpTokenService} from '../../../services/http.token.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit , AfterViewChecked {

  lat: number;
  long: number;
  area_text = '';
  query: string;
  public practice_request = false;
  public show_home_practice = true;
  doctors = [[{img: '/assets/img/doctors/1.jpg'}, {img: '/assets/img/doctors/2.jpg'}, {img: '/assets/img/doctors/3.jpg'}], [{img: '/assets/img/doctors/1.jpg'}, {img: '/assets/img/doctors/3.jpg'}, {img: '/assets/img/doctors/2.jpg'}]];
  promotions = [[{img: '/assets/img/promotions/1.jpg'}, {img: '/assets/img/promotions/2.jpg'}, {img: '/assets/img/promotions/3.jpg'}], [{img: '/assets/img/promotions/1.jpg'}, {img: '/assets/img/promotions/3.jpg'}, {img: '/assets/img/promotions/2.jpg'}]];
  announcements = [[{img: '/assets/img/announcements/1.jpg'}, {img: '/assets/img/announcements/2.jpg'}, {img: '/assets/img/announcements/3.jpg'}], [{img: '/assets/img/announcements/1.jpg'}, {img: '/assets/img/announcements/3.jpg'}, {img: '/assets/img/announcements/2.jpg'}]];
  locations = [{'name': 'Location 1'}, {'name': 'Location 2'}, {'name': 'Location 3'}, {'name': 'Location 4'}, {'name': 'Location 5'}, {'name': 'Location 6'}, {'name': 'Location 7'}, {'name': 'Location 8'}, {'name': 'Location 9'}, {'name': 'Location 10'}, {'name': 'Location 11'}, {'name': 'Location 12'}, {'name': 'Location 13'}, {'name': 'Location 14'}, {'name': 'Location 15'}, {'name': 'Location 16'}, {'name': 'Location 17'}, {'name': 'Location 18'}, {'name': 'Location 19'}, {'name': 'Location 20'}, {'name': 'Location 21'}, {'name': 'Location 22'}, {'name': 'Location 23'}, {'name': 'Location 24'}, {'name': 'Location 25'}, {'name': 'Location 26'}, {'name': 'Location 27'}, {'name': 'Location 28'}, {'name': 'Location 29'}, {'name': 'Location 30'}]
  specialties = [{'name': 'Acupuncturist'}, {'name': 'Adult Companion'}, {'name': 'Advanced Practice Dental Therapist'}, {'name': 'Advanced Practice Midwife'}, {'name': 'Air Carrier'}, {'name': 'Allergy \u0026 Immunology'}, {'name': 'Allergy \u0026 Immunology, Allergy'}, {'name': 'Allergy \u0026 Immunology, Clinical \u0026 Laboratory Immunology'}, {'name': 'Alzheimer Center (Dementia Center)'}, {'name': 'Ambulance'}, {'name': 'Ambulance, Air Transport'}, {'name': 'Ambulance, Land Transport'}, {'name': 'Ambulance, Water Transport'}, {'name': 'Anaplastologist'}, {'name': 'Anesthesiologist Assistant'}, {'name': 'Anesthesiology'}, {'name': 'Anesthesiology, Addiction Medicine'}, {'name': 'Anesthesiology, Critical Care Medicine'}, {'name': 'Anesthesiology, Hospice and Palliative Medicine'}, {'name': 'Anesthesiology, Pain Medicine'}, {'name': 'Anesthesiology, Pediatric Anesthesiology'}, {'name': 'Art Therapist'}, {'name': 'Assistant, Podiatric'}, {'name': 'Assisted Living Facility'}, {'name': 'Assisted Living Facility, Assisted Living, Behavioral Disturbances'}, {'name': 'Assisted Living Facility, Assisted Living, Mental Illness'}, {'name': 'Audiologist'}, {'name': 'Audiologist, Assistive Technology Practitioner'}, {'name': 'Audiologist, Assistive Technology Supplier'}, {'name': 'Audiologist-Hearing Aid Fitter'}]
  public show_login_popup = false;
  public message_error = false;

  constructor(private title: Title, public _userService: UserService,
              private _tokenService: HttpTokenService, private router: Router,
              private route: ActivatedRoute) {
    this._userService.hideFooterForPatient = false;
  }
  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
  }

  ngOnInit() {
    this.route.fragment.subscribe(f => {
      setTimeout(function () {
        const element = document.querySelector('#' + f)
        if (element) { element.scrollIntoView() }
      }, 300)
    })
    this._userService.iAmPatient = false;
  }

  ngAfterViewChecked() {
    this.route.fragment.subscribe(f => {
      if (f == 'login' && !this._tokenService.currentUserData) {
        this._userService.showLogin();
      }
    })
  }

  handle_practice_request(is_submitted) {
    this.show_home_practice = true;
    this.practice_request = false;
  }


  logout() {
    if (this._tokenService.userSignedIn()) {
      this._tokenService.signOut().subscribe(
        res =>  {
          localStorage.removeItem('redirectTo')
        },
        error => {
          console.log(error)
        }
      );
      this.router.navigateByUrl('/');
    }
  }
  area_selected(area_data) {
    this.lat = area_data.lat;
    this.long = area_data.long;
    this.area_text = area_data.name;
  }
  go_to_search() {
    if (this.lat == undefined && this.long == undefined) {
      (<HTMLInputElement>document.getElementById('search_area')).value = '';
      this.area_text = '';
    }
    // if(this.validateSearchParams(this.query,this.lat,this.long,this.area_text)){
    //   this.setTimeOutFailsMessage();
    //   return;
    // } else{
      this.router.navigate(['/search'], { queryParams: { q: this.query, lat: this.lat, long: this.long, area: this.area_text} });
    // }
  }

  areaSearch(data) {
    if (data.replace(' ', '').length == 0) {
      this.lat = 0;
      this.long = 0;
    } else {
      this.lat = undefined;
      this.long = undefined;
    }
  }

  validateSearchParams(query, lat, long, area_text) {
    if ((query == undefined) && (lat == undefined) && (long == undefined) && (area_text == '')) {
     return true;
    }
    return false;
  }

  setTimeOutFailsMessage(): any {
    this.message_error = true;
    setTimeout(function() {
      this.message_error = false;
    }.bind(this), 3000);
  }


  videoConsultation(): void {
    console.log('clicked.....');
    this.router.navigate(['/search'], {queryParams: {app_type: 'virtual_visit'}}).then(r => console.log(r));
  }

  scrollToElement($element: HTMLHeadingElement){
    $element.scrollIntoView({ behavior:"smooth", block:"start"});
  }
}
