import {Component, ChangeDetectorRef, OnInit, Input} from '@angular/core';
import {Case} from '../../case';
import {PaymentService} from '../../../../services/payment.service';
import {PaymentType, Ledger, Payment} from '../payment';
import {Observable} from 'rxjs';
import {ServicesService} from '../../../../services/services.service';
import {Appointment} from '../../../../health/models/appointment';
import {TabsService} from '../../../../services/tabs.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {PdfPrinterComponent} from '../../../../shared/pdf-printer/pdf-printer.component';
import {Tab} from '../../../../tabs/tab';
import {LoaderService} from '../../../../services/loader.service';
import {EmergencyService} from '../../../../services/emergency.service';
import { mergeMap } from 'rxjs/operators';
import {SinglePaymentComponent} from '../single-payment/single-payment.component';
import {AppointmentService} from "../../../../services/appointment.service";


@Component({
  selector: 'app-payment-invoice',
  templateUrl: './payment-invoice.component.html',
  styleUrls: ['./payment-invoice.component.scss']
})
export class PaymentInvoiceComponent implements OnInit {
  public payments: Payment[];
  @Input() admission: Case;
  @Input() appointment: Appointment;
  @Input() appointment_from: string;
  @Input() isVisitCall: string;
  public paymentTypes: PaymentType[] = [];
  public ledger: Ledger = new Ledger();
  public selectedPaymentType: string;
  public services: Observable<any[]>;
  public typedText = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public service_id: string;
  public showNothing = '';
  public current_total: number;
  constructor(private emergency_service: EmergencyService, private loader: LoaderService, private _tab_service: TabsService,
              private cdr: ChangeDetectorRef, private paymentService: PaymentService, public appointment_service: AppointmentService,
              private _servicesService: ServicesService, private globalErrorHandler: GlobalErrorHandlerService) {
    this.services = Observable.create((observer: any) => {
      observer.next(this.typedText);
    }).pipe(mergeMap((token: string) => this.getServices(token)));
  }

  ngOnInit() {
    this.loader.show();
    this.getPaymentTypes();
  }

  setSearchTerm(term: any) {
    this.typedText = term;
  }

  getPayment() {
    let appointment_id = this.appointment ? this.appointment.id : this.admission.appointment_id;
    this.paymentService.getPayment(appointment_id).then(resp => {
      if (resp.ledger) {
        this.ledger = this.ledger.load_from_json(resp.ledger);
      }
      else {
        this.addServiceLine();
        this.addPaymentLine();
      }
      this.loader.hide();
    })
  }

  getPaymentTypes() {
    this.paymentService.getPaymentTypes().then(paymentTypes => {
        this.paymentTypes = paymentTypes.map(paymentType => new PaymentType().load_from_json(paymentType));
        this.getPayment();
      }
    )
  }

  selectedType(paymentType) {
    this.selectedPaymentType = paymentType.value;
  }

  getServices(term) {
    return this._servicesService.search_services(term);
  }


  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.service_id = e.item.id;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public addServiceLine() {
    let payment = new Payment();
    this.paymentDiscriptionAmount(payment, 'Service');
    payment.payment_type_id = this.paymentTypes[0].id;
    payment.paymentType = this.paymentTypes[0];
    payment.current_total = this.ledger.balance;
    this.ledger.payments.push(payment);
  }
  public paymentDiscriptionAmount(payment, type) {
    if (type === 'Service') {
      payment.amount = this.appointment ? +this.appointment.charges : +this.admission.doctor.doctor_practice_visit_charges;
      payment.description = this.appointment ? (this.appointment.service ? this.appointment.service.name : '') : (this.admission.service ? this.admission.service.name : '');
    }
    if (type === 'Payment') {
      payment.amount = 0;
      payment.description = 'Cash Paid.'
    }
  }
  public addPaymentLine() {
    let payment = new Payment();
    payment.payment_type_id = this.paymentTypes[2].id;
    payment.paymentType = this.paymentTypes[2];
    payment.current_total = this.ledger.balance;
    this.paymentDiscriptionAmount(payment, 'Payment');
    this.ledger.payments.push(payment);
  }

  total_balance(payment, index) {
    let current_index = index;
    let previous_index = (index == 0 ) ? index : (index - 1);
    if (payment.paymentType.balance_type == 'credit') {
      this.ledger.payments[current_index].current_total = +this.ledger.payments[previous_index].current_total + (payment.amount == undefined ? 0 : +payment.amount);
    }
    else {
      this.ledger.payments[current_index].current_total = +this.ledger.payments[previous_index].current_total - (payment.amount == undefined ? 0 : +payment.amount);
    }
    this.cdr.detectChanges();
  }

  savePayment() {
    this.ledger.appointment_id = this.appointment ? this.appointment.id : this.admission.appointment_id;
    this.ledger.appointment_status = this.set_label(this.appointment ? this.appointment.status_id : this.admission.appointment.status_id);
    this.paymentService.save(this.ledger).then(resp => {
      if (resp.status == 'success') {
        this.ledger = this.ledger.load_from_json(resp.ledger);
        this._tab_service.set_data(true);
        this._tab_service.close_tab(this._tab_service.active_tab());
        this.appointment_service.reloadSchedulerAppointments.next(this.ledger.appointment_id)
        if (this.ledger.appointment_status == 'checkedin') {
          this.add_to_tabs('Receipt');
        }
      }
      this.emergency_service.applyFilters();
    }).catch(resp => this.globalErrorHandler.error = resp.error);
  }

  changePaymentTypeId(payment, type, payment_array_index) {
    if (type.options[type.options.selectedIndex].value == 'Discount') {
       payment.description = null;
       payment.amount = null;
    }
    else {
      this.paymentDiscriptionAmount(payment, type.options[type.options.selectedIndex].value);
    }
    this.ledger.payments[payment_array_index].payment_type_id = type.options[type.options.selectedIndex].id;
    this.ledger.payments[payment_array_index].paymentType = this.paymentTypes.filter(paymentType => paymentType.id == type.options[type.options.selectedIndex].id)[0];
    this.recalculateTotal(payment, payment_array_index);
  }

  setAmount(payment, tempRef, payment_array_index) {
    this.ledger.payments[payment_array_index].amount = (tempRef.value == '') ? 0 : tempRef.value;
  }

  recalculateTotal(payment, payment_array_index) {
    this.ledger.payments = this.ledger.payments.map((payment, i= 0) => {
      if (i >= payment_array_index) {
        payment.current_total = 0;
        this.total_balance(payment, i);
      }
      return payment;
    })
  }

  calculateBalance(payment, tempRef, payment_array_index, type) {
    if (type == 'credit') {
      payment.amount = tempRef.value;
      this.recalculateTotal(payment, payment_array_index);
    }
    if (type == 'debit') {
      payment.amount = tempRef.value;
      this.recalculateTotal(payment, payment_array_index);
    }
  }

  set_label(status) {
    if (this.appointment_from === 'walkin' || this.appointment_from === 'waiting' || this.appointment_from === 'emergency') {
      return 'Check-In';
    }
    // if (this.appointment_from == 'Emergency') {
    //   return 'checkedin';
    // }
    if (status == 'pending') {
      return 'Check-In';
    }
    if (status == 'scheduled') {
      return 'Check-In';
    }
    if (status == 'confirmed') {
      return 'Check-In';
    }
    if (status == 'checkedin') {
      return 'Check-Out';
    }
    if (status == 'Save') {
      return 'Save'
    }
    if (status == 'Payment') {
      return 'Save'
    }
  }

  add_to_tabs(type) {
    this._tab_service.add_tab(new Tab({
      title: type,
      icon: 'fas fa-print'
    }, PdfPrinterComponent, this.ledger.id + type, {ledger: this.ledger, type: type}))
  }
  add_single_payment(type, payment) {
    let appointment_id = this.appointment ? this.appointment.id : this.admission.appointment_id;
    console.log(appointment_id);
    this._tab_service.add_tab(new Tab({
      title: type,
      icon: 'fas fa-print'
    }, SinglePaymentComponent, payment.id, {appointment: appointment_id, payment: payment, type: type}))
  }


  removePayment(payment) {
    this.ledger.payments.splice(this.ledger.payments.indexOf(payment), 1);
    if (this.ledger.payments.length == 0) {
      this.ledger.balance = 0;
    }
    if (payment.id) {
        this.paymentService.delete(payment.id).then(resp => this.ledger = this.ledger.load_from_json(resp.ledger))
    }
  }
}
