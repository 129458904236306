import {Component, OnInit, Injector, HostListener, TemplateRef, OnDestroy} from '@angular/core';
import {EnoteService} from '../../services/enote.service';
import {Template} from '../enote_models/template';
import {EnoteComponent} from '../enote/enote.component';
import {PatientService} from '../../services/patient.service';
import {Patient} from '../../health/doctor/patient';
import {Appointment} from '../../health/models/appointment';
import {PatientVital} from '../../routes/practice-site/vital/PatientVital';
import { Observable } from 'rxjs';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {mergeMap} from 'rxjs/operators';
import {VideoChatService} from '../../video-chat/services/videochat.service';
import {LoaderService} from '../../services/loader.service';
import {RichTextTemplate} from '../../routes/rich-text-template-index/rich-text-template';
import {Subscription} from "rxjs/Subscription";
import {TabsService} from "../../services/tabs.service";

@Component({
  selector: 'app-patient-note',
  templateUrl: './patient-note.component.html',
  styleUrls: ['./patient-note.component.scss'],
})
export class PatientNoteComponent implements OnInit, OnDestroy {
  bsModal: BsModalRef;
  isSpecial = false;
  custom_injector: Injector;
  videoCustomInjector: Injector;
  enoteComponent: any = EnoteComponent;
  public selectedTemplate: Template;
  public appointment: Appointment;
  note_id: any;
  public stage: any;
  public doctor_id: any;
  public source_id: any;
  public source_type: any;
  public patient: Patient;
  public patientVital: PatientVital;
  public innerWidth: any;
  public patient_latest_three_enotes: any[] = [];
  public templates: any[];
  public limited_templates: any;
  public note_type = 'template';
  public show_enote = false;
  public note_limit = 3;
  public template_limit = 3;
  public patientID: any;
  public loader = false;
  public temp_loader = false;
  public total_templates: number;
  public patient_total_templates: number;
  public templates_list: Observable<any[]>;
  public template_search = '';
  public readonly_template: Template;
  public saving = false;
  public show_video = false;
  public isProgramOrPatientProfile = false;
  public is_rich_text;
  public selectedRichTextTemplate: RichTextTemplate = new RichTextTemplate();
  public viewRichText = false;
  public selectedRichTextSubscription: Subscription;
  constructor(private injector: Injector, public enoteService: EnoteService,
              private bsModalService: BsModalService, private loaderService: LoaderService,
              private patientService: PatientService, public tabsService: TabsService,
              public videoChatService: VideoChatService) {
    this.selectedRichTextSubscription = this.enoteService.reloadRichTextTemplate.subscribe(item => {
      if (this.selectedRichTextTemplate.id == item.id) {
        this.selectedRichTextTemplate = new RichTextTemplate().load_from_json(item);
      }
    });

    this.templates_list = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.template_search);
    }).pipe(mergeMap((token: string) => this.getPublicTemplates(token)));
  }

  getPublicTemplates(token: any) {
    return this.enoteService.get_public_templates(token);
  }
  typeaheadOnTemplateSelect(event, template: TemplateRef<any>) {
    this.enoteService.getTemplate(event.item.id, true)
      .then(resp => {
        this.readonly_template = new Template().load_from_json(resp)
        this.readonly_template.stage = 'read_only_template';
        this.bsModal = this.bsModalService.show(template);
        this.template_search = null;
      });
  }
  save_as_my_template() {
    this.saving = true;
    this.readonly_template.owner_id = null;
    this.readonly_template.stage = 'enote_temp';
    this.enoteService.create(this.readonly_template, true).then(resp => {
      this.enoteService.get_templates().then(resp => {
        this.templates = resp
        this.saving = false;
      });

    })
    this.bsModal.hide();
  }
  hideModal() {
    this.enoteService.template = null;
    this.bsModal.hide();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.patientID = this.injector.get('patientID', null);
    if (this.patientID) {
      this.patientService.getPatient(this.patientID).then(resp => this.patient = new Patient().load_from_json(resp));
    }
    let note_id = this.injector.get('note_id', null);
    this.is_rich_text = this.injector.get('is_rich_text', false);
    this.viewRichText = this.injector.get('viewRichText', false);
    this.selectedRichTextTemplate = this.injector.get('rich_text_template', this.selectedRichTextTemplate);
    this.isProgramOrPatientProfile = this.injector.get('isProgramOrPatientProfile', null);
    this.show_video = this.injector.get('show_video', null);
    this.doctor_id = this.injector.get('doctor_id', null);
    this.videoChatService.showVideo = this.show_video;
    this.videoInjector();
    this.stage = this.injector.get('stage', null);
    this.source_id = this.injector.get('source_id', null);
    this.source_type = this.injector.get('source_type', null);
    if (note_id && !this.is_rich_text) {
      console.log(note_id);
      this.show_enote = true;
      this.setSelectedTemplate(note_id, this.stage);
      this.setCustomInjector(note_id);
    }
    if (this.is_rich_text) {
      this.viewRichTextTemplate();
    }

    this.enoteService.get_templates().then(resp => this.templates = resp);
    this.limit_templates();
    if (!this.isProgramOrPatientProfile) {
      this.latest_five_enotes(this.patientID, this.doctor_id);
    }
  }

  viewRichTextTemplate(selectedRichTextTemplate?: RichTextTemplate ) {
    if (selectedRichTextTemplate) {
      this.selectedRichTextTemplate = new RichTextTemplate().load_from_json(selectedRichTextTemplate);
      this.stage = 'richText';
    }
    this.note_type = 'richText';
    this.viewRichText = true;
    this.is_rich_text = true;
    this.enoteService.template = new Template();
    this.selectedTemplate = new Template();
    this.setCustomInjector(undefined);
    this.show_enote = true;
  }
  limit_templates() {
    this.enoteService.limited_templates(this.template_limit, this.doctor_id, this.patientID).then(resp => {
        this.limited_templates = resp.templates;
        this.total_templates = resp.total_templates;
        this.temp_loader = false;
      }
    ).catch(error => {
      this.temp_loader = false;
      console.log(error)
    });
  }

  latest_five_enotes(patientID: any, doctorID?: any) {
    this.enoteService.getLatestFiveEnotes(patientID, this.note_limit, doctorID).then(resp => {
      this.patient_latest_three_enotes = resp.templates.map(p => {
        if (p.is_rich_text) {
          return new RichTextTemplate().load_from_json(p);
        } else {
          return  new Template().load_from_json(p);
        }
      });
      this.patient_total_templates = resp.patient_total_templates;
      this.loader = false;
    }).catch(error => {
      console.log(error);
      this.loader = false;
    })
  }
  setSelectedTemplate(note_id, stage) {
    this.stage = stage;
    this.viewRichText = false;
    this.is_rich_text = false;
    this.selectedRichTextTemplate = new RichTextTemplate();
    this.loaderService.show();
    this.enoteService.getTemplate(note_id).then(resp => {
      this.selectedTemplate = this.enoteService.template;
      this.enoteService.template.stage = 'create_enote';
      this.setCustomInjector(this.selectedTemplate.id);
      this.loaderService.hide();
    }).catch(error => {
      console.error(error);
      this.loaderService.hide();
    })
  }
  setCustomInjector(note_id) {
    this.custom_injector  = Injector.create([
      {provide: 'template', useValue: note_id},
      {provide: 'stage', useValue: this.stage},
      {provide: 'source_id', useValue: this.source_id},
      {provide: 'source_type', useValue: this.source_type},
      {provide: 'isProgramOrPatientProfile', useValue: this.isProgramOrPatientProfile},
      {provide: 'patient_name', useValue: this.patient ? this.patient.name : ''},
      {provide: 'patient_id', useValue: this.patientID},
      {provide: 'doctor_id', useValue: this.doctor_id},
      {provide: 'selectedRichTextTemplate', useValue: this.selectedRichTextTemplate},
      {provide: 'is_rich_text', useValue: this.is_rich_text},
      {provide: 'viewRichText', useValue: this.viewRichText},
    ], this.injector);
  }

  videoInjector() {
    this.videoCustomInjector =  Injector.create([
      {provide: 'id', useValue: this.source_id}], this.injector);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }
  move_next() {
    if (this.note_type === 'blank') {
      this.enoteService.getEmptyTemplate().then(resp => {
        this.selectedTemplate = this.enoteService.template;
        this.enoteService.template.stage = 'create_enote';
        this.setCustomInjector(this.selectedTemplate.id);
        this.show_enote = !this.show_enote;
      })
    }
  }

  createRichTextTemplate(template?: RichTextTemplate) {
    this.is_rich_text = true;
    this.viewRichText = false;
    this.stage = 'richText';
    template.id = undefined;
    template.sourceable_id = this.source_id;
    template.sourceable_type = this.source_type;
    template.name = template.name.split('-')[0] + ' - ' + new Date().toLocaleString();
    this.selectedRichTextTemplate = new RichTextTemplate().load_from_json(template);
    this.enoteService.template = new Template();
    this.selectedTemplate = new Template();
    this.setCustomInjector(template.id);
    this.show_enote = true;
  }

  ngOnDestroy(): void {
    this.updateRichTextTemplateOnDestroy();
    this.selectedRichTextSubscription.unsubscribe();
  }

  updateRichTextTemplateOnDestroy() {
    const activeTab = this.tabsService.active_tab();
    if (
      this.selectedRichTextTemplate.is_rich_text &&
      activeTab.dependencies.note_id === this.selectedRichTextTemplate.id &&
      activeTab.dependencies.rich_text_template
    ) {
      activeTab.dependencies.rich_text_template = this.selectedRichTextTemplate;
    }
  }
}
