<ng-container *ngIf="appointment.id == '' ">
  <ng-container *ngIf="_user_service.current_user && load_practice_data(_user_service.current_user)">
    <form #walkinForm="ngForm" class="flex-control flex-column flex-height form-horizontal p-lg scroll-browser">
      <div class="form-group">
        <label class="col-md-2 col-sm-3 col-xs-12 field-label control-label">Patient*</label>
        <div class="col-md-4 col-sm-7 col-xs-9">
          <ng-template  #customItemTemplate let-model="item" let-index="index" let-query="query">
            <div (click)="patientAuthService.checkAuthorization(model,authorizationModal)">
              <span [ngClass]="patientAuthService.isAuthorized(model) ? 'text-black':'text-orange'" [innerHTML]="patientAuthService.highlightQuery(model.complete_detail,query)"></span>
            </div>
          </ng-template>
          <div class="input-group">
            <input [(ngModel)]="asyncSelected" [typeaheadAsync]="true"
                   [typeaheadItemTemplate]="customItemTemplate"
                   typeaheadWaitMs="500"
                   [typeahead]="dataSourcePatients"
                   (typeaheadLoading)="changeTypeaheadLoading($event)"
                   autocomplete="off"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)"
                   [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'complete_detail'"
                   placeholder="Search Patient"
                   name="patients" class="form-control">
            <div class="input-group-addon" *ngIf="typeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
          </div>
          <div *ngIf="typeaheadNoResults" class="text-danger fs13">No Results Found!</div>
        </div>

        <div class="col-md-2 col-sm-2 col-xs-3">
          <button type="button" class="btn btn-secondary btn-outline" (click)="classicModal.show()">Add</button>
        </div>
      </div>
      <ng-container *ngIf="selected_patient && selected_patient.id">
        <div class="form-group">
          <label class="col-md-2 col-sm-3 field-label control-label">Selected Patient*</label>
          <div class="col-md-4 col-sm-7">
            <div class="border-box p-sm">
              <div class="media">
                <div class="media-left"><img class="img-circle thumb32" [src]="selected_patient.profile_pic" alt="Image"/></div>
                <div class="media-body">
                  <h4 class="m0 mb fw-500 fs16">{{selected_patient.user.first_name}} {{selected_patient.user.last_name}}</h4>
                  <h6 class="m0 text-capitalize">
                    <span class="pr"><i class="fa icon-user mr-sm"></i> {{selected_patient.gender_for_display()}} {{show_gender_age()}}</span>
                    <span class="pr" *ngIf="selected_patient.user?.contact_numbers">
                            <a href="javascript:void(0);" class="h6 m0" (click)="phoneService.initiatePhoneCall(selected_patient.mobile_number_value,
                            {name: selected_patient.name, pic: selected_patient.profile_pic})">
                              <i class="fa icon-phone mr-sm"></i> {{selected_patient.mobile_number_value  | mask: ' (000) 000-0000'}}</a>
                    </span>
                    <span><i class="fa icon-location-pin mr-sm"></i> {{selected_patient.user?.address?.city_area?.name}}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="form-group">
        <label class="col-md-2 col-sm-3 field-label control-label">Reason of Visit*</label>
        <div class="col-md-4 col-sm-7">
          <div class="input-group">
            <input [typeahead]="dataSourceServices" name="service"
                   [(ngModel)]="appointment.service_name" typeaheadWaitMs="500" [typeaheadAsync]="true"
                   (typeaheadLoading)="changeTypeaheadLoadingService($event)"
                   (typeaheadNoResults)="changeTypeaheadNoResultsService($event)"
                   (typeaheadOnSelect)="typeaheadOnSelectService($event)" [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'name'" placeholder="Search Reason"
                   class="form-control" type='text' autocomplete="off">
            <div class="input-group-addon" *ngIf="typeaheadLoadingService"><i class="fa fa-spinner fa-spin"></i></div>
          </div>
          <div *ngIf="typeaheadNoResultsService" class="text-danger fs13">No Results Found!</div>
        </div>
      </div>
      <ng-container *ngIf="!booking_from_calendar && !_user_service.current_user.isDoctor" >
        <div class="form-group">
          <label class="col-md-2 col-sm-3 field-label control-label">Doctor*</label>
          <div class="col-md-4 col-sm-7">
            <select [(ngModel)]="doctor_id" name="doctor" #provider class="form-control"
                    (change)='change_provider(provider.value)'>
              <option value="">Providers</option>
              <option *ngFor="let p of providers" [value]="p.id">
                {{p.firstname}} {{p.lastname}}
              </option>
            </select>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="booking_from_calendar">
        <div class="form-group">
          <label class="col-md-2 col-sm-3 field-label control-label">Doctor*</label>
          <div class="col-md-4 col-sm-7">
            <span class="form-control" disabled>{{doctor_info.doctor.firstname}} {{doctor_info.doctor.lastname}}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="booking_from_calendar && selected_location">
        <div class="form-group">
          <label class="col-md-2 col-sm-3 field-label control-label">Location*</label>
          <div class="col-md-4 col-sm-7">
            <span class="form-control" disabled>{{selected_location.name}}</span>
          </div>
        </div>
      </ng-container>
      <div class="form-group" *ngIf="!booking_from_calendar && doctor_locations">
        <label class="col-md-2 col-sm-3 field-label control-label">Location*</label>
        <div class="col-md-4 col-sm-7">
          <select [(ngModel)]="location_id" name="locations" #location class="form-control" (change)='change_location(location.value)'>
            <option value="">Locations</option>
            <option *ngFor="let location of doctor_locations" [value]="location.id">
              {{location.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group" *ngIf="amenities.length > 0">
        <label class="col-md-2 col-sm-3 field-label control-label">Services</label>
        <div class="col-md-4 col-sm-7">
          <select [(ngModel)]="amenity_id" name="amenity_id"  #amenity
                  class="form-control"
                  (change)='change_amenity(amenity.value)'>
            <option value="">Services</option>
            <option *ngFor="let amenity of amenities" [value]="amenity.id">
              {{amenity.name}}
            </option>
          </select>
        </div>
      </div>
      <ng-container *ngIf="amenity_id">
        <div class="form-group">
          <label class="col-md-2 col-sm-3 pt-sm">Appointment:</label>
          <div class="col-xs-5">
            <div class="row">
              <div class="col-sm-5 col-xs-12 mb" *ngIf="booking_from_calendar">
                <label class="pt-sm pr-sm">Date/Time:</label>
                <label class="pt-sm fw-500">{{ time | date: 'short' }}</label>
              </div>
              <div class="col-sm-5 col-xs-12 mb">
                <label class="pt-sm pr-sm">Duration:</label>
                <label class="pt-sm fw-500">{{selected_amenity.duration}} mins</label>
              </div>
              <div class="col-sm-2 col-xs-12 mb">
                <label class="pt-sm pr-sm">Fee:</label>
                <label class="pt-sm fw-500">${{fee}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="col-md-2 col-sm-3 pt-sm">Booking Type:</label>
          <div class="col-xs-5">
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-6" *ngFor="let amenity_method of amenity_methods">
                <label class="white-container-box fw-500 p pt-sm pb-sm d-block text-center">
                  <input type="radio" class="mr-sm" [name]="amenity_method.name" [value]="amenity_method.method_type" [(ngModel)]="appointment.appointment_type"/>
                  {{amenity_method.name}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!book_flow && !booking_from_calendar">
        <div class="form-group">
          <div class="col-md-2 col-sm-3"></div>
          <div class="col-md-4 col-sm-7">
            <button class="btn btn-tertiary col-xs-6" (click)="apply_class('scheduled')" [ngClass]="activeTab">Schedule</button>
            <button class="btn btn-tertiary col-xs-6" (click)="apply_class('waiting')" [ngClass]="deActiveTab">Waiting List</button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="show_waiting">
        <div class="form-group">
          <div class="col-md-2 col-sm-3"></div>
          <div class="col-md-2 col-sm-2">
            <label class="field-label control-label">Time</label>
            <input [(ngModel)]="appointment.date" type="time" class="form-control" id="time" name="time" required placeholder="Time*">
          </div>
          <div class="col-md-2 col-sm-2">
            <label class="field-label control-label">Duration</label>
            <select #selectedslotsize [(ngModel)]="appointment.slot_size" name="slot"
                    (change)="onChangeSlotSize(selectedslotsize.value)" class="form-control">
              <option *ngFor="let slot_size of slot_sizes" value={{slot_size}}>{{slotSizeConversion(slot_size)}}</option>
            </select>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="show_waiting == false && !booking_from_calendar && (amenity_id || amenities_empty) && location_id" >
        <div class="form-group">
          <div class="col-md-2 col-sm-3"></div>
          <div class="col-md-4 col-sm-7">
            <app-slot-navigator [amenity_id]="this.amenity_id"
                                [allow_past_navigation]="true"
                                [location_id]="location_id"
                                [show_navigator]="show_navigator"
                                [physicians]="[doctor.physician]"
                                [autoload]="true"
                                [refresh]="refresh"></app-slot-navigator>
            <app-slots [show_future_slot]="show_future_slots" [grouped_slots]="doctor.physician.grouped_slots"
                       [no_navigate]="true" (slot_clicked)="handle_slot($event)"></app-slots>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selected_slot_calendar && amenities.length === 0">
        <div class="form-group">
          <label class="col-md-2 col-sm-3 field-label control-label">Appointment</label>
          <div class="col-xs-5">
            <div class="row">
              <div class="col-sm-5 col-xs-12 mb">
                <label class="pt-sm pr-sm">Date/Time:</label>
                <label class="pt-sm fw-500">{{ appointment.date | date: 'short' }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-2 col-sm-3"><label class="field-label control-label">Booking Method</label></div>
          <div class="col-md-10 col-sm-9 col-xs-12">
            <label class="border-box fw-400 p-sm mr-lg" *ngIf="selected_slot_calendar.booking_type === 'sab' || selected_slot_calendar.booking_type === 'office'">
              Office Visit
              <label class="m0">${{selected_slot_calendar.office_visit_fee}}</label>
              <input type="radio" class="ml" name="office_visit" value="office_visit" [(ngModel)]="appointment.appointment_type" (change)="set_appointment_type_and_fee('office_visit',selected_slot_calendar.office_visit_fee)" required/>
            </label>
            <label class="border-box fw-400 p-sm mr-lg" *ngIf="selected_slot_calendar.booking_type === 'sab' || selected_slot_calendar.booking_type === 'virtual'">
              Video Call
              <label class="m0">${{selected_slot_calendar.virtual_visit_fee}}</label>
              <input type="radio" class="ml" name="virtual_visit" value="virtual_visit" [(ngModel)]="appointment.appointment_type" (change)="set_appointment_type_and_fee('virtual_visit',selected_slot_calendar.virtual_visit_fee)" required/>
            </label>
            <label class="border-box fw-400 p-sm" *ngIf="selected_slot_calendar.booking_type === 'sab' || selected_slot_calendar.booking_type === 'phone'">
              Phone Call
              <label class="m0">${{selected_slot_calendar.phone_visit_fee}}</label>
              <input type="radio" class="ml" name="phone_visit" value="phone_visit" [(ngModel)]="appointment.appointment_type" (change)="set_appointment_type_and_fee('phone_visit',selected_slot_calendar.phone_visit_fee)" required/>
            </label>
          </div>
        </div>
      </ng-container>
      <div class="form-group">
        <div class="col-md-2 col-sm-3"></div>
        <ng-container *ngIf="show_waiting == false">
          <div class="col-md-4 col-sm-7">
            <button type="submit" class="btn btn-secondary btn-min" [disabled]="is_disable_btn(appointment)"
                    (click)="book_appointment(appointment_from)" id="walkinsubmitbtn">Submit
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="show_waiting">
          <div class="col-md-4 col-sm-7">
            <button type="submit" class="btn btn-secondary btn-min mt-xl"
                    [disabled]="waiting_list_enable_disable(appointment)"
                    (click)="book_appointment(appointment_from)">Submit
            </button>
          </div>
        </ng-container>
      </div>
    </form>
  </ng-container>
</ng-container>

<ng-container *ngIf="appointment.id">
  <app-payment [appointment]="appointment" [appointment_from]="appointment_from"></app-payment>
</ng-container>

<div class="modal modal_popup" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-lg" style="height:534px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close_modal(classicModal)"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Add Patient</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-9">
            <app-demographics *ngIf="show_patient_form" [show_extras]="true" [patient]="patient" [injected_practice]="injected_practice" (new_user)="handle_user($event);close_modal(classicModal);"></app-demographics>
          </div>
          <div class="col-sm-3">
            <div class="user-profile-pic thumbnail">
              <a class="icon-holder" (click)="show_attachment_popup= true">
                <i class="fa fa-upload fa-2x"></i>
                <h5>Upload Your Picture</h5>
              </a>
              <img *ngIf="patient.user && patient.user.profile_pic" class="img-rounded img-responsive"
                   [src]="patient.user.profile_pic" alt="Image"/>
            </div>
            <app-attachments (document_uploaded)="handle_image_upload($event)"
                             (popup_closed)="show_attachment_popup= false" [fileType]="'profile_pic'"
                             [only_modal]="true" [attachable_id]="patient.id" [attachable_type]="'User'"
                             [show]="show_attachment_popup" [auto_upload]="true"></app-attachments>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal modal_popup" bsModal #authorizationModal="bs-modal" [config]="{backdrop: false,ignoreBackdropClick: true}">
  <div class="modal-dialog modal-lg" style="height:500px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="hideModal();"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Patient Authorization Required</h4>
      </div>
      <div class="modal-body">
        <p class="alert alert-danger p">
          <i class="fa fa-exclamation-triangle mr-sm"></i>
          You are not authorized to access this patient record, please get authorization from patient via Text Message or KBA.
        </p>
        <div class="clearfix">
          <span class="fs18 mr-lg vl-m">Authorize by</span>
          <button (click)="patientAuthService.authorizationType('sms')" class="btn btn-primary"><i class="fa fa-mobile-phone fs20 mr-sm"></i> Text Message</button>
          <span class="mr-lg ml-lg vl-m">OR</span>
          <button (click)="patientAuthService.authorizationType('kba')" class="btn btn-primary"><i class="fa fa-file-text-o mr-sm"></i> KBA</button>
        </div>
        <div class="p-lg" *ngIf="patientAuthService.type == 'sms'">
          <h4 class="fs22"><i class="fa fa-mobile-phone mr-sm"></i> Text Message Authorization</h4>
          <p class="text-success mb-lg">Message has been sent. Please ask your patient to provide Verification.</p>
          <div class="row">
            <div class="col-sm-6 col-xs-12 form-group">
              <form (ngSubmit)="verifyOtp()" #PhoneForm="ngForm">
                <label>Mobile Verification*</label>
                <div class="input-group">
                  <input type="text" name="mobile_otp" class="form-control" [(ngModel)]="patientAuthService.data.mobile_otp" required #mobile_otp/>
                  <span class="input-group-btn">
                    <button class="btn btn-primary"  *ngIf="!patientAuthService.verified" type="submit" [disabled]="!PhoneForm.form.valid">
                      <i class="fa fa-check-circle mr-sm"></i> Verify
                    </button>
                    <button class="btn btn-success" *ngIf="patientAuthService.verified"><i class="fa fa-check mr-sm"></i> Verified</button>
                  </span>
                </div>
                <p [ngClass]="patientAuthService.message_class">{{patientAuthService.mobile_message}}</p>
              </form>
            </div>
          </div>
          <h5 class="m0 mb-sm">
            <a class="btn-link" (click)="patientAuthService.sendOtp('sms','')"><i class="fa fa-share-square"></i> Resend</a>
          </h5>
          <p class="m0 fs12">If you haven't received the Mobile Verification within one minute, click <span class="text-primary">"Resend"</span></p>
          <p [ngClass]="patientAuthService.message_class" *ngIf="patientAuthService.type == 'sms'">{{patientAuthService.general_message}}</p>
        </div>
        <div class="p-lg" *ngIf="patientAuthService.type == 'kba'">
          <h4 class="fs22"><i class="fa fa-file-text-o mr-sm"></i> KBA Authorization</h4>
          <p class="text-success mb-lg">Please ask for Patient's Mother Name given at time of Registration.</p>
          <div class="row">
            <div class="col-sm-6 col-xs-12 form-group">
              <form (ngSubmit)="verifyMotherName()" #PhoneForm="ngForm">
                <label>Mother's Maiden Name*</label>
                <div class="input-group">
                  <input type="text" name="mother_name" class="form-control" [(ngModel)]="patientAuthService.data.mother_name" maxlength="15" pattern="[^\s]+" required/>
                  <span class="input-group-btn">
                    <button *ngIf="!patientAuthService.verified " type="submit" class="btn btn-primary" [disabled]="!PhoneForm.form.valid">
                      <i class="fa fa-check-circle mr-sm"></i> Verify
                    </button>
                    <button class="btn btn-success" *ngIf="patientAuthService.verified "><i class="fa fa-check mr-sm"></i> Verified</button>
                  </span>
                </div>
                <p class="text-muted mt-sm fs12">{{patientAuthService.hint}}</p>
                <p [ngClass]="patientAuthService.message_class">{{patientAuthService.mobile_message}}</p>
              </form>
            </div>
          </div>
          <p [ngClass]="patientAuthService.message_class" *ngIf="patientAuthService.type.includes('sms')">{{patientAuthService.general_message}}</p>
        </div>
      </div>
      <div class="modal-footer mt-lg">
        <button class="btn btn-secondary" [disabled]="!patientAuthService.verified" (click)="patientAuthService.closeModal(authorizationModal)">Continue</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="hideModal();">Cancel</button>
      </div>
    </div>
  </div>
</div>
