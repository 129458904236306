import { Injectable } from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class PchfService {
    constructor(private _tokenService: CustomHttpClient) { }

    // Additional Info Calls

    save(addtional_info): Promise<any> {
        if (addtional_info && addtional_info.is_new_record()) {
            return this.create(addtional_info);
        }
        else {
            return this.update(addtional_info);
        }
    }

    create(addtional_info): Promise<any> {
        return this._tokenService
            .post('additional_infos/', addtional_info.to_params())
            .toPromise()
            .then(res => res)
    }

    update(addtional_info) {
        return this._tokenService
            .put('additional_infos/update_additional_info/', addtional_info.to_params())
            .toPromise()
            .then(res => res)
    }

    getAdditionalInfoPatient(patient_id: any) {
      let params: HttpParams = new HttpParams();
      params = params.set('patient_id', patient_id);
      return this._tokenService.get('additional_infos/patient_additional_info', {params: params})
          .toPromise()
          .then(response => response);
    }

    delete_document(attachment: any, patient): Promise<any> {
        let params: HttpParams = new HttpParams();
        params = params.set('attachment_id', attachment.id);
        params = params.set('patient_id', patient.id);
        return this._tokenService.get('additional_infos/delete_pchf_file/', {params: params})
            .toPromise()
            .then(res => res);
    }


    // Case Info Calls

    getCaseInfoPatient(patient: any) {
        let params: HttpParams = new HttpParams();
        params = params.set('patient_id', patient.id);
        return this._tokenService.get('case_infos/patient_case_info', {params: params})
            .toPromise()
            .then(response => response);
    }

    saveCaseInfo(caseInfo) {
        if (caseInfo.is_new_record()) {
            return this.createCaseInfo(caseInfo);
        }
        else {
            return this.updateCaseInfo(caseInfo);
        }
    }

    createCaseInfo(caseInfo) {
        return this._tokenService
            .post('case_infos/', caseInfo.to_params())
            .toPromise()
            .then(res => res)
    }

    updateCaseInfo(caseInfo) {
        return this._tokenService
            .put('case_infos/update_case_info/', caseInfo.to_params())
            .toPromise()
            .then(res => res)
    }

    pchfDoctorsList(token: string): Observable<any> {
        // let args = new HttpParams();
        // args = args.set('token', token);
        return this._tokenService.get('case_infos/get_pchf_doctors?token=' + token).share()
            .map(r => r);
    }
}
