import {Component, Injector, Input, ViewChild} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {Bill} from '../../bill';
import {Tab} from '../../../../tabs/tab';
import {PrintBillComponent} from '../print-bill.component';
import {TabsService} from '../../../../services/tabs.service';
import {ProgramService} from '../../../program.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {EligiblePatient, ProgramEnrollment} from '../../../eligible-patient';
import {LoaderService} from '../../../../services/loader.service';

@Component({
  selector: 'app-report-selection',
  templateUrl: './report-selection.component.html',
  styleUrls: ['./report-selection.component.scss']
})
export class ReportSelectionComponent {
  @ViewChild('reportModel') reportModel;
  @Input() programme_category: any;
  reportMonth: number;
  public care_plan_array = [];
  public maxDate: any = new Date();
  public selectMonth: any = new Date();
  report_for: Record<string, boolean>;
  report_for_array
  checkboxes = [
    'current_diagnosis',
    'past_diagnosis',
    'current_medications',
    'past_medications',
    'current_allergies',
    'past_allergies',
    'care_management',
    'monitoring',
    'health_calendar',
    'consent',
    'care_plan'
  ];
  selectedBill: Bill = {} as Bill;
  showCarePlanDropDown: boolean;
  selectedCarePlan: any;
  public selectedCarePlansArray: any[] = [];
  public selectedCarePlansIds: any[] = [];
  public reportYear: number;
  public isBilling: boolean;
  public enrollment_id: any;

  constructor(public userService: UserService, public tabsService: TabsService, public programService: ProgramService,
              public globalErrorHandlerService: GlobalErrorHandlerService,
              public loaderService: LoaderService) {
    this.resetReport();
  }

  openCloseModal(isBill?: boolean, programEnrollment?: any, bill?: Bill, sMonth?: Date,) {
    if (sMonth) {
      this.selectMonth = sMonth;
    }
    this.isBilling = isBill;
    if (programEnrollment) {
      this.selectedBill.enrollment_id = programEnrollment.id;
      this.selectedBill.patient_json =  { id: programEnrollment.patient_id } as EligiblePatient;
    } else {
      this.selectedBill = bill;
    }
    this.reportModel.show();
  }
  print() {
    this.loaderService.show();
    let title = 'Report'
    let report_for_array = this.getSelectedCheckboxes();
    this.setMonthAndYear();
    this.setSelectedCarePlans();
    this.tabsService.add_tab(new Tab({
      title: title,
      icon: 'fas fa-print'
    }, PrintBillComponent, (this.selectedBill.id || this.enrollment_id)  + title, {bill: this.selectedBill, type: title, report_for: report_for_array,
      report_month: this.reportMonth, selectedCarePlans: this.selectedCarePlansArray,
      year: this.reportYear, isBilling: this.isBilling, report_date: this.selectMonth, programme_category: this.programme_category}))
    this.reportModel.hide();
    this.resetReport();
  }

  resetReport() {
    this.report_for = {};
    this.selectedCarePlansIds = [];
    this.selectedCarePlansArray = [];
    this.showCarePlanDropDown = false;
    for (const checkbox of this.checkboxes) {
      this.report_for[checkbox] = false;
    }
  }


  public getProgramCarePlans() {
    let enrollment_id = this.selectedBill.enrollment_id;
    this.programService.getProgramCarePlansForDropdown(enrollment_id).then(resp => {
      this.care_plan_array = resp
      this.showCarePlanDropDown = true;
    }).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  setMonthAndYear(e?: any) {
    this.reportYear = new Date(this.selectMonth).getFullYear();
    this.reportMonth = new Date(this.selectMonth).getMonth() + 1;
    return this

  }

  changeCheckbox(e: any, $event: any) {
    if (e == 'care_plan') {
      if ($event.target.checked) {
        this.getProgramCarePlans()
      } else {
        this.showCarePlanDropDown = false;

      }
    }
  }

  onCheckboxChange(e: any) {
    if (e.target.checked) {
      this.pushInterventionObjectInArray(e.target.value);
    } else {
      const index = this.report_for_array.findIndex(x => x.id === e.target.value);
      this.report_for_array.splice(index, 1);
    }
  }
  pushInterventionObjectInArray(intervention_id) {
    this.report_for_array.push({id: intervention_id});
  }

  selectedCarePlans($event: any) {
    this.selectedCarePlansIds = $event
  }

  getSelectedCheckboxes() {
    const selectedCheckboxes = [];
    for (const checkbox of this.checkboxes) {
      if (this.report_for[checkbox]) {
        selectedCheckboxes.push(checkbox);
      }
    }
    return selectedCheckboxes;
  }

  setSelectedCarePlans() {
    if (this.selectedCarePlansIds.length > 0) {
      this.selectedCarePlansArray = this.care_plan_array.filter((carePlan) =>
        this.selectedCarePlansIds.includes(carePlan.id)
      );

    }
  }

  disableButton() {
    return this.showCarePlanDropDown ? this.selectedCarePlansIds.length === 0 : false;
  }

}
