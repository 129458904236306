import {Patient} from './patient'
import {Doctor} from '../../routes/practices/doctors/doctor';
export class PatientReview {
  id: number;
  patient_id: number;
  doctor_id: number;
  appointment_id: number;
  average_rating: number;
  description = ''
  patientRatings: PatientRating[];
  patient: Patient;
  created_at: string;
  doctor: Doctor;
  status: number
  load_from_json(json) {
    if (json.doctor) {
      this.doctor = new Doctor().load_from_json(json.doctor)
    }
    if (json.patient) {
    this.patient = new Patient().load_from_json(json.patient);
    }
      this.id = json.id;
      this.patient_id = json.patient_id;
      this.doctor_id = json.doctor_id;
      this.appointment_id = json.appointment_id;
      this.average_rating = json.avarage_rating;
      this.created_at = json.format_date;
      this.description = json.description;
    if (json.patient_ratings) {
      let patientRatings = json.patient_ratings.map(patient_rating =>  new PatientRating().load_from_json(patient_rating))
      this.patientRatings = patientRatings;
    }
    return this;
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
  to_params() {
    return {
      patient_review: {
        description: this.description,
        patient_ratings_attributes: this.patientRatings,
        doctor_id: this.doctor_id,
        appointment_id: this.appointment_id,
        patient_id: this.patient_id,
        avarage_rating: this.average_rating,
        status: this.status

      }
    }
  }
}
export class PatientRating {
  id: number;
  score: number;
  rating_type_id: number;
  patient_review_id: number
  ratingType: RatingType;

  load_from_json(json) {
    this.id = json.id;
      this.rating_type_id = json.rating_type_id;
      this.patient_review_id = json.patient_review_id;
      this.score = json.score;
    if (json.rating_type) {
      this.ratingType = new RatingType().load_from_json(json.rating_type);
    }
    return this;

  }
}
export class RatingType {
  id: number;
  name: string;

  load_from_json(json) {
    this.id = json.id;
      this.name = json.name;
    return this;
  }
  to_params() {
    return {
      ratingtype: {
        name: this.name
      }
    }
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
  get_url(): string {
    return this.is_new_record() ? 'ratings/' : ['ratings', this.id].join('/')
  }
}
