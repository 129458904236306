<div  class="bg-lightest-gray flex-control flex-column p">
  <div>
    <div *ngIf="ledger.id" class="pull-right mb">
      <button class="btn btn-tertiary btn-outline btn-sm" (click)="add_to_tabs('Receipt')">Receipt</button>
      <button class="btn btn-tertiary btn-outline btn-sm ml-sm" (click)="add_to_tabs('Statement')">Statement</button>
    </div>
    <h4 class="mt0">Payment</h4>
  </div>
  <div class="border-box flex-control flex-column scroll-browser pl pr pb">
    <table class="table table-hover">
      <thead>
      <tr>
        <th style="min-width:200px; width:380px;">Type test</th>
        <th style="min-width:200px; width:380px;">Reason</th>
        <th class="text-right" style="min-width:100px;">Charge</th>
        <th class="text-right" style="min-width:100px;">Pay</th>
        <th class="text-right" style="min-width:100px;">Balance</th>
        <th class="text-center" style="min-width:120px;">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let payment of ledger.payments;let i = index">
        <td>
          <select #test (click)="changePaymentTypeId(payment,test,i)" class="form-control" name="paymentType">
            <option  *ngFor="let type of paymentTypes" [id]="type.id" [selected]="payment.paymentType && payment.paymentType.name == type.name" [value]="type.name">
              {{type.name}}
            </option>
          </select>
        </td>
        <td>
          <input *ngIf="payment.paymentType.name == 'Service'" [typeahead]="services" name="service" [(ngModel)]="payment.description"
                 (keyup)="setSearchTerm(payment.description)" typeaheadWaitMs="500"
                 (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadAsync]="true"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                 [typeaheadOptionField]="'name'" placeholder="Search Reason"
                 class="form-control" autocomplete="off">
          <input class="form-control"  *ngIf="payment.paymentType.name == 'Discount' || payment.paymentType.name == 'Payment'"
                 [(ngModel)]="payment.description">
        </td>
        <td>
          <input #tempRefCharge type="number" class="form-control text-right" [disabled]="payment.paymentType.name == 'Discount' || payment.paymentType.name == 'Payment'" id="charge"
                 [value]="payment.paymentType.name == 'Service' ? (payment.amount == undefined ? 0 : payment.amount) : showNothing" (blur)="setAmount(payment,tempRefCharge,i)" (change)="calculateBalance(payment,tempRefCharge,i,'credit')">
        </td>
        <td>
          <input #tempRefPay type="number" class="form-control text-right" [disabled]="payment.paymentType.name == 'Service'" id="pay"
                 (blur)="setAmount(payment,tempRefPay,i)" (change)="calculateBalance(payment,tempRefPay,i,'debit')" [value]="(payment.paymentType.name == 'Discount' || payment.paymentType.name == 'Payment') ? (payment.amount == undefined ? 0 : payment.amount) : showNothing">
        </td>
        <td>
          <input class="form-control text-right" type="number" name="balance" id="balance" (appUpdateTotalBalance)="total_balance(payment,i)"
                 [(ngModel)]="ledger.payments[i].current_total" readonly>
        </td>
        <td class="text-center">
          <a *ngIf="payment.paymentType.name == 'Payment' && payment.id"
             class="btn btn-inverse btn-outline btn-sm mr-sm"
             (click)="add_single_payment('Single Payment',payment)"><i class="fa fa-print"></i>
          </a>
          <a class="btn btn-danger btn-outline btn-sm" (click)="removePayment(payment)"><i class="fa fa-trash"></i></a>
        </td>
      </tr>
      </tbody>
    </table>
    <div><button class="btn btn-secondary btn-outline" (click)="addServiceLine()"><i class="fa fa-plus"></i> Add More</button></div>
    <div class="mt-xl text-center">
      <ng-container *ngIf="isVisitCall == 'true'">
        <button class="btn btn-secondary btn-min" (click)="savePayment()">Save</button>
      </ng-container>
      <ng-container *ngIf="isVisitCall == 'false'">
        <button class="btn btn-secondary btn-min" (click)="savePayment()">{{set_label(appointment ? appointment.status_id : 'Save')}}</button>
      </ng-container>
    </div>
  </div>
</div>
