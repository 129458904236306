<div class="flex-control flex-column flex-height">
  <div class="flex-header">
    <h4 class="m0">{{campaign.id ? 'Edit' : 'Add'}} Campaign</h4>
  </div>
  <form (ngSubmit)="save()" #campaignForm="ngForm" class="flex-body">
    <div class="flex-control flex-column" style="height:100%;">
      <div class="flex-body scroll-browser main-wrapper">
        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label class="field-label">Name*</label>
          </div>
          <div class="col-md-4 col-sm-5">
            <input type="text" class="form-control" placeholder="Name of Campaign"
                   [(ngModel)]="campaign.name" name="name" required>
          </div>
        </div>
        <ng-container *ngIf="campaign.outreach_type === 'email'">
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Subject*</label>
            </div>
            <div class="col-md-4 col-sm-5">
              <input type="text" class="form-control" placeholder="Subject of the email"
                     [(ngModel)]="campaign.subject"
                     name="subject"
                     required>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Preview</label>
            </div>
            <div class="col-md-4 col-sm-5">
              <input type="text" class="form-control" placeholder="Preview text"
                     [(ngModel)]="campaign.preview_text"
                     name="preview_text">
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">From*</label>
            </div>
            <div class="col-md-2 col-sm-5">
              <input type="text" class="form-control" placeholder="From Name" [(ngModel)]="campaign.sender_name"
                     name="from_name" required>
            </div>
            <div class="col-md-2 col-sm-5">
              <input type="email" class="form-control" placeholder="From Email" [(ngModel)]="campaign.sender_email"
                     name="email" [email]="true" required>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Reply to*</label>
            </div>
            <div class="col-md-2 col-sm-5">
              <input type="email" class="form-control" placeholder="Email address"
                     [(ngModel)]="campaign.reply_to" name="reply_to" required>
            </div>
          </div>
        </ng-container>

        <span class="h4">Audience</span>
        <hr class="hr-line mt">

        <div class="row form-group">
          <div class="col-md-2 col-sm-3">
            <label class="field-label">Contact type*</label>
          </div>
          <div class="col-md-2 col-sm-5">
            <select class="form-control" [(ngModel)]="campaign.recipient_type" name="recipient_type" required>
              <option value="" selected>Contact Type</option>
              <option value='patients'>Patients</option>
              <option value='staffs'>All Staff Members</option>
              <option value='doctors'>All Doctors</option>
              <option value='doctors_and_staffs'>All Doctors and Staff Members</option>
            </select>
          </div>
        </div>
        <div *ngIf="campaign.recipient_type === 'patients'">
          <div class="row form-group">
            <div class="col-md-2 col-sm-3 hidden-xs"></div>
            <div class="col-md-10 col-sm-9 col-xs-12">
              <label class="white-container-box fw-500 mr-lg">
                All Patients
                <input type="radio" class="ml" name="all_patients" value="all_patients" [(ngModel)]="campaign.patient_selection"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Select Patients
                <input type="radio" class="ml" name="customized_patients" value="customized_patients" [(ngModel)]="campaign.patient_selection"/>
              </label>
              <label class="white-container-box fw-500">
                Program Patients
                <input type="radio" class="ml" name="customized_patients" value="program_patients" [(ngModel)]="campaign.patient_selection"/>
              </label>
            </div>
          </div>
          <ng-container *ngIf="campaign.patient_selection === 'program_patients'">
            <div class="row form-group">
              <div class="col-md-2 col-sm-3">
                <label class="field-label">Status</label>
              </div>
              <div class="col-md-4 col-sm-5">
                <ss-multiselect-dropdown [options]="e_statuses"
                                         [(ngModel)]="campaign.selected_e_statuses"
                                         name="temp_e_statuses"
                                         [settings]="mySettings"
                                         (ngModelChange)="addEStatusToTempArray($event)">
                </ss-multiselect-dropdown>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-2 col-sm-3">
                <label class="field-label">Programs</label>
              </div>
              <div class="col-md-2 col-sm-9 col-xs-12">
                <label class="white-container-box fw-500 mr-lg">
                  All
                  <input type="radio" class="ml" name="all_program_type" value="all_programs" [(ngModel)]="campaign.program_type"/>
                </label>
                <label class="white-container-box fw-500">
                  Select
                  <input type="radio" class="ml" name="select_program_type" value="customized_programs" [(ngModel)]="campaign.program_type"/>
                </label>
              </div>
              <div class="col-md-4 col-sm-5" *ngIf="campaign.program_type === 'customized_programs'">
                <ss-multiselect-dropdown [options]="practicePrograms"
                                         [(ngModel)]="campaign.selected_progs"
                                         name="temp_programs_array"
                                         [settings]="mySettings"
                                         (ngModelChange)="addProgramTempArray($event)">
                </ss-multiselect-dropdown>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="campaign.patient_selection === 'customized_patients'">
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Location</label>
            </div>
            <div class="col-md-2 col-sm-9 col-xs-12">
              <label class="white-container-box fw-500 mr-lg">
                All
                <input type="radio" class="ml" name="all_location_type" value="location_not_applicable" [(ngModel)]="campaign.location_type"/>
              </label>
              <label class="white-container-box fw-500">
                Select
                <input type="radio" class="ml" name="select_location_type" value="customized_locations" [(ngModel)]="campaign.location_type"/>
              </label>
            </div>
            <div class="col-md-4 col-sm-5" *ngIf="campaign.location_type === 'customized_locations'">
              <ss-multiselect-dropdown [options]="practiceLocations"
                                       [(ngModel)]="campaign.selected_loc"
                                       name="temp_locationsss_array"
                                       [settings]="mySettings"
                                       (ngModelChange)="addLocationTempArray($event)">
              </ss-multiselect-dropdown>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3"><label>Doctor</label></div>
            <div class="col-md-2 col-sm-9 col-xs-12">
              <label class="white-container-box fw-500 mr-lg">
                All
                <input type="radio" class="ml" name="all_doctor_type" value="doctor_not_applicable" [(ngModel)]="campaign.doctor_type"/>
              </label>
              <label class="white-container-box fw-500">
                Select
                <input type="radio" class="ml" name="select_doctor_type" value="customized_doctors" [(ngModel)]="campaign.doctor_type"/>
              </label>
             </div>
            <div class="col-md-4 col-sm-5" *ngIf="campaign.doctor_type === 'customized_doctors'">
              <ss-multiselect-dropdown [options]="providers"
                                       name="temp_doctors_array"
                                       [(ngModel)]="campaign.selected_docs"
                                       [settings]="mySettings"
                                       (ngModelChange)="addDoctorsTempArray($event)">
              </ss-multiselect-dropdown>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Patient Reviews</label>
            </div>
            <div class="col-md-2 col-sm-5">
              <select class="form-control" [(ngModel)]="campaign.patient_review_type" name="patient_review_type">
                <option value='review_not_applicable'>All Patients</option>
                <option value='patient_with_reviews'>All Patients with Reviews</option>
                <option value='patient_with_comments'>Patients with comments</option>
                <option value='patient_with_no_comments'>Patient with No Comments</option>
                <option value='patient_with_ratings'>Patient Rating</option>
                <option value='patient_with_tags'>Patients with tag</option>
              </select>
            </div>
            <div class="col-md-4 col-sm-5" *ngIf="campaign.patient_review_type === 'patient_with_ratings'">
              <ss-multiselect-dropdown [options]="ratingOptions"
                                       name="temp_rating_array"
                                       [(ngModel)]="campaign.selected_ratings"
                                       [settings]="mySettings"
                                       (ngModelChange)="addRatingsTempArray($event)">
              </ss-multiselect-dropdown>
            </div>
            <div class="col-md-4 col-sm-5" *ngIf="campaign.patient_review_type === 'patient_with_tags'">
              <ng-select bindLabel="text" name="temp_tags_array"
                         [multiple]="true"
                         #temp_tags_array
                         [(ngModel)]="campaign.selected_tags"
                         [items]="total_tags"
                         (add)="addTagsTempArray($event)"
                         (search)="typedTags($event)"
                         (remove)="removeTags($event)"
                         placeholder="Search Tag"></ng-select>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Demographic</label>
            </div>
            <div class="col-md-2 col-sm-5">
              <select class="form-control" [(ngModel)]="campaign.demographic_type" name="demographic_type">
                <option value='demographic_not_applicable'>All Demographics</option>
                <option value='zip_code'>ZipCode</option>
                <option value='city'>City</option>
                <option value='state'>State</option>
              </select>
            </div>
            <div class="col-md-4 col-sm-5" *ngIf="campaign.demographic_type === 'city'">
              <ng-select bindLabel="text" name="temp_cities_array"
                         [multiple]="true"
                         #temp_cities_array
                         [(ngModel)]="campaign.selected_city"
                         [items]="total_city"
                         (add)="addCityTempArray($event)"
                         (search)="typedCity($event)"
                         (remove)="removeCity($event)"
                         placeholder="Search City"></ng-select>
            </div>
            <div class="col-md-4 col-sm-5" *ngIf="campaign.demographic_type === 'state'">
              <ng-select bindLabel="text" name="temp_state_array"
                         [multiple]="true"
                         #temp_state_array
                         [(ngModel)]="campaign.selected_state"
                         [items]="total_state"
                         (add)="addStateTempArray($event)"
                         (search)="typedState($event)"
                         (remove)="removeState($event)"
                         placeholder="Search State"></ng-select>
            </div>
            <div class="col-md-4 col-sm-5" *ngIf="campaign.demographic_type === 'zip_code'">
              <ng-select bindLabel="text" name="temp_zip_code_array"
                         [multiple]="true"
                         #temp_zip_code_array
                         [(ngModel)]="campaign.selected_zip_code"
                         [items]="total_zip_code"
                         (add)="addZipCodeTempArray($event)"
                         (search)="typedZipCode($event)"
                         (remove)="removeZipCode($event)"
                         placeholder="Search ZipCode"></ng-select>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Gender</label>
            </div>
            <div class="col-md-10 col-sm-9 col-xs-12">
              <label class="white-container-box fw-500 mr-lg">
                All
                <input type="radio" class="ml" name="all_gender_type" value="gender_not_applicable" [(ngModel)]="campaign.gender_type"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Male
                <input type="radio" class="ml" name="male_gender_type" value="male" [(ngModel)]="campaign.gender_type"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Female
                <input type="radio" class="ml" name="female_gender_type" value="female" [(ngModel)]="campaign.gender_type"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Trans
                <input type="radio" class="ml" name="trans_gender_type" value="trans" [(ngModel)]="campaign.gender_type"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Transgender male/Trans man/Female-to-male
                <input type="radio" class="ml" name="trans_male_type" value="trans_male" [(ngModel)]="campaign.gender_type"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Transgender female/Trans woman/Male-to-female
                <input type="radio" class="ml" name="trans_female_type" value="trans_female" [(ngModel)]="campaign.gender_type"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Genderqueer/Non-binary
                <input type="radio" class="ml" name="non_binary_gender_type" value="non_binary" [(ngModel)]="campaign.gender_type"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Decline to answer
                <input type="radio" class="ml" name="decline_gender_type" value="decline" [(ngModel)]="campaign.gender_type"/>
              </label>
              <label class="white-container-box fw-500">
                Other
                <input type="radio" class="ml" name="other_gender_type" value="other" [(ngModel)]="campaign.gender_type"/>
              </label>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Reason of Visit</label>
            </div>
            <div class="col-md-2 col-sm-9 col-xs-12">
              <label class="white-container-box fw-500 mr-lg">
                All
                <input type="radio" class="ml" name="all_service_type" value="service_not_applicable" [(ngModel)]="campaign.service_type"/>
              </label>
              <label class="white-container-box fw-500">
                Select
                <input type="radio" class="ml" name="select_service_type" value="customized_services" [(ngModel)]="campaign.service_type"/>
              </label>
             </div>
            <div class="col-md-6 col-sm-5" *ngIf="campaign.service_type === 'customized_services'">
              <ng-select bindLabel="text" name="servie"
                         [multiple]="true"
                         #servicesInput
                         [(ngModel)]="campaign.selected_services"
                         [items]="total_services"
                         (add)="add_selected_service($event)"
                         (remove)="removeService($event)"
                         (search)="typedServices($event)"
                         placeholder="Search Reason Of Visit">
              </ng-select>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-2 col-sm-3">
              <label class="field-label">Web Access</label>
            </div>
            <div class="col-md-10 col-sm-9 col-xs-12">
              <label class="white-container-box fw-500 mr-lg">
                All
                <input type="radio" class="ml" name="all_web_access_type" value="web_access_not_applicable" [(ngModel)]="campaign.web_access_type"/>
              </label>
              <label class="white-container-box fw-500 mr-lg">
                Yes
                <input type="radio" class="ml" name="yes_web_access_type" value="yes" [(ngModel)]="campaign.web_access_type"/>
              </label>
              <label class="white-container-box fw-500">
                No
                <input type="radio" class="ml" name="no_web_access_type" value="no" [(ngModel)]="campaign.web_access_type"/>
              </label>
            </div>
          </div>
          </ng-container>
        </div>
      </div>
      <div class="flex-footer">
        <div class="row form-group">
          <div class="col-md-2 col-sm-3"></div>
          <div class="col-md-8 col-sm-5">
            <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
            <button type="submit" class="btn btn-secondary mr" [disabled]="!campaignForm.form.valid" *ngIf="!loaderService.uploading_in_process">{{campaign.id ? 'Update' : 'Save'}} to Continue</button>
            <button class="btn btn-tertiary btn-outline mr" (click)="closeNewForm()">Cancel</button>
            <button *ngIf="campaign.status == 'draft' && campaign.id > 0" class="btn btn-danger btn-sm btn-outline" type="button" tooltip="Delete" (click)="deleteModal.show()">Delete <i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete Campaign</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0">Are You Sure?</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="deleteTemplate(campaign);">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
