import {Component, OnInit, Injector} from '@angular/core';
import { MessageService } from '../../../services/message.service';
import {UserService} from '../../../services/user.service';
import {TabsService} from '../../../services/tabs.service';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  public messages: any[] = [];
  public page = 1;
  public message: any = {};
  public chatroom_users: any[];
  public user: any;
  public task_user: any;
  public isVideo = false;
  constructor(public injector: Injector, public _user_service: UserService,
              public messageService: MessageService,
              public tabs_service: TabsService) {
  }

  ngOnInit() {
    this.isVideo = this.injector.get('video', false);
    this.task_user = this.injector.get('id', null);
    if (!this.task_user || this.isVideo) {
      if (!this.task_user) {
        this.tabs_service.register('chat');
      }
      this.actionCableSocketConnect();
    }
    if (!this.messageService.triggered) {
      this.messageService.triggered = true;
    }
  }

  actionCableSocketConnect() {
    !this.messageService.chat_loaded && this.messageService.userChatroomSubscriberList().then(chatrooms => {
          chatrooms.forEach(chatroom => {
            this.messageService.make_chatroom_connection(chatroom);
          });
          this.chatroom_users = this.messageService.chatrooms;
          this.messageService.chat_loaded = true;
      return true;
      });
  }
}
