import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {Bill} from '../bill';
import {UserService} from '../../../services/user.service';
import {BillService} from '../../bill.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-print-bill',
  templateUrl: './print-bill.component.html',
  styleUrls: ['./print-bill.component.scss']
})
export class PrintBillComponent implements OnInit {
  @ViewChild('receipt', { static: false }) receipt: ElementRef | undefined;

  public bill: Bill;
  public print_type: string;
  public today: Date = new Date();
  public report_month: any;
  public report_date: any;
  public year: any;
  public report_for: any;
  public programme_category: any;
  public reportDetails: any = {};
  public selectedCarePlans: any;
  public isBilling: boolean;


  constructor(public injector: Injector, public userService: UserService, public billService: BillService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public loaderService: LoaderService) { }

  ngOnInit(): void {
    this.bill = this.injector.get('bill', null);
    this.print_type = this.injector.get('type', null);
    this.report_for = this.injector.get('report_for', null);
    this.report_month = this.injector.get('report_month', null);
    this.selectedCarePlans = this.injector.get('selectedCarePlans', null);
    this.isBilling = this.injector.get('isBilling', false);
    this.year = this.injector.get('year', null);
    this.report_date = this.injector.get('report_date', null);
    this.programme_category = this.injector.get('programme_category', null);
    if (this.report_for) {
      this.getReportData(this.report_for);
    } else {
      this.loaderService.hide();
    }
  }

  generatePdf(action) {
    this.loaderService.show()
    this.receipt.nativeElement.style.height = this.receipt.nativeElement.style.offsetHeight;
    html2canvas(this.receipt.nativeElement, {
      width: this.receipt.nativeElement.offsetWidth,
      height: this.receipt.nativeElement.offsetHeight})
      .then((canvas) => {
        let imgData = canvas.toDataURL('image/png');
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let doc = new jsPDF('p', 'mm');
        let position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        if (action === 'print') {
          doc.autoPrint();
          window.open(doc.output('bloburl'), '_blank');
        } else {
          doc.save( 'file.pdf');
        }
        this.loaderService.hide();
      });
  }


  public getReportData(report_for: any) {
    let patient_id = this.bill.patient_json.id;
    let enrolment_id = this.bill.enrollment_id;
    let params = {report_for: this.report_for, month: this.report_month, patient_id: patient_id , enrolment_id: enrolment_id,
      selectedCarePlans: this.selectedCarePlans,
      year: this.year}
    this.billService.getDetailBillReport(params).then(resp => {
      this.reportDetails = resp;
      if (!this.isBilling) {
        this.bill = resp['bill'];
      }
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
}
