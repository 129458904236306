import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '../../../../../services/user.service';

@Component({
  selector: 'app-care-management-table',
  templateUrl: './care-management-table.component.html',
  styleUrls: ['./care-management-table.component.scss']
})
export class CareManagementTableComponent implements OnInit, OnChanges {
  @Input() activities: any[];
  totalTimeSpent: any;


  constructor(public userService: UserService) {
  }

  ngOnInit(): void {
  }

  calculateTotalTimeSpent() {
    this.totalTimeSpent = 0; // Initialize total time spent as a number, not a string
    if (this.activities) {
      this.activities.forEach(activity => {
        const minutes = parseFloat(activity['minutes']); // Convert minutes to a float
        if (!isNaN(minutes)) {
          this.totalTimeSpent += minutes;
        }
      });
    }
    // Use toFixed(2) to format the total with 2 decimal places
    this.totalTimeSpent = this.totalTimeSpent.toFixed(2);
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.calculateTotalTimeSpent();
  }


}
