import {Component, OnInit, Input} from '@angular/core';
import {Invoice} from "../invoice";
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import {LoaderService} from '../../../../services/loader.service';

@Component({
  selector: 'app-invoice-print',
  templateUrl: './invoice-print.component.html',
  styleUrls: ['./invoice-print.component.scss']
})
export class InvoicePrintComponent implements OnInit {
  @Input() invoice: Invoice;
  public loading: boolean = false;
  constructor(public loaderService: LoaderService) { }

  ngOnInit() {
  }

  createPdf(action: string, invoiceRefElement: HTMLElement) {

    this.loading = true;
    this.loaderService.show();
    const imgWidth = 210;
    const pageHeight = 295;
    const invoiceRefHeight = invoiceRefElement.offsetHeight;

    html2canvas(invoiceRefElement, {
      width: invoiceRefElement.offsetWidth,
      height: invoiceRefHeight
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const totalPdfPages = Math.ceil(imgHeight / pageHeight);

      const doc = new jsPDF('p', 'mm');
      let position = 0;

      for (let pageNum = 1; pageNum <= totalPdfPages; pageNum++) {
        if (pageNum > 1) {
          doc.addPage();
        }

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        position -= pageHeight;
      }

      if (action === 'print') {
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
      } else {
        doc.save('file.pdf');
      }

      this.loading = false;
      this.loaderService.hide();
    }).catch(error => {
      console.error('Error generating PDF:', error);
      this.loading = false;
      this.loaderService.hide();
    });
  }

}
