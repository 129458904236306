import {Injectable}     from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {CustomHttpClient} from './custom-http-client.service';

@Injectable()
export class ConfirmationService {
  constructor(private _tokenService: CustomHttpClient) {
  }

  confirmPractice(id: any): Promise<any[]> {

    let params: HttpParams = new HttpParams();
    params = params.set('practice_id', id);

    return this._tokenService.get('confirm', {
       params: params
    }).toPromise().then(response => response);
  }
}
