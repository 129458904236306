import { Injectable } from '@angular/core';
import {Activity} from '../program/activity';
import {EnrolmentsService} from '../program/enrolments.service';
import {Subject} from 'rxjs';
import {TabsService} from './tabs.service';
import {ProgramEnrollment} from '../program/eligible-patient';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private cache: {[id: number]: {description: string, time: number}} = {};
  public enrollmentLogTimeArray: [{id: number, loggedTime: any}] = [{id: 0, loggedTime: 0}];
  private id = 0;
  // stopChatTime: Subject<any> = new Subject<any>();
  closeTabCall: Subject<any> = new Subject<any>();

  constructor(public enrolmentsService: EnrolmentsService, public tabs_service?: TabsService) {
    this.tabs_service.closeEnrolledPatientComponent.subscribe(item => {
      if (item) {
        this.destroyEnrolledPatient(item);
      }
    });
    this.tabs_service.startStopEnrollmentObjectObservable.subscribe(item => {
      if (item) {
          if (item['status'] === 'inactive') {
            item['enrolment'].unsubscribe();
          }
          if (item['status'] === 'active') {
            item['enrolment'].startTimer();
          }
      }
    });
  }

  public startTracking(actionDescription: string): number {
    const id = ++this.id;
    this.cache[id] = { description: actionDescription, time: new Date().getTime() };
    console.log(this.cache);
    return id;
  }

  public stopTracking(actionId: number) {
    const data = this.cache[actionId];
    if (data) {
      let duration: any = new Date().getTime() - data.time;
      duration = this.millisToMinutesAndSeconds(duration);
      delete this.cache[this.id];
      return {...data, duration: parseFloat(duration)};
    }
    throw `No action with id [${actionId}] running! `;
  }

  public deleteTrackingObject(id) {
    delete this.cache[id];
    console.log(this.cache);
  }

  logTimeForPatientActivity(params) {
    let add_activity: Activity = new Activity();
    add_activity.time_log_attributes.minutes = params.duration ? params.duration : '';
    add_activity.time_log_attributes.programme_type = params.programme_type ? params.programme_type : '';
    add_activity.activiable_type = 'ProgrammeEnrolment';
    add_activity.activiable_id = params.activiable_id;
    add_activity.activity_type = params.activity_type;
    add_activity.description = params.description;
    add_activity.source_id = params.source_id;
    add_activity.source_type = params.source_type;
    this.enrolmentsService.addActivityAndLogTime(add_activity).then(resp => {
      this.enrolmentsService.reloadActivityList.next(1);
      this.enrolmentsService.reloadProgramEnrolmentTime.next(params.source_id);
      if (!params.remaningTime) {
        this.enrollmentLogTime(params.activiable_id, params.duration);
      }
    }).catch(res => {
      console.log(res);
    })
  }

  millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds: any = ((millis % 60000) / 1000).toFixed(0);
    return minutes + '.' + (seconds < 10 ? '0' : '') + seconds;
  }

  enrollmentLogTime(id: any, time: any) {
    this.upsert(this.enrollmentLogTimeArray, {id: id, loggedTime: time});
  }

  deleteEnrollmentObjectLog(id: any) {
    this.enrollmentLogTimeArray.forEach((element, index) => {
      if (element.id === id) {
        this.enrollmentLogTimeArray.splice(index, 1);
      }
    });
  }

  upsert(array, item) {
    const i = array.findIndex(_item => _item.id === item.id);
    if (i > -1) {
      array[i].loggedTime = (array[i].loggedTime + item.loggedTime);
      array[i].loggedTime.toFixed(2);
    } else {
      array.push(item);
    }
  }

  getLoggedEnrollmentTime(id) {
    let loggedTime = this.enrollmentLogTimeArray.find(i => i.id === id);
    if (loggedTime) {
      return loggedTime.loggedTime.toFixed(2);
    } else {
      return 0
    }
  }

  secondstominutes(s) {
    return (s - (s %= 60) ) / 60 + (9 < s ? '.' : '.0') + s;
  }

  destroyEnrolledPatient(programEnrollment: ProgramEnrollment): any {
    let logged_time = this.getLoggedEnrollmentTime(programEnrollment.id);
    let session_time: any = this.secondstominutes(programEnrollment.session_time);
    let time_for_log =  session_time - logged_time;
    let programme_type = programEnrollment.selected_enrollment_type;
    let params = {time_for_log: time_for_log.toFixed(2), id: programEnrollment.id, logged_time: logged_time,
      session_time: session_time, patient_name: programEnrollment.patient_name, programme_type: programme_type};
    programEnrollment.session_time = 0;
    programEnrollment.unsubscribe();
    this.closeTabCall.next(params);
  }
}
