<div class="navigator">
  <div class="arrow" *ngIf="show_navigator">
    <a class="previous arrow" (click)="setPreviousDate()" *ngIf="!is_today() || allow_past_navigation"><i class="fa fa-chevron-left"></i></a>
  </div>
  <ul class="list-unstyled">
    <li *ngIf="!is_today()" [class.hover]="slot_dates_service.hoveredDate && (previousDay.setHours(0,0,0,0) == slot_dates_service.hoveredDate.setHours(0,0,0,0))">{{previousDay | date: 'EEE'}}<br>{{previousDay | date: 'MMM d'}}</li>
    <li *ngIf="is_today()" class="fw-bold" [class.hover]="slot_dates_service.hoveredDate && (previousDay.setHours(0,0,0,0) == slot_dates_service.hoveredDate.setHours(0,0,0,0))">Today</li>
    <li [class.hover]="slot_dates_service.hoveredDate && (selected_date.setHours(0,0,0,0) == slot_dates_service.hoveredDate.setHours(0,0,0,0))">{{selected_date | date: 'EEE'}}<br>{{selected_date | date: 'MMM d'}}</li>
    <li [class.hover]="slot_dates_service.hoveredDate && (nextDay.setHours(0,0,0,0) == slot_dates_service.hoveredDate.setHours(0,0,0,0))">{{nextDay | date: 'EEE'}}<br>{{nextDay | date: 'MMM d'}}</li>
  </ul>
  <ng-container *ngIf="show_navigator">
    <a class="next arrow" (click)="setNextDate()"><i class="fa fa-chevron-right"></i></a>
  </ng-container>
</div>
