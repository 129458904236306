import {Component, Input, OnInit} from '@angular/core';
import {ProgramEnrollment} from '../../../eligible-patient';

@Component({
  selector: 'app-print-report-section',
  templateUrl: './print-report-section.component.html',
  styleUrls: ['./print-report-section.component.scss']
})
export class PrintReportSectionComponent implements OnInit {
  @Input() reportDetails: {};
  programEnrollment: ProgramEnrollment = new ProgramEnrollment();
  constructor() {
  }
  ngOnInit(): void {
  }
  showConsentFrom(type: any) {
    if (type === 'care_team') return 'Sign consent via care team members device'
    if (type === 'verbally') return 'Patient verbally consents'
    if (type === 'paper') return 'Paper consent has been signed'

  }

}
