<div *ngIf="show" class="enote-wrapper flex-control flex-column flex-height">
  <ng-container *ngIf="_enote_service.template?.stage != 'edit_temp' && _enote_service.template?.stage != 'new_temp' && _enote_service.template?.stage !='print_enote'">
    <div class="row flex-header" *ngIf="patientVital">
      <div class="col-xs-3" *ngIf="patientVital.temperature.value">
        <div class="media">
          <label class="media-left media-middle field-label">Temp</label>
          <h4 class="media-body media-middle" [ngClass]="patientVital.temperature.descriptor_class" *ngIf="patientVital.temperature.value">
            {{patientVital.temperature.value}}
            <small>F</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.bodyMassIndex.value">
        <div class="media">
          <div class="media-left media-middle field-label">BMI</div>
          <h4 class="media-body media-middle" [ngClass]="patientVital.bodyMassIndex.descriptor_class"  *ngIf="patientVital.bodyMassIndex && patientVital.bodyMassIndex.value">
            {{patientVital.bodyMassIndex.value}}
            <small>{{patientVital.bodyMassIndex.descriptor?.toLowerCase()}}</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.heightInFeet || patientVital.heightInInch">
        <div class="media">
          <div class="media-left media-middle field-label">Ht</div>
          <h4 class="media-body media-middle" *ngIf="patientVital.heightInFeet || patientVital.heightInInch">
            {{patientVital.heightInInch}} <small>In</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.weight.value">
        <div class="media">
          <div class="media-left media-middle field-label">Wt</div>
          <h4 class="media-body media-middle" [ngClass]="patientVital.weight.descriptor_class"  *ngIf="patientVital.weight.value">
            {{patientVital.weight.value}} <small>Lb</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.sBP.value">
        <div class="media">
          <div class="media-left media-middle field-label">BP</div>
          <h4 class="media-body media-middle" *ngIf="patientVital.sBP.value">
            <span [ngClass]="patientVital.sBP.descriptor_class">{{patientVital.sBP.value}}</span>
            /<span [ngClass]="patientVital.dBP.descriptor_class">{{patientVital.dBP.value}}</span>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.respiration.value">
        <div class="media">
          <div class="media-left media-middle field-label">Resp</div>
          <h4 class="media-body media-middle" [ngClass]="patientVital.respiration.descriptor_class"  *ngIf="patientVital.respiration.value">
            {{patientVital.respiration.value}} <small>{{patientVital.respiration.descriptor?.toLowerCase()}}</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.pulse.value">
        <div class="media">
          <div class="media-left media-middle field-label">Pulse</div>
          <h4 class="media-body media-middle" [ngClass]="patientVital.pulse.descriptor_class"  *ngIf="patientVital.pulse.value">
            {{patientVital.pulse.value}} <small>{{patientVital.pulse.descriptor?.toLowerCase()}}</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.spo2.value">
        <div class="media">
          <div class="media-left media-middle field-label">SpO2</div>
          <h4 class="media-body media-middle" [ngClass]="patientVital.spo2.descriptor_class" *ngIf="patientVital.spo2 && patientVital.spo2.value">
            {{patientVital.spo2.value}} <small>{{patientVital.spo2.descriptor?.toLowerCase()}}</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.nprs.value">
        <div class="media">
          <div class="media-left media-middle field-label">Pain</div>
          <h4 class="media-body media-middle" [ngClass]="patientVital.nprs.descriptor_class" *ngIf="patientVital.nprs && patientVital.nprs.value">
            {{patientVital.nprs.value}} <small>{{patientVital.nprs.descriptor?.toLowerCase()}}</small>
          </h4>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="patientVital.bloodGlucose.value">
        <div class="media">
          <div class="media-left media-middle field-label">BG</div>
          <h4 class="media-body media-middle" [ngClass]="patientVital.bloodGlucose.descriptor_class" *ngIf="patientVital.bloodGlucose && patientVital.bloodGlucose.value">
            {{patientVital.bloodGlucose.value}} <small>{{patientVital.bloodGlucose.descriptor?.toLowerCase()}}</small>
          </h4>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="flex-body p scroll-browser" *ngIf="isReadOnly">
    <ng-container *ngFor="let term of _enote_service.templateTermsListing(); let index=index">
      <div contenteditable="false" [id]="'whole-wrapper-'+term.id">
        <div contenteditable="false" *ngIf="term">
          <app-read-only-template [term]="term" [term_index]="index" class="mb-lg"
                                  [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-read-only-template>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="isPrintAble()">
    <ng-container *ngFor="let term of _enote_service.templateTermsListing(); let index=index">
      <div contenteditable="false" [id]="'whole-wrapper-'+term.id">
        <div contenteditable="false" *ngIf="term">
          <app-print-enote [term]="term" [term_index]="index" class="mb-lg"
                           [ngClass]="term.isComponentTypeChild() ? 'flex-component' : '' "></app-print-enote>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isNote();">
    <div class="flex-body p scroll-browser">
      <ng-container *ngFor="let term of _enote_service.templateTermsListing(); let index=index">
        <div contenteditable="false" [id]="'whole-wrapper-'+term.id">
          <div contenteditable="false" *ngIf="term">
            <app-template-note [term]="term" [term_index]="index" class="mb-lg"
                               [ngClass]="term.isComponentTypeChild() ? 'flex-component viewable-Enote' : '' "></app-template-note>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="flex-footer p-sm" style="display:flex; align-items:center;">
      <ng-container *ngIf="!saving">
        <label class="label label-primary fs12 fw-500 m0">Signed by</label>
        <span class="text-capitalize mr ml"> {{_enote_service.template.signature.replace("Signed by ", "")}}</span>
      </ng-container>
      <ng-container *ngIf="!saving">
        <button class="btn btn-secondary btn-min ml-xl mr" type="button" *ngIf="isCarePlan()" (click)="edit_care_plan()">Edit</button>
        <button  class="btn btn-secondary btn-min btn-outline" type="button" (click)="add_enote_printer_to_tabs('Print')">Print</button>
      </ng-container>
      <ng-container *ngIf="saving">
        <i class="fa fa-spinner fa-spin" style="margin:auto;"></i>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="stage == 'create_enote'">
    <div class="flex-body p scroll-browser">
      <ng-container *ngFor="let term of _enote_service.templateTermsListing(); let index=index">
        <div contenteditable="false" [id]="'whole-wrapper-'+term.id">
          <div contenteditable="false" *ngIf="term">
            <app-create-note [term]="term" [term_index]="index" class="mb-lg"
                             [ngClass]="term.isComponentTypeChild() ? 'enote-ui' : '' "></app-create-note>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="flex-footer text-center p-sm">
      <ng-container *ngIf="!saving">
        <button class="btn btn-secondary mr" type="button" (click)="sign_template('signed_enote')">Sign</button>
        <button class="btn btn-tertiary btn-outline" type="button" (click)="save_as_draft('drafted_enote')">Save as Draft</button>
      </ng-container>
      <ng-container *ngIf="saving">
        <i class="fa fa-spinner fa-spin"></i>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="stage == 'drafted_enote'">
    <div class="flex-body p scroll-browser">
      <ng-container *ngFor="let term of _enote_service.templateTermsListing(); let index=index">
        <div contenteditable="false" [id]="'whole-wrapper-'+term.id">
          <div contenteditable="false" *ngIf="term">
            <app-drafted-enote [term]="term" [term_index]="index" class="mb-lg"
                               [ngClass]="term.isComponentTypeChild() ? 'enote-ui' : '' "></app-drafted-enote>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="flex-footer text-center p-sm">
      <ng-container *ngIf="!saving">
        <button class="btn btn-secondary mr" type="button" (click)="sign_template('signed_enote')">Sign</button>
        <button *ngIf="!isCarePlan()" class="btn btn-secondary btn-outline mr" type="button" (click)="save_as_draft('drafted_enote')">Save as Draft</button>
        <button *ngIf="!isCarePlan()" class="btn btn-danger btn-outline mr" type="button" (click)="delete_draft()">Delete</button>
        <button class="btn btn-tertiary btn-outline btn-outline" type="button" (click)="add_enote_printer_to_tabs('Print')">Print</button>
      </ng-container>
      <ng-container *ngIf="saving">
        <i class="fa fa-spinner fa-spin"></i>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="stage == 'edit_temp' || stage == 'new_temp'">
    <div class="flex-header filters-sec">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <input class="form-control" type="text" name="name" placeholder="Name" [(ngModel)]="_enote_service.template.name"/>
          <p class="text-danger fs10 m0 mt-sm lhn" *ngIf="show_tempalte_name_error">Template name is required</p>
        </div>
        <div class="col-md-3 col-sm-6">
          <select class="form-control" [(ngModel)]="_enote_service.template.documentTypeID">
            <option [value]="undefined">Document Type</option>
            <ng-container *ngFor="let docType of docTypes">
              <option [value]="docType.id">{{docType.name}}</option>
            </ng-container>
          </select>
          <p class="text-danger fs10 m0 mt-sm lhn" *ngIf="show_document_type_error">Document Type is required</p>
        </div>
        <div class="col-md-3 col-sm-6">
          <input class="form-control" name="specialty" [required]="true" [(ngModel)]="_enote_service.template.specialtyName"
                 [typeahead]="specialties" typeaheadWaitMs="500"
                 [typeaheadAsync]="true"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 (typeaheadNoResults)="typeaheadNoResults($event)"
                 [typeaheadOptionsLimit]="7"
                 [typeaheadOptionField]="'description'" placeholder="Specialty"
                 autocomplete="off">
          <p class="text-danger fs10 m0 mt-sm lhn" *ngIf="show_specialty_error">Specialty is required</p>
        </div>
      </div>
    </div>
    <div class="flex-body p noscroll">
      <div class="create-note flex-control flex-column scroll-browser">
        <ul class="enote-ui">
          <li *ngFor="let term of _enote_service.templateTermsListing(); let index=index">
            <div class="field" contenteditable="false" [id]="'whole-wrapper-'+term.id">
              <div contenteditable="false" *ngIf="term">
                <app-term [term]="term" [term_index]="index" class="d-block"
                          [ngClass]="term.isComponentTypeChild() ? 'add-next-term' : '' "></app-term>
              </div>
            </div>
          </li>
        </ul>
        <div class="new-term" (click)="addNewComponentTerm()"></div>
      </div>
    </div>
    <div class="flex-footer text-center p-sm">
      <p class="text-danger" *ngIf="message || error_message">{{message || error_message}}</p>
      <button class="btn btn-secondary btn-min mr" type="button" (click)="save_template()" *ngIf="!isReadOnly">
        <i class="fa fa-spinner fa-spin" *ngIf="!show_save_button"></i>
        Save
        <ng-container *ngIf="show_save_button"></ng-container>
      </button>
      <button class="btn btn-tertiary btn-outline btn-min" type="button" *ngIf="_enote_service.template.id"
              (click)="add_enote(_enote_service.template, 'new_temp')">
        Create A Copy
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="stage === 'richText' || is_rich_text">
    <app-rich-text-enote class="flex-container" (printRichText)="add_enote_printer_to_tabs('Print')"
                         (richTextSignedSuccessfully)="richTextSignedSuccessfully($event)"
                         [viewTemplate]="viewRichText"
                         [richTextTemplate]="selectedRichTextTemplate"></app-rich-text-enote>
  </ng-container>

  <p class="text-primary text-center m0" *ngIf="message" style="position:relative; z-index:2;">{{message}}</p>
</div>

