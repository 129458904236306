<div *ngIf="isResolveModel">
  <div class="checkbox c-checkbox clearfix m0">
    <ng-container *ngFor="let intervention of alert_template_interventions">
      <label class="d-block mb-sm">
        <input name="checklist" type="checkbox" [value]="intervention.id"
               [checked]="checkAlreadySelected(intervention)"
               (change)="onCheckboxChange($event)" [disabled]="alert?.status === 'intervened'"/>
        <span class="fa fa-check"></span>
        {{intervention.description}}
      </label>
    </ng-container>
  </div>
</div>
<div class="row form-group">
  <div class="col-md-2 col-sm-3">
    <label>Add More</label>
  </div>
  <div class="col-md-6 col-sm-5">
    <div class="input-group">
      <input class="form-control" placeholder="Search or Type to add" name="search_interventions"
             [(ngModel)]="token" [typeahead]="data_source"
             typeaheadWaitMs="500" (typeaheadLoading)="changeTypeaheadLoading($event)"
             [typeaheadAsync]="true"
             (typeaheadNoResults)="changeTypeaheadInterventionNoResults($event)"
             (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
             [typeaheadOptionField]="'description'" [disabled]="alert?.status === 'intervened'"/>
      <div class="input-group-addon" *ngIf="typeaheadLoading"><i class="fa fa-spinner fa-spin"></i></div>
    </div>
  </div>
  <div class="col-md-2 col-sm-2" *ngIf="typeaheadInterventionNoResults">
    <button class="btn btn-secondary btn-min" (click)="addIntervention()" [disabled]="alert?.status === 'intervened'">Add</button>
  </div>
</div>
<div class="row form-group" *ngIf="!isResolveModel">
  <div class="col-md-2 col-sm-3">
  </div>
  <div class="col-md-5 col-sm-5">
    <ul class="list-unstyled border-list m0 scroll-browser" >
      <ng-container *ngFor="let intervention of alert_template_interventions">
        <ng-container *ngIf="!intervention._destroy">
          <li class="list">
            {{intervention.description}}
            <a (click)="removeTempIntervention(intervention)"><i class="fa fa-times vl-m ml text-danger"></i></a>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>
