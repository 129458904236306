<div class="modal fade" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog" style="height:380px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Attach from medical chart</h4>
      </div>
      <div class="modal-body">
        <ul class="p0 user-list scroll-browser user-group-list border-list" (scroll)="onScroll($event)">
          <ng-container *ngFor="let document of documents">
            <li class="list" (click)="sendDocument(document)">
              <small class="fs10 pull-right text-muted">Uploaded on: {{document.no_of_days}}</small>
              <h5 class="m0 fw-500"><i class="fa icon-docs"></i>&nbsp;{{document.documentType.name}}</h5>
              <h5 class="m0 mt-sm fw-400">{{document.name}}</h5>
          </ng-container>
        </ul>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary btn-min" type="submit" (click)="triggerUploadButton()">Upload from Library</button>
        <button type="submit" class="btn btn-tertiary btn-outline btn-min" (click)="classicModal.hide();">Close</button>
      </div>
    </div>
  </div>
</div>
