<div *ngIf="show_form" class="panel panel-default mt-lg">
  <div class="panel-heading"><h3 class="panel-title fs20">Add Calendar</h3></div>
  <div class="panel-body">
    <form (ngSubmit)="save()" #calendarForm="ngForm" class="form-horizontal">
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">Location</label>
        <div class="col-sm-2">
          <select #selectedLocation [(ngModel)]="calendar.location_id" name="location" (change)="onChange(selectedLocation.value)" class="form-control">
            <option value="">Please Select</option>
            <option *ngFor="let location of calendar_locations" value={{location.id}}>{{location.name}}</option>
          </select>
          <!--<div *ngIf="customValidations.formErrors.firstname" style="color: red">-->
          <!--{{ customValidations.formErrors.firstname }}-->
          <!--</div>-->
        </div>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">Name*</label>
        <div class="col-sm-2">
          <input type="text" class="form-control" id="name" [(ngModel)]="calendar.name" name="name" required
                 placeholder="Name">
          <!--<div *ngIf="customValidations.formErrors.lastname" style="color: red">-->
          <!--{{ customValidations.formErrors.lastname }}-->
          <!--</div>-->
        </div>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">Week Days</label>
        <ul class="col-sm-10 weekdays list-unstyled">
          <li>
            <input id="day_monday" type="checkbox" [(ngModel)]="calendar.monday" [checked]="calendar.monday" name="monday" [value]="calendar.monday ? 1 : 0"/>
            <label class="ml-lg" for="day_monday">Monday</label>
          </li>
          <li>
            <input type="checkbox" [(ngModel)]="calendar.tuesday" [checked]="calendar.tuesday" name="tuesday" [value]="calendar.tuesday ? 1 : 0" id="day_tuesday"/>
            <label for="day_tuesday" class="ml-lg">Tuesday</label>
          </li>
          <li>
            <input type="checkbox" [(ngModel)]="calendar.wednesday" [checked]="calendar.wednesday" name="wednesday" [value]="calendar.wednesday ? 1 : 0" id="day_wednesday"/>
            <label for="day_wednesday" class="ml-sm">Wednesday</label>
          </li>
          <li>
            <input type="checkbox" [(ngModel)]="calendar.thursday" [checked]="calendar.thursday" name="thursday" [value]="calendar.thursday ? 1 : 0" id="day_thrusday"/>
            <label class="ml-lg" for="day_thrusday">Thursday</label>
          </li>
          <li>
            <input type="checkbox" [(ngModel)]="calendar.friday" [checked]="calendar.friday" name="friday" [value]="calendar.friday ? 1 : 0" id="day_friday"/>
            <label class="ml-lg"for="day_friday">Friday</label>
          </li>
          <li>
            <input type="checkbox" [(ngModel)]="calendar.saturday" [checked]="calendar.saturday" name="saturday" [value]="calendar.saturday ? 1 : 0" id="day_saturday"/>
            <label class="ml-lg" for="day_saturday">Saturday</label>
          </li>
          <li>
            <input type="checkbox" [(ngModel)]="calendar.sunday" [checked]="calendar.sunday" name="sunday" [value]="calendar.sunday ? 1 : 0" id="day_sunday"/>
            <label class="ml-lg" for="day_sunday">Sunday</label>
          </li>
        </ul>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">From*</label>
        <div class="col-sm-2">
          <!--<input [(ngModel)]="calendar.from" name="from" class="form-control wd-sd"
                 ngui-datetime-picker
                 [disabled-dates]="date2DisabledDates"
                 [min-date]="today"
                 [max-date]="date2MaxDate"
                 [is-draggable]="false"
                 [show-close-layer]="true"
                 [show-week-numbers]="false"
                 date-only="true" required/>-->
          <input [(ngModel)]="calendar.from" name="from" class="form-control"
                 [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                 [minDate]="minFromDate"
                 bsDatepicker
                 required/>
          <!--<div *ngIf="customValidations.formErrors.suffix" style="color: red">-->
          <!--{{ customValidations.formErrors.suffix }}-->
          <!--</div>-->
        </div>
        <label class="col-sm-2 p0 pt-sm text-center">To* &nbsp;</label>
        <div class="col-sm-4">
          <!--<input [(ngModel)]="calendar.to" name="to" class="form-control wd-sd"
                 ngui-datetime-picker
                 [disabled-dates]="date2DisabledDates"
                 [min-date]="today"
                 [max-date]="date2MaxDate"
                 [show-close-layer]="true"
                 [show-week-numbers]="false"
                 date-only="true" required/>-->
          <input [(ngModel)]="calendar.to" name="to" class="form-control wd-sd"
                 [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                 [minDate]="minFromDate"
                 bsDatepicker
                 required/>
          <!--<div *ngIf="customValidations.formErrors.prefix" style="color: red">-->
          <!--{{ customValidations.formErrors.prefix }}-->
          <!--</div>-->
        </div>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">Day Start*</label>
        <div class="col-sm-2">
          <input required [(ngModel)]="calendar.day_starts_at" name="day_starts_at" class="form-control" type="time"/>
        </div>
        <label class="col-sm-2 p0 pt-sm text-center">End*</label>
        <div class="col-sm-3">
          <input  required [(ngModel)]="calendar.day_ends_at" name="day_ends_at" class="form-control wd-sd" type="time" />
        </div>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">Break Start</label>
        <div class="col-sm-2">
          <input  [(ngModel)]="calendar.break_starts_at" name="break_starts_at" class="form-control" type="time" />
          <!--<div *ngIf="customValidations.formErrors.suffix" style="color: red">-->
          <!--{{ customValidations.formErrors.suffix }}-->
          <!--</div>-->
        </div>
        <label class="col-sm-2 p0 pt-sm text-center">End</label>
        <div class="col-sm-3">
          <input  [(ngModel)]="calendar.break_ends_at" name="break_ends_at" class="form-control wd-sd" type="time" />
          <!--<div *ngIf="customValidations.formErrors.prefix" style="color: red">-->
          <!--{{ customValidations.formErrors.prefix }}-->
          <!--</div>-->
        </div>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">Slot Size*</label>
        <div class="col-sm-2">
          <select #selectedslotsize [(ngModel)]="calendar.slot_size" name="slot" (change)="onChangeSlotSize(selectedslotsize.value)" class="form-control" required>
            <option value="">Please Select</option>
            <option *ngFor="let slot_size of slot_sizes" value={{slot_size}}>{{slotSizeConversion(slot_size)}}</option>
          </select>
          <!--<div *ngIf="customValidations.formErrors.suffix" style="color: red">-->
          <!--{{ customValidations.formErrors.suffix }}-->
          <!--</div>-->
        </div>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">Appt Per Slot*</label>
        <div class="col-sm-2">
          <select #selectedappt [(ngModel)]="calendar.appt_per_slop" name="appt_per_slop" (change)="onChangeAppt(selectedappt.value)" class="form-control" required>
            <option value="">Please Select</option>
            <option *ngFor="let appt of appts_per_slot" value={{appt}}>{{appt}}</option>
          </select>
        </div>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0 pt-sm">Booking Type*</label>
        <div class="col-sm-2">
          <select [(ngModel)]="calendar.booking_type" name="booking_type"  class="form-control" required>
            <option value="">Please Select</option>
            <option value="sab">All</option>
            <option value="office">Office</option>
            <option value="virtual">Virtual</option>
            <option value="phone">Phone</option>
          </select>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-4" *ngIf="calendar.booking_type === 'sab' || calendar.booking_type === 'virtual'">
              <div class="row">
                <label class="col-sm-6 p0 pt-sm">Virtual Visit Fee*</label>
                <div class="col-sm-6">
                  <input  [(ngModel)]="calendar.virtual_visit_fee" name="virtual_visit_fee" class="form-control text-right"/>
                </div>
              </div>
            </div>
            <div class="col-sm-4" *ngIf="calendar.booking_type === 'sab' || calendar.booking_type === 'office'">
              <div class="row">
                <label class="col-sm-6 p0 pt-sm">Office Visit Fee*</label>
                <div class="col-sm-6">
                  <input  [(ngModel)]="calendar.office_visit_fee" name="office_visit_fee" class="form-control text-right"/>
                </div>
              </div>
            </div>
            <div class="col-sm-4" *ngIf="calendar.booking_type === 'sab' || calendar.booking_type === 'phone'">
              <div class="row">
                <label class="col-sm-6 p0 pt-sm">Phone Visit Fee*</label>
                <div class="col-sm-6">
                  <input  [(ngModel)]="calendar.phone_visit_fee" name="phone_visit_fee" class="form-control text-right"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='calendar.calendarable_type == "ClientsService"'>
        <div class="form-group ml0">
          <label class="col-sm-2 p0 pt-sm">Technician</label>
          <div class="col-sm-3">
            <select #selectedCategory [(ngModel)]="calendar.service_technician_id" name="service_technician" class="form-control wd-sd" id="calendar" (change)='changeTechnician(calendar,selectedCategory.value)'>
              <option value="">Please Select</option>
              <option *ngFor="let technician of calendar_technicians;" value={{technician.technician_id}}>
                {{technician.name}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group ml0">
        <label class="col-sm-2 p0"></label>
        <div class="col-sm-10">
          <button *userCan="permissions" type="submit" class="btn btn-primary mr" [disabled]="!calendarForm.form.valid">Submit</button>
          <a class="btn btn-inverse mr" (click)="hide_form(calendar)">Cancel</a>
          <ng-container  *ngIf="calendar.id && calendar.is_active == true">
            <a *userCan="permissions" class="btn btn-danger mr"(click)="delete_calendar()">Delete</a>
          </ng-container>
          <ng-container *ngIf="calendar.id && calendar.is_active == false">
            <a *userCan="permissions" class="btn btn-success mr"(click)="delete_calendar()">Activate</a>
          </ng-container>
        </div>
      </div>
      <div *ngIf="messageAfterSave" class="alert alert-danger">{{error}}</div>
      <div *ngIf="dateRange" class="alert alert-danger">starting range should be less then ending range</div>
      <div *ngIf="weekdaysExist" class="alert alert-danger">select atleast one day for calendar</div>

      <div class="modal fade in d-block" *ngIf="show_popup">
        <div class="modal-dialog" style="margin-top:10em;">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" (click)="hide_form(calendar)"><i class="fa fa-times fs16"></i></button>
              <h4 class="m0 text-center">Update</h4>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger text-center">
                Updating calendar settings will cancel all the affected appointments. Are you sure you want to proceed?
              </div>
            </div>
            <div class="modal-footer text-center">
              <button *userCan="permissions"  type="submit" class="btn btn-primary" (click)="confirmCheck = true"  [disabled]="!calendarForm.form.valid">Save</button>
              <button type="submit" class="btn btn-inverse" (click)="hide_form(calendar)">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
