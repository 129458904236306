<h4 class="text-bold">Care Management Activities</h4>
<div class="table-responsive">
  <table class="table table-bordered table-striped table-hover">
    <thead>
    <tr>
      <th>When</th>
      <th>Activity</th>
      <th>Program</th>
      <th>Type</th>
      <th>By</th>
      <th>Time</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let activity of activities">
      <td class="text-center">{{activity['created_at']}}</td>
      <td>{{activity['description']}}</td>
      <td>{{userService.removeUnderScore(activity['time_log']['programme_type'])}}</td>
      <td class="text-capitalize">{{userService.removeUnderScore(activity['activity_type'])}}</td>
      <td class="text-capitalize">{{activity['actor_name']}}</td>
      <td>{{activity['minutes'] }} {{activity['minutes'] == 'N/A' ? '' : 'min'}}</td>
    </tr>
    <tr>
      <td colspan="4"><b>Total Time Spent:</b></td>
      <td><b>{{totalTimeSpent}} min</b></td>
    </tr>
    </tbody>
  </table>
</div>
