<div class="flex-header filters-sec">
  <div class="row">
    <div class="col-sm-3">
      <select #timeRange class="form-control" (change)='selectTime(timeRange)'>
        <option selected="selected">
          Time Range
        </option>
        <option *ngFor="let time of time_range; let i = index;" value={{time}}>
          {{time}}
        </option>
      </select>
    </div>
    <div class="col-sm-3">
      <select #type class="form-control" (change)='selectType(type)'>
        <option selected="selected">
          Type
        </option>
        <option *ngFor="let documentType of documentsType; let i = index;" value={{documentType.id}}>
          {{documentType.name}}
        </option>
      </select>
    </div>
    <div class="col-sm-3">
      <select #problem class="form-control" (change)='selectProblem(problem)'>
        <option selected="selected">
          Problem
        </option>
        <option *ngFor="let service of services; let i = index;" value={{service.id}}>
          {{service.name}}
        </option>
      </select>
    </div>
    <div class="col-sm-3">
      <button class="btn btn-tertiary btn-outline mr-sm" (click)="filter_documents(false)"><i class="fa fa-filter"></i> Filter</button>
      <button class="btn btn-secondary" (click)="show_form = !show_form"><i class="fa fa-upload"></i> Upload</button>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser hor-scroll-browser">
  <ng-container *ngIf="show_form && patient.id">
    <app-chart [patient]="patient" [appointment]="appointment" (close_form)="show_form_toggle($event)" (update_documents_array)="update_documents_array($event)"></app-chart>
  </ng-container>
  <div class="table-responsive" *ngIf="documents && documents.length > 0">
    <table class="table table-hover m0">
      <thead>
      <tr>
        <th style="min-width:150px;"><a class="text-black" (click)="sort('name')">Name <i class="fa fa-fw fa-sort"></i></a></th>
        <th class="text-center" style="min-width:50px;">Pages</th>
        <th style="min-width:150px;"><a class="text-black" (click)="sort('type')">Type <i class="fa fa-fw fa-sort"></i></a></th>
        <th style="min-width:150px;"><a class="text-black" (click)="sort('reason')">Reason <i class="fa fa-fw fa-sort"></i></a></th>
        <th style="min-width:100px;"><a class="text-black" (click)="sort('doctor')">Doctor<i class="fa fa-fw fa-sort"></i></a></th>
        <th style="min-width:100px;"><a class="text-black" (click)="sort('uploader')">Uploader<i class="fa fa-fw fa-sort"></i></a></th>
        <th style="min-width:50px;"><a class="text-black" (click)="sort('date')">Date<i class="fa fa-fw fa-sort"></i></a></th>
        <th class="text-center">Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let document of documents">
        <tr>
          <td>
           <!-- <a (click)="goto_document(document)">
              <i class="fa fa-envelope pr-sm"></i>{{document.name}}
            </a>-->
            <a (click)="openViewer(document)">
              <i class="fa fa-envelope pr-sm"></i>{{document.name}}
            </a>
          </td>
          <td class="text-center"><span *ngIf="document.pages.length">{{document?.pages?.length}}</span></td>
          <td>{{document.documentType.name}}</td>
          <td>{{document.service_name}}</td>
          <td>
            {{document.doctor.firstname}} {{document.doctor.lastname}}
            <h6 class="m0">{{document.doctor?.designation}}</h6>
          </td>
          <td>
            {{document.added_by.full_name}}
            <h6 class="m0">{{document.added_by.role_title}}</h6>
          </td>
          <td>{{document.date | date: 'MMMM d, y'}}</td>
          <td class="text-center"><a class="btn btn-danger btn-outline btn-sm" (click)="classicModal.show();" ><i class="fa fa-trash"></i></a></td>
          <div class="modal fade" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
            <div class="modal-dialog modal-md" style="height:200px;">
              <div class="modal-content">
                <div class="modal-header">
                  <button class="close" type="button" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
                  <h4 class="modal-title">Delete Document</h4>
                </div>
                <div class="modal-body">
                  <div class="alert alert-danger p m0" style="text-align: center">Are you sure you want to delete the document?</div>
                </div>
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary" (click)="deleteDocument(document);">Yes</button>
                  <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">No</button>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <ng-container *ngIf="totalItems > 10">
      <div class="text-center">
        <pagination [totalItems]="totalItems" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="documents && documents.length <= 0"><app-no-result-found></app-no-result-found></ng-container>
  <app-attachment-viewer (closed)="preview_view_closed()" [active]="true" [isLoadedAttachment]="true"></app-attachment-viewer>
  <!--<div [hidden]="timeline_active()">
    <app-document-viewer (closed)="preview_closed()" [active]="!timeline_active()"></app-document-viewer>
  </div>-->
</div>
