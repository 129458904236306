import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {ProgramService} from '../../../program.service';
import { saveAs } from 'file-saver';
import {Subscription} from 'rxjs/Subscription';
import {AssociateCarePlanWithChartService} from '../../../../routes/practice-site/todays-patients/associate-care-plan-with-chart.service';
import {AttachmentService} from '../../../../services/attachments.service';

@Component({
  selector: 'app-vitals-report-view',
  templateUrl: './vitals-report-view.component.html',
  styleUrls: ['./vitals-report-view.component.scss']
})
export class VitalsReportViewComponent implements OnInit, OnDestroy {
  @Input() patient_id;
  @Input() customToDateReport: string;
  @Input() customFromDateReport: string;
  @Input() report_type: string;
  @Input() report_no_of_days: string;
  public dateRangeValue: Date[];
  public filter = {date: '', patient_id: '', shorts: '', from: '', to: '', report_type: ''};
  public show_pdf: boolean;
  public url;
  public blob: any;
  public subscription: Subscription;

  constructor(public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public programService: ProgramService, public carePlanWithChart:  AssociateCarePlanWithChartService,
              public attachmentService: AttachmentService) {
    this.subscription = this.programService.createReportListener.subscribe(item => {
      if (item) {
        this.getSleepReport();
      }
    });
  }

  ngOnInit(): void {
  }

  getSleepReport() {
    this.loaderService.show();
    this.show_pdf = false;
    this.setGraphFilter();
    this.programService.getSleepReport(this.filter).then(resp => {
      this.blob = new Blob([resp.body], {type: 'application/octet-stream;charset=utf-8'});
      this.url = window.URL.createObjectURL(this.blob);
      this.show_pdf = true;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  setGraphFilter() {
    this.filter['patient_id'] =  this.patient_id;
    this.filter['date'] = this.report_no_of_days;
    this.filter['report_type'] = this.report_type;
    if (this.report_no_of_days !== 'custom') {
      delete this.filter['from'];
      delete this.filter['to'];
    }
    if (this.report_no_of_days === 'custom') {
      this.filter['from'] = this.customFromDateReport;
      this.filter['to'] = this.customToDateReport;
    }
    return this.filter
  }

  download() {
    saveAs(this.blob, 'sleep report ' + new Date().toDateString())
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  associateWithMedicalChart() {
    let params = { fileType: 'document', file: this.blob, title: 'Sleep Report', description: 'Sleep Report',
      attachable_type: 'Document', attachable_id: 0, patient_id: this.patient_id, document_type: 'Care Plan'}
    this.carePlanWithChart.uploadAttachment(this.blob, params);
  }
}
