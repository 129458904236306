import {HttpClient} from '@angular/common/http';
import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, ElementRef, Renderer2 } from '@angular/core';
import {UserService} from '../services/user.service';


@Directive({selector: '[userCan]'})
export class UserCanDirective implements OnInit {
  private hasView = false;
  private usersPermissions: any;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef, private user_service: UserService, private _tokenService: HttpClient) {
  }

  ngOnInit() {
  }

  @Input() set userCan(condition) {
    if (!condition) {
      condition = ''
    }
    let [name, parent, category, type, categoryOnly] = condition.split(',');
    let can_access = categoryOnly ? this.user_service.permittedCategory(category) :  this.user_service.is_permitted(name, parent, category, type);
    if (can_access  && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
    else if (!can_access && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  extractPermissions(name, parent_name, role_type_name?) {
    this.usersPermissions = this.user_service.current_user;
    if (this.usersPermissions.permissions) {
      return this.usersPermissions.permissions.filter(item => (item.name === name && item.parent_name === parent_name && (!role_type_name || item.role_type_name === role_type_name)))
    }
  }
}

@Directive({selector: '[permittedClass]'})
export class UserPermittedDirective implements OnInit {
  @Input('condition') condition;
  constructor(public el: ElementRef, public renderer: Renderer2, private user_service: UserService) {
  }

  ngOnInit() {
    if (!this.condition) {
      this.condition = ''
    }
    let [name, parent, category, type] = this.condition.split(',')
    let can_access = this.user_service.is_permitted(name, parent, category);
    if (can_access) {
      this.renderer.addClass(this.el.nativeElement, 'permitted');
      this.renderer.removeClass(this.el.nativeElement, 'not-permitted');
    }
    else {
      this.renderer.removeClass(this.el.nativeElement, 'permitted');
      this.renderer.addClass(this.el.nativeElement, 'not-permitted');
    }
  }
}
/*
let can_access;
if(typeof this.conditions != "string"){
  for(let condition of this.conditions) {
    let [name,parent,category,type,categoryOnly] = condition.join(',').split(',');
    can_access = categoryOnly ? this.user_service.permittedCategory(category) : this.user_service.is_permitted(name, parent, category, type);
    if(can_access)
      break
  }
}
else
{
  let [name,parent,category,type,categoryOnly] = this.conditions.split(',');

  can_access = categoryOnly ? this.user_service.permittedCategory(category) : this.user_service.is_permitted(name, parent, category, type);
}*/
