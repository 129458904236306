import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {nullRemover} from '../services/nullRemover';

@Injectable({
  providedIn: 'root'
})
export class BillService {

  constructor(private _tokenService: CustomHttpClient) { }

  getBills(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: nullRemover(filter)});
    return this._tokenService.getWithHeaders('enrolments/bills', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          bills: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }

  signSuperBill(id: any) {
    let  params = {id: id};
    return this._tokenService
      .put( 'enrolments/bill' , params)
      .toPromise()
      .then(response => response)
  }

  getDetailBillReport(param: any) {
    let params = new HttpParams();

    // Add other parameters
    params = params.set('month', param.month);
    params = params.set('patient_id', param.patient_id);
    params = params.set('enrolment_id', param.enrolment_id);

    // Add the report_for array as a single parameter with comma-separated values
    params = params.set('report_for', param.report_for.join(','));
    const selectedCarePlansJson = JSON.stringify(param.selectedCarePlans);
    params = params.set('selectedCarePlans', selectedCarePlansJson);
     return this._tokenService.get('programme_reports' , {params: params})
      .toPromise()
      .then(response => response)

  }
}
