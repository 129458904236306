import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PatientInsurance} from '../../models/patientInsurance';
import {mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs/Rx';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import {PatientService} from '../../../services/patient.service';
import {Patient} from '../../doctor/patient';
import {toLower} from 'lodash';
import {UserService} from '../../../services/user.service';
import {AlertService} from '../../../_alert';

@Component({
  selector: 'app-patient-insurance',
  templateUrl: './patient-insurance.component.html',
  styleUrls: ['./patient-insurance.component.scss']
})
export class PatientInsuranceComponent implements OnInit, OnDestroy {
  public patientInsurance: PatientInsurance = new PatientInsurance();
  public patientInsurances: PatientInsurance[] = [];
  public insurances: Observable<any>;
  @Input() patient: Patient;
  @Input() intakeReport;
  public openInsuranceForm = false;
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public selectedInsuranceToDelete: PatientInsurance;
  public bsValue: Date = new Date();
  public show_attachment_popup = false;
  public fileType: any;
  @ViewChild('cardPicViewModal') cardPicViewModal;
  public pictureUrl: any;

  constructor(private globalErrorHandlerService: GlobalErrorHandlerService, public loaderService: LoaderService,
              public patientService: PatientService, public userService: UserService, public alertService: AlertService) {
    this.insurances = Observable.create((observer: any) => {
      observer.next(this.patientInsurance.insurance_name);
    }).pipe(mergeMap((token: string) => this.patientService.public_search_insurance(token)));
  }

  ngOnInit(): void {
    this.getPatientInsurances();
  }

  getPatientInsurances() {
    if (this.patient.id) {
      this.patientService.getPatientInsurances(this.patient.id).then( insurances => {
        this.patientInsurances = insurances.map(pInsurance => new PatientInsurance().load_from_json(pInsurance))
      }).catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
    }
  }

  public typeaheadOnInsuranceSelection(e: any) {
    this.patientInsurance.insurance_id = e.item.id;
  }

  save() {
    this.loaderService.show();
    this.patientInsurance.patient_id = this.patient.id;
    this.patientInsurance.insurance_type = toLower(this.patientInsurance.insurance_type);
    this.patientService.savePatientInsurance(this.patientInsurance).then( resp => {
        this.patientInsurance = new PatientInsurance();
        this.openInsuranceForm = false;
        this.loaderService.hide();
        this.getPatientInsurances();
        this.alertService.success('Insurance has been saved successfully', this.alertService.options);
      }
    ).catch(resp => {
        console.log(resp);
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      }
    )
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
    this.patientInsurance.insurance_id = undefined;
  }

  editInsurance(insurance: PatientInsurance) {
    this.patientInsurance = new PatientInsurance().load_from_json(insurance)
    this.openInsuranceForm = true;
  }

  addInsurance() {
    this.patientInsurance = new PatientInsurance();
    this.openInsuranceForm = true;
    this.show_attachment_popup = false;
  }

  deleteInsurance(insurance: PatientInsurance) {
    this.loaderService.show();
    this.patientService.deletePatientInsurance(insurance.id).then(resp => {
      this.patientInsurances.splice(this.patientInsurances.indexOf(insurance), 1);
      this.loaderService.hide();
      this.alertService.success('Insurance has been deleted successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  uploadCardPicture(type: string) {
    this.fileType = '';
    this.fileType = type;
    this.show_attachment_popup = true;
    setTimeout( () => {
      this.show_attachment_popup = false;
    }, 75);
  }

  handle_file_upload(file_date) {
    if (file_date) {
      if (this.fileType === 'insurance_back_pic') {
        this.patientInsurance.card_back_pic = file_date.profile_pic;
        this.patientInsurance.attachments_ids.push(file_date.id);
      }
      if (this.fileType === 'insurance_front_pic') {
        this.patientInsurance.card_front_pic = file_date.profile_pic;
        this.patientInsurance.attachments_ids.push(file_date.id);
      }
      this.show_attachment_popup = false;
      this.alertService.success('Successfully uploaded', this.alertService.options);
    }
  }

  showPicture(url: any) {
    this.pictureUrl = url;
    this.cardPicViewModal.show()
  }

  ngOnDestroy(): void {
    this.cardPicViewModal.hide();
    this.show_attachment_popup = false;
  }
}
