import {Injectable} from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import { HttpParams } from '@angular/common/http';
import { User } from './../routes/users/user'
import {nullRemover} from './nullRemover';

@Injectable()
export class UserManagementService {

  constructor(private _tokenService: CustomHttpClient) {
  }

  search_by_role_type(search_token, role_type) {
    let args = new HttpParams();
    args = args.set('search_token', search_token);
    args = args.set('profile_type', role_type);
    return this._tokenService.get('users/search_users', {params: args})
      .toPromise()
      .then(response => response)
  }

  get_user_inital_data(user, profileable_type, profileable_id) {
    let args = new HttpParams();
    args = args.set('profileable_type', profileable_type);
    args = args.set('profileable_id', profileable_id);
    return this._tokenService.get('users/set_initial_user_data', {params: args})
      .toPromise()
      .then(response => user.load_from_json(response))
  }

  get_user_loaded(new_user, user_id) {
    let args = new HttpParams();
    args = args.set('id', user_id);
    return this._tokenService.get('users/get_user', { params: args})
      .toPromise()
      .then(response => new_user.load_from_json(response))
  }

  activate_user(user_id, user: User) {
    return this._tokenService.post('users/activate_user_profile', user.to_params())
      .toPromise()
      .then(response => response)
  }

  create(user: User): Promise<any> {
    return this._tokenService
      .post('users/', user.to_params())
      .toPromise()
      .then(res => user.load_from_json(res))
  }

  update(user: User) {
    return this._tokenService
      .put(['users', user.id].join('/'), user.to_params())
      .toPromise()
      .then(res => user.load_from_json(res))
  }

  save(user: User): Promise<any> {
    if (user.is_new_record()) {
      return this.create(user);
    }
    else {
      return this.update(user);
    }
  }

  apply_filters(profile_type, name, practice_name, group_name, user_name, role_name, status, page) {
    let parent_group = (profile_type == 'practice') ? practice_name : group_name;
    let filter: Object = {parent_group: parent_group, profileable_type: profile_type, name: name,
      practice_name: practice_name, group_name: group_name, user_name: user_name, role_name: role_name,
      status: status, page: page}
    let params = new HttpParams({fromObject: nullRemover(filter)})
    return this._tokenService.getWithHeaders('users/listing_users', {observe: 'response' , params: params, responseType: 'json'  })
      .toPromise()
      .then(response => {
        return {
          users: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      })
  }



  active_or_deactivate_user_profile(user_id, is_active) {
    let params = new HttpParams();
    params =  params.set('user_id', user_id);
    params =  params.set('is_active', is_active);
    return this._tokenService.get('users/active_deactive_user', {params: params})
      .toPromise()
      .then(response => response)
  }
}
