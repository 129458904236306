import { Component, OnInit, AfterViewChecked, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {PatientDocument, DocumentType} from './document';
import {Attachment} from '../../../../routes/practices/attachments/attachment';
import {DocumentsService} from '../../../../services/document.service';
import {ServicesService} from '../../../../services/services.service';
import {Service} from '../../../../routes/practices/practices/practice';
import {DoctorService} from '../../../../services/doctor.service';
import {Doctor} from '../../../../routes/practices/doctors/doctor';
import {Patient} from '../../../doctor/patient';
import {NgForm} from '@angular/forms';
import {CustomValidations} from '../../../../routes/customValidations/custom-validations';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {Appointment} from '../../../models/appointment';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewChecked {
  public items: any;
  public document: PatientDocument = new PatientDocument();
  public attachment: Attachment;
  public documentsType: DocumentType[] = [];
  public value: any = {};
  public doctors: any[];
  @Input() patient: Patient;
  @Input() appointment: Appointment;
  @Output() close_form: EventEmitter<any> = new EventEmitter<any>();
  @Output() update_documents_array: EventEmitter<any> = new EventEmitter<any>();

  public service: Service = new Service();
  public total_services: any[];
  public show_attachment_popup = false;
  public customValidations: CustomValidations;
  @ViewChild('chartForm') ChartForm: NgForm;
  constructor(private _doctorService: DoctorService , private _documentsService: DocumentsService,
              private globalErrorHandlerService: GlobalErrorHandlerService,
              private _servicesService: ServicesService, public loaderService: LoaderService, ) {
    this.customValidations = new CustomValidations();

  }

  ngOnInit() {
    if (this.appointment && this.appointment.status_id === 'checkedout') {
       this.document.doctor_id = this.appointment.doctor_id;
    }
    this.document.patient_id = this.patient.id;
    this._documentsService.getDocumentsType().then(resp => {
      let documentsType = resp.map(documentType => new DocumentType().load_from_json(documentType));
      this.documentsType = documentsType;
    })
    if (this.patient.id) {
      this.fetchDoctors();
    }
  }
  fetchDoctors() {
    this._doctorService.getPatientDoctors(this.patient.id)
      .then(res => this.doctors = res.map(doctor => new Doctor().load_from_json(doctor)))
  }

  typedServices(text: { term: string; items: any[] }) {
    this.getServices(text.term).subscribe(a => {
      this.total_services = a.map(sp => {return {id: sp.id, name: sp.description}});
      return this.items = this.total_services
    });
  }
  public selected_service(value: any): void {
    this.document.service_id = (value.id);
  }
  getServices(term: any) {
    return this._servicesService.search_services(term);
  }
  changeStatus(id) {
    this.document.document_type_id = +id;

  }
  handle_image_upload(data) {
    if (data) {
       this.document.attachment_id =  data.id;
       this.document.data = data.profile_pic;
     }
  }
  choose_file() {
    this.show_attachment_popup = true;
    setTimeout( () => {
      this.show_attachment_popup = false;
    }, 75); }
  save() {
    this.loaderService.show();
    this._documentsService.save(this.document).then(resp => {
      this.document.load_from_json(resp);
      this.update_documents_array.emit(this.document);
      this.loaderService.hide();
    }).catch((res: Response) => {
      let response = res;
      this.globalErrorHandlerService.error = 'Please select a valid file.';
      this.loaderService.hide();
    })

  }
  cancel() {
    this.close_form.emit(false);
  }

  ngAfterViewChecked() {
      this.customValidations.formChanged(this.ChartForm);
  }
  isSubmitable(): boolean {
    if (this.document.document_type_id && this.document.attachment_id && this.document.date && this.document.name) {
      return true
    }
    else {
      return false
    }
  }

}
