<div class="chat-flex">
  <ng-container *ngIf="patient?.phone && smsService.selected_conversation">
    <app-sms-details class="body-panel p-relative" [conversation]="smsService.selected_conversation"></app-sms-details>
  </ng-container>
  <ng-container *ngIf="!patient?.phone">
    <div class="main-wrapper flex-container">
      <div class="alert alert-info text-center">{{ show_im }}</div>
    </div>
  </ng-container>
</div>
