import {Component, Input, OnInit} from '@angular/core';
import {ProgramEnrollment} from '../../eligible-patient';
import {TrackingService} from '../../../services/tracking.service';
import {Subscription} from 'rxjs/Subscription';
import {EnrolmentsService} from '../../enrolments.service';
import {Alert} from '../../alert';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-enrolled-patient-right-panel',
  templateUrl: './enrolled-patient-right-panel.component.html',
  styleUrls: ['./enrolled-patient-right-panel.component.scss']
})
export class EnrolledPatientRightPanelComponent implements OnInit {
  public selected_stage_id: number;
  public stage_ids: number[] = [0, 1, 2];
  public menu_items: Object = {0: 'Plan', 1: 'Alerts', 2: 'Chat'};
  @Input() patient;
  @Input() programme_type;
  @Input() programEnrollment: ProgramEnrollment;
  public actionId: any = '';
  public subscription: Subscription;


  constructor(public trackingService: TrackingService, public userService: UserService) {
/*    this.subscription = this.trackingService.stopChatTime.subscribe(item => {
      if (item) {
        this.selected_stage_id = 0;
        this.trackingService.stopChatTime.next(0);
        this.stopAction();
      }
    });*/
  }

  ngOnInit(): void {
    this.selected_stage_id = 4;
  }

  onMenuSelect(stage_id: number) {
    this.selected_stage_id = stage_id;
/*    if (this.patient.can_login) {
      stage_id == 2 ? this.startAction('im') : this.stopAction();
    }*/
  }

  startAction(description) {
    if (!this.actionId) {
      this.actionId = this.trackingService.startTracking(description);
    }
  }

  stopAction() {
    if (this.actionId) {
      const trackingResult = this.trackingService.stopTracking(this.actionId);
      this.trackingService.logTimeForPatientActivity({duration: trackingResult.duration, description: 'IM with patient',
        activity_type: trackingResult.description , source_id: this.programEnrollment.id,
        activiable_id: this.programEnrollment.id, programme_type: this.programme_type });
      this.actionId = undefined;
    }
  }

}
