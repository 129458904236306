import {Component, OnInit, Input, OnDestroy, IterableDiffers, IterableDiffer, ViewChild} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { MessageService } from './../../../../services/message.service'
import {Chatroom, ChatroomUser} from '../chatroom';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-chat-listing',
  templateUrl: './chat-listing.component.html',
  styleUrls: ['./chat-listing.component.scss']
})
export class ChatListingComponent implements OnInit, OnDestroy {

  public token = '';
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public data_source: Observable<any>;
  public iterableDiffer: IterableDiffer<Chatroom> | null;
  public chatroom_id: number;
  public new_group: Chatroom;
  public filtered_chatrooms;
  public reciever_id: number;
  public middle_selected = false;
  public chatroom_user: any;
  public new_chatroom_user: Chatroom = new Chatroom();
  @Input() practice;
  @Input() task_user;
  @ViewChild('classicModal') modal;
  @Input() chatroom_users;
  public typeheadResult = [];
  public show_group_info: Boolean = false;
  public show_new_group: Boolean = false;
  constructor(public _message_Service: MessageService, public _user_service: UserService, private _iterableDiffers: IterableDiffers) {
    this.iterableDiffer = this._iterableDiffers.find([]).create();
    this._user_service.hideFooterForPatient = true;
  }

  ngOnInit() {
    if (this.task_user) {
      this._message_Service.set_user_on_selection(this.task_user);
    }
    if (this._message_Service.selected_chatroom) {
      this.loadMessages(this._message_Service.selected_chatroom)
    }
    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).mergeMap((token: string) => this.getPraticeStaffAndPatient(token));
    this.setFilteredChatrooms();
    if (this.filtered_chatrooms.length) {
      this._message_Service.changeSelectedChatroom(this.filtered_chatrooms[0]);
    }
    this._message_Service.getOrderChange.subscribe(e => {
      this.setFilteredChatrooms();
    });
    if (!this._message_Service.triggered) {
      this._message_Service.triggered = true;
    }

  }

  ngOnDestroy() {
    this._message_Service.resetOrderChange();
    this._message_Service.triggered = false;
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this._message_Service.chatrooms);
    if (changes) {
      console.log('Changes detected!');
      this.setFilteredChatrooms();
    }
  }

  checkFilters() {
    if (this.token.length == 0) {
      this.setFilteredChatrooms();
      this.typeheadResult = [];
    }
  }

  handleCrossClicked() {
    this.middle_selected = false;
  }

  public getPraticeStaffAndPatient(token): Observable<any> {
    this.setFilteredChatrooms();
    let result =  this._message_Service.getPraticeStaffAndPatient(token);
    console.log(result)
    result.subscribe(data => {
      console.log(data);
      this.typeheadResult = data;
    });
    return result ;
  }

  currentUserWithSetting() {
    return this._user_service.current_user && (this._user_service.current_user.isDoctor || this.current_user().isStaff)
  }

  addToChatroom(recipient) {
    this._message_Service.set_user_on_selection(recipient.id);
  }

  setFilteredChatrooms() {
    if (!this.token || this.token == '') {
      console.log('setting all chatrooms', this._message_Service.chatrooms.length);
    this.filtered_chatrooms  = this._message_Service.chatrooms;
    } else {
      console.log('setting filtered chatrooms');
      this.filtered_chatrooms = this._message_Service.chatrooms.filter(c => c.title.toLowerCase().includes(this.token.toLowerCase()));
    }
  }

  get practice_id() {
    return this.practice.id;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any) {
    this._message_Service.set_user_on_selection(e.item.id);
    this.token = '';
  }

  handleGroupClicked() {
    this.show_group_info = !this.show_group_info;
  }

  public full_name(user) {
    return `${user.first_name} ${user.last_name}`
  }

  makeNewGroup() {
    this.new_group = new Chatroom();
    this.new_group.chatroom_type = 'group_type';
    this.new_group.user_id = this._user_service.current_user.id;
    this.new_group.chatroom_users.push(new ChatroomUser().load_from_json({user_id: this.new_group.user_id}));
    this.show_new_group = true;
  }

  current_user() {
    return this._user_service.current_user;
  }

  chatroom_list() {
    return this._message_Service.chatrooms;
  }

  loadMessages(chatroom) {
    this._message_Service.load_messages(chatroom);
    this.show_group_info = false;
  }
  handlePopupclose() {
    this.show_new_group = false;
  }

  handleGroupCreation(chatroom) {
    this._message_Service.addNewGroup(chatroom);
    this.show_new_group =  false;
    this.show_group_info = true;
  }

  showSettings() {
    this.modal.show();
  }

  saveSettings() {
    let params = {chat_privacy: this.current_user().chat_privacy}
    this._message_Service.saveSettings(params).subscribe(a => {this.modal.hide()});
  }
}
