import { Component, OnInit, Input } from '@angular/core';

import { Doctor } from './../doctor'
import { DoctorService } from './../../../../services/doctor.service'
import  {DoctorProfileDetailComponent} from '../doctor_profile/doctor-profile-detail.component'
import { Observable } from 'rxjs/Observable';
import { mergeMap } from 'rxjs/operators';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';

@Component({
  selector: 'app-doctors-listing',
  templateUrl: './doctors-listing.component.html',
  styleUrls: ['./doctors-listing.component.scss']
})
export class DoctorsListingComponent implements OnInit {
  public expanded_doctor: any;
  public group_array_for_pair: any;
  public show_doctor_form: boolean;
  public show_doctors_listing = true;
  public doctor_listing_array: Doctor[] = [new Doctor()];
  public new_doctor: Doctor = new Doctor();
  public associate_doctor_profile: Doctor;
  public data_source: Observable<any>;
  public token = '';
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  @Input() practice;
  @Input() doctor_permissions;
  constructor(private doctorService: DoctorService, private globalErrorHandlerService: GlobalErrorHandlerService) {

  }

  ngOnInit() {
    this.new_doctor.practice_id = this.practice_id;
    this.fetch_doctors();

    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).pipe(mergeMap((token: string) => this.doctorService.get_associated_doctors(token, this.practice_id)));
  }

  get practice_id() {
    return this.practice.id
  }

  fetch_doctors() {
    this.doctorService.getAllDoctorForPractice(this.practice_id).then(json => {
      this.doctor_listing_array = [];
      for (let doctor_profile of json) {
        let doctor = new Doctor();
        doctor.load_from_json(doctor_profile);
        doctor.practice_id = this.practice_id;
        this.doctor_listing_array.push(doctor);
      }
      this.set_pairs();
    });
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.doctorService.getDoctor(e.item.id).then(json => {
      this.associate_doctor_profile = new Doctor().load_from_json(json);
    });
  }

  doctor_form() {
    this.show_doctor_form = true;
    this.show_doctors_listing = false;
  }

  doctor_listings() {
    return this.doctor_listing_array.filter(d => d.id);
  }

  group_in(array , group_length) {
    let result = [];
    for (let i = 0; i < array.length / group_length ; i ++) {
      result.push([])
    };

    array.forEach((item , index) => {
      result[Math.floor(index / group_length)].push(item);
    });
    return result;
  }

  set_pairs() {
    this.group_array_for_pair = this.group_in(this.doctor_listing_array.filter(d => d.id), 2);
  }

  doctor_expanded(doctor) {
    if (this.expanded_doctor && this.expanded_doctor != doctor) {
      this.expanded_doctor.collapse();
    }
    this.expanded_doctor = doctor;
  }

  handle_new_doctor_hidden() {
    this.show_doctors_listing = true;
    this.show_doctor_form = false;
  }
  doctor_saved(doctor) {
    this.doctor_listing_array.push(this.new_doctor);
    this.new_doctor = new Doctor();
    this.new_doctor.practice_id = this.practice_id;
    this.show_doctors_listing = true;
    this.show_doctor_form = false;
    this.set_pairs();
  }

  associate_doctor_with_practice() {
    this.doctorService.associate_doctor_to_practice(this.practice_id, this.associate_doctor_profile.id).then(json => {
      this.fetch_doctors();
      this.renew_associate_doctor();
    }).catch(resp => {
      if (resp.error.error.includes('upgrade your account')) {
        this.globalErrorHandlerService.message = resp.error.error.replace('Validation failed: ', 'Information Alert:');
      }
      else {
        this.globalErrorHandlerService.error = resp.error.error;
      }
    });
  }

  renew_associate_doctor() {
    this.associate_doctor_profile = null
  }
}
