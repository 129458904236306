import { Directive, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appUpdateTotalBalance]'
})
export class UpdateTotalBalanceDirective {

  @Output() appUpdateTotalBalance: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}
  ngOnInit() {
    this.appUpdateTotalBalance.emit('dummy');
  }

}
