import { Component, OnInit } from '@angular/core';
import {ControlComponent} from '../control/control.component';
import {EnoteService} from '../../services/enote.service';
import {Value} from '../enote_models/control';
import {TermControlValue} from '../enote_models/term_control';

@Component({
  selector: 'app-note-custom-control-value',
  templateUrl: './note-custom-control-value.component.html',
  styleUrls: ['./note-custom-control-value.component.scss']
})
export class NoteCustomControlValueComponent extends ControlComponent implements OnInit {
  public custom_control_values: TermControlValue[];
  constructor(public _enote_service: EnoteService) {
    super(_enote_service, null)
  }
  ngOnInit() {
    let custom_control_values = this.term_control.term_control_values.filter(term_control_value => term_control_value.recent_value);
     this.custom_control_values = custom_control_values;
    console.log(this.custom_control_values);
  }

}
