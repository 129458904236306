import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DatepickerDateCustomClasses} from 'ngx-bootstrap/datepicker';
import {PatientVital} from '../../../../routes/practice-site/vital/PatientVital';
import {VitalsService} from '../../../../services/vitals.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {AlertService} from '../../../../_alert';
import {Subscription} from 'rxjs/Subscription';
import {ProgramService} from '../../../program.service';
import {EligiblePatient} from '../../../eligible-patient';

@Component({
  selector: 'app-vitals-daily-view',
  templateUrl: './vitals-daily-view.component.html',
  styleUrls: ['./vitals-daily-view.component.scss']
})
export class VitalsDailyViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() patient: EligiblePatient;
  @Input() daily_view_month: string;
  public patientVitals: PatientVital[] = [];
  public sleepDataFor = new Date();
  public bsInlineValue = new Date();
  dateCustomClasses: DatepickerDateCustomClasses[];
  date_filter = new Date();
  showCalendar = false;
  selectedPatientVital: PatientVital;
  public subscription: Subscription;
  device_reading_count: any;
  manual_reading_count: any;

  constructor(public vitalService: VitalsService, public loaderService: LoaderService,
              public programService: ProgramService, public alertService: AlertService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
  }

  ngOnInit(): void {
    this.subscription = this.vitalService.reloadEnrolledPatientVitalsList.subscribe(item => {
      if (item) {
        this.getVitalEntryDates();
      }
    });
  }

  changeValue(e: Date) {
    this.sleepDataFor = new Date(e);
    this.getPatientVitals(e);

  }

  getPatientVitals(date?) {
    this.loaderService.show();
    this.vitalService.getPatientVitals(this.patient.id, date ).then(resp => {
      this.patientVitals = resp.map(pv => new PatientVital().load_from_json(pv));
      this.loaderService.hide();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  async getVitalEntryDates() {
    this.loaderService.show();
    this.showCalendar = false;
    this.dateCustomClasses = [];
    await this.vitalService.getVitalEntryDates( this.daily_view_month, this.patient.id.toString()).then
    (resp => {
      if (resp) {
        if (resp['dates']) {
          this.dateCustomClasses = resp['dates'].map(a => ({date: new Date(a['date']), classes: a['classes']}));
        }
        this.manual_reading_count = resp['manual_vitals_count'];
        this.device_reading_count = resp['device_vitals_count'];
        this.showCalendar = true;
      }
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.daily_view_month?.currentValue === 'current') {
      this.bsInlineValue = new Date();
    }
    if (changes.daily_view_month?.currentValue !== 'current') {
      this.bsInlineValue = new Date(changes.minDate.currentValue);
    }
    this.getVitalEntryDates();
  }

  deletePatientVital(patientVital: PatientVital) {
    this.loaderService.show();
    this.vitalService.deletePatientVital(patientVital.id).then(resp => {
      this.patientVitals.splice(this.patientVitals.indexOf(patientVital), 1);
      this.selectedPatientVital = undefined;
      this.getVitalEntryDates();
      this.loaderService.hide();
      this.alertService.success('Patient Vital has been deleted successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSleepData() {
    this.loaderService.show();
    this.programService.getSleepData(this.patient.id, this.sleepDataFor).then(resp => {
      if (resp.message === 'success') {
        this.getVitalEntryDates();
        this.getPatientVitals(this.sleepDataFor)
      }
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  showGetSleepButton(): boolean {
    return this.patientVitals.filter(a => a.vitalable_type === 'SleepResponse').length > 0 ? false : true;
  }
}
