import { Component, OnInit } from '@angular/core';
import { PackagesService } from '../../../../services/packages.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-pricing-info',
  templateUrl: './pricing-info.component.html',
  styleUrls: ['./pricing-info.component.scss']
})
export class PricingInfoComponent implements OnInit {
  public packages: any[];
  public practice_id: string;
  public current_package: any = {};
  constructor(private packagesService: PackagesService, private route: ActivatedRoute) { }

  ngOnInit() {
      this.packagesService.getPackages().then(resp => {
      this.packages = resp;
        this.route.queryParams.subscribe(params => {
          this.practice_id = params['practice_id'];
          if (this.practice_id) {
            this.packagesService.getPracticePackage(this.practice_id).then(resp => {
              let practice_package = resp;
              this.current_package = resp;
              this.packages = this.packages.filter( p =>  p['rank'] > practice_package['rank'])
              if ( this.current_package.package_type.toLowerCase() == 'easy_going') {
                this.packages = this.packages.filter(p => p.package_type.toLocaleLowerCase() == 'ultimate')
              }
                })
          }
        })
    })
  }

  get freePackage() {
    return this.packages.filter(p => p.name.toLowerCase() === 'free')[0];
  }
  get easyGoingPackage() {
    return this.packages.filter(p => p.name.toLowerCase() === 'easy_going')[0];
  }
  get betterDealPackage() {
    return this.packages.filter(p => p.name.toLowerCase() === 'better_deal')[0];
  }
  get ultimatePackage() {
    return this.packages.filter(p => p.name.toLowerCase() === 'ultimate')[0];
  }

}
