import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Physician, Slot} from '../models/physician';
import {SearchService} from '../../services/search.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-virtual-doctors-listing',
  templateUrl: './virtual-doctors-listing.component.html',
  styleUrls: ['./virtual-doctors-listing.component.scss']
})
export class VirtualDoctorsListingComponent implements OnInit {
  @Input() online_virtual_physicians: Physician[];
  physicians: Physician[];
  limit = 8;
  Object = Object;

  today = new Date().toISOString().split('T')[0];
  constructor(public searchService: SearchService, public router: Router) { }

  ngOnInit(): void {
    this.physicians = this.online_virtual_physicians.slice(0, this.limit);
    console.log(this.physicians);
  }

  onLoadMore(): void {
    this.limit = this.limit + 4;
    this.physicians = this.online_virtual_physicians.slice(0, this.limit);
    console.log(this.physicians,this.limit);
  }

  get limited_physicians(): Physician[] {
    console.log(this.online_virtual_physicians);
    return this.online_virtual_physicians.slice(0, this.limit);
  }

  onSlotSelect(slot: Slot) {
    this.router.navigate(['/appointments'], {
      queryParams: {
        app_type: this.searchService.app_type,
        slot_size: slot.slot_size,
        calendar_id: slot.calendar_id ,
        slot: slot.slot
      }
    }).then(r => console.log(r))
  }

}
