import {Component, ViewChild, OnInit, Input, Output, EventEmitter, Injector, OnChanges} from '@angular/core';
import {Doctor} from './../doctor';
import { DoctorService } from './../../../../services/doctor.service';
import {Calendar} from './../doctor';
import {BlockCalendar} from './../doctor';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {UserService} from '../../../../services/user.service';
import {PracticesService} from '../../../../services/practices.service';
import {Attachment} from '../../attachments/attachment';
import {DoctorShare} from '../../practices/practice';
import {mergeMap} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {GlobalErrorHandlerService} from "../../../../services/global-error.service";
import {AmenityAssociate} from "../../amenity/amenity";
import {AmenityService} from "../../amenity/amenity.service";
import {PhoneService} from '../../../../voice-call/phone.service';

@Component({
  selector: 'app-doctor-profile-detail',
  templateUrl: './doctor-profile-detail.component.html',
  styleUrls: ['./doctor-profile-detail.component.scss']
})
export class DoctorProfileDetailComponent implements OnInit {
  @Input() doctor: Doctor;
  @Input() index: number;
  @Input() doctor_permissions = 'edit,doctors,manage';
  public total_services: any;
  public doctor_profile = false;
  @Input() practice: any;
  @Output() expanded: EventEmitter<Doctor> = new EventEmitter<Doctor>();
  public show_calendar_form: boolean;
  public show_block_calendar_form: boolean;
  public new_calendar: Calendar = new Calendar();
  public new_block_calendar: BlockCalendar = new BlockCalendar();
  public show_calendar_listing = true;
  public show_block_calendar_listing = true;
  public is_component_base = true;
  public show_error = false;
  @ViewChild('classicModal') classicModal: ModalDirective;

  public data_source: Observable<any>;
  public token = '';
  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public associate_amenity_with_doctor_button: boolean = false;
  public associate_doctor_amenity: AmenityAssociate;

  constructor(public amenityService: AmenityService, public globalErrorHandlerService: GlobalErrorHandlerService,
              private doctorService: DoctorService, private injector: Injector, public user_service: UserService,
              public practiceService: PracticesService, public phoneService: PhoneService) {
  }

  ngOnInit() {

    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).pipe(mergeMap((token: string) => this.amenityService.serachAmenities(token)));

  }

  doctor_associated_practices() {
    return this.doctor.practices;
  }

  doctor_visit_charges(doctor_id, practice_id,type: string) {
    if (this.doctor.practices.filter(e => e.id == practice_id).length > 0) {
      let visit_charges_object = this.doctor.practices.filter(e => e.id == practice_id)[0].doctor_share
        .filter(e => e.doctor == doctor_id && e.practice == practice_id)[0]
      if (visit_charges_object) {
        return type === 'visit' ?  visit_charges_object.visit_charges : visit_charges_object.virtual_visit_charges;
      }
    } else {
      return '0'
    }
  }

  doctor_fees_share(doctor_id, practice_id) {
    if (this.doctor.practices.filter(e => e.id == practice_id).length > 0) {
      let fees_share_object = this.doctor.practices.filter(e => e.id == practice_id)[0].doctor_share.filter(e => e.doctor == doctor_id && e.practice == practice_id)[0]
      if (fees_share_object) {
       return fees_share_object.fees_share
      }
    } else {
      return '0'
    }
  }

  updateChargesShare(doctor_id, practice_id) {
    let visit_charges = ((document.getElementById('charges' + practice_id) as HTMLInputElement).value);
    let share = ((document.getElementById('share' + practice_id) as HTMLInputElement).value);
    let virtual_visit_charges = ((document.getElementById('virtual' + practice_id) as HTMLInputElement).value);

    this.doctorService.updateDoctorCharges(doctor_id, practice_id, visit_charges,virtual_visit_charges, share).then(json => {
      if (json.message == 'success') {

        let fees_share_object = this.doctor.practices.filter(e => e.id == practice_id)[0].doctor_share.filter(e => e.doctor == doctor_id && e.practice == practice_id)[0]
        if (fees_share_object) {
          fees_share_object.fees_share = share;
          fees_share_object.visit_charges = visit_charges;
          fees_share_object.virtual_visit_charges = virtual_visit_charges;
        }

        this.setTimeOutFailsMessage();
      }
    });
  }

  setTimeOutFailsMessage(): any {
    this.show_error = true;
    setTimeout(() => {
      this.show_error = false;
    }, 3000)
  }

  get doctor2() {
    if (this.doctor) {
      return this.doctor;
    }
    if (this.user_service.current_user) {
      let doctor_id;
      if (this.doctor == undefined) {
        this.doctor = new Doctor();
        this.is_component_base = false;
        this.doctor.show_profile_detail = true;
        this.index = 0;
      } else {
        this.doctor.show_profile_detail = false;
      }

      if (this.doctor.show_profile_detail) {
        let id = this.injector.get('id', null);
        if (id > 0) {
          doctor_id = id;
        }
      } else {
        doctor_id = this.doctor.id;
      }
      if (this.user_service.current_user && this.user_service.current_user.profileable_type == 'Doctor') {
        this.doctor_profile = true;
        doctor_id = this.user_service.current_user.profileable_id;
      }

      this.doctorService.getDoctor(doctor_id).then(json => {
        this.doctor.load_from_json(json);
      });
    }

    return null;

  }

  get show_profile_detail() {
    return this.doctor.show_profile_detail;
  }

  get_only_pictures() {
    return this.doctor.attachments.filter(e => e.picture_type != 'profile_pic')
  }

  toggle_view(doctor: Doctor) {
    this.doctor.show_profile_detail = !this.show_profile_detail;
    if (this.doctor.show_profile_detail) {
      this.expanded.emit(this.doctor);
      if (!this.doctor.data_fetched) {
        this.doctorService.getDoctor(doctor.id).then(json => {
          this.doctor.load_from_json(json);
          this.doctor.data_fetched = true;
        });
      }
    }
  }

  removePicture(attachment, index) {
    this.practiceService.removePhoto(attachment).then(res => {
      this.doctor.attachments.splice(index, 1);
      return;
    });
  }

  makeActiveInactive(doc_image) {
    doc_image.active_class = !doc_image.active_class;
    return;
  }

  handle_image_upload(data) {
    if (data && data.hasOwnProperty('error') == false) {
      if (data.attachment_type == 'Doctor' && data.document_type == 'photo') {
        let attach = new Attachment();
        attach = attach.load_from_json(data);

        if (this.doctor.attachments) {
          this.doctor.attachments.push(attach);
        }
      } else {
        console.log('No condition match')
      }
    }
  }

  editDoctor() {
    this.doctor.edit_doctor = true;
  }

  handle_form_hidden() {
    this.doctor.edit_doctor = false;
  }

  get_updated_doctor(doc: Doctor) {
    this.doctor.edit_doctor = false;
    return this.doctor;
  }

  practice_calendar_locations() {
    if (this.practice) {
    return this.practice.locations;
    }
    else {
      return this.doctor.practices ?  [].concat.apply([], this.doctor.practices.map(practice => practice.locations)) : []
    }
  }

  doctor_calendars() {
    return this.doctor.calendars_array;
  }

  doctor_block_calendars() {
    return this.doctor.block_calendars_array;
  }

  calendar_form() {
    this.show_calendar_form = true;
    this.show_calendar_listing = false;
  }

  block_calendar_form() {
    this.show_block_calendar_form = true;
    this.show_block_calendar_listing = false;
  }

  calendar_saved(calendar) {
    this.doctor.calendars_array.push(this.new_calendar);
    this.new_calendar = new Calendar();
    this.show_calendar_listing = true;
    this.show_calendar_form = false;
  }

  block_calendar_saved(block_calendar) {
    this.doctor.block_calendars_array.push(this.new_block_calendar);
    this.new_block_calendar = new BlockCalendar();
    this.show_block_calendar_listing = true;
    this.show_block_calendar_form = false;
  }

  handle_hide_form(calendar) {
    this.show_calendar_form = false;
    this.show_calendar_listing = true;
  }

  handle_block_calendar_hide_form() {
    this.show_block_calendar_form = false;
    // this.show_calendar_listing = true;
    this.show_block_calendar_listing = true;
  }

  delete_doctor_association_with_practice(practice) {
    this.doctorService.disassociate_doctor_from_practice(this.doctor.id, practice.id).then(res => {
      this.doctorService.getDoctor(this.doctor.id).then(json => {
        this.doctor.load_from_json(json);
        this.doctor.data_fetched = true;
        this.classicModal.hide();
      });
    })
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
    this.associate_doctor_amenity = new AmenityAssociate().load_from_json(e.item)
  }

  associate_amenity_with_doctor() {
    this.associate_amenity_with_doctor_button = true;
    this.associate_doctor_amenity.doctor_id = this.doctor.id
    this.amenityService.createAssociateAmenity(this.associate_doctor_amenity).then(json => {
      this.doctor.amenity_associators.push(new AmenityAssociate().load_from_json(json));
      this.renew_associate_amenity();
    }).catch(resp => {
        this.globalErrorHandlerService.error = resp.error.error;
    });
  }
  renew_associate_amenity() {
    this.token = '';
    this.associate_doctor_amenity = null;
    this.associate_amenity_with_doctor_button = false;
  }

  doctorShare(doctor_id, practice_id): DoctorShare{
   return  this.doctor.practices.filter(e => e.id === practice_id)[0].doctor_share.filter(e => e.doctor === doctor_id && e.practice === practice_id)[0];
  }
}
