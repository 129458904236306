import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {PchfService} from '../../../../services/pchf.service';
import {AdditionalInfo} from '../../../../routes/practice-site/additional_info';
import {Patient} from '../../../doctor/patient';
import {DocumentsService} from '../../../../services/document.service';

@Component({
  selector: 'app-addiotional-info',
  templateUrl: './addiotional-info.component.html',
  styleUrls: ['./addiotional-info.component.scss']
})

export class AddiotionalInfoComponent implements OnInit {

  @Input() patient: Patient;
  @ViewChild('videoPlayer') videoplayer: any;
  messageAfterSave = false;
  messageAfterSuccessSave = false;
  show_attachment_popup = false;
  document_url = '';
  active_tab = 'timeline';
  public additional_info: AdditionalInfo = new AdditionalInfo();
  public documents: any[] = [];
  public video_documents: any[] = [];
  constructor(public pchf_service: PchfService, public _documentService: DocumentsService) { }

  ngOnInit() {
    if (!this.additional_info.id) {
      this.additional_info_loading();
    }
    this.additional_info.patient_id = this.patient.id
  }

  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  stop_player() {
      this.videoplayer.nativeElement.pause();
  }

  additional_info_loading() {
    this.pchf_service.getAdditionalInfoPatient(this.patient.id).then(res => {
      this.additional_info = new AdditionalInfo().load_from_json(res.response);
      this.additional_info.patient_id = this.patient.id;
      if (res.attachments && res.attachments.length > 0) {
        this.video_documents = res.attachments.filter(e => e.is_video == true)
        this.documents = res.attachments.filter(e => e.is_video == false);
        this.documents.forEach(document => {
          document['loaded'] = false;
          document['url'] = document.document_url;
          document['parent_name'] = 'test';
        });
        this._documentService.pages = this.documents;
        this._documentService.selected_page = 1;
      }
    }).catch(resp =>  resp);
  }

  saveAdditionalInfo() {
    this.pchf_service.save(this.additional_info).then(res => {
     if (res.success == true) {
       this.setTimeOutSuccessMessage();
     } else {
         this.setTimeOutFailsMessage();
     }
     this.additional_info = new AdditionalInfo().load_from_json(res.response);
    }).catch(resp =>  resp);
  }

  handle_file_upload(data) {
    if (data && data.hasOwnProperty('error') == false) {
      if (data.is_video) {
          this.video_documents.push(data)
        } else {
          data['loaded'] = false;
          data['url'] = data.url;
          data['parent_name'] = 'test';
          this.documents.push(data);
          this._documentService.pages = this.documents;
          this._documentService.selected_page = 1;
        }
    }
    this.show_attachment_popup = false;
    return
  }

  setTimeOutSuccessMessage(): any {
    this.messageAfterSuccessSave = true;
    setTimeout(() => {
        this.messageAfterSuccessSave = false;
    }, 3000)
  }

  delete_document(document) {
    this.pchf_service.delete_document(document, this.patient).then(res => {
      this.additional_info.patient_id = this.patient.id;
      if (res.attachments && res.attachments.length > 0) {
        this.video_documents = res.attachments.filter(e => e.is_video == true);
        this.documents = res.attachments.filter(e => e.is_video == false);
      }
    }).catch(resp =>  resp);
  }

  setTimeOutFailsMessage(): any {
    this.messageAfterSave = true;
    setTimeout(() => {
      this.messageAfterSave = false;
    }, 3000)
  }

  show_video_popup(video) {
    this.document_url = '';
    this.document_url = video.document_url;
  }

  timeline_active() {
      return this.active_tab == 'timeline'
  }

  preview_closed() {
    this._documentService.toggleView();
    this.active_tab = 'timeline'
  }

  goto_document(document) {
    this._documentService.is_pchf_viewer(true);
    this._documentService.toggleView();
    this.active_tab = 'preview';
    let page_number = this._documentService.pages.findIndex(page => page.document_id === document.id);
    if (page_number >= 0) {
      this._documentService.goto_document(page_number);
    }
  }

}
