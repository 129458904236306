import { Injectable }     from '@angular/core';
import {HttpParams } from '@angular/common/http';
import { Subject} from 'rxjs';
import {CustomHttpClient} from './custom-http-client.service';
import {Announcement} from '../routes/announcements/announcement';



@Injectable()
export class AnnouncementsService {
  updateAnnouncemets: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient) {}

    announcementsForMe(): Promise<any> {
    return this._tokenService.get('announcements/announcements_for_me')
      .toPromise()
      .then(response => response);
  }
  getAnnouncements(filter: any): Promise<any> {
    let params = new HttpParams({fromObject: filter});
    params =  params.set('filter', filter);
    return this._tokenService.getWithHeaders('announcements', {observe: 'response', params: params, responseType: 'json'})
      .toPromise()
      .then(response => {
        return {
          announcements: response.body,
          total: response.headers.get('X-Total'),
          per_page: response.headers.get('X-Per-Page'),
          current_page: response.headers.get('X-Page'),
          total_pages: response.headers.get('X-Total-Pages'),
          previous_page: response.headers.get('X-Prev-Page')
        }
      });
  }

/*

  getAppointment(id: any): Promise<any> {
    return this._tokenService.get('appointments/'+id)
      .toPromise()
      .then(response => response);
  }
*/


  create(announcement: Announcement): Promise<any> {
    return this._tokenService
      .post(announcement.get_url(), announcement.to_params())
      .toPromise()
      .then(res => {
        this.updateAnnouncemets.next(new Announcement().load_from_json(res));
        return res
      })
  }

  update(announcement: Announcement): Promise<any> {
    return this._tokenService
      .put(announcement.get_url(), announcement.to_params())
      .toPromise()
      .then(res => res)
  }

  save(announcement: Announcement): Promise<any> {
    if (announcement.is_new_record()) {

      return this.create(announcement);
    }
    else {
      return this.update(announcement);
    }
  }

/*  delete(calendar_id:any): Promise<any>{
    return this._tokenService.delete('appointments?calendar_id='+calendar_id)
      .toPromise()
      .then(res => res);
  }*/


}
