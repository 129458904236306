import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {CalendarEvent, CalendarView} from 'angular-calendar';
import {Observable} from 'rxjs/Rx';
import {Patient} from '../../doctor/patient';
import {PatientEventService} from './patient-event.service';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {PatientEvent} from './patient-event';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-health-calendar',
  templateUrl: './health-calendar.component.html',
  styleUrls: ['./health-calendar.component.scss']
})
export class HealthCalendarComponent implements OnInit, OnDestroy, OnChanges {
  @Input() patient: Patient;
  view: CalendarView = CalendarView.Day;
  viewDate: Date = new Date();
  weekStartsOn = 1;
  events: Observable<CalendarEvent<{ film: any }>[]>;
  public subscription: Subscription;
  public scrollToLatestEvent = true;
  public printReport: boolean;

  constructor(public patientEventService: PatientEventService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService) {
    this.subscription = this.patientEventService.reloadPatientEventList.subscribe(item => {
      if (item) {
        this.scrollToLatestEvent = item ? false : true;
        this.fetchEvents();
      }
    });
  }

  ngOnInit(): void {}

  fetchEvents() {
    this.loaderService.show();
    let search_params = { date: this.viewDate, type: this.view, patient_id: this.patient.id};
    this.patientEventService.getPatientEvents(search_params).then(resp => {
        this.events = resp.patient_events.map(e => {
          return this.event_mapper(e);
        });
        if (this.scrollToLatestEvent) {
          this.patientEventService.scrollToLatestEvent.next(resp.patient_events[0]);
        }
        this.loaderService.hide();
        console.log(this.events);
        this.scrollToLatestEvent = true;
      }
    ).catch(error => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = error.error.error;
    })
  }

  event_mapper(a) {
    return {
      title: a.subject,
      start: new Date(a.start_date),
      end: new Date(a.end_date),
      color:  {primary: a.event_color, secondary: a.event_color},
      meta: {event:  new PatientEvent().load_from_json(a)}
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fetchEvents();
  }

  report(event: boolean) {
    this.printReport = event
  }
}
