import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Term} from '../../../../../enote/enote_models/term';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {AlertService} from '../../../../../_alert';
import {GlobalErrorHandlerService} from '../../../../../services/global-error.service';
import {MedicationSummaryService} from './medication-summary.service';
import {LoaderService} from '../../../../../services/loader.service';
import {Control} from '../../../../../enote/enote_models/control';
import {Observable} from 'rxjs';
import {TermControl, TermControlValue} from '../../../../../enote/enote_models/term_control';
import {mergeMap} from 'rxjs/operators';
import {ProgramService} from '../../../../../program/program.service';
import {UserService} from '../../../../../services/user.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {EnrolmentsService} from '../../../../../program/enrolments.service';

@Component({
  selector: 'app-medication-summary',
  templateUrl: './medication-summary.component.html',
  styleUrls: ['./medication-summary.component.scss']
})
export class MedicationSummaryComponent implements OnInit {
  @ViewChild('updateTermStatus') updateTermStatus: ModalDirective;
  @ViewChild('addProblem') addProblem: ModalDirective;
  public showUpdateTermModel: boolean;
  public selectedTerm: Term;
  public changeStatusWarning: boolean;
  public updateMedication: boolean;
  public smallStatusLoader: boolean;
  @Input() patient_id: any;
  public custom_controls: Control[] = [];
  public default_controls: Control[] = [];
  public showAddProblemModel: boolean;
  public typeaheadLoading: boolean;
  public typeaheadNoResults: boolean;
  public diagnosis_token = '';
  public term_token = '';
  public data_source_diagnosis: Observable<any>;
  public data_term_source: Observable<any>;
  public baseTerm: Term = new Term('', '')
  public term_for: string;
  public editPatientSummary: boolean;
  @Output() getPatientSummaryEmitter: EventEmitter<any> = new EventEmitter();
  public formDisabled = false;
  public textMapper: Object = {'Free Text': 'Enter additional comments', 'Onset': 'Enter full date or month-year', 'medications': 'Medication', 'problems': 'Problem', 'allergies': 'Allergy'};


  constructor(public alertService: AlertService, public loaderService: LoaderService,
              public userService: UserService, public enrolmentsService: EnrolmentsService,
              public medicationSummaryService: MedicationSummaryService, public programService: ProgramService,
              public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    this.data_source_diagnosis = Observable.create((observer: any) => {
      observer.next(this.diagnosis_token);
    }).pipe(mergeMap((token: string) => this.programService.searchDiagnosis(token)));

    this.data_term_source = Observable.create((observer: any) => {
      observer.next(this.term_token);
    }).pipe(mergeMap((token: string) => this.medicationSummaryService.searchTermsAllergiesMedication(token, this.term_for)));
  }

  updateTermsStatus(term: Term) {
    this.smallStatusLoader = true;
    this.medicationSummaryService.changeTermStatus({id: term.ps_id, status: term.status}).then( resp => {
      this.loaderService.show();
      this.updateProgramBanner(resp);
      this.getPatientSummaryCallback();
      this.smallStatusLoader = false;
      this.closeUpdateTermModel();
      this.alertService.success('Status has been saved successfully', this.alertService.options);
    }).catch( resp => {
          this.smallStatusLoader = false;
          this.changeStatusWarning = false;
          this.globalErrorHandlerService.error = resp.error.error;
        }
    )
  }

  closeUpdateTermModel() {
    this.updateTermStatus.hide();
    this.showUpdateTermModel = false;
    this.changeStatusWarning = false;
    this.updateMedication = false;
  }

  getControls(type: string) {
    this.loaderService.show();
    this.term_for = type;
    this.diagnosis_token = '';
    this.typeaheadNoResults = false;
    this.typeaheadLoading = false;
    this.medicationSummaryService.getCustomAndDefaultControls(type).then(resp => {
      this.baseTerm = new Term('', '');
      if (resp.custom_controls.length > 1) {
        this.baseTerm.term_controls = resp.custom_controls.map(control => {
          let term_control = new TermControl();
          term_control.control_id = control.id;
          term_control.term_control_values = control.values.map(val => {
            let vale = new TermControlValue().load_from_json_without_id(val);
            return vale
          })
          term_control.control = new Control('', '').load_from_json(control);
          return term_control;
        });
      }
      if (resp.default_controls.length > 1) {
        this.default_controls = resp.default_controls.map(control => new Control('', '').load_from_json(control));
      }
      this.openAddProblemModel();
      this.loaderService.hide();

    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  changeTypeaheadLoading(e: boolean) {
    this.typeaheadLoading = e;
  }
  changeTypeaheadNoResults(e: boolean) {
    if (this.term_for !== 'problems') {
      this.noResultsForMedicationAndAllergies();
    } else {
      this.typeaheadNoResults = e;
    }
  }

  noResultsForMedicationAndAllergies() {
    this.baseTerm.name = this.term_token;
    this.setTermAndComponentType();
  }


  setValue(control_value_id: any, id: any) {
    let selected_term: TermControl = this.selectTerm(id);
    if (selected_term.term_control_values && control_value_id) {
      selected_term.term_control_values.filter(a => a.control_value_id.toString() === control_value_id.toString())[0].recent_value = true
    }
  }

  addTerm() {
    let for_library = this.term_for === 'allergies' ? 'general' : this.term_for
    this.loaderService.show();
    this.medicationSummaryService.addPatientProblem(this.baseTerm, for_library, this.patient_id).then( resp => {
      if (resp.summary_id) {
        this.saveSummaryCallback();
      } else {
        this.createPatientSummaryObject(resp)
      }
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  addPatientSummary(summary: any) {
    this.medicationSummaryService.addPatientSummary(summary).then(res => {
      this.updateProgramBanner(res);
      this.saveSummaryCallback();
    }).catch(rep => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = rep.error.error;
    })
  }

  saveSummaryCallback() {
    this.closeAddProblemModel();
    this.getPatientSummaryCallback();
    this.showAlertMessage();
  }

  showAlertMessage() {
    this.alertService.success(this.userService.capitalize(this.term_for) + ' has been saved successfully',
        this.alertService.options);
  }

  updateTerm() {
    this.loaderService.show();
    this.medicationSummaryService.update(this.baseTerm).then ( resp => {
      console.log(resp)
      this.updateProgramBanner(resp);
      this.saveSummaryCallback();
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  selectTerm(id: any) {
    let term: TermControl = this.baseTerm.term_controls.filter(a => a.control_id === id)[0];
    if (term.control_id) {
      term.term_control_values.map(a => a.recent_value = false);
    }
    return term;
  }

  editControl(selectedTerm: Term) {
    this.closeUpdateTermModel();
    this.baseTerm = new Term('', '').load_from_json2(selectedTerm);
    this.term_for = this.baseTerm.data_type;
    this.editPatientSummary = true
    setTimeout(() => {
      this.openAddProblemModel();
    }, 600);
  }

  openAddProblemModel() {
    this.showAddProblemModel = true;
    this.addProblem.show();
  }

  typeaheadTermOnSelect(prob: TypeaheadMatch) {
    this.baseTerm.d_name = prob.item.sname;
    this.setTermFields(prob.item);
  }

  typeaheadDiagnosisOnSelect(prob) {
    this.baseTerm.d_name = prob.item.d_name;
    this.baseTerm.is_chronic = prob.item.is_chronic ? true : false;
    this.setTermFields(prob.item);
  }

  setTermFields(term) {
    this.baseTerm.library_datum_id = term.library_datum_id;
    this.baseTerm.name = term.name;
    this.setTermAndComponentType();
  }

  setTermAndComponentType() {
    this.baseTerm.term_type = 'term';
    this.baseTerm.component_type = 'term';
    this.formDisabled = true;
  }

  closeAddProblemModel() {
    this.addProblem.hide();
    this.editPatientSummary = false;
    this.showAddProblemModel = false;
    this.diagnosis_token = '';
    this.term_token = '';
    this.formDisabled = false;
  }

  openUpdateTermStatusModel(term: any, type?: string) {
    this.selectedTerm = term;
    this.updateTermStatus.show();
    this.showUpdateTermModel = true;
    if (type === 'medications') {
      this.updateMedication = true;
    }
  }

  checkString(event: KeyboardEvent, token: string) {
    if (event.key === 'Backspace') {
      this.clearBaseTermFields();
    }
  }

  showLabel(name: string): string {
    return name === 'Free Text' ? 'Additional information' : name;
  }

  getPatientSummaryCallback () {
    this.getPatientSummaryEmitter.emit(true);
  }

  clearBaseTermFields() {
    this.formDisabled = false;
    this.baseTerm.d_name = undefined;
    this.baseTerm.library_datum_id = undefined;
    this.baseTerm.name = undefined;
    this.baseTerm.term_type = undefined;
    this.baseTerm.component_type = undefined;
  }

  private updateProgramBanner(res: any) {
    if (res.data_type === 'problems' || res.data_type === 'allergies') {
      if (res['chronic_diagnosis_name_code'] && res.data_type === 'problems') {
        this.enrolmentsService.reloadSummary.next(
            {chronic_diagnosis_name_code: res['chronic_diagnosis_name_code'], patient_id: this.patient_id}
        )
      }
      if (res['allergies_name'] && res.data_type === 'allergies') {
        this.enrolmentsService.reloadSummary.next( {allergies: res['allergies_name'], patient_id: this.patient_id})
      }
    }
  }

  private createPatientSummaryObject(resp: any) {
    let summary = {data_type: this.term_for, base_term_id: resp.id,  patient_id: this.patient_id,
      status: 'current', library_datum_id: resp.library_datum_id,
      practice_id: this.userService.current_user.profileable['practice_id']}
    this.addPatientSummary(summary);
  }

  disabledAddForm(): boolean {
    return this.baseTerm.name.trim().length === 0
  }
}
