<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h3 class="text-primary mt0 mb-xl fs22">Our Goal | Connect all dots of ambulatory healthcare</h3>
      <h1>Why WellWink?</h1>
      <h4 class="sub-title">
        Facilitate patients to optimize on pre and post visit care, and enables them to connect
        with their caregivers, anywhere anytime.
      </h4>
      <h4 class="sub-title">
        Facilitate doctors and medical practices to treat and outreach to patients instantly, establish long-term
        relationship with existing patient while growing patient base with practice marketing and reputation management
        tools all at same place.
      </h4>
      <h4 class="sub-title">
        Facilitate group practices, healthcare organizations and regional centers to get comprehensive insight via
        unified medical record.
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <div class="white-container-box p-xl">
      <h1 class="mt0 mb-xl text-center">The Concept – Continuity of Care</h1>
      <p class="text-center">
        Once connected, WellWink becomes a bridge between Practice Management Systems and Electronic Health Record
        systems giving a unique advantage to many healthcare stakeholders in different healthcare setups.
      </p>
      <img class="img-responsive cloud-img" src="/assets/img/public/WellWink-Cloud.jpg"/>
      <p class="text-center">
        Say for example, a small town in New York has 15 medical centers and are using five different PMS and EHR systems.
        WellWink will connect with all these systems assuming that all providers and medical centers in this community
        are using WellWink as Patient Relationship Management System.
        <br><br>
        Patient will automatically get a consolidated picture of medical records, if two different providers from
        different medical setups see a same patient.
      </p>
    </div>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <div class="white-container-box p-xl">
      <h1 class="mt0 mb-xl text-center">Master Patient Index</h1>
      <div class="row">
        <div class="col-sm-5 col-xs-12 pull-right">
          <p>
            WellWink maintains Master Patient Index. Patient name, DOB, Gender, Mobile Number, eMail, Address, City, State,
            Zip can be used as single parameter or combination of these parameters to identify and maintain patient record
            uniquely nationwide.
            <br><br>
            Patients have full control of their consolidated medical record by maintaining and using single sign-on.
          </p>
        </div>
        <div class="col-sm-7 col-xs-12">
          <img class="img-responsive cloud-img" style="max-width:450px;" src="/assets/img/public/Patient-index.jpg"/>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad pb0">
  <div class="container">
    <div class="white-container-box p-xl">
      <h1 class="mt0 mb-xl text-center">Population Health</h1>
      <div class="row">
        <div class="col-sm-5 col-xs-12">
          <p>
            Population Health Management reduce the frequency of health crises and costly ED visits and hospitalizations.
            Lower the cost per service through an integrated delivery of care team approach, which includes clinicians,
            social workers, physical therapists and behavioral health care professionals.
            <br><br>
            WellWink can enable qualified medical communities or companies engaged in research and healthcare cost saving
            programs to manage their patient population seamlessly. WellWink can work with these organizations to build
            various care programs already standardized or custom programs unique to their needs.
          </p>
        </div>
        <div class="col-sm-7 col-xs-12">
          <img class="img-responsive" src="/assets/img/public/health-mangement.jpg"/>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-pad">
  <div class="container">
    <div class="white-container-box p-xl">
      <h1 class="mt0 mb-xl text-center">Facebook of Healthcare</h1>
      <div class="row">
        <div class="col-sm-7 col-xs-12 pull-right">
          <p style="padding:2em 0;">
            Supported by flexible architecture, WellWink has the vision to expand these product and services for patients
            to share their experiences to like-minded colleagues, friends and family members.
          </p>
        </div>
        <div class="col-sm-5 col-xs-12">
          <img class="img-responsive" src="/assets/img/public/facebook-health.jpg"/>
        </div>
      </div>
    </div>
  </div>
</section>
