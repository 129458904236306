import { Injectable } from '@angular/core';
import { Comment} from './comment';
import {HttpParams} from '@angular/common/http';
import {CustomHttpClient} from '../../../services/custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  constructor(private _tokenService: CustomHttpClient, ) { }

  getComments(commentable_id: any, commentable_type: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('commentable_id', commentable_id);
    params = params.set('commentable_type', commentable_type);
    return this._tokenService.get('comments', {params: params})
      .toPromise()
      .then(response => response);
  }

  create(comment: Comment): Promise<any> {
    return this._tokenService
      .post(comment.get_url(), comment.to_params())
      .toPromise()
      .then(response => response)
  }

  save(comment: Comment): Promise<any> {
    if (comment.is_new_record()) {

      return this.create(comment);
    }
    else {
      return this.update(comment);
    }
  }
  delete(comment_id: any): Promise<any> {
    return this._tokenService.delete('comments/' + comment_id)
      .toPromise()
      .then(res => res);
  }

  update(comment: Comment) {
    return this._tokenService
      .put(comment.get_url(), comment.to_params())
      .toPromise()
      .then(response => response)
  }
}
