<span contenteditable="false" >
  <ng-container *ngIf="searchable == 'keyword'"># </ng-container>
  <ng-container *ngIf="searchable != 'keyword'">; </ng-container>
<input #typeahead [(ngModel)]="token"
       (keydown.backspace)="onBackSpaceDownDelete($event, term)"
       typeaheadWaitMs="800"
       [id]="term.id ? 'typeahead-'+term.id : term.index_counter "
       [typeahead]="data_source"
       [typeaheadAsync]="true"
       (typeaheadLoading)="changeTypeaheadLoading($event)"
       autocomplete="off"
       (typeaheadNoResults)="changeTypeaheadNoResults($event)"
       (typeaheadOnSelect)="typeaheadOnSelect($event)"
       [typeaheadOptionsLimit]="20"
       [typeaheadMinLength]="3"
       [typeaheadOptionField]="'sname'"
       (keydown)="checkString($event)"
       style="border:0;"
        class="pl">
  </span>
