export class PublicReviewLink {
  id: number;
  linkable_type: string;
  linkable_id: number;
  public_site_id: number;
  public_site_url: string;
  public_site_name: string;
  default_auto_review = false;

  to_params() {
    return {
      id: this.id,
      linkable_id: this.linkable_id,
      linkable_type: this.linkable_type,
      public_site_id: this.public_site_id,
      public_site_url: this.public_site_url,
      public_site_name: this.public_site_name,
      default_auto_review: this.default_auto_review
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.linkable_type = json.linkable_type;
    this.linkable_id = json.linkable_id;
    this.public_site_name = json.public_site_name;
    this.public_site_url = json.public_site_url;
    this.public_site_id = json.public_site_id;
    this.default_auto_review = json.default_auto_review;
    return this;
  }
}
