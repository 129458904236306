import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {ProgramEnrollment} from '../../../../eligible-patient';
import {LoaderService} from '../../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../../services/global-error.service';
import {EnrolmentsService} from '../../../../enrolments.service';
import {Goal} from '../../../../goal';
import {Comment} from '../../../../../routes/practice-site/tasks/comment';
import {CommentService} from '../../../../../routes/practice-site/tasks/comment.service';;
import {Tab} from '../../../../../tabs/tab';
import {TabsService} from '../../../../../services/tabs.service';
import {GoalDetailsComponent} from '../../../../goal-details/goal-details.component';
import {GoalService} from '../../../../goal.service';
import {Patient} from '../../../../../health/doctor/patient';
import {TrackingService} from '../../../../../services/tracking.service';
import {AlertService} from '../../../../../_alert';
import {CarePlan} from '../enrolled-patient-plan.component';
import {UserService} from '../../../../../services/user.service';

@Component({
  selector: 'app-enrolled-patient-goals',
  templateUrl: './enrolled-patient-goals.component.html',
  styleUrls: ['./enrolled-patient-goals.component.scss']
})
export class EnrolledPatientGoalsComponent implements OnInit {
  @Input() programEnrollment: ProgramEnrollment;
  @Input() patient: Patient;
  @Input() careplan: CarePlan;
  @Input() enrolment_goals: Goal[] = [];
  @Input() programme_type;
  value: any;
  comment_body: any;
  selected_goal: Goal = new Goal();
  goal: Goal = new Goal();
  trackingResult;
  public actionId: number;
  @ViewChild('newGoalModal') newGoalModal;
  public statuses: Array<string> = ['pending', 'in_progress', 'completed'];
  show_goal_model: any;
  custom_injector: Injector;
  goalDetailsComponent = GoalDetailsComponent;
  @ViewChild('viewGoalModel') viewGoalModel;
  @ViewChild('commentsModal') commentsModal;
  public options = { autoClose: true, keepAfterRouteChange: true};

  constructor(public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public enrolmentsService: EnrolmentsService, public commentService: CommentService,
              public tabs_service: TabsService, public goalService: GoalService, public injector: Injector,
              public trackingService: TrackingService, public alertService: AlertService,
              public userService: UserService) { }

  ngOnInit(): void {
  }

  getEnrolmentGoals() {
    this.loaderService.show();
    this.goalService.getEnrolmentGoals(this.programEnrollment.id).then(resp => {
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  setGoalProgress(value: any, goal: Goal) {
    this.loaderService.show();
    this.goalService.updateProgressOfGoal(goal).then( resp => {
      console.log(resp);
      goal.load_from_json(resp);
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  addCommentOnGoal() {
    this.loaderService.show();
    let comment: Comment = new Comment();
    comment.commentable_type = 'Goal';
    comment.commentable_id = this.selected_goal.id;
    comment.body = this.comment_body;
    this.commentService.save(comment).then(resp => {
      this.selected_goal.comment_count++;
      this.stopAction(resp.body, resp.id, 'Comment');
      this.enrolmentsService.reloadActivityList.next(1);
      this.comment_body = '';
      this.loaderService.hide();
      this.commentsModal.hide();
      this.alertService.success('Comment has successfully been added', this.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  viewGoal(goal: Goal) {
    this.tabs_service.add_tab(new Tab({title: goal.name, icon : 'fa'}, GoalDetailsComponent,
      goal.id, {id: goal.id, goal: goal}));
  }

  newGoal() {
    this.loaderService.show();
    this.goal.achiever_id = this.patient.id;
    this.goal.achiever_type = 'Patient';
    this.goal.source_type = 'ProgrammeEnrolment';
    this.goal.care_plan_name = this.careplan.name;
    this.goal.care_plan_id = this.careplan.id;
    this.goal.source_id = this.programEnrollment.id;
    this.goalService.create(this.goal)
      .then(resp => {
        this.enrolment_goals = this.enrolment_goals || [];
        const goalData = resp.goal || resp;
        this.enrolment_goals.push(new Goal().load_from_json(goalData));
        this.goal = new Goal();
        this.newGoalModal.hide();
        this.loaderService.hide();
        this.alertService.success('Goal has been saved successfully', this.options)
      })
      .catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  checkVaildGoalForm() {
    return this.goal.status && this.goal.description && this.goal.name
  }

  startAction(description) {
    this.actionId = this.trackingService.startTracking(description);
  }

  stopAction(description, id, source_type) {
    const trackingResult = this.trackingService.stopTracking(this.actionId);
    this.trackingService.logTimeForPatientActivity({duration: trackingResult.duration,
      description: description, activity_type: trackingResult.description,
      source_id: id, activiable_id: this.programEnrollment.id, source_type: source_type, programme_type: this.programme_type })
  }

  setCustomInjector(goal: Goal) {
    this.custom_injector  = Injector.create([
      {provide: 'goal', useValue: goal},
      {provide: 'id', useValue:  goal.id},
      {provide: 'programme_type', useValue:  this.programme_type},
    ], this.injector);
    this.show_goal_model = true;
    this.viewGoalModel.show();
  }
}
