<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Telemedicine</h1>
      <h4 class="sub-title fs22 text-white">Expand our virtual presence with end-to-end telehealth solution.</h4>
      <h4 class="sub-title text-white">
        Convenience is a major benefit of virtual care for physicians, nurses and clinicians.
        <br><br>
        Beyond primary care, virtual care also increases access to specialists who may be even further out of range — not
        just for patients in rural areas, but even for those who live in small cities or suburbs. Patients may also
        experience lower costs with virtual care.
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad verified-sec">
  <div class="container">
    <div class="text-center">
      <h1>Book Virtual Visit</h1>
      <br>
      <p style="max-width:900px; margin:0 auto 2em;">
        Easily book an online appointment for a live video consultation, from anywhere securely and privately.
        Join your virtual appointment with a doctor from the comfort of your own home or work. Self-check-in helps patients
        to let doctors know they are ready to take video call and provides doctors ability to see patient has checked in.
        <span class="center-block fw-500">A waiting counter in the waiting room helps sort patient by waiting time in minutes.</span>
      </p>
    </div>
    <div class="white-container-box">
      <img class="img-responsive" src="/assets/img/public/home-public.jpg"/>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div class="sec-pad">
          <div class="text-center">
            <h1>On Call Doctors</h1>
            <br>
            <p class="min-height">
              Doctors can choose to mark them available for instant video consultation. WellWink maintains list of these
              on call doctors and provides patients ability to connect with on-call doctor through an instant appointment.
              Patient will be queued in waiting list sorted by appointment time. Patient may be required to wait for few
              minutes depending upon virtual appointment time slot.
            </p>
          </div>
          <div class="white-container-box">
            <img class="img-responsive" src="/assets/img/public/oncalldoc.jpg"/>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="sec-pad">
          <div class="text-center">
            <h1>Clinical Notes</h1>
            <br>
            <p class="min-height">
              Clinical notes are a key component to document virtual encounter. To make sure patient does not miss important
              information Wellwink has complete template driven eNote capability. Once signed by provider, it automatically
              become party of patient chart and gets available on patient portal. These shared clinical notes help keep
              patients and healthcare team on the same page.
            </p>
          </div>
          <div class="white-container-box">
            <img class="img-responsive" src="/assets/img/public/clinicNotes.jpg"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
