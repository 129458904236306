<ul class="p0 user-list scroll-browser user-group-list border-list">
  <ng-container *ngFor="let chat_user of chatroom_users">
    <li class="list" [class.active]="chat_user.selected" (click)="toggleSelection(chat_user)">
      <div class="media">
        <div class="media-left">
          <img alt="Image" class="thumb32" [src]="chat_user.profile_pic || '#'">
        </div>
        <div class="media-body media-middle">
          <h4 class="name">{{chat_user.name}}</h4>
          <h6 class="sub-msg">
            <i class="status-label" [ngClass]="chat_user.css_class">{{chat_user.online_status}}</i>
            <i *ngIf="!chat_user.is_online && chat_user.last_seen"> last seen at {{chat_user.last_seen}}</i>
          </h6>
          <i class="fa icon-check select-icon text-success"></i>
          <!--<input *ngIf="show_recipients" type="checkbox" [(ngModel)]="chat_user.selected">-->
        </div>
      </div>
  </ng-container>
</ul>
