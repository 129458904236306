<table [ngClass]="tableClass">
  <thead>
  <tr>
    <ng-container *ngFor="let column of tableColumns">
      <th
        [ngStyle]="{'min-width.px': column.minWidth, 'max-width.px': column.maxWidth}"
        *ngIf="column.condition === 'all' || tableFor === column.condition">
        {{ column.label }}
      </th>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let event of events">
    <td>{{event.start_date | date: 'M/d/yy'}}</td>
    <td>{{event.start_date | date: 'h:mm a'}}</td>
    <td>{{userService.removeUnderScore(event.duration)}}</td>
    <td>{{event.subject}}</td>
    <td>{{userService.removeUnderScore(event.event_type)}}</td>
    <td>{{event.description}}</td>
    <td>
          <span *ngFor="let notify of event.notify_before;let i=index">
            {{userService.removeUnderScore(notify)}}
            <span *ngIf="i != event.notify_before.length - 1">,</span>
          </span>
    </td>
    <td>
      <label class="label fs12 fw-500" [style.background]="event.status_color">
        {{userService.removeUnderScore(event.status)}}
      </label>
    </td>
    <td>
      <ng-container *ngFor="let comment of event.comments; let i=index">
        <p class="mb-sm">{{comment.body}}</p>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>
