<div class="flex-control flex-column">
  <div class="flex-body scroll-browser">
    <table class="table table-hover">
      <thead>
      <tr>
        <th>Vendor</th>
        <th>Practice</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="integrated_practices">
        <tr *ngFor="let ip of integrated_practices">
          <td>{{ip.vendor_name}}</td>
          <td>{{ip.practice_name}}</td>
          <td>{{ip.status}}</td>
          <td (click)="update(ip)"><button class="btn btn-primary mr">{{ip.active ? 'Stop' : 'Start'}}</button></td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
