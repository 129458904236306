import {Component, OnInit, Input, ViewChild, Injector, ReflectiveInjector, OnChanges} from '@angular/core';
import {Patient} from '../../../doctor/patient';
import {VitalsService} from '../../../../services/vitals.service';
import {PatientVital} from '../../../../routes/practice-site/vital/PatientVital';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {VitalEntry} from '../../../../routes/practice-site/vital/VitalEntry';
import {LoaderService} from '../../../../services/loader.service';
import {VitalComponent} from '../../../../routes/practice-site/vital/vital.component';
import {PatientService} from '../../../../services/patient.service';
import {PrintEnoteComponent} from '../../../../enote/print-enote/print-enote.component';
import {EnoteService} from '../../../../services/enote.service';
import {AlertService} from '../../../../_alert';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnChanges {

  public mapper: Object = {'bmi': 'Body Mass Index', 'ht': 'Height', 'wt': 'Weight',
    'pulse': 'Pulse', 'rr': 'Respiration', 'temp': 'Temperature', 'dbp': 'Blood Pressure', 'sbp': 'Blood Pressure',
    'rbg': 'Blood Glucose (random)', 'fbg': 'Blood Glucose (fasting)', 'spo2': 'SpO2', 'nprs': 'Pain Scale'};
  public text_mapper: Object = {'bmi': 'BMI', 'ht': 'Height', 'wt': 'Weight', 'pulse': 'Pulse Rate', 'rr': 'Respiration',
    'temp': 'Body Temperature', 'sbp': 'Blood Pressure', 'dbp': 'Blood Pressure',
    'rbg': 'Blood Glucose (random)', 'fbg': 'Blood Glucose (fasting)', 'spo2': 'SpO2', 'nprs': 'Pain Scale'};
  public class_mapper: Object = {'pulse': 'fa fa-heart fa-2x text-danger', 'rr': 'fa fa-pie-chart fa-2x text-info',
    'temp': 'fa fa-line-chart fa-2x text-warning', 'sbp': 'fa fa-heartbeat fa-2x text-success',
    'dbp': 'fa fa-heartbeat fa-2x text-success', 'ht': 'fa fa-pie-chart fa-2x text-info',
    'wt': 'fa fa-pie-chart fa-2x text-info', 'bmi': 'fa fa-pie-chart fa-2x text-info',
    'rbg': 'fa fa-pie-chart fa-2x text-info', 'fbg': 'fa fa-pie-chart fa-2x text-info',
    'spo2': 'fa fa-pie-chart fa-2x text-info', 'nprs': 'fa fa-pie-chart fa-2x text-info'};
  public short: any;
  @ViewChild('vitalModal') vitalModal: ModalDirective;
  public vital_entries: VitalEntry[];
  public dbp_vital_entries: VitalEntry[];
  public show_patient_vitals: boolean;
  public show_vital_entries: boolean;
  @Input() patient: Patient;
  public patientVital: PatientVital;
  public patientVitals: PatientVital[];
  public entries_data: any[];
  public custom_injector: Injector;
  public add_patient_vital = false;
  public vitalCompnent = VitalComponent;
  public printEnoteComponent: PrintEnoteComponent;
  public totalItems: string;
  public current_page = '1';
  constructor(private enoteService: EnoteService, private patientService: PatientService,
              public vitalService: VitalsService, private loaderService: LoaderService,
              private injector: Injector, public alertService: AlertService,
              public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit() {
    let inputProviders = [{provide: 'patient_id', useValue: this.patient.id}];
    this.custom_injector  = Injector.create(inputProviders, this.injector);
  }
  ngOnChanges() {
    this.patientLatestVitals();
  }
  patientLatestVitals() {
    this.loaderService.show();
    this.vitalService.vitalEntriesOfLatestPatientVital(this.patient.id).then(resp => {
      this.entries_data = resp;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  openModal(short: any) {
    this.show_vital_entries = true;
    this.show_patient_vitals = false;
    this.short = short;
    this.vitalService.getPatientVitalEntries(this.patient.id, short).then(resp => {
      this.vital_entries = resp.map(ve => new VitalEntry().load_from_json(ve));
      if (short === 'dbp' || short === 'sbp') {
        this.dbp_vital_entries = this.vital_entries.filter(ve => ve.vital.short === 'dbp');
        this.vital_entries = this.vital_entries.filter(ve => ve.vital.short === 'sbp');
      } else {
        this.dbp_vital_entries = null;
      }
    })
    this.vitalModal.show();
  }
  showPatientVitals() {
    this.get_patient_appointments();
  }
  get_patient_appointments() {
    this.loaderService.show();
    this.vitalService.getPatientVitalsWithPagination(this.patient.id, this.current_page)
      .then(resp => {
        this.patientVitals = resp.vitals.map(vital => new PatientVital().load_from_json(vital));
        this.totalItems = resp.total;
        this.current_page = resp.current_page;
        this.show_patient_vitals = true;
        this.vitalModal.show();
        this.loaderService.hide();
      }).catch( resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      }
    )
  }

  ConvertHeightInFeetAndInch(height) {
    if (height) {
      let heightInFeet = Math.floor(parseInt(height) / 12);
      let heightInInch = parseInt(((parseInt(height) / 12 - heightInFeet) * 12).toFixed(2));
      return heightInFeet + '\'' + heightInInch + '\'\'';
    }
  }

  weightInPounds(w) {
    let wt = ( w  * 2.2046).toFixed(2);
    return wt.toString();
  }

  close() {
    this.vitalModal.hide();
    this.current_page = '1';
    this.patientLatestVitals();
    setTimeout(() => {
      this.show_patient_vitals = false;
      this.show_vital_entries = false;
      this.add_patient_vital = false;
    }, 500);
  }
  addVital() {
    this.loaderService.show();
    this.add_patient_vital = true;
    let inputProviders = [{provide: 'patient_id', useValue: this.patient.id}];
    this.custom_injector  = Injector.create(inputProviders, this.injector);
    this.vitalModal.show();
    setTimeout(() => {
      this.loaderService.hide();
    }, 4000);
  }

  updatePatientVitals(vital: PatientVital) {
    this.show_patient_vitals = false;
    this.add_patient_vital = true;
    let inputProviders = [{provide: 'patient_id', useValue: vital.patient_id}, {provide: 'patient_vital', useValue: vital}];
    this.custom_injector  = Injector.create(inputProviders, this.injector);
  }


  historyPageChange(page: any) {
    this.current_page = page.page;
    this.get_patient_appointments();
  }
}
