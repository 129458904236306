<form #addMedialCenterForm='ngForm' (ngSubmit)="save()">
  <div class="row">
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="Name*" id="name" [(ngModel)]="medicalCenter.name" name="name"
             required/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <select name="center_type" [(ngModel)]="medicalCenter.center_type" required class="form-control" id="center_type">
        <ng-container *ngFor="let center_type of medicalCenterService.center_types">
          <option class="text-capitalize" value='{{center_type}}'>{{ userService.removeUnderScore(center_type) }}
          </option>
        </ng-container>
      </select>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 form-group">
      <input type="text" class="form-control" placeholder="Address" id="address" [(ngModel)]="medicalCenter.address"
             name="address"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="City" id="city" [(ngModel)]="medicalCenter.city"
             name="city"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="State" id="state" [(ngModel)]="medicalCenter.state"
             name="state"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <input type="text" class="form-control" placeholder="Zip" id="zip" [(ngModel)]="medicalCenter.zip" name="zip"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group">
      <input type="email" class="form-control" placeholder="Email" id="email"
             pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
             [(ngModel)]="medicalCenter.email" name="email"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group inline-table ">
      <span class="input-group-addon"><i class="fa fa-phone"></i></span>
      <input type="text" mask="(000) 000-0000" class="form-control phone" placeholder="Phone" id="phone"
             [(ngModel)]="medicalCenter.phone" name="phone"/>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-6 form-group inline-table">
      <span class="input-group-addon"><i class="fa fa-fax"></i></span>
      <input type="text" mask="(000) 000-0000" class="form-control fax" placeholder="Fax" id="fax"
             [(ngModel)]="medicalCenter.fax" name="fax"/>
    </div>
    <div class="col-xs-12 form-group">
      <button type="submit" class="btn btn-secondary btn-sm mr"
              [disabled]="!addMedialCenterForm.form.valid">{{ medicalCenter.is_new_record() ? 'Save' : 'Update' }}
      </button>
      <button type="button" (click)="addMedicalCenterEmitter.emit('close')" class="btn btn-tertiary btn-outline btn-sm">
        Cancel
      </button>
    </div>
  </div>
</form>
