import {Component, Input, ElementRef, ViewChild, Output, EventEmitter, Renderer2, AfterViewInit} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { mergeMap } from 'rxjs/operators';
import {Term} from '../enote_models/term';
import {EnoteService} from '../../services/enote.service';
import {Keyword} from '../enote_models/keyword';
import {LoaderService} from '../../services/loader.service';
@Component({
  selector: 'app-searchable',
  templateUrl: './searchable.component.html',
  styleUrls: ['./searchable.component.scss']
})
export class SearchableComponent implements AfterViewInit {
  @ViewChild('typeahead') typeahead: ElementRef;
  @Input() term: any;
  @Input() searchable: any;
  @Output() removeTypeAhead: EventEmitter<any> = new EventEmitter<any>();
  public data_source: Observable<any>;
  public token = '';

  public asyncSelected = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  @Output() on_select: EventEmitter<Term> = new EventEmitter<Term>();
  constructor(private _enote_service: EnoteService, private loaderService: LoaderService, private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    let lib_id;
    let lib_type;
    if (this.term.parent_term && this.term.parent_term.library_datum_id) {
      lib_id = this.term.parent_term.library_id;
    }
    else {
      lib_type = 'component';
    }
    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).pipe(
      mergeMap((token: string) => {
        if (this.searchable === 'term') {
          return this._enote_service.term_search(this.token, lib_id, lib_type, this.term.parent_term.parent_term && this.term.parent_term.parent_term.library_datum_id, this.term)
        }
        if (this.searchable === 'keyword') {
          return this._enote_service.search_keyword(this.token)
        }
      })
    );
    if (this.term.focus_on_init) {
      this.setFocus();
    }
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  setFocus() {
    this.typeahead.nativeElement.focus();
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }
  public checkString(event) {
    if (event.key === 'Backspace' && this.token?.length === 0) {
       this.term.show_typeahead = false;
       this.removeTypeAhead.emit(true);
     }
     if (event.key === 'Escape' && this.token?.length !== 0) {
       if (this.searchable === 'keyword') {
         let new_keyword = new Keyword();
             new_keyword.name = this.token;
             new_keyword.library_id = this.term.parent_term.library_id;
         this.loaderService.show();
         this._enote_service.create_keyword(new_keyword).then(resp => {
           new_keyword.load_from_json(resp);
           this.typeaheadOnSelect({item: {term_type: 'keyword', keyword: new_keyword}});
           this.loaderService.hide();
         })
       }
       if (this.searchable === 'term') {
         this._enote_service.create_term(this.token).then(resp => {
           let new_term = this.typeaheadOnSelect({item: resp});
           new_term.term_type = 'term';
         })

       }
     }
  }

  public typeaheadOnSelect(e: any): Term {

    let term_json = e.item.term_type === 'keyword' ? {name: null, component_type: 'general', term_type: 'keyword'} : e.item;
    if (e.item.term_type == 'keyword') {
       term_json['keyword'] = e.item.keyword;
    }
    console.log(e.item, term_json);
    let new_term = new Term('', '');
    let parent_term = this.term.parent_term;
    let term_position = this.term.term_position() + 1;
    if (parent_term.initial_component && parent_term.parent_term) {
      term_position = parent_term.term_position() + 1;
      parent_term = parent_term.parent_term;
    }
    new_term.load_from_json(term_json, this.term.parent_term, this.term.term_position(), this._enote_service);
    parent_term.addToChildren(new_term, term_position);
    if (new_term.isComponentTypeChild() && !new_term.is_open_text) {
      new_term.setComponentLevel(new_term.parent_term);
    }
    this.token = '';
    this.on_select.emit(this.term);
    console.log(new_term);
    return new_term;
  }

    onBackSpaceDownDelete(e, term) {
    if (e && e.target && e.target.attributes['ng-reflect-model'] && e.target.attributes['ng-reflect-model'].value?.length === 0) {
        term._destory = true
     }
    }

}
