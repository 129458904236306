<div class="intake-dropdown" dropdown [insideClick]="true">
  <button #buttoncustomhtml id="button-custom-html-{{inputId}}" dropdownToggle type="button" class="btn btn-secondary dropdown-toggle"
          aria-controls="dropdown-custom-html">Send Intake Form <span class="caret"></span>
  </button>
  <div id="dropdown-custom-html-{{inputId}}" *dropdownMenu class="dropdown-menu animated slideInDown">
    <div class="richText-template">
      <input class="form-control" placeholder="Search Form Template" id="intakeTypeAhead-{{inputId}}" name="serchFormTemplate-{{inputId}}"
             [(ngModel)]="template_form_token" [typeahead]="data_source_template_form"
             typeaheadWaitMs="500" (typeaheadLoading)="changeTypeaheadLoading($event)"
             [typeaheadAsync]="true"
             (typeaheadNoResults)="changeTypeaheadNoResults($event)"
             (typeaheadOnSelect)="typeaheadFromTemplateOnSelect($event)" [typeaheadOptionsLimit]="7"
             [typeaheadOptionField]="'name'"/>
      <i class="fa fa-spinner fa-spin" *ngIf="typeaheadLoading"></i>
    </div>
    <div *ngIf="typeaheadNoResults" class="text-danger fs12 pt-sm">No Results Found!</div>
  </div>
</div>
