<ng-container *ngIf="!patientEventService.showDeleteConformation">
  <div class="modal-body event-detail-modal">
    <app-health-calendar-event-tooltip [event]="event" [isViewMode]="isViewMode" (editEvent)="edit($event)"></app-health-calendar-event-tooltip>
    <h6 class="flex-control mt mb-sm" *ngIf="event.appointment_with">
      <label class="field-label mr">Appointment with</label>
      <span>{{event?.appointment_with}}</span>
    </h6>
    <h6 class="flex-control mt mb-sm" *ngIf="event.updater_name">
      <label class="field-label mr">Last updated by</label>
      <span class="text-capitalize" style="display:inline-block; flex:none;">{{event?.updater_name}}</span>
      &nbsp;at {{event?.updated_at | date:'MMMM d, y, h:mm a'}}
    </h6>
    <ng-container>
      <hr class="hr-line mt mb">
      <app-comment class="p" [comments]="comments" [commentable_id]="event.id" [commentable_type]="'PatientEvent'"
                   [commentable]="event"></app-comment>
    </ng-container>
  </div>
  <div class="modal-footer pb0">
    <button type="submit" class="btn btn-secondary mb-lg" (click)="updateStatus('done')">Done</button>
    <button type="submit" class="btn btn-tertiary btn-outline mb-lg" (click)="updateStatus('to_do')">To-Do</button>
    <button type="submit" class="btn btn-tertiary btn-outline mb-lg" (click)="updateStatus('missed')">Missed</button>
    <button type="submit" class="btn btn-tertiary btn-outline mb-lg" (click)="updateStatus('cancelled')">Cancelled</button>
    <button *ngIf="!isPatientPortal" type="submit" class="btn btn-danger mb-lg" (click)="patientEventService.showDeleteConformation = true">Delete</button>
  </div>
</ng-container>

<ng-container *ngIf="patientEventService.showDeleteConformation">
  <div class="modal-body">
    <div class="alert alert-danger p pt-sm pb-sm m0 text-center">“Are you sure you want to delete the event?”</div>
  </div>
  <div class="modal-footer pb0">
    <ng-container *ngIf="event.recur_type !== 'never'">
      <button type="submit" class="btn btn-secondary btn-outline mb-lg" (click)="deletePatientEvent('this')">This Event</button>
      <button type="submit" class="btn btn-secondary btn-outline mb-lg" (click)="deletePatientEvent('all')">This and following events</button>
    </ng-container>
    <ng-container *ngIf="event.recur_type === 'never'">
      <button type="submit" class="btn btn-secondary mb-lg" (click)="deletePatientEvent('this')">Yes</button>
    </ng-container>
    <button type="submit" class="btn btn-tertiary btn-outline mb-lg" (click)="patientEventService.showDeleteConformation = false">No</button>
  </div>
</ng-container>
