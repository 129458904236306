<ng-container *ngIf="show_print == false">
  <form (ngSubmit)="saveCaseInfo()" #caseInfoForm="ngForm">
    <div class="row">
      <div class="col-xs-6"><h4 class="text-primary">Case Information</h4></div>
      <div class="col-xs-6 text-right"><button class="btn btn-primary" (click)="addNewCase()">Add Case</button></div>
    </div>
    <hr class="hr-line mt0">
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Status</label>
          <div class="col-sm-6">
            <select class="form-control" [(ngModel)]="case_info.status_id" name="status_id">
              <option value="open">open</option>
              <option value="inprogress">inprogress</option>
              <option value="closed">closed</option>
              <option value="error">error</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 pt-sm">Consultation date</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
           <!-- <input [(ngModel)]="case_info.consultation_date" autocomplete="off" name="consultation_date" class="form-control"
                   ngui-datetime-picker
                   [disabled-dates]="date2DisabledDates"
                   [show-close-layer]="true"
                   placeholder="Consultation Date"
                   date-only="true"/>-->
            <input type="text"
                   autocomplete="off"
                   name="consultation_date"
                   [(ngModel)]="case_info.consultation_date"
                   placeholder="Consultation Date"
                   class="form-control"
                   [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                   bsDatepicker>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Physician</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <input [typeahead]="physicians" name="physicians" [(ngModel)]="asyncSelected" typeaheadWaitMs="500"
                   (typeaheadLoading)="changeTypeaheadLoading($event)" [typeaheadAsync]="true"
                   (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                   (typeaheadOnSelect)="typeaheadOnSelect($event)" [typeaheadOptionsLimit]="7"
                   [typeaheadOptionField]="'first_name'" placeholder="Physicians"
                   class="form-control" autocomplete="off">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Diagnosis</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.diagnosis" name="diagnosis">
              <option value="diagnosis1">diagnosis1</option>
              <option value="diagnosis2">diagnosis2</option>
              <option value="diagnosis3">diagnosis3</option>
              <option value="diagnosis4">diagnosis4</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Planned Treatment</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.planned_treatment" name="planned_treatment">
              <option value="treatment1">treatment1</option>
              <option value="treatment2">treatment2</option>
              <option value="treatment3">treatment3</option>
              <option value="treatment4">treatment4</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Recommendation</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <input class="form-control" [(ngModel)]="case_info.recommendation" name="recommendation" pattern="^[a-zA-Z ]+$"/>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Referred by</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.referred_by" name="referred_by">
              <option value="Already a Patient">Already a Patient</option>
              <option value="Bahria Town Hospital">Bahria Town Hospital</option>
              <option value="Brigadier Muaad-ullah">Brigadier Muaad-ullah</option>
              <option value="Donor">Donor</option>
              <option value="Electronic Media">Electronic Media</option>
              <option value="Friend">Friend</option>
              <option value="Facebook Page">Facebook Page</option>
              <option value="Gift of Life Rotary">Gift of Life Rotary</option>
              <option value="Kal Tak with Javed Ch.">Kal Tak with Javed Ch.</option>
              <option value="Life Hospital">Life Hospital</option>
              <option value="Patient">Patient</option>
              <option value="Shifa International Hospital">Shifa International Hospital</option>
              <option value="PCHF Hotline Number">PCHF Hotline Number</option>
              <option value="Tare Zameen Par">Tare Zameen Par</option>
              <option value="Transparent Hands">Transparent Hands</option>
              <option value="UOLTH">UOLTH</option>
              <option value="Website">Website</option>
              <option value="Other">Other</option>
              <option value="Dr. Asif-Ur-Rehman">Dr. Asif-Ur-Rehman</option>
              <option value="Dr. Asim Khan">Dr. Asim Khan</option>
              <option value="Dr. Jamal Abdul Nasir">Dr. Jamal Abdul Nasir</option>
              <option value="Dr. Kamran Khatak">Dr. Kamran Khatak</option>
              <option value="Dr. Masood Sadiq">Dr. Masood Sadiq</option>
              <option value="Dr. Mehnaz Atiq">Dr. Mehnaz Atiq</option>
              <option value="Dr. Najam Haider">Dr. Najam Haider</option>
              <option value="Dr. Salman A.Shah">Dr. Salman A.Shah</option>
              <option value="Dr. Younus">Dr. Younus</option>
              <option value="Dr. Zia-ullah">Dr. Zia-ullah</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 pt-sm">When Diagnosed</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.when_diagnosed" name="when_diagnosed"/></div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 pt-sm">Diagnosed during pregnancy</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.diagnosed_during_pregnancy" name="diagnosed_during_pregnancy"/></div>
        </div>
      </div>
    </div>

    <h4 class="text-primary fw-500">Financial</h4>
    <hr class="hr-line mt0">
    <div class="row">
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Expected Cost</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <input type="text" class="form-control" [(ngModel)]="case_info.expected_cost" name="expected_cost" pattern="\d*"/>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Case Condition</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.case_condition" name="case_condition">
              <option value="deserving">Deserving</option>
              <option value="non-deserving">Non-deserving</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Funds</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.funds" name="funds">
              <option value="available">Available</option>
              <option value="not-available">Not-available</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">PCHF share</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.pchf_share" name="pchf_share"></div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Parent share</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.parent_share" name="parent_share"></div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Third Party share</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.third_party_share" name="third_party_share"></div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Third Party Name</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.third_party_name" name="third_party_name" pattern="^[a-zA-Z ]+$"></div>
        </div>
      </div>
    </div>

    <h4 class="text-primary fw-500">Over Stay</h4>
    <hr class="hr-line mt0">
    <div class="row">
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Extended Amount</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.extended_amount" name="extended_amount" pattern="\d*"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">PCHF share</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.overstay_pchf_share" name="overstay_pchf_share"></div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Parent share</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.overstay_parent_share" name="overstay_parent_share"></div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Third Party</label>
          <div class="col-md-8 col-sm-12 col-xs-12"><input type="text" class="form-control" [(ngModel)]="case_info.overstay_third_party_share" name="overstay_third_party_share"></div>
        </div>
      </div>
    </div>

    <h4 class="text-primary fw-500">Schedule</h4>
    <hr class="hr-line mt0">
    <div class="row">
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Referred to</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.referred_to" name="referred_to">
              <option *ngFor="let location of locations" [value]="location.name">
                {{location.name}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Surgery date</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
           <!-- <input [(ngModel)]="case_info.surgery_date" autocomplete="off" name="surgery_date" class="form-control"
                   ngui-datetime-picker
                   [disabled-dates]="date2DisabledDatesSurgery"
                   [show-close-layer]="true"
                   placeholder="Surgery Date"
                   date-only="true"/>-->
            <input [(ngModel)]="case_info.surgery_date"
                   autocomplete="off"
                   name="surgery_date"
                   class="form-control"
                   [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                   bsDatepicker
                   />
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Admission date</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <!--<input [(ngModel)]="case_info.admission_date" autocomplete="off" name="admission_date" class="form-control"
                   ngui-datetime-picker
                   [disabled-dates]="date2DisabledDates"
                   [show-close-layer]="true"
                   placeholder="Admission Date"
                   date-only="true"/>-->
            <input [(ngModel)]="case_info.admission_date"
                   autocomplete="off"
                   name="admission_date" class="form-control"
                   placeholder="Admission Date"
                   [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
                   bsDatepicker/>
          </div>
        </div>
      </div>
    </div>

    <h4 class="text-primary fw-500">Approvals</h4>
    <hr class="hr-line mt0">
    <div class="row">
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Approval 1</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.approval_1" name="approval_1">
              <option *ngFor="let staff of staffs" [value]="staff.full_name">
                {{staff.first_name}} {{staff.last_name}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Approval 2</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.approval_2" name="approval_2">
              <option *ngFor="let staff of staffs" [value]="staff.full_name">
                {{staff.first_name}} {{staff.last_name}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12 form-group">
        <div class="row">
          <label class="col-md-4 col-sm-12 col-xs-12 pt-sm">Approval 3</label>
          <div class="col-md-8 col-sm-12 col-xs-12">
            <select class="form-control" [(ngModel)]="case_info.approval_3" name="approval_3">
              <option *ngFor="let staff of staffs" [value]="staff.full_name">
                {{staff.first_name}} {{staff.last_name}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-xl">
      <div *ngIf="messageAfterSuccessSave" class="text-success text-center">
        <strong class="text-success">Case Info has been saved</strong>
      </div>
      <div *ngIf="messageAfterSave" class="text-success text-center">
        <strong class="text-danger">Sorry!! Case Info cannot be saved</strong>
      </div>
      <button type="submit"class="btn btn-primary btn-lg mr-sm" [disabled]="!caseInfoForm.form.valid">Submit</button>
      <!--<button type="button" class="btn btn-inverse btn-lg">Cancel</button>-->
    </div>
  </form>

  <ng-container *ngIf="case_info_listing && case_info_listing.length > 0">
    <div class="table-responsive mt-xl">
      <table class="table table-bordered table-striped table-hover m0">
        <thead>
        <tr>
          <th>Case Date</th>
          <th>Surgery date</th>
          <th>Diagnosis</th>
          <th>Physician</th>
          <th>Treatment</th>
          <th>Status</th>
          <th style="min-width:100px; width:100px;">Admission report</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let case_info of case_info_listing">
          <td><a (click)="load_case(case_info)">{{case_info.created_at}}</a></td>
          <td>{{case_info.surgery_date_format}}</td>
          <td>{{case_info.diagnosis}}</td>
          <td>{{case_info.physician_name}}</td>
          <td>{{case_info.planned_treatment}}</td>
          <td>{{case_info.status_id}}</td>
          <td><button class="btn btn-primary btn-outline" title="Print report" (click)="print(case_info)"><i class="fa fa-print"></i></button></td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="show_print">
  <app-print-pchf (hide_mode)="handle_response($event)" [print_case_info]="print_case_info"></app-print-pchf>
</ng-container>
