import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-public-patient-communication',
  templateUrl: './public-patient-communication.component.html',
  styleUrls: ['./public-patient-communication.component.scss']
})
export class PublicPatientCommunicationComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0)
      }
    });
  }

  ngOnInit(): void {
  }

}
