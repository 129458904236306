import {AfterViewInit, Component, OnInit} from '@angular/core';
import {PublicServiceService} from '../public-service.service';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-public-appointments-booking',
  templateUrl: './public-appointments-booking.component.html',
  styleUrls: ['./public-appointments-booking.component.scss']
})
export class PublicAppointmentsBookingComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0)
      }
    });
  }

  ngOnInit(): void {
  }

}
