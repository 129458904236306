import { NgModule } from '@angular/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import {HttpClient} from "@angular/common/http";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {VideoChatModule} from '../video-chat/video-chat.module';
import {DragDropModule} from '@angular/cdk/drag-drop';


@NgModule({
    imports: [
        SharedModule, TypeaheadModule, BsDropdownModule, VideoChatModule, DragDropModule
    ],
    providers: [
        UserblockService,HttpClient
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,SubHeaderComponent,
      NavsearchComponent,
        FooterComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,SubHeaderComponent,
      NavsearchComponent,
        FooterComponent
    ]
})
export class LayoutModule { }
