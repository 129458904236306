import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Chatroom, Message} from '../chatroom';
import {MessageService} from '../../../../services/message.service';
import {UserService} from '../../../../services/user.service';
import {Tab} from '../../../../tabs/tab';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {Appointment} from '../../../../health/models/appointment';
import {TabsService} from '../../../../services/tabs.service';
import { LoaderService} from '../../../../services/loader.service';
import {PhoneService} from '../../../../voice-call/phone.service';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss']
})
export class RightPanelComponent implements OnInit {

  @Input() public chatroom: Chatroom;
  @ViewChild('classicModal') modal;
  @ViewChild('title_input') title_input;
  public show_recipients: Boolean = false;
  @Input() public show_group_info: Boolean;
  @Input() public popup_onload: Boolean;
  @Output() group_clicked = new EventEmitter<Boolean>();
  @Output() group_updated = new EventEmitter<Chatroom>();
  @Output() popup_closed = new EventEmitter<Boolean>();
  public  show_attachment_popup = false;
  public title_edit = false;
  constructor(public loaderService: LoaderService, public messageService: MessageService, public _user_service: UserService,
              private tabs_service: TabsService, public phoneService: PhoneService) { }

  ngOnInit() {
    this.show_recipients =  this.chatroom.is_group;
  }

  get title() {
    return this.chatroom.id ? 'Add Participant(s)' : 'Create New Group';
  }

  get selectedTitle() {
    let selected = this.selectedRecipients;
    return selected ? `${selected} selected` : '';
  }


  get selectedRecipients() {
    return this.chatroom.new_recipients.filter(a => a.selected).length
  }

  handle_file_upload(file_date) {
    if (file_date) {
      this.messageService.selected_chatroom.thumbnail = file_date.profile_pic;
    }
  }
  choose_file() {
    this.show_attachment_popup = true;
    setTimeout( () => {
      this.show_attachment_popup = false;
    }, 75); }

  add_to_tabs(chatable_user) {
    this.tabs_service.add_tab(new Tab({title: chatable_user.name, icon: 'fa fa-hospital'}, PatientProfileComponent,
      chatable_user.profileable_id, {id: chatable_user.profileable_id, appointment: new Appointment}))
  }

  ngAfterViewChecked() {
    if (this.popup_onload) {
      this.showSubscribers();
    }
  }

  addRecipients() {
    this.messageService.update_chatroom(this.chatroom).subscribe(r => {
      this.modal.hide();
      this.group_updated.emit(this.chatroom);
    });
  }
  showSubscribers() {
    this.modal.show();
  }

  deleteuser(user) {
    this.messageService.deleteSubscriber(user, this.chatroom).subscribe( (res) => {
      this.chatroom.removeUser(user);
      // this.chatroom.push_message(new Message().load_from_json(res['message']));
    });
  }
  currentUserIsAdmin() {
    return this.chatroom.user_id == (this._user_service.current_user && this._user_service.current_user.id);
  }

  toggleTitleEdit() {
    this.title_edit = !this.title_edit;
    setTimeout(a => {
      if (this.title_edit && this.title_input) {
        this.title_input.nativeElement.focus();
      }
    }, 200);
  }
  toggleGroupInfo() {
    this.group_clicked.emit(true);
  }

  changeTitle() {
    this.messageService.update_chatroom_title(this.chatroom).subscribe( (res) => {
      this.toggleTitleEdit();
      // this.chatroom.push_message(new Message().load_from_json(res['message']));
    });
  }
  clear_variables() {
    this.popup_closed.emit(true);
  }
}
