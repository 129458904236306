<section class="inner-public-banner">
  <div class="container">
    <div class="text-container">
      <h1>Reputation Management</h1>
      <h4 class="fs22 text-primary lhn mt-lg">Get feedback from your customers, rank and make it public.</h4>
      <h4 class="sub-title">
        Knowing level of patient satisfaction is vital for any doctor and medical setup in general. Strategize and
        manage reputation based on patient reviews is a key for practice growth.
      </h4>
      <h4 class="sub-title">
        Everyone knows that the reputation of a business is crucial for its survival. A good reputation management can
        easily help a business to gain trust. Hence, positive reviews can lead potential customers to trust the business
        even more. A good reputation means that the business is more credible than their competitors are
      </h4>
    </div>
  </div>
</section>
<section class="sec-pad verified-sec">
  <div class="container">
    <div class="text-center">
      <h1>Verified Patient Reviews</h1>
      <br>
      <p style="max-width:900px; margin:0 auto 2em;">
        We know Patients look for reputable providers online. It is important to stand out in a crowd so patients can
        choose you. Wellwink allows collecting verified patient reviews by doctor and practices. Publishing online
        reviews benefit medical practice immensely.
        <br><br>
        Gathering patient feedback and publishing on right platforms improves practice credibility, increases SEO and
        strengthen online reputation.
      </p>
    </div>
    <div class="white-container-box p-xl">
      <h3 class="fw-500 text-center mt0 mb-xl">Protect & Enhanced Reputation</h3>
      <img class="img-responsive" src="/assets/img/public/reviews-infographic.jpg"/>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <div class="sec-pad">
          <div class="text-center">
            <h1>Patient Tagging</h1>
            <br>
            <p class="min-height">
              By tagging patients, you can stratify patients in separate groups and subgroups. For instance, patients can
              be grouped by rating, specific feedback or concern. Patients can also be tagged by any keyword or a factor
              that is more relevant to identify a patient. With the strata established, different approaches can devised
              to improve overall reputation.
            </p>
          </div>
          <div class="white-container-box pb">
            <img class="img-responsive" src="/assets/img/public/reviews.jpg"/>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div class="sec-pad">
          <div class="text-center">
            <h1>Manage Reputation</h1>
            <br>
            <p class="min-height">
              Having a good reputation management tool helps bring more patients. WellWink connects all dots and provides
              help to manage customer’s feedback in a meaningful way.  It is important, whether it is negative.
              Reputation management tool provides the ability to publish positive reviews after approval and let you
              work on negative reviews until turns positive. Practice and provider gets better ranking at the end of the day.
            </p>
          </div>
          <div class="white-container-box pb">
            <img class="img-responsive" src="/assets/img/public/reviews-3.jpg"/>
          </div>
        </div>
      </div>
    </div>
    <div class="sec-pad pt">
      <h1 class="text-center mt0">Features You Need</h1>
      <br>
      <div class="white-container-box p-lg">
        <div class="row">
          <div class="col-md-4 col-sm-4">
            <ul class="list-group list-unstyled m0">
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Doctor Review</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Practice Review</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Verified Patients</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Patient comments</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Rating Dashboard</li>
            </ul>
          </div>
          <div class="col-md-4 col-sm-4">
            <ul class="list-group list-unstyled m0">
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Star Ratings</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Display Widget</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Review Approval for website</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Review Request for Social Media</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Auto Review Request at Checkout</li>
            </ul>
          </div>
          <div class="col-md-4 col-sm-4">
            <ul class="list-group list-unstyled m0">
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; In-Email Review Requests</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; SMS Review Requests</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Patient Tagging</li>
              <li class="list-group-item"><i class="fa fa-chevron-circle-right fa-fw fs16"></i>&nbsp; Advance Filtering</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
