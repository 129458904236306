import { Component, OnInit , Injector, OnChanges, ViewChild} from '@angular/core';
import {UserManagementService} from '../../../services/user-management.service'
import {User} from '../../users/user'
import { NgForm } from '@angular/forms';
import { CustomValidations } from '../../customValidations/custom-validations';
import { Observable } from 'rxjs/Observable';
@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss']
})
export class EnterpriseComponent implements OnInit {

  public user: User = new User();

  constructor(private injector: Injector, private _user_management_service: UserManagementService) { }

  ngOnInit() {
    let id = this.injector.get('id', null);
    if (id) {
      this.fetchEnterprise(id);
    }
  }

  fetchEnterprise(user_id) {
    this._user_management_service.get_user_loaded(this.user, user_id).then(res => {
      this.user = res;
    })
  }

}
