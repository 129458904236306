import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {CustomHttpClient} from '../../../../../services/custom-http-client.service';
import {Term} from '../../../../../enote/enote_models/term';
import {Program} from '../../../../../program/program';

@Injectable({
  providedIn: 'root'
})
export class MedicationSummaryService {

  constructor(private _tokenService: CustomHttpClient) { }

  getPatientSummaryData(patient_id: any, status: string, summary_type: string) {
    let args = new HttpParams();
    args = args.set('id', patient_id);
    args = args.set('status', status);
    args = args.set('summary_type', summary_type);
    return this._tokenService.get('patient_summaries', {params: args})
      .toPromise()
      .then(response => response);
  }

  changeTermStatus(params: any): Promise<any> {
    return this._tokenService.put('patient_summaries', params)
      .toPromise()
      .then(response => response);
  }

  getCustomAndDefaultControls(type: any) {
    let args = new HttpParams();
    args = args.set('type', type);
    return this._tokenService.get('patient_summaries/controls', {params: args})
      .toPromise()
      .then(response => response);
  }
  addPatientProblem(term: Term, for_library?: any, patient_id?: any): Promise<any> {
    return this._tokenService
      .post('patient_summaries/term', {base_term: term.to_params(), for_library: for_library, patient_id: patient_id} )
      .toPromise()
      .then(response => response)
  }
  addPatientSummary(summary): Promise<any> {
    return this._tokenService
      .post('patient_summaries/summary', {summary: summary})
      .toPromise()
      .then(response => response)
  }
  save(term: Term): Promise<any> {
    if (term.is_new_record()) {
      return this.addPatientProblem(term);
    }
    else {
      return this.update(term);
    }
  }

  update(term: Term) {
    return this._tokenService
      .put('patient_summaries/term', {base_term: term.to_params()})
      .toPromise()
      .then(response => response)
  }


  searchTermsAllergiesMedication(token: any, term_for: string) {
    let args = new HttpParams();
    args = args.set('token', token);
    args = args.set('term_for', term_for);
    return this._tokenService.get('patient_summaries/search_term', {params: args})
      .toPromise()
      .then(response => response)
  }
}
