import {Component, Input, OnInit} from '@angular/core';
import {TabsService} from "../../../../services/tabs.service";
import {UserService} from "../../../../services/user.service";
import {Patient} from "../../../doctor/patient";

@Component({
  selector: 'app-patient-communication-log',
  templateUrl: './patient-communication-log.component.html',
  styleUrls: ['./patient-communication-log.component.scss']
})
export class PatientCommunicationLogComponent implements OnInit {
  public selected_stage_id: number;
  public stage_ids: number[] = [0,1,2];
  public menu_items: Object = {0: 'Outreach', 1: 'Tasks',2 : 'Notes'};
  @Input() patient: Patient;


  constructor(public _tabs_service: TabsService, public _userService: UserService) {
    this.selected_stage_id = 0;
  }

  ngOnInit(): void {
  }

  onSelect(stage_id: number) {
    this.selected_stage_id = stage_id;
  }




}
