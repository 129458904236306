import { Component, OnInit, NgZone, ElementRef, Inject, Input, EventEmitter, Output, ViewChild} from '@angular/core';
import { Staff } from './contact'
import { ContactService } from '../../../services/contact.service';
import { ActivatedRoute, Params }   from '@angular/router';
import { NgForm } from '@angular/forms';
import { CustomValidations } from '../../customValidations/custom-validations'
import { Practice } from '../practices/practice'
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import { AfterViewChecked, OnChanges } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() staff: Staff;
  public searchElementRef: ElementRef;
  customValidations: CustomValidations;
  @Input() do_show: any;
  @Input() practice_id: any;
  @Output() sync_staff: EventEmitter<Staff> = new EventEmitter<Staff>();
  @Output() reset_do_show: EventEmitter<boolean> = new EventEmitter<boolean>();

  // validations

  @ViewChild('contactForm') contactForm: NgForm;

  constructor(private ngZone: NgZone,
              private route: ActivatedRoute, private globalErrorHandlerService: GlobalErrorHandlerService,
              @Inject(ElementRef) elementRef: ElementRef, private contactService: ContactService) {

    this.searchElementRef = elementRef;
    this.setGoogleMaps();
    this.customValidations = new CustomValidations();
  }

  ngOnInit() {
  }
  ngAfterViewChecked() {
    this.customValidations.formChanged(this.contactForm);
  }
  ngOnChanges() {
    this.setGoogleMaps();
  }
  save() {
    this.staff.email = this.staff.email.toLowerCase();
    this.staff.practice_id = this.practice_id;
    this.contactService.save(this.staff).then(res => {
      if (!this.staff.id) {
        this.staff.id = res.id;
        this.sync_staff.emit(this.staff);
      }
      else {
        this.reset_do_show.emit(false)
      }
    }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error );

  }

  cancel() {
    this.reset_do_show.emit(false)
  }

  setGoogleMaps() {
    let component = this;
    if (component.searchElementRef.nativeElement.querySelector('#city_area_name') === null) {
      return;
    }
    console.log(component.searchElementRef.nativeElement.querySelector('#city_area_name'));
    let searchBox = new google.maps.places.Autocomplete(component.searchElementRef.nativeElement.querySelector('#city_area_name'));
      google.maps.event.addListener(searchBox, 'places_changed', function () {
        function closure(elem) {
          let place = searchBox.getPlace();
          if (!place) {
            return;
          }
          // elem.location.address.city_area.name=searchBox.getPlaces()[0].formatted_address;
          elem.staff.address.city_area.lat = place.geometry.location.lat();
          elem.staff.address.city_area.long = place.geometry.location.lng();
          elem.staff.address.city_area.name = place.formatted_address;

        }
        closure(component);
      })
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.staff.address.city_area.lat = position.coords.latitude.toString();
        this.staff.address.city_area.long = position.coords.longitude.toString();
      });
    }
  }

}
