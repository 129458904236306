import { Injectable }     from '@angular/core';
import {CustomHttpClient} from './custom-http-client.service';
import { Calendar } from '.././routes/practices/doctors/doctor';



@Injectable()
export class CalendarService {
  constructor(private _tokenService: CustomHttpClient) {}

  create(calendar: Calendar): Promise<any> {
    return this._tokenService
      .post('calendars/', calendar.to_params())
      .toPromise()
      .then(res => calendar.load_from_json(res))
  }

  update(calendar: Calendar) {
    return this._tokenService
      .put('calendars/update_calendar/', calendar.to_params())
      .toPromise()
      .then(res => calendar.load_from_json(res))
  }

  save(calendar: Calendar): Promise<any> {
    if (calendar.is_new_record()) {
      return this.create(calendar);
    }
    else {
      return this.update(calendar);
    }
  }

  delete(calendar_id: any): Promise<any> {
    return this._tokenService.get('calendars?calendar_id=' + calendar_id)
      .toPromise()
      .then(res => res);
  }
}
