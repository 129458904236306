<span id="text_box_container" (click)="inputClicked($event)" #text_box>
  <span contenteditable="false">
    <ng-container *ngFor="let literal of literals;let i=index">
      <span *ngIf="literal.freetext">
        <span class="first-input" style="min-height:25px; display:inline-block; word-break:break-all;" (paste)="onPaste($event)"
                contenteditable="true"
                (input)="literal.text=$event.target.innerText"
                (keydown.enter)="notify_submit()" #literal_ref (keydown)="check_keyup($event,literal)"
                (keydown.backspace)="onFreeTextBackSpaceDown(i)" >
        </span>
        <ng-container *ngIf="literal.show_typeahead">
          <span contenteditable="false" class="typeheadp" >@
            <input #typeahead [(ngModel)]="token"
               typeaheadWaitMs="800"
               [typeahead]="data_source"
               [typeaheadAsync]="true"
               (input)="literal.type_text='@'+token"
               autocomplete="off"
               (typeaheadOnSelect)="typeaheadOnSelect($event,literal,i+1)"
               [typeaheadOptionsLimit]="20"
               [typeaheadOptionField]="'name'"
              [typeaheadScrollable]="true"
              [typeaheadOptionsInScrollableView]="10"
              (keydown.enter)="typeheadEnter($event)"
               (keydown)="checkRemoval($event,literal,i)"
               style="border:0;"
               class="">
          </span>
         </ng-container>
      </span>
      <span contenteditable="false" *ngIf="!literal.freetext" (click)="name_clicked(i)" [class.bg-grey]="literal.contenteditable" (paste)="onPaste($event)">
        <span class="tagged_user" name="tagged-user" [attr.contenteditable]="literal.contenteditable"
              #literal_ref (keydown)="stopEditing($event)"  (blur)="blurLiteral(i)"
              (keydown.delete)="onBackSpaceDown($event, i)"
              (keydown.backspace)="onBackSpaceDown($event, i)">
          @{{literal.taggable.name}}
        </span>
      </span>
      </ng-container>
  </span>
</span>
