<div class="row">
  <div class="col-xs-9"><h3 class="m0">Staff</h3></div>
  <div class="col-xs-3 text-right">
    <button *userCan="practice_permissions" class="btn btn-inverse" (click)="toggle_contact()">Add Contact</button>
  </div>
</div>
<hr class="hr-line mt mb">

<div *ngIf="staffs">
  <app-contact [do_show]="show_contact"
               [staff]="(selected_index || selected_index == 0)  ? staffs[selected_index] : new_staff"
               [practice_id]="practice.id" (sync_staff)="sync_staff($event)"
               (reset_do_show)="reset_show_contact($event)">
  </app-contact>
</div>

<ul class="todaysPatient-list p0" *ngIf="staffs">
  <li class="list clearfix pl0" *ngFor="let staff of staffs;let i = index">
    <div class="col-xs-4" permittedClass [condition]="practice_permissions">
      <div class="media">
        <div class="media-left">
          <img class="img-rounded thumbnail thumb64" alt="Image"
               src="{{staff.user.profile_pic}}">
        </div>
        <div class="media-body media-middle">
          <h4 class="mb mt0 fw-500"><a *userCan="practice_permissions" (click)="select_contact(i)">{{staff.name}}</a></h4>
          <h5 class="m0 mt">{{staff.designation}}</h5>
        </div>
      </div>
    </div>
    <div class="col-xs-3">
      <h5 class="hidden" *ngIf="staff.contact_numbers[1] && staff.contact_numbers[1].value">
        <a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(staff.contact_numbers[1].value,
                  {name: staff.name, pic: staff.user.profile_pic})">
          <em class="fa fa-fax mr-sm vl-m"></em> {{staff.contact_numbers[1].value}}
        </a>
      </h5>
      <h5 *ngIf="staff.contact_numbers[0] && staff.contact_numbers[0].value">
        <a href="javascript:void(0);" (click)="phoneService.initiatePhoneCall(staff.phone.value,
                  {name: staff.name, pic: staff.user.profile_pic})">
          <em class="fa icon-phone mr-sm vl-m"></em> {{staff.phone.value | mask: ' (000) 000-0000'}}
        </a>
      </h5>
      <h5 class="m0 mt"><em class="fa icon-envelope mr-sm"></em> {{staff.email}}</h5>
    </div>
    <div class="col-xs-3">
      <div class="media">
        <div class="media-left"><em class="fa fa-map-marker mr-sm"></em></div>
        <div class="media-body">{{staff.address.line_1_line_2()}}<br>{{staff.address.city_state_zip()}}</div>
      </div>
    </div>
    <div class="col-xs-2 text-right">
      <a href="javascript:void(0)" class="btn btn-primary btn-outline mr" *userCan="practice_permissions" (click)="select_contact(i)">
        <i class="fa fa-pencil"></i>
      </a>
      <a href="javascript:void(0)" class="btn btn-danger btn-outline" *userCan="practice_permissions" (click)="classicModal.show();">
        <i class="fa fa-trash"></i>
      </a>
    </div>
    <div class="modal fade" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
      <div class="modal-dialog modal-md" style="height:200px;">
        <div class="modal-content">
          <div class="modal-header">
            <button class="close" type="button" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
            <h4 class="modal-title">Delete Staff</h4>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger p m0">Are You Sure?</div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" (click)="delete(staff);">Yes</button>
            <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">No</button>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
