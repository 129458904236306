import {Patient} from '../../../../health/doctor/patient';
import {BaseModel} from '../../../../shared/base';
import {Comment} from '../comment';
export class Task extends BaseModel {
  id: number;
  current_user_id: number;
  assigned_to: number;
  assigned_to_name = '';
  assigned_by_name = '';
  assigned_by: number;
  patient_id: number;
  patient: Patient = new Patient();
  priority = 'Medium';
  due_date: any;
  status_id = 'Not Started';
  task_name: string;
  task_description: string;
  comments: Comment[] = [];
  source_id: any;
  source_type: any;
  created_at: any;
  comment_count: any;
  temp_activity_id: any;
  care_plan_name = 'Custom';
  care_plan_id: string;
  notify: boolean;
  notify_date: any;
  notify_time: any;
  after_due_date_color: string;
  load_from_json(json) {
    this.current_user_id = json.current_user_id;
    this.care_plan_id = json.care_plan_id;
    this.care_plan_name = json.care_plan_name;
    this.id = json.id;
    this.assigned_to = json.assigned_to;
    this.assigned_by_name = json.assigned_by_name;
    this.assigned_by = json.assigned_by;
    this.patient_id = json.patient_id;
    this.created_at = json.created_at;
    this.comment_count = json.comment_count;
    if (json.patient) {
      this.patient = this.patient.load_from_json(json.patient);
    }
    this.priority = json.priority;
    this.assigned_to_name =  json.assigned_to_name;
    this.status_id = json.status_id.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
    this.task_description = json.task_description;
    this.task_name = json.task_name;
    this.due_date = new Date(json.due_date);
    this.source_id = json.source_id;
    this.source_type = json.source_type;
    this.notify = json.notify;
    if (json.notify_date) {
      this.setNotifyDate(json.notify_date);
    }
    this.notify_time = json.notify_time;
    this.after_due_date_color = json.after_due_date_color;
    return this;
  }

  to_params() {
    return {
      task: {
        id: this.id,
        task_name: this.task_name,
        care_plan_name: this.care_plan_name,
        care_plan_id: this.care_plan_id,
        assigned_to: this.assigned_to,
        patient_id: this.patient_id,
        task_description: this.task_description,
        due_date: this.due_date,
        status_id: this.status_id.replace(/ /g, '_').toLowerCase(),
        priority: this.priority,
        source_id: this.source_id,
        source_type: this.source_type,
        notify: this.notify,
        notify_time: this.notify_time,
        notify_date: this.notify_date
      }
    }
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
  get_url(): string {
    return this.is_new_record() ? 'tasks/' : ['tasks', this.id].join('/')
  }

  clone(): Task {
    return Object.assign(new Task(), this);
  }

  private setNotifyDate(notify_date) {
    const notifyDateStr = notify_date;
    if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(notifyDateStr)) {
      // Input date contains time component (e.g., "2023-08-24T07:36:19.000-04:00")
      return this.notify_date = new Date(notifyDateStr);
    } else {
      // Input date is without time component (e.g., "2023-08-24")
      const dateParts = notifyDateStr.split('-');
      return this.notify_date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    }

  }
}
