import {Component, Injector, OnInit} from '@angular/core';
import {Reminder} from "../reminder";
import {ReminderTemplate} from "../reminder-template";
import {RemindersService} from "../reminders.service";
import {GlobalErrorHandlerService} from "../../services/global-error.service";
import {TabsService} from "../../services/tabs.service";
import {LoaderService} from "../../services/loader.service";

@Component({
  selector: 'app-edit-reminder',
  templateUrl: './edit-reminder.component.html',
  styleUrls: ['./edit-reminder.component.scss']
})
export class EditReminderComponent implements OnInit {
  reminder : Reminder;
  reminder_template : ReminderTemplate;
  editReminder : boolean = false;

  constructor(public injector : Injector, public loaderService: LoaderService, public tabs_service: TabsService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public reminder_template_servie: RemindersService,) {
    this.reminder_template = this.injector.get('reminder', null);
  }
  ngOnInit(): void {
  }
  capitalize(value : string){
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  updateReminder(reminder){
    this.reminder = reminder
    this.editReminder =! this.editReminder
  }

  closeNewForm(event) {
    this.editReminder =! this.editReminder
  }

  deleteTemplate(template) {
    this.loaderService.show()
    this.reminder_template_servie.delete(template.id).then(resp => {
      this.reminder_template_servie.reloadReminder.next(1);
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

}
