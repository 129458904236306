<div class="flex-control flex-column flex-height">
  <div class="flex-header text-center">
    <button class="btn btn-secondary btn-min mr-sm" (click)="generatePdf('print')">Print</button>
    <button class="btn btn-tertiary btn-outline btn-min" (click)="generatePdf('save')">Download</button>
  </div>
  <div class="flex-body scroll-browser">
    <div *ngIf="appointment" class="main-wrapper print-wrapper fs12 text-black" style="max-width:3in; margin:auto;" id="print" #print>
      <div class="print-header text-center mb-sm">
        <h4 class="m0">{{payment_info.practice_name }}</h4>
        <p class="m0">{{payment_info.practice_address}}</p>
        <p class="m0"> Tel: {{payment_info.practice_phone | mask: '(000) 000-0000'}}</p>
      </div>
      <table class="table">
        <tbody>
        <tr>
          <td style="max-width:50px; border:0; padding: 10px 15px 0;">Patient:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>{{appointment.patient.name}}</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">Age:</td>
          <td style="border:0; padding: 10px 15px 0; "><b>{{appointment.patient.age}}, {{appointment.patient.gender}}</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">Phone:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>{{appointment.patient.phone | mask: '(000) 000-0000'}}</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">MRN:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>{{payment_info.patient_mrn}}</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">Consultant:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>{{appointment.doctor.prefix}} {{appointment.doctor.name}}</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">Date/Time:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>{{today | date: 'd/M/y,  h:mm a'}}</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">Bill Date:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>{{today | date: 'd/M/y' }}</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">Receipt#:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>{{payment.id}}</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">Paid:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>Cash</b></td>
        </tr>
        <tr>
          <td style="border:0; padding: 10px 15px 0;">Amount:</td>
          <td style="border:0; padding: 10px 15px 0;"><b>US$. <span *ngIf="payment.amount">{{payment.amount}}</span><span *ngIf="!payment.amount">0</span>/-</b></td>
        </tr>
        </tbody>
      </table>
      <h5 class="fw-500 mt-xxl">Signature ---------------------------------------</h5>
      <h6 class="text-center mt-xl">Printed on {{today | date: 'EEEE, MMMM d, y'}} by www.wellwink.com</h6>
    </div>
  </div>
</div>
