import { Component, OnChanges, Input, Output, OnInit } from '@angular/core';
import { Staff } from './contact';
import { Practice } from '../practices/practice';
import { ContactService } from '../../../services/contact.service'
import { SimpleChanges } from '@angular/core';
import {PhoneService} from '../../../voice-call/phone.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  public staffs = Array<Staff>();
  @Input() practice: Practice;
  @Input() practice_permissions: string;
  public selected_index: number;
  show_contact = false;
  public  pencil: boolean;
  public dom_count: number;
  public edit_contact: boolean;
  public new_staff: Staff = new Staff()
  constructor(private contactService: ContactService, public phoneService: PhoneService) {
  }

  ngOnInit() {
    console.log('staffs', this.staffs)
      this.contactService.getContacts(this.practice.id).then(contacts => {
        for (let contact of contacts) {
          this.staffs.push(new Staff().load_from_jason(contact));

        }
      })
  }
  show_pencil(index) {
    this.dom_count = index;
    this.pencil = true;

  }
  hide_pencil() {
    this.pencil = false;
  }
  toggle_contact() {
    if (!(this.selected_index || this.selected_index == 0) && this.show_contact) {
      this.new_staff = new Staff();
      this.hide_form();
    } else {
      this.selected_index = null;
      this.show_form();
    }
  }
  sync_staff(event) {
    this.staffs.push(event.load_from_jason(event));
    this.practice.staffs = this.staffs;
    this.hide_form();
    this.new_staff = new Staff();
  }
  select_contact(index) {
    this.selected_index = index;
    this.show_form();
  }
  hide_form() {
    this.show_contact = false;
  }
  show_form() {
    this.show_contact = true;
  }
  reset_show_contact(event) {
    this.hide_form();
    this.new_staff = new Staff();
  }
  editContact() {
    this.edit_contact = !this.edit_contact;
  }
  delete(staff: Staff) {
      this.contactService.delete(staff.id).then(response => {
        if (response.status) {
        this.staffs.splice(this.staffs.indexOf(staff), 1)
        }
        this.practice.staffs = this.staffs;
      }
      )
  }
}

