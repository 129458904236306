import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {Goal} from './goal';
import {Subject} from 'rxjs';
import {GoalResponse} from './enrolled-patient/enrolled-patient-right-panel/enrolled-patient-plan/enrolled-patient-plan.component';

@Injectable({
  providedIn: 'root'
})
export class GoalService {
  reloadGoal: Subject<any> = new Subject<any>();

  constructor(private _tokenService: CustomHttpClient) { }

  getEnrolmentGoals(id: any): Promise<GoalResponse> {
    let params: HttpParams = new HttpParams();
    params = params.set('id', id);
    return this._tokenService.get('enrolments/goals', {params: params})
      .toPromise()
      .then(response => response);
  }
  updateProgressOfGoal(goal: Goal): Promise<any> {
    return this._tokenService
      .put('enrolments/goal', goal.to_params())
      .toPromise()
      .then(res => res)
  }
  create(goal: Goal): Promise<any> {
    return this._tokenService
      .post('enrolments/goal', goal.to_params())
      .toPromise()
      .then(response => response)
  }
}
