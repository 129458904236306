import {Component, OnInit, Output, Input, EventEmitter, OnDestroy} from '@angular/core';
import {Task} from '../newtask/task';
import {Tab} from '../../../../tabs/tab';
import {TabsService} from '../../../../services/tabs.service';
import {EdittaskComponent} from '../edittask/edittask.component';
import {User} from '../../../users/user';
import {UserService} from '../../../../services/user.service';
import {MessageService} from '../../../../services/message.service';
import {ChatComponent} from '../../chat/chat.component';
import {TaskService} from '../../../../services/task.service';
import {Subscription} from 'rxjs/index';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'app-taskslisting',
  templateUrl: './taskslisting.component.html',
  styleUrls: ['./taskslisting.component.scss']
})
export class TaskslistingComponent implements OnInit, OnDestroy {

  @Input() patient;
  @Input() isPatientProfile;
  @Input() isProgramDashboard : boolean;
  @Input() isPatientDetailPage;
  @Input() task: Task;
  @Output() update = new EventEmitter<Task>();
  c_user: User;
  public reloadTaskObjectSubject: Subscription;
  constructor(public taskService: TaskService, public _message_Service: MessageService, public tabs_service: TabsService, private userService: UserService) {
    this.reloadTaskObjectSubject = this.taskService.reloadTaskObjectListener.subscribe( resp => {
      if (this.task.id === resp.id) {
        this.task = this.taskService.taskBeforeEdit.clone();
      }
    })
  }

  ngOnInit() {
    this.c_user = this.userService.current_user;
  }
  updateTask(task) {
    this.update.emit(task);
    this.taskService.reloadTasks.next(task);
  }
  edit_Task(task) {
    let task1 = task;
    this.tabs_service.add_tab(new Tab({title: 'Edit Task', icon : 'fa', autorefresh: false}, EdittaskComponent, this.task.id, {id: this.task.id, task: task1}))
  }
  markCompleted() {
    this.task.status_id = 'Completed';
    this.taskService.save(this.task).then(resp => {
      this.taskService.reloadTasks.next(this.task);
    })
  }
  send_message(task) {
    let id;
    if (this.task.assigned_by == this.c_user.id ) {
      id = this.task.assigned_to;
    }
    if (this.task.assigned_to == this.c_user.id) {
      id = this.task.assigned_by;
    }
    this.tabs_service.add_tab(new Tab({title: 'Chat', icon: 'fa'}, ChatComponent , this.task.id, {id: id }))
  }
  ngOnDestroy(): void {
    this.reloadTaskObjectSubject.unsubscribe();
  }
}
