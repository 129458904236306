import {Component, Injector, OnInit} from '@angular/core';
import {Program} from '../program';
import {LoaderService} from '../../services/loader.service';
import {ProgramService} from '../program.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';

@Component({
  selector: 'app-program-enrolled-detail',
  templateUrl: './program-enrolled-detail.component.html',
  styleUrls: ['./program-enrolled-detail.component.scss']
})
export class ProgramEnrolledDetailComponent implements OnInit {
  public program: Program;
  public is_eligible_patient = false;
  public program_id: any;

  constructor(public injector: Injector, public loaderService: LoaderService,
              public programService: ProgramService, public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    this.program = this.injector.get('program', null);
    this.is_eligible_patient = this.injector.get('is_eligible_patient', false);
    this.program_id = this.injector.get('program_id', null);
    if (this.is_eligible_patient) {
      this.getProgram(this.program_id);
    }
  }

  getProgram(id: any) {
    this.loaderService.show();
    this.programService.getProgram(id).then(
      resp => {
        this.program = new Program().load_from_json(resp);
        this.loaderService.hide();
      }
    ).catch(resp => {
      this.globalErrorHandlerService.error = resp.error.error;
      this.loaderService.hide();
      console.log(resp)
    })
  }



}
