import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Case} from '../../case';
import {TabsService} from '../../../../services/tabs.service';
import {NotesComponent} from '../../notes/notes.component';
import {Tab} from '../../../../tabs/tab';
import {PaymentComponent} from '../../payment/payment.component';
import {PatientProfileComponent} from '../../../../health/patient-profile/patient-profile.component';
import {DischargeComponent} from '../discharge/discharge.component';
import {CaseService} from '../../../../services/case.service';
import {ScanComponent} from '../../todays-patients/status-actions/scan/scan.component';
import {CaseFeedback} from '../discharge/discharge_feedback';
import {UserService} from '../../../../services/user.service';
import {ScanService} from '../../../../services/scan.service';
import {AdmitComponent} from '../../admit/admit.component';
import {VitalComponent} from '../../vital/vital.component';

@Component({
  selector: 'app-admissions-listing',
  templateUrl: './admissions-listing.component.html',
  styleUrls: ['./admissions-listing.component.scss']
})
export class AdmissionsListingComponent implements OnInit {
  @Input() admission: Case;
  @Input() view: string;
  @Output() openNote = new EventEmitter<any>();
  public color_code: string;
  public pending_pages: any = '';
  constructor(public scan_service: ScanService, private tabs_service: TabsService, public _userService: UserService) { }
  ngOnInit() {
    if (this.admission && this.admission.id) {
      this.scan_service.get_waiting(this.admission).then(response => {
        this.pending_pages = response;
        this.scan_service.set_data(this.pending_pages.length, this.admission.id);
      });
    }
  }
  add_to_tabs() {
    let title = this.admission.patient_name() + '-' + 'Notes' ;
    let admission_id = this.admission.id;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, NotesComponent, admission_id, {id: this.admission.id}))
  }
  add_payment_to_tabs() {
    let title = this.admission.patient_name() + '-' + 'Payment' ;
    let admission_id = this.admission.id;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PaymentComponent, admission_id, {id: this.admission.id, paymentFor: 'Case'}))
  }
  add_vital_to_tabs(title) {
    let appointment_id = this.admission.appointment_id + title;
    this.tabs_service.add_tab(new Tab({title: this.admission.patient_name() + '-' + title, icon: 'fa fa-hospital', autorefresh: false}, VitalComponent, appointment_id, {id: appointment_id}))
  }
  add_upload_to_tabs(title) {
    this.tabs_service.add_tab(new Tab({title: this.admission.appointment.patient_name() + '-' + title, icon: 'fa fa-hospital'}, ScanComponent, this.admission.id, {id: this.admission.id, admission: this.admission}))
  }
  add_to_tabs_patient_profile(appointment) {
    let title = appointment.patient_name() + '-' + 'Profile' ;
    let id = appointment.patient_id;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id, {id: id}))
  }
  add_discharge_to_tabs() {
    let caseFeedback: CaseFeedback = new CaseFeedback();
    let title = this.admission.patient_name() + '-' + 'Discharge' ;
    let admission_id = this.admission.id;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, DischargeComponent, admission_id, {id: this.admission.id, caseFeedback: caseFeedback}))
  }
  wait_class(ticker) {
    let waiting_time = Number(Math.ceil(ticker / 6));
    if (ticker > 30) {
      return '#ff0000';
    }
    let val = {0: '#008000' , 1: '#008000', 2: '#e5e500' , 3: '#FF8C00', 4: '#ff0000', 5: '#ff0000'}
    let color_val = val[waiting_time];
    this.color_code = color_val;
    return this.color_code;
  }
  editAdmission() {
      this.tabs_service.add_tab(new Tab({title: this.admission.patient_name() + '-' + 'Admission', icon: 'fa fa-hospital'}, AdmitComponent, this.admission.id, {id: this.admission.appoint_id, appointment: this.admission.appointment, case: this.admission}))
  }
}
