export class Consent {
  id: any;
  consent_text: string;
  consentable_id: any;
  consentable_type: string;
  programme_type: string;
  created_at: any;
  created_by: string;
  updated_by: string;
  updated_at: any;
  last_updated_at: any;
  signed_at_time: any;
  signed_at_date: any;
  created_by_id: any;
  updated_by_id: any;
  signer_name: any;
  signed_by_id: any;
  consent_from: any;

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

  to_params() {
    return {
      consent: {
        id: this.id,
        consent_text: this.consent_text,
        programme_type: this.programme_type,
        consentable_id: this.consentable_id,
        consentable_type: this.consentable_type,
        created_by_id: this.created_by_id,
        updated_by_id: this.updated_by_id,
        signed_by_id: this.signed_by_id,
        consent_from: this.consent_from,
        signed_at_date: this.signed_at_date,
        signed_at_time: this.signed_at_time
      }
    }
  }

  load_from_json(json: any) {
    this.id = json.id;
    this.consent_text = json.consent_text;
    this.updated_at = json.updated_at;
    this.updated_by = json.updater_name;
    this.created_by = json.creator_name;
    this.created_at = json.created_at;
    this.signer_name = json.signer_name;
    this.programme_type = json.programme_type;
    this.consentable_type = json.consentable_type;
    this.consentable_id = json.consentable_id;
    this.created_by_id = json.created_by_id;
    this.updated_by_id = json.updated_by_id;
    this.signed_by_id = json.signed_by_id;
    this.consent_from = json.consent_from;
    this.signed_at_time = json.signed_at_time;
    this.signed_at_date = json.signed_at_date;
    return this;
  }

}
