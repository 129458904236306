import {Patient} from '../../../health/doctor/patient';
import {SMSMessage} from './sms';

export class Conversation {
  id: number;
  page_loaded = 1;
  patient: Patient;
  sms_messages: SMSMessage[] = [];
  last_sms: SMSMessage;
  name: string;
  title: any;
  skip_previous = false;
  patient_id: any;
  practice_id: any;
  patient_phone: any;
  status = 'read';
  unread_count: number;
  last_unread_count: number;
  thumbnail: any;
  all_loaded = false;
  load_in_progress = false;
  sms_message: SMSMessage = new SMSMessage();
  owner_id: any;

  physical_exist() {
    if (this.id) {
      return true;
    }
    return false;
  }

  last_sms_id() {
    if (this.last_sms && this.sms_messages[0].id != this.last_sms.id) {
      return this.last_sms && this.last_sms.id;
    }
    if (this.last_sms && this.sms_messages.length > 1) {
      return this.sms_messages[this.sms_messages.length - 2].id
    }
  }

  get lastReadMessageIndex() {
    if (!this.last_unread_count) {
      return Math.max(this.sms_messages.length - 1, 0);
    }
    return Math.max(this.sms_messages.length - this.last_unread_count - 1, 0);
  }

  to_params() {
    return {
      conversation: {
        id: this.id,
        patient_id: this.patient_id,
        practice_id: this.practice_id,
        patient_phone: this.patient_phone,
        status: this.status,
        sms_messages_attributes: this.sms_messages.map(sms => sms.to_params())
      }
    }
  }

  loadNewMessages(messages, pop_last?) {
    if (pop_last && this.sms_messages) {
      this.sms_messages.pop();
    }
    messages.forEach(message => this.push_message(new SMSMessage().load_from_json(message), true));
    return this;
  }

  loadPaginatedMessages(messages, headers?) {
    this.page_loaded += 1;
    console.log(headers);
    this.all_loaded = (!messages || messages.length == 0);
    this.load_in_progress = false;
    messages && messages.forEach(m => this.shift_message(new SMSMessage().load_from_json(m)));
  }

  shift_message(message) {
    this.sms_messages.unshift(message);
  }

  push_message(message: SMSMessage, ignoreOrderChanged?) {
    if (this.sms_messages.every((msg) => msg.id !== message.id)) {
      this.sms_messages.push(message);
    }
    this.last_sms = message;
  }

  setNewConversationAndMesssage(user, reciever_id, message, sms_type?) {
    this.sms_messages = [];
    this.practice_id = user.practices_id[0];
    this.patient_id = reciever_id;
    return this.create_conversation_message(user.id, message, sms_type)
  }

  create_conversation_message(owner_id, message, sms_type?) {
    this.sms_message.body = message;
    this.sms_message.sms_type = sms_type;
    this.sms_message.owner_id = owner_id;
    this.sms_message.status = 'read';
    return this.sms_message;
  }

  load_from_json(json, pop_last?) {
    this.id = json.id;
    this.unread_count = json.unread_count;
    this.last_unread_count = json.unread_count;
    this.practice_id = json.practice_id;
    this.title = json.title;
    this.owner_id = json.owner_id;
    if (json.patient) {
      this.patient = new Patient().load_from_json(json.patient);
      this.patient_id = this.patient.id;
      this.thumbnail = this.patient.profile_pic;
    }
    if (json.status) {
      this.status = json.status;
    }
    if (!this.sms_messages) {
      this.sms_messages = [];
    }
    if (json.sms_messages) {
      this.loadNewMessages(json.sms_messages, pop_last)
    }
    if (json.last_sms && json.sms_messages.length <= 0) {
      this.push_message(new SMSMessage().load_from_json(json.last_sms));
    }
    else {
      this.last_sms =  this.sms_messages[Math.max(this.sms_messages.length - 1, 0)];
    }
    return this;
  }




}
