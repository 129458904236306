import {TimeLog} from './time-log';

export class Activity {
  id: number;
  source_type: string;
  source_id: number;
  activiable_type: string;
  activiable_id: number;
  activity_type: string;
  description: any;
  created_at: any;
  updated_at: any;
  actor_id: number;
  actor_name: string;
  minutes: any;
  time_log_attributes: TimeLog = new TimeLog();
  c_type: string;

  load_from_json(json) {
    this.id = json.id;
    this.c_type = json.c_type;
    this.source_type = json.source_type;
    this.source_id = json.source_id;
    this.activiable_type = json.activiable_type;
    this.activiable_id = json.activiable_id;
    this.activity_type = json.activity_type;
    this.description = json.description;
    this.created_at = json.created_at;
    this.actor_id = json.actor_id;
    this.updated_at = json.updated_at;
    this.actor_name = json.actor_name;
    this.minutes = json.minutes;
    if (json.time_log) {
      this.time_log_attributes = this.time_log_attributes.load_from_json(json.time_log);
    }
    return this
  }

  to_params() {
    return {
      activity: {
        id: this.id,
        source_type: this.source_type,
        source_id: this.source_id,
        activiable_type: this.activiable_type,
        activiable_id: this.activiable_id,
        activity_type: this.activity_type,
        description: this.description,
        actor_id: this.actor_id,
        time_log_attributes: this.time_log_attributes,
      }
    }
  }
}
