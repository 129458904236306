import { Injectable }     from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {BlockCalendar} from '../routes/practices/doctors/doctor';
import {CustomHttpClient} from './custom-http-client.service';


@Injectable()
export class BlockCalendarService {
  constructor(private _tokenService: CustomHttpClient) {}

  create(block_calendar: BlockCalendar): Promise<any> {
    return this._tokenService
      .post('block_calendars/', block_calendar.to_params())
      .toPromise()
      .then(res => res)
  }

  update(block_calendar: BlockCalendar): Promise<any> {
    return this._tokenService
      .put('block_calendars/update_block_calendar', block_calendar.to_params())
      .toPromise()
      .then(res => res)
  }

  save(block_calendar: BlockCalendar): Promise<any> {
    if (block_calendar.is_new_record()) {
      return this.create(block_calendar);
    }
    else {
      return this.update(block_calendar);
    }
  }

  delete_block_calendar(block_calendar: BlockCalendar) {
    if (block_calendar) {
      let args = new HttpParams();
      args = args.set('id', block_calendar.id.toString());
      return this._tokenService
        .get('block_calendars/delete_block', {params: args})
        .toPromise()
        .then(res => res)
    }
    return;
  }
}
