import {Component, OnInit} from '@angular/core';
import {TabsService} from '../../services/tabs.service';

@Component({
  selector: 'app-intake-form-template-index',
  templateUrl: './intake-form-template-index.component.html',
  styleUrls: ['./intake-form-template-index.component.scss']
})
export class IntakeFormTemplateIndexComponent implements OnInit {

  constructor(public tabsService: TabsService) {
    this.tabsService.register('Intake Form Template');
  }

  ngOnInit(): void {
  }
}
