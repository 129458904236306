import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MedicalCenter} from './medical-center';
import {MedicalCentersComponent} from './medical-centers/medical-centers.component';

@Component({
  selector: 'app-patient-medical-center',
  templateUrl: './patient-medical-center.component.html',
  styleUrls: ['./patient-medical-center.component.scss']
})
export class PatientMedicalCenterComponent implements OnInit {
  public medicalCenter: MedicalCenter = new MedicalCenter();
  showAddMedicalCenterForm = false;
  @Input() patient_id: any;
  @Input() intakeReport;
  @ViewChild('medicalCentersComponent', {static: false}) medicalCentersComponent: MedicalCentersComponent;

  constructor() { }

  ngOnInit(): void {
  }

  addMedicalCenter() {
    this.medicalCenter = new MedicalCenter();
    this.medicalCenter.patient_id = this.patient_id;
    this.showAddMedicalCenterForm = true;
  }

  closeMedicalCenterForm(action: any) {
    if (action === 'get') {
      this.medicalCentersComponent.getMedicalCenters();
    }
    this.showAddMedicalCenterForm = false;
  }

  editMedicalCenter(medicalCenter: MedicalCenter) {
    this.medicalCenter = new MedicalCenter().load_from_json(medicalCenter);
    this.showAddMedicalCenterForm = true;
  }
}
