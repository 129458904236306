import './app/core/preloader/preloader.ts';
import 'zone.js'
import '@angular/compiler';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import {environment} from './environments/environment';
import {AppModule} from './app';

if (environment.production) {
  console.log('prod enabled');
     enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
     .then(() => { (<any>window)?.appBootstrap(); })
     .catch(err => console.error('bootstrap error: ', err));
