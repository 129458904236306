<div class="flex-header filters-sec" *ngIf="appointments">
  <a class="btn btn-primary btn-outline btn-filter mb" data-toggle="collapse" href="#collapsefilters"><i class="fa fa-filter mr-sm"></i> Filters</a>
  <div class="collapse" id="collapsefilters">
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-6" *ngIf="!providers">
        <ng-select  required
                   #practiceInput
                   allowClear="true"
                    [items]="practices"
                   (change)="selectedPractice($event)"
                    (search)="typedPractice($event)"
                   (remove)="removePractice($event)"
                   placeholder="Practices"></ng-select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-6" *ngIf="!providers">
        <ng-select  required
                    #doctorInput
                     [items]="doctors"
                    (change)="selectedDoctor($event)"
                    (search)="typedDoctor($event)"
                    allowClear="true"
                    (remove)="removeDoctor($event)"
                    placeholder="Provider"></ng-select>
      </div>
      <ng-container *ngIf="!userService.current_user.isDoctor">
        <div class="col-lg-2 col-md-2 col-sm-6" *ngIf="providers">
          <select #provider class="form-control" (change)='change_provider(provider.value)'>
            <option value="0">Providers</option>
            <option *ngFor="let provider of providers" [value]="provider.id">
              {{provider.firstname}} {{provider.lastname}}
            </option>
          </select>
        </div>
      </ng-container>
      <ng-container *ngIf="userService.current_user.isDoctor">
        <div class="col-lg-2 col-md-2 col-sm-6" *ngIf="locations">
          <select #location class="form-control" (change)='change_location(location.value)'>
            <option value="0">Locations</option>
            <option *ngFor="let location of locations" [value]="location.id">{{location.name}}</option>
          </select>
        </div>
      </ng-container>
      <div class="col-lg-2 col-md-2 col-sm-6">
        <select #appointmentStatus class="form-control" (change)='selectStatus(appointmentStatus)'>
          <option selected="selected" value="0">Pending</option>
          <option *ngFor="let status of status_array; let i = index;" value={{i+2}}>{{status[i+2]}}</option>
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-6">
        <input type="text"
               autocomplete="off"
               name="from"
               [(ngModel)]="filter.date"
               placeholder="Requested On"
               class="form-control"
               today-highlight="true"
               [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }"
               bsDatepicker>
      </div>
      <div class="col-md-1 col-sm-12">
        <button class="btn btn-tertiary btn-outline mr" (click)="filterAppointments()"><i class="fa fa-filter fa-fw"></i> Filter</button>
      </div>
      <div class="col-md-2 col-sm-12 pull-right text-right">
        <ng-container *ngIf="appointments.length > 0 && appointments[0].status_id == 'pending'">
          <button *userCan="(userService.current_user.isPractice || userService.current_user.isDoctor) ? 'approvereject,appointments,reception': 'approvereject,appointments,online'"
                  class="btn btn-secondary" (click)="classicModal.show();">
            Acknowledge All
          </button>
        </ng-container>
      </div>
  </div>
</div>
</div>
<app-appointments class="flex-body" [providers]="providers" (page) = pageChanged($event) [totalItems]='totalItems' [appointments]="appointments"></app-appointments>
<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div class="modal-dialog modal-md" style="height:202px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Acknowledge all</h4>
      </div>
      <div class="modal-body">
        <h5 class="text-center mb-xl">Are you sure you want to acknowledge all pending requests?</h5>
      </div>
      <div class="modal-footer">
        <div class="text-center">
          <button type="submit" class="btn btn-primary" (click)="acknowledgeAll(appointments);classicModal.hide();">Yes</button>
          <button type="submit" class="btn btn-inverse" (click)="classicModal.hide();">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
