<nav class="navbar topnavbar">
  <div class="burger-menu" [ngClass]="user_service.showHideSideBar ? 'hidden' : ''">
    <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
    <a trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="isCollapsedText()">
      <em class="icon icon-menu"></em>
    </a>
    <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
    <!--<a class="visible-xs sidebar-toggle" (click)="settings.layout.asideToggled =! settings.layout.asideToggled">-->
    <!--<em class="icon icon-menu"></em>-->
    <!--</a>-->
  </div>
  <div class="navbar-brand hidden-xs">
    <a routerLink="/"><img class="img-responsive" src="assets/img/branding/logo.png" alt="WellWink"/></a>
  </div>
  <ul class="nav navbar-nav right-nav" *ngIf="keys?.length > 0">
    <ng-container *ngFor="let noti_group of keys">
      <ng-container *userCan="noti_group == 'reviews' ? 'view,Dashboard,reviews' : ''">
        <ng-container *ngIf="noti_group === 'tasks'">
          <li dropdown (click)="refreshNotifications()">
            <a dropdownToggle>
              <i [ngClass]="notifications[noti_group].icon"></i>
              <ng-container *ngIf="get_count_for(notifications[noti_group].count) > 0">
                <div class="badge badge-danger">{{get_count_for(notifications[noti_group].count)}}</div>
              </ng-container>
            </a>
            <div class="dropdown-menu animated slideInDown noti-dropdown">
              <h4 class="noti-title fw-300">Notifications</h4>
              <ul class="list-unstyled border-list m0 scroll-browser">
                <ng-container *ngIf="notifications[noti_group]?.push_notifications && notifications[noti_group]?.push_notifications.length > 0; else noNotification">
                  <li class="list" *ngFor="let notification of notifications[noti_group].push_notifications">
                    <a *ngIf="notification" (click)="notificationClick(noti_group, notification)" [routerLink]="notification?.link">
                      <h5 class="m0 mb-sm text-black fw-400">
                        <small class="text-muted fs11 pull-right">{{notification?.notification_time}}</small>
                        {{notification.message}}
                      </h5>
                      <p class="m0 fs12">{{notification.subject}}</p>
                    </a>
                  </li>
                </ng-container>
                <ng-template #noNotification>
                  <li class="list">
                    <h5 class="text-center text-tertiary p-lg">No Notifications</h5>
                  </li>
                </ng-template>
              </ul>
            </div>
          </li>
        </ng-container>
        <li *ngIf="noti_group !== 'tasks'">
          <a [routerLink]="notifications[noti_group].link_to">
            <i (click)="notificationClick(noti_group)" [ngClass]="notifications[noti_group].icon"></i>
            <ng-container *ngIf="get_count_for(notifications[noti_group].count) > 0">
              <div class="badge badge-danger">{{get_count_for(notifications[noti_group].count)}}</div>
            </ng-container>
          </a>
        </li>
      </ng-container>
    </ng-container>
    <li *userCan="'view,sms,home'">
      <a [routerLink]="'/enterprise/home/sms'">
        <i (click)="smsService.unreadSMSCounter()" [ngClass]="'fa icon-bubbles'"></i>
        <ng-container>
          <div *ngIf="smsService.countUnreadConversation() > 0" class="badge badge-danger">{{smsService.countUnreadConversation()}}</div>
        </ng-container>
      </a>
    </li>
    <ng-container *ngIf="user_service.user_logged_in && user_service.current_user">
      <li dropdown>
        <div class="user-info" dropdownToggle>
          <img class="img-circle" alt="user profile picture"
               [src]="user_service.current_user && user_service.current_user.profile_pic ? user_service.current_user.profile_pic : 'assets/img/user/01.jpg'">
          <div class="text">
            <h5
              class="text-capitalize text-tertiary m0">{{user_service.current_user.first_name}} {{user_service.current_user.last_name}}</h5>
            <h6 class="text-capitalize m0 mt-sm fw-400">{{user_service.current_user?.profileable?.designation}}</h6>
          </div>
        </div>
        <ul class="dropdown-menu animated slideInDown">
          <li class="name text-capitalize">{{user_service.current_user.first_name}} {{user_service.current_user.last_name}}</li>
          <ng-container *ngIf="user_service.current_user?.isPracticeUser">
            <li>
              <a [routerLink]="['/enterprise/profile-settings']" [queryParams]="{ settingsOf: 'profile' }"
                 (click)="set_selected_menu('profile-settings')">
                <i class="fa icon-user"></i> My Profile
              </a>
            </li>
            <li>
              <a [routerLink]="'/enterprise/profile-settings'" [queryParams]="{ settingsOf: 'preferences' }"
                 (click)="set_selected_menu('profile-settings')">
                <i class="fa icon-note"></i> User Preferences
              </a>
            </li>
            <li>
              <a (click)="set_selected_menu('profile-settings')" [routerLink]="'/enterprise/profile-settings'" [queryParams]="{ settingsOf: 'change-password' }">
                <i class="fa icon-key"></i> Change Password
              </a>
            </li>
          </ng-container>
          <ng-container *ngIf="user_service.current_user?.isEnterprise">
            <li>
              <a href="javascript:" [routerLink]="'/change-password'">
                <i class="fa icon-key"></i> Change Password
              </a>
            </li>
          </ng-container>
          <li>
            <a (click)="user_service.logout()"><span class="text-danger"><i
              class="fa icon-logout"></i> Log out</span></a>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
  <ul class="nav navbar-nav left-nav" *ngIf="user_service.rerender">
    <li [class.active]="selected_menu == 'home'" *userCan="'view,anything,home,practice,true'">
      <a [routerLink]="permitted_menu_for('home')" *ngIf="permitted_menu_for('home')"
         (click)="set_selected_menu('home')">
        Home
        <em class="icon icon-home"></em>
      </a>
    </li>
    <li [class.active]="selected_menu == 'reception'" *userCan="'view,anything,reception,practice,true'">
      <a [routerLink]="permitted_menu_for('reception')" *ngIf="permitted_menu_for('reception')"
         (click)="set_selected_menu('reception')">
        Reception
        <svg class="icon" viewBox="0 0 512 512">
          <path
            d="m512 198.198c0-27.271-22.187-49.458-49.458-49.458h-33.756v-40.846c0-8.284-6.716-15-15-15s-15 6.716-15 15v40.846h-237.103v-16.876h57.621c8.284 0 15-6.716 15-15v-94.335c0-8.284-6.716-15-15-15h-145.242c-8.284 0-15 6.716-15 15v94.334c0 8.284 6.716 15 15 15h57.621v16.876h-82.225c-27.271.001-49.458 22.188-49.458 49.459 0 22.049 14.505 40.768 34.473 47.131v244.141c0 8.284 6.716 15 15 15h413.055c8.284 0 15-6.716 15-15v-244.141c19.967-6.363 34.472-25.082 34.472-47.131zm-447.527 276.273v-226.815h383.055v226.814h-383.055zm398.069-256.815h-413.084c-10.729 0-19.458-8.729-19.458-19.458s8.729-19.458 19.458-19.458h413.084c10.729 0 19.458 8.729 19.458 19.458s-8.729 19.458-19.458 19.458zm-373.48-180.127h115.242v64.334h-115.242z"/>
          <path
            d="m292.696 343.563h-21.696v-21.696c0-8.284-6.716-15-15-15s-15 6.716-15 15v21.696h-21.696c-8.284 0-15 6.716-15 15s6.716 15 15 15h21.696v21.695c0 8.284 6.716 15 15 15s15-6.716 15-15v-21.695h21.696c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/>
        </svg>
      </a>
    </li>
    <li [class.active]="selected_menu == 'program'" *userCan="'view,anything,care management,practice,true'">
      <a [routerLink]="permitted_menu_for('program')" *ngIf="permitted_menu_for('program')"
         (click)="set_selected_menu('program')">
        Programs
        <em class="icon icon-layers"></em>
      </a>
    </li>
    <li [class.active]="selected_menu == 'patients'" *userCan="'view,anything,patient communication,practice,true'">
      <a [routerLink]="permitted_menu_for('patients')" *ngIf="permitted_menu_for('patients')"
         (click)="set_selected_menu('patients')">
        Patients
        <em class="icon icon-people"></em>
      </a>
    </li>
    <li [class.active]="selected_menu == 'online'" *userCan="'view,anything,online,practice,true'">
      <a [routerLink]="permitted_menu_for('online')" *ngIf="permitted_menu_for('online')"
         (click)="set_selected_menu('online')">
        Online
        <em class="icon icon-feed"></em>
      </a>
    </li>
    <li [class.active]="selected_menu == 'manage'" *userCan="'view,anything,manage,practice,true'">
      <a [routerLink]="permitted_menu_for('manage')" *ngIf="permitted_menu_for('manage')"
         (click)="set_selected_menu('manage')">
        Manage
        <em class="icon icon-briefcase"></em>
      </a>
    </li>
    <li [class.active]="selected_menu == 'reviews'" *userCan="'view,anything,reputation management,practice,true'">
      <a [routerLink]="permitted_menu_for('reviews')" *ngIf="permitted_menu_for('reviews')"
         (click)="set_selected_menu('reviews')">
        Reviews
        <em class="icon icon-star"></em>
      </a>
    </li>
    <li [class.active]="selected_menu == 'settings'" *userCan="'view,anything,settings,practice,true'">
      <a [routerLink]="permitted_menu_for('settings')" *ngIf="permitted_menu_for('settings')"
         (click)="set_selected_menu('settings')">
        Settings
        <em class="icon icon-settings"></em>
      </a>
    </li>
  </ul>
  <ul class="nav navbar-nav hidden">
    <!-- Fullscreen (only desktops)-->
    <li>
      <a #fsbutton (click)="toggleFullScreen($event)"><em class="icon icon-size-fullscreen"></em></a>
    </li>
    <!-- Search icon-->
    <li>
      <a (click)="openNavSearch($event)"><em class="icon icon-magnifier"></em></a>
    </li>
  </ul>
  <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>
</nav>
