<div class="filters-sec flex-header">
  <a class="btn btn-primary btn-outline btn-filter mb" data-toggle="collapse" href="#collapsefilters">
    <i class="fa fa-filter mr-sm"></i> Filters
  </a>
  <div class="collapse" id="collapsefilters">
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <input class="form-control" placeholder="Name" [(ngModel)]="filter['name']" (keyup.enter)="search()" type="text">
      </div>
      <div class="col-md-2 col-sm-6">
        <select [(ngModel)]="filter['status']" class="form-control">
          <option value="">Status</option>
          <option value='running'>Running</option>
          <option value='completed'>Completed</option>
          <option value='draft'>Draft</option>
        </select>
      </div>
      <div class="col-md-3 col-sm-6">
        <button class="btn btn-tertiary btn-outline mr-sm" type="button" (click)="search()"><i class="fa fa-filter fa-fw"></i> Filter</button>
      </div>
      <div class="col-md-3 col-sm-6 pull-right text-right">
        <button class="btn btn-secondary btn-min" type="button" (click)="addTemplate()"><i class="fa fa-plus fa-fw"></i> Add</button>
      </div>
    </div>
  </div>
</div>
<div class="flex-body scroll-browser">
  <table class="table table-hover m0">
    <thead>
    <tr>
      <th>Name</th>
      <th class="text-center">Type of recipients</th>
      <th class="text-center">Recipients</th>
      <ng-container *ngIf="campaign_type === 'email'">
      <th class="text-center">Open</th>
      <th class="text-center">Clicks</th>
      <th class="text-center">Bounced</th>
      </ng-container>
      <ng-container *ngIf="campaign_type === 'sms'">
        <th class="text-center">Delivered</th>
        <th class="text-center">Failed</th>
      </ng-container>
      <th class="text-center">Created</th>
      <th class="text-center">Status</th>
      <th class="text-center">Sent</th>
      <th class="text-center">Action</th>
    </tr>
    </thead>
    <tbody  *ngIf="campaigns_list.length > 0">
      <ng-container *ngFor="let campaign of campaigns_list">
        <tr>
          <td><a (click)="viewEmail(campaign)">{{campaign.name}}</a></td>
          <td class="text-center text-capitalize">{{campaignService.removeUnderScore(campaign.recipient_type)}}</td>
          <td class="text-center">{{campaign.valid_audience}}</td>
          <ng-container *ngIf="campaign_type === 'email'">
            <td class="text-center">
              <h4 class="mt0 mb-sm fs13">{{campaign.open}}</h4>
              <h6 class="m0">{{campaign.open_percentage}}%</h6>
            </td>
            <td class="text-center">
              <h4 class="mt0 mb-sm fs13">{{campaign.clicks}}</h4>
              <h6 class="m0">{{campaign.clicks_percentage}}%</h6>
            </td>
            <td class="text-center">
              <h4 class="mt0 mb-sm fs13">{{campaign.bounced_count}}</h4>
              <h6 class="m0">{{campaign.bounced_percentage}}%</h6>
            </td>
          </ng-container>
          <ng-container *ngIf="campaign_type === 'sms'">
            <td class="text-center ">
              <h4 class="mt0 mb-sm fs13">{{campaign.delivered_count}}</h4>
              <h6 class="m0">{{campaign.delivered_percentage}}%</h6>
            </td>
            <td class="text-center">
              <h4 class="mt0 mb-sm fs13">{{campaign.failed_count}}</h4>
              <h6 class="m0">{{campaign.failed_percentage}}%</h6>
            </td>
          </ng-container>
          <td class="text-center">
            <p class="m0">{{campaign.creator_name}}</p>
            <p class="m0">{{campaign.created_at}}</p>
          </td>
          <td class="text-center text-capitalize">
            <span *ngIf="campaign.status == 'completed'" class="label label-success fs13">{{campaign.status}}</span>
            <span *ngIf="campaign.status == 'running'" class="label label-info fs13">{{campaign.status}}</span>
            <span *ngIf="campaign.status == 'draft'" class="label bg-gray fs13">{{campaign.status}}</span>
          </td>
          <td class="text-center">
            <p class="m0">{{campaign.runner}}</p>
            <p class="m0">{{campaign.run_at}}</p>
          </td>
          <td class="text-center">
            <button *ngIf="campaign.status == 'draft'"  [disabled]="campaign.campaign_body ? false : true" class="btn btn-secondary btn-min btn-sm" type="button" (click)="selected_campaign = campaign; runModal.show()">Run</button>
            <button *ngIf="campaign.status !== 'draft'" class="btn btn-secondary btn-sm btn-outline btn-min" type="button" (click)="emailResult(campaign)">Results</button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <app-no-result-found *ngIf="campaigns_list && campaigns_list.length == 0"></app-no-result-found>
</div>
<div class="flex-footer text-center" *ngIf="campaigns_list && totalItems > 10">
  <pagination class="m0" [totalItems]="totalItems" [maxSize]="3" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
<div class="modal fade" bsModal #runModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="runModal.hide();selected_campaign = undefined"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Run Campaign</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0">Are you sure you want to Run the campaign?</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-secondary" (click)="runCampaign(selected_campaign);runModal.hide();selected_campaign = undefined">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="runModal.hide();selected_campaign = undefined">No</button>
      </div>
    </div>
  </div>
</div>
