<div class="modal flex-modal fade" bsModal #reportModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1">
  <div class="modal-dialog" style="margin:auto; height:557px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="reportModel.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">Print Comprehensive Report</h4>
      </div>
      <div class="modal-body scroll-browser">
        <div class="row form-group">
          <label class="col-sm-2 field-label control-label">Month</label>
          <div class="col-sm-10">
            <input [bsConfig]="{ minMode:'month',dateInputFormat: 'MM-YYYY', clearBtn: true}"
                   [(ngModel)]="selectMonth"
                   (ngModelChange)="setMonthAndYear($event)"
                   [maxDate]="maxDate"
                   bsDatepicker
                   [disabled]="isBilling"
                   class="form-control"
                   placeholder="Month">
          </div>
        </div>

        <h4 class="fw-400 text-primary mb-lg mt-xl">You may also choose</h4>

        <div class="row">
          <div class="col-sm-5">
            <ul class="list-unstyled m0 comprehensive-list">
              <li *ngFor="let checkbox of checkboxes.slice(0, 6)">
                <input
                  [id]="checkbox"
                  class="m0"
                  type="checkbox"
                  [(ngModel)]="report_for[checkbox]"
                  [name]="checkbox"
                  [value]="true"
                />
                <label class="fw-400 m0 ml-sm" [for]="checkbox">{{ userService.removeUnderScore(checkbox) }}</label>
              </li>
            </ul>
          </div>
          <div class="col-sm-7">
            <ul class="list-unstyled m0 comprehensive-list">
              <li *ngFor="let checkbox of checkboxes.slice(6)">
                <input
                  [id]="checkbox"
                  class="m0"
                  type="checkbox"
                  [(ngModel)]="report_for[checkbox]"
                  (change)="changeCheckbox(checkbox, $event)"
                  [name]="checkbox"
                  [value]="true"
                />
                <label class="fw-400 m0 ml-sm" [for]="checkbox">{{ userService.removeUnderScore(checkbox) }}</label>
              </li>
              <li class="comprehensive-careplan-dropdown" *ngIf="showCarePlanDropDown">
                <ss-multiselect-dropdown [options]="care_plan_array"
                                         name="care_teams"
                                         [(ngModel)]="selectedCarePlansIds"
                                         (ngModelChange)="selectedCarePlans($event)"
                                         required>
                </ss-multiselect-dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-secondary mr" (click)="print()" [disabled]="disableButton()">Print</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="reportModel.hide(); resetReport()">Close</button>
      </div>
    </div>
  </div>
</div>
