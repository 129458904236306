import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {Campaign} from '../../campaign';
import {environment} from '../../../../environments/environment';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {LoaderService} from '../../../services/loader.service';
import {AttachmentService} from '../../../services/attachments.service';
import {default as tinymce} from 'tinymce';
import {CampaignService} from '../../campaign.service';
import {TabsService} from '../../../services/tabs.service';
import {RichTextTemplate} from '../../../routes/rich-text-template-index/rich-text-template';
interface Recipient {
  email?: string;
  name?: string;
  contact_type?: string;
  contact_id?: number;
  dob?: string;
  status?: string;
  gender?: string;
  patient_id?: number;
  registered_on?: string;
  web_access?: string;
  location?: string;
  appointments?: number;
  sent_on?: string;
  count?: number;
  phone_number?: string;
  user_name?: string;
  designation?: string;
  role?: string;
  patient_type?: string;
}


@Component({
  selector: 'app-view-email-campaign',
  templateUrl: './view-email-campaign.component.html',
  styleUrls: ['./view-email-campaign.component.scss']
})
export class ViewEmailCampaignComponent implements OnInit {
  campaign: Campaign;
  editCampaign = false;
  show_popup = false;
  @ViewChild('s_body') s_body: ElementRef;

  public audienceList: Recipient[] = [];
  public showAudienceTable  = false;
  public totalItems;
  public filter: Object = {keyword: undefined, page: 1, status: undefined, link: undefined};

  public patient_custom_fields: Array<string> = ['Tags', 'Patient First Name', 'Patient Last Name', 'Patient DOB', 'Patient Gender', 'Patient Birth Day', 'Patient Birth Month', 'Practice Name', 'Practice Full Address',
    'Practice Phone Number', 'Practice Email Disclaimer', 'Sender Prefix', 'Sender First Name', 'Sender Last Name', 'Sender Designation'];
  public staff_and_doctor_custom_fields: Array<string> = ['Tags', 'Prefix', 'First Name', 'Last Name', 'Designation', 'Practice Email Disclaimer', 'Sender Prefix', 'Sender First Name', 'Sender Last Name', 'Sender Designation'];

  show_attachment_popup = false;
  public image_url: any;
  public editor: any;
  public attachment_ids: any[] = [];
  public testEmailsOrSms: any[] = [];
  public temptestEmailsOrSms;

  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 300,
    relative_urls : false,
    remove_script_host : false,
    convert_urls : true,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
    'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
    'insertfile undo redo numlist outdent | bullist indent |contextmenu table | uploadimage media | \ ' +
    'link image forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.editor = editor;
    },

    images_upload_handler:
      (data, success, failure) => {
        if (data && data.hasOwnProperty('error') == false) {
          this.loaderService.small_loder_show();
          let attachment = { attachable_id: this.campaign.id, attachable_type: 'CampaignTemplate',
            fileType: data.blob().type, file: data.blob(), description: data.filename()};
          let callback = (p: any): void => {
            console.log(p);
          };

          let upload_callback = (data: any) => {
            data = JSON.parse(data);
            if (data !== '') {
              this.loaderService.small_loder_hide();
              this.image_url =  data.url;
              success(data.url);
            }
          };
          if (attachment.file === undefined) {
            this.globalErrorHandlerService.error = 'Please select a valid file.';
          }
          else {
            let latest_picture = this.attachmentService.upload(attachment, callback, upload_callback);
            console.log(latest_picture);
          }
        }
      },
  }

  constructor(public injector: Injector, public globalErrorHandlerService: GlobalErrorHandlerService,
              private attachmentService: AttachmentService, public campaignService: CampaignService,
              public loaderService: LoaderService, public tabs_service: TabsService ) {
    this.campaign = this.injector.get('campaign', null);
    console.log(this.campaign)
  }

  ngOnInit(): void {  }

  updateBody() {
    this.loaderService.small_loder_show();
    this.campaignService.updateCampaignBody(this.campaign)
      .then(resp => {
        this.campaign = this.campaign.load_from_json(resp)
        this.loaderService.small_loder_hide();
        this.campaignService.reloadCampaigns.next(1);
      })
      .catch(resp => {
        this.loaderService.small_loder_hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
  }

  updateCampaign(campaign) {
    this.campaign = campaign
    this.editCampaign = ! this.editCampaign
  }
  closeNewForm(event) {
    this.editCampaign = ! this.editCampaign
  }
  runCampaign(campaign) {
    this.loaderService.show();
    this.updateBody();
    this.campaignService.runCampaign(campaign.id).then(resp => {
      campaign.load_from_json(resp);
      this.loaderService.hide(); }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  // delete

  deleteTemplate(template) {
    this.loaderService.show()
    this.campaignService.delete(template.id).then(resp => {
      this.campaignService.reloadCampaigns.next(1);
      this.tabs_service.close_tab(this.tabs_service.active_tab());
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  // Test Email and sms

  selectTestEmailOrSms($event) {
    console.log('select method ', $event)
  }
  addTestEmailOrSms($event, campaign) {
    this.testEmailsOrSms.push($event.value)
    console.log('add ', $event)
  }
  removeTestEmailOrSms($event, campaign) {
    this.testEmailsOrSms.splice(this.testEmailsOrSms.indexOf($event), 1)
  }
  sendTestEmailOrSms() {
    this.loaderService.show();
    this.campaignService.testRun(this.campaign.id, this.campaign.outreach_type, this.testEmailsOrSms, this.campaign.campaign_body).then(resp => {
        console.log(resp);
        this.testEmailsOrSms = [];
        this.temptestEmailsOrSms = '';
        this.loaderService.hide();
      }
    ).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.message;
    })
    console.log('Test email sms array', this.testEmailsOrSms)
  }


  // InsertTags

  insertTag(myValue) {
    this.campaign.outreach_type === 'email' ? this.insertAtCursor(myValue) : this.insertAtCursorSmS(myValue)
  }

  insertAtCursor(myValue) {
    if (myValue === '[[[Tags]]]') {
      return;
    }
    this.editor = tinymce.get('email_body');
    this.editor.insertContent(myValue);
  }

  insertAtCursorSmS(myValue) {
    if (myValue === '[[[Tags]]]') {
      return;
    }
    let txtarea = this.s_body.nativeElement;

    let scrollPos = txtarea.scrollTop;
    let strPos = 0;
    let br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
      'ff' : (this.s_body.nativeElement.selection ? 'ie' : false ) );
    if (br === 'ie') {
      txtarea.focus();
      let range = this.s_body.nativeElement.selection.createRange();
      range.moveStart ('character', -txtarea.value.length);
      strPos = range.text.length;
    } else if (br === 'ff') {
      strPos = txtarea.selectionStart;
    }

    let front = (txtarea.value).substring(0, strPos);
    let back = (txtarea.value).substring(strPos, txtarea.value.length);
    txtarea.value = front + myValue + back;
    strPos = strPos + myValue.length;
    if (br === 'ie') {
      txtarea.focus();
      let ieRange = this.s_body.nativeElement.selection.createRange();
      ieRange.moveStart ('character', -txtarea.value.length);
      ieRange.moveStart ('character', strPos);
      ieRange.moveEnd ('character', 0);
      ieRange.select();
    } else if (br === 'ff') {
      txtarea.selectionStart = strPos;
      txtarea.selectionEnd = strPos;
      txtarea.focus();
    }
    txtarea.scrollTop = scrollPos;
  }


  // RecientsREsults

  campaignAudience(campaign_id, status) {
    this.audienceList = [];
    delete this.filter['keyword'];
    this.filter['status'] = status;
    this.loaderService.show();
    this.campaignService.campaignAudienceList(campaign_id, this.filter)
      .then(resp => {
        this.audienceList = resp.audienceList;
        this.totalItems = resp.total;
        this.filter['page'] = resp.current_page;
        this.loaderService.hide();
      }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  setTemplateToBody(template: RichTextTemplate) {
    this.campaign.campaign_body = template.body;
  }
}
