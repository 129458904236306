import {Component, OnInit} from '@angular/core';
import {TabsService} from '../../services/tabs.service';

@Component({
  selector: 'app-private-program-bills',
  templateUrl: './private-program-bills.component.html',
  styleUrls: ['./private-program-bills.component.scss']
})
export class PrivateProgramBillsComponent implements OnInit {
  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('Private Billing');
  }
  ngOnInit(): void {
  }

}
