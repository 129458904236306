import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ReviewsComponent} from './reviews/reviews/reviews.component';
import {VideoPanelComponent} from './video-chat/video-panel/video-panel.component';
import {EmailMarketingComponent} from "./marketing/email-marketing/email-marketing.component";
import {SmsMarketingComponent} from "./marketing/sms-marketing/sms-marketing.component";

export const routes: Routes = [
    /*{
      path: 'page-one',
      loadChildren: './public-content-pages/public-content-pages.module#HtmlCssModule'
    }*/
  {path: 'patient_review' , component: ReviewsComponent},
  {path: 'email-marketing' , component: EmailMarketingComponent},
  {path: 'sms-marketing' , component: SmsMarketingComponent},
  {path: 'video', component: VideoPanelComponent},
  // {path: 'patient_review', loadChildren: "../app/posts/posts.module#PostsModule"}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
