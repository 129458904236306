<div class="flex-body scroll-browser">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>Name</th>
        <th>Specialty</th>
        <th>Associated Practices</th>
        <th>Contact</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let doctor of doctors_listing()">
        <tr>
          <td><a href="javascript:void(0)" (click)="add_to_tabs(doctor)">{{doctor.full_name}}</a></td>
          <td [title]="doctor?.specialties_name">{{doctor.specialties_name | truncate:35:'...'}}</td>
          <td>{{doctor.practices_name}}</td>
          <td>
            <div>
              <i class="icon icon-phone fa fa-fw" *ngIf="doctor?.doctor_phone_number"></i>
              {{doctor.doctor_phone_number}}
            </div>
            <div *ngIf="doctor?.doctor_email"><i class="icon icon-envelope fa fa-fw"></i> {{doctor.doctor_email}}</div>
          </td>
          <td *userCan="'activeinactive,Doctors,manage'">
            <select #state class="form-control short-select" [(ngModel)]="doctor.is_active" name="status"
                    [ngClass]="doctor.is_active == true ? 'active' : 'inactive'"
                    (change)="confirmation(doctor);">
              <option value="true">Active</option>
              <option value="false">In Active</option>
            </select>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="flex-footer text-center">
  <pagination class="m0" [totalItems]="totalItems" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
</div>
<div class="modal" bsModal #classicModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:180px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="m0 text-center">Doctor</h4>
      </div>
      <div class="modal-body">
        <p class="text-center">Are you sure you want to Activate/Deactivate?</p>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" class="btn btn-secondary" (click)="change_doctor_status();">Yes</button>
        <button type="submit" class="btn btn-tertiary btn-outline" (click)="classicModal.hide();revert_status();">No</button>
      </div>
    </div>
  </div>
</div>
