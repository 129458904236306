import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AlertIntervention} from '../alert-template';
import {AlertTemplateService} from '../alert-template.service';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {mergeMap} from 'rxjs/operators';
import {Alert} from '../../../program/alert';
import {AlertService} from '../../../_alert';

@Component({
  selector: 'app-alert-template-intervention',
  templateUrl: './alert-template-intervention.component.html',
  styleUrls: ['./alert-template-intervention.component.scss']
})
export class AlertTemplateInterventionComponent implements OnInit {
  public token = '';
  public data_source: Observable<any>;
  public typeaheadLoading = false;
  public typeaheadInterventionNoResults = false;
  @Input() alert_template_interventions: any[];
  @Input() isResolveModel: boolean;
  @Input() alert: Alert;

  constructor(public alertTemplateService: AlertTemplateService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.data_source = Observable.create((observer: any) => {
      observer.next(this.token);
    }).pipe(mergeMap((token: string) => this.alertTemplateService.getInterventions(token)));
  }
  addIntervention() {
    let intervation = new AlertIntervention();
    intervation.description = this.token;
    this.alertTemplateService.createAlertIntervention(intervation).then(resp => {
      if (this.isResolveModel) {
        let inter = new AlertIntervention().load_from_json(resp)
        this.alert_template_interventions.push(inter);
        this.pushInterventionObjectInArray(inter.id)
      } else {
        this.alert_template_interventions.push(new AlertIntervention().load_form_json_for_create_intervention(resp));
        this.alertService.success('Intervention has been saved successfully', this.alertService.options);
      }
      this.loaderService.hide();
    }).catch((res: any) => {
      this.globalErrorHandlerService.error = res.error.error;
      this.loaderService.hide();
    })
    this.token = undefined;
    this.typeaheadInterventionNoResults = false;
  }
  removeTempIntervention(intervention: AlertIntervention) {
    this.alertTemplateService.removeTempObjectFromArray(intervention, this.alert_template_interventions)
  }
  typeaheadOnSelect(e: any) {
    if (!this.interventionExists(e.item)) {
      if (this.isResolveModel) {
        this.alert_template_interventions.push(new AlertIntervention().load_from_json(e.item));
        this.pushInterventionObjectInArray(e.item.id);
      } else {
        this.alert_template_interventions.push(new AlertIntervention().load_form_json_for_create_intervention(e.item))
      }
    }
    this.token = '';
  }
  interventionExists(item) {
    if (this.isResolveModel) {
      return this.alert_template_interventions.some(function(el) {
        return el.id === item.id;
      });
    } else {
      return this.alert_template_interventions.some(function(el) {
        return el.intervention_id === item.id;
      });
    }
  }
  changeTypeaheadLoading(e: boolean) {
    this.typeaheadLoading = e;
  }
  changeTypeaheadInterventionNoResults(e: boolean) {
    this.typeaheadInterventionNoResults = e
  }
  onCheckboxChange(e: any) {
    if (e.target.checked) {
      this.pushInterventionObjectInArray(e.target.value);
    } else {
      const index = this.alert.interventions.findIndex(x => x.id === e.target.value);
      this.alert.interventions.splice(index, 1);
    }
  }
  checkAlreadySelected(e) {
    let index = this.alert.interventions.findIndex(x => x.id.toString() === e.id.toString());
    return index > -1 ? true : false
  }
  pushInterventionObjectInArray(intervention_id) {
    this.alert.interventions.push({id: intervention_id});
  }
}
