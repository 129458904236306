import {User} from '../../users/user';
import {MessageService} from '../../../services/message.service';
import {isNullOrUndefined} from 'util';
import {setHours} from 'ngx-bootstrap/chronos/utils/date-setters';
import {AppointmentService} from '../../../services/appointment.service';
export class Chatroom {
  public static message_service: MessageService;
  id: number;
  page_loaded = 1;
  status = 'active';
  verification_token: string;
  blocked_by_id: number;
  suffix: string;
  second_suffix: string;
  all_loaded = false;
  load_in_progress = false;
  thumbnail = '';
  unread_count: number;
  last_unread_count: number;
  skip_previous = false;
  topic = '';
  user_id: number;
  multi_user = false;
  chatroom_id: number;
  chatroom_type: string;
  receiver_id: number;
  public subscribed = true;
  public logged_user_id: number;
  messages: Message [] = [];
  typing_users = [];
  public messagee = new Message();
  public last_message: Message;
  public subscribed_user: User = new User();
  public recipient_chat_user: ChatroomUser;
  chatroom_users: ChatroomUser[] = new Array();
  public new_recipients: ChatroomUser[] = [];

  constructor() { }

  to_params(merge_new_recipients?) {
    let recipients = this.chatroom_users;
    if (merge_new_recipients) {
      this.newRecipients().forEach(r => recipients.push(r));
    } else {
      this.addChatroomUsers(this.user_id);
      this.addChatroomUsers(this.receiver_id);
      recipients = this.chatroom_users;
    }
    return {
      chatroom: {
        topic: this.topic,
        id: this.id,
        chatroom_type: this.chatroom_type,
        user_id: this.user_id,
        receiver_id: this.receiver_id,
        verification_token: this.verification_token,
        messages_attributes: this.messages.map(msg => msg.to_params()),
        chatroom_users_attributes: this.chatroom_users
      }
    }
  }

  requiresVerification() {
    return this.subscribed_user && this.subscribed_user.chat_privacy == 'allow_with_verification' && this.id == undefined && this.verification_token == undefined;
  }

  merge_recipients() {
    this.newRecipients().forEach(r => this.chatroom_users.push(r));
    this.new_recipients = this.new_recipients.filter(r => !r.selected);
    this.multi_user = this.is_group;
  }

  addChatUser(chat_user) {
    let cu = this.chatroom_users.findIndex(c => c.user_id === chat_user.user_id);
    if (cu < 0) {
      this.chatroom_users.push(chat_user);
    }
  }

  get lastReadMessageIndex() {
    if (!this.last_unread_count) {
      return Math.max(this.messages.length - 1, 0);
    }
    return Math.max(this.messages.length - this.last_unread_count - 1, 0);
  }

  removeUser(chat_user: ChatroomUser) {
    let index = this.chatroom_users.indexOf(chat_user, 0);
    if (index >= 0) {
      this.chatroom_users.splice(index, 1);
      chat_user.id = undefined;
      chat_user.selected = false;
      this.new_recipients.push(chat_user);
    }
  }

  removByUserId(user_id) {
    let index = this.chatroom_users.findIndex( cu => cu.user_id == user_id)
    if (index >= 0) {
      this.removeUser(this.chatroom_users[index]);
    }
  }

  get is_single_user() {
    return this.chatroom_type != 'group_type' && this.chatroom_users.length <= 2;
  }

  get is_group() {
    return !this.is_single_user;
  }


  newRecipients(): ChatroomUser[] {
    return this.new_recipients.filter( (r) => r.selected && this.chatroom_users.filter(u => u.user_id == r.user_id).length == 0)
  }

  get status_label() {
    if (this.is_single_user) {
      let recipient = this.recipient_user;
      if (recipient) {
        return recipient.online_status;
      }
    } else {
      return  ''
    }
  }

  get last_seen_label() {
    if (this.is_single_user) {
      let recipient = this.recipient_user;
      if (recipient && !recipient.is_online) {
        return recipient.last_seen && ' last seen:' + recipient.last_seen;
      }
    }
    return ''
  }

  set_status_data(data) {
    this.chatroom_users.filter(user => user.user_id == data.user_id).map( user => user.set_status_data(data));
  }

  get status_css_class() {
    if (this.is_single_user) {
      let recipient = this.recipient_user;
      if (recipient) {
        return recipient.css_class;
      }
    } else {
      return  ''
    }
  }

  get recipient_user() {
    if (this.is_single_user) {
      return this.chatroom_users.filter(chatuser => chatuser.user_id != this.logged_user_id)[0];
    } else {
      return  null;
    }
  }

  get last_message_id() {
    if (this.last_message && this.messages[0].id != this.last_message.id) {
      return this.last_message && this.last_message.id;
    }
    if (this.last_message && this.messages.length > 1) {
      return this.messages[this.messages.length - 2].id
    }
  }

  get title() {
    let text = this.complete_title;
    // if(text.length > 25){
    //   return `${text.slice(0,22)}...`
    // }
    return text;
  }

  get complete_title() {
    return (this.topic == undefined || this.topic == '') ? this.subscribed_user.full_name || '' : this.topic || ''
  }

  get complete_title_with_suffix() {
    return this.complete_title + (this.suffix ? ' - ' + this.suffix : '')
  }

  get title_info() {
    return this.is_group ? 'Group info' : 'Contact Info'
  }

  push_message(message, ignoreOrderChanged?) {
    if (this.messages.every((msg) => msg.id !== message.id)) {
      message.setChatroom(this);
      this.messages.push(message);
    }
    this.last_message = message;
    !ignoreOrderChanged && Chatroom.message_service && Chatroom.message_service.messageChanged(this);
  }



  delete_message(id) {
    let index = this.messages.findIndex( m => m.id == id);
    if (index >= 0) {
      this.messages.splice(index, 1);
    }

  }

  shift_message(message) {
    message.setChatroom(this);
    this.messages.unshift(message);
  }

  push_raw_message(message_json) {
    this.push_message(new Message().load_from_json(message_json));
  }

  handleUserTyping(user) {
    let existing_user = this.typing_users.filter(u => u.id == user.id)[0]
    if (existing_user) {
      existing_user['refreshed'] = true;
    } else {
      this.typing_users.unshift(user);
    }
    setTimeout(() => {
      let existing_user = this.typing_users.filter(u => u.id == user.id)[0]
      if (!existing_user) {
      }
      if (!existing_user['refreshed']) {
        let index = this.typing_users.findIndex(u => u.id == user.id);
        if (index >= 0) {
          this.typing_users.splice(index, 1)
        }
        else {
          console.log('no found', existing_user, this.typing_users);
        }
      } else {
        existing_user['refreshed'] = false;
      }
    }, 3000);
  }

  get someone_typing() {
    return this.typing_users.length > 0
  }

  get typing_message() {
    let typing_users =  this.typing_users;
    if (typing_users.length) {
      if (typing_users.length == 1) {
        return `${typing_users[0]['name']} is typing...`
      }
      let first = typing_users.slice(0, typing_users.length - 1)
      let last =  typing_users[typing_users.length - 1];
      return `${first.map(u => u.name).join(',')} and ${last.name} are typing...`
    }
  }

  loadNewMessages(messages, pop_last?) {
    if (pop_last && this.messages) {
      this.messages.pop();
    }
    messages.forEach(message => this.push_message(new Message().load_from_json(message), true));
    return this;
  }

  loadPaginatedMessages(messages, headers?) {
    this.page_loaded += 1;
    console.log(headers);
    this.all_loaded = (!messages || messages.length == 0);
    this.load_in_progress = false;
    messages && messages.forEach(m => this.shift_message(new Message().load_from_json(m)));
  }

  set previos_unread_count(val) {
    this.last_unread_count =  val;
  }

  get previos_unread_count() {
    return this.last_unread_count;
  }

  get isActive() {
    return this.status == 'active';
  }

  load_from_json(json, pop_last?) {
    this.id = json.id;
    this.thumbnail = json.thumbnail;
    this.user_id = json.user_id;
    this.unread_count = json.unread_count;
    this.previos_unread_count = json.unread_count;
    this.topic = json.topic;
    if (json.status) {
      this.status = json.status;
    }
    this.suffix = json.suffix;
    this.second_suffix = json.second_suffix;
    this.blocked_by_id = json.blocked_by_id;
    this.chatroom_type = json.chatroom_type;
    this.topic = this.complete_title;
    if (!this.messages) {
      this.messages = [];
    }
    if (json.chatroom_users) {
      this.chatroom_users = json.chatroom_users.map(chatuser => new ChatroomUser(chatuser.user_id).load_from_json(chatuser));
    }
    if (json.messages) {
      this.loadNewMessages(json.messages, pop_last)
    }
    if (json.last_message && json.messages.length <= 0) {
      this.push_message(new Message().load_from_json(json.last_message));
    }
    else {
      this.last_message =  this.messages[Math.max(this.messages.length - 1, 0)];
    }
    if (json.subscribed_user) {
      this.subscribed_user = this.subscribed_user.load_from_json(json.subscribed_user);
    }
    this.multi_user = this.is_group;
    if (this.is_single_user && Chatroom.message_service && Chatroom.message_service.current_user) {
      this.recipient_chat_user = this.chatroom_users.filter(user => user.user_id != Chatroom.message_service.current_user.id)[0] || new ChatroomUser().load_from_json(json.subscribed_user || {});
    }
    if (this.is_single_user) {
      this.suffix =  this.subscribed_user && this.subscribed_user.suffix;
      this.second_suffix =  this.subscribed_user && this.subscribed_user.second_suffix;
    }
    return this;
  }

  addChatroomUsers(user_id: any) {
    if (this.chatroom_users.filter(u => u.user_id == user_id).length == 0) {
      this.chatroom_users.push(new ChatroomUser(user_id));
    }
    return this.chatroom_users;
  }

  recipientsExist(user_id: any): boolean {
    return this.chatroom_users.filter(cu => cu.user_id == user_id).length > 0
  }

  create_chatroom_message(user, reciever_id, message, message_type?, sub_type?) {
    this.messages = []
    this.user_id = user.id;
    this.receiver_id = reciever_id;
    this.messagee.body = message;
    this.messagee.message_type = message_type;
    this.messagee.sub_type = sub_type;
    this.messagee.user_id = user.id;
    this.push_message(this.messagee);
    return this;
  }

  physical_exist() {
    if (this.id) {
      return true;
    }
    return false;
  }

  get user_image() {
    return this.thumbnail;
  }
  processSystemMessage(message) {

  }
}


class MessageTag {
  id: number;
  taggable_id: number;
  taggable_type: number;
  name: string;
  index: number;
  load_from_json(json) {
    this.id = json.id;
    this.taggable_id = json.taggable_id;
    this.taggable_type = json.taggable_type;
    this.index = json.index;
    this.name = json.name;
    return this;
  }
}

export class Message {
  id: number;
  body = '';
  url_body = '';
  user_id: number;
  chatroom_id: number;
  in_process = false;
  chatroom: Chatroom;
  chatroom_user: ChatroomUser;
  file_path: string;
  preview_avaialble: boolean;
  attachment_id: number;
  attachment_type_mapping: number;
  file_name = '';
  data = {};
  message_type = 'text';
  sub_type = 'text';
  is_image = false;
  created_at: Date;
  center_date: any;
  created_at_date_stamp: Number;
  message_tags: MessageTag[] = [];
  split_messages: string[] = [];

  to_params() {
    return {
      body: this.body,
      id: this.id,
      user_id: this.user_id,
      message_type: this.message_type,
      sub_type: this.sub_type,
      chatroom_id: this.chatroom_id
    }
  }


  get attachment_type() {
    return (this.isFileType || this.isDocumentType) && !this.is_image;
  }

  setChatroom(chatroom) {
    this.chatroom = chatroom;
    if (this.chatroom) {
      this.chatroom_user = this.chatroom.chatroom_users.filter(u => u.user_id == this.user_id)[0]
    }
  }

  get sender_name() {
    if (this.chatroom && this.chatroom.multi_user && this.chatroom_user) {
      return this.chatroom_user.name;
    }
  }

  load_from_json(json) {
    this.id = json.id;
    this.body = json.body;
    this.user_id = json.user_id;
    this.file_path = json.file_path;
    this.file_name = json.file_name;
    this.attachment_id = json.attachment_id;
    this.attachment_type_mapping = json.attachment_type_mapping;
    this.message_type = json.message_type;
    this.sub_type = json.sub_type;
    this.is_image = json.is_image;
    this.data = json.data;
    if (this.body) {
      this.url_body = this.body.replace(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi, '<a href="http://$1" target="_blank">$1</a> ').replace(/http:\/\/http(s)?:\/\//gi, 'http://').replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi, '<a href="mailto:$1" target="_blank">$1</a> ').replace(/\n/g, '<br>');
    }
    if (json.in_process != undefined || json.in_process != '') {
      this.in_process = json.in_process;
    }
    this.chatroom_id = json.chatroom_id;
    this.preview_avaialble = json.preview_avaialble;
    this.created_at = new Date(json.created_at);
    let date = new Date(this.created_at);
    date.setHours(0, 0, 0, 0);
    this.created_at_date_stamp =  date.getTime();
    this.center_date = this.renderDate(this.created_at);



    if (json.message_tags) {
      json.message_tags.forEach( tag => {
        this.message_tags.push(new MessageTag().load_from_json((tag)));
      });
    }
    if (this.isTaggedType) {
      this.split_messages = this.split_message();
    }
    if (json.chatroom) {
      this.chatroom  =  new Chatroom().load_from_json(json.chatroom);
    }
    return this;
  }
  renderDate(date) {
    const today = new Date
    const yesterday = new Date; yesterday.setDate(today.getDate() - 1)
    if (date.toLocaleDateString() == today.toLocaleDateString()) {
      return 'Today'
    } else if (date.toLocaleDateString() == yesterday.toLocaleDateString()) {
      return 'Yesterday'
    }
    return date.toLocaleDateString('en-US', {
      day : 'numeric',
      month : 'long',
      year: 'numeric'
    })
  }

  split_message() {
    let prev_index = 0;
    let result = []
    this.message_tags.forEach(tag => {
      result.push(this.body.slice(prev_index, tag.index))
      prev_index = tag.index;
    });
    if (prev_index < this.body.length - 1) {
      result.push(this.body.slice(prev_index))
    }
    return result;
  }

  get isTextType() {
    return this.message_type === 'text';
  }

  get isFileType() {
    return this.message_type === 'file';
  }

  get isAdded() {
    return this.sub_type === 'added'
  }
  get isRemoved() {
    return this.sub_type === 'removed'
  }

  get isTitleChanged() {
    return this.sub_type === 'title_changed';
  }

  get isBlockedType() {
    return this.sub_type == 'chatroom_blocked'
  }

  get isFileProcessed() {
    return this.sub_type === 'file_processed'
  }

  get isPictureChanged() {
    return this.sub_type === 'picture_changed';
  }

  get removed_user_id() {
    if (this.isRemoved) {
      return this.data['user_ids'] && this.data['user_ids'][0];
    }
  }

  get isTaggedType() {
    return this.message_type == 'tagged';
  }
  get isSystemType() {
    return this.message_type == 'system';
  }

  get isDocumentType() {
    return this.message_type == 'document_tagged'
  }

  get text_message() {
    if (this.isDocumentType) {
      return this.file_name;
    }
    if (this.isFileType) {
      if (this.is_image) {
        return 'Photo'
      } else {
        return  'Document'
      }
    } else if (this.isTaggedType) {
      let splits = []
      this.split_messages.forEach((splitm, index) => {
        splits.push(splitm);
        if (this.message_tags[index]) {
          splits.push(this.message_tags[index].name);
        }
      });
      return  splits.join(' ');
    }
    return this.body;
  }

  get attachment_icon_class() {
    if (this.isFileType) {
      if (this.is_image) {
        return 'fa fa-camera vl-m'
      } else {
        return  'fa fa-file vl-m'
      }
    }
    return ''
  }

  get file_icon() {
    if (this.in_process) {
      return  'fas fa-spinner'
    }
    if (this.isDocumentType) {
      return 'fa-medkit'
    }
    if (this.isFileType) {
      // tslint:disable-next-line:max-line-length
      return {'0': 'fa-camera', '1': 'fa-file-video-o', '2': 'fa-file-audio-o', '3': 'fa-file-pdf-o', '-1': 'fa-file'}[this.attachment_type_mapping.toString()]
    }
  }

  file_processed() {
    this.in_process = false;
  }
}

export class ChatroomUser {
  id: number;
  chatroom_id: number;
  user_id: number;
  online_status: string;
  mobile_status: string;
  last_seen: string;
  name: string;
  selected = false;
  profile_pic: string;
  profileable_id: number;
  profileable_type: string;

  constructor(user_id?: number) {
    this.user_id = user_id;
  }
  load_from_json(chatuser_json, skip_id?: boolean) {
    if (!skip_id) {
      this.id = chatuser_json.id;
    }
    this.chatroom_id = chatuser_json.chatroom_id;
    this.user_id = chatuser_json.user_id;
    this.online_status = this.setStatus(chatuser_json.online_status, this.checkStatus(chatuser_json.mobile_status));
    this.mobile_status = chatuser_json.mobile_status;
    this.last_seen = chatuser_json.last_seen;
    this.name = chatuser_json.name;
    this.profile_pic = chatuser_json.profile_pic;
    this.profileable_id = chatuser_json.profileable_id;
    this.profileable_type = chatuser_json.profileable_type;
    return this;
  }

  get isPatient() {
    return this.profileable_type == 'Patient'
  }

  get css_class() {
    return this.online_status && this.online_status.toString().toLowerCase();
  }

  get is_online() {
    return this.online_status && this.online_status.toLowerCase() === 'online';
  }

  set_status_data(data) {
    this.online_status = this.setStatus(data.portal_online_status, this.checkStatus(data.mobile_online_status))
    this.last_seen = data.last_seen;
  }

  isAdminFor(chatroom) {
    return this.user_id === chatroom.user_id;
  }

  setStatus(portal: string, mobile: string) {
    return (portal === 'offline' && mobile === 'offline') ? 'offline' : ((portal === 'away' && mobile === 'away') ? 'away' : ((portal === 'online' || mobile === 'online') ? 'online' : 'away'))
  }
  checkStatus(status: string) {
    if (status) {
      status.toString();
      if (status.includes('online')) {
        return 'online'
      } else if (status.includes('away')) {
        return 'away'
      } else if (status.includes('offline')) {
        return 'offline'
      }
    }
  }

}
