export class Ledger {
  id: number;
  appointment_id: string;
  appointment_status: string;
  balance = 0;
  payments: Payment[] = [];
  load_from_json(json) {
    this.id = json.id;
    this.appointment_id = json.appointment_id;
    if (json.appointment) {
      this.appointment_status = json.appointment.status_id;
    }
    this.balance = json.balance;
    this.payments = json.payments.map(payment => new Payment().load_from_json(payment));
    return this;
  }
  total_balance(payments): any {
    let debit_payments = payments.filter(payment => payment.paymentType.balance_type == 'debit');
    let credit_payments = payments.filter(payment => payment.paymentType.balance_type == 'credit')
    let credit_balance = credit_payments.reduce((a, b) => a + +b.amount, 0);
    let debit_balance = debit_payments.reduce((a, b) => a + +b.amount, 0);
    return credit_balance - debit_balance;
  }
  to_params() {
    return{
      ledger: {
        id: this.id,
        balance: this.total_balance(this.payments),
        appointment_id: this.appointment_id,
        appointment_status: this.appointment_status,
        payments_attributes: this.payments.map(payment => payment.to_params())
      }
    }

  }
  get_url(): string {
    return this.is_new_record() ? 'payments/' : ['payments', this.id].join('/')
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }

}
export class Payment {
  id: number;
  ledger_id: number;
  description: string;
  payment_type_id: number;
  amount: number;
  paymentType: PaymentType = new PaymentType();
  current_total = 0;
  load_from_json(json) {
    this.id = json.id;
    this.ledger_id = json.ledger_id;
    this.description = json.description;
    this.payment_type_id = json.payment_type_id;
    this.amount = json.amount;
    this.paymentType = this.paymentType.load_from_json(json.payment_type || json.paymentType);
    return this;
  }
  to_params() {
    return {
      id: this.id,
      description: this.description,
      payment_type_id: this.payment_type_id,
      amount: this.amount
    }
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
}
export class PaymentType {
  id: number;
  name: string;
  balance_type: string;
  load_from_json(json) {
    this.id = json.id;
    this.name = json.name;
    this.balance_type = json.balance_type;
    return this;
  }
}
