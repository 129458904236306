import {Component, Injector, OnInit} from '@angular/core';
import {NoteComponent} from "./note-component";
import {Subscription} from "rxjs/Subscription";
import {NoteComponentService} from "./note-component.service";
import {Observable} from "rxjs/Observable";
import {mergeMap} from "rxjs/operators";
import {EnoteService} from "../../services/enote.service";
import {AlertService} from "../../_alert";
import {TabsService} from "../../services/tabs.service";
import {GlobalErrorHandlerService} from "../../services/global-error.service";

@Component({
  selector: 'app-note-component',
  templateUrl: './note-component.component.html',
  styleUrls: ['./note-component.component.scss']
})
export class NoteComponentComponent implements OnInit {
  public noteComponent: NoteComponent;
  public noteComponentSubscription: Subscription;

  public typeaheadLoading = false;
  public typeaheadLoadingService = false;
  public typeaheadNoResults = false;
  public data_source: Observable<any>;

  public subTypeaheadLoading = false;
  public subTypeaheadNoResults = false;
  public sub_data_source: Observable<any>;


  constructor(public injector: Injector, public noteComponentService: NoteComponentService, public tabs_service: TabsService, public globalErrorHandlerService: GlobalErrorHandlerService, private  alter_service: AlertService) {

    this.data_source = Observable.create((observer: any) => {
      observer.next(this.noteComponent.component);
    }).pipe(
      mergeMap((token: string) => {
        return this.noteComponentService.searchComponentTypeahead( {component : this.noteComponent.component})
      })
    );


    this.sub_data_source = Observable.create((observer: any) => {
      observer.next(this.noteComponent.sub_component_1);
    }).pipe(
      mergeMap((token: string) => {
        return this.noteComponentService.searchComponentTypeahead( {sub_component_1 : this.noteComponent.sub_component_1, parent_id: this.noteComponent.parent_id})
      })
    );
  }

  ngOnInit(): void {
    let newNoteComponent  = this.injector.get('noteComponent', null);
    if (newNoteComponent) {
      this.noteComponent = newNoteComponent;
    } else {
      this.noteComponent = new NoteComponent();
    }
  }

  save() {
    this.noteComponentService.save(this.noteComponent).then(resp => {
       this.noteComponent = this.noteComponent.load_from_json(resp);
      this.noteComponentService.reloadNoteComponentList.next(this.noteComponent);
      this.alter_service.success('Component has been saved successfully', {autoClose: true, keepAfterRouteChange: true});
    }).catch(error => {
      this.globalErrorHandlerService.error = error.error.error;
    })
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any): void {
   this.noteComponent.parent_id = e.item.id;
  }

  public subComponentChangeTypeaheadLoading(e: boolean): void {
    this.subTypeaheadLoading = e;
  }

  public subComponentChangeTypeaheadNoResults(e: boolean): void {
    this.subTypeaheadNoResults = e;
  }

  public subComponentTypeaheadOnSelect(e: any): void {
    this.noteComponent.parent_id = e.item.id;
  }
}
