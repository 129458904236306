<div class="chat-flex" [class.body-panel-shown]="middle_selected" [class.right-panel-shown]="show_group_info">
  <div class="left-panel" *ngIf="!task_user">
    <div class="header">
      <div class="pic-name-sec">
        <div class="pic-holder"><img [src]="current_user() && current_user().profile_pic ? current_user().profile_pic : 'assets/img/user/01.jpg'"/></div>
      </div>
      <div class="icon-sec" *ngIf="currentUserWithSetting()">
        <a class="btn btn-link-secondary" data-toggle="tooltip" data-placement="top" title="Create New Group" (click)="makeNewGroup()">
          <i class="fa icon-people"></i>
        </a>
        <a class="btn btn-link" title="Settings" (click)="showSettings()">
          <i class="fa fa-cog text-gray-dark"></i>
        </a>
      </div>
    </div>
    <div class="search-sec typeheadchat">
      <i class="fa icon-magnifier"></i>
      <label class="form-control">
          <input placeholder="Search or Start new chat"
                 [(ngModel)]="token" [typeahead]="data_source"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 [typeaheadMinLength]="0"
                 [typeaheadOptionField]="'user_full_name'" (keyup)="checkFilters()"
                 typeaheadWaitMs="500"
          />
      </label>
    </div>
    <ul class="user-list border-list m0 p0 scroll-browser">
      <ng-container *ngIf="_message_Service.selected_chatroom && _message_Service.chatrooms && _message_Service.chatrooms.length > 0">
        <li class="noborder static-li" *ngIf="filtered_chatrooms.length && token.length">Chat</li>
        <ng-container *ngFor="let chat_user of filtered_chatrooms">
          <li class="list" [class.new-msg]="chat_user.unread_count > 0" [class.active]="chat_user.id == _message_Service.selected_chatroom.id" [ngClass]="chat_user.isActive ? '' : chat_user.status">
            <div class="media" (click)="loadMessages(chat_user);middle_selected=true">
              <div class="media-left">
                <img alt="Image" [src]="chat_user.thumbnail">
              </div>
              <div class="media-body media-middle">
                <div class="flex-grow">
                  <h4 class="name grow" [title]="chat_user.complete_title_with_suffix" >{{chat_user.title}}<small *ngIf="chat_user.suffix"> - {{chat_user.suffix}}</small></h4>
                  <small class="date" *ngIf="chat_user.last_message">{{chat_user.last_message.created_at | date: 'shortTime'}}</small>
                </div>
                <div class="flex-grow">
                  <h6 class="sub-msg grow" *ngIf="!chat_user.someone_typing && chat_user.last_message">
                    <span *ngIf="chat_user.is_group && current_user() && chat_user.last_message.user_id &&  current_user().id != chat_user.last_message.user_id">{{chat_user.last_message.sender_name}}: </span>
                    <i [ngClass]="chat_user.last_message.attachment_icon_class"></i>
                    {{chat_user.last_message.text_message}}
                  </h6>
                  <h6 class="sub-msg grow" *ngIf="chat_user.someone_typing">
                    <i class="text-success">{{chat_user.typing_message}}</i>
                  </h6>
<!--                  <ng-container *ngIf="chat_user.id != _message_Service.selected_chatroom.id">-->
                  <span *ngIf="chat_user.unread_count > 0" class="counter">{{chat_user.unread_count}}</span>
<!--                  </ng-container>-->
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="typeheadResult.length">
        <li class="noborder static-li">Contacts</li>
        <li class="list" *ngFor="let recipient of typeheadResult">
          <div class="media" (click)="addToChatroom(recipient);middle_selected=true">
            <div class="media-left">
              <img alt="Image" [src]="recipient.profile_pic">
            </div>
            <div class="media-body media-middle">
              <div class="flex-grow">
                <h4 class="name grow" [title]="recipient.user_full_name + (recipient.suffix ? ' - ' + recipient.suffix : '')">
                  {{recipient.user_full_name}}<small *ngIf="recipient.suffix"> - {{recipient.suffix}}</small>
                </h4>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <app-chat-detail class="body-panel p-relative"
    [reciever_id]="reciever_id"
    [show_group_info]="show_group_info"
    [chatroom]="_message_Service.selected_chatroom"
    (group_clicked)="handleGroupClicked()"
    (cross_clicked)="handleCrossClicked()">
  </app-chat-detail>
  <div class="right-panel" *ngIf="show_group_info">
    <app-right-panel [show_group_info]="show_group_info" [chatroom]="_message_Service.selected_chatroom" (group_clicked)="handleGroupClicked()"></app-right-panel>
  </div>
  <div class="right-panel zero-maxw" *ngIf="show_new_group">
    <app-right-panel [show_group_info]="false" [popup_onload]="true" [chatroom]="new_group" (popup_closed)="handlePopupclose()" (group_updated)="handleGroupCreation($event)"></app-right-panel>
  </div>

  <div class="modal fade" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
    <div class="modal-dialog" style="height:366px;">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="classicModal.hide();"><i class="fa fa-times fs16"></i></button>
          <h4 class="m0 text-center">Chat Settings</h4>
        </div>
        <form class="modal-body" (ngSubmit)="saveSettings()" *ngIf="current_user()">
          <label for="chat_privacy_allow" class="d-block fw-500">
            <input type="radio" [(ngModel)]="current_user().chat_privacy" name="allow_chat" [value]="'allow_patient'" [attr.val2]="current_user().chat_privacy" id="chat_privacy_allow">
            &nbsp; Allow Patient Communication
          </label>
          <label for="chat_privacy_vallow" class="d-block fw-500">
            <input type="radio" [(ngModel)]="current_user().chat_privacy" name="allow_chat" [value]="'allow_with_verification'" id="chat_privacy_vallow">
            &nbsp; Allow Patient Communication with my cell number verification
          </label>
          <label for="chat_privacy_dallow" class="d-block fw-500">
            <input type="radio" [(ngModel)]="current_user().chat_privacy" name="allow_chat" [value]="'dont_allow'" id="chat_privacy_dallow">
            &nbsp; Do not allow patient communication
          </label>
        </form>
        <div class="modal-footer">
          <button class="btn btn-secondary btn-min" type="submit" (click)="saveSettings()">Save</button>
          <button type="submit" class="btn btn-tertiary btn-outline btn-min" (click)="classicModal.hide();">Close</button>
        </div>
      </div>
    </div>
  </div>

</div>
