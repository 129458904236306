<div *ngIf="chatroom && show_group_info">
  <div class="header flex-grow">
    <div class="grow"><h4 class="name m0">{{chatroom.title_info}}</h4></div>
    <div class="icon-sec">
      <a class="btn text-gray-dark fs16" href="javascript:" (click)="toggleGroupInfo();"><i class="fa fa-times"></i></a>
    </div>
  </div>
  <div class="info-sec shadow-sec">
    <ng-container *ngIf="chatroom.is_group && chatroom.id">
      <app-attachments (document_uploaded)="handle_file_upload($event)"
                       [fileType]="'display_pic'" [only_modal]="true"
                       [auto_upload]="true" [attachable_id]="chatroom.id"
                       [show]="show_attachment_popup" [attachable_type]="'Chatroom'">
      </app-attachments>
      <div class="user-profile-pic thumbnail">
        <div class="loader-pic" *ngIf="loaderService.uploading_in_process">
          <i class="fa fa-spinner fa-spin fs22"></i>
        </div>
        <span class="icon-holder" *ngIf="!loaderService.uploading_in_process" (click)="choose_file()">
          <i class="fa fa-upload fs20"></i>
          <h6 class="mb0">Upload Your Picture</h6>
        </span>
        <img class="img-rounded" [src]="chatroom.thumbnail" [title]="'Change Picture'"/>
      </div>
    </ng-container>
    <img class="img-rounded thumbnail" [src]="chatroom.thumbnail" *ngIf="chatroom.is_single_user || !chatroom.id"/>
    <div class="text-center" *ngIf="chatroom.is_single_user && chatroom.recipient_chat_user">
      <a class="btn-link fs12" href="javascript:" *ngIf="chatroom.recipient_chat_user.isPatient" (click)="add_to_tabs(chatroom.recipient_chat_user)">
        View Profile
      </a>
    </div>
    <div class="flex-grow" *ngIf="!title_edit">
      <div class="grow">
        <h5 class="name m0" [title]="chatroom.complete_title">{{chatroom.title}}</h5>
        <h6 class="mt-sm mb0 lhn" *ngIf="chatroom.suffix">{{chatroom.suffix}}</h6>
        <h6 class="mt-sm mb0 lhn" *ngIf="chatroom.second_suffix">
          <a href="javascript:void(0);" class="h6 m0" (click)="phoneService.initiatePhoneCall(chatroom.second_suffix,
          {name: chatroom.title, pic: chatroom.thumbnail})">
            <i class="fa icon-screen-smartphone mr-sm"></i> {{chatroom.second_suffix | mask: ' (000) 000-0000'}}
          </a>
        </h6>
      </div>
      <div class="text-right" *ngIf="chatroom.is_single_user && chatroom.recipient_chat_user">
        <label class="status-label">
          <label [ngClass]="chatroom.recipient_chat_user.css_class">
            {{chatroom.recipient_chat_user.online_status}}
          </label>
        </label>
        <h6 class="m0 fw-500 last-seen" *ngIf="!chatroom.recipient_chat_user.is_online && chatroom.recipient_chat_user.last_seen">
          last seen at <br>{{chatroom.recipient_chat_user.last_seen}}
        </h6>
      </div>
      <a class="btn btn-tertiary btn-outline btn-sm pt0 pb0" href="javascript:" *ngIf="chatroom.is_group" (click)="toggleTitleEdit()">
        <i class="fa fa-pencil"></i>
      </a>
    </div>
    <form class="flex-grow" (ngSubmit)="changeTitle()" *ngIf="chatroom.is_group && title_edit">
      <div class="grow">
        <input class="form-control p0" [(ngModel)]="chatroom.topic" placeholder="Enter title here..."
               type="text" name="body" autocomplete="off" #title_input>
      </div>
      <button class="btn btn-secondary btn-outline btn-sm pt0 pb0" type="submit"><i class="fa fa-check"></i></button>
    </form>
  </div>
  <ul *ngIf="chatroom.is_group" class="user-list border-list m0 p0 shadow-sec scroll-browser">
    <li class="list" *ngIf="chatroom.is_group && currentUserIsAdmin()">
      <div class="media">
        <div class="media-left media-middle">
          <a class="btn btn-secondary" (click)="showSubscribers();"><i class="fa icon-user"></i></a>
        </div>
        <div class="media-body media-middle">
          <h4 class="text-black m0 fs16" (click)="showSubscribers();">Add Participant</h4>
        </div>
      </div>
    </li>
    <ng-container *ngFor="let chat_user of chatroom.chatroom_users">
      <li class="list">
        <div class="media">
          <div class="media-left">
            <img alt="Image" class="thumb32" [src]="chat_user.profile_pic || '#'">
          </div>
          <div class="media-body media-middle">
            <label class="label label-success pull-right fs10" *ngIf="chat_user.isAdminFor(chatroom)">Admin</label>
            <h4 class="name user-name">{{chat_user.name}}</h4>
            <div>
              <label class="status-label vl-m" [ngClass]="chat_user.css_class">{{chat_user.online_status}}</label>
              <span class="vl-m fs10 pl-sm" *ngIf="!chat_user.is_online && chat_user.last_seen"> last seen at {{chat_user.last_seen}}</span>
            </div>
          </div>
        </div>
        <a *ngIf="chatroom.is_group && !chat_user.isAdminFor(chatroom) && currentUserIsAdmin()" href="javascript:" class="btn btn-del">
          <i class="fa icon-trash text-danger" (click)="deleteuser(chat_user)"></i>
        </a>
      </li>
    </ng-container>
  </ul>
</div>

<div class="modal fade" bsModal #classicModal="bs-modal" [config]="{backdrop: false}">
  <div *ngIf="chatroom" class="modal-dialog" style="height:366px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="classicModal.hide();clear_variables();"><i class="fa fa-times fs16"></i></button>
        <h4 class="m0 text-center">{{chatroom.title || title}}</h4>
      </div>
      <div class="modal-body pb0">
        <app-chat-users [show_recipients]="show_recipients" [chatroom]="chatroom"></app-chat-users>
        <div class="text-success text-center fw-500" style="min-height:20.8px;">{{selectedTitle}}</div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary btn-min mr" [disabled]="!selectedRecipients" type="submit" (click)="addRecipients()">Add</button>
        <button type="submit" class="btn btn-tertiary btn-outline btn-min" (click)="classicModal.hide();clear_variables();">Close</button>
      </div>
    </div>
  </div>
</div>
