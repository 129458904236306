import { Observable } from 'rxjs';
import { ActivatedRoute, Params }   from '@angular/router';
import {Component, OnInit, NgZone, ElementRef, Inject, Input, Output, EventEmitter, AfterViewChecked, ViewChild }      from '@angular/core';
import { Location } from './location'
import { LocationService } from '../../../services/location.service';
import {Specialty} from '../practices/practice'
import { SpecialtySearchService } from '../../../services/specialty-search.service';
import { CustomValidations } from '../../customValidations/custom-validations'
import { NgForm } from '@angular/forms';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import { OnChanges } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import {PublicReviewLink} from '../../../reviews/public_review_link';
import {environment} from "../../../../environments/environment";


@Component({
  moduleId: module.id.toString(),
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, AfterViewChecked, OnChanges {
  public editor: any;
  public  init_tiny = {
    base_url: environment.tinymce.theme_url,
    suffix: '.min',
    height: 300,
    relative_urls: false,
    remove_script_host: false,
    convert_urls: true,
    menubar: false,
    branding: false,
    toolbar_mode: 'sliding',
    placeholder: 'Welcome Message...',
    plugins: [
      'advlist autolink lists link charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'image',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
    'styleselect |fontsizeselect fontselect | bold italic underline strikethrough | \ ' +
    'insertfile undo redo numlist outdent | bullist indent |contextmenu table | \ ' +
    'link forecolor backcolor hr charmap | print search searchreplace',
    setup: editor => {
      this.editor = editor;
    },
  }
  public specialties: Observable<any[]>;
  public searchElementRef: ElementRef;
  public asyncSelected = '';
  public is_bookable_message = '';
  public typeaheadLoading = false;
  public typeaheadNoResults = false;
  public is_bookable_allow = false;
  @Input() do_show: any;
  @Input() location: Location;
  @Input() practice_id: any;
  @Output() sync_location: EventEmitter<Location> = new EventEmitter<Location>();
  @Output()  reset_do_show: EventEmitter<boolean> = new EventEmitter<boolean>();
  customValidations: CustomValidations;
  public_sites: any[];
// validations

  @ViewChild('locationForm') locationForm: NgForm;
  constructor(private ngZone: NgZone, private route: ActivatedRoute, private globalErrorHandlerService: GlobalErrorHandlerService,
              @Inject(ElementRef) elementRef: ElementRef, public locationService: LocationService, private specialtySearchService: SpecialtySearchService

  ) {
    this.customValidations = new CustomValidations();
    this.searchElementRef = elementRef;
    this.specialties = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.asyncSelected);
    }).pipe(mergeMap((token: string) => this.getSpecialties(token)));
  }

  ngOnChanges() {
    this.setGoogleMaps();
  }
  getSpecialties(term: any) {
    return this.specialtySearchService.search(term);

  }
  ngOnInit() {
    this.locationService.public_sites().then(resp => {
      this.public_sites = resp;
    })
  }

  addSpecialty() {
    this.get_specialties().push(new Specialty())
  }

  get_specialties() {
    return this.location.specialties
  }
  set_asyncSelected(event, specialty: Specialty) {
    this.asyncSelected = specialty.description;
  }
  setGoogleMaps() {
    // this.setCurrentPosition();
      let component = this;
      if (component.searchElementRef.nativeElement.querySelector('#city_area_name') === null) {
        return;
    }
      console.log(component.searchElementRef.nativeElement.querySelector('#city_area_name'));
      let searchBox = new google.maps.places.Autocomplete(component.searchElementRef.nativeElement.querySelector('#city_area_name'));
      google.maps.event.addListener(searchBox, 'places_changed', function () {
        function closure(elem) {
          let place = searchBox.getPlace();
          if (!place) {
            return;
          }
          // elem.location.address.city_area.name=searchBox.getPlaces()[0].formatted_address;
          elem.location.address.city_area.lat = place.geometry.location.lat();
          elem.location.address.city_area.long = place.geometry.location.lng();
          elem.location.address.city_area.name = place.formatted_address;

        }
        closure(component);
      })
  }
  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location.address.city_area.lat = position.coords.latitude.toString();
        this.location.address.city_area.long = position.coords.longitude.toString();
      });
    }
  }
  public changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadNoResults = e;
  }

  public typeaheadOnSelect(e: any, specialty: Specialty): void {
    specialty.specialty_id = e.item.id;
    specialty.id = e.item.id;
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  save() {
    this.location.practice_id = this.practice_id;
    this.location.email = this.location.email.toLowerCase();
    this.locationService.save(this.location).then(res => {
      if (!this.location.id) {
        this.location.id = res.id;
        this.sync_location.emit(this.location);
      }
      else {
        this.reset_do_show.emit(false)
      }
    }).catch(resp =>  {
      if (resp.error.error.includes('upgrade your account')) {
        this.globalErrorHandlerService.message = resp.error.error.replace('Validation failed: ', 'Information Alert:');
      }
      else {
        this.globalErrorHandlerService.error = resp.error.error;
      }
    } );
  }
  cancel() {
    this.reset_do_show.emit(false)
  }
  removeSpecialty(specialty) {
    this.get_specialties().splice(this.get_specialties().indexOf(specialty), 1);
  }

  is_bookable_check(e) {
    if (this.location && this.location.id) {
      this.locationService.is_bookable_allow(this.location).then(res => {
        if ((res['response'] == 'empty')) {
          this.location.is_bookable = e.target.checked;
        } else {
          this.location.is_bookable = !e.target.checked;
          this.is_bookable_message = 'Location contains appointments so it cannot be available for booking'
          this.is_bookable_allow = true;
          this.setTimeOut();
        }
      }).catch(resp =>  this.globalErrorHandlerService.error = resp.error.error );
    }
  }

  setTimeOut() {
      setTimeout(() => {
          this.is_bookable_allow = false;
          this.is_bookable_message = '';
      }, 3000)
  }

  active() {
    this.location.is_active = true;
  }
  deactive() {
    this.location.is_active = false;
  }

  ngAfterViewChecked() {
    this.customValidations.formChanged(this.locationForm);
  }

  add_link() {
    // let location_public_links_site_ids = this.location.public_review_links.map(a => a.public_site_id);
    // this.public_sites = this.public_sites.filter(a =>  !location_public_links_site_ids.includes(a.id));
    let public_review_link = new PublicReviewLink();
    public_review_link.linkable_type = 'Location';
    this.location.public_review_links.push(public_review_link);
  }
  onChange(review, site) {
    review.public_site_id = site.value;
    review.public_site_url = null;
  }

  checkExistingSites(site_id) {
    return this.location.public_review_links.filter( l => l.public_site_id == site_id).length == 0
  }
  deleteLink(reviewLink) {
    if (reviewLink.id) {
      this.locationService.deleteLink(reviewLink.id).then(resp => {
        this.location.public_review_links = this.location.public_review_links.filter(l => l.id != reviewLink.id);
      })
    }
    else {
      this.location.public_review_links.splice(this.location.public_review_links.indexOf(reviewLink), 1)
    }
  }

  uncheckOther(link_index: number) {
    for ( let i = 0; i < this.location.public_review_links.length; i++ ) {
      if ( i !== link_index) {
        this.location.public_review_links[i].default_auto_review = false;
      }
    }
  }
}
