import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {DoctorService} from "../../../../services/doctor.service";
import {TabsService} from "../../../../services/tabs.service";
import {Tab} from "../../../../tabs/tab";
import {DoctorFormComponent} from "../../doctors/doctor-form.component";
import {DoctorProfileDetailComponent} from "../../doctors/doctor_profile/doctor-profile-detail.component";
import {ModalDirective} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  @Input() listing: any;
  @Input() totalItems: any;
  @Output() current_page = new EventEmitter<any>();

  constructor(private _doctor_service: DoctorService,private tabs_service: TabsService) { }

  ngOnInit() {
  }

  doctors_listing(){
    return this.listing;
  }

  associated_practices(doctor){
    if (doctor.practices.length > 1){
      return doctor.practices.map(e => e.name).join(', ')
    }else{
      return doctor.practices.map(e => e.name)[0];
    }
  }

  change_doctor_status(){
    if(this.selected_doctor.is_active == "true")
      this.selected_doctor.is_active = true;
    this._doctor_service.change_status(this.selected_doctor);
    this.classicModal.hide();
  }
  revert_status(){
    if(this.selected_doctor.is_active == "true")
      this.selected_doctor.is_active = false;
    if(this.selected_doctor.is_active == "false")
      this.selected_doctor.is_active = true;
    this.classicModal.hide();
  }
  add_to_tabs(doctor){
    //let title = doctor.id ? (doctor.firstname + ' ' +  doctor.lastname): 'Add';
    let title = doctor.id ? doctor.full_name : 'Add';
    //this.tabs_service.add_tab(new Tab({title: title,icon: 'fa fa-user'},DoctorProfileDetailComponent,doctor.id,{id: doctor.id,associated_practices: doctor.practices}))
    this.tabs_service.add_tab(new Tab({title: title,icon: 'fa fa-user'},DoctorProfileDetailComponent,doctor.id,{id: doctor.id,associated_practices: doctor.practices_name}))
  }
  pageChanged(page){
    this.current_page.emit(page.page);
  }
  public selected_doctor;
  @ViewChild('classicModal') classicModal: ModalDirective;
  confirmation(doctor){
    this.selected_doctor = doctor;
    this.classicModal.show();
  }
}
