import {User} from '../../routes/users/user';
import baseApiUrl from './../../globals'
import {BaseModel} from '../../shared/base';
import {PatientVital} from '../../routes/practice-site/vital/PatientVital';
import {Econtact} from './econtact';
import {PatientInsurance} from '../models/patientInsurance';
export class Patient extends BaseModel {
  class_name = 'Patient';
  id: number;
  user_id: number;
  hashid: string;
  phonee = '';
  age_month = 'Month';
  age_year = 'Year';
  name: string;
  user_email: string;
  patient_type = 'online';
  user: User =  new User();
  family_members: Patient[];
  mother_name: string;
  dob: string;
  dob_pet: string;
  age: any;
  pets_name: string;
  secret_key: string;
  mrn_no: string;
  year_error = false;
  parent_id: number;
  parent_user_id: number;
  relationship_id: number;
  breed_type = 0;
  can_login: boolean;
  is_pchf: boolean;
  requested_at: string;
  requested_at_date: Date;
  profile_pic: string = baseApiUrl + '/ilaaj.png';
  gender: any;
  message = '';
  lead_message = 'Appointment has been succussfully registered';
  relationship_name = '';
  customize_dob: string;
  customize_age: string;
  success: string;
  textclass: string;
  valid_email: string;
  title = 'Mr.';
  mobile_verified = false;
  email_verified = false;
  programmeable = false;
  mobile_number_value = '';
  patient_address = '';
  appointments_count = '';
  patient_preferred_method = 'sms_email';
  patient_vitals: PatientVital[];
  patient_online_status: string;
  portal_online_status: string;
  mobile_online_status: string;
  third_party_patient_id: string;
  landline_number_value: any;
  contacts: Econtact[];
  last_updated_at: any;
  patient_insurance: PatientInsurance;
  activation_mail_date: any;
  load_from_json(json) {
    if (json.contacts && json.contacts.length > 0) {
      this.contacts = json.contacts.map(c => new Econtact().load_from_json(c));
    }
    this.relationship_id = json.relationship_id;
    this.third_party_patient_id = json.third_party_patient_id;
    this.patient_preferred_method = json.patient_preferred_method;
    if (json.parent_id) {
      this.parent_id = json.parent_id;
    }
    if (json.parent_user_id) {
      this.parent_user_id = json.parent_user_id;
    }
    if (json.user_id) {
      this.user_id = json.user_id;
    }
    this.requested_at = json.requested_at;
    if (this.requested_at) {
      this.requested_at_date =  new Date(this.requested_at)
    }
    this.patient_type = json.patient_type;
    this.secret_key = '';
    if (json.mother_name) {
      this.mother_name = json.mother_name;
    }
    this.title = json.title;
    if (json.customize_dob) {
      this.customize_dob = json.customize_dob;
    }
    if (json.customize_age) {
      this.customize_age = json.customize_age;
    }
    if (json.dob) {
      this.dob = json.dob;
    }
    this.gender = json.gender;
    if (json.breed_type) {
      this.breed_type = json.breed_type;
    }
    if (json.patient_online_status) {
      this.patient_online_status = json.patient_online_status;
    }
    if (json.portal_online_status) {
      this.portal_online_status = json.portal_online_status;
    }
    if (json.mobile_online_status) {
      this.mobile_online_status = json.mobile_online_status;
    }
    this.relationship_name = json.relationship_name;
    this.id = json.id;
    this.last_updated_at = json.last_updated_at;
    this.programmeable = json.programmeable;
    this.hashid = json.hashid;
    this.mobile_verified = json['mobile_verified?'];
    this.email_verified = json['email_verified?'];
    this.name = json.name;
    if (json.activation_mail_date) {
      this.activation_mail_date = new Date(json.activation_mail_date);
    }
    if (json.max_length_name) {
      for (let _i = 0; _i < json.max_length_name; _i++) {
        this.name += ' '
      }
    }
    this.is_pchf = json.is_pchf;
    this.appointments_count = json.appointments_count;
    this.mobile_number_value = json.mobile_number_value;
    this.landline_number_value = json.landline_number_value;
    if (json.user && json.user.dob && (json.age || json.age == 0)) {
      this.age = this.calculate_age(json.user.dob.split('T')[0], json.user.dob_stage);
    }
    this.message = json.message;
    this.can_login = json.can_login;
    if (json.user) {
      this.user.load_from_json(json.user);
    }
    if ( json.user && json.user.profile_pic) {
      this.profile_pic = json.user.profile_pic;
    }
    if (json.family_members) {
      let family_members = json.family_members.map(family_member => new Patient().load_from_json(family_member));
      this.family_members = family_members;
    }
    this.success = json.success;
    this.textclass = json.textclass;
    if (json.user_email) {
      this.user_email = json.user_email
    }
    if (json.full_mrn_no) {
      this.mrn_no = json.full_mrn_no;
    }
    this.phonee =  json.phone;
    if (json.patient_address) {
      this.patient_address = json.patient_address;
    }
    if (json.valid_email) {
      this.valid_email = json.valid_email;
    }
    return this;
  }

  get phone() {
    return this.phonee || (this.user.phone && this.user.phone.value)
  }

  get address() {
    return this.patient_address || this.user.address.humanize();
  }

  set_secret_key(secret) {
    this.secret_key = secret;
  }

  to_params() {
    return {
      patient: {
        patient_type: this.patient_type,
        age_year: this.age_year,
        age_month: this.age_month,
        name: this.user.first_name + ' ' + this.user.last_name,
        gender: this.user.gender,
        id: this.id,
        hashid: this.hashid,
        mother_name: this.mother_name,
        parent_id: this.parent_id,
        relationship_id: this.relationship_id,
        can_login: this.can_login,
        dob: this.user.dob,
        title: this.title,
        patient_preferred_method: this.patient_preferred_method,
        user_attributes: this.user.to_params()
      }, secret_key: this.secret_key
    }
  }
  is_new_record() {
    return this.id == 0 || this.id == undefined;
  }
  get_url(): string {
    return this.is_new_record() ? 'patients/' : ['patients', this.id].join('/')
  }

  handle_dob_and_dob_stage(dob_and_stage) {
    if (dob_and_stage && dob_and_stage.length > 0) {
      this.user.dob = dob_and_stage[0];
      this.dob = dob_and_stage[0];
      this.user.dob_stage = dob_and_stage[1];
      if (dob_and_stage.length > 1) {
        this.age_month =  dob_and_stage[2]
      }
      if (dob_and_stage.length > 2) {
        this.age_year =  dob_and_stage[3]
      }
    }
  }

  handle_dob_and_dob_stage_pet(dob_and_stage) {
    if (dob_and_stage && dob_and_stage.length > 0) {
      this.user.dob_pet = dob_and_stage[0];
      this.dob_pet = dob_and_stage[0];
      this.user.dob_stage_pet = dob_and_stage[1];
    }
  }

  verified() {
    return this.email_verified && this.mobile_verified
  }

  patient_dob_year() {
    this.year_error = true;
    setTimeout(() => {
      this.year_error = false;
      this.success = '';
    }, 2000)
    return;
  }

  lead_custom_params() {
    return {
      patient: {
        patient_type: this.patient_type,
        name: this.name,
        gender: this.user.gender,
        id: this.id,
        hashid: this.hashid,
        mother_name: this.mother_name,
        parent_id: this.parent_id,
        relationship_id: this.relationship_id,
        can_login: this.can_login,
        dob: this.user.dob,
        dob_pet: this.user.dob_pet,
        title: this.title,
        breed_type: this.breed_type,
        user_attributes: this.user.lead_custom_to_params()
      }, secret_key: this.secret_key
    }
  }

  check_valid_dob(dob) {
    let age_month = parseInt(this.age_month);
    let age_year = parseInt(this.age_year);

    if (age_month > 0 || age_year > 0 ) {
      return false;
    }
    if (dob == '' || dob == undefined) {
      return true;
    }
    let year, month, day;
    let d = new Date();
    let current_year = d.getFullYear();
    let current_month = d.getMonth() + 1;
    let current_date = d.getDate();

    [year, month, day] = dob.split('-');
    if (year == current_year && month > current_month) {
      return true
    }
    else if (year == current_year && month == current_month && day > current_date) {
      return true;
    } else {
      return false;
    }
  }

  calculate_age(dob, dob_stage) {
    let one_day, current_date, patient_dob, no_of_days, remaining_days, no_of_years, calculate_month;
    one_day = 1000 * 60 * 60 * 24;
    current_date = new Date();
    patient_dob = new Date(dob);
    no_of_days = Math.round(Math.abs((current_date.getTime() - patient_dob.getTime()) / (one_day)));

    if (no_of_days <= 30) {
      this.age = no_of_days == 30 ? '1 Mon' : no_of_days + ' Days'
    } else if (no_of_days <= 60) {
      calculate_month = Math.floor(no_of_days / 30);
      this.age = calculate_month + ' Mons';
    } else if (no_of_days <= 730 || no_of_days <= 731) {
      if (no_of_days == 365 || no_of_days == 366) {
        this.age = '1 Yr';
      } else if (no_of_days > 365) {
        remaining_days = no_of_days - 365;
        calculate_month = Math.floor(remaining_days / 30);
        calculate_month = this.boundary_case_month(calculate_month)
        this.age = '1 Yr ' + calculate_month;
      } else if (no_of_days < 365) {
        calculate_month = Math.floor(no_of_days / 30);
        calculate_month = this.boundary_case_month(calculate_month)
        this.age = calculate_month;
      }

    } else if (no_of_days > 730) {
      this.age = Math.floor(this.calculate_years(patient_dob)) + ' Yrs';
    }
    return this.age;
  }

  boundary_case_month(calculate_month) {
    calculate_month = calculate_month == 0 ? '' : (calculate_month == 12 ? '11 Mons' : calculate_month + ' Mons');
    return calculate_month;
  }

  calculate_years(birthday) {
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  show_age_and_gender() {
    return `${this.age} - ${this.gender_for_display()}`;
  }

  get email() {
    return this.user_email || this.user.email;
  }

  gender_for_display() {
    switch (this.gender) {
      case 'non_binary':
        return 'Genderqueer/Non-binary';
      case 'trans_male':
        return 'Transgender male/Trans man/Female-to-male';
      case 'trans_female':
        return 'Transgender female/Trans woman/Male-to-female';
      case 'decline':
        return 'Decline to answer'
      default:
        return this.gender;
    }
  }

}
