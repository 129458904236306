import { Component, OnInit } from '@angular/core';
import {TabsService} from "../../../services/tabs.service";

@Component({
  selector: 'app-amenity',
  templateUrl: './amenity.component.html',
  styleUrls: ['./amenity.component.scss']
})
export class AmenityComponent implements OnInit {

  constructor(public tabs_service: TabsService) {
    this.tabs_service.register('Email Marketing')
  }

  ngOnInit(): void {
  }

}
