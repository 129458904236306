import { Component, OnInit, ViewChild } from '@angular/core';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  @ViewChild('classicModal') classicModal: ModalDirective;
  @ViewChild('classicModalMessage') classicModalMessage: ModalDirective;

  constructor(public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit() {

  }
  clearErrors() {
    this.globalErrorHandlerService.error = null;
    this.classicModal.hide();
    this.globalErrorHandlerService.message = null;
    this.classicModalMessage.hide();

  }
}
