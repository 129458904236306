<div class="col-md-3 col-sm-4 col-xs-12">
  <div class="btn-group">
    <button type="button" class="btn btn-secondary btn-outline" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
      <i class="fa fa-chevron-left"></i>
    </button>
    <button type="button" class="btn btn-secondary btn-outline" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
      Today
    </button>
    <button  *ngIf="view == 'day'" type="button" class="btn btn-secondary btn-outline"
             (bsValueChange)="onValueChange($event)"
             bsDatepicker
             [bsConfig]="{ customTodayClass: 'today-date-select', showWeekNumbers:false }">
      <i class="fa icon-calendar fs14"></i>
    </button>
    <button type="button" class="btn btn-secondary btn-outline"
            mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChange.next(viewDate)">
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
</div>
<div class="col-md-6 col-sm-4 col-xs-12"><h4 class="m0 text-center">{{ viewDate | calendarDate: view + 'ViewTitle' : locale }}</h4></div>
<div class="col-md-3 col-sm-4 col-xs-12 text-right">
  <div class="btn-group">
    <button type="button" class="btn btn-secondary btn-outline" (click)="viewChange.emit(CalendarView.Month); viewDateChange.next(viewDate)" [class.active]="view === CalendarView.Month">
      Month
    </button>
    <button type="button" class="btn btn-secondary btn-outline" (click)="viewChange.emit(CalendarView.Week); viewDateChange.next(viewDate)" [class.active]="view === CalendarView.Week">
      Week
    </button>
    <button type="button" class="btn btn-secondary btn-outline" (click)="viewChange.emit(CalendarView.Day); viewDateChange.next(viewDate)" [class.active]="view === CalendarView.Day">
      Day
    </button>
  </div>
</div>
