import {Component, Input, OnInit} from '@angular/core';
import {Term} from '../../../../../enote/enote_models/term';

@Component({
  selector: 'app-allergies-table',
  templateUrl: './allergies-table.component.html',
  styleUrls: ['./allergies-table.component.scss']
})
export class AllergiesTableComponent implements OnInit {
  @Input() allergies: Term[];

  ngOnInit(): void {
    this.allergies = this.allergies.map(term => new Term('', '').load_from_json2(term));
  }

  constructor() {
  }

}
