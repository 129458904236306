import {Component, Input, OnInit} from '@angular/core';
import {Patient} from '../../../health/doctor/patient';
import {PatientService} from '../../../services/patient.service';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';

@Component({
  selector: 'app-enrolled-patient-profile',
  templateUrl: './enrolled-patient-profile.component.html',
  styleUrls: ['./enrolled-patient-profile.component.scss']
})
export class EnrolledPatientProfileComponent implements OnInit {
  @Input() patient_id: any;
  public patient: any;

  constructor(public patientService: PatientService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    if (this.patient_id) {
      this.loaderService.show();
      this.patientService.getPatient(this.patient_id).then(resp => {
        this.patient = new Patient().load_from_json(resp);
      }).catch(resp => {
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      })
    }
  }

}
