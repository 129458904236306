<div class="filters-sec flex-header" *ngIf="tasks && !isPatientDetailPage">
  <div class="collapse-btns visible-sm visible-xs">
    <a class="btn btn-tertiary btn-outline mb" data-toggle="collapse" href="#collapsefilters">
      <span><i class="fa fa-filter mr-sm"></i> Filters</span>
      <i class="fa fa-chevron-down"></i>
    </a>
    <button class="btn btn-secondary mb" *userCan="'add,Tasks,home'" (click)="addNewTaskInPopup()">
      <i class="fa fa-plus mr-sm"></i> Create
    </button>
  </div>
  <div class="collapse" id="collapsefilters">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-12">
        <input class="form-control" [(ngModel)]="filter['keyword']" (keyup.enter)="filterTasks()" placeholder="Keyword">
      </div>
      <div class="col-lg-2 col-md-3 col-sm-12">
        <select placeholder="Assigned To" #assigned_to class="form-control" (change)='selectAssignedTo(assigned_to)'>
          <option value="" selected>Assigned To</option>
          <option *ngFor="let user of users; let i = index;" [value]="user.id">
            {{user.full_name}}
          </option>
        </select>
      </div>
      <div class="col-md-1 col-sm-6" style="min-width:100px;">
        <select #priority class="form-control" (change)='selectPriority(priority)'>
          <option selected value="">Priority</option>
          <option *ngFor="let priority of priorities; let i = index;" [value]="priority">
            {{priority}}
          </option>
        </select>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-6">
        <ss-multiselect-dropdown [options]="myOptions"
                                 [(ngModel)]="optionsModel"
                                 [settings]="selectSettings"
                                 [texts]="myTexts"
                                 (ngModelChange)="selectStatuses($event)">
        </ss-multiselect-dropdown>
      </div>
      <div class="col-md-2 col-sm-6">
        <select #due_date class="form-control" [(ngModel)]="this.filter['due_date']">
          <option selected value="">Due Date</option>
          <option *ngFor="let due_date of due_date_filter; let i = index;" [value]="due_date">
            {{userService.removeUnderScore(due_date)}}
          </option>
        </select>
      </div>
      <div class="col-md-1 col-sm-12 col-xs-12">
        <button class="btn btn-tertiary btn-outline" (click)="filterTasks()"><i class="fa fa-filter"></i> Filter</button>
        <a class="btn btn-tertiary btn-outline ml visible-sm-inline-block visible-xs-inline-block" data-toggle="collapse" href="#collapsefilters">
          Close
        </a>
      </div>
      <div class="col-md-1 pull-right hidden-sm hidden-xs">
        <button class="btn btn-secondary ml pull-right" *userCan="'add,Tasks,home'" (click)="addNewTaskInPopup()">
          <i class="fa fa-plus mr-sm"></i> Create
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!newTask" class="task-panel flex-body">
  <div class="flex-control" *ngIf="!isPatientDetailPage">
    <app-tasksleftpanel class="flex-left scroll-browser" *ngIf="!isPatientProfile" [filter]="filter" [task_count]="task_count" (updateTasks)="updateTasksArray($event)"></app-tasksleftpanel>
    <div class="flex-body flex-control">
      <div class="flex-body scroll-browser hor-scroll-browser">
        <table class="table table-hover m0">
          <thead>
          <tr>
            <th style="width:30%;">Task</th>
            <th >For</th>
            <th >By</th>
            <th *ngIf="!isPatientProfile">Patient</th>
            <th  class="text-center">Priority</th>
            <th  class="text-center">Due Date</th>
            <th  class="text-center">Status</th>
            <th style="min-width:9%;" >Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="tasks && tasks.length == 0"><td colspan="12"><app-no-result-found></app-no-result-found></td></tr>
          <ng-container *ngFor="let task of tasks">
            <app-taskslisting class="tr" (update)="viewTask($event)" [task]="task" [patient]="patient" [isPatientProfile]="isPatientProfile" [isProgramDashboard]="isProgramDashboard"></app-taskslisting>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div class="flex-footer text-center" *ngIf="tasks && +totalItems > 10" >
        <pagination class="m0" [totalItems]="totalItems" [maxSize]="4" [itemsPerPage]="10" (pageChanged)="pageChanged($event)"></pagination>
      </div>
    </div>
  </div>




  <ng-container *ngIf="isPatientDetailPage">
    <div class="scroll-browser">
      <ul class="list-unstyled border-list collapse-list m0">
        <ng-container *ngFor="let group of groupTaskCollapsed">
          <li class="list">
            <a class="title" (click)="toggleCollapse(group)" [attr.aria-expanded]="!group.isCollapsed" [attr.aria-controls]="group.name">
              {{userService.removeUnderScore(group.name)}}
              <span class="label-secondary label" *ngIf="getGroupTaskLength(group.name) > 0">
            {{getGroupTaskLength(group.name)}}
          </span>
            </a>
            <div [@collapseExpand]="group.isCollapsed ? 'collapsed' : 'expanded'" class="bg-lightest-gray scroll-browser hor-scroll-browser" id="{{group.name}}">
              <div *ngIf="!group.isCollapsed">
                <div>
                  <div>
                    <div class="flex-body">
                      <table class="table table-hover m0">
                        <thead>
                        <tr>
                          <th style="width:38%;">Task</th>
                          <th style="width:12%;">By</th>
                          <th style="width:12%;" class="text-center">Priority</th>
                          <th style="width:15%;" class="text-center">Due Date</th>
                          <th style="width:12%;" class="text-center">Status</th>
                          <th style="min-width:9%;" class="pr-sm">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngIf="getGroupTaskLength(group.name) == 0">
                          <td colspan="12" class="pd-5"><app-no-result-found></app-no-result-found></td>
                        </tr>
                        <ng-container *ngIf="getGroupTaskLength(group.name) > 0">
                          <ng-container *ngFor="let task of groupTask[group.name]">
                            <app-taskslisting class="tr" (update)="viewTaskForProgram($event)" [task]="task" [patient]="patient" [isPatientDetailPage]="true" [isPatientProfile]="'true'"></app-taskslisting>
                          </ng-container>
                        </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="newTask">
  <app-newtask [task]="task" (closeform)="closeNewForm($event)"></app-newtask>
</ng-container>

<div class="modal fade" bsModal #newTaskModel="bs-modal" [config]="{backdrop:'static'}" tabindex="-1">
  <div class="modal-dialog" style="height:608px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="newTaskModel.hide(); show_task_model = false;"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Create Task</h4>
      </div>
      <div class="modal-body" style="position:initial;" *ngIf="show_task_model">
        <ng-container *ngComponentOutlet="newTaskComponent; injector: custom_injector;"></ng-container>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #editTaskModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-lg" style="height:616px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="editTaskModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title text-center">Task</h4>
      </div>
      <div class="program-task" *ngIf="show_view_task_model">
        <ng-container *ngComponentOutlet="ediTaskComponent;injector: customInjectorForPopup;"></ng-container>
      </div>
    </div>
  </div>
</div>
