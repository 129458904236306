import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../../../../services/loader.service';
import {SmsService} from '../../../../routes/practice-site/sms/sms.service';
import {Conversation} from '../../../../routes/practice-site/sms/conversation';

@Component({
  selector: 'app-enrolled-patient-sms',
  templateUrl: './enrolled-patient-sms.component.html',
  styleUrls: ['./enrolled-patient-sms.component.scss']
})
export class EnrolledPatientSmsComponent implements OnInit, OnDestroy {
  @Input() patient: any;
  public show_im = '';

  constructor(public smsService: SmsService, public loaderService: LoaderService) {
  }

  ngOnInit(): void {
    if (this.patient.phone) {
      this.getSMSConversation();
    } else {
      this.show_im = 'This patient does not have a valid mobile phone number.';
    }
  }

  getSMSConversation() {
    this.loaderService.show();
    this.smsService.getPatientConversation(this.patient.id).then(resp => {
      this.smsService.selected_conversation = new Conversation().load_from_json(resp);
      this.toggleChat();
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.smsService.selected_conversation = this.smsService.createTempConversations(this.patient);
    });
  }
  toggleChat() {
    if (this.smsService.selected_conversation && this.smsService.selected_conversation.unread_count > 0) {
      this.smsService.restartUnreadCount(this.smsService.selected_conversation.id)
    }
  }

  ngOnDestroy(): void {
    this.smsService.selected_conversation = new Conversation();
  }

}
