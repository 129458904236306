import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Appointment} from '../../../health/models/appointment';
import {AppointmentService} from '../../../services/appointment.service';
import {Tab} from '../../../tabs/tab';
import {PatientProfileComponent} from '../../../health/patient-profile/patient-profile.component';
import {TabsService} from '../../../services/tabs.service';
import {UserService} from '../../../services/user.service';
import {PhoneService} from '../../../voice-call/phone.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
  @Input() appointments: Appointment[];
  public params: Object = {reason_of_cancellation: '', appointments_ids: [], status_id: ''};
  @Input() totalItems;
  @Input() providers;
  public show_doctor_dropdown = false;
  public selected_appointment_id: any;
  @Output() page: EventEmitter<any> = new EventEmitter<any>();
  constructor(private _appointmentService: AppointmentService,
              private tabs_service: TabsService,
              public _userService: UserService,
              public phoneService: PhoneService) {
  }
  ngOnInit() {
  }
  acknowledge(appointment) {
    let appointments = [];
    appointments[0] = appointment;
    this.params['status_id'] = 'scheduled';
    this.params['appointments_ids'] = Appointment.getIds(appointments);
    this.acknowledge_cancel(appointment);
  }
  cancel(appointment) {
    let appointments = [];
    appointments[0] = appointment;
    this.params['status_id'] = 2;
    this.params['reason_of_cancellation'] = appointment.reason_of_cancellation;
    this.params['appointments_ids'] = Appointment.getIds(appointments);
    this.acknowledge_cancel(appointment);


  }
  acknowledge_cancel(appointment) {
    this._appointmentService.acknowledge_cancel(this.params).then(resp => {
      this.appointments.splice(this.appointments.indexOf(appointment), 1);
    })
  }
  update(appointment) {
    this._appointmentService.update(this.params).then(resp => {
      this.appointments.splice(this.appointments.indexOf(appointment), 1);
    })
  }
  pageChanged(event) {
    this.page.emit(event.page);
    this.params['page'] = event.page;
  }

  add_to_tabs_patient_profile(appointment) {
    let title = appointment.patient_name() + '-' + 'Profile';
    let id = appointment.patient_id;
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa fa-hospital'}, PatientProfileComponent, id, {id: id, appointment: appointment}))
  }

  providerSelected(doctorID) {
    this._appointmentService.setDoctorID(this.selected_appointment_id, doctorID).then(resp => {
      this.appointments.filter(appointment => appointment.id == this.selected_appointment_id)[0].load_from_json(resp);
      this.show_doctor_dropdown = !this.show_doctor_dropdown;
    })
  }
  showDropDown(appointment) {
    if (appointment.appointment_with == 'doctor' || !appointment.appointment_with) {
      return true;
    }
    this.show_doctor_dropdown = !this.show_doctor_dropdown;
    this.selected_appointment_id = appointment.id;
  }
}
