import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MedicalCenter} from '../medical-center';
import {MedicalCenterService} from '../medical-center.service';
import {toLower} from 'lodash';
import {LoaderService} from '../../../../services/loader.service';
import {AlertService} from '../../../../_alert';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-medical-center',
  templateUrl: './medical-center.component.html',
  styleUrls: ['./medical-center.component.scss']
})
export class MedicalCenterComponent implements OnInit {
  @Input() medicalCenter: MedicalCenter = new MedicalCenter();
  @Output() addMedicalCenterEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public medicalCenterService: MedicalCenterService, public loaderService: LoaderService,
              public alertService: AlertService, private globalErrorHandlerService: GlobalErrorHandlerService,
              public userService: UserService) { }

  ngOnInit(): void {
  }

  save() {
    this.loaderService.show();
    this.medicalCenter.center_type = toLower(this.medicalCenter.center_type);
    this.medicalCenterService.save(this.medicalCenter).then( resp => {
        this.medicalCenter = new MedicalCenter();
        this.loaderService.hide();
        this.addMedicalCenterEmitter.emit('get');
        this.alertService.success('Medical Center has been saved successfully', this.alertService.options);
      }
    ).catch(resp => {
        console.log(resp);
        this.loaderService.hide();
        this.globalErrorHandlerService.error = resp.error.error;
      }
    )

  }
}
