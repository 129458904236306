import {Control, Value} from '../enote_models/control';
import {IMultiSelectOption} from 'angular-2-dropdown-multiselect';
import {TermControl, TermControlValue} from './term_control';
import {Keyword} from './keyword';
import {EnoteService} from '../../services/enote.service';
import * as _ from 'lodash';
export class Term {
  public static object_counter = 0;
  public myOptions: IMultiSelectOption[];
  public optionsModel: string[];
  public control_ids = '';
  public term_type: string;
  show_keyword_result = false;
  showComponent = false;
  show_control_typeahead = false;
  show_typeahead = false;
  id: number;
  public identifier = 0;
  index_counter = 0;
  name = 'a';
  open_text = '';
  focus_on_init = false;
  initial_component = false;
  token = '';
  owner_id: number;
  parent_id: number;
  _destroy = false;
  parent_library_datum_id: number;
  parent_term: Term;
  updated_by_id: number;
  term_code: number;
  is_active = true;
  is_selected = false;
  show_text = true;
  is_focus = false;
  component_type = 'component'; // Component, Term, Keyword
  is_open_text = false;
  library_datum_id: number;
  children: Term[] = [];
  children_groups = [];
  public force_search = true;
  controls: Control[] = [];
  term_controls: TermControl[] = [];
  template_stage: string;
  control_value_class: string;
  term_custom_controls: TermControl[];
  default_control_default_value_applied = true;
  component_level = 0;
  enable_custom_control = false;
  keyword: Keyword;
  library_id: number;
  created_at: string;
  enote_keyword_id: number;
  d_name: string;
  status: string;
  icd_codes: any;
  ps_id: number;
  data_type: string;
  last_updated_at: string;
  is_chronic: boolean;
  diagnosis_type = '';
  constructor(name, term_code) {
    this.name = name;
    this.term_code = term_code;
    Term.object_counter += 1;
    this.identifier = Term.object_counter;
  }
  focusControl() {
    this.is_focus = true;
  }
  get check_keyword_total_count() {
    if (this.term_type === 'term') {
      return false;
    }
    return this.keyword.child_keywords.reduce((a, b) => a + b.result_count, this.keyword.result_count) === 0 ? true : false
  }

  load_diagnosis_term(json) {
    this.component_type = json.component_type;
    this.controls = json.controls;
    this.library_datum_id = json.library_datum_id;
    this.library_id = json.library_id;
    this.name = json.name;
    this.d_name = json.d_name;
    this.parent_library_datum_id = json.parent_library_datum_id;
    this.term_type = json.term_type;
    return this
  }
  load_from_json2(json, parent_term?, parent_term_position?, enoteService?: EnoteService) {
    this.created_at = json.created_at;
    this.last_updated_at = json.last_updated_at;
    this.force_search = false;
    this.id = json.id;
    this.library_id = json.library_id;
    this.is_open_text = json.is_open_text;
    this.name = json.name;
    this.d_name = json.d_name;
    this.term_type = json.term_type;
    this.data_type = json.data_type;
    this.is_chronic = json.is_chronic;
    this.diagnosis_type = json.diagnosis_type;
    if (json.icd_codes) {
      this.icd_codes = json.icd_codes;
    }
    if (json.status) {
      this.status = json.status;
    }
    if (json.ps_id) {
      this.ps_id = json.ps_id;
    }
    if (json.component_type) {
      this.component_type = json.component_type;
    }
    if (parent_term) {
      this.parent_term = parent_term;
      if (this.isComponentTypeChild() && !this.is_open_text) {
        this.setComponentLevel(parent_term);
      }
    }
    this.owner_id = json.controlable_type;
    this.initial_component = json.initial_component;
    this.open_text = json.open_text;
    this.parent_id = json.parent_id;
    this.updated_by_id = json.control_code;
    this.parent_library_datum_id = json.parent_library_datum_id;
    this.is_active = json.is_active;
    this.term_code = json.term_code;
    this.updated_by_id = json.updated_by_id;
    this.is_active = json.is_active;
    this.is_selected = json.is_selected;
    this.library_datum_id = json.library_datum_id;
    if (json.keyword) {
      this.keyword = new Keyword(enoteService).load_from_json(json.keyword);
      this.enote_keyword_id = this.keyword.id;
    }
    if (json.children && json.children.length > 0) {
      this.children = json.children.map(child_term => new Term('', '').load_from_json2(child_term, this, null, enoteService));
      this.splitChildren();
    }
    if (this.isComponentTypeChild() && json.controls && json.controls.length > 0) {
      this.controls = json.controls.map(control => new Control('', '').load_from_json(control));
    }
    if (json.term_controls && json.term_controls.length > 0) {
      this.term_controls = json.term_controls.map(term_control => new TermControl().load_from_json(term_control));
    }
    if (this.isTermTypeChild()) {
      this.term_controls.forEach(term_control => {
        if (term_control.isDefualtTermControl()) {
          this.set_control_value_class(term_control)
        }
      });
    }
    this.term_custom_controls = this.term_controls.filter(term_control => term_control.control.isCustomControl());
    this.check_default_value();
    if (!this.isComponentTypeChild()) {
      this.checkControlValue();
    }
    if (this.is_open_text || this.initial_component) {
      this.showComponent = true;
    }
    console.log(this, this.name, this.component_type, this.showComponent);
    return this;
  }

  setComponentLevel(parent_term) {
    if (parent_term) {
      this.setComponentLevel(parent_term.parent_term);
    }
    this.component_level += 1;
  }


  checkControlValue() {
    if (this.showTerm()) {
      this.showComponent = true;
      this.parent_term?.markParentComponent();
    }
  }

  markParentComponent() {
    if (this.parent_term) {
      this.parent_term.markParentComponent();
    }
    this.showComponent = true
  }





  load_from_json(json, parent_term?, parent_term_position?, service?) {
    this.library_id = json.library_id;
    this.force_search = false;
    if (json.component_type) {
      this.component_type = json.component_type;
    }
    this.name = json.name;
    this.d_name = json.d_name;
    if (json.status) {
      this.status = json.status;
    }
    if (json.ps_id) {
      this.ps_id = json.ps_id;
    }
    if (parent_term) {
      this.parent_term = parent_term;

    }
    if (json.keyword) {
      this.keyword = new Keyword(service).load_from_json(json.keyword);
      this.enote_keyword_id = this.keyword.id;
    }
    this.term_type = json.term_type;
    this.owner_id = json.controlable_type;
    this.initial_component = json.initial_component;
    this.open_text = json.open_text;
    this.parent_library_datum_id = json.parent_library_datum_id;
    this.updated_by_id = json.control_code;
    this.is_active = json.is_active;
    this.term_code = json.term_code;
    this.updated_by_id = json.updated_by_id;
    this.is_active = json.is_active;
    this.is_selected = json.is_selected;
    this.library_datum_id = json.library_datum_id;
    if (json.children && json.children.length > 0) {
      let childs = [];
      json.children.map(child_term => {
        let open_text_same_line = new Term('', '');
        open_text_same_line.is_open_text = true;
        open_text_same_line.name = 'sameline';
        open_text_same_line.parent_term = this;
        open_text_same_line.parent_id = this.id;


        let open_text = new Term('', '');
        open_text.is_open_text = true;

        let empty_term = new Term('', '');
        empty_term.initial_component = true;
        empty_term.parent_term = this;
        empty_term.addToChildren(open_text, 0);

        childs.push(open_text_same_line);
        childs.push(empty_term);
        childs.push(new Term('', '').load_from_json(child_term, this));
        if (service) {
          service.active_term = open_text_same_line;
        }
      });
      this.children = childs;
      this.splitChildren();
    } else {
      let open_text_term = new Term('', '');
      open_text_term.is_open_text = true;
      if (service) {
        service.active_term = open_text_term;
      }
      if (this.isTermTypeChild()) {
        this.parent_term.addToChildren(open_text_term, parent_term_position ? parent_term_position + 1 : 1);
      } else {
        this.addToChildren(open_text_term, 0);
      }

    }
    if (json.controls && json.controls.length > 0) {
      this.term_controls = json.controls.map(control => {
        let term_control = new TermControl();
        term_control.control_id = control.id;
        term_control.control = new Control('', '').load_from_json(control);
        return term_control;
      });
    }
    if (this.isTermTypeChild()) {
      this.copyTermControls();
    }
    return this;
  }

  copyTermControls() {
    this.term_controls = this.componentTermControls().map(compTermControl => {
      let term_term_control = new TermControl();
      term_term_control.control_id = compTermControl.control_id;
      term_term_control.control = new Control('', '').load_from_json(compTermControl.control);
      if (compTermControl.control.isDefaultControl()) {
        term_term_control.copyDefautlControlDefaultValue(compTermControl);
      }
      return term_term_control;
    });
  }

  lastChild(index?) {
    let active_childs = this.children.filter(a => !a._destroy);
    return active_childs.length === 0 ? undefined : active_childs[(index || active_childs.length) - 1]
  }

  to_params(order?) {
    return {
      id: this.id,
      name: this.name,
      library_id: this.library_id,
      type: this.isComponentTypeChild() ? 'Enote::Component' : 'Enote::Term',
      library_datum_id: this.library_datum_id,
      term_code: this.term_code,
      owner_id: this.owner_id,
      _destroy: this._destroy,
      updated_by_id: this.updated_by_id,
      is_active: this.is_active,
      is_selected: this.is_selected,
      term_type: this.term_type,
      parent_id: this.parent_id,
      d_order: order ? order : this.term_position(),
      open_text: this.open_text,
      initial_component: this.initial_component,
      is_open_text: this.is_open_text,
      is_chronic: this.is_chronic,
      enote_keyword_id: this.enote_keyword_id,
      enote_base_term_controls_attributes: this.term_controls.map((term_control, index) => term_control.to_params(index)),
      enote_base_terms_attributes: (this.children && this.children.length > 0) ? this.children.map(term => term.to_params()) : []
    }
  }

  hasChildren() {
    return (this.children && this.children.length > 0)
  }

  get open_text_uncles() {
    if (this.is_open_text && this.parent_term && this.parent_term.parent_term) {
      return this.parent_term.parent_term.children.filter(c => !c._destroy).map((child) => child.library_datum_id);
    }
    return []
  }

  hideTypeAhead() {
    this.show_typeahead = false;
    this.show_text = true;
    return;
  }

  get splitIndexes() {
    return this.children.reduce(function (accum, val, index) {
      if (val.initial_component && !val._destroy) {
        accum.push(index);
      }
      return accum;
    }, []);
  }

  public splitChildren() {
    let indexes = this.splitIndexes;
    this.children_groups.length = 0;
    let previous = 0;
    for (let i = 0; i < indexes.length; i++) {
      this.children_groups.push(this.children.slice(previous, indexes[i]).filter(c => !c._destroy));
      previous = indexes[i];
    }
    this.children_groups.push(this.children.slice(previous).filter(c => !c._destroy));
    return this.children_groups;
  }

  get defaultTermControl() {
    return this.term_controls.filter(term_control => term_control.isDefualtTermControl())[0];
  }
  get controlText() {
    let text =  this.defaultTermControl?.custom_text || this.name || this.keyword.name.toUpperCase();
    return text?.trim();
  }

  get color() {
    if (this.is_open_text) {
      return '';
    }
    return this.defaultTermControl.color
  }

  showTypeahead() {
    this.show_typeahead = true;
    this.show_text = false;
    return;
  }

  deleteYourself() {
    this.children.forEach(child => child._destroy = true);
    this._destroy = true;
    if (!this.parent_term) {
      return true;
    }
    if (!this.parent_term.parent_term) {
      this.parent_term.force_search = true;
    }
    this.parent_term.splitChildren();
    this.splitChildren();
  }

  addToChildren(term, current_term_index) {
    term.parent_term = this;
    term.parent_id = this.id;
    this.children.splice(current_term_index, 0, term);
    this.splitChildren();
  }

  term_position() {
    if (!this.parent_term) {
      return 0;
    }
    return this.parent_term.children.indexOf(this);
  }

  newLiner() {
    return !this.isComponentTypeChild() && this.parent_term &&
      _.indexOf(['prescription', 'interventions', 'goals', 'tasks'], _.trim(this.parent_term.name.toLowerCase())) !== -1
  }

  isAlreadySelected() {
    return this.is_selected;
  }

  addNewControlToTerm() {
    let new_control = new Control('+', 0);
    this.controls.push(new_control);
    return this;
  }

  getDefaultControl() {
    return this.controls.filter(e => e.id === undefined);
  }

  specificControlFromList(control_id) {
    return this.controls.filter(e => e.id === control_id)
  }

  addTerm(data, _enote_service) {
    this.id = data.item.id;
    this.name = data.item.name;
    if (data.item.name === '+term') {
      return;
    }
    this.is_selected = true;
    let new_term = new Term('+term', 0);
    new_term.controls.push(_enote_service.initializeNewControl());
    _enote_service.template.terms.push(new_term);
    this.hideTypeAhead();
  }

  appliedControls() {
    return this.controls.filter(e => e.is_control_applied === true)
  }

  has_term_control_values() {
    return this.term_custom_controls.filter(a => a.term_control_values.length > 0).length > 0
  }

  custom_term_control_with_values() {
    return this.term_custom_controls?.filter(a => a.term_control_values.length > 0)
  }

  isComponentTypeChild() {
    return this.component_type.toLowerCase() === 'component'
  }

  isTermTypeChild() {
    return this.component_type.toLowerCase() !== 'component'
  }

  seperator() {
    return this.isComponentTypeChild() ? ':' : ';'
  }

  getComponent() {
    if (this.component_type.toLowerCase() === 'component') {
      return this;
    }
    return this.parent_term && this.parent_term.getComponent();
  }

  componentTermControls() {
    let component = this.getComponent();
    if (component) {
      return component.term_controls;
    }
    return [];
  }

  set_control_value_class(term_control: TermControl) {
    console.log(term_control)
    if (this.term_controls.length > 0 && term_control.term_control_values.length > 0) {
      // tslint:disable-next-line:max-line-length
      this.control_value_class = term_control.term_control_values.filter(term_control_value => term_control_value.recent_value)[0].value.name;
    }
  }

  toggle_control_value(termControl: TermControl) {
    if (this.term_type === 'keyword') {
      return ;
    }
    if (this.isComponentTypeChild()) {
      return;
    }
    let current_value_index;
    termControl.control.values.forEach((value, index) => {
      if (value.selected_value) {
        value.selected_value = false;
        current_value_index = index;
      }
    });

    if ((termControl.control.values.length - 1) === current_value_index) {
      termControl.control.values[0].selected_value = true;
    }
    else {
      termControl.control.values[current_value_index + 1].selected_value = true;
    }
    this.term_controls.map(term_control => {
      if (term_control.control_id === termControl.control.id) {
        term_control.term_control_values.map(term_control_value => term_control_value.recent_value = false);
        let default_control_value = termControl.control.values.filter(value => value.selected_value)[0];
        // tslint:disable-next-line:max-line-length
        let recent_term_control_value = term_control.term_control_values.filter(term_control_value => term_control_value.control_value_id === default_control_value.id)[0];
        if (recent_term_control_value) {
          recent_term_control_value.recent_value = true;
          recent_term_control_value.value = default_control_value;
        }
        else {
          let term_control_value = new TermControlValue();
          term_control_value.recent_value = true;
          term_control_value.value = default_control_value;
          term_control_value.control_value_id = default_control_value.id;
          term_control.term_control_values.push(term_control_value)
        }
      }
      this.set_control_value_class(termControl);
      this.check_default_value();
    });
  }

  check_default_value() {
    let default_term_control = this.defaultTermControl;
    if (default_term_control === undefined) {
      return;
    }
    let recent_value = default_term_control.term_control_values.filter(value => value.recent_value)[0];
    // tslint:disable-next-line:max-line-length
    let term_control_value_property = default_term_control.term_control_value_properties.filter(a => a.control_value_id === recent_value.control_value_id)[0];
    if (term_control_value_property && term_control_value_property.enable_custom_control) {
      this.enable_custom_control = true;
    }
    else {
      this.enable_custom_control = false;
      this.deselectCustomControls();
    }
  }

  deselectCustomControls() {
    this.term_custom_controls.forEach(term_custom_control => {
      term_custom_control.term_control_values.forEach(term_control_value => {
        term_control_value.recent_value = false;
      })
    })
  }

  showTerm() {
    let selected_recent_value: Value;
    let term_default_control = this.defaultTermControl;
    let term_default_control_value: Value;
    if (term_default_control) {
      term_default_control_value = term_default_control.control.values.filter(value => value.default_value_flag)[0];
      this.term_controls.forEach(term_control => {
        let recent_value = term_control.term_control_values.filter(term_control_value => term_control_value.recent_value)[0];
        if (recent_value) {
          selected_recent_value = recent_value.value;
        }
      });
    }
    if (selected_recent_value && selected_recent_value.id === term_default_control_value.id) {
      return false;
    }
    else {
      return true;
    }
  }

  filterChild() {
    return this.children.filter(term => !term._destroy)
  }

  reIntiazlizeTermControlValues() {
    this.term_custom_controls.forEach( a => a.reIntiazlizeTermControlValues())
  }

  is_new_record() {
    return this.id === 0 || this.id === undefined;
  }

}
