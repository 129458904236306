import {BaseModel} from '../../shared/base';

export class AdditionalInfo extends BaseModel {
    id = 0;
    guardian_name = '';
    relation_with_patient = '';
    cnic = '';
    qualification = '';
    occupation = '';
    guardian_passport = '';
    mother_passport = '';
    mother_name = '';
    profession = '';
    brothers = '';
    sisters = '';
    religion = '';
    family_income = '';
    house = '';
    eligible_for = '';
    arrange_funds_for: boolean;
    volunteer_for_pchf: boolean;
    availability_for_marketing: boolean;
    patient_id = 0;

    to_params() {
        return {
            additional_info: {
                id: this.id,
                guardian_name: this.guardian_name,
                relation_with_patient: this.relation_with_patient,
                cnic: this.cnic,
                qualification: this.qualification,
                occupation: this.occupation,
                mother_passport: this.mother_passport,
                guardian_passport: this.guardian_passport,
                mother_name: this.mother_name,
                profession: this.profession,
                brothers: this.brothers,
                sisters: this.sisters,
                religion: this.religion,
                family_income: this.family_income,
                house: this.house,
                eligible_for: this.eligible_for,
                arrange_funds_for: this.arrange_funds_for,
                volunteer_for_pchf: this.volunteer_for_pchf,
                availability_for_marketing: this.availability_for_marketing,
                patient_id: this.patient_id,
            }
        }
    }

    load_from_json(json) {
        this.id = json.id;
        this.guardian_name = json.guardian_name;
        this.relation_with_patient = json.relation_with_patient;
        this.cnic = json.cnic;
        this.qualification = json.qualification;
        this.occupation = json.occupation;
        this.mother_passport = json.mother_passport;
        this.guardian_passport = json.guardian_passport;
        this.mother_name = json.mother_name;
        this.profession = json.profession;
        this.brothers = json.brothers;
        this.sisters = json.sisters;
        this.religion = json.religion;
        this.family_income = json.family_income;
        this.house = json.house;
        this.eligible_for = json.eligible_for;
        this.arrange_funds_for = json.arrange_funds_for;
        this.volunteer_for_pchf = json.volunteer_for_pchf;
        this.availability_for_marketing = json.availability_for_marketing;
        this.patient_id = json.patient_id;
        return this;
    }

    is_new_record() {
        return this.id == 0 || this.id == undefined;
    }

    get_url(): string {
        return this.is_new_record() ? 'practices/create_practice' : ['practices', this.id].join('/')
    }
}
