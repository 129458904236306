import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { HealthModule } from './health/health.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {AngularTokenModule, AngularTokenService} from 'angular-token';
import  {HttpTokenService } from './services/http.token.service'
import  {LoaderService } from './services/loader.service'
import  {UserService} from './services/user.service'
import {TabsService} from './services/tabs.service';
import {DataService} from './services/data.service';
import {GlobalErrorHandlerService} from './services/global-error.service';
import {HttpClientModule} from '@angular/common/http';
import {ModalModule} from 'ngx-bootstrap/modal';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PublicContentPagesModule} from './public-content-pages/public-content-pages.module';
import { RouterModule } from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReviewsModule } from './reviews/reviews.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {VideoChatModule} from './video-chat/video-chat.module';
import {RemindersModule} from './reminders/reminders.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {RoutePermissionGuardGuard} from './route-permission-guard.guard';
import {MarketingModule} from './marketing/marketing.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatIconModule } from '@angular/material/icon';
import {ProgramModule} from './program/program.module';
import {SeoService} from './services/seo.service';
import {VoiceCallModule} from './voice-call/voice-call.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {AlertModule} from './_alert';
import { GoogleMapsModule } from '@angular/google-maps';
import {VersionCheckService} from './services/version-check.service';
import { ReloaderComponent } from './app-reloader/reloader.component';

@NgModule({
  declarations: [
    AppComponent,
    ReloaderComponent
  ],
    imports: [
      GoogleMapsModule,
        NgIdleKeepaliveModule.forRoot(),
        MatIconModule,
        MatPasswordStrengthModule.forRoot(),
        AngularTokenModule.forRoot({
            apiBase: 'http://119.63.130.158:3010',
            apiPath: 'api/v1',
            signInPath: 'auth/sign_in',
            loginField: 'email',
            signInRedirect: '/',
            signInStoredUrlStorageKey: 'redirectTo',
            signOutPath: 'auth/sign_out',
            validateTokenPath: 'auth/validate_token',
            signOutFailedValidate: true,

            updatePasswordPath: 'auth',
            resetPasswordPath: 'auth/password',
            resetPasswordCallback: window.location.href
        }),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        GalleryModule.forRoot({ shortcuts: ['ctrl+s', 'meta+s'], disableSsrWorkaround: true }),
        TooltipModule.forRoot(),
        BrowserModule,
        CoreModule,
        LayoutModule,
        SharedModule,
        AppRoutingModule,
        PublicContentPagesModule,
        VideoChatModule,
        RoutesModule, BsDropdownModule.forRoot(),
        RemindersModule, AlertModule,
        MarketingModule,
        HttpClientModule, HealthModule, ScrollingModule, ReviewsModule, ProgramModule, NgSelectModule, FormsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }), VoiceCallModule,
    ],
  exports: [RouterModule, RemindersModule, MarketingModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    VersionCheckService, SeoService,
    UserService, HttpTokenService,
    GlobalErrorHandlerService, RoutePermissionGuardGuard,
    {
      provide: AngularTokenService,
      useClass: HttpTokenService
    }, LoaderService, TabsService, DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
