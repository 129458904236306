import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Comment} from '../comment';
import {UserService} from '../../../../services/user.service';
import {CommentService} from '../comment.service';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {EnrolmentsService} from '../../../../program/enrolments.service';
import {TrackingService} from '../../../../services/tracking.service';
import {AlertService} from '../../../../_alert';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() comments: Comment[];
  @Input() commentable_type;
  @Input() commentable_id;
  @Input() commentable: any;
  @Input() programme_type: any;
  public comment: Comment = new Comment();
  public actionId: number;
  public copyCommentObject: Comment;

  constructor(public loaderService: LoaderService, public globalErrorHandlerService: GlobalErrorHandlerService,
              public userService: UserService, public commentService: CommentService,
              public enrolmentsService: EnrolmentsService, public trackingService: TrackingService,
              public alertService: AlertService) { }

  ngOnInit() {
  }

  delete_comment(comment) {
    this.loaderService.show();
    this.commentService.delete(comment.id).then(resp => {
      this.commentable.comment_count--;
      this.comments.splice(this.comments.indexOf(comment), 1);
      this.loaderService.hide();
      this.alertService.success('Comment has been deleted successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  clearComments() {
    this.comment.body = '';
  }

  editComment(comment) {
    this.copyCommentObject = new Comment().load_from_json(comment);
    if (this.commentable.source_type === 'ProgrammeEnrolment') {
      this.startAction('comment');
    }
    this.comments.forEach(c => c.edit_comment = false);
    comment.edit_comment = true;
  }

  cancelEditComment(comm: Comment): Comment {
    let c = this.comments.find(a => a.id === comm.id);
    c.load_from_json(this.copyCommentObject);
    c.edit_comment = false;
    this.copyCommentObject = new Comment();
    return c;
  }

  updateComment(comment) {
    this.commentService.save(comment).then(resp => {
      this.stopCommentAction(resp);
      let c_index = this.comments.indexOf(comment);
      if (c_index !== -1) {
        this.comments[c_index] = new Comment().load_from_json(resp);
      }
      this.alertService.success('Comment has been saved successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

  addComment() {
    this.loaderService.show();
    this.setCommentFields();
    this.commentService.save(this.comment).then(resp => {
      this.stopCommentAction(resp);
      this.commentable.comment_count++
      this.enrolmentsService.reloadActivityList.next(1);
      let comment = new Comment().load_from_json(resp);
      this.comments.push(comment);
      if (this.commentable_type === 'PatientEvent') {
        this.commentable.comments.push(comment);
      }
      this.loaderService.hide();
      this.alertService.success('Comment has been saved successfully', this.alertService.options);
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
    this.comment = new Comment();
  }

  setCommentFields() {
    this.comment.commenter_id = this.userService.current_user.id;
    this.comment.commenter_name = this.userService.current_user.full_name;
    this.comment.commenter = this.userService.current_user;
    this.comment.commentable_id = this.commentable_id;
    this.comment.commentable_type = this.commentable_type;
  }

  startAction(action) {
    this.actionId = this.trackingService.startTracking(action);
  }

  stopAction(comment: Comment) {
    const trackingResult = this.trackingService.stopTracking(this.actionId);
    this.trackingService.logTimeForPatientActivity({duration: trackingResult.duration, description: comment.body,
      activity_type: 'comment', source_id: comment.id, activiable_id: this.commentable.source_id, source_type: 'Comment',
      programme_type: this.programme_type})
  }

  stopCommentAction(response: any) {
    if (this.commentable.source_type === 'ProgrammeEnrolment') {
      this.stopAction(response);
    }
  }
}
