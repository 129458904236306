<nav class="bg-white" style="border-bottom:1px solid #EEE; padding:1.5em 0 0; margin-bottom:2em; height:85px;">
  <div class="container" style="max-width:700px;">
    <a class="text-black d-block" routerlink="/" href="/">
      <img class="img-rounded thumbnail thumb96 vl-m mr-xl" style="display:inline-block; box-shadow:0 0 12px #EEE;" [src]="practice_logo" alt="Practice">
      <span class="fs26" style="display:inline-block; vertical-align:top;">{{appointment_practice_name}}</span>
    </a>
  </div>
</nav>

<div class="container" style="max-width:700px;" *ngIf="!(end_review || requestPublicReview)">
  <div class="white-container-box p-xl mt-xl">
    <div *ngIf="patient_review.is_practice_review() || practice_submited">
      <div *ngIf="!patient_review.rating">
        <h3 class="mt0">How do you Rate your Practice?</h3>
      </div>
      <h4 class="mt0 fs20" style="letter-spacing:-.5px;" *ngIf="patient_review.rating">
        Thank you for rating  your overall experience as
        <label class="pl pr label label-primary vl-m fs20 ml" style="display:inline-block; min-width:40px;">{{patient_review.rating}}</label>
      </h4>
      <div class="review-star-ui mt-lg mb-lg"><rating [titles]="['Rate 1 star', 'Rate 2 star', 'Rate 3 star', 'Rate 4 star', 'Rate 5 star']" [(ngModel)]="patient_review.rating" [max]="5"></rating></div>
      <h4>Please tell us more about our services, providers and facilities.</h4>
      <textarea class="form-control mt-lg" rows="7"  [(ngModel)] = "patient_review.description" required></textarea>
    </div>

    <div *ngIf="patient_review.is_doctor_review() || doctor_submited">
      <div *ngIf="!patient_review.rating">
        <h3 class="mt0">How do you Rate {{appointment_doctor_name}}?</h3>
      </div>
      <h4 class="mt0 fs20" style="letter-spacing:-.5px;" *ngIf="patient_review.rating">
        Thank you for rating <span class="fw-500 pl-sm pr-sm">{{appointment_doctor_name}}</span> as
        <label class="pl pr label label-primary vl-m fs20 ml" style="display:inline-block; min-width:40px;">{{patient_review.rating}}</label>
      </h4>
      <div class="review-star-ui mt-lg mb-lg"><rating [titles]="['Rate 1 star', 'Rate 2 star', 'Rate 3 star', 'Rate 4 star', 'Rate 5 star']" [(ngModel)]="patient_review.rating" [max]="5"></rating></div>
      <h5>Any Feedback you want to give?</h5>
      <textarea class="form-control mt-lg" rows="7"  [(ngModel)] = "patient_review.description" required></textarea>
    </div>

    <div class="mt-lg">
<!--      <div *ngIf="practice_review_setup.review_type === 'both' else endReview">-->
<!--        <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>-->
<!--        <button (click)="save()"  *ngIf="!loaderService.uploading_in_process" [disabled]="!patient_review.rating" class="btn btn-secondary mr" >{{publicReviewApplicable()? "Continue" : "Submit"}}</button>-->
<!--      </div>-->

        <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin fs16 vl-m mr-sm"></i>Uploading...</a>
        <button (click)="save()"  *ngIf="!loaderService.uploading_in_process" [disabled]="!patient_review.rating" class="btn btn-secondary mr" >{{publicReviewApplicable()? "Continue" : "Submit"}}</button>

    </div>
  </div>
</div>

<div class="container" style="max-width:700px;" *ngIf="requestPublicReview">
  <div class="white-container-box p-xl mt-xl">
    <h3 class="text-success mt0">
      <i class="fa fa-star-o fa-fw fs30"></i> Thank you so much for a wonderful review!
    </h3>
    <hr>
    <h5 class="fs18 mb-lg">We will share this with our team as an appreciation of their amazing work!</h5>
    <p>You rated us {{this.patient_review.rating}} Star, here are your comments.</p>
    <p style="border:1px solid #DDD; border-radius:4px; padding:6px;">
      <i>"{{this.patient_review.description}}"</i>
    </p>
    <a class="text-secondary" (click)="copyToClipBoard(this.patient_review.description)">
      <i class="fa fa-copy"></i>&nbsp; Copy comments
    </a>
    <br><br>
    <p>Please copy these review comments and click on following button to post it on:</p>
    <ng-container *ngFor="let link of patient_review.selected_links">
      <a class="btn btn-secondary mb mr" target="_blank" [href]="link?.link_url">
        Post your review on {{link?.link_name}}
      </a>
    </ng-container>
    <br><br>
    <p>Have a nice day and stay healthy.</p>
    <button class="btn btn-secondary btn-outline" type="button" (click)="continueNextStep()">Cancel</button>
  </div>
</div>

<div class="container" *ngIf="end_review">
  <h2 class="alert alert-info text-center" style="margin:2em auto 0; max-width:700px; line-height:normal;">
    Thank you for your feedback.
  </h2>
</div>
