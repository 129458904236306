import { Component} from '@angular/core';
export  class Tab {
  tab_data: Object = {};
  autorefresh = true;
  active: boolean;
  component: Component;
  unique_id: any;
  dependencies: Object = {};

  constructor(tab_data: Object, component: any, id: any, dependencies?: Object) {
    this.tab_data = tab_data;
    if (typeof(tab_data['autorefresh']) != 'undefined') {
      this.autorefresh = tab_data['autorefresh'];
    }
    this.component = component;
    this.unique_id = id;
    this.dependencies = dependencies;
  }

}
