import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {LoaderService} from '../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../services/global-error.service';
import {PatientService} from '../../../../services/patient.service';
import {ProgramEnrollment} from '../../../../program/eligible-patient';
import {UserService} from '../../../../services/user.service';
import {ProgramService} from '../../../../program/program.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-patient-programs',
  templateUrl: './patient-programs.component.html',
  styleUrls: ['./patient-programs.component.scss']
})
export class PatientProgramsComponent implements OnInit, OnChanges {
  @Input() patient;
  public request_type;
  public selected_program_enrolment: ProgramEnrollment;
  public patient_program_enrollments: ProgramEnrollment[] = [];
  public today_date: any;
  public current_time: any;
  public date: Date = new Date();
  public selectedProgram: ProgramEnrollment;

  constructor(public patientService: PatientService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public userService: UserService,
              public programService: ProgramService) { }

  ngOnInit(): void {
    this.today_date = formatDate ( this.date, 'yyyy-MM-dd', 'en');
    this.current_time = formatDate ( this.date, 'HH:mm', 'en');
  }

  ngOnChanges() {
    this.getPatientProgram();
  }

  getPatientProgram() {
    this.loaderService.show();
    this.patientService.getPatientEnrolledPrograms(this.patient.id).then(resp => {
      this.patient_program_enrollments = resp.map(program => new ProgramEnrollment().load_from_json(program));
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  requestToCareTeam(type: string) {
    if (type == 'Opt Out') {
      this.sendOptOutRequest();
    } else {
      this.sendCallrequest(type);
    }
  }

  private sendOptOutRequest() {
    this.loaderService.show();
    this.patientService.sendOptOutRequestToProgramCareTeam(this.selected_program_enrolment.id).then( resp => {
      this.request_type = undefined;
      this.selected_program_enrolment = undefined;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  private sendCallrequest(type: string) {
    this.loaderService.show();
    this.patientService.sendCallRequestToProgramCareTeam({id: this.selected_program_enrolment.id, call_type: type}).then( resp => {
      this.request_type = undefined;
      this.selected_program_enrolment = undefined;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  saveConsentOfPatient(program: ProgramEnrollment) {
    this.loaderService.show();
    this.programService.saveConsentOfPatient(program.id,
      this.today_date, this.current_time, null,
      'mobile_portal').then( resp => {
      console.log(resp);
      this.getPatientProgram();
      this.selectedProgram = undefined;
    }).catch( resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    })
  }

}
