import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TabsService} from '../../../services/tabs.service';
import {LoaderService} from '../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';
import {UserService} from '../../../services/user.service';
import {RichTextTemplateService} from '../rich-text-template.service';
import {Subscription} from 'rxjs/Subscription';
import {RichTextTemplate} from '../rich-text-template';
import {PageChangedEvent} from 'ngx-bootstrap/pagination/pagination.component';
import {Tab} from '../../../tabs/tab';
import {RichTextTemplateComponent} from '../rich-text-template/rich-text-template.component';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-rich-text-templates',
  templateUrl: './rich-text-templates.component.html',
  styleUrls: ['./rich-text-templates.component.scss']
})
export class RichTextTemplatesComponent implements OnInit {
  public filter: Object = {token: '', type: undefined, status: undefined, page: 1, owner_id: undefined};
  public richTextTemplates: RichTextTemplate[] = [];
  public totalItems: any;
  public subscription: Subscription;
  typeaheadNoResultsTemplate = false;
  typeaheadLoadingTemplate = false;
  public templates: Observable<RichTextTemplate[]>;
  public template_search: string;
  @Input() isEnoteTemplate: boolean;
  @Output() enoteTemplateClick: EventEmitter<RichTextTemplate> = new EventEmitter<RichTextTemplate>();

  constructor(public tabs_service: TabsService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService, public userService: UserService,
              public richTextTemplateService: RichTextTemplateService) {
    this.subscription = this.richTextTemplateService.reload_rich_text_template_list.subscribe(item => {
      if (item) {
        this.getRichTextTemplates();
      }
    });
    this.templates = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.template_search);
    }).pipe(mergeMap((token: string) => this.importPublicTemplates(token)));
  }

  ngOnInit(): void {
    this.filter['owner_id'] = this.userService.current_user.isEnterprise ? undefined : this.userService.current_user.id;
    this.getRichTextTypes();
    if (this.isEnoteTemplate) {
      this.filter['type'] = 'clinical_note'
    }
    this.getRichTextTemplates();
  }

  getRichTextTemplates() {
    this.loaderService.show();
    this.richTextTemplateService.getAlertTemplates(this.filter).then(resp => {
      this.richTextTemplates = resp.richTextTemplates.map(richTextTemplate => new RichTextTemplate().load_from_json(richTextTemplate));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  search() {
    this.filter['page'] = 1;
    this.getRichTextTemplates();
  }

  getRichTextTypes() {
    this.richTextTemplateService.getRichTextTypes().then(resp => {
      this.richTextTemplateService.rich_text_types = resp;
    })
  }

  pageChanged(event: PageChangedEvent) {
    this.filter['page'] = event.page;
    this.getRichTextTemplates();
  }

  editRichTextTemplate(richTextTemplate: RichTextTemplate) {
    let title = (richTextTemplate.id ? 'Edit' : 'Add' ) + ' Rich Text Template'
    this.tabs_service.add_tab(new Tab({title: title, icon: 'fa'}, RichTextTemplateComponent, richTextTemplate.id,
      {id: richTextTemplate.id, richTextTemplate: richTextTemplate}))
  }

  addRichTextTemplate() {
    let richTextTemplate = new RichTextTemplate();
    this.editRichTextTemplate(richTextTemplate);
  }

  changeTypeaheadLoadingTemplate(e: boolean) {
    this.typeaheadLoadingTemplate = e;
  }

  changeTypeaheadNoResultsTemplate(e: boolean) {
    this.typeaheadNoResultsTemplate = e;
  }

  typeaheadOnTemplateSelect(template) {
    let richTextTemplate = new RichTextTemplate().load_from_json(template)
    richTextTemplate.id = undefined;
    richTextTemplate.status = 'private_template';
    richTextTemplate.owner_id = this.userService.current_user.id;
    richTextTemplate.is_import = true;
    this.editRichTextTemplate(richTextTemplate);
    this.template_search = undefined;
  }
  importPublicTemplates(token: any) {
    return this.richTextTemplateService.import_public_rich_templates(token);
  }

  openEnoteTemplate(richTextTemplate: RichTextTemplate) {
    this.enoteTemplateClick.emit(richTextTemplate);
  }
}
