import {Component, Input, OnInit} from '@angular/core';
import {PatientCommunicationLog} from '../patient-communication-log';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {PatientCommunicationLogService} from '../patient-communication-log.service';
import {Patient} from '../../../../doctor/patient';
import {LoaderService} from '../../../../../services/loader.service';
import {GlobalErrorHandlerService} from '../../../../../services/global-error.service';

@Component({
  selector: 'app-outreach-communication-log',
  templateUrl: './outreach-communication-log.component.html',
  styleUrls: ['./outreach-communication-log.component.scss']
})
export class OutreachCommunicationLogComponent implements OnInit {
  public filter: Object = {source: null, page: 1, from_date: '' , to_date : '', status: null, outreach_type: null, patient_id: ''};
  public bsValue = new Date();
  public show_popup = false;
  public selected_log: PatientCommunicationLog;
  public patientCommunicationLogs: PatientCommunicationLog[] = [];
  public totalItems;
  @Input() patient: Patient;


  constructor(public patient_communication_log_service: PatientCommunicationLogService, public loaderService: LoaderService,
              public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ngOnInit(): void {
    this.getPatientCommunicationLog();
  }

  search() {
    this.filter['page'] = 1;
    this.getPatientCommunicationLog();
    console.log('filter ', this.filter)
  }

  pageChanged($event: PageChangedEvent) {
    this.filter['page'] = $event.page;
    this.getPatientCommunicationLog();
  }

  getPatientCommunicationLog() {
    this.loaderService.show();
    this.filter['patient_id'] = this.patient.id;
    this.patient_communication_log_service.getCommunicationLog(this.filter).then(resp => {
      this.patientCommunicationLogs = resp.communicationLog.map(communicationLog => new PatientCommunicationLog().load_from_json(communicationLog));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

}
