<h4 class="mt0"><i class="fa icon-envelope vl-m"></i> SMTP Configuration for Sending Emails</h4>
<p class="m0 text-danger" *ngIf="delete_message">{{delete_message}}</p>

<div class="media" *ngIf="!editConfiguration && !emailConfiguration.id">
  <div class="media-body text-danger media-middle">
    Your email is not yet configured for sending emails. We are sending emails from info@wellwink.com.
    Configure your email in order to send emails using your practice email address.
  </div>
  <div class="media-right pl-xl">
    <button class="btn btn-inverse" (click)="editConfiguration =! editConfiguration" >Configure Email</button>
  </div>
</div>
<form class="mt-xl" (ngSubmit)="save()" #emailConfigurationForm="ngForm" *ngIf="editConfiguration">
  <div class="row form-group">
    <label class="col-sm-2 field-label control-label">Email ADDRESS/USERNAME</label>
    <div class="col-sm-4">
      <input class="form-control" id="user_name" [(ngModel)]="emailConfiguration.user_name" name="user_name" required />
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 field-label control-label">PASSWORD</label>
    <div class="col-sm-4">
      <input type="password" class="form-control" id="password" [(ngModel)]="emailConfiguration.password" name="password" required />
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 field-label control-label">SMTP SERVER</label>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm-5">
          <input class="form-control" [(ngModel)]="emailConfiguration.smtp_server" name="smtp_server" />
        </div>
        <div class="col-sm-2">
          <input type="number" class="form-control" [(ngModel)]="emailConfiguration.smtp_port" name="smtp_port"/>
        </div>
      </div>
    </div>
  </div>
  <div class="row form-group">
    <label class="col-sm-2 field-label control-label">OUTGOING DOMAIN</label>
    <div class="col-sm-4">
      <input class="form-control" id="outgoing_domain" [(ngModel)]="emailConfiguration.outgoing_domain" name="outgoing_domain" required />
    </div>
  </div>
  <div class="row form-group">
    <div class="col-sm-2 field-label control-label">AUTHENTICATION TYPE</div>
    <div class="col-sm-4">
      <select [(ngModel)]="emailConfiguration.authentication_type" name="authentication_type" class="form-control" required>
        <option value="">AUTHENTICATION TYPE</option>
        <option value='plain'>Plain</option>
        <option value='login'>Login</option>
        <option value='md5'>MD5</option>
      </select>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-sm-2 field-label control-label">ENABLE AUTO START TLS</div>
    <div class="col-sm-10">
      <input type="checkbox" class="checkbox" id="auto_start" [(ngModel)]="emailConfiguration.enable_auto_tls" name="auto_start"/>
      <div class="row mt-lg">
        <div class="col-sm-6 col-xs-12">
          <label class="fw-400">
            <input class="mr vl-m" id="ssl" type="radio" value="ssl" name="ssl" [(ngModel)]="emailConfiguration.connection_security" required>
            <span>Secured connection using SSL(recommended)</span>
          </label>
        </div>
        <div class="col-sm-6 col-xs-12">
          <label class="fw-400">
            <input class="mr vl-m" id="tls" type="radio" value="tls" name="tls" [(ngModel)]="emailConfiguration.connection_security" required>
            <span>Secured connection using TLS</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="row form-group mt-xl">
    <div class="col-sm-2 hidden-xs"></div>
    <div class="col-sm-10">
      <a *ngIf="loaderService.uploading_in_process" class="btn btn-default"><i class="fa fa-spinner fa-spin vl-m mr-sm"></i>Uploading...</a>
      <button type="submit" class="btn btn-primary mr" *ngIf="!loaderService.uploading_in_process" [disabled]="!emailConfigurationForm.form.valid">{{emailConfiguration.id ? 'Update' : 'Add'}} Account</button>
      <button  class="btn btn-inverse" (click)="editConfiguration =! editConfiguration">Cancel</button>
    </div>
  </div>
</form>

<p class="alert alert-success text-center p" *ngIf="message">{{message}}</p>

<div *ngIf="!editConfiguration && emailConfiguration.id">
  <span><label>Configured Email</label></span>
  <span class="pl ml" style="background:transparent; border:transparent; padding:1px; font-size:18px;"><a>{{emailConfiguration.user_name}}</a></span>
  <span class="pl ml"><a (click)="editConfiguration =! editConfiguration"><i class="fa fa-edit vl-m mr-sm fs14"></i></a></span>
  <span class="pl ml"><a (click)="deleteModal.show()"><i class="fa fa-trash vl-m mr-sm fs14"></i></a></span>
</div>
<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: false}" tabindex="-1">
  <div class="modal-dialog modal-md" style="height:200px;">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" (click)="deleteModal.hide()"><i class="fa fa-times fs16"></i></button>
        <h4 class="modal-title">Delete SMTP Account</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger p m0">Are You Sure?</div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="removeEmailConfiguration();deleteModal.hide()">Yes</button>
        <button type="submit" class="btn btn-inverse" (click)="deleteModal.hide();">No</button>
      </div>
    </div>
  </div>
</div>
