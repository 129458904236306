import { Component, OnInit } from '@angular/core';
import {TermComponent} from '../term/term.component';
import {EnoteService} from '../../services/enote.service';
import {LoaderService} from '../../services/loader.service';
import {BsModalService} from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-print-enote',
  templateUrl: './print-enote.component.html',
  styleUrls: ['./print-enote.component.scss']
})
export class PrintEnoteComponent extends TermComponent implements OnInit {

  constructor(public _enote_service: EnoteService, public modalService: BsModalService, public loaderService: LoaderService) {
    super(_enote_service, modalService, loaderService);
  }

  ngOnInit() {
  }

}
