import { Component, OnInit } from '@angular/core';
import {PracticesService} from '../../services/practices.service';
import {DoctorService} from '../../services/doctor.service';
import {Doctor} from '../practices/doctors/doctor';
import {TabsService} from '../../services/tabs.service';
import {UserService} from '../../services/user.service';
import {Location} from '../practices/locations/location';

@Component({
  selector: 'app-enterprise-appointments',
  templateUrl: './enterprise-appointments.component.html',
  styleUrls: ['./enterprise-appointments.component.scss']
})
export class EnterpriseAppointmentsComponent implements OnInit {
 public providers;
 public locations;
  constructor(private _userService: UserService, private doctorService: DoctorService, public tabs_service: TabsService) {
    this.tabs_service.register('enterprise_appointments');
  }

  ngOnInit() {
    if (this._userService.user_logged_in && this._userService.current_user && !this._userService.current_user.isEnterprise) {
    this.doctorService.getPracticeDoctors().then(resp => {
      if (resp && resp.is_doctor) {
        this.providers = resp.doctor.map(doctor => new Doctor().load_from_json(doctor))
        this.locations = resp.locations.map(location => new Location().load_from_json(location))
      } else {
          this.providers = resp.map(doctor => new Doctor().load_from_json(doctor))
      }
    })
    }
  }

}
