import {AfterViewChecked, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { CustomValidations } from '../../../routes/customValidations/custom-validations';
import { NgForm } from '@angular/forms';
import { Patient } from '../../doctor/patient';
import {PatientService} from '../../../services/patient.service';
import {GlobalErrorHandlerService} from '../../../services/global-error.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewChecked {

  public patient: Patient = new Patient();
  public customValidations: CustomValidations;
  public  show_verification = false;
  messageAfterSave = false;
  gender_error = false;
  show_error = false;
  @Output() patientCreated = new EventEmitter<boolean>();
  @ViewChild('SignUpForm') SignUpForm: NgForm;
  constructor(private _patient_service: PatientService, private globalErrorHandlerService: GlobalErrorHandlerService) {
    this.customValidations = new CustomValidations();
  }

  ngOnInit() {
    if (this.patient.user.gender === 'male' || this.patient.user.gender === 'female') {
      this.patient.user.gender = this.patient.user.gender
    } else {
      this.patient.user.gender = 'Gender';
    }
  }

  save() {
    if (this.patient.user.gender === 'Gender') {
      this.gender_error = true;
      setTimeout(() => {
        this.gender_error = false;
        this.patient.success = '';
      }, 2000)
      return;
    }

    if (this.patient.check_valid_dob(this.patient.dob)) {
      this.patient.patient_dob_year();
      return;
    }
    let is_password_match = this.check_password_match();
    if (is_password_match) {
      this.patient.user.email = this.patient.user.email.toLowerCase();
      this.patient.name = [this.patient.user.first_name, this.patient.user.last_name].join(' ');
      this._patient_service.save(this.patient, 'skip').then(res => {
        this.patientCreated.emit(true);
      }).catch(resp =>  {
        if (resp && resp.pending_signup) {
          this.show_verification = true;
        } else {
          this.globalErrorHandlerService.error = resp.error.error
        }
      } )
    }
  }

  check_password_match() {
    if (this.patient.user.password === this.patient.user.password_confirmation) {
      return true;
    }
    else {
      this.setTimeOutFailsMessage();
    }
  }

  setTimeOutFailsMessage(): any {
    this.show_error = true;
    setTimeout(() => {
      this.show_error = false;
    }, 3000)
  }
  ngAfterViewChecked() {
    this.customValidations.formChanged(this.SignUpForm);
  }

  callme() {
    this.ngAfterViewChecked();
  }
  handle_patient_verification(patient) {
    this.show_verification = false;
  }

}
