<!-- START Search form-->
<form class="navbar-form" role="search" action="search.html" [class.open]="visible" (submit)="handleForm()">
    <div class="form-group has-feedback">
      <ng-template  #customItemTemplate let-model="item" let-index="index" let-query="query">
        <div>
          <span [innerHTML]="patientAuthService.highlightQuery(model.complete_detail,query)"></span>
        </div>
      </ng-template>
      <input [(ngModel)]="asyncSelected" [typeahead]="dataSourcePatients" typeaheadWaitMs="500"
             [typeaheadItemTemplate]="customItemTemplate" [typeaheadAsync]="true"
             (typeaheadLoading)="changeTypeaheadLoading($event)"
             (typeaheadNoResults)="changeTypeaheadNoResults($event)"
             (typeaheadOnSelect)="typeaheadOnSelect($event)"
             [typeaheadOptionsLimit]="7"
             [typeaheadOptionField]="'complete_detail'"
             placeholder="Search Patient"
             name="patients" class="form-control" autocomplete="off" >
        <div class="fa fa-times form-control-feedback" (click)="closeNavSearch()"></div>
    </div>
    <button class="hidden btn btn-default" type="submit">Submit</button>
</form>
<!-- END Search form-->
