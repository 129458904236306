import { Injectable } from '@angular/core';
import {CustomHttpClient} from '../services/custom-http-client.service';
import {HttpParams} from '@angular/common/http';
import {IntegrationCredential} from './integration_credential';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(private _tokenService: CustomHttpClient) {}

  getPracticeIntegration(practice_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('practice_id', practice_id);
    return this._tokenService.get('pms_vendor_integration/practice_integration', {params: params})
      .toPromise()
      .then(response => response);
  }

  authenticate(credentials: IntegrationCredential): Promise<any> {
    return this._tokenService.put('pms_vendor_integration/authenticate_practice', credentials.to_params())
      .toPromise()
      .then(response => response);
  }

  getLocations(integrated_practice_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('integrated_practice_id', integrated_practice_id);
    return this._tokenService.get('pms_vendor_integration/locations', {params: params})
      .toPromise()
      .then(response => response);
  }
  getProviders(integrated_practice_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('integrated_practice_id', integrated_practice_id);
    return this._tokenService.get('pms_vendor_integration/providers', {params: params})
      .toPromise()
      .then(response => response);
  }

  getResources(integrated_practice_id: any): Promise<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('integrated_practice_id', integrated_practice_id);
    return this._tokenService.get('pms_vendor_integration/resources', {params: params})
      .toPromise()
      .then(response => response);
  }

  getVendors(): Promise<any> {
    return this._tokenService.get('pms_vendor_integration/pms_vendors')
      .toPromise()
      .then(response => response);
  }
  getIntegratePractices(): Promise<any> {
    return this._tokenService.get('pms_vendor_integration/integrated_practices')
      .toPromise()
      .then(response => response);
  }
  updateIntegratedPractice(ip_id: any, active: any): Promise<any> {
    return this._tokenService.put('pms_vendor_integration/update_integrated_practice', {id: ip_id, active: active})
      .toPromise()
      .then(response => response);
  }
}
