import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import {RoutesModule} from  './routes/routes.module';
import { HttpClientModule }     from '@angular/common/http';
import { SearchService } from '../services/search.service';
import { RatingModule } from 'ngx-bootstrap/rating';
import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { PatientPanelComponent } from './patient-profile/patient-panel/patient-panel.component';
import { PatientDashboardComponent } from './patient-profile/patient-dashboard/patient-dashboard.component';
import { ChartComponent } from './patient-profile/patient-dashboard/chart/chart.component';
import { DocumentsComponent } from './patient-profile/patient-dashboard/documents/documents.component';
import {SlotsService} from  '../services/slots.service'
import {SlotDatesService} from  '../services/slot-dates.service';
import { AddiotionalInfoComponent } from './patient-profile/patient-dashboard/addiotional-info/addiotional-info.component';
import { CaseInfoComponent } from './patient-profile/patient-dashboard/case-info/case-info.component';
import { ListAppointmentsComponent } from './patient-profile/patient-dashboard/list-appointments/list-appointments.component';
import { SummaryComponent } from './patient-profile/patient-dashboard/summary/summary.component';
import { DocumentViewerComponent } from './patient-profile/patient-dashboard/document-viewer/document-viewer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MrNumberComponent } from './mr-number/mr-number.component';
import { CasesInfoListingComponent } from './patient-profile/patient-dashboard/cases-info-listing/cases-info-listing.component';
import { PrintPchfComponent } from './patient-profile/patient-dashboard/print-pchf/print-pchf.component';
import { CarouselModule} from 'ngx-bootstrap/carousel';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { AppointmentLayoutComponent } from './appointment-layout/appointment-layout.component';
import {NgxMaskModule} from 'ngx-mask'
import {EnoteModule} from '../enote/enote.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {VideoChatModule} from '../video-chat/video-chat.module';
import { PatientCommunicationLogComponent } from './patient-profile/patient-dashboard/patient-communication-log/patient-communication-log.component';
import { OutreachCommunicationLogComponent } from './patient-profile/patient-dashboard/patient-communication-log/outreach-communication-log/outreach-communication-log.component';
import { PatientTaskCommunicationLogComponent } from './patient-profile/patient-dashboard/patient-communication-log/patient-task-communication-log/patient-task-communication-log.component';
import {TasksModule} from '../routes/practice-site/tasks/tasks/tasks.module';
import { PatientNotesComponent } from './patient-profile/patient-dashboard/patient-communication-log/patient-notes/patient-notes.component';
import { PatientNotesFilterComponent } from './patient-profile/patient-dashboard/patient-communication-log/patient-notes/patient-notes-filter/patient-notes-filter.component';
import { NewPatientNotesComponent } from './patient-profile/patient-dashboard/patient-communication-log/patient-notes/new-patient-notes/new-patient-notes.component';
import { EcontactComponent } from './patient-profile/econtact/econtact.component';
import { PatientProgramsComponent } from './patient-profile/patient-dashboard/patient-programs/patient-programs.component';
// tslint:disable-next-line:max-line-length
import { PatientAlertsSettingsComponent } from './patient-profile/patient-setting/patient-alerts-settings/patient-alerts-settings.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { PatientSettingComponent } from './patient-profile/patient-setting/patient-setting.component';
import { VitalsRangesComponent } from './patient-profile/patient-setting/vitals-ranges/vitals-ranges.component';
import { HealthCalendarComponent } from './patient-profile/health-calendar/health-calendar.component';
import { HealthCalendarHeaderComponent } from './patient-profile/health-calendar/health-calendar-header/health-calendar-header.component';
import { HealthCalendarViewComponent } from './patient-profile/health-calendar/health-calendar-view/health-calendar-view.component';
import {CalendarCommonModule, CalendarDayModule, CalendarMonthModule, CalendarWeekModule} from 'angular-calendar';
import { PatientEventComponent } from './patient-profile/health-calendar/patient-event/patient-event.component';
import { MedicationSummaryComponent } from './patient-profile/patient-dashboard/summary/medication-summary/medication-summary.component';
import { MedicationSummariesComponent } from './patient-profile/patient-dashboard/summary/medication-summaries/medication-summaries.component';
import { HealthCalendarReportComponent } from './patient-profile/health-calendar/health-calendar-report/health-calendar-report.component';
import { HealthCalendarReportPrinterComponent } from './patient-profile/health-calendar/health-calendar-report-printer/health-calendar-report-printer.component';
import { PatientProviderEnotesComponent } from './patient-profile/patient-dashboard/patient-provider-enotes/patient-provider-enotes.component';
import {HealthCalendarTableComponent} from '../program/bills/print-bill/report-selection/health-calendar-table/health-calendar-table.component';
import { PatientIntakeWorkflowComponent } from './patient-profile/patient-intake-workflow/patient-intake-workflow.component';

@NgModule({
  imports: [NgxMaskModule.forRoot(), EnoteModule,
    BrowserAnimationsModule, CommonModule, RatingModule.forRoot(), TasksModule,
    RouterModule, SharedModule, RoutesModule, HttpClientModule, SharedModule,
    CarouselModule.forRoot(), BsDatepickerModule, NgSelectModule, VideoChatModule, MatSlideToggleModule,
    CalendarCommonModule, CalendarMonthModule, CalendarWeekModule, CalendarDayModule
  ],
  declarations: [PrintPchfComponent, PatientProfileComponent, PatientPanelComponent,
    PatientDashboardComponent, AddiotionalInfoComponent, CaseInfoComponent, ChartComponent,
    DocumentsComponent, ListAppointmentsComponent, SummaryComponent, DocumentViewerComponent,
    MrNumberComponent, CaseInfoComponent, AddiotionalInfoComponent, CasesInfoListingComponent,
    AppointmentLayoutComponent,
    PatientCommunicationLogComponent,
    OutreachCommunicationLogComponent,
    PatientTaskCommunicationLogComponent,
    PatientNotesComponent,
    PatientNotesFilterComponent,
    NewPatientNotesComponent,
    PatientProgramsComponent,
    PatientAlertsSettingsComponent,
    PatientSettingComponent,
    VitalsRangesComponent,
    HealthCalendarComponent,
    HealthCalendarHeaderComponent,
    HealthCalendarViewComponent,
    PatientEventComponent,
    MedicationSummaryComponent,
    MedicationSummariesComponent,
    HealthCalendarReportComponent,
    HealthCalendarReportPrinterComponent,
    HealthCalendarTableComponent,
    PatientProviderEnotesComponent,
    PatientIntakeWorkflowComponent],
    exports: [RoutesModule, RatingModule, NewPatientNotesComponent, EcontactComponent, DocumentsComponent,
        PatientSettingComponent, HealthCalendarComponent, MedicationSummariesComponent, HealthCalendarTableComponent],
  providers: [SearchService, SlotsService, SlotDatesService],
  entryComponents: [
    PrintPchfComponent, NewPatientNotesComponent, PatientEventComponent, HealthCalendarReportPrinterComponent
  ]
})
export class HealthModule { }
