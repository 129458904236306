import {Component, EventEmitter, OnInit, Output, ElementRef, ViewChild} from '@angular/core';
import {PatientReview} from '../patient-review';
import {LoaderService} from '../../services/loader.service';
import {PracticeReviewService} from '../practice_review.service';
import {LocationService} from '../../services/location.service';
import {Practice} from '../../routes/practices/practices/practice';
import {User} from '../../routes/users/user';
import {UserService} from '../../services/user.service';
import {Patient} from '../../health/doctor/patient';
import {PatientService} from '../../services/patient.service';
import {Doctor} from '../../routes/practices/doctors/doctor';
import {Location} from '../../routes/practices/locations/location';
import {DoctorService} from '../../services/doctor.service';
import {PracticeReviewSetup} from '../practice_review_setup';
import {PatientReviewService} from '../patient-review.service';
import {GlobalErrorHandlerService} from '../../services/global-error.service';
import {PracticesService} from '../../services/practices.service';
import {Tab} from '../../tabs/tab';
import {PdfPrinterComponent} from '../../shared/pdf-printer/pdf-printer.component';
import {ReviewReportPrinterComponent} from '../review-report-printer/review-report-printer.component';
import {TabsService} from '../../services/tabs.service';



@Component({
  selector: 'app-reviews-filter',
  templateUrl: './reviews-filter.component.html',
  styleUrls: ['./reviews-filter.component.scss']
})
export class ReviewsFilterComponent implements OnInit {

  public reviews: PatientReview[];
  practice_review_setup: PracticeReviewSetup = new PracticeReviewSetup();
  // tslint:disable-next-line:max-line-length
  public filter: Record<string, any> = {from: '', to: '', time_type: '', page: '1', keyword: '', reviewable_type: '', reviewable_id: '',
    time: '', location: '', rating: '', description: '', tag: '', gender: '', doctor: '', practice_id: '',
    location_name: '', doctor_name: ''};
  public durations: Array<string> = ['Last 7 days', 'Current Month', 'Last Month', 'Last 3 months', 'Last 6 months', 'Last Year', 'All times', 'Custom Date'];
  public totalItems: string;
  public switch = false;
  public practiceLocations: any[];
  public providers: any[];
  oneStarReviewsCount: number;
  twoStarReviewsCount: number;
  threeStarReviewsCount: number;
  fourStarReviewsCount: number;
  fiveStarReviewsCount: number;
  oneStarWidth: number;
  twoStarWidth: number;
  threeStarWidth: number;
  fourStarWidth: number;
  fiveStarWidth: number;
  isOpen = false;
  showPracticeDashboard = false;
  showSaveButton = false;
  practiceID: any;
  practices: Practice[];
  practiceName: string;
  text: string;
  reviewableId: any;
  review_response_stats: any;
  show_datepicker = false;
  @ViewChild('dp') dp: any;
  dateRangeValue: Date[];
  constructor(public practiceService: PracticesService, public globalErrorHandlerService: GlobalErrorHandlerService,
              private errorHandler: GlobalErrorHandlerService,
              public patient_review_service: PatientReviewService,
              public patientsService: PatientService,
              private doctorService: DoctorService,
              public userService: UserService,
              public locationService: LocationService,
              public reviewsService: PracticeReviewService ,
              public _tab_service: TabsService,
              public loaderService: LoaderService) { }
  ngOnInit() {
    this.loaderService.show();
    if (this.userService.current_user.isPractice) {
      this.getPracticeSetup(this.userService.current_user.practices_id);
    }
    if (this.userService.current_user.isEnterprise) {
      this.getReviewsForEnterprise();
    }
  }

  getPracticeSetup(practice) {
    this.patient_review_service.getPracticeSetup(practice).then(resp => {
      this.practice_review_setup = this.practice_review_setup.load_from_json(resp);
      if (this.practice_review_setup.review_type === 'both' || this.practice_review_setup.review_type === 'practice') {
        this.filter['reviewable_type'] = 'Practice'
        if (this.userService.current_user.isEnterprise) {
          this.filter['reviewable_id'] = practice;
        }
        this.reviewableId = practice;
      }
      if (this.practice_review_setup.review_type === 'doctor') {
        this.filter['reviewable_type'] = 'Doctor'
        this.filter['practice_id'] = practice
      }
      this.getPracticeReviews(practice);
    }).catch(error => {
      this.filter['reviewable_type'] = 'Practice';
      this.filter['reviewable_id'] = practice;
      this.errorHandler.error = 'Review Module is not Configured.'
      this.getPracticeReviews(practice);
    });
  }
  getReviewsForEnterprise() {
    this.practiceService.getAllPracticeForReviews().then(resp => {
      this.practices = resp;
      this.loaderService.hide()
    })
  }
  clearPracticeFilter() {
    this.text = '';
    this.searchAllPracticeForReviews();
  }
  searchAllPracticeForReviews() {
    this.practiceService.searchAllPracticeForReviews(this.text).then(resp => {
      this.loaderService.show();
      this.practices = resp;
      this.loaderService.hide();
    })
  }
  getReviewsOfPractice(practice) {

    this.loaderService.show();
    this.practiceName = practice.name;
    this.practiceID = practice.id;
    this.getPracticeSetup(practice.id);
    this.showPracticeDashboard = !this.showPracticeDashboard
  }

  back() {
    this.filter = {};
    this.reviews = [];
    this.review_response_stats  = {}
    this.calculateReviewCountAndWidth(0);
    this.showPracticeDashboard = !this.showPracticeDashboard;
  }


  getPracticeReviews(practice) {
    this.getPracticeLocations(practice);
    this.getPracticeDoctor(practice);
    if (this.userService.current_user.isPractice) {
      this.reviewsService.getSaveFilters(this.userService.current_user.id).then(resp => {
        if (resp.filter_data &&
          (resp.filter_data['reviewable_type'].toLowerCase() === this.practice_review_setup.review_type
            || this.practice_review_setup.review_type === 'both'
          )) {
          this.filter_mapper(resp.filter_data);
        }
        this.getReviews();
      }).catch(error => {
        this.getReviews();
      });
    }
    if (this.userService.current_user.isEnterprise) {
      this.getReviews();
    }
  }

  getPracticeDoctor(practice) {
    if (this.userService.user_logged_in && this.userService.current_user) {
      this.doctorService.getPracticeDoctors(practice).then(resp => {
        if (resp && resp.is_doctor) {
          this.providers = resp.doctor.map(doctor => new Doctor().load_from_json(doctor))
        } else {
          this.providers = resp.map(doctor => new Doctor().load_from_json(doctor))
        }
      })
    }
  }



  filter_mapper(json) {
    // this.filter['page'] = json.page;
    this.filter['keyword'] = json.keyword;
    this.filter['reviewable_id'] = json.reviewable_id;
    this.filter['reviewable_type'] = json.reviewable_type;
    this.filter['time'] = json.time;
    this.filter['to'] = json.to;
    this.filter['from'] = json.from;
    this.filter['location'] = json.location;
    this.filter['doctor'] = json.doctor;
    this.filter['tag'] = json.tag;
    this.filter['description'] = json.description;
    this.filter['gender'] = json.gender;
    this.filter['rating'] = json.rating;
    this.filter['time_type'] = json.time_type;
    if (this.filter['time_type'] === 'Custom Date') {
      this.dateRangeValue = [new Date(json.from), new Date(json.to)];
      this.filter['time'] = new Date(this.filter['time']);
      this.isOpen = true;
    }
  }

  selectDoctorFilter(doctor) {
    this.filter['reviewable_id'] = doctor.value;
    if (doctor.value) {
      this.filter['doctor_name'] = this.providers.filter(a => a.id == doctor.value)[0]['name'];
    } else {
      delete this.filter['doctor_name'];
    }
  }

  selectLocationFilter(location) {
    this.filter['location'] = location.value;
    if (location.value) {
      this.filter['location_name'] = this.practiceLocations.filter(a => a.id == location.value)[0]['name'];
    } else {
      delete this.filter['location_name']
    }
  }
  calculateReviewCountAndWidth(reviewsCount) {
    this.fiveStarReviewsCount = reviewsCount['fiveStarCount'];
    this.fourStarReviewsCount = reviewsCount['fourStarCount'];
    this.threeStarReviewsCount = reviewsCount['threeStarCount'];
    this.twoStarReviewsCount = reviewsCount['twoStarCount'];
    this.oneStarReviewsCount = reviewsCount['oneStarCount'];
    this.oneStarWidth =  reviewsCount['oneStarWidth'];
    this.twoStarWidth =  reviewsCount['twoStarWidth'];
    this.threeStarWidth =  reviewsCount['threeStarWidth'];
    this.fourStarWidth =  reviewsCount['fourStarWidth'];
    this.fiveStarWidth =  reviewsCount['fiveStarWidth'];
    if (this.filter['rating'] === 5) {
      this.fiveStarWidth =  this.fiveStarReviewsCount === 0 ? 100 : reviewsCount['fiveStarWidth'];
      this.fourStarWidth = 0;
      this.threeStarWidth = 0;
      this.twoStarWidth = 0;
      this.oneStarWidth = 0;
    }
    if (this.filter['rating'] === 4) {
      this.fourStarWidth =  this.fourStarReviewsCount === 0 ? 100 : reviewsCount['fourStarWidth'];
      this.fiveStarWidth = 0;
      this.threeStarWidth = 0;
      this.twoStarWidth = 0;
      this.oneStarWidth = 0;
    }
    if (this.filter['rating'] === 3) {
      this.threeStarWidth =  this.threeStarReviewsCount === 0 ? 100 : reviewsCount['threeStarWidth'];
      this.fourStarWidth = 0;
      this.fiveStarWidth = 0;
      this.twoStarWidth = 0;
      this.oneStarWidth = 0;
    }
    if (this.filter['rating'] === 2) {
      this.twoStarWidth =  this.twoStarReviewsCount === 0 ? 100 : reviewsCount['twoStarWidth'];
      this.fourStarWidth = 0;
      this.threeStarWidth = 0;
      this.fiveStarWidth = 0;
      this.oneStarWidth = 0;
    }
    if (this.filter['rating'] === 1) {
      this.oneStarWidth =  this.oneStarReviewsCount === 0 ? 100 : reviewsCount['oneStarWidth'];
      this.fourStarWidth = 0;
      this.threeStarWidth = 0;
      this.twoStarWidth = 0;
      this.fiveStarWidth = 0;
    }

  }
  getReviews() {
    this.loaderService.show();
    this.reviewsService.getReviews(this.filter).then(resp => {
      this.reviews = resp.reviews.map(review => new PatientReview().load_from_json(review));
      this.totalItems = resp.total;
      this.filter['page'] = resp.current_page;
      this.calculateReviewCountAndWidth(resp.reviewsCount);
      this.review_response_stats = resp.review_response_stats;
      this.loaderService.hide();
    }).catch(resp => {
      this.loaderService.hide();
      this.globalErrorHandlerService.error = resp.error.error;
    });
  }

  pageChanged(page) {
    this.filter['page'] = page;
    this.getReviews();
  }

  getReviewsOnRating(rating) {
    delete this.filter['page']
    if (rating === this.filter['rating']) {
      delete this.filter['rating'];
    }
    else {
      this.filter['rating'] = rating;
    }
    this.getReviews();
  }

  getReviewsOnDescription(value) {
    delete this.filter['page']
    if (value === 1) {
      if (this.filter['description'] === true) {
        delete this.filter['description'];
      }
      else {
        this.filter['description'] = true;
      }
    }
    else if (value === 0) {
      if (this.filter['description'] === false) {
        delete this.filter['description'];
      }
      else {
        this.filter['description'] = false;
      }
    }
    else {
      delete this.filter['description'];
    }
    this.getReviews();
  }

  getReviewsOnTag(value) {
    delete this.filter['page']
    if (value === 1) {
      if (this.filter['tag'] === true) {
        delete this.filter['tag'];
      }
      else {
        this.filter['tag'] = true;
      }
    }
    else if (value === 0) {
      if (this.filter['tag'] === false) {
        delete this.filter['tag'];
      }
      else {
        this.filter['tag'] = false;
      }
    }
    else {
      delete this.filter['tag'];
    }
    this.getReviews();
  }

  getReviewsOnGender(value) {
    delete this.filter['page']
    if (value === 0) {
      if (this.filter['gender'] === 'male') {
        delete this.filter['gender'];
      }
      else {
        this.filter['gender'] = 'male';
      }
    }
    else if (value === 1) {
      if (this.filter['gender'] === 'female') {
        delete this.filter['gender'];
      }
      else {
        this.filter['gender'] = 'female';
      }
    }
    else if (value === 2) {
      if (this.filter['gender'] === 'trans') {
        delete this.filter['gender'];
      }
      else {
        this.filter['gender'] = 'trans';
      }
    }
    else if (value === 3) {
      if (this.filter['gender'] === 'non_binary') {
        delete this.filter['gender'];
      }
      else {
        this.filter['gender'] = 'non_binary';
      }
    }
    else {
      if (this.filter['gender'] === 'other') {
        delete this.filter['gender'];
      }
      else {
        this.filter['gender'] = 'other';
      }
    }
    this.getReviews();
  }
  selectDuration(time) {
    if (time.value === 'Custom Date') {
      this.isOpen = true;
      setTimeout( () => {
        this.dp.show();
      }, 150);
    }
    else {
      this.isOpen = false;
      delete this.filter['page'];
      delete this.filter['to'];
      delete this.filter['from'];
    }
    this.filter['time_type'] = time.value;
    delete this.filter['page'];
  }

  filterReviews() {
    this.getReviews();
  }
  saveFilters() {
    this.reviewsService.saveFilters(this.filter).then();
  }

  getPracticeLocations(paractice_id) {
    let id = paractice_id;
    this.locationService.practice_locations(id).then(resp => {
      this.practiceLocations = resp;
    });
  }

  getReviewsOf() {
    // delete this.filter['page'];
    for (let key in this.filter ) {
      if (key === 'location' || key === 'doctor' || key === 'reviewable_id') {
        this.filter[key] = null;
      }
    }
    if (this.filter['reviewable_type'] === 'Doctor') {
      this.filter['reviewable_type'] = 'Practice';
      if (this.userService.current_user.isEnterprise) {
        delete this.filter['practice_id'];
        this.filter['reviewable_id'] = this.reviewableId;
      }

    }
    else {
      this.filter['reviewable_type'] = 'Doctor';
      if (this.userService.current_user.isEnterprise) {
        this.filter['practice_id'] = this.practiceID;
      }
    }
    delete this.filter['location_name'];
    delete this.filter['doctor_name'];
    this.getReviews();
    this.switch = ! this.switch;
  }

  add_to_tabs() {
    this._tab_service.add_tab(new Tab({
      title: 'Report',
      icon: 'fas fa-print'

    }, ReviewReportPrinterComponent, 'this.ledger.id + type', {filter: this.filter}))
  }

  setDateRange(event: Date[]) {
    if (event) {
      this.filter['from'] = String(event[0]);
      this.filter['to'] = String(event[1]);
    }
  }
}
