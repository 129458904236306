export  class BaseModel {
  custom_loaded = true;
  reminder_status: string;
  constructor() {
    this.refresh_yourself();
  }
  refresh_yourself() {
    this.custom_loaded = false;
    setTimeout( () => {
      this.custom_loaded = true;
    }, 50);
  }

  capitalize(string) {
    if (string == undefined) {
      return;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
